// material
import { experimentalStyled as styled } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import {
  Box,
  Card,
  Grid,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  IconButton,
  Stack,
  Button,
} from '@material-ui/core'
import { DeleteOutlineOutlined } from '@material-ui/icons'
//
import { useNavigate } from 'react-router'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { useState } from 'react'
import ConfirmationPopup from 'src/components/confirmationPopup'
import axios from 'axios'
import { REST_API_END_POINT } from 'src/constants/Defaultvalues'
import { useSnackbar } from 'notistack'

const img =
  'https://www.shutterstock.com/image-photo/indianapolis-circa-february-2017-pizza-260nw-572078815.jpg'

const CardMediaStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  paddingTop: 'calc(100% * 3 / 16)',
  '&:before': {
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
    //  backdropFilter: 'blur(3px)',
    WebkitBackdropFilter: 'blur(3px)', // Fix on Mobile
    borderTopLeftRadius: theme.shape.borderRadiusMd,
    borderTopRightRadius: theme.shape.borderRadiusMd,
    // backgroundColor: alpha(theme.palette.primary.darker, 0.72)
  },
}))

export default function Members({ setFetchData, data,currentTab,designOrder, projectData}) {
  const theme = useTheme()
  let isTeam = true
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();
  const [True, setTrue] = useState(false)
  const [isDelete,setIsDelete] = useState(false)
  const [deleteStates, setDeleteStates] = useState({});
  const user = JSON.parse(window.localStorage.getItem('user'))
  const explodedPermissions = user?.exploded_permissions

  const handleDeleteClick = (postId) => {
    setDeleteStates((prevState) => ({
      ...prevState,
      [postId]: true,
    }));
  };

  const handleCancelDelete = (postId) => {
    setDeleteStates((prevState) => ({
      ...prevState,
      [postId]: false,
    }));
  };

  const handleDeleteMember = (id,projectId)=>{
    try {
      // const  user  = JSON.parse(window.localStorage.getItem('user'))
      let apiUrl = '';
      if(designOrder){
        apiUrl = `design-order-job/delete-team-member`
      }else{
        apiUrl = `project/delete-project-team-member`
      }
      axios.post(`${REST_API_END_POINT}${apiUrl}`,{ id: Number(id), projectId },{
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if(response.data.status === 1){
          enqueueSnackbar(response.data.message, { variant: 'success' });
          setFetchData(true)
        }else if(response.data.status === 0){
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
        handleCancelDelete(id)
      }).catch((error) => {
        console.error(error);
      })
      }
      catch (error) {
        console.error("Error fetching data:", error);
      }
  }
  return (
    <Grid container spacing={3}>
      {data?.map((data, index) =>{
        console.log('data====',data)
       return (
        <Grid key={index} item xs={12} sm={6}>
          <Card
            key={index}
            sx={{
              minWidth: 275,
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'scale(1.02)',
              },
            }}
            onMouseEnter={() => setTrue(true)}
            onMouseLeave={() => setTrue(false)}
            onClick={() => {
              // navigate(PATH_DASHBOARD.blog.viewproject)
            }}
          >
            <CardMediaStyle>
              {/* <SvgIconStyle
                    color="paper"
                    src="/static/icons/shape-avatar.svg"
                    sx={{
                        width: 144,
                        height: 62,
                        zIndex: 10,
                        bottom: -26,
                        position: 'absolute'
                    }}
                    /> */}
              {((True && user.user_type !== 3) &&
                (currentTab != 'Installs' && currentTab != 'Pickup' && currentTab != 'Shipping' && 
                currentTab!="Completed" && currentTab!="Hold" && projectData?.status != 3 && projectData?.hold != 1) 
                && (!designOrder || explodedPermissions?.some((permission) => ['Delete Member from Design Order Job'].includes(permission))) 
                ) && (
                <DeleteOutlineOutlined
                  onClick={() => handleDeleteClick(data.id)}
                  sx={{
                    zIndex: 10,
                    top: 10,
                    right: 16,
                    color: theme.palette.grey[500],
                    position: 'absolute',
                    cursor: 'pointer',
                  }}
                />
              )}
              <Avatar
                // alt={data?.name}
                src={data?.profile_pic}
                sx={{
                  width: 64,
                  height: 64,
                  // zIndex: 11,
                  // position: 'absolute',
                  // transform: 'translateY(-50%)'
                }}
              />
              {/* <CoverImgStyle alt="cover" src={logo} /> */}
            </CardMediaStyle>
            <Box sx={{ p: 2, textAlign: 'center', justifyContent: 'center' }}>
              <Stack justifyContent={'center'} alignItems={'center'}>
                <Typography variant="h6" align="center">
                  {data?.name}
                </Typography>
                <Typography variant="caption" align="center">
                  {data?.user_role}
                </Typography>
              </Stack>
            </Box>
            <Box
              sx={{
                mb: 5,
                height: 20,
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              {(True && user.user_type !== 3) && (
                <Button
                  sx={{
                    background: theme.palette.primary.dark,
                    boxShadow: 'none',
                  }}
                  size="small"
                  onClick={() =>
                    navigate(PATH_DASHBOARD.callCenter.teamaccount, { state: { data:data.staff_id,isTeam: true } })
                    // navigate(PATH_DASHBOARD.callCenter.teamaccount)
                  }
                  variant="contained"
                >
                  View Profile
                </Button>
              )}
            </Box>
          </Card>
          <ConfirmationPopup open={deleteStates[data.id]} 
            handleClick={()=>{
              if(designOrder){
                handleDeleteMember(data.id,data.design_order_id)
              }else{
                handleDeleteMember(data.id,data.project_id)
              }
            }} 
            message={'You want to delete this Member'} handleClose={()=>handleCancelDelete(data.id)}/>
        </Grid>
      )})}
    </Grid>
  )
}
