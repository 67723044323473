import React, { useRef, useState } from 'react';
import { Button, Checkbox, Typography, Menu, MenuItem, ListItemText, List, Stack } from '@material-ui/core';
import { useEffect } from 'react';
import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';

const Group = [
  { title: 'Sales' },
  { title: 'Design' },
  { title: 'Production' },
  // Add more services as needed
];


export default function Popbar({
   onFilterServices, onFilterLocation, onFilterAvailability, isAdmin,
   clientServices,
   onFilterRank,
   clientAvailability,
   setClientAvailability,
   setClientServices,
    clientGroup,
   setClientGroup,
   clientLocation,
   setClientLocation,
   clientRank,
   setClientRank, }) {
  // const MyComponent = ({ isAdmin }) => {
  //------------------------------------------------------------------------------------------
  //get user logged in data from local storage
  const user = localStorage.getItem('user')
  const userData = JSON.parse(user)
  //------------------------------------------------------------------------------------------
  const ref = useRef(null);
  const refLoc = useRef(null);
  const refRank = useRef(null);
  const refGroup = useRef(null);
  const refAvailabilty = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [locationOpen, setLocationOpen] = useState(false);
  const [rankOpen, setRankOpen] = useState(false);
  const [groupOpen, setGroupOpen] = useState(false);
  const [availabilityOpen, setAvailabilityOpen] = useState(false);

  const [groups, setGroups] = useState(Group);


  //--Popbar items for Team member---------------------------------------
  const refTeamAvail = useRef(null);
  const refTeamServices = useRef(null);
  const refTeamStates = useRef(null);
  const [teamAvailabilitiesOpen, setTeamAvailabilitiesOpen] = useState(false);
  const [teamServicesOpen, setTeamServicesOpen] = useState(false);
  const [teamStatesOpen, setTeamStatesOpen] = useState(false);
  const [teamAvailabilities, setTeamAvailabilities] = useState([]);
  const [teamServices, setTeamServices] = useState([]);
  const [teamStates, setTeamStates] = useState([]);

  //-----------------------------------------------------------------------
  
  if (isAdmin) {
    useEffect(() => {
      const fetchData = async () => {
        try {

          const response = await axios.post(`${REST_API_END_POINT}teams/get-all-teams-data`, {
            usersId: userData.user_id,
            usersType: userData.user_type
          });

          if(isAdmin== true){
          setTeamAvailabilities(response.data.availabilities)
          setTeamServices(response.data.services)
          setTeamStates(response.data.states)
          }
        } catch (error) {
          console.log("Error fetching data : ", error);
        }
      }
      fetchData();
    }, []);
  }

  return (
    isAdmin ?
      (<>
       
        <Button ref={refTeamStates} onClick={() => setTeamStatesOpen(!teamStatesOpen)} >
          <Typography variant='overline' gutterBottom sx={{ color: 'text.secondary' }}>Location</Typography>
        </Button>

        <Menu
          open={Boolean(teamStatesOpen)}
          anchorEl={refTeamStates.current}
          onClose={() => setTeamStatesOpen(false)}
          PaperProps={{
            sx: { width: 'auto', maxWidth: '100%' }
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <List style={{ maxHeight: '250px', overflowY: 'auto' }}>
            {teamStates?.map((type) => (
              <MenuItem key={type.id} >
                <ListItemText primary={`${type.name} (${type.no_of_teams})`} />
                <Checkbox
                  checked={type.checked ? type.checked : false}
                  onChange={() => {
                    const updatedType = [...teamStates];
                    const item = updatedType.find((item) => item.id === type.id);
                    if (item) {
                      item.checked = !item.checked;
                      setTeamStates(updatedType);
                      onFilterLocation((prevIds) => {
                        if (item.checked) {
                          return [...prevIds, item.id];
                        } else {
                          return prevIds.filter((id) => id !== item.id);
                        }
                      });
                    }
                  }}
                />
              </MenuItem>
            ))}
          </List>
        </Menu>

        {/* Availabilities Button and Popover */}

        <Button ref={refTeamAvail} onClick={() => setTeamAvailabilitiesOpen(!teamAvailabilitiesOpen)} >
          <Typography variant='overline' gutterBottom sx={{ color: 'text.secondary' }}>Onboarding</Typography>
        </Button>

        <Menu
          open={Boolean(teamAvailabilitiesOpen)}
          anchorEl={refTeamAvail.current}
          onClose={() => setTeamAvailabilitiesOpen(false)}
          PaperProps={{
            sx: { width: 'auto', maxWidth: '100%', }
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <List style={{ maxHeight: '250px', overflowY: 'auto' }}>
            {Object.values(teamAvailabilities).map((type) => (
              <MenuItem key={type.id} >
                <ListItemText primary={`${type.name} (${type.no_of_teams})`} />
                <Checkbox
                  checked={type.checked || false}
                  onChange={() => {
                    const updatedType = { ...teamAvailabilities };
                    const item = updatedType[type.id];
                    if (item) {
                      item.checked = !item.checked;
                      setTeamAvailabilities(updatedType);
                      onFilterAvailability((prevIds) => {
                        if (item.checked) {
                          return [...prevIds, item.id];
                        } else {
                          return prevIds.filter((id) => id !== item.id);
                        }
                      });
                    }
                  }}
                />
              </MenuItem>
            )
            )}
          </List>
        </Menu>
      </>)
      :
      (<>
        <Button ref={ref} onClick={() => setIsOpen(!isOpen)}>
          <Typography variant='overline' gutterBottom sx={{ color: 'text.secondary' }}>Services</Typography>
        </Button>
        <Menu
          open={Boolean(isOpen)}
          anchorEl={ref.current}
          onClose={() => setIsOpen(false)}
          PaperProps={{
            sx: { width: 300, maxWidth: '100%' }
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
        <List style={{ maxHeight: '250px', overflowY: 'auto' }}>
          {clientServices?.map((type) => (
            <MenuItem key={type.id} >
              <Checkbox
              checked={type.checked || false} 
              onChange={() => {
                const updatedType = clientServices.map((item) =>
                  item.id === type.id ? { ...item, checked: !item.checked } : item
                );
                setClientServices(updatedType);
                const selectedIds = updatedType
                  .filter((item) => item.checked)
                  .map((item) => item.id);
        
                onFilterServices(selectedIds);
              }}
            />
            <ListItemText primary={`${type.name} (${type.no_of_partners})`} />
            </MenuItem>
          ))}
          </List>
        </Menu>
        {/* location */}
        <Button ref={refLoc} onClick={() => setLocationOpen(!locationOpen)} >
          <Typography variant='overline' gutterBottom sx={{ color: 'text.secondary' }}>Locations</Typography>
        </Button>

        <Menu
          open={Boolean(locationOpen)}
          anchorEl={refLoc.current}
          onClose={() => setLocationOpen(false)}
          PaperProps={{
            sx: { width: 250, maxWidth: '100%' }
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
        
        <List style={{ maxHeight: '250px', overflowY: 'auto' }}>
        {clientLocation?.map((type) => (
          <MenuItem key={type.id}>
          <ListItemText primary={`${type.name} (${type.no_of_partners})`} />
            <Checkbox
              checked={type.checked || false} 
              onChange={() => {
                const updatedType = clientLocation.map((item) =>
                  item.id === type.id ? { ...item, checked: !item.checked } : item
                );
        
                setClientLocation(updatedType);
        
                const selectedIds = updatedType
                  .filter((item) => item.checked)
                  .map((item) => item.id);
        
                onFilterLocation(selectedIds);
              }}
            />
          </MenuItem>
        ))}
        
          </List>
        </Menu>
        {/* Groups Button and Popover */}
         {/* <Button ref={refGroup} onClick={() => setGroupOpen(!groupOpen)} >
            <Typography variant='overline' gutterBottom sx={{ color: 'text.secondary' }}> Groups</Typography>
            </Button> */}

        <Menu
          open={Boolean(groupOpen)}
          anchorEl={refGroup.current}
          onClose={() => setGroupOpen(false)}
          PaperProps={{
            sx: { width: 200, maxWidth: '100%' }
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {groups.map((type) => (
            <MenuItem key={type.id} >
              <Checkbox
                checked={type.checked}
                onChange={() => {
                  const updatedType = [...groups];
                  const item = updatedType.find((item) => item.id === type.id);
                  if (item) {
                    item.checked = !item.checked;
                  }
                  setGroups(updatedType);
                }}
              />
              <ListItemText primary={type.title} />
            </MenuItem>
          ))}
        </Menu>

        {/* Ranks Button and Popover */}
        <Button ref={refRank} onClick={() => setRankOpen(!rankOpen)} >
          <Typography variant='overline' gutterBottom sx={{ color: 'text.secondary' }}>  Ranks</Typography>
        </Button>

        <Menu
          open={Boolean(rankOpen)}
          anchorEl={refRank.current}
          onClose={() => setRankOpen(false)}
          PaperProps={{
            sx: { width: 200, maxWidth: '100%' }
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {clientRank?.map((type) => (
            <MenuItem key={type.id} >
            <Checkbox
            checked={type.checked || false} 
            onChange={() => {
              const updatedType = clientRank?.map((item) =>
                item.id === type.id ? { ...item, checked: !item.checked } : item
              );
      
              setClientRank(updatedType);
      
              const selectedIds = updatedType
                .filter((item) => item.checked)
                .map((item) => item.id);
      
              onFilterRank(selectedIds);
            }}
          />
              <ListItemText primary={`${type.name} (${type.no_of_partners})`}/>
            </MenuItem>
          ))}
        </Menu>

        {/* Availabilities Button and Popover */}
        <Button ref={refAvailabilty} onClick={() => setAvailabilityOpen(!availabilityOpen)} >
        <Typography variant='overline' gutterBottom sx={{ color: 'text.secondary' }}> Onboarding</Typography>
      </Button>

     <Menu
  open={Boolean(availabilityOpen)}
  anchorEl={refAvailabilty.current}
  onClose={() => setAvailabilityOpen(false)}
  PaperProps={{
    sx: { width: 300, maxWidth: '100%' },
  }}
  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
>
{Object.values(clientAvailability ?? {}).map((type) => (
  <MenuItem key={type.id}>
    <Checkbox
      checked={type.checked || false}
      onChange={() => {
        const updatedType = Object.values(clientAvailability ?? {}).map((item) =>
          item.id === type.id ? { ...item, checked: !item.checked } : item
        );

        setClientAvailability(updatedType);

        const selectedIds = updatedType
          .filter((item) => item.checked)
          .map((item) => item.id);
          
        onFilterAvailability(selectedIds);
      }}
    />
    <ListItemText primary={`${type.name} (${type.no_of_partners})`} />
  </MenuItem>
))}

</Menu>

      </>)
  );
};

// export default MyComponent;
