import faker from 'faker';
import { sum } from 'lodash';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Card,
  Table,
  Divider,
  TableRow,
  Container,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
  Stack,
  Button,
  IconButton,
  TextField,
  LinearProgress,
  linearProgressClasses,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
  FormControl,
  Tabs,
  Tab,
  Autocomplete,
  Checkbox,
  Tooltip
} from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react'
// routes
import { PATH_DASHBOARD } from 'src/routes/paths.js';
import { useNavigate, useParams } from 'react-router'
import { Add, AddCircle, CheckBox, KeyboardBackspaceOutlined } from '@material-ui/icons';
import { Link as RouterLink, useLocation, } from 'react-router-dom'
// utils
import { fCurrency } from 'src/utils/formatNumber.js';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import searchFill from '@iconify/icons-eva/search-fill';

// components
import Page from 'src/components/Page.js';
import * as Yup from 'yup';
import Label from 'src/components/Label.js';
import Scrollbar from 'src/components/Scrollbar.js';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs.js';
import InvoiceToolbar from 'src/components/_dashboard/e-commerce/invoice/InvoiceToolbar.js';
import JobCostToolbar from './JobCostToolbar';
import JobCostCartToolbar from './JobCostCartToolbar';
import ConfirmationPopup from 'src/components/confirmationPopup';
import Icon from '@iconify/react';
import { LoadingButton } from '@material-ui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import axios from 'axios';
import { COMPANY_NAME, REST_API_END_POINT, UNITS } from 'src/constants/Defaultvalues';
import { useSnackbar } from 'notistack';
import Modal from 'src/components/_dashboard/blog/ProjectModel';
import { useTheme } from '@material-ui/core/styles';
import LoadingScreen from 'src/components/LoadingScreen';
import { parse } from 'date-fns';
import { empty } from 'src/Helpers/Common';
import CompareJobCost from './CompareJobCost';



// ----------------------------------------------------------------------

const INVOICE = {
  id: faker.datatype.uuid(),
  taxes: 5,
  discount: 10,
  status: 'paid',
  invoiceFrom: {
    name: faker.name.findName(),
    address: 'DieSachbearbeiter Choriner Straße 49 10435 Berlin',
    company: faker.company.companyName(),
    email: faker.internet.email(),
    phone: faker.phone.phoneNumberFormat()
  },
  invoiceTo: {
    name: faker.name.findName(),
    address: 'Keas 69 Str. 15234, Chalandri Athens, Greece',
    company: faker.company.companyName(),
    email: faker.internet.email(),
    phone: faker.phone.phoneNumberFormat()
  },
  items: [...Array(1)].map(() => ({
    id: faker.datatype.uuid(),
    title: faker.lorem.sentence(),
    description: faker.lorem.lines(),
    qty: faker.datatype.number({ min: 1, max: 5 }),
    price: faker.datatype.number({ min: 4, max: 99, precision: 0.01 })
  }))
};

const RowResultStyle = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

const CardMediaStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  cursor: 'pointer',
  paddingTop: 'calc(100% * 9 / 16)',
  '&:before': {
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',

    WebkitBackdropFilter: 'blur(3px)',
    borderTopLeftRadius: theme.shape.borderRadiusMd,
    borderTopRightRadius: theme.shape.borderRadiusMd,

  },
}))

const CoverImgStyle = styled('img')({
  top: 0,
  zIndex: 8,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',

})

// ----------------------------------------------------------------------

export default function JobCost({recipeCostId,recipeCostCurrentTab,handleBackToAlljobs,handleClickOpenJobCost,setRecipeCostCurrentTab,popupOn,onDataChange}) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const user = JSON.parse(window.localStorage.getItem('user'))
  const subTotal = sum(INVOICE.items.map((item) => item.price * item.qty));
  const total = subTotal - INVOICE.discount + INVOICE.taxes;
  const location = useLocation();
  
  const locationCurrentTab = location?.state?.currentTab || null; 
  const currentTab = popupOn?recipeCostCurrentTab:locationCurrentTab;
  const params = localStorage.getItem("params")

  const [isHover, setIsHover] = useState(false)
  const [productList, setProductList] = useState([]);
  const [initialMaterials, setInitialMaterials] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [openDeletePop, setOpenDeletePop] = useState(false)
  const [deleteId, setDeleteId] = useState()
  const [renderCards, setRenderCards] = useState(false)
  const [materialsPreview, setMaterialsPreview] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedMaterial, setSelectedMaterial] = useState()
  const [manualTotalPayOut, setManualTotalPayOut] = useState(0)
  const [hourlyTotalPayOut, setHourlyTotalPayOut] = useState(0)
  const [materialPayOut, setMaterialPayOut] = useState(0)
  const [jobCost, setJobCost] = useState(0)
  const [staffTimelogs, setStaffTimelogs] = useState([])
  const [recipe, setRecipe] = useState([])
  const [isRecipe, setIsRecipe] = useState(false)
  const [ingredient, setIngredient] = useState(false)
  const [services, setAllServices] = useState([])
  const [additionalServices, setAdditionalServices] = useState([])
  const [projectData, setProjectData] = useState([])
  const [category, setCategory] = useState([{
    "id": length + 1,
    "name": "Product Formula",
    "added_by": 1,
    "status": 1,
    "timestamp": 1711096046,
    "c_id": 1,
    "authorized_by": "Rakesh Skyniche"
  }]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [color, setColor] = useState('#308fe8')
  const [errorFieldId, setErrorFieldId] = useState([]);
  const [jobCostPreview, setJobCostPreview] = useState(false)
  const logo = 'https://www.shutterstock.com/image-photo/checklist-clipboard-task-documentation-management-260nw-2270860299.jpg'

  const { id:paramsId } = useParams();
  const  id  = popupOn ? recipeCostId: paramsId

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: `hsl(${120 - (progressValue * 1.2)}, ${60}%, 50%)`, // `hsl(${(progressValue * 1.2)}, ${60 + (progressValue * 0.4)}%, 50%)`
    },
  }));
  const fetchData = () => {
    setLoading(true);
    try {
      axios.post(`${REST_API_END_POINT}project/fetch-all-job-cost-recipe`, 
        { userId: user.user_id, userType: user.user_type, projectId: id, orderId: params }).then((response) => {
        const productListData = response.data.results
        const stafftimelogs = response.data.stafftimelogs
        let selectedProducts = response.data.selectedMaterials
        let receivedRfpProducts=selectedProducts?.filter(row=>row.rfp===1)
        let rfpProducts = receivedRfpProducts.map(product => ({
            ...product,
            jobcost_unit_cost: product.unit_cost,
            jobcost_quantity: product.quantity,
            jobcost_actual_quantity: product.actual_quantity,
            jobcost_total: product.total,
        }));
        console.log('rfpProducts:', rfpProducts);

        selectedProducts=selectedProducts.filter(row=>row.rfp===0)
        let initialData = response.data.selectedMaterials
        const allPayouts = response.data.allPayOut
        const project = response.data.projects
        const allMaterials = response.data.allMaterials
        const allRecipes = response.data.allRecipies
        const material = response.data.materials
        let allServices = response.data.allServices
        let additionalServices = response.data.additionalServices
        if (additionalServices) {
          setAdditionalServices(additionalServices.reduce((accumulator, currentValue) => {
            return accumulator + parseFloat(currentValue?.jobcost_total_cost || 0);
            // return accumulator + (currentValue?.originalCost * currentValue?.qty);
          }, 0))
        }
        if (project) {
          setProjectData(project)
          let recipe = [{
            "id": 1,
            "name": project?.job_category_name === 'Time & Material' ? 'Staff Clock-In Time Details' : "Product Formula",
            "added_by": 1,
            "status": 1,
            "timestamp": 1711096046,
            "c_id": 1,
            "authorized_by": "Rakesh Skyniche"
          },
        ]
        if( project?.job_category_name !== 'Time & Material'){
          let  custom ={
            "id": 2,
            "name": 'Staff Clock-In Time Details' ,
            "added_by": 1,
            "status": 1,
            "timestamp": 1711096046,
            "c_id": 1,
            "authorized_by": "Rakesh Skyniche"
          }
          recipe.push(custom)
        }
          setCategory([...recipe]);
          setSelectedCategory(1)
        }
        if (allRecipes) {
          setRecipe(allRecipes)
        }
        if (stafftimelogs) {
          setStaffTimelogs(stafftimelogs)
        }
        if (material?.length > 0) {
          setMaterialPayOut(material?.reduce((acc, curr) => acc + curr.total, 0))
        }
        if (allServices) {
          setAllServices(allServices)
        }
        if (allPayouts?.length > 0) {
          let totalPayOut = allPayouts?.reduce((acc, curr) => acc + curr.amount, 0)
          const totalHourlyPayoutAmount = allPayouts.filter(payout => payout.is_auto_pay === 1).reduce((acc, curr) => acc + curr.amount, 0);
          const totalManualPayoutAmount = allPayouts.filter(payout => payout.is_auto_pay !== 1).reduce((acc, curr) => acc + curr.amount, 0);

          const summedHourlyPayouts = Object.values(
            allPayouts?.filter(payout => payout.is_auto_pay === 1)?.reduce((acc, payout) => {
              // Create a unique key based on project_id, task_id, and team
              const key = `${payout.project_id}-${payout.task_id || "null"}-${payout.team || "null"}`;
              
              // Initialize the group in the accumulator if it doesn't exist
              if (!acc[key]) {
                acc[key] = {
                  project_id: payout.project_id,
                  task_id: payout.task_id,
                  team: payout.team,
                  total_amount: 0,
                };
              }
          
              // Add the current payout amount to the total for this group, rounded to 2 decimals
              acc[key].total_amount = parseFloat((acc[key].total_amount + payout.amount).toFixed(2));
          
              return acc;
            }, {})
          );
          
          console.log('summedHourlyPayouts===',summedHourlyPayouts);

          const summedManualPayouts = Object.values(
            allPayouts?.filter(payout => payout.is_auto_pay !== 1)?.reduce((acc, payout) => {
              // Create a unique key based on project_id, task_id, and team
              const key = `${payout.project_id}-${payout.task_id || "null"}-${payout.team || "null"}`;
              
              // Initialize the group in the accumulator if it doesn't exist
              if (!acc[key]) {
                acc[key] = {
                  project_id: payout.project_id,
                  task_id: payout.task_id,
                  team: payout.team,
                  total_amount: 0,
                };
              }
          
              // Add the current payout amount to the total for this group, rounded to 2 decimals
              acc[key].total_amount = parseFloat((acc[key].total_amount + payout.amount).toFixed(2));
          
              return acc;
            }, {})
          );
          
          console.log('summedManualPayouts===',summedManualPayouts);
          
          setHourlyTotalPayOut(summedHourlyPayouts?.reduce((acc, curr) => acc + curr.total_amount, 0))
          setManualTotalPayOut(summedManualPayouts?.reduce((acc, curr) => acc + curr.total_amount, 0))
          // setHourlyTotalPayOut(allPayouts.filter(payout => payout.is_auto_pay === 1).reduce((acc, curr) => acc + curr.amount, 0))
          // setManualTotalPayOut(allPayouts.filter(payout => payout.is_auto_pay !== 1).reduce((acc, curr) => acc + curr.amount, 0))
        console.log('allPayoutsallPayoutsallPayouts',allPayouts)
        }
        if (initialData) {
          setInitialMaterials(initialData)
        }
        if (productListData) {
          setProductList(productListData)
        }
        if (allMaterials) {
          setMaterialList(allMaterials)
        }
        if (project) {
          setJobCost(project.cost || 0)
        }
        console.log('response.data--', response.data);
        if (response.data.selectedMaterials?.length) {
          console.log('productListData--', productListData);
          console.log('selectedProducts----', selectedProducts);

          // setSelectedProjects(
          //   productListData
          //     .filter(item1 => selectedProducts.some(item2 => item1.id === item2.product_id))
          //     .map(item3 => {
          //       let match = selectedProducts.find(item2 => item3.id === item2.product_id);
          //       return { ...item3, ...(match ? { product_id: match.product_id, quantity: match.quantity, price: match.unit_price, recipeName: match.recipeName } : {}) };
          //     })
          // );
          console.log('selectedProducts------------',selectedProducts);
          const selectedProjects1 = selectedProducts.map(selectedProduct => {
            const matchingProduct = productListData.find(product => product.id === selectedProduct.product_id);
            if (matchingProduct) {
              return {
                ...matchingProduct,
                unit: selectedProduct?.partial_unit ? selectedProduct?.partial_unit : matchingProduct?.unit,
                product_id: selectedProduct.product_id,
                jobcost_quantity: selectedProduct.quantity,
                jobcost_actual_quantity: selectedProduct.actual_quantity,
                jobcost_unit_cost: selectedProduct.unit_price,
                // jobcost_total:  selectedProduct.total ,
                jobcost_total: selectedProduct.total ||  (selectedProduct.quantity || 0) * (selectedProduct.unit_price || 0),
                recipeName: selectedProduct.recipeName,
                taken:selectedProduct.taken,
                task_id:selectedProduct.task_id,
                finalTaken:selectedProduct.finalTaken,

              };
            }
            return null; // Handle cases where no matching product is found
          }).filter(project => project !== null);
          console.log('selectedProjects1------------',selectedProjects1);

          setSelectedProjects([...selectedProjects1,...rfpProducts]);
          
          console.log("##############SElected rfp projects",rfpProducts);
        }
      })  
    }catch (error) {
      console.error("Error fetching data:", error);
    }finally{
      setTimeout(() => {
        setLoading(false)
      }, 1000);
    }
  }

  const fetchAllProductCategory = async () => {
    setLoading(true);
    try {
      axios
        .post(REST_API_END_POINT + 'product/get-all-product-categories')
        .then((res) => {
          let data = res.data
          if (data.status === 1) {
            let length = data.productCategories.length + 2
            console.log("length", length);
            // let recipe = [{
            //   "id": length,
            //   "name": "Product Formula",
            //   "added_by": 1,
            //   "status": 1,
            //   "timestamp": 1711096046,
            //   "c_id": 1,
            //   "authorized_by": "Rakesh Skyniche"
            // }]

            // //  setCategory(data.productCategories)
            // setCategory([...recipe]);
            setSelectedCategory(length)

          }
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    } catch (e) {
      console.error('Exception:', e)
    }finally{
      setTimeout(() => {
        setLoading(false)
      }, 1000);
    }
  }
  console.log("############CAAA", category);
  useEffect(() => {
    fetchData()
    fetchAllProductCategory()
  }, [renderCards]);
  let grandTotalCost = 0
  const progressValue = useMemo(() => {
    // const Cost = ((selectedProjects.reduce((accumulator, currentValue) => {
    //   return accumulator + (currentValue.unit_cost * currentValue.quantity);
    // }, 0) + Number(manualTotalPayOut) + Number(hourlyTotalPayOut) + Number(materialPayOut)) / jobCost) * 100
    let totalCost = selectedProjects.reduce((accumulator, currentValue) => {
      let newTotal = 0;
      if(currentValue?.taken == 1){
        newTotal = currentValue?.jobcost_total
        // newTotal = currentValue?.jobcost_unit_cost * currentValue?.jobcost_quantity
      }
      return accumulator + newTotal;
    }, 0) + manualTotalPayOut + hourlyTotalPayOut + materialPayOut + Math.max(0, (services?.reduce((accumulator, currentValue) => {
      let newTotal = 0;
      if(currentValue?.taken == 1){
        // newTotal = currentValue?.originalCost * currentValue?.qty
        // newTotal = currentValue?.jobcost_unit_cost * currentValue?.jobcost_quantity
        newTotal = parseFloat(currentValue?.jobcost_total_cost || 0)
      }
      return accumulator + newTotal;
    }, 0) + additionalServices) -(parseFloat(manualTotalPayOut ? parseFloat(manualTotalPayOut) : 0) + (hourlyTotalPayOut ? parseFloat(hourlyTotalPayOut) : 0)).toFixed(2)
  )
    let Cost = (totalCost) / jobCost * 100;
    console.log("#################Cost-jobCost", jobCost);
    console.log("#################Cost-totalCost", totalCost);
    console.log("#################Cost-Cost", Cost);
    console.log("#################Cost1",  (manualTotalPayOut ? parseFloat(manualTotalPayOut) : 0) + (hourlyTotalPayOut ? parseFloat(hourlyTotalPayOut) : 0)
  );
    console.log("#################Cost", hourlyTotalPayOut);
    if (Cost > 100) {
      return 100
    } else {
      return Cost
    }
  }, [selectedProjects, manualTotalPayOut, hourlyTotalPayOut, services, additionalServices])

  console.log("###########seselectedProjects", selectedProjects);
  function isSumGreater(productId, valQty) {
    let sum = 0;
    initialMaterials?.forEach(material => {
      if (material.product_id === productId) {
        sum += material.quantity;
      }
    });
    productList?.forEach(product => {
      if (product.id === productId) {
        sum += product.qty;
      }
    });
    return sum < Number(valQty);
  }
  useEffect(() => {
    const errorFields = [];
    setErrorFieldId([]);
    selectedProjects?.forEach((val) => {
      console.log("###########Rfp value",val.rfp);
      const result = isSumGreater(val.productId, val.jobcost_quantity);
      if (result && val.rfp!=1) {
        errorFields.push({ productId: val.productId, errorText: 'Quantity exceeds the stock' });
      }
    });
    selectedProjects?.forEach((val) => {
      console.log("###########Rfp value",val.rfp);
      if (val.quantity <= 0 && val.rfp!=1) {
        errorFields.push({ productId: val.productId, errorText: 'Enter a valid quantity' });
      }
    });
    if (errorFields.length > 0) {
      setErrorFieldId([])
      setErrorFieldId(errorFields);
    }
  }, [selectedProjects, manualTotalPayOut, hourlyTotalPayOut])

  const handleProjectClick = (project) => {
    console.log("###################qqq", project);

    <Stack direction={{ xs: 'column', md: 'row' }}>
      <Typography variant="body" sx={{ display: 'flex', alignItems: 'center' }}>
        Material Name :
        <span style={{ color: theme.palette.primary.dark, fontWeight: 'bold' }}>
          <Autocomplete
            sx={{ ml: 1, width: 300 }}
            id="combo-box-demo"
            options={selectedMaterial.ingredients}
            value={ingredient}
            onChange={(event, newValue) => setIngredient(newValue)}
            renderInput={(params) => (
              <TextField {...params}
                label="Select a Material" />
            )}
            getOptionLabel={(option) => option.name ? option.name : ''}
          />
        </span>
        Quantity : {selectedMaterial?.ingredients?.find(item => item.product_id === ingredient?.product_id)?.quantity}
      </Typography>
    </Stack>

    setSelectedProjects(prevSelectedProjects => [...prevSelectedProjects, project]);
  };

  const NewUserSchema = Yup.object().shape({
    quantity: Yup.number().min(0, 'Quantity must be at least 0').test('valid-quantity', 'Enter a valid quantity', (value) => value >= 0).required('Quantity is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      quantity: 1,
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      try {
        // if(selectedProjects?.length > 0){
        console.log('selectedProjectsselectedProjectsselectedProjects',selectedProjects)
        const values = selectedProjects?.map(project => ({
          ...(project.product_id ? { id: project.product_id } : {}),
          productId: project.id,
          projectId: id,
          recipeName: project.recipeName ? project.recipeName : "",
          quantity: project.jobcost_quantity,
          unitPrice: project.jobcost_unit_cost,
          actual_quantity: project.jobcost_actual_quantity || 0,
          actual_unit_cost: project.unit_cost || 0,
          actual_total: project.actual_total_cost || 0,
          taken: project.taken,
          task_id: project.task_id || null,
          rfp:project.rfp,
          total: (Number(project.jobcost_total))
          // total: (Number(project.jobcost_unit_cost) * project.jobcost_quantity)
        }))
        const serviceValues = services?.map(serv => ({
          ...serv,
          taken: serv.taken,
        }))
        function isSumGreater(productId, valQty) {
          let sum = 0;
          initialMaterials?.forEach(material => {
            if (material.product_id === productId) {
              sum += material.quantity;
            }
          });
          productList?.forEach(product => {
            if (product.id === productId) {
              sum += product.qty;
            }
          });
          return sum < Number(valQty);
        }
        const errorFields = [];
        values.forEach((val) => {
          const result = isSumGreater(val.productId, val.quantity);
          console.log("################val rfp0",val);
          // if (result &&  val.rfp!=1) {
          //   errorFields.push({ productId: val.productId, errorText: 'Quantity exceeds the stock ddd' });
          // }
        });
        values.forEach((val) => {
          if (val.quantity < 0 && val.rfp!=1) {
            errorFields.push({ productId: val.productId, errorText: 'Enter a valid quantity' });
          }
        });
        if (errorFields.length > 0) {
          setErrorFieldId([])
          setErrorFieldId(errorFields);
          // setRenderCards(!renderCards)
          return;
        }
        else {
          setErrorFieldId([])
          axios.post(`${REST_API_END_POINT}project/add-job-cost`, { userId: user.user_id, values,serviceValues, projectId: id, recipe: 1 }, {
            headers: {
              'Content-Type': 'application/json',
            },
          }).then((response) => {
            if (response.data.status === 1) {
              enqueueSnackbar(response.data.message, { variant: 'success' });
            } else if (response?.data?.message) {
              enqueueSnackbar(response.data.message, { variant: 'error' });
            }
            else {
              enqueueSnackbar('Error adding Jobcost', { variant: 'error' });
            }
            setRenderCards(!renderCards)
            onDataChange()
          }).catch((error) => {
            console.error(error);
            enqueueSnackbar('Error adding sssssss Jobcost' + error, { variant: 'error' });
          })
        }
        // }else{
        //   enqueueSnackbar('Please select a material', { variant: 'error' });
        // }
        
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Error adding Jobcost' + error, { variant: 'error' });
      }
    }
  });

  const handleDeleteSubmit = async (deletedId) => {
    try {
      // console.log('deletedId----',deletedId);
      setSelectedProjects([...selectedProjects.filter(selected => selected.id !== deletedId)])
      const values = selectedProjects?.filter(selected => selected.id !== deletedId)?.map(project => ({
        ...(project.product_id ? { id: project.product_id } : {}),
        productId: project.id,
        projectId: id,
        quantity: project.jobcost_quantity,
        task_id: project.task_id || null,
        unitPrice: project.jobcost_unit_cost,
        // actual_quantity: project.actual_quantity,
        // actual_unit_cost: project.actual_unit_cost,
        // actual_total: project.actual_total,
        actual_quantity: project.unit_qty || 0,
        actual_unit_cost: project.unit_cost || 0,
        actual_total: project.actual_total_cost || 0,
        recipeName: project.recipeName ? project.recipeName : '', // Ensure match is defined
        total: (Number(project.jobcost_total))
        // total: (Number(project.jobcost_unit_cost) * project.jobcost_quantity)
      }));

      function isSumGreater(productId, valQty) {
        let sum = 0;
        initialMaterials?.forEach(material => {
          if (material.product_id === productId) {
            sum += material.quantity;
          }
        });
        productList?.forEach(product => {
          if (product.id === productId) {
            sum += product.qty;
          }
        });
        return sum < Number(valQty);
      }

      const errorFields = [];
      values.forEach((val) => {
        const result = isSumGreater(val.productId, val.quantity);
        if (result) {
          errorFields.push({ productId: val.productId, errorText: 'Quantity exceeds the stock' });
        }
      });

      values.forEach((val) => {
        if (val.quantity <= 0) {
          errorFields.push({ productId: val.productId, errorText: 'Enter a valid quantity' });
        }
      });

      if (errorFields.length > 0) {
        setErrorFieldId([])
        setErrorFieldId(errorFields);
        return;
      } else {
        setErrorFieldId([])
        axios.post(`${REST_API_END_POINT}project/add-job-cost`, { userId: user.user_id, values, projectId: id, recipe: 1 }, {
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((response) => {
          if (response.data.status === 1) {
            enqueueSnackbar(response.data.message, { variant: 'success' });
          } else if (response?.data?.message) {
            enqueueSnackbar(response.data.message, { variant: 'error' });
          } else {
            enqueueSnackbar('Error adding Jobcost', { variant: 'error' });
          }
          onDataChange()
          setSelectedProjects([])
          setManualTotalPayOut(0)
          setHourlyTotalPayOut(0)
          setInitialMaterials([])
          setProductList([])
          setMaterialList([])
          setJobCost(0)
          setDeleteId();
          // fetchData()
          setRenderCards(!renderCards)
          onDataChange()
        }).catch((error) => {
          console.error(error);
          enqueueSnackbar('Error adding Jobcost', { variant: 'error' });
        })
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Error adding Jobcost' + error, { variant: 'error' });
    }
  }

  const handleJobCost = async () => {
    console.log("#####################Hifiiiiiiiiiiiiii");
    axios.post(`${REST_API_END_POINT}recipe/update-job-cost-automatically`, { userId: user.user_id, projectId: id, requestId: params, name: projectData.name }).then((response) => {
      if (response.data.status === 1) {
        // fetchData();
        // setJobCost(0)
        setMaterialList([])
        setProductList([])
        setInitialMaterials([])
        setAllServices([])
        setRecipe([])
        setAdditionalServices([])
        setProjectData([])
        // setMaterialPayOut(0)
        // setHourlyTotalPayOut(0)
        // setManualTotalPayOut(0)
        setSelectedProjects([])
        setRenderCards(!renderCards)
        onDataChange()
        enqueueSnackbar(response.data.message, { variant: 'success' });
      } else if (response.data.status === 0) {
        enqueueSnackbar(response.data.message, { variant: 'error' });
      }
    })
  }
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const materialWithStock = materialList?.filter((data) => data?.total_qty > 0)
  const productWithQty = productList?.filter((data) => data?.qty > 0)
  const navigate = useNavigate()
  const linkState = {
    pathname: `${PATH_DASHBOARD.project.jobCost}/${id}`,
    state: params
  };

  const handleClick = () => {
    if(popupOn){
    handleClickOpenJobCost()
    setRecipeCostCurrentTab(currentTab);
  }else{
    navigate(`${PATH_DASHBOARD.project.jobCost}/${id}`,
      {state: { request: params,currentTab : currentTab }});
    }
  };

  // grandTotalCost = selectedProjects.reduce((accumulator, currentValue) => {
  //   let newTotal = 0;
  //   if(currentValue?.taken == 1){
  //     newTotal = currentValue?.unit_cost * currentValue?.quantity
  //   }
  //   return accumulator + newTotal;
  // }, 0) + manualTotalPayOut + hourlyTotalPayOut + materialPayOut + Math.max(0, (services?.reduce((accumulator, currentValue) => {
  //   let newTotal = 0;
  //   if(currentValue?.taken == 1){
  //     newTotal = currentValue?.originalCost * currentValue?.qty
  //   }
  //   return accumulator + newTotal;
  // }, 0) + additionalServices) - (manualTotalPayOut ? manualTotalPayOut?.toFixed(2) : 0) + (hourlyTotalPayOut ? hourlyTotalPayOut?.toFixed(2) : 0))

  
  let newMaterialPayOut = parseFloat(materialPayOut.toFixed(2)) || 0;

  let selectedProjectsTotal = selectedProjects?.reduce((accumulator, currentValue) => {
      let newTotal = 0;
      if (currentValue?.taken === 1) {
          newTotal = currentValue?.jobcost_total;
          // newTotal = currentValue?.jobcost_unit_cost * currentValue?.jobcost_quantity;
      }
      return accumulator + newTotal;
  }, 0) || 0;
  
  let servicesTotal = services?.reduce((accumulator, currentValue) => {
      let newTotal = 0;
      if (currentValue?.taken === 1) {
          // newTotal = currentValue?.originalCost * currentValue?.qty;
          // newTotal = currentValue?.jobcost_unit_cost * currentValue?.jobcost_quantity;
          newTotal = parseFloat(currentValue?.jobcost_total_cost || 0)
          console.log("valueeeeeeeeeeeeee",currentValue)
      }
      return accumulator + newTotal;
  }, 0) || 0;
  
  selectedProjectsTotal = parseFloat(selectedProjectsTotal.toFixed(2));
  servicesTotal = parseFloat(servicesTotal.toFixed(2));
  
  let manualTotal = parseFloat(manualTotalPayOut?.toFixed(2)) || 0;
  let hourlyTotal = parseFloat(hourlyTotalPayOut?.toFixed(2)) || 0;
  
  let newAdditionalServices = additionalServices || 0;
  
  let manualAndHourlyTotal = Math.max(0, manualTotal + hourlyTotal);
  let totalServiceCost = services?.reduce((accumulator, currentValue) => {
      // return accumulator + (currentValue?.originalCost * currentValue?.qty);
      // return accumulator + (currentValue?.jobcost_unit_cost * currentValue?.jobcost_quantity);
      return accumulator + parseFloat(currentValue?.jobcost_total_cost || 0);
  }, 0) || 0;
  
  // const handleMaterialCheckboxChange = (index) => {
  //   setSelectedProjects(prevState => 
  //     prevState.map((project, i) => 
  //       i === index ? { ...project, taken: project.taken ? 0 : 1 } : project
  //     )
  //   );
  // };

  const handleMaterialCheckboxChange = (index) => {
    setSelectedProjects((prevState) =>
      prevState.map((project, i) => {
        if (i === index) {
          console.log('projectprojectprojectproject',project)
        
          if (project.finalTaken === 1) {
            return {
              ...project,
              taken: project.finalTaken ? 1 : 1,
              // jobcost_quantity: project.taken ? project.jobcost_quantity : project.jobcost_actual_quantity,
              // jobcost_unit_cost: project.taken ? project.jobcost_unit_cost : project.jobcost_actual_unit_cost,
              // // jobcost_total: project.taken ? (Number(project.jobcost_actual_quantity) * Number(project.jobcost_unit_cost)) : (project.jobcost_actual_quantity * Number(project.jobcost_actual_unit_cost)),
              // jobcost_total:  (Number(project.jobcost_actual_quantity) * Number(project.jobcost_unit_cost)) || (project.jobcost_actual_quantity * Number(project.jobcost_actual_unit_cost)),
          
            };
          }
          return {
            ...project,
            taken: project.taken ? 0 : 1,
            jobcost_quantity: project.taken==1 ? project.jobcost_quantity : project.jobcost_actual_quantity,
            jobcost_unit_cost: project.taken==1 ? project.jobcost_unit_cost : project.jobcost_actual_unit_cost,
            // jobcost_total: project.taken ? (Number(project.jobcost_actual_quantity) * Number(project.jobcost_unit_cost)) : (project.jobcost_actual_quantity * Number(project.jobcost_actual_unit_cost)),
            jobcost_total:  (Number(project.jobcost_actual_quantity) * Number(project.unit_cost)) ,
        
          };
        }
        return project;
      })
    );
  };
  

  
  const handleServiceCheckboxChange = (index) => {
    setAllServices(prevState => 
      prevState.map((project, i) => 
        i === index ? { ...project, taken: project.taken ? 0 : 1 } : project
      )
    );
  };

  const additionalLaborCost1 = useMemo(() => (
    services.reduce((sum, service) => {
      if(projectData?.job_category_name=='Time & Material'){
        const jobcost_total_cost = parseFloat(service.jobcost_total_cost || 0);
        // if (service.recipe_id == null) {
        if (service?.taken == 1){ //service.recipe_id == null && 
          return sum + jobcost_total_cost;
        }
      }else{
        const quantity = parseFloat(service.jobcost_quantity || service.qty || 0);
        const unitCost = parseFloat(service.jobcost_unit_cost || service.originalCost || service.unit_cost || 0);
        const jobcost_total_cost = parseFloat(service.jobcost_total_cost || 0);
        // if (service.recipe_id == null) {
        if (service.recipe_id == null && service.labour_cost_service_id == 10 && service?.taken == 1){
          sum += jobcost_total_cost;
        }else if (service.recipe_id == null && service?.taken == 1){
          // sum += (quantity * unitCost);
          sum += jobcost_total_cost;
        }
      }
      // if (service.recipe_id == null) {
      //   return sum + service.jobcost_unit_cost * service.jobcost_quantity;
      // }
      return sum;
    }, 0)
  ), [services, projectData]);

  const excessLaborCost1 = useMemo(() => (
    Math.max(0, manualTotalPayOut + hourlyTotalPayOut - additionalLaborCost1)
  ), [manualTotalPayOut, hourlyTotalPayOut, additionalLaborCost1]);

  grandTotalCost = newMaterialPayOut + selectedProjectsTotal + servicesTotal + Math.max(0, manualTotalPayOut + hourlyTotalPayOut - additionalLaborCost1);
  grandTotalCost = parseFloat(grandTotalCost.toFixed(2));

  console.log('costtttttttttttt services',services)
  console.log('costtttttttttttt',servicesTotal,manualAndHourlyTotal,totalServiceCost,newAdditionalServices)

  console.log('servicesservices=12=newMaterialPayOut==',newMaterialPayOut);
  console.log('servicesservices=12=selectedProjectsTotal==',selectedProjectsTotal);
  console.log('servicesservices=12=servicesTotal==',servicesTotal);
  console.log('servicesservices=12=manualAndHourlyTotal==',manualAndHourlyTotal);
  console.log('servicesservices=12=manualTotalPayOut==',manualTotalPayOut);
  console.log('servicesservices=12=hourlyTotalPayOut==',hourlyTotalPayOut);
  console.log('servicesservices=12=additionalLaborCost1==',additionalLaborCost1);
  console.log('servicesservices=12=totalServiceCost==',totalServiceCost);
  console.log('servicesservices=12=newAdditionalServices==',newAdditionalServices);
  
  return (
    (loading || !projectData) ?
      <Stack alignItems='center' justifyContent='center' mt={10}>
        <LoadingScreen />
      </Stack> :
      <Page title={`Job Calculator |${COMPANY_NAME}`} mt={3} >
        <Container  >
          {popupOn?<Button
            variant="outlined"
            type="button"
            // component={RouterLink}
            // to={PATH_DASHBOARD.project.viewproject + id}
            // onClick={()=>{
            //   navigate(`${PATH_DASHBOARD.project.viewproject}/${id}`,
            //     {state: { currentTab : currentTab }});
            // }}
            onClick={handleBackToAlljobs}
            sx={{ alignItems: 'flex-end', mb: 4 }}
            startIcon={
              <Stack>
                <KeyboardBackspaceOutlined />
              </Stack>
            }
          >
            Back to Job Details
          </Button>:<Button
            variant="outlined"
            type="button"
            // component={RouterLink}
            // to={PATH_DASHBOARD.project.viewproject + id}
            onClick={()=>{
              navigate(`${PATH_DASHBOARD.project.viewproject}/${id}`,
                {state: { currentTab : currentTab }});
            }}
            // onClick={handleBackToAlljobs}
            sx={{ alignItems: 'flex-end', mb: 4 }}
            startIcon={
              <Stack>
                <KeyboardBackspaceOutlined />
              </Stack>
            }
          >
            Back to Job Details
          </Button>}

          <Button
            variant="outlined"
            type="button"
            onClick={()=>{ setJobCostPreview(true); }}
            sx={{ alignItems: 'flex-end', mb: 4, ml:4 }}
          >
            Expected V/S Actual
          </Button>

          <Stack direction={'row'} spacing={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Box width={'30%'}>
              <HeaderBreadcrumbs
                heading={
                <>
                  {"Job Calculator"} {projectData?.job_category_name === 'Time & Material' ? <Label variant="filled" color="info">T&M</Label> : ''}
                </>}
              />
            </Box>

            {/* <Box width={'50%'}> */}
            <Stack spacing={2} mb={5} sx={{ mr: '7% !important' }} width={'50%'}>
              {projectData?.job_category_name === 'Time & Material' ? 
                <Typography variant="subtitle2">Expected Budget (Material + Service) : {jobCost}</Typography>
              :
                <Typography variant="subtitle2">Expected Budget (Product Formula Cost + Labor Cost) : {jobCost}</Typography>
              }
              <Box sx={{ display: 'flex', alignItems: 'center', }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                  <BorderLinearProgress variant="determinate" value={progressValue} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                  <Typography variant="body2" color="text.secondary">{Math.round(progressValue) || 0}%</Typography>
                </Box>
              </Box>
            </Stack>
            {/* </Box> */}

          </Stack>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid container spacing={3}>
            <Grid item md={5} xs={12}>
            <Button
              variant="outlined"
              type="button"
              onClick={()=>handleClick()}
              // component={RouterLink}
              // to={linkState}
              sx={{ mb: 4 }}
            >
              {projectData?.job_category_name=='Time & Material' ? 'Go To Materials' : 'Go To Additional Materials'}
            </Button>
            </Grid>
            <Grid item md={7} xs={12}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <div style={{ textAlign: 'left' }}>
                  {
                     projectData?.job_category_name=='Time & Material' ?
                     ''
                     :
                     
                  <Typography variant="subtitle2">
                    
                    Actual Labor Cost: {parseFloat(additionalLaborCost1).toFixed(2)}
                    {/* Actual Labor Cost: {projectData?.job_category_name=='Time & Material' ? '0.00' :
                    (services?.reduce((accumulator, currentValue) => {
                      // return accumulator + (currentValue?.originalCost * currentValue?.qty);
                      return accumulator + (currentValue?.jobcost_unit_cost * currentValue?.jobcost_quantity);
                    }, 0) || 0).toFixed(2)} */}
                  </Typography>
}
                </div>
                <div style={{ textAlign: 'right' }}>
                {/* {projectData?.job_category_name=='Time & Material' ?
                ""
                : */}
                  <Typography variant="subtitle2">
                    Manual Staff Pay Out: {manualTotalPayOut ? manualTotalPayOut.toFixed(2) : 0}
                  </Typography>
{/* } */}
                  <Typography variant="subtitle2">
                    Hourly Staff Pay Out: {hourlyTotalPayOut ? hourlyTotalPayOut.toFixed(2) : 0}
                  </Typography>
                  <Typography variant="subtitle2" sx={{color: 'yellow'}}>
                    Total Staff Pay Out: {
                      manualTotalPayOut ? (
                        hourlyTotalPayOut ? (
                          (parseFloat(manualTotalPayOut) + parseFloat(hourlyTotalPayOut)).toFixed(2)
                        ) : manualTotalPayOut.toFixed(2)
                      ) : hourlyTotalPayOut ? hourlyTotalPayOut.toFixed(2) : 0
                    }
                  </Typography>
                </div>
              </Stack>
            </Grid>
          </Grid>
          </div>
          <Grid item md={12} xs={12} direction='column' sx={{ display: 'flex', }}>
            {/* {projectData.jobcost === 1 ? 
            <Button variant='outlined' sx={{ color: 'error.main', cursor:'none', borderColor: 'error.main' }}>
              {projectData.notifyJobcost}
            </Button> : ""} */}
            {projectData.jobcost === 1 ? (
              <Button
                variant='outlined'
                sx={{
                  color: 'error.main',
                  borderColor: 'error.main',
                  pointerEvents: 'none',
                  userSelect: 'text',
                }}
              >
                {projectData.notifyJobcost}
              </Button>
            ) : ""}
            {/* <Box sx={{ position: 'relative', display: 'inline-block', margin: '20px' }}>
              {projectData.jobcost === 1 ? (
                  <Typography
                  variant="outlined"
                  sx={{
                    position: 'absolute',
                    top: '-20px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    // backgroundColor: 'white',
                    padding: '5px 15px',
                    color: 'error.main',
                    fontSize: '26px',
                    border: '1px solid',
                    borderColor: 'error.main',
                    borderRadius: '4px',
                    whiteSpace: 'nowrap',
                    wordWrap:'break-word'
                  }}
                  >
                    {projectData.notifyJobcost}
                  </Typography>
              ) : ''}
            </Box> */}
          </Grid>
          {projectData.jobcost === 1 && <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
            
            <Button
              variant="outlined"
              type="submit"
              onClick={()=>navigate(PATH_DASHBOARD.products.material)}
              sx={{ mb: 4, mt: 3,ml:2 }}
            >
             Go to Material Inventory
            </Button>
            <Button
            variant="outlined"
            type="submit"
            onClick={handleJobCost}
            sx={{ mb: 4, mt: 3, }}
          >
            Update JobCost
          </Button>
          </Box>}

          <Grid container spacing={3}>
            <Grid item md={5} xs={12}>

              <Tabs
                value={selectedCategory}
                sx={{
                  backgroundColor: theme.palette.grey[700],
                  borderRadius: 2,
                  mb: 1,
                }}
                onChange={(e, newValue) => handleCategoryChange(newValue)}
                variant="scrollable"
                aria-label="scrollable auto tabs example"
                allowScrollButtonsMobile
                autoFocus
                scrollButtons="auto"
              >
                {category.map((category) => (
                  <Tab key={category.id} sx={{ ml: 3,}}
                    disableRipple
                    autoCapitalize={category.name}
                    label={category.name}
                    value={category.id} />
                ))}
              </Tabs>
              {projectData?.job_category_name === 'Time & Material' ? 
                <Card
                  sx={{
                    p: 1,
                    height: '560px',
                    overflowY: 'auto',
                    // backgroundColor: 'transparent',
                    // background: 'transparent',
                    '&::-webkit-scrollbar': {
                      width: '0.4em',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: 'rgba(0,0,0,.1)',
                      borderRadius: '4px',
                    },
                  }}
                >
                  <Grid container spacing={1}>
                    <Scrollbar>
                      <TableContainer sx={{ minWidth: 340 }}>
                        <Table>
                          <TableHead
                            sx={{
                              borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                              // '& th': { backgroundColor: 'transparent' }
                            }}
                          >
                            <TableRow>
                              <TableCell align="center">
                                <Typography variant="body1">Staff Name</Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography variant="body1">Task</Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography variant="body1">Clocked-In Hours</Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography variant="body1">Hourly Rate</Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography variant="body1">Total</Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {staffTimelogs?.map((log, logIndex) => (
                              <TableRow
                                key={`log_${logIndex}`}
                                sx={{
                                  borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                                }}
                              >
                                <TableCell align="center">
                                  <Typography variant="subtitle2">{log.staff_name}</Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography variant="subtitle2">{log.isDriver==1 ? (log.task_name ||'Labor Install') : log.task_name}</Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography variant="subtitle2">{parseFloat(log.hours || 0)?.toFixed(4)}</Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography variant="subtitle2">{log.hourly_rate || 20}</Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography variant="subtitle2">{parseFloat(log.total_cost || 0)?.toFixed(2)}</Typography>
                                </TableCell>
                              </TableRow>
                            ))
                            }

                          </TableBody>
                        </Table>
                      </TableContainer>

                    </Scrollbar>
                  </Grid>
                </Card>
              : 
                <>
                {
                  selectedCategory==1?
                  <>
                
                <SearchStyle
                  sx={{ backgroundColor: '#05191f', height: 40 }}
                  value={filterName}
                  onChange={handleFilterByName}
                  placeholder="Search Material"
                  startAdornment={
                    <InputAdornment position="start">
                      <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  }
                />
                <Card
                  sx={{
                    p: 3,
                    height: '360px',
                    overflowY: 'auto',
                    backgroundColor: 'transparent',
                    background: 'transparent',
                    '&::-webkit-scrollbar': {
                      width: '0.4em',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: 'rgba(0,0,0,.1)',
                      borderRadius: '4px',
                    },
                  }}
                >
                  <Grid container spacing={1}>
                    {recipe.map((recipeItem, index) => (
                      <Grid key={index} item xs={6} sm={4} md={4}> {/* Adjusted md to 4 */}
                        <Card
                          onMouseEnter={() => setIsHover(true)}
                          onMouseLeave={() => setIsHover(false)}
                          sx={{
                            minWidth: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center',
                            transition: 'transform 0.2s',
                            '&:hover': {
                              transform: 'scale(1.02)',
                            },
                          }}
                          // onClick={() => { setMaterialsPreview(true); setIsRecipe(true); setSelectedMaterial(recipeItem) }}
                        >
                          {/* Display truncated recipe name */}
                          <div style={{ fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}>
                            R: {recipeItem.name.split(' ').slice(0, 2).join(' ')}
                          </div>
                          {/* Display counts */}
                          <div>
                            Materials: {recipeItem.ingredients.length}
                          </div>
                          <div>
                            Services: {recipeItem.services.length}
                          </div>
                          {/* Highlighted productName */}
                          <div style={{ fontWeight: 'bold', color: 'yellow', marginTop: '10px', marginBottom: '10px' }}>
                            C: {recipeItem.productName}
                          </div>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Card>

                </>
                :
                <Card
                sx={{
                  p: 1,
                  height: '560px',
                  overflowY: 'auto',
                  // backgroundColor: 'transparent',
                  // background: 'transparent',
                  '&::-webkit-scrollbar': {
                    width: '0.4em',
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.1)',
                    borderRadius: '4px',
                  },
                }}
              >
                <Grid container spacing={1}>
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 340 }}>
                      <Table>
                        <TableHead
                          sx={{
                            borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                            // '& th': { backgroundColor: 'transparent' }
                          }}
                        >
                          <TableRow>
                            <TableCell align="center">
                              <Typography variant="body1">Staff Name</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography variant="body1">Task</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography variant="body1">Clocked-In Hours</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography variant="body1">Hourly Rate</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography variant="body1">Total</Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {staffTimelogs?.map((log, logIndex) => (
                            <TableRow
                              key={`log_${logIndex}`}
                              sx={{
                                borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                              }}
                            >
                              <TableCell align="center">
                                <Typography variant="subtitle2">{log.staff_name}</Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography variant="subtitle2">{log.isDriver==1 ? (log.task_name ||'Labor Install') : log.task_name}</Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography variant="subtitle2">{parseFloat(log.hours || 0)?.toFixed(4)}</Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography variant="subtitle2">{log.hourly_rate || 20}</Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography variant="subtitle2">{parseFloat(log.total_cost || 0)?.toFixed(2)}</Typography>
                              </TableCell>
                            </TableRow>
                          ))
                          }

                        </TableBody>
                      </Table>
                    </TableContainer>

                  </Scrollbar>
                </Grid>
              </Card>
                }
                </>
              }
            </Grid>
            <Grid item md={7} xs={12}>
              <Card>
                <Box sx={{ display: 'flex', alignItems: 'center', py: 2, pl: 3 }}>
                  <Typography variant="h4" noWrap>Expense</Typography>
                </Box>
                <FormikProvider value={formik}>
                  <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <Scrollbar
                      sx={{
                        height: '320px'
                      }}>
                      <TableContainer sx={{ minWidth: 550 }}>
                        <Table>
                          <TableHead
                            sx={{
                              borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                              '& th': { backgroundColor: 'transparent' }
                            }}
                          >
                            <TableRow>
                              <TableCell align="center">
                              </TableCell>
                              <TableCell align="center">
                                <Typography variant="body1" >Name</Typography>
                              </TableCell>
                              <TableCell align="center">
                                {projectData?.job_category_name === 'Time & Material' 
                                  ? ''
                                  : <Typography variant="body1" >Product Formula</Typography>
                                }
                              </TableCell>
                              <TableCell align="center">
                                <Typography variant="body1" noWrap>Expected Qty</Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography variant="body1" noWrap>Actual Qty</Typography>
                              </TableCell>
                              {/* <TableCell align="center">
                                <Typography variant="body1" noWrap>Unit price</Typography>
                              </TableCell> */}
                              <TableCell align="center">
                                <Typography variant="body1" noWrap>Total </Typography>
                              </TableCell>
                              {/* <TableCell align="center">
                                <Typography variant="body1" noWrap></Typography>
                              </TableCell> */}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {console.log("#############selectedProjects", selectedProjects)}
                            {selectedProjects?.map((selectedProject, index) => {
                              let qtyExceed = 0
                              let unitName = UNITS?.filter((item) => item?.id == selectedProject?.unit)
                              let shortUnitName = unitName?.[0]?.short_name || "";
                              return (
                                <TableRow
                                  key={index}
                                  sx={{
                                    borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                                    backgroundColor: (selectedProject?.taken==1) ? theme.palette.divider : null,
                                  }}
                                >
                                  <TableCell align="center">
                                  <Checkbox key={index}
                                    sx={{cursor:'pointer'}}
                                    checked={(selectedProject?.taken==1) ? true : false}
                                    onChange={()=>{ handleMaterialCheckboxChange(index)}}
                                    color="primary"
                                  />
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box sx={{ maxWidth: 130, justifyContent: 'center', alignItems: 'center' }}>
                                      <Tooltip title={selectedProject?.name}>
                                        <Typography variant="subtitle2" sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{selectedProject?.name||selectedProject?.productName}</Typography>
                                      </Tooltip>
                                      <Tooltip title={selectedProject?.barcode||"Sub contract"}>
                                        <Typography variant="subtitle2" sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{selectedProject?.barcode||"Sub contract"}</Typography>
                                      </Tooltip>
                                      {/* <span style={{ color: theme.palette.error.main }}>
                                        {selectedProjects.rfp!=1?selectedProjects?.filter(item => item.id === selectedProject?.id)?.reduce((accumulator, currentValue) => {
                                          const initialQuantity = initialMaterials?.find(item => item.product_id === selectedProject.id)?.quantity || 0;
                                          const productQuantity = productList?.find(item => item.id === selectedProject.id)?.qty || 0;
                                          const stock = (initialQuantity + productQuantity) - currentValue?.quantity;
                                          return stock >= 0 ? ('Stock left : ' + stock.toFixed(2)) : '';
                                        }, 0) || '':''}
                                      </span> */}
                                    </Box>
                                  </TableCell>
                                  <TableCell>{<Typography variant="subtitle2" >{selectedProject?.recipeName ? selectedProject.recipeName : ""}</Typography>}</TableCell>
                                  {/* <TableCell align="left">1</TableCell> */}
                                  <TableCell align="center">
                                    <TextField
                                      sx={{ width: '100%' }}
                                      type='text'
                                      label={(selectedProject?.unit == 4) ? "Quantity" : shortUnitName}
                                      disabled={true}
                                      value={selectedProject.jobcost_actual_quantity}
                                      onKeyPress={(event) => {
                                        event.preventDefault();

                                      }}
                                      onChange={(event) => {
                                        // console.log('errorFieldId--2----',errorFieldId)
                                        let newValue = event.target.value;
                                        if (newValue.startsWith('-')) {
                                          newValue = newValue.slice(1);
                                        }
                                        const updatedProjects = selectedProjects.map(p => {
                                          if (p.id === selectedProject.id) {
                                            return {
                                              ...p,
                                              quantity: event.target.value
                                            };
                                          }
                                          return p;
                                        });
                                        const qtyExceed = selectedProjects?.filter(item => item.id === selectedProject?.id)?.reduce((accumulator, currentValue) => {
                                          const initialQuantity = initialMaterials?.find(item => item.product_id === selectedProject.id)?.quantity || 0;
                                          const productQuantity = productList?.find(item => item.id === selectedProject.id)?.qty || 0;
                                          const stock = initialQuantity + productQuantity;
                                          const balanceStock = stock - event.target.value;
                                          return (balanceStock < 0)
                                        }, 0) || 0

                                        setSelectedProjects(updatedProjects);
                                      }}
                                      // error={errorFieldId?.length > 0 && errorFieldId?.find(data => data.productId === selectedProject.id) || ''}
                                      //  helperText={errorFieldId?.length > 0 && errorFieldId.find(field => field.productId === selectedProject.id)?.errorText || ''}
                                      
                                       // error={Boolean(touched.quantity && errors.quantity)}
                                      // helperText={touched.quantity && errors.quantity}
                                      InputProps={{
                                        inputProps: {
                                          min: 1,
                                          max: () => {
                                            const initialQuantity = initialMaterials?.find(item => item.product_id === selectedProject.id)?.quantity || 0;
                                            const productQuantity = productList?.find(item => item.id === selectedProject.id)?.qty || 0;
                                            return initialQuantity + productQuantity;
                                          }
                                        }
                                      }}
                                      defaultValue={1}
                                    />
                                    
                                  </TableCell>
                                  <TableCell align="center">
                                    <TextField
                                      sx={{ width: '100%' }}
                                      type="number"
                                      disabled={selectedProject.finalTaken == 1}
                                      label={(selectedProject?.unit == 4) ? "Quantity" : shortUnitName}
                                      // disabled={true}
                                      value={selectedProject.jobcost_quantity}
                                      // onKeyPress={(event) => {
                                      //   event.preventDefault();

                                      // }}
                                      // onChange={(event) => {
                                      //   // console.log('errorFieldId--2----',errorFieldId)
                                      //   let newValue = event.target.value;
                                      //   if (newValue.startsWith('-')) {
                                      //     newValue = newValue.slice(1);
                                      //   }
                                      //   const updatedProjects = selectedProjects.map(p => {
                                      //     if (p.id === selectedProject.id) {
                                      //       return {
                                      //         ...p,
                                      //         quantity: event.target.value,
                                      //         jobcost_quantity: event.target.value
                                      //       };
                                      //     }
                                      //     return p;
                                      //   });
                                      //   const qtyExceed = selectedProjects?.filter(item => item.id === selectedProject?.id)?.reduce((accumulator, currentValue) => {
                                      //     const initialQuantity = initialMaterials?.find(item => item.product_id === selectedProject.id)?.quantity || 0;
                                      //     const productQuantity = productList?.find(item => item.id === selectedProject.id)?.qty || 0;
                                      //     const stock = initialQuantity + productQuantity;
                                      //     const balanceStock = stock - event.target.value;
                                      //     return (balanceStock < 0)
                                      //   }, 0) || 0

                                      //   setSelectedProjects(updatedProjects);
                                      // }}
                                      // onChange={(event) => {
                                      //   let newValue = event.target.value;
                                      //   if (newValue.startsWith('-')) {
                                      //     newValue = newValue.slice(1); // Prevent negative values
                                      //   }
                                      
                                      //   const updatedProjects = selectedProjects.map((p, idx) => {
                                      //     if (idx === index) {
                                      //       const updatedProject = {
                                      //         ...p,
                                      //         jobcost_quantity: newValue,
                                      //         jobcost_total: newValue * Number(p.jobcost_unit_cost),
                                      //       };
                                      
                                      //       // If the value entered is less than the first box value, uncheck the checkbox
                                      //       if (Number(newValue) < Number(p.jobcost_actual_quantity)) {
                                      //         updatedProject.taken = 0;
                                      //       } 
                                      //       // If the value exceeds the first box value, reset it to match the first box value
                                      //       else if (Number(newValue) > Number(p.jobcost_actual_quantity)) {
                                      //         updatedProject.jobcost_quantity = p.jobcost_actual_quantity;
                                      //         updatedProject.taken = 1; // Keep the checkbox checked
                                      //       } else {
                                      //         updatedProject.taken = 1; // Keep the checkbox checked for valid values
                                      //       }
                                      
                                      //       return updatedProject;
                                      //     }
                                      //     return p;
                                      //   });
                                      
                                      //   setSelectedProjects(updatedProjects);
                                      // }}

                                      onChange={(event) => {
                                        let newValue = event.target.value;
                                      
                                        // Prevent negative values
                                        if (newValue.startsWith('-')) {
                                          newValue = newValue.slice(1);
                                        }
                                      
                                        setSelectedProjects((prevState) =>
                                          prevState.map((project, idx) => {
                                            if (idx === index) {
                                              // Get the maximum allowed value from the first box
                                              const maxAllowedValue = Number(project.jobcost_actual_quantity);
                                      
                                              // Constrain the value to the maximum allowed
                                              const constrainedValue = Math.min(Number(newValue), maxAllowedValue);
                                      
                                              // Determine the value of "taken" based on the input
                                              const taken = constrainedValue === maxAllowedValue ? 1 : 0;
                                      
                                              // Update the project with the constrained value and "taken" status
                                              return {
                                                ...project,
                                                jobcost_quantity: constrainedValue,
                                                jobcost_total: constrainedValue * Number(project.jobcost_unit_cost),
                                                taken: taken,
                                              };
                                            }
                                            return project;
                                          })
                                        );
                                      }}
                                      
                                      
                                      
                                      error={errorFieldId?.length > 0 && errorFieldId?.find(data => data.productId === selectedProject.id) || ''}
                                       helperText={errorFieldId?.length > 0 && errorFieldId.find(field => field.productId === selectedProject.id)?.errorText || ''}
                                      // error={Boolean(touched.quantity && errors.quantity)}
                                      // helperText={touched.quantity && errors.quantity}
                                      InputProps={{
                                        inputProps: {
                                          min: 1,
                                          max: () => {
                                            const initialQuantity = initialMaterials?.find(item => item.product_id === selectedProject.id)?.quantity || 0;
                                            const productQuantity = productList?.find(item => item.id === selectedProject.id)?.qty || 0;
                                            return initialQuantity + productQuantity;
                                          }
                                        }
                                      }}
                                      defaultValue={1}
                                    />
                                    
                                  </TableCell>

                                  <TableCell align="center">{fCurrency(Number(selectedProject?.jobcost_total))}</TableCell>
                                  
                                </TableRow>
                              )
                            })}

                            {console.log("servicesservicesservicesservices", services)}
                            {services?.map((service, serviceIndex) => (
                              <TableRow
                                key={`service_${serviceIndex}`}
                                sx={{
                                  borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                                  backgroundColor: (service?.taken==1) ? theme.palette.divider : null,
                                }}
                              >
                                <TableCell align="center">
                                  {projectData?.job_category_name=='Time & Material' ? '' :
                                  <Checkbox key={serviceIndex}
                                    sx={{cursor:'pointer'}}
                                    checked={(service?.taken==1) ? true : false}
                                    onChange={()=>{ handleServiceCheckboxChange(serviceIndex)}}
                                    color="primary"
                                  />}
                                </TableCell>
                                <TableCell align="center">
                                  <Typography variant="subtitle2" style={{ color: 'yellow' }}>{service.name}</Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography variant="subtitle2" style={{ color: 'yellow' }}>{service.recipeName}</Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <TextField
                                    sx={{ width: '80%', color: 'yellow' }}
                                    type='number'
                                    label="Hours"
                                    value={service.qty}
                                    disabled={true}
                                    InputProps={{
                                      inputProps: {
                                        min: 1
                                      }
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <TextField
                                    sx={{ width: '80%', color: 'yellow' }}
                                    type='number'
                                    label="Hours"
                                    value={ Number(service.jobcost_quantity) }
                                    // value={ projectData?.job_category_name=='Time & Material' ? Number(service.jobcost_quantity) : service.qty}
                                    disabled={true}
                                    InputProps={{
                                      inputProps: {
                                        min: 1
                                      }
                                    }}
                                  />
                                </TableCell>
                                {/* <TableCell align="center">
                                  <Typography variant="subtitle2" style={{ color: 'yellow' }}>${service.jobcost_unit_cost|| service.originalCost||service.unit_cost}</Typography>
                                </TableCell> */}
                                {/* <Typography variant="subtitle2" style={{ color: 'yellow' }}>${service.originalCost||service.unit_cost}</Typography> */}
                                <TableCell align="center">
                                  {/* {projectData?.job_category_name=='Time & Material' ?  */}
                                    <Typography variant="subtitle2" style={{ color: 'yellow' }}>${service.jobcost_total_cost}</Typography>
                                  {/* :
                                    <Typography variant="subtitle2" style={{ color: 'yellow' }}>${((service.jobcost_quantity || service.qty)*(service.jobcost_unit_cost||service.originalCost||service.unit_cost))?.toFixed(2)}</Typography>
                                  } */}
                                  {/* <Typography variant="subtitle2" style={{ color: 'yellow' }}>${(service.qty)*(service.originalCost||service.unit_cost))?.toFixed(2)}</Typography> */}
                                </TableCell>
                              </TableRow>
                            ))
                            }

                          </TableBody>
                        </Table>
                      </TableContainer>

                    </Scrollbar>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <Box>
                    <RowResultStyle>
                      {/* <TableCell colSpan={3} /> */}
                      <TableCell align="right" colSpan={4} >
                        <Typography variant="h6">{ projectData?.job_category_name=='Time & Material' ? "Material Cost":"Additional Material Cost"}</Typography>
                      </TableCell>
                      <TableCell align="center" width={100}>
                        <Typography variant="h6">{materialPayOut.toFixed(2) || 0}</Typography>
                      </TableCell>
                    </RowResultStyle>

                    {projectData?.job_category_name=='Time & Material' ? "" :
                    <RowResultStyle>
                      <TableCell align="right" colSpan={4} >
                        <Typography variant="h6">Additional services</Typography>
                      </TableCell>
                      <TableCell align="center" width={100}>
                        <Typography variant="h6">
                          {parseFloat(additionalLaborCost1).toFixed(2)}
                          {/* {projectData?.job_category_name=='Time & Material' ? '0.00' :
                          (services?.reduce((accumulator, currentValue) => {
                            let newTotal = 0;
                            if(currentValue.recipe_id == null && currentValue?.taken == 1){
                              newTotal = currentValue?.jobcost_unit_cost * currentValue?.jobcost_quantity
                              // newTotal = currentValue?.originalCost * currentValue?.qty
                            }
                            return accumulator + newTotal;
                          }, 0) || 0).toFixed(2)} */}
                        </Typography>
                      </TableCell>
                    </RowResultStyle>
                    }
                    {/* <RowResultStyle>
                    <TableCell align="right" colSpan={4} >
                      <Typography variant="h6">Additional Labour Cost</Typography>
                    </TableCell>
                    <TableCell align="center" width={100}>
                      <Typography variant="h6">{additionalServices || 0}</Typography>
                    </TableCell>
                  </RowResultStyle> */}

                  {projectData?.job_category_name=='Time & Material' ? "" :
                    <RowResultStyle>
                      {/* <TableCell  width={240}/> */}
                      <TableCell align="right" colSpan={4} >
                        <Typography variant="h6">Actual Product Formula Cost</Typography>
                      </TableCell>
                      {/* <TableCell align="center">
                      <Typography variant="h6" sx={{ whiteSpace: { xs: 'none', md: 'nowrap' }, width: '100%', ml: 3 }}>
                        {`${selectedProjects?.reduce((accumulator, currentValue) => {
                          return accumulator + (currentValue?.unit_cost * currentValue?.quantity);
                        }, 0) + services?.reduce((accumulator, currentValue) => {
                          return accumulator + (currentValue?.originalCost * currentValue?.qty);
                        }, 0) || 0} (${selectedProjects?.reduce((accumulator, currentValue) => {
                          return accumulator + (currentValue?.unit_cost * currentValue?.quantity);
                        }, 0) || 0} + ${services?.reduce((accumulator, currentValue) => {
                          return accumulator + (currentValue?.originalCost * currentValue?.qty);
                        }, 0) || 0})`}
                      </Typography>
                    </TableCell> */}
                      <TableCell align="center">
                        <Typography variant="h6" sx={{ whiteSpace: { xs: 'none', md: 'nowrap' }, width: '100%', ml: 3 }}>
                          {`${(selectedProjects?.reduce((accumulator, currentValue) => {
                            let newTotal = 0;
                            if(currentValue?.taken == 1){
                              newTotal = currentValue?.jobcost_total
                              // newTotal = currentValue?.jobcost_unit_cost * currentValue?.jobcost_quantity // currentValue?.unit_cost * currentValue?.quantity
                            }
                            return accumulator + newTotal;
                          }, 0) + services?.reduce((accumulator, currentValue) => {
                            let newTotal = 0;
                            if(currentValue.recipe_id && currentValue?.taken == 1){
                              newTotal = parseFloat(currentValue?.jobcost_total_cost || 0)
                              // newTotal = currentValue?.jobcost_unit_cost * currentValue?.jobcost_quantity // currentValue?.originalCost * currentValue?.qty
                            }
                            return accumulator + newTotal;
                          }, 0) || 0).toFixed(2)} (${(selectedProjects?.reduce((accumulator, currentValue) => {
                            let newTotal = 0;
                            if(currentValue?.taken == 1){
                              newTotal = currentValue?.jobcost_total
                              // newTotal = currentValue?.jobcost_unit_cost * currentValue?.jobcost_quantity // currentValue?.unit_cost * currentValue?.quantity
                            }
                            return accumulator + newTotal;
                          }, 0) || 0).toFixed(2)} + ${(services?.reduce((accumulator, currentValue) => {
                            let newTotal = 0;
                            if(currentValue.recipe_id && currentValue?.taken == 1){
                              // newTotal = currentValue?.originalCost * currentValue?.qty
                              // newTotal = currentValue?.jobcost_unit_cost * currentValue?.jobcost_quantity
                              newTotal = parseFloat(currentValue?.jobcost_total_cost || 0)
                            }
                            return accumulator + newTotal;
                          }, 0) || 0).toFixed(2)})`}
                        </Typography>
                      </TableCell>

                    </RowResultStyle>
                  }

                    <RowResultStyle>
                      {/* <TableCell colSpan={3} /> */}
                      <TableCell align="right" colSpan={4} >
                        <Typography variant="h6">{projectData?.job_category_name=='Time & Material' ? "Service Cost" : "Excess Labor Cost"}</Typography>
                      </TableCell>
                      <TableCell align="center" width={100}>
                        <Typography variant="h6">
                          {projectData?.job_category_name=='Time & Material' ? (parseFloat(additionalLaborCost1)).toFixed(2) : parseFloat(excessLaborCost1).toFixed(2)}
                          {/* {Math.max(
                            0,
                            (
                              Math.max(
                                0,
                                manualTotalPayOut
                                  ? parseFloat(manualTotalPayOut.toFixed(2))
                                  : 0
                              ) +
                              (hourlyTotalPayOut
                                ? parseFloat(hourlyTotalPayOut.toFixed(2))
                                : 0)
                            ) -
                            ((projectData?.job_category_name=='Time & Material' ? 0 :
                              (services?.reduce((accumulator, currentValue) => {
                                return accumulator + Number(currentValue?.jobcost_total_cost || 0);
                              }, 0) || 0)) + Number(additionalServices || 0)
                            )
                          ).toFixed(2)} */}
                        </Typography>
                      </TableCell>
                    </RowResultStyle>
                    {/* <RowResultStyle>
                      <TableCell align="right" colSpan={4} >
                      <Typography variant="h6">Manual Staff Pay Out</Typography>
                    </TableCell>
                    <TableCell align="center" width={100}>
                      <Typography variant="h6">{manualTotalPayOut ? manualTotalPayOut?.toFixed(2) : 0}</Typography>
                    </TableCell>
                  </RowResultStyle> */}
                    {/* <RowResultStyle>
                      <TableCell align="right" colSpan={4} >
                        <Typography variant="h6">Hourly Staff Pay Out</Typography>
                    </TableCell>
                    <TableCell align="center" width={100}>
                      <Typography variant="h6">{hourlyTotalPayOut ? hourlyTotalPayOut?.toFixed(2) : 0}</Typography>
                    </TableCell>
                  </RowResultStyle> */}

                    <RowResultStyle>
                      {/* <TableCell colSpan={3} />  */}
                      {/* <TableCell align="center" width={240}> */}
                      <TableCell align="right" colSpan={4} sx={{color:'#fc8c03'}} >
                        <Typography variant="h6">Total Cost</Typography>

                      </TableCell>
                      <TableCell align="center" width={100} sx={{color:'#fc8c03'}}>
                        <Typography variant="h6">{grandTotalCost ? grandTotalCost?.toFixed(2) : 0}</Typography>

                      </TableCell>

                    </RowResultStyle>
                    </Box>
                    </Box>
                    {/* {currentTab =="Installations" ? '' : */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', }}>
                      <LoadingButton loading={isSubmitting} sx={{ borderRadius: '20px', mx: 3, mb: 1, width: '80%' }} type="submit" variant="contained">
                        Submit
                      </LoadingButton>
                    </Box>
                    {/* } */}
                  </Form>
                </FormikProvider>
              </Card>
            </Grid>
          </Grid>
        </Container>
        {
          materialsPreview && (
            <Modal
              open={materialsPreview}
              handleClose={() => {
                setMaterialsPreview(false);
                setIsRecipe(false)
              }}
              modalTitle={'Select material'}
            >
              {isRecipe ? (
                <Grid container sx={{ justifyContent: 'center' }} spacing={3}>
                  <Grid item xs={12} md={10}>
                    <Stack
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                      spacing={3}
                    >
                      <Stack
                        direction={{ xs: 'column', md: 'column' }}
                        display="flex"
                        justifyContent="space-between"
                        spacing={3}
                      >
                        {console.log("#####selectedMaterial", selectedMaterial)}
                        <Stack direction={{ xs: 'column', md: 'row' }}>
                          <Typography variant="body" sx={{ display: 'flex', alignItems: 'center' }}>
                            Material Name :
                            <span style={{ color: theme.palette.primary.dark, fontWeight: 'bold', paddingRight: "2" }}>
                              <Autocomplete
                                sx={{ ml: 1, width: 300 }}
                                id="combo-box-demo"
                                options={selectedMaterial.ingredients}
                                value={ingredient}
                                onChange={(event, newValue) => setIngredient(newValue)}
                                renderInput={(params) => (
                                  <TextField {...params}
                                    label="Select a Material" />
                                )}
                                getOptionLabel={(option) => option.name ? option.name : ''}
                              />
                            </span>
                            Quantity : {selectedMaterial?.ingredients?.find(item => item.product_id === ingredient?.product_id)?.quantity}
                          </Typography>
                        </Stack>




                        {/* <Card> */}
                        <Scrollbar>
                          <TableContainer>
                            <Table>
                              <TableHead sx={{ width: '100%' }}>
                                <TableRow>
                                  <TableCell align='center'>#</TableCell>
                                  <TableCell align='center'>Barcode</TableCell>
                                  <TableCell align='center'>Unit Cost</TableCell>
                                  <TableCell align='center'>Available Stock</TableCell>
                                  <TableCell align='center'></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {loading ? (
                                  <CircularProgress color="primary" variant="indeterminate" />
                                ) : (

                                  <>
                                    {productWithQty?.filter((data) => (data?.material_id === ingredient?.product_id)).map((row, index) => {
                                      let { id, name, unit_cost, qty, barcode } = row;

                                      const isAlreadyAdded = selectedProjects.some(project => project.material_id === row.material_id);

                                      let totalQuantityInSelectedProjects = 0;

                                      if (isAlreadyAdded) {
                                        totalQuantityInSelectedProjects = selectedProjects.reduce((total, project) => {
                                          if (project.material_id === row.material_id) {
                                            return total + project.quantity;
                                          }
                                          return total;
                                        }, 0);
                                      }

                                      let quantity = selectedMaterial?.ingredients?.find(item => item.product_id === ingredient?.product_id)?.quantity || 1;

                                      if (qty < quantity) {
                                        quantity = qty;
                                      }
                                      console.log("selectedprojects.Product Formula", selectedMaterial.name);
                                      return (
                                        <TableRow
                                          hover
                                          key={index}
                                          tabIndex={-1}
                                          sx={{
                                            borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                                          }}
                                        >
                                          <TableCell align='center'> {index + 1} </TableCell>
                                          <TableCell align="center"> {barcode} </TableCell>
                                          <TableCell align="center"> ${unit_cost} </TableCell>
                                          <TableCell align="center"> {qty} </TableCell>
                                          <TableCell align="center">
                                            <Button
                                              onClick={() => {
                                                if (!selectedProjects.map(s => s.id).includes(id)) {
                                                  if (totalQuantityInSelectedProjects > 0) {
                                                    let newQuantity = Math.max(0, quantity - totalQuantityInSelectedProjects);
                                                    if (newQuantity === 0) {
                                                      enqueueSnackbar('Material Stock exceed', { variant: 'error' });
                                                      return;
                                                    }
                                                    handleProjectClick({ ...row, quantity: newQuantity, recipeName: selectedMaterial.name });
                                                  } else {
                                                    handleProjectClick({ ...row, quantity: quantity, recipeName: selectedMaterial.name });
                                                  }
                                                } else {
                                                  enqueueSnackbar('Material Already Added', { variant: 'error' });
                                                }
                                              }}
                                            >
                                              <AddCircle />
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </>


                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Scrollbar>
                        {/* </Card> */}
                        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 2 }}>
                          <Button variant='outlined' onClick={() => { setMaterialsPreview(false); setSelectedMaterial(null); }}>Close</Button>
                        </Box>
                      </Stack>

                    </Stack>
                  </Grid>
                </Grid>) :
                (<Grid container sx={{ justifyContent: 'center' }} spacing={3}>
                  <Grid item xs={12} md={10}>
                    <Stack
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                      spacing={3}
                    >
                      <Stack
                        direction={{ xs: 'column', md: 'column' }}
                        display="flex"
                        justifyContent="space-between"
                        spacing={3}
                      >
                        {console.log("#####selectedMaterial", selectedMaterial)}
                        <Stack direction={{ xs: 'column', md: 'column' }}>
                          <Typography variant="body">
                            Material Name : <span style={{ color: theme.palette.primary.dark, fontWeight: 'bold' }}>{selectedMaterial?.name}</span>
                          </Typography>
                        </Stack>

                        {/* <Card> */}
                        <Scrollbar>
                          <TableContainer>
                            <Table>
                              <TableHead sx={{ width: '100%' }}>
                                <TableRow>
                                  <TableCell align='center'>#</TableCell>
                                  <TableCell align='center'>Barcode</TableCell>
                                  <TableCell align='center'>Unit Cost</TableCell>
                                  <TableCell align='center'>Available Stock</TableCell>
                                  <TableCell align='center'></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {loading ? (
                                  <CircularProgress color="primary" variant="indeterminate" />
                                ) : (
                                  <>
                                    {productWithQty?.filter((data) => (data?.material_id === selectedMaterial?.id)).map((row, index) => {
                                      // console.log('selectedProjects------',selectedProjects);
                                      // console.log('selectedMaterial------',selectedMaterial);
                                      let { id, name, unit_cost, qty, barcode } = row;
                                      return (
                                        <TableRow
                                          hover
                                          key={index}
                                          tabIndex={-1}
                                          sx={{
                                            borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                                          }}
                                        >
                                          <TableCell align='center'> {index + 1} </TableCell>
                                          <TableCell align="center"> {barcode} </TableCell>
                                          <TableCell align="center"> ${unit_cost} </TableCell>
                                          <TableCell align="center"> {qty} </TableCell>
                                          <TableCell align="center">
                                            <Button
                                              onClick={() => !selectedProjects.map(s => s.id).includes(id) ? handleProjectClick({ ...row, quantity: 1 }) : enqueueSnackbar('Material Already Added', { variant: 'error' })}
                                            >
                                              <AddCircle />
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Scrollbar>
                        {/* </Card> */}
                        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 2 }}>
                          <Button variant='outlined' onClick={() => { setMaterialsPreview(false); setSelectedMaterial(null); }}>Close</Button>
                        </Box>
                      </Stack>

                    </Stack>
                  </Grid>
                </Grid>)}
            </Modal>
          )
        }
        {jobCostPreview && (
          <Modal
            open={jobCostPreview}
            handleClose={() => {
              setJobCostPreview(false);
            }}
            modalTitle={
              <>
              {"Job Cost Calculator "}
              {projectData?.job_category_name === 'Time & Material' ? <Label variant="filled" color="info">T&M</Label> : ''}
              </>
            }
            width={1200}
          >
            <CompareJobCost id={id} />
          </Modal>
        )}
      </Page >
  );
}
