import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import { capitalCase } from 'change-case'
import videoFill from '@iconify/icons-eva/video-fill'
import phoneFill from '@iconify/icons-eva/phone-fill'
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill'
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill'
// material
import { experimentalStyled as styled } from '@material-ui/core/styles'
import { Box, Link, Avatar, Typography, AvatarGroup, IconButton, Autocomplete, Stack, Button, TextField } from '@material-ui/core'
// utils
//
import BadgeStatus from 'src/components/BadgeStatus'
import { MChip, MIconButton } from 'src/components/@material-extend'
import { fToNow } from 'src/utils/formatTime'
import defaltProfile from '../../../../assets/defaultAvatar.png'
import { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { REST_API_END_POINT, STATUS_OPTIONS } from 'src/constants/Defaultvalues'
import { ArrowBackIosNew, PersonAddAlt, Send } from '@material-ui/icons'
import SearchNotFound from 'src/components/SearchNotFound'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { useSnackbar } from 'notistack'
import axios from 'axios'
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  flexShrink: 0,
  minHeight: 92,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 3),
}))

const AutocompleteStyle = styled('div')(({ theme }) => ({
  '& .MuiAutocomplete-root': {
    width: 280,
    '&.Mui-focused .MuiAutocomplete-inputRoot': {
      boxShadow: theme.customShadows.z8,
    },
  },
  '& .MuiAutocomplete-inputRoot': {
    transition: theme.transitions.create('box-shadow', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '& fieldset': {
      borderWidth: `1px !important`,
    },
  },
  '& .MuiAutocomplete-paper': {
    maxHeight: '50vh', // Set a maximum height
    overflowY: 'auto',  // Enable vertical scrolling
  },
}));

// ----------------------------------------------------------------------

OneAvatar.propTypes = {
  participants: PropTypes.array,
}

function OneAvatar({ participants }) {
  const participant = participants

  if (participant === undefined) {
    return null
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ position: 'relative' }}>
        <Avatar
          src={
            typeof participant?.profile_pic === 'string'
              ? participant.profile_pic
              : defaltProfile
          }
          alt={participant?.full_name}
        />
        <BadgeStatus
          status={participant?.status}
          sx={{ position: 'absolute', right: 2, bottom: 2 }}
        />
      </Box>
      <Box sx={{ ml: 2 }}>
        <Typography variant="subtitle2">{participant?.full_name}</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {/* {participant?.active !== 'N' ? "Online" : fToNow(participant?.lastActivity)} */}
          {participant?.chat_status ? STATUS_OPTIONS?.find(item=>item.id == participant?.chat_status)?.label : (participant?.last_activity ? fToNow(moment.unix(participant?.last_activity)) : 'Offline')}
          {/* Offline */}
        </Typography>
      </Box>
    </Box>
  )
}

GroupAvatar.propTypes = {
  contact: PropTypes.array,
}

function GroupAvatar({ contact,handleOpensidebar }) {
  let participants = contact?.participants;
  let groupName = contact?.group_name || 'Unnamed Group';

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <AvatarGroup
        max={3}
        sx={{
          mb: 0.5,
          '& .MuiAvatar-root': { width: 32, height: 32 },
        }}
      >
        {participants?.map((participant) => (
          <Avatar
            key={participant.id}
            alt={participant?.full_name}
            src={
              typeof participant?.profile_pic === 'string'
                ? participant.profile_pic
                : defaltProfile
            }
          />
        ))}
      </AvatarGroup>
      <Box sx={{ ml: 2 }}>
        <Typography variant="subtitle2">{groupName}</Typography>
        <Link
          variant="body2"
          underline="none"
          component="button"
          color="text.secondary"
          onClick={handleOpensidebar}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {participants?.length} persons
            <Icon icon={arrowIosForwardFill} />
          </Box>
        </Link>
      </Box>
    </Box>
  );
}


ChatHeaderDetailSignWise.propTypes = {
  contact: PropTypes.array,
}

export default function ChatHeaderDetailSignWise({ contact, handleRefresh, allContacts, handleOpensidebar,setSelectedContact,setCompose, ...other }) {
  const [isGroup, setIsGroup] = useState(false)
  const [showAddMembers, setShowAddMembers] = useState(false);
  const [members, setMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [query, setQuery] = useState('')
  const user = JSON.parse(localStorage.getItem('user'));
  const { enqueueSnackbar } = useSnackbar();

  const filteredContacts = useMemo(() => {
    return (allContacts || []).filter((contact) => {
      if (!query.trim()) return contact.user_id !== null;
      return contact.user_id !== null && contact?.first_name?.toLowerCase().includes(query.trim().toLowerCase());
    });
  }, [allContacts, query]);

  useEffect(() => {
    if (contact?.conversation_type === 'group') {
      setIsGroup(true);
      setMembers(filteredContacts?.filter(c => !contact.participants.find(p => p.user_id === c.user_id)));
    } else {
      setIsGroup(false);
    }
  }, [contact,filteredContacts])
  console.log('contact@$^', contact)

  const handleToggleAddMembers = () => {
    setShowAddMembers(!showAddMembers);
    setSelectedMembers([]);
  };

  const handleAddMemberToGroup = async () => {
    if(selectedMembers?.length === 0){
      enqueueSnackbar("Please select at least one member", { variant: "warning" });
      return;
    }
    try {
      const response = await axios.post(`${REST_API_END_POINT}chat/add-members-to-group`,
        { conversationId : contact?.conversation_id, userId: user?.user_id, selectedMembers })
      let res = response?.data
      if(res){
        enqueueSnackbar(res.message, { variant: res.status === 1 ? 'success' : 'error' });
        if(res.status === 1){
          handleRefresh();
          setShowAddMembers(false);
          setSelectedMembers([]);
          setQuery('');
        }
      }
    } catch (error) {
      console.error('Error removing member from group:', error);
      if (error.response) {
        enqueueSnackbar(error.response.data?.message || "Something went wrong!", { variant: 'error' });
      } else {
        enqueueSnackbar("Error: Something went wrong!", { variant: 'error' });
      }
    }
  }

  return (
    <RootStyle {...other}>

      <IconButton onClick={() => {
        setSelectedContact(null)
        setCompose(true)
        }} sx={{ mr: 2 }}>
        <ArrowBackIosNew/>
      </IconButton>

      {isGroup ? (
        <GroupAvatar contact={contact} handleOpensidebar={handleOpensidebar} />
      ) : (
        <OneAvatar participants={contact} />
      )}
      <Box sx={{ flexGrow: 1 }} />
      {isGroup && (
        <>
          {showAddMembers && (
            <Stack direction="row" spacing={1} alignItems="center">
              <AutocompleteStyle>
                <Autocomplete
                  multiple
                  size="small"
                  disablePortal
                  popupIcon={null}
                  noOptionsText={<SearchNotFound searchQuery={query} />}
                  onChange={(event, newValue) => {
                    setSelectedMembers(newValue);
                  }}
                  value={selectedMembers}
                  inputValue={query}
                  onInputChange={(event, newQuery) => setQuery(newQuery)}
                  options={members}
                  getOptionLabel={(contact) => contact.first_name || 'Unknown'}
                  renderOption={(props, contact, { inputValue, selected }) => {
                    const matches = match(contact.first_name, inputValue);
                    const parts = parse(contact.first_name, matches);
                    return (
                      <li {...props}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar
                            alt={contact.first_name}
                            src={contact.profile_pic || '/default-avatar.png'}
                          />
                          <Box sx={{ ml: 2 }}>
                            {parts.map((part, index) => (
                              <Typography
                                key={index}
                                variant="subtitle2"
                                color={part.highlight ? 'primary' : 'textPrimary'}
                              >
                                {part.text}
                              </Typography>
                            ))}
                          </Box>
                        </Box>
                      </li>
                    )
                  }}
                  renderTags={(recipients, getTagProps) =>
                    recipients.map((recipient, index) => (
                      <MChip
                        key={recipient.id}
                        size="small"
                        label={recipient.first_name}
                        color="info"
                        avatar={
                          <Avatar
                            alt={recipient.first_name}
                            src={recipient.profile_pic || '/default-avatar.png'}
                          />
                        }
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      label="Select members..."
                      {...params}
                      placeholder={selectedMembers.length === 0 ? 'Recipients' : ''}
                    />
                  )}
                />
              </AutocompleteStyle>
              <Button
                onClick={handleAddMemberToGroup}
                sx={{
                  border: '1px dashed #2e434b',
                  '&:hover': {
                    backgroundColor: 'primary.main',
                    color: 'common.white',
                  },
                }}
              >
                <Send sx={{ fontSize: '1rem' }} />
              </Button>
            </Stack>
          )}
          <MIconButton onClick={handleToggleAddMembers}>
            <PersonAddAlt color={showAddMembers ? 'primary' : '#ffffff'}/>
          </MIconButton>
        </>
      )}
      <MIconButton>
        <Icon icon={phoneFill} width={20} height={20} />
      </MIconButton>
      <MIconButton>
        <Icon icon={videoFill} width={20} height={20} />
      </MIconButton>
      {/* <MIconButton>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </MIconButton> */}
    </RootStyle>
  )
}
