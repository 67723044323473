import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import instagramFilled from '@iconify/icons-ant-design/instagram-filled';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { sentenceCase } from 'change-case';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { Box, Card, Grid, Avatar, Tooltip, Divider, Typography, IconButton,Button, LinearProgress,
  linearProgressClasses,
  Checkbox, } from '@material-ui/core';
// utils
import { fShortenNumber } from '../../../../utils/formatNumber';
//
import SvgIconStyle from '../../../SvgIconStyle';
import Label from 'src/components/Label';
import ViewProject from '../../../../pages/dashboard/ViewProject';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import moment from 'moment';
import { UserMoreMenu } from '../list';
import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
import ConfirmationPopup from 'src/components/confirmationPopup';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Stack } from 'immutable';
import { MChip } from 'src/components/@material-extend';
import FullScreenDialogsJobs from 'src/pages/components-overview/material-ui/dialog/FullScreenDialogsAllJobs';
import { useSearchParams } from 'react-router-dom';

// ----------------------------------------------------------------------

// const SOCIALS = [
//   {
//     name: 'Facebook',
//     icon: <Icon icon={facebookFill} width={20} height={20} color="#1877F2" />
//   },
//   {
//     name: 'Instagram',
//     icon: <Icon icon={instagramFilled} width={20} height={20} color="#D7336D" />
//   },
//   {
//     name: 'Linkedin',
//     icon: <Icon icon={linkedinFill} width={20} height={20} color="#006097" />
//   },
//   {
//     name: 'Twitter',
//     icon: <Icon icon={twitterFill} width={20} height={20} color="#1C9CEA" />
//   }
// ];

const option = [
  { id: 0, value: 'Inactive' },
  { id: 1, value: 'New' },
  { id: 2, value: 'Open' },
  { id: 3, value: 'Completed' },
  { id: 4, value: 'Hold' },
  { id: 5, value: 'Accepted' },
  { id: 6, value: 'Rejected' },
  { id: 7, value: 'Verified' },
  { id: 6, value: 'Reopen' },
]
const colors = [
  'error',
  'primary',
  'secondary',
  'success',
  'warning',
  'success',
  'error',
  'success',
  'error',
]

const CardMediaStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  paddingTop: 'calc(100% * 9 / 16)',
  '&:before': {
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
    //  backdropFilter: 'blur(3px)',
     WebkitBackdropFilter: 'blur(3px)', // Fix on Mobile
    borderTopLeftRadius: theme.shape.borderRadiusMd,
    borderTopRightRadius: theme.shape.borderRadiusMd,
    // backgroundColor: alpha(theme.palette.primary.darker, 0.72)
  }
}));

const CoverImgStyle = styled('img')({
  top: 0,
  zIndex: 8,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  // background: `url(${src}) center/cover no-repeat`
});

// ----------------------------------------------------------------------

function InfoItem(number) {
  return (
    <Grid item xs={4}>
      <Typography variant="caption" sx={{ mb: 0.5, color: 'text.secondary', display: 'block' }}>
        Follower
      </Typography>
      <Typography variant="subtitle1">{fShortenNumber(number)}</Typography>
    </Grid>
  );
}

// UserCard.propTypes = {
//   user: PropTypes.object.isRequired
// };

export default function UserCard({ user, handleClickOpenViewPage, setRenderCards, medal, setIsEdit, openViewPage,removePopupData, setOpenViewPage,projectId, setProjectId,projectName,setProjectName, handleFetchEditData,currentTab,handleCheckboxChange,renderCards,communityJob}) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const navigate=useNavigate()
  // const [searchParams, setSearchParams] = useSearchParams();
  const currentDateUnix = moment();
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: `hsl(${120 - (progressValue * 1.2)}, ${60}%, 50%)`, // `hsl(${(progressValue * 1.2)}, ${60 + (progressValue * 0.4)}%, 50%)`
    },
  }));
  console.log('user==========',user);
  const { name, due_date,installation_date, id, status,cover_image, member_count, task_count, img, logo='https://t3.ftcdn.net/jpg/04/60/44/08/360_F_460440876_a8lkfz8UHz64eDPFzmSJJZ55UQBNiC0a.jpg' ,progressValue,hold,jobcost,job_category_name,job_class_name, community_name, customer_name,allInstallationCompleted,InstallCompleteStatus,attachment,isFromDesignOrder  } = user;                 
  const [isDelete,setIsDelete] = useState(false)
  const [openHoldPopup, setOpenHoldPopup] = useState(false);

  // const [openViewPageTemp, setOpenViewPageTemp] = useState(false);
  // const [openViewPage, setOpenViewPage] = useState(openViewPageTemp?openPopupTemp:false||false);

  // const [projectName,setProjectName]=useState('')
  // const [projectId, setProjectId] = useState('');
  const filteredAttachments = attachment?.filter(imageItem=>(imageItem?.file_type?.startsWith("image") || imageItem?.file_type === "img")) || []
  let userData = JSON.parse(localStorage.getItem('user'))
  const CompletedStatus = InstallCompleteStatus ===1? 'Installed' : InstallCompleteStatus === 2 ? 'Picked Up':InstallCompleteStatus === 3? 'Shipped' : ''
  const explodedPermissions =userData?.exploded_permissions
  const handleDeleteTask = (status)=>{
    try {
      // const  user  = JSON.parse(window.localStorage.getItem('user')
      let newStatus = 0
      if ( status === 0 ){
        newStatus = 1
      }else if ( status === 2 ){
        newStatus = 2
      }else{
        newStatus = 0
      }
      axios.post(`${REST_API_END_POINT}project/delete-project`,{ id: Number(id), status: newStatus },{
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if(response.data.status === 4 || response.data.status === 2 || response.data.status === 1){
          enqueueSnackbar(response.data.message, { variant: 'success' });
          setRenderCards(true)
          // navigate(PATH_DASHBOARD.project.viewproject+projectId)
        }else if(response.data.status === 5 || response.data.status === 3 || response.data.status === 0){
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
        setIsDelete(false)
      }).catch((error) => {
        console.error(error);
      })
      }
      catch (error) {
        console.error("Error fetching data:", error);
      }
  }

  const handleUpdateHoldStatus = async () => {
    try {
      setOpenHoldPopup(false)
      const user = JSON.parse(window.localStorage.getItem('user'))
      const res = await axios.post(`${REST_API_END_POINT}project/update-job-hold-status`,
        { projectId: Number(id), userId: user.user_id }, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if (response.data.status === 1) {
          enqueueSnackbar(response.data.message, { variant: 'success' });
          setRenderCards(true)
        } else if (response.data.status === 0) {
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
      });
    } catch (error) {
      console.error('Error Moving to Hold:', error);
      throw error;
    }
  };

  return (
   <>
      <Card sx={{transition: 'transform 0.2s',
      '&:hover': {
        transform: 'scale(1.02)',
      }, }}
        >
        <Box sx={{ position: 'absolute', top: 8, left: 8, zIndex: 10, padding: '12px' }}>
        {currentTab === 'Post Production'? <Checkbox
              onChange={(e) => handleCheckboxChange(user.id, e.target.checked,user)}
            />
            :
            <img width={25} height={25} src={medal}/>
            }
          
          {/* {jobcost===1?<MChip label="Hold" color="warning" ></MChip>:""} */}
          {/* {jobcost===1?<Label variant="filled" color="warning">Hold</Label>:""} */}
          
          
        </Box>
        {/* {job_category_name=='Time & Material' ? 
          <Box sx={{ position: 'absolute', top: 8, left: '40%', zIndex: 10, padding: '12px' }}> 
            <Label variant='filled' color="info">T&M</Label>
          </Box>
        :''} */}
        <Box sx={{ position: 'absolute', top: 8, left: '40%', zIndex: 10, padding: '12px' }}> 
          {hold===1?<Label variant="filled" color="warning">Hold</Label>:""}
          {currentTab === 'Post Production'? <img width={25} height={25} src={medal}/> : ""}
        </Box>
        <Box sx={{ position: 'absolute',top: 140, left: 16, zIndex: 10,display:"flex",justifyContent:"space-between" }}>
          {jobcost === 1 ?
            <Tooltip title="Material Out of Stock">
              <Typography>
                <Label variant='filled' color="error">Material Out of Stock</Label>
              </Typography>
            </Tooltip>
          : ""}
        </Box>
        {job_class_name ? 
        <Box sx={{ position: 'absolute',cursor:'pointer', top: 110, right: 6, zIndex: 10 }}>
          <Tooltip title={`Job Class: ${job_class_name}`}>
            <Typography >
              <Label variant='filled' color="secondary">{job_class_name}</Label>
            </Typography>
          </Tooltip>
        </Box> : '' }
        {job_category_name ? 
        <Box sx={{ position: 'absolute',cursor:'pointer', top: 140, right: 6, zIndex: 10 }}>
          <Tooltip title={`Job Type: ${job_category_name}`}>
          <Typography>
          <Label variant='filled' color="info">{job_category_name}</Label>
          </Typography>
          </Tooltip>
        </Box> : '' }
        <Box sx={{ position: 'absolute', top: 0, right: 0, zIndex: 20, padding: '12px' }}>
        {/* <IconButton
            sx={{
              color: (theme) => theme.palette.common.white, 
              '&.Mui-checked': {
                color: (theme) => theme.palette.common.white 
              }
            }}
            aria-controls="simple-menu"
            aria-haspopup="true"
            // onClick={handleClick}
            size="small"
          >
             <Icon  icon={moreVerticalFill} width={20} height={20} />
          </IconButton> */}
         {(userData?.user_type !=3 && currentTab!="Completed") && <UserMoreMenu hold={hold} text={status === 0 ? 'Activate' : null} 
          {...((currentTab!="Installations" && currentTab!="Completed" && currentTab!="Hold") ? {
            onDeactivate: ()=>{
            if(explodedPermissions?.some(permission => ['Activate/Deactivate Jobs'].includes(permission))){
              handleDeleteTask(status)
            }else{
              enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
            }
          }} : {})}
          onDelete={()=>{
            if(explodedPermissions?.some(permission => ['Activate/Deactivate Jobs'].includes(permission))){
              setIsDelete(true);
            }else{
              enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' });
            }
          }}
          {...((currentTab!="Installations" && currentTab!="Completed") ? { onHold:()=>{
            if(explodedPermissions?.some(permission => ['Activate/Deactivate Jobs'].includes(permission))){
              setOpenHoldPopup(true);
            }else{
              enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' });
            }
          }} : {})}
          {...(status !== 3 ? {
            onEdit: () => {
              if(explodedPermissions?.some(permission => ['Edit Jobs'].includes(permission))){
                setIsEdit(true);
                handleFetchEditData(id);
              }else{
                enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
              }
            }
          } : {})} />}
        </Box>
        <CardMediaStyle sx={{cursor:'pointer'}} 
        onClick={()=>handleClickOpenViewPage(id,name)}
      //   onClick={()=>{
      //   //  navigate(PATH_DASHBOARD.project.viewproject + id, { state: { currentTab:currentTab } });
        
  
      // }}
      >
       
        {/* <IconButton
          sx={{
            top: 8,
            right: 8,
            position: 'absolute'
          }}
        >
          <Icon icon={moreVerticalFill} width={20} height={20} />
        </IconButton> */}
          {/* <SvgIconStyle
            color="paper"
            src="/static/icons/shape-avatar.svg"
            sx={{
              width: 144,
              height: 62,
              zIndex: 10,
              bottom: -26,
              position: 'absolute'
            }}
          /> */}
          {/* <Avatar
            alt={name}
            src={img}
            sx={{
              width: 64,
              height: 64,
              zIndex: 11,
              position: 'absolute',
              transform: 'translateY(-50%)'
            }}
          /> */}
          <CoverImgStyle alt="cover" src={(isFromDesignOrder && filteredAttachments?.length >0)? filteredAttachments[0]?.name:(cover_image && cover_image !== 'DefaultThumbnailURL') ? cover_image : logo} />
          
        </CardMediaStyle>
        <Grid container sx={{cursor:'pointer', py: 0.5, textAlign: 'center',justifyContent:'space-between' ,display:'flex',flexDirection:'row',px:2 }}>
          <Typography>
            <Label
              variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
              color="secondary"
            >
              <Tooltip title={customer_name}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: '12px',
                    cursor: 'pointer',
                    display: 'inline-block',
                    maxWidth: 100, // Adjust as necessary
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <span
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {customer_name}
                  </span>
                </Typography>
              </Tooltip>
            </Label>
          </Typography>
          <Typography>
          {
                currentTab==="Completed"?
            <Label
              variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
              color="success"
            >
              
  
              
              <Tooltip title={CompletedStatus}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: '12px',
                    cursor: 'pointer',
                    display: 'inline-block',
                    maxWidth: 100, // Adjust as necessary
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <span
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {CompletedStatus}
                  </span>
                </Typography>
              </Tooltip>
            </Label>
              :""}
          </Typography>
          <Typography>
            <Label
              variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
              color="default"
            >
              <Tooltip title={community_name}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: '12px',
                    cursor: 'pointer',
                    display: 'inline-block',
                    maxWidth: 100, // Adjust as necessary
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <span
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {community_name}
                  </span>
                </Typography>
              </Tooltip>
            </Label>
          </Typography>
        </Grid>
        {/* <Typography style={{cursor:'pointer'}} onClick={()=>{
         navigate(PATH_DASHBOARD.project.viewproject+id, { state: { currentTab:currentTab } })
      }} variant="subtitle1" align="center" sx={{ mt: 2 }}>
          {name}
        </Typography> */}
        <Tooltip title={name}>
          <Typography
            variant="subtitle1"
            align="center"
          //   onClick={()=>{
          //     navigate(PATH_DASHBOARD.project.viewproject+id, { state: { currentTab:currentTab } })
          //  }}
          onClick={()=>{
            if(communityJob){
              navigate(PATH_DASHBOARD.project.viewproject+id, { state: { fromCommunityJob: true } })
            }else{
            handleClickOpenViewPage(id,name)
            }
          }}
          
            sx={{
              alignItems:'center',
              justifyContent:'center',
              mt: 2,
              px: 2,
              cursor: 'pointer',
              display: 'flex',
              maxWidth: "100%", // Adjust as necessary
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <span
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {name}
            </span>
          </Typography>
        </Tooltip>
  
  
  
  
  
        <Typography variant="body2" align="center" sx={{ color: 'text.secondary',mb:3 }}>
          {(status!==3&& moment.unix(installation_date).isBefore(currentDateUnix))?(
           <Label 
              variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
              color={'error'}
              >
               Overdue:{moment.unix(installation_date).format('MM/DD/YYYY')}
           </Label>
           ):(
           <>
          Date:{moment.unix(installation_date).format('MM/DD/YYYY')}
          </>
          )}
        </Typography>
        {/* {userData.user_type === 1 && <Button onClick={() => {
                              navigate(PATH_DASHBOARD.project.jobCost+'/'+id)
                            }} sx={{ borderRadius: 20,marginBottom:1 }} variant="outlined">
                    Job Cost
                  </Button>} */}
  
        {userData.user_type === 1 ?<>
          <Typography variant="body2" color="text.secondary"  style={{display:"flex", flexDirection:"column" ,alignItems:"center"}}>Job Cost</Typography>
          <Box sx={{ width: '100%', mr: 1 }}>
          
          <BorderLinearProgress variant="determinate" value={progressValue} />
        </Box>
       <Box sx={{ minWidth: 35 }}>  
         <Typography variant="body2" color="text.secondary">{`${Math.round(
           progressValue,
         )}%`}</Typography>
       </Box>
       </>:""}
  
        <Divider />
        
        <Grid container sx={{cursor:'pointer', py: 3, textAlign: 'center',justifyContent:'space-between' ,display:'flex',flexDirection:'row',px:2, }}
        onClick={()=>{
          navigate(PATH_DASHBOARD.project.viewproject+id)
       }}>
          <Typography>
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
             color={colors[status]} >
             {option.find(opt=>opt.id===status).value} 
            </Label>
          </Typography>
          <Typography variant='subtitle2'>
          {(status!==3&& moment.unix(installation_date).isBefore(currentDateUnix))?(
           <Label 
              variant={theme.palette.mode === 'light' ? 'ghost' : 'outlined'}
              color={'error'}
              >
               {`Overdue Tasks:${task_count}`}
           </Label>
           ):(
           <>
              {` Tasks:${task_count}`}
          </>
          )}
            {/* {` Tasks:${task_count}`} */}
            </Typography>
            <Typography variant='subtitle2'>{` Members:${member_count}`}</Typography>
        </Grid>
        {/* <Grid container sx={{paddingBottom:1, textAlign: 'center',justifyContent:'center'  }}>
        <Typography>{` Members:${member_count}`}</Typography>
        </Grid> */}
        {/* <ConfirmationPopup open={isDelete} handleClick={()=>handleDeleteTask(status)} message={'Want to Deactivate This Project'} handleClose={()=>setIsDelete(false)}/> */}
        <ConfirmationPopup
          open={isDelete}
          handleClick={()=>handleDeleteTask(2)}
          handleClose={() => { setIsDelete(false) }}
          message={'You want to delete this Job. This action will also permanently delete the associated Sales Order and any related data.'}
          // message={'You want to delete this Job. The related Offical Request will also get deleted.'}
        />
        <ConfirmationPopup
          open={openHoldPopup}
          handleClick={() => { handleUpdateHoldStatus() }}
          handleClose={() => { setOpenHoldPopup(false) }}
          message={hold === 1 ? `You want to move this Job from Hold` : `You want to move this Job to Hold`}
        />
      </Card>
   </>
  );
}
