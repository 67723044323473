import React, { useState } from 'react';

const AnimatedButton = ({ title, onClick }) => {
    const [isHovered, setIsHovered] = useState(false);

    const buttonStyle = {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        outline: 0,
        border: 0,
        margin: 0,
        cursor: 'pointer',
        verticalAlign: 'middle',
        WebkitAppearance: 'none',
        MozAppearance: 'none',
        appearance: 'none',
        textDecoration: 'none',
        lineHeight: 1.2,
        fontSize: '0.9375rem',
        letterSpacing: '0.43px',
        fontWeight: 'bold', // Bold text
        borderRadius: '6px',
        color: 'hsla(0, 0%, 100%, 0.9)',
        minInlineSize: '50px',
        textTransform: 'none',
        paddingBlock: '0.625rem',
        paddingInline: '1.25rem',
        background: 'linear-gradient(-45deg, #ffa63d, #ff3d77, #338aff, #3cf0c5)',
        backgroundSize: '600%',
        animation: 'gradientAnimation 12s linear infinite',
        // boxShadow: isHovered
        //     ? '0 6px 12px rgba(255, 166, 61, 0.7), 0 6px 12px rgba(255, 61, 119, 0.7), 0 6px 12px rgba(51, 138, 255, 0.7), 0 6px 12px rgba(60, 240, 197, 0.7)' // Hover effect
        //     : 
        //     // '',
        //     '0 4px 8px rgba(255, 166, 61, 0.5), 0 4px 8px rgba(255, 61, 119, 0.5), 0 4px 8px rgba(51, 138, 255, 0.5), 0 4px 8px rgba(60, 240, 197, 0.5)', // Default shadow
        // transition: 'box-shadow 0.3s ease-in-out', // Smooth transition
    };

    // Keyframes for the gradient animation
    const keyframesStyle = `
        @keyframes gradientAnimation {
            0% {
                background-position: 0 50%;
            }
            50% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0 50%;
            }
        }
    `;

    return (
        <>
            <style>{keyframesStyle}</style>
            <button
                onClick={onClick}
                style={buttonStyle}
                onMouseEnter={() => setIsHovered(true)}  // On hover
                onMouseLeave={() => setIsHovered(false)} // On hover end
            >
                {title}
            </button>
        </>
    );
};

export default AnimatedButton;
