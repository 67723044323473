import { Button, Grid, Box, TextField, Autocomplete, Stack } from '@material-ui/core';
import { LoadingButton, MobileDatePicker } from '@material-ui/lab';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import Modal from '../../components/_dashboard/blog/ProjectModel';
import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
import { useSnackbar } from 'notistack';



export default function AddMember({ projectId,prevProjectMembers,allTeamMembers,add,setAdd,setIsOpen,onRefresh, designOrder}) {
  //------------------------------------------------------------------------------------------
  const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    projectMembers: Yup.array().required('Select projectMembers'),
  });
  console.log('123==prevProjectMembers========',prevProjectMembers)
  console.log('123==allTeamMembers========',allTeamMembers)

  const filteredTeamMembers = allTeamMembers?.filter(
    (teamMember) => !prevProjectMembers?.some(
      (projectMember) => projectMember.staff_id === teamMember.id
    )
  );
  console.log('123==filteredTeamMembers========',filteredTeamMembers);
  //
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
        projectMembers: [],
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        // const  user  = JSON.parse(window.localStorage.getItem('user'))
        let apiUrl = '';
        if(designOrder){
          apiUrl = `design-order-job/add-member`
        }else{
          apiUrl = `project/add-project-member`
        }
          values.projectId = projectId
          axios.post(`${REST_API_END_POINT}${apiUrl}`,{ ...values },{
            headers: {
              'Content-Type': 'application/json',
            },
          }).then((response) => {
            if(response.data.status === 1){
              resetForm();
              setSubmitting(false);
              setAdd(false);
              setIsOpen(false);
              enqueueSnackbar(response.data.msg, { variant: 'success' });
            } else{
              enqueueSnackbar(response.data.msg, { variant: 'error' });
            }
          }).catch((error) => {
            console.error(error);
          })
          onRefresh(true);
      } catch (error) {
        console.error("Adding error - ", error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  return (

        <Modal
          open={add}
          handleClose={() => {
            setAdd(false);
          }}
          modalTitle={'Add Member'}
        >
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container justifyContent="center" >
                <Grid item xs={12} md={10}>
                  {/* <Card sx={{ p: 3 }}> */}
                  <Stack spacing={3}>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 3, sm: 2 }}
                    >
                      <Autocomplete
                        multiple
                        fullWidth
                        id="projectMembers"
                        options={filteredTeamMembers || []}
                        getOptionLabel={(state) => state.name}
                        onChange={(event, values) => {
                            const selectedIds = values.map((value) => {
                               return {id: value.id}
                            });
                            setFieldValue('projectMembers', selectedIds);
                        }}
                        // sx={{ width: 400 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Select Reporter"
                            error={Boolean(touched.projectMembers && errors.projectMembers)}
                            helperText={touched.projectMembers && errors.projectMembers}
                          />
                        )}
                      />
                    </Stack>
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                      <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        Save
                      </LoadingButton>
                      <Button variant="outlined" onClick={()=>{
                        setAdd(false)
                        // setIsEdit(false)
                        }}>
                        Cancel
                    </Button>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Modal>

  );
}
