import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid
} from '@material-ui/core';
// redux
import { useDispatch, useSelector } from 'src/redux/store.js';
import { getUserList, deleteUser } from 'src/redux/slices/user.js';
// import { UserMoreMenu } from 'src/components/_dashboard/user/list/index.js';

// routes
// components
import Page from 'src/components/Page.js';
import Label from 'src/components/Label.js'; 
import Scrollbar from 'src/components/Scrollbar.js';
import SearchNotFound from 'src/components/SearchNotFound.js';
import { UserListHead, UserListToolbar, UserMoreMenu } from 'src/components/_dashboard/user/list/index.js';
import  ProjectListViewToolbar  from 'src/components/_dashboard/user/list/ProjectListViewToolbar';
import  ProjectListMenu  from 'src/components/_dashboard/user/list/ProjectListMenu';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
import LoadingScreen from 'src/components/LoadingScreen';
import ConfirmationPopup from '../official-request/ConfirmationPopup';
import FullScreenDialogsAllJobs from 'src/pages/components-overview/material-ui/dialog/FullScreenDialogsAllJobs';


// ----------------------------------------------------------------------

  const option = [
    { id: 0, value: 'Inactive' },
    { id: 1, value: 'New' },
    { id: 2, value: 'Open' },
    { id: 3, value: 'Completed' },
    { id: 4, value: 'Hold' },
    { id: 5, value: 'Accepted' },
    { id: 6, value: 'Rejected' },
    { id: 7, value: 'Verified' },
    { id: 6, value: 'Reopen' },
  ]
  const colors = [
    'error',
    'primary',
    'secondary',
    'success',
    'warning',
    'success',
    'error',
    'success',
    'error',
  ]
  
  
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const onboard_data=[
    {
        id:0,
        status:'Pending',
        name:'Laffety',
        number:'123456789',
        address:'California',
        total:'23925.00 USD',
        createdon:'12/31/1969',
    },
    {
        id:1,
        status:'Pending',
        name:'K Hovnanian',
        number:'6532611678',
        address:'Manteca',
        total:'11990.00 USD',
        createdon:'12/31/1969',

    },
    {
        id:2,
        status:'Pending',
        name:'Chase Brendle',
        number:'1215165265',
        address:'San Francisco',
        total:'24480.00 USD',
        createdon:'12/31/1969',

    },
    {
        id:3,
        status:'Pending',
        name:'Jane Doe',
        number:'281728173',
        address:'Sedona',
        total:'68206.00 USD',
        createdon:'12/31/1969',

    },
    {
        id:4,
        status:'Pending',
        name:'Adam Sandler',
        number:'3152614141',
        address:'Miami',
        total:'14280.00 USD',
        createdon:'12/31/1969',

    },
    {
        id:5,
        status:'Pending',
        name:'Umila Garib',
        number:'2728738268',
        address:'West Palm Beach',
        total:'11990.00 USD',
        createdon:'12/31/1969',

    },
]

export default function ProjectList( {user,communityJob, currentTab, setRenderCards, renderCards, projectData, isLoading, setIsEdit, handleFetchEditData,projectId, setProjectId,openViewPage, setOpenViewPage,projectName,setProjectName,removePopupData}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const ProjectList = onboard_data; // Replaced ProjectList with onboard_data
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { enqueueSnackbar } = useSnackbar();
  const navigate=useNavigate()
  let userData = JSON.parse(localStorage.getItem('user'))
  const explodedPermissions =userData?.exploded_permissions
  const [isDelete,setIsDelete] = useState(false)
  const [deleteId,setDeleteId] = useState(null)
  const [openHoldPopup, setOpenHoldPopup] = useState(false);

  const TABLE_HEAD = [
    { id: 'image', label: 'Job Image', alignLeft: false },
    { id: 'id', label: 'Order ID', alignLeft: false },
    { id: 'name', label: 'Job Name', alignRight: false },
    { id: 'customer_name', label: 'Client', alignRight: false },
    { id: 'community_name', label: 'Project', alignRight: false },
    { id: 'createdby', label: 'Created By', alignRight: false },
    { id: 'members', label: 'Members', alignRight: false },
    // { id: 'city', label: 'City Name', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    ...(userData.user_type === 3
      ? []
      : [{ id: 'options', label: 'Options', alignRight: false }])      

  ];

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);

  // Rest of the code remains unchanged from here
  // ...
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = ProjectList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleDeleteTask = (status,id)=>{
    try {
      let newStatus = 0
      if ( status === 0 ){
        newStatus = 1
      }else if ( status === 2 ){
        newStatus = 2
      }else{
        newStatus = 0
      }
        
      // const  user  = JSON.parse(window.localStorage.getItem('user'))
      axios.post(`${REST_API_END_POINT}project/delete-project`,{ id: Number(id), status: newStatus },{
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if(response.data.status === 4 || response.data.status === 2 || response.data.status === 1){
          enqueueSnackbar(response.data.message, { variant: 'success' });
          setRenderCards(!renderCards)
          // navigate(PATH_DASHBOARD.project.viewproject+projectId)
        }else if(response.data.status === 5 || response.data.status === 3 || response.data.status === 0){
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
        setIsDelete(false)
        setDeleteId(null)
      }).catch((error) => {
        console.error(error);
      })
      }
      catch (error) {
        console.error("Error fetching data:", error);
      }
  }

  const handleUpdateHoldStatus = async (id) => {
    try {
      setOpenHoldPopup(false)
      const user = JSON.parse(window.localStorage.getItem('user'))
      const res = await axios.post(`${REST_API_END_POINT}project/update-job-hold-status`,
        { projectId: Number(id), userId: user.user_id }, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if (response.data.status === 1) {
          enqueueSnackbar(response.data.message, { variant: 'success' });
          setRenderCards(!renderCards)
        } else if (response.data.status === 0) {
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
        setDeleteId(null)
      });
    } catch (error) {
      console.error('Error Moving to Hold:', error);
      throw error;
    }
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleDeleteUser = (userId) => {
    dispatch(deleteUser(userId));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - projectData.length) : 0;

  const filteredUsers = applySortFilter(projectData, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  const handleClickOpenViewPage=(id,name)=>{
    setProjectId(id)
    setProjectName(name)
    setOpenViewPage(true)
    localStorage.setItem("openProductionPopup", "true");
    localStorage.setItem("projectId", id);
    localStorage.setItem("projectName", name);
    
    // setSearchParams({
    //   open: "true",
    //   // view: "viewPage",
    //   // projectId: id,
    //   // projectName: name,
    // });
  }
  return (
    <>
      <Grid container spacing={3}>
        {isLoading ? <LoadingScreen /> :
          <Grid item xs={12}>
            <Card>
              {/* <ProjectListViewToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
  
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={projectData.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {projectData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const status = Number(row.status)
                        const hold = Number(row.hold)
                        return (
                          <TableRow
                            hover
                            key={0}
                          >
                            <TableCell padding="checkbox">
                              {/* <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, name)} /> */}
                            </TableCell>
                            <TableCell align="center">
                              <img
                                src={row.cover_image || 'https://t3.ftcdn.net/jpg/04/60/44/08/360_F_460440876_a8lkfz8UHz64eDPFzmSJJZ55UQBNiC0a.jpg'}
                                style={{
                                  width: '90px',
                                  height: '70px',
                                  borderRadius: '5px',
                                  margin: '0 auto', // Center align the image
                                  display: 'block', // Ensure block-level display for margin to work
                                  objectFit: 'fill',
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">{row.request_id}</TableCell>
                            <TableCell align="center" sx={{cursor:'pointer'}} onClick={()=>{
                              if (communityJob) {
                                navigate(PATH_DASHBOARD.project.viewproject + row.id, { state: { fromCommunityJob: true } });
                              } else {
                                handleClickOpenViewPage(row.id, row.name);
                              }
                              // navigate(PATH_DASHBOARD.project.viewproject+row.id)}
                              // handleClickOpenViewPage(row.id,row.name)
                            }
                            }                            
                              >{row.name}
                              </TableCell>
                            <TableCell align="center">{row.customer_name}</TableCell>
                            <TableCell align="center">{row.community_name}</TableCell>
                            <TableCell align="center">{row.authorized_by}</TableCell>
                            <TableCell align="center">{row.member_count}</TableCell>
                            {/* <TableCell align="center">{row.city==='DefaultCity' ? '' : row.city}</TableCell> */}
                            {console.log('88888888888888888',row.status,option.find(row=>row.id===status))}
                            <TableCell align="center">
                            <Label
              variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                color={colors[status]} >
                {option.find(row=>row.id===status)?.value} 
              </Label>
                            </TableCell>
                            {userData.user_type == 3 ? null :
                            <TableCell align="center"> 
                            {currentTab!="Completed" && <UserMoreMenu hold={hold} text={status === 0 ? 'Activate' : null}
                            {...((currentTab!="Installations" && currentTab!="Completed" && currentTab!="Hold") ? {
                              onDeactivate: ()=>{
                                if(explodedPermissions?.some(permission => ['Activate/Deactivate Jobs'].includes(permission))){
                                  handleDeleteTask(status,row.id)
                                }else{
                                  enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                                }
                            }} : {})}
                            {...((currentTab!="Installations" && currentTab!="Completed") ? { onHold:()=>{
                              if(explodedPermissions?.some(permission => ['Activate/Deactivate Jobs'].includes(permission))){
                                setDeleteId(row.id)
                                setOpenHoldPopup(true);
                              }else{
                                enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' });
                              }
                            }} : {})}
                            onDelete={()=>{
                              if(explodedPermissions?.some(permission => ['Activate/Deactivate Jobs'].includes(permission))){
                                setIsDelete(true);
                                setDeleteId(row.id);
                              }else{
                                enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' });
                              }
                            }}
                            {...(status !== 3 ? { 
                              onEdit: () => {
                                if(explodedPermissions?.some(permission => ['Edit Jobs'].includes(permission))){
                                  setIsEdit(true);
                                  handleFetchEditData(row.id);
                                }else{
                                  enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                                }
                              }
                            } : {})} />}
                            </TableCell>}
                            <ConfirmationPopup
                              open={openHoldPopup}
                              handleClick={() => { handleUpdateHoldStatus(deleteId) }}
                              handleClose={() => { setOpenHoldPopup(false); setDeleteId(null) }}
                              message={hold === 1 ? `You want to move this Job from Hold` : `You want to move this Job to Hold`}
                            />
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
  
              <TablePagination
                rowsPerPageOptions={[10, 25,50,100]}
                component="div"
                count={projectData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
            <ConfirmationPopup
              open={isDelete}
              handleClick={()=>handleDeleteTask(2, deleteId)}
              handleClose={() => { setIsDelete(false); setDeleteId(null) }}
              message={'You want to delete this Job. This action will also permanently delete the associated Sales Order and any related data.'}
              // message={'You want to delete this Job. The related Offical Request will also get deleted.'}
            />
          </Grid>
        }
      </Grid>
      <FullScreenDialogsAllJobs
      initialFromCalender={"false"}
                  open={openViewPage}
                  setOpen={setOpenViewPage}
                  renderCards={renderCards}
                  handleClickOpen={handleClickOpenViewPage}
                  projectName={projectName}
                  removePopupData={removePopupData}
                  projectId={projectId}
                  setRenderCards={setRenderCards}
                  />
    </>
  );
}
