// import { forwardRef, useState, useEffect } from "react";
// // Material UI components
// import CloseIcon from "@material-ui/icons/Close";
// import {
//   Slide,
//   Dialog,
//   AppBar,
//   Toolbar,
//   IconButton,
//   Typography,
// } from "@material-ui/core";
// // React Router for query parameter management
// import { useSearchParams } from "react-router-dom";
// // Components
// import ViewProject from "src/pages/dashboard/ViewProject";
// import JobCost from "src/pages/dashboard/JobCost";
// import RecipeCost from "src/pages/dashboard/recipeCost";
// import ViewTask from "src/pages/dashboard/ViewTask";

// // Transition for Dialog
// const Transition = forwardRef((props, ref) => (
//   <Slide direction="down" ref={ref} {...props} />
// ));

// export default function FullScreenDialogsJobs({
//   open,
//   setOpen,
//   projectId:initialProjectId,
//   projectName:initialProjectName,
//   setRenderCards,
//   renderCards,
// }) {
//   // Query params for persistence
//   const [searchParams, setSearchParams] = useSearchParams();

//   // Local states to manage nested views
//   const [projectId, setProjectId] = useState(initialProjectId || "");
//   const [projectName, setProjectName] = useState(initialProjectName || "");
  
//   const [currentView, setCurrentView] = useState("viewPage");
//   const [recipeCostCurrentTab, setRecipeCostCurrentTab] = useState("");
//   const [recipeCostId, setRecipeCostId] = useState(searchParams.get("projectId") || "");
//   const [projectsId, setProjectsId] = useState("");
//   const [postId, setPostId] = useState("");
//   const [taskCurrentTab, setTaskCurrentTab] = useState("");
//   const [taskDetails, setTaskdetails] = useState("");
//   // const [projectIds, setProjectIds] = useState('');
//   console.log("recipeCost====recipeCostId",recipeCostId);
//   console.log("recipeCost====postId",postId);
//   console.log("recipeCost====projectsId",projectsId);
//   console.log("recipeCost====taskCurrentTab",taskCurrentTab);
//   console.log("recipeCost====recipeCostCurrentTab",recipeCostCurrentTab);
  
//   useEffect(() => {
//     if (!open && searchParams.get("open") === "true") {
//       setOpen(true);
//       setProjectId(searchParams.get("projectId") ||initialProjectId || "");
//       setProjectName(searchParams.get("projectName") || initialProjectName || "");
//       setCurrentView(searchParams.get("view") || "viewPage");
//     }
//   }, [searchParams, open, setOpen , initialProjectId, initialProjectName]);
//   useEffect(() => {
//     if (open) {
      
//       setSearchParams({
//         open: "true",
//         projectId: projectId || initialProjectId || "",
//         projectName: projectName || initialProjectName || "",
//         view: currentView,
//       });
//     } else {
//       setSearchParams({});
//     }
//   }, [open, projectId, projectName, currentView, setSearchParams]);

//   // const [viewPage, setViewPage] = useState(viewforView==='viewPage'||viewforView===null ||true);
//   // const [recipeCost, setRecipeCost] = useState(false);
//   // const [jobCost, setJobCost] = useState(false);
//   // const [task, setTask] = useState(false);


//   // Data states


//   // Handle dialog transitions with query parameters
//   // useEffect(() => {
//   //   const view = searchParams.get("view");
//   //   setViewPage(view === "viewPage" || true);
//   //   setRecipeCost(view === "recipeCost");
//   //   setJobCost(view === "jobCost");
//   //   setTask(view === "task");
//   // }, [searchParams]);

//   // Open specific views and update query parameters


//   // Handle dialog close
//   const handleClose = () => {
//     setOpen(false);
//     setSearchParams({}); // Clear query parameters
//     setRenderCards(!renderCards);
//   };
//   const handleViewChange = (view) => {
//     setCurrentView(view);
//     setSearchParams({
//       open: "true",
//       projectId,
//       projectName,
//       view,
//     });
//   };
//   const renderContent = () => {
//     switch (currentView) {
//       case "recipeCost":
//         return (
//           <RecipeCost
//           popupOn={true}
//           recipeCostId={recipeCostId}
//           closeView={handleClose}
//           handleBackToAllJobs={() => handleViewChange("viewPage")}
//           recipeCostCurrentTab={recipeCostCurrentTab}
//           handleClickOpenJobCost={() => handleViewChange("jobCost")}
//           setRecipeCostCurrentTab={setRecipeCostCurrentTab}
//           />
//         );
//       case "jobCost":
//         return (
//           <JobCost
//           popupOn={true}
//           jobCostId={recipeCostId}
//           handleClickOpenRecipeCost={() => handleViewChange("recipeCost")}
//           handleBackToAllJobs={() => handleViewChange("viewPage")}
//           setRecipeCostCurrentTab={setRecipeCostCurrentTab}
//           />
//         );
//       case "task":
//         return (
//           <ViewTask
//           popupOn={true}
//           postId={postId}
//           projectsId={projectsId}
//           taskCurrentTab={taskCurrentTab}
//           taskDetails={taskDetails}
//           handleBackToAllJobs={() => handleViewChange("viewPage")}
//           />
//         );
//       default:
//         return (
//           <ViewProject
//           popupOn={true}
//           setPostId={setPostId}
//           idOfProject={projectId}
//           setProjectsId={setProjectsId}
//           closeView={handleClose}
//           handleClickOpenRecipeCost={() => handleViewChange("recipeCost")}
//           handleinnerTask={() => handleViewChange("task")}
//           setRecipeCostId={setRecipeCostId}
//           setRecipeCostCurrentTab={setRecipeCostCurrentTab}
//           setRenderCardsMain={setRenderCards}
//           setTaskdetails={setTaskdetails}
//           setTaskCurrentTab={setTaskCurrentTab}
//           />
//         );
//     }
//   };
//   return (
//     <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
//       <AppBar position="relative" mb={3}>
//         <Toolbar>
//           <Typography variant="h6" sx={{ flex: 1, ml: 2 }}>
//             {projectName}
//           </Typography>
//           <CloseIcon onClick={handleClose} sx={{ fontSize: "2.7rem" }} />
//         </Toolbar>
//       </AppBar>
//       {renderContent()}
//       {/* Render views based on current state */}
//       {/* {viewPage ? (
//         <ViewProject
//           popupOn={true}
//           setPostId={setPostId}
//           idOfProject={projectId}
//           setProjectsId={setProjectsId}
//           closeView={handleClose}
//           handleClickOpenRecipeCost={handleClickOpenRecipeCost}
//           handleinnerTask={handleInnerTask}
//           setRecipeCostId={setRecipeCostId}
//           setRecipeCostCurrentTab={setRecipeCostCurrentTab}
//           setRenderCardsMain={setRenderCards}
//           setTaskdetails={setTaskdetails}
//           setTaskCurrentTab={setTaskCurrentTab}
//         />
//       ) : recipeCost ? (
//         <RecipeCost
//           popupOn={true}
//           recipeCostId={recipeCostId}
//           closeView={handleClose}
//           handleBackToAlljobs={handleBackToAllJobs}
//           recipeCostCurrentTab={recipeCostCurrentTab}
//           handleClickOpenJobCost={handleClickOpenJobCost}
//           setRecipeCostCurrentTab={setRecipeCostCurrentTab}
//         />
//       ) : jobCost ? (
//         <JobCost
//           popupOn={true}
//           jobCostId={recipeCostId}
//           handleClickOpenRecipeCost={handleClickOpenRecipeCost}
//           setRecipeCostCurrentTab={setRecipeCostCurrentTab}
//           handleBackToAlljobs={handleBackToAllJobs}
//         />
//       ) : task ? (
//         <ViewTask
//           popupOn={true}
//           postId={postId}
//           projectsId={projectsId}
//           taskCurrentTab={taskCurrentTab}
//           taskDetails={taskDetails}
//           handleBackToAlljobs={handleBackToAllJobs}
//         />
//       ) : (
//         ""
//       )} */}
//     </Dialog>
//   );
// }

// import React, { forwardRef, useState, useEffect } from "react";
// // Material UI components
// import CloseIcon from "@material-ui/icons/Close";
// import {
//   Slide,
//   Dialog,
//   AppBar,
//   Toolbar,
//   Typography,
// } from "@material-ui/core";
// // React Router for query parameter management
// import { useSearchParams } from "react-router-dom";
// // Components
// import ViewProject from "src/pages/dashboard/ViewProject";
// import JobCost from "src/pages/dashboard/JobCost";
// import RecipeCost from "src/pages/dashboard/recipeCost";
// import ViewTask from "src/pages/dashboard/ViewTask";

// // Transition for Dialog
// const Transition = forwardRef((props, ref) => (
//   <Slide direction="down" ref={ref} {...props} />
// ));

// // Memoized Components
// const MemoizedViewProject = React.memo(ViewProject);
// const MemoizedRecipeCost = React.memo(RecipeCost);
// const MemoizedJobCost = React.memo(JobCost);
// const MemoizedViewTask = React.memo(ViewTask);

// function FullScreenDialogsJobs({
//   open,
//   setOpen,
//   projectId: initialProjectId,
//   projectName: initialProjectName,
//   setRenderCards,
//   renderCards,
// }) {
//   // Query params for persistence
//   const [searchParams, setSearchParams] = useSearchParams();

//   // Local states to manage nested views
//   const [projectId, setProjectId] = useState(initialProjectId || "");
//   const [projectName, setProjectName] = useState(initialProjectName || "");
//   const [currentView, setCurrentView] = useState("viewPage");
//   const [recipeCostCurrentTab, setRecipeCostCurrentTab] = useState("");
//   const [recipeCostId, setRecipeCostId] = useState(searchParams.get("projectId") || "");
//   const [projectsId, setProjectsId] = useState("");
//   const [postId, setPostId] = useState("");
//   const [taskCurrentTab, setTaskCurrentTab] = useState("");
//   const [taskDetails, setTaskdetails] = useState("");

//   // Initialize states based on query params
//   useEffect(() => {
//     if (!open && searchParams.get("open") === "true") {
//       setOpen(true);
//       setProjectId(searchParams.get("projectId") || "");
//       setProjectName(searchParams.get("projectName") || "");
//       setCurrentView(searchParams.get("view") || "viewPage");
//     }
//   }, [searchParams, open, setOpen]);

//   // Sync query parameters with state
//   useEffect(() => {
//     if (open) {
//       setSearchParams({
//         open: "true",
//         projectId:projectId ||initialProjectId,
//         projectName:projectName||initialProjectName,
//         view: currentView,
//       });
//     } else {
//       setSearchParams({});
//     }
//   }, [open, projectId, projectName, currentView, setSearchParams]);

//   const handleClose = () => {
//     setOpen(false);
//     setSearchParams({}); // Clear query parameters
//     setRenderCards(!renderCards);
//   };

//   const handleViewChange = (view) => {
//     setCurrentView(view);
//     setSearchParams({
//       open: "true",
//       projectId,
//       projectName,
//       view,
//     });
//   };

//   const renderContent = () => {
//     switch (currentView) {
//       case "recipeCost":
//         return (
//           <MemoizedRecipeCost
//             popupOn={true}
//             recipeCostId={recipeCostId}
//             closeView={handleClose}
//             handleBackToAllJobs={() => handleViewChange("viewPage")}
//             recipeCostCurrentTab={recipeCostCurrentTab}
//             handleClickOpenJobCost={() => handleViewChange("jobCost")}
//             setRecipeCostCurrentTab={setRecipeCostCurrentTab}
//           />
//         );
//       case "jobCost":
//         return (
//           <MemoizedJobCost
//             popupOn={true}
//             jobCostId={recipeCostId}
//             handleClickOpenRecipeCost={() => handleViewChange("recipeCost")}
//             handleBackToAllJobs={() => handleViewChange("viewPage")}
//             setRecipeCostCurrentTab={setRecipeCostCurrentTab}
//           />
//         );
//       case "task":
//         return (
//           <MemoizedViewTask
//             popupOn={true}
//             postId={postId}
//             projectsId={projectsId}
//             taskCurrentTab={taskCurrentTab}
//             taskDetails={taskDetails}
//             handleBackToAllJobs={() => handleViewChange("viewPage")}
//           />
//         );
//       default:
//         return (
//           <MemoizedViewProject
//             popupOn={true}
//             setPostId={setPostId}
//             idOfProject={projectId}
//             setProjectsId={setProjectsId}
//             closeView={handleClose}
//             handleClickOpenRecipeCost={() => handleViewChange("recipeCost")}
//             handleinnerTask={() => handleViewChange("task")}
//             setRecipeCostId={setRecipeCostId}
//             setRecipeCostCurrentTab={setRecipeCostCurrentTab}
//             setRenderCardsMain={setRenderCards}
//             setTaskdetails={setTaskdetails}
//             setTaskCurrentTab={setTaskCurrentTab}
//           />
//         );
//     }
//   };

//   return (
//     <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
//       <AppBar position="relative" mb={3}>
//         <Toolbar>
//           <Typography variant="h6" sx={{ flex: 1, ml: 2 }}>
//             {projectName}
//           </Typography>
//           <CloseIcon onClick={handleClose} sx={{ fontSize: "2.7rem" }} />
//         </Toolbar>
//       </AppBar>
//       {renderContent()}
//     </Dialog>
//   );
// }

// export default React.memo(FullScreenDialogsJobs);


import React, { forwardRef, useState, useEffect } from "react";
// Material UI components
import CloseIcon from "@material-ui/icons/Close";
import {
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
} from "@material-ui/core";
// Components
import ViewProject from "src/pages/dashboard/ViewProject";
import JobCost from "src/pages/dashboard/JobCost";
import RecipeCost from "src/pages/dashboard/recipeCost";
import ViewTask from "src/pages/dashboard/ViewTask";

// Transition for Dialog
const Transition = forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

// Memoized Components
const MemoizedViewProject = React.memo(ViewProject);
const MemoizedRecipeCost = React.memo(RecipeCost);
const MemoizedJobCost = React.memo(JobCost);
const MemoizedViewTask = React.memo(ViewTask);

function FullScreenDialogsJobs({
  open,
  setOpen,
  removePopupData,
  projectId: initialProjectId,
  projectName: initialProjectName,
  setRenderCards,
  renderCards,
  initialFromCalender
}) {
  // Local states for dialog content
  const [projectId, setProjectId] = useState(
    initialProjectId ||localStorage.getItem("projectId") ||  ""
  );
  const [projectName, setProjectName] = useState(
    initialProjectName || localStorage.getItem("projectName") ||  ""
  );
  const [currentView, setCurrentView] = useState(
    localStorage.getItem("view") || "viewPage"
  );
  const [recipeCostCurrentTab, setRecipeCostCurrentTab] = useState(
    localStorage.getItem("recipeCostCurrentTab") || ""
  );
  const [recipeCostId, setRecipeCostId] = useState(
    localStorage.getItem("recipeCostId") || ""
  );
  const [projectsId, setProjectsId] = useState(
    localStorage.getItem("projectsId") || ""
  );
  const [postId, setPostId] = useState(localStorage.getItem("postId") || "");
  const [taskCurrentTab, setTaskCurrentTab] = useState(
    localStorage.getItem("taskCurrentTab") || ""
  );
  const [taskDetails, setTaskdetails] = useState(
    localStorage.getItem("taskDetails") || ""
  );
  const tempVariable=localStorage.getItem('jobsFromCalender')

const [jobsFromCalender, setJobsFromCalender] = useState(
  tempVariable || initialFromCalender || false
);
  // Save states to localStorage when they change

  useEffect(() => {
   if(open){  
    localStorage.setItem("projectId", projectId);
    localStorage.setItem("projectName", projectName);
    localStorage.setItem("view", currentView);
    localStorage.setItem("recipeCostCurrentTab", recipeCostCurrentTab);
    localStorage.setItem("recipeCostId", recipeCostId);
    localStorage.setItem("projectsId", projectsId);
    localStorage.setItem("postId", postId);
    localStorage.setItem("taskCurrentTab", taskCurrentTab);
    localStorage.setItem("jobsFromCalender", jobsFromCalender);
    localStorage.setItem("taskDetails", taskDetails);}
    else{
      localStorage.removeItem("openProductionPopup");
      // localStorage.setItem("openProductionPopup", "false");
    }
  }, [open,
    projectId,
    projectName,
    currentView,
    recipeCostCurrentTab,
    recipeCostId,
    projectsId,
    postId,
    taskCurrentTab,
    taskDetails,
    jobsFromCalender
  ]);

  // Restore state from localStorage on initial load
  useEffect(() => {
    if (open) {
      console.log("projectId===",initialProjectId);
      setProjectId(localStorage.getItem("projectId") ||initialProjectId || "");
      setProjectName(localStorage.getItem("projectName") || initialProjectName || "");
      setCurrentView(localStorage.getItem("view") || "viewPage");
      setJobsFromCalender(localStorage.getItem("jobsFromCalender") || initialFromCalender||false);
    }
  }, [open]);
  const [dataChanged, setDataChanged] = useState(false); 
  const handleClose = () => {
    
    setProjectId("");
    setProjectName("");
    setJobsFromCalender("");
    setCurrentView("");
    localStorage.removeItem("projectId");
    localStorage.removeItem("projectName");
    localStorage.removeItem("view");
    localStorage.removeItem("jobsFromCalender");
    localStorage.removeItem("openProductionPopup");
    setOpen(false);
    if (dataChanged) {
      setRenderCards(!renderCards);
      setDataChanged(false); // Reset the flag after triggering the re-render
    }
    removePopupData()
  };
  const handleDataChange = () => {
    setDataChanged(true); 
    console.log('Entered=handleDataChange')
  };

  const handleViewChange = (view) => {
    setCurrentView(view);
   
  };
  // useEffect(() => {
  //   const handlePopState = () => {
  //     if (open) {
  //       handleClose(); // Close popup when back button is pressed
  //     }
  //   };

  //   if (open) {
  //     // Add a new history state only when the popup opens
  //     window.history.pushState(null, document.title);
  //   }

  //   // Add event listener for 'popstate'
  //   window.addEventListener("popstate", handlePopState);

  //   return () => {
  //     window.removeEventListener("popstate", handlePopState);

  //     // Remove the current history entry only if the popup was open
  //     if (open) {
  //       window.history.go(-1);
  //     }
  //   };
  // }, [open, handleClose]);
  const renderContent = () => {
    switch (currentView) {
      case "recipeCost":
        return (
          <MemoizedRecipeCost
            popupOn={true}
            recipeCostId={recipeCostId}
            closeView={handleClose}
            handleBackToAlljobs={() => handleViewChange("viewPage")}
            recipeCostCurrentTab={recipeCostCurrentTab}
            handleClickOpenJobCost={() => handleViewChange("jobCost")}
            setRecipeCostCurrentTab={setRecipeCostCurrentTab}
            onDataChange={handleDataChange}
          />
        );
      case "jobCost":
        return (
          <MemoizedJobCost
            popupOn={true}
            jobCostId={recipeCostId}
            handleClickOpenRecipeCost={() => handleViewChange("recipeCost")}
            handleBackToAlljobs={() => handleViewChange("viewPage")}
            setRecipeCostCurrentTab={setRecipeCostCurrentTab}
            onDataChange={handleDataChange}
          />
        );
      case "task":
        return (
          <MemoizedViewTask
            popupOn={true}
            postId={postId}
            projectsId={projectsId}
            taskCurrentTab={taskCurrentTab}
            taskDetails={taskDetails}
            handleBackToAlljobs={() => handleViewChange("viewPage")}
            onDataChange={handleDataChange}
          />
        );
      default:
        return (
          <MemoizedViewProject
          fromCalender={jobsFromCalender}
            popupOn={true}
            setPostId={setPostId}
            idOfProject={projectId}
            setProjectsId={setProjectsId}
            closeView={handleClose}
            handleClickOpenRecipeCost={() => handleViewChange("recipeCost")}
            handleinnerTask={() => handleViewChange("task")}
            setRecipeCostId={setRecipeCostId}
            setRecipeCostCurrentTab={setRecipeCostCurrentTab}
            setRenderCardsMain={setRenderCards}
            setTaskdetails={setTaskdetails}
            setTaskCurrentTab={setTaskCurrentTab}
            onDataChange={handleDataChange}
          />
        );
    }
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar position="relative" mb={3}>
        <Toolbar>
          <Typography variant="h6" sx={{ flex: 1, ml: 2 }}>
            {projectName}
          </Typography>
          <CloseIcon onClick={handleClose} sx={{ fontSize: "2.7rem" }} />
        </Toolbar>
      </AppBar>
      {renderContent()}
    </Dialog>
  );
}

export default React.memo(FullScreenDialogsJobs);