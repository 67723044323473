import { useRef, useState } from 'react'
import {
  Card,
  Typography,
  Stack,
  ImageList,
  ImageListItem,
  ListSubheader,
  ImageListItemBar,
  Box,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Badge,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ThumbUpAltOutlined, ThumbDownOffAltOutlined, LocationOn } from '@material-ui/icons'
import { sentenceCase } from 'change-case'
import moment from 'moment'
import Scrollbar from 'src/components/Scrollbar'
import {
  ref as imageref,
  uploadBytesResumable,
  getDownloadURL,
} from '@firebase/storage'
import { storage } from 'src/firebase/firebase'
import { REST_API_END_POINT } from 'src/constants/Defaultvalues'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import { LoadingButton } from '@material-ui/lab'
import { UserMoreMenu } from '../user/list'
import Label from 'src/components/Label'
import EditViewTemplate from 'src/components/installation/EditViewTemplate'
import { Link as RouterLink, useParams, useNavigate, Link } from 'react-router-dom';

export default function SalesOrderInstallationReportView({
  products,
  designs,
  isFullWidth,
  details,
  additionalSelectedServices,
  rfpServices
}) {
  console.log('rfpServicesrfpServices--products',products)
  console.log('rfpServicesrfpServices--additionalSelectedServices',additionalSelectedServices)
  console.log('rfpServicesrfpServices--rfpServices',rfpServices)
  const theme = useTheme()
  const [templateData, setTemplateData] = useState([])
  const [openEdit, setOpenEdit] = useState(false)
  const [operation, setOperation] = useState('view_template')
  const [projectData, setProjectData] = useState(null);
  
  const fetchTemplateData = async (projectData) => {
    try {
      if (projectData) {
        axios.post(`${REST_API_END_POINT}installation/get-template-data`, { id: projectData?.installation_temp_id, inst_id: projectData?.installation_id })
          .then((res) => {
            setTemplateData(res.data?.data)
          })
          .catch((error) => {
            console.log(error)
            setTemplateData([])
          })
      }
    } catch (error) {
      console.log(error)
      setTemplateData([])
    }
  }


  return (
    <Card  sx={{...(isFullWidth ? {width:'100%'} : {})}}>
      <Stack sx={{ m: 3 }} spacing={3}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          spacing={{ xs: 2, sm: 3 }}
        >
          <Typography variant="h4" sx={{ color: 'text.secondary' }}>Installation Reports</Typography>
        </Stack>
        <Divider/>
        {(products?.filter(item=>item.material == 0)?.length > 0) ? (
          <Box sx={{ px: 3 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell align='center' >Report</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products?.filter(item=>item.material == 0)?.map((item, index) => (
                    <>
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="h6" sx={{ color: 'text.secondary', ml:2 }}>
                          {item.product_name}
                        </Typography>
                      </TableCell>
                      <TableCell align='center'>
                        {item?.published_status == 1 ?
                            <Link
                            color={theme.palette.primary.main}
                            component={RouterLink}
                            to={`#`} // replace `#` with your target route
                            onClick={() => {
                                setOpenEdit(!openEdit);
                                setOperation('view_template');
                                fetchTemplateData(item);
                                setProjectData(item);
                            }}
                            >
                            {item?.installation_temp_name}
                            </Link>
                        : 
                            <Typography variant="caption" sx={{ color: theme.palette.text.disabled }}>
                                Report not published
                            </Typography>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Divider />
                      </TableCell>
                    </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          ''
        )}
        {(details?.job_category_name=='Service only' || details?.job_category_name=='Time & Material') && 
            (additionalSelectedServices?.length > 0) ? (
                <Box sx={{ px: 3 }}>
                    <TableContainer>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell>Service</TableCell>
                            <TableCell align='center'>Report</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {additionalSelectedServices?.map((item, index) => (
                            <>
                            <TableRow key={index}>
                            <TableCell>
                                <Typography variant="h6" sx={{ color: 'text.secondary', ml:2 }}>
                                {item.name}
                                </Typography>
                            </TableCell>
                            <TableCell align='center'>
                                {item?.published_status == 1 ?
                                    <Link
                                        color={theme.palette.primary.main}
                                        component={RouterLink}
                                        to={`#`}
                                        onClick={() => {
                                            setOpenEdit(!openEdit);
                                            setOperation('view_template');
                                            fetchTemplateData(item);
                                        }}
                                    >
                                        {item?.installation_temp_name}
                                    </Link> : 
                                    <Typography variant="caption" sx={{ color: theme.palette.text.disabled }}>
                                        Report not published
                                    </Typography>
                                }
                            </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell colSpan={5}>
                                <Divider />
                            </TableCell>
                            </TableRow>
                            </>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>
                </Box>
            ) : ( 
                '' 
            )
        }
      </Stack>
        {openEdit && <EditViewTemplate
            openEdit={openEdit}
            setOpenEdit={setOpenEdit}
            templateData={templateData}
            label={operation}
            fetchTemplateData={fetchTemplateData}
            instId={projectData?.installation_id}
        />}
    </Card>
  )
}
