import PropTypes from 'prop-types';
import { findIndex } from 'lodash';
import { useEffect, useState, useRef } from 'react';
//
import LightboxModal from 'src/components/LightboxModal';
import Scrollbar from 'src/components/Scrollbar';
import ChatMessageItemSignWise from './ChatMessageItemSignWise';

// ----------------------------------------------------------------------

ChatMessageListSignWise.propTypes = {
  messages: PropTypes.object.isRequired
};

export default function ChatMessageListSignWise({ messages, setMessages, isTyping, typingUser }) {
  const scrollRef = useRef();
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  let user = JSON.parse(localStorage.getItem('user'))
  console.log('handleTypingMessage---isTyping----',isTyping)
  console.log('handleTypingMessage---typingUser----',typingUser)
  console.log('handleTypingMessage---user----',user)
  console.log('handleTypingMessage---messages-2---',messages)

  const handleTypingMessage = () => {
    if (isTyping && (user?.user_id !== typingUser?.user_id)) {
      const alreadyTyping = messages.some(
        (msg) => msg.isTyping && msg.sender === typingUser?.first_name
      );
    
      if (!alreadyTyping) {
        setMessages([
          ...messages,
          {
            id: messages.length + 1,
            sender: typingUser?.first_name,
            text: 'typing...',
            type: 'received',
            isTyping: true,
            profile_pic: typingUser.profile_pic,
          },
        ]);
      }
    } else {
      const filteredMessages = messages.filter((msg) => !msg.isTyping);
      setMessages(filteredMessages);
    }    
  };

  useEffect(()=>{
    handleTypingMessage()
  },[isTyping,typingUser])

  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    scrollMessagesToBottom();
  }, [messages]);

  const images = messages
    .filter((messages) => messages?.contentType === 'image')
    .map((messages) => messages?.attachment);

  // const handleOpenLightbox = (url) => {
  //   const selectedImage = findIndex(images, (index) => index === url);
  //   setOpenLightbox(true);
  //   setSelectedImage(selectedImage);
  // };
  const handleOpenLightbox = (url) => {
    setSelectedImage(images.indexOf(url));
    setOpenLightbox(true);
  };
  return (
    <Scrollbar scrollableNodeProps={{ ref: scrollRef }} sx={{ p: 3, flexGrow: 1 }}>
      {messages?.map((message) => (
        <ChatMessageItemSignWise
          key={message.id}
          message={message}
          onOpenLightbox={handleOpenLightbox}
        />
      ))}

      <LightboxModal
        images={images}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onClose={() => setOpenLightbox(false)}
      />
    </Scrollbar>
  );
}
