import 'emoji-mart/css/emoji-mart.css'
import PropTypes from 'prop-types'
import { Picker } from 'emoji-mart'
import { Icon } from '@iconify/react'
import { useEffect, useState } from 'react'
import smilingFaceFill from '@iconify/icons-eva/smiling-face-fill'
import { Tabs, Tab, Box, Typography, InputBase } from '@mui/material'
import { useTheme, styled } from '@mui/material/styles'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { IconButton } from '@mui/material'
import axios from 'axios'
import { ChatGifApiKey } from 'src/constants/Defaultvalues'

const RootStyle = styled('div')({
  position: 'relative',
  display: 'inline-block',
})

const PickerWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '200%',
  right: 0,
  zIndex: 999,
  minHeight: 250,
  maxHeight: 250,
  width: 325,
  backgroundColor: '#1e1e1e',
  boxShadow: theme.shadows[5],
  borderRadius: 10,
  overflow: 'hidden',
  transform: 'translateY(8px)',
  transition: 'opacity 0.2s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #444',
}))

const ContentBox = styled(Box)({
  flex: 1,
  maxHeight: 250,
  padding: '0px 0px 10px 0px',
  width: 325,
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

export default function EmojiPickerChatSignWise({
  disabled,
  value,
  setValue,
  alignRight = false,
  onSend,
}) {
  const [emojiPickerState, setEmojiPickerState] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const [gifSearch, setGifSearch] = useState('')
  const [emojiSearch, setEmojiSearch] = useState('')
  const [liveEmojis, setLiveEmojis] = useState([])
  const [gifs, setGifs] = useState([])

  console.log('liveEmojis---',liveEmojis)

  const fetchGifs = async () => {
    try {
      const lmt = 24;
      const search_term = gifSearch ? gifSearch : "trending";
      const search_url = `https://tenor.googleapis.com/v2/search?q=${encodeURIComponent(search_term)}&key=${ChatGifApiKey}&limit=${lmt}`;
      const res = await axios.get(search_url);
      setGifs(res.data.results);
    } catch (error) {
      console.error('Failed to load GIFs', error);
    }
  };

  useEffect(()=>{
    fetchGifs();
  },[gifSearch])

  useEffect(()=>{
    fetchLiveEmojis();
  },[emojiSearch])
  
  // const fetchLiveEmojis = async () => {
  //   try {

  //     const apikey = "AIzaSyBFaHxqfZL4xpwtA5WnSqXmrUvCvJ2bFzc";
  //     const lmt = 24;
  //     const search_term = emojiSearch ? emojiSearch : "trending";
  //     const search_url = `https://tenor.googleapis.com/v2/search?q=${encodeURIComponent(search_term)}&key=${apikey}&limit=${lmt}&media_filter=sticker&contentfilter=high`;
  //     // const search_url = `https://tenor.googleapis.com/v2/search?q=${encodeURIComponent(search_term)}&key=${apikey}&limit=${lmt}`;
  //     const res = await axios.get(search_url);
  //     setLiveEmojis(res.data.results);
  //   } catch (error) {
  //     console.error('Failed to load Live Emojis')
  //   }
  // }

  const fetchLiveEmojis = async () => {
    try {
      const lmt = 50;
      const search_term = emojiSearch ? emojiSearch : "emoji";
  
      const search_url = `https://tenor.googleapis.com/v2/search?q=${encodeURIComponent(search_term)}&key=${ChatGifApiKey}&limit=${lmt}`;
  
      const res = await axios.get(search_url);
  
      console.log("API Response:", res.data);

      const emojiStickers = res.data.results.map((item) => ({
        id: item.id,
        description: item.content_description,
        gifUrl: item.media_formats?.gif?.url || null,
        webpUrl: item.media_formats?.webp?.url || null,
        mp4Url: item.media_formats?.mp4?.url || null,
        fallbackUrl: item.url,
      }));
  
      setLiveEmojis(emojiStickers);
    } catch (error) {
      console.error("Failed to load Live Emoji Stickers", error);
    }
  };
  

  const triggerPicker = (e) => {
    e.preventDefault()
    setEmojiPickerState(!emojiPickerState)
  }
  const clearSearch = () => {
    setGifSearch('');
    setEmojiSearch('');
    setTabIndex(0)
  };
  const handleClickAway = () => {
    setEmojiPickerState(false)
    clearSearch();
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <RootStyle>
        {emojiPickerState && (
          <PickerWrapper sx={{ ...(alignRight && { right: 0, left: 'auto' }) }}>
            <Tabs
              value={tabIndex}
              onChange={(e, newValue) => {
                setTabIndex(newValue)
                if (newValue === 1) fetchGifs()
                if (newValue === 2) fetchLiveEmojis()
              }}
              variant="fullWidth"
              sx={{ backgroundColor: '#161c24' }}
            >
              <Tab label="😀 Emojis" />
              <Tab label="🎥 GIFs" />
              <Tab label="😉 Live" />
            </Tabs>
            <ContentBox>
              {tabIndex === 0 ? (
                <Picker
                  set="apple"
                  title=""
                  emoji="point_up"
                  emojiTooltip={true}
                  showPreview={false}
                  perLine={9}
                  sheetSize={32}
                  showSkinTones={false}
                  showCategories={false}
                  theme="dark"
                  color="#ffcc00"
                  onSelect={(emoji) => {
                    if (emoji.unified.includes('-')) {
                      const flagEmoji = emoji.unified
                        .split('-')
                        .map((code) => String.fromCodePoint(parseInt(code, 16)))
                        .join('')
                      setValue(value + flagEmoji)
                    } else {
                      setValue(value + emoji.native)
                    }
                  }}
                  emojiSize={32}
                  style={{
                    backgroundColor: '#161c24',
                    color: '#fff',
                    border: 'none',
                    width: '100%',
                  }}
                  emojiButtonProps={{
                    style: { display: 'flex', justifyContent: 'center' },
                  }}
                />
              ) : tabIndex === 1 ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '10px',
                    backgroundColor: '#161c24',
                  }}
                >
                  <InputBase
                    fullWidth
                    placeholder="Search GIFs..."
                    value={gifSearch}
                    onChange={(e) => setGifSearch(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') fetchGifs()
                    }}
                    sx={{
                      paddingLeft: '5px',
                      marginBottom: '10px',
                      borderRadius: '5px',
                      backgroundColor: '#333',
                      color: '#fff',
                      fontSize:'14px'
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      maxHeight: '250px',
                      overflowY: 'auto',
                      justifyContent: 'center',
                      alignItems: 'center',
                      scrollbarWidth: 'none',
                      '&::-webkit-scrollbar': {
                        display: 'none',
                      },
                    }}
                  >
                    {gifs.length > 0 ? (
                      gifs.map((gif) => (
                        <img
                          key={gif?.id}
                          src={gif?.media_formats?.gif.url} 
                          // src={gif.url}
                          alt="gif"
                          style={{
                            width: '80px',
                            height: '80px',
                            cursor: 'pointer',
                            margin: '5px',
                            borderRadius: '5px',
                          }}
                          onClick={() => {
                            if (onSend) {
                              onSend(
                                `<img src="${gif.media_formats.gif.url}" alt="gif" style="max-width: 100%; height: auto;"/>`,
                                gif.url,
                              )
                            }
                            setTimeout(() => setEmojiPickerState(false), 100)
                          }}
                        />
                      ))
                    ) : (
                      <Typography
                        variant="body2"
                        sx={{
                          color: '#bbb',
                          fontSize: '14px',
                          textAlign: 'center',
                          width: '100%',
                          paddingTop: '20px',
                        }}
                      >
                        No GIFs available 😔
                      </Typography>
                    )}
                  </Box>
                </Box>
              ) : tabIndex === 2 ? (
                // Live Emojis Tab
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '10px',
                  backgroundColor: '#161c24',
                }}>
                {/* <InputBase
                    fullWidth
                    placeholder="Search GIFs..."
                    value={emojiSearch}
                    onChange={(e) => setEmojiSearch(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') fetchLiveEmojis()
                    }}
                    sx={{
                      padding: '5px',
                      marginBottom: '10px',
                      borderRadius: '5px',
                      backgroundColor: '#333',
                      color: '#fff',
                    }}
                  /> */}
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    maxHeight: '250px',
                    overflowY: 'auto',
                    justifyContent: 'center',
                    alignItems: 'center',
                    scrollbarWidth: 'none',
                      '&::-webkit-scrollbar': {
                        display: 'none',
                      },
                  }}
                >
                  {liveEmojis.length > 0 ? (
                    liveEmojis.map((emoji) => (
                      <img
                        key={emoji?.id}
                        src={emoji?.webpUrl || emoji?.gifUrl || emoji?.mp4Url || emoji?.url}
                        // key={emoji.id}
                        // src={emoji?.url} 
                        alt="live emoji"
                        style={{
                          width: '80px',
                          height: '80px',
                          cursor: 'pointer',
                          margin: '5px',
                          borderRadius: '10px',
                        }}
                        onClick={() => {
                          if (onSend) {
                            onSend(
                              `<img src="${emoji?.webpUrl || emoji?.gifUrl || emoji?.mp4Url || emoji?.url}" alt="live emoji" style="max-width: 100%; height: auto;"/>`,
                              emoji?.webpUrl || emoji?.gifUrl || emoji?.mp4Url || emoji?.url
                            );
                          }
                          setTimeout(() => setEmojiPickerState(false), 100);
                        }}
                      />
                    ))
                  ) : (
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#bbb',
                        fontSize: '14px',
                        textAlign: 'center',
                        width: '100%',
                        paddingTop: '20px',
                      }}
                    >
                      No Live Emojis available 😔
                    </Typography>
                  )}
                </Box>
                </Box>
              ) : null}
            </ContentBox>
          </PickerWrapper>
        )}

        <IconButton disabled={disabled} size="small" onClick={triggerPicker}>
          <Icon icon={smilingFaceFill} width={20} height={20} />
        </IconButton>
      </RootStyle>
    </ClickAwayListener>
  )
}

EmojiPickerChatSignWise.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  setValue: PropTypes.func,
  alignRight: PropTypes.bool,
}
