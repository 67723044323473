import Logo from '../../src/Gif/1721042180148Logo-With-White-BG.png'
export const GOOGLE_API_KEY = "AIzaSyB0VFLrPEaYGqP4FVdGDEeUUN6Ks9AZPao"; 
export const defaultZipCode = '85122'; 
export const ChatGifApiKey = 'AIzaSyBFaHxqfZL4xpwtA5WnSqXmrUvCvJ2bFzc'
export const SEND_EMAIL = false

// export const GOOGLE_API_KEY = "AIzaSyBe6vrykAsiKjwIHWOUcfH1Li4VT-2LHTg";

// export const REST_API_END_POINT = "http://localhost:4000/webservice/";
// export const REST_API = "http://localhost:4000/";
// export const REST_API_IO = "http://localhost:4000";
// export const BASE_URL = "http://localhost:3000/";

// export const REST_API_END_POINT = "https://fv4t30mq-4000.inc1.devtunnels.ms/webservice/";
// export const REST_API = "https://fv4t30mq-4000.inc1.devtunnels.ms/";
// export const REST_API_IO = "https://fv4t30mq-4000.inc1.devtunnels.ms";
// export const BASE_URL = "https://fv4t30mq-3000.inc1.devtunnels.ms/";

// arizona.signwisesolutions.com
// export const BASE_URL = "https://arizona.signwisesolutions.com/"
// export const REST_API_END_POINT = "https://arizona.signwisesolutions.com/backend/webservice/";
// export const REST_API = "https://arizona.signwisesolutions.com/backend/";
// export const REST_API_IO = "wss://arizona.signwisesolutions.com/backend";

// oklahoma.signwisesolutions.com/
// export const BASE_URL = "https://oklahoma.signwisesolutions.com/"
// export const REST_API_END_POINT = "https://oklahoma.signwisesolutions.com/backend/webservice/";
// export const REST_API = "https://oklahoma.signwisesolutions.com/backend/";
// export const REST_API_IO = "https://oklahoma.signwisesolutions.com/backend";

// riverside.signwisesolutions.com/
// export const BASE_URL = "https://riverside.signwisesolutions.com/"
// export const REST_API_END_POINT = "https://riverside.signwisesolutions.com/backend/webservice/";
// export const REST_API = "https://riverside.signwisesolutions.com/backend/";
// export const REST_API_IO = "https://riverside.signwisesolutions.com/backend";

//la.signwisesolutions.com/
// export const BASE_URL = "https://la.signwisesolutions.com/"
// export const REST_API_END_POINT = "https://la.signwisesolutions.com/backend/webservice/";
// export const REST_API = "https://la.signwisesolutions.com/backend/";
// export const REST_API_IO = "https://la.signwisesolutions.com/backend";

// //manteca.signwisesolutions.com/
// export const BASE_URL = "https://manteca.signwisesolutions.com/"
// export const REST_API_END_POINT = "https://manteca.signwisesolutions.com/backend/webservice/";
// export const REST_API = "https://manteca.signwisesolutions.com/backend/";
// export const REST_API_IO = "https://manteca.signwisesolutions.com/backend";

//denver.signwisesolutions.com/
// export const BASE_URL = "https://denver.signwisesolutions.com/"
// export const REST_API_END_POINT = "https://denver.signwisesolutions.com/backend/webservice/";
// export const REST_API = "https://denver.signwisesolutions.com/backend/";
// export const REST_API_IO = "https://denver.signwisesolutions.com/backend";

//cosprings.signwisesolutions.com/
// export const BASE_URL = "https://cosprings.signwisesolutions.com/"
// export const REST_API_END_POINT = "https://cosprings.signwisesolutions.com/backend/webservice/";
// export const REST_API = "https://cosprings.signwisesolutions.com/backend/";
// export const REST_API_IO = "https://cosprings.signwisesolutions.com/backend";

//lasvegas.signwisesolutions.com/
// export const BASE_URL = "https://lasvegas.signwisesolutions.com/"
// export const REST_API_END_POINT = "https://lasvegas.signwisesolutions.com/backend/webservice/";
// export const REST_API = "https://lasvegas.signwisesolutions.com/backend/";
// export const REST_API_IO = "https://lasvegas.signwisesolutions.com/backend";


const user =  JSON.parse(localStorage.getItem('user'))

export const COMPANY_LOGO = user?.company_logo || Logo
export const COMPANY_NAME = user?.company_name || 'Signwise Solutions Arizona'



// arizona
export const VERSION = '3.91'; 

// oklahoma
// export const VERSION = '0.01';

export const PUBNUB_PUBLISH_KEY= 'pub-c-014b4ee8-2c24-46d0-ae01-4a933c3fee2e'
export const PUBNUB_SUBSCRIBE_KEY= 'sub-c-53f81de7-6961-4d0e-87bb-f3defdef49a0'
export const PUBNUB_SECRET_KEY="sec-c-NTY0NDYwZDEtN2M3MC00Y2YwLWE4ZmItMzdmMjc5MTY1YTNl"

// export const UNITS = [
//     { id: 1, name: 'Square feet', short_name: 'Sq ft', unit_id: 1 },
//     { id: 2, name: 'Linear feet', short_name: 'Lin ft', unit_id: 1 },
//     { id: 3, name: 'Square inches', short_name: 'Sq in', unit_id: 1 },
//     { id: 4, name: 'Unit', short_name: 'Unit', unit_id: 2 },
//     { id: 5, name: 'Gallons', short_name: 'Gal', unit_id: 3 },
//     { id: 6, name: 'Quarts', short_name: 'Qt', unit_id: 3 },
//     { id: 7, name: 'Milliliter', short_name: 'ml', unit_id: 3 },
//     { id: 8, name: 'Ounce', short_name: 'oz', unit_id: 3 },
//     { id: 9, name: 'Kilogram', short_name: 'kg', unit_id: 4 },
//     { id: 10, name: 'Gram', short_name: 'g', unit_id: 4 },
//     { id: 11, name: 'Milligram', short_name: 'mg', unit_id: 4 },
// ];


export const UNITS = [
  { id: 1, name: 'Square feet', short_name: 'Sq ft', unit_id: 1 },
  { id: 2, name: 'Linear feet', short_name: 'Lin ft', unit_id: 1 },
  { id: 3, name: 'Square inches', short_name: 'Sq in', unit_id: 1 },
  { id: 4, name: 'Unit', short_name: 'Unit', unit_id: 2 },
  { id: 5, name: 'Gallons', short_name: 'Gal', unit_id: 3 },
  { id: 6, name: 'Quarts', short_name: 'Qt', unit_id: 3 },
  { id: 7, name: 'Milliliter', short_name: 'ml', unit_id: 3 },
  { id: 8, name: 'Liter', short_name: 'L', unit_id: 3 },
  { id: 9, name: 'Pint', short_name: 'pt', unit_id: 3 },
  { id: 10, name: 'Half Pint', short_name: 'half-pt', unit_id: 3 },
  { id: 11, name: 'Ounce', short_name: 'oz', unit_id: 3 },
  { id: 12, name: 'Kilogram', short_name: 'kg', unit_id: 4 },
  { id: 13, name: 'Gram', short_name: 'g', unit_id: 4 },
  { id: 14, name: 'Milligram', short_name: 'mg', unit_id: 4 },
  { id: 15, name: 'Each', short_name: 'EA', unit_id: 2 },
];


export const MATERIALTYPES = [
    { id: 1, name: 'Inventory', },
    { id: 2, name: 'Non-Inventory', },
    { id: 3, name: 'Services', },
    // { id: 4, name: 'Buldle', },
]

//For messenger chat
export const STATUS_OPTIONS = [
  { id: 1, statusData: 'online', label: 'Available' },
  { id: 2, statusData: 'away', label: 'Away' },
  { id: 3, statusData: 'busy', label: 'Busy' },
  { id: 4, statusData: 'invisible', label: 'Invisible' },
  { id: 5, statusData: 'dnd', label: 'Do not disturb' },
];

// wiseai.signwisesolutions.com
// export const BASE_URL = "https://wiseai.signwisesolutions.com/"
// export const REST_API_END_POINT = "https://wiseai.signwisesolutions.com/backend/webservice/";
// export const REST_API = "https://wiseai.signwisesolutions.com/backend/";
// export const REST_API_IO = "https://wiseai.signwisesolutions.com/backend";

// dev.signwisesolutions.com
export const BASE_URL = "https://dev.signwisesolutions.com/"
export const REST_API_END_POINT = "https://dev-api.signwisesolutions.com/webservice/";
export const REST_API = "https://dev-api.signwisesolutions.com/";
export const REST_API_IO = "https://dev-api.signwisesolutions.com";

// my.signwisesolutions.com
// export const REST_API_END_POINT = "https://api.signwisesolutions.skyniche.website/webservice/";
// export const REST_API = "https://api.signwisesolutions.skyniche.website/";
// export const REST_API_IO = "https://api.signwisesolutions.skyniche.website";
// export const BASE_URL = "https://my.signwisesolutions.com/";