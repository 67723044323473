import { useRef, useState } from 'react'
import {
  Card,
  Typography,
  Stack,
  ImageList,
  ImageListItem,
  ListSubheader,
  ImageListItemBar,
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  AvatarGroup,
  Tooltip,
  Avatar,
  Snackbar,
  IconButton,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ThumbUpAltOutlined, ThumbDownOffAltOutlined, CancelOutlined, Link, Notes, Description, PictureAsPdf, CalendarToday, Event
} from '@material-ui/icons'
import { sentenceCase } from 'change-case'
import moment from 'moment'
import Scrollbar from 'src/components/Scrollbar'
import {
  ref as imageref,
  uploadBytesResumable,
  getDownloadURL,
} from '@firebase/storage'
import { storage } from 'src/firebase/firebase'
import { REST_API_END_POINT } from 'src/constants/Defaultvalues'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import { LoadingButton } from '@material-ui/lab'
import { UserMoreMenu } from '../user/list'
import Label from 'src/components/Label'
import Modal from '../blog/ProjectModel'
import { AccountNotifications } from '../user/account'
import { empty } from 'src/Helpers/Common'
import { Icon } from '@iconify/react'
import arrowLeftOutlined from '@iconify/icons-ant-design/arrow-left-outlined';
import arrowRightOutlined from '@iconify/icons-ant-design/arrow-right-outlined';

export default function ViewDesignOrderDetails({
  designs,
  products,
  user,
  setDesigns,
  details,
  fetchRequestDetails,
  isRequest,
  requestId,
  signAgreements,
  customTemplate,
  questionnaireTemplate,
  allDataEmpty,
  portfolio,
  project,
  clientDetails,
  teamMembers,
  invoice,
  quotation,
  proposalView,
  isDesign
 
}) {

  const fileInputRef = useRef(null)
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const {
    name,
    notes,
    description,
    services,
    start_date,
    due_date,
    status,
    reason,
    reasonWithQb,
    estimate_id,
    added_by,
    awarded,
    rfpQbId
  } = details
  const submissionDate = moment.unix(start_date).format('MM/DD/YYYY')
  const endDate = moment.unix(due_date).format('MM/DD/YYYY')
  const xs = useMediaQuery(theme.breakpoints.down('xs'))
  const sm = useMediaQuery(theme.breakpoints.down('sm'))
  const mdd = useMediaQuery(theme.breakpoints.down('md'))
  const xld = useMediaQuery(theme.breakpoints.up('xl'))
  const [progress, setProgress] = useState(false)
  const [modalClose, setModalClose] = useState(false)
  const [openProductModel, setOpenProductModel] = useState(false)
  const [openReasonModal, setOpenReasonModal] = useState(false)
  const [itemId, setItemId] = useState(null)
  const [reasonText, setReasonText] = useState('')
  const [openImageModal,setOpenImageModal] = useState(false)
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  console.log("detailsdetails",invoice);
  const handleUpdateDesign = (data) => { 
    setModalClose(true)
    data.requestId = details.id
    data.userId = user.user_id
    if (data.approved) data.approved_by = user.user_id
    if(data.approved === 0 && reasonText === ''){
      enqueueSnackbar('Please add Reason', { variant: 'error' })
      return
    }
    let productName = ''
    if (data?.hasOwnProperty('product_name')) {
      productName = data?.product_name
      delete data?.product_name;
    }

    axios
      .post(`${REST_API_END_POINT}request/update-designs`, data)
      .then((res) => {
        if (res.data.status === 1) {
          enqueueSnackbar(data.approved === 1 ? 'Design has been Approved' : data.product_id ? `Design has been linked with ${productName}` : data?.status==0 ? 'Design has been Deleted' : 'Design has been Rejected', { variant: 'success' })
          fetchRequestDetails()
        } else {
          enqueueSnackbar(res.data.message, { variant: 'error' })
        }
        setItemId(null)
        setModalClose(false)
        setOpenProductModel(false)
        setOpenReasonModal(false)
        setReasonText('')
      })
  }
  const handleFileChange = async (event) => {
    const file = event.target.files[0]
    const fileExtension = file?.name?.split('.')?.pop()?.toLowerCase();
    const Filename = new Date().getTime() + file?.name

    // const isImage = file?.type?.startsWith('image/');
    // const isPdf = file?.type === 'application/pdf';
    const isImage = file?.type?.startsWith('image/') || file?.type === 'img' || file?.type === 'image';
    const isPdf = file?.type === 'application/pdf' || file?.type === "other" || file?.type === "pdf" ;

    if (!isImage && !isPdf){
      enqueueSnackbar('Please select a valid file. Only Images and PDF are allowed', { variant: 'error' })
      return;
    }
    const StorageRef = imageref(storage, Filename)
    const uploadTask = uploadBytesResumable(StorageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const pro = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        )
        setProgress(true)
      },
      (error) => {
        console.error(error.message)
        setProgress(false)
        enqueueSnackbar('Please upload Image or PDF less than 5 MB', { variant: 'error' });
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

          const uploaded = [downloadURL]
          const requestId = details.id
          const data = { requestId, userId: user.user_id, uploaded, type:file.type, size:file.size, extension:fileExtension }
          axios
            .post(`${REST_API_END_POINT}request/upload-designs`, data)
            .then((res) => {
              if (res.data.status === 1) {
                setDesigns((pv) => [
                  ...pv,
                  ...res.data.data.map((d) => ({
                    ...d,
                    updated_by: user.person_name,
                  })),
                ])
                enqueueSnackbar(res.data.message, { variant: 'success' })
              } else {
                enqueueSnackbar(res.data.message, { variant: 'error' })
              }
            })
          setProgress(false)
          fetchRequestDetails()
        })
      },
    )
  }
  const approvedCount = designs?.filter(item => item.approved === 1)?.length;
  const rejectedCount = designs?.filter(item => item.approved === 0)?.length;
  
  let borderColor = "GrayText";
  // let borderColor = theme.palette.text.secondary;
  
  // if (rejectedCount > 0) {
  //   borderColor = theme.palette.error.main;
  // } else {
  //   borderColor = theme.palette.success.main;
  // }

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setOpenImageModal(true);
  };

  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex < imageDesigns.length - 1 ? prevIndex + 1 : prevIndex));
  };
  
    const imageDesigns = designs?.filter(
    (item) => item.file_type?.startsWith('image/') || item.file_type === 'img' || item.file_type === 'image'
  );
  
  return (
    <Card>
      <Stack sx={{ m: 3 }} spacing={3}>
        <Stack
          direction={{ xs: 'row', sm: 'row' }}
          sx={{ justifyContent: 'space-between' }}
          spacing={{ xs: 3, sm: 2 }}
        >
          <Typography variant="overline" sx={{ color: 'text.secondary' }}>
            {sentenceCase(name)}
          </Typography>
          { reason &&
          <Typography variant="subtitle2" sx={{ color:theme.palette.error.main }}>
          {isDesign? details?.status==6? `Reason :${reason}`: "":`Reason :${reason}`}
          </Typography>
}
        </Stack>
        {/* {(added_by == 1 && user.user_type!=3) &&
        <Typography variant="subtitle2" sx={{ color: `${(proposalView ? !rfpQbId : (reasonWithQb || !estimate_id)) ? 'red' :'green'}` }}>
          {(proposalView && awarded == null) ? '' : 
          (proposalView ? 
          	(reasonWithQb 
            ? reasonWithQb.split('\n').map((line, index) => (
              <span key={index} style={{ display: 'block' }}>{line}</span>
            )) 
            : 'Not Synced With QuickBooks. A Network Issue has Occurred. Please try again...')
           :
          (estimate_id 
            ? 'Successfully Synced With QuickBooks' 
            : (reasonWithQb 
              ? reasonWithQb.split('\n').map((line, index) => (
                <span key={index} style={{ display: 'block' }}>{line}</span>
              )) 
              : 'Not Synced With QuickBooks. A Network Issue has Occurred. Please try again...')))}
          </Typography>} */}
         <Stack
          direction={{ xs: 'column', sm: 'row',md:'row' }}
          sx={{ justifyContent: 'space-between' }}
          spacing={{ xs: 3, sm: 2 }}
        > 
          <Box sx={{width:'60%'}}>
           <Typography variant="subtitle2" sx={{ color: 'GrayText' }}>
           Client Name : {clientDetails?.customer_name}
          </Typography>
           <Typography variant="subtitle2" sx={{ color: 'GrayText' }}>
           Client Address : {clientDetails?.customer_address}
          </Typography>
           <Typography variant="subtitle2" sx={{ color: 'GrayText' }}>
           Client Phone : {clientDetails?.customer_phone}
          </Typography>
          </Box>
          <Box sx={{ width: '35%' }}>
            {!user.isVendor && (
              <Stack spacing={1}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CalendarToday sx={{ color: 'text.secondary' }} />
                  <Typography variant="overline" sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
                    Requested Start Date:
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.primary' }}>
                    {submissionDate}
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Event sx={{ color: 'text.secondary' }} />
                  <Typography variant="overline" sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
                    Due Date:
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.primary' }}>
                    {endDate}
                  </Typography>
                </Box>
              </Stack>
            )}
          </Box>
          </Stack>
        <Stack
          direction={{ xs: 'row', sm: 'row' }}
          sx={{ justifyContent: 'space-between' }}
          spacing={{ xs: 3, sm: 2 }}
        >
          <Box sx={{width:'80%'}}>
            <Stack sx={{flexDirection:'row'}}>
            <Tooltip title='Description'>
              <Description />
            </Tooltip>
            <Typography variant="body2" sx={{ color: 'text.secondary', flexWrap:'wrap', ml:2 }}>
              {description}
            </Typography>
            </Stack>
          </Box>
        </Stack>
        <Stack
          direction={{ xs: 'row', sm: 'row' }}
          // sx={{ justifyContent: 'space-between' }}
          spacing={{ xs: 3, sm: 2 }}
        >
          {notes ?
          <>
            <Tooltip title='Notes'>
              <Notes />
            </Tooltip>
            <Typography variant="caption" sx={{ flexWrap:'wrap' }}>
              {notes}
            </Typography> 
          </> : ''}
        </Stack>
        {(user?.user_type === 3 ? details?.send_client_app_req : designs?.length >0) ? 
        <Stack
          direction={{ xs: 'row', sm: 'row' }}
          spacing={{ xs: 3, sm: 2 }}
        >
          Designs
        </Stack>
        :""}
        {/* <Stack
          direction={'row'}
          alignItems={'center'}
          spacing={{ xs: 2, sm: 3 }}
          mb={2}
        >
         {empty(quotation)&& details?.job_category_name!='Service only' && details?.job_category_name!='Time & Material' && <ListSubheader component="div">Designs</ListSubheader>}
          { empty(quotation) && status !== 6 && !user.isVendor && !project&& details?.job_category_name!='Service only' && details?.job_category_name!='Time & Material' && <LoadingButton
            variant="outlined"
            size="small"
            sx={{ height: 40 }}
            onClick={() => fileInputRef.current.click()}
            loading={progress}
          >
            Upload Design
          </LoadingButton>}
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
            accept="image/*,application/pdf"
          />
        </Stack> */}
        
        {/* {designs?.length ? ( */}
        {(user?.user_type === 3 ? details?.send_client_app_req : designs?.length >0) ? (
          <Box sx={{border: '0.1px solid', borderColor: borderColor,padding:2, }}>
            <Scrollbar sx={{ width: '100%', height: 450 }}>
               
              <ImageList  gap={mdd ? 8 : xld ? 12 : 8} cols={mdd ? 3 : xld ? 3 : xs ? 1  : 3}>
                {/* <ImageListItem key="Subheader" cols={2}> */}
                {/* </ImageListItem> */}
                {designs?.map((item,index) =>{
                    // const { file_type, name, url } = item;
                    // const isImage = file_type?.startsWith('image/') || file_type ==='img';
                    // const isPdf = file_type === 'application/pdf';
                    const { file_type, name, url, extension } = item;
                    const isImage = file_type?.startsWith('image/') || file_type ==='img' || file_type ==='image';
                    // const isPdf = file_type === 'application/pdf' || extension=='pdf';
                    const isPdf = file_type === 'application/pdf' || file_type === "other" || file_type === "pdf" ;
                  return (
                  <ImageListItem  key={item.name} sx={{cursor:'pointer'}}>
                    {/* <Stack sx={{
                      position: 'absolute',
                      zIndex: 9,
                      top: 8,
                      left: 10,
                    }}>
                      {item.approved === 1 ?(
                        <Label
                          variant={'filled'}
                          color={'success'}
                          style={{ color: 'white', height: 20, width: 100 }}
                        >
                          {'Approved'}
                        </Label>                       
                      ): 
                      item.reason ? (
                        <Label
                        variant={'filled'}
                        color={'error'}
                        style={{ color: 'white', height: 20, width: 100 }}
                        >
                        {'Rejected'}
                      </Label>
                      ):null}
                    </Stack> */}
                    {/* <Stack sx={{
                      position: 'absolute',
                      zIndex: 9,
                      top: 110,
                      left: 10,
                    }}>
                      {item.approved === 1 ? (
                        // <Box
                        //   sx={{
                        //     position: 'absolute',
                        //     zIndex: 9,
                        //     bottom: 8,
                        //     left: 100,
                        //   }}
                        // >                      
                        //     <Typography
                        //       fontSize={'0.7rem'}
                        //       variant={'caption'}
                        //       sx={{ color: 'text.primary' }}
                        //     >
                        //       Approved By: {item.approved_by}
                        //     </Typography>
                        
                        // </Box>

                        <Typography sx={{ position: 'absolute', top: 40, zIndex: 100 }}>
                          <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            color="success"
                          >
                            <Tooltip title={item.approved_by}>
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  fontSize: '12px',
                                  cursor: 'pointer',
                                  display: 'inline-block',
                                  maxWidth: 150, // Adjust as necessary
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                <span
                                  style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  Approved By: {item.approved_by}
                                </span>
                              </Typography>
                            </Tooltip>
                          </Label>
                        </Typography>

                      ) : item.reason ? 
                      // <Box
                      //   sx={{
                      //     position: 'absolute',
                      //     zIndex: 9,
                      //     bottom: 8,
                      //     left: 100,
                      //   }}
                      // >
                      //   <Typography
                      //     fontSize={'0.7rem'}
                      //     variant={'caption'}
                      //     sx={{ color: 'text.error' }}
                      //   >
                      //     Rejected Reason: {item.reason}
                      //   </Typography>
                      // </Box>
                      <Typography sx={{ position: 'absolute', top: 40, zIndex: 100 }}>
                      <Label
                        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                        color="error"
                      >
                        <Tooltip title={item.reason}>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              fontSize: '12px',
                              cursor: 'pointer',
                              display: 'inline-block',
                              maxWidth: 150, // Adjust as necessary
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <span
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Rejected Reason: {item.reason}
                            </span>
                          </Typography>
                        </Tooltip>
                      </Label>
                    </Typography>
                      : ''}
                    </Stack> */}
                        {isImage ? (
                          <a
                          // href={name}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => {
                            e.preventDefault();
                            handleImageClick(imageDesigns.findIndex((img) => img.name === name))
                          }}
                        >
                          <img
                            // srcSet={`https://gratisography.com/wp-content/uploads/2025/01/gratisography-dog-vacation-1170x780.jpg?w=240&h=240&fit=crop&auto=format&dpr=2 2x`}
                            // src={`https://gratisography.com/wp-content/uploads/2025/01/gratisography-dog-vacation-1170x780.jpg?w=240&h=240&fit=crop&auto=format`}
                            srcSet={`${item.name}?w=240&h=240&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item.name}?w=240&h=240&fit=crop&auto=format`}
                            alt={item.title}
                            loading="lazy"
                            style={{ width: '240px', height: '240px' }}
                          />
                          </a>
                        ) : isPdf ? (
                          <Box display="flex" alignItems="center">
                          {/* PDF Icon Button */}
                          <Tooltip title='View'>
                            <IconButton onClick={() => window.open(name, '_blank')} aria-label="open pdf"
                              sx={{ position:'absolute', top:60, right:'50%', zIndex: 200,color:theme.palette.error.main, backgroundColor:theme.palette.text.primary }} >
                              <PictureAsPdf style={{ fontSize: 40 }} />
                            </IconButton>
                          </Tooltip>
                    
                          {/* Iframe */}
                          <iframe
                            src={name}
                            title={name}
                            style={{ width: project ? '150px' :'240px', height: '240px' }}
                            frameBorder="0"
                          />
                        </Box>
                        ) : (
                          <Typography 
                            sx={{
                              color:theme.palette.error.main, 
                              alignItems:'center', 
                              justifyContent:'center',
                              width: project ? '150px' :'240px', height: '240px'
                            }}>
                            Unsupported file type</Typography>
                        )}
                    <ImageListItemBar
                     sx={{
                      width:mdd? '92%':sm ? '20%' :xld?"70%":xs ?'35%' :'82%',                 
                    }}
                      // title={item.updated_by}
                      subtitle={moment
                        .unix(item.timestamp)
                        .format('MM/DD/YYYY')}
                      // actionIcon={
                      //    <>                       
                      //    {!user.isVendor&& !project&&(
                      //   <UserMoreMenu
                      //     common={{
                      //       name: (item?.approved !== 1 && item?.product_id !== null) && 'Approve',
                      //       function:
                      //       (item?.approved !== 1 && item?.product_id !== null) ? () => {
                      //         setItemId(item.id)
                      //         setModalClose(true)
                      //         handleUpdateDesign({
                      //           id: item.id,
                      //           // product_id: product.product_id,
                      //           approved: 1,
                      //           reason:null
                      //         })
                      //         // setOpenProductModel(true)
                      //       } : null ,
                      //       Iccon:
                      //       (item?.approved !== 1 && item?.product_id !== null) && (
                      //           <ThumbUpAltOutlined />
                      //         ),
                      //       modalClose: modalClose,
                      //     }}
                      //     linkImageWithTask={{
                      //       name: item?.product_id == null && 'Link with catalog',
                      //       function:
                      //       item?.product_id == null ? () => {
                                    
                      //               setItemId(item.id)
                      //               setModalClose(true)
                      //               setOpenProductModel(true)
                      //             } : null ,
                      //       Iccon: item?.product_id == null && (
                      //           <Link />
                      //         ),
                      //       modalClose: modalClose,
                      //     }}

                      //     common2={{
                      //       name: (item?.approved === 1 || (item?.approved === 0 && item?.reason === null)) && 'Reject',
                      //       function:
                      //       (item?.approved === 1 || (item?.approved === 0 && item?.reason === null)) ? () => {
                      //                 setItemId(item.id)
                      //                 setOpenReasonModal(true)
                      //             } : null ,
                      //       Iccon:
                      //       (item?.approved === 1 || (item?.approved === 0 && item?.reason === null)) && (
                      //           <ThumbDownOffAltOutlined />
                      //         )
                      //         ,
                      //       modalClose: modalClose,
                      //     }}
                      //     //   onDelete={() => handleUpdateDesign({ id: item.id, status: 0 })}
                      //     {...(item.approved === 1
                      //       ? {}
                      //       : {
                      //           onDelete: () =>
                      //             handleUpdateDesign({
                      //               id: item.id,
                      //               status: 0,
                      //             }),
                      //         })}
                      //   />
                      //   )}                          
                      //   </>
                      // }
                    />
                    {openProductModel && itemId === item.id && <Card sx={{ position:'absolute', zIndex: 10, bottom: 5,left: 5,p: 2}}>
                      <Stack direction={'row'} justifyContent={'space-between'}>
                      <Typography marginBottom={2} variant='subtitle2'>Choose Product</Typography>
                      <CancelOutlined sx={{ cursor: 'pointer' }} onClick={()=>{
                        setModalClose(false)
                        setOpenProductModel(false)
                        }}/>
                      </Stack>
                      <Divider sx={{ marginBottom: 2}}/>
                    <Stack spacing={1}>
                      {
                        products?.filter((prodItem)=>prodItem?.material===0 && prodItem?.rfp===0)?.map((product) => 
                          <><Typography sx={{ cursor: 'pointer' }} variant='caption' onClick={()=>handleUpdateDesign({
                                    id: item.id,
                                    product_id: product.product_id,
                                    product_name: product.product_name,
                                    // approved: 1,
                                    reason:null
                                  })}>{product.product_name}</Typography>
                                  <Divider/>
                                  </>
                        )
                      }
                      </Stack>
                    </Card>}
                    
                    <Modal
                        open={openReasonModal && itemId === item.id}
                        handleClose={() => {
                          setOpenReasonModal(false)
                        }}
                        modalTitle={`Reason for Design Rejection`}
                      >
                        
                            <Grid container justifyContent="center" alignItems="center">
                              <Grid item xs={12} >
                                <TextField
                                  fullWidth
                                  label="Reason"
                                  value={reasonText}
                                  onChange={(e) => setReasonText(e.target.value)}
                                />
                                <Box
                                  sx={{
                                    mt: 3,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    gap: 1,
                                  }}
                                >
                                  <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    onClick={()=>handleUpdateDesign({
                                      id: itemId,
                                      reason: reasonText,
                                      approved: 0,
                                    })}
                                    // loading={isSubmitting}
                                  >
                                    {' Save '}
                                  </LoadingButton>
                                  <Button
                                    variant="outlined"
                                    type="submit"
                                    onClick={() => {
                                      setOpenReasonModal(false)
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                      </Modal>
                  </ImageListItem>
                   )
                   })}
              </ImageList>
            </Scrollbar>
          </Box>
        ) : (
          ''
        )}
      </Stack>
      <Modal width={1000}
              open={openImageModal}
              handleClose={() => {
                setOpenImageModal(false);
              }}
              modalTitle={'Designs'}
              >
              <div
              style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                display: 'flex',
                gap:'1rem',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
            <IconButton
              onClick={handlePrevious}
              disabled={currentImageIndex === 0}
              sx={{
                opacity: currentImageIndex === 0 ? 0.3 : 1,
                pointerEvents: currentImageIndex === 0 ? 'none' : 'auto',
              }}
            >
              <Icon
                icon={arrowLeftOutlined}
                width={24}
                height={24}
                color="#006097"
              />
            </IconButton>

            <Box
            component={'div'} 
              style={{
                width: '500px',
                height: '500px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 8,
                overflow: 'hidden',
              }}
            >
              <img
                src={imageDesigns[currentImageIndex]?.name}
                // src={`https://gratisography.com/wp-content/uploads/2025/01/gratisography-dog-vacation-1170x780.jpg`}
                alt={`Image ${currentImageIndex + 1}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: 8,
                }}
              />
            </Box>
            <IconButton
              onClick={handleNext}
              disabled={currentImageIndex === imageDesigns.length - 1}
              sx={{
                opacity: currentImageIndex === imageDesigns.length - 1 ? 0.3 : 1,
                pointerEvents: currentImageIndex === imageDesigns.length - 1 ? 'none' : 'auto',
              }}
            >
              <Icon
                icon={arrowRightOutlined}
                width={24}
                height={24}
                color="#006097"
              />
            </IconButton>
          </div>
        </Modal>
      {/* {(user.isVendor === null || user.isVendor === 0) &&(
      <AccountNotifications
                  isRequest={true}
                  requestId={requestId}
                 signAgreements={signAgreements}
                 customTemplate={customTemplate}
                 questionnaireTemplate={questionnaireTemplate}
                 allDataEmpty={allDataEmpty}
                 portfolio={portfolio}
                 updatingFunction={fetchRequestDetails}/>
                 )} */}
    </Card>
  )
}