import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import twitterFill from '@iconify/icons-eva/twitter-fill'
import linkedinFill from '@iconify/icons-eva/linkedin-fill'
import facebookFill from '@iconify/icons-eva/facebook-fill'
import instagramFilled from '@iconify/icons-ant-design/instagram-filled'
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill'
import { sentenceCase } from 'change-case'
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'

import {
  Box,
  Card,
  Grid,
  Avatar,
  Tooltip,
  Divider,
  Stack,
  Typography,
  IconButton,
  AvatarGroup,
} from '@material-ui/core'
// utils
import { fShortenNumber } from 'src/utils/formatNumber'
//
import SvgIconStyle from 'src/components/SvgIconStyle'
import Label from 'src/components/Label'
import { useNavigate } from 'react-router'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { Message, MessageSharp, RequestQuote } from '@material-ui/icons'
import moment from 'moment'
import { getAgoString } from 'src/common/Helper'
import { useSnackbar } from 'notistack'
import { Link } from 'react-router-dom'
import { empty } from 'src/Helpers/Common'
import { COMPANY_LOGO } from 'src/constants/Defaultvalues'
import QuickbookLogo from '../../../assets/quickbookLogo-removebg-preview.png'
import { useState } from 'react'
import FullScreenDialogEstimate from 'src/pages/components-overview/material-ui/dialog/FullScreenDialogEstimate'
import FullScreenDialogSalesOrder from 'src/pages/components-overview/material-ui/dialog/FullScreenDialogSalesOrder'

// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  paddingTop: 'calc(100% * 9 / 16)',
  '&:before': {
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
    //  backdropFilter: 'blur(3px)',
    WebkitBackdropFilter: 'blur(3px)', // Fix on Mobile
    borderTopLeftRadius: theme.shape.borderRadiusMd,
    borderTopRightRadius: theme.shape.borderRadiusMd,
    // backgroundColor: alpha(theme.palette.primary.darker, 0.72)
  },
}))

const CoverImgStyle = styled('img')({
  top: 0,
  zIndex: 8,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  //    background: `url(${src}) center/cover no-repeat`
})

// ----------------------------------------------------------------------

function InfoItem(number) {
  return (
    <Grid item xs={4}>
      <Typography
        variant="caption"
        sx={{ mb: 0.5, color: 'text.secondary', display: 'block' }}
      >
        Follower
      </Typography>
      <Typography variant="subtitle1">{fShortenNumber(number)}</Typography>
    </Grid>
  )
}

OfficialCard.propTypes = {
  card: PropTypes.object.isRequired,
  team_member: PropTypes.array.isRequired,
}

export default function OfficialCard({
  card,
  team_member,
  communityId,
  clientId,
  communityName,
  CommunityProjects,
  quotationView,
  proposalView,
  designOrder,
  setRenderCards,
  renderCards,
  handleOpenViewSalesOrder,
  handleOpenDetailsSalesOrder,
  setOpenSalesOrder,
  opeSalesOrder,
  handleOpenViewEstimate,
  handleOpenDetails,
  SalesOrderId,
  setSalesOrderId,
  salesOrderName,
  setSalesOrderName,
  removePopupData,
  openPurchaseOrder,
  setOpenPurchaseOrder,
  PurchaseOrderName,
  purchaseOrderId,
  isEditPurchaseOrder,
  setIsEditPurchaseOrder,
  setPurchaseOrderId,
  setPurchaseOrderName,
  CommunityJob,
  communityDesignOrder,
  handleOpenDetailsPurchaseOrder,
  handleOpenViewPurchaseOrder
  // handleOpenEstimate,setHandleOpenEstimate,estimateId,setEstimateId,estimateName,setEstimateName,isEditEstimate,setIsEditEstimate
}) {
  const theme = useTheme()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  let user = JSON.parse(localStorage.getItem('user'))
  const userType = user.user_type
  const explodedPermissions = user?.exploded_permissions
  const userId = user.user_id
  const handleOpenEstimateTemp = localStorage.getItem(
    'estimateOpenProductionPopup',
  )
  const [handleOpenEstimate, setHandleOpenEstimate] = useState(
    handleOpenEstimateTemp || false,
  )
  const [estimateId, setEstimateId] = useState(false)
  const [estimateName, setEstimateName] = useState(false)
  const [isEditEstimate, setIsEditEstimate] = useState(false)

  const [isEditSalesOrder, setIsEditSalesOrder] = useState('')
  const {
    image,
    message,
    timestamp,
    id,
    name,
    status,
    hold,
    design_order_job_status,
    request,
    people,
    description,
    subject,
    submission_deadline,
    msg_count,
    proposal_count,
    participation_ends,
    services,
    accept_status,
    thumbnail,
    due_date,
    cover_image,
    invoice_total,
    invoice_grand_total,
    partners,
    customer_pic,
    community_name,
    installationStatus,
    allInstallationCompleted,
    installation_acceptance,
   customer_name,
   awarded,
   paymentMode,
   quotation_status,
   quotation,
   statusRfp,
   syncWithQuickbooks,
   doc_number,
  min_payment_status,
  project_category,
  job_category_name,
  installationCompleteStatus,
  billing_type,
  attachment_count,
  attachment,
  isFromDesignOrder
  } = card

  const filteredAttachments = attachment?.filter(imageItem=>(imageItem?.file_type?.startsWith("image") || imageItem?.file_type === "img")) || []
console.log('cardcardcardcard card',card)
 
  let customer_image = partners?.filter(
    (item) => item?.profile_pic != 'avatar.jpg',
  )
  let accepted_customer_image = partners
    ?.filter((item) => {
      item?.profile_pic != 'avatar.jpg' && item?.status == 1
    })
    ?.map((data) => {
      return data?.profile_pic
    })
  // console.log('customer_image--',customer_image);
  // console.log('accepted_customer_image--',accepted_customer_image);
  //  const currentUnix = moment().unix()
  //  console.log(currentUnix,'))))))))))))))))')
  //   console.log(submission_deadline,'$$$$$$$$$$$$$$$$')
  const updatedAt = moment.unix(timestamp).format('YYYY-MM-DD')
  //status color
  let CompletedStatus =
    installationCompleteStatus == 1
      ? 'Installs'
      : installationCompleteStatus == 2
      ? 'Pick up'
      : CommunityJob ? 'Installs' :'Shipping'
  const getStatusColorAndLabel = (
    status,
    installationStatus,
    allInstallationCompleted,
    accepted,
    min_payment_status,
    billing_type,
  ) => {
    switch (status) {
      case 1:
        return { color: 'warning', label: 'Status: Draft' }
      case 2:
        return { color: 'info', label: 'Status: Open' }
      case 3:
        return {
          color: `${
            project_category == 13
              ? 'primary'
              : min_payment_status == 0
              ? 'warning'
              : 'primary'
          }`,
          label: `Status: ${
            project_category == 13 || billing_type == 0
              ? 'Approved'
              : min_payment_status == 1
              ? 'Approved and Paid'
              : 'Approved and Payment Due'
          }`,
        }
      // return { color: `${min_payment_status==0? 'warning' :'primary'}`, label: `Status: ${min_payment_status==1? 'Approved and Paid' :'Approved & Awaiting for Payment'}` }
      case 4:
        // return { color: 'success', label: !empty(allInstallationCompleted) ? 'Completed' : (!empty(installationStatus) ? 'Status: Installation' : 'Status: In Production') }
        return {
          color:
            empty(accepted) && !empty(allInstallationCompleted)
              ? 'warning'
              : 'success',
          label:
            empty(accepted) && !empty(allInstallationCompleted)
              ? `${CompletedStatus} completed & Awaiting approval`
              : !empty(accepted) && !empty(allInstallationCompleted)
              ? `${CompletedStatus} completed & Approved`
              : !empty(installationStatus)
              ? `Status: ${CompletedStatus}`
              : CommunityJob ? `${CompletedStatus} completed & Approved` : `Status: In-Production`,
        } // return { color: 'primary', label: 'Status: Converted to Job' };
      case 5:
        return { color: 'warning', label: 'Status: Closed' }
      case 6:
        return { color: 'error', label: 'Status: Rejected' }
      default:
        return { color: 'default', label: 'Status: Open' }
    }
  }
  const getStatusColorAndLabelQuickbid = (status, quotation) => {
    switch (status) {
      case 1:
        return { color: 'warning', label: 'Status: Draft' }
      case 2:
        return { color: 'info', label: 'Status: Open' }
      case 3:
        return { color: 'primary', label: 'Status: Evaluating' }
      case 4:
        return {
          color: quotation == 0 ? 'default' : 'success',
          label: quotation == 0 ? 'Status: Archive' : 'Status: Approved',
        }
      case 5:
        return { color: 'warning', label: 'Status: Closed' }
      case 6:
        return { color: 'error', label: 'Status: Rejected' }
      default:
        return { color: 'default', label: 'Status: Open' }
    }
  }
  const getStatusColorAndLabelDesignOrder = (status, hold) => {
    switch (status) {
      case 1:
        return {
          color: hold == 1 ? 'warning' : 'info',
          label: hold == 1 ? 'Status: Hold' : 'Status: Open',
        }
      case 2:
        return {
          color: hold == 1 ? 'warning' : 'success',
          label: hold == 1 ? 'Status: Hold' : 'Status: Approved ',
        } // Evaluating
      case 3:
        return {
          color: hold == 1 ? 'warning' : 'success',
          label: hold == 1 ? 'Status: Hold' : 'Status: Approved',
        }
      case 4:
        return {
          color: hold == 1 ? 'warning' : 'warning',
          label: hold == 1 ? 'Status: Hold' : 'Status: Draft',
        }
      case 5:
        return {
          color: hold == 1 ? 'warning' : 'warning',
          label: hold == 1 ? 'Status: Hold' : 'Status: Closed',
        }
      case 6:
        return {
          color: hold == 1 ? 'warning' : 'error',
          label: hold == 1 ? 'Status: Hold' : 'Status: Rejected',
        }
      default:
        return {
          color: hold == 1 ? 'warning' : 'default',
          label: hold == 1 ? 'Status: Hold' : 'Status: Open',
        }
    }
  }
  const getStatusColorAndLabelForProposal = (status) => {
    switch (status) {
      case 1:
        return { color: 'info', label: 'Status: Open' }
      case 2:
        return { color: 'warning', label: 'Status: Awarded' }
      case 3:
        return { color: 'error', label: 'Status: Rejected' }
      case 4:
        return { color: 'success', label: 'Status: Approved' }
      case 5:
        return { color: 'warning', label: 'Status: Closed' }
      case 6:
        return { color: 'error', label: 'Status: Rejected' }
      case 7:
        return { color: 'success', label: 'Status: Paid' }
      default:
        return { color: 'info', label: 'Status: Open' }
    }
  }

  const option = [
    { id: 0, value: 'Inactive' },
    { id: 1, value: 'New' },
    { id: 2, value: 'Open' },
    { id: 3, value: 'Completed' },
    { id: 4, value: 'Hold' },
    { id: 5, value: 'Approved' },
    { id: 6, value: 'Rejected' },
    { id: 7, value: 'Verified' },
    { id: 6, value: 'Reopen' },
  ]
  const colors = [
    'error',
    'primary',
    'secondary',
    'success',
    'warning',
    'success',
    'error',
    'success',
    'error',
  ]

  // const handleOpenViewEstimate = (id,name) =>{
  //   setIsEditEstimate(true)
  //   setEstimateId(id)
  //   setEstimateName(name)
  //   setHandleOpenEstimate(true)
  //   console.log('hjsfgdhjdhbfhhhh')
  //   localStorage.setItem('estimateProjectId', id);
  //   localStorage.setItem('estimateProjectName', name);
  //   localStorage.setItem('isEditEstimate', "true");
  //   localStorage.setItem('estimateOpenProductionPopup', 'true');

  // }
  // const handleOpenDetails= (id,name) =>{
  //   setIsEditEstimate(false)
  //   setEstimateId(id)
  //   setEstimateName(name)
  //   setHandleOpenEstimate(true)
  //   localStorage.setItem('estimateProjectId', id);
  //   localStorage.setItem('estimateProjectName', name);
  //   localStorage.setItem('isEditEstimate', "false");
  //   localStorage.setItem('estimateOpenProductionPopup', 'true');
  // }
  // const handleOpenViewSalesOrder = (id,name) =>{
  //   setIsEditSalesOrder(true)
  //   setSalesOrderId(id)
  //   setSalesOrderName(name)
  //   setOpenSalesOrder(true)
  //   localStorage.setItem('salesOrderProjectId', id);
  //   localStorage.setItem('salesOrderProjectName', name);
  //   localStorage.setItem('isEditSalesOrder', "true");
  //   localStorage.setItem('salesOrderOpenPopup', 'true');

  // }
  // const handleOpenDetailsSalesOrder= (id,name) =>{
  //   setIsEditSalesOrder(false)
  //   setSalesOrderId(id)
  //   setSalesOrderName(name)
  //   setOpenSalesOrder(true)
  //   localStorage.setItem('salesOrderProjectId', id);
  //   localStorage.setItem('salesOrderProjectName', name);
  //   localStorage.setItem('isEditSalesOrder', "false");
  //   localStorage.setItem('salesOrderOpenPopup', 'true');
  // }
  // const handleOpenViewPurchaseOrder = (id, name) => {
  //   setIsEditPurchaseOrder(true)
  //   setPurchaseOrderId(id)
  //   setPurchaseOrderName(name)
  //   setOpenPurchaseOrder(true)
  //   localStorage.setItem('purchaseOrderProjectId', id)
  //   localStorage.setItem('purchaseOrderProjectName', name)
  //   localStorage.setItem('isEditPurchaseOrder', 'true')
  //   localStorage.setItem('purchaseOrderOpenPopup', 'true')
  // }
  // const handleOpenDetailsPurchaseOrder = (id, name) => {
  //   setIsEditPurchaseOrder(false)
  //   setPurchaseOrderId(id)
  //   setPurchaseOrderName(name)
  //   setOpenPurchaseOrder(true)
  //   localStorage.setItem('purchaseOrderProjectId', id)
  //   localStorage.setItem('purchaseOrderProjectName', name)
  //   localStorage.setItem('isEditPurchaseOrder', 'false')
  //   localStorage.setItem('purchaseOrderOpenPopup', 'true')
  // }

  return (
    <>
    <Card
    sx={{ ml: 2 , opacity: quotationView && quotation_status === 4 && quotation === 0 ? '75%':'100%'}}
      // sx={{ ml: 2 , opacity: quotationView && quotation_status === 4 && quotation === 0 ? '75%':'100%'}}
      id={id}
      onClick={() => {
        //    navigate(PATH_DASHBOARD.blog.viewproject)
      }}
    >
      <CardMediaStyle>
        {/* <IconButton
        sx={{
          top: 8,zIndex:500,color:theme.palette.grey.A100,
          right: 5,
          position: 'absolute'
        }}
      >
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton> */}
          {/* <SvgIconStyle
          color="paper"
          src={image}
          sx={{
            width: 144,
            height: 62,
            zIndex: 10,
            bottom: -26,
            position: 'absolute'
          }}
        /> */}

          <AvatarGroup
            max={7}
            sx={{ '& .MuiAvatar-root': { width: 62, height: 62, zIndex: 10 } }}
          >
            {team_member?.length > 0 &&
              team_member?.map((person) => {
                return (
                  <Tooltip title={person?.member_name}>
                    <Avatar
                      key={person.id}
                      sx={{
                        width: 30,
                        height: 30,
                        position: 'absolute',
                        transform: 'translateY(-50%)',
                      }}
                      alt={person?.member_name}
                      src={person?.profile_pic}
                    />
                  </Tooltip>
                )
              })}
          </AvatarGroup>
          <CoverImgStyle
            alt="cover"
            src={
              ((designOrder || isFromDesignOrder) && filteredAttachments?.length >0) ? filteredAttachments[0]?.name :filteredAttachments?.length >0? filteredAttachments[0]?.name : (customer_pic && customer_pic != 'avatar.jpg')
                ? customer_pic
                : COMPANY_LOGO
            } 
            //thumbnail ? thumbnail :
            // ((accepted_customer_image?.length > 0) ? accepted_customer_image[0] : customer_image[0]?.profile_pic || '/static/mock-images/avatars/avatar_default1.jpg')} //thumbnail ? thumbnail :
            // communityName && (cover_image ? cover_image : '/static/mock-images/avatars/avatar_default1.jpg') ||
          />
          <Stack sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              sx={{ position: 'absolute', top: 8, left: 10, zIndex: 10 }}
            >
              {CommunityProjects ? (
                <Label
                  variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                  color={colors[status]}
                >
                  {option.find((opt) => opt.id === status).value}
                </Label>
              ) : (
                <Label
                  variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                  color={
                    designOrder
                      ? getStatusColorAndLabelDesignOrder(status, hold).color
                      : quotationView
                      ? getStatusColorAndLabelQuickbid(
                          quotation_status,
                          quotation,
                        ).color
                      : proposalView
                      ? getStatusColorAndLabelForProposal(
                          userType != 3 ? (paymentMode ? 7 : awarded ? 2 : 1) : paymentMode ? 7 : statusRfp,
                        ).color
                      : getStatusColorAndLabel(
                          status,
                          installationStatus,
                          allInstallationCompleted,
                          installation_acceptance,
                          min_payment_status,
                          billing_type,
                        ).color
                  }
                >
                  <Tooltip
                    title={
                      designOrder
                        ? getStatusColorAndLabelDesignOrder(status, hold).label
                        : quotationView
                        ? getStatusColorAndLabelQuickbid(
                            quotation_status,
                            quotation,
                          ).label
                        : proposalView
                        ? getStatusColorAndLabelForProposal(
                            userType != 3 ? (paymentMode? 7:awarded ? 2 : 1) : paymentMode ? 7 : statusRfp,
                          ).label
                        : getStatusColorAndLabel(
                            status,
                            installationStatus,
                            allInstallationCompleted,
                            installation_acceptance,
                            min_payment_status,
                            billing_type,
                          ).label
                    }
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: '14px',
                        cursor: 'pointer',
                        display: 'inline-block',
                        maxWidth: 100, // Adjust as necessary
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <span
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {designOrder
                          ? getStatusColorAndLabelDesignOrder(status, hold)
                              .label
                          : quotationView
                          ? getStatusColorAndLabelQuickbid(
                              quotation_status,
                              quotation,
                            ).label
                          : proposalView
                          ? getStatusColorAndLabelForProposal(
                              userType != 3 ? (paymentMode? 7:awarded ? 2 : 1) : paymentMode ? 7 : statusRfp,
                            ).label
                          : getStatusColorAndLabel(
                              status,
                              installationStatus,
                              allInstallationCompleted,
                              installation_acceptance,
                              min_payment_status,
                              billing_type,
                            ).label}
                      </span>
                    </Typography>
                  </Tooltip>
                </Label>
              )}
            </Typography>
            {/* {!quotationView && !proposalView ?<Typography sx={{ position: 'absolute', top: 8, right: 10, zIndex: 10 }}> */}
            {!proposalView ? (
              <Typography
                sx={{ position: 'absolute', top: 8, right: 10, zIndex: 10 }}
              >
                {!CommunityProjects ? (
                  community_name ? (
                    <Label
                      variant={
                        theme.palette.mode === 'light' ? 'ghost' : 'filled'
                      }
                      color={'warning'}
                    >
                      <Tooltip title={community_name}>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontSize: '14px',
                            cursor: 'pointer',
                            display: 'inline-block',
                            maxWidth: 100, // Adjust as necessary
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          <span
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {community_name}
                          </span>
                        </Typography>
                      </Tooltip>
                    </Label>
                  ) : (
                    <Label
                      variant={
                        theme.palette.mode === 'light' ? 'ghost' : 'filled'
                      }
                      color={'error'}
                    >
                      No project
                    </Label>
                  )
                ) : (
                  ''
                )}
              </Typography>
            ) : (
              ''
            )}
            {!CommunityProjects && !proposalView && customer_name && (
              <Typography
                sx={{ position: 'absolute', bottom: 8, left: 10, zIndex: 10 }}
              >
                <Label
                  variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                  color="secondary"
                >
                  <Tooltip title={customer_name}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: '14px',
                        cursor: 'pointer',
                        display: 'inline-block',
                        maxWidth: 150, // Adjust as necessary
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <span
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {customer_name}
                      </span>
                    </Typography>
                  </Tooltip>
                </Label>
              </Typography>
            )}
            {
              <Typography
                sx={{ position: 'absolute', bottom: 8, right: 10, zIndex: 10 }}
              >
                {!CommunityProjects ? (
                  participation_ends ? (
                    <Label
                      variant={
                        theme.palette.mode === 'light' ? 'ghost' : 'filled'
                      }
                      color={'default'}
                    >
                      <Tooltip
                        title={
                          quotationView || proposalView
                            ? 'Date Created'
                            : 'Installation Date'
                        }
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontSize: '14px',
                            cursor: 'pointer',
                            display: 'inline-block',
                            maxWidth: 100, // Adjust as necessary
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          <span
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {moment
                              .unix(participation_ends)
                              .format('MM/DD/YYYY')}
                          </span>
                        </Typography>
                      </Tooltip>
                    </Label>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                {designOrder ? (
                  due_date ? (
                    <Label
                      variant={
                        theme.palette.mode === 'light' ? 'ghost' : 'filled'
                      }
                      color={'default'}
                    >
                      <Tooltip title={'Installation Date'}>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontSize: '14px',
                            cursor: 'pointer',
                            display: 'inline-block',
                            maxWidth: 100,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          <span
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {moment.unix(due_date).format('MM/DD/YYYY')}
                          </span>
                        </Typography>
                      </Tooltip>
                    </Label>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
              </Typography>
            }
          </Stack>
        </CardMediaStyle>
        {job_category_name ? (
          <Stack
            sx={{
              mt: 2,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Label
              variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
              color="info"
            >
              <Tooltip title={job_category_name}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: '14px',
                    cursor: 'pointer',
                    display: 'inline-block',
                    maxWidth: 150, // Adjust as necessary
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <span
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {job_category_name}
                  </span>
                </Typography>
              </Tooltip>
            </Label>
          </Stack>
        ) : (
          ''
        )}

        {designOrder && status == 1 && attachment_count > 0 ? (
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Label
              variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
              color="success"
            >
              <Tooltip title={'Awaiting Approval'}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: '14px',
                    cursor: 'pointer',
                    display: 'inline-block',
                    maxWidth: 150, // Adjust as necessary
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <span
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Awaiting Approval
                  </span>
                </Typography>
              </Tooltip>
            </Label>
          </Stack>
        ) : (
          ''
        )}

        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Tooltip title={name}>
            {/* <Typography
              onClick={
                CommunityProjects
                  ? explodedPermissions?.some((permission) => [ 'Add Jobs', 'Edit Jobs', 'Activate/Deactivate Jobs', 'Delete Jobs', 'Manage Job Cost', ].includes(permission), ) || userType == 3
                    ? () => navigate(PATH_DASHBOARD.project.viewproject + id)
                    : () => enqueueSnackbar( 'Access Denied: You do not have sufficient permissions', { variant: 'error' },)
                  : quotationView
                        ? quotation_status != 6
                          ? () => quotation_status === 4 && quotation === 0
                                ? (explodedPermissions?.some((permission) =>['View Estimate, Edit Estimate, Delete Estimate'].includes(permission)) || (userType === 3 && status !== 6))
                                  ? () => handleOpenDetails(id, name)
                                  : () => enqueueSnackbar('Access Denied: You do not have sufficient permissions', { variant: 'error' })
                                : (explodedPermissions?.some((permission) =>['Edit Estimate'].includes(permission)) || (userType === 3 && status !== 6))
                                  ? () => handleOpenViewEstimate(id, name)
                                  : () => enqueueSnackbar('Access Denied: You do not have sufficient permissions', { variant: 'error' })
                          : () => enqueueSnackbar( 'Access Denied: Rejected Quotation',{ variant: 'error' },)
                        : proposalView
                        ? proposal_count === 0
                          ? () => handleOpenViewPurchaseOrder(id, name)
                          : () => enqueueSnackbar( 'Access Denied: Proposals Submited', { variant: 'error' },)
                        : designOrder
                        ? (explodedPermissions?.some((permission) =>
                          ['View Design Order', 'Create Design Order', 'Edit Design Order', 'Delete Design Order'].includes(permission)
                        ) || (userType === 3 && status !== 6))
                          ? () => navigate(`/dashboard/design-order/view/${id}`)
                          : () => enqueueSnackbar('Access Denied: You do not have sufficient permissions', { variant: 'error' })
                      : explodedPermissions?.some((permission) =>
                        [
                          'Create Request',
                          'Edit Request',
                          'Delete request',
                          'Add vendor to request',
                          'Add client to request',
                        ].includes(permission),
                      ) ||
                      (userType === 3 && status !== 6)
                    ? userType !== 3
                    ? empty(installationStatus)
                      ? () =>
                            // navigate(`/dashboard/official-request/view/${id}`)
                            handleOpenViewSalesOrder(id, name)
                      : () => enqueueSnackbar( 'Access Denied: Moved to installation Phase',{ variant: 'error' },)
                    : quotationView
                    ? explodedPermissions?.some((permission) =>
                      ['View Estimate', 'Create Estimate', 'Edit Estimate', 'Delete Estimate'].includes(permission)
                    )
                      ? () => handleOpenDetails(id, name)
                      : () => enqueueSnackbar('Access Denied: You do not have sufficient permissions', { variant: 'error' })
                    // ? () =>
                    //     // navigate(`/dashboard/quick-bid/view/${id}`)
                    //     handleOpenDetails(id, name)
                    : designOrder
                    ? explodedPermissions?.some((permission) =>
                      ['View Design Order', 'Create Design Order', 'Edit Design Order', 'Delete Design Order'].includes(permission)
                    )
                      ? () => navigate(`/dashboard/design-order/view/${id}`)
                      : () => enqueueSnackbar('Access Denied: You do not have sufficient permissions', { variant: 'error' })
                    : proposalView
                    ? statusRfp === null || statusRfp === 1
                      ? () => handleOpenViewPurchaseOrder(id, name)
                      : // navigate(`/dashboard/rfp/view/${id}`, { state: true })
                        () => enqueueSnackbar('Access Denied: Proposal submission closed',{ variant: 'error' },)
                    : () =>
                        // navigate(`/dashboard/official-request/view/${id}`)
                        handleOpenDetailsSalesOrder(id, name)
                  : () => enqueueSnackbar('Access Denied: You do not have sufficient permissions',{ variant: 'error' },)
              }
              variant="subtitle1"
              sx={{
                mt: 2,
                ml: 2,
                maxWidth: userType != 3 && !CommunityProjects ? 150 : '70%',
                cursor: 'pointer',
                display: 'inline-block',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                color: theme.palette.primary.main,
                '&:hover': {
                  cursor: CommunityProjects
                    ? explodedPermissions?.some((permission) =>
                        [
                          'Add Jobs',
                          'Edit Jobs',
                          'Activate/Deactivate Jobs',
                          'Delete Jobs',
                          'Manage Job Cost',
                        ].includes(permission),
                      )
                      ? 'pointer'
                      : 'default'
                    : quotationView
                      ? explodedPermissions?.some((permission) =>
                        [ 'View Estimate', 'Create Estimate', 'Edit Estimate', 'Delete Estimate', ].includes(permission)
                      )
                      ? 'pointer'
                      : 'default'
                    : designOrder
                      ? explodedPermissions?.some((permission) =>
                        ['View Design Order', 'Create Design Order', 'Edit Design Order', 'Delete Design Order'].includes(permission)
                      )
                      ? 'pointer'
                      : 'default'
                    : (explodedPermissions?.some((permission) =>
                        [
                          'Create Request',
                          'Edit Request',
                          'Delete request',
                          'Add vendor to request',
                          'Add client to request',
                        ].includes(permission),
                      ) ||
                        (userType === 3 && status !== 6)) &&
                      !(userType === 3 && accept_status === 2)
                    ? 'pointer'
                    : 'default',
                },
              }}
            >
              <span
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {name}
              </span>
            </Typography> */}
            <Typography
              onClick={
                CommunityProjects
                  ? explodedPermissions?.some((permission) =>
                      ['Add Jobs', 'Edit Jobs', 'Activate/Deactivate Jobs', 'Delete Jobs', 'Manage Job Cost'].includes(permission)
                    ) || userType == 3
                    ? () => navigate(PATH_DASHBOARD.project.viewproject + id)
                    : () => enqueueSnackbar('Access Denied: You do not have sufficient permissions', { variant: 'error' })
                  : quotationView
                  ? quotation_status != 6
                    ? ((quotation_status === 4 && quotation === 0) || userType === 3)
                      ? explodedPermissions?.some((permission) =>
                          ['View Estimate', 'Edit Estimate', 'Delete Estimate'].includes(permission)
                        ) || (userType === 3 && status !== 6)
                        ? () => handleOpenDetails(id, name)
                        : () => enqueueSnackbar('Access Denied: You do not have sufficient permissions', { variant: 'error' })
                      : explodedPermissions?.some((permission) =>
                          ['Edit Estimate'].includes(permission)
                        ) || (userType === 3 && status !== 6)
                        ? () => handleOpenViewEstimate(id, name)
                        : () => enqueueSnackbar('Access Denied: You do not have sufficient permissions', { variant: 'error' })
                    : () => enqueueSnackbar('Access Denied: Rejected Quotation', { variant: 'error' })
                  : proposalView
                  ? proposal_count === 0
                    ? () => handleOpenViewPurchaseOrder(id, name)
                    : () => enqueueSnackbar('Access Denied: Proposals Submitted', { variant: 'error' })
                  : designOrder
                  ? explodedPermissions?.some((permission) =>
                      ['View Design Order', 'Edit Design Order', 'Delete Design Order'].includes(permission)
                    // ) || (userType === 3 && status !== 6)
                    ) || (userType === 3 )
                    ? () => navigate(`/dashboard/design-order/view/${id}`)
                    : () => enqueueSnackbar('Access Denied: You do not have sufficient permissions', { variant: 'error' })
                  : explodedPermissions?.some((permission) =>
                      ['Create Request', 'Edit Request', 'Delete request', 'Add vendor to request', 'Add client to request'].includes(permission)
                    ) || (userType === 3 && status !== 6)
                  ? userType !== 3
                    ? empty(installationStatus)
                      ? () => CommunityJob ? navigate(`/dashboard/official-request/view/${id}`, { state: { fromProjects: true } }) :communityDesignOrder ? navigate(`/dashboard/design-order/view/${id}`) : handleOpenViewSalesOrder(id, name)
                      : () => enqueueSnackbar('Access Denied: Moved to installation Phase', { variant: 'error' })
                    : quotationView
                    ? explodedPermissions?.some((permission) =>
                        ['View Estimate', 'Create Estimate', 'Edit Estimate', 'Delete Estimate'].includes(permission)
                      )
                      ? () => handleOpenDetails(id, name)
                      : () => enqueueSnackbar('Access Denied: You do not have sufficient permissions', { variant: 'error' })
                    : designOrder
                    ? explodedPermissions?.some((permission) =>
                        ['View Design Order', 'Edit Design Order', 'Delete Design Order'].includes(permission)
                      )
                      ? () => navigate(`/dashboard/design-order/view/${id}`)
                      : () => enqueueSnackbar('Access Denied: You do not have sufficient permissions', { variant: 'error' })
                    : proposalView
                    ? statusRfp === null || statusRfp === 1
                      ? () => handleOpenViewPurchaseOrder(id, name)
                      : () => enqueueSnackbar('Access Denied: Proposal submission closed', { variant: 'error' })
                    : () => CommunityJob ? navigate(`/dashboard/official-request/view/${id}`, { state: { fromProjects: true } }) : communityDesignOrder ? navigate(`/dashboard/design-order/view/${id}`) : handleOpenDetailsSalesOrder(id, name)
                  : () => enqueueSnackbar('Access Denied: You do not have sufficient permissions', { variant: 'error' })
              }
              variant="subtitle1"
              sx={{
                mt: 2,
                ml: 2,
                maxWidth: userType != 3 && !CommunityProjects ? 150 : '70%',
                cursor: 'pointer',
                display: 'inline-block',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                color: theme.palette.primary.main,
                '&:hover': {
                  cursor: CommunityProjects
                    ? explodedPermissions?.some((permission) =>
                        ['Add Jobs', 'Edit Jobs', 'Activate/Deactivate Jobs', 'Delete Jobs', 'Manage Job Cost'].includes(permission)
                      ) || (userType === 3 && status !== 6)
                      ? 'pointer'
                      : 'default'
                    : quotationView
                    ? explodedPermissions?.some((permission) =>
                        ['View Estimate', 'Edit Estimate', 'Delete Estimate'].includes(permission)
                      ) || (userType === 3 && status !== 6)
                      ? 'pointer'
                      : 'default'
                    : designOrder
                    ? explodedPermissions?.some((permission) =>
                        ['View Design Order', 'Edit Design Order', 'Delete Design Order'].includes(permission)
                      ) || (userType === 3 && status !== 6)
                      ? 'pointer'
                      : 'default'
                    : explodedPermissions?.some((permission) =>
                        ['Create Request', 'Edit Request', 'Delete request', 'Add vendor to request', 'Add client to request'].includes(permission)
                      ) || (userType === 3 && status !== 6)
                    ? 'pointer'
                    : 'default',
                },
              }}
            >
              <span
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {name}
              </span>
            </Typography>
          </Tooltip>
          {((userType != 3 && !CommunityProjects) || proposalView) && (
            <Typography
              onClick={
                CommunityProjects
                  ? explodedPermissions?.some((permission) =>
                      [
                        'Add Jobs',
                        'Edit Jobs',
                        'Activate/Deactivate Jobs',
                        'Delete Jobs',
                        'Manage Job Cost',
                      ].includes(permission),
                    ) || userType == 3
                    ? () => navigate(PATH_DASHBOARD.project.viewproject + id)
                    : () =>
                        enqueueSnackbar(
                          'Access Denied: You do not have sufficient permissions',
                          { variant: 'error' },
                        )
                  : quotationView
                    ? (explodedPermissions?.some((permission) =>
                      ['View Estimate', 'Edit Estimate', 'Delete Estimate'].includes(permission)
                    ) ||
                    (userType === 3 && status !== 6)) &&
                    !(userType === 3 && accept_status === 2)
                      ? () => handleOpenDetails(id, name)
                      : () => enqueueSnackbar('Access Denied: You do not have sufficient permissions', { variant: 'error' })
                      // ? () => handleOpenDetails(id, name)
                      // ? () => navigate(`/dashboard/quick-bid/view/${id}`)
                  : designOrder
                    ? (explodedPermissions?.some((permission) =>
                      ['View Design Order', 'Edit Design Order', 'Delete Design Order'].includes(permission)
                    ) ||
                    (userType === 3 && status !== 6)) &&
                    !(userType === 3 && accept_status === 2)
                      ? () => navigate(`/dashboard/design-order/view/${id}`)
                      : () => enqueueSnackbar('Access Denied: You do not have sufficient permissions', { variant: 'error' })
                  :(explodedPermissions?.some((permission) =>
                      [
                        'Edit Request',
                        'View request',
                        'Delete request',
                        'Add vendor to request',
                        'Add client to request',
                      ].includes(permission),
                    ) ||
                      (userType === 3 && status !== 6)) &&
                    !(userType === 3 && accept_status === 2)
                  ? proposalView
                    ? () => handleOpenDetailsPurchaseOrder(id, name, false)
                    : // navigate(`/dashboard/rfp/view/${id}`)
                      () =>
                        CommunityJob ? 
                        navigate(`/dashboard/official-request/view/${id}`, { state: { fromProjects: true } }) :
                        communityDesignOrder ? navigate(`/dashboard/design-order/view/${id}`) :
                        handleOpenDetailsSalesOrder(id, name)
                  : () =>
                      enqueueSnackbar(
                        'Access Denied: You do not have sufficient permissions',
                        { variant: 'error' },
                      )
              }
              variant="subtitle1"
              sx={{
                mt: 3,
                mr: 2,
                color: theme.palette.primary.main,

                '&:hover': {
                  cursor: CommunityProjects
                    ? explodedPermissions?.some((permission) =>
                        [
                          'Add Jobs',
                          'Edit Jobs',
                          'Activate/Deactivate Jobs',
                          'Delete Jobs',
                          'Manage Job Cost',
                        ].includes(permission),
                      )
                      ? 'pointer'
                      : 'default'
                    :  quotationView
                    ? (explodedPermissions?.some((permission) =>
                        ['View Estimate', 'Edit Estimate', 'Delete Estimate'].includes(permission)
                      ) ||
                      (userType === 3 && status !== 6)) &&
                      !(userType === 3 && accept_status === 2)
                      ? 'pointer'
                      : 'default'
                    :  designOrder
                    ? (explodedPermissions?.some((permission) =>
                        ['View Design Order', 'Edit Design Order', 'Delete Design Order'].includes(permission)
                      ) ||
                      (userType === 3 && status !== 6)) &&
                      !(userType === 3 && accept_status === 2)
                      ? 'pointer'
                      : 'default'
                    :
                    (explodedPermissions?.some((permission) =>
                        [
                          'Edit Request',
                          'View request',
                          'Delete request',
                          'Add vendor to request',
                          'Add client to request',
                        ].includes(permission),
                      ) ||
                        (userType === 3 && status !== 6)) &&
                      !(userType === 3 && accept_status === 2)
                    ? 'pointer'
                    : 'default',
                },
              }}
            >
              {quotationView || proposalView ? 'Details' : 'Details'}
              {/* {quotationView || proposalView ? 'Details' : 'Sales Order Details'} */}
            </Typography>
          )}
        </Stack>
        <Typography
          variant="overline"
          align="center"
          sx={{ color: 'text.secondary' }}
        ></Typography>

        {/* <Divider /> */}

        {/* <Box sx={{display:"flex",justifyContent:"center"}}>

       <img src={QuickbookLogo} width={"50px"} height={"30px"}/>
    </Box> */}

        <Typography
          variant="subtitle1"
          align="center"
          sx={{ color: 'text.secondary', mt: 2 }}
        >
          Last updated {getAgoString(timestamp)}
        </Typography>

        <Grid
          container
          sx={{
            textAlign: 'center',
            // justifyContent: 'space-between',
            justifyContent: syncWithQuickbooks ? 'space-between' : 'end',
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
            pb: 2,
            pr: 2,
          }}
        >
          {syncWithQuickbooks ? (
            <Box sx={{ display: 'flex' }}>
              <img
                src={QuickbookLogo}
                width={'50px'}
                height={'25px'}
                style={{ marginTop: '10px' }}
              />
              <Typography sx={{ marginTop: '13px', color: 'text.secondary' }}>
                {doc_number ? `#${doc_number}` : ''}
              </Typography>
            </Box>
          ) : (
            ''
          )}

          {(userType === 3 &&
            proposalView &&
            statusRfp === 2 &&
            invoice_grand_total !== null &&
            invoice_grand_total !== undefined) ||
          (userType !== 3 &&
            proposalView &&
            awarded &&
            invoice_grand_total !== null &&
            invoice_grand_total !== undefined) ? (
            <Typography
              variant="body2"
              align="center"
              sx={{ color: theme.palette.success.main }}
            >
              <RequestQuote
                sx={{
                  width: 20,
                  height: 20,
                  color: theme.palette.success.main,
                  mt: 1,
                }}
              />
              {invoice_grand_total.toFixed(2)}
            </Typography>
          ) : (userType === 3 && proposalView && statusRfp !== 2) ||
            (userType !== 3 &&
              proposalView &&
              !awarded) ? null : invoice_grand_total !== null &&
            invoice_grand_total !== undefined ? (
            <Typography
              variant="body2"
              align="center"
              sx={{ color: 'text.secondary' }}
            >
              <RequestQuote
                sx={{
                  width: 20,
                  height: 20,
                  color:
                    status === 4
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                  mt: 1,
                }}
              />
              {invoice_grand_total.toFixed(2)}
            </Typography>
          ) : invoice_total ? (
            <Typography
              variant="body2"
              align="center"
              sx={{ color: 'text.secondary' }}
            >
              <RequestQuote
                sx={{
                  width: 20,
                  height: 20,
                  color:
                    status === 4
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                  mt: 1,
                }}
              />
              {invoice_total.toFixed(2)}
            </Typography>
          ) : null}
        </Grid>
      </Card>


    </>
  )
}
