import React from 'react';
import { Box } from '@material-ui/core';

const TypingAnimation = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 0.5 }}>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <span className="dot" />
        <span className="dot" />
        <span className="dot" />
      </Box>
      <style>
        {`
          .dot {
            width: 2px;
            height: 2px;
            background-color:rgb(255, 255, 255);
            border-radius: 50%;
            animation: blink 1.4s infinite ease-in-out;
          }

          .dot:nth-child(2) {
            animation-delay: 0.2s;
          }

          .dot:nth-child(3) {
            animation-delay: 0.4s;
          }

          @keyframes blink {
            0%, 80%, 100% {
              opacity: 0.2;
            }
            40% {
              opacity: 1;
            }
          }
        `}
      </style>
    </Box>
  );
};

export default TypingAnimation;
