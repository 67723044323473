import React, { useState } from 'react';
import { Grid, Card, makeStyles, Checkbox, Box, Button, Typography } from '@material-ui/core';
import { Icon } from '@iconify/react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
import { useSnackbar } from 'notistack';


const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: theme.spacing(1), 
        height: '200px', 
        width:'200px',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative', 
    },
    delete:{
        m:2,
        display:'flex',
        justifyContent:'flex-end' 
      },
    video: {
        width: '100%',
        height: '100%', 
        objectFit: 'cover', 
    },
    checkboxContainer: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
    gridContainer: {
        height: '350px', 
        overflowY: 'auto', 
        '&::-webkit-scrollbar': {
            width: '0.4em',
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            borderRadius: '4px',
          },
    },
}));

export default function ProjectVideo({videos,refresh,setRefresh,projectId}) {
    const classes = useStyles();
    let user = JSON.parse(localStorage.getItem('user'))
    const {enqueueSnackbar} = useSnackbar()
    const [checkedItems, setCheckedItems] = useState([]);
    
    const handleCheckboxChange = (id) => {
        console.log(id)
        setCheckedItems((prevState) => {
            const isPresent = prevState.includes(id);
            const updatedState = isPresent
              ? prevState.filter((item) => item !== id)
              : [...prevState, id];
            return updatedState;
          });   
    };
const handleDelete=async()=>{
        try {
            console.log("delete")
            const res = await axios.post(`${REST_API_END_POINT}project-task/delete-project-attachment`,{
                userId:user.user_id,attachments:checkedItems,projectId, 
            })
            if(res.data.status===1){
                enqueueSnackbar(res.data.message,{variant:'success'}) 
                setRefresh(!refresh)
                setCheckedItems([])
            }else{
                enqueueSnackbar(res.data.message,{variant:'error'}) 
            }
        } catch (error) {
            console.log(error)
        }
      }
    return (
        <Grid className={classes.gridContainer}>
             {checkedItems.length > 0 &&(
                 <Box sx={{ mb: 3, display: 'flex', justifyContent: 'flex-end',gap:2,m:2.5,  }}>
                 <Typography  variant='subtitle1'>{checkedItems.length} selected</Typography>
                    <Button onClick={()=>handleDelete()} className={classes.delete} size='small' variant='outlined'>
                         <Icon icon={trash2Outline} width={24} height={24} /> Delete
                         </Button>
                </Box>)}
                {videos?.length>0 ?(
        <Grid container spacing={2}>
            {videos?.map((video) => (
                <Grid key={video.ID} item xs={12} md={6} pb={4}>
                    <Card className={classes.card}>
                        <video className={classes.video} controls>
                            <source src={video.file_url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <Box className={classes.checkboxContainer}>
                            <Checkbox sx={{cursor:'pointer'}}
                                    checked={checkedItems.includes(video.ID) ? true : false}
                                    onChange={() => handleCheckboxChange(video.ID)}
                                    color="primary"
                                />
                        </Box>
                    </Card>
                </Grid>
            ))}
        </Grid>):(
             <Box
             display={'flex'}
             justifyContent={'center'}
             alignItems={'center'}
             py={15}
           >
             No videos found
           </Box>
        )}
        </Grid>
    );
}
