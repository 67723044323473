// import { useEffect } from 'react';
// import { useNavigate, useParams, useLocation } from 'react-router-dom';
// // material
// import { Box, Divider } from '@material-ui/core';
// // redux
// import { useDispatch, useSelector } from '../../../../redux/store';
// import {
//   addRecipients,
//   onSendMessage,
//   getConversation,
//   getParticipants,
//   markConversationAsRead,
//   resetActiveConversation
// } from '../../../../redux/slices/chat';
// // routes
// //
// import { PATH_DASHBOARD } from 'src/routes/paths';
// import ChatRoomSignWise from './ChatRoomSignWise';
// import ChatHeaderDetailSignWise from './ChatHeaderDetailSignWise';
// import ChatHeaderComposeSignWise from './ChatHeaderComposeSignWise';
// import ChatMessageListSignWise from './ChatMessageList';
// import ChatMessageInputSignWise from './ChatMessageInputSignWise';

// // ----------------------------------------------------------------------

// const conversationSelector = (state) => {
//   const { conversations, activeConversationId } = state.chat;
//   const conversation = conversations.byId[activeConversationId];
//   if (conversation) {
//     return conversation;
//   }
//   return {
//     id: null,
//     messages: [],
//     participants: [],
//     unreadMessages: 0
//   };
// };

// export default function ChatScreenWindow({contact,openCompose,setCompose}) {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { pathname } = useLocation();
//   // const { conversationKey } = useParams();
//   const conversationKey = contact
//   const { contacts, recipients, participants, activeConversationId } = useSelector((state) => state.chat);
//   const conversation = useSelector((state) => conversationSelector(state));
//   const mode = openCompose ? 'COMPOSE' : 'DETAIL';
//   console.log('openCompose2', openCompose)
//   console.log('conversationKey', conversationKey,mode)
//   console.log('activeConversationId', activeConversationId)

//   const displayParticipants = participants.filter((item) => item.id !== '8864c717-587d-472a-929a-8e5f298024da-0');

//   useEffect(() => {
//     const getDetails = async () => {
//       dispatch(getParticipants(conversationKey));
//       try {
//         dispatch(getConversation(conversationKey));
//       } catch (error) {
//         console.error(error);
//         navigate(PATH_DASHBOARD.chatSignwise.new);
//       }
//     };
//     if (conversationKey) {
//       getDetails();
//     } else if (activeConversationId) {
//       dispatch(resetActiveConversation());
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [conversationKey]);

//   useEffect(() => {
//     if (activeConversationId) {
//       dispatch(markConversationAsRead(activeConversationId));
//     }
//   }, [dispatch, activeConversationId]);

//   const handleAddRecipient = (recipient) => {
//     dispatch(addRecipients(recipient));
//   };

//   const handleSendMessage = async (value) => {
//     try {
//       dispatch(onSendMessage(value));
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (
//     <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
//       {mode === 'DETAIL' ? (
//         <ChatHeaderDetailSignWise participants={displayParticipants} />
//       ) : (
//         <ChatHeaderComposeSignWise
//           recipients={recipients}
//           contacts={Object.values(contacts.byId)}
//           onAddRecipient={handleAddRecipient}
//         />
//       )}

//       <Divider />

//       <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
//         {!openCompose &&(<Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
//           <ChatMessageListSignWise conversation={conversation} />

//           <Divider />

//           <ChatMessageInputSignWise
//             conversationId={activeConversationId}
//             onSend={handleSendMessage}
//             disabled={pathname === PATH_DASHBOARD.chatSignwise.new}
//           />
//         </Box>)}

//         {mode === 'DETAIL' && <ChatRoomSignWise conversation={conversation} participants={displayParticipants} />}
//       </Box>
//     </Box>
//   );
// }


import { useEffect, useState } from 'react';
import { Box, Divider } from '@material-ui/core';
import ChatHeaderDetailSignWise from './ChatHeaderDetailSignWise';
import ChatMessageListSignWise from './ChatMessageList';
import ChatMessageInputSignWise from './ChatMessageInputSignWise';
import ChatHeaderComposeSignWise from './ChatHeaderComposeSignWise';
import ChatRoomSignWise from './ChatRoomSignWise';
import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';

export default function ChatScreenWindow({ contact,setSelectedContact,setRenderChat,renderChat,handleRefresh,openCompose, setCompose,conversationKey,teamMembers,refreshChat,chatOpenTime,setChatOpenTime,historyMessage,setHistoryMessage,selectedChatLogId,newChat,setNewChat }) {
  const [chatLogId, setChatLogId] = useState(null);
  const [message, setMessage] = useState('');
  const [image,setImage] = useState('')
  const [messages, setMessages] = useState([]);
  const [openSidebarRoom, setOpenSidebarRoom] = useState(true);
  const [groupName, setGroupName] = useState('');
  let user = JSON.parse(localStorage.getItem('user'))
  const conversationId = (contact?.conversation_type == "single") ? contact?.user_id : contact?.conversation_id || contact?.id
  const conversationIdForEvent = contact?.new_conversation_id || contact?.conversation_id || contact?.id
  console.log('contact@$^groupName', groupName)
  console.log('contact@$^contact', contact)
  const handleOpensidebar = () => {
    setOpenSidebarRoom(!openSidebarRoom);
  };
  // const mode = conversationKey ? 'DETAIL' : 'COMPOSE';
  console.log('messages+++++++++', messages)

  const [chat, setChat] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [typingUser, setTypingUser] = useState("");

  useEffect(() => {
    if (contact) {
      // ✅ Load previous messages (Simulated API Call)
      // setMessages([
      //   { id: 1, sender: contact.first_name, text: 'Hello!', type: 'received',profile_pic:contact.profile_pic },
      //   { id: 2, sender: 'You', text: 'Hi, how are you?', type: 'sent' }
      // ]);
      fetchMessages();
      fetchGroupName(contact);
      setMessage('');
      setImage('');
    }
  }, [contact,selectedChatLogId,newChat]);

  const fetchMessages = async () => {
    const response = await axios.post(`${REST_API_END_POINT}chat/fetch-messages`, 
      {conversationId, userId:user.user_id, conversationType: contact?.conversation_type,chatOpenTime,selectedChatLogId });
    if (response.data.status === 1) {
      const messageData = response.data.messageResult
      const historyMessageData = response.data.historyMessageResult
      let messageArray = [];
      let historyMessageArray = [];
      for(const message of messageData){
        let newData = {
          id : message.id,
          sender : message?.sender_id === user.user_id ? 'You' : message.full_name,
          text : message?.message || '',
          type : message?.sender_id === user.user_id ? 'sent' : 'received',
          profile_pic : message?.profile_pic || null,
          attachment : message?.attachment_url || null,
          contentType : message?.message_type || 'text',
          chat_log_id: message?.chat_log_id || null
        }
        messageArray.push(newData)
      }
      setMessages(messageArray)

      for(const message of historyMessageData){
        let newData = {
          id : message.id,
          sender : message?.sender_id === user.user_id ? 'You' : message.full_name,
          text : message?.message || '',
          type : message?.sender_id === user.user_id ? 'sent' : 'received',
          profile_pic : message?.profile_pic || null,
          attachment : message?.attachment_url || null,
          contentType : message?.message_type || 'text',
          chat_log_id: message?.chat_log_id || null,
          created_at:message?.created_at || null,
          chat_log_msg:message?.chat_log_msg || message?.message || '',
          chat_log_title:message?.chat_log_title || message?.message || '',
        }
        historyMessageArray.push(newData)
      }
      setHistoryMessage(historyMessageArray)

      // setMessages([
      //   { id: 1, sender: contact.first_name, text: 'Hello!', type: 'received',profile_pic:contact.profile_pic },
      //   { id: 2, sender: 'You', text: 'Hi, how are you?', type: 'sent' }
      // ]);

    }else{
      setMessages([]);
    }
  };
  const fetchGroupName = (contact) => {
    // Simulated API call to fetch group name
    const fetchedGroupName = contact.group_name || 'Unnamed Group';
    setGroupName(fetchedGroupName);
  };

  const handleSendMessage = async (value) => {
    console.log(`Sending message to ${contact?.full_name || contact?.group_name}:`, value);
    let chatLogId=messages[0]?.chat_log_id || null
    const response = await axios.post(`${REST_API_END_POINT}chat/send-message`, {
      chatLogId:chatLogId ||  null, 
      conversation_id : conversationId, 
      message : value?.message, 
      sender_id : user.user_id, 
      message_type : value?.contentType || 'text', 
      attachment_url : value?.contentType == "text" ? null : value?.attachment || null ,
      userType: user.user_type,
      conversationType: contact?.conversation_type == "single" ? 1 : 2
    });
    if (response.data.data) {
      fetchMessages(conversationId);
      handleRefresh()
    }else{
      fetchMessages(conversationId);
      handleRefresh()
    }
    // setMessages([...messages, { id: messages.length + 1, sender: 'You', text: value?.message, type: 'sent' }]);
  };

  // if (!contact) return <Box sx={{ flexGrow: 1, textAlign: 'center', p: 3 }}>Select a contact to start chatting</Box>;

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      {openCompose ? (
        <ChatHeaderComposeSignWise
          contacts={teamMembers}
          refreshChat={handleRefresh}
          setRenderChat={setRenderChat}
          renderChat={renderChat}
        />
      ) : (
        <ChatHeaderDetailSignWise refreshChat={refreshChat} handleRefresh={handleRefresh} allContacts={teamMembers} setRenderChat={setRenderChat} renderChat={renderChat} handleOpensidebar={handleOpensidebar} contact={{ ...contact, group_name: groupName }} setSelectedContact={setSelectedContact} setCompose={setCompose}/>
      )}
      <Divider />
      <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
        {!openCompose && (
          <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
      <ChatMessageListSignWise messages={messages} setMessages={setMessages} isTyping={isTyping} typingUser={typingUser} />
      <Divider />
      <ChatMessageInputSignWise 
        fetchMessages={fetchMessages} conversationId={conversationIdForEvent} 
        onSend={handleSendMessage} chat={chat} setChat={setChat} isTyping={isTyping} setIsTyping={setIsTyping} 
        message={message} setMessage={setMessage} image={image} setImage={setImage}
        typingUser={typingUser} setTypingUser={setTypingUser} handleRefresh={handleRefresh}
        refreshChat={refreshChat} setRenderChat ={setRenderChat} renderChat={renderChat}
      />
      </Box>
    )}
      {!openCompose && <ChatRoomSignWise handleRefresh={handleRefresh} openSidebar={openSidebarRoom} setOpenSidebar={setOpenSidebarRoom} conversation={{}} participants={contact} setSelectedContact={setSelectedContact} setCompose={setCompose}/>}
      </Box>
    </Box>
  );
}
