import faker from 'faker';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { io } from "socket.io-client";
import { useEffect, useRef, useState } from 'react';
import micFill from '@iconify/icons-eva/mic-fill';
import roundSend from '@iconify/icons-ic/round-send';
import attach2Fill from '@iconify/icons-eva/attach-2-fill';
import roundAddPhotoAlternate from '@iconify/icons-ic/round-add-photo-alternate';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Divider, IconButton, Stack, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button} from '@material-ui/core';
import EmojiPickerChatSignWise from 'src/components/EmojiPickerChatSignWise';
import { REST_API_IO } from 'src/constants/Defaultvalues';
import { useSnackbar } from 'notistack';
import { ref as imageref, uploadBytesResumable, getDownloadURL } from '@firebase/storage';
import { storage } from 'src/firebase/firebase';
import ReactQuill from 'react-quill';

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: 75,
  maxHeight: 75,
  minWidth:400,
  maxWidth:400,
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent:'center',
  paddingLeft: theme.spacing(2),
  width: '100%',
  '& .ql-toolbar': {
    border: 'none !important',
    padding: '4px !important',
    maxHeight: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  '& .ql-container': {
    border: 'none !important',
    fontSize: '14px',
    position: 'relative !important', 
  },
  '& .ql-editor': {
    maxHeight: '60px',
    overflowY: 'auto',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    padding: '4px !important',
    scrollbarWidth: 'thin', 
    scrollbarColor: ' #1a1a1a',
    // display: 'flex',
    // flexWrap: 'wrap',
    // alignItems: 'center',
  },
  '& .ql-toolbar button': {
    width: '15px',
    height: '15px',
    padding: '2px',
    color: '#d3d3d3!important',
  },
  '& .ql-toolbar button svg': {
    width: '16px',
    height: '16px',
  },
  '& .ql-toolbar button:hover': {
    color: '#1ccaff !important', 
  },
  '& .ql-toolbar .ql-active': {
    color: '#1ccaff !important', 
  },
  '& .ql-editor::before': {
    color: '#d3d3d3 !important',
    fontStyle: 'normal !important',
    fontSize:'14px !important'
  },
  '& .ql-tooltip': {
    zIndex: 1050,
    position: 'absolute !important',
    left: '50% !important',
    transform: 'translateX(-50%) !important',
    top: '-40px !important',
    backgroundColor: '#00191e !important',
    color: 'white !important',
    padding: '6px 8px !important',
    borderRadius: '6px !important',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2) !important',
    border: '1px solid #555 !important',
    fontSize: '12px !important', 
  },

  '& .ql-tooltip input': {
    backgroundColor: '#00191e !important', 
    color: '#f5f5f5 !important',
    padding: '4px !important',
    borderRadius: '4px !important',
    border: '1px solid #555 !important',
    outline: 'none !important',
  },

  '& .ql-tooltip a.ql-action': {
    color: '#1ccaff !important',
  },

  '& .ql-tooltip a.ql-remove': {
    color: '#ff4d4d !important',
  }
}));



ChatMessageInputSignWise.propTypes = {
  disabled: PropTypes.bool,
  conversationId: PropTypes.string,
  onSend: PropTypes.func
};

export default function ChatMessageInputSignWise({
  disabled, handleRefresh, refreshChat, setRenderChat, renderChat, 
  message, setMessage, image, setImage, fetchMessages, conversationId, 
  onSend, chat, setChat, isTyping, setIsTyping, typingUser, setTypingUser, ...other
}) {
  const fileInputRef = useRef(null);
  const [progress, setProgress] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  let user = JSON.parse(localStorage.getItem('user'));
  let updatedUserId = `user_${user.user_id}`;
  const typingTimeout = useRef(null);
  const socketRef = useRef(null);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [imagePreview, setImagePreview] = useState('');

  useEffect(() => {
    socketRef.current = io(REST_API_IO, { path: "/backend/socket.io", transports: ["websocket", "polling"], secure: true });

    socketRef.current.on("message", (msg) => {
      let receivedConversationId = String(msg.conversationId);
      let senderConversationId = String(msg.senderId);
      if (conversationId.startsWith("user_")) {
        if (!receivedConversationId.startsWith("user_")) receivedConversationId = `user_${receivedConversationId}`;
        if (!senderConversationId.startsWith("user_")) senderConversationId = `user_${senderConversationId}`;
      }
      if (receivedConversationId === updatedUserId || receivedConversationId === conversationId || senderConversationId === conversationId) {
        setChat((prev) => [...prev, msg]);
        if (receivedConversationId === conversationId) {
          fetchMessages(conversationId);
          setRenderChat(!renderChat);
          handleRefresh();
        } else {
          setTimeout(() => {
            fetchMessages(conversationId);
            setRenderChat(!renderChat);
            handleRefresh();
          }, 1000);
        }
      }
    });

    socketRef.current.on("typing", (data) => {
      let receivedConversationId = String(data.conversationId);
      let senderConversationId = String(data.senderId);
      if (conversationId.startsWith("user_")) {
        if (!receivedConversationId.startsWith("user_")) receivedConversationId = `user_${receivedConversationId}`;
        if (!senderConversationId.startsWith("user_")) senderConversationId = `user_${senderConversationId}`;
      }
      if (receivedConversationId === conversationId || senderConversationId === conversationId) {
        setTypingUser(data.user);
        setIsTyping(true);
      }
    });

    socketRef.current.on("stop typing", (data) => {
      let receivedConversationId = String(data.conversationId);
      let senderConversationId = String(data.senderId);
      if (conversationId.startsWith("user_")) {
        if (!receivedConversationId.startsWith("user_")) receivedConversationId = `user_${receivedConversationId}`;
        if (!senderConversationId.startsWith("user_")) senderConversationId = `user_${senderConversationId}`;
      }
      if (receivedConversationId === conversationId || senderConversationId === conversationId) {
        setIsTyping(false);
      }
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [conversationId]);

  const handleTyping = () => {
    socketRef.current.emit("typing", { user, conversationId, senderId: user.user_id });
    if (typingTimeout.current) clearTimeout(typingTimeout.current);
    typingTimeout.current = setTimeout(() => {
      socketRef.current.emit("stop typing", { conversationId, senderId: user.user_id });
    }, 2000);
  };

  const handleAttach = () => {
    fileInputRef.current.click();
  };
  const quillModules = {
    toolbar: {
      container: [['bold', 'italic', 'underline', 'strike', 'clean', 'link', 'image']],
      handlers: {
        image: handleAttach,
      }
    },
    clipboard: {
      matchVisual: false, 
    }
  };
  const sendMessage = () => {
    const newMessage = { conversationId, message, sender: user, senderId: user.user_id };
    socketRef.current.emit("message", newMessage);
    setMessage("");
    clearTimeout(typingTimeout.current);
    socketRef.current.emit("stop typing", { conversationId, senderId: user.user_id });
  };

  const handleSend = () => {
    let cleanedMessage = message.replace(/<p><br><\/p>/g, '').trim();
    if (!cleanedMessage && !image) return;
    sendMessage();
    if (onSend) {
      onSend({
        conversationId,
        messageId: faker.datatype.uuid(),
        message:cleanedMessage || '',
        contentType: image ? 'image' : 'text',
        attachment: image,
        createdAt: new Date(),
        senderId: user?.user_id
      });
    }
    setMessage(prev => prev.replace(/<p>/g, '').replace(/<\/p>/g, '').trim());
    setImage('');
    setOpenImagePreview(false);
  };
  const handleSendStickers = (messageContent = message, attachmentUrl = null) => {
    if (!messageContent.trim() && !attachmentUrl) return;

    sendMessage();

    if (onSend) {
      onSend({
        conversationId,
        messageId: faker.datatype.uuid(),
        message: messageContent,
        contentType: attachmentUrl ? 'gif' : image ? 'image' : 'text',
        attachment: attachmentUrl || image || '',
        createdAt: new Date(),
        senderId: user?.user_id
      });
    }

    setMessage('');
    setImage('');
    setOpenImagePreview(false);
};

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    event.target.value = '';
    const filename = new Date().getTime() + file.name;
    const storageRef = imageref(storage, filename);
    const uploadTask = uploadBytesResumable(storageRef, file);
    
    uploadTask.on(
      "state_changed",
      () => setProgress(true),
      (error) => {
        console.error(error.message);
        setProgress(false);
        enqueueSnackbar("Please upload an image less than 3 MB", { variant: "error" });
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImagePreview(downloadURL);
          setImage(downloadURL); 
          setOpenImagePreview(!openImagePreview);
          // setMessage(prevMessage => `${prevMessage}<img src="${downloadURL}" alt="uploaded image" style="max-width: 100%; height: auto;"/>`);
          setProgress(false);
        });
      }
    );
  };
  const handleCloseModal = () => {
    setOpenImagePreview(false);
    setImagePreview('');
    setImage('');
    setMessage('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <RootStyle {...other}>
      <ReactQuill
        value={message}
        onChange={(val) => {
          setMessage(val);
          handleTyping();
        }}
        modules={quillModules}
        placeholder="Type a message..."
        style={{ flexGrow: 1, maxHeight: '50px' }}
        onKeyUp={(event) => {
          if (event.key === 'Enter' || event.keyCode === 13) {
            handleSend();
          }
        }}
      />
      <Divider orientation="vertical" flexItem />
      <Stack direction="row" spacing={0.5} sx={{mx:1}} alignItems='center' justifyContent='center'>
            <EmojiPickerChatSignWise disabled={disabled} value={message} setValue={setMessage} onSend={handleSendStickers}/>
            <IconButton color="primary" disabled={!message.replace(/<(.|\n)*?>/g, '').trim() && !image} onClick={handleSend} >
        {progress ? <CircularProgress size={15} color="primary" /> : <Icon icon={roundSend} width={24} height={24} />}
      </IconButton>
          </Stack>
      <input type="file" ref={fileInputRef} onChange={handleFileChange} style={{ display: 'none' }} />
      <Dialog open={openImagePreview} onClose={handleCloseModal}>
      <DialogTitle>Image Preview</DialogTitle>
      <DialogContent 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
            overflow: 'hidden'
          }}
        >
        <img src={imagePreview} alt="Selected" style={{ width: '100%', maxHeight: '250px', objectFit: 'contain',marginBottom:'1rem' }} />
        <RootStyle {...other}>
        <ReactQuill
          value={message}
          onChange={(val) => {
            setMessage(val);
          }}
          modules={quillModules}
          placeholder="Type a message..."
          style={{ marginTop: '80px', height: '150px' }}
          onKeyUp={(event) => {
            if (event.key === 'Enter' || event.keyCode === 13) {
              handleSend();
            }
          }}
        />
      <Stack direction="row" spacing={0.5} sx={{mx:1}} alignItems='center' justifyContent='center'>
      <Divider orientation="vertical" flexItem />
            <EmojiPickerChatSignWise disabled={disabled} value={message} setValue={setMessage} onSend={handleSendStickers}/>
            <IconButton color="primary" disabled={!message.replace(/<(.|\n)*?>/g, '').trim() && !image}  onClick={handleSend} >
        {progress ? <CircularProgress size={15} color="primary" /> : <Icon icon={roundSend} width={24} height={24} />}
      </IconButton>
          </Stack>
          </RootStyle>
      </DialogContent>
      <DialogActions sx={{ padding: '0px !important' }} >
      <Button sx={{mx:2,my:1}} onClick={handleCloseModal} color="primary">Cancel</Button>
      </DialogActions>
    </Dialog>
    </RootStyle>
  );
}
