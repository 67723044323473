import * as Yup from 'yup'
import React, { useState, useEffect, useRef } from 'react'
import {
  GoogleMap,
  LoadScript,
  InfoWindow,
  DirectionsRenderer,
  Marker,
} from '@react-google-maps/api'
import LocationDetails from './LocationDetails'
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill'
import {
  defaultZipCode,
  GOOGLE_API_KEY,
  PUBNUB_PUBLISH_KEY,
  PUBNUB_SECRET_KEY,
  PUBNUB_SUBSCRIBE_KEY,
  REST_API,
  REST_API_END_POINT,
} from 'src/constants/Defaultvalues'
import PubNub from 'pubnub'
import axios from 'axios'
import { Form, FormikProvider, useFormik } from 'formik'
import {
  Box,
  Card,
  Grid,
  Stack,
  TextField,
  Button,
  Autocomplete,
  Typography,
  CardContent,
  Tab,
  Tabs,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Chip,
  Avatar,
  Divider,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Popover,
} from '@material-ui/core'
import { LoadingButton, TabContext, TabList, TabPanel } from '@material-ui/lab'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import LoadingScreen from 'src/components/LoadingScreen'
import { useSnackbar } from 'notistack'
import { Delete, KeyboardBackspaceOutlined, Place } from '@material-ui/icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import homeIcon from '../../../src/assets/active-routes-home.png'
import { Block } from '../components-overview/Block'
import MapComponent from './TrackDriver'
import { MButtonGroup } from 'src/components/@material-extend'

import truckIcon from 'src/assets/delivery.svg'
import installationIcon from 'src/assets/2jKMY8AFOqiToSHy1XkhiN26QCc.svg'
import car from 'src/assets/car-removebg-preview.png'
import truck from 'src/assets/semi.png'
import Scrollbar from 'src/components/Scrollbar'
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs'
import ConfirmationPopup from 'src/components/_dashboard/official-request/ConfirmationPopup'
import Icon from '@iconify/react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick'
import moment from 'moment'

const mapContainerStyle = {
  width: '100%',
  height: '70vh',
}

const locations = [
  {
    name: '123 Main Street, Los Angeles, CA 90001',
    lat: 34.0522,
    lng: -118.2437,
  },
  {
    name: '456 Oak Avenue, San Francisco, CA 94102a',
    lat: 37.7749,
    lng: -122.4194,
  },
  {
    name: '789 Maple Drive, San Diego, CA 92101',
    lat: 32.7157,
    lng: -117.1611,
  },
  {
    name: '101 Pine Street, Sacramento, CA 95814',
    lat: 38.5816,
    lng: -121.4944,
  },
  {
    name: '222 Beach Boulevard, Santa Monica, CA 90401',
    lat: 34.0195,
    lng: -118.4912,
  },
  {
    name: '333 Sunset Avenue, Oakland, CA 94601',
    lat: 37.8044,
    lng: -122.2711,
  },
  {
    name: '444 Broadway Street, San Jose, CA 95113',
    lat: 37.3382,
    lng: -121.8863,
  },
]
// Function to calculate distance between two points
function calculateDistance(point1, point2) {
  const lat1 = point1.lat
  const lon1 = point1.lng
  const lat2 = point2.lat
  const lon2 = point2.lng

  const R = 6371 // Radius of the Earth in km
  const dLat = ((lat2 - lat1) * Math.PI) / 180
  const dLon = ((lon2 - lon1) * Math.PI) / 180
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = R * c
  return distance
}

// Function to solve TSP using greedy algorithm
function tspSolver(locations) {
  const numLocations = locations?.length
  const visited = new Array(numLocations).fill(false)
  const path = []
  let totalDistance = 0

  // Start from the first location
  let currentLocation = 0
  path.push(currentLocation)
  visited[currentLocation] = true

  // Greedily select the nearest unvisited location until all locations are visited
  while (path.length < numLocations) {
    let minDistance = Infinity
    let nearestLocation = -1

    for (let i = 0; i < numLocations; i++) {
      if (!visited[i]) {
        const distance = calculateDistance(
          locations[currentLocation],
          locations[i],
        )
        if (distance < minDistance) {
          minDistance = distance
          nearestLocation = i
        }
      }
    }

    // Mark the nearest location as visited and add it to the path
    visited[nearestLocation] = true
    path.push(nearestLocation)
    totalDistance += minDistance
    currentLocation = nearestLocation
  }

  // Return the path (indices of locations)
  return path
}

const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  '& > *': { mx: '8px !important' },
}

const SIMPLE_TAB = [
  { value: '1', label: 'Active Routes', disabled: false },
  { value: '2', label: 'Track Driver', disabled: false },
]

const statusFilter = [
  { status: 1, label: 'Awaiting' },
  { status: 2, label: 'Accepted / In-transit' },
]
const containerStyle = {
  width: '100%',
  height: '500px',
}

const center = {
  lat: 34.0522,
  lng: -118.2437,
}

const MapComponent2 = ({
  driverDataaa,
  home,
  details,
  selectedSalesOrder,
  setSelectedSalesOrder,
  selectedRoute,
  setSelectedRoute,
  locations,
  setLocations,
  directions,
  setDirections,
  getLatandLongFromAddress,
  selectedData,
  isFromPs,
  locationDetails,
  requestProducts,
  setRquestProducts,
  additionalSelectedServices,
  setAdditionalSelectedServices,
  selectedRfpServices,
  selectedRfpProduct,
  render
}) => {
  const [markers, setMarkers] = useState([])
  const [apiLoaded, setApiLoaded] = useState(false)
  const [selectedMarker, setSelectedMarker] = useState(null)
  const [selectedDriverId, setSelectedDriverId] = useState(null)
  const [selectedDriver, setSelectedDriver] = useState(null)
  const [selectProject, setSelectProject] = useState(null)
  const [isDriver, setIsDriver] = useState(false)
  const [selectedTeam, setSelectedTeam] = useState(null)
  const [selectTemplate, setSelectTemplate] = useState(null)
  const [templates, setTemplates] = useState([])
  const [salesOrderData, setSalesOrderData] = useState([])
  const [routeData, setRouteData] = useState([])
  // const [locations, setLocations] = useState([]);
  // const [directions, setDirections] = useState([]);
  const [originalDriverId, setOriginalDriverId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingTeam, setLoadingTeam] = useState(false)
  const [previosSalesOrderId, setPreviosSalesOrderid] = useState(null)
  const navigate = useNavigate()
  const [selectedOriginalDriverId, setSelectedOriginalDriverId] = useState(null)
  const [updatedRoute, setUpdatedRoute] = useState(false)
  const [allAppointmentData, setAllAppointmentData] = useState([])
  const [teamMembers, setTeamMembers] = useState([])
  const [addedLocations, setAddedLocations] = useState([])
  const [deleteLocationIndex, setDeleteLocationIndex] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [allProducts, setAllproducts] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const [tooltipInfo, setTooltipInfo] = useState([])
  const [debouncedFetch, setDebouncedFetch] = useState(false);
  // const [geocoder] = useState(new window.google.maps.Geocoder());
  const { enqueueSnackbar } = useSnackbar()
  const userData = JSON.parse(window.localStorage.getItem('user'))
  const userId = userData.user_id

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const popoverTimer = useRef(null);
  
  const handlePopoverOpen = (event, location) => {
    clearTimeout(popoverTimer.current);

    let updatedLocation = { ...location };
    if (location.service_id !== null) {
      updatedLocation.attachments = location?.attachments?.length
        ? location.attachments
        : location?.service_image
        ? [{ name: location.service_image }]
        : [];
    }
  
    popoverTimer.current = setTimeout(() => {
      setMousePosition({ x: event.clientX ?? event?.domEvent?.clientX, y: event.clientY ?? event?.domEvent?.clientY });
      setAnchorEl(event.target ?? event?.domEvent?.target);
      setSelectedLocation(updatedLocation);
      console.log("Popover Opened at:", event.clientX ?? event?.domEvent?.clientX, event.clientY ?? event?.domEvent?.clientY);
      console.log("Updated Location Data:", updatedLocation);
    }, 200);
  };
  
  const handlePopoverClose = () => {
    clearTimeout(popoverTimer.current);
    setAnchorEl(null);
    setSelectedLocation(null);
  };  

  const open = Boolean(anchorEl);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 2000, // Changes every 2 seconds
    beforeChange: (oldIndex, newIndex) => setCurrentImageIndex(newIndex), // Track current slide
    customPaging: (i) => (
      <div
        style={{
          width: "6px",
          height: "6px",
          borderRadius: "50%",
          backgroundColor: i === currentImageIndex ? "#007BFF" : "#ccc", // Active dot color
          margin: "0px",
        }}
      />
    ),
    dotsClass: "slick-dots slick-thumb",
  };

console.log("addedLocations>>>",addedLocations)
  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true)
      try {
        const response = await axios.post(
          `${REST_API_END_POINT}route/get-all-products-for-wed-route`,
        )
        const productListData = response.data.results
        if (productListData) {
          setAllproducts(productListData)
        } else {
          setAllproducts([])
        }
      } catch (error) {
        console.error('Error fetching data:', error)
        setAllproducts([])
      } finally {
        setLoading(false)
      }
    }
    fetchProducts()
  }, [])

  const generateRandomMarkers = (qty, center, color) => {
    const markers = []
    for (let i = 0; i < qty; i++) {
      const randomLat = center.lat + (Math.random() - 0.5) * 0.02
      const randomLng = center.lng + (Math.random() - 0.5) * 0.02
      markers.push({
        lat: randomLat,
        lng: randomLng,
        color,
      })
    }
    return markers
  }

  const generateRandomColor = () => {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }

  const handleMarkerDrag = async (event, index, location) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    console.log("locationData---", location);
  
    const updatedLocations = [...addedLocations];
    updatedLocations[index] = {
      ...updatedLocations[index],
      lat: newLat,
      lng: newLng,
    };
  
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: { lat: newLat, lng: newLng } }, async (results, status) => {
      if (status === "OK" && results[0]) {
        const components = results[0].address_components;
        const plusCode = components.find((c) => c.types.includes("plus_code"))?.long_name || null;
        const streetNumber = components.find((c) => c.types.includes("street_number"))?.long_name || "";
        const route = components.find((c) => c.types.includes("route"))?.long_name || "";
        const address = plusCode || (streetNumber || route ? `${streetNumber} ${route}`.trim() : null);
        const country = components.find((c) => c.types.includes("country"))?.long_name || null;
        const city = components.find((c) => c.types.includes("locality"))?.long_name || null;
        const state = components.find((c) => c.types.includes("administrative_area_level_1"))?.short_name || null;
        const zip = components.find((c) => c.types.includes("postal_code"))?.long_name || null;
  
        updatedLocations[index].address = address;
        updatedLocations[index].city = city;
        updatedLocations[index].state = state;
        updatedLocations[index].zip = zip;
        updatedLocations[index].country = country;
        updatedLocations[index].wholeAddress = results[0].formatted_address;
        
        if(location?.type === 'product'){
          await handleUpdateProductsAddress({
            // order_id: location?.order_id || location?.id,
            location_id: location?.id,
            address: address,
            city: city,
            state: state,
            zip: zip,
            lat:newLat,
            lng:newLng
          });
        }
        if(location?.type === 'service'){
          await handleUpdateServiceAddress({
            // service_id: location?.service_id,
            location_id: location?.id,
            address: address,
            city: city,
            state: state,
            zip: zip,
            lat:newLat,
            lng:newLng
          });
        }
        setAddedLocations(updatedLocations);
      }
    });
  };
  
  const handleUpdateProductsAddress = async (data) => {
    console.log('productsssssssss' , data)
    try {
      const response = await axios.post(`${REST_API_END_POINT}rfq/update-products-route`, data);
      if (response.data.status === 1) {
        enqueueSnackbar(response.data.message, { variant: "success" });
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
      }
    } catch (error) {
      console.log("Server error:", error);
      enqueueSnackbar("Server error", { variant: "error" });
    }
  };
  const handleUpdateServiceAddress = async (data) => {
    console.log("view dataaaa>",data)
    try {
      const response = await axios.post(`${REST_API_END_POINT}rfq/update-service-route`, data);
      if (response.data.status === 1) {
        console.log("address updated")
        enqueueSnackbar(response.data.message, { variant: "success" });
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
      }
    } catch (error) {
      console.log("Server error:", error);
      enqueueSnackbar("Server error", { variant: "error" });
    }
  };

  const handleMapClick = (event) => {
    const lat = event.latLng.lat()
    const lng = event.latLng.lng()

    // Reverse Geocode to get the address
    const geocoder = new google.maps.Geocoder()
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      console.log('results=========', results)
      console.log('status=========', status)
      if (status === 'OK' && results[0]) {
        const components = results[0].address_components

        const plusCode =
          components.find((c) => c.types.includes('plus_code'))?.long_name ||
          null
        // const address = plusCode || components.find((c) => c.types.includes("street_address"))?.long_name || null;
        const streetNumber =
          components.find((c) => c.types.includes('street_number'))
            ?.long_name || ''
        const route =
          components.find((c) => c.types.includes('route'))?.long_name || ''
        const address =
          plusCode ||
          (streetNumber || route ? `${streetNumber} ${route}`.trim() : null)
        const country =
          components.find((c) => c.types.includes('country'))?.long_name || null
        const city =
          components.find((c) => c.types.includes('locality'))?.long_name ||
          null
        const state =
          components.find((c) =>
            c.types.includes('administrative_area_level_1'),
          )?.short_name || null
        const zip =
          components.find((c) => c.types.includes('postal_code'))?.long_name ||
          null
        const wholeAddress = results[0].formatted_address || null

        const newLocations = [
          ...addedLocations,
          { lat, lng, address, city, state, zip, country, wholeAddress },
        ]
        setAddedLocations(newLocations)
        calculateRoutes(newLocations)
      } else {
        console.error('Geocoder failed due to: ' + status)
      }
    })
  }

  const handleMarkerDelete = () => {
    // Create a new array without the deleted location
    const updatedLocations = addedLocations.filter(
      (_, i) => i !== deleteLocationIndex,
    )
    setDeleteLocationIndex(null)
    // Update state and recalculate the route
    setAddedLocations(updatedLocations)
    calculateRoutes(updatedLocations)
  }

  const handleFetchLocations = async () => {
    try {
      let productLocations = []
      let serviceLocations = []
      let rfpProductLocations = []
      let rfpServiceLocations = []
      if (requestProducts?.length > 0) {
        const routeData = locationDetails || null
        const productData = requestProducts || null
        console.log('viewroute---allproducts', allProducts)
        console.log('viewroute---routeData', routeData)
        console.log('viewroute---productData', productData)
        let newProducts = productData
        ? await Promise.all(
            productData.map(async (item) => ({
              ...item, 
              color: item?.color || generateRandomColor(),
              quantity: item?.quantity || 0,
            }))
          )
        : [];
        console.log('newProducts=1====', newProducts)
        setSelectedProducts(newProducts?.filter(Boolean)) 
        productLocations =
          routeData &&
          (await Promise.all(
            routeData?.map(async (item) => {
              const product = newProducts?.find(
                (prod) => prod.id == item.product_id,
              )
              let lat = item.lat ? parseFloat(item.lat) : null
              let lng = item.lng ? parseFloat(item.lng) : null
              // console.log('location1>>>', product)
              let installationAddress = `${item?.address}, ${item?.city}, ${item?.state}, ${item?.zip}`
              if (isFromPs && (!lat || !lng)) {
                installationAddress = `${item?.address}, ${item?.city}, ${item?.state}, ${item?.zip}`
                try {
                  let latLong = await getLatandLongFromAddress(
                    installationAddress,
                  )
                  lat = latLong?.Latitude || lat
                  lng = latLong?.Longitude || lng
                  if(lat && lng){
                    await handleUpdateProductsAddress({
                      // order_id: product?.location[0]?.order_id,
                      // order_id: item?.order_id || item?.id,
                      location_id: item?.id,
                      lat:lat,
                      lng:lng,
                    });
                  }
                } catch (error) {
                  console.error('Error fetching lat/long:', error)
                }
              }
              
              return {
                lat,
                lng,
                is_dos: item?.is_dos || null,
                address: item.address || null, // Use geocoded address if available
                city: item.city || null,
                state: item.state || null,
                zip: item.zip || null,
                color: product ? product.color : 'black',
                productId: product ? product.id : null,
                order_id: item?.order_id || null,
                location_id: item?.id,
                type:'product',
                productName: product ? product.name : null,
                service_image: product?.service_image || null,
                country: item.country || 'USA',
                attachments:product.attachments || [],
                quantity:product.quantity || 0,
                wholeAddress: item.wholeAddress || installationAddress || null,
              }
            }),
          ))
      }

      if (additionalSelectedServices?.length > 0) {
        const updatedAdditionalServices = additionalSelectedServices.map(
          (item) => ({
            ...item,
            color: item.color || generateRandomColor(),
          }),
        )
        if (
          JSON.stringify(updatedAdditionalServices) !==
          JSON.stringify(additionalSelectedServices)
        ) {
          setAdditionalSelectedServices(updatedAdditionalServices)
        }

        serviceLocations = await Promise.all(
          updatedAdditionalServices.map(async (item) => {
            let lat = item.lat ? parseFloat(item.lat) : null
            let lng = item.lng ? parseFloat(item.lng) : null
            let serviceAddress = `${item?.address}, ${item?.city}, ${item?.state}, ${item?.zip}`

            if (!lat || !lng) {
              serviceAddress = `${item?.address}, ${item?.city}, ${item?.state}, ${item?.zip}`
              try {
                let latLong = await getLatandLongFromAddress(serviceAddress)
                lat = latLong?.Latitude || lat
                lng = latLong?.Longitude || lng
                if(lat && lng){
                  await handleUpdateServiceAddress({
                    service_id: item?.id,
                    lat:lat,
                    lng:lng,
                    is_lat_lng:true
                  });
                }
              } catch (error) {
                console.error('Error fetching lat/long:', error)
              }
            }

            console.log('service_item', item)
            return {
              lat,
              lng,
              is_dos: (item?.is_dos === 1 || item?.service_type === 33) ? 1 : null,
              address: item.address || null,
              city: item.city || null,
              state: item.state || null,
              zip: item.zip || null,
              color: item.color,
              productId: item?.id || null,
              service_id: item?.id || null,
              type:'service',
              productName: item?.name || null,
              service_image: item?.service_image || null,
              country: item.country || 'USA',
              quantity:item.quantity || 0,
              sales_order:item.request_name || '',
              wholeAddress: item.wholeAddress || serviceAddress || null,
            }
          }),
        )
      }

      if (selectedRfpServices?.length > 0) {
        rfpServiceLocations = await Promise.all(
          selectedRfpServices.map(async (item) => {
            let lat = item.lat ? parseFloat(item.lat) : null
            let lng = item.lng ? parseFloat(item.lng) : null
            let serviceAddress = `${item?.address}, ${item?.city}, ${item?.state}, ${item?.zip}`
            console.log('service_item_rfp', item)
            return {
              lat,
              lng,
              is_dos: (item?.is_dos === 1 || item?.service_type === 33) ? 1 : null,
              address: item.address || null,
              city: item.city || null,
              state: item.state || null,
              zip: item.zip || null,
              color: item.color,
              productId: item?.id || null,
              service_id:item?.id || null,
              type:'service',
              productName: item?.name || null,
              service_image: item?.service_image || null,
              country: item.country || 'USA',
              quantity:item.quantity || 0,
              sales_order:item.request_name || '',
              wholeAddress: item.wholeAddress || serviceAddress || null,
            }
          }),
        )
      }
      if (selectedRfpProduct?.length > 0) {
        rfpProductLocations = await Promise.all(
          selectedRfpProduct.map(async (item) => {
            let lat = item.lat ? parseFloat(item.lat) : null
            let lng = item.lng ? parseFloat(item.lng) : null
            let serviceAddress = `${item?.address}, ${item?.city}, ${item?.state}, ${item?.zip}`

            return {
              ...item,
              lat,
              lng,
              is_dos: item?.is_dos || null,
              address: item.address || null,
              city: item.city || null,
              state: item.state || null,
              zip: item.zip || null,
              color: item.color,
              productId: item?.product_id || null,
              type:'product',
              order_id:item?.customer_order_product_id,
              location_id: item?.id,
              attachments:item.attachments || [],
              productName: item?.product_name || null,
              service_image: item?.service_image || null,
              country: item.country || 'USA',
              quantity:item.quantity || 0,
              wholeAddress: item.wholeAddress || serviceAddress || null,
            }
          }),
        )
      }
      if (
        productLocations?.length > 0 ||
        serviceLocations?.length > 0 ||
        rfpProductLocations?.length > 0 ||
        rfpServiceLocations?.length > 0
      ) {
        const combinedArray = [...productLocations,...serviceLocations,...rfpProductLocations,...rfpServiceLocations]
        console.log('processedLocations--',combinedArray);
        
        const filteredLocations = combinedArray?.filter(item => 
          item?.is_dos === null || item?.is_dos === 0
        );        
        setAddedLocations(filteredLocations);
        calculateRoutes(filteredLocations)
      } else {
        setAddedLocations([])
        setSelectedProducts([])
      }
    } catch (error) {
      console.error('Error fetching locations:', error)
      setAddedLocations([])
      setSelectedProducts([])
    } finally {
      setIsLoading(false)
    }
  }

  console.log('newProducts=2====', selectedProducts)
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        allProducts?.length > 0 &&
        selectedRoute &&
        (requestProducts?.length > 0 || 
         locationDetails?.length > 0 || 
         additionalSelectedServices?.length > 0 || 
         selectedRfpServices?.length > 0 || 
         selectedRfpProduct?.length > 0)
      ) {
        setDebouncedFetch(true);
      }
    }, 1000);
  
    return () => clearTimeout(timeout);
  }, [selectedRoute, allProducts, locationDetails, additionalSelectedServices, selectedRfpServices, selectedRfpProduct]);
  
  useEffect(() => {
    if (debouncedFetch) {
      handleFetchLocations();
      setDebouncedFetch(false); // Reset after API call
    }
  }, [debouncedFetch]);

  // useEffect(() => {
  //   if (
  //     allProducts?.length > 0 &&
  //     selectedRoute &&
  //     (requestProducts?.length > 0 || locationDetails?.length > 0 || additionalSelectedServices?.length > 0 || selectedRfpServices?.length > 0 || selectedRfpProduct?.length >0)
  //   ) {
  //     handleFetchLocations()
  //   }
  // }, [selectedRoute, allProducts, locationDetails,additionalSelectedServices,selectedRfpServices,selectedRfpProduct])

  // useEffect(() => {
  //   if (additionalSelectedServices?.length > 0) {
  //     handleFetchServices()
  //   }
  //   if (selectedRfpServices?.length > 0) {
  //     handleFetchRfpServices()
  //   }
  // }, [additionalSelectedServices, selectedRfpServices])

  const handleSaveLocations = async () => {
    try {
      const response = await axios.post(
        `${REST_API_END_POINT}route/save-locations`,
        { selectedRoute, addedLocations, selectedProducts, userId },
      )
      if (response.data.status === 1) {
        enqueueSnackbar('Locations saved successfully!', { variant: 'success' })
        navigate(PATH_DASHBOARD.transportation.routes, { state: { ts: true } })
      } else {
        enqueueSnackbar('Failed to save locations.', { variant: 'error' })
      }
    } catch (error) {
      console.error('Error saving locations:', error)
      enqueueSnackbar('An error occurred while saving locations.', {
        variant: 'error',
      })
    }
  }

  const handleChange = (event, value) => {
    setSelectedTeam(value)
  }
  const handleNewDriverChange = (event, value) => {
    console.log('valueeeeeeeeeee', value)
    setSelectedDriver(value)
  }

  const handleDriverChange = (event, value) => {
    setSelectedTeam(value)
  }

  const filteredTeamMembers =
    teamMembers?.filter((item) =>
      isDriver ? item.role === 'DRIVER' : item.role !== 'DRIVER',
    ) || []

  const filteredDrivers =
    teamMembers?.filter((item) =>
      !isDriver ? item.role === 'DRIVER' : item.role !== 'DRIVER',
    ) || []

  const handleFetchTeam = async () => {
    try {
      setLoadingTeam(true)
      const user = JSON.parse(window.localStorage.getItem('user'))
      const response = await axios.post(
        `${REST_API_END_POINT}installation/all-project`,
        {
          userType: { value: user.user_type },
          userId: { value: user.user_id },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      if (response.data.status === 1) {
        const TeamData = response.data.TeamMembers
        let DriversData = response.data.allDrivers
        DriversData = DriversData?.filter((row) => row.status === 1)
        const allTeamData = [...DriversData, ...TeamData]
        setTeamMembers(allTeamData)
        let team = allTeamData.find(
          (val) => val?.user_id == selectedData?.team_id,
        )
        if (selectedData.assigned) {
          setSelectedTeam(team)
        }
        //  let driverData =[]
        //  let driver= allTeamData.find(val=>val?.id==selectedData?.driver_id)
        //    if(driver){
        //     driverData.push(driver)
        //    }
        //  console.log('driverdriverdriver',driverData)
        //  setSelectedDriver(driverData)

        let driverData = []
        const driverIds =
          selectedData?.driver_id?.split(',').map((id) => id.trim()) || []

        driverIds.forEach((id) => {
          const driver = allTeamData.find((val) => String(val?.id) === id)
          if (driver) {
            driverData.push(driver)
          }
        })

        if (selectedData.assigned) {
          setSelectedDriver(driverData)
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoadingTeam(false)
    }
  }

  const fetchAllInstTemplates = async () => {
    try {
      axios
        .post(`${REST_API_END_POINT}installation/get-all-template`, {
          value: false,
        })
        .then((res) => {
          if (res.data.status === 1) {
            setTemplates(res.data.result)

            let templateValue = res.data.result.find(
              (val) => val.id == selectedData.installation_temp_id,
            )
            console.log('templates templates 2', templateValue)
            if (selectedData.assigned) {
              setSelectTemplate(templateValue)
            }
          }
        })
        .catch((error) => {
          console.log(error)
          setTemplates([])
        })
    } catch (error) {
      console.log(error)
      setTemplates([])
    }
  }

  useEffect(() => {
    const pubnub = new PubNub({
      publishKey: PUBNUB_PUBLISH_KEY,
      subscribeKey: PUBNUB_SUBSCRIBE_KEY,
      secretKey: PUBNUB_SECRET_KEY,
    })

    pubnub.addListener({
      message: (messageEvent) => {
        const {
          driverId,
          latitude,
          longitude,
          speed,
          customer_name,
          address,
          city,
          state,
          zipcode,
          driver_name,
        } = messageEvent.message
        setSelectedDriverId(driverId)
        setMarkers((prevMarkers) => {
          const existingMarker = prevMarkers.find(
            (marker) => marker.driverId === driverId,
          )
          if (existingMarker) {
            return prevMarkers.map((marker) =>
              marker.driverId === driverId
                ? {
                    ...marker,
                    lat: Number(latitude),
                    lng: Number(longitude),
                    speed,
                  }
                : marker,
            )
          } else {
            return [
              ...prevMarkers,
              {
                driverId,
                lat: latitude,
                lng: longitude,
                speed,
                customer_name,
                address,
                city,
                state,
                zipcode,
                driver_name,
              },
            ]
          }
        })
      },
    })

    pubnub.subscribe({ channels: ['driver_location'] })

    return () => {
      pubnub.unsubscribeAll()
    }
  }, [])

  useEffect(async () => {
    try {
      if (selectedDriverId) {
        const response = await axios.post(
          `${REST_API_END_POINT}driver/get-driver-by-userId`,
          { id: selectedDriverId },
        )
        if (response.data.status === 1) {
          setOriginalDriverId(response.data.result[0].id)
          setSelectedOriginalDriverId(response.data.result[0].id)
        }
      }
    } catch (error) {
      console.log('error', error)
    }
  }, [selectedDriverId])

  const groupAppointmentsByRequestId = (appointments) => {
    const grouped = appointments.reduce((result, appointment) => {
      const { request_id, name, address, city, state, zipcode } = appointment

      // Construct the installationAddress
      const installationAddress = `${address || ''}, ${city || ''}, ${
        state || ''
      }, ${zipcode || ''}`.replace(/(, )+$/, '')

      if (!result[request_id]) {
        result[request_id] = {
          requestId: request_id,
          name: name, // Use the first appointment's name as the group name
          appointments: [],
        }
      }

      result[request_id].appointments.push({
        ...appointment,
        installationAddress, // Add installationAddress to each appointment
      })

      return result
    }, {})

    // Convert the grouped object into an array
    return Object.values(grouped)
  }

  const handleFetchAllAppointments = async () => {
    setLoading(true)
    try {
      const user = JSON.parse(window.localStorage.getItem('user'))
      const explodedPermissions = user?.exploded_permissions
      const hasPermission = explodedPermissions?.some((permission) =>
        ['View All Installations'].includes(permission),
      )
        ? true
        : false
      const response = await axios.post(
        `${REST_API_END_POINT}route/fetch-all-unassigned-installation-salesorder`,
        {
          userType: { value: user.user_type },
          userId: { value: user.user_id },
          hasPermission: hasPermission,
          routeId: selectedData?.assigned == 1 ? selectedRoute : null,
        },
        { headers: { 'Content-Type': 'application/json' } },
      )
      if (response.data.status === 1) {
        if (
          response.data.appointments &&
          response.data.appointments?.length > 0
        ) {
          const allAppointments = response.data.appointments
          const groupedAppointments =
            groupAppointmentsByRequestId(allAppointments)
          console.log(groupedAppointments) // View the grouped structure
          setAllAppointmentData(groupedAppointments)
          let value = groupedAppointments.find(
            (val) =>
              val.appointments[0]?.request_id ===
              selectedData?.assigned_sales_order,
          )

          console.log('loggggggggggg', value)
          if (selectedData.assigned && value) {
            setSelectedSalesOrder(value)
            setPreviosSalesOrderid(value.requestId)
            initializeLocations(value)
            handleSalesOrderSelect(value)
          }
        } else {
          setAllAppointmentData([])
        }
      }
    } catch (error) {
      console.log('Error fetch appointments--', error)
      setAllAppointmentData([])
    } finally {
      setLoading(false)
    }
  }

  const initializeLocations = async (value) => {
    console.log('location data 1', value)

    if (selectedData.assigned && value?.appointments) {
      console.log('location data 2')

      const installations = await fetchInstallationLocations(value.appointments)
      setLocations(installations) // Initialize locations state
      calculateRoute(installations) // Calculate route based on initial locations
      console.log('location data 3', installations)
    }
  }

  // useEffect(async () => {
  //   // handleFetchAllAppointments()
  //   // fetchAllInstTemplates()
  //   // handleFetchTeam()
  //   console.log(
  //     'selectedData?.driver_is_installer',
  //     selectedData?.driver_is_installer,
  //   )
  //   let installerAsDriver =
  //     selectedData?.driver_is_installer == 2 ? true : false
  //   if (selectedData?.assigned) {
  //     setIsDriver(installerAsDriver)
  //   }
  // }, [selectedData])

  // useEffect(() => {
  //   if (selectedSalesOrder) {
  //     // Automatically handle the selected sales order and calculate directions
  //     handleSalesOrderSelect(selectedSalesOrder)
  //     calculateRoute(locations)
  //   }
  // }, [selectedSalesOrder])

  useEffect(() => {
    const script = document.createElement('script')
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`
    script.async = true
    script.onload = () => setApiLoaded(true)
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    if (apiLoaded && (markers.length > 0 || locations.length > 0)) {
      console.log(
        'apiLoadedapiLoaded',
        apiLoaded,
        markers.length,
        locations.length,
      )
      calculateRoute(locations)
    }
  }, [markers, locations, selectedRoute, selectedSalesOrder, apiLoaded])

  // const calculateRoute = () => {
  //   console.log("markers1234567890",markers[0])
  //   console.log("locations1234567890",locations)
  //   const origin = { lat: markers[0].lat, lng: markers[0].lng };
  //   const destination = { lat: locations[locations.length - 1].lati, lng: locations[locations.length - 1].longi };
  //   const waypoints = locations.slice(0, -1).map(location => ({
  //     location: { lat: location.lati, lng: location.longi },
  //     stopover: true
  //   }));

  //   const directionsService = new window.google.maps.DirectionsService();
  //   directionsService.route(
  //     {
  //       origin,
  //       destination,
  //       waypoints,
  //       travelMode: window.google.maps.TravelMode.DRIVING
  //     },
  //     (result, status) => {
  //       if (status === window.google.maps.DirectionsStatus.OK) {
  //         setDirections(result);
  //         console.log("result123456",result)
  //       } else {
  //         console.error(`Directions request failed due to ${status}`);
  //       }
  //     }
  //   );
  // };

  const calculateRoute = (locations) => {
    // if (!locations || locations.length < 2) {
    //   console.error('At least two locations are required for a route.');
    //   setDirections([])
    //   return;
    // }

    // const origin = locations[0];
    // const destination = locations[locations.length - 1];

    const waypoints = [
      { location: home, stopover: true },
      ...locations.map((location) => ({
        location: { lat: Number(location?.lat), lng: Number(location?.lng) },
        stopover: true,
      })),
      { location: home, stopover: true },
    ]

    const origin = waypoints[0]?.location
    const destination = waypoints[waypoints.length - 1]?.location

    // const waypoints = locations.slice(1, -1).map((location) => ({
    //   location: { lat: location.lat, lng: location.lng },
    //   stopover: true,
    // }));

    const directionsService = new window.google.maps.DirectionsService()
    directionsService.route(
      {
        origin,
        destination,
        waypoints,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result) // Set the directions state
          console.log('Directions result:', result)
        } else {
          console.error(`Directions request failed: ${status}`)
          setDirections(null)
        }
      },
    )
  }

  //For generating route with more than 25 locations
  const calculateRoutes = (locations) => {
    console.log('calculateRoutes--locations--', locations)
    if (!locations) {
      console.error('At least one location is required for a route.')
      setDirections([])
      return
    }

    // Split locations into chunks of 25 waypoints
    const splitIntoChunks = (locations, chunkSize) => {
      const chunks = []
      for (let i = 0; i < locations.length; i += chunkSize) {
        chunks.push(locations.slice(i, i + chunkSize))
      }
      return chunks
    }

    const locationChunks = splitIntoChunks(locations, 25) // Maximum 25 waypoints
    const directionsService = new window.google.maps.DirectionsService()
    const routeResults = []

    const calculateChunkRoute = (chunk, isLastChunk) => {
      return new Promise((resolve, reject) => {
        const waypoints = chunk.map((location) => ({
          location: { lat: Number(location.lat), lng: Number(location.lng) },
          stopover: true,
        }))

        // Always start and end at home
        const origin = home
        const destination = home
        // const destination = isLastChunk ? home : waypoints[waypoints.length - 1].location;

        directionsService.route(
          {
            origin,
            destination,
            waypoints: waypoints.slice(0, waypoints.length), // Intermediate points only
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === 'OK') {
              resolve(result)
            } else {
              console.error(`Directions request failed: ${status}`)
              reject(status)
            }
          },
        )
      })
    }

    const calculateAllRoutes = async () => {
      try {
        for (let i = 0; i < locationChunks.length; i++) {
          const isLastChunk = i === locationChunks.length - 1
          const route = await calculateChunkRoute(
            locationChunks[i],
            isLastChunk,
          )
          routeResults.push(route)
        }
        setDirections(routeResults) // Store all route objects in the state
      } catch (error) {
        console.error('Error calculating routes:', error)
        setDirections([])
      }
    }

    calculateAllRoutes()
  }

  console.log('directiondirectiondirectiondirection', directions)

  const handleSalesOrderSelect = async (newValue) => {
    if (newValue) {
      console.log('Selected Sales Order:', newValue)
      setSelectedSalesOrder(newValue)
      // Extract installation addresses and fetch geocoded locations
      const geocodedLocations = await fetchInstallationLocations(
        newValue.appointments,
      )

      if (geocodedLocations.length > 0) {
        setLocations(geocodedLocations)
        calculateRoute(geocodedLocations)
      } else {
        console.error('No valid locations for this sales order.')
        setLocations([])
        setDirections(null)
      }
    } else {
      setLocations([])
      setDirections(null)
      setSelectedSalesOrder(null)
    }
  }

  // const fetchInstallationLocations = async (appointments) => {
  //   const locations = []

  //   for (const appointment of appointments) {
  //     try {
  //       const address = `${appointment.address}, ${appointment.city}, ${appointment.state}, ${appointment.zipcode}`

  //       let latLong = null

  //       if (appointment.lat && appointment.lng) {
  //         console.log(
  //           'getLatandLongFromAddress existing',
  //           appointment.lat,
  //           appointment.lng,
  //         )

  //         locations.push({
  //           lat: Number(appointment.lat),
  //           lng: Number(appointment.lng),
  //           name: appointment.name,
  //         })
  //       } else {
  //         latLong = await getLatandLongFromAddress(address)
  //         console.log('getLatandLongFromAddress', latLong)
  //       }
  //       if (latLong) {
  //         locations.push({
  //           lat: latLong ? latLong.Latitude : appointment.lat,
  //           lng: latLong ? latLong.Longitude : appointment.lng,
  //           name: appointment.name,
  //         })
  //       }
  //     } catch (error) {
  //       console.error('Error geocoding address:', error)
  //     }
  //   }

  //   return locations
  // }

  const handleAssignRoute = async () => {
    const user = JSON.parse(window.localStorage.getItem('user'))

    const res = await axios.post(
      `${REST_API_END_POINT}route/assign-ts-job-to-route`,
      {
        selectedRoute,
        selectedSalesOrder,
        selectedDriver,
        selectedTeam,
        isDriver,
        selectTemplate,
        previosSalesOrderId,
        userId: user.user_id,
      },
    )
    console.log('resresresresres', res)
    // console.log('selectedRoute==========',selectedRoute)
    // console.log('selectedSalesOrder==========',selectedSalesOrder)
    // console.log('selectedDriver==========',selectedDriver)
    if (res.data.status === 1) {
      enqueueSnackbar(
        `Successfully ${
          selectedData.assigned ? 'Re-assigned' : 'Assigned'
        } to Driver`,
        { variant: 'success' },
      )
      //  window.location.reload()
    } else if (res.data.status === 3) {
      enqueueSnackbar(res.data.message, { variant: 'error' })
    } else {
      enqueueSnackbar(
        `Failed to ${selectedData.assigned ? 'Re-assign' : 'Assign'}`,
        { variant: 'error' },
      )
    }
  }

  // const handleMarkerDragEnd = (event, index) => {
  //   console.log("Marker")
  //   const newLat = event.latLng.lat();
  //   const newLng = event.latLng.lng();

  //   // Update the location in the state
  //   setLocations((prevLocations) => {
  //     const updatedLocations = [...prevLocations];
  //     updatedLocations[index] = {
  //       ...updatedLocations[index],
  //       lati: newLat,
  //       longi: newLng,
  //     };
  //     return updatedLocations;
  //   });

  //   console.log(`Marker ${index} moved to: Latitude: ${newLat}, Longitude: ${newLng}`);
  // };

  const handleMarkerDragEnd = async (event, index, location) => {
    const newLat = event.latLng.lat()
    const newLng = event.latLng.lng()
    console.log('locationlocationlocation', location)

    // Update the location in the state
    setLocations((prevLocations) => {
      const updatedLocations = [...prevLocations]
      updatedLocations[index] = {
        ...updatedLocations[index],
        lat: Number(newLat),
        lng: Number(newLng),
      }
      calculateRoute(updatedLocations)
      return updatedLocations
    })

    console.log(
      `Marker ${index} moved to: Latitude: ${newLat}, Longitude: ${newLng}`,
    )

    // Recalculate the route with updated locations
    //update lat long on installation table
    try {
      const response = await axios.post(
        `${REST_API_END_POINT}installation/update-lat-lng`,
        {
          markerId: location.id,
          latitude: Number(newLat), // Ensure latitude is sent as a number
          longitude: Number(newLng), // Ensure longitude is sent as a number
        },
      )

      if (response.data.status === 1) {
        console.log('Marker updated successfully in DB:', response.data)
      } else {
        console.error('Failed to update marker in DB:', response.data)
      }
    } catch (error) {
      console.error('Error updating marker in DB:', error)
    }
  }

  console.log('addedLocations==========', addedLocations)

  let explodedPermissions = userData?.exploded_permissions
const hasRouteMovingPermission = explodedPermissions?.some((permission) =>
['Route Management (Route Map)'].includes(permission), ) ? true : false

  return (
    <Grid item xs={12}>
      <Stack direction="column" spacing={2} sx={{ mb: 2 }}>
        <HeaderBreadcrumbs
          sx={{ mt: 2 }}
          heading={`Route Details${selectedData?.name ? ` - ${selectedData?.name}` : ''}`}
          action={
            <Stack direction="row" spacing={2}>
              <Button
                sx={{ mt: 2 }}
                variant="outlined"
                startIcon={
                  <Stack>
                    <KeyboardBackspaceOutlined />
                  </Stack>
                }
                onClick={() =>
                  navigate(PATH_DASHBOARD.transportation.routes, {
                    state: { ts: false },
                  })
                }
              >
                Back to Routes
              </Button>
            </Stack>
          }
        />
      </Stack>
      {selectedSalesOrder ? (
        <>
          <Stack direction="column" sx={{ mb: 2, mt: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={2}
              sx={{ mb: 2, mx: 2 }}
            >
              <Autocomplete
                // sx={{ width : '50%' }}
                fullWidth
                options={allAppointmentData || []}
                //   value={
                //     allAppointmentData?.find(
                //         (val) => val.appointments[0]?.request_id === selectedData?.assigned_sales_order
                //     ) || null
                // }
                value={selectedSalesOrder || null}
                getOptionLabel={(option) => option.name}
                onChange={async (event, newValue) => {
                  await handleSalesOrderSelect(newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Sales Order"
                    variant="outlined"
                    fullWidth
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
              <Autocomplete
                fullWidth
                options={templates || []}
                filterSelectedOptions
                value={selectTemplate || null}
                onChange={(event, newValue) => {
                  setSelectTemplate(newValue)
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Template"
                    placeholder="Select Template"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={2}
              sx={{ mb: 2, mx: 2 }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isDriver}
                    onChange={(event) => {
                      setIsDriver(event.target.checked)
                      setSelectedDriver(null)
                      setSelectedTeam(null)
                    }}
                  />
                }
                label="Installer is also the Driver"
              />
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={2}
              sx={{ mb: 2, mx: 2 }}
            >
              <Autocomplete
                fullWidth
                options={filteredTeamMembers}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                loading={loadingTeam}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={isDriver ? 'Installer & Driver' : 'Installer'}
                    placeholder={
                      isDriver
                        ? 'Search for Installer & Driver'
                        : 'Search for Installer'
                    }
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingTeam && (
                            <CircularProgress color="inherit" size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.user_id}>
                    {option.name ? option.name : ''}
                  </li>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={option.id}
                      label={
                        <Stack
                          direction={{ xs: 'row' }}
                          spacing={1}
                          sx={{ alignItems: 'center' }}
                        >
                          <Avatar
                            alt={option.name}
                            src={option.profile_pic}
                            sx={{ height: 32, width: 32 }}
                          />
                          <Box direction="row" spacing={0}>
                            <Typography variant="subtitle2">
                              {option.name}
                            </Typography>
                            <Typography variant="caption">
                              {option.email}
                            </Typography>
                          </Box>
                        </Stack>
                      }
                      sx={{ height: 48 }}
                    />
                  ))
                }
                value={selectedTeam || null}
                onChange={isDriver ? handleDriverChange : handleChange}
              />
              {isDriver ? (
                ''
              ) : (
                <Autocomplete
                  multiple
                  fullWidth
                  sx={{ mt: 3 }}
                  options={filteredDrivers}
                  getOptionLabel={(option) => option.name}
                  filterSelectedOptions
                  loading={loadingTeam}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={'Drivers'}
                      placeholder={'Search for Driver'}
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loadingTeam && (
                              <CircularProgress color="inherit" size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option.user_id}>
                      {option.name ? option.name : ''}
                    </li>
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        key={option.id}
                        label={
                          <Stack
                            direction={{ xs: 'row' }}
                            spacing={1}
                            sx={{ alignItems: 'center' }}
                          >
                            <Avatar
                              alt={option.name}
                              src={option.profile_pic}
                              sx={{ height: 32, width: 32 }}
                            />
                            <Box direction="row" spacing={0}>
                              <Typography variant="subtitle2">
                                {option.name}
                              </Typography>
                              <Typography variant="caption">
                                {option.email}
                              </Typography>
                            </Box>
                          </Stack>
                        }
                        sx={{ height: 48 }}
                      />
                    ))
                  }
                  value={selectedDriver || []}
                  onChange={handleNewDriverChange}
                />
              )}
            </Stack>
            <Stack
              direction="row"
              justifyContent="center"
              spacing={2}
              sx={{ mb: 2, mx: 2 }}
            >
              {selectedRoute &&
              selectedSalesOrder &&
              ((selectedDriver && !isDriver) || (selectedTeam && isDriver)) &&
              selectTemplate ? (
                <Button
                  // fullWidth
                  sx={{
                    mt: 2,
                    width: '50%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  variant="contained"
                  onClick={() => handleAssignRoute()}
                >
                  {selectedData.assigned ? 'Re-assign' : 'Assign'}
                </Button>
              ) : (
                ''
              )}
            </Stack>
            {/* <Stack spacing={3} sx={{ pl: 2 }}>
          <Scrollbar
            sx={{
              maxHeight: 400,
              '& .simplebar-content': {
                maxHeight: 400,
                display: 'flex',
                flexDirection: 'column',
              },
            }}
          > */}
            <Grid container spacing={2} sx={{ py: 2, pl: 5, pr: 2 }}>
              {/* sx={{p: 2, pl:2}} */}
              <Grid item xs={12}>
                {selectedData?.assigned ? (
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      mt: 2,
                      border: '1px solid',
                      borderColor: 'green',
                      borderRadius: '8px',
                      p: 2,
                      // backgroundColor: '#f5f5f5',
                    }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        align="center"
                        sx={{ fontWeight: 'bold', color: 'green', mb: 1 }}
                      >
                        Assigned Installation Details
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ pr: 2 }}>
                      <Stack spacing={1}>
                        {selectedData?.customer_name && (
                          <Typography variant="body2" sx={{ fontWeight: 500 }}>
                            Client Name: {selectedData.customer_name}
                          </Typography>
                        )}
                        {selectedData?.installation_name && (
                          <Typography variant="body2" sx={{ fontWeight: 500 }}>
                            Job Name: {selectedData.installation_name}
                          </Typography>
                        )}
                        {selectedData?.project_name && (
                          <Typography variant="body2" sx={{ fontWeight: 500 }}>
                            Project Name: {selectedData.project_name}
                          </Typography>
                        )}
                      </Stack>
                    </Grid>
                    {/* <Divider orientation="vertical" flexItem sx={{ borderColor: 'green' }} /> */}
                    <Grid item xs={12} md={6} sx={{ pl: 2 }}>
                      <Stack spacing={1}>
                        {selectedData?.name && (
                          <Typography variant="body2" sx={{ fontWeight: 500 }}>
                            Route Name: {selectedData.name}
                          </Typography>
                        )}
                        {selectedData?.installation_name && (
                          <Typography variant="body2" sx={{ fontWeight: 500 }}>
                            Route Description: {selectedData.installation_name}
                          </Typography>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
            {/* </Scrollbar>
        </Stack> */}

            <Stack
              direction="row"
              justifyContent="end"
              spacing={2}
              sx={{ mb: 2 }}
            >
              {selectedData?.assigned ? (
                <>
                  <Typography variant="caption" sx={{ mt: 1, opacity: '50%' }}>
                    Click here if the route is not displayed
                  </Typography>
                  <Button
                    sx={{ mt: 2 }}
                    variant="contained"
                    onClick={() => window.location.reload()}
                  >
                    Refresh
                  </Button>
                </>
              ) : (
                ''
              )}
            </Stack>
            {/* <Autocomplete
                    fullWidth
                    options={driverDataaa || []}
                    getOptionLabel={(option) => option.name}
                    onChange={async (event, newValue) => {
                        if(newValue){
                            setSelectedDriver(newValue);
                        }else{
                            setSelectedDriver(newValue);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select Driver"
                            variant="outlined"
                            fullWidth
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                                ),
                            }}
                        />
                    )}
                /> */}

            {/* {(selectedRoute && selectedSalesOrder && ((selectedDriver && !isDriver) || (selectedTeam && isDriver)) && selectTemplate) ?
                <Button variant="contained" onClick={()=>handleAssignRoute()}>Assign</Button>
                : '' } */}
          </Stack>
          {apiLoaded && home && locations && (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={4}
            >
              {home && (
                <Marker
                  position={home}
                  icon={{
                    url: homeIcon,
                    scaledSize: new window.google.maps.Size(40, 40),
                  }}
                  label={{
                    color: '#000000',
                    fontWeight: 'bold',
                    fontSize: '14px',
                  }}
                />
              )}
              {markers.map((marker) => (
                <Marker
                  key={marker.driverId}
                  position={{ lat: marker.lat, lng: marker.lng }}
                  title={`${marker.driver_name} - Speed: ${marker.speed} km/h`}
                  onClick={() => setSelectedMarker(marker)}
                  icon={{
                    // url: truckIcon,
                    // url: car,
                    url: truck,
                    scaledSize: new window.google.maps.Size(20, 20),
                  }}
                />
              ))}

              {locations.map((location, index) => (
                <Marker
                  key={index}
                  position={{ lat: location.lati, lng: location.longi }}
                  icon={{
                    url: installationIcon,
                    scaledSize: new window.google.maps.Size(40, 40),
                  }}
                />
              ))}

              {selectedMarker && (
                <InfoWindow
                  position={{
                    lat: selectedMarker.lat,
                    lng: selectedMarker.lng,
                  }}
                  onCloseClick={() => setSelectedMarker(null)}
                  options={{
                    pixelOffset: new window.google.maps.Size(0, -30),
                    maxWidth: 200,
                    styles: {
                      backgroundColor: 'rgba(0, 0, 0, 0.8)',
                      color: '#fff',
                      padding: 10,
                      borderRadius: 5,
                    },
                  }}
                >
                  <div
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0.8)',
                      color: '#fff',
                      padding: 10,
                      borderRadius: 5,
                    }}
                  >
                    <h3>{selectedMarker.driver_name}</h3>
                    <p>Speed: {Math.round(selectedMarker.speed)} km/h</p>
                    {/* <p>Speed: {selectedMarker.speed} km/h</p> */}
                  </div>
                </InfoWindow>
              )}
              {/* {directions && (
                    <DirectionsRenderer
                    directions={directions}
                    options={{
                        polylineOptions: {
                        strokeColor: '#1ccaff',
                        strokeOpacity: 0.8,
                        strokeWeight: 5
                        },
                        suppressMarkers: true // This option suppresses default markers
                    }}
                    />
                )} */}
              {directions && (
                <DirectionsRenderer
                  directions={directions}
                  options={{
                    polylineOptions: {
                      strokeColor: '#1ccaff',
                      strokeOpacity: 0.8,
                      strokeWeight: 5,
                    },
                    suppressMarkers: true,
                  }}
                />
              )}

              {locations.map((location, index) => (
                <Marker
                  key={index}
                  position={{ lat: location.lat, lng: location.lng }}
                  title={location.name}
                  draggable = {hasRouteMovingPermission ? true : false}
                  onDragEnd={(event) => {hasRouteMovingPermission ? handleMarkerDragEnd(event, index, location) : enqueueSnackbar( 'Access Denied: You do not have permission to manage route', { variant: 'error' },) }}
                />
              ))}
            </GoogleMap>
          )}
        </>
      ) : isLoading ? (
        <CircularProgress color="inherit" size={20} />
      ) : (
        <>
          {/* { !isFromPs &&
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }} sx={{mb:2}}>
          <Autocomplete
            sx={{ width: '50%' }}
            multiple
            id="combo-box-demo"
            // options={allProducts || []}
            options={allProducts?.filter(
              (product) =>
                !selectedProducts?.some((selectedProduct) => selectedProduct?.id == product?.id)
            )} 
            value={selectedProducts}
            onChange={(event, newValue) => {
              setSelectedProducts(newValue);
              const selectedProductIds = newValue?.map((product) => product.id);
              const newLocations = addedLocations?.filter((item) =>
                selectedProductIds?.includes(item.productId)
              );
              setAddedLocations(newLocations);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Select product" />
            )}
            getOptionLabel={(option) => option.name ? option.name : ''}
          />
        </Stack>} */}
          {selectedProducts?.length > 0 ? (
            <Accordion sx={{ my: 3 }}>
              <AccordionSummary
                sx={{ color: '#919EAB' }}
                expandIcon={
                  <Icon icon={arrowIosDownwardFill} width={20} height={20} />
                }
              >
                Selected Products
              </AccordionSummary>
              <AccordionDetails>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 500 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No.</TableCell>
                          <TableCell>Product Name</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedProducts?.map((data, index) => (
                          <TableRow key={index}>
                            <TableCell>#{index + 1}</TableCell>
                            <TableCell>
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                              >
                                <div
                                  style={{
                                    width: '18px',
                                    height: '18px',
                                    borderRadius: '50%',
                                    backgroundColor: data.color || 'black',
                                  }}
                                ></div>
                                <span>{data.name}</span>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <TextField
                                label="Quantity"
                                type="number"
                                value={data.quantity || 0}
                                disabled
                              />
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
              </AccordionDetails>
            </Accordion>
          ) : (
            ''
          )}
          {additionalSelectedServices?.filter(item=>!item?.is_dos && item?.service_type != 33)?.length > 0 ? (
            <Accordion sx={{ my: 3 }}>
              <AccordionSummary
                sx={{ color: '#919EAB' }}
                expandIcon={
                  <Icon icon={arrowIosDownwardFill} width={20} height={20} />
                }
              >
                Selected Additional Services
              </AccordionSummary>
              <AccordionDetails>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 500 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No.</TableCell>
                          <TableCell>Additional Service Name</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {additionalSelectedServices?.filter(item=>!item?.is_dos && item?.service_type != 33)?.map((data, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>#{index + 1}</TableCell>
                              {/* <TableCell>{data.name}</TableCell> */}
                              <TableCell>
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <div
                                    style={{
                                      width: '18px',
                                      height: '18px',
                                      borderRadius: '50%',
                                      backgroundColor: data.color || 'black',
                                    }}
                                  ></div>
                                  {/* <span>{data.name}{data?.is_dos == 1 ? ' (From Design)' : ''}</span> */}
                                  <span>{data.name}</span>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                <TextField
                                  label="Quantity"
                                  type="number"
                                  value={data.quantity > 0 ? data.quantity : 1}
                                  disabled
                                  onChange={(event) => {
                                    const qty = Number(event.target.value)
                                  }}
                                />
                              </TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
              </AccordionDetails>
            </Accordion>
          ) : (
            ''
          )}
          {selectedRfpProduct?.length > 0 ? (
            <Accordion sx={{ my: 3 }}>
              <AccordionSummary
                sx={{ color: '#919EAB' }}
                expandIcon={
                  <Icon icon={arrowIosDownwardFill} width={20} height={20} />
                }
              >
                Selected Proposal Products
              </AccordionSummary>
              <AccordionDetails>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 500 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No.</TableCell>
                          <TableCell>Proposal Products Name</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedRfpProduct?.map((data, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>#{index + 1}</TableCell>
                              {/* <TableCell>{data.name}</TableCell> */}
                              <TableCell>
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <div
                                    style={{
                                      width: '18px',
                                      height: '18px',
                                      borderRadius: '50%',
                                      backgroundColor: data.color || 'black',
                                    }}
                                  ></div>
                                  <span>{data.product_name}</span>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                <TextField
                                  label="Quantity"
                                  type="number"
                                  value={data?.location_quantity > 0 ? data?.location_quantity : 1}
                                  disabled
                                  onChange={(event) => {
                                    const qty = Number(event.target.value)
                                  }}
                                />
                              </TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
              </AccordionDetails>
            </Accordion>
          ) : (
            ''
          )}
          {selectedRfpServices?.filter(item=>!item?.is_dos && item?.service_type != 33)?.length > 0 ? (
            <Accordion sx={{ my: 3 }}>
              <AccordionSummary
                sx={{ color: '#919EAB' }}
                expandIcon={
                  <Icon icon={arrowIosDownwardFill} width={20} height={20} />
                }
              >
                Selected Proposal Services
              </AccordionSummary>
              <AccordionDetails>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 500 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No.</TableCell>
                          <TableCell>Proposal Service Name</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedRfpServices?.filter(item=>!item?.is_dos && item?.service_type != 33)?.map((data, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>#{index + 1}</TableCell>
                              {/* <TableCell>{data.name}</TableCell> */}
                              <TableCell>
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <div
                                    style={{
                                      width: '18px',
                                      height: '18px',
                                      borderRadius: '50%',
                                      backgroundColor: data.color || 'black',
                                    }}
                                  ></div>
                                  <span>{data.name}</span>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                <TextField
                                  label="Quantity"
                                  type="number"
                                  value={data.quantity > 0 ? data.quantity : 1}
                                  disabled
                                  onChange={(event) => {
                                    const qty = Number(event.target.value)
                                  }}
                                />
                              </TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
              </AccordionDetails>
            </Accordion>
          ) : (
            ''
          )}
          {apiLoaded && home && locations && (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={home}
              zoom={10}
            >
              {/* onClick={!selectedSalesOrder ? handleMapClick : null} */}
              {home && (
                <Marker
                  position={home}
                  icon={{
                    url: homeIcon,
                    scaledSize: new window.google.maps.Size(40, 40),
                  }}
                  label={{
                    color: '#000000',
                    fontWeight: 'bold',
                    fontSize: '14px',
                  }}
                />
              )}

              {markers?.map((marker) => (
                <Marker
                  key={marker.driverId}
                  position={{ lat: marker.lat, lng: marker.lng }}
                  title={`${marker.driver_name} - Speed: ${marker.speed} km/h`}
                  onClick={() => setSelectedMarker(marker)}
                  icon={{
                    // url: truckIcon,
                    // url: car,
                    url: truck,
                    scaledSize: new window.google.maps.Size(20, 20),
                  }}
                />
              ))}

              {addedLocations?.map((location, index) => (
                <Marker
                  key={index}
                  position={{ lat: location.lat, lng: location.lng }}
                  draggable = {(!details?.allInstallationCompleted && details?.installationCompleteStatus == null && hasRouteMovingPermission) ? true : false}
                  onDragEnd={(event) => {hasRouteMovingPermission ? handleMarkerDrag(event, index, location) : enqueueSnackbar( 'Access Denied: You do not have permission to manage route', { variant: 'error' },) }}
                  onMouseOver={(e) => handlePopoverOpen(e, location)}
                  onMouseOut={handlePopoverClose}
                  // onDragEnd={(event) => {
                  //   const newLat = event.latLng.lat();
                  //   const newLng = event.latLng.lng();

                  //   const geocoder = new google.maps.Geocoder();
                  //   geocoder.geocode({ location: { lat: newLat, lng: newLng } }, (results, status) => {
                  //     console.log('results=========',results);
                  //     console.log('status=========',status);
                  //     if (status === "OK" && results[0]) {
                  //       const components = results[0].address_components;
                  //       const plusCode = components.find((c) => c.types.includes("plus_code"))?.long_name || null;
                  //       // const address = plusCode || components.find((c) => c.types.includes("street_address"))?.long_name || null;
                  //       const streetNumber = components.find((c) => c.types.includes("street_number"))?.long_name || '';
                  //       const route = components.find((c) => c.types.includes("route"))?.long_name || '';
                  //       const address = plusCode || (streetNumber || route ? `${streetNumber} ${route}`.trim() : null);
                  //       const country = components.find((c) => c.types.includes("country"))?.long_name || null;
                  //       const city = components.find((c) => c.types.includes("locality"))?.long_name || null;
                  //       const state = components.find((c) => c.types.includes("administrative_area_level_1"))?.short_name || null;
                  //       const zip = components.find((c) => c.types.includes("postal_code"))?.long_name || null;
                  //       const wholeAddress = results[0].formatted_address || null;

                  //       // Add the location with address to the state
                  //       const updatedLocations = addedLocations.map((loc, i) =>
                  //         i === index ? { lat: newLat, lng: newLng, address, city, state, zip, country, wholeAddress  } : loc
                  //       );
                  //       setAddedLocations(updatedLocations);
                  //       calculateRoutes(updatedLocations);
                  //     } else {
                  //       console.error("Geocoder failed due to: " + status);
                  //     }
                  //   })

                  //   setAddedLocations((prev) =>
                  //     prev.map((loc, i) =>
                  //       i === index ? { ...loc, lat: newLat, lng: newLng } : loc
                  //     )
                  //   );
                  // }}
                  // onClick={() => {
                  //   setDeleteLocationIndex(index);
                  // }}
                  // onClick={() => {
                  //   setTooltipInfo(location.productName);
                  // }}
                  // label={location.wholeAddress} // Show address as label
                  // label={`${index + 1}`}
                  // label={{
                  //   text: `${index + 1}`,  // Show the index number
                  //   color: 'black',  // Change the text color to white for contrast
                  //   fontWeight: 'bold',  // Make the text bold
                  //   fontSize: '14px',  // Adjust font size
                  //   backgroundColor: 'red', // You can customize the background color as well
                  //   padding: '5px',  // Add padding around the label
                  //   borderRadius: '50%', // Make the label circular
                  // }}
                  icon={{
                    path: google.maps.SymbolPath.CIRCLE,
                    fillColor: location.color,
                    fillOpacity: 1,
                    scale: 10,
                    strokeColor: 'white',
                    strokeWeight: 2,
                  }}
                  label={{
                    text: `${index + 1}`, //location.productName,
                    color: 'black',
                    fontWeight: 'bold',
                    fontSize: '12px',
                  }}
                  // title={`${location?.productName} (${location?.order_id || location?.service_id || location?.id || ''})`}
                  // icon={{
                  //   url: installationIcon,
                  //   scaledSize: new window.google.maps.Size(40, 40),
                  // }}
                  // onMouseOver={() => {
                  //   setTooltipInfo(location.productName);
                  // }}
                ></Marker>
              ))}

              {selectedMarker && (
                <InfoWindow
                  position={{
                    lat: selectedMarker.lat,
                    lng: selectedMarker.lng,
                  }}
                  onCloseClick={() => setSelectedMarker(null)}
                  options={{
                    pixelOffset: new window.google.maps.Size(0, -30),
                    maxWidth: 200,
                    styles: {
                      backgroundColor: 'rgba(0, 0, 0, 0.8)',
                      color: '#fff',
                      padding: 10,
                      borderRadius: 5,
                    },
                  }}
                >
                  <div
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0.8)',
                      color: '#fff',
                      padding: 10,
                      borderRadius: 5,
                    }}
                  >
                    <h3>{selectedMarker.driver_name}</h3>
                    <p>Speed: {Math.round(selectedMarker.speed)} km/h</p>
                  </div>
                </InfoWindow>
              )}

              {directions &&
                (Array.isArray(directions) ? (
                  directions.map((route, index) => (
                    <DirectionsRenderer
                      key={index}
                      directions={route} // Pass each route individually
                      options={{
                        polylineOptions: {
                          strokeColor: '#1ccaff',
                          strokeOpacity: 0.8,
                          strokeWeight: 5,
                        },
                        suppressMarkers: true, // Prevent default markers
                      }}
                    />
                  ))
                ) : (
                  <DirectionsRenderer
                    directions={directions} // Single route
                    options={{
                      polylineOptions: {
                        strokeColor: '#1ccaff',
                        strokeOpacity: 0.8,
                        strokeWeight: 5,
                      },
                      suppressMarkers: true, // Prevent default markers
                    }}
                  />
                ))}

              {locations.map((location, index) => (
                <Marker
                  key={index}
                  position={{ lat: location.lat, lng: location.lng }}
                  title={location.name}
                  draggable = {hasRouteMovingPermission ? true : false}
                  onDragEnd={(event) => {hasRouteMovingPermission ? handleMarkerDragEnd(event, index, location) : enqueueSnackbar( 'Access Denied: You do not have permission to manage route', { variant: 'error' },) }}
                />
              ))}

              {/* {tooltipInfo && (
            <InfoWindow position={{ lat: home.lat, lng: home.lng }} onCloseClick={() => setTooltipInfo(null)}>
              <div>{tooltipInfo}</div>
            </InfoWindow>
          )} */}
            </GoogleMap>
          )}
          <Popover
            open={Boolean(selectedLocation)}
            anchorReference="anchorPosition"
            anchorPosition={{ top: mousePosition.y, left: mousePosition.x }}
            onClose={handlePopoverClose}
            sx={{ pointerEvents: "none" }} // Prevents popover closing on accidental hover
          >
            {selectedLocation && (
              <Box sx={{ width: 250, p: 2 }}>
                {/* Image Slider */}
                <Slider {...sliderSettings}>
                  {selectedLocation?.attachments?.map((attachment, index) => (
                    <Box key={index} component="img" src={attachment?.name} alt="Design"
                        sx={{ width: "100%", height: 120, objectFit: "cover" }} />
                  ))}
                </Slider>

                {/* Location Details */}
                <Typography variant="h6">{selectedLocation?.productName || ""}</Typography>
                <Typography variant="body2">📍 {selectedLocation?.wholeAddress || "Location"}</Typography>
                <Typography variant="body2">📦 {selectedLocation?.quantity ? `Quantity: ${selectedLocation?.quantity}` : ""}</Typography>

                {!selectedLocation?.service_image && selectedLocation?.attachments?.[currentImageIndex] && (
                  <>
                    <Typography variant="body2">{selectedLocation?.attachments?.[currentImageIndex]?.approved_by_name ? `✅ Approved By: ${selectedLocation?.attachments?.[currentImageIndex]?.approved_by_name}` : ""}</Typography>
                    <Typography variant="body2">
                      {selectedLocation?.attachments?.[currentImageIndex]?.timestamp
                        ? `📅 Approved On: ${moment.unix(selectedLocation.attachments[currentImageIndex].timestamp).format("MM-DD-YYYY")}`
                        : ""}
                    </Typography>
                  </>
                )}
              </Box>
            )}
          </Popover>
          {console.log(
            '(addedLocations?.length == selectedProducts?.reduce((sum, item) => sum + Number(item?.qty), 0))===',
            addedLocations?.length ==
              selectedProducts?.reduce(
                (sum, item) => sum + Number(item?.qty),
                0,
              ),
          )}
          {console.log('selectedLocation====', selectedLocation)}
          {console.log('addedLocations?.length===', addedLocations?.length)}
          {console.log(
            'selectedProducts?.reduce((sum, item) => sum + Number(item?.qty), 0)===',
            selectedProducts?.reduce((sum, item) => sum + Number(item?.qty), 0),
          )}
          {addedLocations && addedLocations?.length > 0 ? (
            <Stack flexDirection={'row'} justifyContent={'space-between'}>
              <Box sx={{ mt: 2 }}>
                <>
                  <Typography variant="h6">Added Locations</Typography>
                  {addedLocations?.filter(item=>item?.address != null)?.map((location, index) => (
                    <Box key={index} sx={{ mt: 1 }}>
                      {console.log('location>>>', location)}
                      <Typography variant="body2">
                        {index + 1}. {location?.wholeAddress} (
                        {location?.lat?.toFixed(6)}, {location?.lng?.toFixed(6)}
                        ) - <span style={{color:'yellow'}}>{`${location?.productName} (${location?.location_id || location?.order_id || location?.service_id || location?.id || ''})`}</span>
                      </Typography>
                    </Box>
                  ))}
                </>
              </Box>
              <Box
                sx={{ mt: 2, gap: 2, display: 'flex', flexDirection: 'row' }}
              >
                {/* <Button
              variant="contained"
              sx={{ mt: 2, alignSelf: 'flex-start', }}
              disabled={((selectedProducts?.length > 0) && (addedLocations?.length == selectedProducts?.reduce((sum, item) => sum + Number(item?.qty), 0)) ? false : true) }
              onClick={handleSaveLocations}
            >
              { (addedLocations?.length == selectedProducts?.reduce((sum, item) => sum + Number(item?.qty), 0)) ? 'Save Locations' : 'Loading Locations' }
            </Button> */}
                <Button
                  variant="outlined"
                  sx={{
                    mt: 2,
                    alignSelf: 'flex-start', // Align this button to the right
                  }}
                  disabled={addedLocations?.length > 0 ? false : true}
                  onClick={() => {
                    // Conditional check if needed for the route calculation
                    // if(addedLocations.length > 23) {
                    calculateRoutes(addedLocations)
                    // } else {
                    //   calculateRoute(addedLocations);
                    // }
                  }}
                >
                  Generate Route
                </Button>
              </Box>
            </Stack>
          ) : (
            ''
          )}
          <ConfirmationPopup
            open={deleteLocationIndex ? true : false}
            handleClick={handleMarkerDelete}
            message={'You want to delete this location?'}
            handleClose={() => setDeleteLocationIndex(null)}
          />
        </>
      )}
    </Grid>
  )
}

// const ViewRoute = ({routeId,selectedData}) => {
const ViewRouteInstalls = () => {
  const location = useLocation()
  const routeId = location.state?.routeId // assigned_sales_order
  const selectedData = location.state?.selectedData // route row data
  const isFromPs = location.state?.isPs // true
  const [mapLoaded, setMapLoaded] = useState(false)
  const [tspSolution, setTspSolution] = useState([])
  const [apiLoaded, setApiLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [selectedSalesOrder, setSelectedSalesOrder] = useState(null)
  const [selectedRoute, setSelectedRoute] = useState(routeId)
  const [routeData, setRouteData] = useState([])
  const [driverData, setDriverData] = useState([])
  const [locations, setLocations] = useState([])
  const [directions, setDirections] = useState([])
  const [allDirections, setAllDirections] = useState([]) // Array to store multiple directions
  const [center, setCenter] = useState()
  const [home, setHome] = useState()
  const { google } = window
  const [currentLocation, setCurrentLocation] = useState(null)
  const userData = JSON.parse(localStorage.getItem('user'))
  const userId = userData.user_id
  const userType = userData.user_type
  const { enqueueSnackbar } = useSnackbar()
  const [totalDistance, setTotalDistance] = useState(0)
  const [nearestDistance, setNearestDistance] = useState(0)
  const [fetchingData, setFetchingData] = useState(false)
  const [filteredLocations, setFilteredLocations] = useState([])
  const [value, setValue] = useState('1')
  const [viewRoute, setViewRoute] = useState(false)
  console.log('isFromPs', isFromPs)
  useEffect(() => {
    setSelectedRoute(routeId)
  }, [routeId])

  const handleUpdateProductsAddress = async (data) => {
    console.log('productsssssssss' , data)
    try {
      const response = await axios.post(`${REST_API_END_POINT}rfq/update-products-route`, data);
      if (response.data.status === 1) {
        if(data?.address){
        enqueueSnackbar(response.data.message, { variant: "success" });
        }
        console.log('handleUpdateProductsAddress response', response.data.message);
      } else {
        if(data?.address){
        enqueueSnackbar(response.data.message, { variant: "error" });
        }
        console.log('handleUpdateProductsAddress response', response.data.message);
      }
    } catch (error) {
      console.log("Server error:", error);
      if(data?.address){
      enqueueSnackbar("Server error", { variant: "error" });
      }
      console.log('handleUpdateProductsAddress response', response.data.message);
    }
  };
  const handleUpdateServiceAddress = async (data) => {
    console.log("view dataaaa>",data)
    try {
      const response = await axios.post(`${REST_API_END_POINT}rfq/update-service-route`, data);
      if (response.data.status === 1) {
        if(data?.address){
        enqueueSnackbar(response.data.message, { variant: "success" });
        }
        console.log('handleUpdateServiceAddress response', response.data.message);
      } else {
        if(data?.address){
        enqueueSnackbar(response.data.message, { variant: "error" });
        }
        console.log('handleUpdateServiceAddress response', response.data.message);
      }
    } catch (error) {
      console.log("Server error:", error);
      if(data?.address){
      enqueueSnackbar("Server error", { variant: "error" });
      }
      console.log('handleUpdateServiceAddress response', response.data.message);
    }
  };

  const getLatandLongFromAddress = async (address) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address,
        )}&key=${GOOGLE_API_KEY}`,
      )
      const data = await response.json()
      if (data.status === 'OK') {
        const location = data.results[0].geometry.location
        return {
          Latitude: Number(location.lat),
          Longitude: Number(location.lng),
        }
      } else {
        console.error(
          'Geocode was not successful for the following reason:',
          data.status,
        )
        return null
      }
    } catch (error) {
      throw new Error('Geocoding failed. ' + error.message)
    }
  }
  const generateRandomColor = () => {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }
  const fetchAllDrivers = async () => {
    try {
      const response = await axios.post(
        `${REST_API_END_POINT}driver/get-all-verified-active-drivers`,
      )
      if (response.data.status === 1) {
        setDriverData(response.data.drivers)
        console.log('Fetch drivers - ', response.data)
      } else {
        setDriverData([])
      }
    } catch (error) {
      console.log('Error fetching drivers : ', error)
    }
  }

  useEffect(() => {
    if (!home) {
      fetchDetails()
    }
    fetchAllDrivers()
  }, [home])

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return // Dragged outside of droppable area
    }

    const startIndex = result.source.index
    const endIndex = result.destination.index

    // Update the order of locations in the state
    const newLocations = Array.from(locations)
    const [removed] = newLocations.splice(startIndex, 1)
    newLocations.splice(endIndex, 0, removed)

    setLocations(newLocations)

    // Recalculate directions based on the new order of locations
    // recalculateDirections(newLocations);
  }

  const fetchDetails = async () => {
    try {
      const res = await axios.post(
        `${REST_API_END_POINT}settings/get-companyProfile-details`,
      )
      if (res.data.status === 1) {
        const companyAddressData = res.data.data[0]
        const { address, city, state, zipcode,lat,lng ,id} = companyAddressData
        const fullAddress = `${address}, ${city}, ${state}, ${zipcode}`

        console.log('Company Address----', fullAddress)
        if(!lat && !lng){
          const latLong = await getLatandLongFromAddress(fullAddress)
          const { Latitude, Longitude } = latLong
          setCenter({ lat: Number(Latitude), lng: Number(Longitude) })
          setHome({ lat: Number(Latitude), lng: Number(Longitude) })
          if(Latitude && Longitude){
            console.log('productsssssssss 3')
            await handleUpdateCompanyAddress({
              id: id,
              lat:Latitude,
              lng:Longitude,
            })}

        }else{
          setCenter({ lat: Number(lat), lng: Number(lng) })
          setHome({ lat: Number(lat), lng: Number(lng) })
        }
        // if ("geolocation" in navigator) {
        //   navigator.geolocation.getCurrentPosition(
        //     position => {
        //       const { latitude, longitude } = position.coords;
        //       console.log('Current latitude:',latitude);
        //       console.log('Current longitude : ',longitude);
        //       setCenter({ lat:latitude, lng:longitude });
        //     },
        //     error => {
        //       console.error("Error getting user's location:", error);
        //     }
        //   );
        // } else {
        //   console.error("Geolocation is not supported by this browser.");
        // }
      }
    } catch (error) {
      console.log(error, 'errrrrrr')
    }
  }

  const handleUpdateCompanyAddress = async (data) => {
    try {
      const response = await axios.post(`${REST_API_END_POINT}settings/update-company-lat-lng`, data);
      if (response.data.status === 1) {
        // enqueueSnackbar(response.data.message, { variant: "success" });
        console.log('handleUpdateCompanyAddress success', response.data.message)
      } else {
        // enqueueSnackbar(response.data.message, { variant: "error" });
        console.log('handleUpdateCompanyAddress failed', response.data.message)
      }
    } catch (error) {
      console.log("Server error:", error);
      // enqueueSnackbar("Server error", { variant: "error" });
      console.log('handleUpdateCompanyAddress error', response.data.message)
    }
  };

  useEffect(() => {
    fetchDetails()
  }, [])

  useEffect(() => {
    // Load Google Maps API script dynamically
    const script = document.createElement('script')
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`
    script.async = true
    script.onload = () => setApiLoaded(true)
    document.body.appendChild(script)
    setMapLoaded(true)
    setLoading(false)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    console.log('locations===========', locations)
    const loadMap = async () => {
      try {
        if (!window.google || !window.google.maps) {
          throw new Error('Google Maps API is not loaded')
        }

        const tspSolution = tspSolver(locations)
        setTspSolution(tspSolution)

        const orderedLocations = tspSolution.map((index) => locations[index])

        const waypoints = [
          { location: home, stopover: true },
          ...orderedLocations.map((location) => ({
            location: { lat: location?.lat, lng: location?.lng },
            stopover: true,
          })),
          { location: home, stopover: true },
        ]

        const origin = waypoints[0]?.location
        const destination = waypoints[waypoints.length - 1]?.location

        const directionsService = new window.google.maps.DirectionsService()

        directionsService.route(
          {
            origin,
            destination,
            waypoints: waypoints.slice(1, waypoints.length - 1),
            travelMode: 'DRIVING',
          },
          (result, status) => {
            if (status === 'OK') {
              setAllDirections((prev) => [...prev, { result, type: 'forward' }])

              // Calculate total distance
              let distance = 0
              result.routes[0].legs.forEach((leg) => {
                distance += leg.distance.value // Distance in meters
              })
              setTotalDistance((distance / 1000).toFixed(2)) // Convert to km and fix to 2 decimal points
            } else {
              console.error(`Forward route request failed: ${status}`)
            }
          },
        )
      } catch (error) {
        console.error('Error loading routes:', error)
      }
    }

    if (mapLoaded && apiLoaded) {
      loadMap()
    }
  }, [mapLoaded, apiLoaded, locations])

  const calculateNearestInstallation = () => {
    if (!home || locations.length === 0) return

    let minDistance = Infinity
    locations.forEach((location) => {
      const distance = calculateDistance(home, location)
      if (distance < minDistance) {
        minDistance = distance
      }
    })

    setNearestDistance(minDistance.toFixed(2)) // Fix to 2 decimal points
  }

  const filterLatestMarkers = (locations) => {
    const uniqueLocations = new Map()

    locations.forEach((location, index) => {
      const key = `${location.lat},${location.lng}`
      uniqueLocations.set(key, { ...location, index: index + 1 }) // Overwrite to keep the latest marker
    })

    return Array.from(uniqueLocations.values())
  }

  useEffect(() => {
    if (home && locations.length > 0) {
      calculateNearestInstallation()
    }
    if (locations) {
      const uniqueMarkers = filterLatestMarkers(locations)
      setFilteredLocations(uniqueMarkers)
    }
  }, [home, locations])

  // const fetchInstallationByRoute = async (appointments) => {
  //   try {
  //     if (appointments) {
  //       const installations = appointments
  //       const installationData = []
  //       const seenInstallations = new Set()

  //       for (const data of installations) {
  //         if (!seenInstallations.has(data.id)) {
  //           seenInstallations.add(data.id)
  //           const latLong = await getLatandLongFromAddress(
  //             data?.installationAddress,
  //           )
  //           // installationData.push({ ...data, lat: latLong?.Latitude, lng: latLong?.Longitude });
  //           installationData.push({ ...data })
  //         }
  //       }
  //       console.log('Unique installationData:', installationData)
  //       return installationData
  //     } else {
  //       console.log(`No installations found`)
  //       return []
  //     }
  //   } catch (error) {
  //     console.log('Error fetching installations', error)
  //     return []
  //   }
  // }

  const [loadingEdit, setLoadingEdit] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [selectedServices, setSelectedServices] = useState([])
  const [additionalSelectedServices, setAdditionalSelectedServices] = useState(
    [],
  )
  const [rfpProduct, setRfpProduct] = useState([])
  const [rfpServices, setRfpServices] = useState([])
  const [details, setDetails] = useState(null)
  const [locationDetails, setLocationDetails] = useState([])
  const [render, setRender] = useState(false)
  console.log('viewroute---selectedProducts----',selectedProducts);
  console.log('viewroute---selectedServices----',selectedServices);
  console.log('viewroute---additionalSelectedServices----',additionalSelectedServices);
  console.log('viewroute---rfpProduct----', rfpProduct)
  console.log('viewroute---rfpServices----', rfpServices)
  // console.log('viewroute---location----',locationDetails);

  useEffect(() => {
    fetchRequestDetails()
  }, [routeId])

  useEffect(() => {
    if (selectedProducts.length > 0) {
      const extractedLocations = selectedProducts.flatMap(
        (product) => product.location || [],
      )

      setLocationDetails(extractedLocations)
      if (extractedLocations?.length > 0) {
        setRender(!render)
      }
    }
  }, [selectedProducts])

  const fetchRequestDetails = async () => {
    try {
      setLoadingEdit(true)
      const res = await axios.post(`${REST_API}webservice/view-request`, {
        request_id: routeId,
        userId,
        userType,
      })
      setDetails(res.data?.data[0])
      const requestData = res.data?.data?.[0] || {}
      const invoiceData = await fetchOrderProductDetails(requestData?.id)

      const newAllProducts = res.data.products || []
      const allMaterials =
        res.data.materials?.map((mat) => ({
          ...mat,
          markup: Number(mat.retail_price || 0),
        })) || []
      console.log('invoiceData---?.cust_product----', invoiceData?.cust_product)
      console.log('invoiceData---newAllProducts----', newAllProducts)

      // Extracting product data
      const extractedProducts = invoiceData?.cust_product
        ?.map((row) => {
          const match = newAllProducts.find(
            (data) => data.id === row.product_id,
          )
          return match
            ? {
                ...match,
                indexId: row.product_index,
                customer_order_product_id:row.id,
                product_notes: row.product_notes,
                discount: row.discount,
                adjustment: row.adjustment,
                quantity: row.locationQuantity,
                location: row.location,
                attachments: row.attachments,
                locationQuantity: row.locationQuantity,
                markAdjType: row.markAdjType,
                fromMapServices: row.map_service === 1,
              }
            : undefined
        })
        .filter(Boolean)

      setSelectedProducts(extractedProducts)
      if (extractedProducts?.length >0) {
        setRender(!render)
      }

      // Extracting RFP products
      const extractedRfpProduct = invoiceData?.cust_product?.filter(
        (row) => row.rfp === 1 && row.material === 0,
      )
      const updatedRfpProduct = await Promise.all(
        extractedRfpProduct.map(async (item) => {
          console.log('productsssssssss 1' , item)
          let lat = item.lat ? parseFloat(item.lat) : null;
          let lng = item.lng ? parseFloat(item.lng) : null;
          let color = item.color || generateRandomColor();
      
          // Extract address fields from both item and its location array (if available)
          let address = item.address || "";
          let city = item.city || "";
          let state = item.state || "";
          let zip = item.zip || "";
      
          if (item.location?.length > 0) {
            const locationData = item.location[0]; // Assuming we take the first location object
            address = locationData.address || address;
            city = locationData.city || city;
            state = locationData.state || state;
            zip = locationData.zip || zip;
            lat = locationData.lat || lat;
            lng = locationData.lng || lng;
          }
      
          if (!lat || !lng) {
            console.log('productsssssssss 2')
            const productAddress = `${address}, ${city}, ${state}, ${zip}`;
            try {
              let latLong = await getLatandLongFromAddress(productAddress);
      
              lat = latLong?.Latitude || lat;
              lng = latLong?.Longitude || lng;
              if(lat && lng){
                console.log('productsssssssss 3')
                await handleUpdateProductsAddress({
                  // order_id: item?.customer_order_product_id || item?.id,
                  location_id: item?.id,
                  lat:lat,
                  lng:lng,
                })}
            } catch (error) {
              console.error("Error fetching lat/long:", error);
            }
          }
      
          return {
            ...item,
            lat,
            lng,
            color,
            address,
            city,
            state,
            zip,
          };
        })
      );
      console.log('updated jobs >>' , updatedRfpProduct)
      setRfpProduct(updatedRfpProduct)
      if (updatedRfpProduct?.length > 0) {
        setRender(!render)
      }
      // Extracting service data
      const extractedServices = invoiceData?.cust_product?.flatMap(
        (product) => {
          return product.services.map((service) => ({
            ...service,
            productIndex: product.product_index,
            product_id: product.product_id,
            qtyData: product.quantity,
            quantity:product.locationQuantity || 1,
            fromMapServices: product.map_service === 1,
            locationQuantity: product.locationQuantity || 1,
          }))
        },
      )

      setSelectedServices(extractedServices)

      // Extracting additional services
      let newIndexCounter = 0
      const additionalServices =
        res.data.requestServices?.filter((item) => item.recipe_id === null && item.rfp != 1) ||
        []
      const additionalServicesData = additionalServices.map((serviceItem) => {
        console.log("serviceItem",serviceItem)
        newIndexCounter++
        return {
          ...serviceItem,
          indexId: newIndexCounter,
          productId: serviceItem.labour_cost_service_id,
          location: [
            {
              address: serviceItem.address,
              city: serviceItem.city,
              state: serviceItem.state,
              zip: serviceItem.zip,
            },
          ],
        }
      })
      console.log("serviceItem2",additionalServicesData)
      setAdditionalSelectedServices(additionalServicesData)
      if (additionalServicesData?.length > 0) {
        setRender(!render)
      }

      // Extracting RFP services
      // const extractedRfpServices = res.data.requestServices?.filter(item => item.recipe_id === null && item.rfp === 1)?.map(serviceItem => ({
      //   ...serviceItem,
      //   addresses: [
      //     {
      //       address: serviceItem.address,
      //       city: serviceItem.city,
      //       state: serviceItem.state,
      //       zip: serviceItem.zip,
      //     }
      //   ],

      // })) || [];
      const extractedRfpServices =
        res.data.requestServices
          ?.filter((item) => item.recipe_id === null && item.rfp === 1)
          ?.map((serviceItem) => ({
            ...serviceItem,
            addresses: [
              {
                address: serviceItem.address,
                city: serviceItem.city,
                state: serviceItem.state,
                zip: serviceItem.zip,
              },
            ],
          })) || []
      const updatedRfpServices = await Promise.all(
        extractedRfpServices.map(async (item) => {
          const serviceId = item?.id
          console.log("rfp servicessss1 >",item)
          let lat = item.lat ? parseFloat(item.lat) : null
          let lng = item.lng ? parseFloat(item.lng) : null
          let serviceAddress = ''
          let color = item.color || generateRandomColor()

          if (!lat || !lng) {
            console.log("rfp servicessss2 >")
            serviceAddress = `${item?.address}, ${item?.city}, ${item?.state}, ${item?.zip}`
            try {
              let latLong = await getLatandLongFromAddress(serviceAddress)
              console.log('rfpservice--latlong---',latLong);
              
              lat = latLong?.Latitude || lat
              lng = latLong?.Longitude || lng
              if(lat && lng){
                console.log("rfp servicessss3 >",serviceId)
                await handleUpdateServiceAddress({
                  service_id: serviceId,
                  lat:lat,
                  lng:lng,
                  is_lat_lng:true
                });
              }
            } catch (error) {
              console.error('Error fetching lat/long:', error)
            }
          }

          return {
            ...item,
            lat,
            lng,
            color,
          }
        }),
      )

      setRfpServices(updatedRfpServices)
      if (updatedRfpServices?.length > 0) {
        setRender(!render)
      }
      console.log('setRfpServices>>>', extractedRfpServices)
    } catch (error) {
      console.error('Error fetching request details:', error)
    } finally {
      setLoadingEdit(false)
    }
  }

  const fetchOrderProductDetails = async (order_id) => {
    try {
      setLoadingEdit(true)
      let orderId = details?.id || order_id

      if (orderId) {
        const res = await axios.post(
          `${REST_API}webservice/get-customer-order-product/${orderId}`,
        )
        return res.data.data
      }
    } catch (error) {
      console.error('Error fetching order product details:', error)
    } finally {
      setLoadingEdit(false)
    }
  }

  // useEffect(async () => {
  //   if (selectedSalesOrder) {
  //     const directionsPromises = [selectedSalesOrder]?.map(
  //       async (route, index) => {
  //         const installations = await fetchInstallationByRoute(
  //           route.appointments,
  //         )
  //         if (installations.length >= 1) {
  //           setLocations(installations)
  //           const waypoints = [
  //             { location: home, stopover: true }, // Add home as the first location
  //             ...installations.map((location) => ({
  //               location: { lat: location.lat, lng: location.lng },
  //               stopover: true,
  //             })),
  //             { location: home, stopover: true }, // Add home as the last location
  //           ]
  //           const origin = waypoints[0]?.location
  //           const destination = waypoints[waypoints.length - 1]?.location

  //           const directionsService = new window.google.maps.DirectionsService()
  //           return new Promise((resolve, reject) => {
  //             directionsService.route(
  //               {
  //                 origin,
  //                 destination,
  //                 waypoints: waypoints.slice(1, waypoints.length - 1),
  //                 travelMode: 'DRIVING',
  //               },
  //               (result, status) => {
  //                 if (status === 'OK') {
  //                   resolve({ result, index })
  //                 } else {
  //                   reject(
  //                     `Directions request failed for route ${
  //                       index + 1
  //                     }: ${status}`,
  //                   )
  //                 }
  //               },
  //             )
  //           })
  //         }
  //       },
  //     )

  //     const directionsResults = await Promise.all(directionsPromises)
  //     setAllDirections(directionsResults.filter((d) => d !== undefined))
  //   }
  // }, [selectedSalesOrder])

  const containerStyle = {
    // display: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'space-between',
    // alignItems: 'center',
    marginBottom: 10,
    position: 'absolute',
    right: '10px',
    width: '260px',
  }
  const subcontainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: -35,
  }
  let i = 0

  return (
    <Stack spacing={3}>
      <Box
        sx={{
          p: 2,
          mt: 2,
          height: 'fit-content',
          width: '100%',
          borderRadius: 1,
          // bgcolor: 'grey.50012'
        }}
      >
        <MapComponent2
          driverDataaa={driverData}
          home={home}
          selectedSalesOrder={selectedSalesOrder}
          setSelectedSalesOrder={setSelectedSalesOrder}
          selectedRoute={selectedRoute}
          setSelectedRoute={setSelectedRoute}
          locations={locations}
          setLocations={setLocations}
          directions={directions}
          setDirections={setDirections}
          getLatandLongFromAddress={getLatandLongFromAddress}
          selectedData={selectedData}
          isFromPs={isFromPs}
          locationDetails={locationDetails}
          requestProducts={selectedProducts}
          setRequestProducts={setSelectedProducts}
          additionalSelectedServices={additionalSelectedServices}
          setAdditionalSelectedServices={setAdditionalSelectedServices}
          selectedRfpServices={rfpServices}
          selectedRfpProduct={rfpProduct}
          render={render}
          details={details}
        />
      </Box>
    </Stack>
  )
}

export default ViewRouteInstalls
