import { Autocomplete, Avatar, Backdrop, Badge, Box, Button, Card, CardHeader, Checkbox, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from "@material-ui/core";
import Page from '../../components/Page';
import { capitalCase, sentenceCase } from "change-case";
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import lockOpenFill from '@iconify/icons-ph/lock-open-fill';
import editFill from '@iconify/icons-eva/edit-fill';
import plusFill from '@iconify/icons-eva/plus-fill';
//materail
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Label from "src/components/Label";
import { useLocation, useNavigate, useParams } from "react-router";

import ViewRequest from "src/components/_dashboard/official-request/ViewRequest";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import PublicMsgBoard from "src/components/_dashboard/official-request/PublicMsgBoard";
import RequestTabUpdates from 'src/components/_dashboard/user/account/RequestTabUpdates'
import PublicMessageBoard from 'src/components/_dashboard/user/profile/PublicMessageBoard.js'
import RequestUpdates from "src/components/_dashboard/official-request/RequestUpdates";
import PaymentRequest from "src/components/_dashboard/official-request/PaymentRequest";
import { AddCircle, BlockOutlined, Delete, KeyboardBackspaceOutlined, Print, LocationOn, Add, Remove, CenterFocusStrong, NoteAdd } from "@material-ui/icons";
import Modal from "src/components/_dashboard/blog/ProjectModel";
import Icon from "@iconify/react";
import Scrollbar from "src/components/Scrollbar";
import axios from "axios";
import { REST_API, REST_API_END_POINT, SEND_EMAIL, UNITS } from "src/constants/Defaultvalues";
import moment from "moment";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useSnackbar } from "notistack";
import { LoadingButton, MobileDatePicker } from "@material-ui/lab";
import { useCallback } from "react";
import { UploadAvatar, UploadMultiFile } from "src/components/upload";
import AddClients from "src/components/_dashboard/official-request/AddClients";
import AddProposal from "src/components/_dashboard/official-request/AddProposal";
import ConfirmationPopup from "src/components/_dashboard/official-request/ConfirmationPopup";
import ReactToPrint from 'react-to-print'
import {storage} from '../../firebase/firebase'
import { getDownloadURL, ref as imageref , uploadBytesResumable } from 'firebase/storage';
import { Link } from "react-router-dom";
import { empty } from "src/Helpers/Common";
import Locations from "src/components/_dashboard/official-request/Locations";
import Workflow from "src/components/_dashboard/general-analytics/RfqForm";
import {  AccountNotifications } from '../../components/_dashboard/user/account'
import { set } from "lodash";
import Proposals from "src/components/_dashboard/official-request/Proposals";
import useCompanyProfile from "src/hooks/useCompanyProfile";
import LoadingScreen from "src/components/LoadingScreen";
import { ButtonAnimate } from "src/components/animate";
import AnimatedButton from "src/components/animate/AnimatedButton";
import RouteMap from "src/components/_dashboard/official-request/RouteMap";
const RowResultStyle = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));
const MAX_CHARACTERS = 35;
const getStatusColorAndLabel = (status , quotation) => {
  console.log("statussss",status , quotation)
  switch (status) {
    case 0:
      return { color: 'error', label: 'Status: Removed' };
    case 1:
      return { color: 'warning', label: 'Status: Draft' };
    case 2:
      return { color: 'info', label: 'Status: Open' };
    case 3:
      return { color: 'primary', label: 'Status: Evaluating' };
    case 4:
      return { color: quotation == 0 ? 'default' : 'success', label: quotation == 0 ? 'Status: Archive' :'Status: Approved' };
    case 5:
      return { color: 'warning', label: 'Status: Closed' };
    case 6:
      return { color: 'error', label: 'Status: Rejected' };
    default:
      return { color: 'default', label: 'Status: Open' };
  }
};

const markupTypes = [
  {id : 1 , name : 'Wholesale Markup'},
  {id : 2 , name : 'Retail Markup'}
]

const marketingAdjustmentType = [
  { id: 1, name: '%' },
  { id: 2, name: '$' },
  { id: 3, name: '¢' }
];

export default function ViewofficialRequest({popupOn,idOfProject,isEditEstimate,closeView,setRenderCards,renderCards,onDataChange}) {
 const {COMPANY_LOGO,COMPANY_NAME} = useCompanyProfile()
  const theme = useTheme()
  const { id:ids } = useParams()
  const id =popupOn ? idOfProject :ids
  let edit= popupOn ? isEditEstimate : useLocation().state
  const componentRef = useRef();
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();
  const user = JSON.parse(localStorage.getItem('user'))
  const [currentRFQData, setCurrentRFQdata] = useState({})
  const [openEdit, setOpenEdit] = useState(false)
  const [showServices, setShowServices] = useState(true)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [initialServiceCostInProductFormula, setInitialServiceCostInProductFormula] = useState(0)
  const [markupType, setMarkupType] = useState();
  const [clientApprove, setClientApprove] = useState();
  const [designs, setDesigns] = useState([])
  const [details, setDetails] = useState(null)
  const [skills, setSkill] = useState(null)
  const [workflow, setWorkFlow] = useState(null)
  const [allProducts, setAllproducts] = useState(null)
  const [invoice, setInvoice] = useState(null)
  const [open, setIsOpen] = useState(false)
  const [totalsum, setTotalSum] = useState(0);
  const [rfqSubTotal, setRFQSubTotal] = useState(0);
  const [DiscountTotal, setDiscountTotal] = useState(0);
  const [totalWholesalePrice, setTotalWholesalePrice] = useState(0);
  const [totalRetailPrice, setTotalRetailPrice] = useState(0);
  const [totalProductFormula, setTotalProductFormula] = useState(0);
  const [addClient, setAddClient] = useState(false)
  const [addVendor, setAddVendor] = useState(false)
  const [addProposal, setAddProposal] = useState(false)
  const [openInvoice, setOpenInvoice] = useState(false)
  const [requestAccept,setRequestAccept] = useState(false)
  const [requestReject,setRequestReject] = useState(false)
  const [allClients, setAllClients] = useState(null)
  const [allClientsEdit, setAllClientsEdit] = useState(null)
  const [customerData, setCustomerData] = useState([])
  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));
  const [currentTab, setCurrentTab] = useState(user.isVendor ? 'Details' : 'Estimate Details')
  const [openPreview, setOpenPreview] = useState(false)
  const [avatarUrl,setAvatarUrl] = useState([])
  const [openDelete,setOpenDelete] = useState(false)
  const [reOpen,setReopen] = useState(false)
  const [convertRfq,setConvertRfq] = useState(false)
  const [confirmInvoice,setConfirmInvoice] = useState(false)
  const [invoiceReject,setRejectInvoice] = useState(false)
  // const [reqAcceptClient,setReqAcceptClient] = useState(false)
  const [reason,setReason] = useState('')
  const [acceptedClients,setAcceptedClients] = useState(null)
  const [updates,setUpdates] = useState(null)
  const [isSaving,setIsSaving] = useState(false)
  const [previewData,setPreviewData] = useState({})
  const [locationStates, setLocationStates] = useState({});
  const [payments,setPayments]= useState(null)
  const [projectTypes, setProjectTypes] = useState([]);
  const [projectCategories, setProjectCategories] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [refresh,setRefresh]=useState(false)
  const [refreshData,setRefreshData]=useState(false)
  const ref = useRef()
  const [openWorkflow,setWorkflowOpen]=useState(false)
  const [manual, setManual] = useState(false)
  const [signAgreements,setSignAgreements] = useState(null)
  const [portfolio,setPortfolio] = useState(null)
  const [customTemplate,setCustomTemplate] = useState(null)
  const [questionnaireTemplate,setQuestionnaireTemplate] = useState(null)
  const [allDataEmpty,setDataEmpty]=useState(true)
  const [proposalData,setProposalData]=useState(null)
  const [teamMembers,setTeamMember] = useState([])
  const [labourCostServices, setLabourCostServices] = useState([])
  const [selectedServices, setSelectedServices] = useState([]);
  const [invoiceSelectedServices, setInvoiceSelectedServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([])
  const [requestServices, setRequestServices] = useState([])
  const [loading,setLoading]=useState(false)
  const [budget,setBudget]=useState(0)
  const [totalMarkupPercentage,setTotalMarkupPercentage]=useState(0)
  const [totalMarkupAmount,setTotalMarkupAmount]=useState(0)
  const [isSubmitted, setIsSubmitted] = useState(false);    const [labourInstallService, setLabourInstallService] = useState([]);
  

  const [additionalSelectedServices, setAdditionalSelectedServices] = useState([]);
  localStorage.setItem('requestId',id?id:null)
  const userType=user.user_type
  const userId=user.user_id
  const explodedPermissions = user?.exploded_permissions
  const hasMarkupPermission=explodedPermissions?.some(permission => ['View Markup'].includes(permission)) ? true : false
  const hasDiscountPermission = explodedPermissions?.some(permission => ['Manage Discount'].includes(permission)) ? false : true
  const [showWarning, setShowWarning] = useState(selectedServices.map(() => false));
  const [allLocationSame, setAllLocationSame] = useState(false)
  const [indexCounter, setIndexCounter] = useState(0)
  let productFormulaCost=0
  const [totalLabourCost, setTotalLabourCost] = useState(0)
  const [totalProductLabourCost, setTotalProductLabourCost] = useState(0)
  const [rfps, setRfps] = useState([]); // List of RFPs
  const [selectedRfp, setSelectedRfp] = useState(null); // Selected RFP
  const [openModal, setOpenModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [rfpServices, setRfpServices] = useState([]);
  const [rfpProducts, setRfpProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [cartService, setCartService] = useState([]);
  const [rfpServicesEdit, setRfpServicesEdit] = useState([]);
  const [rfpLocationStates, setRfpLocationStates] = useState({});
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [materials, setAllMaterials] = useState([])
  const [openProductNote, setOpenProductNote] = useState({});
  const [isSavingNote, setIsSavingNote] = useState(false);
  const [currentNote, setCurrentNote] = useState('');
  const [taxAmount, setTaxAmount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [selectedTaxCategory,setSelectedTaxCategory]=  useState(null);
  const [taxCategories,setTaxCategories]=  useState([]);
  const [data, setData] = useState({});
  const [isCommunityLoading, setIsCommunityLoading] = useState(false);
  const [mapServices,setMapServices]=  useState([]);
  const [mapServicesLoading, setMapServicesLoading] = useState(false);
  const [selectedMapserviceProducts, setSelectedMapserviceProducts] = useState([]);
  const [rfpServiceLocationStates, setRfpServiceLocationStates] = useState({});
  const [allRfpServiceLocationSame, setAllRfpServiceLocationSame] = useState(true)
  const [allRfpProductsLocationSame, setAllRfpProductsLocationSame] = useState(true)

  const fetchDetails = async () => {
    try {
      const res = await axios.post(`${REST_API_END_POINT}settings/get-companyProfile-details`);
      if (res.data.status === 1) {
        setData(...res.data.data);
      }
    } catch (error) {
      console.log('get-companyProfile-details error : ', error);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const handleOpenNote = (indexId, note) => {
    setCurrentNote(note || '');
    setOpenProductNote((prev) => ({ ...prev, [indexId]: true }));
  };

  const handleCloseNote = (indexId) => {
    setOpenProductNote((prev) => ({ ...prev, [indexId]: false }));
    setCurrentNote('');
  };

  const handleNoteChange = (event) => {
    const newNote = event.target.value;
    if (newNote.length <= MAX_CHARACTERS) {
      setCurrentNote(newNote);
    }
  };

  const handleSaveNote = (indexId) => {
    setIsSavingNote(true);
    setTimeout(() => {
      setSelectedProducts((prev) =>
        prev.map((product) =>
          product.indexId === indexId ? { ...product, product_notes: currentNote } : product
        )
      );
      setIsSavingNote(false);
      handleCloseNote(indexId);
    }, 100);
  };

  const handleRfpSelect = (event, value) => {
    setSelectedRfp(value);
    setRfpServices(value?.services?.filter(service => !isInCartService(service)) || []);
    setRfpProducts(value?.products?.filter(product => !isInCart(product)) || []);
    if (!empty(value)) {
      setOpenModal(true);
    }

  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // const addToCart = (item) => {
  //   // Extract project location from Formik's values
  //   const { address: projAddress = '', city: projCity = '', state: projState = '', zip: projZip = '' } = values?.community || {};
  //   const communityAddress = {
  //     id: 0,
  //     address: projAddress,
  //     city: projCity,
  //     state: projState,
  //     zip: projZip,
  //   };
  
  //   if (tabIndex === 0) {
  //     // Handle RFP services
  //     const serviceWithLocation = {
  //       ...item,
  //       location: [communityAddress], // Assign project location to the service
  //     };
  
  //     setCartService((prevServices) => [...prevServices, serviceWithLocation]);
  //   } else {
  //     // Handle RFP products
  //     let location = [
  //       {
  //         id: 0,
  //         address: '',
  //         city: '',
  //         state: '',
  //         zip: '',
  //       },
  //     ];
  
  //     // Use project location if available
  //     if (projAddress && projCity && projState && projZip) {
  //       location = [communityAddress];
  //     }
  
  //     const productWithLocation = {
  //       ...item,
  //       location,
  //     };
  
  //     setCart((prevProducts) => [...prevProducts, productWithLocation]);
  //   }
  
  //   // Close modal and reset RFP selection
  //   setOpenModal(false);
  //   setSelectedRfp(null);
  //   setFieldValue('rfp', null);
  // };

  const addToCart = (item) => {
    // Extract project/community address
    const { address: projAddress = '', city: projCity = '', state: projState = '', zip: projZip = '' } = values?.community || {};
    const communityAddress = { address: projAddress, city: projCity, state: projState, zip: projZip };

    const locationQuantity = item.location_quantity || 1; // Ensure a default value of 1

    if (tabIndex === 0) {
        // ✅ Adding to RFP Services
        const newService = assignDefaultLocation(cartService, item, allRfpServiceLocationSame, communityAddress, locationQuantity);
        setCartService([...cartService, newService]);

        if (allRfpServiceLocationSame) {
            setCartService((prevServices) =>
                prevServices.map((service) => ({
                    ...service,
                    location: Array.from({ length: locationQuantity }, () => ({ ...communityAddress })),
                }))
            );
        }
    } else {
        // ✅ Adding to RFP Products
        const newProduct = assignDefaultLocation(cart, item, item.allRfpProductsLocationSame, communityAddress, locationQuantity);
        setCart([...cart, newProduct]);

        if (item.allRfpProductsLocationSame) {
            setCart((prevProducts) =>
                prevProducts.map((product) => ({
                    ...product,
                    location: Array.from({ length: locationQuantity }, () => ({ ...communityAddress })),
                }))
            );
        }
    }

    // ✅ Close modal and reset form fields
    setOpenModal(false);
    setSelectedRfp(null);
    setFieldValue('rfp', null);
  };

  const assignDefaultLocation = (existingItems, newItem, allLocationsSame, communityAddress) => {
    let assignedLocation = [];
  
    if (allLocationsSame && existingItems.length > 0) {
      // Use the first item's location as the common location
      const commonLocation = existingItems[0]?.location?.[0] || {
        address: '',
        city: '',
        state: '',
        zip: '',
      };
      assignedLocation = [{ ...commonLocation }];
    } else if (
      communityAddress.address &&
      communityAddress.city &&
      communityAddress.state &&
      communityAddress.zip
    ) {
      // Use the community address as the default location
      assignedLocation = [
        {
          id: 0,
          address: communityAddress.address,
          city: communityAddress.city,
          state: communityAddress.state,
          zip: communityAddress.zip,
        },
      ];
    } else {
      // Default to an empty location
      assignedLocation = [
        {
          id: 0,
          address: '',
          city: '',
          state: '',
          zip: '',
        },
      ];
    }
  
    return {
      ...newItem,
      location: assignedLocation,
    };
  };

  console.log("######################Cart", cart);
  console.log("######################Cart Service", cartService);
  const isInCart = (item) => {
    return cart.some(cartItem => cartItem.product_id === item.product_id);
  };
  const isInCartService = (item) => {
    return cartService.some(cartItem => cartItem.labour_cost_service_id
      === item.labour_cost_service_id);
  };
  const closeModal = () => {
    setOpenModal(false);
    setFieldValue('rfp',null)
  };
  const handleDeleteRFPProduct = (productId) => {
    setCart(prevCart => prevCart.filter(item => item.product_id !== productId));
  };
  const handleDeleteRFPService = (productId) => {
    console.log("######################Cartde", productId);
    setCartService(prevCart => prevCart.filter(item => item.labour_cost_service_id !== productId));
  };

  const handleMaterial = (newValue) => {
    setSelectedMaterials((prevMaterials) => {
      const additionals = prevMaterials?.filter(item => item.isAdditionalProduct == true);
      const baseIndex = prevMaterials?.length || 0;
      const updatedNewValue = newValue.map((item, idx) => ({
        ...item,
        newIndexId: baseIndex + 1,
      }));
      return [...updatedNewValue, ...additionals];
    });
  };

  const handleDeleteMaterial = (id, indexId) => {
    const updatedMaterials = selectedMaterials?.filter((data) => {
      if (data?.isAdditionalProduct) {
        return data?.newIndexId !== indexId;
      }
      return data?.id !== id;
    });
    
    setSelectedMaterials(updatedMaterials);
    };

    const addNewProductRow = () => {
      const newIndexCounter = indexCounter + 1
      setIndexCounter(newIndexCounter)
      const { address: projAddress = '', city: projCity = '', state: projState = '', zip: projZip = '' } = values?.community || {};
      // Auto-populate addresses from the selected Project
      const newAddresses = [{
        id: 0,
        address: projAddress,
        city: projCity,
        state: projState,
        zip: projZip
      }];
    
      setSelectedProducts((prevProducts) => {
      // if (allLocationSame && prevProducts.length > 0) {
      //     const commonAddress = { ...prevProducts[0].addresses[0] } || {
      //     address: '',
      //     city: '',
      //     state: '',
      //     zip: '',
      //     };
      //     newAddresses[0] = commonAddress;
      // }
      return [
      ...prevProducts,
      { 
          indexId: newIndexCounter, 
          name: '', 
          quantity: 1, 
          price: 0,
          adjustment:0,
          retail_price:0, 
          unit:4,
          locationQuantity: 1, 
          expected_total_service_cost:0,
          expected_service_cost:0,
          expected_material_cost:0,
          discount: 0 ,
          allLocationSame: true,
          service:[],
          isAdditionalProduct:true,
          location: newAddresses
      },
      ]});
    };
  
    // const addNewServiceRow = () => {
    //   const { address: projAddress = '', city: projCity = '', state: projState = '', zip: projZip = '' } = values?.community || {};
    //   // Auto-populate addresses from the selected Project
    //   const newAddresses = [{
    //     id: 0,
    //     address: projAddress,
    //     city: projCity,
    //     state: projState,
    //     zip: projZip
    //   }];
  
    //   setAdditionalSelectedServices((prevServices) => {
    //     return [
    //       ...prevServices,
    //       {  
    //         id:null,
    //         newIndexId: prevServices?.length + 1,
    //         name: '', 
    //         avatarUrl: null,
    //         quantity: 1, 
    //         cost: 0,
    //         markup: 0, 
    //         workflow: null,
    //         originalMarkup: 0, 
    //         service_type: null,
    //         total_cost:0,
    //         product_total:0,
    //         allLocationSame: true,
    //         isAdditionalProduct:true,
    //         addresses: newAddresses,
    //       }
    //     ]
    //   });
    // };

    const addNewServiceRow = () => {
      const {
        address: projAddress = '',
        city: projCity = '',
        state: projState = '',
        zip: projZip = ''
      } = values?.community || {};
    
      const newAddresses = [{
        id: 0,
        address: projAddress,
        city: projCity,
        state: projState,
        zip: projZip
      }];
    
      setAdditionalSelectedServices((prevServices) => {
        const maxId = prevServices.reduce(
          (max, item) => Math.max(max, item.newIndexId || 0),
          0
        );
    
        return [
          ...prevServices,
          {
            id: null,
            newIndexId: maxId + 1,
            name: '',
            avatarUrl: null,
            quantity: 1,
            cost: 0,
            markup: 0,
            workflow: null,
            originalMarkup: 0,
            service_type: null,
            total_cost: 0,
            product_total: 0,
            allLocationSame: true,
            isAdditionalProduct: true,
            addresses: newAddresses,
          }
        ];
      });
    };
    
  
    // const addNewMaterialRow = () => {
    //   setSelectedMaterials((prevProducts) => {
    //   return [
    //   ...prevProducts,
    //   { 
    //     newIndexId: prevProducts?.length + 1,
    //     quantity: 1, 
    //     allLocationSame: true,
    //     isAdditionalProduct:true,
    //     description: '',
    //     finishedProductQty: 0,
    //     markUpCost: 0,
    //     markup: 0,
    //     material_types: 1,
    //     max_price: null,
    //     max_value: 10,
    //     meterial: 1,
    //     min_value: 1,
    //     name: '',
    //     partial: 0,
    //     partialProductQty: 0,
    //     pf_retail_markup: null,
    //     price: 0,
    //     recipe_id: null,
    //     retail_price: 0,
    //     special_price: 0,
    //     tags: '',
    //     tax: null,
    //     tax_category: 0,
    //     total_qty: 0,
    //     twilio_field_value_sid: null,
    //     unit: 4,
    //     unit_qty: 0,
    //     vendor_id: '',
    //     vpt_number: '',
    //     workflow: null,
    //   },
    //   ]});
    // };
    

    const addNewMaterialRow = () => {
      setSelectedMaterials((prevProducts) => {
        const maxId = prevProducts.reduce(
          (max, item) => Math.max(max, item.newIndexId || 0),
          0
        );
    
        return [
          ...prevProducts,
          {
            newIndexId: maxId + 1,
            quantity: 1,
            allLocationSame: true,
            isAdditionalProduct: true,
            description: '',
            finishedProductQty: 0,
            markUpCost: 0,
            product_retail_price:0,
            markup: 0,
            material_types: 1,
            max_price: null,
            max_value: 10,
            meterial: 1,
            min_value: 1,
            name: '',
            partial: 0,
            partialProductQty: 0,
            pf_retail_markup: null,
            price: 0,
            product_price:0,
            recipe_id: null,
            retail_price: 0,
            special_price: 0,
            tags: '',
            tax: null,
            tax_category: 0,
            total_qty: 0,
            twilio_field_value_sid: null,
            unit: 4,
            unit_qty: 0,
            vendor_id: '',
            vpt_number: '',
            workflow: null,
          }
        ];
      });
    };
    


  const VALUE = [
    { id: 0, value: 'Sl No.' },
    { id: 1, value: 'Products' },
    { id: 2, value: 'Note' },
    // { id: 3, value: 'Product formula cost (Material cost + Service cost with markup)' },
    { id: 4, value: 'Product formula cost' },
    { id: 5, value: markupType ? ((markupType == 2 && hasMarkupPermission) ? 'Retail Price Each' : null) : null },
    { id: 6, value: markupType ? ((markupType == 1 && hasMarkupPermission) ? 'Retail Price Each' : null) : null },
    { id: 14, value: hasMarkupPermission ? 'SPMA' : null },
    // { id: 7, value: hasMarkupPermission ? 'Markup (%)' : null },
    { id: 8, value: 'Unit' },
    { id: 9, value: 'Quantity' },
    { id: 10, value: 'Location' },
    // { id: 11, value: 'Discount' },
    { id: 11, value: 'Marketing Adjustment Type' },
    { id: 11, value: 'Marketing Adjustment' },
    { id: 12, value: 'Subtotal' },
    { id: 13, value: '' }
  ];
  
  const VALUEPreview = [
    { id: 0, value: 'Sl No.' },
    { id: 1, value: 'Products' },
    // { id: 2, value: 'Product formula cost (Material cost + Service cost with markup)' },
    { id: 3, value: markupType ? ((markupType == 2 && hasMarkupPermission) ? 'Retail Price Each' : null) : null },
    { id: 4, value: markupType ? ((markupType == 1 && hasMarkupPermission) ? 'Retail Price Each' : null) : null },
    { id: 14, value: hasMarkupPermission ? 'SPMA' : null },
    { id: 5, value: hasMarkupPermission ? 'Markup (%)' : null },
    { id: 6, value: 'Unit' },
    { id: 7, value: 'Quantity' },
    // { id: 8, value: 'Discount' },
    { id: 11, value: 'Marketing Adjustment Type' },
    { id: 8, value: 'Marketing Adjustment' },
    { id: 9, value: 'Subtotal' },
    { id: 10, value: '' }
  ]

  const VALUE_SERVICES = [
    { id: 0, value: 'Product Sl No.' },
    { id: 1, value: 'Service Name' },
    { id: 2, value: 'Markup (%)' },
    { id: 3, value: 'Cost (Hourly)' },
    { id: 4, value: 'Markup Cost (Hourly)' },
    { id: 5, value: 'Hours' },
    { id: 6, value: 'Product Formula Cost'},
    { id: 7, value: 'Additional cost' },
    { id: 8, value: 'Total cost' },
    { id: 9, value: '' }
  ];
  const VALUE_MATERIAL=[
    {id: 0, value: 'Product'},
    {id: 1, value: 'Unit Price'},
    {id: 2, value: 'Markup (%)'},
    {id: 3, value: 'Markup Cost (Unit)' },
    {id: 4, value: 'Quantity'},
    {id: 5, value: 'Product Formula Cost'},
    {id: 6, value: 'Total'},
    {id: 7, value: '' }
  ]
  const VALUE_RFP_Product = [
    { id: 0, value: 'Name' },
    { id: 1, value: 'Mark Up' },
    { id: 2, value: 'Cost ' },
    { id: 3, value: 'Retail Price Each ' },
    { id: 4, value: 'Unit' },
    { id: 5, value: 'Quantity' },
    { id: 6, value: 'Product Formula Cost'},
    { id: 7, value: 'Total' },
    { id: 8, value: 'Location' },
    { id: 9, value: '' }
  ];
  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue)
    // setRefresh(true)
  }
  const HandleClickmenu = () => {
    setAddClient(true)
  }
    
  const fetchTaxCategory = async () => {
    try{
        const response = await axios.post(`${REST_API_END_POINT}product/get-tax-category`)
        if(response.data.status === 1){
            setTaxCategories(response.data.taxResult);
        }else{
          setTaxCategories([])
        }
    }catch(error){
        console.log("Error - ",error);
        setTaxCategories([])
    }
  }

  //add clients
  const fetchAllRequestClients = async () => {
    
    try {
      const res = await axios.post(`${REST_API}webservice/request/fetch-all-clients-request`, { requestId: id })
      
     await setAllClients(res.data.data)
    } catch (error) {
      console.log(error, 'Error in fetching all clients')
    }
  }
 
  useEffect(() => {
    fetchAllRequestClients()
  }, [addClient])

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    //  avatarUrl: Yup.mixed().required('Image is required'),
    // skills:userType!=3 && Yup.array().min(1, 'Skill is required'),
    // workflow:userType!=3 && Yup.array().min(1, 'Workflow is required'),
    // start: Yup.mixed().required('Start Date is required'),
    // end: Yup.date()
    //   .nullable()
    //   .transform((value, originalValue) => {
    //     return originalValue ? new Date(originalValue * 1000) : null;
    //   })
    //   .when('start', (start, schema) => {
    //     return start
    //       ? schema.min(
    //         start,
    //         'Installation Date must be greater than the Start Date'
    //       )
    //       : schema;
    //   }),
    products:userType==1 && Yup.array(), //.min(1, 'At least one product is required'), 
    // services:userType!=3 && Yup.array().min(1, 'At least one service is required'), 
    markup_type:userType!=3 && Yup.object().nullable().required('Please select the markup type'),
    // map_service:userType!=3 && Yup.object().nullable().required('Please select the map service'),
    project_type: userType != 3 && Yup.object().nullable().required('Job class is required'),
    map_service: Yup.mixed().when('project_category', {
      is: (project_category) => userType !== 3 && project_category?.id === 14 && values.project_type?.name == 'TS',
      then: Yup.object().nullable().required('Please select the map service'),
      otherwise: Yup.mixed().nullable(),
    }),
    // project_type:Yup.object().nullable().required('Job Type is required'),
    // project_category:Yup.object().nullable().required('Job Category is required'),
    // community:Yup.object().nullable().required('Project is required'),
  });
  //
  const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    //   marginBottom: theme.spacing(1)
  }));
  const LabelStyleHead = styled(Typography)(({ theme }) => ({
    ...theme.typography.h5,
    color: theme.palette.text.secondary,
  }))
  //delete official request 
  const handleDeleteRFQ = async () => {
    try {
      const data = { request_id: id ,request:false,userId:userId}
      const res = await axios.post(`${REST_API}webservice/delete-request/id`, data)
      
      if (res.data.data == true) {
        enqueueSnackbar('Delete success', { variant: 'success' });
        // navigate(PATH_DASHBOARD.quotation.root)
        if(popupOn){
        closeView()
        setRenderCards(!renderCards)
        }else{
          navigate(`/dashboard/quick-bid`)
        }
      }
    } catch (error) {
      console.log(error, 'Error in deleting the request')
    }

  }
  //fetch request details
  const fetchRequestDetails = async () => {
    try {
      setLoading(true)
      const res = await axios.post(`${REST_API}webservice/view-request`, { request_id: id,userId, userType });
      res.data;
      setTeamMember(res.data.team)
      setAvatarUrl(res.data.attachments)
      setDetails(res.data?.data[0])
      setRfps(res.data.allProposals)
      setMarkupType(res.data?.data[0]?.markup_type)
      setDesigns(res.data?.attachments)
      setCustomerData(res.data.customerData)
      setSkill(res.data.skill)
      setWorkFlow(res.data.workflows)
      setAllproducts(res.data.products)
      setClientApprove(res.data?.data[0]?.client_approve);
      // setAllMaterials(res.data.materials)  15-01-25
         //new
         const allMatData = Array.isArray(res.data.materials) 
         ? res.data.materials.map((material) => ({
             ...material,
             markup: Number(material.retail_price || 0), 
           }))
         : [];

        //  setAllMaterials(allMatData) 
        setAllMaterials(allMatData?.filter(item=>item.isAdditionalProduct == 0))

      setAcceptedClients(res.data.requestAcceptedPartners)
      setUpdates(res.data.updates)
      setPayments(res.data.payments)
      setCustomTemplate(res.data.fieldTemplates)
      setQuestionnaireTemplate(res.data.QuestionTemplates)
      setSignAgreements(res.data.signAgrementData)
      setDataEmpty(res.data.allDataEmpty)
      setPortfolio(res.data.portfolio)
      setRefresh(false)
      setProposalData(res.data.requestProposals)
      setLabourCostServices(res.data.labourCostServices)
      setFilteredServices(res.data.labourCostServices)
      setRequestServices(res.data.requestServices.filter(row => row.rfp === 0))
      setRfpServicesEdit(res.data.requestServices.filter(row => row.rfp === 1))
      // if(res.data?.data[0]?.is_location===1){
      //   setAllLocationSame(true)
      // }
      if(edit){
        setOpenEdit(true)
        
      }
      // setTimeout(()=>{
      //   setLoading(false)
      // },2000)
     
    } catch (error) {
      console.error('Error fetching request details:', error);
    }finally{
      setLoading(false)
    }
  };

  const fetchAllMapServices = async () => {
    setMapServicesLoading(true);
    try {
      const res = await axios.post(`${REST_API}webservice/route/fetch-routes-with-locations`,{ requestId : id })
      let data = res.data?.routeData
      if(data){
        const newData = data?.filter((i) => i.locations != null)
        if(newData){
          setMapServices(newData);
        }else{
          setMapServices([]);
        }
      }else{
        setMapServices([]);
      }
    } catch (error) {
      setMapServices([]);
      console.log(error, 'Error in fetching map services');
    }finally {
      setMapServicesLoading(false)
    }
  }

  const fetchAllClients = async () => {
    try {
      const res = await axios.post(`${REST_API}webservice/clients/getAllClients`, { userType, userId: user.user_id,order:true })
      let data = res.data?.customer
      await setAllClientsEdit(data)
    } catch (error) {
      console.log(error, 'Error in fetching all clients', error)
    }
  }

  const fetchAllCommunityByClient = async (clientId) => {
    setIsCommunityLoading(true);
    try {
      clientId = clientId?.id
      const res = await axios.post(`${REST_API}webservice/rfq/get-community-by-client`, { clientId })
      let data = res.data?.community
      await setCommunities(data)
    } catch (error) {
      console.log(error, 'Error in fetching all clients');
      setCommunities([]);
    } finally {
      setIsCommunityLoading(false)
    }
  }

  useEffect(() => {
    if(id){
      fetchRequestDetails()
    }
    fetchTaxCategory()
    fetchAllMapServices()
    fetchAllClients()
    fetchAllCommunityByClient()
  }, [id,refresh])

  useEffect(() => {
    try {
      axios.post(`${REST_API_END_POINT}project/get-data-for-add-project`,{
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if(response.data.status === 1){
          setProjectTypes(response.data.projectTypes)
          const category = response.data.projectCategories;
          const filteredCategory = category?.filter(item=>item.id == 14)
          setProjectCategories(filteredCategory)
          // setProjectCategories(response.data.projectCategories)
          // setCommunities(response.data.community)
          
        }
      }).catch((error) => {
        console.error(error);
      })
      }
      catch (error) {
        console.error("Error fetching data:", error);
      }
  },[])
  //rfq product detais
  const fetchOrderProductDetails = async () => {
    try {
      let orderId = details?.id;
      const res = await axios.post(`${REST_API}webservice/get-customer-order-product/${orderId}`);
      setInvoice(res.data.data);
      setCommunities(res.data.community)

      
      const rfqServicesData = res?.data?.data?.cust_product?.flatMap(product => {
        console.log('product1234', product)
        return product.services.map(service => ({
          ...service,
          productIndex: product.product_index,
          product_id: product.product_id,
          qtyData: product.quantity,
          locationQuantity: product.locationQuantity || 1,
          productName :product?.product_name
  
        }));
      });
      let labourInstallData =rfqServicesData?.filter(item =>item.labour_cost_service_id == 10)
      setLabourInstallService(labourInstallData)

      return res.data;
    } catch (error) {
      console.error('Error fetching request details:', error);
      throw error;
    }
  };
  const handleSendEmail = async () => {
    try {
      let orderId = details?.id;
       const res = await axios.post(`${REST_API}webservice/settings/send-mail`,{ id: orderId });
      
       if(res.data.status===1){
        enqueueSnackbar('Email send successfully', { variant: 'success' });
        setOpenInvoice(false)
       }
      return res.data;
    } catch (error) {
      console.error('Error fetching request details:', error);
      throw error;
    }
  };
  const fetchRequestServicesDetails = async () => {
    try {
      let orderId = details?.id;
      const res = await axios.post(`${REST_API}webservice/get-request-services/${orderId}`);
      setInvoice(res.data.data);
      return res.data.data;
    } catch (error) {
      console.error('Error fetching request details:', error);
      throw error;
    }
  };

  const assignProjectAgain = (rfqCommunity, shouldCallAssignProject, shouldCallAssignProjectRFP) => {
    console.log('New Value:', rfqCommunity);
    const { address, city, state, zip } = rfqCommunity;
    if (shouldCallAssignProject) {
      setAdditionalSelectedServices((prevServices) =>
        prevServices.map((service) => {
          const updatedAddresses = service.addresses?.map((loc) => ({
            ...loc,
            address: address ?? loc.address,
            city: city ?? loc.city,
            state: state ?? loc.state,
            zip: zip ?? loc.zip,
          })) ?? []; // Ensures addresses is never undefined
  
          return { ...service, addresses: updatedAddresses };
        })
      );
    }
    if (shouldCallAssignProjectRFP) {
      setCartService((prevServices) =>
        prevServices.map((service) => {
          const updatedAddresses = service.addresses?.map((loc) => ({
            ...loc,
            address: address ?? loc.address,
            city: city ?? loc.city,
            state: state ?? loc.state,
            zip: zip ?? loc.zip,
          })) ?? []; 
  
          return { ...service, addresses: updatedAddresses };
        })
      );
    }
  };

  const addingInitialdataRFQ = async () => {
    // setLoading(true)
    try {
      if (openEdit) {
        let servicesArray = details?.services?.split(',')?.map(Number);
        let workflowSet = details?.workflows?.split(',')?.map(Number);
        const newInvoiceData = await fetchOrderProductDetails(); 
        const invoiceData = newInvoiceData?.data
        const communityData = newInvoiceData?.community
        const rfqTaxCategory = taxCategories?.find(cat => invoiceData.tax_category === cat.id) || null
        setSelectedTaxCategory(rfqTaxCategory)
        // let rfqSkill = skills?.filter((row) => row.id == details?.services);
        // let rfqSkill = skills.filter(skill => servicesArray.includes(skill.id));
        // let rfqWorkFlow = workflow?.filter(skill => workflowSet?.includes(skill.id));
        let rfqProjectType = projectTypes?.find(type=>details.project_type === type.id)    
        let rfqCustomerData = allClientsEdit?.find(cat => cat?.id === customerData?.customer_id)
        let rfqProjectcategory = projectCategories?.find(cat=>details.project_category === cat.id)   
        let rfqMarkupType = markupTypes?.find(cat=>details?.markup_type === cat.id)   
        let rfqMapServices = mapServices?.find(cat=>details?.map_service === cat.id)   
        let rfqCommunity = communityData?.find(com=>details.community === com.id)   
        
        // rfqProduct = invoiceData?.cust_product.filter((row) => {
        //   return allProducts.filter(data => data.id == row.product_id)
        // })
      let rfqProduct = invoiceData?.cust_product?.filter((row) => row.rfp === 0 && row.material===0)?.map((row) => {
        let match = allProducts.find(data => data.id == row.product_id);
        if (match) {
            // If a match is found, return a new object with data from allProducts and quantity from invoiceData?.cust_product
            return {
                ...match,
                indexId: row.product_index,
                product_total: row.product_total || 0,
                product_notes: row.product_notes,
                discount: row.discount,
                adjustment: row.adjustment,
                quantity: row.quantity,
                location:row.location,
                allLocationSame:row.allLocationSame,
                locationQuantity:row.locationQuantity,
                markAdjType:row.markAdjType,
                fromMapServices : row.map_service == 1 ? true : false
            };
        }  return undefined;
      }).filter(item => item !== undefined);
      console.log("####################RFQ",rfqProduct);
      console.log("invoiceData----",invoiceData);
      const highestIndexId = rfqProduct?.reduce((max, product) => {
        return typeof product.indexId === 'number' && product.indexId > max ? product.indexId : max;
      }, 0);

      setIndexCounter(highestIndexId);
      const filteredData = rfqProduct?.filter(item=>item?.fromMapServices == true)
      setSelectedMapserviceProducts(filteredData || [])
      setSelectedProducts(rfqProduct || [])
      // const serviceIdArray = details?.labour_cost_services?.split(',')?.map(Number);
      // const rfqServices = invoiceData?.cust_product?.map((row)=>
      //   labourCostServices?.find(data => data.id == row.id)
      // )
    //   const rfqServices = invoiceData?.cust_product?.map(product => {
    //     const productServices = product.services?.map(service => {
    //         const serviceData = labourCostServices.find(data => data.id === service.id);
    //         return serviceData;
    //     });
    
    //     // Return an object containing product details along with its services
    //     return productServices;
    // });
    let rfpProduct = invoiceData?.cust_product?.filter((row) => row.rfp === 1 && row.material===0)
    // let materials = invoiceData?.cust_product.filter((row) => row.material === 1 &&  row.rfp === 0 )

    let materials = invoiceData?.cust_product
        ?.filter(row => row.material === 1 && row.rfp === 0)
        ?.map((row, index) => ({
          ...row,
          newIndexId: index + 1,
        }));

    console.log('rfpProduct---', rfpProduct)

    let rfpProductUpdated = rfpProduct?.map(item => ({
      ...item,
      allRfpProductsLocationSame:item.allLocationSame == 1 ? true : false,
    }))
    console.log('rfpProduct---rfpProductUpdated', rfpProductUpdated)

    setSelectedMaterials(materials)
        setCart(rfpProductUpdated)
        const rfpServices = rfpServicesEdit?.filter(item => item?.recipe_id === null && item?.rfp === 1)?.map(serviceItem => ({
          ...serviceItem,
          location: [
            {
              address: serviceItem.address,
              city: serviceItem.city,
              state: serviceItem.state,
              zip: serviceItem.zip,
            }
          ],
        }));
        setCartService(rfpServices)
      const rfqServicesData = invoiceData?.cust_product?.flatMap(product => {
        console.log('product--',product)
        return product.services.map(service => ({
            ...service,
            productIndex: product.product_index,
            product_id: product.product_id,
            qtyData:product.quantity,
            fromMapServices : product.map_service == 1 ? true : false,
            locationQuantity:product.locationQuantity||1
        }));
      });
      console.log("##################rfqServiceData",rfqServicesData);
       await setSelectedServices(rfqServicesData||[])

       const filteredServices = rfqServicesData.length === 0
       ? labourCostServices
       : labourCostServices.filter((service) => !rfqServicesData.find((selectedService) => selectedService.labour_cost_service_id=== service.id));
         setFilteredServices(filteredServices);

      const additionalServices = requestServices?.filter(item => item?.recipe_id === null);
      const additionalServicesData = additionalServices || [];

      const updatedServicesData = additionalServicesData?.map((serviceItem, index) => {

        return {
          ...serviceItem,
          newIndexId:index + 1,
          productId : serviceItem.labour_cost_service_id,
          addresses: [
            {
              address: serviceItem.address,
              city: serviceItem.city,
              state: serviceItem.state,
              zip: serviceItem.zip,
            }
          ]
        };
      });

      setAdditionalSelectedServices(updatedServicesData);

      
      const shouldCallAssignProject = (updatedServicesData ?? []).some((service) =>
        (service.addresses ?? []).some((loc) =>
          loc.address == null || loc.city == null || loc.state == null || loc.zip == null
        )
      );
      
      const shouldCallAssignProjectRFP = (rfpServices ?? []).some((service) =>
        (service.addresses ?? []).some((loc) =>
          loc.address == null || loc.city == null || loc.state == null || loc.zip == null
        )
      );
      
      if(rfqCommunity && (shouldCallAssignProject || shouldCallAssignProjectRFP)){
        assignProjectAgain(rfqCommunity, shouldCallAssignProject, shouldCallAssignProjectRFP);
      }

      console.log("details++++",details)

        setCurrentRFQdata({
          name: details?.name,
          description: details?.description,
          notes:details?.notes,
        
          // skills: rfqSkill,
          // workflow: rfqWorkFlow,
          project_category:rfqProjectcategory,
          markup_type:rfqMarkupType,
          client:rfqCustomerData,
          community:rfqCommunity,
          project_type:rfqProjectType,
          map_service: rfqMapServices,
          start: details?.submission_deadline,
          end: details?.participation_ends,
          labour_cost:details?.labour_cost,
          budget:invoiceData?.budget || 0,
          products: rfqProduct || null,
          services: additionalServicesData || null,
          invoice_discount: invoiceData?.extra_discount,
          welcome_message: details?.welcome_message==1?true:false || null,
          total: invoiceData?.total,
          taxAmount: invoiceData?.taxAmount || 0,
          tax: invoiceData?.tax,
          draft: details?.status==1?true:false  || null,
          sub_total: invoiceData?.sub_total,
          order_id:invoiceData?.id,
          subject:details?.subject||null,
          email_content:details?.email_content||null,
          estimate_id:details?.estimate_id,
          customer_name:details?.customer_name
          // avatarUrl:details?.thumbnail||null
        });
      }
    } catch (error) {
      console.error('Error adding initial data for Sales Order:', error);
    }finally{
      // setTimeout(() => {
      //   setLoading(false)
      // }, 3000);
    }
  };

  const handleAllRfpServiceLocationSameChange = () => {
    
    if (!allRfpServiceLocationSame) {
      // Ensure there's at least one service and its location array is valid
      const commonAddress = cartService[0]?.location?.[0] || {
        address: '',
        city: '',
        state: '',
        zip: '',
      };
  
      console.log("Common Address being applied:", commonAddress);
  
      // Update all locations to use the commonAddress
      setCartService(prevProducts =>
        prevProducts.map(product => ({
          ...product,
          location: product.location ? product.location.map(() => ({ ...commonAddress })) : [{ ...commonAddress }],
        }))
      );
    }
  
    setAllRfpServiceLocationSame(!allRfpServiceLocationSame);
  };

  const handleAllRfpProductsLocationSameChange = (productIndex) => {
    setCart(prevProducts =>
      prevProducts.map((product, index) => {
        if (index === productIndex) {
          const isAllSame = !product.allRfpProductsLocationSame;
  
          // Ensure there's at least one valid address and it matches `location_quantity`
          const locationQuantity = product.location_quantity || 1;
          const commonAddress = product?.location?.[0] || {
            address: '',
            city: '',
            state: '',
            zip: '',
          };
  
          console.log("Common Address being applied:", commonAddress);
  
          return {
            ...product,
            allRfpProductsLocationSame: isAllSame,
            location: isAllSame 
              ? Array.from({ length: locationQuantity }, () => ({ ...commonAddress }))
              : Array.from({ length: locationQuantity }, () => ({ ...commonAddress })),
              // : Array.from({ length: locationQuantity }, () => ({ address: '', city: '', state: '', zip: '' })),
          };
        }
        return product;
      })
    );
  };

  useEffect(() => {
    if(details){
      fetchOrderProductDetails();
      if(details?.is_rfp_service_location == 1){
        setAllRfpServiceLocationSame(true)
      }else{
        setAllRfpServiceLocationSame(false)
      }
      if(details?.is_rfp_product_location == 1){
        setAllRfpProductsLocationSame(true)
      }else{
        setAllRfpProductsLocationSame(false)
      }
    }
  }, [details]);

  useEffect(() => {
    setLoading(true)
    addingInitialdataRFQ();
    setTimeout(()=>{
      setLoading(false)
    },3000)
  }, [openEdit]);

  // The below useEffect is used for fetching the invoice details only
  useEffect(() => {
    if(details){
    fetchOrderProductDetails();
    }
    console.log('requestServices--',requestServices);
    const rfqServicesData = invoice?.cust_product?.flatMap(product => {
      console.log('product--', product)
      return product.services.map(service => ({
        ...service,
        productIndex: product.product_index,
        product_id: product.product_id,
        qtyData: product.quantity,
        locationQuantity: product.locationQuantity || 1

      }));
    });
    console.log("##################rfqInvoiceServiceData", rfqServicesData);
    const newrfqServicesData = rfqServicesData?.filter(item => {
      const newQty = parseFloat((item.prQty * item.qtyData * item.locationQuantity)?.toFixed(2));
      console.log('rfqInvoiceServiceDatanewQty-----------', newQty);
      console.log('rfqInvoiceServiceDataitem.qty-----------', item.qty);
      return newQty !== item.qty;
    }).map(item => {
      const newQty = item.prQty * item.qtyData * item.locationQuantity;
      const quantityDifference = item.qty - newQty;
      return {
        ...item,
        quantity: quantityDifference,
        quantityDifference: quantityDifference,
        total_cost: item.unit_cost * quantityDifference
      };
    });

    console.log('newrfqServicesData-------', newrfqServicesData);

    setInvoiceSelectedServices(newrfqServicesData || [])
    const additionalServices = requestServices?.filter(item => item?.recipe_id === null);
    const additionalServicesData = additionalServices || [];
    setAdditionalSelectedServices(additionalServicesData);
    const rfpServices = rfpServicesEdit?.filter(item => item?.recipe_id === null && item?.rfp === 1);
    setCartService(rfpServices)
  }, [openInvoice]);


  
  const invoiceDate = moment.unix(details?.timestamp).format('MM/DD/YYYY')

  //edit rfq
  const handleEditClick = () => {
    setOpenEdit(!openEdit)
    
  }
  const handleDeleteMenu=()=>{
    setOpenDelete(!openDelete)
  }
  const handleReopen=()=>{
    setReopen(!reOpen)
  }
  const handleConvertToRFQ=()=>{
    setConvertRfq(!convertRfq)
  }

  // const handleProduct = (value) => {
  //   console.log("#################@@@@@@@@@@Value", value);
  
  //   // Extract community address from Formik's values
  //   const { address: projAddress = '', city: projCity = '', state: projState = '', zip: projZip = '' } = values?.community || {};
  //   const communityAddress = { address: projAddress, city: projCity, state: projState, zip: projZip };
  
  //   setSelectedProducts((prevProducts) => {
  //     const commonAddresses = allLocationSame && prevProducts.length > 0
  //       ? { ...prevProducts[0].location[0] }
  //       : null;
  
  //     const updatedProducts = value.map((product) => {
  //       const dataRetailPrice = product.product_retail_price || product.retail_price;
  //       const dataWholesalePrice = product.product_price || product.price || 0;
  
  //       // Check if the product already exists
  //       const existingProduct = prevProducts.find((p) =>
  //         (p.id === product.id || p.id === product.product_id) &&
  //         p.indexId !== undefined &&
  //         p.indexId === product.indexId
  //       );
  //       console.log("###############ExistingProduct", existingProduct);
  
  //       if (existingProduct) {
  //         // Update existing product's quantity and location
  //         const quantity = existingProduct.quantity;
  //         const locationQuantity = existingProduct.locationQuantity;
  //         const marketingAdjustment = Number(existingProduct?.adjustment);
  
  //         let location = { ...existingProduct.location[0] };
  
  //         // Use common address or project address if applicable
  //         if (allLocationSame && commonAddresses) {
  //           location = { ...commonAddresses };
  //         } else if (projAddress && projCity && projState && projZip) {
  //           location = { ...communityAddress };
  //         }
  
  //         const rowTotal = calculateTotal(
  //           markupType === 1 ? dataWholesalePrice : markupType === 2 ? dataRetailPrice : 0,
  //           product.dataQuantity,
  //           product.dataDiscount,
  //           quantity,
  //           locationQuantity,
  //           marketingAdjustment
  //         );
  
  //         return {
  //           ...existingProduct,
  //           quantity,
  //           rowTotal,
  //           location: [location],
  //         };
  //       } else {
  //         // Add a new product with default location
  //         let newAddress = {
  //           id: 0,
  //           address: '',
  //           city: '',
  //           state: '',
  //           zip: '',
  //         };
  
  //         // Assign location dynamically
  //         if (allLocationSame && commonAddresses) {
  //           newAddress = { ...commonAddresses };
  //         } else if (projAddress && projCity && projState && projZip) {
  //           newAddress = { ...communityAddress };
  //         }
  
  //         const newIndexCounter = indexCounter + 1;
  //         setIndexCounter(newIndexCounter);
  
  //         return {
  //           ...product,
  //           indexId: newIndexCounter,
  //           quantity: 1,
  //           locationQuantity: 1,
  //           discount: 0,
  //           adjustment: 0,
  //           markAdjType: 1,
  //           rowTotal: calculateTotal(
  //             markupType === 1 ? dataWholesalePrice : markupType === 2 ? dataRetailPrice : 0,
  //             product.dataQuantity,
  //             product.dataDiscount,
  //             1,
  //             1,
  //             0
  //           ),
  //           location: [newAddress],
  //         };
  //       }
  //     });

  //     // Merge products from selectedMapserviceProducts with fromMapServices = true into the value
  //     const allSelectedProducts = [
  //       ...updatedProducts,
  //       ...selectedMapserviceProducts?.filter(product => product?.fromMapServices == true)
  //     ];
  
  //     const productServices = allSelectedProducts?.reduce((services, product) => {
  //       if (product.services && product.services.length > 0) {
  //         return services.concat(
  //           product.services.map((service) => ({
  //             ...service,
  //             fromMapServices: product?.fromMapServices,
  //             productIndex: product.indexId,
  //           }))
  //         );
  //       }
  //       return services;
  //     }, []);
  
  //     setSelectedServices((prevServices) => {
  //       const filteredProductServices = productServices.filter((service) => {
  //         return !prevServices.some((existingService) => (
  //           existingService?.labour_cost_service_id === service?.id &&
  //           existingService?.recipe_id === service?.recipe_id &&
  //           existingService?.product_id === service?.product_id &&
  //           existingService?.productIndex === service?.productIndex
  //         ));
  //       });
  
  //       let updatedServices = prevServices.map((service) => ({
  //         ...service,
  //         quantity: service.quantity || 1,
  //         locationQuantity: service.locationQuantity || 1,
  //       }));
  
  //       updatedServices = updatedServices.filter((existingService) =>
  //         productServices.some((service) => (
  //           (existingService?.labour_cost_service_id === service?.id ||
  //             existingService?.id === service?.id) &&
  //           existingService?.recipe_id === service?.recipe_id &&
  //           existingService?.product_id === service?.product_id
  //         ))
  //       );
  
  //       const filteredProductServicesUnique = filteredProductServices.filter((service) => {
  //         return !updatedServices.some((existingService) => (
  //           (existingService?.labour_cost_service_id === service?.id ||
  //             existingService?.id === service?.id) &&
  //           existingService?.recipe_id === service?.recipe_id &&
  //           existingService?.product_id === service?.product_id &&
  //           existingService?.productIndex === service?.productIndex
  //         ));
  //       });
  
  //       return updatedServices.concat(filteredProductServicesUnique);
  //     });
  
  //     const filteredServices =
  //       productServices.length === 0
  //         ? labourCostServices
  //         : labourCostServices.filter(
  //             (service) => !productServices.find((selectedService) => selectedService.id === service.id)
  //           );
  //     setFilteredServices(filteredServices);
  
  //     let initialServicesCost = 0;
  //     if (updatedProducts.length > 0) {
  //       initialServicesCost = updatedProducts.reduce((total, data) => {
  //         return total + (data.quantity * parseFloat(data.expected_total_service_cost?.toFixed(2)));
  //       }, 0);
  //     }
  
  //     setInitialServiceCostInProductFormula(initialServicesCost);
  //     return allSelectedProducts;
  //   });
  // };
 
  const handleProduct = (value) => {
    // Extract community address from Formik's values
    const { address: projAddress = '', city: projCity = '', state: projState = '', zip: projZip = '' } = values?.community || {};
    const communityAddress = { address: projAddress, city: projCity, state: projState, zip: projZip };
  
    setSelectedProducts((prevProducts) => {
  
      // Determine common address if allLocationSame is enabled
      // const commonAddress = allLocationSame && prevProducts.length > 0
      //   ? { ...prevProducts[0].location[0] }
      //   : null;
      const commonAddress = null;
      
      const updatedProducts = value.map((product) => {
        const dataRetailPrice = product.product_retail_price || product.retail_price;
        const dataWholesalePrice = product.product_price || product.price || 0;
  
        // Check if the product already exists in the selected products
        const existingProduct = prevProducts.find((p) =>
          (p.id === product.id || p.id === product.product_id) &&
          p.indexId !== undefined &&
          p.indexId === product.indexId
        );
  
        console.log("###############ExistingProduct", existingProduct);
  
        if (existingProduct) {
          // Update existing product's quantity and location
          const quantity = existingProduct.quantity;
          const locationQuantity = existingProduct.locationQuantity;
          const marketingAdjustment = Number(existingProduct?.adjustment);
          let location = [...existingProduct.location];
          // Preserve existing address if it exists, otherwise assign dynamically

          if (allLocationSame && commonAddress) {
            // Overwrite all location with the "common" location (from the first product)
            location = [...commonAddress];
          } else {
              // Ensure at least one address object
              for (let i = location.length; i < 1; i++) {
                location.push({
                  id: i,
                  address: '',
                  city: '',
                  state: '',
                  zip: '',
              });
              }
          }
  
          const rowTotal = calculateTotal(
            markupType === 1 ? dataWholesalePrice : markupType === 2 ? dataRetailPrice : 0,
            product.dataQuantity,
            product.dataDiscount,
            quantity,
            locationQuantity,
            marketingAdjustment
          );
  
          return {
            ...existingProduct,
            quantity,
            rowTotal,
            location: location, // Ensure location is always an array
          };
        } else {
          // Add new product with default quantity and location
          const location = commonAddress || {
            id: 0,
            address: communityAddress.address || '',
            city: communityAddress.city || '',
            state: communityAddress.state || '',
            zip: communityAddress.zip || '',
          };
  
          const newIndexCounter = indexCounter + 1;
          setIndexCounter(newIndexCounter);
  
          return {
            ...product,
            indexId: newIndexCounter,
            quantity: 1,
            locationQuantity: 1,
            discount: 0,
            allLocationSame: true,
            adjustment: 0,
            markAdjType: 1,
            rowTotal: calculateTotal(
              markupType === 1 ? dataWholesalePrice : markupType === 2 ? dataRetailPrice : 0,
              product.dataQuantity,
              product.dataDiscount,
              1,
              1,
              0
            ),
            location: [location],
          };
        }
      });
      // Merge products from selectedMapserviceProducts with fromMapServices = true into the value
      const allSelectedProducts = [
        ...updatedProducts,
        ...selectedProducts?.filter((item)=>item.isAdditionalProduct == true),
        ...selectedMapserviceProducts?.filter(product => product?.fromMapServices == true)
      ];
      const productServices = allSelectedProducts?.reduce((services, product) => {
        console.log("updatedProducts--product---------", product);
        if (product.services && product.services.length > 0) {
          return services.concat(
            product.services.map((service) => ({
              ...service,
              fromMapServices: product?.fromMapServices,
              productIndex: product.indexId,
              locationQuantity: 1,
            }))
          );
        }
        return services;
      }, []);
  
      setSelectedServices((prevServices) => {
        const filteredProductServices = productServices.filter((service) => {
          console.log("productServices-----service--", service);
          return !prevServices.some(
            (existingService) =>
              existingService?.labour_cost_service_id === service?.id &&
              existingService?.recipe_id === service?.recipe_id &&
              existingService?.product_id === service?.product_id &&
              existingService?.productIndex === service?.productIndex
          );
        });
  
        let updatedServices = prevServices.map((service) => ({
          ...service,
          quantity: service.quantity || 1,
          locationQuantity: service.locationQuantity || 1,
        }));
  
        updatedServices = updatedServices.filter((existingService) =>
          productServices.some(
            (service) =>
              (existingService?.labour_cost_service_id === service?.id ||
                existingService?.id === service?.id) &&
              existingService?.recipe_id === service?.recipe_id &&
              existingService?.product_id === service?.product_id &&
              existingService?.productIndex === service?.productIndex
          )
        );
  
        const filteredProductServicesUnique = filteredProductServices.filter((service) => {
          console.log("filteredProductServicesUnique--service---", service);
          return !updatedServices.some(
            (existingService) =>
              (existingService?.labour_cost_service_id === service?.id ||
                existingService?.id === service?.id) &&
              existingService?.recipe_id === service?.recipe_id &&
              existingService?.product_id === service?.product_id &&
              existingService?.productIndex === service?.productIndex
          );
        });
  
        return updatedServices.concat(filteredProductServicesUnique);
      });
  
      const filteredServices =
        productServices.length === 0
          ? labourCostServices
          : labourCostServices.filter(
              (service) =>
                !productServices.find(
                  (selectedService) => selectedService.id === service.id
                )
            );
      setFilteredServices(filteredServices);
  
      let initialServicesCost = 0;
      if (updatedProducts.length > 0) {
        initialServicesCost = updatedProducts.reduce((total, data) => {
          return (
            total +
            data.quantity *
              parseFloat(data.expected_total_service_cost?.toFixed(2))
          );
        }, 0);
      }
  
      setInitialServiceCostInProductFormula(initialServicesCost);
      return allSelectedProducts;
    });
  };
  console.log("###############ExistingProduct111",selectedProducts);
console.log('selectedServices-------',selectedServices);

// const handleAllLocationSameChange = () => {
 
//   console.log("##################Alllll",allLocationSame);
//   if (!allLocationSame) {
//     const commonAddress = selectedProducts[0]?.location[0] || {
//       address: '',
//       city: '',
//       state: '',
//       zip: '',
//     };
//     console.log("##################Alllll",commonAddress);
//     setSelectedProducts(prevProducts =>
//       prevProducts.map(product => ({
//         ...product,
//         location: product.location.map(() => commonAddress),
//       }))
//     );
//   }
//   setAllLocationSame(!allLocationSame);
// };

  const handleAllLocationSameChange = (productIndex) => {
    setSelectedProducts(prevProducts =>
      prevProducts.map((product, index) => {
        if (index === productIndex) {
          console.log('productIndex--1---', index)
          console.log('productIndex--2---', productIndex)

          // Toggle allLocationSame flag
          const isAllSame = !product?.allLocationSame;
          // Get the first address of the selected product
          const commonAddress = product.location[0] || {
            address: '',
            city: '',
            state: '',
            zip: '',
          };

          return {
            ...product,
            allLocationSame: isAllSame,
            location: product.location.map(() => commonAddress),
          };
        }
        return product;
      })
    );
  };
  const handleDeleteProduct = (productId, indexId) => {
    const fileredProduct = selectedProducts.filter(data => !((data.id || data.product_id) === productId && data.indexId === indexId))
    setFieldValue('products', fileredProduct);
    setSelectedProducts(fileredProduct)
    const quantityFieldName = `quantity_${productId}`;
    const discountFieldName = `discount_${productId}`;
    setFieldValue(quantityFieldName, 1);
    setFieldValue(discountFieldName, 1);
    console.log('fileredProduct-------',fileredProduct);
    console.log('selectedServices-------',selectedServices);
    console.log("###############InitialserviceCost",initialServiceCostInProductFormula);
    const filteredServices = selectedServices?.filter(service => {
      // Check if there is any product in fileredProduct array which contains the service
      return fileredProduct?.some(product => (
          product?.recipe_id === service?.recipe_id &&
          product?.id === service?.product_id &&
          product?.indexId === service?.productIndex
      ));
  });
 

  setSelectedServices(filteredServices);
  
    setSelectedServices(filteredServices);
    let initialServicesCost=0
          if(fileredProduct.length>0){
            initialServicesCost = fileredProduct?.reduce((total, data, index) => {
              return total + ((data?.quantity)*(parseFloat(data?.expected_total_service_cost?.toFixed(2))));
            }, 0);
          }
    console.log('initialServiceCost In ProductFormula-----',initialServicesCost);
    setInitialServiceCostInProductFormula(initialServicesCost)
    

  }
 

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: currentRFQData.name || '',
      description: currentRFQData.description || '',
      notes:currentRFQData.notes || '',
      clients:currentRFQData.client || null,
      // skills: currentRFQData.skills || [],
      // workflow: currentRFQData.workflow || [],
      project_type:currentRFQData.project_type || null,
      project_category:currentRFQData.project_category || null,
      community:currentRFQData.community || null,
      map_service:currentRFQData.map_service || null,
      start: currentRFQData.start || currentDate,
      end: currentRFQData.end || currentDate,
      // avatarUrl: currentRFQData.avatarUrl||'',
      products: currentRFQData.products || [],
      services: currentRFQData.services || [],
      markup_type:currentRFQData.markup_type || null,
      invoice_discount: currentRFQData.invoice_discount || '',
      welcome_message: currentRFQData.welcome_message || false,
      draft: currentRFQData.draft || false,
      total: currentRFQData.total || '',
      client_approve : clientApprove == 0 ? false : true,
      labour_cost: currentRFQData.labour_cost || 0,
      budget: currentRFQData.budget || 0,
      tax: 0,
      order_id:currentRFQData.order_id||'',
      subject:currentRFQData.subject||'',
      email_content:currentRFQData.email_content||'',
      rfp:null,
      estimate_id:currentRFQData?.estimate_id,
      customer_name:currentRFQData?.customer_name,
      editReq:true
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        setIsSubmitted(true);
        const hasEmptyProductName = selectedProducts?.some(product => (product.name || product.product_name || '').trim() === '');
        const hasEmptyServiceName = additionalSelectedServices?.some(product => product.name.trim() === '');
        const hasEmptyMaterialName = selectedMaterials?.some(product => (product.name || product.product_name || '').trim() === '');

        if (hasEmptyProductName) {
          enqueueSnackbar('Please fill in all required product names.', { variant: 'error' });
          return;
        }
        if (hasEmptyServiceName) {
          enqueueSnackbar('Please fill in all required service names.', { variant: 'error' });
          return;
        }
        if (hasEmptyMaterialName) {
          enqueueSnackbar('Please fill in all required material names.', { variant: 'error' });
          return;
        }
        const hasInvalidProductName = selectedProducts?.filter(item=>item?.isAdditionalProduct == true)?.some(product => {
          const newName = product.product_name || product.name;
          const trimmedName = newName.trim();
          if (trimmedName === '.') {
            enqueueSnackbar('Product name cannot be just a dot.', { variant: 'error' });
            return true;
          }
          if (trimmedName === '-') {
            enqueueSnackbar('Product name cannot be just a hyphen.', { variant: 'error' });
            return true;
          }
          // if (!/^[a-zA-Z0-9\s-]+$/.test(trimmedName)) {
          //   enqueueSnackbar('Product name can only contain letters, numbers, and hyphens.', { variant: 'error' });
          //   return true;
          // }
          if (/[:]/.test(trimmedName)) {
            enqueueSnackbar('Product name cannot contain colon (:).', { variant: 'error' });
            return true;
          }
          if (trimmedName[0] === '.') {
            enqueueSnackbar('Product name cannot start with a dot.', { variant: 'error' });
            return true;
          }
          if (trimmedName[0] === '-') {
            enqueueSnackbar('Product name cannot start with a hyphen.', { variant: 'error' });
            return true;
          }
          if (trimmedName === '') {
            enqueueSnackbar('Product name cannot be empty or just spaces.', { variant: 'error' });
            return true;
          }
          return false;
        });
        
        const hasInvalidServiceName = additionalSelectedServices?.filter(item=>item?.isAdditionalProduct == true)?.some(product => {
          const trimmedName = product.name.trim();
          if (trimmedName === '.') {
            enqueueSnackbar('Service name cannot be just a dot.', { variant: 'error' });
            return true;
          }
          if (trimmedName === '-') {
            enqueueSnackbar('Service name cannot be just a hyphen.', { variant: 'error' });
            return true;
          }
          if (/[:]/.test(trimmedName)) {
            enqueueSnackbar('Service name cannot contain colon (:).', { variant: 'error' });
            return true;
          }                
          if (trimmedName[0] === '.') {
            enqueueSnackbar('Service name cannot start with a dot.', { variant: 'error' });
            return true;
          }
          if (trimmedName[0] === '-') {
            enqueueSnackbar('Service name cannot start with a hyphen.', { variant: 'error' });
            return true;
          }
          if (trimmedName === '') {
            enqueueSnackbar('Service name cannot be empty or just spaces.', { variant: 'error' });
            return true;
          }
          return false;
        });
        
        const hasInvalidMaterialName = selectedMaterials?.filter(item=>item?.isAdditionalProduct == true)?.some(product => {
          const newName = product.product_name || product.name;
          const trimmedName = newName.trim();
          if (trimmedName === '.') {
            enqueueSnackbar('Material name cannot be just a dot.', { variant: 'error' });
            return true;
          }
          if (trimmedName === '-') {
            enqueueSnackbar('Material name cannot be just a hyphen.', { variant: 'error' });
            return true;
          }
          if (/[:]/.test(trimmedName)) {
            enqueueSnackbar('Material name cannot contain colon (:).', { variant: 'error' });
            return true;
          }                
          if (trimmedName[0] === '.') {
            enqueueSnackbar('Material name cannot start with a dot.', { variant: 'error' });
            return true;
          }
          if (trimmedName[0] === '-') {
            enqueueSnackbar('Material name cannot start with a hyphen.', { variant: 'error' });
            return true;
          }
          if (trimmedName === '') {
            enqueueSnackbar('Material name cannot be empty or just spaces.', { variant: 'error' });
            return true;
          }
          return false;
        });
        
        if (hasInvalidProductName || hasInvalidServiceName || hasInvalidMaterialName) {
          // enqueueSnackbar('Product names must contain letters or numbers.', { variant: 'error' });
          return;
        }
        const clientsArray = values.clients ? [values.clients] : [];
        values.clientsData = clientsArray
        values.userName = user.first_name;
        const request_id =id
                
        values.project_category = values.project_type?.name == 'TS' ? (values.project_category || null) : null
        values.map_service = (values.project_type?.name == 'TS' && (values.project_category && values.project_category?.id == 14)) ? values.map_service : null

        values.products = selectedProducts
        const allServices = [...selectedServices, ...additionalSelectedServices];
        const servicesIdArray = allServices?.map(service => service.id);
        values.services = servicesIdArray?.join(',')
        values.totalMarkup = totalMarkup
        values.selectedServices = allServices
        values['labour_cost'] = totalLabourCost;
           const data = { values, userId, userType ,request_id,
            allLocationSame,
            rfpProducts: cart,
            taxCategory : selectedTaxCategory?.id || null,
            allRfpServiceLocationSame : allRfpServiceLocationSame ,
            rfpServices: cartService,
            materials:selectedMaterials,
            address: selectedProducts.reduce((acc, product) => {
              return [
                ...acc,
                ...product.location.map((address) => ({
                  ...address,
                  productId: product.id,
                  productIndex: product.indexId,
                })),
              ];
            }, []),
          }
          const isvalidationNotOk = selectedProducts.find(product=>{
            if(product.location.length && product.location.some(obj =>
              [obj.address, obj.city, obj.state, obj.zip].some(value => (value === '' || value === null))
            )){
              return true
            }else{
              return false
            }
          })
          values.total=totalsum
          values.taxAmount = taxAmount
          values.budget=budget
          values.sub_total = rfqSubTotal
          console.log("######isvalidationNotOk",isvalidationNotOk);
          console.log("######totalAmount", values.total);
          console.log("######totalAmountbudget",values.budget);
          data.quotation=1
          data.customer_name= values.customer_name   //new one
          const isvalidationNotOkRfp = cart?.find(product => {
            console.log("Checking product:", product);
  
            if (product.location.length > 0 && product.location.some(obj =>
              [obj.address, obj.city, obj.state, obj.zip].some(value => (value === '' || value === null))
            )) {
              console.log("Validation failed for product:", product);
              return true;
            } else {
              console.log("Validation passed for product:", product);
              return false;
            }
          });
          if(isvalidationNotOk || isvalidationNotOkRfp){
            enqueueSnackbar('Please fill all the address fields', { variant: 'error' });
          }else{
           await axios.post(`${REST_API}webservice/edit-request`, data)
             .then((res) => {
              
          setOpenEdit(false)
        
          setIsOpen(false)
          setCurrentRFQdata({}) // added on 3-01-2024
           resetForm();
            setSelectedProducts([])
            setSelectedMapserviceProducts([]);
            setSelectedTaxCategory(null);
            setSelectedServices([]);
            setAdditionalSelectedServices([])
            setFilteredServices([])
            fetchRequestDetails()
            // if(edit){
            //   navigate(`/dashboard/quick-bid`)
            // }
           enqueueSnackbar('Update success' , { variant: 'success' });
           if(popupOn){
           closeView()
           setRenderCards(!renderCards)
           }else{
            navigate(`/dashboard/quick-bid`)
           }
           if(edit){
            edit=false
            setOpenEdit(false)
          setIsOpen(false)
          }
         })
         .catch((errors)=>{
           console.log(errors,'error')
         })
        }
      } catch (error) {
        console.error(error);

      }
    }
  });

  
  const handleEstimateCount = async () => {
    try {
      const response = await axios.post(`${REST_API}webservice/estimate/reduce-tabs-count`, {
        userId,
        estimate_id:id,
      });
      
    } catch (error) {
      console.error("Error reducing estimate count:", error);
    }
  };

  useEffect(() => {
    handleEstimateCount()
  }, [])
  

// useEffect(()=>{
//   // let currentUnix = moment(currentDate).unix()
//   //  let requestSubmitDeadline = details?.submission_deadline

//    if((user?.user_id == details?.partner_user_id)&& (details?.accept_status == 0)){
//     setReqAcceptClient(true)
//    }
  
// },[details])

const submissionDeadlineUnix = details?.submission_deadline;
const submissionDeadlineMoment = moment.unix(submissionDeadlineUnix);

const current = moment();

const daysLeft = submissionDeadlineMoment.diff(current, 'days');

const handleAcceptorRejectInvoice=async(value)=>{
  try {
    if(value === 2 && reason === ''){
      enqueueSnackbar('Please fill rejected reason ',{variant:'warning'})
      return
    }
    else{

    let requestId = id
    let requestPartnerId = details?.rt_partner_id
    let acceptStatus = value
    let userId=user.user_id
  
    const data={requestId,requestPartnerId,acceptStatus,userId,reason}
    const res = await axios.post(`${REST_API}webservice/request/accept-or-reject-quotation`,data)
    
    if(res.data.status==1){
      setOpenInvoice(!openInvoice)
      setConfirmInvoice(false)
      enqueueSnackbar( 'Estimate Approved', { variant: 'success' });
      fetchRequestDetails()
      onDataChange()
    }else if(res.data.status==2){
      onDataChange()
      setOpenInvoice(!openInvoice)
      setRejectInvoice(false)
      enqueueSnackbar('Estimate Rejected',{variant:'error'})
      setReason('')
      navigate(PATH_DASHBOARD.quotation.root)
    }
    else{
      setOpenInvoice(!openInvoice)
      setConfirmInvoice(false)
    }
  }
  } catch (error) {
    console.log(error,'error in accepting Estimate')
  }
}
const calculateServiceTotal = (price, quantity, discount, value, location = 1) => {

  const parsedQuantity = parseFloat(quantity ? quantity : value) || 0;
  const parsedDiscount = parseFloat(discount) || 0;
  const locationQuantity = parseFloat(location) || 1
  const subtotal = price * parsedQuantity * locationQuantity;

  if (discount) {
    const totalDiscount = subtotal - discount;
    return totalDiscount;
  } else {
    return subtotal;
  }
};
const handleReopeningRequest=async()=>{
  try {
    let requestId = id
    let userId=user.user_id
    const  data={requestId,userId}
    const res = await axios.post(`${REST_API}webservice/quotation/reopen`,data)
    if(res.data.status ==1){
      enqueueSnackbar('Estimate reopened successfully',{variant:"success"})
      setReopen(false)
      fetchRequestDetails()
    }else if(res.data.status ==0){
      enqueueSnackbar('Estimate reopened failed',{variant:"error"})
      setReopen(false)
    }
  } catch (error) {
    console.log(error,'Error in reopen')
  }
}
const convertToRFQ=async()=>{
  try {
    let requestId = id
    let userId=user.user_id
    const  data={requestId,userId}
    const res = await axios.post(`${REST_API}webservice/quotation/convert-rfq`,data)
    if(res.data.status ==1){
      enqueueSnackbar('Estimate converted to Sales Order successfully',{variant:"success"})
      setReopen(false)
      // navigate(PATH_DASHBOARD.official.root)
      navigate(`/dashboard/official-request/view/${id}`,{state:true})
      if(popupOn){
        closeView()
      }
      // fetchQuotationDetails()
    }else if(res.data.status ==0){
      enqueueSnackbar('Estimate failed to convert',{variant:"error"})
      setReopen(false)
    }
  } catch (error) {
    console.log(error,'Error in reopen')
  }
}
// const handleAcceptOrRejectRequest=async(value)=>{
//   try {
//     console.log(value)
//     let requestId = id
//     let requestPartnerId = details?.rt_partner_id
//     let acceptStatus = value
//     let userId=user.user_id
  
//     const data={requestId,requestPartnerId,acceptStatus,userId}
//     const res = await axios.post(`${REST_API_END_POINT}request/accept-or-reject-request`,data)
    
//     if(res.data.status ==1){
//       setReqAcceptClient(!reqAcceptClient)
//       setRequestAccept(false)
//       setRequestReject(false)
//       enqueueSnackbar(value === 1 ? 'Request Accepted' : 'Request Rejected', { variant: value === 1 ? 'success' : 'error' });
//       if(value==2){
//         navigate(PATH_DASHBOARD.official.root)
//       }
//     }
//     // setReqAcceptClient(false)
//     // setRequestAccept(false)
//     // setRequestReject(false)
//   } catch (error) {
//     console.log(error,'error in reject request')
//   }
 
// }

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;
 


  const setValuesToPreview=()=>{
    setPreviewData(values)
  }

  // let  totalsum = 0
  // let DiscountTotal=0
  // let newTotalProductFormula1 = 0;
  // let newTotalWholesalePrice1 = 0;
  // let newTotalRetailPrice1 = 0;

  const calculateTotal = (price, quantity, discount,value,location=1, adjustment = 0 ,markAdjType=1 ) => {
    const parsedQuantity = parseFloat(quantity?quantity:value) || 1;
    const parsedDiscount = parseFloat(discount) || 0;
    const locationQuantity=parseFloat(location)||1
    const newPrice = parseFloat(price) || 0
    // let subtotal = price * parsedQuantity*locationQuantity;
    let SPMA = newPrice + ((newPrice * (adjustment ? Number(adjustment) : 0)) / 100)
    let subtotal = parseFloat(SPMA)?.toFixed(2) * parsedQuantity * locationQuantity;

    if(markAdjType ==2 || markAdjType ==3){
      SPMA = newPrice + (adjustment ? Number(adjustment) : 0)
      subtotal = parseFloat(SPMA)?.toFixed(2) * parsedQuantity * locationQuantity;
    }
    
    if (discount) {
      subtotal = subtotal - discount;
    }
    // if (adjustment) {
    //   subtotal = subtotal + ((subtotal * adjustment) / 100);
    // }
    return subtotal;
 };

 let sum =0
 let taxAmountData = 0 ;
 let grandTotalData = 0;
 let totalJobcostSum = 0
 useEffect(() => {
  try {
    console.log('selectedProducts-----', selectedProducts);
    // if(selectedProducts?.length > 0 || selectedServices?.length > 0 || additionalSelectedServices?.length > 0){
    let selectedProductsTotalDiscount = selectedProducts?.reduce((total, data, index) => {
      let quantityFieldName = `quantity_${data.product_id ? data.product_id : data.id}`;
      let discountFieldName = `discount_${data.product_id ? data.product_id : data.id}`;
      const dataRetailPrice = data.product_retail_price || data.retail_price
      const dataWholesalePrice = data.product_price || data.price
      // const dataQuantity = values[quantityFieldName]?values[quantityFieldName]:(data?.quantity||1)

      // const dataDiscount = values[discountFieldName]?values[discountFieldName]:(data?.discount||0);
      const dataQuantity = data?.quantity || 1

      const dataDiscount = data?.discount || 0;

      const productTotal = calculateTotal(markupType == 1 ? dataWholesalePrice : (markupType == 2 ? dataRetailPrice : 0), dataQuantity, dataDiscount, null, data?.locationQuantity, Number(data?.adjustment),data.markAdjType);
      const fixedProductTotal = parseFloat(productTotal).toFixed(2);
      return total + parseFloat(fixedProductTotal);
    }, 0);

    const selectedProductsTotalDiscountForJobCost = selectedProducts?.reduce((total, data, index) => {
      const quantityFieldName = `quantity_${data.product_id || data.id}`;
      const discountFieldName = `discount_${data.product_id || data.id}`;
      const quantityVal = values[quantityFieldName] ? values[quantityFieldName] : 1
      const discountVal = values[discountFieldName] ? values[discountFieldName] : 0
      console.log("###############quantityVal", quantityVal)
      console.log("###############quantityValdata", data)
      // const productTotal = calculateTotal(data.expected_material_cost, data.quantity, discountVal);
      const productTotal = calculateTotal(parseFloat(data.expected_material_cost), data.quantity, 0, null, data.locationQuantity, 0);
      console.log("###############product formula", productTotal)
      return total + productTotal;
    }, 0);
    const allServices = [...selectedServices, ...additionalSelectedServices];
    let selectedServicesCost = allServices?.reduce((total, service, index) => {
      // const quantityFieldName = `service_quantity_${index}`;
      // const quantityVal = values[quantityFieldName]?values[quantityFieldName]:1
      const quantityVal = service?.quantity ? Number(service?.quantity) : 0
      const serviceTotal = calculateServiceTotal(service.cost, quantityVal, 0);

      console.log("############SSSSSSSSSSSer", serviceTotal);

      // let selectedQuantity=selectedProducts.filter(product=>product.id===service.product_id)
      // console.log("############SSSSSSSSSSSer",selectedQuantity);
      // let quantity=selectedQuantity[0].quantity
      // return total + (serviceTotal*quantity);
      return total + serviceTotal;
    }, 0);
    // selectedServicesCost = selectedServicesCost - initialServiceCostInProductFormula
    console.log('allServices-----------', allServices);

    let selectedServicesTotalCost = allServices?.reduce((total, service, index) => {
      const quantityVal = service?.quantity ? Number(service?.quantity) : 0
      const costVal = service?.unit_cost ? Number(service?.unit_cost) : Number(service?.total_cost)
      const serviceTotal = calculateServiceTotal(costVal, quantityVal, 0);
      // const roundedServiceTotal = Math.round(serviceTotal * 100) / 100;
      return total + serviceTotal;
    }, 0);
    let initialServicesCost = selectedProducts?.reduce((total, data, index) => {
      const serviceTotal = (data?.quantity) * (parseFloat(data?.expected_total_service_cost?.toFixed(2))) * (data?.locationQuantity || 1) || 0;
      // const roundedServiceTotal = Math.round(serviceTotal * 100) / 100;
      return total + serviceTotal;
    }, 0);

    console.log("###################1111selectedServicesTotalCost-1", selectedServicesTotalCost);
    console.log("###################1111initialServicesCost-1", initialServicesCost);

    const roundToThreeDecimals = (num) => Math.round(num * 1000) / 1000;
    selectedServicesTotalCost = roundToThreeDecimals(selectedServicesTotalCost);
    initialServicesCost = roundToThreeDecimals(initialServicesCost);

    console.log("###################1111selectedServicesTotalCost-2", selectedServicesTotalCost);
    console.log("###################1111initialServicesCost-2", initialServicesCost);
    const roundToTwoDecimals = (num) => Math.round(num * 100) / 100;
    const selectedServicesSubTotal = roundToTwoDecimals(selectedServicesTotalCost - initialServicesCost)
    setTotalLabourCost(selectedServicesSubTotal)
    setTotalProductLabourCost(selectedServicesSubTotal)
    console.log("###################1111selectedServicesSubTotal", selectedServicesSubTotal);

    const newSum = (selectedProductsTotalDiscount + selectedServicesTotalCost) - (values['invoice_discount'] ? values['invoice_discount'] : 0)
    // setRFQSubTotal(newSum)
    console.log("###################1selectedProductsTotalDiscount", selectedProductsTotalDiscount);
    const selectedRfpProduct = cart?.reduce((total, data, index) => {
      let markup = markupType ? (markupType === 1 ? data.WholesaleMarkup || data.product_markup : data.RetailMarkup || data.product_markup) : 0;

      let productTotal = parseFloat((data.product_price + data.product_price * markup / 100)?.toFixed(2)) * data.quantity * data.location_quantity;
      productTotal = parseFloat(productTotal.toFixed(2));  // Ensure two decimal places and parse back to float

      console.log("#####################ProductTotal", productTotal);
      return total + productTotal;
    }, 0);

    const selectedRfpProductJobCost = cart?.reduce((total, data, index) => {
      let productTotal = data.product_price * data.quantity * data.location_quantity;
      productTotal = parseFloat(productTotal.toFixed(2));  // Ensure two decimal places and parse back to float

      console.log("#####################ProductTotal", productTotal);
      return total + productTotal;
    }, 0);

    const selectedRfpServices = cartService?.reduce((total, data, index) => {
      let markup = data.originalMarkup;
      data.sellingPrice = data.sellingPrice || data.unit_cost;

      let productTotal = (data.sellingPrice + (data.sellingPrice * markup / 100)) * data.qty;
      productTotal = parseFloat(productTotal.toFixed(2));  // Ensure two decimal places and parse back to float

      console.log("#####################ProductTotal", productTotal);
      return total + productTotal;
    }, 0);

    const selectedRfpServicesJobCost = cartService?.reduce((total, data, index) => {
      let markup = data.originalMarkup;
      data.sellingPrice = data.sellingPrice || data.unit_cost;

      let productTotal = data.sellingPrice * data.qty;
      productTotal = parseFloat(productTotal.toFixed(2));  // Ensure two decimal places and parse back to float

      console.log("#####################ProductTotal", productTotal);
      return total + productTotal;
    }, 0);
    const selectedMaterialCostJobCost = selectedMaterials?.reduce((total, data, index) => {
      let productTotal = (data.product_price||data.price) * (data.quantity||1) ;
      productTotal = parseFloat(productTotal.toFixed(2));  // Ensure two decimal places and parse back to float
      console.log("#####################ProductTotalewwww1",data);
      console.log("#####################ProductTotalewwww1", productTotal);
      return total + productTotal;
    }, 0);
    const selectedMaterialCost = selectedMaterials?.reduce((total, data, index) => {
      let markUpCost = data.price + ((parseFloat(data.price) * parseFloat(data.markup || 0)) / 100)
      markUpCost = parseFloat(parseFloat(markUpCost).toFixed(2)) || 0;
      let productTotal = (markUpCost || data.product_retail_price) * (data.quantity||1) ;
      productTotal = parseFloat(productTotal.toFixed(2));  // Ensure two decimal places and parse back to float
      console.log("#####################ProductTotalewwww2",data);
      console.log("#####################ProductTotalewwww2",markUpCost);
      console.log("#####################ProductTotalewwww2", productTotal);
      return total + productTotal;
    }, 0);
    console.log("#####################Labour cost", selectedServicesSubTotal + selectedRfpServices);
    setTotalLabourCost(selectedServicesSubTotal + selectedRfpServices)
    console.log("#####################selectedRfpServices", selectedRfpServices);
    console.log("#####################selectedRfpProduct", selectedRfpProduct);
    sum = (selectedProductsTotalDiscount + selectedServicesSubTotal + selectedRfpServices+selectedMaterialCost + selectedRfpProduct) - (values['invoice_discount'] ? values['invoice_discount'] : 0)
    console.log('sumsumsum--values[invoice_discount]', values['invoice_discount'])
    console.log('sumsumsum--selectedRfpProduct', selectedRfpProduct)
    console.log('sumsumsum--selectedMaterialCost', selectedMaterialCost)
    console.log('sumsumsum--selectedRfpServices', selectedRfpServices)
    console.log('sumsumsum--selectedServicesSubTotal', selectedServicesSubTotal)
    console.log('sumsumsum--selectedProductsTotalDiscount', selectedProductsTotalDiscount)
    console.log('sumsumsum--sum', sum)
    taxAmountData = selectedTaxCategory ? (selectedTaxCategory.type === 1 ? (sum * (selectedTaxCategory.value || 0) / 100) : (selectedTaxCategory.value || 0) ) : 0;
    grandTotalData = (parseFloat(sum) + parseFloat(taxAmountData)).toFixed(2);
    console.log("#####################selectedRfpServices", selectedRfpServices);
    setTotalSum(sum);
    setTaxAmount(taxAmountData);
    setGrandTotal(grandTotalData);

    let selectedProductsTotalProductFormula = selectedProducts?.reduce((total, data) => {
      let quantityFieldName = `quantity_${data.product_id ? data.product_id : data.id}`;
      let discountFieldName = `discount_${data.product_id ? data.product_id : data.id}`;
      const dataRetailPrice = data.product_retail_price || data.retail_price;
      const dataWholesalePrice = data.product_price || data.price;
      const dataQuantity = values[quantityFieldName] ? values[quantityFieldName] : (data?.quantity || 1);

      let newTotalProductFormula = parseFloat(data.expected_cost?.toFixed(2)) * data.quantity * data.locationQuantity;
      newTotalProductFormula = newTotalProductFormula ? Number(newTotalProductFormula) : 0;

      console.log('newTotalProductFormula--', newTotalProductFormula);

      return total + newTotalProductFormula;
    }, 0);
    console.log("###############jobcost", selectedRfpProductJobCost);
    selectedProductsTotalProductFormula = selectedProductsTotalProductFormula ? (Number(selectedProductsTotalProductFormula)) : 0

    setTotalProductFormula(selectedProductsTotalProductFormula)

    let selectedProductsTotalWholesalePrice = selectedProducts?.reduce((total, data, index) => {
      let quantityFieldName = `quantity_${data.product_id ? data.product_id : data.id}`;
      let discountFieldName = `discount_${data.product_id ? data.product_id : data.id}`;
      const dataRetailPrice = data.product_retail_price || data.retail_price
      const dataWholesalePrice = data.product_price || data.price
      const dataQuantity = values[quantityFieldName] ? values[quantityFieldName] : (data?.quantity || 1)

      let newTotalWholesalePrice = dataWholesalePrice * data.quantity * data.locationQuantity
      if(data?.adjustment){
        newTotalWholesalePrice = newTotalWholesalePrice ? newTotalWholesalePrice + ((newTotalWholesalePrice * Number(data?.adjustment)) / 100) : 0
      }
      newTotalWholesalePrice = newTotalWholesalePrice ? Number(newTotalWholesalePrice) : 0

      console.log('newTotalWholesalePrice--', newTotalWholesalePrice);

      return total + newTotalWholesalePrice;
    }, 0);
    selectedProductsTotalWholesalePrice = selectedProductsTotalWholesalePrice ? Number(selectedProductsTotalWholesalePrice) : 0

    let selectedServicesTotalWholesalePrice = selectedServices?.reduce((total, data, index) => {
      let newTotalWholesalePrice = (data?.unit_cost * data?.quantityDifference)?.toFixed(2)
      newTotalWholesalePrice = newTotalWholesalePrice ? Number(newTotalWholesalePrice) : 0
      return total + newTotalWholesalePrice;
    }, 0);
    selectedServicesTotalWholesalePrice = selectedServicesTotalWholesalePrice ? Number(selectedServicesTotalWholesalePrice) : 0

    let selectedAddlServicesTotalWholesalePrice = additionalSelectedServices?.reduce((total, data, index) => {
      let newTotalWholesalePrice = data?.total
      newTotalWholesalePrice = newTotalWholesalePrice ? Number(newTotalWholesalePrice) : 0
      return total + newTotalWholesalePrice;
    }, 0);
    selectedAddlServicesTotalWholesalePrice = selectedAddlServicesTotalWholesalePrice ? Number(selectedAddlServicesTotalWholesalePrice) : 0

    let selectedCartTotalWholesalePrice = cart?.reduce((total, data, index) => {
      let markup = markupType ? (markupType === 1 ? data.WholesaleMarkup || data.product_markup : data.RetailMarkup || data.product_markup) : 0;
      let newTotalWholesalePrice = (data.location_quantity * data.quantity * (data.product_price + (data.product_price * markup / 100))).toFixed(2)
      newTotalWholesalePrice = newTotalWholesalePrice ? Number(newTotalWholesalePrice) : 0
      return total + newTotalWholesalePrice;
    }, 0);

    selectedCartTotalWholesalePrice = selectedCartTotalWholesalePrice ? Number(selectedCartTotalWholesalePrice) : 0

    let selectedCartServicesTotalWholesalePrice = cartService?.reduce((total, data, index) => {
      let newTotalWholesalePrice = (data.qty * (data.sellingPrice + (data.sellingPrice * data.originalMarkup / 100)))?.toFixed(2)
      newTotalWholesalePrice = newTotalWholesalePrice ? Number(newTotalWholesalePrice) : 0
      return total + newTotalWholesalePrice;
    }, 0);
    selectedCartServicesTotalWholesalePrice = selectedCartServicesTotalWholesalePrice ? Number(selectedCartServicesTotalWholesalePrice) : 0
    
    let selectedMaterialsTotalWholesalePrice = selectedMaterials?.reduce((total, data, index) => {
      let newTotalWholesalePrice = ((data.markUpCost || 0) * (data.quantity||1))?.toFixed(2)
      newTotalWholesalePrice = newTotalWholesalePrice ? Number(newTotalWholesalePrice) : 0
      return total + newTotalWholesalePrice;
    }, 0);
    selectedMaterialsTotalWholesalePrice = selectedMaterialsTotalWholesalePrice ? Number(selectedMaterialsTotalWholesalePrice) : 0

    let newTotalWholesalePrice = selectedProductsTotalWholesalePrice + selectedServicesTotalWholesalePrice + selectedAddlServicesTotalWholesalePrice + selectedCartTotalWholesalePrice + selectedCartServicesTotalWholesalePrice + selectedMaterialsTotalWholesalePrice
    setTotalWholesalePrice(newTotalWholesalePrice)

    let selectedProductsTotalRetailPrice = selectedProducts?.reduce((total, data, index) => {
      let quantityFieldName = `quantity_${data.product_id ? data.product_id : data.id}`;
      let discountFieldName = `discount_${data.product_id ? data.product_id : data.id}`;
      const dataRetailPrice = data.product_retail_price || data.retail_price
      const dataWholesalePrice = data.product_price || data.price
      const dataQuantity = values[quantityFieldName] ? values[quantityFieldName] : (data?.quantity || 1)

      let newTotalRetailPrice = dataRetailPrice * data.quantity * data.locationQuantity
      if(data?.adjustment){
        newTotalRetailPrice = newTotalRetailPrice ? newTotalRetailPrice + ((newTotalRetailPrice * Number(data?.adjustment)) / 100) : 0
      }
      newTotalRetailPrice = newTotalRetailPrice ? Number(newTotalRetailPrice) : 0

      console.log('newTotalRetailPrice--', newTotalRetailPrice);

      return total + newTotalRetailPrice;
    }, 0);
    selectedProductsTotalRetailPrice = selectedProductsTotalRetailPrice ? Number(selectedProductsTotalRetailPrice) : 0

    let newTotalRetailPrice = selectedProductsTotalRetailPrice + selectedServicesTotalWholesalePrice + selectedAddlServicesTotalWholesalePrice + selectedCartTotalWholesalePrice + selectedCartServicesTotalWholesalePrice + selectedMaterialsTotalWholesalePrice
    setTotalRetailPrice(newTotalRetailPrice)

    //discount count
    let selectedProductDiscount = selectedProducts?.reduce((acc, data, index) => {
      // const discountFieldName = `discount_${data.product_id?data.product_id:data.id}`;
      // const discountVal = values[discountFieldName]?values[discountFieldName]:(data?.discount||0);
      const discountVal = data.discount ? data.discount : 0
      let productDiscount = +discountVal || 0;
      return acc + productDiscount;
    }, 0);
    const newDiscountTotal = selectedProductDiscount + (parseFloat(values['invoice_discount']) || 0);
    setDiscountTotal(selectedProductDiscount + (parseFloat(values['invoice_discount']) || 0));

    console.log("#############selectedProductsTotalProductFormula", selectedProductsTotalProductFormula);
    console.log("#############selectedTotalWholesalePrice", newTotalWholesalePrice);
    console.log("#############selectedTotalRetailPrice", newTotalRetailPrice);
    console.log("####################selectedMaterials", selectedMaterials);

    totalJobcostSum = (selectedProductsTotalDiscountForJobCost + selectedServicesCost +selectedMaterialCostJobCost+ selectedRfpProductJobCost + selectedRfpServicesJobCost);
    console.log('total job cost------- budget', totalJobcostSum);
    values['total'] = sum
    values['taxAmount'] = taxAmountData
    values['budget'] = totalJobcostSum
    console.log("################Budget", totalJobcostSum);
    setBudget(totalJobcostSum)
    let subTotal = markupType == 1 ? ((selectedProductsTotalWholesalePrice - newDiscountTotal + selectedRfpProduct+selectedMaterialCost))
      : (markupType == 2 ? ((selectedProductsTotalRetailPrice - newDiscountTotal + selectedRfpProduct)) : 0);
    console.log("################subTotal", subTotal);
    setRFQSubTotal(subTotal)
    // let selectedProductsTotalMarkupAmount = markupType == 1 ? ((selectedProductsTotalWholesalePrice - newDiscountTotal) - selectedProductsTotalProductFormula)
    //   : (markupType == 2 ? ((selectedProductsTotalRetailPrice - newDiscountTotal) - selectedProductsTotalProductFormula) : 0);
    // setTotalMarkupAmount(selectedProductsTotalMarkupAmount)
    let selectedProductsTotalMarkupAmount = sum - totalJobcostSum
    setTotalMarkupAmount(selectedProductsTotalMarkupAmount)


    let selectedProductsTotalMarkup = (selectedProductsTotalMarkupAmount / totalJobcostSum) * 100
    setTotalMarkupPercentage(selectedProductsTotalMarkup)

    console.log('selectedProductsTotalMarkup-------', selectedProductsTotalMarkup);

    // let selectedProductsTotalMarkup = markupType == 1 ? ((((selectedProductsTotalWholesalePrice - newDiscountTotal) - selectedProductsTotalProductFormula) / selectedProductsTotalProductFormula) * 100)
    //   : (markupType == 2 ? ((((selectedProductsTotalRetailPrice - newDiscountTotal) - selectedProductsTotalProductFormula) / selectedProductsTotalProductFormula) * 100) : 0);
    // setTotalMarkupPercentage(selectedProductsTotalMarkup)

    // console.log('selectedProductsTotalDiscount-------',selectedProductsTotalDiscount);
    // console.log('selectedProductsTotalDiscountForJobCost-------',selectedProductsTotalDiscountForJobCost);
    // console.log('selectedServicesCost-------', selectedServicesCost);
    // console.log('selectedServicesTotalCost-------',selectedServicesTotalCost);
    // console.log('selectedServicesSubTotal-------',selectedServicesSubTotal);
    // console.log('sum-------',sum);

    console.log('initialServiceCost In ProductFormula-----', initialServicesCost);
    setInitialServiceCostInProductFormula(initialServicesCost)
    // }else{
    //   console.log('eeeeeeeeeeeeeeeeee');
    // }
  } catch (error) {
    console.log('Error--------------', error);
  }
}, [currentRFQData, selectedTaxCategory, selectedProducts,selectedMaterials, selectedServices, additionalSelectedServices, values.markup_type, values.invoice_discount, refreshData, cartService, cart])

console.log('additionalSelectedServices--',additionalSelectedServices);
////image functionality
const handleRemoveAll = () => {
  setAvatarUrl([]);
};

const handleRemove = (file) => {
  const filteredItems = avatarUrl.filter((_file) => _file !== file);
  setAvatarUrl([filteredItems]);
};

///////
const handleDrop = useCallback(
  (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0]; 
      const Filename = new Date().getTime() + file.name;

      const StorageRef = imageref(storage, Filename);
      const uploadTask = uploadBytesResumable(StorageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        },
        (error) => {
          console.error(error.message);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setFieldValue('avatarUrl',downloadURL)
           
          });
        }
      );
    }
  },
  [setFieldValue]
);

let subTotal = 0
const ACCOUNT_TABS = [
  {
    value: `${user.isVendor ? '' : 'Estimate '}Details`,
    component: details ? (
      <ViewRequest
        teamMembers={teamMembers}
        clientDetails={invoice}
        products={invoice?.cust_product}
        fetchRequestDetails={fetchRequestDetails}
        user={user}
        designs={designs}
        setDesigns={setDesigns}
        details={details}
        isRequest={true}
        requestId={id}
        signAgreements={signAgreements}
        customTemplate={customTemplate}
        questionnaireTemplate={questionnaireTemplate}
        allDataEmpty={allDataEmpty}
        portfolio={portfolio}
        invoice={invoice}
        quotation={1}
        setRenderCards={setRenderCards}
        renderCards={renderCards}
        rfpServicesEdit={rfpServicesEdit}
        requestServices={requestServices}
        labourInstallService={labourInstallService}
      />
    ) : "",
  },
...((user.user_type === 3 || explodedPermissions?.some((permission) => ['Access Estimate Public Message Board'].includes(permission))) ? [
  {
    value: 'public message board',
    component: (
      <PublicMessageBoard
        products={invoice?.cust_product}
        requestDetails={details}
        requestId={id}
        acceptedClients={acceptedClients}
      />
    ),
  }
] : []),
  // ...(user.isVendor ? [] : [
  //   {
  //     value: 'change order',
  //     component: <RequestTabUpdates updates={updates?.length > 0 ? updates : []} />,
  //   },
  //   {
  //     value: 'payments',
  //     component: <PaymentRequest totalValue={invoice?.total} />,
  //   },
    {
      value: 'Locations and Notes',
      component: <Locations products={invoice?.cust_product} designs={designs} />,
    },
    ...(!details?.map_service ? [] : [
      {
        value: 'Route - Map Services',
        component: <RouteMap details={details} id={id} route_id={details?.map_service} />,
      },
    ]),
  // ...(userType === 3 ? (user.isVendor ? [
  //   {
  //     value: 'My Proposal',
  //     component: <Proposals setReloadData={() => setRefresh(!refresh)} acceptedClients={acceptedClients} proposalData={proposalData} />,
  //   },
  // ] : []) : [
  //   {
  //     value: 'Proposals',
  //     component: <Proposals setReloadData={() => setRefresh(!refresh)} acceptedClients={acceptedClients} proposalData={proposalData} />,
  //   },
  // ]),
];

const handleDeleteService = (servId, indexId) => {
  // const newFilteredServices = additionalSelectedServices?.filter(data => data.id !== servId);
  const newFilteredServices = additionalSelectedServices?.filter((data) => {
    if (data?.isAdditionalProduct) {
      return data?.newIndexId !== indexId;
    }
    return !(data.id == servId && data.indexId == indexId)
  });
  // const allFilteredServices = filteredServices?.find(data => data.id !== servId);
  setFieldValue('services', newFilteredServices);
  setAdditionalSelectedServices(newFilteredServices);
};
useEffect(()=>{
  if(selectedProducts.length<=0){
    setAllLocationSame(false)
  }
   },[selectedProducts])
const handleCancel = async (isEdit) =>{
  console.log("isEdit++++++++isEdit",isEdit)
  console.log("isEdit++++++++edit",edit)
  // setOpenEdit(false)
  // setIsOpen(false)
  setCurrentRFQdata({})
  // resetForm();
  setSelectedProducts([])
  setSelectedMapserviceProducts([]);
  setSelectedServices([]);
  setAdditionalSelectedServices([])
  setFilteredServices([])
  fetchRequestDetails()
  setAllLocationSame(false)
  if(popupOn && !isEdit ){
  closeView()
  // setRenderCards(!renderCards)
  }
  if ((isEdit && edit) || (isEdit === undefined && edit)) {
    if(popupOn){
      closeView()
    }
      // else{
      //   navigate(`/dashboard/quick-bid`)
      // }
  } else if (isEdit && !edit) {
    if(popupOn){setIsOpen(false);}
    // else{
    //   navigate(`/dashboard/quick-bid`)
    // }
  }
  if(edit){
    edit=false
    setOpenEdit(false)
  setIsOpen(false)
  }
  if(!popupOn && !isEdit){
    navigate(`/dashboard/quick-bid`)
  }
   setOpenEdit(false)
  setIsOpen(false)
}
console.log("values++++++++",values)
console.log('selectedProductsselectedProducts',selectedProducts)
const selectedProductsAdditionalCost = selectedProducts?.filter(item=>item?.isAdditionalProduct == true)
?.reduce((total, item) => total + (item.product_total || 0), 0)
?.toFixed(2)

// const selectedProductsAdditionalCost = useMemo(() => {
//   console.log('selectedProductsselectedProducts',selectedProducts)
//   return selectedProducts
//     ?.filter(item => item?.isAdditionalProduct)
//     ?.reduce((total, item) => total + (parseFloat(item.product_total) || 0), 0)
//     ?.toFixed(2);
// }, [selectedProducts]);


const selectedMaterialSubTotal = selectedMaterials //?.filter(item=>item?.isAdditionalProduct == true)
?.reduce((total, item) => total + ((item.markUpCost || 0) * (item.quantity || 1)), 0)
?.toFixed(2)

let totalMarkup = 0;
let totalServiceMarkup = 0;
let originaltotalMarkup = 0
  let originaltotalServiceMarkup=0
let totalAmount = 0;
  return (
    <Page title="View Estimate: View | Sign Wise Solutions">
    
  {loading?<Stack sx={{display:'flex',justifyContent:'center',alignItems:'center',alignContent:'center',mt:8}} >
      <LoadingScreen />
    </Stack>:

      <Container>
      
      
        <Button
          variant="outlined"
          type="submit"
          // onClick={()=>navigate(PATH_DASHBOARD.official.root)}
          onClick={()=>{ openEdit ? handleCancel(true) : handleCancel() }}
          sx={{ alignItems: 'flex-end',mt:3 , mb: 4 }}
          startIcon={ // Use the startIcon prop to add an icon to the left
            <Stack>
              <KeyboardBackspaceOutlined/>
            </Stack>

          }
        >
          Back
        </Button>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >         
          <HeaderBreadcrumbs
            heading={details?.name}
          />
          {!openEdit && (
            <Stack marginBottom={5} flexDirection={'column'}>
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                {(user.isVendor===1 && proposalData && !proposalData?.length>0) &&( <Button 
                  size="small"
                  variant="contained"
                  disabled={explodedPermissions?.some(permission => ['Submit Proposal'].includes(permission)) ? false : true}
                  onClick={()=>setAddProposal(true)}
                  sx={{ height: 34 }}
                  startIcon={<Icon icon={plusFill} />}
                >
                Submit Proposal
                </Button>)}
                </Stack>
              {(userType!=3) && details && details?.quotation !== 0  ? 
              <Stack>
                <Stack flexDirection={'row'} gap={2}>
                  {details?.quotation_status !==6 && empty(details?.installationStatus)&&(
                    <Tooltip title="Edit">
                      <IconButton sx={{backgroundColor:theme.palette.divider}} onClick={()=>{(explodedPermissions?.some(permission => ['Edit Estimate'].includes(permission))) ? handleEditClick() : !empty(details?.installationStatus)?(enqueueSnackbar("Access Denied: Moved to Installation Phase", { variant: 'error' })):(enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' }))}}>
                          <Icon icon={editFill} width={24} height={24} color="#ffffff"/>
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Delete">
                    <IconButton sx={{backgroundColor:theme.palette.divider}} onClick={()=>{(explodedPermissions?.some(permission => ['Delete Estimate'].includes(permission))) ? handleDeleteMenu() : (enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' }))}} >
                        <Icon icon={trash2Outline} width={24} height={24} color="#ffffff"/>
                    </IconButton>
                  </Tooltip>                
                  {details?.quotation_status ==6 &&(
                    <Tooltip title="Reopen">
                      <IconButton sx={{backgroundColor:theme.palette.divider}} onClick={handleReopen} >
                          <Icon icon={lockOpenFill} width={24} height={24} color="#ffffff"/>
                      </IconButton>
                    </Tooltip>
                  )}
                  {explodedPermissions?.some(permission => ['Create Estimate','Edit Estimate','Delete Estimate'].includes(permission)) ? 
                    (details?.quotation_status ==4  &&(
                      <AnimatedButton title={'Convert to Sales Order'} onClick={handleConvertToRFQ} />
                    )) : ''}
                  {/* {details?.quotation_status ==4 &&(
                    <Tooltip title="Convert to RFQ">
                      <IconButton sx={{backgroundColor:theme.palette.divider}} onClick={handleConvertToRFQ} >
                          <Icon icon={plusFill} width={24} height={24} color="#ffffff"/>
                      </IconButton>
                    </Tooltip>
                  )} */}
                </Stack>
              </Stack>:""}
            </Stack>)}
        </Stack>
        {openEdit && (
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container spacing={3}>

                <Grid item xs={12} md={12}>
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={3}>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <Stack flexDirection={'column'}>
                          {/* <LabelStyle>Name</LabelStyle> */}
                          <TextField
                            //   fullWidth
                            sx={{ width: 400 }}
                            label="Requested Name"
                            {...getFieldProps('name')}
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                          />
                        </Stack>
                      </Stack>
                      {/* <LabelStyle>Description</LabelStyle> */}
                      <TextField
                        fullWidth
                        multiline
                        minRows={3}
                        maxRows={7}
                        label="keep the summary brief and make it communicates"
                        {...getFieldProps('description')}
                        error={Boolean(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                      />
                      {/* <LabelStyle>Upload Approved Design</LabelStyle> */}
                      {/* <UploadAvatar
                        accept="image/*"
                        file={values.avatarUrl}
                        maxSize={3145728}
                        onDrop={handleDrop}
                        error={Boolean(touched.avatarUrl && errors.avatarUrl)}
                      /> */}
                         {/* <UploadMultiFile
                         showPreview
                        maxSize={3145728}
                        accept="image/*"
                         files={avatarUrl}
                        onDrop={handleDrop}
                        onRemove={handleRemove}
                        onRemoveAll={handleRemoveAll}
                    // error={Boolean(touched.avatarUrl && errors.avatarUrl)}
                    /> */}
                      {/* <LabelStyle>Skills or services required</LabelStyle> */}
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        {/* <Autocomplete
                          multiple
                          fullWidth
                          id="combo-box-demo"
                          options={skills}
                          value={values.skills}
                          onChange={(event, newValue) => setFieldValue('skills', newValue)}
                          renderInput={(params) => (
                            <TextField {...params} label="Skills or services required" />
                          )}
                          getOptionLabel={(option) => option.name}
                        /> */}
                          <Autocomplete
                            sx={{ width: '50%' }}
                            id="combo-box-demo"
                            disabled
                            // disabled={details?.checkProjectExist ? true : false}
                            options={allClientsEdit}
                            value={values.clients}
                            onChange={(event, newValue) => {
                              // if (newValue) {
                              //   setFieldValue('clients', newValue);
                              // } else {
                              //   setFieldValue('clients', null);
                              // }
                              if (newValue) {
                                setFieldValue('clients', newValue);
                                setFieldValue('community', null);
                                fetchAllCommunityByClient(newValue);
                              } else {
                                setFieldValue('clients', null);
                                setFieldValue('community', null);
                                setCommunities([]);
                              }
                            }}

                            renderInput={(params) => (
                              <TextField required
                                {...params} label="Clients"
                                error={Boolean(touched.clients && errors.clients)}
                                helperText={touched.clients && errors.clients} />
                            )}
                            getOptionLabel={(option) => option.name}
                          />

{isCommunityLoading ?
                              <Stack flexDirection={'row'} alignItems='center' justifyContent='center'>
                                <CircularProgress color="primary" variant="indeterminate" />
                                <Typography variant='body2'>Loading projects...</Typography>
                              </Stack> :
                          communities?.length > 0 ? (<Autocomplete
                            sx={{ width: '50%' }}
                            id="community"
                            options={communities}
                            value={values?.community}
                            onChange={(event, newValue) => {
                              if(newValue){
                              setFieldValue('community', newValue)

                              // Extract new community address
                             const { address: projAddress = '', city: projCity = '', state: projState = '', zip: projZip = '' } = newValue || {};
                             const communityAddress = {
                               id: 0,
                               address: projAddress,
                               city: projCity,
                               state: projState,
                               zip: projZip,
                             };
                         
                             // Update locations in `setSelectedProducts`
                             setSelectedProducts((prevProducts) =>
                               prevProducts.map((product) => {
                                if (product?.fromMapServices == true) {
                                  return product;
                                }else{
                                  const updatedAddresses = product.location?.map((loc) => ({
                                    ...loc,
                                    address: projAddress || loc.address,
                                    city: projCity || loc.city,
                                    state: projState || loc.state,
                                    zip: projZip || loc.zip,
                                  })) || [communityAddress];
                                  return { ...product, location: updatedAddresses };
                                }
                               })
                             );
                         console.log("selectedProductsselectedProducts",selectedProducts)
                             // Update locations in `setCart`
                             setCart((prevCart) =>
                               prevCart.map((product) => {
                                 const updatedAddresses = product.location?.map((loc) => ({
                                   ...loc,
                                   address: projAddress || loc.address,
                                   city: projCity || loc.city,
                                   state: projState || loc.state,
                                   zip: projZip || loc.zip,
                                 })) || [communityAddress];
                                 return { ...product, location: updatedAddresses };
                               })
                             );
                              if(newValue.tax_category){
                              let taxCat = taxCategories?.find(cat => newValue.tax_category === cat.id)
                              setSelectedTaxCategory(taxCat)
                              }
                            } else {
                              
                              setFieldValue('community', null);
                              setSelectedTaxCategory(null);
                            }
                            }}
                            renderInput={(params) => (
                              <TextField required
                                {...params} label="Project"
                                error={Boolean(touched.community && errors.community)}
                                helperText={touched.community && errors.community} />
                            )}
                            getOptionLabel={(option) => option.name}
                          />) : (
                            <Stack sx={{ width: '50%' }} justifyContent={'center'}>
                              {values?.clients ?
                                <Typography sx={{ color: "red" }}>The selected client does not have any projects yet. Please create a new project for them!</Typography>
                                : <Typography sx={{ color: "red" }}>No client selected. Please choose a client from the list.</Typography>}
                            </Stack>
                          )
                        }
                    
                    </Stack>
                        {/* {communities && communities.length > 0 ? ( <Autocomplete
                          fullWidth
                          id="community"
                          options={communities}
                          value={values.community}
                          onChange={(event, newValue) => setFieldValue('community', newValue)}
                          renderInput={(params) => (
                            <TextField  required 
                            {...params} label="Project"
                            error={Boolean(touched.community && errors.community)}
                            helperText={touched.community && errors.community} />
                          )}
                          getOptionLabel={(option) => option.name}
                        />):
                          <Typography sx={{color:"red", width: '50%'}}>The client you have chosen does not have a project yet. Please create one for them! </Typography>
                        } */}
                      {/* </Stack>
                      
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}> */}
                         {/* <Autocomplete                        
                          fullWidth
                          id="combo-box-demo-type"
                          options={projectTypes}
                          value={values.project_type}
                          onChange={(event, newValue) => setFieldValue('project_type', newValue)}
                          renderInput={(params) => (
                            <TextField  required 
                            {...params} label="Job Type"
                            error={Boolean(touched.project_type && errors.project_type)}
                            helperText={touched.project_type && errors.project_type} />
                          )}
                          getOptionLabel={(option) =>option.name}

                        />
                         <Autocomplete
                         fullWidth
                          id="combo-box-demo-category"
                          options={projectCategories}
                          value={values.project_category}
                          onChange={(event, newValue) => setFieldValue('project_category', newValue)}
                          renderInput={(params) => (
                            <TextField  required 
                            {...params} label="Job Category"
                            error={Boolean(touched.project_category && errors.project_category)}
                            helperText={touched.project_category && errors.project_category} />
                          )}
                          getOptionLabel={(option) => option.name}

                        /> */}
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                          {/* <Autocomplete                        
                            fullWidth
                            disabled
                            // sx={{ width: '50%' }}
                            id="combo-box-demo-type"
                            options={markupTypes}
                            value={values.markup_type}
                            onChange={(event, newValue) => {
                              setFieldValue('markup_type', newValue)
                              setMarkupType(newValue?.id)
                            }}
                            renderInput={(params) => (
                              <TextField  required 
                              {...params} label="Markup Type"
                              error={Boolean(touched.markup_type && errors.markup_type)}
                              helperText={touched.markup_type && errors.markup_type} />
                            )}
                            getOptionLabel={(option) => option.name}
                          /> */}
                          <Autocomplete                        
                            fullWidth
                            sx={{ width: {xs:'100%' ,sm:'50%'} }}
                            id="combo-box-demo-type"
                            options={projectTypes}
                            value={values.project_type}
                            onChange={(event, newValue) => {
                              setFieldValue('project_type', newValue)
                              if (newValue?.name !== 'TS') {
                                setFieldValue('map_service', null)
                                const filteredProducts = selectedProducts?.filter(product => product?.fromMapServices !== true);
                                setSelectedProducts(filteredProducts);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField  required 
                              {...params} label="Job Class"
                              error={Boolean(touched.project_type && errors.project_type)}
                              helperText={touched.project_type && errors.project_type} />
                            )}
                            getOptionLabel={(option) =>option.name}
                          />
                        </Stack>
                        {values?.project_type?.name == 'TS' ? 
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                          <Autocomplete
                            // fullWidth
                            sx={{ width: '50%' }}
                            id="combo-box-demo-category"
                            options={projectCategories}
                            value={values.project_category}
                            onChange={(event, newValue) => {
                              setFieldValue('project_category', newValue)
                              if (values?.project_type?.name !== 'TS' || newValue?.id != 14) {
                                setFieldValue('map_service', null)
                                const filteredProducts = selectedProducts?.filter(product => product?.fromMapServices !== true);
                                setSelectedProducts(filteredProducts);
                                setSelectedMapserviceProducts([]);
                                setSelectedServices([]);
                              }
                              if(values.project_category?.id == 14){
                                setSelectedProducts([]);
                                setSelectedMapserviceProducts([]);
                                setSelectedServices([]);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField  
                              {...params} label="Job Type"
                              error={Boolean(touched.project_category && errors.project_category)}
                              helperText={touched.project_category && errors.project_category} />
                            )}
                            getOptionLabel={(option) => option.name}
                          />
                          {values.project_category?.id == 14 ? 
                          <Autocomplete
                            // fullWidth
                            sx={{ width: '50%' }}
                            id="combo-box-demo-type"
                            options={mapServices}
                            value={values.map_service}
                            onChange={(event, newValue) => {
                              setFieldValue('map_service', newValue)
                              if (newValue?.products) {
                                let newIndexCounter = indexCounter;
                                const updatedProductsWithLocations = newValue?.products?.map((product,index) => {
                                  const filteredData = allProducts?.find((i) => i.id === product.product_id);
                                  const associatedLocations = newValue?.locations
                                    .filter((location) => location?.route_product_id === product?.id)
                                    .map((location) => ({
                                      address: location.address,
                                      city: location.city,
                                      state: location.state,
                                      zip: location.zip,
                                      route_location_id: location.id,
                                    }));
                                    newIndexCounter += 1;
                                  return {
                                    ...product,
                                    ...filteredData,
                                    indexId: newIndexCounter, // `map_${index}`,
                                    fromMapServices:true,
                                    locationQuantity: product?.qty,
                                    location: associatedLocations,
                                    discount: 0,
                                    adjustment: 0,
                                    quantity: 1,
                                    markAdjType:1
                                  };
                                });
                                console.log('updatedProductsWithLocations-----------',updatedProductsWithLocations)
                                const mergedSelectedProducts = [
                                  ...selectedProducts.filter((product) => product?.fromMapServices !== true), // Keep existing products without fromMapServices
                                  ...updatedProductsWithLocations, // Add the new products from map services
                                ];
                                setSelectedMapserviceProducts(updatedProductsWithLocations);
                                setSelectedProducts(mergedSelectedProducts);
                                setIndexCounter(newIndexCounter);
                                const productServices = mergedSelectedProducts?.reduce((services, product) => {
                                  if (product.services && product.services.length > 0) {
                                    return services.concat(
                                      product.services.map((service) => ({
                                        ...service,
                                        fromMapServices:product?.fromMapServices || false,
                                        productIndex: product?.indexId,
                                        quantity: service.quantity * product.quantity * product.locationQuantity,
                                        previousQty: service.quantity,
                                        qtyData: product.quantity,
                                        locationQuantity: product.locationQuantity,
                                      }))
                                    );
                                  }
                                  return services;
                                }, []);
                                setSelectedServices(productServices);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField required
                                {...params} label="Map Service"
                                error={Boolean(touched.map_service && errors.map_service)}
                                helperText={touched.map_service && errors.map_service} />
                            )}
                            // getOptionLabel={(option) => option.name}
                            getOptionLabel={(option) => `${option.name} (${option.locations_count})`}
                          /> : '' }
                      </Stack> : '' }
                      {/* <Stack>
                      <Autocomplete
                          multiple
                          fullWidth
                          id="combo-box-demo"
                          options={workflow}
                          value={values.workflow}
                          onChange={(event, newValue) => setFieldValue('workflow', newValue)}
                          renderInput={(params) => (
                            <TextField {...params} label="Workflow" />
                          )}
                          getOptionLabel={(option) => option.name}

                          />
                      </Stack> */}
                      {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <Box
                       component={MobileDatePicker}
                      label="Start Date"
                      minDate={moment()}
                      defaultValue={moment.unix(values.start).format('MM/DD/YYYY')}
                      value={moment.unix(values.start)}
                       onChange={(date) => setFieldValue('start', moment(date).unix())}
                       renderInput={(params) => (
                      <TextField size="small" {...params} fullWidth />
                       )}
                       />

                        <Box
                          component={MobileDatePicker}
                          label="Installation Date"
                          minDate={moment()}
                          defaultValue={moment.unix(values.end).format('MM/DD/YYYY')}
                          value={moment.unix(values.end)}
                           onChange={(date) => setFieldValue('end', moment(date).unix())}
                           renderInput={(params) => (
                          <TextField size="small" {...params} fullWidth 
                            error={Boolean(touched.end && errors.end)}
                            helperText={touched.end && errors.end}
                          />
                           )}
                        />
                      </Stack> */}
                      
                      <Divider />
                      <LabelStyleHead>Products</LabelStyleHead>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <Autocomplete
                          sx={{ width: 400 }}
                          multiple
                          id="combo-box-demo"
                          options={allProducts?.filter(item=>item.inhouse == 1) || []}
                          // value={selectedProducts?.filter(item=>item?.fromMapServices != true)}
                          value={selectedProducts?.filter(
                            (item) => (item?.fromMapServices != true && !item.isAdditionalProduct)
                          )}
                          onChange={(event, newValue) => {
                            // Update values.products and call handleProduct(newValue)
                            handleProduct(newValue);
                            setFieldValue('products', newValue);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Select a product" />
                          )}
                          getOptionLabel={(option) => option.product_name ? option.product_name : option.name}
                          renderOption={(props, option) => (
                            <li {...props}>
                              {option.name}
                            </li>
                          )}
                        />
                      </Stack>
                      <LabelStyle>Selected Products</LabelStyle>
                      <Scrollbar>
                        <TableContainer sx={{ minWidth: 500 }}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                  {VALUE?.map((val) => (
                                    val.id === 10 ? (
                                      <Tooltip key={val.id} title="Product Formula Cost Per Unit * Quantity">
                                        <TableCell>{val.value}</TableCell>
                                      </Tooltip>
                                    ) : (
                                      val.id === 14 ? (
                                        <Tooltip key={val.id} title="Selling Price With Marketing Adjustment">
                                          <TableCell>{val.value}</TableCell>
                                        </Tooltip> ) :
                                      <TableCell key={val.id}>{val.value}</TableCell>
                                    )
                                  ))}
                                {/* {VALUE?.map((val) => (
                                  <TableCell>{val.value}</TableCell>
                                ))} */}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            
                             
                               {selectedProducts?.sort((a, b) => a.indexId - b.indexId)?.map((data, index) => {
                                  const quantityFieldName = `quantity_${data.product_id||data.id}`;
                                  const discountFieldName = `discount_${data.product_id||data.id}`;
                                  const adjustmentFieldName = `adjustment_${data.product_id || data.id}`;
                                
                                  let unitName = UNITS?.filter((item)=>item?.id==data?.unit)
                                  unitName = unitName[0]?.name
                                  let shortUnitName = ''
                                  if(unitName=='Square feet'){
                                    shortUnitName = 'Sq ft'
                                  }else if(unitName=='Lineal feet'){
                                    shortUnitName = 'Lin ft'
                                  }else if(unitName=='Square Inches'){
                                    shortUnitName ='Sq Inches'
                                  }else if(unitName=='Unit'){
                                    shortUnitName = 'Unit'
                                  }
                                  const dataPrice = data.product_price||data.price
                                  const dataretailPrice = data.product_retail_price||data.retail_price
                                  const dataWholesalePrice = data.product_price||data.price
                                  // const dataQuantity = values[quantityFieldName]
                                  const dataQuantity = data.quantity||1
                                  console.log('data.discount--',data.discount);
                                  const dataDiscount = data.discount?data.discount:0
                                  const rowTotal = calculateTotal(markupType == 1 ? dataWholesalePrice : (markupType == 2 ? dataretailPrice : 0), dataQuantity, dataDiscount,data.quantity,data.locationQuantity, Number(data?.adjustment),data.markAdjType);
                                  console.log('calculateTotal--rowTotal', rowTotal)
                                  console.log('calculateTotal--dataretailPrice', dataretailPrice)
                                  console.log('calculateTotal--dataWholesalePrice', dataWholesalePrice)
                                  console.log('calculateTotal--markupType', markupType)
                                  // const markup = ((dataretailPrice - dataPrice) / dataPrice) * 100;
                                  const invoiceAmount = parseFloat(data?.expected_material_cost?.toFixed(2)) + parseFloat(data?.expected_total_service_cost?.toFixed(2))
                                  // let markup = markupType ? (invoiceAmount / rowTotal) : null

                                  let markup = markupType == 1 ? (((dataWholesalePrice - parseFloat(data.expected_cost?.toFixed(2))) / parseFloat(data.expected_cost?.toFixed(2))) * 100) 
                                                : (markupType == 2 ? (((dataretailPrice - parseFloat(data.expected_cost?.toFixed(2))) / parseFloat(data.expected_cost?.toFixed(2)))*100) : 0);
                                  markup = markup ? Number(markup) : 0
                                  data['markup'] = markup
                                  let markupData = data.product_markup?.toFixed(2) ||data.markup?.toFixed(2)
                                  markupData = Number(markupData) * data.quantity
                                  data['markup'] = markupData
                                  totalMarkup += Number(data.product_markup) || Number(data.markup) || 0
                                  data['markup_type'] = markupType
                                  data['totalMarkup'] = totalMarkup?.toFixed(2)
                                  let originalMarkup= markupType == 1 ? data.wholesaleMarkup :  markupType == 2 ? data.retailMarkup : 0
                                  originaltotalMarkup+=originalMarkup
                                  // values['totalMarkup'] = totalMarkup?.toFixed(2)
                                  productFormulaCost+=parseFloat(data.expected_cost?.toFixed(2))*data.quantity*data.locationQuantity

                                  const pfc = parseFloat(data.expected_cost?.toFixed(2)) * data.quantity*data.locationQuantity // total product formula cost
                                  const rtp = dataretailPrice * data.quantity*data.locationQuantity // total retail price
                                  const wsp = dataWholesalePrice * data.quantity*data.locationQuantity // total wholesale price
  
                                  const maxDiscount_wholesale = wsp - pfc
                                  const maxDiscount_retail = rtp - pfc

                                  // const maxDiscount_wholesale = (wsp - pfc) - DiscountTotal
                                  // const maxDiscount_retail = (rtp - pfc) - DiscountTotal

                                  console.log('maxDiscount_wholesale--',maxDiscount_wholesale);
                                  console.log('maxDiscount_retail--',maxDiscount_retail);
                                  console.log('DiscountTotal--',DiscountTotal);
                                  console.log('pfc--',pfc);
                                  console.log('rtp--',rtp);
                                  console.log('wsp--',wsp);
                                  data['product_total'] = rowTotal
                                  let SPMA = 0
                                  if(markupType){
                                    if(markupType == 1){
                                      if(data.markAdjType==2 || data.markAdjType==3) {
                                        SPMA = (Number(dataPrice) || 0) + (data.adjustment ? Number(data.adjustment) : 0)
                                      }else{

                                        SPMA = (Number(dataPrice) || 0) + (((dataPrice || 0) * (data.adjustment ? Number(data.adjustment) : 0)) / 100)
                                      }
                                    }else{
                                      if(data.markAdjType==2 || data.markAdjType==3) {
                                        SPMA = (Number(dataretailPrice) || 0) + (dataretailPrice ? Number(data.adjustment) : 0)
                                      }else{

                                        SPMA = (Number(dataretailPrice) || 0) + (((Number(dataretailPrice) || 0) * (dataretailPrice ? Number(data.adjustment) : 0)) / 100)
                                      }
                                    }
                                  }
                                  console.log('SPMA-----data.price-----',dataPrice)
                                  console.log('SPMA-----data.adjustment-----',data.adjustment)
                                  console.log('SPMA-----data.retail_price-----',dataretailPrice)
                                  console.log('SPMA-----SPMA-----',SPMA)
                                  SPMA = Number(SPMA)
                                  data['SPMA'] = SPMA?.toFixed(2)
                                  return (
                                    <>
                                    <TableRow key={index}>
                                      <TableCell>#{data.indexId}</TableCell>
                                      <TableCell>{data?.isAdditionalProduct ?
                                        <TextField
                                          required
                                          fullWidth
                                          type="text"
                                          label={'Product Name'}
                                          sx={{ width: 85 }}
                                          value={data.product_name || data.name}
                                          onChange={(event) => {
                                            let newValue =
                                              event.target.value

                                            // Update the selectedProducts state
                                            setSelectedProducts(
                                              (prevProducts) =>
                                                prevProducts.map(
                                                  (product) => {
                                                    if (
                                                      product.indexId ===
                                                      data.indexId
                                                    ) {
                                                      return {
                                                        ...product,
                                                        name: newValue,
                                                        product_name: newValue,
                                                      }
                                                    } else {
                                                      return product
                                                    }
                                                  },
                                                ),
                                            )
                                            data['name'] = newValue || ''
                                            data['product_name'] = newValue || ''
                                            if (isSubmitted)
                                              setIsSubmitted(false)
                                          }}
                                          error={
                                            isSubmitted && !data.name?.trim() && !data.product_name?.trim()
                                          }
                                          helperText={
                                            isSubmitted && !data.name?.trim() && !data.product_name?.trim()
                                              ? 'Product Name is required'
                                              : ''
                                          }                                            
                                        />
                                      : data.product_name||data.name}</TableCell>
                                      <TableCell>
                                          <IconButton onClick={() => handleOpenNote(data.indexId, data.product_notes)}>
                                            <NoteAdd
                                              sx={{
                                                color: (!data?.product_notes || data?.product_notes === '') ? '' : theme.palette.primary.main,
                                              }}
                                            />
                                          </IconButton>
                                        </TableCell>
                                      <TableCell>{data?.isAdditionalProduct ? 'N/A' : (parseFloat(data.expected_cost?.toFixed(2))*data.quantity*data.locationQuantity).toFixed(2)}</TableCell>
                                      {/* <TableCell>{invoiceAmount}</TableCell> */}
                                      <TableCell>{(hasMarkupPermission && markupType == 2) ? 
                                      (data?.isAdditionalProduct ?
                                        <TextField
                                          required
                                          fullWidth
                                          type="number"
                                          label={'Retail Price Each'}
                                          disabled={values.project_category?.name == 'Time & Material' ? true : false}
                                          sx={{ width: 85 }}
                                          value={dataretailPrice}
                                          // onWheel={(e) => e.target.blur()}
                                          onKeyPress={(event) => {
                                            // if (event.key === '0' && event.target.value === '') {
                                            //   event.preventDefault();
                                            // }
                                            if (event.key === '-') {
                                              event.preventDefault();
                                            }
                                            if (event.key === 'e') {
                                              event.preventDefault();
                                            }
                                          }}
                                          inputProps={{ min: 0 }}
                                          defaultValue={0}
                                          onChange={(event) => {
                                            let newValue =
                                              event.target.value
                                            if (newValue !== '') {
                                              setSelectedProducts(
                                                (pv) => [
                                                  ...pv.map((p) => {
                                                    if ( p.indexId === data.indexId ) {
                                                      return {
                                                        ...p,
                                                        retail_price:newValue,
                                                        product_retail_price:newValue,
                                                      }
                                                    } else {
                                                      return p
                                                    }
                                                  }),
                                                ],
                                              )
                                              data['product_retail_price'] = newValue || 0
                                              data['retail_price'] = newValue || 0
                                              // dataretailPrice = newValue || 0
                                            }
                                          }}
                                          // error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                          // helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                        /> :
                                        dataretailPrice?.toFixed(2))
                                      : null}</TableCell>
                                      <TableCell>{(hasMarkupPermission && markupType == 1) ?
                                      (data?.isAdditionalProduct ?
                                        <TextField
                                          required
                                          fullWidth
                                          type="number"
                                          label={'Retail Price Each'}
                                          disabled={values.project_category?.name == 'Time & Material' ? true : false}
                                          sx={{ width: 85 }}
                                          value={dataPrice}
                                          // onWheel={(e) => e.target.blur()}
                                          onKeyPress={(event) => {
                                            // if (event.key === '0' && event.target.value === '') {
                                            //   event.preventDefault();
                                            // }
                                            if (event.key === '-') {
                                              event.preventDefault();
                                            }
                                            if (event.key === 'e') {
                                              event.preventDefault();
                                            }
                                          }}
                                          inputProps={{ min: 0 }}
                                          defaultValue={0}
                                          onChange={(event) => {
                                            let newValue = event.target.value
                                            if (newValue !== '') {
                                              setSelectedProducts(
                                                (pv) => [
                                                  ...pv.map((p) => {
                                                    if ( p.indexId === data.indexId ) {
                                                      return {
                                                        ...p,
                                                        product_price:newValue,
                                                        price:newValue,
                                                      }
                                                    } else {
                                                      return p
                                                    }
                                                  }),
                                                ],
                                              )
                                              data['product_price'] = newValue || 0
                                              data['price'] = newValue || 0
                                              // dataPrice = newValue || 0
                                            }
                                          }}
                                          // error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                          // helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                        /> :
                                        dataPrice?.toFixed(2))
                                      : null}</TableCell>
                                      <TableCell>{hasMarkupPermission ? (data.SPMA || null) : null}</TableCell>
                                      {/* <TableCell>{hasMarkupPermission ? Number(markupData).toFixed(2) : null}</TableCell> */}
                                     
                                      {/* <TableCell> */}
                                        {/* previos */}
                                            {/* {(hasMarkupPermission && markupType == 1) ? data.wholesaleMarkup?.toFixed(2)
                                              : (hasMarkupPermission && markupType == 2) ? data.pf_retail_markup?.toFixed(2)
                                                : null} */}

                                                {/* //old */}
                                            {/* {(hasMarkupPermission && markupType == 1) ? data.wholesaleMarkup?.toFixed(2)
                                              : (hasMarkupPermission && markupType == 2) ? data.retailMarkup?.toFixed(2)
                                                : null} */}
                                          {/* </TableCell> */}
                                      {/* <TableCell>{shortUnitName}</TableCell> */}
                                      <TableCell>
                                        <TextField
                                         type="number"
                                         sx={{width:85}}
                                         onKeyPress={(event) => {
                                          if (event.key === '0' && event.target.value === '') {
                                            event.preventDefault();
                                          }
                                          if (event.key === '.' ) {
                                            event.preventDefault();
                                          }
                                        }}
                                          fullWidth                                                                                
                                          label={shortUnitName}
                                          disabled={(data?.fromMapServices == true) ? true : shortUnitName == 'Unit' ? true : false}
                                          // {...getFieldProps(quantityFieldName)}
                                          defaultValue={data.quantity?data.quantity:1}
                                          value={data.quantity?data.quantity:1}
                                          
                                          onChange={(event) => {
                                           
                                            let newValue = event.target.value;
                                            if (newValue.startsWith('-')) {
                                              newValue = newValue.slice(1);
                                            }
                                            newValue = newValue.replace(/[^0-9]/g, '');
                                            if (newValue === '' || Number(newValue) <= 0) {
                                              newValue = 1;
                                            }
                                            else{
                                              newValue = Number(newValue);
                                            }
                                           
                                            setSelectedProducts(pv=>([...pv.map(p=>{
                                              // if(p.id===data.id){
                                              if (p.indexId === data.indexId) {
                                                let arrayAddr
                                                     
                                                const commonAddresses = (data?.allLocationSame && pv.length > 0) ? p.location[0] : null;
                                               
                                                if(data?.allLocationSame && commonAddresses){
                                                  arrayAddr = Array((newValue)).fill().map((_, i) => ({
                                                    ...commonAddresses,
                                                    id: i
                                                  }));
                                                
                                                }
                                                else{
                                                  arrayAddr = [...p.location]
                                                  console.log("Hifiiiiiiiiiiiiii Aff",arrayAddr);
                                                  if(p.location.length<=newValue){
                                                    arrayAddr.push(...[...Array((newValue)-p.location.length)].map((a,i)=>{
                                                      return {
                                                        id: p.location.length + i,
                                                        address: '',
                                                        city: '',
                                                        state: '',
                                                        zip: '',
                                                      }
                                                    }))
                                                  }else{
                                                    arrayAddr = arrayAddr.slice(0,-(p.location.length-(newValue)));
                                                  }
                                                }
                                               
                                                return {
                                                  ...p,
                                                  quantity:newValue,
                                                  // location: arrayAddr
                                                }
                                              }else{
                                                return p;
                                              }
                                            })]));
                                            console.log("###############SElected services",selectedServices);
                                            setSelectedServices(pv => ([...pv.map(service => {
                                              // if (service.product_id === data.id &&service.prQty) {
                                              if (service.productIndex === data.indexId && service.prQty) {
                                                  return {
                                                      ...service,
                                                      quantity: (service.prQty* (newValue)*(service?.locationQuantity||1)).toFixed(2),
                                                      qtyData: Number(newValue),
                                                  };
                                              } else {
                                                  return {...service,
                                                   
                                                  }
                                              }
                                          })]));
                                            //new chnage on 2-2-24
                                          //   if (newValue === '.') {
                                          //     event.preventDefault();
                                          //     return;
                                          //   }
                                          // if (newValue === '' || newValue <= 0) {
                                          //   event.preventDefault();
                                          //   return;
                                          // }
                                          //   if (newValue.startsWith('-')) {
                                          //     newValue = newValue.slice(1);
                                          //   }
                                            data['quantity'] = newValue || 1;
                                           
                                            setFieldValue(quantityFieldName, newValue);
                                          }}
                                          
                                          error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                          helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <TextField
                                         type="number"
                                         onKeyPress={(event) => {
                                          if (event.key === '0' && event.target.value === '') {
                                            event.preventDefault();
                                          }
                                          if (event.key === '.' ) {
                                            event.preventDefault();
                                          }
                                        }}
                                          fullWidth                                                                                
                                          label={"Qty"}
                                          disabled={(data?.fromMapServices == true) ? true : false}
                                          // {...getFieldProps(quantityFieldName)}
                                          defaultValue={data.locationQuantity?data.locationQuantity:1}
                                          value={data.locationQuantity?data.locationQuantity:1}
                                          onChange={(event) => {
                                           
                                            let newValue = event.target.value;
                                            if (newValue.startsWith('-')) {
                                              newValue = newValue.slice(1);
                                            }
                                            newValue = newValue.replace(/[^0-9]/g, '');
                                            if (newValue === '' || Number(newValue) <= 0) {
                                              newValue = 1;
                                            }
                                            else{
                                              newValue = Number(newValue);
                                            }
                                           
                                            setSelectedProducts(pv=>([...pv.map(p=>{
                                              // if(p.id===data.id){
                                              if (p.indexId === data.indexId) {
                                                
                                                let arrayAddr
                                                     
                                                const commonAddresses = (data?.allLocationSame && pv.length > 0) ? p.location[0] : null;
                                                console.log("Hifiiiiiiiiiiiiii Aff",commonAddresses);
                                                if(data?.allLocationSame && commonAddresses){
                                                  arrayAddr = Array(Number(newValue)).fill().map((_, i) => ({ //1
                                                    ...commonAddresses,
                                                    id: i
                                                  }));
                                                
                                                }
                                                else{
                                                  arrayAddr = [...p.location]
                                                  console.log("Hifiiiiiiiiiiiiii Aff",arrayAddr);
                                                  if(p.location.length<=Number(newValue)){
                                                    arrayAddr.push(...[...Array(Number(newValue)-p.location.length)].map((a,i)=>{
                                                      return {
                                                        id: p.location.length + i,
                                                        address: '',
                                                        city: '',
                                                        state: '',
                                                        zip: '',
                                                      }
                                                    }))
                                                  }
                                                  else if (p.location.length > Number(newValue)) {
                                                    arrayAddr = arrayAddr.slice(0, Number(newValue));
                                                  }
                                                  else{
                                                    arrayAddr = arrayAddr.slice(0,-(p.location.length-Number(newValue)));
                                                  }
                                                }
                                               
                                                return {
                                                  ...p,
                                                  // quantity:newValue,
                                                  locationQuantity:newValue,
                                                  location: arrayAddr
                                                }
                                              }else{
                                                return p;
                                              }
                                            })]));
                                            setSelectedServices(pv => ([...pv.map(service => {
                                              // if (service.product_id === data.id &&service.prQty) {
                                              if (service.productIndex === data.indexId && service.prQty) {
                                                console.log("############service.qtyData",service.qtyData);
                                                console.log("############service.prQty",service.prQty);
                                                console.log("############service.newValue",newValue);
                                                  return {
                                                      ...service,
                                                      quantity: (newValue*(service.qtyData||1)*service.prQty).toFixed(2),
                                                      locationQuantity:newValue,
                                                  };
                                              } else {
                                                  return {...service,
                                                   
                                                  }
                                              }
                                          })]));
                                          }}
                                          
                                          error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                          helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <IconButton
                                          onClick={() => {
                                            setLocationStates({ ...locationStates, [data.indexId]: true });
                                          }}
                                        >
                                          <LocationOn sx={{ color: data.location.some(obj =>
                                            [obj.address, obj.city, obj.state, obj.zip].some(value => (value === '' || value === null))
                                          ) ? '' : theme.palette.primary.main }} />
                                        </IconButton>
                                      </TableCell>
                                      {/* <TableCell>{data.product_id||data.id}</TableCell> */}

                                      <TableCell>
                                      <Autocomplete
  sx={{ width: 70 }}
  id="combo-box-demo"
  disableClearable
  options={marketingAdjustmentType || []}
  value={marketingAdjustmentType?.find((type) => type.id === data.markAdjType)} // Use value to set the correct selected option
  onChange={(event, newValue) => {
    setSelectedProducts((prevProducts) => {
      return prevProducts.map((product) => {
        if (product.indexId === data.indexId) {
          return {
            ...product,
            markAdjType: newValue?.id, // Set adjustment type or default to the first option
            // adjustment: 0,
          };
        }
        return product;
      });
    });
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      required
      error={Boolean(touched.marketingAdjType && errors.marketingAdjType)}
      helperText={touched.marketingAdjType && errors.marketingAdjType}
      label="Select type"
    />
  )}
  getOptionLabel={(option) => (option.name ? option.name : '')}
/>

                                          </TableCell>


                                      <TableCell>
                                        {/* <TextField
                                          type="number"
                                          label="Discount"
                                          value={Number(data.discount)}
                                          // {...getFieldProps(discountFieldName)}
                                          defaultValue={data.discount?data.discount:0}
                                          onChange={(event) => {
                                            let newValue = event.target.value;
                                            if (newValue.startsWith('-')) {
                                              newValue = newValue.slice(1);
                                            }
                                            if(markupType == 1){
                                              if (Number(newValue) > maxDiscount_wholesale) {
                                                newValue = maxDiscount_wholesale.toFixed(2);
                                              }
                                            }else if(markupType == 2){
                                              if (Number(newValue) > maxDiscount_retail) {
                                                newValue = maxDiscount_retail.toFixed(2);
                                              }
                                            }else{
                                              newValue = 0;
                                            }
                                            if (newValue === '-0.00') {
                                              newValue = 0;
                                            }
                                            setRefreshData(!refreshData)
                                            data['discount'] = newValue
                                            setFieldValue(discountFieldName, newValue);
                                          }}
                                          error={Boolean(touched[discountFieldName] && errors[discountFieldName])}
                                          helperText={touched[discountFieldName] && errors[discountFieldName]}
                                          inputProps={{ max: markupType == 1 ? maxDiscount_wholesale : markupType == 2 ? maxDiscount_retail : 0, min: 0 }}
                                        /> */}

                                        


                                         <TextField
                                            type="number"
                                            // label="(%)"
                                            label={
                                              data.markAdjType === 2 ? "$" : data.markAdjType === 3 ? "¢" : "%"
                                            }
                                            value={Number(data.adjustment) || null}
                                            // defaultValue={data?.adjustment ? Number(data?.adjustment) : 0}
                                            onChange={(event) => {
                                              let newValue = event.target.value;
                                              // if (newValue.startsWith('-')) {
                                              //   newValue = newValue.slice(1);
                                              // }
                                              // if (newValue === '-0.00') {
                                              //   newValue = 0;
                                              // }
                                                  // Allow only two decimal places
                                                  if (newValue.includes('.')) {
                                                    const parts = newValue.split('.');
                                                    if (parts[1].length > 2) {
                                                      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
                                                    }
                                                  }
                                              setRefreshData(!refreshData)
                                              data['adjustment'] = newValue
                                              setFieldValue(adjustmentFieldName, newValue);
                                            }}
                                            onKeyDown={(event) => {
                                              if (event.key === '-' || event.key === '+') {
                                                event.preventDefault();
                                              }
                                            }}
                                            error={Boolean(touched[adjustmentFieldName] && errors[adjustmentFieldName])}
                                            helperText={touched[adjustmentFieldName] && errors[adjustmentFieldName]}
                                          />
                                      </TableCell>
                                      <TableCell>
                                          {rowTotal.toFixed(2)}
                                      </TableCell>
                                      <TableCell >
                                        {(data?.fromMapServices == true) ? '' : 
                                          <IconButton onClick={() => handleDeleteProduct(data.id||data.product_id, data.indexId)}>
                                            <Delete />
                                          </IconButton>
                                        }
                                      </TableCell>
                                    </TableRow>
                                    <Modal
                                      open={locationStates[data.indexId]}
                                      handleClose={() => {
                                        setLocationStates({ ...locationStates, [data.indexId]: false });
                                      }}
                                    >
                                       {((data?.fromMapServices != true) 
                                      //  && index === 0
                                      ) && <FormControlLabel
                                                  control={
                                                    <Switch
                                                      checked={data?.allLocationSame}
                                                      onChange={()=>handleAllLocationSameChange(index)}
                                                    />
                                                  }
                                                  label="All Locations Same"
                                                />}
                                        <Grid
                                          container
                                          spacing={3}
                                          sx={{ justifyContent: 'center', width: '100%' }}
                                          >
                                          {data.location.map((addr, i) => (
                                          <Grid item xs={12}>
                                            <Typography variant="h5" sx={{ mb: 2 }}>
                                              Location {data?.fromMapServices == true ? i+1 : i+1}
                                            </Typography>

                                            <Stack
                                              direction={{ xs: 'column', sm: 'row' }}
                                              spacing={{ xs: 3, sm: 2 }}
                                              sx={{ mb: 2 }}
                                            >
                                              <TextField
                                                fullWidth
                                                label="Address"
                                                size="small"
                                                value={data.location[i].address || ''}
                                                disabled={(data?.fromMapServices == true && data.location[i].address) ? true : (i !== 0 && data?.allLocationSame)?true:false}
                                                // onChange={(event)=>{
                                                //   let arrayAddr = [...data.location]
                                                //   arrayAddr[i].address = event.target.value;
                                                //   setSelectedProducts(pv=>([...pv.map(p=>{
                                                //     // if(p.id===data.id){
                                                //     if (p.indexId === data.indexId) {
                                                //       return {
                                                //         ...p,
                                                //         location: arrayAddr
                                                //       }
                                                //     }else{
                                                //       return p;
                                                //     }
                                                //   })]))
                                                // }}
                                                onChange={(event) => {
                                                  setSelectedProducts((prevProducts) =>
                                                    prevProducts.map((p) => {
                                                      if (p.indexId === data.indexId) {
                                                        let updatedLocations = [...p.location];
                                                        if (p.allLocationSame) {
                                                          updatedLocations = updatedLocations.map(() => ({
                                                            ...updatedLocations[0], 
                                                            address: event.target.value, 
                                                          }));
                                                        } else {
                                                          updatedLocations[i] = {
                                                            ...updatedLocations[i], 
                                                            address: event.target.value,
                                                          };
                                                        }
                                                        return {
                                                          ...p,
                                                          location: updatedLocations,
                                                        };
                                                      }
                                                      return p;
                                                    })
                                                  );
                                                }}
                                              />
                                            </Stack>

                                            <Stack
                                              direction={{ xs: 'column', sm: 'row' }}
                                              spacing={{ xs: 3, sm: 2 }}
                                              sx={{ mb: 2 }}
                                            >
                                              <TextField
                                                fullWidth
                                                label="City"
                                                size="small"
                                                value={data.location[i].city || ''}
                                                disabled={(data?.fromMapServices == true && data.location[i].city) ? true : (i !== 0 && data?.allLocationSame)?true:false}
                                                // onChange={(event)=>{
                                                //   let arrayAddr = [...data.location]
                                                //   arrayAddr[i].city = event.target.value;
                                                //   setSelectedProducts(pv=>([...pv.map(p=>{
                                                //     // if(p.id===data.id){
                                                //     if (p.indexId === data.indexId) {
                                                //       return {
                                                //         ...p,
                                                //         location: arrayAddr
                                                //       }
                                                //     }else{
                                                //       return p;
                                                //     }
                                                //   })]))
                                                // }}
                                                onChange={(event) => {
                                                  setSelectedProducts((prevProducts) =>
                                                    prevProducts.map((p) => {
                                                      if (p.indexId === data.indexId) {
                                                        let updatedLocations = [...p.location];
                                                        if (p.allLocationSame) {
                                                          updatedLocations = updatedLocations.map(() => ({
                                                            ...updatedLocations[0], 
                                                            city: event.target.value, 
                                                          }));
                                                        } else {
                                                          updatedLocations[i] = {
                                                            ...updatedLocations[i], 
                                                            city: event.target.value,
                                                          };
                                                        }
                                                        return {
                                                          ...p,
                                                          location: updatedLocations,
                                                        };
                                                      }
                                                      return p;
                                                    })
                                                  );
                                                }}
                                              />
                                              <TextField
                                                fullWidth
                                                label="State"
                                                size="small"
                                                value={data.location[i].state || ''}
                                                disabled={(data?.fromMapServices == true && data.location[i].state) ? true : (i !== 0 && data?.allLocationSame)?true:false}
                                                // onChange={(event)=>{
                                                //   let arrayAddr = [...data.location]
                                                //   arrayAddr[i].state = event.target.value;
                                                //   setSelectedProducts(pv=>([...pv.map(p=>{
                                                //     // if(p.id===data.id){
                                                //     if (p.indexId === data.indexId) {
                                                //       return {
                                                //         ...p,
                                                //         location: arrayAddr
                                                //       }
                                                //     }else{
                                                //       return p;
                                                //     }
                                                //   })]))
                                                // }}
                                                onChange={(event) => {
                                                  setSelectedProducts((prevProducts) =>
                                                    prevProducts.map((p) => {
                                                      if (p.indexId === data.indexId) {
                                                        let updatedLocations = [...p.location];
                                                        if (p.allLocationSame) {
                                                          updatedLocations = updatedLocations.map(() => ({
                                                            ...updatedLocations[0], 
                                                            state: event.target.value, 
                                                          }));
                                                        } else {
                                                          updatedLocations[i] = {
                                                            ...updatedLocations[i], 
                                                            state: event.target.value,
                                                          };
                                                        }
                                                        return {
                                                          ...p,
                                                          location: updatedLocations,
                                                        };
                                                      }
                                                      return p;
                                                    })
                                                  );
                                                }}
                                              />
                                              <TextField
                                                fullWidth
                                                label="Zip / Postal Code"
                                                size="small"
                                                value={data.location[i].zip || ''}
                                                disabled={(data?.fromMapServices == true && data.location[i].zip) ? true : (i !== 0 && data?.allLocationSame)?true:false}
                                                // onChange={(event)=>{
                                                //   let arrayAddr = [...data.location]
                                                //   arrayAddr[i].zip = event.target.value;
                                                //   setSelectedProducts(pv=>([...pv.map(p=>{
                                                //     // if(p.id===data.id){
                                                //     if (p.indexId === data.indexId) {
                                                //       return {
                                                //         ...p,
                                                //         location: arrayAddr
                                                //       }
                                                //     }else{
                                                //       return p;
                                                //     }
                                                //   })]))
                                                // }}
                                                onChange={(event) => {
                                                  setSelectedProducts((prevProducts) =>
                                                    prevProducts.map((p) => {
                                                      if (p.indexId === data.indexId) {
                                                        let updatedLocations = [...p.location];
                                                        if (p.allLocationSame) {
                                                          updatedLocations = updatedLocations.map(() => ({
                                                            ...updatedLocations[0], 
                                                            zip: event.target.value, 
                                                          }));
                                                        } else {
                                                          updatedLocations[i] = {
                                                            ...updatedLocations[i], 
                                                            zip: event.target.value,
                                                          };
                                                        }
                                                        return {
                                                          ...p,
                                                          location: updatedLocations,
                                                        };
                                                      }
                                                      return p;
                                                    })
                                                  );
                                                }}
                                              />
                                            </Stack>
                                          </Grid>
                                            ))}
                                        </Grid>

                                        <Grid container justifyContent="center" alignItems="center">
                                          <Grid item xs={12} md={4}>
                                          {(data?.fromMapServices == true) ? true : // (i !== 0 && data?.allLocationSame) ? true : 
                                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 1 }}>
                                              <LoadingButton variant="contained" onClick={() => {
                                                setIsSaving(true)
                                                setTimeout(() => {
                                                  setIsSaving(false)
                                                  setLocationStates(false)
                                                },100)
                                              }} loading={isSaving}>
                                                {' Save '}
                                              </LoadingButton>
                                            </Box>}
                                          </Grid>
                                        </Grid> 
                                  </Modal>
                                  <Modal
                                        open={openProductNote[data.indexId] || false}
                                        handleClose={() => handleCloseNote(data.indexId)}
                                      >
                                        <Grid
                                          container
                                          spacing={3}
                                          sx={{ justifyContent: 'center', width: '100%' }}
                                        >
                                            <Grid item xs={12}>
                                              <Typography variant="h5" sx={{ mb: 2 }}>
                                                Note
                                              </Typography>

                                              <Stack
                                                direction={{ xs: 'column', sm: 'row' }}
                                                spacing={{ xs: 3, sm: 2 }}
                                                sx={{ mb: 2 }}
                                              >
                                                <TextField
                                                  fullWidth
                                                  multiline
                                                  rows={4}
                                                  size="medium"
                                                  value={currentNote}
                                                  onChange={handleNoteChange}
                                                  inputProps={{ maxLength: MAX_CHARACTERS }}
                                                  helperText={`${currentNote.length}/${MAX_CHARACTERS} characters`}
                                                />
                                              </Stack>
                                            </Grid>
                                        </Grid>

                                        <Grid container justifyContent="center" alignItems="center">
                                          <Grid item xs={12} md={4}>
                                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 1 }}>
                                              <LoadingButton
                                                variant="contained"
                                                onClick={() => handleSaveNote(data.indexId)}
                                                loading={isSavingNote}>
                                                Save
                                              </LoadingButton>
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </Modal>
                                  </>
                                  );
                                })} 
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Scrollbar>
                      <Stack sx={{ justifyContent: 'space-between', alignItems: 'center', display:'flex', flexDirection:'row' }}>
                        <Button
                          variant="outlined"
                          startIcon={
                            <Stack>
                              {' '}
                              <Add />{' '}
                            </Stack>
                          }
                          onClick={() => {
                            addNewProductRow()
                            // setMarkupType(1)
                          }}
                        >
                          Add Product Row
                        </Button>
                        <Typography variant="h6">
                          Total Additional Product Cost:{' '}
                          {selectedProductsAdditionalCost}
                        </Typography>
                      </Stack>
                      <Divider />
                      <Stack direction='row'>
                        <LabelStyleHead>Services</LabelStyleHead>
                        {showServices ? <Remove sx={{color:theme.palette.primary.main}} onClick={(e)=>{setShowServices(false)}} /> : <Add sx={{color:theme.palette.primary.main}} onClick={(e)=>{setShowServices(true)}} />}
                      </Stack>
                      {showServices && <>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <Autocomplete
                          sx={{ width: 400 }}
                          multiple
                          id="combo-box-demo"
                          options={
                            filteredServices?.filter(item => 
                              item.status === 1 && 
                              !additionalSelectedServices.some(selectedService => 
                                selectedService?.id === item?.id || selectedService?.labour_cost_service_id === item?.id
                              )
                            ) || []
                          }
                          value={additionalSelectedServices?.filter(item=>!item.isAdditionalProduct)}
                          // value={values.services}
                          onChange={(event, newValue) => {
                            setFieldValue('services', newValue);
                            // setAdditionalSelectedServices(newValue);
                            setAdditionalSelectedServices((prev) => {
                              const additionals = prev?.filter(item => item.isAdditionalProduct == true || item.isAdditionalProduct == 1);
                              return [...newValue, ...additionals];
                            })
                          }}
                          renderInput={(params) => (
                            <TextField {...params} required 
                            error={Boolean(touched.services && errors.services)}
                            helperText={touched.services && errors.services} 
                            label="Select additional services" />
                          )}
                          getOptionLabel={(option) => option.name ? option.name : ''}
                        />
                      </Stack>
                        <LabelStyle>Selected services</LabelStyle>
                        <Scrollbar>
                          <TableContainer sx={{ minWidth: 500 }}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  {VALUE_SERVICES?.map((val) => (
                                    <TableCell>{val.value}</TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                            
                              <TableBody>
                                {console.log('selectedServices1----',selectedServices)}
                                {selectedServices?.map((data, index) => {
                                  const quantityFieldName = `service_quantity_${index}`;
                                  const dataCost = data.unit_cost || data.total_cost
                                  const total = dataCost * data.quantity
                                  totalAmount += total
                                  data['totalAmount'] = totalAmount?.toFixed(2)
                                  const markup = dataCost / total
                                  data['markup'] = markup?.toFixed(2)
                                  totalServiceMarkup += markup
                                  data['total'] = total?.toFixed(2)
                                  data['totalServiceMarkup'] = totalServiceMarkup?.toFixed(2)
                                  let originalMarkup=data.originalMarkup||0
                                  originaltotalServiceMarkup+=originalMarkup
                                  // values['totalServiceMarkup'] = totalServiceMarkup?.toFixed(2)
                                  console.log("############WWWWW",originalMarkup);

                                  let qtyData = data.qtyData || 1
                                  console.log("############qtyData", qtyData);
                                  const previousQty = data.prQty*qtyData * (data.locationQuantity||1);
                                  const newQty = (data.prQty * qtyData * (data.locationQuantity || 1))?.toFixed(2)
                                  console.log("############newQty", newQty);
                                  const quantityDifference = Number(data.quantity) - newQty;
                                  data['quantityDifference'] = quantityDifference
                                  return (
                                    <>
                                      <TableRow key={index}>
                                      <TableCell>#{data?.productIndex}</TableCell>
                                      <TableCell>{data.name}</TableCell>
                                      <TableCell>{originalMarkup?.toFixed(2)}</TableCell>
                                      <TableCell>{data.cost}</TableCell>
                                      <TableCell>{dataCost}</TableCell>
                                      <TableCell>
                                      <TextField
                                        type="number"
                                        // sx={{ width: 400 }}                                                                                
                                        label={'Hours'}
                                        disabled={data?.fromMapServices ? true : false}
                                        // {...getFieldProps(quantityFieldName)}
                                        value={Number(data.quantity)}
                                        defaultValue={data.quantity ? Number(data.quantity) : 1}
                                        onChange={(event) => {
                                          let newValue = event.target.value;
                                          const newData = { ...data, quantity: newValue || 1 };
                                          if (newValue.startsWith('-')) {
                                            newValue = newValue.slice(1);
                                          }
                                          if (newValue < previousQty) {
                                            setShowWarning(prev => {
                                              const newWarnings = [...prev];
                                              newWarnings[index] = true;
                                              return newWarnings;
                                            });
                                            console.log('data?.prQty---', data?.prQty);
                                            data['quantity'] = previousQty;
                                            setFieldValue(quantityFieldName, previousQty);
                                          } else {
                                            setShowWarning(prev => {
                                              const newWarnings = [...prev];
                                              newWarnings[index] = false;
                                              return newWarnings;
                                            });
                                            data['quantity'] = newValue || 1;
                                            setFieldValue(quantityFieldName, newValue);
                                          }
                                          
                                          const updatedServices = [...selectedServices];
                                        updatedServices[index] = newData;
                                        updatedServices[index] = newData;
                                       
                                          updatedServices[index] = newData;
                                       
                                          setSelectedServices(updatedServices);
                                        }}
                                        inputProps={{ min: data.prQty }}
                                        error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                        helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                      />
                                      {showWarning[index] && (
                                        <Typography color="error" variant='body2'>
                                          Cannot reduce the added hours in the product formula. If you need to reduce, change it in the product formula.
                                        </Typography>
                                      )}
                                      </TableCell>
                                      <TableCell>{(data.cost*quantityDifference?.toFixed(2))?.toFixed(2)}</TableCell>
                                      <TableCell>{(data.unit_cost * quantityDifference?.toFixed(2))?.toFixed(2)}</TableCell>
                                      <TableCell>{total?.toFixed(2)}</TableCell>
                                      <TableCell></TableCell>
                                      {/* <TableCell align='right'>
                                        <IconButton onClick={() => handleDeleteService(data.id)}>
                                          <Delete />
                                        </IconButton>
                                      </TableCell> */}
                                    </TableRow>
                                    </>
                                  );
                                })}
                                {additionalSelectedServices?.map((data, index) => {
                                  const quantityFieldName = `service_quantity_${index}`;
                                  const dataQuantity = data?.quantity ? data?.quantity : 1;
                                  const dataCost = data.unit_cost || data.total_cost
                                  const total = dataCost * dataQuantity
                                  const markup = dataCost / Number(total)
                                  data['markup'] = markup ? markup?.toFixed(2) : 0.00
                                  data['quantity'] = dataQuantity
                                  totalServiceMarkup += markup
                                  data['total'] = total ? total?.toFixed(2) : 0.00
                                  data['totalServiceMarkup'] = totalServiceMarkup?.toFixed(2)
                                  let originalMarkup=data.originalMarkup||0
                                    originaltotalServiceMarkup+=originalMarkup
                                  // values['totalServiceMarkup'] = totalServiceMarkup?.toFixed(2)
                                  console.log('total---',total);
                                  console.log('data---',data);
                                  const quantityDifference = Number(data.quantity)
                                  data['quantityDifference'] = quantityDifference
                                  return (
                                    <>
                                      <TableRow key={index}>
                                      <TableCell></TableCell>
                                      <TableCell>{ data?.isAdditionalProduct ?
                                          <TextField
                                            required
                                            fullWidth
                                            type="text"
                                            label={'Service Name'}
                                            sx={{ width: 85 }}
                                            value={data.name}
                                            onChange={(event) => {
                                              let newValue = event.target.value

                                              // Update the selectedProducts state
                                              setAdditionalSelectedServices(
                                                (prevProducts) =>
                                                  prevProducts.map((service) => {
                                                      if ( service.newIndexId === data.newIndexId ) {
                                                        return {
                                                          ...service,
                                                          name: newValue,
                                                        }
                                                      } else {
                                                        return service
                                                      }
                                                    },
                                                  ),
                                              )
                                              data['name'] = newValue || ''
                                              if (isSubmitted)
                                                setIsSubmitted(false)
                                            }}
                                            error={
                                              isSubmitted && data?.name?.trim() === ''
                                            }
                                            helperText={
                                              isSubmitted && data?.name?.trim() === '' ? 'Service Name is required' : ''
                                            }
                                          />
                                          : data.name}</TableCell>
                                      <TableCell>{originalMarkup ? originalMarkup?.toFixed(2) : 0.00}</TableCell>
                                      <TableCell>{data.cost}</TableCell>
                                      <TableCell>{
                                        data?.isAdditionalProduct ?
                                          <TextField
                                            required
                                            fullWidth
                                            type="number"
                                            label={'Hourly Cost'}
                                            disabled={values.project_category?.name == 'Time & Material' ? true : false}
                                            sx={{ width: 85 }}
                                            value={dataCost}
                                            // onWheel={(e) => e.target.blur()}
                                            onKeyPress={(event) => {
                                              // if (event.key === '0' && event.target.value === '') {
                                              //   event.preventDefault();
                                              // }
                                              if (event.key === '-') {
                                                event.preventDefault();
                                              }
                                              if (event.key === 'e') {
                                                event.preventDefault();
                                              }
                                            }}
                                            inputProps={{ min: 0 }}
                                            defaultValue={0}
                                            onChange={(event) => {
                                              let newValue = event.target.value
                                              if (newValue !== '') {
                                                setAdditionalSelectedServices((pv) => [
                                                    ...pv.map((p) => {
                                                      if ( p.newIndexId === data.newIndexId ) {
                                                        return {
                                                          ...p,
                                                          total_cost: newValue,
                                                          unit_cost: newValue,
                                                        }
                                                      } else {
                                                        return p
                                                      }
                                                    }),
                                                  ],
                                                )
                                                data['total_cost'] =  newValue || 0
                                                data['unit_cost'] =  newValue || 0
                                              }
                                            }}
                                            // error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                            // helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                          /> : dataCost}</TableCell>
                                      <TableCell>
                                        <TextField
                                          type="number"
                                          // sx={{ width: 400 }}                                                                                
                                          label={'Hours'}
                                          {...getFieldProps(quantityFieldName)}
                                          value={Number(data?.quantity) || 1}
                                          defaultValue={1}
                                          onChange={(event) => {
                                            let newValue = event.target.value;
                                            console.log('newValue-----',newValue);
                                            if (newValue.startsWith('-')) {
                                              newValue = newValue.slice(1);
                                            }
                                            // data['quantity'] = newValue || 1;
                                            const newData = { ...data, quantity: newValue || 1 };
                                            const updatedServices = [...additionalSelectedServices];
                                            updatedServices[index] = newData;
                                            setAdditionalSelectedServices(updatedServices);
                                            setFieldValue(quantityFieldName, newValue);
                                          }}
                                          
                                          error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                          helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                        />
                                      </TableCell>
                                      <TableCell>{data?.isAdditionalProduct ? 'N/A' : (data.cost*quantityDifference?.toFixed(2))?.toFixed(2)}</TableCell>
                                      <TableCell>{total ? total?.toFixed(2) : 0.00}</TableCell>
                                      <TableCell>{total ? total?.toFixed(2) : 0.00}</TableCell>
                                      {/* <TableCell></TableCell> */}
                                      <TableCell align='right'>
                                        <IconButton onClick={() => handleDeleteService(data.id, data.newIndexId)}>
                                          <Delete />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                    </>
                                  );
                                })}
                                {/* <TableRow>
                                    <TableCell colSpan={8}>
                                      <Typography variant="h6" align="right">
                                        Total Additional Cost: {totalProductLabourCost.toFixed(2)}
                                      </Typography>
                                    </TableCell>
                                  </TableRow> */}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Scrollbar>
                        <Stack sx={{ justifyContent: 'space-between', alignItems: 'center', display:'flex', flexDirection:'row' }}>
                          <Button
                            variant="outlined"
                            startIcon={
                              <Stack>
                                {' '}
                                <Add />{' '}
                              </Stack>
                            }
                            onClick={() => {
                              addNewServiceRow()
                              // setMarkupType(1)
                            }}
                          >
                            Add Service Row
                          </Button>
                          <Typography variant="h6">
                            Total Additional Service Cost:{' '}
                            {totalProductLabourCost?.toFixed(2)}
                          </Typography>
                        </Stack>
                        {(values?.project_type?.name == 'TS' && values?.project_category?.id == 14) ? '' :
                          <>
                          <LabelStyleHead>Proposals</LabelStyleHead>
                          <Autocomplete
                            options={rfps}
                            getOptionLabel={(option) => option.name}
                            value={values.rfp}
                            onChange={(event, value) => {handleRfpSelect(event, value)
                              setFieldValue('rfp',value)
                            }}
                            renderInput={(params) => <TextField {...params} label="Select Proposal" />}
                          />
                          {/* Modal for RFP Selection */}
                          <Modal open={openModal} handleClose={closeModal}>
                            <Box sx={{ position: 'relative', p: 3, bgcolor: 'background.paper', boxShadow: 24 }}>

                              <Tabs value={tabIndex}
                                variant="fullWidth"
                                sx={{
                                  marginBottom: 4

                                }}
                                onChange={handleTabChange}>
                                <Tab label="Services" sx={{ width: '50%', minWidth: 0 }} />
                                <Tab label="Products" sx={{ width: '50%', minWidth: 0 }} />
                              </Tabs>
                              {/* {tabIndex === 0 && (
                                <div>
                                  {rfpServices.map((service) => (
                                    <Box key={service.id} sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                      <Typography>{service.name}</Typography>
                                      <Typography>${service.sellingPrice}</Typography>
                                      <Button onClick={() => addToCart(service)}>Add</Button>
                                    </Box>
                                  ))}
                                </div>
                              )}
                              {tabIndex === 1 && (
                                <div>
                                  {rfpProducts.map((product) => (
                                    <Box key={product.id} sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                      <Typography>{product.product_name}</Typography>
                                      <Typography>${product.product_price}</Typography>
                                      <Button onClick={() => addToCart(product)}>Add</Button>
                                    </Box>
                                  ))}
                                </div>
                              )} */}
                              {tabIndex === 0 && (
                                  <TableContainer sx={{ minWidth: 500 }}>
                                  <Table>
                                  <TableHead>
                                    <TableCell align='center'>Name</TableCell>
                                    <TableCell align='center'>Unit Cost</TableCell>
                                    <TableCell align='center'>Hours</TableCell>
                                    <TableCell align='center'>Total Cost</TableCell>
                                    <TableCell align='center'></TableCell>
                                  </TableHead>
                                  {rfpServices.map((service) => (
                                    // <Box key={service.id} sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                      <TableRow key={service.id}>
                                        <TableCell align='center'>{service.name}</TableCell>
                                        <TableCell align='center'>${service.sellingPrice}</TableCell>
                                        <TableCell align='center'>{service.quantity}</TableCell>
                                        <TableCell align='center'>${service.total_cost}</TableCell>
                                        <TableCell align='center'><Button onClick={() => addToCart(service)}>Add</Button></TableCell>
                                      </TableRow>
                                    // </Box>
                                  ))}
                                  </Table>
                                  </TableContainer>
                              )}
                              {tabIndex === 1 && (
                                <TableContainer sx={{ minWidth: 500 }}>
                                  <Table>
                                  <TableHead>
                                    <TableCell align='center'>Name</TableCell>
                                    <TableCell align='center'>Unit Cost</TableCell>
                                    <TableCell align='center'>Unit</TableCell>
                                    <TableCell align='center'>Qty</TableCell>
                                    <TableCell align='center'>Total Cost</TableCell>
                                    <TableCell align='center'></TableCell>
                                  </TableHead>
                                  {rfpProducts.map((product) => {
                                    let unitName = UNITS?.filter((item) => item?.id == product?.unit)
                                    unitName = unitName[0]?.name
                                    let shortUnitName = ''
                                    if (unitName == 'Square feet') {
                                      shortUnitName = 'Sq ft'
                                    } else if (unitName == 'Lineal feet') {
                                      shortUnitName = 'Lin ft'
                                    } else if (unitName == 'Square Inches') {
                                      shortUnitName = 'Sq Inches'
                                    } else if (unitName == 'Unit') {
                                      shortUnitName = 'Unit'
                                    }
                                    return(
                                    // <Box key={product.id} sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                      <TableRow key={product.id}>
                                        <TableCell align='center'>{product.product_name}</TableCell>
                                        <TableCell align='center'>${product.product_price}</TableCell>
                                        <TableCell align='center'>{product.quantity} {shortUnitName}</TableCell>
                                        <TableCell align='center'>{product.location_quantity}</TableCell>
                                        <TableCell align='center'>${(product.location_quantity*product.quantity * ((product.product_price).toFixed(2) || 0)).toFixed(2)}</TableCell>
                                        <TableCell align='center'><Button onClick={() => addToCart(product)}>Add</Button></TableCell>
                                      </TableRow>
                                    // </Box>
                                  )})}
                                  </Table>
                                </TableContainer>
                              )}
                            </Box>
                          </Modal>
                          <Scrollbar>
                            <TableContainer sx={{ minWidth: 500 }}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    {VALUE_RFP_Product?.map((val) => (

                                      <TableCell key={val.id}>{val.value}</TableCell>

                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {cart?.map((data, index) => {
                                    let unitName = UNITS?.filter((item) => item?.id == data?.unit)
                                    unitName = unitName[0]?.name
                                    let shortUnitName = ''
                                    if (unitName == 'Square feet') {
                                      shortUnitName = 'Sq ft'
                                    } else if (unitName == 'Lineal feet') {
                                      shortUnitName = 'Lin ft'
                                    } else if (unitName == 'Square Inches') {
                                      shortUnitName = 'Sq Inches'
                                    } else if (unitName == 'Unit') {
                                      shortUnitName = 'Unit'
                                    }
                                    let markup = markupType ? markupType === 1 ? data.WholesaleMarkup || data.product_markup : data.RetailMarkup || data.product_markup : 0
                                    data.product_markup = markup
                                    return (
                                      <>
                                        <TableRow key={data.id}>
                                          <TableCell>{data.name || data.product_name}</TableCell>
                                          {/* <TableCell>{invoiceAmount}</TableCell> */}
                                          <TableCell>
                                            {markup?.toFixed(2) || 0.00}
                                          </TableCell>
                                          <TableCell>
                                            {data.product_price?.toFixed(2) || 0}
                                          </TableCell>
                                          <TableCell>
                                            {(data.product_price + (data.product_price * markup / 100)).toFixed(2) || 0.00}
                                          </TableCell>
                                          <TableCell>
                                            {data.quantity} {shortUnitName}
                                          </TableCell>
                                          <TableCell>
                                            {data.location_quantity}
                                          </TableCell>
                                         
                                          <TableCell>
                                            {(data.product_price * data.quantity * data.location_quantity)?.toFixed(2)||0}
                                          </TableCell>
                                          <TableCell>
                                            {(data.location_quantity * data.quantity * ((data.product_price + (data.product_price * markup / 100))?.toFixed(2) || 0)).toFixed(2)}
                                          </TableCell>
                                          <TableCell>
                                            <IconButton
                                              onClick={() => {
                                                setRfpLocationStates({ ...rfpLocationStates, [data.product_id]: true });
                                              }}
                                            >
                                              <LocationOn sx={{
                                                color: data && data.location && data.location.some(obj =>
                                                  [obj.address, obj.city, obj.state, obj.zip].some(value => (value === '' || value === null))
                                                ) ? '' : theme.palette.primary.main
                                              }} />
                                            </IconButton>
                                          </TableCell>
                                          <TableCell >
                                            <IconButton onClick={() => handleDeleteRFPProduct(data.product_id)}>
                                              <Delete />
                                            </IconButton>
                                          </TableCell>
                                        </TableRow>
                                        <Modal
                                          open={rfpLocationStates[data.product_id]}
                                          handleClose={() => {
                                            setRfpLocationStates({ ...rfpLocationStates, [data.product_id]: false });
                                          }}
                                        >
                                          {/* {index === 0 &&  */}
                                          <FormControlLabel
                                            control={
                                              <Switch
                                                checked={data?.allRfpProductsLocationSame}
                                                onChange={()=>handleAllRfpProductsLocationSameChange(index) }
                                              />
                                            }
                                            label="All Locations Same"
                                          />
                                          {/* } */}

                                          <Grid
                                            container
                                            spacing={3}
                                            sx={{ justifyContent: 'center', width: '100%' }}
                                          >

                                            {data?.location?.map((addr, i) => (
                                              <Grid item xs={12}>
                                                {/* Side Heading */}
                                                <Typography variant="h5" sx={{ mb: 2 }}>
                                                  Location {i+1}
                                                </Typography>

                                                {/* Address Fields */}

                                                <Stack
                                                  direction={{ xs: 'column', sm: 'row' }}
                                                  spacing={{ xs: 3, sm: 2 }}
                                                  sx={{ mb: 2 }}
                                                >
                                                  <TextField
                                                    fullWidth
                                                    label="Address"
                                                    size="small"
                                                    value={data.location[i].address || ''}

                                                    // onChange={(event) => {
                                                    //   let arrayAddr = [...data.location]
                                                    //   arrayAddr[i].address = event.target.value;
                                                    //   setCart(pv => ([...pv.map(p => {


                                                    //     if (p.product_id === data.product_id) {
                                                    //       return {
                                                    //         ...p,
                                                    //         addresses: arrayAddr
                                                    //       }
                                                    //     }
                                                    //     else {
                                                    //       return {
                                                    //         ...p
                                                    //       }
                                                    //     }

                                                    //   })]))
                                                    // }}
                                                    disabled={
                                                      i !== 0 &&
                                                      data?.allRfpProductsLocationSame
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(event) => {
                                                      setCart((prevProducts) =>
                                                        prevProducts.map((p) => {
                                                          if (p.product_id === data.product_id) {
                                                            let updatedAddresses = [...p.location];
                                                            if (p.allRfpProductsLocationSame) {
                                                              updatedAddresses = updatedAddresses.map(() => ({
                                                                ...updatedAddresses[0],
                                                                address: event.target.value,
                                                              }));
                                                            } else {
                                                              updatedAddresses[i] = {
                                                                ...updatedAddresses[i],
                                                                address: event.target.value,
                                                              };
                                                            }
                                                            return {
                                                              ...p,
                                                              location: updatedAddresses,
                                                            };
                                                          }
                                                          return p;
                                                        })
                                                      );
                                                    }}
                                                  />
                                                </Stack>

                                                {/* City, State, Zip Fields */}
                                                <Stack
                                                  direction={{ xs: 'column', sm: 'row' }}
                                                  spacing={{ xs: 3, sm: 2 }}
                                                  sx={{ mb: 2 }}
                                                >
                                                  <TextField
                                                    fullWidth
                                                    label="City"
                                                    size="small"
                                                    value={data.location[i].city || ''}

                                                    // onChange={(event) => {
                                                    //   let arrayAddr = [...data.location]
                                                    //   arrayAddr[i].city = event.target.value;
                                                    //   setCart(pv => ([...pv.map(p => {


                                                    //     if (p.product_id === data.product_id) {
                                                    //       return {
                                                    //         ...p,
                                                    //         addresses: arrayAddr
                                                    //       }
                                                    //     }
                                                    //     else {
                                                    //       return {
                                                    //         ...p
                                                    //       }
                                                    //     }

                                                    //   })]))
                                                    // }}
                                                    disabled={
                                                      i !== 0 &&
                                                      data?.allRfpProductsLocationSame
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(event) => {
                                                      setCart((prevProducts) =>
                                                        prevProducts.map((p) => {
                                                          if (p.product_id === data.product_id) {
                                                            let updatedAddresses = [...p.location];
                                                            if (p.allRfpProductsLocationSame) {
                                                              updatedAddresses = updatedAddresses.map(() => ({
                                                                ...updatedAddresses[0],
                                                                city: event.target.value,
                                                              }));
                                                            } else {
                                                              updatedAddresses[i] = {
                                                                ...updatedAddresses[i],
                                                                city: event.target.value,
                                                              };
                                                            }
                                                            return {
                                                              ...p,
                                                              location: updatedAddresses,
                                                            };
                                                          }
                                                          return p;
                                                        })
                                                      );
                                                    }}
                                                  />
                                                  <TextField
                                                    fullWidth
                                                    label="State"
                                                    size="small"
                                                    value={data.location[i].state || ''}

                                                    // onChange={(event) => {
                                                    //   let arrayAddr = [...data.location]
                                                    //   arrayAddr[i].state = event.target.value;
                                                    //   setCart(pv => ([...pv.map(p => {

                                                    //     if (p.product_id === data.product_id) {
                                                    //       return {
                                                    //         ...p,
                                                    //         addresses: arrayAddr
                                                    //       }
                                                    //     }
                                                    //     else {
                                                    //       return {
                                                    //         ...p
                                                    //       }
                                                    //     }

                                                    //   })]))
                                                    // }}
                                                    disabled={
                                                      i !== 0 &&
                                                      data?.allRfpProductsLocationSame
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(event) => {
                                                      setCart((prevProducts) =>
                                                        prevProducts.map((p) => {
                                                          if (p.product_id === data.product_id) {
                                                            let updatedAddresses = [...p.location];
                                                            if (p.allRfpProductsLocationSame) {
                                                              updatedAddresses = updatedAddresses.map(() => ({
                                                                ...updatedAddresses[0],
                                                                state: event.target.value,
                                                              }));
                                                            } else {
                                                              updatedAddresses[i] = {
                                                                ...updatedAddresses[i],
                                                                state: event.target.value,
                                                              };
                                                            }
                                                            return {
                                                              ...p,
                                                              location: updatedAddresses,
                                                            };
                                                          }
                                                          return p;
                                                        })
                                                      );
                                                    }}
                                                  />
                                                  <TextField
                                                    fullWidth
                                                    label="Zip / Postal Code"
                                                    size="small"
                                                    value={data.location[i].zip || ''}

                                                    // onChange={(event) => {
                                                    //   let arrayAddr = [...data.location]
                                                    //   arrayAddr[i].zip = event.target.value;
                                                    //   setCart(pv => ([...pv.map(p => {


                                                    //     if (p.product_id === data.product_id) {
                                                    //       return {
                                                    //         ...p,
                                                    //         addresses: arrayAddr
                                                    //       }
                                                    //     }
                                                    //     else {
                                                    //       return {
                                                    //         ...p
                                                    //       }
                                                    //     }

                                                    //   })]))
                                                    // }}
                                                    disabled={
                                                      i !== 0 &&
                                                      data?.allRfpProductsLocationSame
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(event) => {
                                                      setCart((prevProducts) =>
                                                        prevProducts.map((p) => {
                                                          if (p.product_id === data.product_id) {
                                                            let updatedAddresses = [...p.location];
                                                            if (p.allRfpProductsLocationSame) {
                                                              updatedAddresses = updatedAddresses.map(() => ({
                                                                ...updatedAddresses[0],
                                                                zip: event.target.value,
                                                              }));
                                                            } else {
                                                              updatedAddresses[i] = {
                                                                ...updatedAddresses[i],
                                                                zip: event.target.value,
                                                              };
                                                            }
                                                            return {
                                                              ...p,
                                                              location: updatedAddresses,
                                                            };
                                                          }
                                                          return p;
                                                        })
                                                      );
                                                    }}
                                                  />
                                                </Stack>
                                              </Grid>
                                            ))}
                                          </Grid>


                                          <Grid container justifyContent="center" alignItems="center">
                                            <Grid item xs={12} md={4}>
                                              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 1 }}>
                                                <LoadingButton variant="contained" onClick={() => {
                                                  setIsSaving(true)
                                                  setTimeout(() => {
                                                    setIsSaving(false)
                                                    setRfpLocationStates(false)
                                                  }, 100)
                                                }} loading={isSaving}>
                                                  {' Save '}
                                                </LoadingButton>

                                              </Box>
                                            </Grid>
                                          </Grid>
                                        </Modal>
                                      </>
                                    );
                                  })}
                                </TableBody>
                                <h6>Services</h6>
                                <TableBody>
                                  {cartService?.map((data, index) => {
                                    data.sellingPrice = data.sellingPrice || data.unit_cost
                                    data.originalMarkup = data.originalMarkup || data.product_markup

                                    return (
                                      <>
                                        <TableRow key={data.id}>
                                          <TableCell>{data.name || data.product_name}</TableCell>
                                          {/* <TableCell>{invoiceAmount}</TableCell> */}
                                          <TableCell>
                                            {data.originalMarkup?.toFixed(2)}
                                          </TableCell>
                                          <TableCell>
                                            {data.sellingPrice?.toFixed(2)}
                                          </TableCell>
                                          <TableCell>
                                            {((data.sellingPrice + (data.sellingPrice * data.originalMarkup / 100)))?.toFixed(2)}
                                          </TableCell>
                                          <TableCell>
                                            {data.qty} Hours
                                          </TableCell>
                                          <TableCell></TableCell>
                                          <TableCell>
                                            {(data.qty*data.sellingPrice).toFixed(2)||0}
                                          </TableCell>
                                          <TableCell>
                                            {(data.qty * (data.sellingPrice + (data.sellingPrice * data.originalMarkup / 100)))?.toFixed(2)}
                                          </TableCell>
                                          <TableCell>
                                            <IconButton
                                              onClick={() => {
                                                setRfpServiceLocationStates({ ...rfpServiceLocationStates, [data.id]: true });
                                              }}
                                            >
                                              <LocationOn sx={{
                                                color: data && data.location && data.location.some(obj =>
                                                  [obj.address, obj.city, obj.state, obj.zip].some(value => (value === '' || value === null))
                                                ) ? '' : theme.palette.primary.main
                                              }} />
                                            </IconButton>
                                          </TableCell>

                                          <TableCell >
                                            <IconButton onClick={() => handleDeleteRFPService(data.labour_cost_service_id)}>
                                              <Delete />
                                            </IconButton>
                                          </TableCell>
                                        </TableRow>
                                        <Modal
                                          open={rfpServiceLocationStates[data.id]}
                                          handleClose={() => {
                                            setRfpServiceLocationStates({ ...rfpServiceLocationStates, [data.id]: false });
                                          }}
                                        >
                                            {index === 0 && <FormControlLabel
                                          control={
                                            <Switch
                                              checked={allRfpServiceLocationSame}
                                              onChange={handleAllRfpServiceLocationSameChange}
                                            />
                                          }
                                          label="All Locations Same"
                                        />}

                                          <Grid
                                            container
                                            spacing={3}
                                            sx={{ justifyContent: 'center', width: '100%' }}
                                          >

                                            {data?.location?.map((addr, i) => (
                                              <Grid item xs={12}>
                                                {/* Side Heading */}
                                                <Typography variant="h5" sx={{ mb: 2 }}>
                                                  Location
                                                </Typography>

                                                {/* Address Fields */}

                                                <Stack
                                                  direction={{ xs: 'column', sm: 'row' }}
                                                  spacing={{ xs: 3, sm: 2 }}
                                                  sx={{ mb: 2 }}
                                                >
                                                  <TextField
                                                    fullWidth
                                                    label="Address"
                                                    size="small"
                                                    value={data.location[i].address || ''}
                                                    disabled={index !== 0 && allRfpServiceLocationSame ? true : false}
                                                    // onChange={(event) => {
                                                    //   let arrayAddr = [...data.location]
                                                    //   arrayAddr[i].address = event.target.value;
                                                    //   setCartService(pv => ([...pv.map(p => {


                                                    //     if (p.id === data.id) {
                                                    //       return {
                                                    //         ...p,
                                                    //         location: arrayAddr
                                                    //       }
                                                    //     }
                                                    //     else {
                                                    //       return {
                                                    //         ...p
                                                    //       }
                                                    //     }

                                                    //   })]))
                                                    // }}
                                                    onChange={(event) => {
                                                      const updatedValue = event.target.value;
                                                    
                                                      setCartService((prevServices) =>
                                                        prevServices.map((service) => {
                                                          let updatedLocations = [...service.location];
                                                    
                                                          if (allRfpServiceLocationSame) {
                                                            // Apply the new value to all services in cartService
                                                            return {
                                                              ...service,
                                                              location: updatedLocations.map(loc => ({
                                                                ...loc,
                                                                address: updatedValue, // Update address field, same logic applies for others
                                                              })),
                                                            };
                                                          } else {
                                                            // Update only the specific service
                                                            if (service.id === data.id) {
                                                              updatedLocations[i].address = updatedValue;
                                                            }
                                                            return {
                                                              ...service,
                                                              location: updatedLocations,
                                                            };
                                                          }
                                                        })
                                                      );
                                                    }}                                                    
                                                  />
                                                </Stack>

                                                {/* City, State, Zip Fields */}
                                                <Stack
                                                  direction={{ xs: 'column', sm: 'row' }}
                                                  spacing={{ xs: 3, sm: 2 }}
                                                  sx={{ mb: 2 }}
                                                >
                                                  <TextField
                                                    fullWidth
                                                    label="City"
                                                    size="small"
                                                    value={data.location[i].city || ''}
                                                    disabled={index !== 0 && allRfpServiceLocationSame ? true : false}
                                                    // onChange={(event) => {
                                                    //   let arrayAddr = [...data.location]
                                                    //   arrayAddr[i].city = event.target.value;
                                                    //   setCartService(pv => ([...pv.map(p => {


                                                    //     if (p.id === data.id) {
                                                    //       return {
                                                    //         ...p,
                                                    //         location: arrayAddr
                                                    //       }
                                                    //     }
                                                    //     else {
                                                    //       return {
                                                    //         ...p
                                                    //       }
                                                    //     }

                                                    //   })]))
                                                    // }}
                                                    onChange={(event) => {
                                                      const updatedValue = event.target.value;
                                                    
                                                      setCartService((prevServices) =>
                                                        prevServices.map((service) => {
                                                          let updatedLocations = [...service.location];
                                                    
                                                          if (allRfpServiceLocationSame) {
                                                            // Apply the new value to all services in cartService
                                                            return {
                                                              ...service,
                                                              location: updatedLocations.map(loc => ({
                                                                ...loc,
                                                                city: updatedValue, // Update city field, same logic applies for others
                                                              })),
                                                            };
                                                          } else {
                                                            // Update only the specific service
                                                            if (service.id === data.id) {
                                                              updatedLocations[i].city = updatedValue;
                                                            }
                                                            return {
                                                              ...service,
                                                              location: updatedLocations,
                                                            };
                                                          }
                                                        })
                                                      );
                                                    }}                                                    
                                                  />
                                                  <TextField
                                                    fullWidth
                                                    label="State"
                                                    size="small"
                                                    value={data.location[i].state || ''}
                                                    disabled={index !== 0 && allRfpServiceLocationSame ? true : false}
                                                    // onChange={(event) => {
                                                    //   let arrayAddr = [...data.location]
                                                    //   arrayAddr[i].state = event.target.value;
                                                    //   setCartService(pv => ([...pv.map(p => {

                                                    //     if (p.id === data.id) {
                                                    //       return {
                                                    //         ...p,
                                                    //         location: arrayAddr
                                                    //       }
                                                    //     }
                                                    //     else {
                                                    //       return {
                                                    //         ...p
                                                    //       }
                                                    //     }

                                                    //   })]))
                                                    // }}
                                                    onChange={(event) => {
                                                      const updatedValue = event.target.value;
                                                    
                                                      setCartService((prevServices) =>
                                                        prevServices.map((service) => {
                                                          let updatedLocations = [...service.location];
                                                    
                                                          if (allRfpServiceLocationSame) {
                                                            // Apply the new value to all services in cartService
                                                            return {
                                                              ...service,
                                                              location: updatedLocations.map(loc => ({
                                                                ...loc,
                                                                state: updatedValue, // Update state field, same logic applies for others
                                                              })),
                                                            };
                                                          } else {
                                                            // Update only the specific service
                                                            if (service.id === data.id) {
                                                              updatedLocations[i].state = updatedValue;
                                                            }
                                                            return {
                                                              ...service,
                                                              location: updatedLocations,
                                                            };
                                                          }
                                                        })
                                                      );
                                                    }}                                                    
                                                  />
                                                  <TextField
                                                    fullWidth
                                                    label="Zip / Postal Code"
                                                    size="small"
                                                    value={data.location[i].zip || ''}
                                                    disabled={index !== 0 && allRfpServiceLocationSame ? true : false}
                                                    // onChange={(event) => {
                                                    //   let arrayAddr = [...data.location]
                                                    //   arrayAddr[i].zip = event.target.value;
                                                    //   setCartService(pv => ([...pv.map(p => {


                                                    //     if (p.id === data.id) {
                                                    //       return {
                                                    //         ...p,
                                                    //         location: arrayAddr
                                                    //       }
                                                    //     }
                                                    //     else {
                                                    //       return {
                                                    //         ...p
                                                    //       }
                                                    //     }

                                                    //   })]))
                                                    // }}
                                                    onChange={(event) => {
                                                      const updatedValue = event.target.value;
                                                    
                                                      setCartService((prevServices) =>
                                                        prevServices.map((service) => {
                                                          let updatedLocations = [...service.location];
                                                    
                                                          if (allRfpServiceLocationSame) {
                                                            // Apply the new value to all services in cartService
                                                            return {
                                                              ...service,
                                                              location: updatedLocations.map(loc => ({
                                                                ...loc,
                                                                zip: updatedValue, // Update ZIP field, same logic applies for others
                                                              })),
                                                            };
                                                          } else {
                                                            // Update only the specific service
                                                            if (service.id === data.id) {
                                                              updatedLocations[i].zip = updatedValue;
                                                            }
                                                            return {
                                                              ...service,
                                                              location: updatedLocations,
                                                            };
                                                          }
                                                        })
                                                      );
                                                    }}                                                    
                                                  />
                                                </Stack>
                                              </Grid>
                                            ))}
                                          </Grid>

                                          {index !== 0 && allRfpServiceLocationSame ? '' :
                                          <Grid container justifyContent="center" alignItems="center">
                                            <Grid item xs={12} md={4}>
                                              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 1 }}>
                                                <LoadingButton variant="contained" onClick={() => {
                                                  setIsSaving(true)
                                                  setTimeout(() => {
                                                    setIsSaving(false)
                                                    setRfpServiceLocationStates(false)
                                                  }, 100)
                                                }} loading={isSaving}>
                                                  {' Save '}
                                                </LoadingButton>

                                              </Box>
                                            </Grid>
                                          </Grid>}
                                        </Modal>
                                      </>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Scrollbar>
                          </>
                        }
                          <LabelStyleHead>Materials</LabelStyleHead>
                          {/* <Typography></Typography> */}
                          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                            <Autocomplete
                              sx={{ width: 400 }}
                              multiple
                              id="combo-box-demo"
                              // options={materials || []}
                              // options={materials?.filter((option) => !selectedMaterials?.some(selected => selected.product_id === option.id)) || []}
                              options={
                                materials?.filter((option) => {
                                  // Exclude selected materials
                                  const isSelected = selectedMaterials?.some((selected) => selected.product_id === option.id);
                                  const isServiceOnly = values.project_category?.name === 'Service only';
                                  // Filter materials: show only inhouse = 1 unless it's 'Service only'
                                  const shouldInclude = isServiceOnly || option.inhouse === 1;
                                  return !isSelected && shouldInclude;
                                }) || []
                              }
                              value={selectedMaterials?.filter(item => !item?.isAdditionalProduct)}
                              onChange={(event, newValue) => {
                                
                                handleMaterial(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Materials" variant="outlined" />
                              )}
                              getOptionLabel={(option) => option.name ? option.name : option.product_name ? option.product_name : ''}
                              // getOptionLabel={(option) => {
                              //   const name = option.name || option.product_name || '';
                              //   const ownershipLabel = option.inhouse === 1 ? ' (Inhouse)' : ' (Client owned)';
                              //   return name + ownershipLabel;
                              // }}
                            />
                          </Stack>
                          <LabelStyle>Selected Materials</LabelStyle>
                          <Scrollbar>
                            <TableContainer sx={{ minWidth: 500 }}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    {VALUE_MATERIAL?.map((val) => (
                                      val.id === 3 ? (
                                        <Tooltip key={val.id} title="Unit Price + ((Unit Price * Markup)/100)">
                                          <TableCell key={val.id}>{val.value}</TableCell>
                                        </Tooltip>
                                      ) : val.id === 5 ? (
                                        <Tooltip key={val.id} title="Unit Price * Quantity">
                                          <TableCell key={val.id}>{val.value}</TableCell>
                                        </Tooltip>
                                      ) : val.id === 6 ? (
                                        <Tooltip key={val.id} title="Markup Cost * Quantity">
                                          <TableCell key={val.id}>{val.value}</TableCell>
                                        </Tooltip>
                                      ) : <TableCell key={val.id}>{val.value}</TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {selectedMaterials?.map((data, index) => {
                                    console.log("################Materials",data);
                                    let unitName = UNITS?.filter((item) => item?.id == data?.unit)
                                    unitName = unitName[0]?.name
                                    let shortUnitName = ''
                                    if (unitName == 'Square feet') {
                                      shortUnitName = 'Sq ft'
                                    } else if (unitName == 'Lineal feet') {
                                      shortUnitName = 'Lin ft'
                                    } else if (unitName == 'Square Inches') {
                                      shortUnitName = 'Sq Inches'
                                    } else if (unitName == 'Unit') {
                                      shortUnitName = 'Unit'
                                    }
                                    data.price = data.price ? data.price : data.product_price 
                                    data.product_id = data.product_id ? data.product_id : data.id
                                    data.markup = data.markup ? data.markup : data.product_markup
                                    const markUpCost = data.price + ((parseFloat(data.price) * parseFloat(data.markup || 0)) / 100)
                                    data['markUpCost'] = parseFloat(parseFloat(markUpCost).toFixed(2)) || 0;
                                    return (
                                      <>
                                        <TableRow key={data?.newIndexId || index}>
                                         
                                         <TableCell>{data?.isAdditionalProduct ?
                                            <TextField
                                              required
                                              fullWidth
                                              type="text"
                                              label={'Material Name'}
                                              sx={{ width: 85 }}
                                              value={data.name||data.product_name}
                                              onChange={(event) => {
                                                let newValue = event.target.value

                                                // Update the selectedProducts state
                                                setSelectedMaterials(
                                                  (prevProducts) =>
                                                    prevProducts.map((service) => {
                                                        if ( service.newIndexId === data.newIndexId ) {
                                                          return {
                                                            ...service,
                                                            name: newValue,
                                                            product_name: newValue,
                                                          }
                                                        } else {
                                                          return service
                                                        }
                                                      },
                                                    ),
                                                )
                                                data['name'] = newValue || ''
                                                data['product_name'] = newValue || ''
                                                if (isSubmitted)
                                                  setIsSubmitted(false)
                                              }}
                                              error={
                                                isSubmitted && !data.name?.trim() && !data.product_name?.trim()
                                              }
                                              helperText={
                                                isSubmitted && !data.name?.trim() && !data.product_name?.trim()
                                                  ? 'Material Name is required'
                                                  : ''
                                              }                                              
                                            />
                                          : (data.name||data.product_name)}
                                          {data?.isAdditionalProduct ? '' : data.inhouse==1 ? ' (Inhouse)' : ' (Client owned)'}
                                          </TableCell>
                                         <TableCell>{data?.isAdditionalProduct ?
                                            <TextField
                                              required
                                              fullWidth
                                              type="number"
                                              label={'Unit Price'}
                                              disabled={values.project_category?.name == 'Time & Material' ? true : false}
                                              sx={{ width: 85 }}
                                              value={data.price}
                                              // onWheel={(e) => e.target.blur()}
                                              onKeyPress={(event) => {
                                                // if (event.key === '0' && event.target.value === '') {
                                                //   event.preventDefault();
                                                // }
                                                if (event.key === '-') {
                                                  event.preventDefault();
                                                }
                                                if (event.key === 'e') {
                                                  event.preventDefault();
                                                }
                                              }}
                                              inputProps={{ min: 0 }}
                                              defaultValue={0}
                                              onChange={(event) => {
                                                let newValue = event.target.value
                                                if (newValue !== '') {
                                                  setAdditionalSelectedServices((pv) => [
                                                      ...pv.map((p) => {
                                                        if ( p.newIndexId === data.newIndexId ) {
                                                          return {
                                                            ...p,
                                                            price: Number(newValue),
                                                          }
                                                        } else {
                                                          return p
                                                        }
                                                      }),
                                                    ],
                                                  )
                                                  data['price'] =  Number(newValue || 0)
                                                }
                                              }}
                                              // error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                              // helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                            />
                                          : data.price}</TableCell>
                                         <TableCell>
                                            <TextField required
                                              fullWidth
                                              type='number'
                                              label={'Markup'}
                                              value={Number(data.markup)||0}
                                              defaultValue={0}
                                              onKeyPress={(event) => {
                                                // if (event.key === '0' && event.target.value === '') {
                                                //   event.preventDefault();
                                                // }
                                                if (event.key === '-') {
                                                  event.preventDefault();
                                                }
                                                if (event.key === 'e') {
                                                  event.preventDefault();
                                                }
                                              }}
                                              onChange={(event) => {

                                                let newValue = event.target.value;

                                                
                                                setSelectedMaterials((pv) =>
                                                  pv.map((p) =>
                                                    p.newIndexId === data.newIndexId
                                                      ? {
                                                          ...p,
                                                          markup: newValue === "" ? "" : Number(newValue), // Allow empty string temporarily
                                                        }
                                                      : p
                                                  )
                                                );

                                                if(newValue == null ||newValue == '' || (/^0[0-9]+$/.test(newValue))){
                                                  console.log('newValuenewValue 0',newValue)

                                                  setSelectedMaterials(pv => ([...pv.map(p => {
                                                    if (p.newIndexId === data.newIndexId) {
                                                      return {
                                                        ...p,
                                                        markup: '0',
                                                      }
                                                    } else {
                                                      return p
                                                    }
                                                  })]));
                                                  newValue=0
                                                  console.log('newValuenewValue data',selectedMaterials)
                                                  return selectedMaterials
 
                                                }else if (newValue !== "") {
                                                  setSelectedMaterials(pv => ([...pv.map(p => {
                                                    if (p.newIndexId === data.newIndexId) {
                                                      return {
                                                        ...p,
                                                        markup: Number(newValue),
                                                      }
                                                    } else {
                                                      return p
                                                    }
                                                  })]));
                                                 if (newValue === '' || newValue < 0) {
                                                    event.preventDefault();
                                                    return;
                                                  }
                                                  if (newValue.startsWith('-')) {
                                                    newValue = newValue.slice(1);
                                                  }
                                                  data['markup'] = newValue || 0;
                                                 
                                                }
                                              }}
                                              inputProps={{ min: 0 }}
                                            
                                            />
                                         </TableCell>
                                         <TableCell>{data.markUpCost || 0}</TableCell>
                                         <TableCell>
                                            <TextField required
                                              fullWidth
                                              type='number'
                                              label={shortUnitName || 'Quantity'}
                                              value={Number(data.quantity)||1}
                                              defaultValue={1}
                                              onKeyPress={(event) => {
                                                if (event.key === '0' && event.target.value === '') {
                                                  event.preventDefault();
                                                }
                                                if (event.key === '-') {
                                                  event.preventDefault();
                                                }
                                                // if (event.key === '.') {
                                                //   event.preventDefault();
                                                // }
                                                if (event.key === 'e') {
                                                  event.preventDefault();
                                                }
                                              }}
                                              onChange={(event) => {

                                                let newValue = event.target.value;
                                                if (newValue !== "0" && newValue !== "") {

                                                  setSelectedMaterials(pv => ([...pv.map(p => {
                                                    console.log("################p.id", p.id);
                                                    console.log("################data.id", data.id);
                                                   
                                                    if (p.newIndexId === data.newIndexId) {
                                                     
                                                      return {
                                                        ...p,
                                                        quantity: Number(newValue),
                                                       
                                                      }
                                                    } else {
                                                      return p
                                                    }
                                                  })]));
                                                  
                                                 if (newValue === '' || newValue <= 0) {
                                                    event.preventDefault();
                                                    return;
                                                  }
                                                  if (newValue.startsWith('-')) {
                                                    newValue = newValue.slice(1);
                                                  }
                                                  data['quantity'] = newValue || 1;
                                                 
                                                }
                                              }}
                                            
                                            />
                                          </TableCell>
                                         <TableCell>{(data.price*(data.quantity||1))?.toFixed(2)}</TableCell>
                                         <TableCell>{((data.markUpCost || 0) * (data.quantity||1))?.toFixed(2)}</TableCell>
                                          <TableCell >
                                            <IconButton onClick={() => handleDeleteMaterial(data.id, data.newIndexId)}>
                                              <Delete />
                                            </IconButton>
                                          </TableCell>
                                        </TableRow>
                                        
                                      </>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Scrollbar>
                          <Stack sx={{ justifyContent: 'space-between', alignItems: 'center', display:'flex', flexDirection:'row' }}>
                            <Button
                              variant="outlined"
                              startIcon={
                                <Stack>
                                  {' '}
                                  <Add />{' '}
                                </Stack>
                              }
                              onClick={() => {
                                addNewMaterialRow()
                                // setMarkupType(1)
                              }}
                            >
                              Add Material Row
                            </Button>
                            <Typography variant="h6">
                              Total Additional Material Cost:{' '}
                              {selectedMaterialSubTotal}
                            </Typography>
                          </Stack>
                        <Divider />
                        <TextField
                        fullWidth
                        multiline
                        minRows={3}
                        maxRows={7}
                        label="Notes"
                        {...getFieldProps('notes')}
                        error={Boolean(touched.notes && errors.notes)}
                        helperText={touched.notes && errors.notes}
                      />
                      </> }
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }} justifyContent={'space-between'}>
                        <TextField
                          fullWidth
                          // sx={{ width: 400 }}
                          type='number'
                          onKeyPress={(event) => {
                            if (event.key === '-') {
                              event.preventDefault();
                            }
                          }}
                          onChange={(event) => {
                            let newValue = event.target.value
                            let data = 0
                            if(markupType === 1){
                              data = totalWholesalePrice - budget
                            }else if(markupType === 2){
                              data = totalRetailPrice - budget
                            }
                            console.log("extradiscount---data",data)
                            console.log("extradiscount---totalWholesalePrice",totalWholesalePrice)
                            console.log("extradiscount---totalRetailPrice",totalRetailPrice)
                            console.log("extradiscount---budget",budget)
                            if (Number(newValue) > data) {
                              newValue = data?.toFixed(2);
                            }
                            if (newValue === '-0.00') {
                              newValue = 0;
                            }
                            console.log('newValue-2-------',newValue);
                            setFieldValue('invoice_discount', newValue)
                          }}
                          label={hasDiscountPermission ? "Discount (Access Denied)" : "Discount"}
                          disabled = {hasDiscountPermission}
                          value={values.invoice_discount}
                          // {...getFieldProps('invoice_discount')}
                          error={Boolean(touched.invoice_discount && errors.invoice_discount)}
                          helperText={touched.invoice_discount && errors.invoice_discount}
                          inputProps={{ max: (markupType === 1) ? totalWholesalePrice - budget : ((markupType === 2) ? totalRetailPrice - budget : 0) , min: 0 }}
                        />
                        <Autocomplete
                          // sx={{ width: 400 }}
                          fullWidth
                          id="combo-box-demo"
                          disabled={true}
                          options={taxCategories || []}
                          value={selectedTaxCategory || null}
                          onChange={(event, newValue) => {
                            setSelectedTaxCategory(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Tax category" />
                          )}
                          getOptionLabel={(option) => option.name ? option.name : ''}
                        />
                      </Stack>
                      <Typography sx={{ color: theme.palette.error.main }}>**All values in the software are rounded to two decimal places in accordance with standard US calculation practices.</Typography>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <Stack>
                          {/* <TextField
                            sx={{ width: 400 }}
                            type='number'
                            onChange={(event, newValue) => setFieldValue('invoice_discount', newValue)}
                            label="Extra Discount"
                            {...getFieldProps('invoice_discount')}
                            error={Boolean(touched.invoice_discount && errors.invoice_discount)}
                            helperText={touched.invoice_discount && errors.invoice_discount}
                          /> */}

                          {/* <Typography sx={{ color: 'text.secondary' }}>Sub Total: {rfqSubTotal ? rfqSubTotal?.toFixed(2) : 0}</Typography>
                          <Typography sx={{ color: 'text.secondary' }}>Service cost in Product Formula: {initialServiceCostInProductFormula ? initialServiceCostInProductFormula?.toFixed(2) : 0}</Typography>
                          <Typography sx={{ color: 'text.secondary' }}>Total ({rfqSubTotal?.toFixed(2)} - {initialServiceCostInProductFormula?.toFixed(2)}): {totalsum ? totalsum?.toFixed(2) : 0}</Typography> */}
                            <Typography sx={{ color: 'text.secondary' }}>
                              Sub Total: {totalsum ? totalsum.toFixed(2) : '0.00'}
                            </Typography>
                            <Typography sx={{ color: 'text.secondary' }}>
                              Tax: {parseFloat(taxAmount).toFixed(2) || 0.00}
                            </Typography>
                            <Typography sx={{ color: 'yellow' }}>
                              Grand Total: {grandTotal || 0.00}
                            </Typography>
                          {/* <Typography sx={{ color: 'yellow' }}>Grand Total : {totalsum ? totalsum?.toFixed(2) : 0}</Typography>  */}
                          <Typography sx={{ color: 'yellow' }}>Total Product Formula Cost: {budget ? budget?.toFixed(2) : 0}</Typography> 
                          <Typography sx={{ color: 'text.secondary' }}>Total Discount: {DiscountTotal ? DiscountTotal?.toFixed(2) : 0.00}</Typography>

                          <Tooltip title="Sub Total - Total Product Formula Cost">
                            <Typography sx={{ color: 'text.secondary', cursor:'pointer' }}>Total Markup Amount: {totalMarkupAmount ? totalMarkupAmount?.toFixed(2) : 0}</Typography>
                          </Tooltip>

                          <Tooltip title="(Total Markup Amount / Total Product Formula Cost) * 100">
                            <Typography sx={{ color: 'text.secondary', cursor:'pointer' }}>Total Markup Percentage: {totalMarkupPercentage ? totalMarkupPercentage?.toFixed(2) : 0}</Typography>
                          </Tooltip>

                          {/* <Typography sx={{ color: 'text.secondary' }}>Total Product Markup: {(originaltotalMarkup/selectedProducts.length)?.toFixed(2)}</Typography> */}
                         {/* { showServices ?<Typography sx={{ color: 'text.secondary' }}>Total Service Markup: {(originaltotalServiceMarkup/(selectedServices.length+additionalSelectedServices.length))?.toFixed(2)}</Typography>:""} */}
                        </Stack> 
                        <Stack direction={'column'} display={'flex'} justifyContent={'center'}>                    
                        <FormControlLabel
                           onChange={() => {                                           
                          // Toggle the value of welcome_message using setCurrentRFQdata
                            setCurrentRFQdata((prevData) => ({
                              ...prevData,
                              welcome_message: !prevData.welcome_message,
                            }));
                          }}
                          control={<Switch  {...getFieldProps('welcome_message')} color="primary" 
                          checked={values.welcome_message == 1}
                          />}         
                          labelPlacement="end"
                          label="Use Custom Welcome Message"
                          sx={{ mt: 6 ,mb:2}}
                        />
                        <FormControlLabel
                          control={
                            <Switch
                            {...getFieldProps('client_approve')}
                              color="primary"
                              checked={values.client_approve}
                              onChange={(event) => setFieldValue('client_approve', event.target.checked)}
                            />
                          }
                          labelPlacement="end"
                          label="Enable for Client Approval"
                          sx={{ mt: 0 }}
                        />
                        </Stack>  
                      </Stack>
                      {values.welcome_message == true &&(
                        <>
                      <Stack>
                        <LabelStyle>Subject</LabelStyle>
                          <TextField
                            //   fullWidth
                            type='text'
                            sx={{ width: 400 }}
                            defaultValue={values.subject}
                            {...getFieldProps('subject')}
                            error={Boolean(touched.subject && errors.subject)}
                            helperText={touched.subject && errors.subject}
                          />
                      </Stack>
                      <LabelStyle>Email Content</LabelStyle>
                      <TextField
                        fullWidth
                        multiline
                        minRows={3}
                        maxRows={7}
                        label="Email Content"
                        defaultValue={values.email_content}
                        {...getFieldProps('email_content')}
                        error={Boolean(touched.email_content && errors.email_content)}
                        helperText={touched.email_content && errors.email_content}
                      />
                      </>
                      )}
                      {/* <LabelStyle>Request state</LabelStyle>
                      <Typography>While in draft mode, clients you add to request tasks won't be notified</Typography>
                      <FormControlLabel
                       onChange={() => {                     
                       
                        setCurrentRFQdata((prevData) => ({
                          ...prevData,
                          draft: !prevData.draft,
                        }));
                      }}
                        control={<Switch {...getFieldProps('draft')} color="primary" checked={values.draft} />}
                        labelPlacement="end"
                        label="Draft"
                        sx={{ mt: 5 }}
                      /> */}
                      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                        <LoadingButton type="submit" variant="contained" loading={isSubmitting}  >
                          Update Estimate
                        </LoadingButton>
                        {/* <Button variant="outlined"  onClick={() =>{
                          setOpenPreview(!openPreview),
                          setValuesToPreview() }}>
                          Preview
                        </Button> */}
                        <Button variant="outlined" onClick={() => handleCancel(true) }>
                          Cancel
                        </Button>
                      </Box>
                    </Stack>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        )}
        {!openEdit && (
          <>
            <Stack direction={'row'} display={'flex'} alignItems={'center'}  spacing={{ xs: 3, sm: 2 }}>
              {/* <Typography  fontSize={'1.09rem'} variant="overline" sx={{ color: 'text.secondary', }}>RFQ</Typography> */}
             {details?.id && invoice?.total !==0 && !user.isVendor && ( 
           
            <Link
              color={theme.palette.success.dark}
              onClick={() => setOpenInvoice(!openInvoice)}
              // component={RouterLink}
              to={''}
            >
              {details?.quotation_status === 2 ? (
              <Badge badgeContent={'1'} color={'warning'}>
                <Typography fontSize={'1.09rem'} variant="overline">
                  <span style={{ textDecoration: 'underline' }}>Estimate</span>
                </Typography>
              </Badge>) : (
                <Typography fontSize={'1.09rem'} variant="overline">Estimate</Typography>
              )}
            </Link>
            
              )}
              <Typography fontWeight={'0.09rem'} variant="overline" >
                <Label
                  sx={{ p: '12px' }}
                  variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                  color={getStatusColorAndLabel(details?.quotation_status,details?.quotation).color} >
                  {getStatusColorAndLabel(details?.quotation_status,details?.quotation).label}
                </Label>
              </Typography>
            </Stack>

            <Grid container spacing={3}>

              <Grid sx={{ mt: 3 }} item xs={12} md={12}>
                <Stack spacing={3}>
                  <Stack spacing={5}>
                    <Tabs
                      value={currentTab}
                      scrollButtons="auto"
                      variant="scrollable"
                      allowScrollButtonsMobile
                      onChange={handleChangeTab}
                    >
                      {ACCOUNT_TABS.map((tab) => (
                        <Tab
                          disableRipple
                          key={tab.value}
                          label={capitalCase(tab.value)}
                          value={tab.value}
                        />
                      ))}
                    </Tabs>

                    {ACCOUNT_TABS.map((tab) => {
                      const isMatched = tab.value === currentTab
                      return isMatched && <Box key={tab.value}>{tab.component}</Box>
                    })}
                  </Stack>
                </Stack>
              </Grid>
             
            </Grid>
          </>)}

        {/* adding client modal  */} 
        <AddClients 
        request_id={id}
         setIsOpen={setIsOpen}
         setAllClients={setAllClients} 
         allClients={allClients?.filter(a=>!a.isVendor)}
          addClient={addClient} 
          setAddClient={setAddClient} />
        <AddClients 
          isVendor
          request_id={id}
          setIsOpen={setIsOpen}
          setAllClients={setAllClients} 
          allClients={allClients?.filter(a=>a.isVendor)}
          addClient={addVendor}
          setAddClient={setAddVendor} />
        <AddProposal
          request_id={id}
          setIsOpen={setIsOpen}
          setReloadData={()=>setRefresh(!refresh)}
          addProposal={addProposal}
          setAddProposal={setAddProposal} />
        {/* invoice modal */}
        <Modal modalTitle={'Estimate Details'} open={openInvoice === true} handleClose={() => setOpenInvoice(false)}>
          <Stack direction={{ xs: 'column', sm: 'row', md: 'column' }}
            spacing={{ xs: 3, sm: 2 }}>
            <Stack direction="row" justifyContent={'flex-end'} alignItems="center" spacing={1}>
            <ReactToPrint 
    trigger={() =>(<Button> <Print />Print</Button>)}
    content={() => componentRef.current}
    copyStyles={false}
    pageStyle={`
      @media print {
        body {
          font-size: 12pt;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          margin-bottom: 10px;
        }

        th, td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
        }
        #right {
          text-align: right;
          margin-top: -200px; 
        }
        #total {
          text-align: right;
          display:flex;
          justify-content: flex-end;
        }
        #subtotal {
          text-align: left;
          
        }
        #discount {
          text-align: left;
          word-wrap: break-word;
          margin-right: 100px;
      }
      
        #totalmain {
          text-align: left;
          
        }
        #subtotal1 {
          text-align: left;
          
        }
        #discount1 {
          text-align: left;
          word-wrap: break-word;
          margin-right: 30px;
      }
      
        #totalmain1 {
          text-align: left;
          
        }
        #total1 {
          text-align: right;
          display:flex;
          justify-content: flex-end;
          margin-top: -220px; 
          
        }
        #logoImage {
          height: 100px;
          width:100px;
        }
        #order{
          margin-bottom:-20px
        }
        #title{
          margin-bottom:-20px
        }
        #orderID {
          margin-bottom:-20px
        }
        #to {
          margin-bottom:-20px
        }
        
      }
    `}
    
  />
{details?.quotation_status===4 && SEND_EMAIL && <Button onClick={handleSendEmail}>Send Email</Button>}
            <div style={{ display: "none" }}>
    <PrintFunction
      invoiceDate={invoiceDate}
      openInvoice={openInvoice}
      details={details}
      invoice={invoice}
      subTotal={subTotal}
      ref={componentRef}
      additionalSelectedServices={additionalSelectedServices}
      cartService={cartService}
      invoiceSelectedServices={invoiceSelectedServices}
      data={data}
      labourInstallService={labourInstallService}
    />
  </div>

            </Stack>
          </Stack>
          <Grid container spacing={3} >

            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Box component="img" alt="logo" src={COMPANY_LOGO} sx={{ height: 98 }} />
          </Grid>
              <Typography variant="body2">{COMPANY_NAME}</Typography>
              <Typography variant="body2">www.{COMPANY_NAME}.com</Typography>
              <Typography variant="body2">
                {data?.address}, {data?.city}, {data?.state}, {data?.zipcode}
              </Typography>
              <Typography variant="body2">Telephone: +15208362947</Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>

              <Typography variant="body2">
                Order date: {invoiceDate}
              </Typography>
              {/* <Stack sx={{display:'flex',flexDirection:'row'}}> */}
              {/* <Typography variant="body2">Order status:</Typography> */}
              <Label
                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                color={getStatusColorAndLabel(details?.quotation_status,details?.quotation).color} >
                {getStatusColorAndLabel(details?.quotation_status,details?.quotation).label}
              </Label>
              {/* </Stack> */}
              <Typography variant="body2">Request Name:{details?.name}</Typography>
              <Typography variant="body2">Order ID:{details?.id}</Typography>
              <Typography paragraph variant="overline" sx={{ color: 'text.disabled', marginTop: '20px' }}>
                TO {invoice?.customer_name}
              </Typography>
              <Typography variant="body2">Phone: {invoice?.customer_phone}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>

            <Scrollbar>
            <Typography variant='h6'>Products</Typography>
              <TableContainer sx={{ minWidth: 420, mt:3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>PRODUCT & NOTE</TableCell>
                      <TableCell>UNIT PRICE</TableCell>
                      <TableCell align="center">UNIT</TableCell>
                      <TableCell>QTY</TableCell>
                      {/* <TableCell>MARKETING ADJUSTMENT (%)</TableCell> */}
                      <TableCell>SUB TOTAL</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoice?.cust_product?.map((val) => {
                      const { vid, product_name,product_notes, product_price,product_retail_price, adjustment,
                        quantity,locationQuantity, product_total, discount,unit,unit_qty, material, rfp,markAdjType,
                        services = []
                      } = val

                      const labourService = services.find(service => service.labour_cost_service_id === 10);
                      const minusAmountLabourInstall = labourService?.total_cost || 0;
                      let unitName = UNITS?.filter((item)=>item?.id==unit)
                      unitName = unitName[0]?.name
                      let shortUnitName = ''
                      if(unitName=='Square feet'){
                        shortUnitName =  'Sq ft'
                      }else if(unitName=='Lineal feet'){
                        shortUnitName = 'Lin ft'
                      }else if(unitName=='Square Inches'){
                        shortUnitName =  'Sq Inches'
                      }else if(unitName=='Unit'){
                        shortUnitName = 'Unit'
                      }
                      //  subTotal += product_total    
                      let SPMA = 0
                      if(markAdjType==2 || markAdjType==3) {
                        SPMA = (product_retail_price || 0) + (adjustment ? Number(adjustment) : 0)
                      }else{
                        SPMA = (product_retail_price || 0) + (((product_retail_price || 0) * (adjustment ? Number(adjustment) : 0)) / 100)

                      }
                      SPMA = parseFloat(SPMA)?.toFixed(2)   

                      return (

                        <TableRow key={vid} sx={{
                          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                        }}>
                          {/* <TableCell width={'25%'} sx={{flexWrap:'wrap'}}>
                            <Typography variant='body2'>{product_name}</Typography>
                            {product_notes ? <Typography variant='subtitle2'>({product_notes})</Typography> : ''}
                          </TableCell> */}
                          <TableCell width={'25%'} sx={{ flexWrap: 'wrap', overflow: 'hidden' }}>
                            <Typography variant='body2' sx={{ fontWeight: 'bold', mb: 1, wordBreak: 'break-word', overflowWrap: 'break-word' }}>{product_name}</Typography>
                            {product_notes ? 
                            <Stack direction={'column'}>
                              <Typography variant='body2' sx={{textDecoration: 'underline'}}>Note : </Typography> 
                              <Typography variant='body2' sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>{product_notes}</Typography> 
                            </Stack>
                            : ''}
                            {material === 1 ? 
                              <Typography variant='body2' sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>(Addl. Material)</Typography> 
                            : ''}
                          </TableCell>
                          <TableCell>{SPMA ? SPMA : 0.00} {minusAmountLabourInstall && minusAmountLabourInstall > 0? "(Included Labor Install)" :""}</TableCell>
                          <TableCell align="center">{quantity}({shortUnitName})</TableCell>
                          <TableCell>{locationQuantity}</TableCell>
                          {/* <TableCell>{discount ? discount?.toFixed(2) : 0.00}</TableCell> */}
                          {/* <TableCell>{(material === 1 || rfp === 1) ? 0.00 : (adjustment ? Number(adjustment)?.toFixed(2) : 0.00)}</TableCell> */}
                          {markAdjType==1?
                          <TableCell>{product_total ? (product_total - minusAmountLabourInstall)?.toFixed(2) : 0.00}</TableCell>
                        :
                        <TableCell>{((SPMA * (locationQuantity * quantity))-minusAmountLabourInstall).toFixed(2)}</TableCell>

                        }
                        </TableRow>
                      )
                    }
                    )}

                    {/* <RowResultStyle>
                      <TableCell colSpan={3} />
                      <TableCell align="center">
                        <Typography variant="overline">Subtotal</Typography>
                      </TableCell>
                      <TableCell align="center" width={10}>
                        <Typography variant="overline">{subTotal ? subTotal?.toFixed(2) : 0.00}</Typography>
                      </TableCell>
                    </RowResultStyle>
                    <RowResultStyle>
                      <TableCell colSpan={3} />
                      <TableCell align="center">
                        <Typography variant="overline">Additional Labor Cost</Typography>
                      </TableCell>
                      <TableCell align="center" width={10}>
                        <Typography variant="overline">{details ? details?.labour_cost?.toFixed(2) : 0.00}</Typography>
                      </TableCell>
                    </RowResultStyle>
                    <RowResultStyle>
                      <TableCell colSpan={3} />
                      <TableCell align="center">
                        <Typography variant="overline">Extra Discount</Typography>
                      </TableCell>
                      <TableCell align="center" width={10}>
                        <Typography variant="overline">{invoice?.extra_discount ? invoice?.extra_discount?.toFixed(2) : 0.00}</Typography>
                      </TableCell>

                    </RowResultStyle>
                    <RowResultStyle>
                      <TableCell colSpan={3} />
                      <TableCell align="center">
                        <Typography variant="subtitle1">Total</Typography>
                      </TableCell>
                      <TableCell align="center" width={10}>
                        <Typography variant="subtitle1">{invoice?.total ? invoice?.total?.toFixed(2) : 0.00}</Typography>
                      </TableCell>

                    </RowResultStyle> */}

                  </TableBody>
                </Table>
              </TableContainer>

              {/*below for labour Install */}
                                <Typography variant='h6' sx={{ mt: 2 }}>{(labourInstallService?.length > 0 ) ? ((details?.job_category_name != 'Service only' && details?.job_category_name != 'Time & Material') ? "Services(Labor Install)" : 'Services(Labor Install)') : ""}</Typography>
                                <TableContainer sx={{ minWidth: 420, mt: 3 }}>
                                  <Table>
                                    {(labourInstallService?.length > 0 ) &&
                                      <TableHead>
                                        <TableRow>
                                          <TableCell colSpan={2}>NAME</TableCell>
                                          <TableCell>COST (HOURLY)</TableCell>
                                          <TableCell>HOURS</TableCell>
                                          <TableCell>TOTAL COST</TableCell>
                                        </TableRow>
                                      </TableHead>}
                                    <TableBody>
                                    
                                    {labourInstallService?.map((ser) => {
                                        console.log('invoiceSelectedServices--@@ 1', ser);
                                        return (
                                          <TableRow sx={{
                                            borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                                          }}>
                                            <TableCell colSpan={2}>{ser?.name}-({ser.productName})</TableCell>
                                            <TableCell>{ser ? ser?.unit_cost?.toFixed(2) : 0}</TableCell>
                                            <TableCell>{ser ? ser?.quantity?.toFixed(2) : 0}</TableCell>
                                            <TableCell>{ser ? ser?.total_cost?.toFixed(2) : 0}</TableCell>
                                          </TableRow>
                                        )
                                      })}
                                      </TableBody>
                                      </Table>
                                      </TableContainer>
              
              {/*above  for labour Install */}

              <Typography variant='h6' sx={{ mt:2 }}>{(additionalSelectedServices?.length > 0) ? "Additional Services" : ""}</Typography>
              <TableContainer sx={{ minWidth: 420, mt:3 }}>
                <Table>
                {(additionalSelectedServices?.length > 0|| cartService?.length > 0 || invoiceSelectedServices?.length > 0) && 
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>NAME</TableCell>
                      <TableCell>COST (HOURLY)</TableCell>
                      <TableCell>HOURS</TableCell>
                      <TableCell>TOTAL COST</TableCell>
                    </TableRow>
                  </TableHead>}
                  <TableBody>
                    {invoice?.cust_product?.map((val) => {
                      const { vid, product_name, product_price,product_retail_price,
                        quantity, product_total, discount,unit,unit_qty,markAdjType,adjustment,locationQuantity ,
                        services = []
                      } = val

                      const labourService = services?.find(service => service?.labour_cost_service_id === 10);
                      const minusAmountLabourInstall = labourService?.total_cost || 0;

                      let unitName = UNITS?.filter((item)=>item?.id==unit)
                      unitName = unitName[0]?.name
                      let shortUnitName = ''
                      if(unitName=='Square feet'){
                        shortUnitName = 'Sq ft'
                      }else if(unitName=='Lineal feet'){
                        shortUnitName = 'Lin ft'
                      }else if(unitName=='Square Inches'){
                        shortUnitName = 'Sq Inches'
                      }else if(unitName=='Unit'){
                        shortUnitName = 'Unit'
                      }

                      let SPMA = 0
                      if(markAdjType==2 || markAdjType==3) {
                        SPMA = (product_retail_price || 0) + (adjustment ? Number(adjustment) : 0)
                      }else{
                        SPMA = (product_retail_price || 0) + (((product_retail_price || 0) * (adjustment ? Number(adjustment) : 0)) / 100)

                      }
                      SPMA = parseFloat(SPMA)?.toFixed(2)  

                      let theSubTotalAmount =markAdjType==1?product_total: (SPMA * (locationQuantity * quantity))
                       subTotal += theSubTotalAmount                   
                      return (
                        null
                      )})}
                      {openInvoice && invoiceSelectedServices?.map((ser) => {
                        console.log('invoiceSelectedServices--@@', ser);
                        return (
                          <TableRow sx={{
                            borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                          }}>
                            <TableCell colSpan={2}>{ser?.name}</TableCell>
                            <TableCell>{ser ? ser?.unit_cost?.toFixed(2) : 0}</TableCell>
                            <TableCell>{ser ? ser?.quantity?.toFixed(2) : 0}</TableCell>
                            <TableCell>{ser ? ser?.total_cost?.toFixed(2) : 0}</TableCell>
                          </TableRow>
                        )
                      })}
                      {openInvoice && additionalSelectedServices?.map((ser) => {
                        console.log('additionalSelectedServices--',ser);
                        return(
                        <TableRow sx={{
                          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                        }}>
                          <TableCell colSpan={2}>{ser?.name}</TableCell>
                          <TableCell>{ser ? ser?.unit_cost?.toFixed(2) : 0}</TableCell>
                          <TableCell>{ser ? ser?.quantity?.toFixed(2) : 0}</TableCell>
                          <TableCell>{ser ? ser?.total_cost?.toFixed(2) : 0}</TableCell>
                        </TableRow>
                      )
                    }
                    )}
                      {openInvoice && cartService?.map((ser) => {
                        console.log('cartService--', ser);
                        ser.sellingPrice = ser.sellingPrice || ser.unit_cost
                        ser.originalMarkup = ser.originalMarkup || ser.product_markup
                        return (
                          <TableRow sx={{
                            borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                          }}>
                            <TableCell colSpan={2}>{ser?.name}</TableCell>
                            <TableCell> {((ser.sellingPrice + (ser.sellingPrice * ser.originalMarkup / 100)))?.toFixed(2)}</TableCell>
                            <TableCell>{ser ? ser?.qty?.toFixed(2) : 0}</TableCell>
                        
                            <TableCell> {(ser.qty * (ser.sellingPrice + (ser.sellingPrice * ser.originalMarkup / 100)))?.toFixed(2)}</TableCell>
                          </TableRow>
                        )
                      }
                      )}
                    <RowResultStyle>
                      <TableCell colSpan={3} />
                      <TableCell align="center">
                        <Typography variant="overline">Subtotal</Typography>
                      </TableCell>
                      <TableCell align="center" width={10}>
                        <Typography variant="overline">{subTotal ? subTotal?.toFixed(2) : 0.00}</Typography>
                      </TableCell>
                    </RowResultStyle>

                    <RowResultStyle>
                      <TableCell colSpan={3} />
                      <TableCell align="center">
                        <Typography variant="overline">ADDITIONAL SERVICES</Typography>
                      </TableCell>
                      <TableCell align="center" width={10}>
                        <Typography variant="overline">{details ? details?.labour_cost?.toFixed(2) : 0.00}</Typography>
                      </TableCell>
                    </RowResultStyle>

                    <RowResultStyle>
                      <TableCell colSpan={3} />
                      <TableCell align="center">
                        <Typography variant="overline">Discount</Typography>
                      </TableCell>
                      <TableCell align="center" width={10}>
                        <Typography variant="overline">{invoice ? invoice?.extra_discount?.toFixed(2) : 0.00}</Typography>
                      </TableCell>
                    </RowResultStyle>

                    <RowResultStyle>
                      <TableCell colSpan={3} />
                      <TableCell align="center">
                        <Typography variant="overline">Tax</Typography>
                      </TableCell>
                      <TableCell align="center" width={10}>
                        <Typography variant="overline">{invoice ? invoice?.tax_amount?.toFixed(2) : 0.00}</Typography>
                      </TableCell>
                    </RowResultStyle>

                    <RowResultStyle>
                      <TableCell colSpan={3} />
                      <TableCell align="center">
                        <Typography variant="subtitle1">Total</Typography>
                      </TableCell>
                      <TableCell align="center" width={10}>
                        <Typography variant="subtitle1">{invoice?.total ? invoice?.total?.toFixed(2) : 0.00}</Typography>
                      </TableCell>

                    </RowResultStyle>

                  </TableBody>
                </Table>
              </TableContainer>
              {(userType ==3&&details?.quotation_status===2) && 
              <Box sx={{ mt: 3,  display: 'flex', justifyContent: 'flex-end',gap:1 }}>
                  <Button type="submit" variant="contained" 
                   onClick={()=>setConfirmInvoice(true)}
                  // onClick={()=>handleAcceptorRejectInvoice(1)}
                   >                 
                   Approve
                  </Button>
                  <Button size="small" variant="outlined"
                   onClick={()=>setRejectInvoice(true)}
                  //  onClick={()=>handleAcceptorRejectInvoice(2)}
                   sx={{color:theme.palette.error.main}} >
                    Reject
                  </Button>
                </Box>}
                {/* {(userType ==3&&details?.status!=4&&details?.onboard_complete==0) && 
                <Box sx={{ mt: 3,  display: 'flex', justifyContent: 'flex-end',gap:1 }}>
                  <Button type="submit" variant="contained" 
                   onClick={()=> navigate(PATH_DASHBOARD.official.process,{state:{requestId:id}})}
                  // onClick={()=>handleAcceptorRejectInvoice(1)}
                   >                 
                   Proceed to Workflow
                  </Button>
                 
                </Box>} */}
            </Scrollbar>
                      
          </Grid>
        </Modal>
        {/* delete popup */}
        <ConfirmationPopup 
        open={openDelete}
        handleClick={handleDeleteRFQ}
         handleClose={()=>{setOpenDelete(!openDelete),setIsOpen(false)}}
         message={'You want to delete this Estimate '}
        />
       
        {/* Accept request invoice */}
        <ConfirmationPopup 
        open={confirmInvoice}
        handleClick={()=>handleAcceptorRejectInvoice(1)}
         handleClose={()=>setConfirmInvoice(false)}
         message={'You want to Approve this Estimate '}
        />
        {/* reject request invoice */}
         {/* <ConfirmationPopup 
        open={invoiceReject}
        handleClick={()=>handleAcceptorRejectInvoice(2)}
         handleClose={()=>setRejectInvoice(false)}
         message={'You want to reject this invoice '}
        /> */}
        {/* Reopen rejected request  */}
        <ConfirmationPopup
         open={reOpen}
          handleClick={()=>handleReopeningRequest()}
        handleClose={()=>setReopen(false)}
        message={'You want to reopen this Estimate '}
        />
        <ConfirmationPopup
         open={convertRfq}
          handleClick={()=>convertToRFQ()}
        handleClose={()=>setConvertRfq(false)}
        message={'This Estimate will be converted and moved to Sales Order page '}
        />
         {/* Preview Modal */}
        {openPreview && (
          <Modal
            open={openPreview}
            handleClose={() => {
              setOpenPreview(false);
            }}
            modalTitle={'Preview'}
          >
         
            <Grid container sx={{ justifyContent: 'center' }} spacing={3}>
              <Grid item xs={12} md={12}>
                <Stack
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                  spacing={2}
                >
                   <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Typography variant="subtitle2">
                      <span style={{ color:theme.palette.primary.darker }}>Name: </span>
                      {previewData?.name}
                    </Typography>
                   
                    <Typography variant="subtitle2">
                    <span style={{ color:theme.palette.primary.darker }}>Use custom welcome message: </span>
                    {previewData.welcome_message==null?'No':previewData.welcome_message==false?'No':'Yes'}
                    </Typography>
                  </Stack>
                    <Stack>
                    <Typography variant="subtitle2" style={{ color:theme.palette.primary.darker }}>
                      Description:
                    </Typography>
                    <Typography variant="subtitle2" style={{ overflowWrap: 'break-word', wordWrap: 'break-word', wordBreak: 'break-word' }}>
                      {previewData?.description}
                    </Typography>
                    </Stack> 
                  <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    display="flex"
                    justifyContent="space-between"
                  >
                    {/* <Typography variant="subtitle2">
                      <span style={{ color:theme.palette.primary.darker,
                        overflowWrap: 'break-word', wordWrap: 'break-word',
                         wordBreak: 'break-word' }}>Skills or services required:</span> <br/>
                          <List>
                           {previewData?.skills?.map((val, index) => (
                           <ListItem sx={{ listStyleType: 'disc', paddingLeft: '20px' }} key={index}>
                           <ListItemText primary={val.name} />
                           </ListItem>
                                   ))}
                                </List>
                    </Typography> */}
                    {/* <Typography variant="subtitle2">
                      <span style={{ color:theme.palette.primary.darker }}>Workflows: </span> <br/>                                           
                      <List>
                           {previewData?.workflow?.map((val, index) => (
                           <ListItem sx={{ listStyleType: 'dics', paddingLeft: '20px' }} key={index}>
                           <ListItemText primary={val.name} />
                           </ListItem>
                                   ))}
                                </List>
                    </Typography> */}
                  </Stack>
                  <Stack
                    direction={{ xs: 'column', md: 'row' }} 
                    display="flex"
                    justifyContent="space-between"                 
                  >
                    <Typography variant="subtitle2">
                      <span style={{ color:theme.palette.primary.darker }}>Job Class: </span> 
                      {previewData?.project_type?.name}
                    </Typography>
                    <Typography variant="subtitle2">
                      <span style={{ color:theme.palette.primary.darker,}}>Job Types: </span> 
                      {previewData?.project_category?.name}
                    </Typography>                
                  </Stack>

                  <Stack
                    direction={{ xs: 'column', md: 'row' }} 
                    display="flex"
                    justifyContent="space-between"                 
                  >
                    <Typography variant="subtitle2">
                      <span style={{ color:theme.palette.primary.darker }}>Project: </span>                      
                      {previewData?.community?.name}
                    </Typography>
                    <Typography variant="subtitle2">
                      <span style={{ color:theme.palette.primary.darker,}}>Markup Type: </span> 
                      {previewData?.markup_type?.name}
                    </Typography>                
                  </Stack>
                               
                  <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Typography variant="subtitle2">
                      <span style={{ color:theme.palette.primary.darker }}>Start Date: </span>
                      {moment.unix(previewData.start).format("MM/DD/YYYY")}
                    </Typography>
                    <Typography variant="subtitle2">
                      <span style={{ color:theme.palette.primary.darker }}>installation Date: </span>
                      {moment.unix(previewData.end).format("MM/DD/YYYY")}
                    </Typography>
                  </Stack>  
                  <LabelStyle>Products</LabelStyle>            
                  <Scrollbar>
                        <TableContainer sx={{ minWidth: 500 }}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                {/* {VALUEPreview?.map((val) => (
                                  <TableCell>{val.value}</TableCell>
                                ))} */}
                                {VALUEPreview?.map((val) => (
                                  val.id === 14 ? (
                                    <Tooltip key={val.id} title="Selling Price With Marketing Adjustment">
                                      <TableCell>{val.value}</TableCell>
                                    </Tooltip>
                                  )
                                  : (
                                  <TableCell key={val.id}>{val.value}</TableCell>
                                  )
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {selectedProducts?.map((data, index) => {
                                const quantityFieldName = `quantity_${data.product_id||data.id}`;
                                const discountFieldName = `discount_${data.product_id||data.id}`;
                                let unitName = UNITS?.filter((item)=>item?.id==data?.unit)
                                unitName = unitName[0]?.name
                                let shortUnitName = ''
                                if(unitName=='Square feet'){
                                  shortUnitName =  'Sq ft'
                                }else if(unitName=='Lineal feet'){
                                  shortUnitName = 'Lin ft'
                                }else if(unitName=='Square Inches'){
                                  shortUnitName = 'Sq Inches'
                                }else if(unitName=='Unit'){
                                  shortUnitName = 'Unit'
                                }
                                const dataPrice = data.product_price||data.price
                                const dataWholesalePrice = data.product_price||data.price
                                const dataQuantity = values[quantityFieldName]
                                const dataDiscount = data.discount?data.discount:values[discountFieldName]
                                const dataretailPrice = data.product_retail_price||data.retail_price
                                // const markup = ((dataretailPrice - dataPrice) / dataPrice) * 100;
                                const rowTotal = calculateTotal(markupType == 1 ? dataPrice : (markupType == 2 ? dataretailPrice : 0), dataQuantity, dataDiscount,data.quantity,data.locationQuantity, Number(data?.adjustment));
                                const invoiceAmount = parseFloat(data?.expected_material_cost?.toFixed(2)) + parseFloat(data?.expected_total_service_cost?.toFixed(2))
                                let markup = markupType ? (invoiceAmount / rowTotal) : null
                                markup = markup ? Number(markup) : 0
                                // data['markup'] = Math.round(markup)
                                console.log("###########Discount",data);
                                return (                             
                                 <TableRow key={index}>
                                    <TableCell>#{data.indexId}</TableCell>
                                    <TableCell>{data.product_name||data.name}</TableCell>
                                    {/* <TableCell>{invoiceAmount}</TableCell> */}
                                    <TableCell>{(hasMarkupPermission && markupType == 2) ? dataretailPrice : null}</TableCell>
                                    <TableCell>{(hasMarkupPermission && markupType == 1) ? dataPrice : null}</TableCell>
                                    <TableCell>{hasMarkupPermission ? (data?.SPMA || null) : null}</TableCell>
                                    <TableCell>
                                      {/* {hasMarkupPermission ? (data.markup ? data.markup?.toFixed(2) : 0) : null} */}
                                            {(hasMarkupPermission && markupType == 1) ? data.wholesaleMarkup
                                              : (hasMarkupPermission && markupType == 2) ? data.retailMarkup
                                                : null}
                                          </TableCell>
                                    {/* <TableCell>{shortUnitName}</TableCell> */}
                                    <TableCell>
                                  <TextField required
                                    fullWidth
                                    type='  '
                                    sx={{width:85}}
                                    disabled
                                    label={shortUnitName}
                                    value={Number(data.quantity)}
                                    defaultValue={1}
                                  />
                                </TableCell>
                                    <TableCell>
                                      <TextField required
                                        fullWidth
                                        type='number'
                                        disabled
                                        sx={{width:85}}
                                        label={'Qty'}                                    
                                        value={Number(data.locationQuantity)}
                                        defaultValue={1}                                                                                                              
                                      />
                                    </TableCell>                                 
                                    {/* <TableCell>{data.id}</TableCell> */}
                                    <TableCell align="center">
                                      {/* <TextField
                                        disabled
                                        type="number"
                                        label="Discount"
                                        defaultValue={0}   
                                        value={Number(data.discount)?.toFixed(2)}                                  
                                        // {...getFieldProps(discountFieldName)}
                                      /> */}
                                      {/* {Number(data.discount)?.toFixed(2)} */}
                                      {data?.adjustment ? Number(data?.adjustment)?.toFixed(2) : 0}
                                    </TableCell>
                                    <TableCell>
                                      {rowTotal?.toFixed(2)}
                                    </TableCell>                               
                                  </TableRow>                                                        
                                );
                              })}
                  
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Scrollbar>
                      <LabelStyle>Services</LabelStyle>
                      <Scrollbar>
                        <TableContainer sx={{ minWidth: 500 }}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                {VALUE_SERVICES?.map((val) => (
                                  <TableCell>{val.value}</TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {selectedServices?.map((data, index) => {
                                const dataCost = data.unit_cost || data.total_cost
                                let originalMarkup=data.originalMarkup||0
                                let markup=data.markup||0
                                const total = dataCost * data.quantity
                                data['total'] = total?.toFixed(2)
                                console.log("###########data total",data);
                                let qtyData = data.qtyData || 1
                                console.log("############qtyData", qtyData);
                                const newQty = (data.prQty * qtyData * data.locationQuantity || 1)?.toFixed(2)
                                console.log("############newQty", newQty);
                                const quantityDifference = Number(data.quantity) - newQty;
                                data['quantityDifference'] = quantityDifference
                                return (
                                  <>
                                    <TableRow key={index}>
                                    <TableCell>#{data.indexId}</TableCell>
                                    <TableCell>{data.name}</TableCell>
                                    <TableCell>{originalMarkup?.toFixed(2)}</TableCell>
                                    <TableCell>{data.cost}</TableCell>
                                    <TableCell>{dataCost}</TableCell>
                                    <TableCell>{data.quantity}</TableCell>
                                    <TableCell>{(data.cost*quantityDifference?.toFixed(2))?.toFixed(2)}</TableCell>
                                    <TableCell>{(data.unit_cost * quantityDifference?.toFixed(2))?.toFixed(2)}</TableCell>
                                    <TableCell>{data.total}</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell align='right'>
                                    </TableCell>
                                  </TableRow>
                                  </>
                                );
                              })}
                              {additionalSelectedServices?.map((data, index) => {
                                const dataCost = data.unit_cost || data.total_cost
                                const total = dataCost *(data?.quantity || 1)
                                data['total'] = total ? total?.toFixed(2) : 0.00
                                let originalMarkup=data.originalMarkup||0
                                console.log("###########data total",data);
                                return (                             
                                 <TableRow key={data.id}>
                                    <TableCell></TableCell>
                                    <TableCell>{data.name}</TableCell>
                                    <TableCell>{originalMarkup?.toFixed(2)}</TableCell>
                                    <TableCell>{data.cost}</TableCell>
                                    <TableCell>{data.unit_cost || data.total_cost}</TableCell>
                                    <TableCell>{data?.quantity || 1}</TableCell>
                                    <TableCell>{data.total}</TableCell>
                                  </TableRow>                                                        
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Scrollbar>
                      <Divider />
                      <Stack>
                    <Typography variant="subtitle2" style={{ color:theme.palette.primary.darker }}>
                     Notes:
                    </Typography>
                    <Typography variant="subtitle2" style={{ overflowWrap: 'break-word', wordWrap: 'break-word', wordBreak: 'break-word' }}>
                    <Scrollbar
                                  sx={{
                                    maxHeight: 200,
                                    '& .simplebar-content': {
                                      maxHeight: 200,
                                      display: 'flex',
                                      flexDirection: 'column',
                                    },
                                  }}
                                >{previewData?.notes}</Scrollbar>
                    
                    </Typography>
                    </Stack> 
                      <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    display="flex"
                    justifyContent="space-between"
                  >
                    {/* <Typography variant="subtitle2">
                      <span style={{ color:theme.palette.primary.darker }}>Total Price: </span>
                      {totalsum < 0 ? 0 : totalsum?.toFixed(2)}
                    </Typography> */}

                    <Typography variant="subtitle2">
                      <span style={{ color: theme.palette.primary.darker }}>Grand Total: </span>
                      {grandTotal ? (grandTotal < 0 ? 0.00 : parseFloat(grandTotal)?.toFixed(2)) : 0.00}
                    </Typography>
                    
                    <Tooltip title="Sub Total - Total Product Formula Cost">
                      <Typography variant="subtitle2" sx={{ cursor:'pointer'}}>
                        <span style={{ color:theme.palette.primary.darker }}>Total Markup Amount: </span>
                        {totalMarkupAmount ? totalMarkupAmount?.toFixed(2) : 0}
                      </Typography>
                    </Tooltip>

                    <Tooltip title="(Total Markup Amount / Total Product Formula Cost) * 100">
                      <Typography variant="subtitle2" sx={{ cursor:'pointer'}}>
                        <span style={{ color:theme.palette.primary.darker }}>Total Markup Percentage: </span>
                        {totalMarkupPercentage ? totalMarkupPercentage?.toFixed(2) : 0}
                        {/* {totalMarkup ? totalMarkup?.toFixed(2) : 0} */}
                      </Typography>
                    </Tooltip>

                    {/* <Typography variant="subtitle2">
                      <span style={{ color:theme.palette.primary.darker }}>Total Service Markup: </span>
                      {totalServiceMarkup ? totalServiceMarkup?.toFixed(2) : 0}
                    </Typography> */}
                    <Typography variant="subtitle2">
                      <span style={{ color:theme.palette.primary.darker }}>Total Discount: </span>
                      {DiscountTotal ? DiscountTotal?.toFixed(2) : 0.00}
                    </Typography>
                  </Stack>    
                  <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    display="flex"
                    justifyContent="space-between"
                  >            
                    <Typography variant="subtitle2">
                    <Label
                      variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                      color={getStatusColorAndLabel(status).color} >
                       {getStatusColorAndLabel(status).label}
                   </Label>
                    </Typography>
                  </Stack>

                </Stack>
              </Grid>
            </Grid>
          </Modal>
        )}
         {/* reject request invoice */}
        { invoiceReject==true &&<Dialog
        open={invoiceReject}
         onClose={()=>{setRejectInvoice(false)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropComponent={Backdrop}
        BackdropProps={{ invisible: true }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          You want to reject this Estimate 
          </DialogContentText>
                 <TextField       
                       sx={{ width: 400 }}
                            label="Reason For Rejection"
                            type="text"
                            multiline
                            minRows={3}
                            maxRows={7} 
                            onInput={(event)=>setReason(event.target.value)}                          
                          />
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained"
            onClick={()=>handleAcceptorRejectInvoice(2)}
           sx={{color:theme.palette.background.paper}}>OK</Button>

          <Button size="small" variant="outlined"
            onClick={()=>{setRejectInvoice(false),setReason('')}}
           sx={{color:theme.palette.error.main}}
           >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>}
       {/* reject request invoice */}
    
        </Container>}
    </Page>
  )
}

const PrintFunction = forwardRef(({ invoiceDate, details, additionalSelectedServices,invoice, subTotal, openInvoice,cartService,invoiceSelectedServices, data,labourInstallService }, ref) => {
  const theme = useTheme();
  const {COMPANY_LOGO,COMPANY_NAME} = useCompanyProfile()
  return (
    <Stack ref={ref}>
      <Grid container spacing={3}>
      <Grid item xs={12} sm={6} sx={{ mb: 5, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Box component="img" alt="logo" id="logoImage" src={COMPANY_LOGO} sx={{ height: 98, alignSelf: 'flex-start' }} />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ mb: 5, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          {/* <Typography variant="h1">Invoice from:</Typography> */}
          <Typography variant="h2">{COMPANY_NAME}</Typography>
          <Typography variant="h2">www.{COMPANY_NAME}.com</Typography>
          <Typography variant="h3">
            {data?.address}<br /> {data?.city}<br /> {data?.state}, {data?.zipcode}
          </Typography>
          <Typography variant="h3">Telephone: +15208362947</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
      {/* <Grid id="right" item xs={12} sm={6} >
        <Box sx={{ mb: 5, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <Typography variant="h3">Order date: {invoiceDate}</Typography>
          
          <Typography variant="h3">Title: {details?.name}</Typography>
          <Typography variant="h3">Order ID: {details?.id}</Typography>
          <Typography variant="h3" sx={{ marginTop: '20px' }}>To: {invoice?.customer_name}</Typography>
          <Typography variant="h3">Phone: {invoice?.customer_phone}</Typography>
          </Box>
        </Grid> */}

        <Grid id="total1"  item xs={12} sm={6} >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>        
        <Box id="order" style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between'  }}>
          <Typography id="discount1" variant="h3" style={{ textAlign:'left'}}>Order date:</Typography>
          <Typography variant="h3">{invoiceDate}</Typography>
        </Box>
        <Box id="title" style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between', maxWidth:250 }}>
          <Typography id="discount1" variant="h3" style={{ textAlign:'left'}}>Title:</Typography>
          <Typography variant="h3">{details?.name}</Typography>
        </Box>
        <Box id="orderID" style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between'  }}>
          <Typography id="discount" variant="h3" style={{ textAlign:'left'}}>Order ID:</Typography>
          <Typography variant="h3">{details?.id}</Typography>
        </Box>
        <Box id="to" style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between' }}>
          <Typography id="totalmain1" variant="h3" >To:</Typography>
          <Typography variant="h3" style={{textAlign:'right'}}>{invoice?.customer_name}</Typography>
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between' }}>
          <Typography id="totalmain1" variant="h3" >Phone:</Typography>
          <Typography variant="h3" style={{textAlign:'right'}}>{invoice?.customer_phone}</Typography>
        </Box>
            
          </Box>
        </Grid>

        <Scrollbar>
              <TableContainer sx={{ minWidth: 420 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>PRODUCT & NOTE</TableCell>
                      <TableCell>UNIT PRICE</TableCell>
                      <TableCell>UNIT</TableCell>
                      <TableCell>QTY</TableCell>
                      {/* <TableCell>MARKETING ADJUSTMENT (%)</TableCell> */}
                      <TableCell>SUB TOTAL</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {invoice?.cust_product?.map((val) => {
              const { vid, product_name,product_notes,locationQuantity, adjustment, material,rfp,unit_qty, product_price,product_retail_price, quantity, product_total, discount,unit,markAdjType,services = [] } = val;
             
                       const labourService = services.find(service => service.labour_cost_service_id === 10);
                       const minusAmountLabourInstall = labourService?.total_cost || 0;

              let SPMAAmount = 0
              if(markAdjType==2 || markAdjType==3) {
                SPMAAmount = (product_retail_price || 0) + (adjustment ? Number(adjustment) : 0)
              }else{
                SPMAAmount = (product_retail_price || 0) + (((product_retail_price || 0) * (adjustment ? Number(adjustment) : 0)) / 100)

              }
              SPMAAmount = parseFloat(SPMAAmount)?.toFixed(2)  
              
              let theSubTotalAmount =markAdjType==1?product_total: (SPMAAmount * (locationQuantity * quantity))
              subTotal += theSubTotalAmount;
              let unitName = UNITS?.filter((item)=>item?.id==unit)
              unitName = unitName[0]?.name
              let shortUnitName = ''
              if(unitName=='Square feet'){
                shortUnitName = 'Sq ft'
              }else if(unitName=='Lineal feet'){
                shortUnitName =  'Lin ft'
              }else if(unitName=='Square Inches'){
                shortUnitName =  'Sq Inches'
              }else if(unitName=='Unit'){
                shortUnitName = 'Unit'
              }
              let SPMA = 0
              if(markAdjType==2 || markAdjType==3){
                SPMA = (product_retail_price || 0) +  (adjustment ? Number(adjustment) : 0)

              }else{
                SPMA = (product_retail_price || 0) + (((product_retail_price || 0) * (adjustment ? Number(adjustment) : 0)) / 100)
              }
              SPMA = parseFloat(SPMA)?.toFixed(2)    
              return (
                <TableRow key={vid} sx={{
                  borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                  '& .productName': {
                    fontWeight: 'bold',
                  },
                }}>
                          {/* <TableCell variant="h4" className="productName">{product_name}</TableCell> */}
                          <TableCell width={'25%'} sx={{flexWrap:'wrap'}}>
                            <Typography variant="h4" className="productName">{product_name}</Typography>
                            {product_notes ? <Typography variant='subtitle2'>Note : {product_notes}</Typography> : ''}
                            {material === 1 ? <Typography variant='subtitle2'>(Addl. Material)</Typography> : ''}
                          </TableCell>
                          <TableCell variant="h4">{SPMA ? SPMA : 0.00} {minusAmountLabourInstall && minusAmountLabourInstall > 0? "(Included Labor Install)" :""}</TableCell>
                          <TableCell variant="h4">{quantity}{shortUnitName}</TableCell>
                          <TableCell variant="h4">{locationQuantity}</TableCell>
                          {/* <TableCell variant="h4">{discount ? discount?.toFixed(2) : 0.00}</TableCell> */}
                          {/* <TableCell variant="h4">{(material === 1 || rfp === 1) ? 0.00 : adjustment ? Number(adjustment)?.toFixed(2) : 0.00}</TableCell> */}
                          
                          {markAdjType==1?
                          <TableCell>{product_total ? (product_total -minusAmountLabourInstall)?.toFixed(2) : 0.00}</TableCell>
                        :
                        <TableCell>{((SPMA * (locationQuantity * quantity))-minusAmountLabourInstall).toFixed(2)}</TableCell>

                        }
                          {/* <TableCell variant="h4">{product_total ? product_total?.toFixed(2) : 0.00}</TableCell> */}
                        </TableRow>
                      )
                    }
                    )}

                    {/* <RowResultStyle>
                      <TableCell colSpan={3} />
                      <TableCell align="center">
                        <Typography variant="h3">Subtotal</Typography>
                      </TableCell>
                      <TableCell align="center" width={10}>
                        <Typography variant="h3">{subTotal}</Typography>
                      </TableCell>

                    </RowResultStyle> */}
                    {/* <RowResultStyle>
                      <TableCell colSpan={3} />
                      <TableCell align="center">
                        <Typography variant="h3">Extra Discount</Typography>
                      </TableCell>
                      <TableCell align="center" width={10}>
                        <Typography variant="h3">{invoice?.extra_discount}</Typography>
                      </TableCell>

                    </RowResultStyle> */}
                  
                    {/* <RowResultStyle>
                      <TableCell colSpan={3} />
                      <TableCell align="center">
                        <Typography variant="h3">Total</Typography>
                      </TableCell>
                      <TableCell align="center" width={10}>
                        <Typography variant="h3">{invoice?.total}</Typography>
                      </TableCell>

                    </RowResultStyle> */}

                  </TableBody>
                </Table>
              </TableContainer>

              {/*below for labour Install */}

                                <TableContainer sx={{ minWidth: 420, mt: 3 }}>
                                  <Table>
                                    {(labourInstallService?.length > 0 ) &&
                                      <TableHead>
                                        <TableRow>
                                          <TableCell colSpan={2}>Services(Labor Install) NAME</TableCell>
                                          <TableCell>COST (HOURLY)</TableCell>
                                          <TableCell>HOURS</TableCell>
                                          <TableCell>TOTAL COST</TableCell>
                                        </TableRow>
                                      </TableHead>}
                                    <TableBody>
                                    
                                    {labourInstallService?.map((ser) => {
                                        console.log('invoiceSelectedServices--@@ 1', ser);
                                        return (
                                          <TableRow sx={{
                                            borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                                          }}>
                                            <TableCell colSpan={2}>{ser?.name}-({ser.productName})</TableCell>
                                            <TableCell>{ser ? ser?.unit_cost?.toFixed(2) : 0}</TableCell>
                                            <TableCell>{ser ? ser?.quantity?.toFixed(2) : 0}</TableCell>
                                            <TableCell>{ser ? ser?.total_cost?.toFixed(2) : 0}</TableCell>
                                          </TableRow>
                                        )
                                      })}
                                      </TableBody>
                                      </Table>
                                      </TableContainer>
              
              {/*above  for labour Install */}

              {(additionalSelectedServices?.length > 0 || invoiceSelectedServices?.length > 0 || cartService?.length > 0) && <Typography variant='h4' sx={{ mt:2 }}>Additional Services</Typography>}
              <TableContainer sx={{ minWidth: 420, mt:3 }}>
                <Table>
                {(additionalSelectedServices?.length > 0 || invoiceSelectedServices?.length > 0 || cartService?.length > 0) && 
                  <TableHead>
                    <TableRow>
                      <TableCell>NAME</TableCell>
                      <TableCell>UNIT COST</TableCell>
                      <TableCell>HOURS</TableCell>
                      <TableCell>TOTAL COST</TableCell>
                    </TableRow>
                  </TableHead>}
                  <TableBody>
                  {openInvoice && invoiceSelectedServices?.map((ser) => {
                  return (
                    <TableRow sx={{
                      borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                    }}>
                      <TableCell>{ser?.name}</TableCell>
                      <TableCell>{ser ? ser?.unit_cost?.toFixed(2) : 0}</TableCell>
                      <TableCell>{ser ? ser?.quantity?.toFixed(2) : 0}</TableCell>
                      <TableCell>{ser ? ser?.total_cost?.toFixed(2) : 0}</TableCell>
                    </TableRow>
                  )
                }
                )}
                      {openInvoice && additionalSelectedServices?.map((ser) => {
                        console.log('additionalSelectedServices--',ser);
                        return(
                        <TableRow sx={{
                          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                        }}>
                          <TableCell>{ser?.name}</TableCell>
                          <TableCell>{ser ? ser?.unit_cost?.toFixed(2) : 0}</TableCell>
                          <TableCell>{ser ? ser?.quantity?.toFixed(2) : 0}</TableCell>
                          <TableCell>{ser ? ser?.total_cost?.toFixed(2) : 0}</TableCell>
                        </TableRow>
                      )
                    }
                    )}
{openInvoice && cartService?.map((ser) => {
                  console.log('cartService--', ser);
                  ser.sellingPrice = ser.sellingPrice || ser.unit_cost
                  ser.originalMarkup = ser.originalMarkup || ser.product_markup
                  return (
                    <TableRow sx={{
                      borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                    }}>
                      <TableCell>{ser?.name}</TableCell>
                      <TableCell> {((ser.sellingPrice + (ser.sellingPrice * ser.originalMarkup / 100)))?.toFixed(2)}</TableCell>
                      <TableCell>{ser ? ser?.qty?.toFixed(2) : 0}</TableCell>
                      <TableCell> {(ser.qty * (ser.sellingPrice + (ser.sellingPrice * ser.originalMarkup / 100)))?.toFixed(2)}</TableCell>
                    </TableRow>
                  )
                }
                )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <Grid id="total"  item xs={12} sm={6} >
        <Box sx={{ mb: 5, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <Box style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between' }}>
          <Typography id="subtotal" variant="h2" >Subtotal</Typography>
          <Typography variant="h2" style={{textAlign:'right'}}>{subTotal ? subTotal?.toFixed(2) : 0.00}</Typography>
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between'  }}>
          <Typography id="discount" variant="h2" style={{ textAlign:'left'}}> ADDITIONAL SERVICES</Typography>
          <Typography variant="h2">{details ? details?.labour_cost?.toFixed(2) : 0.00}</Typography>
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between'  }}>
          <Typography id="discount" variant="h2" style={{ textAlign:'left'}}>Discount</Typography>
          <Typography variant="h2">{invoice ? invoice?.extra_discount?.toFixed(2) : 0.00}</Typography>
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography id="discount" variant="h2" style={{ textAlign: 'left' }}>Tax</Typography>
          <Typography variant="h2">{invoice ? invoice?.tax_amount?.toFixed(2) : 0.00}</Typography>
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between' }}>
          <Typography id="totalmain" variant="h2" >Total</Typography>
          <Typography variant="h2" style={{textAlign:'right'}}>{invoice?.total ? invoice?.total?.toFixed(2) : 0.00}</Typography>
        </Box>
                   
          </Box>
        </Grid>
                      
          </Grid>
          </Stack>
  )
})