import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';

// material
import { useTheme, } from '@material-ui/core/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  ListItemIcon,
  IconButton
} from '@material-ui/core';
// redux
import { useDispatch, useSelector } from 'src/redux/store.js';
import { getUserList, deleteUser } from 'src/redux/slices/user.js';
// import { UserMoreMenu } from 'src/components/_dashboard/user/list/index.js';

// routes
// components
import Page from 'src/components/Page.js';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import Label from 'src/components/Label.js'; 
import Scrollbar from 'src/components/Scrollbar.js';
import SearchNotFound from 'src/components/SearchNotFound.js';
import { UserListHead, UserListToolbar, UserMoreMenu } from 'src/components/_dashboard/user/list/index.js';
import  ProjectGridListViewToolbar  from 'src/components/_dashboard/user/list/ProjectGridListViewToolbar';
import  ProjectListMenu  from 'src/components/_dashboard/user/list/ProjectListMenu';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
import { da } from 'date-fns/locale';
import moment from 'moment';

import ConfirmationPopup from 'src/components/confirmationPopup'



function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const logo =
    'https://www.shutterstock.com/image-photo/checklist-clipboard-task-documentation-management-260nw-2270860299.jpg'

const option = [
    { id: 1, color:'primary', value: 'New' },
    { id: 2, color:'info', value: 'In Progress' },
    { id: 3, color:'success', value: 'Completed' },
    { id: 4, color:'warning', value: 'Hold' },
    { id: 5, color:'error', value: 'Canceled' },
    { id: 6, color:'success', value: 'Approved by Dev team' },
    { id: 7, color:'success', value: 'Approved by the client' },
    { id: 8, color:'primary', value: 'Reopen' },
  ]

// const onboard_data=[
//     {
//         id:0,
//         status:'Pending',
//         name:'Laffety',
//         number:'123456789',
//         address:'California',
//         total:'23925.00 USD',
//         createdon:'12/31/1969',
//     },
//     {
//         id:1,
//         status:'Pending',
//         name:'K Hovnanian',
//         number:'6532611678',
//         address:'Manteca',
//         total:'11990.00 USD',
//         createdon:'12/31/1969',
//     },
//     {
//         id:2,
//         status:'Pending',
//         name:'Chase Brendle',
//         number:'1215165265',
//         address:'San Francisco',
//         total:'24480.00 USD',
//         createdon:'12/31/1969',
//     },
//     {
//         id:3,
//         status:'Pending',
//         name:'Jane Doe',
//         number:'281728173',
//         address:'Sedona',
//         total:'68206.00 USD',
//         createdon:'12/31/1969',
//     },
//     {
//         id:4,
//         status:'Pending',
//         name:'Adam Sandler',
//         number:'3152614141',
//         address:'Miami',
//         total:'14280.00 USD',
//         createdon:'12/31/1969',
//     },
//     {
//         id:5,
//         status:'Pending',
//         name:'Umila Garib',
//         number:'2728738268',
//         address:'West Palm Beach',
//         total:'11990.00 USD',
//         createdon:'12/31/1969',
//     },
// ]

export default function ProjectListView({data,handleDeleteTask,handleOpenTask,popupOn}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDelete, setIsDelete] = useState(false);
  const user = JSON.parse(window.localStorage.getItem('user'))
  const navigate = useNavigate()

  // ----------------------------------------------------------------------

  const TABLE_HEAD = [
      
    { id: 'id', label: 'Task ID', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'count', label: 'Sub Task Count', alignRight: false },
    { id: 'startdate', label: 'Start Date', alignRight: false },
    { id: 'duedate', label: 'Due Date', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    ...(user.user_type === 3
      ? []
      : [{ id: 'options', label: 'Options', alignRight: false }])
            // { id: 'name', label: 'Name', alignRight: false },
            // { id: 2, label: 'Price', alignRight: false },
            // { id: 3, label: 'Added By', alignRight: false },
            // { id:4, label: 'Added On', alignRight: false },        
            // { id:5, label: 'Status', alignRight: false },  
            // {id:6, label: 'Options', alignRight: false}    
  ];

  // ----------------------------------------------------------------------

  // Use an object to store delete confirmation states for each task
  const [deleteConfirmations, setDeleteConfirmations] = useState({});

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = data.map((n) => n.name);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    };
  
    const handleClick = (event, name) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
    };
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    const handleFilterByName = (event) => {
      setFilterName(event.target.value);
    };
  
    const handleDeleteUser = (userId) => {
      dispatch(deleteUser(userId));
    };
  
    const handleDeleteConfirmation = (taskId) => {
      setDeleteConfirmations((prev) => ({ ...prev, [taskId]: false }));
      handleDeleteTask(taskId);
    };
  
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
  
    const filteredUsers = applySortFilter(
      data,
      getComparator(order, orderBy),
      filterName
    );
  
    const isUserNotFound = filteredUsers.length === 0;

  return (
    <Grid container >
    <Grid item xs={12}>
    <Card sx={{
  '@media screen and (max-width: 1920px)': {
    width: '750px'
  },
  '@media screen and (max-width: 1500px)': {
    width: '650px'
  },
  '@media screen and (max-width: 1300px)': {
    width: '610px'
  },
  '@media screen and (max-width: 1270px)': {
    width: '650px'
  },
  '@media screen and (max-width: 692px)': {
    width: '500px'
  },
  '@media screen and (max-width: 500px)': {
    width: '350px'
  },
  '@media screen and (max-width: 300px)': {
    width: '250px'
  }
}}>
          {/* <ProjectGridListViewToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 1000 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={data.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
      {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(
        (row) => {
          const status = Number(row.status);
          return (
            <TableRow hover key={row.id} tabIndex={-1} role="checkbox">
                        <TableCell padding="checkbox">
                          {/* <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, name)} /> */}
                        </TableCell>
                        
                        <TableCell align="center">
                          
                            <Typography variant="subtitle2" noWrap>
                              {row.ID}
                            </Typography>
                          
                        </TableCell>
                        <TableCell sx={{cursor:user.user_type==3 ? 'default' :'pointer'}} onClick={() => {
                          
                          if(popupOn){user.user_type==3 ? null :
                            handleOpenTask(row.ID,row.project_id)

                          }else{user.user_type==3 ? null :
                navigate(PATH_DASHBOARD.project.viewTask+row.ID+'/'+row.project_id)}
              }} align="center"
                        >{row.name}</TableCell>
                        <TableCell align="center">{row.subtask_count}</TableCell>
                        <TableCell align="center">{moment.unix(row.start_date).format('MM/DD/YYYY')}</TableCell>
                        
                        <TableCell align="center">{moment.unix(row.due_date).format('MM/DD/YYYY')}</TableCell>
                        <TableCell align="center">
                          <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            color={option.find(opt=>opt.id===status).color}
                          >
                            {sentenceCase(option.find(opt=>opt.id===status).value)}
                          </Label>
                        </TableCell>
                        {user.user_type==3 ? null :
                        <TableCell align="center">
                <IconButton
                  onClick={() =>
                    setDeleteConfirmations((prev) => ({
                      ...prev,
                      [row.ID]: true,
                    }))
                  }
                >
                  <Icon icon={trash2Outline} width={24} height={24} />
                </IconButton>
                <ConfirmationPopup
                  open={deleteConfirmations[row.ID] || false}
                  handleClick={() => {
                    handleDeleteTask(row.ID);
                    // Add a delay of one second before closing the popup
                    setTimeout(() => {
                      setDeleteConfirmations((prev) => ({
                        ...prev,
                        [row.ID]: false,
                      }));
                    }, 1000);
                  }}
                  message={'Want to Delete This Task'}
                  handleClose={() =>
                    setDeleteConfirmations((prev) => ({
                      ...prev,
                      [row.ID]: false,
                    }))
                  }
                />
              </TableCell>}
                        <ConfirmationPopup open={isDelete} handleClick={()=>handleDeleteTask(row.ID,row.project_id)} message={'Want to Delete This Task'} handleClose={()=>setIsDelete(false)}/>


                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
           rowsPerPageOptions={[10, 25, 50,100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        </Grid>
       </Grid>
  );
}
