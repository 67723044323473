import {Autocomplete, Box, Button, Card, CardHeader, Checkbox, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@material-ui/core";
import { MonetizationOn, Payment } from "@material-ui/icons";
import { MButton } from "src/components/@material-extend";
import Scrollbar from "src/components/Scrollbar";
import { Icon } from '@iconify/react';
import PaymentSearch from "./PaymentSearchbar";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import Modal from "../blog/ProjectModel";
import axios from "axios";
import { REST_API_END_POINT } from "src/constants/Defaultvalues";
import SearchNotFound from "src/components/SearchNotFound";
import PaymentTableHead from "./PaymentTableHead";
import PaymentMenu from "./PaymentMenu";
import { filter } from "lodash";
import { useParams } from "react-router";
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import moment from "moment";
const Table_head=[
  {id:1,label:"Title", alignRight: false},
  {id:2,label:"Amount", alignRight: false},
  {id:3,label:"Added by", alignRight: false},
  {id:3,label:"Payment Mode", alignRight: false},
  {id:3,label:"Payment Source", alignRight: false},
  {id:3,label:"Date", alignRight: false},
  // {id:3,label:"Options", alignRight: false},
]
const data=[
  {id:0,amount:100,title:'dfdf',partner:'Rocky'},
  {id:1,amount:150,title:'dfsfgdsgdf',partner:'Rocky'},
  {id:2,amount:1008,title:'dfdfdfgfdgfdg',partner:'Rocky'},
 ] 

 const paymentMode = [
  {id: 0, name: 'Cash'},
  {id: 1, name: 'Check'},
  {id: 2, name: 'Debit Card'},
  {id: 3, name: 'Credit Card'},
  {id: 4, name: 'Consignment'},
];
 function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
  }
  
  function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis?.map((el) => el[0]);
  }

  const RowResultStyle = styled(TableRow)(({ theme }) => ({
    '& td': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  }));
export default function PaymentRequest({idOfProject,popupOn,totalValue,requestIdFromInstallationView, onUpdate,setLoading,setRefresh,refresh}){
  let user = JSON.parse(localStorage.getItem('user'))
  const explodedPermissions =user?.exploded_permissions
  const userType=user.user_type
  const { enqueueSnackbar } = useSnackbar();
  const [openModal,setOpenModal] = useState(false)
  const [paymentSubmit,setpaymentSubmit] = useState({}) 
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('id');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // let ins = {}
  // ins['id'] = requestIdFromInstallationView || null
  // let {id} = (ins.id !== null) ? ins : useParams()
  // id = popupOn? idOfProject: idd
  let id = requestIdFromInstallationView || useParams().id;
  id = popupOn ? idOfProject : id;
  console.log('id------------------------------',id);
  const [payments,setPayments]= useState(null)
  const [trigger,setTrigger]=useState()
  const[edit,setEdit]=useState(false)
  const[balance,setBalance]=useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [paymentType,setPaymentType]= useState('')

  const handlePaymentTypeChange =(newValue)=>{
    setPaymentType(newValue.name)
  }
  const handleInputChange = (event) => {
    const { name, value} = event.target;
    setpaymentSubmit((prevStageSubmit) => ({
      ...prevStageSubmit,
      [name]: value,
    }));
  };
  console.log("response-----------------", balance);
const fetchAllPayments=async()=>{
  const res = await axios.post(`${REST_API_END_POINT}request/fetch-all-payments`, {request_id:id});
  if(res){
    setPayments(res.data.payments)
    const totalAmount = res.data.payments.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue.amount || 0);
    }, 0);
    const newBalance = (parseFloat(totalValue) - totalAmount).toFixed(2);
    setBalance(newBalance);
  }
}
useEffect(() => {
   fetchAllPayments()
  setTrigger(false)
}, [trigger,openModal])
const handleSubmit = async (event) => {
  try {
    setLoading(true)

    console.log("#############paymentsubmit1",paymentSubmit);
    event.preventDefault();

    if (isSubmitting) return;
    
    let user = JSON.parse(localStorage.getItem('user'))
    paymentSubmit.userId=user.user_id;
    paymentSubmit.requestId=id
    if (paymentSubmit?.title?.trim() === '' || paymentSubmit?.amount?.trim() === '' || !paymentType || !paymentSubmit.title || !paymentSubmit.amount) {
      console.log("#############paymentsubmit1",paymentSubmit.title,paymentSubmit.amount);
      enqueueSnackbar('Please fill all the fields', { variant: 'warning' });
      return;
    } 
    else {
      if (Number(paymentSubmit.amount) <=0) {
        enqueueSnackbar('Please Enter valid Amount', { variant: 'warning' });
        return;
      }

      if (Number(paymentSubmit.amount) > balance) {
        console.log("#############paymentsubmit1222222",balance);
        console.log("#############paymentsubmit1222222",paymentSubmit.amount);
        enqueueSnackbar('Amount should be less than Total', { variant: 'warning' });
        return;
      }
      setIsSubmitting(true); // Disable the button during submission
      let res=null
      console.log("#############paymentsubmit2",paymentSubmit);
      if(edit){
        res = await axios.post(`${REST_API_END_POINT}request/edit-payment`, { paymentSubmit });
      }
      else{
        res = await axios.post(`${REST_API_END_POINT}request/add-payment`, { paymentSubmit,paymentType });
      }
      if (res.data.status === 1) {
        enqueueSnackbar(res.data.message, { variant: 'success' });
        onUpdate(true);
        setTrigger(true);
        setEdit(false)
        setpaymentSubmit({});
      } else {
        enqueueSnackbar(res.data.message, { variant: 'error' });
        onUpdate(true);
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    setIsSubmitting(false); // Re-enable the button after submission
    setLoading(false)
    setRefresh(!refresh)

  }

  setpaymentSubmit({});
  setOpenModal(false);
};

const handleRequestSort = (event, property) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrder(isAsc ? 'desc' : 'asc');
  setOrderBy(property);
  };
const handleSelectAllClick = (event) => {
  if (event.target.checked) {
    const newSelecteds = payments?.map((n) => n.id);
    setSelected(newSelecteds);
    return;
  }
  setSelected([]);
};

const handleClick = (event, id) => {
  const selectedIndex = selected.indexOf(id);
  let newSelected = [];
  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, id);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected?.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
  }
  setSelected(newSelected);
};

const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - payments?.length) : 0;
  
const filteredUsers = applySortFilter(payments, getComparator(order, orderBy), filterName);
  
const isUserNotFound = filteredUsers?.length === 0;

const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};

const handleFilterByName = (event) => {
  setFilterName(event.target.value);
};

    return(
      <>
        <Card sx={{ pb: 3 }}>
        {userType!=3?<>
           <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
         >
          <CardHeader title="Received Payments"  />
          <MButton sx={{mr:4,mt:3}}
          color="primary"
          size="small"
          disabled={(balance > 0) ? false : true}
          variant="contained" onClick={()=>{
            if(explodedPermissions?.some(permission => ['Add New Payment'].includes(permission))) {
              setOpenModal(!false)
            }else{
              enqueueSnackbar("Access Denied: You do not have permission to add payment", { variant: 'error' })
            }
            }}>
         <MonetizationOn /> Add Payments
          </MButton>
        </Stack>
        </>:""}
        <PaymentSearch numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName}/>
        <CardHeader  sx={{ display: 'flex', flexWrap:"nowrap",justifyContent:'flex-end',}}/>
        <Scrollbar>
            <TableContainer sx={{ minWidth: 620 }}>
              <Table>
                <PaymentTableHead
                   order={order}
                   orderBy={orderBy}
                   headLabel={Table_head}
                   rowCount={payments?.length}
                   numSelected={selected.length}
                   onRequestSort={handleRequestSort}
                   onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => {
                    const { id, title, amount,updated_by,timestamp,status,paymentMode,source} = row;
                    const isItemSelected = selected.indexOf(id) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          {/* <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} /> */}
                        </TableCell>
                       
                        <TableCell align="center">
                          <Typography gutterBottom variant="subtitle1">
                           {title}
                          </Typography>
                          </TableCell>
                        <TableCell align="center">{amount}</TableCell>
                          <TableCell align="center"> {updated_by} </TableCell>
                          <TableCell align="center"> {paymentMode} </TableCell>
                          <TableCell align="center"> {source==1? "Software" : "Quickbooks"} </TableCell>
                        <TableCell align="center"> {moment.unix(timestamp).format('MM/DD/YYYY')} </TableCell>
                        {/* {userType!=3?<TableCell align="center">
                          <PaymentMenu onDelete={() => handleDeleteUser(id)} title={title} id={id} status={status} amount={amount} setTrigger={setTrigger} setEdit={setEdit} setpaymentSubmit={setpaymentSubmit}/>
                        </TableCell>:""} */}
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <RowResultStyle sx={{justifyContent:'flex-start',display:'flex'}}>
            <TableCell colSpan={3} />
            <TableCell align="center">
              <Typography variant="subtitle1">Balance Due:{(balance && balance > 0) ?' '+parseFloat(balance).toFixed(2):0}</Typography>
            </TableCell>              
          </RowResultStyle>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50,100]}
            component="div"
            count={payments?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Card>
      <Modal open={openModal||edit} handleClose={()=>setOpenModal(false)} modalTitle={'Payment'}>
      <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} md={8}>
                <Stack spacing={2} direction={'row'}>
                    <TextField
                        name="title" 
                        id="title"
                        label="Title"
                        placeholder="Title"
                        fullWidth 
                        value={paymentSubmit.title}
                       onChange={handleInputChange}
                   
                    />

               <Autocomplete
                 options={paymentMode}
                 fullWidth
                 onChange={(event,newValue)=>{
                  handlePaymentTypeChange(newValue)
                 }} 
                 getOptionLabel={(option) => option.name}
                 renderInput={(params) => (
                 <TextField {...params} label="Payment Mode" variant="outlined" />
                 )}
              
                />
                </Stack>

                <Stack spacing={2} direction={'row'}  mt={2}>

                     <TextField
                        name="amount" 
                        id="amount"
                        type="number"
                        label="Amount"
                        placeholder="Amount"
                        fullWidth 
                        value={paymentSubmit.amount}
                        onChange={handleInputChange}                                
                    />
                </Stack>
                <Box
                  sx={{
                    mt: 3,
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 1,
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting} 
                    onClick={handleSubmit}                  
                  >
                    Save
                  </Button>
                  <Button color="error"
                    variant="outlined"
                    type="submit"
                    onClick={() => {
                      setOpenModal(false)
                      setEdit(false)
                      setpaymentSubmit({})
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
                  </Grid>
                  </Grid>
      </Modal>
      </>
    )
}