import { useRef, useState } from 'react'
import {
  Card,
  Typography,
  Stack,
  ImageList,
  ImageListItem,
  ListSubheader,
  ImageListItemBar,
  Box,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Badge,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ThumbUpAltOutlined, ThumbDownOffAltOutlined, LocationOn } from '@material-ui/icons'
import { sentenceCase } from 'change-case'
import moment from 'moment'
import Scrollbar from 'src/components/Scrollbar'
import {
  ref as imageref,
  uploadBytesResumable,
  getDownloadURL,
} from '@firebase/storage'
import { storage } from 'src/firebase/firebase'
import { REST_API_END_POINT } from 'src/constants/Defaultvalues'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import { LoadingButton } from '@material-ui/lab'
import { UserMoreMenu } from '../user/list'
import Label from 'src/components/Label'

export default function Locations({
  products,
  designs,
  isFullWidth,
  details,
  additionalSelectedServices,
  rfpServices
}) {
  console.log('rfpServicesrfpServices',rfpServices)
  const theme = useTheme()
  return (
    <Card  sx={{...(isFullWidth ? {width:'100%'} : {})}}>
      <Stack sx={{ m: 3 }} spacing={3}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          spacing={{ xs: 2, sm: 3 }}
        >
          {/* <ListSubheader component="div">Locations</ListSubheader> */}
          <Typography variant="h4" sx={{ color: 'text.secondary' }}>
          Location Details</Typography>
        </Stack>
        <Divider/>
        {(products?.length > 0) ? (
          <Box sx={{ px: 3 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>Note</TableCell>
                    <TableCell align='center' >Location</TableCell>
                    <TableCell>Qty</TableCell>
                    <TableCell>Workflow</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products?.map((item, index) => (
                    <>
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                          {item.product_name}
                        </Typography>
                        {item?.material ==1 ? <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                           (Material)
                        </Typography> : ''}
                      </TableCell>
                      <TableCell width={5}>
                        <Typography variant="body2" sx={{ color: 'text.secondary', flexWrap:'wrap' }}>
                          {item?.product_notes}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {item.location.map((l,i)=> (
                          // <Badge badgeContent={item?.location_quantity} color={'primary'}>
                            <Card key={i} sx={{ p: 2, mb: 1}}>
                              <Stack direction={'row'} spacing={1}>
                                {<LocationOn sx={{ color: theme.palette.error.main }}/>}
                                <Typography variant="caption" sx={{ color: theme.palette.primary.main }}>
                                  {l.address+', '+ l.city +', ' +l.state +', ' +l.zip}
                                </Typography>
                              </Stack>
                            </Card>
                          // </Badge>
                        ))}
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                          {item.location_quantity}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {item.workflow_name ?
                        <Card sx={{ p: 2}}>
                          <Typography  variant="h6" sx={{ color: 'text.secondary',cursor:'pointer' }}>
                            {item.workflow_name}
                          </Typography>
                        </Card> : ''}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Divider />
                      </TableCell>
                    </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          ''
        )}
        {(
          // (details?.job_category_name=='Service only' || details?.job_category_name=='Time & Material') && 
          additionalSelectedServices?.length > 0) ? (
          <Box sx={{ px: 3 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Service</TableCell>
                    <TableCell align='center'>Location</TableCell>
                    <TableCell>Qty</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {additionalSelectedServices?.map((item, index) => (
                    <>
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                          {item.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {/* {item.addresses.map((l,i)=> ( */}
                          {/* // <Badge badgeContent={item?.location_quantity} color={'primary'}> */}
                            <Card sx={{ p: 2, mb: 1}}>
                              <Stack direction={'row'} spacing={1}>
                                {(item.address || item.city || item.state || item.zip) ? 
                                <>
                                  <LocationOn sx={{ color: theme.palette.error.main }}/>
                                  <Typography variant="caption" sx={{ color: theme.palette.primary.main }}>
                                    {item.address+', '+ item.city +', ' +item.state +', ' +item.zip}
                                  </Typography>
                                </> : 
                                  <Typography variant="caption" sx={{ color: theme.palette.text.disabled }}>
                                    N/A
                                  </Typography>
                                }
                              </Stack>
                            </Card>
                          {/* // </Badge> */}
                        {/* ))} */}
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                          {item.quantity}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Divider />
                      </TableCell>
                    </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          ''
        )}

        {(
          // (details?.job_category_name=='Service only' || details?.job_category_name=='Time & Material') && 
        rfpServices?.length > 0) ? (
          <Box sx={{ px: 3 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Proposal service</TableCell>
                    <TableCell align='center' >Location</TableCell>
                    <TableCell>Qty</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rfpServices?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                          {item.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {/* {item.addresses.map((l,i)=> ( */}
                          {/* // <Badge badgeContent={item?.location_quantity} color={'primary'}> */}
                            <Card sx={{ p: 2, mb: 1}}>
                              <Stack direction={'row'} spacing={1}>
                                {<LocationOn sx={{ color: theme.palette.error.main }}/>}
                                <Typography variant="caption" sx={{ color: theme.palette.primary.main }}>
                                  {item.address+', '+ item.city +', ' +item.state +', ' +item.zip}
                                </Typography>
                              </Stack>
                            </Card>
                          {/* // </Badge> */}
                        {/* ))} */}
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                          {item.quantity}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          ''
        )}
      </Stack>
    </Card>
  )
}
