import PropTypes from 'prop-types'
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles'
import { Avatar, Box, Card, FormHelperText, Typography } from '@material-ui/core'
// hooks
import useAuth from '../../../../hooks/useAuth'
import { capitalCase } from 'change-case'
//
import MyAvatar from '../../../MyAvatar'
import { REST_API, REST_API_END_POINT } from 'src/constants/Defaultvalues'
import { UploadAvatar } from 'src/components/upload'
import { useCallback, useEffect, useState } from 'react'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from 'src/firebase/firebase'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import blank from 'src/Gif/blank_img.jpg'
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  '&:before': {
    top: 0,
    zIndex: 9,
    width: '100%',
    content: "''",
    height: '100%',
    position: 'absolute',
    //  backdropFilter: 'blur(3px)',
    WebkitBackdropFilter: 'blur(3px)', // Fix on Mobile
    //  backgroundColor: alpha(theme.palette.primary.darker, 0.72),
  },
}))

const InfoStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: 'absolute',
  marginTop: theme.spacing(5),
  [theme.breakpoints.up('md')]: {
    right: 'auto',
    display: 'flex',
    alignItems: 'center',
    left: theme.spacing(3),
    bottom: theme.spacing(3),
  },
}))

const CoverImgStyle = styled('img')({
  zIndex: 8,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
})

// ----------------------------------------------------------------------

ProfileCover.propTypes = {
  myProfile: PropTypes.object,
  teamData: PropTypes.object,
  userData: PropTypes.object,
}

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  textAlign:'center',
  marginBottom: theme.spacing(1)
}));
export default function ProfileCover({ myProfile, teamData, userData,coverImage }) {
  // const { user } = useAuth();
  const { cover } = myProfile
  const [progress,setProgress] = useState(null)
  const {enqueueSnackbar}=useSnackbar()
  const [imgURL,setimageURL] = useState('')

  const updateProfilePic=async(imgURL)=>{
    let response;
    if (teamData) {
      response = await axios.post(`${REST_API_END_POINT}teams/update-profile-pic`,{
        userId:userData.user_id,imgURL:imgURL
      })
    }
    else {
      response = await axios.post(`${REST_API_END_POINT}clients/update-pic`,{
        userid:userData.user_id,imgURL:imgURL
      })
    }

    if(response.data.status == 1){
      userData.profile_pic=imgURL
      localStorage.removeItem('user')
      localStorage.removeItem('sidebar_order');
      localStorage.removeItem('permission_data');
      if(!localStorage.getItem('user')){
        localStorage.setItem('user',JSON.stringify( userData))
      }
      enqueueSnackbar(response.data.message,{variant:'success'})
    }else{
      enqueueSnackbar(response.data.message,{variant:'error'})
    }
  
  }
 useEffect(()=>{
  setimageURL(userData.profile_pic)
 },[])

  const handleDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const file = acceptedFiles[0]; 
        const Filename = new Date().getTime() + file.name;
  
        const StorageRef = ref(storage, Filename);
        const uploadTask = uploadBytesResumable(StorageRef, file);
  
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setProgress(progress);
          },
          (error) => {
            console.error(error.message);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setimageURL(downloadURL)
              updateProfilePic(downloadURL)
              console.log(downloadURL, '&&&&&&&&&&&&&&&&&&&');
            });
          }
        );
      } 
      
    },
    [setimageURL]
  );
  return (
    <RootStyle>
      <InfoStyle>
        {userData.user_type == 3 || teamData ? (
          <Card sx={{borderRadius:500}}>
          <UploadAvatar
            accept="image/*"
             file={imgURL}
            maxSize={3145728}
            type="file"
            onDrop={handleDrop}
          />

        </Card>
        ) : (
          <Avatar
            alt={userData.first_name}
            src={`${REST_API}build/images/team_members/${userData.profile_pic}`}
            sx={{
              mx: 'auto',
              borderWidth: 2,
              borderStyle: 'solid',
              borderColor: 'common.white',
              width: { xs: 80, md: 128 },
              height: { xs: 80, md: 128 },
            }}
          />
        )}
        <Box
          sx={{
            ml: { md: 3 },
            mt: { xs: 1, md: 0 },
            color: 'common.white',
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          <Typography variant="h4">
            {userData.first_name} {userData.last_name}
          </Typography>
          <Typography sx={{ opacity: 0.72 }}>
            {userData.isVendor ===1 ? 'Vendor': userData?.user_type == 3 ? 'Client' : capitalCase(userData.user_role)}
          </Typography>
        </Box>
      </InfoStyle>
      <CoverImgStyle alt="profile cover" src={coverImage || blank} />
    </RootStyle>
  )
}
