import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import roundSend from '@iconify/icons-ic/round-send';
import heartFill from '@iconify/icons-eva/heart-fill';
import shareFill from '@iconify/icons-eva/share-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import messageSquareFill from '@iconify/icons-eva/message-square-fill';
import roundAddPhotoAlternate from '@iconify/icons-ic/round-add-photo-alternate';
// material
import {
  Box,
  Link,
  Card,
  Stack,
  Paper,
  Avatar,
  TextField,
  Typography,
  CardHeader,
  IconButton,
  AvatarGroup,
  InputAdornment,
  FormControlLabel
} from '@material-ui/core';
// utils
import { fDate } from '../../../../utils/formatTime';
import { fShortenNumber } from '../../../../utils/formatNumber';
// hooks
import useAuth from '../../../../hooks/useAuth';
//
import MyAvatar from '../../../MyAvatar';
import EmojiPicker from '../../../EmojiPicker';
import { MCheckbox } from '../../../@material-extend';
import { ChatSidebar, ChatWindow } from '../../chat';

// ----------------------------------------------------------------------

PublicMessageBoard.propTypes = {
  post: PropTypes.object
};



export default function PublicMessageBoard({ products,acceptedClients,requestDetails,fromDesign,isDesignOrder,renderCards,setRenderCards,fetchRequestDetails,details }) {
  let user = JSON.parse(localStorage.getItem('user'))
  const userType=user.user_type
  const userId=user.user_id

  return (
   
    <Card sx={{ height: '72vh', display: 'flex' }}>
      {/* {userType ==1 && */}
    {/* // <ChatSidebar conversationUser={acceptedClients} /> } */}
    <ChatWindow products={products} isDesignOrder={isDesignOrder} requestDetails={requestDetails} allUser={acceptedClients} fromDesign={fromDesign} setRenderCards={setRenderCards} renderCards={renderCards} fetchRequestDetails={fetchRequestDetails}  details={details}/>
  </Card>
    //

  );
}
