import * as Yup from 'yup'
import axios from 'axios'
import { Icon } from '@iconify/react'
import trash2Outline from '@iconify/icons-eva/trash-2-outline'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { capitalCase, sentenceCase } from 'change-case'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Form, FormikProvider, useFormik } from 'formik'
import {
  Container,
  Grid,
  IconButton,
  Stack,
  Box,
  Button,
  TableRow,
  TableBody,
  Checkbox,
  Card,
  TableCell,
  Typography,
  TableContainer,
  Table,
  TablePagination,
  TextField,
  Switch,
  FormControlLabel,
  Autocomplete,
  Divider,
  Paper,
  Popover,
  CardHeader,
  TableHead,
  List,
  Menu,
  MenuItem,
  ListItemText,
  ListItem,
  Tabs,
  Tab,
  Tooltip,
  CircularProgress,
  Badge,
} from '@material-ui/core'
import CloseIcon from '@iconify/icons-eva/close-fill'
import {
  useTheme,
  experimentalStyled as styled,
} from '@material-ui/core/styles'
import plusFill from '@iconify/icons-eva/plus-fill'
// redux
import {
  LoadingButton,
  MobileDatePicker,
  MobileDateRangePicker,
} from '@material-ui/lab'
// routes
// components
import Page from '../../components/Page'
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs'
import Modal from '../../components/_dashboard/blog/ProjectModel'
import Label from 'src/components/Label'
import { useSnackbar } from 'notistack'
import {
  Delete,
  UploadFile,
  LocationOn,
  ExpandMore,
  ExpandLess,
  Remove,
  Add,
  NoteAdd,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons'
import OfficialRequestSearch from 'src/components/_dashboard/official-request/OfficialRequestSearch'
import InfiniteScroll from 'react-infinite-scroll-component'
import OfficialCard from 'src/components/_dashboard/official-request/OfficialCard'
import Scrollbar from 'src/components/Scrollbar'
import moment from 'moment'
import {
  REST_API,
  REST_API_END_POINT,
  UNITS,
} from 'src/constants/Defaultvalues'
import { UploadAvatar, UploadMultiFile } from 'src/components/upload'
import { storage } from '../../firebase/firebase'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import ReactToPrint from 'react-to-print'
import { empty } from 'src/Helpers/Common'
import LoadingScreen from 'src/components/LoadingScreen'
import SearchNotFound from 'src/components/SearchNotFound'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const getStatusColorAndLabel = (status) => {
  switch (status) {
    case 1:
      return { color: 'warning', label: 'Status: Draft' }
    case 2:
      return { color: 'info', label: 'Status: Open' }
    case 3:
      return { color: 'primary', label: 'Status: Accepted' } // Evaluating
    case 4:
      return { color: 'success', label: 'Status: In Production' } // Converted to Job
    case 5:
      return { color: 'warning', label: 'Status: Closed' }
    case 6:
      return { color: 'error', label: 'Status: Rejected' }
    default:
      return { color: 'default', label: 'Status: Open' }
  }
}

const markupTypes = [
  { id: 1, name: 'Wholesale Markup' },
  { id: 2, name: 'Retail Markup' },
]
const marketingAdjustmentType = [
  { id: 1, name: '%' },
  { id: 2, name: '$' },
  // { id: 3, name: '¢' }
]

//sorting
const SORT = [
  { id: 'new', label: 'Newest', color: '#54D62C' },
  { id: 'old', label: 'Oldest', color: '#ffff00' },
  { id: 'last_updated', label: 'Recently Updated', color: '#ffff00' },
  { id: 'custom_order', label: 'Custom Order', color: '#ffff00' },
]

const MAX_CHARACTERS = 35

export default function OfficialRequestAdd({
    open,
    searching,
    setOpen,
    page,
    setPage,
    preview,
    setPreview,
    rowsPerPage,
    setRowsPerPage,
    totalCount,
    setTotalCount,
    allCount,
    setAllCount,
    acceptedCount,
    setAcceptedCount,
    openCount,
    setOpenCount,
    inProductionCount,
    setinProductionCount,
    completedCount,
    setCompletedCount,
    installsCount,
    setInstallsCount,
    pickupCount,
    setPickupCount,
    shippingCount,
    setShippingCount,
    allRequest,
    setAllRequest,
    loadingCards,
    setLoadingCards,
    skillServices,
    setSkillServices,
    allProducts,
    setAllproducts,
    materials,
    setAllMaterials,
    labourCostServices,
    setLabourCostServices,
    filteredServices,
    setFilteredServices,
    requestTeam,
    setRequestTeam,
    openPreview,
    setOpenPreview,
    isSaving,
    setIsSaving,
    selectedProducts,
    setSelectedProducts,
    selectedMaterials,
    setSelectedMaterials,
    selectedServices,
    setSelectedServices,
    additionalSelectedServices,
    setAdditionalSelectedServices,
    avatarUrl,
    setAvatarUrl,
    location,
    setLocation,
    projectTypes,
    setProjectTypes,
    workflow,
    setWorkFlow,
    projectCategories,
    setProjectCategories,
    noChargeType,
    setNoChargeType,
    communities,
    setCommunities,
    isCommunityLoading,
    setIsCommunityLoading,
    locationStates,
    setLocationStates,
    serviceOnlyLocationStates,
    setServiceOnlyLocationStates,
    rfpLocationStates,
    setRfpLocationStates,
    rfpServiceLocationStates,
    setRfpServiceLocationStates,
    allLocationSame,
    setAllLocationSame,
    allServiceLocationSame,
    setAllServiceLocationSame,
    allRfpServiceLocationSame,
    setAllRfpServiceLocationSame,
    allRfpProductsLocationSame,
    setAllRfpProductsLocationSame,
    address,
    setAddress,
    progress,
    setProgress,
    currentDate,
    setCurrentDate,
    user,
    userType,
    explodedPermissions,
    hasMarkupPermission,
    hasDiscountPermission,
    allClients,
    setAllClients,
    communitiesOpen,
    setCommunitiesOpen,
    communitiesData,
    setCommunitiesData,
    filterCommunity,
    setFilterCommunity,
    markupType,
    setMarkupType,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    showServices,
    setShowServices,
    showWarning,
    setShowWarning,
    currentTab,
    setCurrentTab,
    indexCounter,
    setIndexCounter,
    serviceIndexCounter,
    setServiceIndexCounter,
    rfps,
    setRfps,
    selectedRfp,
    setSelectedRfp,
    openModal,
    setOpenModal,
    tabIndex,
    setTabIndex,
    rfpServices,
    setRfpServices,
    rfpProducts,
    setRfpProducts,
    cart,
    setCart,
    cartService,
    setCartService,
    totalProductLabourCost,
    filterDateValue,
    setFilterDateValue,
    sort,
    setSort,
    sortOpen,
    setSortOpen,
    noCharge,
    setNoCharge,
    openProductNote,
    setOpenProductNote,
    isSavingNote,
    setIsSavingNote,
    currentNote,
    setCurrentNote,
    selectedTaxCategory,
    setSelectedTaxCategory,
    taxCategories,
    setTaxCategories,
    isSubmitted,
    setIsSubmitted,
    allId,
    setAllId,
    mapServices,
    setMapServices,
    mapServicesLoading,
    setMapServicesLoading,
    selectedMapserviceProducts,
    setSelectedMapserviceProducts,

    newTotalAmount,
    setNewTotalAmount,
    newTotalMarkup,
    setNewTotalMarkup,
    newTotalProductFormula,
    setNewTotalProductFormula,
    newTotalWholesalePrice,
    setNewTotalWholesalePrice,
    newTotalRetailPrice,
    setNewTotalRetailPrice,
    newOriginalTotalMarkup,
    setNewOriginalTotalMarkup,
    newOriginalTotalServiceMarkup,
    setNewOriginalTotalServiceMarkup,
    newTotalServiceMarkup,
    setNewTotalServiceMarkup,
    newTotalDiscount,
    setNewTotalDiscount,
    newDiscountTotal,
    setNewDiscountTotal,
    newTotalJobcostSum,
    setNewTotalJobcostSum,
    newTotalsum,
    setNewTotalsum,
    newGrandTotal,
    setNewGrandTotal,

    newValues,
    setNewValues,
}) {
    const theme = useTheme()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const refCommunities = useRef(null);
    const refSort = useRef(null);
      const [showAddNewRow,setShowAddNewRow] = useState(false)
    

    const NewUserSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        description: Yup.string().required('Description is required'),
        // address: Yup.string().required('Address is required'),
        // city: Yup.string().required('City is required'),
        // state: Yup.string().required('State is required'),
        // zip: Yup.string().required('Zip/Postal Code is required'),
        // skills:userType!=3 && Yup.array().min(1, 'Skill is required'),
        // clients:userType!=3 && Yup.array().min(1, 'Clients is required'),
        clients: userType != 3 && Yup.object().nullable().required('Clients is required'),
        // workflows:userType!=3 && Yup.array().min(1, 'workflow is required'),
        start: Yup.mixed().required('Start Date is required'),
        end: Yup.date()
          .nullable()
          .transform((value, originalValue) => {
            return originalValue ? new Date(originalValue * 1000) : null;
          })
          .when('start', (start, schema) => {
            return start
              ? schema.min(
                start,
                'Installation Date must be greater than the Start Date'
              )
              : schema;
          }),
        products: userType != 3 && Yup.array(),
        //.min(1, 'At least one product is required')
        // services:userType!=3 && Yup.array(), 
        project_type: userType != 3 && Yup.object().nullable().required('Job Class is required'),
        markup_type: userType != 3 && Yup.object().nullable().required('Please select the markup type'),
        project_categories: userType != 3 && Yup.object().nullable().required('Job Type is required'),
        map_service: Yup.mixed().when('project_categories', {
          is: (project_categories) => userType !== 3 && project_categories?.id == 14,
          then: Yup.object().nullable().required('Please select the map service'),
          otherwise: Yup.mixed().nullable(),
        }),
        community: userType != 3 && Yup.object().nullable().required('Project is required,Enable projects for client'),
    });
    
    const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    //   marginBottom: theme.spacing(1)
    }));

    const LabelStyleHead = styled(Typography)(({ theme }) => ({
    ...theme.typography.h5,
    color: theme.palette.text.secondary,
    }))
    
    const load = useLocation().state

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            description: '',
            notes: '',
            // skills: [],
            clients: null,
            // workflows:[],
            start: currentDate,
            end: currentDate,
            // avatarUrl: [],
            products: [],
            services: [],
            additionalMaterials: [],
            labour_cost: 0,
            budget: 0,
            sub_total: 0,
            invoice_discount: null,
            welcome_message: null,
            draft: null,
            client_approve: userType !==3 ? false : true,
            total: 0,
            taxAmount: 0,
            subject: null,
            email_content: null,
            map_service: null,
            project_categories: null,
            project_type: null,
            markup_type:  markupTypes[1] ||null,
            no_charge_type: null,
            community: null,
            // address:null,
            // city:null,
            // state:null,
            // zip:null,
            rfp:null,
        },
        validationSchema: NewUserSchema,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
            try {
            setIsSubmitted(true);
            // if(values.project_categories?.name == 'Time & Material'){
              const hasEmptyProductName = selectedProducts?.some(product => product.name.trim() === '');
              const hasEmptyServiceName = additionalSelectedServices?.some(product => product.name.trim() === '');
              const hasEmptyMaterialName = selectedMaterials?.some(product => product.name.trim() === '');
              if (hasEmptyProductName) {
                enqueueSnackbar('Please fill in all required product names.', { variant: 'error' });
                return;
              }
              if (hasEmptyServiceName) {
                enqueueSnackbar('Please fill in all required service names.', { variant: 'error' });
                return;
              }
              if (hasEmptyMaterialName) {
                enqueueSnackbar('Please fill in all required material names.', { variant: 'error' });
                return;
              }
              const hasInvalidProductName = selectedProducts?.filter(item=>item?.isAdditionalProduct == true)?.some(product => {
                const trimmedName = product.name.trim();
                if (trimmedName === '.') {
                  enqueueSnackbar('Product name cannot be just a dot.', { variant: 'error' });
                  return true;
                }
                if (trimmedName === '-') {
                  enqueueSnackbar('Product name cannot be just a hyphen.', { variant: 'error' });
                  return true;
                }
                // if (!/^[a-zA-Z0-9\s-]+$/.test(trimmedName)) {
                //   enqueueSnackbar('Product name can only contain letters, numbers, and hyphens.', { variant: 'error' });
                //   return true;
                // }
                if (/[:]/.test(trimmedName)) {
                  enqueueSnackbar('Product name cannot contain colon (:).', { variant: 'error' });
                  return true;
                }
                if (trimmedName[0] === '.') {
                  enqueueSnackbar('Product name cannot start with a dot.', { variant: 'error' });
                  return true;
                }
                if (trimmedName[0] === '-') {
                  enqueueSnackbar('Product name cannot start with a hyphen.', { variant: 'error' });
                  return true;
                }
                if (trimmedName === '') {
                  enqueueSnackbar('Product name cannot be empty or just spaces.', { variant: 'error' });
                  return true;
                }
                return false;
              });
              
              const hasInvalidServiceName = additionalSelectedServices?.filter(item=>item?.isAdditionalProduct == true)?.some(product => {
                const trimmedName = product.name.trim();
                if (trimmedName === '.') {
                  enqueueSnackbar('Service name cannot be just a dot.', { variant: 'error' });
                  return true;
                }
                if (trimmedName === '-') {
                  enqueueSnackbar('Service name cannot be just a hyphen.', { variant: 'error' });
                  return true;
                }
                if (/[:]/.test(trimmedName)) {
                  enqueueSnackbar('Service name cannot contain colon (:).', { variant: 'error' });
                  return true;
                }                
                if (trimmedName[0] === '.') {
                  enqueueSnackbar('Service name cannot start with a dot.', { variant: 'error' });
                  return true;
                }
                if (trimmedName[0] === '-') {
                  enqueueSnackbar('Service name cannot start with a hyphen.', { variant: 'error' });
                  return true;
                }
                if (trimmedName === '') {
                  enqueueSnackbar('Service name cannot be empty or just spaces.', { variant: 'error' });
                  return true;
                }
                return false;
              });
              
              const hasInvalidMaterialName = selectedMaterials?.filter(item=>item?.isAdditionalProduct == true)?.some(product => {
                const trimmedName = product.name.trim();
                if (trimmedName === '.') {
                  enqueueSnackbar('Material name cannot be just a dot.', { variant: 'error' });
                  return true;
                }
                if (trimmedName === '-') {
                  enqueueSnackbar('Material name cannot be just a hyphen.', { variant: 'error' });
                  return true;
                }
                if (/[:]/.test(trimmedName)) {
                  enqueueSnackbar('Material name cannot contain colon (:).', { variant: 'error' });
                  return true;
                }                
                if (trimmedName[0] === '.') {
                  enqueueSnackbar('Material name cannot start with a dot.', { variant: 'error' });
                  return true;
                }
                if (trimmedName[0] === '-') {
                  enqueueSnackbar('Material name cannot start with a hyphen.', { variant: 'error' });
                  return true;
                }
                if (trimmedName === '') {
                  enqueueSnackbar('Material name cannot be empty or just spaces.', { variant: 'error' });
                  return true;
                }
                return false;
              });
              
              if (hasInvalidProductName || hasInvalidServiceName || hasInvalidMaterialName) {
                // enqueueSnackbar('Product names must contain letters or numbers.', { variant: 'error' });
                return;
              }
            // }
            let user = JSON.parse(localStorage.getItem('user'));
            const userType = user.user_type;
            const userId = user.user_id;
            const userName = user.first_name;
            const clientsArray = values.clients ? [values.clients] : [];

            values.map_service = (values.project_type?.name == 'TS' && (values.project_categories && values.project_categories?.id == 14)) ? values.map_service : null

            values.clientsData = clientsArray
            values.avatarUrl = avatarUrl
            const markupTotal = markupType == 1 ? (((values.totalWholesalePrice - values.totalProductFormula) / values.totalProductFormula) * 100)?.toFixed(2) : (((values.totalRetailPrice - values.totalProductFormula) / values.totalProductFormula) * 100)?.toFixed(2)
            values.totalMarkup = markupTotal
            values.products = selectedProducts
            const allServices = [...selectedServices, ...additionalSelectedServices];
            values.selectedServices = allServices
            const servicesIdArray = allServices?.map(service => service.id);
            values.serviceData = servicesIdArray?.join(',')

            const prodAddress= selectedProducts.reduce((acc, product) => {
                return [
                ...acc,
                ...product.addresses.map((address) => ({
                    ...address,
                    productId: product.id,
                    productIndex: product.indexId,
                    isService:0
                })),
                ];
            }, []);
            const serviceAddress= additionalSelectedServices?.reduce((acc, product) => {
                return [
                ...acc,
                ...product.addresses.map((address) => ({
                    ...address,
                    productId: product.id,
                    productIndex: product.indexId,
                    isService:1
                })),
                ];
            }, []);

            const combinedAddress = [...prodAddress,...serviceAddress]

            const requestData = {
                values,
                userId,
                userType,
                userName,
                taxCategory : selectedTaxCategory?.id,
                allLocationSame,
                allServiceLocationSame,
                allRfpServiceLocationSame,
                allRfpProductsLocationSame,
                rfpProducts: cart,
                rfpServices: cartService,
                materials:selectedMaterials,
                address: combinedAddress

                // address: (values.project_categories?.name == 'Service only' || values.project_categories?.name == 'Time & Material') ? serviceAddress : prodAddress
            };

            let isvalidationNotOk = false
            let isServicevalidationNotOk = false
            // if((values.project_categories?.name == 'Service only') || (values?.project_categories?.name == 'Time & Material')){
                isServicevalidationNotOk = additionalSelectedServices?.find(product => {
                if (product.addresses.length && product.addresses.some(obj =>
                    [obj.address, obj.city, obj.state, obj.zip].some(value => (value === '' || value === null))
                )) {
                    return true
                } else {
                    return false
                }
                })
            // }
            if((values.project_categories?.name != 'Service only') && (values?.project_categories?.name != 'Time & Material')){
                isvalidationNotOk = selectedProducts?.find(product => {
                if (product.addresses.length && product.addresses.some(obj =>
                    [obj.address, obj.city, obj.state, obj.zip].some(value => (value === '' || value === null))
                )) {
                    return true
                } else {
                    return false
                }
                })
            }
            const isvalidationNotOkRfp = cart?.find(product => {
                if (product.addresses.length > 0 && product.addresses.some(obj =>
                [obj.address, obj.city, obj.state, obj.zip].some(value => (value === '' || value === null))
                )) {
                console.log("Validation failed for product:", product);
                return true;
                } else {
                console.log("Validation passed for product:", product);
                return false;
                }
            });

            let isvalidationNotOkRfpServices= false
            // if(values.project_categories?.name == 'Service only' || (values?.project_categories?.name == 'Time & Material')){
                isvalidationNotOkRfpServices = cartService?.find(product => {
                if (product.addresses.length > 0 && product.addresses.some(obj =>
                    [obj.address, obj.city, obj.state, obj.zip].some(value => (value === '' || value === null))
                )) {
                    console.log("Validation failed for product:", product);
                    return true;
                } else {
                    console.log("Validation passed for product:", product);
                    return false;
                }
                });
            // }
            if(userType != 3 &&
                (values?.project_categories?.name == "Time & Material") && 
                // (cart.length <= 0 && cartService?.length <= 0 && additionalSelectedServices?.length <= 0 && selectedProducts?.length <= 0)
                (cartService?.length <= 0 && additionalSelectedServices?.length <= 0)
            ){
                enqueueSnackbar('Please choose atleast one Service/ Proposal service', { variant: 'error' });
            }else if((values?.project_categories?.name != "Time & Material") && values.total <= 0 && userType != 3){
                enqueueSnackbar('Please choose atleast one product/ service/ Proposal', { variant: 'error' });
            }else if (
                isvalidationNotOk || isServicevalidationNotOk ||
                isvalidationNotOkRfp || 
                // (isvalidationNotOkRfpServices && values.project_categories?.name == 'Service only' ) ||
                // (isvalidationNotOkRfpServices && values.project_categories?.name == 'Time & Material' )
                (isvalidationNotOkRfpServices && cartService?.length > 0 )
            ) {
                enqueueSnackbar('Please fill all the address fields', { variant: 'error' });
            } else {

            //   if(values?.project_categories?.name =="Time & Material"){
            //  const productResponse = await axios.post(`${REST_API_END_POINT}product/add-product-on-request`,{values})
            
            //  if(productResponse) {
            //   await axios.post(`${REST_API}webservice/request/add-request`, requestData)
            //   .then((res) => {
            //     setOpen(false);
            //     resetForm();
            //     setSelectedProducts([]);
            //     setSelectedMapserviceProducts([]);
            //     setSelectedServices([]);
            //     setAdditionalSelectedServices([])
            //     setFilteredServices([])
            //     setRfps([])
            //     setSelectedTaxCategory(null);
            //     setSelectedRfp(null)
            //     setRfpServices([])
            //     setRfpProducts([])
            //     setCart([])
            //     setCartService([])
            //     setSelectedMaterials([])
            //     setAvatarUrl([])
            //     enqueueSnackbar('Create success', { variant: 'success' });
            //   })
            //   .catch((errors) => {
            //     console.log(errors, 'error');
            //   });

            //  }   
            
            //   }else{
                await axios.post(`${REST_API}webservice/request/add-request`, requestData)
                .then((res) => {
                    setOpen(false);
                    resetForm();
                    setSelectedProducts([]);
                    setSelectedMapserviceProducts([]);
                    setSelectedTaxCategory(null);
                    setSelectedServices([]);
                    setAdditionalSelectedServices([])
                    setFilteredServices([])
                    setRfps([])
                    setSelectedRfp(null)
                    setRfpServices([])
                    setRfpProducts([])
                    setCart([])
                    setCartService([])
                    setSelectedMaterials([])
                    setAvatarUrl([])
                    enqueueSnackbar('Create success', { variant: 'success' });
                    const reqResult = res?.data?.reqresult;
                      if (reqResult && Array.isArray(reqResult.data) && reqResult.data.length > 0) {
                          const partnerId = reqResult.data[0]?.rt_partner_id;
                          
                          if (requestData.values.project_categories?.name === "Time & Material" && requestData.values.client_approve === false) {
                              handleConvertToJob(res?.data?.result,partnerId)
                          }
                      }
                  })
                .catch((errors) => {
                    console.log(errors, 'error');
                });

                // }
            
            }
            } catch (error) {
            console.error(error);
            }
        }
    });
    
    console.log('setSelectedProducts', selectedProducts)
    const handleConvertToJob = async (requestId,requestPartnerId) => {
      try {
        // setOpenConvertToRFQ(false);
        // setConvertToRFQ(true);
        // let requestPartnerId = details?.rt_partner_id
        let acceptStatus = 1
        let userId = user.user_id
  
        const data = { requestId, requestPartnerId, acceptStatus, userId }
        const res = await axios.post(`${REST_API}webservice/request/accept-or-reject-invoice`, data);
  
        if (res.data.status == 1) {
          // setConfirmInvoice(false)
          // setRejectInvoice(false)
          enqueueSnackbar('Job created successfully', { variant: 'success' });
          // fetchRequestDetailsNew()
          // fetchRequestDetails()
          // if(details?.job_category_name== "Service only" || details?.job_category_name == 11){
          //   handleConvertJobToInstallation(requestId)
          // }
        } else {
          // setConfirmInvoice(false)
          // setRejectInvoice(false)
          enqueueSnackbar('Failed to convert Sales Order to Job', { variant: 'error' });
        }
      } catch (error) {
        console.log(error, 'error in Converting Sales Order to job')
        // setConfirmInvoice(false)
        // setRejectInvoice(false)
        enqueueSnackbar('Server Error', { variant: 'error' })
      }
      // finally{
      //   setConvertToRFQ(false);
      // }
    }

    const VALUE = [
        { id: 1, value: 'Sl No.' },
        { id: 2, value: 'Products' },
        { id: 3, value: 'Note' },
        //  { id: 4, value: 'Product formula cost (Material cost + Service cost with markup)' },
        { id: 5, value: 'Product formula cost' },
        { id: 6, value: markupType ? ((markupType == 2 && hasMarkupPermission) ? 'Retail Price Each' : null) : null },
        { id: 7, value: markupType ? ((markupType == 1 && hasMarkupPermission) ? 'Retail Price Each' : null) : null },
        { id: 14, value: hasMarkupPermission ? 'SPMA' : null },
        // { id: 8, value: hasMarkupPermission ? 'Markup (%)' : null },
        { id: 9, value: 'Unit' },
        { id: 10, value: 'Quantity' },
        { id: 11, value: 'Location' },
        { id: 11, value: 'Marketing Adjustment Type' },
        { id: 12, value: 'Marketing Adjustment ' },
        // { id: 12, value: 'Discount' },
        { id: 13, value: 'Subtotal' },
        { id: 15, value: '' }
    ];
    
    const VALUE_TIME = [
        { id: 1, value: 'Sl No.' },
        { id: 2, value: 'Products' },
        { id: 3, value: 'Note' },
        { id: 4, value: 'Retail Price Each'},
        { id: 14, value: 'SPMA' },
        { id: 5, value: 'Unit' },
        { id: 6, value: 'Quantity' },
        { id: 7, value: 'Location' },
        { id: 11, value: 'Marketing Adjustment Type' },
        { id: 8, value: 'Marketing Adjustment ' },
        { id: 9, value: 'Subtotal' },
        { id: 10, value: '' }
    ];

    const VALUE_TIME_Preview = [
        { id: 1, value: 'Sl No.' },
        { id: 2, value: 'Products' },
        // { id: 3, value: 'Note' },
        { id: 4, value: 'Retail Price Each'},
        { id: 14, value: 'SPMA' },
        { id: 5, value: 'Unit' },
        { id: 6, value: 'Quantity' },
        // { id: 7, value: 'Location' },
        { id: 8, value: 'Marketing Adjustment' },
        { id: 9, value: 'Subtotal' },
        { id: 10, value: '' }
    ];

    const VALUE_MATERIAL=[
        {id: 0, value: 'Material'},
        {id: 1, value: 'Unit Price'},
        {id: 2, value: 'Markup (%)'},
        {id: 3, value: 'Markup Cost (Unit)' },
        {id: 4, value: 'Quantity'},
        {id: 5, value: 'Product Formula Cost'},
        {id: 6, value: 'Total'},
        {id: 7, value: ''}
    ]

    const VALUEPreview = [
        { id: 0, value: 'Sl No.' },
        { id: 1, value: 'Products & Notes' },
        // { id: 2, value: 'Product formula cost' }, // (Material cost + Service cost with markup)
        { id: 3, value: markupType ? ((markupType == 2 && hasMarkupPermission) ? 'Retail Price Each' : null) : null },
        { id: 4, value: markupType ? ((markupType == 1 && hasMarkupPermission) ? 'Retail Price Each' : null) : null },
        { id: 14, value: hasMarkupPermission ? 'SPMA' : null },
        // { id: 5, value: hasMarkupPermission ? 'Markup (%)' : null },
        { id: 6, value: 'Unit  ' },
        { id: 7, value: 'Quantity' },
        { id: 8, value: 'Marketing Adjustment' },
        // { id: 8, value: 'Discount' },
        { id: 9, value: 'Subtotal' },
        { id: 10, value: '' }
    ]

    const VALUE_SERVICES = [
        { id: 0, value: 'Product Sl No.' },
        { id: 1, value: 'Service Name' },
        { id: 2, value: 'Markup (%)' },
        { id: 3, value: 'Cost (Hourly)' },
        { id: 4, value: 'Markup Cost (Hourly)' },
        { id: 5, value: 'Hours' },
        { id: 6, value: 'Location' },
        { id: 7, value: 'Product Formula Cost'},
        { id: 8, value: 'Additional Cost' },
        { id: 9, value: 'Total cost' },
        { id: 10, value: '' }
    ];

    const VALUE_RFP_Product = [
        { id: 0, value: ' Name' },
        { id: 1, value: 'Mark Up' },
        { id: 2, value: 'Cost ' },
        { id: 3, value: 'Selling Price' },
        { id: 4, value: 'Unit' },
        { id: 5, value: 'Quantity' },
        { id: 6, value: 'Product Formula Cost'},
        { id: 7, value: 'Total' },
        { id: 8, value: '' },
        { id: 9, value: '' }
    ];

    const VALUE_RFP = [
        { id: 0, value: ' Name' },
        { id: 1, value: 'Markup (%)' },
        { id: 2, value: 'Cost ' },
        { id: 3, value: 'Markup Cost (Hourly)' },
        { id: 4, value: 'Hours' },
        { id: 5, value: 'Total cost' },
        { id: 6, value: '' },
        { id: 7, value: '' }
    ];

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };
    
    // const addToCart = (item) => {
    // console.log("communitycommunitycommunity",values?.community)
    // let { address: projAddress = '', city: projCity = '', state: projState = '', zip: projZip = '' } = values?.community || {};
    // let communityAddress = { address: projAddress, city: projCity, state: projState, zip: projZip };
    // if (tabIndex === 0) {
    //     let addresses = [{
    //     id: 0,
    //     address: '',
    //     city: '',
    //     state: '',
    //     zip: '',
    //     }]
    //     setCartService([...cartService, { ...item,addresses: addresses }]);

    //     if(values?.community?.address  && values?.community?.city  && values?.community?.state  && values?.community?.zip){
    //     // Assign addresses using the utility function
    //     const newService = assignAddresses(cartService, item, allRfpServiceLocationSame, communityAddress);

    //     // Update the cartService state immutably
    //     setCartService([...cartService, newService]);
    //     }
    // }
    // else {
    //     let addresses = [{
    //     id: 0,
    //     address: '',
    //     city: '',
    //     state: '',
    //     zip: '',
    //     }]
    //     setCart([...cart, { ...item, addresses: addresses },]);

        
    // if(values?.community?.address  && values?.community?.city  && values?.community?.state  && values?.community?.zip){

    //     const newProduct = assignAddresses(cart, item, allRfpProductsLocationSame, communityAddress);
    //     setCart([...cart, newProduct]);
    // }

    // }
    
    //     communityAddress = { address: '', city: '', state: '', zip: '' };
    // setOpenModal(false);
    // setSelectedRfp(null);
    // setFieldValue('rfp',null)
    // if(allRfpServiceLocationSame){

    //     const commonAddress = cartService[0]?.addresses?.[0] || {
    //     address: '',
    //     city: '',
    //     state: '',
    //     zip: '',
    //     };

    //     setCartService(prevProducts =>
    //     prevProducts.map(product => ({
    //         ...product,
    //         addresses: product.addresses ? product.addresses.map(() => ({ ...commonAddress })) : [{ ...commonAddress }],
    //     }))
    //     );
    // }

    // if(allRfpProductsLocationSame) {


    //     const commonAddress = cart[0]?.addresses?.[0] || {
    //     address: '',
    //     city: '',
    //     state: '',
    //     zip: '',
    //     };

    //     setCart(prevProducts =>
    //     prevProducts.map(product => ({
    //         ...product,
    //         addresses: product.addresses ? product.addresses.map(() => ({ ...commonAddress })) : [{ ...commonAddress }],
    //     }))
    //     );

    // }
    // };

    // Utility function to assign addresses
    
    const addToCart = (item) => {
      console.log("itemitemitemitemitemitem", item);
  
      let { address: projAddress = '', city: projCity = '', state: projState = '', zip: projZip = '' } = values?.community || {};
      let communityAddress = { address: projAddress, city: projCity, state: projState, zip: projZip };
  
      if (tabIndex === 0) {
          // **For Services**
        let addresses = [{
          id: 0,
          address: '',
          city: '',
          state: '',
          zip: '',
        }];
        setCartService([...cartService, { ...item, addresses: addresses }]);
  
        if (values?.community?.address && values?.community?.city && values?.community?.state && values?.community?.zip) {
          // Assign addresses using utility function
          const newService = assignAddresses(cartService, item, allRfpServiceLocationSame, communityAddress);
          setCartService([...cartService, newService]);
        }
      } else {
        // **For Products**
        let locationQuantity = item?.location_quantity || 1;

        // Generate `locationQuantity` number of address fields
        let addresses = Array.from({ length: locationQuantity }, () => ({
          id: 0,
          address: '',
          city: '',
          state: '',
          zip: '',
        }));

        let newProduct = { 
          ...item, 
          allRfpProductsLocationSame: true, // Each product gets its own flag
          addresses 
        };

        setCart([...cart, newProduct]);

        if (values?.community?.address && values?.community?.city && values?.community?.state && values?.community?.zip) {
          newProduct = assignAddresses(cart, newProduct, newProduct.allRfpProductsLocationSame, communityAddress);
          setCart([...cart, newProduct]);
        }
      }
  
      communityAddress = { address: '', city: '', state: '', zip: '' };
      setOpenModal(false);
      setSelectedRfp(null);
      setFieldValue('rfp', null);
  
      // Ensure each product maintains its own independent "All Locations Same" state
      setCart(prevProducts =>
          prevProducts.map(product => {
              let locationQuantity = product?.location_quantity || 1;
              const commonAddress = prevProducts[0]?.addresses?.[0] || {
                  address: '',
                  city: '',
                  state: '',
                  zip: '',
              };
  
              return {
                  ...product,
                  addresses: product.allRfpProductsLocationSame
                      ? Array.from({ length: locationQuantity }, () => ({ ...commonAddress }))
                      : product.addresses
              };
          })
      );
    };
  
    const assignAddresses = (existingCart, newProduct, allLocationsSame, communityAddress) => {
    let assignedAddresses = [];

    if (allLocationsSame && existingCart.length > 0) {
        
        const commonAddress = existingCart[0].addresses[0] || {
        address: '',
        city: '',
        state: '',
        zip: '',
        };
        assignedAddresses = [{ ...commonAddress }];
    } else {
        
        assignedAddresses = [{
        id: 0,
        address: communityAddress.address,
        city: communityAddress.city,
        state: communityAddress.state,
        zip: communityAddress.zip,
        }];
    }

    return {
        ...newProduct,
        addresses: assignedAddresses,
    };
    };

    const handleMaterial = (newValue) => {
      setSelectedMaterials((prevMaterials) => {
        const additionals = prevMaterials?.filter(item => item.isAdditionalProduct == true);
        return [...newValue, ...additionals];
      });
    };

    const handleDeleteService = (servId, indexId) => {
      // const newFilteredServices = additionalSelectedServices?.filter(data => (data.indexId !== indexId));
      const newFilteredServices = additionalSelectedServices?.filter((data) => {
        if (data?.isAdditionalProduct) {
          return data?.newIndexId !== indexId;
        }
        return data?.id !== servId;
      });
      
      const newLocationStates = { ...serviceOnlyLocationStates };
      delete newLocationStates[indexId];
      setServiceOnlyLocationStates(newLocationStates);
      // const allFilteredServices = filteredServices?.find(data => data.id !== servId);
      setFieldValue('services', newFilteredServices);
      setAdditionalSelectedServices(newFilteredServices);
    };
    
    const handleDeleteMaterial = (id, indexId) => {
    // const updatedMaterials = selectedMaterials.filter(data => (data.indexId !== indexId));
    const updatedMaterials = selectedMaterials?.filter((data) => {
      if (data?.isAdditionalProduct) {
        return data?.newIndexId !== indexId;
      }
      return data?.id !== id;
    });
    
    setSelectedMaterials(updatedMaterials);
    };

    const isInCart = (item) => {
    console.log("######################Cart111New", item);
    console.log("######################Cart111", cart);
    return cart.some(cartItem => cartItem.product_id === item.product_id);
    };

    const isInCartService = (item) => {
    return cartService.some(cartItem => cartItem.id === item.id);
    };

    const closeModal = () => {
    setOpenModal(false);
    setFieldValue('rfp',null)
    };

    useEffect(() => {
        if (selectedProducts.length <= 0) {
          setAllLocationSame(false)
        }
        if (additionalSelectedServices.length <= 0) {
          setAllServiceLocationSame(false)
        }
        if (cart.length <= 0) {
          setAllRfpProductsLocationSame(false)
        }
        if (cartService.length <= 0) {
          setAllRfpServiceLocationSame(false)
        }
    }, [selectedProducts,additionalSelectedServices,cart,cartService])

    const handleDeleteRFPProduct = (productId) => {
    setCart(prevCart => prevCart.filter(item => item.id !== productId));
    };

    const handleDeleteRFPService = (productId) => {
    console.log("######################Cartde", productId);
    setCartService(prevCart => prevCart.filter(item => item.id !== productId));
    };
    
    const handleOpenNote = (indexId, note) => {
        setCurrentNote(note || '');
        setOpenProductNote((prev) => ({ ...prev, [indexId]: true }));
    };

    const handleCloseNote = (indexId) => {
        setOpenProductNote((prev) => ({ ...prev, [indexId]: false }));
        setCurrentNote('');
    };

    const handleNoteChange = (event) => {
        const newNote = event.target.value;
        if (newNote.length <= MAX_CHARACTERS) {
        setCurrentNote(newNote);
        }
    };

    const handleSaveNote = (indexId) => {
        setIsSavingNote(true);
        setTimeout(() => {
        setSelectedProducts((prev) =>
            prev.map((product) =>
            product.indexId === indexId ? { ...product, product_notes: currentNote } : product
            )
        );
        setIsSavingNote(false);
        handleCloseNote(indexId);
        }, 100);
    };

    const handleRfpSelect = (event, value) => {

        if (!empty(value)) {
        setOpenModal(true);
        setSelectedRfp(value);
        console.log("########Services", value?.services);
        console.log("########Services cart", value?.products);

        setRfpServices(value?.services?.filter(service => !isInCartService(service)) || []);
        setRfpProducts(value?.products?.filter(product => !isInCart(product)) || []);
        }
    };
  
    const fetchAllMaterials = async () => {
        setLoadingCards(true)
        try {
        let user = JSON.parse(localStorage.getItem('user'))
        let userType = user.user_type
        let userId = user.user_id

        let whereCondition = '';
        if (currentTab == 'OPEN') {
            whereCondition = ` ((r.status=2) OR (r.status=3 AND r.min_payment_status=0)) `
        } else if (currentTab == 'ACCEPTED') {
            whereCondition = ` (r.status=3 AND r.min_payment_status =1) ` // r.status=4 OR 
        } else if (currentTab == 'IN PRODUCTION') {
            whereCondition = ` r.status=4 and i.status IS NULL `
        } else if (currentTab == 'INSTALLATION' ||currentTab =='PICKUP' || currentTab =='SHIPPING'  ) {
            let completeStatus = currentTab == 'INSTALLATION' ? 1: currentTab == 'PICKUP' ? 2 : 3
            // whereCondition = ` i.status IS NOT NULL AND ( COALESCE(inst_counts.installation_count, 0) < COALESCE(cop.product_count, 0) OR (pc.name = 'Service only' AND COALESCE(inst_counts.installation_count, 0) < COALESCE(rss.service_count, 0)) ) `
            whereCondition = ` i.status IS NOT NULL AND i.status != 5 AND i.complete_status = ${completeStatus} AND ( 
            COALESCE(inst_counts.installation_count, 0) < COALESCE(cop.product_count, 0) 
            OR (pc.name = 'Service Only' AND COALESCE(inst_counts.installation_count, 0) < COALESCE(rss.service_count, 0))
            OR (COALESCE(cop.product_count, 0) = 0 AND COALESCE(rss.service_count, 0) > 0 AND COALESCE(inst_counts.installation_count, 0) < COALESCE(rss.service_count, 0))
            ) `
        } else if (currentTab == 'COMPLETED') {
            whereCondition = ` i.status IS NOT NULL AND ( inst_counts.installation_count >= cop.product_count OR inst_counts.installation_count >= rss.service_count ) `
        }

        const formattedFrom = filterDateValue[0] ? moment(filterDateValue[0]).format('YYYY-MM-DD HH:mm:ss') : null;
        const formattedTo = filterDateValue[1] ? moment(filterDateValue[1]).format('YYYY-MM-DD HH:mm:ss') : null;

        const values = {
            userType: userType, userId: userId, page: page + 1, rowsPerPage, search: searching, where: whereCondition,
            sort:sort.id,
            from:formattedFrom,
            to:formattedTo,
            filterCommunity:filterCommunity
        }
        await axios.post(`${REST_API}webservice/request/get-all-request`, values)
            .then((res) => {
            setAllproducts(res.data.products)
            // setAllMaterials(res.data.materials)
            const allMatData = Array.isArray(res.data.materials)
              ? res.data.materials.map((material) => ({
                  ...material,
                  markup: Number(material.retail_price || 0),
                }))
              : []
            console.log('allMatData---',allMatData)
            setAllMaterials(allMatData?.filter(item=>item.isAdditionalProduct == 0))
            setLabourCostServices(res.data.labourCostServices)
            setRfps(res.data.allProposals)
            setFilteredServices(res.data.labourCostServices)
            })
            .catch((errors) => {
            console.log(errors, 'error in fetching all requests')
            })
        }catch(error){
        console.log("fetchAllMaterials error : ",error)
        }finally{
        setLoadingCards(false)
        }
    }

    const fetchAllCommunityByClient = async (clientId) => {
        setIsCommunityLoading(true);
        try {
        clientId = clientId.id
        const res = await axios.post(`${REST_API}webservice/rfq/get-community-by-client`, { clientId })
        let data = res.data?.community
        await setCommunities(data)
        } catch (error) {
        console.log(error, 'Error in fetching all clients');
        setCommunities([]);
        } finally {
        setIsCommunityLoading(false)
        }
    }

    const fetchAllMapServices = async () => {
        setMapServicesLoading(true);
        try {
        const res = await axios.post(`${REST_API}webservice/route/fetch-routes-with-locations`)
        let data = res.data?.routeData
        if(data){
            const newData = data?.filter((i) => i.locations != null)
            if(newData){
            setMapServices(newData);
            }else{
            setMapServices([]);
            }
        }else{
            setMapServices([]);
        }
        } catch (error) {
        setMapServices([]);
        console.log(error, 'Error in fetching map services');
        }finally {
        setMapServicesLoading(false)
        }
    }

    const fetchAllClients = async () => {

        try {
        const res = await axios.post(`${REST_API}webservice/clients/getAllClients`, { userType, userId: user.user_id ,order:true})
        let data = res.data?.customer
        await setAllClients(data)
        } catch (error) {
        console.log(error, 'Error in fetching all clients', error)
        }
    }
    
    const fetchTaxCategory = async () => {
        try{
            const response = await axios.post(`${REST_API_END_POINT}product/get-tax-category`)
            if(response.data.status === 1){
                setTaxCategories(response.data.taxResult);
            }else{
            setTaxCategories([])
            }
        }catch(error){
            console.log("Error - ",error);
            setTaxCategories([])
        }
    }

    useEffect(() => {
        if(open){
        // setFieldValue('markup_type', markupTypes[1])
        setMarkupType(2)
        try {
            axios.post(`${REST_API_END_POINT}project/get-data-for-add-project`, {
            headers: {
                'Content-Type': 'application/json',
            },
            }).then((response) => {
            if (response.data.status === 1) {
                setProjectTypes(response.data.projectTypes)
                setProjectCategories(response.data.projectCategories)
                setNoChargeType(response.data.noChargeType)
                setCommunities(response.data.community)
            } else {
                setProjectTypes([])
                setNoChargeType([])
                setProjectCategories([])
                setCommunities([])
            }
            }).catch((error) => {
            console.error(error);
            setProjectTypes([])
            setNoChargeType([])
            setProjectCategories([])
            setCommunities([])
            })
        }
        catch (error) {
            console.error("Error fetching data:", error);
        }
        fetchTaxCategory();
        fetchAllMapServices();
        fetchAllClients()
        }
    }, [open])

    const addNewProductRow = () => {
        const newIndexCounter = indexCounter + 1
        setIndexCounter(newIndexCounter)
        const { address: projAddress = '', city: projCity = '', state: projState = '', zip: projZip = '' } = values?.community || {};
        // Auto-populate addresses from the selected Project
        const newAddresses = [{
          id: 0,
          address: projAddress,
          city: projCity,
          state: projState,
          zip: projZip
        }];
      
        setSelectedProducts((prevProducts) => {
        // if (allLocationSame && prevProducts.length > 0) {
        //     const commonAddress = { ...prevProducts[0].addresses[0] } || {
        //     address: '',
        //     city: '',
        //     state: '',
        //     zip: '',
        //     };
        //     newAddresses[0] = commonAddress;
        // }
        return [
        ...prevProducts,
        { 
            indexId: newIndexCounter, 
            name: '', 
            quantity: 1, 
            price: 0,
            adjustment:0,
            retail_price:0, 
            unit:4,
            locationQuantity: 1, 
            expected_total_service_cost:0,
            expected_service_cost:0,
            expected_material_cost:0,
            discount: 0 ,
            allLocationSame: true,
            service:[],
            isAdditionalProduct:true,
            addresses: newAddresses
        },
        ]});
    };

    // const addNewServiceRow = () => {
    //   const { address: projAddress = '', city: projCity = '', state: projState = '', zip: projZip = '' } = values?.community || {};
    //   // Auto-populate addresses from the selected Project
    //   const newAddresses = [{
    //     id: 0,
    //     address: projAddress,
    //     city: projCity,
    //     state: projState,
    //     zip: projZip
    //   }];
    
    //   setAdditionalSelectedServices((prevServices) => {
    //     return [
    //       ...prevServices,
    //       {  
    //         id:null,
    //         newIndexId: prevServices?.length + 1,
    //         name: '', 
    //         avatarUrl: null,
    //         quantity: 1, 
    //         cost: 0,
    //         markup: 0, 
    //         workflow: null,
    //         originalMarkup: 0, 
    //         service_type: null,
    //         total_cost:0,
    //         product_total:0,
    //         allLocationSame: true,
    //         isAdditionalProduct:true,
    //         addresses: newAddresses,
    //       }
    //     ]
    //   });
    // };

    // const addNewMaterialRow = () => {
    //   setSelectedMaterials((prevProducts) => {
    //   return [
    //   ...prevProducts,
    //   { 
    //     newIndexId: prevProducts?.length + 1,
    //     quantity: 1, 
    //     allLocationSame: true,
    //     isAdditionalProduct:true,
    //     description: '',
    //     finishedProductQty: 0,
    //     markUpCost: 0,
    //     markup: 0,
    //     material_types: 1,
    //     max_price: null,
    //     max_value: 10,
    //     meterial: 1,
    //     min_value: 1,
    //     name: '',
    //     partial: 0,
    //     partialProductQty: 0,
    //     pf_retail_markup: null,
    //     price: 0,
    //     recipe_id: null,
    //     retail_price: 0,
    //     special_price: 0,
    //     tags: '',
    //     tax: null,
    //     tax_category: 0,
    //     total_qty: 0,
    //     twilio_field_value_sid: null,
    //     unit: 4,
    //     unit_qty: 0,
    //     vendor_id: '',
    //     vpt_number: '',
    //     workflow: null,
    //   },
    //   ]});
    // };

    const addNewServiceRow = () => {
      const {
        address: projAddress = '',
        city: projCity = '',
        state: projState = '',
        zip: projZip = '',
      } = values?.community || {};
    
      const newAddresses = [
        {
          id: 0,
          address: projAddress,
          city: projCity,
          state: projState,
          zip: projZip,
        },
      ];
    
      setAdditionalSelectedServices((prevServices) => {
        const maxId = prevServices.reduce(
          (max, item) => Math.max(max, item.newIndexId || 0),
          0
        );
    
        return [
          ...prevServices,
          {
            id: null,
            newIndexId: maxId + 1,
            name: '',
            avatarUrl: null,
            quantity: 1,
            cost: 0,
            markup: 0,
            workflow: null,
            originalMarkup: 0,
            service_type: null,
            total_cost: 0,
            product_total: 0,
            allLocationSame: true,
            isAdditionalProduct: true,
            addresses: newAddresses,
          },
        ];
      });
    };
    

    const addNewMaterialRow = () => {
      setSelectedMaterials((prevProducts) => {
        const maxId = prevProducts.reduce(
          (max, item) => Math.max(max, item.newIndexId || 0),
          0
        );
    
        return [
          ...prevProducts,
          {
            newIndexId: maxId + 1,
            quantity: 1,
            allLocationSame: true,
            isAdditionalProduct: true,
            description: '',
            finishedProductQty: 0,
            markUpCost: 0,
            markup: 0,
            material_types: 1,
            max_price: null,
            max_value: 10,
            meterial: 1,
            min_value: 1,
            name: '',
            partial: 0,
            partialProductQty: 0,
            pf_retail_markup: null,
            price: 0,
            recipe_id: null,
            retail_price: 0,
            special_price: 0,
            tags: '',
            tax: null,
            tax_category: 0,
            total_qty: 0,
            twilio_field_value_sid: null,
            unit: 4,
            unit_qty: 0,
            vendor_id: '',
            vpt_number: '',
            workflow: null,
          },
        ];
      });
    };

    
    const handleProduct = (value) => {
      console.log('handleProduct---value--', value)
      console.log('handleProduct---selectedProducts--', selectedProducts)
      // 1) Grab the project's address data from Formik (or wherever you store your project)
      //    If there's no project selected, default to empty strings.
      const { address: projAddress = '', city: projCity = '', state: projState = '', zip: projZip = '' } = values?.community || {};
  
      setSelectedProducts((prevProducts) => {
        // If "All Locations Same" is on, we might use the addresses from the very first product
        let commonAddresses = null;
        // const commonAddresses = (allLocationSame && prevProducts.length > 0) ? [...prevProducts[0].addresses] : null;
    
        // 2) Map over the newly selected array of products
        const updatedProducts = value.map((product, index) => {
          // Check if product already exists in selectedProducts
          const existingProduct = prevProducts.find((p) => p.id === product.id && p.indexId === product.indexId);
          console.log('existingProduct', existingProduct)
  
          if (existingProduct) {
            // -----------------------
            // If product already exists
            // -----------------------
            const quantity = existingProduct.quantity; 
            const locationQuantity = existingProduct.locationQuantity;
            let addresses = [...existingProduct.addresses];

            if (allLocationSame && commonAddresses) {
                // Overwrite all addresses with the "common" addresses (from the first product)
                addresses = [...commonAddresses];
            } else {
                // Ensure at least one address object
                for (let i = addresses.length; i < 1; i++) {
                addresses.push({
                    id: i,
                    address: '',
                    city: '',
                    state: '',
                    zip: '',
                });
                }
            }
    
            return {
                ...existingProduct,
                quantity,
                locationQuantity,
                addresses
            };
          } else {
            // -----------------------
            // BRAND NEW product
            // -----------------------
            // Auto-populate addresses from the selected Project
            const newAddresses = [
                {
                id: 0,
                address: projAddress,
                city: projCity,
                state: projState,
                zip: projZip
                }
            ];
    
            // If "allLocationSame" is on, we might prefer the addresses from the first product
            if (false) {
            // if (allLocationSame && prevProducts.length > 0) {
                const commonAddress = { ...prevProducts[0].addresses[0] } || {
                address: '',
                city: '',
                state: '',
                zip: '',
                };
                newAddresses[0] = commonAddress;
            }
    
            // Bump the index counter for a unique indexId
            const newIndexCounter = indexCounter + 1;
            setIndexCounter(newIndexCounter);
    
            return {
                ...product,
                indexId: newIndexCounter,
                allLocationSame: true,
                quantity: 1,
                locationQuantity: 1,
                discount: 0,
                addresses: newAddresses
            };
          }
        });

        console.log('updatedProducts', updatedProducts)
        // Merge products from selectedMapserviceProducts with fromMapServices = true into the value
        const allSelectedProducts = [
            ...updatedProducts,
            ...selectedProducts?.filter((item)=>item.isAdditionalProduct == true),
            ...selectedMapserviceProducts?.filter(product => product?.fromMapServices == true)
        ];
    
        // 3) Build up a combined list of “services” for all products
        const productServices = allSelectedProducts?.reduce((services, product) => {
          if (product.services && product.services.length > 0) {
            return services.concat(
              product.services.map((service) => ({
                ...service,
                productIndex: product?.indexId,
                fromMapServices: product?.fromMapServices,
                quantity: service.quantity * product.quantity * product.locationQuantity,
                previousQty: service.quantity,
                qtyData: product.quantity,
                locationQuantity: product.locationQuantity
              }))
            );
          }
          return services;
        }, []);
    
        // Update your “selectedServices” state (if your code needs it)
        setSelectedServices(productServices);
    
        // Optionally filter out services you’ve already chosen, etc.
        const filteredServices =
          productServices.length === 0
          ? labourCostServices
          : labourCostServices.filter(
              (service) =>
                  !productServices.find(
                  (selectedService) => selectedService.id === service.id
                  )
              );
        setFilteredServices(filteredServices);
        setFieldValue('products', allSelectedProducts);
        return allSelectedProducts;
      });
    };
  
    const handleServices = (value) => {
      setAdditionalSelectedServices(prevProducts => {
        const updatedProducts = value.map((product, index) => {
          const existingProduct = prevProducts.find(p => (p?.id === product?.id && p?.indexId === product?.indexId));
          console.log('existingProduct-----------------',existingProduct)
          console.log('prevProducts-----------------',prevProducts)
          console.log('product-----------------',product)
          if (existingProduct) {
            let addresses = [...existingProduct.addresses];
            for (let i = addresses.length; i < 1; i++) {
              addresses.push({
                id: i,
                address: '',
                city: '',
                state: '',
                zip: '',
              });
            }
            return {
              ...existingProduct,
              addresses
            };
          } else {
            const newAddresses = [{
              id: 0,
              address: '',
              city: '',
              state: '',
              zip: '',
            }];
  
            // if (prevProducts.length > 0) {
            //   const commonAddress = { ...prevProducts[0].addresses[0] } || {
            //     address: '',
            //     city: '',
            //     state: '',
            //     zip: '',
            //   };
            //   newAddresses[0] = commonAddress;
            // }
            const newIndexCounter = indexCounter + 1
            console.log('indexCounter product------------',newIndexCounter)
            setIndexCounter(newIndexCounter);
            return {
              ...product,
              indexId: newIndexCounter,
              addresses: newAddresses
            };
          }
        });
        return updatedProducts;
      });
    };

    const handleDeleteProduct = (productId, indexId) => {
      const filteredProduct = selectedProducts?.filter((data) => !(data.id === productId && data.indexId === indexId));
      setFieldValue('products', filteredProduct);
      setSelectedProducts(filteredProduct);
  
      // Remove the corresponding location state when a product is deleted
      const newLocationStates = { ...locationStates };
      delete newLocationStates[indexId];
      setLocationStates(newLocationStates);
      console.log("Filtered products", filteredProduct);
      console.log("Filtered All Services", selectedServices);
      const optedProductIds = filteredProduct.map(product => product.id);
      const result = selectedServices?.filter(service =>
        filteredProduct?.some((product) => (product?.id === service?.product_id && product?.indexId === service?.productIndex))
      )
  
  
      console.log("Filtered chat", result);
      const filteredServices = selectedServices?.filter(service => !filteredProduct?.some(product => product?.services?.includes(service)));
      console.log("Filtered Services", filteredServices);
      if (filteredProduct?.length == 0) {
        setSelectedServices([])
      }
      else {
        // setSelectedServices(filteredServices);
        setSelectedServices(result)
      }
  
  
    };
  
    // const handleAllLocationSameChange = () => {
  
    //   console.log("##################Alllll", allLocationSame);
    //   if (!allLocationSame) {
    //     const commonAddress = selectedProducts[0]?.addresses[0] || {
    //       address: '',
    //       city: '',
    //       state: '',
    //       zip: '',
    //     };
    //     setSelectedProducts(prevProducts =>
    //       prevProducts.map(product => ({
    //         ...product,
    //         addresses: product.addresses.map(() => commonAddress),
    //       }))
    //     );
    //   }
    //   setAllLocationSame(!allLocationSame);
    // };
  
    const handleAllLocationSameChange = (productIndex) => {
      setSelectedProducts(prevProducts =>
        prevProducts.map((product, index) => {
          if (index === productIndex) {
            console.log('productIndex--1---', index)
            console.log('productIndex--2---', productIndex)

            // Toggle allLocationSame flag
            const isAllSame = !product?.allLocationSame;
            // Get the first address of the selected product
            const commonAddress = product.addresses[0] || {
              address: '',
              city: '',
              state: '',
              zip: '',
            };
    
            return {
              ...product,
              allLocationSame: isAllSame,
              addresses: product.addresses.map(() => commonAddress),
            };
          }
          return product;
        })
      );
    };
    
    const handleAllServiceLocationSameChange = () => {
  
      console.log("##################Alllll", allLocationSame);
      if (!allServiceLocationSame) {
        const commonAddress = additionalSelectedServices[0]?.addresses[0] || {
          address: '',
          city: '',
          state: '',
          zip: '',
        };
        setAdditionalSelectedServices(prevProducts =>
          prevProducts.map(product => ({
            ...product,
            addresses: product.addresses.map(() => commonAddress),
          }))
        );
      }
      setAllServiceLocationSame(!allServiceLocationSame);
    };
  
    const handleAllRfpServiceLocationSameChange = () => {
      
      if (!allRfpServiceLocationSame) {
        // Ensure there's at least one service and its location array is valid
        const commonAddress = cartService[0]?.addresses?.[0] || {
          address: '',
          city: '',
          state: '',
          zip: '',
        };
    
        console.log("Common Address being applied:", commonAddress);
    
        // Update all locations to use the commonAddress
        setCartService(prevProducts =>
          prevProducts.map(product => ({
            ...product,
            addresses: product.addresses ? product.addresses.map(() => ({ ...commonAddress })) : [{ ...commonAddress }],
          }))
        );
        setAllRfpServiceLocationSame(true);
  
      }else{
  
        setAllRfpServiceLocationSame(false);
      }
    
    };
  
    // const handleAllRfpProductsLocationSameChange = () => {
      
    //   if (!allRfpProductsLocationSame) {
    //     // Ensure there's at least one service and its location array is valid
    //     const commonAddress = cart[0]?.addresses?.[0] || {
    //       address: '',
    //       city: '',
    //       state: '',
    //       zip: '',
    //     };
    
    //     console.log("Common Address being applied:", commonAddress);
    
    //     // Update all locations to use the commonAddress
    //     setCart(prevProducts =>
    //       prevProducts.map(product => ({
    //         ...product,
    //         addresses: product.addresses ? product.addresses.map(() => ({ ...commonAddress })) : [{ ...commonAddress }],
    //       }))
    //     );
    //     setAllRfpProductsLocationSame(true);
  
    //   }else{
  
    //     setAllRfpProductsLocationSame(false);
    //   }
    
    // };

    const handleAllRfpProductsLocationSameChange = (productIndex) => {
      setCart(prevProducts => 
        prevProducts.map((product, index) => {
          if (index === productIndex) {
            const isAllSame = !product?.allRfpProductsLocationSame;
    
            // Ensure there's at least one service and its location array is valid
            const commonAddress = product?.addresses?.[0] || {
              address: '',
              city: '',
              state: '',
              zip: '',
            };
    
            console.log("Common Address being applied:", commonAddress);
    
            return {
              ...product,
              allRfpProductsLocationSame: isAllSame,
              addresses: product.addresses ? product.addresses.map(() => ({ ...commonAddress })) : [{ ...commonAddress }]
            };
          }
          return product; // Keep other products unchanged
        })
      );
    };
    
  const calculateTotal = (
    price,
    quantity,
    discount = 0,
    location = 1,
    adjustment = 0,
    markAdjType = 1,
  ) => {
    const parsedQuantity = parseFloat(quantity) || 1
    const parsedDiscount = parseFloat(discount) || 0
    const locationQuantity = parseFloat(location) || 1
    const newPrice = parseFloat(price) || 0
    // let subtotal = price * parsedQuantity * locationQuantity;

    let SPMA =
      newPrice + (newPrice * (adjustment ? Number(adjustment) : 0)) / 100
    let subtotal =
      parseFloat(SPMA)?.toFixed(2) * parsedQuantity * locationQuantity

    if (markAdjType == 2 || markAdjType == 3) {
      SPMA = newPrice + (adjustment ? Number(adjustment) : 0)
      subtotal =
        parseFloat(SPMA)?.toFixed(2) * parsedQuantity * locationQuantity
    }

    if (discount) {
      subtotal = subtotal - discount
    }
    // if (adjustment) {
    //   subtotal = subtotal + ((subtotal * adjustment) / 100);
    // }
    return subtotal
  }

  const calculateServiceTotal = (price, quantity, discount, location = 1) => {
    const parsedQuantity = parseFloat(quantity) || 0;
    const parsedDiscount = parseFloat(discount) || 0;
    const locationQuantity = parseFloat(location) || 1
    const subtotal = price * parsedQuantity * locationQuantity;
    if (discount) {
      const totalDiscount = subtotal - discount;
      return totalDiscount;
    } else {
      return subtotal;
    }
  };

  
  let totalAmount = 0;
  let totalMarkup = 0;
  let totalProductFormula = 0;
  let totalWholesalePrice = 0;
  let totalRetailPrice = 0;
  let originaltotalMarkup = 0
  let originaltotalServiceMarkup = 0
  let totalServiceMarkup = 0;
  let totalDiscount = 0

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  useState(()=>{
    if(values){
        setNewValues(values)
    }
},[values, setNewValues])

  useEffect(() => {
    setPreview(values)
  }, [openPreview])

  const selectedProductsTotalDiscount = selectedProducts?.reduce((total, data, index) => {
    const quantityFieldName = `quantity_${index}`;
    const discountFieldName = `discount_${index}`;
    const adjustmentFieldName = `adjustment_${index}`;
    // const quantityVal = values[quantityFieldName] ? values[quantityFieldName] : 1
    const quantityVal = data.quantity ? Number(data.quantity) : 1
    const discountVal = values[discountFieldName] ? values[discountFieldName] : 0
    const adjustmentVal = values[adjustmentFieldName] ? values[adjustmentFieldName] : 0

    const productTotal = calculateTotal(markupType == 1 ? data.price : (markupType == 2 ? data.retail_price : 0), quantityVal, discountVal, data.locationQuantity, data?.adjustment,data.markAdjType);
    const fixedProductTotal = parseFloat(productTotal).toFixed(2);
    return total + parseFloat(fixedProductTotal);
  }, 0);

  const selectedProductsTotalDiscountForJobCost = selectedProducts?.reduce((total, data, index) => {
    const quantityFieldName = `quantity_${index}`;
    const discountFieldName = `discount_${index}`;
    // const quantityVal = values[quantityFieldName] ? values[quantityFieldName] : 1
    const quantityVal = data.quantity ? Number(data.quantity) : 1
    const discountVal = values[discountFieldName] ? values[discountFieldName] : 0
    console.log("#########DATAJobcost", data);
    console.log("#########quantityVal", quantityVal);
    const productTotal = calculateTotal(parseFloat(data.expected_material_cost), quantityVal, 0, data.locationQuantity);
    // const productTotal = calculateTotal(data.expected_material_cost, quantityVal, discountVal);
    console.log("#####################ProductTotal", productTotal);
    return total + parseFloat(productTotal);
  }, 0);

  const allServices = [...selectedServices, ...additionalSelectedServices];
  let selectedServicesCost = allServices?.reduce((total, service, index) => {
    const quantityFieldName = `service_quantity_${index}`;
    const quantityVal = values[quantityFieldName] ? values[quantityFieldName] : 1
    console.log("#######Service quantity-------", service.quantity);
    const serviceTotal = calculateServiceTotal(service.cost, Number(service.quantity), 0, 1);
    console.log("#######Service Total--2-----", serviceTotal);
    return total + parseFloat(serviceTotal?.toFixed(2));
  }, 0);
  let selectedServicesTotalCost = allServices?.reduce((total, service, index) => {
    const quantityFieldName = `service_quantity_${index}`;
    const quantityVal = values[quantityFieldName] ? values[quantityFieldName] : 1
    console.log("###########service?.locationQuantity", service?.locationQuantity);
    const serviceTotal = calculateServiceTotal(service?.total_cost, service?.quantity, 0, 1);
    console.log("###########service", service);
    console.log("###########serviceTotal", serviceTotal);
    
    // return total + Number(service?.total);
    return total + Number(serviceTotal);
  }, 0);
  let InitialServiceCostInProductFormula = selectedProducts?.reduce((total, data, index) => {
    console.log("#####################Data", data);
    const serviceTotal = (data?.quantity) * (parseFloat(data?.expected_total_service_cost?.toFixed(2))) * (data?.locationQuantity || 1)
    return total + Number(serviceTotal);
  }, 0);
  const InitialServiceCostforJobcost = selectedProducts?.reduce((total, data, index) => {
    const totalServiceCost = (data?.quantity) * (parseFloat(data?.expected_service_cost?.toFixed(2))) * (data?.locationQuantity || 1)
    return total + parseFloat(Number(totalServiceCost)?.toFixed(2));
  }, 0);
  // selectedServicesCost = selectedServicesCost - InitialServiceCostforJobcost
  const roundToThreeDecimals = (num) => Math.round(num * 1000) / 1000;
  selectedServicesTotalCost = roundToThreeDecimals(selectedServicesTotalCost);
  InitialServiceCostInProductFormula = roundToThreeDecimals(InitialServiceCostInProductFormula);
  const roundToTwoDecimals = (num) => Math.round(num * 100) / 100;

  const selectedAdditionalServiceCost = additionalSelectedServices?.filter(item=>item?.isAdditionalProduct == true)
                                          ?.reduce((total, item) => total + (Number(item.total_cost) || 0), 0)

  const selectedServicesSubTotal = roundToTwoDecimals(selectedServicesTotalCost - InitialServiceCostInProductFormula);

  values['labour_cost'] = selectedServicesSubTotal // selectedServicesCost
  const RFQSubTotal = (selectedProductsTotalDiscount + selectedServicesTotalCost) - (values['invoice_discount'] ? values['invoice_discount'] : 0)

  console.log('InitialServiceCostforJobcost-------', InitialServiceCostforJobcost);
  console.log('InitialServiceCostInProductFormula-------', InitialServiceCostInProductFormula);
  console.log('selectedServicesCost--------hhhh', selectedServicesCost);
  console.log('selectedServicesTotalCost-------', selectedServicesTotalCost);
  console.log('selectedServicesSubTotal-------', selectedServicesSubTotal);
  // console.log('InitialServiceCostInProductFormula-------',InitialServiceCostInProductFormula);
  console.log('selectedProductsTotalDiscountForJobCost111-------', selectedProductsTotalDiscountForJobCost);
  console.log('selectedProductsTotalDiscount-------', selectedProductsTotalDiscount);

  const calculateProductTotal = (price, markup, quantity,location=1) => {
    return parseFloat((price + price * (markup / 100))?.toFixed(2)) * quantity*location;
  };

  const calculateJobCostTotal = (price, quantity,location=1) => {
    return price * quantity * location;
  };

  const getProductMarkup = (data, markupType) => {
    if (markupType) {
      return markupType === 1 ? data.WholesaleMarkup || data.product_markup : data.RetailMarkup || data.product_markup;
    }
    return 0;
  };

  const selectedRfpProduct = useMemo(() => {
    return cart?.reduce((total, data) => {
      const markup = getProductMarkup(data, markupType);
      let productTotal = calculateProductTotal(data.product_price, markup, data.quantity,data.location_quantity);
      productTotal = parseFloat(productTotal.toFixed(2));
      console.log("#####################ProductTotal", productTotal);
      return total + productTotal;
    }, 0);
  }, [cart, markupType]);

  const selectedRfpServices = useMemo(() => {
    return cartService?.reduce((total, data) => {
      let productTotal = calculateProductTotal(data.sellingPrice, data.originalMarkup, data.qty);
      productTotal = parseFloat(productTotal.toFixed(2));
      return total + productTotal;
    }, 0);
  }, [cartService]);

  const selectedRfpServicesForJobCost = useMemo(() => {
    return cartService?.reduce((total, data) => {
      let productTotal = calculateJobCostTotal(data.sellingPrice, data.qty);
      productTotal = parseFloat(productTotal.toFixed(2));
      return total + productTotal;
    }, 0);
  }, [cartService]);

  const selectedRfpProductJobCost = useMemo(() => {
    return cart?.reduce((total, data) => {
      let productTotal = calculateJobCostTotal(data.product_price, data.quantity,data.location_quantity);
      productTotal = parseFloat(productTotal.toFixed(2));
      return total + productTotal;
    }, 0);
  }, [cart]);
  //   const selectedMaterialCost = useMemo(() => {
  //   return selectedMaterials?.reduce((total, data) => {
  //   console.log("########SElected",data);
  //     let productTotal = calculateJobCostTotal(data.price, data.quantity||1);
  //      console.log("########SElectedproductTotal",productTotal);
  //     productTotal = parseFloat(productTotal.toFixed(2));
      
  //     return total + productTotal;
  //   }, 0);
  // }, [selectedMaterials]);
  const selectedMaterialCostJobCost = useMemo(() => {
    return selectedMaterials?.reduce((total, data) => {
      console.log("########SElectedmaterial-jobcost",data);
      let price = data?.inhouse == 0 ? 0 : parseFloat(data?.price || 0);
      let quantity = parseFloat(data?.quantity || 1);
      if(values.project_categories?.name == 'Time & Material'){
        price = parseFloat(0);
        quantity = parseFloat(0);
      }
      let productTotal = price * (quantity);
      if(data?.inhouse == 0){
        productTotal = 0
      }
      // let productTotal = calculateJobCostTotal(data.price, data.quantity||1);
      console.log("########SElectedmaterial-jobcost-productTotal",productTotal);
      productTotal = parseFloat(productTotal.toFixed(2));
      return total + productTotal;
    }, 0);
  }, [selectedMaterials]);

  const selectedMaterialCost = useMemo(() => {
    return selectedMaterials?.reduce((total, data) => {
      console.log("########SElectedmaterial-totalsum1",data);
      let price = data?.inhouse == 0 ? 0 : parseFloat(data?.price || 0);
      let markup = parseFloat(data?.markup || 0);
      let quantity = parseFloat(data?.quantity || 1);
      if(values.project_categories?.name == 'Time & Material'){
        price = parseFloat(0);
        quantity = parseFloat(0);
      }
      let markUpCost = price + ((price * markup) / 100);
      markUpCost = parseFloat(markUpCost.toFixed(2)) || 0;
      let productTotal = markUpCost * quantity;
      if(data?.inhouse == 0){
        productTotal = 0
      }
      productTotal = parseFloat(productTotal.toFixed(2));

      // let markUpCost = data?.price + ((parseFloat(data?.price) * parseFloat(data?.markup || 0)) / 100)
      // markUpCost = parseFloat(parseFloat(markUpCost).toFixed(2)) || 0;
      // let productTotal = markUpCost * (data.quantity || 1);
      // let productTotal = calculateJobCostTotal(data.markUpCost, data.quantity||1);
      console.log("########SElectedmaterial-totalsum2-data.price",data?.price);
      console.log("########SElectedmaterial-totalsum2-price",price);
      console.log("########SElectedmaterial-totalsum3-data.markup",data?.markup);
      console.log("########SElectedmaterial-totalsum4-markUpCost",markUpCost);
      console.log("########SElectedmaterial-totalsum5-productTotal",productTotal);
      // productTotal = parseFloat(productTotal.toFixed(2));
      return total + parseFloat(productTotal.toFixed(2));
    }, 0);
  }, [selectedMaterials]);
  console.log("########SElectedmaterial-totalsum-selectedMaterialCost",selectedMaterialCost);
  values['labour_cost'] = (selectedServicesSubTotal || 0) + selectedRfpServices
  // setTotalProductLabourCost(selectedServicesSubTotal || 0)
  // const totalsum = (selectedProductsTotalDiscount + selectedServicesSubTotal+selectedRfpProduct+selectedRfpServices) - parseFloat(values['invoice_discount'] || 0);

  // const totalJobcostSum = (selectedProductsTotalDiscountForJobCost + selectedServicesCost+selectedRfpProductJobCost);
  const totalsum = useMemo(() => {
    return (selectedProductsTotalDiscount + selectedServicesSubTotal + selectedMaterialCost+selectedRfpProduct + selectedRfpServices) - parseFloat(values['invoice_discount'] || 0);
  }, [selectedProductsTotalDiscount, selectedServicesSubTotal, selectedRfpProduct,selectedMaterialCost, selectedRfpServices, values['invoice_discount']]);
  setNewTotalsum(totalsum);

  const taxAmount = selectedTaxCategory ? (selectedTaxCategory.type === 1 ? (totalsum * (selectedTaxCategory.value || 0) / 100) : (selectedTaxCategory.value || 0) ) : 0;
  // const grandTotal =  (parseFloat(totalsum) + parseFloat(taxAmount)).toFixed(2);

  let grandTotal = noCharge && totalsum >0
  ? (parseFloat(totalsum) + parseFloat(taxAmount)) * -1
  : (parseFloat(totalsum) + parseFloat(taxAmount)) 
  setNewGrandTotal(grandTotal);

  const totalJobcostSum = useMemo(() => {
    return (selectedProductsTotalDiscountForJobCost + selectedServicesCost+selectedMaterialCostJobCost + selectedRfpProductJobCost + selectedRfpServicesForJobCost);
  }, [selectedProductsTotalDiscountForJobCost, selectedServicesCost, selectedRfpProductJobCost,selectedMaterialCostJobCost, selectedRfpServicesForJobCost]);
  setNewTotalJobcostSum(totalJobcostSum)
  // console.log("Budget---------------", totalJobcostSum)
  console.log("####################selectedMaterials", selectedMaterials);
  
  values['total'] = totalsum
  values['taxAmount'] = taxAmount
  values['budget'] = totalJobcostSum
  // //discount count
  const selectedProductDiscount = selectedProducts?.reduce((acc, data, index) => {
    // const discountFieldName = `discount_${index}`;
    // const discountVal = values[discountFieldName] ? values[discountFieldName] : 0
    const discountVal = data.discount ? data.discount : 0
    let productDiscount = +discountVal || 0;
    return acc + productDiscount;
  }, 0);

  const DiscountTotal = selectedProductDiscount + (parseFloat(values['invoice_discount']) || 0);
  setNewDiscountTotal(DiscountTotal);

  let selectedProductsTotalWholesalePrice = selectedProducts?.reduce((total, data, index) => {
    let quantityFieldName = `quantity_${data.product_id ? data.product_id : data.id}`;
    let discountFieldName = `discount_${data.product_id ? data.product_id : data.id}`;
    const dataWholesalePrice = data.product_price || data.price

    let newTotalWholesalePrice = dataWholesalePrice * data.quantity * data.locationQuantity
    newTotalWholesalePrice = newTotalWholesalePrice ? Number(newTotalWholesalePrice) : 0

    return total + newTotalWholesalePrice;
  }, 0);
  selectedProductsTotalWholesalePrice = selectedProductsTotalWholesalePrice ? Number(selectedProductsTotalWholesalePrice) : 0

  let selectedProductsTotalRetailPrice = selectedProducts?.reduce((total, data, index) => {
    let quantityFieldName = `quantity_${data.product_id ? data.product_id : data.id}`;
    let discountFieldName = `discount_${data.product_id ? data.product_id : data.id}`;
    const dataRetailPrice = data.product_retail_price || data.retail_price

    let newTotalRetailPrice = dataRetailPrice * data.quantity * data.locationQuantity
    newTotalRetailPrice = newTotalRetailPrice ? Number(newTotalRetailPrice) : 0

    return total + newTotalRetailPrice;
  }, 0);
  selectedProductsTotalRetailPrice = selectedProductsTotalRetailPrice ? Number(selectedProductsTotalRetailPrice) : 0


    let subTotal = markupType == 1 ? ((selectedProductsTotalWholesalePrice - DiscountTotal + selectedRfpProduct + selectedMaterialCost))
    : (markupType == 2 ? ((selectedProductsTotalRetailPrice - DiscountTotal + selectedRfpProduct + selectedMaterialCost)) : 0);
  console.log("################subTotal", subTotal);
  values['sub_total'] = subTotal
  console.log("project_categories", values.project_categories);

  const selectedProductsAdditionalCost = selectedProducts?.filter(item=>item?.isAdditionalProduct == true)
      ?.reduce((total, item) => total + (item.product_total || 0), 0)
      ?.toFixed(2)

  const selectedMaterialSubTotal = selectedMaterials //?.filter(item=>item?.isAdditionalProduct == true)
      ?.reduce((total, item) => total + ((item.markUpCost || 0) * (item.quantity || 1)), 0)
      ?.toFixed(2)    

  console.log("cartService--cartService--", cartService);
  console.log("cartService--rfpServiceLocationStates--", rfpServiceLocationStates);
  console.log("cartService--cart--", cart);
  console.log("cartService--rfpLocationStates--", rfpLocationStates);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const uploadPromises = acceptedFiles?.map((file) => {
          return new Promise((resolve, reject) => {
            const Filename = new Date().getTime() + file.name;
            const StorageRef = ref(storage, Filename);
            const uploadTask = uploadBytesResumable(StorageRef, file);

            uploadTask.on(
              'state_changed',
              (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgress(progress);
              },
              (error) => {
                console.error(error.message);
                reject(error);
              },
              () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                  resolve({
                    url: downloadURL,
                    file_type: file.type,
                    extension: file.name.split('.').pop(),
                    size: file.size
                  });
                });
              }
            );
          });
        });

        Promise.all(uploadPromises)
          .then((downloadURLs) => {
            setAvatarUrl((prevAvatarUrls) => {
              const updatedFiles = downloadURLs.map((file) => ({
                url: file.url,
                file_type: file.file_type,
                extension: file.extension,
                size: file.size
              }));
              return [...prevAvatarUrls, ...updatedFiles]
            });
          })
          .catch((error) => {
            console.error(error.message);
          });
      }
    },
    [setFieldValue]
  );

  const handleRemoveAll = () => {
    setAvatarUrl([]);
  };
  //  console.log({avatarUrl},'--------avt')
  const handleRemove = (file) => {
    // console.log(file, '--------file');
    const filteredItems = avatarUrl.filter((_file) => _file !== file);
    setAvatarUrl(filteredItems);
  };

  const handleCancel = async () => {
    setOpen(false);
    formik.resetForm();
    setSelectedProducts([]);
    setSelectedMapserviceProducts([]);
    setSelectedServices([]);
    setAdditionalSelectedServices([])
    setFilteredServices([])
    setShowAddNewRow(false)
    setAllLocationSame(false)
    setAvatarUrl([])
    setAllMaterials([])
    setSelectedTaxCategory(null);
    setRfps([])
    setSelectedRfp(null)
    setRfpServices([])
    setRfpProducts([])
    setCart([])
    setCartService([])
    setSelectedMaterials([])
    }
    
    return (
      <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <CardHeader sx={{ ml: -3, mt: -4 }} title="New Sales Order" />
              <Stack spacing={3}>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <Stack flexDirection={'column'}>
                    <TextField
                      required
                      //   fullWidth
                      sx={{ width: 400 }}
                      label="Ordered Name"
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Stack>
                </Stack>
                <LabelStyle>Description</LabelStyle>
                <TextField
                  required
                  fullWidth
                  multiline
                  minRows={3}
                  maxRows={7}
                  label="keep the summary brief and make it communicates"
                  {...getFieldProps('description')}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                />

                {userType !== 3 &&
                  explodedPermissions?.some((permission) =>
                    ['Create Request'].includes(permission),
                  ) && (
                    <>
                      {/* {values?.project_categories?.name != 'Service only' &&
                        values?.project_categories?.name !=
                          'Time & Material' && ( */}
                          <>
                            <LabelStyle>Upload Designs / Documents</LabelStyle>
                            <UploadMultiFile
                              showPreview
                              maxSize={3145728}
                              accept="image/*,application/pdf"
                              files={avatarUrl}
                              onDrop={handleDrop}
                              onRemove={handleRemove}
                              onRemoveAll={handleRemoveAll}
                              // error={Boolean(touched.avatarUrl && errors.avatarUrl)}
                            />
                          </>
                        {/* )} */}
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 3, sm: 2 }}
                      >
                        <Autocomplete
                          // multiple
                          sx={{ width: '50%' }}
                          // fullWidth

                          id="combo-box-demo"
                          options={allClients}
                          value={values.clients}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setFieldValue('clients', newValue)
                              setFieldValue('community', null)
                              fetchAllCommunityByClient(newValue)
                              setShowAddNewRow(true)
                            } else {
                              setFieldValue('clients', null)
                              setFieldValue('community', null)
                              setCommunities([])
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              label="Clients"
                              error={Boolean(touched.clients && errors.clients)}
                              helperText={touched.clients && errors.clients}
                            />
                          )}
                          getOptionLabel={(option) => option.name}
                        />
                        {isCommunityLoading ? (
                          <Stack
                            flexDirection={'row'}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <CircularProgress
                              color="primary"
                              variant="indeterminate"
                            />
                            <Typography variant="body2">
                              Loading projects...
                            </Typography>
                          </Stack>
                        ) : communities?.length > 0 ? (
                        
                          <Autocomplete
                            // e.g. label="Project"
                            sx={{ width: '50%' }}
                            id="community"
                            options={communities}
                            value={values.community}
                            onChange={(event, newValue) => {
                              // 1) Set the community in Formik
                              if (newValue) {
                                setFieldValue('community', newValue)

                                // 2) Check if the project has address/city/state/zip
                                if (
                                  newValue.address ||
                                  newValue.city ||
                                  newValue.state ||
                                  newValue.zip
                                ) {
                                  const { address, city, state, zip } = newValue

                                  // 3) Update selected products’ addresses
                                  setSelectedProducts((prevProducts) =>
                                    prevProducts?.map((product) => {
                                      if (product?.fromMapServices == true) {
                                        return product
                                      } else {
                                        const updatedAddresses =
                                          product.addresses.map((loc) => ({
                                            ...loc,
                                            address: address || loc.address,
                                            city: city || loc.city,
                                            state: state || loc.state,
                                            zip: zip || loc.zip,
                                          }))
                                        return {
                                          ...product,
                                          addresses: updatedAddresses,
                                        }
                                      }
                                    }),
                                  )

                                  // 4) Update selected services’ addresses (if applicable)
                                  setAdditionalSelectedServices(
                                    (prevServices) =>
                                      prevServices.map((service) => {
                                        const updatedAddresses =
                                          service.addresses.map((loc) => ({
                                            ...loc,
                                            address: address || loc.address,
                                            city: city || loc.city,
                                            state: state || loc.state,
                                            zip: zip || loc.zip,
                                          }))
                                        return {
                                          ...service,
                                          addresses: updatedAddresses,
                                        }
                                      }),
                                  )

                                  setCart((prevCart) =>
                                    prevCart.map((product) => {
                                      const updatedAddresses =
                                        product.addresses.map((loc) => ({
                                          ...loc,
                                          address: address || loc.address,
                                          city: city || loc.city,
                                          state: state || loc.state,
                                          zip: zip || loc.zip,
                                        }))
                                      return {
                                        ...product,
                                        addresses: updatedAddresses,
                                      }
                                    }),
                                  )

                                  // 6) Update RFP Services’ addresses
                                  // if(values.project_categories?.name == 'Service only' || (values?.project_categories?.name == 'Time & Material')){
                                  setCartService((prevServices) =>
                                    prevServices.map((service) => {
                                      const updatedAddresses =
                                        service.addresses.map((loc) => ({
                                          ...loc,
                                          address: address || loc.address,
                                          city: city || loc.city,
                                          state: state || loc.state,
                                          zip: zip || loc.zip,
                                        }))
                                      return {
                                        ...service,
                                        addresses: updatedAddresses,
                                      }
                                    }),
                                  )
                                  // }
                                }

                                // (Optional) If your community also decides the default tax category
                                if (newValue.tax_category) {
                                  const taxCat = taxCategories?.find(
                                    (cat) => newValue.tax_category === cat.id,
                                  )
                                  setSelectedTaxCategory(taxCat)
                                }
                              } else {
                                // Clear everything if user removes project
                                setFieldValue('community', null)
                                setSelectedTaxCategory(null)
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                label="Project"
                                error={Boolean(
                                  touched.community && errors.community,
                                )}
                                helperText={
                                  touched.community && errors.community
                                }
                              />
                            )}
                            getOptionLabel={(option) => option.name}
                          />
                        ) : (
                          <Stack
                            sx={{ width: '50%' }}
                            justifyContent={'center'}
                          >
                            {values?.clients ? (
                              <Typography sx={{ color: 'red' }}>
                                The selected client does not have any projects
                                yet. Please create a new project for them!
                              </Typography>
                            ) : (
                              <Typography sx={{ color: 'red' }}>
                                No client selected. Please choose a client from
                                the list.
                              </Typography>
                            )}
                          </Stack>
                        )}
                      </Stack>
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 3, sm: 2 }}
                      >
                        <Autocomplete
                          sx={{ width: {xs:'100%', sm:'50%'} }}
                          id="combo-box-demo-type"
                          options={projectTypes}
                          value={values.project_type}
                          onChange={(event, newValue) => {
                            setFieldValue('project_type', newValue)
                            setShowAddNewRow(true)
                            if (newValue?.name !== 'TS') {
                              setFieldValue('map_service', null)
                              const filteredProducts = selectedProducts?.filter(
                                (product) => product?.fromMapServices !== true,
                              )
                              setSelectedProducts(filteredProducts)
                            }
                            setFieldValue('project_categories', null)
                            setFieldValue('map_service', null)
                          }}
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              label="Job Class"
                              error={Boolean(
                                touched.project_type && errors.project_type,
                              )}
                              helperText={
                                touched.project_type && errors.project_type
                              }
                            />
                          )}
                          getOptionLabel={(option) => option.name}
                        />
                        {/* <Autocomplete
                          fullWidth
                          id="combo-box-demo-type"
                          options={markupTypes}
                          disabled
                          value={values.markup_type}
                          onChange={(event, newValue) => {
                            setFieldValue('markup_type', newValue)
                            setMarkupType(newValue?.id)
                          }}
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              label="Markup Type"
                              error={Boolean(
                                touched.markup_type && errors.markup_type,
                              )}
                              helperText={
                                touched.markup_type && errors.markup_type
                              }
                            />
                          )}
                          getOptionLabel={(option) => option.name}
                        /> */}
                      </Stack>
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 3, sm: 2 }}
                      >
                        <Autocomplete
                          // fullWidth
                          sx={{ width: '50%' }}
                          id="combo-box-demo-category"
                          options={
                            values.project_type?.name == 'TS'
                              ? projectCategories
                              : projectCategories?.filter((ite) => ite.id != 14)
                          }
                          value={values.project_categories}
                          onChange={(event, newValue) => {
                            console.log('newValuenewValuenewValue',newValue)
                            setFieldValue('project_categories', newValue)

                            if (
                              newValue?.name == 'No Charge' ||
                              newValue?.id == 13
                            ) {
                              setNoCharge(true)
                              // setFieldValue('client_approve',true)
                            } else {
                              setNoCharge(false)
                              setFieldValue('no_charge_type', null)
                            }
                            if (
                              newValue?.name == 'Service only'|| newValue?.id==11 || newValue?.name == 'Time & Material' || values.project_categories?.id == 14
                            ) {
                            console.log('newValuenewValuenewValue 111',selectedProducts)

                              // fetchAllMaterials()
                              setAvatarUrl([])
                              setSelectedProducts([])
                              setSelectedMapserviceProducts([]);
                              
                              setSelectedServices([])
                              setSelectedMaterials([])
                              setAdditionalSelectedServices([])
                              setFieldValue('services', [])
                              // fetchAllMaterials()
                              console.log('newValuenewValuenewValue 222',selectedProducts)

                              setCart([])
                              setCartService([])
                              if (newValue?.name == 'Time & Material') {
                                // addNewProductRow()
                                // setMarkupType(1)
                              // setFieldValue('client_approve',true)
                              } else {
                                setSelectedMaterials([])
                              }
                            }
                            if (values.project_categories?.name == 'Time & Material') {

                              fetchAllMaterials()
                              setSelectedProducts([])
                              setSelectedMapserviceProducts([]);
                              setSelectedServices([])
                              setSelectedMaterials([])
                              setAdditionalSelectedServices([])
                              setFieldValue('services', [])
                              // fetchAllMaterials()
                              setCart([])
                              setCartService([])
                              // setFieldValue('client_approve',true)
                            }
                            if ( values.project_categories?.name == 'Service only' ) {
                              setSelectedMaterials([])
                            }
                            if (
                              values?.project_type?.name !== 'TS' ||
                              newValue?.id != 14
                            ) {
                              setFieldValue('map_service', null)
                              if( newValue?.name == 'Service only'|| newValue?.id==11 || newValue?.name == 'Time & Material'){

                                setSelectedProducts([]);
                                setSelectedMapserviceProducts([]);
                                setSelectedServices([])
                              }
                              setSelectedMapserviceProducts([]);
                              setSelectedServices([]);
                              // const filteredProducts = selectedProducts?.filter(
                              //   (product) => product?.fromMapServices !== true,
                              // )
                              // setSelectedProducts(filteredProducts)
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              label="Job Type"
                              error={Boolean(
                                touched.project_categories &&
                                  errors.project_categories,
                              )}
                              helperText={
                                touched.project_categories &&
                                errors.project_categories
                              }
                            />
                          )}
                          getOptionLabel={(option) => option.name}
                        />
                        {values.project_categories?.id == 14 ? (
                          <Autocomplete
                            // fullWidth
                            sx={{ width: '50%' }}
                            id="combo-box-demo-type"
                            options={mapServices}
                            value={values.map_service}
                            onChange={(event, newValue) => {
                              setFieldValue('map_service', newValue)
                              console.log('newValue=======', newValue)
                              if (newValue?.products) {
                                let newIndexCounter = indexCounter
                                const updatedProductsWithLocations =
                                  newValue?.products?.map((product, index) => {
                                    const filteredData = allProducts?.find(
                                      (i) => i.id === product.product_id,
                                    )
                                    const associatedLocations =
                                      newValue?.locations
                                        .filter(
                                          (location) =>
                                            location?.route_product_id ===
                                            product?.id,
                                        )
                                        .map((location) => ({
                                          address: location.address,
                                          city: location.city,
                                          state: location.state,
                                          zip: location.zip,
                                          route_location_id: location.id,
                                        }))
                                    newIndexCounter += 1
                                    return {
                                      ...product,
                                      ...filteredData,
                                      indexId: newIndexCounter, //`map_${index}`,
                                      fromMapServices: true,
                                      locationQuantity: product?.qty,
                                      addresses: associatedLocations,
                                      discount: 0,
                                      adjustment: 0,
                                      quantity: 1,
                                      markAdjType: 1,
                                    }
                                  })
                                const mergedSelectedProducts = [
                                  ...selectedProducts.filter(
                                    (product) =>
                                      product?.fromMapServices !== true,
                                  ), // Keep existing products without fromMapServices
                                  ...updatedProductsWithLocations, // Add the new products from map services
                                ]

                                setSelectedMapserviceProducts(
                                  updatedProductsWithLocations,
                                )
                                setSelectedProducts(mergedSelectedProducts)
                                setFieldValue(
                                  'products',
                                  mergedSelectedProducts,
                                )
                                setIndexCounter(newIndexCounter)
                                const productServices =
                                  mergedSelectedProducts?.reduce(
                                    (services, product) => {
                                      if (
                                        product.services &&
                                        product.services.length > 0
                                      ) {
                                        return services.concat(
                                          product.services.map((service) => ({
                                            ...service,
                                            fromMapServices:
                                              product?.fromMapServices || false,
                                            productIndex: product?.indexId,
                                            quantity:
                                              service.quantity *
                                              product.quantity *
                                              product.locationQuantity,
                                            previousQty: service.quantity,
                                            qtyData: product.quantity,
                                            locationQuantity:
                                              product.locationQuantity,
                                          })),
                                        )
                                      }
                                      return services
                                    },
                                    [],
                                  )
                                setSelectedServices(productServices)
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                label="Map Service"
                                error={Boolean(
                                  touched.map_service && errors.map_service,
                                )}
                                helperText={
                                  touched.map_service && errors.map_service
                                }
                              />
                            )}
                            // getOptionLabel={(option) => option.name}
                            getOptionLabel={(option) =>
                              `${option.name} (${option.locations_count})`
                            }
                          />
                        ) : (
                          ''
                        )}
                        {values?.project_categories?.id === 13 && (
                          <Autocomplete
                            // fullWidth
                            sx={{ width: '50%' }}
                            id="combo-box-demo-type"
                            options={noChargeType}
                            value={values.no_charge_type}
                            onChange={(event, newValue) => {
                              if(newValue){
                                setFieldValue('no_charge_type', newValue)
                                // setMarkupType(newValue?.id)
                              }else{
                                setFieldValue('no_charge_type', null)

                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                label="No charge Type"
                                error={Boolean(
                                  touched.no_charge_type &&
                                    errors.no_charge_type,
                                )}
                                helperText={
                                  touched.no_charge_type &&
                                  errors.no_charge_type
                                }
                              />
                            )}
                            getOptionLabel={(option) => option.name}
                          />
                        )}
                      </Stack>
                      {/* <Stack>
          <Autocomplete
              multiple
              // sx={{ width: 400 }}
              fullWidth
              id="combo-box-demo"
              options={workflow}
              value={values.workflows}
              onChange={(event, newValue) => setFieldValue('workflows', newValue)}
              renderInput={(params) => (
                <TextField  required 
                {...params} label="WorkFlows"
                error={Boolean(touched.workflows && errors.workflows)}
                helperText={touched.workflows && errors.workflows} />
              )}
              getOptionLabel={(option) => option.name}

            />
          </Stack> */}
                    </>
                  )}
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <Box
                    component={MobileDatePicker}
                    minDate={moment()}
                    label="Start Date"
                    value={moment.unix(values.start)}
                    onChange={(date) =>
                      setFieldValue('start', moment(date).unix())
                    }
                    // value={values.start}
                    // onChange={(date) => setFieldValue('start', moment(date).format('YYYY-MM-DD'))}
                    renderInput={(params) => (
                      <TextField size="medium" {...params} fullWidth />
                    )}
                  />
                  <Box
                    component={MobileDatePicker}
                    minDate={moment()}
                    label="Installation Date"
                    value={moment.unix(values.end)}
                    onChange={(date) =>
                      setFieldValue('end', moment(date).unix())
                    }
                    // value={values.end}
                    // onChange={(date) => setFieldValue('end', moment(date).format('YYYY-MM-DD'))}
                    renderInput={(params) => (
                      <TextField
                        size="medium"
                        {...params}
                        fullWidth
                        error={Boolean(touched.end && errors.end)}
                        helperText={touched.end && errors.end}
                      />
                    )}
                  />
                </Stack>
                {userType !== 3 &&
                  explodedPermissions?.some((permission) =>
                    ['Create Request'].includes(permission),
                  ) && (
                    <>
                      {values.project_categories?.name == 'Service only' ||
                      values.project_categories?.name == 'Time & Material' ? (
                        ''
                      ) : (
                        <>
                          <Divider />
                          <LabelStyleHead>Products</LabelStyleHead>
                          {/* {values.project_categories?.name == 'Time & Material' ?  */}
                          {false ? (
                            <>
                              <Scrollbar>
                                <TableContainer sx={{ minWidth: 500 }}>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        {VALUE_TIME?.map((val) =>
                                          val.id === 14 ? (
                                            <Tooltip
                                              key={val.id}
                                              title="Selling Price With Marketing Adjustment"
                                            >
                                              <TableCell>{val.value}</TableCell>
                                            </Tooltip>
                                          ) : (
                                            <TableCell key={val.id}>
                                              {val.value}
                                            </TableCell>
                                          ),
                                        )}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {selectedProducts?.map((data, index) => {
                                        const quantityFieldName = `quantity_${index}`
                                        const discountFieldName = `discount_${index}`
                                        const adjustmentFieldName = `adjustment_${index}`
                                        let unitName = UNITS?.filter(
                                          (item) => item?.id == data?.unit,
                                        )
                                        unitName = unitName[0]?.name
                                        let shortUnitName = ''
                                        if (unitName == 'Square feet') {
                                          shortUnitName = 'Sq ft'
                                        } else if (unitName == 'Lineal feet') {
                                          shortUnitName = 'Lin ft'
                                        } else if (
                                          unitName == 'Square Inches'
                                        ) {
                                          shortUnitName = 'Sq Inches'
                                        } else if (unitName == 'Unit') {
                                          shortUnitName = 'Unit'
                                        }
                                        let rowTotal = calculateTotal(
                                          markupType == 1
                                            ? data.price
                                            : markupType == 2
                                            ? data.retail_price
                                            : 0,
                                          Number(data.quantity),
                                          values[discountFieldName],
                                          data.locationQuantity,
                                          data.adjustment,
                                        )
                                        // Accumulate the totalAmount
                                        totalAmount += rowTotal
                                        // values['sub_total'] = totalAmount?.toFixed(2)
                                        data['product_sub_total'] = rowTotal
                                        data['markup_type'] = markupType
                                        const invoiceAmount =
                                          parseFloat(
                                            data?.expected_material_cost?.toFixed(
                                              2,
                                            ),
                                          ) +
                                          parseFloat(
                                            data?.expected_total_service_cost?.toFixed(
                                              2,
                                            ),
                                          )

                                        let markup =
                                          markupType == 1
                                            ? ((data.price -
                                                parseFloat(
                                                  data.expected_cost?.toFixed(
                                                    2,
                                                  ),
                                                )) /
                                                parseFloat(
                                                  data.expected_cost?.toFixed(
                                                    2,
                                                  ),
                                                )) *
                                              100
                                            : markupType == 2
                                            ? ((data.retail_price -
                                                parseFloat(
                                                  data.expected_cost?.toFixed(
                                                    2,
                                                  ),
                                                )) /
                                                parseFloat(
                                                  data.expected_cost?.toFixed(
                                                    2,
                                                  ),
                                                )) *
                                              100
                                            : 0
                                        // const markup = ((data.retail_price - data.price) / data.price) * 100;
                                        // let markup = markupType ? (invoiceAmount / rowTotal) : null
                                        markup = markup ? Number(markup) : 0

                                        let newTotalProductFormula =
                                          parseFloat(
                                            data.expected_cost?.toFixed(2),
                                          ) *
                                          data.quantity *
                                          data.locationQuantity
                                        newTotalProductFormula =
                                          newTotalProductFormula
                                            ? Number(newTotalProductFormula)
                                            : 0

                                        let newTotalWholesalePrice =
                                          data.price *
                                          data.quantity *
                                          data.locationQuantity
                                        if (data?.adjustment) {
                                          newTotalWholesalePrice =
                                            newTotalWholesalePrice
                                              ? newTotalWholesalePrice +
                                                (newTotalWholesalePrice *
                                                  data?.adjustment) /
                                                  100
                                              : 0
                                        }
                                        newTotalWholesalePrice =
                                          newTotalWholesalePrice
                                            ? Number(newTotalWholesalePrice)
                                            : 0

                                        let newTotalRetailPrice =
                                          data.retail_price *
                                          data.quantity *
                                          data.locationQuantity
                                        if (data?.adjustment) {
                                          newTotalRetailPrice =
                                            newTotalRetailPrice
                                              ? newTotalRetailPrice +
                                                (newTotalRetailPrice *
                                                  data?.adjustment) /
                                                  100
                                              : 0
                                        }
                                        newTotalRetailPrice =
                                          newTotalRetailPrice
                                            ? Number(newTotalRetailPrice)
                                            : 0

                                        totalProductFormula += Number(
                                          newTotalProductFormula,
                                        )
                                        totalWholesalePrice += Number(
                                          newTotalWholesalePrice,
                                        )
                                        totalRetailPrice +=
                                          Number(newTotalRetailPrice)

                                        values['totalProductFormula'] =
                                          totalProductFormula
                                        values['totalWholesalePrice'] =
                                          totalWholesalePrice
                                        values['totalRetailPrice'] =
                                          totalRetailPrice

                                        totalMarkup += markup
                                        let originalMarkup =
                                          markupType == 1
                                            ? data.wholesaleMarkup
                                            : markupType == 2
                                            ? data.retailMarkup
                                            : 0
                                        originaltotalMarkup += originalMarkup
                                        // values['totalMarkup'] = totalMarkup?.toFixed(2)
                                        data['markup'] = markup?.toFixed(2)
                                        // data['markup'] = Math.round(markup)
                                        console.log(
                                          '###############markup',
                                          markup,
                                        )
                                        console.log(
                                          '###############devvvvvv123',
                                          data,
                                        )

                                        const pfc =
                                          parseFloat(
                                            data.expected_cost?.toFixed(2),
                                          ) *
                                          data.quantity *
                                          data.locationQuantity // total product formula cost
                                        const rtp =
                                          data.retail_price *
                                          data.quantity *
                                          data.locationQuantity // total retail price
                                        const wsp =
                                          data.price *
                                          data.quantity *
                                          data.locationQuantity // total wholesale price

                                        // const maxDiscount_wholesale = (wsp - pfc) - DiscountTotal
                                        // const maxDiscount_retail = (rtp - pfc) - DiscountTotal

                                        const maxDiscount_wholesale = wsp - pfc
                                        const maxDiscount_retail = rtp - pfc

                                        console.log(
                                          'maxDiscount_wholesale--',
                                          maxDiscount_wholesale,
                                        )
                                        console.log(
                                          'maxDiscount_retail--',
                                          maxDiscount_retail,
                                        )
                                        console.log(
                                          'DiscountTotal--',
                                          DiscountTotal,
                                        )
                                        console.log('pfc--', pfc)
                                        console.log('rtp--', rtp)
                                        console.log('wsp--', wsp)
                                        data['product_total'] = rowTotal
                                        const productFormulaCost =
                                          parseFloat(
                                            (
                                              parseFloat(
                                                data.expected_material_cost?.toFixed(
                                                  2,
                                                ),
                                              ) *
                                              data.quantity *
                                              data.locationQuantity
                                            ).toFixed(2),
                                          ) +
                                          parseFloat(
                                            (
                                              parseFloat(
                                                data.expected_total_service_cost?.toFixed(
                                                  2,
                                                ),
                                              ) *
                                              data.quantity *
                                              data.locationQuantity
                                            ).toFixed(2),
                                          )

                                        let SPMA = 0
                                        if (markupType) {
                                          if (markupType == 1) {
                                            if (
                                              data.markAdjType == 2 ||
                                              data.markAdjType == 3
                                            ) {
                                              SPMA =
                                                (data.price
                                                  ? Number(data.price)
                                                  : 0) +
                                                (data.adjustment
                                                  ? Number(data.adjustment)
                                                  : 0)
                                            } else {
                                              SPMA =
                                                (data.price
                                                  ? Number(data.price)
                                                  : 0) +
                                                ((data.price
                                                  ? Number(data.price)
                                                  : 0) *
                                                  (data.adjustment
                                                    ? Number(data.adjustment)
                                                    : 0)) /
                                                  100
                                            }
                                          } else {
                                            if (
                                              data.markAdjType == 2 ||
                                              data.markAdjType == 3
                                            ) {
                                              SPMA =
                                                (data.retail_price
                                                  ? Number(data.retail_price)
                                                  : 0) +
                                                (data.adjustment
                                                  ? Number(data.adjustment)
                                                  : 0)
                                            } else {
                                              SPMA =
                                                (data.retail_price
                                                  ? Number(data.retail_price)
                                                  : 0) +
                                                ((data.retail_price
                                                  ? Number(data.retail_price)
                                                  : 0) *
                                                  (data.adjustment
                                                    ? Number(data.adjustment)
                                                    : 0)) /
                                                  100
                                            }
                                          }
                                        }
                                        console.log(
                                          'SPMA-----data.price-----',
                                          data.price,
                                        )
                                        console.log(
                                          'SPMA-----data.adjustment-----',
                                          data.adjustment,
                                        )
                                        console.log(
                                          'SPMA-----data.retail_price-----',
                                          data.retail_price,
                                        )
                                        console.log('SPMA-----SPMA-----', SPMA)
                                        SPMA = Number(SPMA)
                                        data['SPMA'] = SPMA?.toFixed(2)

                                        return (
                                          <>
                                            <TableRow key={index}>
                                              <TableCell>
                                                #{data?.indexId}
                                              </TableCell>
                                              <TableCell>
                                                {/* albin */}
                                                {/* <TextField required
                                      fullWidth
                                      type='text'
                                      label={'Product Name'}
                                      sx={{ width: 85 }}
                                      value={data.name}
                                      onChange={(event) => {
                                        let newValue = event.target.value;
                                        if (newValue !== "") {
                                          setSelectedProducts(pv => ([...pv.map(p => {
                                            if (p.indexId === data.indexId) {
                                              return {
                                                ...p,
                                                name: newValue,
                                              }
                                            } else {
                                              return p;
                                            }
                                          })]));
                                          data['name'] = newValue || '';
                                        }
                                      }}
                                      // error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                      // helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                    /> */}

                                                {/* ram */}
                                                <TextField
                                                  required
                                                  fullWidth
                                                  type="text"
                                                  label={'Product Name'}
                                                  sx={{ width: 85 }}
                                                  value={data.name}
                                                  onChange={(event) => {
                                                    let newValue =
                                                      event.target.value

                                                    // Update the selectedProducts state
                                                    setSelectedProducts(
                                                      (prevProducts) =>
                                                        prevProducts.map(
                                                          (product) => {
                                                            if (
                                                              product.indexId ===
                                                              data.indexId
                                                            ) {
                                                              return {
                                                                ...product,
                                                                name: newValue,
                                                              }
                                                            } else {
                                                              return product
                                                            }
                                                          },
                                                        ),
                                                    )
                                                    data['name'] =
                                                      newValue || ''
                                                    if (isSubmitted)
                                                      setIsSubmitted(false)
                                                  }}
                                                  error={
                                                    isSubmitted &&
                                                    data?.name?.trim() === ''
                                                  }
                                                  helperText={
                                                    isSubmitted &&
                                                    data?.name?.trim() === ''
                                                      ? 'Product Name is required'
                                                      : ''
                                                  }
                                                />
                                              </TableCell>
                                              <TableCell>
                                                <IconButton
                                                  onClick={() =>
                                                    handleOpenNote(
                                                      data.indexId,
                                                      data.product_notes,
                                                    )
                                                  }
                                                >
                                                  <NoteAdd
                                                    sx={{
                                                      color:
                                                        !data?.product_notes ||
                                                        data?.product_notes ===
                                                          ''
                                                          ? ''
                                                          : theme.palette
                                                              .primary.main,
                                                    }}
                                                  />
                                                </IconButton>
                                              </TableCell>
                                              <TableCell>
                                                {/* {(hasMarkupPermission && markupType == 2) ? data.retail_price?.toFixed(2) : null} */}

                                                <TextField
                                                  required
                                                  fullWidth
                                                  type="number"
                                                  label={'Retail Price Each'}
                                                  disabled={
                                                    values.project_categories
                                                      ?.name ==
                                                    'Time & Material'
                                                      ? true
                                                      : false
                                                  }
                                                  sx={{ width: 85 }}
                                                  value={data.retail_price}
                                                  defaultValue={0}
                                                  onChange={(event) => {
                                                    let newValue =
                                                      event.target.value
                                                    if (newValue !== '') {
                                                      setSelectedProducts(
                                                        (pv) => [
                                                          ...pv.map((p) => {
                                                            if (
                                                              p.indexId ===
                                                              data.indexId
                                                            ) {
                                                              return {
                                                                ...p,
                                                                retail_price:
                                                                  newValue,
                                                                price: newValue,
                                                              }
                                                            } else {
                                                              return p
                                                            }
                                                          }),
                                                        ],
                                                      )
                                                      data['retail_price'] =
                                                        newValue || 0
                                                      data['price'] =
                                                        newValue || 0
                                                    }
                                                  }}
                                                  // error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                                  // helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                                />
                                              </TableCell>
                                              <TableCell>
                                                {hasMarkupPermission
                                                  ? data?.SPMA || null
                                                  : null}
                                              </TableCell>
                                              <TableCell>
                                                <TextField
                                                  required
                                                  fullWidth
                                                  type="number"
                                                  label={shortUnitName}
                                                  disabled={
                                                    shortUnitName == 'Unit'
                                                      ? true
                                                      : false
                                                  }
                                                  sx={{ width: 85 }}
                                                  // {...getFieldProps(quantityFieldName)}
                                                  value={Number(data.quantity)}
                                                  defaultValue={1}
                                                  onKeyPress={(event) => {
                                                    if (
                                                      event.key === '0' &&
                                                      event.target.value === ''
                                                    ) {
                                                      event.preventDefault()
                                                    }
                                                    if (event.key === '-') {
                                                      event.preventDefault()
                                                    }
                                                    if (event.key === '.') {
                                                      event.preventDefault()
                                                    }
                                                  }}
                                                  onChange={(event) => {
                                                    let newValue =
                                                      event.target.value
                                                    if (
                                                      newValue !== '0' &&
                                                      newValue !== ''
                                                    ) {
                                                      console.log(
                                                        '################SelectedSeer',
                                                        selectedServices,
                                                      )

                                                      setSelectedProducts(
                                                        (pv) => [
                                                          ...pv.map((p) => {
                                                            if (
                                                              p.indexId ===
                                                              data.indexId
                                                            ) {
                                                              // if (p.id === data.id) {
                                                              // let arrayAddr

                                                              // const commonAddresses = (allLocationSame && pv.length > 0) ? [...pv[0].addresses][0] : null;

                                                              // if (allLocationSame && commonAddresses) {
                                                              //   arrayAddr = Array(Number(newValue)).fill().map((_, i) => ({
                                                              //     ...commonAddresses,
                                                              //     id: i
                                                              //   }));

                                                              // }
                                                              // else {
                                                              //   arrayAddr = [...p.addresses]
                                                              //   if (p.addresses.length < Number(newValue)) {
                                                              //     arrayAddr.push(...[...Array(Number(newValue) - p.addresses.length)].map((a, i) => {

                                                              //       return {
                                                              //         id: p.addresses.length + i,
                                                              //         address: '',
                                                              //         city: '',
                                                              //         state: '',
                                                              //         zip: '',
                                                              //       }
                                                              //     }))
                                                              //   } else {
                                                              //     arrayAddr = arrayAddr.slice(0, -(p.addresses.length - Number(newValue)));
                                                              //   }
                                                              // }

                                                              return {
                                                                ...p,
                                                                quantity:
                                                                  Number(
                                                                    newValue,
                                                                  ),
                                                                // addresses: arrayAddr
                                                              }
                                                            } else {
                                                              return p
                                                            }
                                                          }),
                                                        ],
                                                      )
                                                      setSelectedServices(
                                                        (pv) => [
                                                          ...pv.map(
                                                            (service) => {
                                                              // if (service.product_id === data.id) {
                                                              if (
                                                                service.productIndex ===
                                                                data.indexId
                                                              ) {
                                                                return {
                                                                  ...service,
                                                                  productIndex:
                                                                    data.indexId,
                                                                  quantity: (
                                                                    service.previousQty *
                                                                    Number(
                                                                      newValue,
                                                                    ) *
                                                                    service.locationQuantity
                                                                  )?.toFixed(2),
                                                                  qtyData:
                                                                    Number(
                                                                      newValue,
                                                                    ),
                                                                  total: (
                                                                    service.cost *
                                                                    (service.previousQty *
                                                                      Number(
                                                                        newValue,
                                                                      ) *
                                                                      service.locationQuantity)
                                                                  )?.toFixed(2),
                                                                }
                                                              } else {
                                                                return service
                                                              }
                                                            },
                                                          ),
                                                        ],
                                                      )
                                                      //new chnage on 2-2-24
                                                      if (
                                                        newValue === '' ||
                                                        newValue <= 0
                                                      ) {
                                                        event.preventDefault()
                                                        return
                                                      }
                                                      if (
                                                        newValue.startsWith('-')
                                                      ) {
                                                        newValue =
                                                          newValue.slice(1)
                                                      }
                                                      data['quantity'] =
                                                        newValue || 1
                                                      setFieldValue(
                                                        quantityFieldName,
                                                        newValue,
                                                      )
                                                    }
                                                  }}
                                                  error={Boolean(
                                                    touched[
                                                      quantityFieldName
                                                    ] &&
                                                      errors[quantityFieldName],
                                                  )}
                                                  helperText={
                                                    touched[
                                                      quantityFieldName
                                                    ] &&
                                                    errors[quantityFieldName]
                                                  }
                                                />
                                              </TableCell>
                                              <TableCell>
                                                <TextField
                                                  required
                                                  fullWidth
                                                  type="number"
                                                  label={'Qty'}
                                                  // {...getFieldProps(quantityFieldName)}
                                                  value={Number(
                                                    data.locationQuantity,
                                                  )}
                                                  defaultValue={1}
                                                  onKeyPress={(event) => {
                                                    if (
                                                      event.key === '0' &&
                                                      event.target.value === ''
                                                    ) {
                                                      event.preventDefault()
                                                    }
                                                    if (event.key === '-') {
                                                      event.preventDefault()
                                                    }
                                                    if (event.key === '.') {
                                                      event.preventDefault()
                                                    }
                                                  }}
                                                  onChange={(event) => {
                                                    let newValue =
                                                      event.target.value
                                                    if (
                                                      newValue !== '0' &&
                                                      newValue !== ''
                                                    ) {
                                                      setSelectedProducts(
                                                        (pv) => [
                                                          ...pv.map((p) => {
                                                            console.log(
                                                              '################p.id',
                                                              p.id,
                                                            )
                                                            console.log(
                                                              '################data.id',
                                                              data.id,
                                                            )
                                                            // if (p.id === data.id) {
                                                            if (
                                                              p.indexId ===
                                                              data.indexId
                                                            ) {
                                                              let arrayAddr

                                                              const commonAddresses =
                                                                data?.allLocationSame &&
                                                                pv.length > 0
                                                                  ? p.addresses[0]
                                                                  : null

                                                              if (
                                                                data?.allLocationSame &&
                                                                commonAddresses
                                                              ) {
                                                                arrayAddr =
                                                                  Array(
                                                                    Number(1),
                                                                  )
                                                                    .fill()
                                                                    .map(
                                                                      (
                                                                        _,
                                                                        i,
                                                                      ) => ({
                                                                        ...commonAddresses,
                                                                        id: i,
                                                                      }),
                                                                    )
                                                              } else {
                                                                arrayAddr = [
                                                                  ...p.addresses,
                                                                ]
                                                                console.log(
                                                                  '################arrayAddr',
                                                                  arrayAddr,
                                                                )
                                                                console.log(
                                                                  '################p.addresses.length',
                                                                  p.addresses
                                                                    .length,
                                                                )
                                                                console.log(
                                                                  '################Number(newValue)',
                                                                  Number(
                                                                    newValue,
                                                                  ),
                                                                )
                                                                if (
                                                                  p.addresses
                                                                    .length < 1
                                                                ) {
                                                                  arrayAddr.push(
                                                                    ...[
                                                                      ...Array(
                                                                        1 -
                                                                          p
                                                                            .addresses
                                                                            .length,
                                                                      ),
                                                                    ].map(
                                                                      (
                                                                        a,
                                                                        i,
                                                                      ) => {
                                                                        return {
                                                                          id:
                                                                            p
                                                                              .addresses
                                                                              .length +
                                                                            i,
                                                                          address:
                                                                            '',
                                                                          city: '',
                                                                          state: '',
                                                                          zip: '',
                                                                        }
                                                                      },
                                                                    ),
                                                                  )
                                                                } else if (
                                                                  p.addresses
                                                                    .length > Number(newValue) //1
                                                                ) {
                                                                  arrayAddr =
                                                                    arrayAddr.slice(
                                                                      0,
                                                                      Number(newValue), //1
                                                                    )
                                                                } else {
                                                                  arrayAddr = arrayAddr.slice(0, -(p.addresses.length - Number(newValue)));
                                                                }
                                                              }

                                                              console.log(
                                                                '###########Arrayadde',
                                                                arrayAddr,
                                                              )
                                                              return {
                                                                ...p,
                                                                // quantity: Number(newValue),
                                                                locationQuantity:
                                                                  Number(
                                                                    newValue,
                                                                  ),
                                                                addresses:
                                                                  arrayAddr,
                                                              }
                                                            } else {
                                                              return p
                                                            }
                                                          }),
                                                        ],
                                                      )
                                                      setSelectedServices(
                                                        (pv) => [
                                                          ...pv.map(
                                                            (service) => {
                                                              // if (service.product_id === data.id) {
                                                              console.log(
                                                                'locationQty---service----',
                                                                service,
                                                              )
                                                              console.log(
                                                                'locationQty---data----',
                                                                data,
                                                              )
                                                              if (
                                                                service?.productIndex ===
                                                                data?.indexId
                                                              ) {
                                                                return {
                                                                  ...service,
                                                                  productIndex:
                                                                    data?.indexId,
                                                                  quantity: (
                                                                    service.previousQty *
                                                                    Number(
                                                                      newValue,
                                                                    ) *
                                                                    (service.qtyData ||
                                                                      1)
                                                                  )?.toFixed(2),
                                                                  locationQuantity:
                                                                    Number(
                                                                      newValue,
                                                                    ),
                                                                }
                                                              } else {
                                                                return service
                                                              }
                                                            },
                                                          ),
                                                        ],
                                                      )
                                                      //new chnage on 2-2-24
                                                      if (
                                                        newValue === '' ||
                                                        newValue <= 0
                                                      ) {
                                                        event.preventDefault()
                                                        return
                                                      }
                                                      if (
                                                        newValue.startsWith('-')
                                                      ) {
                                                        newValue =
                                                          newValue.slice(1)
                                                      }
                                                      data['locationQuantity'] =
                                                        newValue || 1
                                                      // setFieldValue(quantityFieldName, newValue);
                                                    }
                                                  }}
                                                  // error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                                  // helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                                />
                                              </TableCell>
                                              <TableCell>
                                                <IconButton
                                                  onClick={() => {
                                                    setLocationStates({
                                                      ...locationStates,
                                                      [data.indexId]: true,
                                                    })
                                                  }}
                                                >
                                                  <LocationOn
                                                    sx={{
                                                      color:
                                                        data.addresses.some(
                                                          (obj) =>
                                                            [obj.address, obj.city, obj.state, obj.zip].some(
                                                              (value) =>
                                                                (value === '' || value === null),
                                                            ),
                                                        )
                                                          ? ''
                                                          : theme.palette
                                                              .primary.main,
                                                    }}
                                                  />
                                                </IconButton>
                                              </TableCell>
                                              {/* <TableCell>{data.id}</TableCell> */}
                                              <TableCell>
                                                <Autocomplete
                                                  sx={{ width: 70 }}
                                                  id="combo-box-demo"
                                                  disableClearable
                                                  options={
                                                    marketingAdjustmentType ||
                                                    []
                                                  }
                                                  defaultValue={
                                                    marketingAdjustmentType.find(
                                                      (type) =>
                                                        type.id ===
                                                        data.markAdjType,
                                                    ) ||
                                                    marketingAdjustmentType[0]
                                                  }
                                                  onChange={(
                                                    event,
                                                    newValue,
                                                  ) => {
                                                    setSelectedProducts(
                                                      (prevProducts) => {
                                                        return prevProducts.map(
                                                          (product) => {
                                                            if (
                                                              product.indexId ===
                                                              data.indexId
                                                            ) {
                                                              return {
                                                                ...product,
                                                                markAdjType:
                                                                  newValue?.id ||
                                                                  marketingAdjustmentType[0]
                                                                    .id, // Set adjustment type or default to the first option
                                                                adjustment: 0,
                                                              }
                                                            }
                                                            return product
                                                          },
                                                        )
                                                      },
                                                    )
                                                  }}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      required
                                                      error={Boolean(
                                                        touched.marketingAdjType &&
                                                          errors.marketingAdjType,
                                                      )}
                                                      helperText={
                                                        touched.marketingAdjType &&
                                                        errors.marketingAdjType
                                                      }
                                                      label="Select type"
                                                    />
                                                  )}
                                                  getOptionLabel={(option) =>
                                                    option.name
                                                      ? option.name
                                                      : ''
                                                  }
                                                />
                                              </TableCell>
                                              <TableCell>
                                                {/* <TextField
                                      type="number"
                                      label="Discount"
                                      defaultValue={0}
                                      value={Number(data.discount)}
                                      // {...getFieldProps(discountFieldName)}
                                      onChange={(event) => {
                                        let newValue = event.target.value;
                                        if (newValue.startsWith('-')) {
                                          newValue = newValue.slice(1);
                                        }
                                        if (markupType == 1) {
                                          if (Number(newValue) > maxDiscount_wholesale) {
                                            newValue = maxDiscount_wholesale.toFixed(2);
                                          }
                                        } else if (markupType == 2) {
                                          if (Number(newValue) > maxDiscount_retail) {
                                            newValue = maxDiscount_retail.toFixed(2);
                                          }
                                        } else {
                                          newValue = 0;
                                        }
                                        if (newValue === '-0.00') {
                                          newValue = 0;
                                        }
                                        data['discount'] = newValue
                                        setFieldValue(discountFieldName, newValue);
                                      }}
                                      error={Boolean(touched[discountFieldName] && errors[discountFieldName])}
                                      helperText={touched[discountFieldName] && errors[discountFieldName]}
                                      inputProps={{ max: markupType == 1 ? maxDiscount_wholesale : markupType == 2 ? maxDiscount_retail : 0, min: 0 }}
                                    /> */}
                                                <TextField
                                                  type="number"
                                                  label={
                                                    data.markAdjType === 2
                                                      ? '$'
                                                      : data.markAdjType === 3
                                                      ? '¢'
                                                      : '%'
                                                  }
                                                  defaultValue={
                                                    data?.adjustment
                                                      ? Number(data?.adjustment)
                                                      : 0
                                                  }
                                                  value={Number(
                                                    data.adjustment,
                                                  ).toFixed(2)}
                                                  onChange={(event) => {
                                                    let newValue =
                                                      event.target.value
                                                    if (
                                                      newValue.startsWith('-')
                                                    ) {
                                                      newValue =
                                                        newValue.slice(1)
                                                    }
                                                    if (newValue === '-0.00') {
                                                      newValue = 0
                                                    }
                                                    data['adjustment'] =
                                                      newValue
                                                    setFieldValue(
                                                      adjustmentFieldName,
                                                      newValue,
                                                    )
                                                  }}
                                                  error={Boolean(
                                                    touched[
                                                      adjustmentFieldName
                                                    ] &&
                                                      errors[
                                                        adjustmentFieldName
                                                      ],
                                                  )}
                                                  helperText={
                                                    touched[
                                                      adjustmentFieldName
                                                    ] &&
                                                    errors[adjustmentFieldName]
                                                  }
                                                />
                                              </TableCell>
                                              <TableCell>
                                                {calculateTotal(
                                                  markupType == 1
                                                    ? Number(data.price)
                                                    : markupType == 2
                                                    ? Number(data.retail_price)
                                                    : 0,
                                                  Number(data.quantity),
                                                  values[discountFieldName],
                                                  data.locationQuantity,
                                                  data.adjustment,
                                                  data.markAdjType,
                                                )?.toFixed(2)}
                                              </TableCell>
                                              <TableCell>
                                                <IconButton
                                                  onClick={() =>
                                                    handleDeleteProduct(
                                                      data.id,
                                                      data.indexId,
                                                    )
                                                  }
                                                >
                                                  <Delete />
                                                </IconButton>
                                              </TableCell>
                                            </TableRow>
                                            <Modal
                                              open={
                                                locationStates[data.indexId]
                                              }
                                              handleClose={() => {
                                                setLocationStates({
                                                  ...locationStates,
                                                  [data.indexId]: false,
                                                })
                                              }}
                                            >
                                              {/* {index === 0 && ( */}
                                                <FormControlLabel
                                                  control={
                                                    <Switch
                                                      checked={data?.allLocationSame}
                                                      onChange={()=>
                                                        handleAllLocationSameChange(index)
                                                      }
                                                    />
                                                  }
                                                  label="All Locations Same"
                                                />
                                              {/* )} */}
                                              <Grid
                                                container
                                                spacing={3}
                                                sx={{
                                                  justifyContent: 'center',
                                                  width: '100%',
                                                }}
                                              >
                                                {data?.addresses?.map(
                                                  (addr, i) => (
                                                    <Grid item xs={12}>
                                                      {/* Side Heading */}
                                                      <Typography
                                                        variant="h5"
                                                        sx={{ mb: 2 }}
                                                      >
                                                        Location {i + 1}
                                                      </Typography>

                                                      {/* Address Fields */}

                                                      <Stack
                                                        direction={{
                                                          xs: 'column',
                                                          sm: 'row',
                                                        }}
                                                        spacing={{
                                                          xs: 3,
                                                          sm: 2,
                                                        }}
                                                        sx={{ mb: 2 }}
                                                      >
                                                        <TextField
                                                          fullWidth
                                                          label="Address"
                                                          size="small"
                                                          value={
                                                            data.addresses[i]
                                                              .address || ''
                                                          }
                                                          disabled={
                                                            i !== 0 &&
                                                            data?.allLocationSame
                                                              ? true
                                                              : false
                                                          }
                                                          // onChange={(event) => {
                                                          //   let arrayAddr = [
                                                          //     ...data.addresses,
                                                          //   ]
                                                          //   arrayAddr[
                                                          //     i
                                                          //   ].address =
                                                          //     event.target.value
                                                          //   setSelectedProducts(
                                                          //     (pv) => [
                                                          //       ...pv.map(
                                                          //         (p) => {
                                                          //           // if (p.id === data.id) {
                                                          //           if (
                                                          //             p.indexId ===
                                                          //             data.indexId
                                                          //           ) {
                                                          //             return {
                                                          //               ...p,
                                                          //               addresses:
                                                          //                 arrayAddr,
                                                          //             }
                                                          //           } else {
                                                          //             return p
                                                          //           }
                                                          //         },
                                                          //       ),
                                                          //     ],
                                                          //   )
                                                          // }}
                                                          onChange={(event) => {
                                                            setSelectedProducts((prevProducts) =>
                                                              prevProducts.map((p) => {
                                                                if (p.indexId === data.indexId) {
                                                                  let updatedAddresses = [...p.addresses];
                                                                  if (p.allLocationSame) {
                                                                    updatedAddresses = updatedAddresses.map(() => ({
                                                                      ...updatedAddresses[0],
                                                                      address: event.target.value,
                                                                    }));
                                                                  } else {
                                                                    updatedAddresses[i] = {
                                                                      ...updatedAddresses[i],
                                                                      address: event.target.value,
                                                                    };
                                                                  }
                                                                  return {
                                                                    ...p,
                                                                    addresses: updatedAddresses,
                                                                  };
                                                                }
                                                                return p;
                                                              })
                                                            );
                                                          }}
                                                        />
                                                      </Stack>

                                                      {/* City, State, Zip Fields */}
                                                      <Stack
                                                        direction={{
                                                          xs: 'column',
                                                          sm: 'row',
                                                        }}
                                                        spacing={{
                                                          xs: 3,
                                                          sm: 2,
                                                        }}
                                                        sx={{ mb: 2 }}
                                                      >
                                                        <TextField
                                                          fullWidth
                                                          label="City"
                                                          size="small"
                                                          value={
                                                            data.addresses[i]
                                                              .city || ''
                                                          }
                                                          disabled={
                                                            i !== 0 &&
                                                            data?.allLocationSame
                                                              ? true
                                                              : false
                                                          }
                                                          // onChange={(event) => {
                                                          //   let arrayAddr = [
                                                          //     ...data.addresses,
                                                          //   ]
                                                          //   arrayAddr[i].city =
                                                          //     event.target.value
                                                          //   setSelectedProducts(
                                                          //     (pv) => [
                                                          //       ...pv.map(
                                                          //         (p) => {
                                                          //           // if (p.id === data.id) {
                                                          //           if (
                                                          //             p.indexId ===
                                                          //             data.indexId
                                                          //           ) {
                                                          //             return {
                                                          //               ...p,
                                                          //               addresses:
                                                          //                 arrayAddr,
                                                          //             }
                                                          //           } else {
                                                          //             return p
                                                          //           }
                                                          //         },
                                                          //       ),
                                                          //     ],
                                                          //   )
                                                          // }}
                                                          onChange={(event) => {
                                                            setSelectedProducts((prevProducts) =>
                                                              prevProducts.map((p) => {
                                                                if (p.indexId === data.indexId) {
                                                                  let updatedAddresses = [...p.addresses];
                                                                  if (p.allLocationSame) {
                                                                    updatedAddresses = updatedAddresses.map(() => ({
                                                                      ...updatedAddresses[0],
                                                                      city: event.target.value,
                                                                    }));
                                                                  } else {
                                                                    updatedAddresses[i] = {
                                                                      ...updatedAddresses[i],
                                                                      city: event.target.value,
                                                                    };
                                                                  }
                                                                  return {
                                                                    ...p,
                                                                    addresses: updatedAddresses,
                                                                  };
                                                                }
                                                                return p;
                                                              })
                                                            );
                                                          }}
                                                        />
                                                        <TextField
                                                          fullWidth
                                                          label="State"
                                                          size="small"
                                                          value={
                                                            data.addresses[i]
                                                              .state || ''
                                                          }
                                                          disabled={
                                                            i !== 0 &&
                                                            data?.allLocationSame
                                                              ? true
                                                              : false
                                                          }
                                                          // onChange={(event) => {
                                                          //   let arrayAddr = [
                                                          //     ...data.addresses,
                                                          //   ]
                                                          //   arrayAddr[i].state =
                                                          //     event.target.value
                                                          //   setSelectedProducts(
                                                          //     (pv) => [
                                                          //       ...pv.map(
                                                          //         (p) => {
                                                          //           // if (p.id === data.id) {
                                                          //           if (
                                                          //             p.indexId ===
                                                          //             data.indexId
                                                          //           ) {
                                                          //             return {
                                                          //               ...p,
                                                          //               addresses:
                                                          //                 arrayAddr,
                                                          //             }
                                                          //           } else {
                                                          //             return p
                                                          //           }
                                                          //         },
                                                          //       ),
                                                          //     ],
                                                          //   )
                                                          // }}
                                                          onChange={(event) => {
                                                            setSelectedProducts((prevProducts) =>
                                                              prevProducts.map((p) => {
                                                                if (p.indexId === data.indexId) {
                                                                  let updatedAddresses = [...p.addresses];
                                                                  if (p.allLocationSame) {
                                                                    updatedAddresses = updatedAddresses.map(() => ({
                                                                      ...updatedAddresses[0],
                                                                      state: event.target.value,
                                                                    }));
                                                                  } else {
                                                                    updatedAddresses[i] = {
                                                                      ...updatedAddresses[i],
                                                                      state: event.target.value,
                                                                    };
                                                                  }
                                                                  return {
                                                                    ...p,
                                                                    addresses: updatedAddresses,
                                                                  };
                                                                }
                                                                return p;
                                                              })
                                                            );
                                                          }}
                                                        />
                                                        <TextField
                                                          fullWidth
                                                          label="Zip / Postal Code"
                                                          size="small"
                                                          value={
                                                            data.addresses[i]
                                                              .zip || ''
                                                          }
                                                          disabled={
                                                            i !== 0 &&
                                                            data?.allLocationSame
                                                              ? true
                                                              : false
                                                          }
                                                          // onChange={(event) => {
                                                          //   let arrayAddr = [
                                                          //     ...data.addresses,
                                                          //   ]
                                                          //   arrayAddr[i].zip =
                                                          //     event.target.value
                                                          //   setSelectedProducts(
                                                          //     (pv) => [
                                                          //       ...pv.map(
                                                          //         (p) => {
                                                          //           if (
                                                          //             p.indexId ===
                                                          //             data.indexId
                                                          //           ) {
                                                          //             // if (p.id === data.id) {
                                                          //             return {
                                                          //               ...p,
                                                          //               addresses:
                                                          //                 arrayAddr,
                                                          //             }
                                                          //           } else {
                                                          //             return p
                                                          //           }
                                                          //         },
                                                          //       ),
                                                          //     ],
                                                          //   )
                                                          // }}
                                                          onChange={(event) => {
                                                            setSelectedProducts((prevProducts) =>
                                                              prevProducts.map((p) => {
                                                                if (p.indexId === data.indexId) {
                                                                  let updatedAddresses = [...p.addresses];
                                                                  if (p.allLocationSame) {
                                                                    updatedAddresses = updatedAddresses.map(() => ({
                                                                      ...updatedAddresses[0],
                                                                      zip: event.target.value,
                                                                    }));
                                                                  } else {
                                                                    updatedAddresses[i] = {
                                                                      ...updatedAddresses[i],
                                                                      zip: event.target.value,
                                                                    };
                                                                  }
                                                                  return {
                                                                    ...p,
                                                                    addresses: updatedAddresses,
                                                                  };
                                                                }
                                                                return p;
                                                              })
                                                            );
                                                          }}
                                                        />
                                                      </Stack>
                                                    </Grid>
                                                  ),
                                                )}
                                              </Grid>

                                              <Grid
                                                container
                                                justifyContent="center"
                                                alignItems="center"
                                              >
                                                <Grid item xs={12} md={4}>
                                                  {/* {index !== 0 && data?.allLocationSame */}
                                                  {false
                                                  ? (
                                                    ''
                                                  ) : (
                                                    <Box
                                                      sx={{
                                                        mt: 3,
                                                        display: 'flex',
                                                        justifyContent:
                                                          'center',
                                                        gap: 1,
                                                      }}
                                                    >
                                                      <LoadingButton
                                                        variant="contained"
                                                        onClick={() => {
                                                          setIsSaving(true)
                                                          setTimeout(() => {
                                                            setIsSaving(false)
                                                            setLocationStates(
                                                              false,
                                                            )
                                                          }, 100)
                                                        }}
                                                        loading={isSaving}
                                                      >
                                                        {' Save '}
                                                      </LoadingButton>
                                                      {/* <Button variant="outlined" type="submit" onClick={() => { setLocationStates(false) }}>
                                        Cancel
                                      </Button> */}
                                                    </Box>
                                                  )}
                                                </Grid>
                                              </Grid>
                                            </Modal>
                                            <Modal
                                              open={
                                                openProductNote[data.indexId] ||
                                                false
                                              }
                                              handleClose={() =>
                                                handleCloseNote(data.indexId)
                                              }
                                            >
                                              <Grid
                                                container
                                                spacing={3}
                                                sx={{
                                                  justifyContent: 'center',
                                                  width: '100%',
                                                }}
                                              >
                                                <Grid item xs={12}>
                                                  <Typography
                                                    variant="h5"
                                                    sx={{ mb: 2 }}
                                                  >
                                                    Note
                                                  </Typography>

                                                  <Stack
                                                    direction={{
                                                      xs: 'column',
                                                      sm: 'row',
                                                    }}
                                                    spacing={{ xs: 3, sm: 2 }}
                                                    sx={{ mb: 2 }}
                                                  >
                                                    <TextField
                                                      fullWidth
                                                      multiline
                                                      rows={4}
                                                      size="medium"
                                                      value={currentNote}
                                                      onChange={
                                                        handleNoteChange
                                                      }
                                                      inputProps={{
                                                        maxLength:
                                                          MAX_CHARACTERS,
                                                      }}
                                                      helperText={`${currentNote.length}/${MAX_CHARACTERS} characters`}
                                                    />
                                                  </Stack>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                justifyContent="center"
                                                alignItems="center"
                                              >
                                                <Grid item xs={12} md={4}>
                                                  <Box
                                                    sx={{
                                                      mt: 3,
                                                      display: 'flex',
                                                      justifyContent: 'center',
                                                      gap: 1,
                                                    }}
                                                  >
                                                    <LoadingButton
                                                      variant="contained"
                                                      onClick={() =>
                                                        handleSaveNote(
                                                          data.indexId,
                                                        )
                                                      }
                                                      loading={isSavingNote}
                                                    >
                                                      Save
                                                    </LoadingButton>
                                                  </Box>
                                                </Grid>
                                              </Grid>
                                            </Modal>
                                          </>
                                        )
                                      })}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Scrollbar>
                              {
                            showAddNewRow ?
                              <Stack sx={{ justifyContent: 'center' }}>
                                <Box sx={{ justifyContent: 'center' }}>
                                  <Button
                                    variant="outlined"
                                    startIcon={
                                      <Stack>
                                        {' '}
                                        <Add />{' '}
                                      </Stack>
                                    }
                                    onClick={() => {
                                      addNewProductRow()
                                      // setMarkupType(1)
                                    }}
                                  >
                                    Add Product Row
                                  </Button>
                                </Box>
                              </Stack>
                              :""}
                            </>
                          ) : (
                            <>
                              <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                spacing={{ xs: 3, sm: 2 }}
                              >
                                <Autocomplete
                                  sx={{ width: 400 }}
                                  multiple
                                  id="combo-box-demo"
                                  options={allProducts || []}
                                  value={selectedProducts?.filter(
                                    (item) => (item?.fromMapServices != true && !item.isAdditionalProduct)
                                  )}
                                  onChange={(event, newValue) => {
                                    setFieldValue('products', newValue)
                                    handleProduct(newValue)
   setShowAddNewRow(true)

                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      required
                                      error={Boolean(
                                        touched.products && errors.products,
                                      )}
                                      helperText={
                                        touched.products && errors.products
                                      }
                                      label="Select a product"
                                    />
                                  )}
                                  getOptionLabel={(option) =>
                                    option.name ? option.name : ''
                                  }
                                />
                              </Stack>
                              <LabelStyle>Selected Products</LabelStyle>
                              <Scrollbar>
                                <TableContainer sx={{ minWidth: 500 }}>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        {VALUE?.map((val) =>
                                          val.id === 14 ? (
                                            <Tooltip
                                              key={val.id}
                                              title="Selling Price With Marketing Adjustment"
                                            >
                                              <TableCell>{val.value}</TableCell>
                                            </Tooltip>
                                          ) : (
                                            // ) : (val.id === 2 ? (
                                            //   <Tooltip key={val.id} title="((Wholesale Price - Product Formula Cost)/Product Formula Cost) * 100">
                                            //     <TableCell>{val.value}</TableCell>
                                            //   </Tooltip>
                                            <TableCell key={val.id}>
                                              {val.value}
                                            </TableCell>
                                          ),
                                          // )
                                        )}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {selectedProducts?.sort((a, b) => a.indexId - b.indexId)?.map((data, index) => {
                                        const quantityFieldName = `quantity_${index}`
                                        const discountFieldName = `discount_${index}`
                                        const adjustmentFieldName = `adjustment_${index}`
                                        let unitName = UNITS?.filter(
                                          (item) => item?.id == data?.unit,
                                        )
                                        unitName = unitName[0]?.name
                                        let shortUnitName = ''
                                        if (unitName == 'Square feet') {
                                          shortUnitName = 'Sq ft'
                                        } else if (unitName == 'Lineal feet') {
                                          shortUnitName = 'Lin ft'
                                        } else if (
                                          unitName == 'Square Inches'
                                        ) {
                                          shortUnitName = 'Sq Inches'
                                        } else if (unitName == 'Unit') {
                                          shortUnitName = 'Unit'
                                        }
                                        if (
                                          values.project_categories?.name ==
                                          'Time & Material'
                                        ) {
                                          data.price = 0
                                          data.retail_price = 0
                                          data.expected_cost = 0
                                          data.expected_material_cost = 0
                                          data.expected_total_service_cost = 0
                                        }
                                        const rowTotal = calculateTotal(
                                          markupType == 1
                                            ? data.price
                                            : markupType == 2
                                            ? data.retail_price
                                            : 0,
                                          Number(data.quantity),
                                          values[discountFieldName],
                                          data.locationQuantity,
                                          data.adjustment,
                                        )
                                        // Accumulate the totalAmount
                                        totalAmount += rowTotal
                                        // values['sub_total'] = totalAmount?.toFixed(2)
                                        data['product_sub_total'] = rowTotal
                                        data['markup_type'] = markupType
                                        const invoiceAmount =
                                          parseFloat(
                                            data?.expected_material_cost?.toFixed(
                                              2,
                                            ),
                                          ) +
                                          parseFloat(
                                            data?.expected_total_service_cost?.toFixed(
                                              2,
                                            ),
                                          )

                                        let markup =
                                          markupType == 1
                                            ? ((data.price -
                                                parseFloat(
                                                  data.expected_cost?.toFixed(
                                                    2,
                                                  ),
                                                )) /
                                                parseFloat(
                                                  data.expected_cost?.toFixed(
                                                    2,
                                                  ),
                                                )) *
                                              100
                                            : markupType == 2
                                            ? ((data.retail_price -
                                                parseFloat(
                                                  data.expected_cost?.toFixed(
                                                    2,
                                                  ),
                                                )) /
                                                parseFloat(
                                                  data.expected_cost?.toFixed(
                                                    2,
                                                  ),
                                                )) *
                                              100
                                            : 0
                                        // const markup = ((data.retail_price - data.price) / data.price) * 100;
                                        // let markup = markupType ? (invoiceAmount / rowTotal) : null
                                        markup = markup ? Number(markup) : 0

                                        let newTotalProductFormula =
                                          parseFloat(
                                            data.expected_cost?.toFixed(2),
                                          ) *
                                          data.quantity *
                                          data.locationQuantity
                                        newTotalProductFormula =
                                          newTotalProductFormula
                                            ? Number(newTotalProductFormula)
                                            : 0

                                        let newTotalWholesalePrice =
                                          data.price *
                                          data.quantity *
                                          data.locationQuantity
                                        if (data?.adjustment) {
                                          newTotalWholesalePrice =
                                            newTotalWholesalePrice
                                              ? newTotalWholesalePrice +
                                                (newTotalWholesalePrice *
                                                  data?.adjustment) /
                                                  100
                                              : 0
                                        }
                                        newTotalWholesalePrice =
                                          newTotalWholesalePrice
                                            ? Number(newTotalWholesalePrice)
                                            : 0

                                        let newTotalRetailPrice =
                                          data.retail_price *
                                          data.quantity *
                                          data.locationQuantity
                                        if (data?.adjustment) {
                                          newTotalRetailPrice =
                                            newTotalRetailPrice
                                              ? newTotalRetailPrice +
                                                (newTotalRetailPrice *
                                                  data?.adjustment) /
                                                  100
                                              : 0
                                        }
                                        newTotalRetailPrice =
                                          newTotalRetailPrice
                                            ? Number(newTotalRetailPrice)
                                            : 0

                                        totalProductFormula += Number(
                                          newTotalProductFormula,
                                        )
                                        totalWholesalePrice += Number(
                                          newTotalWholesalePrice,
                                        )
                                        totalRetailPrice +=
                                          Number(newTotalRetailPrice)

                                        values['totalProductFormula'] =
                                          totalProductFormula
                                        values['totalWholesalePrice'] =
                                          totalWholesalePrice
                                        values['totalRetailPrice'] =
                                          totalRetailPrice

                                        totalMarkup += markup
                                        let originalMarkup =
                                          markupType == 1
                                            ? data.wholesaleMarkup
                                            : markupType == 2
                                            ? data.retailMarkup
                                            : 0
                                        originaltotalMarkup += originalMarkup
                                        // values['totalMarkup'] = totalMarkup?.toFixed(2)
                                        data['markup'] = markup?.toFixed(2)
                                        // data['markup'] = Math.round(markup)
                                        console.log(
                                          '###############markup',
                                          markup,
                                        )
                                        console.log(
                                          '###############devvvvvv123',
                                          data,
                                        )

                                        const pfc =
                                          parseFloat(
                                            data.expected_cost?.toFixed(2),
                                          ) *
                                          data.quantity *
                                          data.locationQuantity // total product formula cost
                                        const rtp =
                                          data.retail_price *
                                          data.quantity *
                                          data.locationQuantity // total retail price
                                        const wsp =
                                          data.price *
                                          data.quantity *
                                          data.locationQuantity // total wholesale price

                                        // const maxDiscount_wholesale = (wsp - pfc) - DiscountTotal
                                        // const maxDiscount_retail = (rtp - pfc) - DiscountTotal

                                        const maxDiscount_wholesale = wsp - pfc
                                        const maxDiscount_retail = rtp - pfc

                                        console.log(
                                          'maxDiscount_wholesale--',
                                          maxDiscount_wholesale,
                                        )
                                        console.log(
                                          'maxDiscount_retail--',
                                          maxDiscount_retail,
                                        )
                                        console.log(
                                          'DiscountTotal--',
                                          DiscountTotal,
                                        )
                                        console.log('pfc--', pfc)
                                        console.log('rtp--', rtp)
                                        console.log('wsp--', wsp)
                                        data['product_total'] = rowTotal
                                        const productFormulaCost =
                                          parseFloat(
                                            (
                                              parseFloat(
                                                data.expected_material_cost?.toFixed(
                                                  2,
                                                ),
                                              ) *
                                              data.quantity *
                                              data.locationQuantity
                                            ).toFixed(2),
                                          ) +
                                          parseFloat(
                                            (
                                              parseFloat(
                                                data.expected_total_service_cost?.toFixed(
                                                  2,
                                                ),
                                              ) *
                                              data.quantity *
                                              data.locationQuantity
                                            ).toFixed(2),
                                          )

                                        let SPMA = 0
                                        if (markupType) {
                                          if (markupType == 1) {
                                            if (
                                              data.markAdjType == 2 ||
                                              data.markAdjType == 3
                                            ) {
                                              SPMA =
                                                (Number(data.price) || 0) +
                                                (data.adjustment
                                                  ? Number(data.adjustment)
                                                  : 0)
                                            } else {
                                              SPMA =
                                                (Number(data.price) || 0) +
                                                ((Number(data.price) || 0) *
                                                  (data.adjustment
                                                    ? Number(data.adjustment)
                                                    : 0)) /
                                                  100
                                            }
                                          } else {
                                            if (
                                              data.markAdjType == 2 ||
                                              data.markAdjType == 3
                                            ) {
                                              SPMA =
                                                (Number(data.retail_price) || 0) +
                                                (data.adjustment
                                                  ? Number(data.adjustment)
                                                  : 0)
                                            } else {
                                              SPMA =
                                                (Number(data.retail_price) || 0) +
                                                ((Number(data.retail_price) || 0) *
                                                  (data.adjustment
                                                    ? Number(data.adjustment)
                                                    : 0)) /
                                                  100
                                            }
                                          }
                                        }
                                        console.log(
                                          'SPMA-----data.price-----',
                                          data.price,
                                        )
                                        console.log(
                                          'SPMA-----data.adjustment-----',
                                          data.adjustment,
                                        )
                                        console.log(
                                          'SPMA-----data.retail_price-----',
                                          data.retail_price,
                                        )
                                        console.log('SPMA-----SPMA-----', SPMA)
                                        SPMA = Number(SPMA)
                                        data['SPMA'] = SPMA?.toFixed(2)

                                        return (
                                          <>
                                            <TableRow key={index}>
                                              <TableCell>
                                                #{data?.indexId}
                                              </TableCell>
                                              <TableCell>
                                              {data?.isAdditionalProduct ?
                                                <TextField
                                                  required
                                                  fullWidth
                                                  type="text"
                                                  label={'Product Name'}
                                                  sx={{ width: 85 }}
                                                  value={data.name}
                                                  onChange={(event) => {
                                                    let newValue =
                                                      event.target.value

                                                    // Update the selectedProducts state
                                                    setSelectedProducts(
                                                      (prevProducts) =>
                                                        prevProducts.map(
                                                          (product) => {
                                                            if (
                                                              product.indexId ===
                                                              data.indexId
                                                            ) {
                                                              return {
                                                                ...product,
                                                                name: newValue,
                                                              }
                                                            } else {
                                                              return product
                                                            }
                                                          },
                                                        ),
                                                    )
                                                    data['name'] =
                                                      newValue || ''
                                                    if (isSubmitted)
                                                      setIsSubmitted(false)
                                                  }}
                                                  error={
                                                    isSubmitted &&
                                                    data?.name?.trim() === ''
                                                  }
                                                  helperText={
                                                    isSubmitted &&
                                                    data?.name?.trim() === ''
                                                      ? 'Product Name is required'
                                                      : ''
                                                  }
                                                />
                                              : data.name}
                                              </TableCell>
                                              <TableCell>
                                                <IconButton
                                                  onClick={() =>
                                                    handleOpenNote(
                                                      data.indexId,
                                                      data.product_notes,
                                                    )
                                                  }
                                                >
                                                  <NoteAdd
                                                    sx={{
                                                      color:
                                                        !data?.product_notes ||
                                                        data?.product_notes ===
                                                          ''
                                                          ? ''
                                                          : theme.palette
                                                              .primary.main,
                                                    }}
                                                  />
                                                </IconButton>
                                              </TableCell>
                                              <TableCell>
                                                {data?.isAdditionalProduct ? 'N/A' :(
                                                  parseFloat(
                                                    data.expected_cost?.toFixed(
                                                      2,
                                                    ),
                                                  ) *
                                                  (data.quantity || 1) *
                                                  (data.locationQuantity || 1)
                                                ).toFixed(2)}
                                              </TableCell>
                                              {/* <TableCell>{invoiceAmount}</TableCell> */}
                                              {/* <TableCell>{productFormulaCost?.toFixed(2)}</TableCell> */}
                                              <TableCell>
                                                {((hasMarkupPermission && markupType == 2)
                                                    ? 
                                                    (data?.isAdditionalProduct ?
                                                      <TextField
                                                        required
                                                        fullWidth
                                                        type="number"
                                                        label={'Retail Price Each'}
                                                        disabled={
                                                          values.project_categories
                                                            ?.name ==
                                                          'Time & Material'
                                                            ? true
                                                            : false
                                                        }
                                                        sx={{ width: 85 }}
                                                        value={data.retail_price}
                                                        // onWheel={(e) => e.target.blur()}
                                                        onKeyPress={(event) => {
                                                          // if (event.key === '0' && event.target.value === '') {
                                                          //   event.preventDefault();
                                                          // }
                                                          if (event.key === '-') {
                                                            event.preventDefault();
                                                          }
                                                          if (event.key === 'e') {
                                                            event.preventDefault();
                                                          }
                                                        }}
                                                        inputProps={{ min: 0 }}
                                                        defaultValue={0}
                                                        onChange={(event) => {
                                                          let newValue =
                                                            event.target.value
                                                          if (newValue !== '') {
                                                            setSelectedProducts(
                                                              (pv) => [
                                                                ...pv.map((p) => {
                                                                  if (
                                                                    p.indexId ===
                                                                    data.indexId
                                                                  ) {
                                                                    return {
                                                                      ...p,
                                                                      retail_price:
                                                                        newValue,
                                                                      price: newValue,
                                                                    }
                                                                  } else {
                                                                    return p
                                                                  }
                                                                }),
                                                              ],
                                                            )
                                                            data['retail_price'] =
                                                              newValue || 0
                                                            data['price'] =
                                                              newValue || 0
                                                          }
                                                        }}
                                                        // error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                                        // helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                                      /> :
                                                      data.retail_price?.toFixed(2))
                                                    : null)}
                                              </TableCell>
                                              <TableCell>
                                                {hasMarkupPermission &&
                                                markupType == 1
                                                  ? 
                                                  (data?.isAdditionalProduct ?
                                                    <TextField
                                                      required
                                                      fullWidth
                                                      type="number"
                                                      label={'Retail Price Each'}
                                                      disabled={
                                                        values.project_categories
                                                          ?.name ==
                                                        'Time & Material'
                                                          ? true
                                                          : false
                                                      }
                                                      // onWheel={(e) => e.target.blur()}
                                                      onKeyPress={(event) => {
                                                        // if (event.key === '0' && event.target.value === '') {
                                                        //   event.preventDefault();
                                                        // }
                                                        if (event.key === '-') {
                                                          event.preventDefault();
                                                        }
                                                        if (event.key === 'e') {
                                                          event.preventDefault();
                                                        }
                                                      }}
                                                      inputProps={{ min: 0 }}
                                                      sx={{ width: 85 }}
                                                      value={data.retail_price}
                                                      defaultValue={0}
                                                      onChange={(event) => {
                                                        let newValue =
                                                          event.target.value
                                                        if (newValue !== '') {
                                                          setSelectedProducts(
                                                            (pv) => [
                                                              ...pv.map((p) => {
                                                                if (
                                                                  p.indexId ===
                                                                  data.indexId
                                                                ) {
                                                                  return {
                                                                    ...p,
                                                                    retail_price:
                                                                      newValue,
                                                                    price: newValue,
                                                                  }
                                                                } else {
                                                                  return p
                                                                }
                                                              }),
                                                            ],
                                                          )
                                                          data['retail_price'] =
                                                            newValue || 0
                                                          data['price'] =
                                                            newValue || 0
                                                        }
                                                      }}
                                                      // error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                                      // helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                                    /> :
                                                  data.price?.toFixed(2))
                                                  : null}
                                              </TableCell>
                                              <TableCell>
                                                {hasMarkupPermission
                                                  ? data?.SPMA || null
                                                  : null}
                                              </TableCell>
                                              {/* <TableCell> */}
                                              {/* old */}
                                              {/* {(hasMarkupPermission && markupType == 1) ? data.wholesaleMarkup?.toFixed(2)
                                    : (hasMarkupPermission && markupType == 2) ? data.retailMarkup?.toFixed(2)
                                      : null} */}
                                              {/* previos */}
                                              {/* {(hasMarkupPermission && markupType == 1) ? data.wholesaleMarkup?.toFixed(2)
                                    : (hasMarkupPermission && markupType == 2) ? data.pf_retail_markup?.toFixed(2)
                                      : null} */}
                                              {/* {(hasMarkupPermission && markupType) ? (markup * data.quantity).toFixed(2) : null} */}
                                              {/* </TableCell> */}

                                              {/* <TableCell>{shortUnitName}</TableCell> */}
                                              <TableCell>
                                                <TextField
                                                  required
                                                  fullWidth
                                                  type="number"
                                                  label={shortUnitName}
                                                  disabled={
                                                    data?.fromMapServices ==
                                                    true
                                                      ? true
                                                      : shortUnitName == 'Unit'
                                                      ? true
                                                      : false
                                                  }
                                                  sx={{ width: 85 }}
                                                  // {...getFieldProps(quantityFieldName)}
                                                  value={Number(data.quantity)}
                                                  defaultValue={1}
                                                  onKeyPress={(event) => {
                                                    if (
                                                      event.key === '0' &&
                                                      event.target.value === ''
                                                    ) {
                                                      event.preventDefault()
                                                    }
                                                    if (event.key === '-') {
                                                      event.preventDefault()
                                                    }
                                                    if (event.key === '.') {
                                                      event.preventDefault()
                                                    }
                                                  }}
                                                  onChange={(event) => {
                                                    let newValue =
                                                      event.target.value
                                                    if (
                                                      newValue !== '0' &&
                                                      newValue !== ''
                                                    ) {
                                                      console.log(
                                                        '################SelectedSeer',
                                                        selectedServices,
                                                      )

                                                      setSelectedProducts(
                                                        (pv) => [
                                                          ...pv.map((p) => {
                                                            if (
                                                              p.indexId ===
                                                              data.indexId
                                                            ) {
                                                              // if (p.id === data.id) {
                                                              // let arrayAddr

                                                              // const commonAddresses = (allLocationSame && pv.length > 0) ? [...pv[0].addresses][0] : null;

                                                              // if (allLocationSame && commonAddresses) {
                                                              //   arrayAddr = Array(Number(newValue)).fill().map((_, i) => ({
                                                              //     ...commonAddresses,
                                                              //     id: i
                                                              //   }));

                                                              // }
                                                              // else {
                                                              //   arrayAddr = [...p.addresses]
                                                              //   if (p.addresses.length < Number(newValue)) {
                                                              //     arrayAddr.push(...[...Array(Number(newValue) - p.addresses.length)].map((a, i) => {

                                                              //       return {
                                                              //         id: p.addresses.length + i,
                                                              //         address: '',
                                                              //         city: '',
                                                              //         state: '',
                                                              //         zip: '',
                                                              //       }
                                                              //     }))
                                                              //   } else {
                                                              //     arrayAddr = arrayAddr.slice(0, -(p.addresses.length - Number(newValue)));
                                                              //   }
                                                              // }

                                                              return {
                                                                ...p,
                                                                quantity:
                                                                  Number(
                                                                    newValue,
                                                                  ),
                                                                // addresses: arrayAddr
                                                              }
                                                            } else {
                                                              return p
                                                            }
                                                          }),
                                                        ],
                                                      )
                                                      setSelectedServices(
                                                        (pv) => [
                                                          ...pv.map(
                                                            (service) => {
                                                              // if (service.product_id === data.id) {
                                                              if (
                                                                service.productIndex ===
                                                                data.indexId
                                                              ) {
                                                                return {
                                                                  ...service,
                                                                  productIndex:
                                                                    data.indexId,
                                                                  quantity: (
                                                                    service.previousQty *
                                                                    Number(
                                                                      newValue,
                                                                    ) *
                                                                    service.locationQuantity
                                                                  )?.toFixed(2),
                                                                  qtyData:
                                                                    Number(
                                                                      newValue,
                                                                    ),
                                                                  total: (
                                                                    service.cost *
                                                                    (service.previousQty *
                                                                      Number(
                                                                        newValue,
                                                                      ) *
                                                                      service.locationQuantity)
                                                                  )?.toFixed(2),
                                                                }
                                                              } else {
                                                                return service
                                                              }
                                                            },
                                                          ),
                                                        ],
                                                      )
                                                      //new chnage on 2-2-24
                                                      if (
                                                        newValue === '' ||
                                                        newValue <= 0
                                                      ) {
                                                        event.preventDefault()
                                                        return
                                                      }
                                                      if (
                                                        newValue.startsWith('-')
                                                      ) {
                                                        newValue =
                                                          newValue.slice(1)
                                                      }
                                                      data['quantity'] =
                                                        newValue || 1
                                                      setFieldValue(
                                                        quantityFieldName,
                                                        newValue,
                                                      )
                                                    }
                                                  }}
                                                  error={Boolean(
                                                    touched[
                                                      quantityFieldName
                                                    ] &&
                                                      errors[quantityFieldName],
                                                  )}
                                                  helperText={
                                                    touched[
                                                      quantityFieldName
                                                    ] &&
                                                    errors[quantityFieldName]
                                                  }
                                                />
                                              </TableCell>
                                              <TableCell>
                                                <TextField
                                                  required
                                                  fullWidth
                                                  type="number"
                                                  label={'Qty'}
                                                  disabled={
                                                    data?.fromMapServices ==
                                                    true
                                                      ? true
                                                      : false
                                                  }
                                                  // {...getFieldProps(quantityFieldName)}
                                                  value={Number(
                                                    data.locationQuantity,
                                                  )}
                                                  defaultValue={1}
                                                  onKeyPress={(event) => {
                                                    if (
                                                      event.key === '0' &&
                                                      event.target.value === ''
                                                    ) {
                                                      event.preventDefault()
                                                    }
                                                    if (event.key === '-') {
                                                      event.preventDefault()
                                                    }
                                                    if (event.key === '.') {
                                                      event.preventDefault()
                                                    }
                                                  }}
                                                  onChange={(event) => {
                                                    let newValue =
                                                      event.target.value
                                                    if (
                                                      newValue !== '0' &&
                                                      newValue !== ''
                                                    ) {
                                                      setSelectedProducts(
                                                        (pv) => [
                                                          ...pv.map((p) => {
                                                            console.log(
                                                              '################p.id',
                                                              p.id,
                                                            )
                                                            console.log(
                                                              '################data.id',
                                                              data.id,
                                                            )
                                                            // if (p.id === data.id) {
                                                            if (
                                                              p.indexId ===  data.indexId
                                                            ) {
                                                              let arrayAddr

                                                              const commonAddresses =
                                                              data?.allLocationSame &&
                                                                pv.length > 0
                                                                  ? p.addresses[0]
                                                                  : null

                                                              if (
                                                                data?.allLocationSame &&
                                                                commonAddresses
                                                              ) {
                                                                arrayAddr =
                                                                  Array( Number(newValue), ) //1
                                                                    .fill()
                                                                    .map(
                                                                      (
                                                                        _,
                                                                        i,
                                                                      ) => ({
                                                                        ...commonAddresses,
                                                                        id: i,
                                                                      }),
                                                                    )
                                                              } else {
                                                                arrayAddr = [
                                                                  ...p.addresses,
                                                                ]
                                                                console.log(
                                                                  '################arrayAddr',
                                                                  arrayAddr,
                                                                )
                                                                console.log(
                                                                  '################p.addresses.length',
                                                                  p.addresses
                                                                    .length,
                                                                )
                                                                console.log(
                                                                  '################Number(newValue)',
                                                                  Number(
                                                                    newValue,
                                                                  ),
                                                                )
                                                                if ( p.addresses?.length < newValue ) { //1
                                                                  arrayAddr.push(
                                                                    ...[
                                                                      ...Array(
                                                                        newValue - p.addresses.length, //1
                                                                      ),
                                                                    ].map(
                                                                      ( a, i, ) => {
                                                                        return {
                                                                          id:
                                                                            p.addresses.length + i,
                                                                          address:
                                                                            '',
                                                                          city: '',
                                                                          state:
                                                                            '',
                                                                          zip: '',
                                                                        }
                                                                      },
                                                                    ),
                                                                  )
                                                                } else if ( p.addresses?.length > Number(newValue) ) { //1
                                                                  arrayAddr = arrayAddr.slice( 0, Number(newValue), ) //1
                                                                } else {
                                                                  arrayAddr = arrayAddr.slice(0, -(p.addresses.length - Number(newValue)));
                                                                }
                                                              }

                                                              console.log( '###########Arrayadde1', p.addresses, )
                                                              console.log( '###########Arrayadde2', arrayAddr, )
                                                              return {
                                                                ...p,
                                                                // quantity: Number(newValue),
                                                                locationQuantity: Number( newValue, ),
                                                                addresses: arrayAddr,
                                                              }
                                                            } else {
                                                              return p
                                                            }
                                                          }),
                                                        ],
                                                      )
                                                      setSelectedServices(
                                                        (pv) => [
                                                          ...pv.map(
                                                            (service) => {
                                                              // if (service.product_id === data.id) {
                                                              console.log(
                                                                'locationQty---service----',
                                                                service,
                                                              )
                                                              console.log(
                                                                'locationQty---data----',
                                                                data,
                                                              )
                                                              if ( service?.productIndex === data?.indexId ) {
                                                                return {
                                                                  ...service,
                                                                  productIndex:
                                                                    data?.indexId,
                                                                  quantity: (
                                                                    service.previousQty *
                                                                    Number(
                                                                      newValue,
                                                                    ) *
                                                                    (service.qtyData ||
                                                                      1)
                                                                  )?.toFixed(2),
                                                                  locationQuantity:
                                                                    Number(
                                                                      newValue,
                                                                    ),
                                                                }
                                                              } else {
                                                                return service
                                                              }
                                                            },
                                                          ),
                                                        ],
                                                      )
                                                      //new chnage on 2-2-24
                                                      if (
                                                        newValue === '' ||
                                                        newValue <= 0
                                                      ) {
                                                        event.preventDefault()
                                                        return
                                                      }
                                                      if (
                                                        newValue.startsWith('-')
                                                      ) {
                                                        newValue =
                                                          newValue.slice(1)
                                                      }
                                                      data['locationQuantity'] =
                                                        newValue || 1
                                                      // setFieldValue(quantityFieldName, newValue);
                                                    }
                                                  }}
                                                  // error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                                  // helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                                />
                                              </TableCell>
                                              <TableCell>
                                                <IconButton
                                                  onClick={() => {
                                                    setLocationStates({
                                                      ...locationStates,
                                                      [data.indexId]: true,
                                                    })
                                                  }}
                                                >
                                                  <LocationOn
                                                    sx={{
                                                      color:
                                                        data.addresses.some(
                                                          (obj) =>
                                                            [obj.address, obj.city, obj.state, obj.zip].some(
                                                              (value) =>
                                                                (value === '' || value === null),
                                                            ),
                                                        )
                                                          ? ''
                                                          : theme.palette
                                                              .primary.main,
                                                    }}
                                                  />
                                                </IconButton>
                                              </TableCell>
                                              {/* <TableCell>{data.id}</TableCell> */}
                                              <TableCell>
                                                <Autocomplete
                                                  disabled={
                                                    values.project_categories
                                                      ?.name ==
                                                    'Time & Material'
                                                      ? true
                                                      : false
                                                  }
                                                  sx={{ width: 70 }}
                                                  id="combo-box-demo"
                                                  disableClearable
                                                  options={
                                                    marketingAdjustmentType ||
                                                    []
                                                  }
                                                  defaultValue={
                                                    marketingAdjustmentType.find(
                                                      (type) =>
                                                        type.id ===
                                                        data.markAdjType,
                                                    ) ||
                                                    marketingAdjustmentType[0]
                                                  }
                                                  onChange={(
                                                    event,
                                                    newValue,
                                                  ) => {
                                                    setSelectedProducts(
                                                      (prevProducts) => {
                                                        return prevProducts.map(
                                                          (product) => {
                                                            if (
                                                              product.indexId ===
                                                              data.indexId
                                                            ) {
                                                              return {
                                                                ...product,
                                                                markAdjType:
                                                                  newValue?.id ||
                                                                  marketingAdjustmentType[0]
                                                                    .id, // Set adjustment type or default to the first option
                                                                // adjustment: 0,
                                                              }
                                                            }
                                                            return product
                                                          },
                                                        )
                                                      },
                                                    )
                                                  }}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      required
                                                      error={Boolean(
                                                        touched.marketingAdjType &&
                                                          errors.marketingAdjType,
                                                      )}
                                                      helperText={
                                                        touched.marketingAdjType &&
                                                        errors.marketingAdjType
                                                      }
                                                      label="Select type"
                                                    />
                                                  )}
                                                  getOptionLabel={(option) =>
                                                    option.name
                                                      ? option.name
                                                      : ''
                                                  }
                                                />
                                              </TableCell>
                                              <TableCell>
                                                {/* <TextField
                                    type="number"
                                    label="Discount"
                                    defaultValue={0}
                                    value={Number(data.discount)}
                                    // {...getFieldProps(discountFieldName)}
                                    onChange={(event) => {
                                      let newValue = event.target.value;
                                      if (newValue.startsWith('-')) {
                                        newValue = newValue.slice(1);
                                      }
                                      if (markupType == 1) {
                                        if (Number(newValue) > maxDiscount_wholesale) {
                                          newValue = maxDiscount_wholesale.toFixed(2);
                                        }
                                      } else if (markupType == 2) {
                                        if (Number(newValue) > maxDiscount_retail) {
                                          newValue = maxDiscount_retail.toFixed(2);
                                        }
                                      } else {
                                        newValue = 0;
                                      }
                                      if (newValue === '-0.00') {
                                        newValue = 0;
                                      }
                                      data['discount'] = newValue
                                      setFieldValue(discountFieldName, newValue);
                                    }}
                                    error={Boolean(touched[discountFieldName] && errors[discountFieldName])}
                                    helperText={touched[discountFieldName] && errors[discountFieldName]}
                                    inputProps={{ max: markupType == 1 ? maxDiscount_wholesale : markupType == 2 ? maxDiscount_retail : 0, min: 0 }}
                                  /> */}
                                                <TextField
                                                  type="number"
                                                  label={
                                                    data.markAdjType === 2
                                                      ? '$'
                                                      : data.markAdjType === 3
                                                      ? '¢'
                                                      : '%'
                                                  }
                                                  disabled={
                                                    values.project_categories
                                                      ?.name ==
                                                    'Time & Material'
                                                      ? true
                                                      : false
                                                  }
                                                  // defaultValue={data?.adjustment ? Number(data?.adjustment) : 0}
                                                  // value={Number(data.adjustment)}
                                                  value={
                                                    Number(data.adjustment) ||
                                                    null
                                                  }
                                                  onChange={(event) => {
                                                    let newValue =
                                                      event.target.value

                                                    // Allow only two decimal places
                                                    if (
                                                      newValue.includes('.')
                                                    ) {
                                                      const parts =
                                                        newValue.split('.')
                                                      if (parts[1].length > 2) {
                                                        newValue = `${
                                                          parts[0]
                                                        }.${parts[1].slice(
                                                          0,
                                                          2,
                                                        )}`
                                                      }
                                                    }
                                                    data['adjustment'] =
                                                      newValue
                                                    setFieldValue(
                                                      adjustmentFieldName,
                                                      newValue,
                                                    )
                                                  }}
                                                  onKeyDown={(event) => {
                                                    if (
                                                      event.key === '-' ||
                                                      event.key === '+'
                                                    ) {
                                                      event.preventDefault()
                                                    }
                                                  }}
                                                  error={Boolean(
                                                    touched[
                                                      adjustmentFieldName
                                                    ] &&
                                                      errors[
                                                        adjustmentFieldName
                                                      ],
                                                  )}
                                                  helperText={
                                                    touched[
                                                      adjustmentFieldName
                                                    ] &&
                                                    errors[adjustmentFieldName]
                                                  }
                                                />
                                              </TableCell>
                                              <TableCell>
                                                {calculateTotal(
                                                  markupType == 1
                                                    ? data.price
                                                    : markupType == 2
                                                    ? data.retail_price
                                                    : 0,
                                                  Number(data.quantity),
                                                  values[discountFieldName],
                                                  data.locationQuantity,
                                                  data.adjustment,
                                                  data.markAdjType,
                                                )?.toFixed(2)}
                                              </TableCell>
                                              <TableCell>
                                                {data?.fromMapServices ==
                                                true ? (
                                                  ''
                                                ) : (
                                                  <IconButton
                                                    onClick={() =>
                                                      handleDeleteProduct(
                                                        data.id,
                                                        data.indexId,
                                                      )
                                                    }
                                                  >
                                                    <Delete />
                                                  </IconButton>
                                                )}
                                              </TableCell>
                                            </TableRow>
                                            <Modal
                                              open={
                                                locationStates[data.indexId]
                                              }
                                              handleClose={() => {
                                                setLocationStates({
                                                  ...locationStates,
                                                  [data.indexId]: false,
                                                })
                                              }}
                                            >
                                              {data?.fromMapServices != true &&
                                                // index === 0 && 
                                                (
                                                  <FormControlLabel
                                                    control={
                                                      <Switch
                                                        checked={
                                                          data?.allLocationSame
                                                        }
                                                        onChange={()=>
                                                          handleAllLocationSameChange(index)
                                                        }
                                                      />
                                                    }
                                                    label="All Locations Same"
                                                  />
                                                )}
                                              <Grid
                                                container
                                                spacing={3}
                                                sx={{
                                                  justifyContent: 'center',
                                                  width: '100%',
                                                }}
                                              >
                                                {data?.addresses?.map(
                                                  (addr, i) => (
                                                    <Grid item xs={12}>
                                                      {/* Side Heading */}
                                                      <Typography
                                                        variant="h5"
                                                        sx={{ mb: 2 }}
                                                      >
                                                        Location{' '}
                                                        {data?.fromMapServices ==
                                                        true
                                                          ? i + 1
                                                          : i + 1}
                                                      </Typography>

                                                      {/* Address Fields */}

                                                      <Stack
                                                        direction={{
                                                          xs: 'column',
                                                          sm: 'row',
                                                        }}
                                                        spacing={{
                                                          xs: 3,
                                                          sm: 2,
                                                        }}
                                                        sx={{ mb: 2 }}
                                                      >
                                                        <TextField
                                                          fullWidth
                                                          label="Address"
                                                          size="small"
                                                          value={
                                                            data.addresses[i]
                                                              .address || ''
                                                          }
                                                          disabled={
                                                            data?.fromMapServices ==
                                                              true &&
                                                            data.addresses[i]
                                                              .address
                                                              ? true
                                                              : 
                                                              i !== 0 &&
                                                              data?.allLocationSame
                                                              ? true
                                                              : false
                                                          }
                                                          // onChange={(event) => {
                                                          //   let arrayAddr = [...data.addresses,]
                                                          //   arrayAddr[i].address = event.target.value
                                                          //   setSelectedProducts(
                                                          //     (pv) => [
                                                          //       ...pv.map(
                                                          //         (p) => {
                                                          //           // if (p.id === data.id) {
                                                          //           if ( p.indexId === data.indexId ) {
                                                          //             if(p.allLocationSame == true){
                                                          //               return {
                                                          //                 ...p,
                                                          //                 addresses:
                                                          //                   arrayAddr,
                                                          //               }
                                                          //             }else{
                                                          //               return {
                                                          //                 ...p,
                                                          //                 addresses: arrayAddr,
                                                          //               }
                                                          //             }
                                                          //           } else {
                                                          //             return p
                                                          //           }
                                                          //         },
                                                          //       ),
                                                          //     ],
                                                          //   )
                                                          // }}
                                                          onChange={(event) => {
                                                            setSelectedProducts((prevProducts) =>
                                                              prevProducts.map((p) => {
                                                                if (p.indexId === data.indexId) {
                                                                  let updatedAddresses = [...p.addresses];
                                                                  if (p.allLocationSame) {
                                                                    updatedAddresses = updatedAddresses.map(() => ({
                                                                      ...updatedAddresses[0],
                                                                      address: event.target.value,
                                                                    }));
                                                                  } else {
                                                                    updatedAddresses[i] = {
                                                                      ...updatedAddresses[i],
                                                                      address: event.target.value,
                                                                    };
                                                                  }
                                                                  return {
                                                                    ...p,
                                                                    addresses: updatedAddresses,
                                                                  };
                                                                }
                                                                return p;
                                                              })
                                                            );
                                                          }}
                                                        />
                                                      </Stack>

                                                      {/* City, State, Zip Fields */}
                                                      <Stack
                                                        direction={{
                                                          xs: 'column',
                                                          sm: 'row',
                                                        }}
                                                        spacing={{
                                                          xs: 3,
                                                          sm: 2,
                                                        }}
                                                        sx={{ mb: 2 }}
                                                      >
                                                        <TextField
                                                          fullWidth
                                                          label="City"
                                                          size="small"
                                                          value={
                                                            data.addresses[i]
                                                              .city || ''
                                                          }
                                                          disabled={
                                                            data?.fromMapServices ==
                                                              true &&
                                                            data.addresses[i]
                                                              .city
                                                              ? true
                                                              : 
                                                              i !== 0 &&
                                                              data?.allLocationSame
                                                              ? true
                                                              : false
                                                          }
                                                          // onChange={(event) => {
                                                          //   let arrayAddr = [
                                                          //     ...data.addresses,
                                                          //   ]
                                                          //   arrayAddr[i].city =
                                                          //     event.target.value
                                                          //   setSelectedProducts(
                                                          //     (pv) => [
                                                          //       ...pv.map(
                                                          //         (p) => {
                                                          //           // if (p.id === data.id) {
                                                          //           if (
                                                          //             p.indexId ===
                                                          //             data.indexId
                                                          //           ) {
                                                          //             return {
                                                          //               ...p,
                                                          //               addresses:
                                                          //                 arrayAddr,
                                                          //             }
                                                          //           } else {
                                                          //             return p
                                                          //           }
                                                          //         },
                                                          //       ),
                                                          //     ],
                                                          //   )
                                                          // }}
                                                          onChange={(event) => {
                                                            setSelectedProducts((prevProducts) =>
                                                              prevProducts.map((p) => {
                                                                if (p.indexId === data.indexId) {
                                                                  let updatedAddresses = [...p.addresses];
                                                                  if (p.allLocationSame) {
                                                                    updatedAddresses = updatedAddresses.map(() => ({
                                                                      ...updatedAddresses[0],
                                                                      city: event.target.value,
                                                                    }));
                                                                  } else {
                                                                    updatedAddresses[i] = {
                                                                      ...updatedAddresses[i],
                                                                      city: event.target.value,
                                                                    };
                                                                  }
                                                                  return {
                                                                    ...p,
                                                                    addresses: updatedAddresses,
                                                                  };
                                                                }
                                                                return p;
                                                              })
                                                            );
                                                          }}
                                                        />
                                                        <TextField
                                                          fullWidth
                                                          label="State"
                                                          size="small"
                                                          value={
                                                            data.addresses[i]
                                                              .state || ''
                                                          }
                                                          disabled={
                                                            data?.fromMapServices ==
                                                              true &&
                                                            data.addresses[i]
                                                              .state
                                                              ? true
                                                              : 
                                                              i !== 0 &&
                                                              data?.allLocationSame
                                                              ? true
                                                              : false
                                                          }
                                                          // onChange={(event) => {
                                                          //   let arrayAddr = [
                                                          //     ...data.addresses,
                                                          //   ]
                                                          //   arrayAddr[i].state =
                                                          //     event.target.value
                                                          //   setSelectedProducts(
                                                          //     (pv) => [
                                                          //       ...pv.map(
                                                          //         (p) => {
                                                          //           // if (p.id === data.id) {
                                                          //           if (
                                                          //             p.indexId ===
                                                          //             data.indexId
                                                          //           ) {
                                                          //             return {
                                                          //               ...p,
                                                          //               addresses:
                                                          //                 arrayAddr,
                                                          //             }
                                                          //           } else {
                                                          //             return p
                                                          //           }
                                                          //         },
                                                          //       ),
                                                          //     ],
                                                          //   )
                                                          // }}
                                                          onChange={(event) => {
                                                            setSelectedProducts((prevProducts) =>
                                                              prevProducts.map((p) => {
                                                                if (p.indexId === data.indexId) {
                                                                  let updatedAddresses = [...p.addresses];
                                                                  if (p.allLocationSame) {
                                                                    updatedAddresses = updatedAddresses.map(() => ({
                                                                      ...updatedAddresses[0],
                                                                      state: event.target.value,
                                                                    }));
                                                                  } else {
                                                                    updatedAddresses[i] = {
                                                                      ...updatedAddresses[i],
                                                                      state: event.target.value,
                                                                    };
                                                                  }
                                                                  return {
                                                                    ...p,
                                                                    addresses: updatedAddresses,
                                                                  };
                                                                }
                                                                return p;
                                                              })
                                                            );
                                                          }}
                                                        />
                                                        <TextField
                                                          fullWidth
                                                          label="Zip / Postal Code"
                                                          size="small"
                                                          value={
                                                            data.addresses[i]
                                                              .zip || ''
                                                          }
                                                          disabled={
                                                            data?.fromMapServices ==
                                                              true &&
                                                            data.addresses[i]
                                                              .zip
                                                              ? true
                                                              : 
                                                              i !== 0 &&
                                                              data?.allLocationSame
                                                              ? true
                                                              : false
                                                          }
                                                          // onChange={(event) => {
                                                          //   let arrayAddr = [
                                                          //     ...data.addresses,
                                                          //   ]
                                                          //   arrayAddr[i].zip =
                                                          //     event.target.value
                                                          //   setSelectedProducts(
                                                          //     (pv) => [
                                                          //       ...pv.map(
                                                          //         (p) => {
                                                          //           if (
                                                          //             p.indexId ===
                                                          //             data.indexId
                                                          //           ) {
                                                          //             // if (p.id === data.id) {
                                                          //             return {
                                                          //               ...p,
                                                          //               addresses:
                                                          //                 arrayAddr,
                                                          //             }
                                                          //           } else {
                                                          //             return p
                                                          //           }
                                                          //         },
                                                          //       ),
                                                          //     ],
                                                          //   )
                                                          // }}
                                                          onChange={(event) => {
                                                            setSelectedProducts((prevProducts) =>
                                                              prevProducts.map((p) => {
                                                                if (p.indexId === data.indexId) {
                                                                  let updatedAddresses = [...p.addresses];
                                                                  if (p.allLocationSame) {
                                                                    updatedAddresses = updatedAddresses.map(() => ({
                                                                      ...updatedAddresses[0],
                                                                      zip: event.target.value,
                                                                    }));
                                                                  } else {
                                                                    updatedAddresses[i] = {
                                                                      ...updatedAddresses[i],
                                                                      zip: event.target.value,
                                                                    };
                                                                  }
                                                                  return {
                                                                    ...p,
                                                                    addresses: updatedAddresses,
                                                                  };
                                                                }
                                                                return p;
                                                              })
                                                            );
                                                          }}
                                                        />
                                                      </Stack>
                                                    </Grid>
                                                  ),
                                                )}
                                              </Grid>

                                              <Grid
                                                container
                                                justifyContent="center"
                                                alignItems="center"
                                              >
                                                <Grid item xs={12} md={4}>
                                                  {data?.fromMapServices ==
                                                  true ? (
                                                    ''
                                                  ) : 
                                                  // index !== 0 && data?.allLocationSame
                                                  false ? (
                                                    ''
                                                  ) : (
                                                    <Box
                                                      sx={{
                                                        mt: 3,
                                                        display: 'flex',
                                                        justifyContent:
                                                          'center',
                                                        gap: 1,
                                                      }}
                                                    >
                                                      <LoadingButton
                                                        variant="contained"
                                                        onClick={() => {
                                                          setIsSaving(true)
                                                          setTimeout(() => {
                                                            setIsSaving(false)
                                                            setLocationStates(
                                                              false,
                                                            )
                                                          }, 100)
                                                        }}
                                                        loading={isSaving}
                                                      >
                                                        {' Save '}
                                                      </LoadingButton>
                                                      {/* <Button variant="outlined" type="submit" onClick={() => { setLocationStates(false) }}>
                                      Cancel
                                    </Button> */}
                                                    </Box>
                                                  )}
                                                </Grid>
                                              </Grid>
                                            </Modal>
                                            <Modal
                                              open={
                                                openProductNote[data.indexId] ||
                                                false
                                              }
                                              handleClose={() =>
                                                handleCloseNote(data.indexId)
                                              }
                                            >
                                              <Grid
                                                container
                                                spacing={3}
                                                sx={{
                                                  justifyContent: 'center',
                                                  width: '100%',
                                                }}
                                              >
                                                <Grid item xs={12}>
                                                  <Typography
                                                    variant="h5"
                                                    sx={{ mb: 2 }}
                                                  >
                                                    Note
                                                  </Typography>

                                                  <Stack
                                                    direction={{
                                                      xs: 'column',
                                                      sm: 'row',
                                                    }}
                                                    spacing={{ xs: 3, sm: 2 }}
                                                    sx={{ mb: 2 }}
                                                  >
                                                    <TextField
                                                      fullWidth
                                                      multiline
                                                      rows={4}
                                                      size="medium"
                                                      value={currentNote}
                                                      onChange={
                                                        handleNoteChange
                                                      }
                                                      inputProps={{
                                                        maxLength:
                                                          MAX_CHARACTERS,
                                                      }}
                                                      helperText={`${currentNote.length}/${MAX_CHARACTERS} characters`}
                                                    />
                                                  </Stack>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                justifyContent="center"
                                                alignItems="center"
                                              >
                                                <Grid item xs={12} md={4}>
                                                  <Box
                                                    sx={{
                                                      mt: 3,
                                                      display: 'flex',
                                                      justifyContent: 'center',
                                                      gap: 1,
                                                    }}
                                                  >
                                                    <LoadingButton
                                                      variant="contained"
                                                      onClick={() =>
                                                        handleSaveNote(
                                                          data.indexId,
                                                        )
                                                      }
                                                      loading={isSavingNote}
                                                    >
                                                      Save
                                                    </LoadingButton>
                                                  </Box>
                                                </Grid>
                                              </Grid>
                                            </Modal>
                                          </>
                                        )
                                      })}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Scrollbar>
                              {
                            showAddNewRow ?
                              <Stack sx={{ justifyContent: 'space-between', alignItems: 'center', display:'flex', flexDirection:'row' }}>
                                <Button
                                  variant="outlined"
                                  startIcon={
                                    <Stack>
                                      {' '}
                                      <Add />{' '}
                                    </Stack>
                                  }
                                  onClick={() => {
                                    addNewProductRow()
                                    // setMarkupType(1)
                                  }}
                                >
                                  Add Product Row
                                </Button>
                                <Typography variant="h6">
                                  Total Additional Product Cost:{' '}
                                  {selectedProductsAdditionalCost}
                                </Typography>
                              </Stack>
                              :""}
                            </>
                          )}
                        </>
                      )}
                      <Divider />

                      <Stack direction="row">
                        <LabelStyleHead>Services</LabelStyleHead>
                        {/* {showServices ? <Remove sx={{color:theme.palette.primary.main}} onClick={(e)=>{setShowServices(false)}} /> : <Add sx={{color:theme.palette.primary.main}} onClick={(e)=>{setShowServices(true)}} />} */}
                      </Stack>
                      {/* {showServices && <> */}
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 3, sm: 2 }}
                      >
                        <Autocomplete
                          sx={{ width: 400 }}
                          multiple
                          id="combo-box-demo"
                          options={
                            filteredServices?.filter(
                              (item) => item.status == 1,
                            ) || []
                          }
                          value={values.services?.filter(item=>!item.isAdditionalProduct) || null}
                          // onChange={(event, newValue) => {
                          //   // setFieldValue('services', newValue);
                          //   // setAdditionalSelectedServices(newValue);
                          //   // handleServices(newValue)
                          //   setAdditionalSelectedServices(prevProducts => {
                          //     const updatedProducts = newValue?.map((product, index) => {
                          //       const existingProduct = prevProducts.find(p => (p?.id === product?.id && p?.indexId === product?.indexId));
                          //       console.log('existingProduct-----------------',existingProduct)
                          //       console.log('prevProducts-----------------',prevProducts)
                          //       console.log('product-----------------',product)
                          //       if (existingProduct) {
                          //         let addresses = [...existingProduct.addresses];
                          //         for (let i = addresses.length; i < 1; i++) {
                          //           addresses.push({
                          //             id: i,
                          //             address: '',
                          //             city: '',
                          //             state: '',
                          //             zip: '',
                          //           });
                          //         }
                          //         return {
                          //           ...existingProduct,
                          //           addresses
                          //         };
                          //       } else {
                          //         const newAddresses = [{
                          //           id: 0,
                          //           address: '',
                          //           city: '',
                          //           state: '',
                          //           zip: '',
                          //         }];

                          //         // if (prevProducts.length > 0) {
                          //         //   const commonAddress = { ...prevProducts[0].addresses[0] } || {
                          //         //     address: '',
                          //         //     city: '',
                          //         //     state: '',
                          //         //     zip: '',
                          //         //   };
                          //         //   newAddresses[0] = commonAddress;
                          //         // }
                          //         const newIndexCounter = serviceIndexCounter + 1
                          //         setServiceIndexCounter(newIndexCounter);
                          //         return {
                          //           ...product,
                          //           indexId: newIndexCounter,
                          //           addresses: newAddresses
                          //         };
                          //       }
                          //     });
                          //     setFieldValue('services', updatedProducts);
                          //     return updatedProducts;
                          //   });
                          // }}

                          onChange={(event, newValue) => {
                            setAdditionalSelectedServices((prevServices) => {
                              // 1. Extract community address from Formik's values
                              const {
                                address: projAddress = '',
                                city: projCity = '',
                                state: projState = '',
                                zip: projZip = '',
                              } = values?.community || {}

                              const communityAddress = {
                                address: projAddress,
                                city: projCity,
                                state: projState,
                                zip: projZip,
                              }
                              const additionals = prevServices?.filter(item => item.isAdditionalProduct === true)
                              // 2. Map over the newly selected services
                              const updatedServices =
                                newValue?.map((service) => {
                                  // Check if the service already exists in the previous selected services
                                  const existingService = prevServices.find(
                                    (p) =>
                                      p?.id === service?.id &&
                                      p?.indexId === service?.indexId,
                                  )

                                  if (existingService) {
                                    // -----------------------
                                    // If service already exists
                                    // -----------------------
                                    let addresses = [
                                      ...existingService.addresses,
                                    ]

                                    // Ensure at least one address exists
                                    if (addresses.length < 1) {
                                      addresses.push({
                                        id: 0,
                                        address: '',
                                        city: '',
                                        state: '',
                                        zip: '',
                                      })
                                    }

                                    return {
                                      ...existingService,
                                      addresses,
                                    }
                                  } else {
                                    // -----------------------
                                    // BRAND NEW service
                                    // -----------------------

                                    // Determine if community address is available
                                    const hasCommunityAddress =
                                      projAddress &&
                                      projCity &&
                                      projState &&
                                      projZip

                                    let assignedService

                                    if (hasCommunityAddress) {
                                      // Assign community address using the utility function
                                      assignedService = assignAddresses(
                                        prevServices,
                                        service,
                                        allServiceLocationSame,
                                        communityAddress,
                                      )
                                    } else {
                                      // Assign empty address
                                      assignedService = {
                                        ...service,
                                        addresses: [
                                          {
                                            id: 0,
                                            address: '',
                                            city: '',
                                            state: '',
                                            zip: '',
                                          },
                                        ],
                                      }

                                      // If allServiceLocationSame is enabled, use the common address from existing services
                                      if (
                                        allServiceLocationSame &&
                                        prevServices.length > 0
                                      ) {
                                        const commonAddress = {
                                          ...prevServices[0].addresses[0],
                                        }
                                        assignedService.addresses[0] = commonAddress
                                      }
                                    }

                                    // Assign a unique indexId
                                    const newIndexCounter =
                                      serviceIndexCounter + 1
                                    setServiceIndexCounter(newIndexCounter)

                                    return {
                                      ...assignedService,
                                      indexId: newIndexCounter,
                                    }
                                  }
                                }) || []
                              // 3. Update Formik's 'services' field
                              const finalServices = [...updatedServices, ...additionals];
                              setFieldValue('services', finalServices)
                              return finalServices
                            })
  setShowAddNewRow(true)

                          }}
                          //previous one
                          // onChange={(event, newValue) => {
                          //   setAdditionalSelectedServices(prevProducts => {
                          //     const updatedProducts = newValue?.map((product, index) => {
                          //       const existingProduct = prevProducts.find(p => p?.id === product?.id && p?.indexId === product?.indexId);

                          //       if (existingProduct) {
                          //         let addresses = [...existingProduct.addresses];
                          //         for (let i = addresses.length; i < 1; i++) {
                          //           addresses.push({
                          //             id: i,
                          //             address: '',
                          //             city: '',
                          //             state: '',
                          //             zip: '',
                          //           });
                          //         }
                          //         return {
                          //           ...existingProduct,
                          //           addresses,
                          //         };
                          //       } else {
                          //         const newAddresses = [{
                          //           id: 0,
                          //           address: '',
                          //           city: '',
                          //           state: '',
                          //           zip: '',
                          //         }];

                          //         // If allServiceLocationSame is enabled, use the common address
                          //         if (allServiceLocationSame && prevProducts.length > 0) {
                          //           const commonAddress = { ...prevProducts[0].addresses[0] };
                          //           newAddresses[0] = commonAddress;
                          //         }

                          //         const newIndexCounter = serviceIndexCounter + 1;
                          //         setServiceIndexCounter(newIndexCounter);
                          //         return {
                          //           ...product,
                          //           indexId: newIndexCounter,
                          //           addresses: newAddresses,
                          //         };
                          //       }
                          //     });
                          //     setFieldValue('services', updatedProducts);
                          //     return updatedProducts;
                          //   });
                          // }}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              error={Boolean(
                                touched.services && errors.services,
                              )}
                              helperText={touched.services && errors.services}
                              label={
                                values.project_categories?.name ==
                                  'Time & Material' ||
                                values.project_categories?.name ==
                                  'Service only'
                                  ? 'Select services'
                                  : 'Select additional services'
                              }
                            />
                          )}
                          getOptionLabel={(option) =>
                            option.name ? option.name : ''
                          }
                        />
                      </Stack>
                      <LabelStyle>Selected services</LabelStyle>
                      <Scrollbar>
                        <TableContainer sx={{ minWidth: 500 }}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                {VALUE_SERVICES?.map((val) =>
                                  // ((values.project_categories?.name == 'Time & Material') && val.id === 6) ? <TableCell></TableCell> :
                                  (values.project_categories?.name ==
                                    'Service only' ||
                                    values.project_categories?.name ==
                                      'Time & Material') &&
                                  val.id ===
                                    0 ? //  || ((values.project_categories?.name !== 'Service only') && val.id === 6)
                                  null : (
                                    <TableCell>{val.value}</TableCell>
                                  ),
                                )}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {selectedServices?.map((data, index) => {
                                console.log('-------------------------', data)
                                const quantityFieldName = `service_quantity_${index}`
                                if (
                                  values.project_categories?.name ==
                                  'Time & Material'
                                ) {
                                  data['total_cost'] = 0
                                  data['unit_cost'] = 0
                                  data['cost'] = 0
                                  data['quantity'] = 0
                                }
                                const total = data.total_cost * data.quantity
                                let markup = 0
                                if (total > 0) {
                                  markup = data.total_cost / total
                                }
                                let originalMarkup = data.originalMarkup || 0
                                data['markup'] = markup?.toFixed(2)
                                totalServiceMarkup += markup
                                originaltotalServiceMarkup += originalMarkup
                                console.log('#############Total', total)
                                data['total'] = total?.toFixed(2)
                                data['totalServiceMarkup'] =
                                  totalServiceMarkup?.toFixed(2)
                                values['totalServiceMarkup'] =
                                  totalServiceMarkup?.toFixed(2)
                                const previousQty =
                                  data.previousQty *
                                    (data.qtyData || 1) *
                                    data.locationQuantity || 0
                                const newQty = (
                                  data.previousQty *
                                  (data.qtyData || 1) *
                                  data.locationQuantity
                                )?.toFixed(2)
                                let quantityDifference = 0
                                if (
                                  values.project_categories?.name ==
                                  'Time & Material'
                                ) {
                                  quantityDifference = 0
                                } else {
                                  quantityDifference =
                                    Number(data.quantity) - newQty
                                }
                                data['quantityDifference'] = quantityDifference
                                data['jobcost_unit_cost'] =
                                  data.cost?.toFixed(2)
                                data['jobcost_quantity'] = quantityDifference
                                data['jobcost_total_cost'] = (
                                  data.cost * Number(data.quantity)?.toFixed(2)
                                )?.toFixed(2)

                                let newTotalCost = (
                                  data.unit_cost * quantityDifference
                                )?.toFixed(2)
                                let newTotalProductFormula = (
                                  data.cost * quantityDifference
                                )?.toFixed(2)
                                totalProductFormula += Number(
                                  newTotalProductFormula,
                                )
                                totalWholesalePrice += Number(newTotalCost)
                                totalRetailPrice += Number(newTotalCost)
                                values['totalProductFormula'] =
                                  totalProductFormula
                                values['totalWholesalePrice'] =
                                  totalWholesalePrice
                                values['totalRetailPrice'] = totalRetailPrice

                                return (
                                  <>
                                    <TableRow key={index}>
                                      <TableCell>
                                        #{data.productIndex}
                                      </TableCell>
                                      <TableCell>{data.name}</TableCell>
                                      <TableCell>
                                        {originalMarkup?.toFixed(2)}
                                      </TableCell>
                                      <TableCell>
                                        {data.cost?.toFixed(2)}
                                      </TableCell>
                                      <TableCell>
                                        {data.total_cost?.toFixed(2)}
                                      </TableCell>
                                      <TableCell>
                                        <TextField
                                          type="number"
                                          // sx={{ width: 400 }}
                                          label={'Hours'}
                                          {...getFieldProps(quantityFieldName)}
                                          disabled={
                                            data?.fromMapServices
                                              ? true
                                              : values.project_categories
                                                  ?.name == 'Time & Material'
                                              ? true
                                              : false
                                          }
                                          value={
                                            values.project_categories?.name ==
                                            'Time & Material'
                                              ? 0
                                              : Number(data.quantity)?.toFixed(
                                                  2,
                                                ) || 1
                                          }
                                          defaultValue={
                                            values.project_categories?.name ==
                                            'Time & Material'
                                              ? 0
                                              : 1
                                          }
                                          // value={Number(data.quantity)?.toFixed(2)}
                                          // defaultValue={1}
                                          onChange={(event) => {
                                            let newValue = event.target.value
                                            // if (newValue <= 0) {
                                            //   event.preventDefault();
                                            //   return;
                                            // }
                                            if (newValue.startsWith('-')) {
                                              newValue = newValue.slice(1)
                                            }
                                            if (newValue < previousQty) {
                                              setShowWarning((prev) => {
                                                const newWarnings = [...prev]
                                                newWarnings[index] = true
                                                return newWarnings
                                              })
                                              data['quantity'] = previousQty
                                            } else {
                                              setShowWarning((prev) => {
                                                const newWarnings = [...prev]
                                                newWarnings[index] = false
                                                return newWarnings
                                              })
                                              data['quantity'] = newValue || 1
                                            }
                                            setFieldValue(
                                              quantityFieldName,
                                              newValue,
                                            )
                                          }}
                                          inputProps={{ min: previousQty }}
                                          error={Boolean(
                                            touched[quantityFieldName] &&
                                              errors[quantityFieldName],
                                          )}
                                          helperText={
                                            touched[quantityFieldName] &&
                                            errors[quantityFieldName]
                                          }
                                        />
                                        {showWarning[index] && (
                                          <Typography
                                            color="error"
                                            variant="body2"
                                          >
                                            Cannot reduce the added hours in the
                                            product formula. If you need to
                                            reduce, change it in the product
                                            formula.
                                          </Typography>
                                        )}
                                      </TableCell>
                                      {/* {values.project_categories?.name == 'Time & Material' ?  */}
                                      <TableCell></TableCell>
                                      {/* : null } */}
                                      <TableCell>
                                        {(
                                          data.cost *
                                          quantityDifference?.toFixed(2)
                                        )?.toFixed(2)}
                                      </TableCell>
                                      <TableCell>
                                        {(
                                          data.unit_cost *
                                          quantityDifference?.toFixed(2)
                                        )?.toFixed(2)}
                                      </TableCell>
                                      <TableCell>{total?.toFixed(2)}</TableCell>
                                      {/* <TableCell align='right'>
                          <IconButton onClick={() => handleDeleteService(data.id)}>
                            <Delete />
                          </IconButton>
                        </TableCell> */}
                                    </TableRow>
                                  </>
                                )
                              })}
                              {additionalSelectedServices?.map(
                                (data, index) => {
                                  console.log(
                                    '############additional services',
                                    data,
                                  )
                                  if (
                                    values.project_categories?.name ==
                                    'Time & Material'
                                  ) {
                                    data['total_cost'] = 0
                                    data['cost'] = 0
                                    data['quantity'] = 0
                                  }
                                  const quantityFieldName = `service_quantity_${index}`
                                  const dataQuantity = data?.quantity
                                    ? data?.quantity
                                    : 1
                                  data['quantity'] = dataQuantity
                                  const total = data.total_cost * data?.quantity
                                  let markup = 0
                                  if (total > 0) {
                                    markup = data.total_cost / Number(total)
                                    data['markup'] = markup?.toFixed(2)
                                  } else {
                                    markup = 0
                                    data['markup'] = 0
                                  }
                                  totalServiceMarkup += markup

                                  let originalMarkup = data.originalMarkup || 0
                                  originaltotalServiceMarkup += originalMarkup
                                  data['total'] = total?.toFixed(2)
                                  data['totalServiceMarkup'] =
                                    totalServiceMarkup?.toFixed(2)
                                  values['totalServiceMarkup'] =
                                    totalServiceMarkup?.toFixed(2)
                                  const quantityDifference = Number(
                                    data.quantity,
                                  )
                                  data['quantityDifference'] =
                                    quantityDifference
                                  data['jobcost_unit_cost'] = data.cost
                                  data['jobcost_quantity'] = quantityDifference
                                  data['jobcost_total_cost'] = (
                                    data.cost * Number(data.quantity) || 1
                                  )?.toFixed(2)

                                  let newTotalCost = total?.toFixed(2)
                                  let newTotalProductFormula = (
                                    data.cost * quantityDifference
                                  )?.toFixed(2)
                                  totalProductFormula += Number(
                                    newTotalProductFormula,
                                  )
                                  totalWholesalePrice += Number(newTotalCost)
                                  totalRetailPrice += Number(newTotalCost)
                                  values['totalProductFormula'] =
                                    totalProductFormula
                                  values['totalWholesalePrice'] =
                                    totalWholesalePrice
                                  values['totalRetailPrice'] = totalRetailPrice

                                  return (
                                    <>
                                      <TableRow key={index}>
                                        {values.project_categories?.name !=
                                          'Service only' &&
                                        values.project_categories?.name !=
                                          'Time & Material' ? (
                                          <TableCell></TableCell>
                                        ) : null}
                                        <TableCell>
                                          {data?.isAdditionalProduct ?
                                            <TextField
                                              required
                                              fullWidth
                                              type="text"
                                              label={'Service Name'}
                                              sx={{ width: 85 }}
                                              value={data.name}
                                              onChange={(event) => {
                                                let newValue = event.target.value

                                                // Update the selectedProducts state
                                                setAdditionalSelectedServices(
                                                  (prevProducts) =>
                                                    prevProducts.map((service) => {
                                                        if ( service.newIndexId === data.newIndexId ) {
                                                          return {
                                                            ...service,
                                                            name: newValue,
                                                          }
                                                        } else {
                                                          return service
                                                        }
                                                      },
                                                    ),
                                                )
                                                data['name'] = newValue || ''
                                                if (isSubmitted)
                                                  setIsSubmitted(false)
                                              }}
                                              error={
                                                isSubmitted && data?.name?.trim() === ''
                                              }
                                              helperText={
                                                isSubmitted && data?.name?.trim() === '' ? 'Service Name is required' : ''
                                              }
                                            />
                                          : data.name}
                                        </TableCell>
                                        <TableCell>
                                        {
                                        // data?.isAdditionalProduct ?
                                        //   <TextField
                                        //     required
                                        //     fullWidth
                                        //     type="number"
                                        //     label={'Markup'}
                                        //     disabled={values.project_categories?.name == 'Time & Material' ? true : false}
                                        //     sx={{ width: 85 }}
                                        //     value={data.originalMarkup}
                                        //     defaultValue={0}
                                        //     onChange={(event) => {
                                        //       let newValue = event.target.value
                                        //       if (newValue !== '') {
                                        //         setAdditionalSelectedServices((pv) => [
                                        //             ...pv.map((p) => {
                                        //               if ( p.newIndexId === data.newIndexId ) {
                                        //                 return {
                                        //                   ...p,
                                        //                   originalMarkup: newValue,
                                        //                 }
                                        //               } else {
                                        //                 return p
                                        //               }
                                        //             }),
                                        //           ],
                                        //         )
                                        //         data['originalMarkup'] =  newValue || 0
                                        //         originalMarkup = newValue || 0
                                        //         data['total_cost'] = newValue * data.cost
                                        //       }
                                        //     }}
                                        //     // error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                        //     // helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                        //   />
                                        // : 
                                        originalMarkup?.toFixed(2)}
                                        </TableCell>
                                        <TableCell>
                                        {
                                        // data?.isAdditionalProduct ?
                                        //   <TextField
                                        //     required
                                        //     fullWidth
                                        //     type="number"
                                        //     label={'Cost'}
                                        //     disabled={values.project_categories?.name == 'Time & Material' ? true : false}
                                        //     sx={{ width: 85 }}
                                        //     value={data.cost}
                                        //     defaultValue={0}
                                        //     onChange={(event) => {
                                        //       let newValue = event.target.value
                                        //       if (newValue !== '') {
                                        //         setAdditionalSelectedServices((pv) => [
                                        //             ...pv.map((p) => {
                                        //               if ( p.newIndexId === data.newIndexId ) {
                                        //                 return {
                                        //                   ...p,
                                        //                   cost: newValue,
                                        //                 }
                                        //               } else {
                                        //                 return p
                                        //               }
                                        //             }),
                                        //           ],
                                        //         )
                                        //         data['cost'] =  newValue || 0
                                        //         data['total_cost'] = newValue * data.originalMarkup
                                        //       }
                                        //     }}
                                        //     // error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                        //     // helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                        //   />
                                        // : 
                                        data.cost}</TableCell>
                                        <TableCell>{
                                          data?.isAdditionalProduct ?
                                          <TextField
                                            required
                                            fullWidth
                                            type="number"
                                            label={'Hourly Cost'}
                                            disabled={values.project_categories?.name == 'Time & Material' ? true : false}
                                            sx={{ width: 85 }}
                                            value={data.total_cost}
                                            // onWheel={(e) => e.target.blur()}
                                            onKeyPress={(event) => {
                                              // if (event.key === '0' && event.target.value === '') {
                                              //   event.preventDefault();
                                              // }
                                              if (event.key === '-') {
                                                event.preventDefault();
                                              }
                                              if (event.key === 'e') {
                                                event.preventDefault();
                                              }
                                            }}
                                            inputProps={{ min: 0 }}
                                            defaultValue={0}
                                            onChange={(event) => {
                                              let newValue = event.target.value
                                              if (newValue !== '') {
                                                setAdditionalSelectedServices((pv) => [
                                                    ...pv.map((p) => {
                                                      if ( p.newIndexId === data.newIndexId ) {
                                                        return {
                                                          ...p,
                                                          total_cost: newValue,
                                                        }
                                                      } else {
                                                        return p
                                                      }
                                                    }),
                                                  ],
                                                )
                                                data['total_cost'] =  newValue || 0
                                              }
                                            }}
                                            // error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                            // helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                          /> :
                                        data.total_cost}</TableCell>
                                        <TableCell>
                                          <TextField
                                            type="number"
                                            // sx={{ width: 400 }}
                                            label={'Hours'}
                                            // {...getFieldProps(quantityFieldName)}
                                            disabled={
                                              values.project_categories?.name ==
                                              'Time & Material'
                                                ? true
                                                : false
                                            }
                                            value={
                                              values.project_categories?.name ==
                                              'Time & Material'
                                                ? 0
                                                : Number(data.quantity) || 1
                                            }
                                            defaultValue={
                                              values.project_categories?.name ==
                                              'Time & Material'
                                                ? 0
                                                : 1
                                            }
                                            onChange={(event) => {
                                              let newValue = event.target.value
                                              // if (newValue <= 0) {
                                              //   event.preventDefault();
                                              //   return;
                                              // }
                                              if (newValue.startsWith('-')) {
                                                newValue = newValue.slice(1)
                                              }
                                              data['quantity'] = newValue || 1
                                              setFieldValue(
                                                quantityFieldName,
                                                newValue,
                                              )
                                            }}
                                            error={Boolean(
                                              touched[quantityFieldName] &&
                                                errors[quantityFieldName],
                                            )}
                                            helperText={
                                              touched[quantityFieldName] &&
                                              errors[quantityFieldName]
                                            }
                                          />
                                        </TableCell>
                                        {/* {(values.project_categories?.name == 'Service only' || (values?.project_categories?.name == 'Time & Material')) ? ( */}
                                        <TableCell>
                                          <IconButton
                                            onClick={() => {
                                              setServiceOnlyLocationStates({
                                                ...serviceOnlyLocationStates,
                                                [data.indexId]: true,
                                              })
                                            }}
                                          >
                                            <LocationOn
                                              sx={{
                                                color: data?.addresses?.some(
                                                  (obj) =>
                                                    [obj.address, obj.city, obj.state, obj.zip].some(
                                                      (value) => (value === '' || value === null),
                                                    ),
                                                )
                                                  ? ''
                                                  : theme.palette.primary.main,
                                              }}
                                            />
                                          </IconButton>
                                        </TableCell>
                                        {/* ) : null} */}
                                        <TableCell>
                                          {data?.isAdditionalProduct ? 'N/A' : (data.cost * quantityDifference?.toFixed(2))?.toFixed(2)}
                                        </TableCell>
                                        <TableCell>
                                          {total?.toFixed(2)}
                                        </TableCell>
                                        <TableCell>
                                          {total?.toFixed(2)}
                                        </TableCell>
                                        <TableCell align="right">
                                          <IconButton
                                            onClick={() =>
                                              handleDeleteService(
                                                data.id,
                                                data.newIndexId,
                                              )
                                            }
                                          >
                                            <Delete />
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                      <Modal
                                        open={
                                          serviceOnlyLocationStates[
                                            data.indexId
                                          ]
                                        }
                                        handleClose={() => {
                                          setServiceOnlyLocationStates({
                                            ...serviceOnlyLocationStates,
                                            [data.indexId]: false,
                                          })
                                        }}
                                      >
                                        {index === 0 && (
                                          <FormControlLabel
                                            control={
                                              <Switch
                                                checked={allServiceLocationSame}
                                                onChange={
                                                  handleAllServiceLocationSameChange
                                                }
                                              />
                                            }
                                            label="All Locations Same"
                                          />
                                        )}
                                        <Grid
                                          container
                                          spacing={3}
                                          sx={{
                                            justifyContent: 'center',
                                            width: '100%',
                                          }}
                                        >
                                          {data?.addresses?.map((addr, i) => (
                                            <Grid item xs={12}>
                                              {/* Side Heading */}
                                              <Typography
                                                variant="h5"
                                                sx={{ mb: 2 }}
                                              >
                                                Location
                                              </Typography>

                                              {/* Address Fields */}

                                              <Stack
                                                direction={{
                                                  xs: 'column',
                                                  sm: 'row',
                                                }}
                                                spacing={{ xs: 3, sm: 2 }}
                                                sx={{ mb: 2 }}
                                              >
                                                <TextField
                                                  fullWidth
                                                  label="Address"
                                                  size="small"
                                                  value={
                                                    data.addresses[i].address ||
                                                    ''
                                                  }
                                                  disabled={index !== 0 && allServiceLocationSame ? true : false}
                                                  onChange={(event) => {
                                                    let arrayAddr = [
                                                      ...data.addresses,
                                                    ]
                                                    arrayAddr[i].address =
                                                      event.target.value
                                                    setAdditionalSelectedServices(
                                                      (pv) => [
                                                        ...pv.map((p) => {
                                                          // if (p.id === data.id) {
                                                          if (
                                                            p.indexId ===
                                                            data.indexId
                                                          ) {
                                                            return {
                                                              ...p,
                                                              addresses:
                                                                arrayAddr,
                                                            }
                                                          } else {
                                                            return p
                                                          }
                                                        }),
                                                      ],
                                                    )
                                                  }}
                                                />
                                              </Stack>

                                              {/* City, State, Zip Fields */}
                                              <Stack
                                                direction={{
                                                  xs: 'column',
                                                  sm: 'row',
                                                }}
                                                spacing={{ xs: 3, sm: 2 }}
                                                sx={{ mb: 2 }}
                                              >
                                                <TextField
                                                  fullWidth
                                                  label="City"
                                                  size="small"
                                                  value={
                                                    data.addresses[i].city || ''
                                                  }
                                                  disabled={index !== 0 && allServiceLocationSame ? true : false}
                                                  onChange={(event) => {
                                                    let arrayAddr = [
                                                      ...data.addresses,
                                                    ]
                                                    arrayAddr[i].city =
                                                      event.target.value
                                                    setAdditionalSelectedServices(
                                                      (pv) => [
                                                        ...pv.map((p) => {
                                                          // if (p.id === data.id) {
                                                          if (
                                                            p.indexId ===
                                                            data.indexId
                                                          ) {
                                                            return {
                                                              ...p,
                                                              addresses:
                                                                arrayAddr,
                                                            }
                                                          } else {
                                                            return p
                                                          }
                                                        }),
                                                      ],
                                                    )
                                                  }}
                                                />
                                                <TextField
                                                  fullWidth
                                                  label="State"
                                                  size="small"
                                                  value={
                                                    data.addresses[i].state ||
                                                    ''
                                                  }
                                                  disabled={index !== 0 && allServiceLocationSame ? true : false}
                                                  onChange={(event) => {
                                                    let arrayAddr = [
                                                      ...data.addresses,
                                                    ]
                                                    arrayAddr[i].state =
                                                      event.target.value
                                                    setAdditionalSelectedServices(
                                                      (pv) => [
                                                        ...pv.map((p) => {
                                                          // if (p.id === data.id) {
                                                          if (
                                                            p.indexId ===
                                                            data.indexId
                                                          ) {
                                                            return {
                                                              ...p,
                                                              addresses:
                                                                arrayAddr,
                                                            }
                                                          } else {
                                                            return p
                                                          }
                                                        }),
                                                      ],
                                                    )
                                                  }}
                                                />
                                                <TextField
                                                  fullWidth
                                                  label="Zip / Postal Code"
                                                  size="small"
                                                  value={
                                                    data.addresses[i].zip || ''
                                                  }
                                                  disabled={index !== 0 && allServiceLocationSame ? true : false}
                                                  onChange={(event) => {
                                                    let arrayAddr = [
                                                      ...data.addresses,
                                                    ]
                                                    arrayAddr[i].zip =
                                                      event.target.value
                                                    setAdditionalSelectedServices(
                                                      (pv) => [
                                                        ...pv.map((p) => {
                                                          if (
                                                            p.indexId ===
                                                            data.indexId
                                                          ) {
                                                            // if (p.id === data.id) {
                                                            return {
                                                              ...p,
                                                              addresses:
                                                                arrayAddr,
                                                            }
                                                          } else {
                                                            return p
                                                          }
                                                        }),
                                                      ],
                                                    )
                                                  }}
                                                />
                                              </Stack>
                                            </Grid>
                                          ))}
                                        </Grid>
                                          {index !== 0 && allServiceLocationSame ? '' :
                                            <Grid
                                              container
                                              justifyContent="center"
                                              alignItems="center"
                                            >
                                              <Grid item xs={12} md={4}>
                                                <Box
                                                  sx={{
                                                    mt: 3,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    gap: 1,
                                                  }}
                                                >
                                                  <LoadingButton
                                                    variant="contained"
                                                    onClick={() => {
                                                      setIsSaving(true)
                                                      setTimeout(() => {
                                                        setIsSaving(false)
                                                        setServiceOnlyLocationStates(
                                                          false,
                                                        )
                                                      }, 100)
                                                    }}
                                                    loading={isSaving}
                                                  >
                                                    {' Save '}
                                                  </LoadingButton>
                                                  {/* <Button variant="outlined" type="submit" onClick={() => { setLocationStates(false) }}>
                                          Cancel
                                        </Button> */}
                                                </Box>
                                              </Grid>
                                            </Grid>
                                          }
                                      </Modal>
                                    </>
                                  )
                                },
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Scrollbar>
                      {
                            showAddNewRow ?
                      <Stack sx={{ justifyContent: 'space-between', alignItems: 'center', display:'flex', flexDirection:'row' }}>
                        <Button
                          variant="outlined"
                          startIcon={
                            <Stack>
                              {' '}
                              <Add />{' '}
                            </Stack>
                          }
                          onClick={() => {
                            addNewServiceRow()
                            // setMarkupType(1)
                          }}
                        >
                          Add Service Row
                        </Button>
                        <Typography variant="h6">
                          Total Additional Service Cost:{' '}
                          {selectedServicesSubTotal?.toFixed(2)}
                        </Typography>
                      </Stack>
                      :""}
                      <Divider />
                      {/* {values.project_categories?.name == 'Time & Material'? '' :
              <> */}
                      {values?.project_type?.name == 'TS' &&
                      values?.project_categories?.id == 14 ? (
                        ''
                      ) : (
                        <>
                          <LabelStyleHead>Proposals</LabelStyleHead>
                          <Autocomplete
                            options={rfps}
                            getOptionLabel={(option) => option.name}
                            value={values.rfp}
                            onChange={(event, value) => {
                              handleRfpSelect(event, value)
                              setFieldValue('rfp', value)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Proposal"
                              />
                            )}
                          />

                          {/* Modal for RFP Selection */}
                          <Modal open={openModal} handleClose={closeModal}>
                            <Box
                              sx={{
                                position: 'relative',
                                p: 3,
                                bgcolor: 'background.paper',
                                boxShadow: 24,
                              }}
                            >
                              <Tabs
                                value={tabIndex}
                                onChange={handleTabChange}
                                variant="fullWidth"
                                sx={{
                                  marginBottom: 4,
                                }}
                              >
                                <Tab
                                  label="Services"
                                  sx={{ width: '50%', minWidth: 0 }}
                                />
                                {values?.project_categories?.name !=
                                  'Service only' &&
                                  values?.project_categories?.name !=
                                    'Time & Material' && (
                                    <Tab
                                      label="Products"
                                      sx={{ width: '50%', minWidth: 0 }}
                                    />
                                  )}
                              </Tabs>
                             
                              {tabIndex === 0 && (
                                <TableContainer sx={{ minWidth: 500 }}>
                                  <Table>
                                    <TableHead>
                                      <TableCell align="center">Name</TableCell>
                                      <TableCell align="center">
                                        Unit Cost
                                      </TableCell>
                                      <TableCell align="center">
                                        Hours
                                      </TableCell>
                                      <TableCell align="center">
                                        Total Cost
                                      </TableCell>
                                      <TableCell align="center"></TableCell>
                                    </TableHead>
                                    {rfpServices.map((service) => {
                                      if (
                                        values?.project_categories?.name ==
                                        'Time & Material'
                                      ) {
                                        service.sellingPrice = 0
                                        service.quantity = 0
                                        service.total_cost = 0
                                      }
                                      return (
                                        // <Box key={service.id} sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                        <TableRow key={service.id}>
                                          <TableCell align="center">
                                            {service.name}
                                          </TableCell>
                                          <TableCell align="center">
                                            ${service.sellingPrice}
                                          </TableCell>
                                          <TableCell align="center">
                                            {service.quantity}
                                          </TableCell>
                                          <TableCell align="center">
                                            ${service.total_cost}
                                          </TableCell>
                                          <TableCell align="center">
                                            <Button
                                              onClick={() => addToCart(service)}
                                            >
                                              Add
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                        // </Box>
                                      )
                                    })}
                                  </Table>
                                </TableContainer>
                              )}
                              {tabIndex === 1 && (
                                <TableContainer sx={{ minWidth: 500 }}>
                                  <Table>
                                    <TableHead>
                                      <TableCell align="center">Name</TableCell>
                                      <TableCell align="center">
                                        Unit Cost
                                      </TableCell>
                                      <TableCell align="center">Unit</TableCell>
                                      <TableCell align="center">Qty</TableCell>
                                      <TableCell align="center">
                                        Total Cost
                                      </TableCell>
                                      <TableCell align="center"></TableCell>
                                    </TableHead>
                                    {rfpProducts.map((product) => {
                                      let unitName = UNITS?.filter(
                                        (item) => item?.id == product?.unit,
                                      )
                                      unitName = unitName[0]?.name
                                      let shortUnitName = ''
                                      if (unitName == 'Square feet') {
                                        shortUnitName = 'Sq ft'
                                      } else if (unitName == 'Lineal feet') {
                                        shortUnitName = 'Lin ft'
                                      } else if (unitName == 'Square Inches') {
                                        shortUnitName = 'Sq Inches'
                                      } else if (unitName == 'Unit') {
                                        shortUnitName = 'Unit'
                                      }
                                      if (
                                        values?.project_categories?.name ==
                                        'Time & Material'
                                      ) {
                                        product.product_price = 0
                                        // product.quantity = 0
                                      }
                                      return (
                                        // <Box key={product.id} sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                        <TableRow key={product.id}>
                                          <TableCell align="center">
                                            {product.product_name}
                                          </TableCell>
                                          <TableCell align="center">
                                            ${product.product_price}
                                          </TableCell>
                                          <TableCell align="center">
                                            {product.quantity} {shortUnitName}
                                          </TableCell>
                                          <TableCell align="center">
                                            {product.location_quantity}
                                          </TableCell>
                                          <TableCell align="center">
                                            $
                                            {(
                                              product.location_quantity *
                                              product.quantity *
                                              (product.product_price.toFixed(
                                                2,
                                              ) || 0)
                                            ).toFixed(2)}
                                          </TableCell>
                                          <TableCell align="center">
                                            <Button
                                              onClick={() => addToCart(product)}
                                            >
                                              Add
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                        // </Box>
                                      )
                                    })}
                                  </Table>
                                </TableContainer>
                              )}
                            </Box>
                          </Modal>
                          <Scrollbar>
                            <TableContainer sx={{ minWidth: 500 }}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    {VALUE_RFP_Product?.map((val) => (
                                      <TableCell key={val.id}>
                                        {val.value}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {cart?.map((data, index) => {
                                    let unitName = UNITS?.filter(
                                      (item) => item?.id == data?.unit,
                                    )
                                    unitName = unitName[0]?.name
                                    let shortUnitName = ''
                                    if (unitName == 'Square feet') {
                                      shortUnitName = 'Sq ft'
                                    } else if (unitName == 'Lineal feet') {
                                      shortUnitName = 'Lin ft'
                                    } else if (unitName == 'Square Inches') {
                                      shortUnitName = 'Sq Inches'
                                    } else if (unitName == 'Unit') {
                                      shortUnitName = 'Unit'
                                    }
                                    if (
                                      values.project_categories?.name ==
                                      'Time & Material'
                                    ) {
                                      data.product_price = 0
                                      data.product_retail_price = 0
                                      data.sellingPrice = 0
                                    }
                                    let markup = markupType
                                      ? markupType === 1
                                        ? data.WholesaleMarkup
                                        : data.RetailMarkup
                                      : 0
                                    data.product_markup = markup

                                    let newTotalProductFormula =
                                      (
                                        data.product_price *
                                        data.quantity *
                                        data.location_quantity
                                      )?.toFixed(2) || 0
                                    let newTotalCost = (
                                      data.location_quantity *
                                      data.quantity *
                                      ((
                                        data.product_price +
                                        (data.product_price * markup) / 100
                                      ).toFixed(2) || 0)
                                    ).toFixed(2)

                                    totalProductFormula += Number(
                                      newTotalProductFormula,
                                    )
                                    totalWholesalePrice += Number(newTotalCost)
                                    totalRetailPrice += Number(newTotalCost)
                                    values['totalProductFormula'] =
                                      totalProductFormula
                                    values['totalWholesalePrice'] =
                                      totalWholesalePrice
                                    values['totalRetailPrice'] =
                                      totalRetailPrice

                                    return (
                                      <>
                                        <TableRow key={data.id}>
                                          <TableCell>
                                            {data.name || data.product_name}
                                          </TableCell>
                                          {/* <TableCell>{invoiceAmount}</TableCell> */}
                                          <TableCell>
                                            {markup?.toFixed(2) || 0.0}
                                          </TableCell>
                                          <TableCell>
                                            {data.product_price?.toFixed(2) ||
                                              0}
                                          </TableCell>
                                          <TableCell>
                                            {(
                                              data.product_price +
                                              (data.product_price * markup) /
                                                100
                                            ).toFixed(2) || 0.0}
                                          </TableCell>
                                          <TableCell>
                                            {data.quantity} {shortUnitName}
                                          </TableCell>
                                          <TableCell>
                                            {data.location_quantity}
                                          </TableCell>
                                          <TableCell>
                                            {(
                                              data.product_price *
                                              data.quantity *
                                              data.location_quantity
                                            )?.toFixed(2) || 0}
                                          </TableCell>
                                          <TableCell>
                                            {(
                                              data.location_quantity *
                                              data.quantity *
                                              ((
                                                data.product_price +
                                                (data.product_price * markup) /
                                                  100
                                              ).toFixed(2) || 0)
                                            ).toFixed(2)}
                                          </TableCell>

                                          <TableCell>
                                            <IconButton
                                              onClick={() => {
                                                setRfpLocationStates({
                                                  ...rfpLocationStates,
                                                  [data.product_id]: true,
                                                })
                                              }}
                                            >
                                              <LocationOn
                                                sx={{
                                                  color:
                                                    data &&
                                                    data.addresses &&
                                                    data.addresses.some((obj) =>
                                                      [obj.address, obj.city, obj.state, obj.zip].some(
                                                        (value) => (value === '' || value === null),
                                                      ),
                                                    )
                                                      ? ''
                                                      : theme.palette.primary
                                                          .main,
                                                }}
                                              />
                                            </IconButton>
                                          </TableCell>

                                          <TableCell>
                                            <IconButton
                                              onClick={() =>
                                                handleDeleteRFPProduct(data.id)
                                              }
                                            >
                                              <Delete />
                                            </IconButton>
                                          </TableCell>
                                        </TableRow>

                                        <Modal
                                          open={
                                            rfpLocationStates[data.product_id]
                                          }
                                          handleClose={() => {
                                            setRfpLocationStates({
                                              ...rfpLocationStates,
                                              [data.product_id]: false,
                                            })
                                          }}
                                        >
                                          {/* {index === 0 && ( */}
                                            <FormControlLabel
                                              control={
                                                <Switch
                                                  checked={
                                                    data?.allRfpProductsLocationSame
                                                  }
                                                  onChange={()=>
                                                    handleAllRfpProductsLocationSameChange(index)
                                                  }
                                                />
                                              }
                                              label="All Locations Same"
                                            />
                                          {/* )} */}

                                          <Grid
                                            container
                                            spacing={3}
                                            sx={{
                                              justifyContent: 'center',
                                              width: '100%',
                                            }}
                                          >
                                            {data?.addresses?.map((addr, i) => (
                                              <Grid item xs={12}>
                                                {/* Side Heading */}
                                                <Typography
                                                  variant="h5"
                                                  sx={{ mb: 2 }}
                                                >
                                                  Location {i+1}
                                                </Typography>

                                                {/* Address Fields */}

                                                <Stack
                                                  direction={{
                                                    xs: 'column',
                                                    sm: 'row',
                                                  }}
                                                  spacing={{ xs: 3, sm: 2 }}
                                                  sx={{ mb: 2 }}
                                                >
                                                  <TextField
                                                    fullWidth
                                                    label="Address"
                                                    size="small"
                                                    value={
                                                      data.addresses[i]
                                                        .address || ''
                                                    }
                                                    // onChange={(event) => {
                                                    //   let arrayAddr = [
                                                    //     ...data.addresses,
                                                    //   ]
                                                    //   arrayAddr[i].address =
                                                    //     event.target.value

                                                    //   setCart((pv) => [
                                                    //     ...pv.map((p) => {
                                                    //       if (
                                                    //         p.product_id ===
                                                    //         data.product_id
                                                    //       ) {
                                                    //         return {
                                                    //           ...p,
                                                    //           addresses:
                                                    //             arrayAddr,
                                                    //         }
                                                    //       } else {
                                                    //         return {
                                                    //           ...p,
                                                    //         }
                                                    //       }
                                                    //     }),
                                                    //   ])
                                                    // }}
                                                    disabled={
                                                      i !== 0 &&
                                                      data?.allRfpProductsLocationSame
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(event) => {
                                                      setCart((prevProducts) =>
                                                        prevProducts.map((p) => {
                                                          if (p.product_id === data.product_id) {
                                                            let updatedAddresses = [...p.addresses];
                                                            if (p.allRfpProductsLocationSame) {
                                                              updatedAddresses = updatedAddresses.map(() => ({
                                                                ...updatedAddresses[0],
                                                                address: event.target.value,
                                                              }));
                                                            } else {
                                                              updatedAddresses[i] = {
                                                                ...updatedAddresses[i],
                                                                address: event.target.value,
                                                              };
                                                            }
                                                            return {
                                                              ...p,
                                                              addresses: updatedAddresses,
                                                            };
                                                          }
                                                          return p;
                                                        })
                                                      );
                                                    }}
                                                  />
                                                </Stack>

                                                {/* City, State, Zip Fields */}
                                                <Stack
                                                  direction={{
                                                    xs: 'column',
                                                    sm: 'row',
                                                  }}
                                                  spacing={{ xs: 3, sm: 2 }}
                                                  sx={{ mb: 2 }}
                                                >
                                                  <TextField
                                                    fullWidth
                                                    label="City"
                                                    size="small"
                                                    value={
                                                      data.addresses[i].city ||
                                                      ''
                                                    }
                                                    // onChange={(event) => {
                                                    //   let arrayAddr = [
                                                    //     ...data.addresses,
                                                    //   ]
                                                    //   arrayAddr[i].city =
                                                    //     event.target.value

                                                    //   setCart((pv) => [
                                                    //     ...pv.map((p) => {
                                                    //       if (
                                                    //         p.product_id ===
                                                    //         data.product_id
                                                    //       ) {
                                                    //         return {
                                                    //           ...p,
                                                    //           addresses:
                                                    //             arrayAddr,
                                                    //         }
                                                    //       } else {
                                                    //         return {
                                                    //           ...p,
                                                    //         }
                                                    //       }
                                                    //     }),
                                                    //   ])
                                                    // }}
                                                    disabled={
                                                      i !== 0 &&
                                                      data?.allRfpProductsLocationSame
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(event) => {
                                                      setCart((prevProducts) =>
                                                        prevProducts.map((p) => {
                                                          if (p.product_id === data.product_id) {
                                                            let updatedAddresses = [...p.addresses];
                                                            if (p.allRfpProductsLocationSame) {
                                                              updatedAddresses = updatedAddresses.map(() => ({
                                                                ...updatedAddresses[0],
                                                                city: event.target.value,
                                                              }));
                                                            } else {
                                                              updatedAddresses[i] = {
                                                                ...updatedAddresses[i],
                                                                city: event.target.value,
                                                              };
                                                            }
                                                            return {
                                                              ...p,
                                                              addresses: updatedAddresses,
                                                            };
                                                          }
                                                          return p;
                                                        })
                                                      );
                                                    }}
                                                  />
                                                  <TextField
                                                    fullWidth
                                                    label="State"
                                                    size="small"
                                                    value={
                                                      data.addresses[i].state ||
                                                      ''
                                                    }
                                                    // onChange={(event) => {
                                                    //   let arrayAddr = [
                                                    //     ...data.addresses,
                                                    //   ]
                                                    //   arrayAddr[i].state =
                                                    //     event.target.value

                                                    //   setCart((pv) => [
                                                    //     ...pv.map((p) => {
                                                    //       if (
                                                    //         p.product_id ===
                                                    //         data.product_id
                                                    //       ) {
                                                    //         return {
                                                    //           ...p,
                                                    //           addresses:
                                                    //             arrayAddr,
                                                    //         }
                                                    //       } else {
                                                    //         return {
                                                    //           ...p,
                                                    //         }
                                                    //       }
                                                    //     }),
                                                    //   ])
                                                    // }}
                                                    disabled={
                                                      i !== 0 &&
                                                      data?.allRfpProductsLocationSame
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(event) => {
                                                      setCart((prevProducts) =>
                                                        prevProducts.map((p) => {
                                                          if (p.product_id === data.product_id) {
                                                            let updatedAddresses = [...p.addresses];
                                                            if (p.allRfpProductsLocationSame) {
                                                              updatedAddresses = updatedAddresses.map(() => ({
                                                                ...updatedAddresses[0],
                                                                state: event.target.value,
                                                              }));
                                                            } else {
                                                              updatedAddresses[i] = {
                                                                ...updatedAddresses[i],
                                                                state: event.target.value,
                                                              };
                                                            }
                                                            return {
                                                              ...p,
                                                              addresses: updatedAddresses,
                                                            };
                                                          }
                                                          return p;
                                                        })
                                                      );
                                                    }}
                                                  />
                                                  <TextField
                                                    fullWidth
                                                    label="Zip / Postal Code"
                                                    size="small"
                                                    value={
                                                      data.addresses[i].zip ||
                                                      ''
                                                    }
                                                    // onChange={(event) => {
                                                    //   let arrayAddr = [
                                                    //     ...data.addresses,
                                                    //   ]
                                                    //   arrayAddr[i].zip =
                                                    //     event.target.value

                                                    //   setCart((pv) => [
                                                    //     ...pv.map((p) => {
                                                    //       if (
                                                    //         p.product_id ===
                                                    //         data.product_id
                                                    //       ) {
                                                    //         return {
                                                    //           ...p,
                                                    //           addresses:
                                                    //             arrayAddr,
                                                    //         }
                                                    //       } else {
                                                    //         return {
                                                    //           ...p,
                                                    //         }
                                                    //       }
                                                    //     }),
                                                    //   ])
                                                    // }}
                                                    disabled={
                                                      i !== 0 &&
                                                      data?.allRfpProductsLocationSame
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(event) => {
                                                      setCart((prevProducts) =>
                                                        prevProducts.map((p) => {
                                                          if (p.product_id === data.product_id) {
                                                            let updatedAddresses = [...p.addresses];
                                                            if (p.allRfpProductsLocationSame) {
                                                              updatedAddresses = updatedAddresses.map(() => ({
                                                                ...updatedAddresses[0],
                                                                zip: event.target.value,
                                                              }));
                                                            } else {
                                                              updatedAddresses[i] = {
                                                                ...updatedAddresses[i],
                                                                zip: event.target.value,
                                                              };
                                                            }
                                                            return {
                                                              ...p,
                                                              addresses: updatedAddresses,
                                                            };
                                                          }
                                                          return p;
                                                        })
                                                      );
                                                    }}
                                                  />
                                                </Stack>
                                              </Grid>
                                            ))}
                                          </Grid>

                                          <Grid
                                            container
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            <Grid item xs={12} md={4}>
                                              <Box
                                                sx={{
                                                  mt: 3,
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  gap: 1,
                                                }}
                                              >
                                                <LoadingButton
                                                  variant="contained"
                                                  onClick={() => {
                                                    setIsSaving(true)
                                                    setTimeout(() => {
                                                      setIsSaving(false)
                                                      setRfpLocationStates(
                                                        false,
                                                      )
                                                    }, 100)
                                                  }}
                                                  loading={isSaving}
                                                >
                                                  {' Save '}
                                                </LoadingButton>
                                              </Box>
                                            </Grid>
                                          </Grid>
                                        </Modal>
                                      </>
                                    )
                                  })}
                                </TableBody>
                                <h6>Services</h6>
                                <TableBody>
                                  {cartService?.map((data, index) => {
                                    if (
                                      values.project_categories?.name ==
                                      'Time & Material'
                                    ) {
                                      data.sellingPrice = 0
                                      data.unit_cost = 0
                                      data.qty = 0
                                    }
                                    let newTotalProductFormula =
                                      (data.qty * data.sellingPrice).toFixed(
                                        2,
                                      ) || 0.0
                                    let newTotalCost = (
                                      data.qty *
                                      (data.sellingPrice +
                                        (data.sellingPrice *
                                          data.originalMarkup) /
                                          100)
                                    )?.toFixed(2)

                                    totalProductFormula += Number(
                                      newTotalProductFormula,
                                    )
                                    totalWholesalePrice += Number(newTotalCost)
                                    totalRetailPrice += Number(newTotalCost)
                                    values['totalProductFormula'] =
                                      totalProductFormula
                                    values['totalWholesalePrice'] =
                                      totalWholesalePrice
                                    values['totalRetailPrice'] =
                                      totalRetailPrice
                                    return (
                                      <>
                                        <TableRow key={data.id}>
                                          <TableCell>
                                            {data.name || data.product_name}
                                          </TableCell>
                                          {/* <TableCell>{invoiceAmount}</TableCell> */}
                                          <TableCell>
                                            {data.originalMarkup?.toFixed(2)}
                                          </TableCell>
                                          <TableCell>
                                            {data.sellingPrice?.toFixed(2)}
                                          </TableCell>
                                          <TableCell>
                                            {(
                                              data.sellingPrice +
                                              (data.sellingPrice *
                                                data.originalMarkup) /
                                                100
                                            )?.toFixed(2)}
                                          </TableCell>
                                          <TableCell>
                                            {data.qty} Hours
                                          </TableCell>
                                          <TableCell></TableCell>
                                          <TableCell>
                                            {(
                                              data.qty * data.sellingPrice
                                            ).toFixed(2) || 0.0}
                                          </TableCell>
                                          <TableCell>
                                            {(
                                              data.qty *
                                              (data.sellingPrice +
                                                (data.sellingPrice *
                                                  data.originalMarkup) /
                                                  100)
                                            )?.toFixed(2)}
                                          </TableCell>
                                          {/* {(values?.project_categories?.name == 'Time & Material') ? '' : 
                                <TableCell >

                              </TableCell>} */}
                                          {/* {((values?.project_categories?.name == 'Service only') ||
                                (values?.project_categories?.name == 'Time & Material')) ?  */}
                                          <TableCell>
                                            <IconButton
                                              onClick={() => {
                                                setRfpServiceLocationStates({
                                                  ...rfpServiceLocationStates,
                                                  [data.id]: true,
                                                })
                                              }}
                                            >
                                              <LocationOn
                                                sx={{
                                                  color:
                                                    data &&
                                                    data.addresses &&
                                                    data.addresses.some((obj) =>
                                                      [obj.address, obj.city, obj.state, obj.zip].some(
                                                        (value) => (value === '' || value === null),
                                                      ),
                                                    )
                                                      ? ''
                                                      : theme.palette.primary
                                                          .main,
                                                }}
                                              />
                                            </IconButton>
                                          </TableCell>
                                          {/* : '' } */}
                                          <TableCell>
                                            <IconButton
                                              onClick={() =>
                                                handleDeleteRFPService(data.id)
                                              }
                                            >
                                              <Delete />
                                            </IconButton>
                                          </TableCell>
                                        </TableRow>

                                        <Modal
                                          open={
                                            rfpServiceLocationStates[
                                              data.id
                                            ]
                                          }
                                          handleClose={() => {
                                            setRfpServiceLocationStates({
                                              ...rfpServiceLocationStates,
                                              [data.id]: false,
                                            })
                                          }}
                                        >
                                          {index === 0 && (
                                            <FormControlLabel
                                              control={
                                                <Switch
                                                  checked={
                                                    allRfpServiceLocationSame
                                                  }
                                                  onChange={
                                                    handleAllRfpServiceLocationSameChange
                                                  }
                                                />
                                              }
                                              label="All Locations Same"
                                            />
                                          )}

                                          <Grid
                                            container
                                            spacing={3}
                                            sx={{
                                              justifyContent: 'center',
                                              width: '100%',
                                            }}
                                          >
                                            {data?.addresses?.map((addr, i) => (
                                              <Grid item xs={12}>
                                                {/* Side Heading */}
                                                <Typography
                                                  variant="h5"
                                                  sx={{ mb: 2 }}
                                                >
                                                  Location
                                                </Typography>

                                                {/* Address Fields */}

                                                <Stack
                                                  direction={{
                                                    xs: 'column',
                                                    sm: 'row',
                                                  }}
                                                  spacing={{ xs: 3, sm: 2 }}
                                                  sx={{ mb: 2 }}
                                                >
                                                  <TextField
                                                    fullWidth
                                                    label="Address"
                                                    size="small"
                                                    value={
                                                      data.addresses[i]
                                                        .address || ''
                                                    }
                                                    // onChange={(event) => {
                                                    //   let arrayAddr = [
                                                    //     ...data.addresses,
                                                    //   ]
                                                    //   arrayAddr[i].address =
                                                    //     event.target.value

                                                    //   setCartService((pv) => [
                                                    //     ...pv.map((p) => {
                                                    //       if (
                                                    //         p.labour_cost_service_id ===
                                                    //         data.labour_cost_service_id
                                                    //       ) {
                                                    //         return {
                                                    //           ...p,
                                                    //           addresses:
                                                    //             arrayAddr,
                                                    //         }
                                                    //       } else {
                                                    //         return {
                                                    //           ...p,
                                                    //         }
                                                    //       }
                                                    //     }),
                                                    //   ])
                                                    // }}
                                                    disabled={index !== 0 && allRfpServiceLocationSame ? true : false}
                                                    onChange={(event) => {
                                                      const updatedValue = event.target.value;
                                                    
                                                      setCartService((prevServices) =>
                                                        prevServices.map((service) => {
                                                          let updatedLocations = [...service.addresses];
                                                    
                                                          if (allRfpServiceLocationSame) {
                                                            // Apply the new value to all services in cartService
                                                            return {
                                                              ...service,
                                                              addresses: updatedLocations.map(loc => ({
                                                                ...loc,
                                                                address: updatedValue, // Update address field, same logic applies for others
                                                              })),
                                                            };
                                                          } else {
                                                            // Update only the specific service
                                                            if (service.id === data.id) {
                                                              updatedLocations[i].address = updatedValue;
                                                            }
                                                            return {
                                                              ...service,
                                                              addresses: updatedLocations,
                                                            };
                                                          }
                                                        })
                                                      );
                                                    }}
                                                  />
                                                </Stack>

                                                {/* City, State, Zip Fields */}
                                                <Stack
                                                  direction={{
                                                    xs: 'column',
                                                    sm: 'row',
                                                  }}
                                                  spacing={{ xs: 3, sm: 2 }}
                                                  sx={{ mb: 2 }}
                                                >
                                                  <TextField
                                                    fullWidth
                                                    label="City"
                                                    size="small"
                                                    value={
                                                      data.addresses[i].city ||
                                                      ''
                                                    }
                                                    // onChange={(event) => {
                                                    //   let arrayAddr = [
                                                    //     ...data.addresses,
                                                    //   ]
                                                    //   arrayAddr[i].city =
                                                    //     event.target.value

                                                    //   setCartService((pv) => [
                                                    //     ...pv.map((p) => {
                                                    //       if (
                                                    //         p.labour_cost_service_id ===
                                                    //         data.labour_cost_service_id
                                                    //       ) {
                                                    //         return {
                                                    //           ...p,
                                                    //           addresses:
                                                    //             arrayAddr,
                                                    //         }
                                                    //       } else {
                                                    //         return {
                                                    //           ...p,
                                                    //         }
                                                    //       }
                                                    //     }),
                                                    //   ])
                                                    // }}
                                                    disabled={index !== 0 && allRfpServiceLocationSame ? true : false}
                                                    onChange={(event) => {
                                                      const updatedValue = event.target.value;
                                                    
                                                      setCartService((prevServices) =>
                                                        prevServices.map((service) => {
                                                          let updatedLocations = [...service.addresses];
                                                    
                                                          if (allRfpServiceLocationSame) {
                                                            // Apply the new value to all services in cartService
                                                            return {
                                                              ...service,
                                                              addresses: updatedLocations.map(loc => ({
                                                                ...loc,
                                                                city: updatedValue, // Update city field, same logic applies for others
                                                              })),
                                                            };
                                                          } else {
                                                            // Update only the specific service
                                                            if (service.id === data.id) {
                                                              updatedLocations[i].city = updatedValue;
                                                            }
                                                            return {
                                                              ...service,
                                                              addresses: updatedLocations,
                                                            };
                                                          }
                                                        })
                                                      );
                                                    }}
                                                  />
                                                  <TextField
                                                    fullWidth
                                                    label="State"
                                                    size="small"
                                                    value={
                                                      data.addresses[i].state ||
                                                      ''
                                                    }
                                                    // onChange={(event) => {
                                                    //   let arrayAddr = [
                                                    //     ...data.addresses,
                                                    //   ]
                                                    //   arrayAddr[i].state =
                                                    //     event.target.value

                                                    //   setCartService((pv) => [
                                                    //     ...pv.map((p) => {
                                                    //       if (
                                                    //         p.labour_cost_service_id ===
                                                    //         data.labour_cost_service_id
                                                    //       ) {
                                                    //         return {
                                                    //           ...p,
                                                    //           addresses:
                                                    //             arrayAddr,
                                                    //         }
                                                    //       } else {
                                                    //         return {
                                                    //           ...p,
                                                    //         }
                                                    //       }
                                                    //     }),
                                                    //   ])
                                                    // }}
                                                    disabled={index !== 0 && allRfpServiceLocationSame ? true : false}
                                                    onChange={(event) => {
                                                      const updatedValue = event.target.value;
                                                    
                                                      setCartService((prevServices) =>
                                                        prevServices.map((service) => {
                                                          let updatedLocations = [...service.addresses];
                                                    
                                                          if (allRfpServiceLocationSame) {
                                                            // Apply the new value to all services in cartService
                                                            return {
                                                              ...service,
                                                              addresses: updatedLocations.map(loc => ({
                                                                ...loc,
                                                                state: updatedValue, // Update state field, same logic applies for others
                                                              })),
                                                            };
                                                          } else {
                                                            // Update only the specific service
                                                            if (service.id === data.id) {
                                                              updatedLocations[i].state = updatedValue;
                                                            }
                                                            return {
                                                              ...service,
                                                              addresses: updatedLocations,
                                                            };
                                                          }
                                                        })
                                                      );
                                                    }}  
                                                  />
                                                  <TextField
                                                    fullWidth
                                                    label="Zip / Postal Code"
                                                    size="small"
                                                    value={
                                                      data.addresses[i].zip ||
                                                      ''
                                                    }
                                                    // onChange={(event) => {
                                                    //   let arrayAddr = [
                                                    //     ...data.addresses,
                                                    //   ]
                                                    //   arrayAddr[i].zip =
                                                    //     event.target.value

                                                    //   setCartService((pv) => [
                                                    //     ...pv.map((p) => {
                                                    //       if (
                                                    //         p.labour_cost_service_id ===
                                                    //         data.labour_cost_service_id
                                                    //       ) {
                                                    //         return {
                                                    //           ...p,
                                                    //           addresses:
                                                    //             arrayAddr,
                                                    //         }
                                                    //       } else {
                                                    //         return {
                                                    //           ...p,
                                                    //         }
                                                    //       }
                                                    //     }),
                                                    //   ])
                                                    // }}
                                                    disabled={index !== 0 && allRfpServiceLocationSame ? true : false}
                                                    onChange={(event) => {
                                                      const updatedValue = event.target.value;
                                                    
                                                      setCartService((prevServices) =>
                                                        prevServices.map((service) => {
                                                          let updatedLocations = [...service.addresses];
                                                    
                                                          if (allRfpServiceLocationSame) {
                                                            // Apply the new value to all services in cartService
                                                            return {
                                                              ...service,
                                                              addresses: updatedLocations.map(loc => ({
                                                                ...loc,
                                                                zip: updatedValue, // Update zip field, same logic applies for others
                                                              })),
                                                            };
                                                          } else {
                                                            // Update only the specific service
                                                            if (service.id === data.id) {
                                                              updatedLocations[i].zip = updatedValue;
                                                            }
                                                            return {
                                                              ...service,
                                                              addresses: updatedLocations,
                                                            };
                                                          }
                                                        })
                                                      );
                                                    }}
                                                  />
                                                </Stack>
                                              </Grid>
                                            ))}
                                          </Grid>
                                          {index !== 0 && allRfpServiceLocationSame ? '' :
                                            <Grid
                                              container
                                              justifyContent="center"
                                              alignItems="center"
                                            >
                                              <Grid item xs={12} md={4}>
                                                <Box
                                                  sx={{
                                                    mt: 3,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    gap: 1,
                                                  }}
                                                >
                                                  <LoadingButton
                                                    variant="contained"
                                                    onClick={() => {
                                                      setIsSaving(true)
                                                      setTimeout(() => {
                                                        setIsSaving(false)
                                                        setRfpServiceLocationStates(
                                                          false,
                                                        )
                                                      }, 100)
                                                    }}
                                                    loading={isSaving}
                                                  >
                                                    {' Save '}
                                                  </LoadingButton>
                                                </Box>
                                              </Grid>
                                            </Grid>
                                          }
                                        </Modal>
                                      </>
                                    )
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Scrollbar>
                        </>
                      )}
                      {/* {values.project_categories?.name == 'Service only' ? (
                        ''
                      ) : ( */}
                        <>
                          <LabelStyleHead>Materials</LabelStyleHead>
                          <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={{ xs: 3, sm: 2 }}
                          >
                            <Autocomplete
                              sx={{ width: 400 }}
                              multiple
                              id="combo-box-demo"
                              // options={materials || []}
                              // options={
                              //   materials?.filter(
                              //     (option) =>
                              //       !selectedMaterials?.some(
                              //         (selected) => selected.id === option.id,
                              //       ),
                              //   ) || []
                              // }
                              options={
                                materials?.filter((option) => {
                                  // Exclude selected materials
                                  const isSelected = selectedMaterials?.some((selected) => selected.id === option.id);
                                  const isServiceOnly = values.project_categories?.name === 'Service only';
                                  // Filter materials: show only inhouse = 1 unless it's 'Service only'
                                  const shouldInclude = isServiceOnly || option.inhouse === 1;
                                  return !isSelected && shouldInclude;
                                }) || []
                              }
                              value={selectedMaterials?.filter(item => !item?.isAdditionalProduct)}
                              onChange={(event, newValue) => {
                                console.log('newValue=======', newValue)
                                setFieldValue('additionalMaterials', newValue)
                                handleMaterial(newValue)
                                setShowAddNewRow(true)

                              }}
                              renderInput={(params) => (
                                // <TextField {...params} label="Materials" variant="outlined" />
                                <TextField
                                  {...params}
                                  label="Materials"
                                  error={Boolean(
                                    touched.additionalMaterials &&
                                      errors.additionalMaterials,
                                  )}
                                  helperText={
                                    touched.additionalMaterials &&
                                    errors.additionalMaterials
                                  }
                                />
                              )}
                              // getOptionLabel={(option) =>
                              //   option.name ? option.name : ''
                              // }
                              getOptionLabel={(option) => {
                                const name = option.name || '';
                                const ownershipLabel = option.inhouse === 1 ? ' (Inhouse)' : ' (Client owned)';
                                return name + ownershipLabel;
                              }}
                            />
                          </Stack>
                          <LabelStyle>Selected Materials</LabelStyle>
                          <Scrollbar>
                            <TableContainer sx={{ minWidth: 500 }}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    {VALUE_MATERIAL?.map((val) =>
                                      val.id === 3 ? (
                                        <Tooltip
                                          key={val.id}
                                          title="Unit Price + ((Unit Price * Markup)/100)"
                                        >
                                          <TableCell key={val.id}>
                                            {val.value}
                                          </TableCell>
                                        </Tooltip>
                                      ) : val.id === 5 ? (
                                        <Tooltip
                                          key={val.id}
                                          title="Unit Price * Quantity"
                                        >
                                          <TableCell key={val.id}>
                                            {val.value}
                                          </TableCell>
                                        </Tooltip>
                                      ) : val.id === 6 ? (
                                        <Tooltip
                                          key={val.id}
                                          title="Markup Cost * Quantity"
                                        >
                                          <TableCell key={val.id}>
                                            {val.value}
                                          </TableCell>
                                        </Tooltip>
                                      ) : (
                                        <TableCell key={val.id}>
                                          {val.value}
                                        </TableCell>
                                      ),
                                    )}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {selectedMaterials?.map((data, index) => {
                                    let price = data.price || 0
                                    let quantity = data.quantity || 1
                                    if ( values.project_categories?.name == 'Time & Material' ) {
                                      data['price'] = 0
                                      data['quantity'] = 0
                                      price = 0
                                      quantity = 0
                                    }
                                    if (values.project_categories?.name == 'Service only' && data?.inhouse == 0 ) {
                                      data['price'] = 0
                                      price = 0
                                    }
                                    let unitName = UNITS?.filter((item) => item?.id == data?.unit,)
                                    unitName = unitName[0]?.name
                                    let shortUnitName = ''
                                    if (unitName == 'Square feet') {
                                      shortUnitName = 'Sq ft'
                                    } else if (unitName == 'Lineal feet') {
                                      shortUnitName = 'Lin ft'
                                    } else if (unitName == 'Square Inches') {
                                      shortUnitName = 'Sq Inches'
                                    } else if (unitName == 'Unit') {
                                      shortUnitName = 'Unit'
                                    }
                                    // const markUpCost = data.price + ((parseFloat(data.price) * parseFloat(data.markup || 0)) / 100)
                                    const markUpCost = price + (price * parseFloat(data.markup || 0)) / 100
                                    data['markUpCost'] = parseFloat(parseFloat(markUpCost).toFixed(2),) || 0

                                    // let newTotalProductFormula = (data.price*(data.quantity||1))?.toFixed(2)
                                    let newTotalProductFormula = (
                                      price * quantity
                                    ).toFixed(2)
                                    let newTotalCost = (
                                      (data.markUpCost || 0) *
                                      (data.quantity || 1)
                                    )?.toFixed(2)

                                    totalProductFormula += Number( newTotalProductFormula, )
                                    totalWholesalePrice += Number(newTotalCost)
                                    totalRetailPrice += Number(newTotalCost)
                                    values['totalProductFormula'] = totalProductFormula
                                    values['totalWholesalePrice'] = totalWholesalePrice
                                    values['totalRetailPrice'] = totalRetailPrice

                                    console.log('datadatadatamaterial',index,data)
                                    return (
                                      <>
                                        <TableRow key={data?.newIndexId || index}>
                                          <TableCell>
                                            {data?.isAdditionalProduct ?
                                              <TextField
                                                required
                                                fullWidth
                                                type="text"
                                                label={'Material Name'}
                                                sx={{ width: 85 }}
                                                value={data.name}
                                                onChange={(event) => {
                                                  let newValue = event.target.value
  
                                                  // Update the selectedProducts state
                                                  setSelectedMaterials(
                                                    (prevProducts) =>
                                                      prevProducts.map((service) => {
                                                          if ( service.newIndexId === data.newIndexId ) {
                                                            return {
                                                              ...service,
                                                              name: newValue,
                                                            }
                                                          } else {
                                                            return service
                                                          }
                                                        },
                                                      ),
                                                  )
                                                  data['name'] = newValue || ''
                                                  if (isSubmitted)
                                                    setIsSubmitted(false)
                                                }}
                                                error={
                                                  isSubmitted && data?.name?.trim() === ''
                                                }
                                                helperText={
                                                  isSubmitted && data?.name?.trim() === '' ? 'Material Name is required' : ''
                                                }
                                              />
                                            : data.name}
                                            {data?.isAdditionalProduct ? '' : data.inhouse==1 ? ' (Inhouse)' : ' (Client owned)'}
                                          </TableCell>
                                          <TableCell>
                                            {data?.isAdditionalProduct ?
                                              <TextField
                                                required
                                                fullWidth
                                                type="number"
                                                label={'Unit Price'}
                                                disabled={values.project_categories?.name == 'Time & Material' ? true : false}
                                                sx={{ width: 85 }}
                                                value={data.price}
                                                // onWheel={(e) => e.target.blur()}
                                                onKeyPress={(event) => {
                                                  // if (event.key === '0' && event.target.value === '') {
                                                  //   event.preventDefault();
                                                  // }
                                                  if (event.key === '-') {
                                                    event.preventDefault();
                                                  }
                                                  if (event.key === 'e') {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                inputProps={{ min: 0 }}
                                                defaultValue={0}
                                                onChange={(event) => {
                                                  let newValue = event.target.value
                                                  if (newValue !== '') {
                                                    setAdditionalSelectedServices((pv) => [
                                                        ...pv.map((p) => {
                                                          if ( p.newIndexId === data.newIndexId ) {
                                                            return {
                                                              ...p,
                                                              price: Number(newValue),
                                                            }
                                                          } else {
                                                            return p
                                                          }
                                                        }),
                                                      ],
                                                    )
                                                    data['price'] =  Number(newValue || 0)
                                                  }
                                                }}
                                                // error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                                // helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                              />
                                            : data.price}
                                          </TableCell>
                                          <TableCell>
                                            <TextField
                                              required
                                              fullWidth
                                              type="number"
                                              label={'Markup'}
                                              value={Number(data.markup) || 0}
                                              defaultValue={0}
                                              onKeyPress={(event) => {
                                                // if (event.key === '0' && event.target.value === '') {
                                                //   event.preventDefault();
                                                // }
                                                if (event.key === '-') {
                                                  event.preventDefault()
                                                }
                                                if (event.key === 'e') {
                                                  event.preventDefault()
                                                }
                                              }}
                                              onChange={(event) => {
                                                let newValue =
                                                  event.target.value

                                                setSelectedMaterials((pv) =>
                                                  pv.map((p) =>
                                                    p.newIndexId === data.newIndexId
                                                      ? {
                                                          ...p,
                                                          markup:
                                                            newValue === ''
                                                              ? ''
                                                              : Number(
                                                                  newValue,
                                                                ), // Allow empty string temporarily
                                                        }
                                                      : p,
                                                  ),
                                                )

                                                if (
                                                  newValue == null ||
                                                  newValue == '' ||
                                                  /^0[0-9]+$/.test(newValue)
                                                ) {
                                                  console.log(
                                                    'newValuenewValue 0',
                                                    newValue,
                                                  )

                                                  setSelectedMaterials((pv) => [
                                                    ...pv.map((p) => {
                                                      if (p.id === data.id) {
                                                        return {
                                                          ...p,
                                                          markup: '0',
                                                        }
                                                      } else {
                                                        return p
                                                      }
                                                    }),
                                                  ])
                                                  newValue = 0
                                                  console.log(
                                                    'newValuenewValue data',
                                                    selectedMaterials,
                                                  )
                                                  return selectedMaterials
                                                } else if (newValue !== '') {
                                                  setSelectedMaterials((pv) => [
                                                    ...pv.map((p) => {
                                                      if (p.newIndexId === data.newIndexId) {
                                                        return {
                                                          ...p,
                                                          markup:
                                                            Number(newValue),
                                                        }
                                                      } else {
                                                        return p
                                                      }
                                                    }),
                                                  ])
                                                  if (
                                                    newValue === '' ||
                                                    newValue < 0
                                                  ) {
                                                    event.preventDefault()
                                                    return
                                                  }
                                                  if (
                                                    newValue.startsWith('-')
                                                  ) {
                                                    newValue = newValue.slice(1)
                                                  }
                                                  data['markup'] = newValue || 0
                                                }
                                              }}
                                              inputProps={{ min: 0 }}
                                            />
                                          </TableCell>
                                          <TableCell>
                                            {data.markUpCost || 0}
                                          </TableCell>
                                          <TableCell>
                                            <TextField
                                              required
                                              fullWidth
                                              type="number"
                                              label={
                                                shortUnitName || 'Quantity'
                                              }
                                              disabled={
                                                values.project_categories
                                                  ?.name == 'Time & Material'
                                                  ? true
                                                  : false
                                              }
                                              value={
                                                values.project_categories
                                                  ?.name == 'Time & Material'
                                                  ? 0
                                                  : Number(data.quantity) || 1
                                              }
                                              defaultValue={
                                                values.project_categories
                                                  ?.name == 'Time & Material'
                                                  ? 0
                                                  : 1
                                              }
                                              onKeyPress={(event) => {
                                                if (
                                                  event.key === '0' &&
                                                  event.target.value === ''
                                                ) {
                                                  event.preventDefault()
                                                }
                                                if (event.key === '-') {
                                                  event.preventDefault()
                                                }
                                                // if (event.key === '.') {
                                                //   event.preventDefault();
                                                // }
                                                if (event.key === 'e') {
                                                  event.preventDefault()
                                                }
                                              }}
                                              onChange={(event) => {
                                                let newValue =
                                                  event.target.value
                                                if (
                                                  newValue !== '0' &&
                                                  newValue !== ''
                                                ) {
                                                  setSelectedMaterials((pv) => [
                                                    ...pv.map((p) => {
                                                      console.log(
                                                        '################p.id',
                                                        p.id,
                                                      )
                                                      console.log(
                                                        '################data.id',
                                                        data.id,
                                                      )

                                                      if (p.newIndexId === data.newIndexId) {
                                                        return {
                                                          ...p,
                                                          quantity:
                                                            Number(newValue),
                                                        }
                                                      } else {
                                                        return p
                                                      }
                                                    }),
                                                  ])

                                                  if (
                                                    newValue === '' ||
                                                    newValue <= 0
                                                  ) {
                                                    event.preventDefault()
                                                    return
                                                  }
                                                  if (
                                                    newValue.startsWith('-')
                                                  ) {
                                                    newValue = newValue.slice(1)
                                                  }
                                                  data['quantity'] =
                                                    newValue || 1
                                                }
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell>
                                            {(
                                              data.price * (data.quantity || 1)
                                            )?.toFixed(2)}
                                          </TableCell>
                                          <TableCell>
                                            {(
                                              (data.markUpCost || 0) *
                                              (data.quantity || 1)
                                            )?.toFixed(2)}
                                          </TableCell>
                                          <TableCell>
                                            <IconButton
                                              onClick={() =>
                                                handleDeleteMaterial(data.id, data.newIndexId,)
                                              }
                                            >
                                              <Delete />
                                            </IconButton>
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    )
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Scrollbar>
                          {
                            showAddNewRow ?
                          <Stack sx={{ justifyContent: 'space-between', alignItems: 'center', display:'flex', flexDirection:'row' }}>
                            <Button
                              variant="outlined"
                              startIcon={
                                <Stack>
                                  {' '}
                                  <Add />{' '}
                                </Stack>
                              }
                              onClick={() => {
                                addNewMaterialRow()
                                // setMarkupType(1)
                              }}
                            >
                              Add Material Row
                            </Button>
                            <Typography variant="h6">
                              Total Additional Material Cost:{' '}
                              {selectedMaterialSubTotal}
                            </Typography>
                          </Stack>
                          :""}
                        </>
                      {/* )} */}

                      <Divider />
                      <Divider />
                      <LabelStyle>Notes</LabelStyle>
                      <TextField
                        fullWidth
                        multiline
                        minRows={3}
                        maxRows={7}
                        label="Notes"
                        {...getFieldProps('notes')}
                        error={Boolean(touched.notes && errors.notes)}
                        helperText={touched.notes && errors.notes}
                      />
                      {/* </>} */}
                      {/* <LabelStyle>Extra Discount</LabelStyle> */}
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 3, sm: 2 }}
                      >
                        <TextField
                          // sx={{ width: 400 }}
                          fullWidth
                          type="number"
                          onKeyPress={(event) => {
                            if (event.key === '-') {
                              event.preventDefault()
                            }
                          }}
                          onChange={(event) => {
                            let newValue = event.target.value
                            let data = 0
                            if (markupType === 1) {
                              data = totalWholesalePrice - totalJobcostSum
                            } else if (markupType === 2) {
                              data = totalRetailPrice - totalJobcostSum
                            }
                            console.log('extradiscount--totalsum--', totalsum)
                            console.log(
                              'extradiscount--totalWholesalePrice--',
                              totalWholesalePrice,
                            )
                            console.log(
                              'extradiscount--totalRetailPrice--',
                              totalRetailPrice,
                            )
                            console.log(
                              'extradiscount--DiscountTotal--',
                              DiscountTotal,
                            )
                            console.log(
                              'extradiscount--totalJobcostSum--',
                              totalJobcostSum,
                            )
                            console.log('extradiscount--newValue--', newValue)
                            console.log('extradiscount--data--', data)
                            // if (markupType === 1) {
                            //   data = (totalWholesalePrice - DiscountTotal) - totalProductFormula
                            // } else if (markupType === 2) {
                            //   data = (totalRetailPrice - DiscountTotal) - totalProductFormula
                            // } else {
                            //   data = 0
                            // }
                            if (Number(newValue) > data) {
                              newValue = data?.toFixed(2)
                            }
                            if (newValue === '-0.00') {
                              newValue = 0
                            }
                            setFieldValue('invoice_discount', newValue)
                          }}
                          label={
                            hasDiscountPermission
                              ? 'Discount (Access Denied)'
                              : 'Discount'
                          }
                          disabled={hasDiscountPermission}
                          value={values.invoice_discount}
                          // {...getFieldProps('invoice_discount')}
                          error={Boolean(
                            touched.invoice_discount && errors.invoice_discount,
                          )}
                          helperText={
                            touched.invoice_discount && errors.invoice_discount
                          }
                          inputProps={{
                            max:
                              markupType === 1
                                ? totalWholesalePrice - totalJobcostSum
                                : markupType === 2
                                ? totalRetailPrice - totalJobcostSum
                                : 0,
                            min: 0,
                          }}
                        />
                        <Autocomplete
                          // sx={{ width: 400 }}
                          fullWidth
                          id="combo-box-demo"
                          options={taxCategories || []}
                          disabled={true}
                          value={selectedTaxCategory || null}
                          onChange={(event, newValue) => {
                            setSelectedTaxCategory(newValue)
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Tax category" />
                          )}
                          getOptionLabel={(option) =>
                            option.name ? option.name : ''
                          }
                        />
                      </Stack>
                      <Typography sx={{ color: theme.palette.error.main }}>
                        **All values in the software are rounded to two decimal
                        places in accordance with standard US calculation
                        practices.
                      </Typography>
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 3, sm: 2 }}
                      >
                        <Stack>
                          {/* <Typography sx={{ color: 'text.secondary' }}>Sub Total: {RFQSubTotal ? RFQSubTotal?.toFixed(2) : 0}</Typography> */}
                          {/* <Typography sx={{ color: 'text.secondary' }}>Service cost in Product Formula: {InitialServiceCostInProductFormula ? InitialServiceCostInProductFormula?.toFixed(2) : 0}</Typography> */}
                          {/* <Typography sx={{ color: 'text.secondary' }}>Total ({RFQSubTotal?.toFixed(2)} - {InitialServiceCostInProductFormula?.toFixed(2)}): {totalsum ? totalsum?.toFixed(2) : 0}</Typography> */}
                          <Typography sx={{ color: 'text.secondary' }}>
                            Sub Total: {totalsum ? totalsum.toFixed(2) : '0.00'}
                          </Typography>
                          <Typography sx={{ color: 'text.secondary' }}>
                            Tax: {parseFloat(taxAmount).toFixed(2) || 0.0}
                          </Typography>
                          {noCharge ? (
                            <Typography sx={{ color: 'red' }}>
                              Grand Total:{' '}
                              {grandTotal
                                ? parseFloat(grandTotal)?.toFixed(2)
                                : 0.0}
                            </Typography>
                          ) : (
                            <Typography sx={{ color: 'yellow' }}>
                              Grand Total:{' '}
                              {grandTotal
                                ? parseFloat(grandTotal)?.toFixed(2)
                                : 0.0}
                            </Typography>
                          )}
                          {/* <Typography sx={{ color: 'yellow' }}>
                    Grand Total: {grandTotal ? parseFloat(grandTotal)?.toFixed(2) : 0.00}
                  </Typography> */}
                          {/* <Typography sx={{ color: 'yellow' }}>Grand Total : {totalsum ? totalsum?.toFixed(2) : 0.00}</Typography> */}
                          <Typography sx={{ color: 'yellow' }}>
                            Total Product Formula Cost:{' '}
                            {totalJobcostSum ? totalJobcostSum?.toFixed(2) : 0}
                          </Typography>
                          {/* <Typography sx={{ color: 'text.secondary' }}>Total: {totalsum < 0 ? 0 : totalsum?.toFixed(2)}</Typography> */}
                          <Typography sx={{ color: 'text.secondary' }}>
                            Total Discount:
                            {DiscountTotal ? DiscountTotal?.toFixed(2) : 0.0}
                          </Typography>

                          <Tooltip title="Sub Total - Total Product Formula Cost">
                            {/* <Typography sx={{ color: 'text.secondary', cursor: 'pointer' }}>Total Markup Amount:{markupType == 1 ? ((totalWholesalePrice - DiscountTotal) - totalProductFormula)?.toFixed(2) : ((totalRetailPrice - DiscountTotal) - totalProductFormula)?.toFixed(2)}</Typography> */}
                            <Typography
                              sx={{
                                color: 'text.secondary',
                                cursor: 'pointer',
                              }}
                            >
                              Total Markup Amount:
                              {(totalsum - totalJobcostSum)?.toFixed(2)}
                            </Typography>
                          </Tooltip>

                          <Tooltip title="(Total Markup Amount / Total Product Formula Cost) * 100">
                            <Typography
                              sx={{
                                color: 'text.secondary',
                                cursor: 'pointer',
                              }}
                            >
                              Total Markup Percentage:
                              {totalJobcostSum !== 0 && totalsum !== 0
                                ? (
                                    ((totalsum - totalJobcostSum) /
                                      totalJobcostSum) *
                                    100
                                  ).toFixed(2)
                                : '0.00'}
                            </Typography>
                            {/* <Typography sx={{ color: 'text.secondary', cursor: 'pointer' }}>
                      Total Markup Percentage:
                      {markupType === 1
                        ? (totalProductFormula !== 0
                          ? ((((totalWholesalePrice - DiscountTotal) - totalProductFormula) / totalProductFormula) * 100).toFixed(2)
                          : '0.00')
                        : markupType === 2
                          ? (totalProductFormula !== 0
                            ? ((((totalRetailPrice - DiscountTotal) - totalProductFormula) / totalProductFormula) * 100).toFixed(2)
                            : '0.00')
                          : '0.00'}
                    </Typography> */}
                          </Tooltip>

                          {/* <Typography sx={{ color: 'text.secondary' }}>Total Product Markup:{(originaltotalMarkup / selectedProducts.length)?.toFixed(2)}</Typography> */}
                          {/* <Typography sx={{ color: 'text.secondary' }}>Total Service Markup:{(originaltotalServiceMarkup / (selectedServices.length + additionalSelectedServices.length))?.toFixed(2)}</Typography> */}
                        </Stack>
                        <Stack direction={'column'} display={'flex'} justifyContent={'center'}>
                        <FormControlLabel
                          control={
                            <Switch
                              {...getFieldProps('welcome_message')}
                              color="primary"
                              checked={values.welcome_message}
                            />
                          }
                          labelPlacement="end"
                          label="Use Custom Welcome Message"
                          sx={{ mt: 6 ,mb:2 }}
                        />
                        {
                          noCharge ? ""
                            :
                        
                        <FormControlLabel
                          control={
                            <Switch
                            {...getFieldProps('client_approve')}
                              color="primary"
                              checked={values.client_approve}
                              onChange={(event) => setFieldValue('client_approve', event.target.checked)}
                            />
                          }
                          labelPlacement="end"
                          label="Enable for Client Approval"
                          sx={{ mt: 0 }}
                        />
}
                        </Stack>
                      </Stack>
                      {values.welcome_message == true && (
                        <>
                          <Stack>
                            <LabelStyle>Subject</LabelStyle>
                            <TextField
                              //   fullWidth
                              type="text"
                              sx={{ width: 400 }}
                              {...getFieldProps('subject')}
                              error={Boolean(touched.subject && errors.subject)}
                              helperText={touched.subject && errors.subject}
                            />
                          </Stack>
                          <LabelStyle>Email Content</LabelStyle>
                          <TextField
                            fullWidth
                            multiline
                            minRows={3}
                            maxRows={7}
                            label="Email Content"
                            {...getFieldProps('email_content')}
                            error={Boolean(
                              touched.email_content && errors.email_content,
                            )}
                            helperText={
                              touched.email_content && errors.email_content
                            }
                          />
                        </>
                      )}
                      <LabelStyle>Request state</LabelStyle>
                      <Typography>
                        While in draft mode, clients you add to request tasks
                        won't be notified
                      </Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            {...getFieldProps('draft')}
                            color="primary"
                            checked={values.draft}
                          />
                        }
                        labelPlacement="end"
                        label="Draft"
                        sx={{ mt: 5 }}
                      />
                    </>
                  )}

                <Box
                  sx={{
                    mt: 3,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 1,
                  }}
                >
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Save Order
                  </LoadingButton>
                  {userType !== 3 &&
                    explodedPermissions?.some((permission) =>
                      ['Create Request'].includes(permission),
                    ) && (
                      <Button
                        variant="outlined"
                        onClick={() => setOpenPreview(!openPreview)}
                      >
                        Preview
                      </Button>
                    )}
                  <Button variant="outlined" onClick={() => handleCancel()}>
                    Cancel
                  </Button>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
}
