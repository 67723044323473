import { useEffect, useRef, useState } from 'react'
import {
    Card,
    Typography,
    Stack,
    ImageList,
    ImageListItem,
    ListSubheader,
    ImageListItemBar,
    Box,
    Button,
    Divider,
    Avatar,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableBody,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ThumbUpAltOutlined, ThumbDownOffAltOutlined, LocationOn, EmojiEvents, KeyboardBackspaceOutlined } from '@material-ui/icons'
import fileCompare from '@iconify/icons-mdi/file-compare';
import { sentenceCase } from 'change-case'
import moment from 'moment'
import Scrollbar from 'src/components/Scrollbar'
import {
    ref as imageref,
    uploadBytesResumable,
    getDownloadURL,
} from '@firebase/storage'
import { storage } from 'src/firebase/firebase'
import { REST_API_END_POINT } from 'src/constants/Defaultvalues'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import { LoadingButton } from '@material-ui/lab'
import { UserMoreMenu } from '../user/list'
import Label from 'src/components/Label'
import Icon from '@iconify/react'
import AddProposal from './AddProposal'
import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import ConfirmationPopup from 'src/components/confirmationPopup'
import { useNavigate } from 'react-router'
import AddClients from "src/components/_dashboard/official-request/AddClients";
import ConfirmationPopupWithPaymentType from 'src/components/confirmationPopupWithPaymentType'
export default function Proposals({ proposalData, setReloadData, acceptedClients, handleEditClick, setVendorId,fetchAllClients,allClients,id,setAllClients,handleDataChange }) {
    const theme = useTheme()
    const { enqueueSnackbar } = useSnackbar()
    const [isCompare, setIsCompare] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [editData, setEditData] = useState(null);
    const [awardingData, setAwardingData] = useState(null)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [awaitingVendor, setAwaitingVendor] = useState([])
  const [loading2, setLoading2] = useState(false)
  const [paymentType,setPaymentType]= useState('')


    let user = JSON.parse(localStorage.getItem('user'))
    const explodedPermissions = user?.exploded_permissions
    const userType = user.user_type
    const userId = user.user_id
    const [addClient, setAddClient] = useState(false)
      const [open, setIsOpen] = useState(false)

      const handlePaymentTypeChange =(newValue)=>{
        if(newValue){
            setPaymentType(newValue.name)
        }else{
            setPaymentType(null)

        }
        console.log("dataaaaaaaaaaaaa",newValue)
      }

    useEffect(() => {
        fetchAllClients()
      }, [addClient])
 
    const handleAwardProposalPayment = async (proposalId, requestId, name, vendorId) => {
        try {
            
   if(!paymentType){
   return enqueueSnackbar(`Select Payment Type`, { variant: 'error' });
   }
    setLoading2(true); 

        const response = await axios.post(`${REST_API_END_POINT}rfq/update-proposal-status-payment`, { id: proposalId, status: 4, requestId: requestId, userId: userId, vendorId: vendorId,paymentType:paymentType })
        // console.log("setAwardedProposal - ",response.data)
        if (response.data.status === 1) {
            setReloadData(response.data);
            enqueueSnackbar(`Payment done for proposal awarded to ${name}`, { variant: 'success' });
            handleDataChange()
        }
        else {
            enqueueSnackbar(`Failed to award proposal`, { variant: 'error' });
        }
        setAwardingData(null)
        setOpenConfirm(false)
    setLoading2(false);

    } catch (error) {
            console.log("error",error)
            setLoading2(false);

    }
        
    };
        const handleAwardProposal = async (proposalId, requestId, name, vendorId) => {
            try {
        setLoading2(true); 
    
            const response = await axios.post(`${REST_API_END_POINT}rfq/update-proposal-status`, { id: proposalId, status: 2, requestId: requestId, userId: userId, vendorId: vendorId })
            // console.log("setAwardedProposal - ",response.data)
            if (response.data.status === 1) {
                setReloadData(response.data);
                enqueueSnackbar(`Proposal awarded to ${name}`, { variant: 'success' });
                handleDataChange()
            }
            else {
                enqueueSnackbar(`Failed to award proposal`, { variant: 'error' });
            }
            setAwardingData(null)
            setOpenConfirm(false)
        setLoading2(false);
    
        } catch (error) {
                console.log("error",error)
                setLoading2(false);
    
        }
    }
    const handleAwardPayment = (data) => {
        setOpenConfirm(true)
        setAwardingData(data)
    }
    useEffect(() => {
        const filteredVendor = acceptedClients?.filter(client => {
            return (
                client.isVendor === 1 &&
                !proposalData?.some(proposal => proposal.vendor_id === client.user_id)
            );
        });

        console.log("proposalData-----", proposalData);
        console.log("acceptedClients-----", acceptedClients);
        console.log("filteredVendor----", filteredVendor);
        setAwaitingVendor(filteredVendor);
    }, [acceptedClients, proposalData])

    const filteredProposalData = proposalData ? proposalData : []
    const navigate = useNavigate()
    const handleDelete = async (itemId) => {
        try {


            let res = await axios.post(`${REST_API_END_POINT}rfq/delete-proposal`, { id: itemId })
            if (res.data.status == 1) {
                setReloadData('true')
                enqueueSnackbar(res.data.message, { variant: 'success' })
            } else {
                enqueueSnackbar(res.data.message, {
                    variant: 'error',
                })
            }
        } catch (error) {
            console.error(error)
        }
    }
    const convertRFQ = async (item) => {
        try {


            let res = await axios.post(`${REST_API_END_POINT}rfq/convert-to-rfq`, { id: item.id, request_id: item.request_id, userId: item.vendor_id })
            if (res.data.status == 1) {
                setReloadData('true')
                enqueueSnackbar(res.data.message, { variant: 'success' })
            } else {
                enqueueSnackbar(res.data.message, {
                    variant: 'error',
                })
            }
        } catch (error) {
            console.error(error)
        }
    }
    const HandleClickmenu = () => {
        setAddClient(true)
      }
    const viewDetails = (vendorId) => {
        // console.log("############Id",id);
        console.log(`View Details clicked`, vendorId);
        setVendorId(vendorId)
        handleEditClick()
        //  navigate(`/dashboard/rfp/view/${id}`,{state:true})
        // Add your desired functionality here, e.g., navigating to another page or opening a dialog
    };
    return (
        <>
        <Card>
            {!isCompare ? (
                <Stack sx={{ m: 3 }} spacing={3}>
                    {(filteredProposalData?.length > 0) ?
                        <>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                spacing={{ xs: 2, sm: 3 }}
                            >
                                <Typography variant="h4" sx={{ color: 'text.secondary' }}>{user.isVendor ? 'Proposal' : 'Proposals'}</Typography>
                                {(filteredProposalData?.length > 1 && userType === 1) && <Button variant='contained'
                                    onClick={() => { setIsCompare(true) }}>
                                    <Icon icon={fileCompare} width={24} height={24} />
                                    Compare</Button>}
                            </Stack>
                            <Box sx={{ px: 3 }}>
                                <Scrollbar sx={{ width: '100%', height: 450 }}>
                                    <Stack spacing={2}>
                                        {filteredProposalData?.map((item, index) => (
                                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', border: '1px solid', borderColor: item.status === 2 ? theme.palette.success.main : item.status === 3 ? theme.palette.error.main : 'divider', borderRadius: '8px', p: 3 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                                    <Box sx={{ width: '100%' }}>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, width: '100%' }}>
                                                            <Avatar sx={{ mr: 2 }} alt={item.vendor_name} src={item.profile_pic} />
                                                            <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
                                                                {item.vendor_name}
                                                            </Typography>
                                                        </Box>
                                                        <Divider sx={{ mb: 2, width: '90%' }} />
                                                        <Typography variant="body2" fontWeight="bold" sx={{ mt: 1 }}>
                                                            {item.title}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            {item.description}
                                                        </Typography>
                                                        <Typography variant="body2" sx={{ mt: 1 }}>
                                                            {item.note}
                                                        </Typography>
                                                        <Stack direction={'row'} spacing={2} alignItems="center" sx={{ mt: 1 }}>
                                                            <Typography variant="body2">
                                                                ${item.budget}
                                                            </Typography>
                                                            <Typography variant="body2">
                                                                {' | '}
                                                            </Typography>
                                                            <Typography variant="body2" onClick={() => viewDetails(item.vendor_id)} style={{ cursor: 'pointer' }}>
                                                                View Details 
                                                            </Typography>
                    
                                                            {/* <Typography variant="body2">
                                                {item?.attachment_type==='image' ?
                                                <img
                                                    src={item?.attachment_url}
                                                    alt={item?.vendor_name}
                                                    style={{
                                                        width: '70px',
                                                        height: '50px',
                                                        borderRadius: '5px',
                                                        marginRight: '10px',
                                                        objectFit : 'fill'
                                                    }}  
                                                />
                                                :item?.attachment_type==='video'?
                                                <video style={{ width: '60%',
                                                height: '60%', 
                                                 objectFit: 'cover', }} controls>
                                               <source src={item?.attachment_url} type="video/mp4" />
                                             Your browser does not support the video tag.
                                             </video>
                                             :item?.attachment_type==='other'?
                                             <embed src={item?.attachment_url}
                                             type="application/pdf"
                                             style={{  width: '200px',
                                                height: '200px',}} />
                                                 : 'No Attachments'}
                                            </Typography> */}
                                                        </Stack>
                                                        <Stack direction={'row'} spacing={2} alignItems="center" sx={{ mt: 1 }}>
                                                     {
                                                        item.status === 4 && item?.paymentMode ?

                                  
                                                        <Button variant="outlined"  style={{ cursor: 'pointer' }}>
                                                            { `Payment Type : ${item?.paymentMode?item?.paymentMode :''}` }
                                                            </Button>             
                                                       :item.status === 2 ?

                                  
                                                       <Button variant="outlined"  style={{ cursor: 'pointer' }} onClick={()=>handleAwardPayment(item)}>
                                                           Make Payment
                                                           </Button> :''}
                                                        </Stack>


                                                    </Box>
                                                    {/* {item.status === 2 ? userType === 1 &&
                                                         <Button
                                                                variant="outlined"


                                                                // onClick={() => handleAwardProposal(item.id,item.request_id,item.vendor_name,item.vendor_id)}
                                                                onClick={() => convertRFQ(item)}
                                                                sx={{ ml: 2,mr:2,mt:2 }}
                                                            >
                                                              Convert to RFQ
                                                            </Button>
                                                        <Button
                                                            size="small"
                                                            variant="contained"
                                                            // disabled={(explodedPermissions?.some(permission => ['Add client to request'].includes(permission))) ? false : true}
                                                            onClick={HandleClickmenu}
                                                            sx={{ height: 34 }}
                                                            // startIcon={<Icon icon={plusFill} />}
                                                        >
                                                            Add Client and convert to RFQ
                                                        </Button>
                                                        : ""} */}
                                                    {item.status === 1 ?
                                                        userType === 1 && <Button
                                                            variant="outlined"
                                                            endIcon={<EmojiEvents />}

                                                            // onClick={() => handleAwardProposal(item.id,item.request_id,item.vendor_name,item.vendor_id)}
                                                            onClick={() => handleAwardProposal(item?.id,item?.request_id,item?.vendor_name,item?.vendor_id)}
                                                           
                                                            sx={{ ml: 2 }}
                                                        >
                                                            Award
                                                        </Button>
                                                        :
                                                        <Typography variant="body2" sx={{ color: item.status === 2 ? theme.palette.success.main : item.status === 3 && theme.palette.error.main }}>
                                                            {item.status === 2 || item.status === 4 ? "Awarded" : (item.status === 3 && "Rejected")}
                                                        </Typography>

                                                    }
                                                    {/* {user.isVendor && item.status === 1 &&
                                                        <UserMoreMenu
                                                            onEdit={() => {
                                                                if (explodedPermissions?.some(permission => ['Edit Request Proposal'].includes(permission))) {
                                                                    setOpenEdit(true)
                                                                    setEditData(item)
                                                                } else {
                                                                    enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                                                                }
                                                            }}
                                                            onDelete={() => {
                                                                if (explodedPermissions?.some(permission => ['Delete Request Proposal'].includes(permission))) {
                                                                    handleDelete(item.id)
                                                                } else {
                                                                    enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                                                                }
                                                            }}
                                                        />} */}
                                                </Box>
                                            </Box>
                                        ))}
                                    </Stack>
                                </Scrollbar>
                            </Box>
                            <ConfirmationPopupWithPaymentType
                                open={openConfirm}
                                handleClick={() => handleAwardProposalPayment(awardingData?.id, awardingData?.request_id, awardingData?.vendor_name, awardingData?.vendor_id)}
                                handleClose={() => setOpenConfirm(false)}
                                message={'You want to mark as this paid'}
                                loading2={loading2}
                                handlePaymentTypeChange={handlePaymentTypeChange}
                            />
                             {/* old */}
                            {/* <ConfirmationPopup
                                open={openConfirm}
                                handleClick={() => handleAwardProposal(awardingData?.id, awardingData?.request_id, awardingData?.vendor_name, awardingData?.vendor_id)}
                                handleClose={() => setOpenConfirm(false)}
                                message={'You want to award Proposal'}
                                loading2={loading2}
                            /> */}
                            <AddProposal
                                request_id={editData?.request_id}
                                editData={editData}
                                setReloadData={setReloadData}
                                // setIsOpen={setIsOpen}
                                addProposal={openEdit}
                                setAddProposal={setOpenEdit} />
                        </>
                        :
                        <Stack alignItems={'center'} justifyContent={'center'} spacing={{ xs: 2, sm: 3 }}>
                            <Typography variant="h4" sx={{ color: 'text.secondary' }}>No Proposals Available</Typography>
                        </Stack>
                    }

                </Stack>
            ) : (
                <Stack sx={{ m: 3 }} spacing={3}>
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        spacing={{ xs: 2, sm: 3 }}
                    >
                        <Typography variant="h4">Compare Proposals</Typography>
                        <Button variant='outlined' onClick={() => { setIsCompare(false) }}
                            startIcon={
                                <Stack>
                                    <KeyboardBackspaceOutlined />
                                </Stack>
                            }>Back to All Proposals</Button>
                    </Stack>
                    <Box sx={{ mt: 4 }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650, alignItems: 'center', justifyContent: 'center' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ border: '1px solid', borderColor: 'divider', color: theme.palette.primary.main, textAlign: 'center' }}>Vendor Name</TableCell>
                                        {filteredProposalData?.map((proposal, index) => (
                                            <TableCell key={index} sx={{ border: '1px solid', borderColor: 'divider', textAlign: 'center' }}>{proposal?.vendor_name}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ border: '1px solid', borderColor: 'divider', color: theme.palette.primary.main, textAlign: 'center' }}>Contact No</TableCell>
                                        {filteredProposalData?.map((proposal, index) => (
                                            <TableCell key={index} sx={{ border: '1px solid', borderColor: 'divider', textAlign: 'center' }}>{proposal?.phone}</TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ border: '1px solid', borderColor: 'divider', color: theme.palette.primary.main, textAlign: 'center' }}>Email ID</TableCell>
                                        {filteredProposalData?.map((proposal, index) => (
                                            <TableCell key={index} sx={{ border: '1px solid', borderColor: 'divider', textAlign: 'center' }}>{proposal?.email}</TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ border: '1px solid', borderColor: 'divider', color: theme.palette.primary.main, textAlign: 'center' }}>Title</TableCell>
                                        {filteredProposalData?.map((proposal, index) => (
                                            <TableCell key={index} sx={{ border: '1px solid', borderColor: 'divider', textAlign: 'center' }}>{proposal?.title}</TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ border: '1px solid', borderColor: 'divider', color: theme.palette.primary.main, textAlign: 'center' }}>Description</TableCell>
                                        {filteredProposalData?.map((proposal, index) => (
                                            <TableCell key={index} sx={{ border: '1px solid', borderColor: 'divider', textAlign: 'center' }}>{proposal?.description}</TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ border: '1px solid', borderColor: 'divider', color: theme.palette.primary.main, textAlign: 'center' }}>Proposed Budget</TableCell>
                                        {filteredProposalData?.map((proposal, index) => (
                                            <TableCell key={index} sx={{ border: '1px solid', borderColor: 'divider', textAlign: 'center' }}>${proposal?.budget}</TableCell>
                                        ))}
                                    </TableRow>
                                    {/* <TableRow>
                                        <TableCell sx={{ border: '1px solid', borderColor: 'divider', color: theme.palette.primary.main, textAlign: 'center' }}>Attachments</TableCell>
                                        {filteredProposalData?.map((proposal, index) => (
                                            <TableCell key={index} sx={{ border: '1px solid', borderColor: 'divider', textAlign: 'center' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                                    {proposal?.attachment_type === 'image' ? (
                                                        <img
                                                            src={proposal?.attachment_url}
                                                            alt={proposal?.attachment_url}
                                                            style={{
                                                                width: '70px',
                                                                height: '50px',
                                                                borderRadius: '5px',
                                                                objectFit: 'fill'
                                                            }}
                                                        />
                                                    ) : proposal?.attachment_type === 'video' ? (
                                                        <video style={{
                                                            width: '50%',
                                                            height: '100%',
                                                            objectFit: 'cover',
                                                        }} controls>
                                                            <source src={proposal?.attachment_url} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    ) : proposal?.attachment_type === 'other' ? (
                                                        <embed src={proposal?.attachment_url}
                                                            type="application/pdf"
                                                            style={{
                                                                width: '150px',
                                                                height: '150px',
                                                            }} />
                                                    ) : 'No Attachment'}
                                                </div>
                                            </TableCell>
                                        ))}
                                    </TableRow> */}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Stack>
            )}

            {(awaitingVendor && userType === 1) &&
                <Stack sx={{ m: 3 }} spacing={3}>
                    <Divider />
                    {(filteredProposalData?.length > 0) ?
                        <>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                spacing={{ xs: 2, sm: 3 }}
                            >
                                <Typography variant="h4" sx={{ color: 'text.secondary' }}>Awaiting Vendors</Typography>
                            </Stack>
                            <Box sx={{ px: 3 }}>
                                <Scrollbar sx={{ width: '100%', height: 200 }}>
                                    <List>
                                        {awaitingVendor.map((vendor, index) => (
                                            <div key={index}>
                                                <ListItem alignItems="center">
                                                    <ListItemAvatar>
                                                        <Avatar alt={vendor.name} src={vendor.profile_pic} />
                                                    </ListItemAvatar>
                                                    <ListItemText primary={vendor.name} />
                                                </ListItem>
                                            </div>
                                        ))}
                                    </List>
                                </Scrollbar>
                            </Box>
                        </>
                        : null}

                </Stack>}
        </Card>
        <AddClients
            request_id={id}
            setIsOpen={setIsOpen}
            setAllClients={setAllClients}
            allClients={allClients?.filter(a => !a.isVendor)}
            addClient={addClient}
            setAddClient={setAddClient} 
            proposal={1}
            convertRFQ={convertRFQ}
            />
        </>
    )
}