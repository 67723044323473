import { Autocomplete, Avatar, Box, Button, Checkbox, Grid, TextField } from "@material-ui/core"
import Modal from "../blog/ProjectModel"
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from "@material-ui/lab";
import { REST_API } from "src/constants/Defaultvalues";
import axios from "axios";
import { useSnackbar } from "notistack";

export default function AddClients ({
  isVendor,allClients,setAddClient,addClient,setIsOpen,request_id,setAllClients,proposal,convertRFQ
}){
  const { enqueueSnackbar } = useSnackbar();
    const NewUserSchema = Yup.object().shape({
       clients:Yup.mixed().required('Client is required'),
      });
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
          
          clients:[],
        },
        validationSchema: NewUserSchema,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
          try {
            request_id

            let user = JSON.parse(localStorage.getItem('user'))
        const userType=user.user_type
        const userId=user.user_id
        const userName = user.username
                
               const data = { values, userId, userType ,request_id,userName}
               if(!empty(proposal)){
                let res =  await axios.post(`${REST_API}webservice/request/add-clients`, data)
                res.data
              if(res.data.status ==1){
                enqueueSnackbar(`${isVendor ? 'Vendors' : 'Clients'} added successfully` , { variant: 'success' });
                resetForm()
                setIsOpen(false)
              }else{
                enqueueSnackbar(`Failed to add ${isVendor ? 'Vendors' : 'Clients'}` , { variant: 'error' });
                setIsOpen(false)
              }
               }
             else{
              convertRFQ(values)
             }
          } catch (error) {
            console.error(error);
    
          }
        }
      });
    const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;
    return(
        <Modal modalTitle={'Add people to this request'} open={addClient == true} handleClose={() => {formik.resetForm(),setAddClient(false),setIsOpen(false)}}>
           <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid item xs={12} md={8}>
            <Autocomplete
              fullWidth
              multiple
              id="clients"
              options={allClients?allClients:[]}
              disableCloseOnSelect
              value={values.clients}
               onChange={(event, newValue) =>{console.log(newValue), setFieldValue('clients', newValue)}}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    sx={{ width: '24px', height: '24px' }}
                    checked={selected}
                  />
                  <Avatar src="img" alt={option.name} />
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label={isVendor ? 'Search Vendors' : 'Search Clients'} placeholder={isVendor ? 'Search Vendors' : 'Search Clients'} />
              )}
            />
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}  onClick={() => setAddClient(false)}>
                Add
                </LoadingButton>
              <Button variant="outlined" onClick={() => {formik.resetForm(), setAddClient(false) ,setIsOpen(false)}}>Close</Button>
            </Box>
          </Grid>

        </Grid>
        </Form>
        </FormikProvider>
      </Modal>
    )
}