import faker from 'faker';
import { sum } from 'lodash';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Card,
  Table,
  Divider,
  TableRow,
  Container,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
  Stack,
  Button,
  IconButton,
  TextField,
  LinearProgress,
  linearProgressClasses,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
  FormControl,
  Tabs,
  Tab,
  Autocomplete,
  Tooltip
} from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react'
// routes
import { PATH_DASHBOARD } from 'src/routes/paths.js';
import { useLocation, useNavigate, useParams } from 'react-router'
import { Add, AddCircle, KeyboardBackspaceOutlined } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom'
// utils
import { fCurrency } from 'src/utils/formatNumber.js';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import searchFill from '@iconify/icons-eva/search-fill';

// components
import Page from 'src/components/Page.js';
import * as Yup from 'yup';
import Label from 'src/components/Label.js';
import Scrollbar from 'src/components/Scrollbar.js';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs.js';
import InvoiceToolbar from 'src/components/_dashboard/e-commerce/invoice/InvoiceToolbar.js';
import JobCostToolbar from './JobCostToolbar';
import JobCostCartToolbar from './JobCostCartToolbar';
import ConfirmationPopup from 'src/components/confirmationPopup';
import Icon from '@iconify/react';
import { LoadingButton } from '@material-ui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import axios from 'axios';
import { COMPANY_NAME, REST_API_END_POINT, UNITS } from 'src/constants/Defaultvalues';
import { useSnackbar } from 'notistack';
import Modal from 'src/components/_dashboard/blog/ProjectModel';
import { useTheme } from '@material-ui/core/styles';
import { capitalCase } from 'change-case';
import CompareJobCost from './CompareJobCost';
import LoadingScreen from 'src/components/LoadingScreen';



// ----------------------------------------------------------------------

const INVOICE = {
  id: faker.datatype.uuid(),
  taxes: 5,
  discount: 10,
  status: 'paid',
  invoiceFrom: {
    name: faker.name.findName(),
    address: 'DieSachbearbeiter Choriner Straße 49 10435 Berlin',
    company: faker.company.companyName(),
    email: faker.internet.email(),
    phone: faker.phone.phoneNumberFormat()
  },
  invoiceTo: {
    name: faker.name.findName(),
    address: 'Keas 69 Str. 15234, Chalandri Athens, Greece',
    company: faker.company.companyName(),
    email: faker.internet.email(),
    phone: faker.phone.phoneNumberFormat()
  },
  items: [...Array(1)].map(() => ({
    id: faker.datatype.uuid(),
    title: faker.lorem.sentence(),
    description: faker.lorem.lines(),
    qty: faker.datatype.number({ min: 1, max: 5 }),
    price: faker.datatype.number({ min: 4, max: 99, precision: 0.01 })
  }))
};

const RowResultStyle = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

const CardMediaStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  cursor: 'pointer',
  paddingTop: 'calc(100% * 9 / 16)',
  '&:before': {
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',

    WebkitBackdropFilter: 'blur(3px)',
    borderTopLeftRadius: theme.shape.borderRadiusMd,
    borderTopRightRadius: theme.shape.borderRadiusMd,

  },
}))

const CoverImgStyle = styled('img')({
  top: 0,
  zIndex: 8,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',

})

// ----------------------------------------------------------------------

export default function JobCost({jobCostId,handleClickOpenRecipeCost,setRecipeCostCurrentTab,handleBackToAlljobs,popupOn,onDataChange}) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const user = JSON.parse(window.localStorage.getItem('user'))
  const subTotal = sum(INVOICE.items.map((item) => item.price * item.qty));
  const total = subTotal - INVOICE.discount + INVOICE.taxes;
  const location = useLocation();
  const currentTabNew = location?.state?.currentTab

  console.log('location-123-',location);
  console.log('currentTabNew--',currentTabNew);
  const params = localStorage.getItem("params");
  const ACCOUNT_TABS = [
    {
      value: 'Materials',
      name: 'Materials',

    },
    // {
    //   value: 'Services',
    //   name: 'Services',
    // },
  ]
  const [isHover, setIsHover] = useState(false)
  const [productList, setProductList] = useState([]);
  const [initialMaterials, setInitialMaterials] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [openDeletePop, setOpenDeletePop] = useState(false)
  const [deleteId, setDeleteId] = useState()
  const [renderCards, setRenderCards] = useState(false)
  const [materialsPreview, setMaterialsPreview] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedMaterial, setSelectedMaterial] = useState()
  const [manualTotalPayOut, setManualTotalPayOut] = useState(0)
  const [hourlyTotalPayOut, setHourlyTotalPayOut] = useState(0)
  const [jobCost, setJobCost] = useState(0)
  const [recipe, setRecipe] = useState([])
  const [isRecipe, setIsRecipe] = useState(false)
  const [ingredient, setIngredient] = useState(false)
  const [materialPayOut, setMaterialPayOut] = useState(0)
  const [selectedServices, setSelectedServices] = useState([])
  const [services, setServices] = useState([])
  const [allServices, setAllServices] = useState([])
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [color, setColor] = useState('#308fe8')
  const [errorFieldId, setErrorFieldId] = useState([]);
  const [currentTab, setCurrentTab] = useState('Materials')
  const [tab, setTab] = useState(false)
  const [jobCostPreview, setJobCostPreview] = useState(false)
  const [details, setDetails] = useState(null);
  const handleChangeTab = (event, newValue) => {
    if (newValue == 'Materials') {
      setCurrentTab(newValue);
      setTab(false);
    } else {
      setCurrentTab(newValue);
      setTab(true);
    }
  };
  const logo = 'https://www.shutterstock.com/image-photo/checklist-clipboard-task-documentation-management-260nw-2270860299.jpg'

  const { id:paramsId } = useParams();
  const  id  = popupOn ? jobCostId: paramsId

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: `hsl(${120 - (progressValue * 1.2)}, ${60}%, 50%)`, // `hsl(${(progressValue * 1.2)}, ${60 + (progressValue * 0.4)}%, 50%)`
    },
  }));
  const fetchAllProductCategory = async () => {
    setLoading(true);
    try {
      axios
        .post(REST_API_END_POINT + 'product/get-all-product-categories')
        .then((res) => {
          let data = res.data
          if (data.status === 1) {
            // let length = data.productCategories.length + 2
            // console.log("length", length);
            // let recipe = {
            //   "id": length,
            //   "name": "Services",
            //   "added_by": 1,
            //   "status": 1,
            //   "timestamp": 1711096046,
            //   "c_id": 1,
            //   "authorized_by": "Rakesh Skyniche"
            // }
            // data.productCategories.push(recipe);
            console.log("######################Data.product", data.productCategories);
            //  setCategory(data.productCategories)
            setCategory([...data.productCategories]);

          }
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    } catch (e) {
      console.error('Exception:', e)
    }finally{
      setTimeout(() => {
        setLoading(false)
      }, 1000);
    }
  }
  console.log("############CAAA", category);
  useEffect(() => {
    fetchData()
    fetchAllProductCategory()
  }, [renderCards]);
  let grandTotalCost = 0
  const progressValue = useMemo(() => {
    //  let Cost = ((selectedProjects.reduce((accumulator, currentValue) => {
    //     return accumulator + (currentValue.unit_cost * currentValue.quantity);
    //   }, 0) + Number(manualTotalPayOut)+Number(materialPayOut)) / jobCost) * 100
    // let tc = selectedProjects.reduce((accumulator, currentValue) => {
    //   return accumulator + (currentValue.unit_cost * currentValue.quantity);
    // }, 0)
    let tc = selectedProjects.reduce((accumulator, currentValue) => {
      // return accumulator + (currentValue.unit_cost * currentValue.quantity);
      let newTotal = 0;
      if(currentValue?.taken == 1){
        newTotal = currentValue?.jobcost_total
        // newTotal = currentValue?.jobcost_unit_cost * currentValue?.jobcost_quantity
      }
      return accumulator + newTotal;
    }, 0) + manualTotalPayOut + hourlyTotalPayOut + materialPayOut + Math.max(0, (services?.reduce((accumulator, currentValue) => {
      let newTotal = 0;
      if(currentValue?.taken == 1){
        // newTotal = currentValue?.originalCost * currentValue?.qty
        newTotal = parseFloat(currentValue?.jobcost_total_cost || 0)
      }
      return accumulator + newTotal;
    }, 0) + selectedServices?.reduce((accumulator, currentValue) => {
      let newTotal = 0;
      if(currentValue?.taken == 1){
        // newTotal = currentValue?.originalCost * currentValue?.qty
        newTotal = parseFloat(currentValue?.jobcost_total_cost || 0)
      }
      return accumulator + newTotal;
    }, 0)) - (parseFloat(manualTotalPayOut ? parseFloat(manualTotalPayOut) : 0) + (hourlyTotalPayOut ? parseFloat(hourlyTotalPayOut) : 0)).toFixed(2))
    let Cost = 0
    if (jobCost !== 0) {
      // Cost = (tc + manualTotalPayOut + hourlyTotalPayOut + materialPayOut) / jobCost * 100;
      Cost = (tc) / jobCost * 100;
      console.log("#################Cost", Cost);
    }else{
      Cost = 100
    }
    console.log("#################Cost", jobCost);
    console.log("#################Cost", tc);
    console.log("#################Cost", manualTotalPayOut);
    console.log("#################Cost", hourlyTotalPayOut);
    console.log("#################Cost", materialPayOut);
    console.log("#################Cost", Cost);

    if (Cost > 100) {
      return 100
    } else {
      return Cost
    }
  }, [selectedProjects, manualTotalPayOut, hourlyTotalPayOut, materialPayOut, jobCost, grandTotalCost])

  const fetchData = () => {
    setLoading(true);
    try {
      axios.post(`${REST_API_END_POINT}project/fetch-all-job-cost`, { userId: user.user_id, projectId: id, orderId: params }).then((response) => {
        const productListData = response.data.results
        const selectedProducts = response.data.selectedMaterials
        let initialData = response.data.selectedMaterials
        const allPayouts = response.data.allPayOut
        const project = response.data.projects
        const allMaterials = response.data.allMaterials
        const allRecipes = response.data.allRecipies
        const material = response.data.materials
        let allServices = response.data.allServices
        let services = response.data.services

        let selectedServices = response.data.selectedServices
        if (services) {
          setServices(services)
        }
        if (selectedServices) {
          setSelectedServices(selectedServices)
        }
        if (allServices) {
          setAllServices(allServices)
        }
        if (allRecipes) {
          setRecipe(allRecipes)
        }
        if (material?.length > 0) {
          const data = material
            ?.filter(curr => curr.taken === 1)
            ?.reduce((acc, curr) => {
              return acc + curr.total;
            }, 0);
          setMaterialPayOut(data);
        }
        if (allPayouts?.length > 0) {
          let totalPayOut = allPayouts?.reduce((acc, curr) => acc + curr.amount, 0)
          const totalHourlyPayoutAmount = allPayouts.filter(payout => payout.is_auto_pay === 1).reduce((acc, curr) => acc + curr.amount, 0);
          const totalManualPayoutAmount = allPayouts.filter(payout => payout.is_auto_pay !== 1).reduce((acc, curr) => acc + curr.amount, 0);
          setHourlyTotalPayOut(allPayouts.filter(payout => payout.is_auto_pay === 1).reduce((acc, curr) => acc + curr.amount, 0))
          setManualTotalPayOut(allPayouts.filter(payout => payout.is_auto_pay !== 1).reduce((acc, curr) => acc + curr.amount, 0))
        }
        if (initialData) {
          setInitialMaterials(initialData)
        }
        if (productListData) {
          setProductList(productListData)
        }
        if (allMaterials) {
          const filteredMaterials = project?.job_category_name == 'Service only' ? allMaterials : allMaterials?.filter(item=>item.inhouse == 1)
          setMaterialList(filteredMaterials)
        }
        if (project) {
          setJobCost(project.cost || 0)
        }
        if (project) {
          setDetails(project || null);
        }
        if (response.data.selectedMaterials?.length) {
          setSelectedProjects(productListData?.filter(item1 =>
            selectedProducts?.some(item2 => item1.id === item2.product_id)
          ).map(item1 => {
            let match = selectedProducts?.find(item2 => item1.id === item2.product_id);
            console.log('item1========',item1)
            console.log('match========',match)
            return { ...item1, ...(match ? 
              { product_id: match.id, 
                unit: match?.partial_unit ? match?.partial_unit : item1?.unit,
                // unit_cost:(match?.unit_cost && match?.unit_cost > 0) ? match.unit_cost : match.material_unit_cost, 
                unit_cost: item1?.inhouse == 0 ? 0 : match?.unit_cost > 0 ? match.unit_cost : match?.material_unit_cost || 0,
                total:match.total, 
                quantity: match.quantity, 
                price: match.price, 
                match_actual_quantity: match.actual_quantity, 
                match_actual_total: match.actual_total, 
                match_actual_unit_price: match.actual_unit_price
              } : {}) };
          }))
        }
      })
    }catch (error) {
      console.error("Error fetching data:", error);
    }finally{
      setTimeout(() => {
        setLoading(false)
      }, 1000);
    }
  }
  const handleAddService = (service) => {
    console.log("################service", service);
    service.qty = 1
    service.originalCost = service.cost
    if (!selectedServices.some((item) => (item.labour_cost_service_id || item.id) === service.id)) {
      setSelectedServices([...selectedServices, service]);
    } else {
      enqueueSnackbar("Service already in cart", { variant: 'error' });
      console.log('Service already added');
    }
  };
  const handleQuantityChange = (index, newValue) => {
    const updatedServices = [...selectedServices];
    updatedServices[index].qty = newValue;
    setSelectedServices(updatedServices);
  };
  const handleDeleteService = (index) => {
    const updatedServices = [...selectedServices];
    updatedServices.splice(index, 1);
    setSelectedServices(updatedServices);
  };
  console.log("###################selectedServices", selectedServices);
  function isSumGreater(productId, valQty) {
    let sum = 0;
    initialMaterials?.forEach(material => {
      if (material.product_id === productId) {
        sum += material.quantity;
      }
    });
    productList?.forEach(product => {
      if (product.id === productId) {
        sum += product.qty;
      }
    });
    return sum < Number(valQty);
  }
  useEffect(() => {
    const errorFields = [];
    setErrorFieldId([]);
    selectedProjects?.forEach((val) => {
      const result = isSumGreater(val.productId, val.quantity);
      if (result) {
        errorFields.push({ productId: val.productId, errorText: 'Quantity exceeds the stock' });
      }
    });
    selectedProjects?.forEach((val) => {
      if (val.quantity <= 0) {
        errorFields.push({ productId: val.productId, errorText: 'Enter a valid quantity' });
      }
    });
    if (errorFields.length > 0) {
      setErrorFieldId([])
      setErrorFieldId(errorFields);
    }
  }, [selectedProjects, manualTotalPayOut, hourlyTotalPayOut])

  const handleProjectClick = (project) => {
    console.log('project---',project)
    // setSelectedProjects(prevSelectedProjects => [...prevSelectedProjects, project]);
    setSelectedProjects(prevSelectedProjects => [
      ...prevSelectedProjects,
      {
        ...project,
        unit : project?.partial_unit ? project?.partial_unit : project?.unit,
        unit_cost: project.inhouse === 0 ? 0 : project.unit_cost // Set unit_cost to 0 if inhouse is 0
      }
    ]);    
  };
  console.log('selectedProjects===',selectedProjects)
  const NewUserSchema = Yup.object().shape({
    quantity: Yup.number().min(1, 'Quantity must be at least 1').test('valid-quantity', 'Enter a valid quantity', (value) => value > 0).required('Quantity is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      quantity: 1,
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      try {
        // if(selectedProjects?.length > 0){
        const values = selectedProjects?.map(project => ({
          ...(project.product_id ? { id: project.product_id } : {}),
          productId: project.id,
          projectId: id,
          // actual_quantity: project.actual_quantity,
          // actual_unit_cost: project.actual_unit_cost,
          // actual_total: project.actual_total,
          actual_quantity: project.match_actual_quantity, // project.unit_qty || 0,
          actual_unit_cost: project.match_actual_unit_price, // project.unit_cost || 0,
          actual_total: project.match_actual_total, // project.actual_total_cost || 0,
          quantity: project.quantity,
          unitPrice: project?.inhouse == 0 ? 0 : project.unit_cost,
          task_id: project.task_id || null,
          total: project?.inhouse == 0 ? 0 : (Number(project.unit_cost) * Number(project.quantity))
        }))
        function isSumGreater(productId, valQty) {
          let sum = 0;
          initialMaterials?.forEach(material => {
            if (material.product_id === productId) {
              sum += material.quantity;
            }
          });
          productList?.forEach(product => {
            if (product.id === productId) {
              sum += product.qty;
            }
          });
          return sum < Number(valQty);
        }
        const errorFields = [];
        values.forEach((val) => {
          const result = isSumGreater(val.productId, val.quantity);
          if (result) {
            errorFields.push({ productId: val.productId, errorText: 'Quantity exceeds the stock' });
          }
        });
        values.forEach((val) => {
          if (val.quantity <= 0) {
            errorFields.push({ productId: val.productId, errorText: 'Enter a valid quantity' });
          }
        });
        if (errorFields.length > 0) {
          setErrorFieldId([])
          setErrorFieldId(errorFields);
          // setRenderCards(!renderCards)
          return;
        }
        else {
          setErrorFieldId([])
          axios.post(`${REST_API_END_POINT}project/add-job-cost`, { userId: user.user_id, values, projectId: id, recipe: 0, selectedServices, requestId: params }, {
            headers: {
              'Content-Type': 'application/json',
            },
          }).then((response) => {
            if (response.data.status === 1) {
              enqueueSnackbar(response.data.message, { variant: 'success' });
            } else if (response?.data?.message) {
              enqueueSnackbar(response.data.message, { variant: 'error' });
            } else {
              enqueueSnackbar('Error adding Jobcost', { variant: 'error' });
            }
            setRenderCards(!renderCards)
            onDataChange()
          }).catch((error) => {
            console.error(error);
            enqueueSnackbar('Error adding Jobcost', { variant: 'error' });
          })
        }
        // }else{
        //   enqueueSnackbar('Please select a material', { variant: 'error' });
        // }
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Error adding Jobcost', { variant: 'error' });
      }
    }
  });

  console.log('selectedProjects----',selectedProjects);

  const handleDeleteSubmit = async (deletedId) => {
    try {
      // console.log('deletedId----',deletedId);
      setSelectedProjects([...selectedProjects.filter(selected => selected.id !== deletedId)])
      const values = selectedProjects?.filter(selected => selected.id !== deletedId)?.map(project => ({
        ...(project.product_id ? { id: project.product_id } : {}),
        productId: project.id,
        projectId: id,
        // actual_quantity: project.actual_quantity,
        // actual_unit_cost: project.actual_unit_cost,
        // actual_total: project.actual_total,
        actual_quantity: project.match_actual_quantity, // project.unit_qty || 0,
        actual_unit_cost: project.match_actual_unit_price, // project.unit_cost || 0,
        actual_total: project.match_actual_total, // project.actual_total_cost || 0,
        quantity: project.quantity,
        task_id: project.task_id || null,
        unitPrice: project?.inhouse == 0 ? 0 : project.unit_cost,
        total: project?.inhouse == 0 ? 0 : (Number(project.unit_cost) * Number(project.quantity))
      }))
      function isSumGreater(productId, valQty) {
        let sum = 0;
        initialMaterials?.forEach(material => {
          if (material.product_id === productId) {
            sum += material.quantity;
          }
        });
        productList?.forEach(product => {
          if (product.id === productId) {
            sum += product.qty;
          }
        });
        return sum < Number(valQty);
      }
      const errorFields = [];
      values.forEach((val) => {
        const result = isSumGreater(val.productId, val.quantity);
        if (result) {
          errorFields.push({ productId: val.productId, errorText: 'Quantity exceeds the stock' });
        }
      });
      values.forEach((val) => {
        if (val.quantity <= 0) {
          errorFields.push({ productId: val.productId, errorText: 'Enter a valid quantity' });
        }
      });
      if (errorFields.length > 0) {
        setErrorFieldId([])
        setErrorFieldId(errorFields);
        return;
      }
      else {
        setErrorFieldId([])
        // console.log('values-------------',values);
        axios.post(`${REST_API_END_POINT}project/add-job-cost`, { userId: user.user_id, values, projectId: id, recipe: 0 }, {
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((response) => {
          if (response.data.status === 1) {
            enqueueSnackbar(response.data.message, { variant: 'success' });
          } else if (response?.data?.message) {
            enqueueSnackbar(response.data.message, { variant: 'error' });
          } else {
            enqueueSnackbar('Error adding Jobcost', { variant: 'error' });
          }
          onDataChange()
          setSelectedProjects([])
          setManualTotalPayOut(0)
          setHourlyTotalPayOut(0)
          setInitialMaterials([])
          setProductList([])
          setMaterialList([])
          setJobCost(0)
          setDeleteId();
          // fetchData()
          setRenderCards(!renderCards)
          onDataChange()
        }).catch((error) => {
          console.error(error);
          enqueueSnackbar('Error adding Jobcost', { variant: 'error' });
        })
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Error adding Jobcost', { variant: 'error' });
    }
  }

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const materialWithStock = materialList?.filter((data) => data?.total_qty > 0)
  const productWithQty = productList?.filter((data) => data?.qty > 0)
  const navigate = useNavigate()

  const linkState = {
    pathname: `${PATH_DASHBOARD.project.recipeCost}/${id}`,
    state: { request: params,currentTab : currentTabNew }
  };

  const handleClick = () => {
    if(popupOn){handleClickOpenRecipeCost()
    setRecipeCostCurrentTab(currentTab)}
    else{navigate(`${PATH_DASHBOARD.project.recipeCost}/${id}`,
      {state: { request: params,currentTab : currentTabNew }});}
  };

  // grandTotalCost = selectedProjects.reduce((accumulator, currentValue) => {
  //   return accumulator + (currentValue.unit_cost * currentValue.quantity);
  // }, 0) + manualTotalPayOut + hourlyTotalPayOut + materialPayOut + Math.max(0, (services?.reduce((accumulator, currentValue) => {
  //   let newTotal = 0;
  //   if(currentValue?.taken == 1){
  //     newTotal = currentValue?.originalCost * currentValue?.qty
  //   }
  //   return accumulator + newTotal;
  //   // return accumulator + (currentValue?.originalCost * currentValue?.qty);
  // }, 0) + selectedServices?.reduce((accumulator, currentValue) => {
  //   let newTotal = 0;
  //   if(currentValue?.taken == 1){
  //     newTotal = currentValue?.originalCost * currentValue?.qty
  //   }
  //   return accumulator + newTotal;
  //   // return accumulator + (currentValue?.originalCost * currentValue?.qty);
  // }, 0)) - (manualTotalPayOut ? manualTotalPayOut?.toFixed(2) : 0) + (hourlyTotalPayOut ? hourlyTotalPayOut?.toFixed(2) : 0))

let selectedProjectsTotal = selectedProjects.reduce((accumulator, currentValue) => {
  return accumulator + (currentValue.unit_cost * currentValue.quantity);
}, 0);

let materialPayOutFormatted = parseFloat(materialPayOut.toFixed(2));

let servicesTotal = services?.reduce((accumulator, currentValue) => {
  let newTotal = 0;
  if (currentValue?.taken === 1) {
      // newTotal = currentValue.originalCost * currentValue.qty;
      newTotal = parseFloat(currentValue?.jobcost_total_cost || 0)
  }
  return accumulator + newTotal;
}, 0) || 0;

servicesTotal = parseFloat(servicesTotal.toFixed(2));

let manualTotal = parseFloat((manualTotalPayOut ? manualTotalPayOut.toFixed(2) : 0));
let hourlyTotal = parseFloat((hourlyTotalPayOut ? hourlyTotalPayOut.toFixed(2) : 0));

let totalPayout = Math.max(0, manualTotal + hourlyTotal);

let totalServiceCost = services.reduce((accumulator, currentValue) => {
  // return accumulator + (currentValue.originalCost * currentValue.qty);
  return accumulator + parseFloat(currentValue?.jobcost_total_cost || 0);
}, 0) || 0;

let selectedServicesTotal = selectedServices?.reduce((accumulator, currentValue) => {
  // return accumulator + (currentValue.originalCost * currentValue.qty);
  return accumulator + parseFloat(currentValue?.jobcost_total_cost || 0);
}, 0) || 0;

totalServiceCost += selectedServicesTotal;

const additionalLaborCost1 = useMemo(() => (
  services.reduce((sum, service) => {
    if(details?.job_category_name=='Time & Material'){
      const jobcost_total_cost = parseFloat(service.jobcost_total_cost || 0);
      // if (service.recipe_id == null) {
      if (service.recipe_id == null && service?.taken == 1){
        return sum + jobcost_total_cost;
      }
    }else{
      const quantity = parseFloat(service.jobcost_quantity || service.qty || 0);
      const unitCost = parseFloat(service.jobcost_unit_cost || service.originalCost || service.unit_cost || 0);
      const jobcost_total_cost = parseFloat(service.jobcost_total_cost || 0);
      // if (service.recipe_id == null) {
      if (service.recipe_id == null && service.labour_cost_service_id == 10 && service?.taken == 1){
        sum += jobcost_total_cost;
      }else if (service.recipe_id == null && service?.taken == 1){
        // sum += (quantity * unitCost);
        sum += jobcost_total_cost;
      }
    }
    // if (service.recipe_id == null) {
    //   return sum + service.jobcost_unit_cost * service.jobcost_quantity;
    // }
    return sum;
  }, 0)
), [services, details]);

const additionalLaborCost2 = useMemo(() => (
  selectedServices.reduce((sum, service) => {
    if(details?.job_category_name=='Time & Material'){
      const jobcost_total_cost = parseFloat(service.jobcost_total_cost || 0);
      if (service.recipe_id == null && service?.taken == 1){
        return sum + jobcost_total_cost;
      }
    }else{
      const quantity = parseFloat(service.jobcost_quantity || service.qty || 0);
      const unitCost = parseFloat(service.jobcost_unit_cost || service.originalCost || service.unit_cost || 0);
      const jobcost_total_cost = parseFloat(service.jobcost_total_cost || 0);
      if (service.recipe_id == null && service?.taken == 1){
        // return sum + (quantity * unitCost);
        return sum + jobcost_total_cost;
      }
    }
    // if (service.recipe_id == null) {
    //   return sum + service.jobcost_unit_cost * service.jobcost_quantity;
    // }
    return sum;
  }, 0)
), [selectedServices, details]);

const excessLaborCost1 = useMemo(() => (
  Math.max(0, manualTotalPayOut + hourlyTotalPayOut - (additionalLaborCost1 + additionalLaborCost2))
), [manualTotalPayOut, hourlyTotalPayOut, additionalLaborCost1, additionalLaborCost2]);

grandTotalCost = selectedProjectsTotal + materialPayOutFormatted + servicesTotal + excessLaborCost1;
grandTotalCost = parseFloat(grandTotalCost.toFixed(2));

console.log('servicesservices=12=selectedProjectsTotal==',selectedProjectsTotal);
console.log('servicesservices=12=materialPayOutFormatted==',materialPayOutFormatted);
console.log('servicesservices=12=servicesTotal==',servicesTotal);
console.log('servicesservices=12=totalPayout==',totalPayout);
console.log('servicesservices=12=manualTotalPayOut==',manualTotalPayOut);
console.log('servicesservices=12=hourlyTotalPayOut==',hourlyTotalPayOut);
console.log('servicesservices=12=additionalLaborCost1==',additionalLaborCost1);
console.log('servicesservices=12=additionalLaborCost2==',additionalLaborCost2);

console.log('servicesservices=12=totalServiceCost==',totalServiceCost);
console.log('servicesservices=12=services==',services);

// {Math.max(0, (
//   parseFloat((manualTotalPayOut ? manualTotalPayOut.toFixed(2) : 0)) + 
//   parseFloat((hourlyTotalPayOut ? hourlyTotalPayOut.toFixed(2) : 0))) - 
//   Math.max(0, (services?.reduce((accumulator, currentValue) => {
//   return accumulator + (currentValue?.originalCost * currentValue?.qty);
// }, 0) + selectedServices?.reduce((accumulator, currentValue) => {
//   return accumulator + (currentValue?.originalCost * currentValue?.qty);
// }, 0)))).toFixed(2)}

  return (
    (loading || !details) ?
      <Stack alignItems='center' justifyContent='center' mt={10}>
        <LoadingScreen />
      </Stack> 
    :
      <Page title={`Job Calculator |${COMPANY_NAME}`} mt={3}>
        <Container>
         {popupOn? <Button
            variant="outlined"
            type="button"
            // component={RouterLink}
            // to={PATH_DASHBOARD.project.viewproject + id}
            // onClick={()=>{navigate(`${PATH_DASHBOARD.project.viewproject}/${id}`,
            //   {state: { currentTab : currentTabNew }})}}
            onClick={handleBackToAlljobs}
            sx={{ alignItems: 'flex-end', mb: 4 }}
            startIcon={
              <Stack>
                <KeyboardBackspaceOutlined />
              </Stack>
            }
          >
            Back to Job Details
          </Button>:
          <Button
          variant="outlined"
          type="button"
          // component={RouterLink}
          // to={PATH_DASHBOARD.project.viewproject + id}
          onClick={()=>{navigate(`${PATH_DASHBOARD.project.viewproject}/${id}`,
            {state: { currentTab : currentTabNew }})}}
          // onClick={handleBackToAlljobs}
          sx={{ alignItems: 'flex-end', mb: 4 }}
          startIcon={
            <Stack>
              <KeyboardBackspaceOutlined />
            </Stack>
          }
        >
          Back to Job Details
        </Button>}

          <Button
            variant="outlined"
            type="button"
            onClick={()=>{ setJobCostPreview(true); }}
            sx={{ alignItems: 'flex-end', mb: 4, ml:4 }}
          >
            Expected V/S Actual
          </Button>

          <Stack direction={'row'} spacing={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Box width={'30%'}>
              <HeaderBreadcrumbs
                heading={
                <>
                  {"Job Calculator"} {details?.job_category_name === 'Time & Material' ? <Label variant="filled" color="info">T&M</Label> : ''}
                </>}
              />
            </Box>

            {/* <Box width={'50%'}> */}
            <Stack spacing={2} mb={5} sx={{ mr: '7% !important' }} width={'50%'}>
              {details?.job_category_name === 'Time & Material' ? 
                <Typography variant="subtitle2">Expected Budget (Material + Service) : {jobCost}</Typography>
              :
                <Typography variant="subtitle2">Expected Budget (Product Formula Cost + Labor Cost) : {jobCost}</Typography>
              }
              <Box sx={{ display: 'flex', alignItems: 'center', }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                  <BorderLinearProgress variant="determinate" value={progressValue} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                  <Typography variant="body2" color="text.secondary">{Math.round(progressValue) || 0}%</Typography>
                </Box>
              </Box>
            </Stack>
            {/* </Box> */}
          </Stack>
          {console.log("################44", linkState)}
          <Button
            variant="outlined"
            type="button"
            // component={RouterLink}
            // to={linkState}
            onClick={handleClick}
            sx={{ alignItems: 'flex-end', mb: 4 }}

          >
            {details?.job_category_name === 'Time & Material' ? 'Go to Services' : 'Go to Product Formula Cost'}
          </Button>
          <Grid container spacing={3}>
            <Grid item md={5} xs={12}>
              <Tabs
                value={currentTab}
                scrollButtons="auto"
                variant="scrollable"
                allowScrollButtonsMobile
                onChange={handleChangeTab}
              // sx={{pb:2}}      
              >
                {ACCOUNT_TABS.map((tabItem) => (
                  <Tab sx={{ px: 3 }}
                    disableRipple
                    key={tabItem.value}
                    label={
                      // <Badge badgeContent={tabItem.count} color="primary">
                      <Typography variant="overline">{capitalCase(tabItem.name)}</Typography>
                      // </Badge>
                    }
                    // icon={tabItem.icon}
                    value={tabItem.value}
                  />
                ))}
              </Tabs>
              <Tabs
                value={selectedCategory}
                sx={{
                  backgroundColor: theme.palette.grey[700],
                  borderRadius: 2,
                  mb: 1,
                }}
                onChange={(e, newValue) => handleCategoryChange(newValue)}
                variant="scrollable"
                aria-label="scrollable auto tabs example"
                allowScrollButtonsMobile
                autoFocus
                scrollButtons="auto"
              >
                {category.map((category) => (
                  <Tab key={category.id}
                    disableRipple
                    autoCapitalize={category.name}
                    label={category.name}
                    value={category.id} />
                ))}
              </Tabs>
              <SearchStyle
                sx={{ backgroundColor: '#05191f', height: 40 }}
                value={filterName}
                onChange={handleFilterByName}
                placeholder="Search Material"
                startAdornment={
                  <InputAdornment position="start">
                    <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                }
              />
              <Card
                sx={{
                  p: 3,
                  height: '450px',
                  overflowY: 'auto',
                  backgroundColor: 'transparent',
                  background: 'transparent',
                  '&::-webkit-scrollbar': {
                    width: '0.4em',
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.1)',
                    borderRadius: '4px',
                  },
                }}
              >
                {console.log("###########selectedCategory", category.length + 1, selectedCategory)}
                <Grid container spacing={1}>

                  {currentTab != "Materials" ? (
                    // Render content based on recipe
                    <>
                      {console.log("###########Product Formula", recipe)}
                      {
                        allServices?.map((service, index) => (
                          <Grid key={index} item xs={6} sm={4} md={4}> {/* Adjusted md to 4 */}
                            <Card
                              onMouseEnter={() => setIsHover(true)}
                              onMouseLeave={() => setIsHover(false)}
                              sx={{
                                minWidth: 100,
                                transition: 'transform 0.2s',
                                '&:hover': {
                                  transform: 'scale(1.02)',
                                },
                              }}
                              // onClick={() => { setMaterialsPreview(true); setIsRecipe(true); setSelectedMaterial(recipeItem) }}
                              onClick={() => { handleAddService(service) }}
                            >
                              {service.name}
                            </Card>
                          </Grid>
                        ))}
                    </>
                  ) : (
                    materialWithStock?.filter(material => (
                      (filterName ? material.name.toLowerCase().includes(filterName.toLowerCase()) : true) &&

                      (selectedCategory ? material.category_id === selectedCategory : true)
                    ))?.map((post, index) => (
                      <Grid key={index} item xs={6} sm={4} md={4}>
                        <Card
                          onMouseEnter={() => setIsHover(true)}
                          onMouseLeave={() => setIsHover(false)}
                          sx={{
                            minWidth: 100,
                            transition: 'transform 0.2s',
                            '&:hover': {
                              transform: 'scale(1.02)',
                            },
                            cursor: (currentTabNew == "Installations" || currentTabNew == "Completed" || currentTabNew == "Hold") ? 'default' : 'pointer',
                          }}
                          onClick={() => { (currentTabNew == "Installations" || currentTabNew == "Completed" || currentTabNew == "Hold") ? null : setMaterialsPreview(true); setSelectedMaterial(post) }}
                        >
                          <Box sx={{ 
                            p: 2,
                            textAlign: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            // height: 'auto',
                            height: '80px',
                            overflow: 'hidden',
                           }}>
                            <Stack
                              direction={'row'}
                              justifyContent={'center'}
                              alignItems={'center'}
                              sx={{
                                height: '100%',
                              }}
                            >
                              <Tooltip title={post.name + ` (${post?.inhouse ? 'Inhouse' : 'Client owned'})`}>
                                <Typography
                                  variant="body2"
                                  align="center"
                                  sx={{
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    WebkitLineClamp: 3,
                                    lineHeight: '1.2',
                                    maxHeight: '3.6em',
                                    fontSize: '14px',
                                    // cursor: (currentTabNew == "Installations" || currentTabNew == "Completed" || currentTabNew == "Hold") ? 'default' : 'pointer',
                                  }}
                                >
                                  {post.name + ` (${post?.inhouse ? 'I' : 'C'})`}
                                </Typography>
                              </Tooltip>
                            </Stack>
                          </Box>
                          <Divider />
                          <Box sx={{ 
                            p: 2,
                            textAlign: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: 'auto',
                            overflow: 'hidden',
                          }}>
                            <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                              <Typography
                                variant="caption"
                                align="center"
                                sx={{
                                  marginTop: 0.5,
                                  fontSize: '12px',
                                  // color: 'gray',
                                }}
                              >
                                Stock : {post?.total_qty}
                              </Typography>
                            </Stack>
                          </Box>
                        </Card>
                      </Grid>
                    )))}
                </Grid>
              </Card>
            </Grid>

          <Grid item md={7} xs={12}>
            <Card>
              <Box sx={{ display: 'flex', alignItems: 'center', py: 2, pl: 3 }}>
                <Typography variant="h4" noWrap>Expense</Typography>
              </Box>
              <FormikProvider value={formik}>
                <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <Scrollbar
                    sx={{
                      height: '320px'
                    }}>
                    <TableContainer sx={{ minWidth: 550 }}>
                      <Table>
                        <TableHead
                          sx={{
                            borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                            '& th': { backgroundColor: 'transparent' }
                          }}
                        >
                          <TableRow>
                            {/* <TableCell align="left">
                          <Typography variant="body1" noWrap>ID</Typography>
                        </TableCell> */}
                            <TableCell align="center">
                              <Typography variant="body1" >Name & Barcode</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography variant="body1" noWrap>Quantity</Typography>
                            </TableCell>
                            {/* <TableCell align="center">
                              <Typography variant="body1" noWrap>Unit price</Typography>
                            </TableCell> */}
                            <TableCell align="center">
                              <Typography variant="body1" noWrap>Total </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography variant="body1" noWrap></Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>

                          <TableBody>
                            {selectedProjects?.map((selectedProject, index) => {
                              let qtyExceed = 0
                              console.log('selectedProject---', selectedProject);
                              let unitNameNew = UNITS?.filter((item) => item?.id == selectedProject?.unit)
                              let unitName = unitNameNew?.[0]?.name || '';
                              let shortUnitName = unitNameNew?.[0]?.short_name || "";
                              return (
                                <TableRow
                                  key={selectedProject.id}
                                  sx={{
                                    borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                                  }}
                                >
                                  <TableCell align="center">
                                    <Box sx={{ maxWidth: 130, justifyContent: 'center', alignItems: 'center' }}>
                                    <Tooltip title={selectedProject?.name + (selectedProject?.inhouse == 1 ? ' (Inhouse)' : ' (Client owned)')}>
                                      <Typography variant="subtitle2" sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                        {selectedProject?.name} {selectedProject?.inhouse == 1 ? ' (I)' : ' (C)'}
                                      </Typography>
                                    </Tooltip>
                                      <Typography variant="subtitle2" >{selectedProject?.barcode}</Typography>
                                      <span style={{ color: theme.palette.error.main }}>
                                        {selectedProjects?.filter(item => item.id === selectedProject?.id)?.reduce((accumulator, currentValue) => {
                                          const initialQuantity = initialMaterials?.find(item => item.product_id === selectedProject.id)?.quantity || 0;
                                          const productQuantity = productList?.find(item => item.id === selectedProject.id)?.qty || 0;
                                          const stock = (initialQuantity + productQuantity) - currentValue?.quantity;
                                          return stock >= 0 ? ('Stock left : ' + stock.toFixed(2)) : '';
                                        }, 0) || ''}
                                      </span>
                                    </Box>
                                  </TableCell>
                                  {/* <TableCell>{index + 1}</TableCell> */}
                                  {/* <TableCell align="left">1</TableCell> */}
                                  <TableCell align="center">
                                    <TextField
                                      sx={{ width: '100%' }}
                                      type='number'
                                      disabled = {(currentTabNew =="Installations" || currentTabNew =="Completed" || currentTabNew == "Hold") ? true : false}
                                      label={(selectedProject?.unit == 4) ? "Quantity" : shortUnitName}
                                      value={selectedProject.quantity}
                                      onKeyPress={(event) => {
                                        // console.log('errorFieldId------',errorFieldId)
                                        if (event.key === '0' && event.target.value === '0') {
                                          event.preventDefault();
                                        }
                                        if (event.key === '0' && event.target.value === '') {
                                          event.preventDefault();
                                        }
                                        // if (event.key === '.') {
                                        //   event.preventDefault();
                                        // }
                                        if (event.key === '-') {
                                          event.preventDefault();
                                        }
                                      }}
                                      onChange={(event) => {
                                        // console.log('errorFieldId--2----',errorFieldId)
                                        let newValue = event.target.value;
                                        if (newValue.startsWith('-')) {
                                          newValue = newValue.slice(1);
                                        }
                                        const updatedProjects = selectedProjects.map(p => {
                                          if (p.id === selectedProject.id) {
                                            return {
                                              ...p,
                                              quantity: event.target.value
                                            };
                                          }
                                          return p;
                                        });
                                        const qtyExceed = selectedProjects?.filter(item => item.id === selectedProject?.id)?.reduce((accumulator, currentValue) => {
                                          const initialQuantity = initialMaterials?.find(item => item.product_id === selectedProject.id)?.quantity || 0;
                                          const productQuantity = productList?.find(item => item.id === selectedProject.id)?.qty || 0;
                                          const stock = initialQuantity + productQuantity;
                                          const balanceStock = stock - event.target.value;
                                          return (balanceStock < 0)
                                        }, 0) || 0

                                        setSelectedProjects(updatedProjects);
                                      }}
                                      error={errorFieldId?.length > 0 && errorFieldId?.find(data => data.productId === selectedProject.id) || ''}
                                      helperText={errorFieldId?.length > 0 && errorFieldId.find(field => field.productId === selectedProject.id)?.errorText || ''}
                                      // error={Boolean(touched.quantity && errors.quantity)}
                                      // helperText={touched.quantity && errors.quantity}
                                      InputProps={{
                                        inputProps: {
                                          min: 1,
                                          max: () => {
                                            const initialQuantity = initialMaterials?.find(item => item.product_id === selectedProject.id)?.quantity || 0;
                                            const productQuantity = productList?.find(item => item.id === selectedProject.id)?.qty || 0;
                                            return initialQuantity + productQuantity;
                                          }
                                        }
                                      }}
                                      defaultValue={1}
                                    />
                                    <span style={{ color: theme.palette.error.main, fontSize: 12 }}>
                                      {(errorFieldId?.length > 0 && errorFieldId?.find(data => data.productId === selectedProject.id)) ? '' : (selectedProjects?.filter(item => item.id === selectedProject?.id)?.reduce((accumulator, currentValue) => {
                                        const initialQuantity = initialMaterials?.find(item => item.product_id === selectedProject.id)?.quantity || 0;
                                        const productQuantity = productList?.find(item => item.id === selectedProject.id)?.qty || 0;
                                        const stock = initialQuantity + productQuantity;
                                        const balance = stock - currentValue?.quantity;
                                        return balance < 0 ? ((selectedProject?.unit == 4) ? "Quantity exceeds the stock" : unitName + ' exceeds the stock') : ((currentValue?.quantity <= 0) ? ((selectedProject?.unit == 4) ? "Enter a valid quantity" : 'Enter a valid ' + unitName) : '')
                                      }, 0) || '')}
                                    </span>
                                    {/* <TextField
                                    sx={{ width: '80%', borderColor: (qtyExceed || errors.quantity) ? theme.palette.error.main : theme.palette.success.main }}
                                    type='number'
                                    label="Quantity"
                                    {...getFieldProps('quantity')}
                                    error={(qtyExceed || errors.quantity) && touched.quantity}
                                    helperText={(qtyExceed || errors.quantity) && touched.quantity ? "Quantity exceeds the stock" : ""}
                                    InputProps={{
                                      inputProps: {
                                        min: 1,
                                        max: () => {
                                          const initialQuantity = initialMaterials?.find(item => item.product_id === selectedProject.id)?.quantity || 0;
                                          const productQuantity = productList?.find(item => item.id === selectedProject.id)?.qty || 0;
                                          return initialQuantity + productQuantity;
                                        },
                                      },
                                    }}
                                    defaultValue={1}
                                  /> */}
                                  </TableCell>

                                {/* <TableCell align="center">${selectedProject?.unit_cost?.toFixed(2)}</TableCell> */}
                                <TableCell align="center">{fCurrency(Number(selectedProject?.unit_cost) * Number(selectedProject?.quantity))}</TableCell>
                                {/* <TableCell align="center">{fCurrency(Number(selectedProject?.total))}</TableCell> */}
                                <TableCell align="center">
                                  {(currentTabNew =="Installations" || currentTabNew == "Completed" || currentTabNew == "Hold") ? '' :
                                  <IconButton onClick={() => { setOpenDeletePop(true); setDeleteId(selectedProject?.id) }} >
                                    <Icon icon={trash2Outline} width={20} height={20} />
                                  </IconButton>
                                  }
                                  <ConfirmationPopup
                                    open={openDeletePop}
                                    handleClose={() => { setOpenDeletePop(false); setDeleteId() }}
                                    message={`You want to delete ! `}
                                    handleClick={() => { setOpenDeletePop(false); handleDeleteSubmit(deleteId) }}
                                  />
                                </TableCell>
                                {/* <ConfirmationPopup open={isDelete} handleClick={()=>handleDeleteTask(row.ID,row.project_id)} message={'Want to Delete This Task'} handleClose={()=>setIsDelete(false)}/> */}
                              </TableRow>
                            )
                          })}
                          {selectedServices?.map((service, serviceIndex) => (
                            <TableRow
                              key={`service_${serviceIndex}`}
                              sx={{
                                borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,

                                }}
                              >
                                <TableCell align="center">
                                  <Typography variant="subtitle2" style={{ color: 'yellow' }}>{service.name}</Typography>
                                </TableCell>
                                {/* <TableCell align="center">
                                  <Typography variant="subtitle2" style={{ color: 'yellow' }}>Service</Typography>
                                </TableCell> */}
                                <TableCell align="center">
                                  <TextField
                                    sx={{ width: '80%', color: 'yellow' }}
                                    type='number'
                                    label="Hours"
                                    value={service.qty}
                                    onChange={(e) => handleQuantityChange(serviceIndex, e.target.value)}
                                    InputProps={{
                                      inputProps: {
                                        min: 1
                                      }
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <Typography variant="subtitle2" style={{ color: 'yellow' }}>${service.originalCost}</Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography variant="subtitle2" style={{ color: 'yellow' }}>${(service.qty) * (service.originalCost)}</Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <IconButton onClick={() => handleDeleteService((serviceIndex))} >
                                    <Icon icon={trash2Outline} width={20} height={20} />
                                  </IconButton>

                                </TableCell>
                              </TableRow>
                            ))
                            }

                          </TableBody>
                        </Table>
                      </TableContainer>

                    </Scrollbar>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <Box>
                    <RowResultStyle>
                      {/* <TableCell colSpan={3} /> */}
                      <TableCell align="right" colSpan={4} >
                        <Typography variant="h6">{details?.job_category_name=='Time & Material' ? "Material Cost" : "Additional Material Cost"}</Typography>
                      </TableCell>
                      <TableCell align="center" width={100}>
                        <Typography variant="h6">{
                          selectedProjects.reduce((accumulator, currentValue) => {
                            return (parseFloat(accumulator) + (currentValue.unit_cost * currentValue.quantity)).toFixed(2);
                          }, 0)
                        }
                        </Typography>
                      </TableCell>
                    </RowResultStyle>
                    {details?.job_category_name=='Time & Material' ? '' :
                      <RowResultStyle>
                        <TableCell align="right" colSpan={4} >
                          <Typography variant="h6">Additional Services</Typography>
                        </TableCell>
                        <TableCell align="center" width={100}>
                          <Typography variant="h6">
                            {parseFloat(additionalLaborCost1).toFixed(2)}
                            {/* {details?.job_category_name=='Time & Material' ? '0.00' :
                            (services?.reduce((accumulator, currentValue) => {
                              let newTotal = 0;
                              if(currentValue.recipe_id == null && currentValue?.taken == 1){
                                newTotal = currentValue?.originalCost * currentValue?.qty
                              }
                              return accumulator + newTotal;
                            }, 0) || 0).toFixed(2)} */}
                          </Typography>
                        </TableCell>
                      </RowResultStyle>
                    }

                    { /*<RowResultStyle>
                    
                      <TableCell align="right" colSpan={4} >
                        <Typography variant="h6">Additional Labour Cost</Typography>
                      </TableCell>
                      <TableCell align="center" width={100}>
                        <Typography variant="h6">{selectedServices.reduce((accumulator, currentValue) => {
                          return accumulator + (currentValue.originalCost * currentValue.qty);
                        }, 0)}</Typography>
                      </TableCell>
                      </RowResultStyle>*/}
                    {details?.job_category_name=='Time & Material' ? '' :
                      <RowResultStyle>
                        {/* <TableCell  width={240}/> */}
                        <TableCell align="right" colSpan={4} >
                          <Typography variant="h6">Actual Product Formula Cost</Typography>

                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="h6" sx={{ whiteSpace: { xs: 'none', md: 'nowrap' }, width: '100%', ml: 3 }}>
                            {`${(materialPayOut + services?.reduce((accumulator, currentValue) => {
                              let newTotal = 0;
                              if(currentValue.recipe_id && currentValue?.taken == 1){
                                newTotal = currentValue?.originalCost * currentValue?.qty
                              }
                              return accumulator + newTotal;
                            }, 0) || 0).toFixed(2)} (${(materialPayOut || 0).toFixed(2)} + ${(services?.reduce((accumulator, currentValue) => {
                              let newTotal = 0;
                              if(currentValue.recipe_id && currentValue?.taken == 1){
                                newTotal = currentValue?.originalCost * currentValue?.qty
                              }
                              return accumulator + newTotal;
                              // return accumulator + (currentValue?.originalCost * currentValue?.qty);
                            }, 0) || 0).toFixed(2)})`}
                          </Typography>
                        </TableCell>


                      </RowResultStyle>
                    }
                    <RowResultStyle>
                      {/* <TableCell colSpan={3} /> */}
                      <TableCell align="right" colSpan={4} >
                        <Typography variant="h6">{details?.job_category_name=='Time & Material' ? "Service Cost" : "Excess Labor Cost"}</Typography>
                      </TableCell>
                      <TableCell align="center" width={100}>
                        <Typography variant="h6">
                          {details?.job_category_name=='Time & Material' ? (parseFloat(additionalLaborCost1)).toFixed(2) : parseFloat(excessLaborCost1).toFixed(2)}
                          {/* {Math.max(0, (parseFloat((manualTotalPayOut ? manualTotalPayOut.toFixed(2) : 0)) + parseFloat((hourlyTotalPayOut ? hourlyTotalPayOut.toFixed(2) : 0))) - Math.max(0, (services?.reduce((accumulator, currentValue) => {
                          return accumulator + (currentValue?.originalCost * currentValue?.qty);
                        }, 0) + selectedServices?.reduce((accumulator, currentValue) => {
                          return accumulator + (currentValue?.originalCost * currentValue?.qty);
                        }, 0)))).toFixed(2)} */}
                        </Typography>
                      </TableCell>
                    </RowResultStyle>
                    {/* <RowResultStyle>
                        <TableCell align="right" colSpan={4} >
                        <Typography variant="h6">Manual Staff Pay Out</Typography>
                      </TableCell>
                      <TableCell align="center" width={100}>
                        <Typography variant="h6">{manualTotalPayOut ? manualTotalPayOut?.toFixed(2) : 0}</Typography>
                      </TableCell>
                    </RowResultStyle> */}
                    {/* <RowResultStyle>
                        <TableCell align="right" colSpan={4} >
                          <Typography variant="h6">Hourly Staff Pay Out</Typography>
                      </TableCell>
                      <TableCell align="center" width={100}>
                        <Typography variant="h6">{hourlyTotalPayOut ? hourlyTotalPayOut?.toFixed(2) : 0}</Typography>
                      </TableCell>
                    </RowResultStyle> */}

                    <RowResultStyle>
                      {/* <TableCell colSpan={3} />  */}
                      {/* <TableCell align="center" width={240}> */}
                      <TableCell align="right" colSpan={4} sx={{color:'#fc8c03'}} >
                        <Typography variant="h6">Total Cost</Typography>

                      </TableCell>
                      <TableCell align="center" width={100} sx={{color:'#fc8c03'}}>
                        <Typography variant="h6">{grandTotalCost ? grandTotalCost?.toFixed(2) : 0}</Typography>

                      </TableCell>

                    </RowResultStyle>
                    </Box>
                    </Box>
                    {(currentTabNew == "Installations" || currentTabNew == "Completed" || currentTabNew == "Hold") ? '' :
                    <Box sx={{ display: 'flex', justifyContent: 'center', }}>
                      <LoadingButton loading={isSubmitting} sx={{ borderRadius: '20px', mx: 3, mb: 1, width: '80%' }} type="submit" variant="contained">
                        Submit
                      </LoadingButton>
                    </Box>
                    }
                  </Form>
                </FormikProvider>
              </Card>
            </Grid>
          </Grid>
        </Container>
        {materialsPreview && (
          <Modal
            open={materialsPreview}
            handleClose={() => {
              setMaterialsPreview(false);
              setIsRecipe(false)
            }}
            modalTitle={'Select material'}
          >
            {isRecipe ? (
              <Grid container sx={{ justifyContent: 'center' }} spacing={3}>
                <Grid item xs={12} md={10}>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                    spacing={3}
                  >
                    <Stack
                      direction={{ xs: 'column', md: 'column' }}
                      display="flex"
                      justifyContent="space-between"
                      spacing={3}
                    >
                      {console.log("#####selectedMaterial", selectedMaterial?.ingredients)}
                      <Stack direction={{ xs: 'column', md: 'column' }}>
                        <Stack>
                          <Typography variant="body">
                            Material Name :
                            <span style={{ color: theme.palette.primary.dark, fontWeight: 'bold' }}>

                              <Autocomplete
                                sx={{ width: 400 }}
                                id="combo-box-demo"
                                options={selectedMaterial.ingredients}
                                value={ingredient}
                                onChange={(event, newValue) => setIngredient(newValue)}
                                renderInput={(params) => (
                                  <TextField {...params}

                                    label="Select a Material" />
                                )}
                                getOptionLabel={(option) => option.name ? option.name : ''}

                              />
                            </span>

                          </Typography>
                        </Stack>
                      </Stack>

                      {/* <Card> */}
                      <Scrollbar>
                        <TableContainer>
                          <Table>
                            <TableHead sx={{ width: '100%' }}>
                              <TableRow>
                                <TableCell align='center'>#</TableCell>
                                <TableCell align='center'>Barcode</TableCell>
                                <TableCell align='center'>Unit Cost</TableCell>
                                <TableCell align='center'>Available Stock</TableCell>
                                <TableCell align='center'></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {loading ? (
                                <CircularProgress color="primary" variant="indeterminate" />
                              ) : (
                                <>
                                  {productWithQty?.filter((data) => (data?.material_id === ingredient?.product_id)).map((row, index) => {
                                    // console.log('selectedProjects------',selectedProjects);
                                    // console.log('selectedMaterial------',selectedMaterial);
                                    let { id, name, unit_cost, qty, barcode } = row;
                                    let quantity = selectedMaterial?.ingredients?.find(item => item.product_id === ingredient?.product_id)?.quantity || 1;
                                    console.log("&&&&&&&&&&&&&Qty", quantity);
                                    return (
                                      <TableRow
                                        hover
                                        key={index}
                                        tabIndex={-1}
                                        sx={{
                                          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                                        }}
                                      >
                                        <TableCell align='center'> {index + 1} </TableCell>
                                        <TableCell align="center"> {barcode} </TableCell>
                                        <TableCell align="center"> ${unit_cost} </TableCell>
                                        <TableCell align="center"> {qty} </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            onClick={() => !selectedProjects.map(s => s.id).includes(id) ? handleProjectClick({ ...row, quantity }) : enqueueSnackbar('Material Already Added', { variant: 'error' })}
                                          >
                                            <AddCircle />
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Scrollbar>
                      {/* </Card> */}
                      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 2 }}>
                        <Button variant='outlined' onClick={() => { setMaterialsPreview(false); setSelectedMaterial(null); }}>Close</Button>
                      </Box>
                    </Stack>

                  </Stack>
                </Grid>
              </Grid>) :
              (<Grid container sx={{ justifyContent: 'center' }} spacing={3}>
                <Grid item xs={12} md={10}>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                    spacing={3}
                  >
                    <Stack
                      direction={{ xs: 'column', md: 'column' }}
                      display="flex"
                      justifyContent="space-between"
                      spacing={3}
                    >
                      {console.log("#####selectedMaterial", selectedMaterial)}
                      <Stack direction={{ xs: 'column', md: 'column' }}>
                        <Typography variant="body">
                          Material Name : <span style={{ color: theme.palette.primary.dark, fontWeight: 'bold' }}>{selectedMaterial?.name} {selectedMaterial?.inhouse == 1 ? 'Inhouse' : 'Client owned'}</span>
                        </Typography>
                      </Stack>

                      {/* <Card> */}
                      <Scrollbar>
                        <TableContainer>
                          <Table>
                            <TableHead sx={{ width: '100%' }}>
                              <TableRow>
                                <TableCell align='center'>#</TableCell>
                                <TableCell align='center'>Barcode</TableCell>
                                <TableCell align='center'>Unit Cost</TableCell>
                                <TableCell align='center'>Available Stock</TableCell>
                                <TableCell align='center'></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {loading ? (
                                <CircularProgress color="primary" variant="indeterminate" />
                              ) : (
                                <>
                                  {productWithQty?.filter((data) => (data?.material_id === selectedMaterial?.id)).map((row, index) => {
                                    // console.log('selectedProjects------',selectedProjects);
                                    // console.log('selectedMaterial------',selectedMaterial);
                                    let { id, name,inhouse, unit_cost, qty, barcode } = row;
                                    return (
                                      <TableRow
                                        hover
                                        key={index}
                                        tabIndex={-1}
                                        sx={{
                                          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                                        }}
                                      >
                                        <TableCell align='center'> {index + 1} </TableCell>
                                        <TableCell align="center"> {barcode} </TableCell>
                                        <TableCell align="center"> ${inhouse == 0 ? '0' : unit_cost} </TableCell>
                                        <TableCell align="center"> {qty} </TableCell>
                                        <TableCell align="center">
                                          <Button
                                            onClick={() => !selectedProjects.map(s => s.id).includes(id) ? handleProjectClick({ ...row, quantity: 1 }) : enqueueSnackbar('Material Already Added', { variant: 'error' })}
                                          >
                                            <AddCircle />
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Scrollbar>
                      {/* </Card> */}
                      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 2 }}>
                        <Button variant='outlined' onClick={() => { setMaterialsPreview(false); setSelectedMaterial(null); }}>Close</Button>
                      </Box>
                    </Stack>

                  </Stack>
                </Grid>
              </Grid>)}
          </Modal>
        )}
        {jobCostPreview && (
          <Modal
            open={jobCostPreview}
            handleClose={() => {
              setJobCostPreview(false);
            }}
            modalTitle={
              <>
              {"Job Cost Calculator "}
              {details?.job_category_name === 'Time & Material' ? <Label variant="filled" color="info">T&M</Label> : ''}
              </>
            }
            width={1200}
          >
            <CompareJobCost id={id} />
          </Modal>
        )}
      </Page>
  );
}
