import React, { forwardRef, useEffect, useState } from 'react';
// material
import CloseIcon from '@material-ui/icons/Close';
import {
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ViewOfficialRequest from 'src/pages/dashboard/ViewOfficialRequest';
//


const Transition = forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);
 function FullScreenDialogSalesOrder({
  open,
   setOpen,
   projectId:initialProjectId,
   projectName:initialProjectName,
   isEditSalesOrder:initialIsEditEstimate,
   setRenderCards,
   renderCards,
   removePopupData
  }) {

  const [dataChanged, setDataChanged] = useState(false); 
// const[jobCostId,setJobCostId]=useState('');
const [projectId, setProjectId] = useState(
     localStorage.getItem('salesOrderProjectId') || initialProjectId || ''
);
const [projectName, setProjectName] = useState(
    localStorage.getItem('salesOrderProjectName') || initialProjectName || ''
);
const ndfd=localStorage.getItem('salesOrderProjectName')
console.log("FullScreenDialogSalesOrder=====ndfd",ndfd)
console.log("FullScreenDialogSalesOrder=====projectName",projectName)
console.log("FullScreenDialogSalesOrder=====initialProjectName",initialProjectName)
let edit =localStorage.getItem('isEditSalesOrder')
const [isEditSalesOrder, setIsEditSalesOrder] = useState(
    initialIsEditEstimate ||edit ||  false
);
useEffect(() => {
  if (open) {
    localStorage.setItem('salesOrderProjectId', projectId);
    localStorage.setItem('salesOrderProjectName', projectName);
    localStorage.setItem('isEditSalesOrder', isEditSalesOrder);
    localStorage.setItem('salesOrderOpenPopup', 'true');
  } else {
    // localStorage.removeItem('salesOrderProjectId');
    // localStorage.removeItem('salesOrderProjectName');
    // localStorage.removeItem('isEditSalesOrder');
    localStorage.removeItem('salesOrderOpenPopup',);
  }
}, [open, projectId, projectName, isEditSalesOrder]);
useEffect(() => {
  if (open) {
    setProjectId(localStorage.getItem('salesOrderProjectId') || initialProjectId || '');
    setProjectName(localStorage.getItem('salesOrderProjectName') || initialProjectName || '');
    let edit =localStorage.getItem('isEditSalesOrder')
    setIsEditSalesOrder( edit || initialIsEditEstimate || false);
  }
}, [open]);
  const handleClose = () => {

    setProjectId("");
    setProjectName("");
    setIsEditSalesOrder("");
    setOpen(false);
    localStorage.removeItem('salesOrderProjectId');
    localStorage.removeItem('salesOrderProjectName');
    localStorage.removeItem('isEditSalesOrder');
    localStorage.removeItem('salesOrderOpenPopup',);
    // if (dataChanged) {
      setRenderCards(!renderCards);
      setDataChanged(false); // Reset the flag after triggering the re-render
    // }
    removePopupData()
  };
  const handleDataChange = () => {
    setDataChanged(true); 
    console.log('Entered=handleDataChange')
  };
  return (
    <>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar position="relative" mb={3}>
          <Toolbar>
            <Typography variant="h6" sx={{ flex: 1, ml: 2 }}>
              {projectName}
            </Typography>
              <CloseIcon onClick={handleClose} sx={{fontSize:'2.7rem',cursor:'pointer'}}/>
          </Toolbar>
        </AppBar>
        <MemoizedViewOfficialRequest
          popupOn={true}
          idOfProject={projectId}
          isEditSalesOrder={isEditSalesOrder}
          closeView={handleClose}
          setRenderCards={setRenderCards}
          renderCards={renderCards}
          onDataChange={handleDataChange} // Pass data change handler
        />
      </Dialog>
    </>
  );
}
const MemoizedViewOfficialRequest = React.memo(ViewOfficialRequest);

export default React.memo(FullScreenDialogSalesOrder);