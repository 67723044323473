import { useEffect, useRef, useState } from 'react'
import {
  Card,
  Typography,
  Stack,
  ImageList,
  ImageListItem,
  ListSubheader,
  ImageListItemBar,
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  AvatarGroup,
  Tooltip,
  Avatar,
  Snackbar,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
  ThumbUpAltOutlined,
  ThumbDownOffAltOutlined,
  CancelOutlined,
  Link,
  Notes,
  Description,
  PictureAsPdf,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  Info,
} from '@material-ui/icons'
import { sentenceCase } from 'change-case'
import moment from 'moment'
import Scrollbar from 'src/components/Scrollbar'
import {
  ref as imageref,
  uploadBytesResumable,
  getDownloadURL,
} from '@firebase/storage'
import { storage } from 'src/firebase/firebase'
import { REST_API_END_POINT, UNITS } from 'src/constants/Defaultvalues'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import { LoadingButton } from '@material-ui/lab'
import { UserMoreMenu } from '../user/list'
import Label from 'src/components/Label'
import Modal from '../blog/ProjectModel'
import { AccountNotifications } from '../user/account'
import { empty } from 'src/Helpers/Common'
import { Icon } from '@iconify/react'
import arrowLeftOutlined from '@iconify/icons-ant-design/arrow-left-outlined';
import arrowRightOutlined from '@iconify/icons-ant-design/arrow-right-outlined';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';

const RowResultStyle = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));

export default function viewRequest({
  designs=[],
  fromProposal,
  products,
  productsWithLocation,
  user,
  setDesigns,
  details,
  fetchRequestDetails,
  // fetchRequestDetailsNew,
  isRequest,
  requestId,
  signAgreements,
  customTemplate,
  questionnaireTemplate,
  allDataEmpty,
  portfolio,
  project,
  clientDetails,
  teamMembers,
  invoice,
  quotation,
  proposalView,
  setRenderCards,
  renderCards,
  rfpServicesEdit,
  requestServices,
  labourInstallService
}) {
  const fileInputRef = useRef(null)
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const {
    name,
    notes,
    description,
    services,
    submission_deadline,
    participation_ends,
    status,
    reason,
    reasonWithQb,
    estimate_id,
    added_by,
    awarded,
    rfpQbId,
  } = details
  console.log('designsdesignsdesignsdesigns', labourInstallService)
  const submissionDate = moment.unix(submission_deadline).format('MM/DD/YYYY')
  const endDate = moment.unix(participation_ends).format('MM/DD/YYYY')
  const xs = useMediaQuery(theme.breakpoints.down('xs'))
  const sm = useMediaQuery(theme.breakpoints.down('sm'))
  const mdd = useMediaQuery(theme.breakpoints.down('md'))
  const xld = useMediaQuery(theme.breakpoints.up('xl'))
  const [progress, setProgress] = useState(false)
  const [modalClose, setModalClose] = useState(false)
  const [openProductModel, setOpenProductModel] = useState(false)
  const [openReasonModal, setOpenReasonModal] = useState(false)
  const [itemId, setItemId] = useState(null)
  const [reasonText, setReasonText] = useState('')
  const [openImageModal,setOpenImageModal] = useState(false)
  const [currentImageIndex, setCurrentImageIndex] = useState(null);

  const [additionalSelectedServices, setAdditionalSelectedServices] = useState([]);
  const [invoiceSelectedServices, setInvoiceSelectedServices] = useState([]);
  const [cartService, setCartService] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const userType = user.user_type
  let subTotal = 0
  console.log('#Invoice##########', invoice)

    // The below useEffect is used for fetching the invoice details only
    useEffect(() => {
      console.log('requestServices--', requestServices);
  
      const rfqServicesData = invoice?.cust_product?.flatMap(product => {
        console.log('product--', product)
        return product.services.map(service => ({
          ...service,
          productIndex: product.product_index,
          product_id: product.product_id,
          qtyData: product.quantity,
          locationQuantity: product.locationQuantity || 1
  
        }));
      });
      console.log("##################rfqInvoiceServiceData", rfqServicesData);
      const newrfqServicesData = rfqServicesData?.filter(item => {
        const newQty = parseFloat((item.prQty * item.qtyData * item.locationQuantity)?.toFixed(2));
        console.log('rfqInvoiceServiceDatanewQty-----------', newQty);
        console.log('rfqInvoiceServiceDataitem.qty-----------', item.qty);
        return newQty !== item.qty;
      }).map(item => {
        const newQty = item.prQty * item.qtyData * item.locationQuantity;
        let quantityDifference = 0;
        if(details?.job_category_name === 'Time & Material'){
          quantityDifference = 0;
        }else{
          quantityDifference = item.qty - newQty;
        }
        return {
          ...item,
          quantity: quantityDifference,
          quantityDifference: quantityDifference,
          total_cost: item.unit_cost * quantityDifference
        };
      });
  
      console.log('newrfqServicesData-------', newrfqServicesData);
  
      setInvoiceSelectedServices(newrfqServicesData || [])
  
      const additionalServices = requestServices?.filter(item => item?.recipe_id === null);
      const additionalServicesData = additionalServices || [];
      const updatedServicesData = additionalServicesData?.map((serviceItem)=>{
        return{
          ...serviceItem,
          productId : serviceItem.labour_cost_service_id,
          addresses: [
            {
              address: serviceItem.address,
              city: serviceItem.city,
              state: serviceItem.state,
              zip: serviceItem.zip,
            }
          ]
        }
      })
      console.log('updatedServicesData----',updatedServicesData);
      setAdditionalSelectedServices(updatedServicesData);
      const rfpServices = rfpServicesEdit?.filter(item => item?.recipe_id === null && item?.rfp === 1);
     
      setCartService(rfpServices)
    }, [requestServices, rfpServicesEdit]);


    const initializeSelectedProducts = (item) => {
      console.log("done 1",item)
      const preSelected = item.location_ids
        ? item.location_ids.split(',').map(id => ({
            id: item.id,
            product_id: parseInt(id.trim(), 10),
            product_name: productsWithLocation?.find(p => p.request_location_id === parseInt(id.trim(), 10))?.product_name || '',
            reason: null,
          }))
        : [];
    
      setSelectedProducts(preSelected);
    };
    

    // const handleCheckboxChange = (product, isChecked,item) => {
    //   console.log('productproduct',product)
    //   setSelectedProducts((prevSelected) => {
    //     if (isChecked) {
    //       // Add product to state if checked
    //       return [...prevSelected, { 
    //         id: item.id, 
    //         // product_id: product.product_id, 
    //         product_id: product.id, 
    //         product_name: product.product_name, 
    //         reason: null, 
    //       }];
    //     } else {
    //       // Remove product from state if unchecked
    //       return prevSelected.filter(
    //         (selected) => selected.product_id !== product.id
    //       );
    //     }
    //   });
    // };
    

    // ✅ Updated `handleCheckboxChange` to handle pre-selected products
const handleCheckboxChange = (product, isChecked, item) => {
  setSelectedProducts((prevSelected) => {
    if (isChecked) {
      // ✅ Add product if checked (avoid duplicates)
      if (!prevSelected.some((p) => p.product_id === product.request_location_id)) {
        return [...prevSelected, { 
          id: item.id, 
          product_id: product.request_location_id, 
          product_name: product.product_name, 
          reason: null, 
        }];
      }
      return prevSelected;
    } else {
      // ❌ Remove product if unchecked
      return prevSelected.filter(
        (selected) => selected.product_id !== product.request_location_id
      );
    }
  });
};


    const handleSubmit = async (attacmentId) => {
      console.log('Selected Products:', selectedProducts);
      let requestId = details.id
      let userId = user.user_id
      let designId =attacmentId
      const response = await axios.post(`${REST_API_END_POINT}request/update-multi-designs`,{requestId,userId,data:selectedProducts,designId})
      if(response.data.status==1){
        enqueueSnackbar( `Design has been linked Successfully`,{ variant: 'success' },)
        fetchRequestDetails()
      }else{
        enqueueSnackbar(response.data.message, { variant: 'error' })
      }
      setModalClose(false)
      setOpenProductModel(false)
    };

  const handleUpdateDesign = (data) => {
    console.log("numberrrrrrrrrrr",data?.num)
    
    setModalClose(true)
    data.requestId = details.id
    data.userId = user.user_id
    if (data.approved) data.approved_by = user.user_id
    if (data.approved === 0 && reasonText === '') {
      enqueueSnackbar('Please add Reason', { variant: 'error' })
      return
    }
    let productName = ''
    if (data?.hasOwnProperty('product_name')) {
      productName = data?.product_name
      delete data?.product_name
    }

    axios
      .post(`${REST_API_END_POINT}request/update-designs`, data)
      .then((res) => {
        if (res.data.status === 1) {
          enqueueSnackbar(
            data.approved === 1
              ? 'Design has been Approved'
              : data.product_id
              ? `Design has been linked with ${productName}`
              : data?.status == 0
              ? 'Design has been Deleted'
              : 'Design has been Rejected',
            { variant: 'success' },
          )
          // fetchRequestDetailsNew()
          fetchRequestDetails()
        } else {
          enqueueSnackbar(res.data.message, { variant: 'error' })
        }
        setItemId(null)
        setModalClose(false)
        setOpenProductModel(false)
        setOpenReasonModal(false)
        setReasonText('')
      })
  }
  const handleFileChange = async (event) => {
    const file = event.target.files[0]
    const fileExtension = file?.name?.split('.')?.pop()?.toLowerCase()
    const Filename = new Date().getTime() + file?.name

    // const isImage = file?.type?.startsWith('image/')
    // const isPdf = file?.type === 'application/pdf'
    const isImage =
    file?.type?.startsWith('image/') ||
    file?.type === 'img' ||
    file?.type === 'image'
  const isPdf = file?.type === 'pdf' || file?.type === 'application/pdf' || file?.type === "other"

    if (!isImage && !isPdf) {
      enqueueSnackbar(
        'Please select a valid file. Only Images and PDF are allowed',
        { variant: 'error' },
      )
      return
    }
    const StorageRef = imageref(storage, Filename)
    const uploadTask = uploadBytesResumable(StorageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const pro = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        )
        setProgress(true)
      },
      (error) => {
        console.error(error.message)
        setProgress(false)
        enqueueSnackbar('Please upload Image or PDF less than 5 MB', {
          variant: 'error',
        })
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          const uploaded = [downloadURL]
          const requestId = details.id
          const data = {
            requestId,
            userId: user.user_id,
            uploaded,
            type: file.type,
            size: file.size,
            extension: fileExtension,
          }
          // axios
          //   .post(`${REST_API_END_POINT}request/upload-designs`, data)
          //   .then((res) => {
          //     if (res.data.status === 1) {
          //       setDesigns((pv) => [
          //         ...pv,
          //         ...res.data.data.map((d) => ({
          //           ...d,
          //           updated_by: user.person_name,
          //         })),
          //       ])
          axios.post(`${REST_API_END_POINT}request/upload-designs`, data)
          .then((res) => {
            if (res.data.status === 1) {
              // setDesigns((prev) => [
              //   ...(Array.isArray(prev) ? prev : []), // Ensure prev is an array
              //   ...((Array.isArray(res?.data?.data) ? res?.data?.data : [])).map((d) => ({
              //     ...d,
              //     updated_by: user.person_name,
              //   })),
              // ]);
              setRenderCards(!renderCards)
                enqueueSnackbar(res.data.message, { variant: 'success' })
              } else {
                enqueueSnackbar(res.data.message, { variant: 'error' })
              }
            })
          setProgress(false)
          // fetchRequestDetailsNew()
          fetchRequestDetails()
        })
      },
    )
  }
  const approvedCount = designs?.filter((item) => item.approved === 1)?.length
  const linkedCount = designs?.filter((item) => item.approved === 0 )?.length
  const rejectedCount = designs?.filter((item) => item.reason !== null)?.length

  let borderColor

  if (rejectedCount > 0) {
    borderColor = theme.palette.error.main
  } else {
    borderColor = theme.palette.success.main
  }
  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setOpenImageModal(true);
  };

  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex < imageDesigns.length - 1 ? prevIndex + 1 : prevIndex));
  };

  const imageDesigns = designs?.filter(
    (item) => item.file_type?.startsWith('image/') || item.file_type === 'img' || item.file_type === 'image'
  );

  return (
    <Card>
      <Stack sx={{ m: 3 }} spacing={3}>
        <Stack
          direction={{ xs: 'row', sm: 'row' }}
          sx={{ justifyContent: 'space-between' }}
          spacing={{ xs: 3, sm: 2 }}
        >
          <Typography variant="overline" sx={{ color: 'text.secondary' }}>
            {sentenceCase(name)}
          </Typography>
          {reason && (
            <Typography variant="subtitle2" sx={{ color: 'GrayText' }}>
              Reason :{reason}
            </Typography>
          )}
        </Stack>
        {added_by == 1 && user.user_type != 3 && (
          <Typography
            variant="subtitle2"
            sx={{
              color: `${
                (proposalView ? !rfpQbId : reasonWithQb || !estimate_id)
                  ? 'red'
                  : 'green'
              }`,
            }}
          >
            {/* {reasonWithQb? reasonWithQb : 'Successfully Synced With QuickBooks'} */}
            {proposalView && awarded == null
              ? ''
              : proposalView
              ? reasonWithQb
                ? reasonWithQb.split('\n').map((line, index) => (
                    <span key={index} style={{ display: 'block' }}>
                      {line}
                    </span>
                  ))
                : 'Not Synced With QuickBooks. A Network Issue has Occurred. Please try again...'
              : estimate_id
              ? 'Successfully Synced With QuickBooks'
              : reasonWithQb
              ? reasonWithQb.split('\n').map((line, index) => (
                  <span key={index} style={{ display: 'block' }}>
                    {line}
                  </span>
                ))
              : 'Not Synced With QuickBooks. A Network Issue has Occurred. Please try again...'}
          </Typography>
        )}
        <Stack
          direction={{ xs: 'column', sm: 'row', md: 'row' }}
          sx={{ justifyContent: 'space-between' }}
          spacing={{ xs: 3, sm: 2 }}
        >
          {!proposalView && (
            <Box>
              <Typography variant="subtitle2" sx={{ color: 'GrayText' }}>
                Client Name : {clientDetails?.customer_name}
              </Typography>
              <Typography variant="subtitle2" sx={{ color: 'GrayText' }}>
                Client Address : {clientDetails?.customer_address}
              </Typography>
              <Typography variant="subtitle2" sx={{ color: 'GrayText' }}>
                Client Phone : {clientDetails?.customer_phone}
              </Typography>
            </Box>
          )}
          <Box sx={{ minWidth: 200, borderColor: 'GrayText' }}>
            {/* empty(quotation)&& details?.job_category_name!='Service only' && details?.job_category_name!='Time & Material' && */}
            {invoice && invoice?.total != 0 && user.user_type != 3 && (
              <>
                <Typography alignItems={'center'} variant="subtitle1">
                  Staff Members
                </Typography>
                <Scrollbar sx={{ width: '100%', height: 100 }}>
                  <Stack>
                    {teamMembers?.map((row) => (
                      <Stack
                        direction={{ xs: 'column', sm: 'row', md: 'row' }}
                        mb={1}
                        spacing={{ xs: 3, sm: 2 }}
                      >
                        <Avatar
                          src={row?.profile_pic}
                          alt={row?.first_name + ' ' + row?.last_name}
                        />
                        <Typography variant="caption">
                          {row?.first_name + ' ' + row?.last_name}
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>
                </Scrollbar>
              </>
            )}
          </Box>
        </Stack>
        <Stack
          direction={{ xs: 'row', sm: 'row' }}
          sx={{ justifyContent: 'space-between' }}
          spacing={{ xs: 3, sm: 2 }}
        >
          <Box sx={{ width: '60%' }}>
            <Stack sx={{ flexDirection: 'row' }}>
              <Tooltip title="Description">
                <Description />
              </Tooltip>
              <Typography
                variant="body2"
                sx={{ color: 'text.secondary', flexWrap: 'wrap', ml: 2 }}
              >
                {description}
              </Typography>
            </Stack>
          </Box>
          {/* {invoice?<Typography variant="caption" sx={{ color: 'text.secondary' }}>
            {invoice[0].name}
          </Typography>:""} */}
          <Box sx={{ width: '35%' }}>
            {!user.isVendor && empty(quotation) && (
              <Stack>
                <Typography variant="overline" sx={{ color: 'text.secondary' }}>
                  Requested Start Date: {submissionDate}
                </Typography>
                <Typography variant="overline" sx={{ color: 'text.secondary' }}>
                  Requested Installation Date:{endDate}
                </Typography>
              </Stack>
            )}
          </Box>
        </Stack>
        <Stack
          direction={{ xs: 'row', sm: 'row' }}
          // sx={{ justifyContent: 'space-between' }}
          spacing={{ xs: 3, sm: 2 }}
        >
          {notes ? (
            <>
              <Tooltip title="Notes">
                <Notes />
              </Tooltip>
              <Typography variant="caption" sx={{ flexWrap: 'wrap' }}>
                {notes}
              </Typography>
            </>
          ) : (
            ''
          )}
        </Stack>
        {/* <Grid container spacing={2}> */}
          {/* <Scrollbar> */}
          {(project || fromProposal) ? null :
            <Stack>
              <Box
              sx={{
                border: '1px solid',
                borderColor: theme.palette.divider,
                padding: 2,
                overflow: 'auto'
              }}
            >
                {((details?.job_category_name == 'Time & Material') && (details?.allInstallationCompleted == 1)) ? 
                  <>
                    {(!empty(invoice?.jobcost_product)) ? 
                    <>
                    <Typography variant='h6' sx={{ mt: 2 }}>Materials</Typography> 
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>NAME</TableCell>
                            <TableCell>UNIT COST</TableCell>
                            <TableCell>QUANTITY</TableCell>
                            <TableCell>TOTAL COST</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {invoice?.jobcost_product?.map((val,index) => {
                            const { id, product_name, unit_price, quantity, total, unit,partial_unit, unit_qty } = val
                            let unitToUse = partial_unit ? partial_unit : unit
                            let unitName = UNITS?.filter((item) => item?.id == unitToUse)
                            let shortUnitName = unitName[0]?.short_name || 'Unit'
                            unitName = unitName[0]?.name
                            return (
                              <TableRow sx={{
                                borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                              }}>
                                <TableCell colSpan={2}>{product_name}</TableCell>
                                <TableCell> {unit_price}</TableCell>
                                <TableCell>{quantity}({shortUnitName})</TableCell>
                                <TableCell> {total}</TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    </>
                    : ''}
                    {invoice?.jobcost_service?.length > 0 ? <Typography variant='h6' sx={{ mt: 2 }}>Services</Typography> : ''}
                    <TableContainer>
                        <Table>
                        {invoice?.jobcost_service?.length > 0 ? 
                        <>
                          <TableHead>
                            <TableRow>
                              <TableCell colSpan={2}>NAME</TableCell>
                              <TableCell>HOURLY COST</TableCell>
                              <TableCell>HOURS</TableCell>
                              <TableCell>TOTAL COST</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {invoice?.jobcost_service?.map((ser,index) => {
                              return (
                                <TableRow sx={{
                                  borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                                }}>
                                  <TableCell colSpan={2}>{ser?.name}</TableCell>
                                  <TableCell>{ser ? parseFloat(ser?.service_original_cost)?.toFixed(2) : 0}</TableCell>
                                  <TableCell>{ser ? parseFloat(ser?.jobcost_quantity)?.toFixed(2) : 0}</TableCell>
                                  <TableCell>{ser ? ser?.total_service_cost : 0}</TableCell>
                                </TableRow>
                              )
                            })}
                          </TableBody>
                        </> : ''}
                          <RowResultStyle>
                            <TableCell colSpan={3} />
                            <TableCell align="right">
                              <Typography variant="overline">Subtotal</Typography>
                            </TableCell>
                            <TableCell align="left" width={10}>
                              <Typography variant="overline">{invoice?.jobcostTotal ? invoice?.jobcostTotal?.toFixed(2) : 0.00}</Typography>
                            </TableCell>
                          </RowResultStyle>

                          <RowResultStyle>
                            <TableCell colSpan={3} />
                            <TableCell align="right">
                              <Typography variant="overline">{'Services'}</Typography>
                            </TableCell>
                            <TableCell align="left" width={10}>
                              <Typography variant="overline">{invoice?.jobcostServiceTotal ? invoice?.jobcostServiceTotal?.toFixed(2) : 0.00}</Typography>
                            </TableCell>
                          </RowResultStyle>

                          <RowResultStyle>
                            <TableCell colSpan={3} />
                            <TableCell align="right">
                              <Typography variant="overline">Discount</Typography>
                            </TableCell>
                            <TableCell align="left" width={10}>
                              <Typography variant="overline">{invoice ? invoice?.extra_discount?.toFixed(2) : 0.00}</Typography>
                            </TableCell>
                          </RowResultStyle>

                          <RowResultStyle>
                            <TableCell colSpan={3} />
                            <TableCell align="right">
                              <Typography variant="overline">Tax</Typography>
                            </TableCell>
                            <TableCell align="left" width={10}>
                              <Typography variant="overline">{invoice ? invoice?.tax_amount?.toFixed(2) : 0.00}</Typography>
                            </TableCell>
                          </RowResultStyle>

                          <RowResultStyle>
                            <TableCell colSpan={3} />
                            <TableCell align="right">
                              <Typography variant="subtitle1"  sx={{ color: invoice?.grandTotal < 0 ? 'red' : 'inherit' }}>Total</Typography>
                            </TableCell>
                            <TableCell align="left" width={10}>
                              <Typography variant="subtitle1"  sx={{ color: invoice?.grandTotal < 0 ? 'red' : 'inherit' }}>{invoice?.grandTotal ? invoice?.grandTotal?.toFixed(2) : 0.00}</Typography>
                            </TableCell>
                          </RowResultStyle>
                          {invoice?.project_category == 13 ? '' : 
                            <>
                            {(userType !=3 && details?.job_category_name != 'Time & Material' && details?.job_category_name != 'No Charge') ?
                            <RowResultStyle>
                              <TableCell colSpan={3} />
                              <TableCell align="right" sx={{display:"flex"}}>
                              <Typography fontSize={"small"} variant="caption"  sx={{ color:  'inherit' }}> (only viewable for admin)</Typography>
                                <Typography variant="subtitle1"  sx={{ color:  '#12b2e3' }}>Billing Type</Typography>
                              </TableCell>
                              <TableCell align="left" width={10}>
                                <Typography variant="subtitle1"  sx={{ color:  '#12b2e3' }}>{details?.billing_type ? `${details?.billing_type} %` :"Nill"}</Typography>
                              </TableCell>
                            </RowResultStyle>
                            :""}
                          {
                          (invoice?.total && details?.billing_type)?

                            <RowResultStyle>
                              <TableCell colSpan={3} />
                              <TableCell align="right">
                                <Typography variant="subtitle1"  sx={{ color:  'yellow' }}>Minimum Payable Amount</Typography>
                              </TableCell>
                              <TableCell align="left" width={10}>
                                <Typography variant="subtitle1"  sx={{ color:  'yellow' }}>{(invoice?.total && details?.billing_type)? parseFloat((invoice?.total *details?.billing_type) / 100).toFixed(2) :0.00}</Typography>
                              </TableCell>
                            </RowResultStyle>
                            :""
                            }

                              <RowResultStyle>
                                <TableCell colSpan={3} />
                                <TableCell align="right">
                                  <Typography variant="subtitle1"  sx={{ color: details?.totalPaidAmount < 0 ? 'red' : 'green' }}>Amount Paid</Typography>
                                </TableCell>
                                <TableCell align="left" width={10}>
                                  <Typography variant="subtitle1"  sx={{ color: details?.totalPaidAmount < 0 ? 'red' : 'green' }}>{details?.totalPaidAmount ? parseFloat(details?.totalPaidAmount)?.toFixed(2) : 0.00}</Typography>
                                </TableCell>
                              </RowResultStyle>
                              <RowResultStyle>
                                <TableCell colSpan={3} />
                                <TableCell align="right">
                                  <Typography variant="subtitle1"  sx={{ color: invoice?.newBalanceDue <= 0 ? 'inherit' : 'red' }}>Balance Due</Typography>
                                </TableCell>
                                <TableCell align="left" width={10}>
                                  <Typography variant="subtitle1"  sx={{ color: invoice?.newBalanceDue <= 0 ? 'inherit' : 'red' }}>{invoice?.newBalanceDue ? parseFloat(invoice?.newBalanceDue)?.toFixed(2) : 0.00}</Typography>
                                </TableCell>
                              </RowResultStyle>
                            </>
                          }
                      </Table>
                    </TableContainer>
                    {(details?.allInstallationCompleted == 1 && !empty(invoice?.note_data)) ? (
                      <>
                        <Typography variant="h6" sx={{ mt: 2, mb: 1, fontWeight: 'bold' }}>Installation Notes</Typography>
                        <Stack spacing={2}>
                          {invoice?.note_data?.map((val, index) => {
                            const { id, authorized_by, note, timestamp } = val;
                            return (
                              <Paper
                                key={id}
                                elevation={3}
                                sx={{ padding: 2, borderRadius: 2, backgroundColor: 'rgba(145, 158, 171, 0.16)', color: '#ffffff' }}
                              >
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={8}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                      {note}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={4} sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                    {/* <Typography variant="body2" sx={{ color: 'primary.light' }}> */}
                                    <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
                                      Added By: {authorized_by}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
                                      {moment.unix(timestamp).format('MM-DD-YYYY hh:mm:ss A')}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Paper>
                            );
                          })}
                        </Stack>
                      </>
                    ) : ''}
                  </> :
                  <>
                  {(invoice?.cust_product?.length > 0) ? <>
                  <Typography variant='h6'>{details?.job_category_name != 'Time & Material' ? 'Products' : 'Materials'}</Typography>
                  <TableContainer sx={{ minWidth: 420, mt: 3 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{details?.job_category_name != 'Time & Material' ? 'PRODUCT & NOTE' : 'MATERIAL' }</TableCell>
                          <TableCell>UNIT PRICE</TableCell>
                          <TableCell align="center">UNIT</TableCell>
                          <TableCell>QTY</TableCell>
                          {/* <TableCell>MARKETING ADJUSTMENT (%)</TableCell> */}
                          <TableCell>SUB TOTAL</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {invoice?.cust_product?.map((val) => {
                          const { vid, product_name, product_notes, product_price, product_retail_price, adjustment, 
                            quantity, locationQuantity, product_total, discount, unit, unit_qty, material,rfp,markAdjType,
                            services = [],
                          } = val
                          console.log('val--------------',val);
                          const labourService = services?.find(service => service.labour_cost_service_id === 10);
                          const minusAmountLabourInstall = labourService?.total_cost || 0;

                          let unitName = UNITS?.filter((item) => item?.id == unit)
                          unitName = unitName[0]?.name
                          let shortUnitName = ''
                          if (unitName == 'Square feet') {
                            shortUnitName = 'Sq ft'
                          } else if (unitName == 'Lineal feet') {
                            shortUnitName = 'Lin ft'
                          } else if (unitName == 'Square Inches') {
                            shortUnitName = 'Sq Inches'
                          } else {
                            shortUnitName = 'Unit'
                          }
                          //  subTotal += product_total    
                          let SPMA = 0
                          if(markAdjType==2 || markAdjType==3){
                            SPMA = (product_retail_price || 0) +  (adjustment ? Number(adjustment) : 0)
            
                          }else{

                            SPMA = (product_retail_price || 0) + (((product_retail_price || 0) * (adjustment ? Number(adjustment) : 0)) / 100)
                          }
                          SPMA = parseFloat(SPMA)?.toFixed(2)                 
                          return (

                            <TableRow key={vid} sx={{
                              borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                            }}>
                              <TableCell width={'25%'} sx={{ flexWrap: 'wrap', overflow: 'hidden' }}>
                                <Typography variant='subtitle2' sx={{ fontWeight: 'bold', mb: 1, wordBreak: 'break-word', overflowWrap: 'break-word' }}>{product_name}</Typography>
                                {product_notes ? 
                                <Stack direction={'column'}>
                                  <Typography variant='body2' sx={{textDecoration: 'underline'}}>Note : </Typography> 
                                  <Typography variant='body2' sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>{product_notes}</Typography> 
                                </Stack>
                                : ''}
                                {(material === 1 && details?.job_category_name != 'Time & Material') ? 
                                  <Typography variant='body2' sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>(Addl. Material)</Typography> 
                                : ''}
                              </TableCell>
                              <TableCell>{SPMA ? SPMA : 0.00}  {minusAmountLabourInstall && minusAmountLabourInstall > 0? "(Included Labor Install)" :""}</TableCell>
                              <TableCell align="center">{quantity}({shortUnitName})</TableCell>
                              <TableCell>{locationQuantity}</TableCell>
                              {/* <TableCell>{discount ? discount?.toFixed(2) : 0.00}</TableCell> */}
                              {/* <TableCell>{(material === 1 || rfp === 1) ? 0.00 : adjustment ? Number(adjustment)?.toFixed(2) : 0.00}</TableCell> */}
                              
                              {markAdjType==1?
                            <TableCell>{product_total ?( product_total - minusAmountLabourInstall)?.toFixed(2) : 0.00}</TableCell>
                          :
                          <TableCell>{((SPMA * (locationQuantity * quantity))-minusAmountLabourInstall).toFixed(2)}</TableCell>

                          }
                              {/* <TableCell>{product_total ? product_total?.toFixed(2) : 0.00}</TableCell> */}
                            </TableRow>
                          )
                        }
                        )}

                      </TableBody>
                    </Table>
                  </TableContainer>
                  </> : null}

{/*below for labour Install */}
                  <Typography variant='h6' sx={{ mt: 2 }}>{(labourInstallService?.length > 0 ) ? ((details?.job_category_name != 'Service only' && details?.job_category_name != 'Time & Material') ? "Services(Labor Install)" : 'Services(Labor Install)') : ""}</Typography>
                  <TableContainer sx={{ minWidth: 420, mt: 3 }}>
                    <Table>
                      {(labourInstallService?.length > 0 ) &&
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>NAME</TableCell>
                            <TableCell>COST (HOURLY)</TableCell>
                            <TableCell>HOURS</TableCell>
                            <TableCell>TOTAL COST</TableCell>
                          </TableRow>
                        </TableHead>}
                      <TableBody>
                      
                      {labourInstallService?.map((ser) => {
                          console.log('invoiceSelectedServices--@@ 1', ser);
                          return (
                            <TableRow sx={{
                              borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                            }}>
                              <TableCell colSpan={2}>{ser?.name}-({ser.productName})</TableCell>
                              <TableCell>{ser ? ser?.unit_cost?.toFixed(2) : 0}</TableCell>
                              <TableCell>{ser ? ser?.quantity?.toFixed(2) : 0}</TableCell>
                              <TableCell>{ser ? ser?.total_cost?.toFixed(2) : 0}</TableCell>
                            </TableRow>
                          )
                        })}
                        </TableBody>
                        </Table>
                        </TableContainer>

{/*above  for labour Install */}


                  <Typography variant='h6' sx={{ mt: 2 }}>{(additionalSelectedServices?.length > 0 || cartService?.length > 0 || invoiceSelectedServices?.length > 0) ? ((details?.job_category_name != 'Service only' && details?.job_category_name != 'Time & Material') ? "Additional Services" : 'Services') : ""}</Typography>
                  <TableContainer sx={{ minWidth: 420, mt: 3 }}>
                    <Table>
                      {(additionalSelectedServices?.length > 0 || cartService?.length > 0 || invoiceSelectedServices?.length > 0) &&
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>NAME</TableCell>
                            <TableCell>COST (HOURLY)</TableCell>
                            <TableCell>HOURS</TableCell>
                            <TableCell width={'15%'}>TOTAL COST</TableCell>
                          </TableRow>
                        </TableHead>}
                      <TableBody>
                        {invoice?.cust_product?.map((val) => {
                          const { vid, product_name, product_price, product_retail_price,
                            quantity, product_total, discount, unit, unit_qty,markAdjType,adjustment,locationQuantity
                          } = val
                          let unitName = UNITS?.filter((item) => item?.id == unit)
                          unitName = unitName[0]?.name
                          let shortUnitName = ''
                          if (unitName == 'Square feet') {
                            shortUnitName = 'Sq ft'
                          } else if (unitName == 'Lineal feet') {
                            shortUnitName = 'Lin ft'
                          } else if (unitName == 'Square Inches') {
                            shortUnitName = 'Sq Inches'
                          } else if (unitName == 'Unit') {
                            shortUnitName = 'Unit'
                          }

                          let SPMA = 0
                        if(markAdjType==2 || markAdjType==3) {
                          SPMA = (product_retail_price || 0) + (adjustment ? Number(adjustment) : 0)
                        }else{
                          SPMA = (product_retail_price || 0) + (((product_retail_price || 0) * (adjustment ? Number(adjustment) : 0)) / 100)

                        }
                        SPMA = parseFloat(SPMA)?.toFixed(2)  

                        let theSubTotalAmount =markAdjType==1?product_total: (SPMA * (locationQuantity * quantity))

                          subTotal += theSubTotalAmount
                          return (
                            null
                          )
                        })}

                        {invoiceSelectedServices?.map((ser) => {
                          console.log('invoiceSelectedServices--@@', ser);
                          return (
                            <TableRow sx={{
                              borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                            }}>
                              <TableCell colSpan={2}>{ser?.name}</TableCell>
                              <TableCell>{ser ? ser?.unit_cost?.toFixed(2) : 0}</TableCell>
                              <TableCell>{ser ? ser?.quantity?.toFixed(2) : 0}</TableCell>
                              <TableCell>{ser ? ser?.total_cost?.toFixed(2) : 0}</TableCell>
                            </TableRow>
                          )
                        })}

                        {additionalSelectedServices
                          ?.filter(servItem=>{
                            if (details?.job_category_name != 'Time & Material' && servItem.labour_cost_service_id == 10) {
                              return false;
                            }
                            return true;
                          })?.map((ser) => {
                            console.log('additionalSelectedServices--', ser);
                            return (
                              <TableRow sx={{
                                borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                              }}>
                                <TableCell colSpan={2}>{ser?.name}</TableCell>
                                <TableCell>{ser ? ser?.unit_cost?.toFixed(2) : 0}</TableCell>
                                <TableCell>{ser ? ser?.quantity?.toFixed(2) : 0}</TableCell>
                                <TableCell>{ser ? ser?.total_cost?.toFixed(2) : 0}</TableCell>
                              </TableRow>
                            )
                          }
                        )}

                        {cartService?.map((ser) => {
                          ser.sellingPrice = ser.sellingPrice || ser.unit_cost
                          ser.originalMarkup = ser.originalMarkup || ser.product_markup
                          return (
                            <TableRow sx={{
                              borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                            }}>
                              <TableCell colSpan={2}>{ser?.name}</TableCell>
                              <TableCell> {((ser.sellingPrice + (ser.sellingPrice * ser.originalMarkup / 100)))?.toFixed(2)}</TableCell>
                              <TableCell>{ser ? ser?.qty?.toFixed(2) : 0}</TableCell>
                              <TableCell> {(ser.qty * (ser.sellingPrice + (ser.sellingPrice * ser.originalMarkup / 100)))?.toFixed(2)}</TableCell>
                            </TableRow>
                          )
                        }
                        )}
                        {(details?.job_category_name != 'Service only' || subTotal > 0) &&
                        <RowResultStyle>
                          <TableCell colSpan={3} />
                          <TableCell align="right">
                            <Typography variant="overline">Subtotal</Typography>
                          </TableCell>
                          <TableCell align="left" width={10}>
                            <Typography variant="overline">{subTotal ? subTotal?.toFixed(2) : 0.00}</Typography>
                          </TableCell>
                        </RowResultStyle>}

                        <RowResultStyle>
                          <TableCell colSpan={3} />
                          <TableCell align="right">
                            {/* <Typography variant="overline">{details?.job_category_name != 'Service only' ? 'Additional Labor Cost' : 'Labor Cost'}</Typography> */}
                            <Typography variant="overline">{(details?.job_category_name != 'Service only' && details?.job_category_name != 'Time & Material') ? 'Additional Services' : 'Services'}</Typography>
                          </TableCell>
                          <TableCell align="left" width={10}>
                            <Typography variant="overline">{details ? details?.labour_cost?.toFixed(2) : 0.00}</Typography>
                          </TableCell>
                        </RowResultStyle>

                        <RowResultStyle>
                          <TableCell colSpan={3} />
                          <TableCell align="right">
                            <Typography variant="overline">Discount</Typography>
                          </TableCell>
                          <TableCell align="left" width={10}>
                            <Typography variant="overline">{invoice ? invoice?.extra_discount?.toFixed(2) : 0.00}</Typography>
                          </TableCell>
                        </RowResultStyle>

                        <RowResultStyle>
                          <TableCell colSpan={3} />
                          <TableCell align="right">
                            <Typography variant="overline">Tax</Typography>
                          </TableCell>
                          <TableCell align="left" width={10}>
                            <Typography variant="overline">{invoice ? invoice?.tax_amount?.toFixed(2) : 0.00}</Typography>
                          </TableCell>
                        </RowResultStyle>

                        <RowResultStyle>
                          <TableCell colSpan={3} />
                          <TableCell align="right">
                            <Typography variant="subtitle1"  sx={{ color: invoice?.total < 0 ? 'red' : 'inherit' }}>Total</Typography>
                          </TableCell>
                          <TableCell align="left" width={10}>
                            <Typography variant="subtitle1"  sx={{ color: invoice?.total < 0 ? 'red' : 'inherit' }}>{invoice?.total ? invoice?.total?.toFixed(2) : 0.00}</Typography>
                          </TableCell>
                        </RowResultStyle>
                        {invoice?.project_category == 13 ? '' : 
                          <>
                            {
                              (userType !=3 && details?.job_category_name != 'Time & Material' && details?.job_category_name != 'No Charge') ?
                            <RowResultStyle>
                              <TableCell colSpan={3} />
                              <TableCell align="right" sx={{display:"flex", alignItems: "center",gap: 1}}>
                                <Typography fontSize={"small"} variant="caption"  sx={{ color:  'inherit' }}> (only viewable for admin)</Typography>
                                <Typography variant="subtitle1"  sx={{ color:  '#12b2e3' }}>Billing Type</Typography>
                              </TableCell>
                              <TableCell align="left" width={10}>
                                <Typography variant="subtitle1"  sx={{ color:  '#12b2e3' }}>{details?.billing_type ? `${details?.billing_type} %` :"Nill"}</Typography>
                              </TableCell>
                            </RowResultStyle>
                            :""}
                          {
                          (invoice?.total && details?.billing_type) ?
                            <RowResultStyle>
                              <TableCell colSpan={3} />
                              <TableCell align="right">
                                <Typography variant="subtitle1"  sx={{ color:  'yellow' }}>Minimum Payable Amount</Typography>
                              </TableCell>
                              <TableCell align="left" width={10}>
                                <Typography variant="subtitle1"  sx={{ color:  'yellow' }}>{(invoice?.total && details?.billing_type)? parseFloat((invoice?.total *details?.billing_type) / 100).toFixed(2) :0.00}</Typography>
                              </TableCell>
                            </RowResultStyle>
                            :""
                            }

                            <RowResultStyle>
                              <TableCell colSpan={3} />
                              <TableCell align="right">
                                <Typography variant="subtitle1"  sx={{ color: details?.totalPaidAmount < 0 ? 'red' : 'green' }}>Amount Paid</Typography>
                              </TableCell>
                              <TableCell align="left" width={10}>
                                <Typography variant="subtitle1"  sx={{ color: details?.totalPaidAmount < 0 ? 'red' : 'green' }}>{details?.totalPaidAmount ? parseFloat(details?.totalPaidAmount)?.toFixed(2) : 0.00}</Typography>
                              </TableCell>
                            </RowResultStyle>
                            <RowResultStyle>
                              <TableCell colSpan={3} />
                              <TableCell align="right">
                                <Typography variant="subtitle1"  sx={{ color: details?.dueAmount <= 0 ? 'inherit' : 'red' }}>Balance Due</Typography>
                              </TableCell>
                              <TableCell align="left" width={10}>
                                <Typography variant="subtitle1"  sx={{ color: details?.dueAmount <= 0 ? 'inherit' : 'red' }}>{details?.dueAmount ? parseFloat(details?.dueAmount <=0 ? 0.00:details?.dueAmount)?.toFixed(2) : 0.00}</Typography>
                              </TableCell>
                            </RowResultStyle>
                          </>
                        }
                        </TableBody>
                    </Table>
                  </TableContainer>
                  {(details?.allInstallationCompleted == 1 && !empty(invoice?.note_data)) ? (
                    <>
                      <Typography variant="h6" sx={{ mt: 2, mb: 1, fontWeight: 'bold' }}>Installation Notes</Typography>
                      <Stack spacing={2}>
                        {invoice?.note_data?.map((val, index) => {
                          const { id, authorized_by, note, timestamp } = val;
                          return (
                            <Paper
                              key={id}
                              elevation={3}
                              sx={{ padding: 2, borderRadius: 2, backgroundColor: 'rgba(145, 158, 171, 0.16)', color: '#ffffff' }}
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={8}>
                                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                    {note}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4} sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                  {/* <Typography variant="body2" sx={{ color: 'primary.light' }}> */}
                                  <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
                                    Added By: {authorized_by}
                                  </Typography>
                                  <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
                                    {moment.unix(timestamp).format('MM-DD-YYYY hh:mm:ss A')}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Paper>
                          );
                        })}
                      </Stack>
                    </>
                  ) : ''}
                  </>
                }
              </Box>
            </Stack>
          }
          {/* </Scrollbar> */}
        {/* </Grid> */}
        <Stack
          direction={'row'}
          alignItems={'center'}
          spacing={{ xs: 2, sm: 3 }}
          mb={2}
        >
          {empty(quotation) &&
            details?.job_category_name != 'Service only' &&
            details?.job_category_name != 'Time & Material' && (
              <ListSubheader component="div">Designs / Documents</ListSubheader>
            )}
          {empty(quotation) &&
            status !== 6 &&
            !user.isVendor &&
            !project &&
            details?.job_category_name != 'Service only' &&
            details?.job_category_name != 'Time & Material' && !details?.installationStatus &&(
              <LoadingButton
                variant="outlined"
                size="small"
                sx={{ height: 40 }}
                onClick={() => fileInputRef.current.click()}
                loading={progress}
              >
                Upload Designs / Documents
              </LoadingButton>
            )}
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
            accept="image/*,application/pdf"
          />
        </Stack>
        {designs && designs?.length && fromProposal ? (
          //  style={{ width: project ? '150px' :'240px', height: '240px' }}
          <Box
            sx={{ border: '1px solid', borderColor: borderColor, padding: 2 }}
          >
            <Scrollbar sx={{ width: '100%', height: 450 }}>
              <ImageList
                gap={mdd ? 8 : xld ? 12 : 8}
                cols={mdd ? 3 : xld ? 3 : xs ? 1 : 3}
              >
                {/* <ImageListItem key="Subheader" cols={2}> */}
                {/* </ImageListItem> */}
                {designs?.map((item) => {
                  const { file_type, name, url } = item
                  const isImage =
                    file_type?.startsWith('image/') ||
                    file_type === 'img' ||
                    file_type === 'image'
                  const isPdf = file_type === 'pdf' || file_type === 'application/pdf' || file_type === "other"
                  return (
                    <ImageListItem key={item.name}>
                      <Stack
                        sx={{
                          position: 'absolute',
                          zIndex: 9,
                          top: 8,
                          left: 10,
                        }}
                      >
                        {(item.approved === 1 && isImage) ? (
                          <Label
                            variant={'filled'}
                            color={'success'}
                            style={{ color: 'white', height: 20, width: 100 }}
                          >
                            {'Approved'}
                          </Label>
                        ) : item.product_id !== null ? (
                          ''
                        ) : // <Label
                        //   variant={'filled'}
                        //   color={'info'}
                        //   style={{ color: 'white', height: 20 }}
                        // >
                        //   {`Linked with ${item?.product_name}`}
                        // </Label>
                        item.reason ? (
                          <Label
                            variant={'filled'}
                            color={'error'}
                            style={{ color: 'white', height: 20, width: 100 }}
                          >
                            {'Rejected'}
                          </Label>
                        ) : null}
                      </Stack>
                      <Stack
                        sx={{
                          position: 'absolute',
                          zIndex: 9,
                          top: 110,
                          left: 10,
                        }}
                      >
                        {item.approved === 1 ? (
                          // <Box
                          //   sx={{
                          //     position: 'absolute',
                          //     zIndex: 9,
                          //     bottom: 8,
                          //     left: 100,
                          //   }}
                          // >
                          //     <Typography
                          //       fontSize={'0.7rem'}
                          //       variant={'caption'}
                          //       sx={{ color: 'text.primary' }}
                          //     >
                          //       Approved By: {item.approved_by}
                          //     </Typography>

                          // </Box>

                          <Typography
                            sx={{ position: 'absolute', top: 40, zIndex: 100 }}
                          >
                            <Label
                              variant={
                                theme.palette.mode === 'light'
                                  ? 'ghost'
                                  : 'filled'
                              }
                              color="success"
                            >
                              <Tooltip title={item.approved_by}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    fontSize: '12px',
                                    cursor: 'pointer',
                                    display: 'inline-block',
                                    maxWidth: 150, // Adjust as necessary
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <span
                                    style={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    Approved By: {item.approved_by}
                                  </span>
                                </Typography>
                              </Tooltip>
                            </Label>
                          </Typography>
                        ) : item.reason ? (
                          // <Box
                          //   sx={{
                          //     position: 'absolute',
                          //     zIndex: 9,
                          //     bottom: 8,
                          //     left: 100,
                          //   }}
                          // >
                          //   <Typography
                          //     fontSize={'0.7rem'}
                          //     variant={'caption'}
                          //     sx={{ color: 'text.error' }}
                          //   >
                          //     Rejected Reason: {item.reason}
                          //   </Typography>
                          // </Box>
                          <Typography
                            sx={{ position: 'absolute', top: 40, zIndex: 100 }}
                          >
                            <Label
                              variant={
                                theme.palette.mode === 'light'
                                  ? 'ghost'
                                  : 'filled'
                              }
                              color="error"
                            >
                              <Tooltip title={item.reason}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    fontSize: '12px',
                                    cursor: 'pointer',
                                    display: 'inline-block',
                                    maxWidth: 150, // Adjust as necessary
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <span
                                    style={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    Rejected Reason: {item.reason}
                                  </span>
                                </Typography>
                              </Tooltip>
                            </Label>
                          </Typography>
                        ) : (
                          ''
                        )}
                      </Stack>

                      {isImage ? (
                        <img
                          srcSet={`${item.name}?w=240&h=240&fit=crop&auto=format&dpr=2 2x`}
                          src={`${item.name}?w=240&h=240&fit=crop&auto=format`}
                          alt={item.title}
                          loading="lazy"
                          // style={{ width: '240px', height: '240px' }}
                        />
                      ) : isPdf ? (
                        <Box display="flex" alignItems="center">
                          {/* PDF Icon Button */}
                          <Tooltip title="View">
                            <IconButton
                              onClick={() => window.open(name, '_blank')}
                              aria-label="open pdf"
                              sx={{
                                position: 'absolute',
                                top: 60,
                                right: '50%',
                                zIndex: 200,
                                color: theme.palette.error.main,
                                backgroundColor: theme.palette.text.primary,
                              }}
                            >
                              <PictureAsPdf style={{ fontSize: 40 }} />
                            </IconButton>
                          </Tooltip>

                          {/* Iframe */}
                          <iframe
                            src={name}
                            title={name}
                            style={{
                              width: project ? '150px' : '240px',
                              height: '240px',
                            }}
                            frameBorder="0"
                          />
                        </Box>
                      ) : (
                        // <iframe
                        //   src={name}
                        //   title={name}
                        //   style={{ width: '240px', height: '240px' }}
                        //   frameBorder="0"
                        // />
                        // <div
                        //   style={{ justifyContent: 'center', alignItems:'center', cursor: 'pointer', width: '240px', height: '240px' }}
                        // >
                        // <IconButton
                        //   onClick={() => window.open(name, '_blank')}
                        //   aria-label="open pdf"
                        //   sx={{ borderWidth:0.5,borderColor:'white', justifyContent: 'center', alignItems:'center', cursor: 'pointer', width: '240px', height: '240px' }}
                        // >
                        //   <PictureAsPdf style={{ fontSize: 40, color:theme.palette.error.main }} />
                        // </IconButton>
                        // </div>
                        <Typography
                          sx={{
                            color: theme.palette.error.main,
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: project ? '150px' : '240px',
                            height: '240px',
                          }}
                        >
                          Unsupported file type
                        </Typography>
                        // <Snackbar
                        //   open={true}
                        //   message="Unsupported file type"
                        //   style={{ width: '240px', height: '240px' }}
                        //   autoHideDuration={6000}
                        // />
                      )}
                      <ImageListItemBar
                        sx={{
                          width: mdd
                            ? '92%'
                            : sm
                            ? '20%'
                            : xld
                            ? '70%'
                            : xs
                            ? '35%'
                            : '82%',
                        }}
                        title={item.updated_by}
                        subtitle={moment
                          .unix(item.timestamp)
                          .format('MM/DD/YYYY')}
                        actionIcon={
                          <>
                            {
                              !user.isVendor && !project && ''
                              // <UserMoreMenu
                              //   common={{
                              //     name: (item?.approved !== 1 && item?.product_id !== null) && 'Approve',
                              //     function:
                              //     (item?.approved !== 1 && item?.product_id !== null) ? () => {
                              //       setItemId(item.id)
                              //       setModalClose(true)
                              //       handleUpdateDesign({
                              //         id: item.id,
                              //         // product_id: product.product_id,
                              //         approved: 1,
                              //         reason:null
                              //       })
                              //       // setOpenProductModel(true)
                              //     } : null ,
                              //     Iccon:
                              //     (item?.approved !== 1 && item?.product_id !== null) && (
                              //         <ThumbUpAltOutlined />
                              //       ),
                              //     modalClose: modalClose,
                              //   }}
                              //   linkImageWithTask={{
                              //     name: item?.product_id == null && 'Link with catalog',
                              //     function:
                              //     item?.product_id == null ? () => {

                              //             setItemId(item.id)
                              //             setModalClose(true)
                              //             setOpenProductModel(true)
                              //           } : null ,
                              //     Iccon: item?.product_id == null && (
                              //         <Link />
                              //       ),
                              //     modalClose: modalClose,
                              //   }}

                              //   common2={{
                              //     name: (item?.approved === 1 || (item?.approved === 0 && item?.reason === null)) && 'Reject',
                              //     function:
                              //     (item?.approved === 1 || (item?.approved === 0 && item?.reason === null)) ? () => {
                              //               setItemId(item.id)
                              //               setOpenReasonModal(true)
                              //           } : null ,
                              //     Iccon:
                              //     (item?.approved === 1 || (item?.approved === 0 && item?.reason === null)) && (
                              //         <ThumbDownOffAltOutlined />
                              //       )
                              //       ,
                              //     modalClose: modalClose,
                              //   }}
                              //   //   onDelete={() => handleUpdateDesign({ id: item.id, status: 0 })}
                              //   {...(item.approved === 1
                              //     ? {}
                              //     : {
                              //         onDelete: () =>
                              //           handleUpdateDesign({
                              //             id: item.id,
                              //             status: 0,
                              //           }),
                              //       })}
                              // />
                            }
                          </>
                        }
                      />
                      {openProductModel && itemId === item.id && (
                        <Card
                          sx={{
                            position: 'absolute',
                            zIndex: 10,
                            bottom: 5,
                            left: 5,
                            p: 2,
                          }}
                        >
                          <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                          >
                            <Typography marginBottom={2} variant="subtitle2">
                              Choose Products
                            </Typography>
                            <CancelOutlined
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                setModalClose(false)
                                setOpenProductModel(false)
                              }}
                            />
                          </Stack>
                          <Divider sx={{ marginBottom: 2 }} />
                          <Stack spacing={1}>
                            {products
                              ?.filter(
                                (prodItem) =>
                                  prodItem?.material === 0 &&
                                  prodItem?.rfp === 0,
                              )
                              ?.map((product) => (
                                <>
                                  <Typography
                                    sx={{ cursor: 'pointer' }}
                                    variant="caption"
                                    onClick={() =>
                                      handleUpdateDesign({
                                        id: item.id,
                                        product_id: product.product_id,
                                        product_name: product.product_name,
                                        // approved: 1,
                                        reason: null,
                                        num:1
                                      })
                                    }
                                  >
                                    {product.product_name}
                                  </Typography>
                                  <Divider />
                                </>
                              ))}
                          </Stack>
                        </Card>
                      )}

                      <Modal
                        open={openReasonModal && itemId === item.id}
                        handleClose={() => {
                          setOpenReasonModal(false)
                        }}
                        modalTitle={`Reason for Design Rejection`}
                      >
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="Reason"
                              value={reasonText}
                              onChange={(e) => setReasonText(e.target.value)}
                            />
                            <Box
                              sx={{
                                mt: 3,
                                display: 'flex',
                                justifyContent: 'center',
                                gap: 1,
                              }}
                            >
                              <LoadingButton
                                type="submit"
                                variant="contained"
                                onClick={() =>
                                  handleUpdateDesign({
                                    id: itemId,
                                    product_id: null,
                                    product_name: null,
                                    reason: reasonText,
                                    approved: 0,
                                    num:2
                                  })
                                }
                                // loading={isSubmitting}
                              >
                                {' Save '}
                              </LoadingButton>
                              <Button
                                variant="outlined"
                                type="submit"
                                onClick={() => {
                                  setOpenReasonModal(false)
                                }}
                              >
                                Cancel
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Modal>
                    </ImageListItem>
                  )
                })}
              </ImageList>
            </Scrollbar>
          </Box>
        ) : (
          ''
        )}

        {designs && designs?.length && !fromProposal ? (
          <Box
            sx={{
              padding: 3,
              border: '1px solid', // ✅ Green border
              borderColor:borderColor,
              borderRadius: 3,
              // backgroundColor: 'rgba(0, 255, 0, 0.05)', // Light green tint for better visibility
            }}
          >
            <Scrollbar sx={{ width: '100%', height: 600 }}>
              {' '}
              {/* ✅ Increased height */}
              <ImageList
                sx={{
                  padding: '10px',
                  display: 'grid',
                  // gridTemplateColumns: 'repeat(auto-fill, minmax(220px, 1fr))',
                  gridTemplateColumns: 'repeat(4, 1fr)',
                  gap: 6, // ✅ Added more spacing
                }}
                cols={mdd ? 3 : xld ? 3 : xs ? 1 : 3}
              >
                                   {/* Sales order attachments---------- */}
                {designs?.map((item,index) => {
                  const { file_type, name, location_ids } = item
                  console.log('itemitemitem',item)
                  let filteredProducts = productsWithLocation?.filter((prodItem) => {
                    // Convert linkedLocationIds to an array of numbers
                    const linkedProductsArray = item?.linkedLocationIds
                        ? item?.linkedLocationIds.split(',').map(id => parseInt(id.trim(), 10)) // Convert to array of numbers
                        : [];
                
                    // Check if prodItem.id is NOT in the linkedProductsArray
                    return (
                        prodItem?.material === 0 &&
                        prodItem?.rfp === 0 &&
                        !linkedProductsArray.includes(prodItem.request_location_id) // Exclude linked productsWithLocation
                    );
                });
  
                
                  const isImage = file_type?.startsWith('image/') || file_type === 'img' || file_type === 'image'
                  const isPdf = file_type === 'pdf' || file_type === 'application/pdf' || file_type === "other"
                  return (
                    <ImageListItem
                      key={item.name}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 250, // ✅ Increased width
                        height: 250, // ✅ Increased height
                        backgroundColor: 'rgba(0, 0, 0, 0.05)',
                        borderRadius: 2, // ✅ Softer border radius
                        padding: 2,
                        position: 'relative',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // ✅ Added shadow for better visibility
                        border: '1px solid',
                        borderColor: (item.approved === 1 && isImage) ? theme.palette.success.main : item.reason ? theme.palette.error.main : theme.palette.info.main,
                      }}
                    >
                      <Stack
                        sx={{
                          position: 'absolute',
                          zIndex: 9,
                          top: 8,
                          left: 10,
                        }}
                      >
                        {(item.approved === 1 && isImage) ? (
                          (location_ids !== null ||  location_ids !== '') ? (
                            // <Tooltip title={`Linked with ${item?.product_name}`}>
                            <Tooltip
                              title={`Linked with: ${[...new Map(item?.productNames.map(p => [p.product_id, p])).values()]
                                .map((p, index) => `(${index + 1}). ${p?.product_name}`) // Add index before each unique product name
                                .join(', ')}`}
                              // title={`Linked with: ${item?.productNames
                              // .map((p, index) => `(${index + 1}). ${p?.product_name}`) // Add index before each product name
                              // .join(', ')}`} // Join all names into a single string
                              arrow
                            >
                              <span>
                                <Label
                                  variant={'filled'}
                                  color={'success'}
                                  style={{ color: 'white', height: 22, width: 110 }}
                                >
                                  Approved
                                </Label>
                              </span>
                            </Tooltip>
                          ) : (
                          <Label
                            variant={'filled'}
                            color={'success'}
                            style={{ color: 'white', height: 22, width: 110 }}
                          >
                            Approved
                          </Label>
                        )) :
                        (item?.location_ids && item?.location_ids.length > 0) || (item?.productNames && item?.productNames.length > 0)?(
                        // ( location_ids != null ||  location_ids != '') ? (

                          // <Tooltip title={`Linked with : ${item?.productNames.map(p =>p?.product_name)} `} arrow>
                          <Tooltip
                            // title={`Linked with: ${item?.productNames
                            // .map((p, index) => `(${index + 1}). ${p?.product_name}`) // Add index before each product name
                            // .join(', ')}`} // Join all names into a single string

                            title={`Linked with: ${[...new Map(item?.productNames.map(p => [p.product_id, p])).values()]
                              .map((p, index) => `(${index + 1}). ${p?.product_name}`)
                              .join(', ')}`}
                            arrow
                          >

                            <span>
                              <Label
                                variant={'filled'}
                                color={'info'}
                                style={{ color: 'white', height: 20 }}
                              >
                                {/* {`Linked with: ${
                                   item?.productNames
                                     ?.map(p => p?.product_name) // Extract product names
                                     ?.join(', ') // Join them as a single string
                                     ?.length > 15 // Check total length
                                     ? item?.productNames.map(p => p?.product_name).join(', ').substring(0, 12) + '...' // Shorten if necessary
                                     : item?.productNames.map(p => p?.product_name).join(', ') // Otherwise, show full names
                                 }`} */}

                                  {`Linked with: ${
                                    (() => {
                                      const uniqueNames = [...new Map(item?.productNames.map(p => [p.product_id, p.product_name])).values()];
                                      const fullString = uniqueNames.join(', ');

                                      return fullString.length > 15 
                                        ? fullString.substring(0, 12) + '...' 
                                        : fullString;
                                    })()
                                  }`}

                                  {/* {`Linked: ${
                                    item?.product_name.length > 15 // Shorten dynamically
                                      ? item?.product_name.substring(0, 12) + '...' // Show first 12 chars + "..."
                                      : item?.product_name
                                  }`} */}
                              </Label>
                            </span>
                          </Tooltip>
                        ) : item.reason ? (
                          <Tooltip title={`Reason : ${item.reason}`}>
                            <span>
                              <Label
                                variant={'filled'}
                                color={'error'}
                                style={{ color: 'white', height: 22, width: 110 }}
                              >
                                Rejected
                              </Label>
                            </span>
                          </Tooltip>
                        ) : null}
                      </Stack>

                      {/* Image / PDF Preview */}
                      {isImage ? (
                        <a
                          // href={name}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => {
                            e.preventDefault();
                            handleImageClick(imageDesigns.findIndex((img) => img.name === name))
                          }}
                        >
                          <Tooltip title="View Design">
                            <img
                              src={name}
                              alt={name}
                              loading="lazy"
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                                borderRadius: 8,
                              }}
                            />
                          </Tooltip>
                        </a>
                      ) : isPdf ? (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <Tooltip title="View PDF">
                            <IconButton
                              onClick={() => window.open(name, '_blank')}
                              aria-label="open pdf"
                            >
                              <PictureAsPdf
                                style={{ fontSize: 50, color: 'red' }}
                              />{' '}
                              {/* ✅ Increased size */}
                            </IconButton>
                          </Tooltip>
                        </Box>
                      ) : (
                        <Typography
                          sx={{
                            color: 'error.main',
                            fontSize: '14px',
                            textAlign: 'center',
                          }}
                        >
                          Unsupported file type
                        </Typography>
                      )}

                      {/* File Info Bar */}
                      <ImageListItemBar
                        // title={item.updated_by}
                        subtitle={moment
                          .unix(item.timestamp)
                          .format('MM/DD/YYYY')}
                        sx={{
                          backgroundColor: 'rgba(0, 0, 0, 0.6)',
                          fontSize: '14px', // ✅ Slightly increased font size
                          borderRadius: 2,
                          overflow: 'hidden',
                        }}
                        actionIcon={
                          <>
                            {!(user.isVendor && project) && ((item?.approved !== 1 && isImage) || !isImage) && !details?.installationStatus && (
                              <UserMoreMenu
                                common={{
                                  name:
                                    item?.approved !== 1 &&
                                    (item?.location_ids !== null &&  item?.location_ids !== '') &&
                                    // item?.product_id !== null &&
                                    'Approve',
                                  function:
                                    item?.approved !== 1 &&
                                    (item?.location_ids !== null &&  item?.location_ids !== '')
                                    // item?.product_id !== null
                                      ? () => {
                                          setItemId(item.id)
                                          setModalClose(true)
                                          handleUpdateDesign({
                                            id: item.id,
                                            // product_id: product.product_id,
                                            approved: 1,
                                            reason: null,
                                            num:3
                                          })
                                          // setOpenProductModel(true)
                                        }
                                      : null,
                                  Iccon: item?.approved !== 1 &&
                                    // item?.product_id !== null && (
                                      (item?.location_ids !== null &&  item?.location_ids !== '') &&(
                                      <ThumbUpAltOutlined />
                                    ),
                                  modalClose: modalClose,
                                }}
                                linkImageWithTask={
                                  productsWithLocation?.length > 0 && item?.product_id === null ? {
                                    name:
                                      item?.product_id == null && item.reason==null &&
                                      item?.file_type?.includes('image')
                                      ? 'Link with catalog'
                                      : '',
                                    function:
                                      item?.product_id == null && item?.file_type?.includes('image')
                                        ? () => {
                                            setItemId(item.id)
                                            setModalClose(true)
                                            setOpenProductModel(true)
                                            initializeSelectedProducts(item);
                                          }
                                        : null,
                                    Iccon: item?.product_id == null && <Link />,
                                    modalClose: modalClose,
                                  } : null
                                }
                                common2={{
                                  name:
                                    (item?.approved === 1 ||
                                      (item?.approved === 0 &&
                                        item?.reason === null)) &&
                                        item?.file_type?.includes('image')
                                      ? 'Reject'
                                      : '',
                                  function:
                                    item?.approved === 1 ||
                                    (item?.approved === 0 &&
                                      item?.reason === null) &&
                                      item?.file_type?.includes('image')
                                      ? () => {
                                          setItemId(item.id)
                                          setOpenReasonModal(true)
                                        }
                                      : null,
                                  Iccon: (item?.approved === 1 ||
                                    (item?.approved === 0 &&
                                      item?.reason === null)) && (
                                    <ThumbDownOffAltOutlined />
                                  ),
                                  modalClose: modalClose,
                                }}
                                //   onDelete={() => handleUpdateDesign({ id: item.id, status: 0 })}
                                {...((item.approved === 1 && isImage)
                                  ? {}
                                  : {
                                      onDelete: () =>
                                        handleUpdateDesign({
                                          id: item.id,
                                          status: 0,
                                          num:4
                                        }),
                                    })}
                              />
                            )}
                          </>
                        }
                      />
                      {openProductModel && itemId === item.id && (
                        <Card
                          sx={{
                            position: 'absolute',
                            zIndex: 10,
                            bottom: 5,
                            left: 5,
                            p: 2,
                            top: 20,
                            maxHeight: '600px',  
                            overflowY: 'auto',
                            width:"250px"
                          }}
                          // sx={{
                          //   position: 'absolute',
                          //   zIndex: 10,
                          //   bottom: 'auto',
                          //   left: 'auto',
                          //   top: 50,       
                          //   boxShadow: 3,
                          //   p: 2,
                          //   borderRadius: 2, 
                          // }}
                        >
                          <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            
                          >
                            <Typography  variant="subtitle2">
                              Choose Product
                            </Typography>
                            <CancelOutlined
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                setModalClose(false)
                                setOpenProductModel(false)
                                setSelectedProducts([])
                              }}
                            />
                          </Stack>

                          {filteredProducts?.length > 0 ? (
      //                     <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
      // <Checkbox
      //   checked={selectedProducts.length === products.length} // If all selected, check "Select All"
      //   indeterminate={selectedProducts.length > 0 && selectedProducts.length < products.length} // Partial selection indicator
      //   onChange={(e) => {
      //     if (e.target.checked) {
      //       // Select all
      //       setSelectedProducts(
      //         products.map((product) => ({
      //           id: item.id,
      //           // product_id: product.product_id,
      //           product_id: product.id,
      //           product_name: product.product_name,
      //           reason: null,
      //           num: 5,
      //         }))
      //       );
      //     } else {
      //       // Deselect all
      //       setSelectedProducts([]);
      //     }
      //   }}
      // />
      // <Typography variant="caption" sx={{ cursor: 'pointer' }} onClick={() => {
      //   // Toggle Select All when clicking text
      //   const allSelected = selectedProducts.length === products.length;
      //   setSelectedProducts(
      //     allSelected
      //       ? []
      //       : products.map((product) => ({
      //           id: item.id,
      //           product_id: product.product_id,
      //           product_name: product.product_name,
      //           reason: null,
      //           num: 5,
      //         }))
      //   );
      // }}>
      //   Select All
      // </Typography>
      //                      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
  <Checkbox
    checked={selectedProducts.length === filteredProducts.length} // ✅ Match against filteredProducts, not all products
    indeterminate={selectedProducts.length > 0 && selectedProducts.length < filteredProducts.length} // ✅ Partial selection
    onChange={(e) => {
      if (e.target.checked) {
        // ✅ Select only filteredProducts
        setSelectedProducts(
          filteredProducts.map((product) => ({
            id: item.id,
            product_id: product.request_location_id, // ✅ Use filtered product ID
            product_name: product.product_name,
            reason: null,
            num: 5,
          }))
        );
      } else {
        // ✅ Deselect all
        setSelectedProducts([]);
      }
    }}
  />
  <Typography
    variant="caption"
    sx={{ cursor: 'pointer' }}
    onClick={() => {
      const allSelected = selectedProducts.length === filteredProducts.length;
      setSelectedProducts(
        allSelected
          ? []
          : filteredProducts.map((product) => ({
              id: item.id,
              product_id: product.request_location_id, // ✅ Use filtered product ID
              product_name: product.product_name,
              reason: null,
              num: 5,
            }))
      );
    }}
  >
    Select All
  </Typography>
</Box>

                          ) : null}
    <Divider sx={{ marginBottom: 2 }} />

    <Stack spacing={1}>
    {filteredProducts.length > 0 ? (
      filteredProducts.map((product) => {
       
        return(
        
        <Box key={product.request_location_id} sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            checked={selectedProducts.some((p) => p.product_id === product.request_location_id)}
            onChange={(e) => handleCheckboxChange(product, e.target.checked, item)}
          />
          <Typography sx={{ cursor: 'pointer' }} variant="caption">
            {product.product_name}
          </Typography>
          <Divider />
        </Box>
      )})
    ) : (
      // ✅ Display "No Products Left" message when no products are available
      <Typography variant="body2" sx={{ textAlign: "center", color: "gray", padding: 2 }}>
        No products left
      </Typography>
    )}
  </Stack>

  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
    <Button
      onClick={() => {
        setModalClose(false);
        setOpenProductModel(false);
        setSelectedProducts([]);
      }}
      sx={{ color: "red" }}
    >
      Close
    </Button>
    {filteredProducts.length > 0 && <Button onClick={()=>handleSubmit(item.id)}>Submit</Button>}
  </Box>
                          

                        </Card>
                      )}

                      <Modal
                        open={openReasonModal && itemId === item.id}
                        handleClose={() => {
                          setOpenReasonModal(false)
                        }}
                        modalTitle={`Reason for Design Rejection`}
                      >
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="Reason"
                              value={reasonText}
                              onChange={(e) => setReasonText(e.target.value)}
                            />
                            <Box
                              sx={{
                                mt: 3,
                                display: 'flex',
                                justifyContent: 'center',
                                gap: 1,
                              }}
                            >
                              <LoadingButton
                                type="submit"
                                variant="contained"
                                onClick={() =>
                                  handleUpdateDesign({
                                    id: itemId,
                                    product_id: null,
                                    product_name: null,
                                    reason: reasonText,
                                    approved: 0,
                                    num:6
                                  })
                                }
                                // loading={isSubmitting}
                              >
                                {' Save '}
                              </LoadingButton>
                              <Button
                                variant="outlined"
                                type="submit"
                                onClick={() => {
                                  setOpenReasonModal(false)
                                }}
                              >
                                Cancel
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Modal>
                    </ImageListItem>
                  )
                })}


              </ImageList>
            </Scrollbar>
          </Box>
        ) : (
          ''
        )}
        <Modal width={1000}
              open={openImageModal}
              handleClose={() => {
                setOpenImageModal(false);
              }}
              modalTitle={'Designs'}
              >
              <div
              style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                display: 'flex',
                gap:'1rem',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
            <IconButton
              onClick={handlePrevious}
              disabled={currentImageIndex === 0}
              sx={{
                opacity: currentImageIndex === 0 ? 0.3 : 1,
                pointerEvents: currentImageIndex === 0 ? 'none' : 'auto',
              }}
            >
              <Icon
                icon={arrowLeftOutlined}
                width={24}
                height={24}
                color="#006097"
              />
            </IconButton>

            <Box
            component={'div'} 
              style={{
                width: '500px',
                height: '500px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 8,
                overflow:'hidden'
              }}
            >
              <img
                src={imageDesigns[currentImageIndex]?.name}
                alt={`Image ${currentImageIndex + 1}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: 8,
                }}
              />
            </Box>
            <IconButton
              onClick={handleNext}
              disabled={currentImageIndex === imageDesigns.length - 1}
              sx={{
                opacity: currentImageIndex === imageDesigns.length - 1 ? 0.3 : 1,
                pointerEvents: currentImageIndex === imageDesigns.length - 1 ? 'none' : 'auto',
              }}
            >
              <Icon
                icon={arrowRightOutlined}
                width={24}
                height={24}
                color="#006097"
              />
            </IconButton>
          </div>
        </Modal>
        
        {/* previos code */}
        {/* {designs?.length && !fromProposal ? (
          <Box>
            <Scrollbar sx={{ width: '100%', height: 550 }}>
              <ImageList
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '2px',
                  paddingRight: '2px',
                }}
                gap={mdd ? 8 : xld ? 12 : 8}
                cols={mdd ? 3 : xld ? 3 : xs ? 1 : 3}
              >
                {designs?.map((item) => {
                  const { file_type, name, url } = item
                  const isImage =
                    file_type?.startsWith('image/') || file_type === 'img'
                  const isPdf = file_type === 'pdf' || 'application/pdf'
                  return (
                    <ImageListItem key={item.name}>
                      <Stack
                        sx={{
                          position: 'absolute',
                          zIndex: 9,
                          top: 8,
                          left: 10,
                        }}
                      >
                        {item.approved === 1 ? (
                          <Label
                            variant={'filled'}
                            color={'success'}
                            style={{ color: 'white', height: 20, width: 100 }}
                          >
                            {'Approved'}
                          </Label>
                        ) : item.product_id !== null ? (
                          ''
                        ) : item.reason ? (
                          <Label
                            variant={'filled'}
                            color={'error'}
                            style={{ color: 'white', height: 20, width: 100 }}
                          >
                            {'Rejected'}
                          </Label>
                        ) : null}
                      </Stack>
                      <Stack
                        sx={{
                          position: 'absolute',
                          zIndex: 9,
                          top: 110,
                          left: 10,
                        }}
                      >
                        {item.approved === 1 ? (
                          <Typography
                            sx={{ position: 'absolute', top: 40, zIndex: 100 }}
                          >
                            <Label
                              variant={
                                theme.palette.mode === 'light'
                                  ? 'ghost'
                                  : 'filled'
                              }
                              color="success"
                            >
                              <Tooltip title={item.approved_by}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    fontSize: '12px',
                                    cursor: 'pointer',
                                    display: 'inline-block',
                                    maxWidth: 150, // Adjust as necessary
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <span
                                    style={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    Approved By: {item.approved_by}
                                  </span>
                                </Typography>
                              </Tooltip>
                            </Label>
                          </Typography>
                        ) : item.reason ? (
                          <Typography
                            sx={{ position: 'absolute', top: 40, zIndex: 100 }}
                          >
                            <Label
                              variant={
                                theme.palette.mode === 'light'
                                  ? 'ghost'
                                  : 'filled'
                              }
                              color="error"
                            >
                              <Tooltip title={item.reason}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    fontSize: '12px',
                                    cursor: 'pointer',
                                    display: 'inline-block',
                                    maxWidth: 150,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <span
                                    style={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    Rejected Reason: {item.reason}
                                  </span>
                                </Typography>
                              </Tooltip>
                            </Label>
                          </Typography>
                        ) : (
                          ''
                        )}
                      </Stack>

                      {isImage ? (
                        <img
                          srcSet={`${item.name}?w=240&h=240&fit=crop&auto=format&dpr=2 2x`}
                          src={`${item.name}?w=240&h=240&fit=crop&auto=format`}
                          alt={item.title}
                          loading="lazy"
                        />
                      ) : isPdf ? (
                        <Box display="flex" alignItems="center">
                          <iframe
                            src={name}
                            type="application/pdf"
                            width="100%"
                            height="400px"
                            style={{
                              borderRadius: '10px',
                              border: '1px #2f444b solid',
                            }}
                          />
                        </Box>
                      ) : (
                        <Typography
                          sx={{
                            color: theme.palette.error.main,
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: project ? '150px' : '240px',
                            height: '240px',
                          }}
                        >
                          Unsupported file type
                        </Typography>
                      )}

                      {openProductModel && itemId === item.id && (
                        <Card
                          sx={{
                            position: 'absolute',
                            zIndex: 10,
                            bottom: 5,
                            left: 5,
                            p: 2,
                          }}
                        >
                          <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                          >
                            <Typography marginBottom={2} variant="subtitle2">
                              Choose Product
                            </Typography>
                            <CancelOutlined
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                setModalClose(false)
                                setOpenProductModel(false)
                              }}
                            />
                          </Stack>
                          <Divider sx={{ marginBottom: 2 }} />
                          <Stack spacing={1}>
                            {products
                              ?.filter(
                                (prodItem) =>
                                  prodItem?.material === 0 &&
                                  prodItem?.rfp === 0,
                              )
                              ?.map((product) => (
                                <>
                                  <Typography
                                    sx={{ cursor: 'pointer' }}
                                    variant="caption"
                                    onClick={() =>
                                      handleUpdateDesign({
                                        id: item.id,
                                        product_id: product.product_id,
                                        product_name: product.product_name,
                                        // approved: 1,
                                        reason: null,
                                      })
                                    }
                                  >
                                    {product.product_name}
                                  </Typography>
                                  <Divider />
                                </>
                              ))}
                          </Stack>
                        </Card>
                      )}

                      <Modal
                        open={openReasonModal && itemId === item.id}
                        handleClose={() => {
                          setOpenReasonModal(false)
                        }}
                        modalTitle={`Reason for Design Rejection`}
                      >
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="Reason"
                              value={reasonText}
                              onChange={(e) => setReasonText(e.target.value)}
                            />
                            <Box
                              sx={{
                                mt: 3,
                                display: 'flex',
                                justifyContent: 'center',
                                gap: 1,
                              }}
                            >
                              <LoadingButton
                                type="submit"
                                variant="contained"
                                onClick={() =>
                                  handleUpdateDesign({
                                    id: itemId,
                                    reason: reasonText,
                                    approved: 0,
                                  })
                                }
                                // loading={isSubmitting}
                              >
                                {' Save '}
                              </LoadingButton>
                              <Button
                                variant="outlined"
                                type="submit"
                                onClick={() => {
                                  setOpenReasonModal(false)
                                }}
                              >
                                Cancel
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Modal>
                    </ImageListItem>
                  )
                })}
              </ImageList>
            </Scrollbar>
          </Box>
        ) : (
          ''
        )} */}
      </Stack>

      {/* {(user.isVendor === null || user.isVendor === 0) &&(
      <AccountNotifications
                  isRequest={true}
                  requestId={requestId}
                 signAgreements={signAgreements}
                 customTemplate={customTemplate}
                 questionnaireTemplate={questionnaireTemplate}
                 allDataEmpty={allDataEmpty}
                 portfolio={portfolio}
                 updatingFunction={fetchRequestDetails}/>
                 )} */}
    </Card>
  )
}
