import * as Yup from 'yup'
import axios from 'axios'
import { Icon } from '@iconify/react'
import trash2Outline from '@iconify/icons-eva/trash-2-outline'
import arrowLeftFill from '@iconify/icons-eva/arrow-back-fill';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { capitalCase, sentenceCase } from 'change-case'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Form, FormikProvider, useFormik } from 'formik'
import {
  Container,
  Grid,
  IconButton,
  Stack,
  Box,
  Button,
  TableRow,
  TableBody,
  Checkbox,
  Card,
  TableCell,
  Typography,
  TableContainer,
  Table,
  TablePagination,
  TextField,
  Switch,
  FormControlLabel,
  Autocomplete,
  Divider,
  Paper,
  Popover,
  CardHeader,
  TableHead,
  List,
  Menu,
  MenuItem,
  ListItemText,
  ListItem,
  Tabs,
  Tab,
  Tooltip,
  CircularProgress,
  Badge,
  FormHelperText,
} from '@material-ui/core'
import CloseIcon from '@iconify/icons-eva/close-fill'
import {
  useTheme,
  experimentalStyled as styled,
} from '@material-ui/core/styles'
import plusFill from '@iconify/icons-eva/plus-fill'
// redux
import {
  LoadingButton,
  MobileDatePicker,
  MobileDateRangePicker,
} from '@material-ui/lab'
// routes
// components
import Page from '../../components/Page'
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs'
import Modal from '../../components/_dashboard/blog/ProjectModel'
import Label from 'src/components/Label'
import { useSnackbar } from 'notistack'
import {
  Delete,
  UploadFile,
  LocationOn,
  ExpandMore,
  ExpandLess,
  Remove,
  Add,
  NoteAdd,
  Visibility,
  VisibilityOff,
  KeyboardBackspaceOutlined,
} from '@material-ui/icons'
import OfficialRequestSearch from 'src/components/_dashboard/official-request/OfficialRequestSearch'
import InfiniteScroll from 'react-infinite-scroll-component'
import OfficialCard from 'src/components/_dashboard/official-request/OfficialCard'
import Scrollbar from 'src/components/Scrollbar'
import moment from 'moment'
import {
  REST_API,
  REST_API_END_POINT,
  UNITS,
} from 'src/constants/Defaultvalues'
import { UploadAvatar, UploadMultiFile } from 'src/components/upload'
import { storage } from '../../firebase/firebase'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import ReactToPrint from 'react-to-print'
import { empty } from 'src/Helpers/Common'
import LoadingScreen from 'src/components/LoadingScreen'
import SearchNotFound from 'src/components/SearchNotFound'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import OfficialRequestAddPreview from './OfficialRequestAddPreview'
import OfficialRequestAdd from './OfficialRequestAdd'
import { MIconButton } from 'src/components/@material-extend'
import FullScreenDialogSalesOrder from '../components-overview/material-ui/dialog/FullScreenDialogSalesOrder';

const getStatusColorAndLabel = (status) => {
  switch (status) {
    case 1:
      return { color: 'warning', label: 'Status: Draft' }
    case 2:
      return { color: 'info', label: 'Status: Open' }
    case 3:
      return { color: 'primary', label: 'Status: Accepted' } // Evaluating
    case 4:
      return { color: 'success', label: 'Status: In Production' } // Converted to Job
    case 5:
      return { color: 'warning', label: 'Status: Closed' }
    case 6:
      return { color: 'error', label: 'Status: Rejected' }
    default:
      return { color: 'default', label: 'Status: Open' }
  }
}

const markupTypes = [
  { id: 1, name: 'Wholesale Markup' },
  { id: 2, name: 'Retail Markup' },
]
const marketingAdjustmentType = [
  { id: 1, name: '%' },
  { id: 2, name: '$' },
  // { id: 3, name: '¢' }
]

//sorting
const SORT = [
  { id: 'new', label: 'Newest', color: '#54D62C' },
  { id: 'old', label: 'Oldest', color: '#ffff00' },
  { id: 'last_updated', label: 'Recently Updated', color: '#ffff00' },
  { id: 'custom_order', label: 'Custom Order', color: '#ffff00' },
]

const MAX_CHARACTERS = 35

export default function CommunityOfficialRequest() {
  const theme = useTheme()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const locationState = useLocation()
  const newTab = locationState.state?.newTab
  const communityId = locationState.state && locationState.state.id;
  const communityName = locationState.state && locationState.state.name;
  const projectsClients = locationState.state && locationState.state.clients;
  const projectsClientsArray = projectsClients ? projectsClients.split(',').map(id => id.trim()) : [];
  console.log("projectsClientsArray" , projectsClientsArray)
  const [searching, setSearching] = useState('')
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(0)
  const [preview, setPreview] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(6)
  const [totalCount, setTotalCount] = useState(0)
  const [allCount, setAllCount] = useState(0)
  const [acceptedCount, setAcceptedCount] = useState(0)
  const [openCount, setOpenCount] = useState(0)
  const [inProductionCount, setinProductionCount] = useState(0)
  const [completedCount, setCompletedCount] = useState(0)
  const [installsCount, setInstallsCount] = useState(0)
  const [pickupCount, setPickupCount] = useState(0)
  const [shippingCount, setShippingCount] = useState(0)
  const [allRequest, setAllRequest] = useState([])
  const [loadingCards, setLoadingCards] = useState(false)
  const [loadingCardsAdd, setLoadingCardsAdd] = useState(false)
  const [skillServices, setSkillServices] = useState([])
  const [allProducts, setAllproducts] = useState([])
  const [materials, setAllMaterials] = useState([])
  const [labourCostServices, setLabourCostServices] = useState([])
  const [filteredServices, setFilteredServices] = useState([])
  const [requestTeam, setRequestTeam] = useState([])
  const [openPreview, setOpenPreview] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [selectedMaterials, setSelectedMaterials] = useState([])
  const [selectedServices, setSelectedServices] = useState([])
  const [additionalSelectedServices, setAdditionalSelectedServices] = useState([], )
  const [avatarUrl, setAvatarUrl] = useState([])
  const [location, setLocation] = useState(false)
  const [projectTypes, setProjectTypes] = useState([])
  const [workflow, setWorkFlow] = useState([])
  const [projectCategories, setProjectCategories] = useState([])
  const [noChargeType, setNoChargeType] = useState([])
  const [communities, setCommunities] = useState([])
  const [isCommunityLoading, setIsCommunityLoading] = useState(false)
  const [locationStates, setLocationStates] = useState({})
  const [serviceOnlyLocationStates, setServiceOnlyLocationStates] = useState({})
  const [rfpLocationStates, setRfpLocationStates] = useState({})
  const [rfpServiceLocationStates, setRfpServiceLocationStates] = useState({})
  const [allLocationSame, setAllLocationSame] = useState(false)
  const [allServiceLocationSame, setAllServiceLocationSame] = useState(false)
  const [allRfpServiceLocationSame, setAllRfpServiceLocationSame] = useState(false)
  const [allRfpProductsLocationSame, setAllRfpProductsLocationSame] = useState(false)
  const [address, setAddress] = useState({23: [{ 0: { address: 'addd', city: 'fsdasadf', state: 'fsd', zip: 1654 } }],}) // 23:[{address:'sdfsdf',city:'fsdasadf',state:'fsd',zip:1654}]
  const [progress, setProgress] = useState(null)
  const [currentDate, setCurrentDate] = useState(moment().unix())
  let user = JSON.parse(localStorage.getItem('user'))
  const userType = user.user_type
  const explodedPermissions = user?.exploded_permissions
  const hasMarkupPermission = explodedPermissions?.some((permission) => ['View Markup'].includes(permission),)
    ? true
    : false
  const hasDiscountPermission = explodedPermissions?.some((permission) => ['Manage Discount'].includes(permission),)
    ? false
    : true
  const refCommunities = useRef(null)
  const [allClients, setAllClients] = useState([])
  const [communitiesOpen, setCommunitiesOpen] = useState(false)
  const [communitiesData, setCommunitiesData] = useState([])
  const [filterCommunity, setFilterCommunity] = useState([communityId])
  const [markupType, setMarkupType] = useState(2)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('id')
  const [showServices, setShowServices] = useState(false)
  const [showWarning, setShowWarning] = useState( selectedServices.map(() => false),)
  const [currentTab, setCurrentTab] = useState(newTab || 'OPEN')
  const [indexCounter, setIndexCounter] = useState(0)
  const [serviceIndexCounter, setServiceIndexCounter] = useState(0)
  const refClients = useRef(null)
  const [clientsOpen, setClientsOpen] = useState(false)
  const [clientData, setClientData] = useState([])
  const [filterClient, setFilterClient] = useState([])
  const [rfps, setRfps] = useState([]) // List of RFPs
  const [selectedRfp, setSelectedRfp] = useState(null) // Selected RFP
  const [openModal, setOpenModal] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const [rfpServices, setRfpServices] = useState([])
  const [rfpProducts, setRfpProducts] = useState([])
  const [cart, setCart] = useState([])
  const [cartService, setCartService] = useState([])
  const [totalProductLabourCost, setTotalProductLabourCost] = useState(0)
  const [filterDateValue, setFilterDateValue] = useState([null, null])
  const [sort, setSort] = useState(SORT[0])
  const [sortOpen, setSortOpen] = useState(false)
  const [noCharge, setNoCharge] = useState(false)
  const refSort = useRef(null)

  const [openProductNote, setOpenProductNote] = useState({})
  const [isSavingNote, setIsSavingNote] = useState(false)
  const [currentNote, setCurrentNote] = useState('')
  const [selectedTaxCategory, setSelectedTaxCategory] = useState(null)
  const [taxCategories, setTaxCategories] = useState([])
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [allId, setAllId] = useState([])
  const [mapServices, setMapServices] = useState([])
  const [mapServicesLoading, setMapServicesLoading] = useState(false)
  const [selectedMapserviceProducts, setSelectedMapserviceProducts] = useState([],)
  const [dueData, setDueData] = useState(true)
  const [skipFetch, setSkipFetch] = useState(false)
  const [newValues, setNewValues] = useState(null)

  const [newTotalAmount, setNewTotalAmount] = useState(0);
  const [newTotalMarkup, setNewTotalMarkup] = useState(0);
  const [newTotalProductFormula, setNewTotalProductFormula] = useState(0);
  const [newTotalWholesalePrice, setNewTotalWholesalePrice] = useState(0);
  const [newTotalRetailPrice, setNewTotalRetailPrice] = useState(0);
  const [newOriginalTotalMarkup, setNewOriginalTotalMarkup] = useState(0);
  const [newOriginalTotalServiceMarkup, setNewOriginalTotalServiceMarkup] = useState(0);
  const [newTotalServiceMarkup, setNewTotalServiceMarkup] = useState(0);
  const [newTotalDiscount, setNewTotalDiscount] = useState(0);
  const [newDiscountTotal, setNewDiscountTotal] = useState(0);
  const [newTotalJobcostSum, setNewTotalJobcostSum] = useState(0);
  const [newTotalsum, setNewTotalsum] = useState(0);
  const [newGrandTotal, setNewGrandTotal] = useState(0);
  const openSalesOrderTemp= localStorage.getItem('salesOrderOpenPopup')
  const [opeSalesOrder,setOpenSalesOrder] = useState(openSalesOrderTemp||false)
  const [SalesOrderId,setSalesOrderId] = useState('')
  const [salesOrderName,setSalesOrderName] = useState('')
  const [renderCard,setRenderCard] = useState(false)
  const removePopupData=()=>{
    setSalesOrderId('')
    setSalesOrderName('')
  }
  const [isEditSalesOrder,setIsEditSalesOrder] = useState('')
  const handleOpenViewSalesOrder = (id,name) =>{
    setIsEditSalesOrder(true)
    setSalesOrderId(id)
    setSalesOrderName(name)
    setOpenSalesOrder(false)
    localStorage.setItem('salesOrderProjectId', id);
    localStorage.setItem('salesOrderProjectName', name);
    localStorage.setItem('isEditSalesOrder', "true");
    localStorage.setItem('salesOrderOpenPopup', 'true');

  }
  const handleOpenDetailsSalesOrder= (id,name) =>{
    setIsEditSalesOrder(false)
    setSalesOrderId(id)
    setSalesOrderName(name)
    setOpenSalesOrder(false)
    localStorage.setItem('salesOrderProjectId', id);
    localStorage.setItem('salesOrderProjectName', name);
    localStorage.setItem('isEditSalesOrder', "false");
    localStorage.setItem('salesOrderOpenPopup', 'true');
  }

  let totalAmount = 0;
  let totalMarkup = 0;
  let totalProductFormula = 0;
  let totalWholesalePrice = 0;
  let totalRetailPrice = 0;
  let originaltotalMarkup = 0;
  let originaltotalServiceMarkup = 0;
  let totalServiceMarkup = 0;
  let totalDiscount = 0;
  let DiscountTotal = 0;
  let totalJobcostSum = 0;
  let totalsum = 0;
  let grandTotal = 0;

  const handleByDue = () => {
    setDueData(!dueData)
  }

  // const [userId, setUserId] = useState(() => {
  //   const storedUser = JSON.parse(localStorage.getItem('user'));
  //   return storedUser ? storedUser.user_id : null;
  // });

  const VALUE = [
    { id: 1, value: 'Sl No.' },
    { id: 2, value: 'Products' },
    { id: 3, value: 'Note' },
    //  { id: 4, value: 'Product formula cost (Material cost + Service cost with markup)' },
    { id: 5, value: 'Product formula cost' },
    {
      id: 6,
      value: markupType
        ? markupType == 2 && hasMarkupPermission
          ? 'Selling Price (per product)'
          : null
        : null,
    },
    {
      id: 7,
      value: markupType
        ? markupType == 1 && hasMarkupPermission
          ? 'Selling Price (per product)'
          : null
        : null,
    },
    { id: 14, value: hasMarkupPermission ? 'SPMA' : null },
    // { id: 8, value: hasMarkupPermission ? 'Markup (%)' : null },
    { id: 9, value: 'Unit' },
    { id: 10, value: 'Quantity' },
    { id: 11, value: 'Location' },
    { id: 11, value: 'Marketing Adjustment Type' },
    { id: 12, value: 'Marketing Adjustment ' },
    // { id: 12, value: 'Discount' },
    { id: 13, value: 'Subtotal' },
    { id: 15, value: '' },
  ]
  const VALUE_TIME = [
    { id: 1, value: 'Sl No.' },
    { id: 2, value: 'Products' },
    { id: 3, value: 'Note' },
    { id: 4, value: 'Selling Price (per product)' },
    { id: 14, value: 'SPMA' },
    { id: 5, value: 'Unit' },
    { id: 6, value: 'Quantity' },
    { id: 7, value: 'Location' },
    { id: 11, value: 'Marketing Adjustment Type' },
    { id: 8, value: 'Marketing Adjustment ' },
    { id: 9, value: 'Subtotal' },
    { id: 10, value: '' },
  ]
  const VALUE_TIME_Preview = [
    { id: 1, value: 'Sl No.' },
    { id: 2, value: 'Products' },
    // { id: 3, value: 'Note' },
    { id: 4, value: 'Selling Price (per product)' },
    { id: 14, value: 'SPMA' },
    { id: 5, value: 'Unit' },
    { id: 6, value: 'Quantity' },
    // { id: 7, value: 'Location' },
    { id: 8, value: 'Marketing Adjustment' },
    { id: 9, value: 'Subtotal' },
    { id: 10, value: '' },
  ]
  const VALUE_MATERIAL = [
    { id: 0, value: 'Material' },
    { id: 1, value: 'Unit Price' },
    { id: 2, value: 'Markup (%)' },
    { id: 3, value: 'Markup Cost (Unit)' },
    { id: 4, value: 'Quantity' },
    { id: 5, value: 'Product Formula Cost' },
    { id: 6, value: 'Total' },
    { id: 7, value: '' },
  ]
  const VALUEPreview = [
    { id: 0, value: 'Sl No.' },
    { id: 1, value: 'Products & Notes' },
    // { id: 2, value: 'Product formula cost' }, // (Material cost + Service cost with markup)
    {
      id: 3,
      value: markupType
        ? markupType == 2 && hasMarkupPermission
          ? 'Selling Price (per product)'
          : null
        : null,
    },
    {
      id: 4,
      value: markupType
        ? markupType == 1 && hasMarkupPermission
          ? 'Selling Price (per product)'
          : null
        : null,
    },
    { id: 14, value: hasMarkupPermission ? 'SPMA' : null },
    // { id: 5, value: hasMarkupPermission ? 'Markup (%)' : null },
    { id: 6, value: 'Unit  ' },
    { id: 7, value: 'Quantity' },
    { id: 8, value: 'Marketing Adjustment' },
    // { id: 8, value: 'Discount' },
    { id: 9, value: 'Subtotal' },
    { id: 10, value: '' },
  ]

  const VALUE_SERVICES = [
    { id: 0, value: 'Product Sl No.' },
    { id: 1, value: 'Service Name' },
    { id: 2, value: 'Markup (%)' },
    { id: 3, value: 'Cost (Hourly)' },
    { id: 4, value: 'Markup Cost (Hourly)' },
    { id: 5, value: 'Hours' },
    { id: 6, value: 'Location' },
    { id: 7, value: 'Product Formula Cost' },
    { id: 8, value: 'Additional Cost' },
    { id: 9, value: 'Total cost' },
    { id: 10, value: '' },
  ]
  const VALUE_RFP_Product = [
    { id: 0, value: ' Name' },
    { id: 1, value: 'Mark Up' },
    { id: 2, value: 'Cost ' },
    { id: 3, value: 'Selling Price' },
    { id: 4, value: 'Unit' },
    { id: 5, value: 'Quantity' },
    { id: 6, value: 'Product Formula Cost' },
    { id: 7, value: 'Total' },
    { id: 8, value: '' },
    { id: 9, value: '' },
  ]
  const VALUE_RFP = [
    { id: 0, value: ' Name' },
    { id: 1, value: 'Markup (%)' },
    { id: 2, value: 'Cost ' },
    { id: 3, value: 'Markup Cost (Hourly)' },
    { id: 4, value: 'Hours' },
    { id: 5, value: 'Total cost' },
    { id: 6, value: '' },
    { id: 7, value: '' },
  ]
  // const [expandedCommunities, setExpandedCommunities] = useState({});

  // const handleExpandToggle = (community) => {
  //   setExpandedCommunities(prevState => ({
  //     ...prevState,
  //     [community]: !prevState[community]
  //   }));
  // };

  const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    //   marginBottom: theme.spacing(1)
  }))
  const LabelStyleHead = styled(Typography)(({ theme }) => ({
    ...theme.typography.h5,
    color: theme.palette.text.secondary,
  }))
  const load = useLocation().state

  //get All request
  const fetchAllRequest = async () => {
    setLoadingCards(true)
    setLoadingCardsAdd(true)
    try {
      let user = JSON.parse(localStorage.getItem('user'))
      let userType = user.user_type
      let userId = user.user_id

      let whereCondition = ''
      if (currentTab == 'OPEN') {
        whereCondition = ` ((r.status=2) OR (r.status=3 AND r.min_payment_status=0)) `
      } else if (currentTab == 'ACCEPTED') {
        whereCondition = ` (r.status=3 AND r.min_payment_status =1) ` // r.status=4 OR
      } else if (currentTab == 'IN PRODUCTION') {
        whereCondition = ` r.status=4 and i.status IS NULL `
      } else if (currentTab == 'INSTALLATION') {
        // whereCondition = ` i.status IS NOT NULL AND ( COALESCE(inst_counts.installation_count, 0) < COALESCE(cop.product_count, 0) OR (pc.name = 'Service only' AND COALESCE(inst_counts.installation_count, 0) < COALESCE(rss.service_count, 0)) ) `
        whereCondition = ` i.status IS NOT NULL AND i.status != 4 AND i.status != 5 AND  i.complete_status = 1 AND ( 
          COALESCE(inst_counts.installation_count, 0) < COALESCE(cop.product_count, 0) 
          OR (pc.name = 'Service Only' AND COALESCE(inst_counts.installation_count, 0) < COALESCE(rss.service_count, 0))
          OR (COALESCE(cop.product_count, 0) = 0 AND COALESCE(rss.service_count, 0) > 0 AND COALESCE(inst_counts.installation_count, 0) < COALESCE(rss.service_count, 0))
        ) `
      } else if (currentTab == 'PICKUP') {
        // whereCondition = ` i.status IS NOT NULL AND ( COALESCE(inst_counts.installation_count, 0) < COALESCE(cop.product_count, 0) OR (pc.name = 'Service only' AND COALESCE(inst_counts.installation_count, 0) < COALESCE(rss.service_count, 0)) ) `
        whereCondition = ` i.status IS NOT NULL AND i.status != 4 AND i.status != 5 AND i.complete_status = 2 AND ( 
          COALESCE(inst_counts.installation_count, 0) < COALESCE(cop.product_count, 0) 
          OR (pc.name = 'Service Only' AND COALESCE(inst_counts.installation_count, 0) < COALESCE(rss.service_count, 0))
          OR (COALESCE(cop.product_count, 0) = 0 AND COALESCE(rss.service_count, 0) > 0 AND COALESCE(inst_counts.installation_count, 0) < COALESCE(rss.service_count, 0))
        ) `
      } else if (currentTab == 'SHIPPING') {
        // whereCondition = ` i.status IS NOT NULL AND ( COALESCE(inst_counts.installation_count, 0) < COALESCE(cop.product_count, 0) OR (pc.name = 'Service only' AND COALESCE(inst_counts.installation_count, 0) < COALESCE(rss.service_count, 0)) ) `
        whereCondition = ` i.status IS NOT NULL AND i.status != 4 AND i.status != 5 AND i.complete_status = 3 AND ( 
          COALESCE(inst_counts.installation_count, 0) < COALESCE(cop.product_count, 0) 
          OR (pc.name = 'Service Only' AND COALESCE(inst_counts.installation_count, 0) < COALESCE(rss.service_count, 0))
          OR (COALESCE(cop.product_count, 0) = 0 AND COALESCE(rss.service_count, 0) > 0 AND COALESCE(inst_counts.installation_count, 0) < COALESCE(rss.service_count, 0))
        ) `
      } else if (currentTab == 'COMPLETED') {
        whereCondition = ` i.status IS NOT NULL AND ( inst_counts.installation_count >= cop.product_count OR inst_counts.installation_count >= rss.service_count ) AND r.installation_acceptance IS NOT NULL  `
      }
      else if (currentTab == 'INVOICING') {
        whereCondition = `i.status IS NOT NULL AND ( inst_counts.installation_count >= cop.product_count OR inst_counts.installation_count >= rss.service_count ) AND r.installation_acceptance IS NULL `
      }

      const formattedFrom = filterDateValue[0]
        ? moment(filterDateValue[0]).format('YYYY-MM-DD HH:mm:ss')
        : null
      const formattedTo = filterDateValue[1]
        ? moment(filterDateValue[1]).format('YYYY-MM-DD HH:mm:ss')
        : null

      const values = {
        userType: userType,
        userId: userId,
        page: page + 1,
        rowsPerPage,
        search: searching,
        where: whereCondition,
        sort: sort.id,
        from: formattedFrom,
        to: formattedTo,
        filterCommunity: filterCommunity,
        filterClient: filterClient,
        dueData: dueData,
      }
      await axios
        .post(`${REST_API}webservice/request/get-all-request-new`, values)
        .then((res) => {
          res.data.data

          setAllRequest(res.data.data.data)
          if (res?.data?.data?.totalCount) {
            setTotalCount(res?.data?.data?.totalCount)
          } else {
            setTotalCount(0)
          }
          console.log('hbafdhbsidhfbhsdbfi', searching !== '')
          console.log('hbafdhbsidhfbhsdbfi', searching.length)

          if (currentTab == 'ALL' && searching !== '') {
            setAllCount(res?.data?.data?.totalCount || 0)
          } else {
            setAllCount(res?.data?.data?.allCount || 0)
          }
          if (currentTab == 'OPEN') {
            setOpenCount(res?.data?.data?.totalCount || 0)
          } else {
            setOpenCount(res?.data?.data?.openCount || 0)
          }
          if (currentTab == 'ACCEPTED') {
            setAcceptedCount(res?.data?.data?.totalCount || 0)
          } else {
            setAcceptedCount(res?.data?.data?.acceptedCount || 0)
          }
          if (currentTab == 'IN PRODUCTION') {
            setinProductionCount(res?.data?.data?.totalCount || 0)
          } else {
            setinProductionCount(res?.data?.data?.inProductionCount || 0)
          }
          if (currentTab == 'INSTALLATION') {
            setInstallsCount(res?.data?.data?.totalCount || 0)
          } else {
            setInstallsCount(res?.data?.data?.installsCount || 0)
          }
          if (currentTab == 'PICKUP') {
            setPickupCount(res?.data?.data?.totalCount || 0)
          } else {
            setPickupCount(res?.data?.data?.pickupCount || 0)
          }
          if (currentTab == 'SHIPPING') {
            setShippingCount(res?.data?.data?.totalCount || 0)
          } else {
            setShippingCount(res?.data?.data?.shippingCount || 0)
          }
          if (currentTab == 'COMPLETED') {
            setCompletedCount(res?.data?.data?.totalCount || 0)
          } else {
            setCompletedCount(res?.data?.data?.completedCount || 0)
          }
          
        })
        .catch((errors) => {
          console.log(errors, 'error in fetching all requests')
        })
    } catch (error) {
      console.log('fetchAllRequest error : ', error)
    } finally {
      setLoadingCards(false)
    }
  }
  const fetchAllRequestNew = async () => {
    setLoadingCardsAdd(true)
    try {
      let user = JSON.parse(localStorage.getItem('user'))
      let userType = user.user_type
      let userId = user.user_id

      let whereCondition = ''
      if (currentTab == 'OPEN') {
        whereCondition = ` ((r.status=2) OR (r.status=3 AND r.min_payment_status=0)) `
      } else if (currentTab == 'ACCEPTED') {
        whereCondition = ` (r.status=3 AND r.min_payment_status =1) ` // r.status=4 OR
      } else if (currentTab == 'IN PRODUCTION') {
        whereCondition = ` r.status=4 and i.status IS NULL `
      } else if (currentTab == 'INSTALLATION') {
        // whereCondition = ` i.status IS NOT NULL AND ( COALESCE(inst_counts.installation_count, 0) < COALESCE(cop.product_count, 0) OR (pc.name = 'Service only' AND COALESCE(inst_counts.installation_count, 0) < COALESCE(rss.service_count, 0)) ) `
        whereCondition = ` i.status IS NOT NULL AND i.status != 4 AND i.status != 5 AND  i.complete_status = 1 AND ( 
          COALESCE(inst_counts.installation_count, 0) < COALESCE(cop.product_count, 0) 
          OR (pc.name = 'Service Only' AND COALESCE(inst_counts.installation_count, 0) < COALESCE(rss.service_count, 0))
          OR (COALESCE(cop.product_count, 0) = 0 AND COALESCE(rss.service_count, 0) > 0 AND COALESCE(inst_counts.installation_count, 0) < COALESCE(rss.service_count, 0))
        ) `
      } else if (currentTab == 'PICKUP') {
        // whereCondition = ` i.status IS NOT NULL AND ( COALESCE(inst_counts.installation_count, 0) < COALESCE(cop.product_count, 0) OR (pc.name = 'Service only' AND COALESCE(inst_counts.installation_count, 0) < COALESCE(rss.service_count, 0)) ) `
        whereCondition = ` i.status IS NOT NULL AND i.status != 5 AND i.status != 4 AND i.complete_status = 2 AND ( 
          COALESCE(inst_counts.installation_count, 0) < COALESCE(cop.product_count, 0) 
          OR (pc.name = 'Service Only' AND COALESCE(inst_counts.installation_count, 0) < COALESCE(rss.service_count, 0))
          OR (COALESCE(cop.product_count, 0) = 0 AND COALESCE(rss.service_count, 0) > 0 AND COALESCE(inst_counts.installation_count, 0) < COALESCE(rss.service_count, 0))
        ) `
      } else if (currentTab == 'SHIPPING') {
        // whereCondition = ` i.status IS NOT NULL AND ( COALESCE(inst_counts.installation_count, 0) < COALESCE(cop.product_count, 0) OR (pc.name = 'Service only' AND COALESCE(inst_counts.installation_count, 0) < COALESCE(rss.service_count, 0)) ) `
        whereCondition = ` i.status IS NOT NULL AND i.status != 4 AND i.status != 5 AND i.complete_status = 3 AND ( 
          COALESCE(inst_counts.installation_count, 0) < COALESCE(cop.product_count, 0) 
          OR (pc.name = 'Service Only' AND COALESCE(inst_counts.installation_count, 0) < COALESCE(rss.service_count, 0))
          OR (COALESCE(cop.product_count, 0) = 0 AND COALESCE(rss.service_count, 0) > 0 AND COALESCE(inst_counts.installation_count, 0) < COALESCE(rss.service_count, 0))
        ) `
      } else if (currentTab == 'COMPLETED') {
        whereCondition = ` i.status IS NOT NULL AND ( inst_counts.installation_count >= cop.product_count OR inst_counts.installation_count >= rss.service_count ) `
      }

      const formattedFrom = filterDateValue[0]
        ? moment(filterDateValue[0]).format('YYYY-MM-DD HH:mm:ss')
        : null
      const formattedTo = filterDateValue[1]
        ? moment(filterDateValue[1]).format('YYYY-MM-DD HH:mm:ss')
        : null

      const values = {
        userType: userType,
        userId: userId,
        page: page + 1,
        rowsPerPage,
        search: searching,
        where: whereCondition,
        sort: sort.id,
        from: formattedFrom,
        to: formattedTo,
        filterCommunity: filterCommunity,
        filterClient: filterClient,
        dueData: dueData,
      }
      await axios
        .post(`${REST_API}webservice/request/get-all-request`, values)
        .then((res) => {
          res.data.data
          console.log('reeeeeeeeeeeee', res.data.data)

          const ids = res?.data?.data.data.map((item) => item.id)
          setAllId(ids)

          setRequestTeam(res.data.requestTeam)
          setSkillServices(res.data.services)
          setWorkFlow(res.data.workflows)
          setAllproducts(res.data.products)
          // setAllMaterials(res.data.materials)  15-01-25 previos
          //new
          const allMatData = Array.isArray(res.data.materials)
            ? res.data.materials.map((material) => ({
                ...material,
                markup: Number(material.retail_price || 0),
              }))
            : []

          setAllMaterials(allMatData)

          setLabourCostServices(res.data.labourCostServices)
          setRfps(res.data.allProposals)
          setFilteredServices(res.data.labourCostServices)
          const othersCommunity = { id: 'other', name: 'Others' }
          const othersClient = { id: 'other', name: 'Others' }
          let clientData = res.data.clients || []
          let communitiesData = res.data.community || []
          if (res.data?.community) {
            // setCommunitiesData([...res.data.community, othersCommunity]);
            communitiesData = communitiesData.map((community) => ({
              ...community,
              checked: filterCommunity.includes(community.id),
            }))

            setCommunitiesData([...communitiesData]) //othersCommunity
          } else {
            setCommunitiesData([othersCommunity])
          }
          console.log("clienttttttttttt>", clientData)
          if (res.data?.clients) {
            clientData = clientData.map((client) => ({
              ...client,
              checked: filterClient.includes(client.id),
            }))
            setClientData([...clientData])
          } else {
            setClientData([othersClient])
          }
          updatePaymentFromQb(ids)
        })
        .catch((errors) => {
          console.log(errors, 'error in fetching all requests')
        })
    } catch (error) {
      console.log('fetchAllRequest error : ', error)
    } finally {
      setLoadingCardsAdd(false)
    }
  }

  const updatePaymentFromQb = async (ids) => {
    try {
      let user = JSON.parse(localStorage.getItem('user'))
      console.log('useruseruseruser', user)
      let userType = user.user_type
      let userId = user.user_id
      let admin_email = user.email
      let company_name = user.company_name
      let username = user.person_name
      let user_role = user.user_role
      const response = await axios.post(
        `${REST_API_END_POINT}sales-order/update-payment-from-qb`,
        { userId, allId: ids, admin_email, company_name, username, user_role },
      )
      if (response.data.details.success > 0) {
        // fetchAllRequest()
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    // fetchAllRequest()
    if (!skipFetch) {
      fetchAllRequest()
      fetchAllRequestNew()
    }
    setSkipFetch(false)
  }, [
    open,
    searching,
    page,
    rowsPerPage,
    sort,
    filterDateValue,
    filterCommunity,
    filterClient,
    dueData,
    renderCard
  ])

  const handleOnDragEnd = (result) => {
    // Check if item was dropped outside of the list
    if (!result.destination) return

    // Create a copy of the original array
    const reorderedItems = Array.from(allRequest)

    // Remove the item from its original position
    const [movedItem] = reorderedItems.splice(result.source.index, 1)

    // Insert the item at the new position
    reorderedItems.splice(result.destination.index, 0, movedItem)

    // Update the state with the reordered items
    setAllRequest(reorderedItems)

    // Persist the new order (ensure this function handles errors appropriately)
    saveNewOrder(reorderedItems)
  }

  const saveNewOrder = async (reorderedItems) => {
    try {
      await axios.post(`${REST_API}webservice/request/save-order`, {
        reorderedItems,
      })
      console.log('Order saved successfully')
    } catch (error) {
      console.error('Error saving order:', error)
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  function handleToggleCommunity(id) {
    setFilterCommunity((prevIds) => {
      // If not yet in filterCommunity, add it
      if (!prevIds.includes(id)) {
        return [...prevIds, id]
      }
      // If it’s already in there, remove it
      return prevIds.filter((oldId) => oldId !== id)
    })
  }

  const calculateTotal = (
    price,
    quantity,
    discount = 0,
    location = 1,
    adjustment = 0,
    markAdjType = 1,
  ) => {
    const parsedQuantity = parseFloat(quantity) || 1
    const parsedDiscount = parseFloat(discount) || 0
    const locationQuantity = parseFloat(location) || 1
    const newPrice = parseFloat(price) || 0
    // let subtotal = price * parsedQuantity * locationQuantity;

    let SPMA =
      newPrice + (newPrice * (adjustment ? Number(adjustment) : 0)) / 100
    let subtotal =
      parseFloat(SPMA)?.toFixed(2) * parsedQuantity * locationQuantity

    if (markAdjType == 2 || markAdjType == 3) {
      SPMA = newPrice + (adjustment ? Number(adjustment) : 0)
      subtotal =
        parseFloat(SPMA)?.toFixed(2) * parsedQuantity * locationQuantity
    }

    if (discount) {
      subtotal = subtotal - discount
    }
    // if (adjustment) {
    //   subtotal = subtotal + ((subtotal * adjustment) / 100);
    // }
    return subtotal
  }

  const calculateServiceTotal = (price, quantity, discount, location = 1) => {
    const parsedQuantity = parseFloat(quantity) || 0
    const parsedDiscount = parseFloat(discount) || 0
    const locationQuantity = parseFloat(location) || 1
    const subtotal = price * parsedQuantity * locationQuantity
    if (discount) {
      const totalDiscount = subtotal - discount
      return totalDiscount
    } else {
      return subtotal
    }
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  function applySortFilter(array, comparator, community) {
    const stabilizedThis = array?.map((el, index) => [el, index])
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    // Filter based on the community parameter
    if (community && community?.length > 0) {
      if (community?.includes('other')) {
        array = array?.filter(
          (item) =>
            item?.community === null || community?.includes(item?.community),
        )
      } else {
        array = array?.filter((item) => community?.includes(item?.community))
      }
    }
    return array
  }
  function handleToggleClient(id) {
    setFilterClient((prevIds) => {
      if (!prevIds.includes(id)) {
        return [...prevIds, id]
      }
      return prevIds.filter((oldId) => oldId !== id)
    })
  }

  const handleChangeTab = async (event, newValue) => {
    try {
      setLoadingCards(true)
      setSkipFetch(true)
      setCurrentTab(newValue)
      setSearching('')
      setFilterDateValue([null, null])
      setSalesOrderName('')
      setSalesOrderId('')
      let newPage = 0
      setPage(0)

      if (newValue == 'OPEN') {
        let user = JSON.parse(localStorage.getItem('user'))
        let userType = user.user_type
        let userId = user.user_id

        const values = {
          // userType: userType, userId: userId, page: newPage + 1, rowsPerPage, search: searching, where: ` r.status=2`,
          userType: userType,
          userId: userId,
          page: newPage + 1,
          rowsPerPage,
          search: searching,
          where: ` ((r.status=2) OR (r.status=3 AND r.min_payment_status=0)) `,
          from: filterDateValue[0],
          to: filterDateValue[1],
          filterCommunity: filterCommunity,
          filterClient: filterClient,
          dueData: dueData,
        }
        await axios
          .post(`${REST_API}webservice/request/get-all-request-new`, values)
          .then((res) => {
            res.data.data
            const ids = res?.data?.data.data.map((item) => item.id)
            setAllId(ids)

            setAllRequest(res.data.data.data)
            if (res?.data?.data?.totalCount) {
              setTotalCount(res?.data?.data?.totalCount)
            } else {
              setTotalCount(0)
            }
            setOpenCount(res?.data?.data?.openCount || 0)
            setAcceptedCount(res?.data?.data?.acceptedCount || 0)
            setAllCount(res?.data?.data?.allCount || 0)
            setinProductionCount(res?.data?.data?.inProductionCount || 0)
            setCompletedCount(res?.data?.data?.completedCount || 0)
            setInstallsCount(res?.data?.data?.installsCount || 0)
            setShippingCount(res?.data?.data?.shippingCount || 0)
            setPickupCount(res?.data?.data?.pickupCount || 0)
            // setLoadingCards(false);
            // setRequestTeam(res.data.requestTeam)
            // setSkillServices(res.data.services)
            // setWorkFlow(res.data.workflows)
            // setAllproducts(res.data.products)
            // setLabourCostServices(res.data.labourCostServices)
            // setFilteredServices(res.data.labourCostServices)
            // const othersCommunity = { id: 'other', name: 'Others' }
            // let communitiesData = res.data.community || []
            // if (res.data?.community) {
            //   // setCommunitiesData([...res.data.community, othersCommunity]);
            //   communitiesData = communitiesData.map((community) => ({
            //     ...community,
            //     checked: filterCommunity.includes(community.id),
            //   }))

            //   setCommunitiesData([...communitiesData]) //othersCommunity
            // } else {
            //   setCommunitiesData([othersCommunity])
            // }
            // updatePaymentFromQb(ids)
          })
          .catch((errors) => {
            console.log(errors, 'error in fetching all requests')
          })
      } else if (newValue == 'ACCEPTED') {
        let user = JSON.parse(localStorage.getItem('user'))
        let userType = user.user_type
        let userId = user.user_id

        const values = {
          userType: userType,
          userId: userId,
          page: newPage + 1,
          rowsPerPage,
          search: searching,
          where: ` (r.status=3 AND r.min_payment_status =1) `, //r.status=4 OR
          // where: ` r.status=3`, //r.status=4 OR
          from: filterDateValue[0],
          to: filterDateValue[1],
          filterCommunity: filterCommunity,
          filterClient: filterClient,
          dueData: dueData,
        }
        await axios
          .post(`${REST_API}webservice/request/get-all-request-new`, values)
          .then((res) => {
            res.data.data
            const ids = res?.data?.data.data.map((item) => item.id)
            setAllId(ids)
            setAllRequest(res.data.data.data)
            if (res?.data?.data?.totalCount) {
              setTotalCount(res?.data?.data?.totalCount)
            } else {
              setTotalCount(0)
            }
            setOpenCount(res?.data?.data?.openCount || 0)
            setAcceptedCount(res?.data?.data?.acceptedCount || 0)
            setAllCount(res?.data?.data?.allCount || 0)
            setinProductionCount(res?.data?.data?.inProductionCount || 0)
            setCompletedCount(res?.data?.data?.completedCount || 0)
            setInstallsCount(res?.data?.data?.installsCount || 0)
            setShippingCount(res?.data?.data?.shippingCount || 0)
            setPickupCount(res?.data?.data?.pickupCount || 0)
            // setLoadingCards(false);
            // setRequestTeam(res.data.requestTeam)
            // setSkillServices(res.data.services)
            // setWorkFlow(res.data.workflows)
            // setAllproducts(res.data.products)
            // setLabourCostServices(res.data.labourCostServices)
            // setFilteredServices(res.data.labourCostServices)
            // const othersCommunity = { id: 'other', name: 'Others' }
            // let communitiesData = res.data.community || []
            // if (res.data?.community) {
            //   // setCommunitiesData([...res.data.community, othersCommunity]);
            //   communitiesData = communitiesData.map((community) => ({
            //     ...community,
            //     checked: filterCommunity.includes(community.id),
            //   }))

            //   setCommunitiesData([...communitiesData]) //othersCommunity
            // } else {
            //   setCommunitiesData([othersCommunity])
            // }
            // updatePaymentFromQb(ids)
          })
          .catch((errors) => {
            console.log(errors, 'error in fetching all requests')
          })
      } else if (newValue == 'IN PRODUCTION') {
        let user = JSON.parse(localStorage.getItem('user'))
        let userType = user.user_type
        let userId = user.user_id

        const values = {
          userType: userType,
          userId: userId,
          page: newPage + 1,
          rowsPerPage,
          search: searching,
          where: ` r.status=4 and i.status IS NULL`,
          from: filterDateValue[0],
          to: filterDateValue[1],
          filterCommunity: filterCommunity,
          filterClient: filterClient,
          dueData: dueData,
        }
        await axios
          .post(`${REST_API}webservice/request/get-all-request-new`, values)
          .then((res) => {
            res.data.data
            const ids = res?.data?.data.data.map((item) => item.id)
            setAllId(ids)
            setAllRequest(res.data.data.data)
            if (res?.data?.data?.totalCount) {
              setTotalCount(res?.data?.data?.totalCount)
            } else {
              setTotalCount(0)
            }
            setOpenCount(res?.data?.data?.openCount || 0)
            setAcceptedCount(res?.data?.data?.acceptedCount || 0)
            setAllCount(res?.data?.data?.allCount || 0)
            setinProductionCount(res?.data?.data?.inProductionCount || 0)
            setCompletedCount(res?.data?.data?.completedCount || 0)
            setInstallsCount(res?.data?.data?.installsCount || 0)
            setShippingCount(res?.data?.data?.shippingCount || 0)
            setPickupCount(res?.data?.data?.pickupCount || 0)
            // setLoadingCards(false);
            // setRequestTeam(res.data.requestTeam)
            // setSkillServices(res.data.services)
            // setWorkFlow(res.data.workflows)
            // setAllproducts(res.data.products)
            // setLabourCostServices(res.data.labourCostServices)
            // setFilteredServices(res.data.labourCostServices)
            // const othersCommunity = { id: 'other', name: 'Others' }
            // let communitiesData = res.data.community || []
            // if (res.data?.community) {
            //   // setCommunitiesData([...res.data.community, othersCommunity]);
            //   communitiesData = communitiesData.map((community) => ({
            //     ...community,
            //     checked: filterCommunity.includes(community.id),
            //   }))

            //   setCommunitiesData([...communitiesData]) //othersCommunity
            // } else {
            //   setCommunitiesData([othersCommunity])
            // }
            // updatePaymentFromQb(ids)
          })
          .catch((errors) => {
            console.log(errors, 'error in fetching all requests')
          })
      } else if (
        newValue == 'INSTALLATION' ||
        newValue == 'PICKUP' ||
        newValue == 'SHIPPING'
      ) {
        let user = JSON.parse(localStorage.getItem('user'))
        let userType = user.user_type
        let userId = user.user_id
        let completeStatus =
          newValue == 'INSTALLATION' ? 1 : newValue == 'PICKUP' ? 2 : 3
        const values = {
          userType: userType,
          userId: userId,
          page: newPage + 1,
          rowsPerPage,
          search: searching,
          // where: ` i.status IS NOT NULL AND ( COALESCE(inst_counts.installation_count, 0) <
          // COALESCE(cop.product_count, 0) OR (pc.name = 'Service Only' AND
          //COALESCE(inst_counts.installation_count, 0) < COALESCE(rss.service_count, 0)) ) `,
          where: ` i.status IS NOT NULL AND i.status != 4 AND i.status != 5 AND i.complete_status = ${completeStatus}  AND ( 
            COALESCE(inst_counts.installation_count, 0) < COALESCE(cop.product_count, 0) 
            OR (pc.name = 'Service Only' AND COALESCE(inst_counts.installation_count, 0) < COALESCE(rss.service_count, 0))
            OR (COALESCE(cop.product_count, 0) = 0 AND COALESCE(rss.service_count, 0) > 0 AND COALESCE(inst_counts.installation_count, 0) < COALESCE(rss.service_count, 0))
          ) `,
          from: filterDateValue[0],
          to: filterDateValue[1],
          filterCommunity: filterCommunity,
          filterClient: filterClient,
          dueData: dueData,
        }
        await axios
          .post(`${REST_API}webservice/request/get-all-request-new`, values)
          .then((res) => {
            res.data.data
            const ids = res?.data?.data.data.map((item) => item.id)
            setAllId(ids)
            setAllRequest(res.data.data.data)
            if (res?.data?.data?.totalCount) {
              setTotalCount(res?.data?.data?.totalCount)
            } else {
              setTotalCount(0)
            }
            setOpenCount(res?.data?.data?.openCount || 0)
            setAcceptedCount(res?.data?.data?.acceptedCount || 0)
            setAllCount(res?.data?.data?.allCount || 0)
            setinProductionCount(res?.data?.data?.inProductionCount || 0)
            setCompletedCount(res?.data?.data?.completedCount || 0)
            setInstallsCount(res?.data?.data?.installsCount || 0)
            setShippingCount(res?.data?.data?.shippingCount || 0)
            setPickupCount(res?.data?.data?.pickupCount || 0)
            // setLoadingCards(false);
            // setRequestTeam(res.data.requestTeam)
            // setSkillServices(res.data.services)
            // setWorkFlow(res.data.workflows)
            // setAllproducts(res.data.products)
            // setLabourCostServices(res.data.labourCostServices)
            // setFilteredServices(res.data.labourCostServices)
            // const othersCommunity = { id: 'other', name: 'Others' }
            // let communitiesData = res.data.community || []
            // if (res.data?.community) {
            //   // setCommunitiesData([...res.data.community, othersCommunity]);
            //   communitiesData = communitiesData.map((community) => ({
            //     ...community,
            //     checked: filterCommunity.includes(community.id),
            //   }))

            //   setCommunitiesData([...communitiesData]) //othersCommunity
            // } else {
            //   setCommunitiesData([othersCommunity])
            // }
            // updatePaymentFromQb(ids)
          })
          .catch((errors) => {
            console.log(errors, 'error in fetching all requests')
          })
      } else if (newValue == 'COMPLETED') {
        let user = JSON.parse(localStorage.getItem('user'))
        let userType = user.user_type
        let userId = user.user_id

        const values = {
          userType: userType,
          userId: userId,
          page: newPage + 1,
          rowsPerPage,
          search: searching,
          where: `i.status IS NOT NULL AND ( inst_counts.installation_count >= cop.product_count OR inst_counts.installation_count >= rss.service_count ) AND r.installation_acceptance IS NOT NULL `,
          from: filterDateValue[0],
          to: filterDateValue[1],
          filterCommunity: filterCommunity,
          filterClient: filterClient,
          dueData: dueData,
        }
        await axios
          .post(`${REST_API}webservice/request/get-all-request-new`, values)
          .then((res) => {
            res.data.data
            const ids = res?.data?.data.data.map((item) => item.id)
            setAllId(ids)
            setAllRequest(res.data.data.data)
            if (res?.data?.data?.totalCount) {
              setTotalCount(res?.data?.data?.totalCount)
            } else {
              setTotalCount(0)
            }
            setOpenCount(res?.data?.data?.openCount || 0)
            setAcceptedCount(res?.data?.data?.acceptedCount || 0)
            setAllCount(res?.data?.data?.allCount || 0)
            setinProductionCount(res?.data?.data?.inProductionCount || 0)
            setCompletedCount(res?.data?.data?.completedCount || 0)
            setInstallsCount(res?.data?.data?.installsCount || 0)
            setShippingCount(res?.data?.data?.shippingCount || 0)
            setPickupCount(res?.data?.data?.pickupCount || 0)
            // setLoadingCards(false);
            // setRequestTeam(res.data.requestTeam)
            // setSkillServices(res.data.services)
            // setWorkFlow(res.data.workflows)
            // setAllproducts(res.data.products)
            // setLabourCostServices(res.data.labourCostServices)
            // setFilteredServices(res.data.labourCostServices)
            // const othersCommunity = { id: 'other', name: 'Others' }
            // let communitiesData = res.data.community || []
            // if (res.data?.community) {
            //   // setCommunitiesData([...res.data.community, othersCommunity]);
            //   communitiesData = communitiesData.map((community) => ({
            //     ...community,
            //     checked: filterCommunity.includes(community.id),
            //   }))

            //   setCommunitiesData([...communitiesData]) //othersCommunity
            // } else {
            //   setCommunitiesData([othersCommunity])
            // }
            // updatePaymentFromQb(ids)
          })
          .catch((errors) => {
            console.log(errors, 'error in fetching all requests')
          })}
          else if (newValue == 'INVOICING') {
            let user = JSON.parse(localStorage.getItem('user'))
            let userType = user.user_type
            let userId = user.user_id
    
            const values = {
              userType: userType,
              userId: userId,
              page: newPage + 1,
              rowsPerPage,
              search: searching,
              where: ` i.status IS NOT NULL AND ( inst_counts.installation_count >= cop.product_count OR inst_counts.installation_count >= rss.service_count ) AND r.installation_acceptance IS NULL `,
              from: filterDateValue[0],
              to: filterDateValue[1],
              filterCommunity: filterCommunity,
              filterClient: filterClient,
              dueData: dueData,
            }
            await axios
              .post(`${REST_API}webservice/request/get-all-request-new`, values)
              .then((res) => {
                res.data.data
                const ids = res?.data?.data.data.map((item) => item.id)
                setAllId(ids)
                setAllRequest(res.data.data.data)
                if (res?.data?.data?.totalCount) {
                  setTotalCount(res?.data?.data?.totalCount)
                } else {
                  setTotalCount(0)
                }
                setOpenCount(res?.data?.data?.openCount || 0)
                setAcceptedCount(res?.data?.data?.acceptedCount || 0)
                setAllCount(res?.data?.data?.allCount || 0)
                setinProductionCount(res?.data?.data?.inProductionCount || 0)
                setCompletedCount(res?.data?.data?.completedCount || 0)
                setInstallsCount(res?.data?.data?.installsCount || 0)
                setShippingCount(res?.data?.data?.shippingCount || 0)
                setPickupCount(res?.data?.data?.pickupCount || 0)
                // setLoadingCards(false);
                // setRequestTeam(res.data.requestTeam)
                // setSkillServices(res.data.services)
                // setWorkFlow(res.data.workflows)
                // setAllproducts(res.data.products)
                // setLabourCostServices(res.data.labourCostServices)
                // setFilteredServices(res.data.labourCostServices)
                // const othersCommunity = { id: 'other', name: 'Others' }
                // let communitiesData = res.data.community || []
                // if (res.data?.community) {
                //   // setCommunitiesData([...res.data.community, othersCommunity]);
                //   communitiesData = communitiesData.map((community) => ({
                //     ...community,
                //     checked: filterCommunity.includes(community.id),
                //   }))
    
                //   setCommunitiesData([...communitiesData]) //othersCommunity
                // } else {
                //   setCommunitiesData([othersCommunity])
                // }
                // updatePaymentFromQb(ids)
              })
              .catch((errors) => {
                console.log(errors, 'error in fetching all requests')
              })
      } else {
        let user = JSON.parse(localStorage.getItem('user'))
        let userType = user.user_type
        let userId = user.user_id

        const values = {
          userType: userType,
          userId: userId,
          page: newPage + 1,
          rowsPerPage,
          search: searching,
          from: filterDateValue[0],
          to: filterDateValue[1],
          filterCommunity: filterCommunity,
          filterClient: filterClient,
          dueData: dueData,
        }
        await axios
          .post(`${REST_API}webservice/request/get-all-request-new`, values)
          .then((res) => {
            res.data.data
            const ids = res?.data?.data.data.map((item) => item.id)
            setAllId(ids)
            setAllRequest(res.data.data.data)
            if (res?.data?.data?.totalCount) {
              setTotalCount(res?.data?.data?.totalCount)
            } else {
              setTotalCount(0)
            }
            setOpenCount(res?.data?.data?.openCount || 0)
            setAcceptedCount(res?.data?.data?.acceptedCount || 0)
            setAllCount(res?.data?.data?.allCount || 0)
            setinProductionCount(res?.data?.data?.inProductionCount || 0)
            setCompletedCount(res?.data?.data?.completedCount || 0)
            setInstallsCount(res?.data?.data?.installsCount || 0)
            setShippingCount(res?.data?.data?.shippingCount || 0)
            setPickupCount(res?.data?.data?.pickupCount || 0)
            // setLoadingCards(false);
            // setRequestTeam(res.data.requestTeam)
            // setSkillServices(res.data.services)
            // setWorkFlow(res.data.workflows)
            // setAllproducts(res.data.products)
            // setLabourCostServices(res.data.labourCostServices)
            // setFilteredServices(res.data.labourCostServices)
            // const othersCommunity = { id: 'other', name: 'Others' }
            // let communitiesData = res.data.community || []
            // if (res.data?.community) {
            //   // setCommunitiesData([...res.data.community, othersCommunity]);
            //   communitiesData = communitiesData.map((community) => ({
            //     ...community,
            //     checked: filterCommunity.includes(community.id),
            //   }))

            //   setCommunitiesData([...communitiesData]) //othersCommunity
            // } else {
            //   setCommunitiesData([othersCommunity])
            // }
            // updatePaymentFromQb(ids)
          })
          .catch((errors) => {
            console.log(errors, 'error in fetching all requests')
          })
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingCards(false)
    }
  }
  const filteredUsers = applySortFilter(
    allRequest,
    getComparator(order, orderBy),
    [],
  )
  // const filteredUsers = applySortFilter(allRequest, getComparator(order, orderBy), filterCommunity);

  const handleChangeSort = (category) => {
    setSort(category)
    setSortOpen(false)
  }

  const ACCOUNT_TABS = [
    {
      value: 'ALL',
      name: 'ALL',
      count: allCount || 0,
    },
    {
      value: 'OPEN',
      name: 'OPEN',
      count: openCount || 0,
    },
    {
      value: 'ACCEPTED',
      name: 'APPROVED',
      count: acceptedCount || 0,
    },
    {
      value: 'IN PRODUCTION',
      name: 'IN PRODUCTION',
      count: inProductionCount || 0,
    },
    {
      value: 'INSTALLATION',
      name: 'INSTALLS',
      count: installsCount || 0,
    },
    {
      value: 'PICKUP',
      name: 'PICK UP',
      count: pickupCount || 0,
    },
    {
      value: 'SHIPPING',
      name: 'SHIPPING',
      count: shippingCount || 0,
    },
    {
      value: 'INVOICING',
      name: 'INVOICING',
      count: shippingCount || 0,
    },
    {
      value: 'COMPLETED',
      name: 'COMPLETED',
      count: completedCount || 0,
    },
  ]

  
  return (
    <Page title="Sales Order: list | Sign Wise Solutions">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
            <Button
                        variant="outlined"
                        type="submit"
                        // onClick={()=>navigate(PATH_DASHBOARD.official.root)}
                        // onClick={() => { window.history.back() }}
                        onClick={() => window.history.back()}
                        sx={{ alignItems: 'flex-end', mb: 4 }}
                        startIcon={ // Use the startIcon prop to add an icon to the left
                          <Stack>
                            <KeyboardBackspaceOutlined />
                          </Stack>
            
                        }
                      >
                        Back
                      </Button>
          <HeaderBreadcrumbs
            // heading={'Sales Orders'}
            heading={communityName ? `${communityName} - Sales Orders ` :"Sales Orders"}
            // leftAction={
            //   (!user.isVendor && open) && (
            //     <MIconButton
            //       sx={{
            //         mr:1,
            //         // backgroundColor: '#8b8e92',
            //       //   '&:hover': {
            //       //     backgroundColor: '#454545', // Slightly lighter shade on hover
            //       //   },
            //       }}
            //       onClick={() => {
            //         setOpen(false);
            //         // formik.resetForm();
            //         setNewValues(null);
            //         setSelectedProducts([]);
            //         setSelectedServices([]);
            //         setAdditionalSelectedServices([])
            //         setFilteredServices([])
            //         setAllLocationSame(false)
            //         setAvatarUrl([])
            //         setAllMaterials([])
            //       }}
            //     >
            //       <Icon style={{color:'#ffffff'}} icon={arrowLeftFill} width={25} height={25} />
            //     </MIconButton>
            //   )
            // }
          />
          {(userType === 1 ||
            explodedPermissions?.some((permission) =>
              ['Create Request'].includes(permission),
            )) &&
          !user.isVendor &&
          open == false ? (
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Stack>
                {!user.isVendor && user.user_type !==3 && (
                  <Button
                    ref={refClients}
                    onClick={() =>
                      setClientsOpen((prevState) => !prevState)
                    }
                    variant="outlined"
                    type="submit"
                    sx={{ alignItems: 'flex-end' }}
                    endIcon={
                      <Stack>
                        {clientsOpen ? (
                          <ExpandLess sx={{ ml: 'auto' }} />
                        ) : (
                          <ExpandMore sx={{ ml: 'auto' }} />
                        )}
                      </Stack>
                    }
                  >
                    Filter By Client
                  </Button>
                )}
                <Menu
                  open={Boolean(clientsOpen)}
                  anchorEl={refClients.current}
                  onClose={() => setClientsOpen(false)}
                  PaperProps={{
                    sx: { width: 'auto', maxWidth: '100%' },
                  }}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                  {/* <List style={{ maxHeight: '250px', overflowY: 'auto' }}>
                    {clientData?.map((type) => (
                      <MenuItem key={type.id}>
                        {type.id === 'other' ? (
                          <ListItemText primary={`${type.name}`} />
                        ) : (
                          <ListItemText
                            // primary={`${type.name} (${type.no_of_requests})`}
                            primary={`${type.name}`}
                          />
                        )}
                        <Checkbox
                          checked={filterClient.includes(type.id)}
                          onChange={() => {
                            setPage(0)
                            handleToggleClient(type.id)
                          }}
                        />
                      </MenuItem>
                    ))}
                  </List> */}
                  <List style={{ maxHeight: '250px', overflowY: 'auto' }}>
                  {clientData
                    ?.filter(type => type.id && projectsClientsArray.includes(String(type.id)))
                    .map((type) => (
                      <MenuItem key={type.id}>
                        {type.id === 'other' ? (
                          <ListItemText primary={`${type.name}`} />
                        ) : (
                          <ListItemText primary={`${type.name}`} />
                        )}
                        <Checkbox
                          checked={filterClient.includes(type.id)}
                          onChange={() => {
                            setPage(0);
                            handleToggleClient(type.id);
                          }}
                        />
                      </MenuItem>
                    ))}
                </List>
                </Menu>
              </Stack>
              <Button
                variant="outlined"
                sx={{
                  // height: '40px',
                  maxHeight: '250px',
                  backgroundColor: dueData ? '#1ccaff' : '',
                  color: dueData ? '#fff' : '',
                }}
                onClick={handleByDue}
                endIcon={
                  <Stack>
                    {' '}
                    {dueData ? <Visibility /> : <VisibilityOff />}{' '}
                  </Stack>
                }
              >
                By Due
              </Button>
              {/* <Button
                disabled={loadingCardsAdd}
                onClick={() => {
                  setOpen(!open)
                }}
                variant="contained"
                startIcon={
                  loadingCardsAdd ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <Icon icon={plusFill} />
                  )
                }
              >
                {loadingCardsAdd ? 'Loading Data...' : 'Add New Orders'}
              </Button> */}
            </Box>
          ) : (
            ''
          )}
        </Stack>
        {open ? (
          <OfficialRequestAdd
            open = {open}
            searching={searching}
            setOpen = {setOpen}
            page = {page}
            setPage = {setPage}
            preview = {preview}
            setPreview = {setPreview}
            rowsPerPage = {rowsPerPage}
            setRowsPerPage = {setRowsPerPage}
            totalCount = {totalCount}
            setTotalCount = {setTotalCount}
            allCount = {allCount}
            setAllCount = {setAllCount}
            acceptedCount = {acceptedCount}
            setAcceptedCount = {setAcceptedCount}
            openCount = {openCount}
            setOpenCount = {setOpenCount}
            inProductionCount = {inProductionCount}
            setinProductionCount = {setinProductionCount}
            completedCount = {completedCount}
            setCompletedCount = {setCompletedCount}
            installsCount = {installsCount}
            setInstallsCount = {setInstallsCount}
            pickupCount = {pickupCount}
            setPickupCount = {setPickupCount}
            shippingCount = {shippingCount}
            setShippingCount = {setShippingCount}
            allRequest = {allRequest}
            setAllRequest = {setAllRequest}
            loadingCards = {loadingCardsAdd}
            setLoadingCards = {setLoadingCardsAdd}
            skillServices = {skillServices}
            setSkillServices = {setSkillServices}
            allProducts = {allProducts}
            setAllproducts = {setAllproducts}
            materials = {materials}
            setAllMaterials = {setAllMaterials}
            labourCostServices = {labourCostServices}
            setLabourCostServices = {setLabourCostServices}
            filteredServices = {filteredServices}
            setFilteredServices = {setFilteredServices}
            requestTeam = {requestTeam}
            setRequestTeam = {setRequestTeam}
            openPreview = {openPreview}
            setOpenPreview = {setOpenPreview}
            isSaving = {isSaving}
            setIsSaving = {setIsSaving}
            selectedProducts = {selectedProducts}
            setSelectedProducts = {setSelectedProducts}
            selectedMaterials = {selectedMaterials}
            setSelectedMaterials = {setSelectedMaterials}
            selectedServices = {selectedServices}
            setSelectedServices = {setSelectedServices}
            additionalSelectedServices = {additionalSelectedServices}
            setAdditionalSelectedServices = {setAdditionalSelectedServices}
            avatarUrl = {avatarUrl}
            setAvatarUrl = {setAvatarUrl}
            location = {location}
            setLocation = {setLocation}
            projectTypes = {projectTypes}
            setProjectTypes = {setProjectTypes}
            workflow={workflow}
            setWorkFlow={setWorkFlow}
            projectCategories={projectCategories}
            setProjectCategories={setProjectCategories}
            noChargeType={noChargeType}
            setNoChargeType={setNoChargeType}
            communities={communities}
            setCommunities={setCommunities}
            isCommunityLoading={isCommunityLoading}
            setIsCommunityLoading={setIsCommunityLoading}
            locationStates={locationStates}
            setLocationStates={setLocationStates}
            serviceOnlyLocationStates={serviceOnlyLocationStates}
            setServiceOnlyLocationStates={setServiceOnlyLocationStates}
            rfpLocationStates={rfpLocationStates}
            setRfpLocationStates={setRfpLocationStates}
            rfpServiceLocationStates={rfpServiceLocationStates}
            setRfpServiceLocationStates={setRfpServiceLocationStates}
            allLocationSame={allLocationSame}
            setAllLocationSame={setAllLocationSame}
            allServiceLocationSame={allServiceLocationSame}
            setAllServiceLocationSame={setAllServiceLocationSame}
            allRfpServiceLocationSame={allRfpServiceLocationSame}
            setAllRfpServiceLocationSame={setAllRfpServiceLocationSame}
            allRfpProductsLocationSame={allRfpProductsLocationSame}
            setAllRfpProductsLocationSame={setAllRfpProductsLocationSame}
            address={address}
            setAddress={setAddress}
            progress={progress}
            setProgress={setProgress}
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            user={user}
            userType={userType}
            explodedPermissions={explodedPermissions}
            hasMarkupPermission={hasMarkupPermission}
            hasDiscountPermission={hasDiscountPermission}
            allClients={allClients}
            setAllClients={setAllClients}
            communitiesOpen={communitiesOpen}
            setCommunitiesOpen={setCommunitiesOpen}
            communitiesData={communitiesData}
            setCommunitiesData={setCommunitiesData}
            filterCommunity={filterCommunity}
            setFilterCommunity={setFilterCommunity}
            markupType={markupType}
            setMarkupType={setMarkupType}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            showServices={showServices}
            setShowServices={setShowServices}
            showWarning={showWarning}
            setShowWarning={setShowWarning}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            indexCounter={indexCounter}
            setIndexCounter={setIndexCounter}
            serviceIndexCounter={serviceIndexCounter}
            setServiceIndexCounter={setServiceIndexCounter}
            rfps={rfps}
            setRfps={setRfps}
            selectedRfp={selectedRfp}
            setSelectedRfp={setSelectedRfp}
            openModal={openModal}
            setOpenModal={setOpenModal}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            rfpServices={rfpServices}
            setRfpServices={setRfpServices}
            rfpProducts={rfpProducts}
            setRfpProducts={setRfpProducts}
            cart={cart}
            setCart={setCart}
            cartService={cartService}
            setCartService={setCartService}
            totalProductLabourCost={totalProductLabourCost}
            filterDateValue={filterDateValue}
            setFilterDateValue={setFilterDateValue}
            sort={sort}
            setSort={setSort}
            sortOpen={sortOpen}
            setSortOpen={setSortOpen}
            noCharge={noCharge}
            setNoCharge={setNoCharge}
            openProductNote={openProductNote}
            setOpenProductNote={setOpenProductNote}
            isSavingNote={isSavingNote}
            setIsSavingNote={setIsSavingNote}
            currentNote={currentNote}
            setCurrentNote={setCurrentNote}
            selectedTaxCategory={selectedTaxCategory}
            setSelectedTaxCategory={setSelectedTaxCategory}
            taxCategories={taxCategories}
            setTaxCategories={setTaxCategories}
            isSubmitted={isSubmitted}
            setIsSubmitted={setIsSubmitted}
            allId={allId}
            setAllId={setAllId}
            mapServices={mapServices}
            setMapServices={setMapServices}
            mapServicesLoading={mapServicesLoading}
            setMapServicesLoading={setMapServicesLoading}
            selectedMapserviceProducts={selectedMapserviceProducts}
            setSelectedMapserviceProducts={setSelectedMapserviceProducts}
            newTotalAmount={newTotalAmount}
            setNewTotalAmount={setNewTotalAmount}
            newTotalMarkup={newTotalMarkup}
            setNewTotalMarkup={setNewTotalMarkup}
            newTotalProductFormula={newTotalProductFormula}
            setNewTotalProductFormula={setNewTotalProductFormula}
            newTotalWholesalePrice={newTotalWholesalePrice}
            setNewTotalWholesalePrice={setNewTotalWholesalePrice}
            newTotalRetailPrice={newTotalRetailPrice}
            setNewTotalRetailPrice={setNewTotalRetailPrice}
            newOriginalTotalMarkup={newOriginalTotalMarkup}
            setNewOriginalTotalMarkup={setNewOriginalTotalMarkup}
            newOriginalTotalServiceMarkup={newOriginalTotalServiceMarkup}
            setNewOriginalTotalServiceMarkup={setNewOriginalTotalServiceMarkup}
            newTotalServiceMarkup={newTotalServiceMarkup}
            setNewTotalServiceMarkup={setNewTotalServiceMarkup}
            newTotalDiscount={newTotalDiscount}
            setNewTotalDiscount={setNewTotalDiscount}
            newDiscountTotal={newDiscountTotal}
            setNewDiscountTotal={setNewDiscountTotal}
            newTotalJobcostSum={newTotalJobcostSum}
            setNewTotalJobcostSum={setNewTotalJobcostSum}
            newTotalsum={newTotalsum}
            setNewTotalsum={setNewTotalsum}
            newGrandTotal={newGrandTotal}
            setNewGrandTotal={setNewGrandTotal}
            newValues={newValues}
            setNewValues={setNewValues}
          />
        ) : (
          <>
            <Stack
              mb={5}
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 3, sm: 2 }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <OfficialRequestSearch
                  searching={searching}
                  setSearching={setSearching}
                  setAllRequest={setAllRequest}
                />

                {/* <TextField
                    select
                     value={sort}
                    onChange={handleChangeSort}
                    renderValue={(selected) => selected.label}
                    sx={{
                      width: '100%',
                      mt:1,              
                      borderRadius: 1,
                      border: 'none',
                      '&:hover': {
                        border: 'none',
                      },
                    }}
                  >
                    {SORT.map((category) => (
                    <MenuItem key={category.id} value={category}>
                    <strong>{category.label}</strong>
                    </MenuItem>
                    ))}
                  </TextField> */}
              </Box>
              <Stack flexDirection={'row'} gap={2}>
                {/* <Button
                  variant="outlined"
                  sx={{
                    // height: '40px',
                    maxHeight: '250px',
                    backgroundColor: dueData ? '#1ccaff' : '',
                    color: dueData ? '#fff' : '',
                  }}
                  onClick={handleByDue}
                  endIcon={
                    <Stack>
                      {' '}
                      {dueData ? <Visibility /> : <VisibilityOff />}{' '}
                    </Stack>
                  }
                >
                  By Due
                </Button> */}
                <MobileDateRangePicker
                  startText="Filter Date"
                  endText="Filter Date"
                  value={filterDateValue}
                  onChange={(newValue) => {
                    console.log(
                      'newValuenewValuenewValuenewValuenewValuenewValue',
                      newValue,
                    )
                    setFilterDateValue(newValue)
                  }}
                  renderInput={(startProps, endProps) => (
                    <>
                      <TextField
                        {...startProps}
                        sx={{ width: 160 }}
                        helperText="Filter By Due Date"
                        label="From Date"
                        size="small"
                        InputLabelProps={{
                          style: {
                            color: theme.palette.primary.main,
                            borderColor: theme.palette.primary.main,
                          },
                        }}
                      />
                      <Box sx={{ mx: 1, color: theme.palette.primary.main }}>
                        {' '}
                        -{' '}
                      </Box>
                      {/* <Box sx={{ mx: 2 }}> to </Box> */}
                      <TextField
                        {...endProps}
                        sx={{ width: 150 }}
                        helperText=""
                        label="To Date"
                        size="small"
                        InputLabelProps={{
                          style: {
                            color: theme.palette.primary.main,
                            borderColor: theme.palette.primary.main,
                          },
                        }}
                      />
                    </>
                  )}
                />
                <Stack>
                  <Button
                    ref={refSort}
                    onClick={() => setSortOpen((prevState) => !prevState)}
                    variant="outlined"
                    disabled={dueData}
                    type="button"
                    endIcon={
                      <Stack>
                        {sortOpen ? (
                          <ExpandLess sx={{ ml: 'auto' }} />
                        ) : (
                          <ExpandMore sx={{ ml: 'auto' }} />
                        )}
                      </Stack>
                    }
                  >
                    {sort.label}
                  </Button>
                  {dueData && (
                    <FormHelperText sx={{ color: 'error.main', fontSize: '0.65rem', mt: 0.5 }}>
                      Disable "By Due" to enable this.
                    </FormHelperText>
                  )}
                  <Menu
                    open={Boolean(sortOpen)}
                    anchorEl={refSort.current}
                    onClose={() => setSortOpen(false)}
                    PaperProps={{
                      sx: {
                        width: 'auto',
                        maxWidth: '100%',
                        maxHeight: '250px',
                      },
                    }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  >
                    {SORT.map((category) => (
                      <MenuItem
                        key={category.id}
                        onClick={() => handleChangeSort(category)}
                      >
                        <ListItemText primary={category.label} />
                      </MenuItem>
                    ))}
                  </Menu>
                </Stack>
                {/* <Stack>
                  {!user.isVendor && (
                    <Button
                      ref={refCommunities}
                      onClick={() =>
                        setCommunitiesOpen((prevState) => !prevState)
                      }
                      variant="outlined"
                      type="submit"
                      sx={{ alignItems: 'flex-end' }}
                      endIcon={
                        <Stack>
                          {communitiesOpen ? (
                            <ExpandLess sx={{ ml: 'auto' }} />
                          ) : (
                            <ExpandMore sx={{ ml: 'auto' }} />
                          )}
                        </Stack>
                      }
                    >
                      Filter Project
                    </Button>
                  )}
                  <Menu
                    open={Boolean(communitiesOpen)}
                    anchorEl={refCommunities.current}
                    onClose={() => setCommunitiesOpen(false)}
                    PaperProps={{
                      sx: { width: 'auto', maxWidth: '100%' },
                    }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  >
                    <List style={{ maxHeight: '250px', overflowY: 'auto' }}>
                      {communitiesData?.map((type) => (
                        <MenuItem key={type.id}>
                          {type.id === 'other' ? (
                            <ListItemText primary={`${type.name}`} />
                          ) : (
                            <ListItemText
                              primary={`${type.name} (${type.no_of_requests})`}
                            />
                          )}
                          <Checkbox
                            // checked={type.checked ? type.checked : false}
                            checked={filterCommunity.includes(type.id)}
                            // onChange={() => {
                            //   setPage(0)
                            //   const updatedType = [...communitiesData];
                            //   const item = updatedType.find((item) => item.id === type.id);
                            //   if (item) {
                            //     item.checked = !item.checked;
                            //     setCommunitiesData(updatedType);
                            //     setFilterCommunity((prevIds) => {
                            //       if (item.checked) {
                            //         return [...prevIds, item.id];
                            //       } else {
                            //         return prevIds.filter((id) => id !== item.id);
                            //       }
                            //     });
                            //   }
                            // }}
                            onChange={() => {
                              setPage(0) // If needed, reset page when toggling
                              handleToggleCommunity(type.id)
                            }}
                          />
                        </MenuItem>
                      ))}
                    </List>
                  </Menu>
                </Stack> */}
              </Stack>
            </Stack>
            {filterClient?.length > 0 && (
                <>
                  <Button
                    variant="outlined"
                    sx={{
                      color: 'white',
                      borderColor: 'primary.main',
                      pointerEvents: 'none',
                      userSelect: 'text',
                    }}
                  >
                    <span
                      style={{
                        color: theme.palette.primary.darker,
                        marginRight: 8,
                      }}
                    >
                      Selected Clients{' '}
                    </span>
                    {clientData
                      .filter((client) =>
                        filterClient?.includes(client.id),
                      )
                      .map((client) => client.name)
                      .join(', ')}
                  </Button>
                </>
              )}
            {/* {filterCommunity?.length > 0 && (
              <>
                <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 2 }}>
                <span style={{ color: theme.palette.primary.darker }}>Selected Projects : </span>
                {communitiesData
                  .filter(community => filterCommunity.includes(community.id))
                  .map(community => community.name)
                  .join(', ')
                }
              </Typography> 
                <Button
                  variant="outlined"
                  sx={{
                    color: 'white',
                    borderColor: 'primary.main',
                    pointerEvents: 'none',
                    userSelect: 'text',
                  }}
                >
                  <span
                    style={{
                      color: theme.palette.primary.darker,
                      marginRight: 8,
                    }}
                  >
                    Selected Projects{' '}
                  </span>
                  {communitiesData
                    .filter((community) =>
                      filterCommunity?.includes(community.id),
                    )
                    .map((community) => community.name)
                    .join(', ')}
                </Button>
              </>
            )} */}
            <Scrollbar>
              <Grid container spacing={2}>
                <Tabs
                  value={currentTab}
                  scrollButtons="auto"
                  variant="scrollable"
                  allowScrollButtonsMobile
                  onChange={handleChangeTab}
                >
                  {ACCOUNT_TABS.map((tabItem) => (
                    <Tab
                      sx={{
                        justifyContent: 'center',
                        marginX: 6,
                        mt: 2.5,
                        overflow: 'visible',
                      }}
                      disableRipple
                      disabled={loadingCards}
                      key={tabItem.value}
                      label={
                        // <Badge
                        //   badgeContent={tabItem.count}
                        //   color="primary"
                        //   max={9999}
                        //   showZero
                        // >
                          <Typography variant="h6">
                            {capitalCase(tabItem.name)}
                          </Typography>
                        // </Badge>
                      }
                      // icon={tabItem.icon}
                      value={tabItem.value}
                    />
                  ))}
                </Tabs>
                {loadingCards ? (
                  <Grid
                    container
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Stack sx={{ my: 15 }}>
                      <LoadingScreen />
                    </Stack>
                  </Grid>
                ) : (
                  //   (filteredUsers?.length > 0 ? filteredUsers?.map((row) => {
                  //     const team_member = requestTeam?.length > 0 && requestTeam?.filter(member => member.request_id == row.id) || []

                  //     return (
                  //       <>
                  //       {/* <Grid key={row.id} item xs={12} sm={6} md={4}>
                  //         <OfficialCard card={row} team_member={team_member} key={row.id} />
                  //       </Grid> */}

                  //       <DragDropContext onDragEnd={handleOnDragEnd}>
                  //       <Droppable droppableId="officialRequests" direction="vertical">
                  //         {(provided) => (
                  //           <Grid container spacing={3} {...provided.droppableProps} ref={provided.innerRef}>

                  //             {filteredUsers.map((row, index) => (
                  //               <Draggable key={row.id} draggableId={row.id.toString()} index={index}>
                  //                 {(provided, snapshot) => (
                  //                   <Grid
                  //                   key={row.id}
                  //                     item
                  //                     xs={12}
                  //                     sm={6}
                  //                     md={4}
                  //                     sx={{mt:3}}
                  //                     ref={provided.innerRef}
                  //                     {...provided.draggableProps}
                  //                     {...provided.dragHandleProps}
                  //                     style={{
                  //                       ...provided.draggableProps.style,
                  //                       opacity: snapshot.isDragging ? 0.5 : 1,
                  //                     }}
                  //                   >
                  //                     <OfficialCard card={row} team_member={team_member} key={row.id} />
                  //                   </Grid>
                  //                 )}
                  //               </Draggable>
                  //             ))}
                  //             {provided.placeholder}
                  //           </Grid>
                  //         )}
                  //       </Droppable>
                  //     </DragDropContext>
                  //     </>

                  //     )
                  //   })
                  //   :
                  //   <Grid container alignItems={'center'} justifyContent={'center'}>
                  //     <Stack sx={{my:15}}>
                  //       <Typography gutterBottom align="center" variant="subtitle1">
                  //         No {capitalCase(currentTab)} Requests Found
                  //       </Typography>
                  //     </Stack>
                  //   </Grid>
                  // )
                  <>
                    {filteredUsers?.length > 0 ? (
                      <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable
                          droppableId="officialRequests"
                          direction="vertical"
                        >
                          {(provided) => (
                            <Grid
                              container
                              spacing={3}
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {filteredUsers.map((row, index) => {
                                console.log('fgbidfigfiig', row)
                                console.log(
                                  'fgbidfigfiig',
                                  row.installationCompleteStatus,
                                )

                                const team_member =
                                  requestTeam?.length > 0
                                    ? requestTeam.filter(
                                        (member) =>
                                          member.request_id === row.id,
                                      )
                                    : []

                                return (
                                  <Draggable
                                    key={row.id}
                                    draggableId={row.id.toString()}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        sx={{ mt: 3 }}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          ...provided.draggableProps.style,
                                          opacity: snapshot.isDragging
                                            ? 0.5
                                            : 1,
                                        }}
                                      >
                                        <OfficialCard
                                          card={row}
                                          team_member={team_member}
                                        //   setOpenSalesOrder={setOpenSalesOrder}
                                        //   opeSalesOrder={opeSalesOrder}
                                        //   removePopupData={removePopupData}
                                          SalesOrderId={SalesOrderId }
                                          setSalesOrderId={setSalesOrderId}
                                          salesOrderName={salesOrderName}
                                          setSalesOrderName={setSalesOrderName}
                                          renderCards={renderCard}
                                          setRenderCards={setRenderCard}
                                        //   handleOpenViewSalesOrder={handleOpenViewSalesOrder}
                                        //   handleOpenDetailsSalesOrder={handleOpenDetailsSalesOrder}
                                          CommunityJob={true}
                                          communityId={communityId}
                                        />
                                      </Grid>
                                    )}
                                  </Draggable>
                                )
                              })}
                              {provided.placeholder}
                            </Grid>
                          )}
                        </Droppable>
                      </DragDropContext>
                    ) : (
                      <Grid
                        container
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        <Stack sx={{ my: 15 }}>
                          <Typography
                            gutterBottom
                            align="center"
                            variant="subtitle1"
                          >
                            No {capitalCase(currentTab)} Orders Found
                          </Typography>
                        </Stack>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Scrollbar>
            {/* <Scrollbar>
            {allRequest?.length > 0 && (
              Object.entries(allRequest.reduce((acc, row) => {
                const community = row.community_name || "Others";
                if (!acc[community]) {
                  acc[community] = [];
                }
                acc[community].push(row);
                return acc;
              }, {})).map(([community, groupedRequests]) => (
                <div key={community}>
                  <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleExpandToggle(community)}>
                  {expandedCommunities[community] ? <ExpandLess /> : <ExpandMore />}
                  <Typography variant="h6" sx={{marginTop:2,marginBottom:2}}>{community}</Typography>
                  </div>
                  {expandedCommunities[community] && (
                  <Grid container spacing={3}>
                   {groupedRequests?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => {
                      const team_member = requestTeam?.length > 0 && requestTeam?.filter(member => member.request_id == row.id) || [];
                      // console.log("row-----------------------", row);
                      return (
                        <Grid key={row.id} item xs={12} sm={6} md={4}>
                          <OfficialCard card={row} team_member={team_member} />
                        </Grid>
                      );
                    })}
                  </Grid>
                  )}
                </div>
              ))
            )}
          </Scrollbar> */}
            {loadingCards ? (
              ''
            ) : (
              <Stack gap={2} sx={{ mt: 2 }}>
                <TablePagination
                  rowsPerPageOptions={[6, 12, 27]}
                  component="div"
                  count={totalCount || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Stack>
            )}
          </>
        )}
        {openPreview && (
          <Modal
            width={900}
            open={openPreview}
            handleClose={() => {
              setOpenPreview(false)
            }}
            modalTitle={'Preview'}
          >
            <OfficialRequestAddPreview
              preview={preview}
              markupType={markupType}
              hasMarkupPermission={hasMarkupPermission}
              DiscountTotal={newDiscountTotal}
              totalJobcostSum={newTotalJobcostSum}
              totalsum={newTotalsum}
              grandTotal={newGrandTotal}
              noCharge={noCharge}
              selectedMaterials={selectedMaterials}
              cartService={cartService}
              cart={cart}
              additionalSelectedServices={additionalSelectedServices}
              selectedServices={selectedServices}
              selectedProducts={selectedProducts}
              values={newValues}
            />
          </Modal>
        )}
      </Container>
      <FullScreenDialogSalesOrder
        open={opeSalesOrder}
        setOpen={setOpenSalesOrder}
        handleClickOpen={handleOpenViewSalesOrder}
        projectName={salesOrderName}
        removePopupData={removePopupData}
        projectId={SalesOrderId}
        isEditSalesOrder={isEditSalesOrder}
        setRenderCards={setRenderCard}
        renderCards={renderCard}
      />
    </Page>
  )
}
