import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography,
    Slide,
    Stack,
    Box,
    CircularProgress,
    Autocomplete,
    TextField,
  } from '@material-ui/core'
  import { useSnackbar } from 'notistack'
  import { alpha, experimentalStyled as styled } from '@material-ui/core/styles'
  import { useTheme } from '@material-ui/core/styles'
  import { forwardRef } from 'react'
  
  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  })

  const paymentMode = [
    {id: 0, name: 'Cash'},
    {id: 1, name: 'Check'},
    {id: 2, name: 'Debit Card'},
    {id: 3, name: 'Credit Card'},
    {id: 4, name: 'Consignment'},
  ];
  
  export default function ConfirmationPopupWithPaymentType({
    open,
    handleClose,
    message,
    handleClick,
    loading2,
    handlePaymentTypeChange
  }) {
    const { enqueueSnackbar } = useSnackbar()
    const theme = useTheme()
    return (
      <Dialog
        aria-describedby="alert-dialog-slide-description"
        TransitionComponent={Transition}
        transitionDuration={500}
        sx={{ minWidth: {xs:0 , sm: 600} }}
        open={open}
        onClose={handleClose}
        onBackdropClick={handleClose}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent sx={{ minWidth: 270, mt: -2 }}>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>

          <DialogContentText id="alert-dialog-description">
          <Autocomplete
                 options={paymentMode}
                 fullWidth
                 onChange={(event,newValue)=>{
                  handlePaymentTypeChange(newValue)
                 }} 
                 getOptionLabel={(option) => option.name || ''}
                 renderInput={(params) => (
                 <TextField {...params} label="Select Payment Mode" variant="outlined" />
                 )}
              
                />
          </DialogContentText>

        </DialogContent>
        {/* <DialogActions sx={{ justifyContent: 'flex-end !important' }}> */}
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          px={3}
          mb={2.5}
          mt={-1}
        >
          <Button
            size="medium"
            onClick={() => {
              handleClose()
              enqueueSnackbar('Operation was cancelled', { variant: 'error' })
            }}
            sx={{
              background: '#BB2525',
              boxShadow: `0 8px 16px 0 ${alpha('#BB2525', 0.24)}`,
              '&:hover': {
                background: '#FF8F8F',
                boxShadow: `0 8px 16px 0 ${alpha('#FF8F8F', 0.24)}`,
              },
            }}
          >
            <Typography
              sx={{ color: theme.palette.common.white }}
              variant="overline"
            >
              Cancel
            </Typography>
          </Button>
          <Button
            size="medium"
            variant="contained"
            onClick={() => {
              handleClick()
            }}
            sx={{ color: theme.palette.primary.main }}
            disabled={loading2}
          >
            <Typography
              sx={{ color: theme.palette.common.white }}
              variant="overline"
            >
              {loading2 ? <CircularProgress size={24} color="inherit" /> : 'Yes'}
            </Typography>
          </Button>
        </Stack>
        {/* </DialogActions> */}
      </Dialog>
    )
  }
  