import faker from 'faker';
import { sum } from 'lodash';
import {
  Box,
  Grid,
  Card,
  Table,
  Divider,
  TableRow,
  Container,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
  Stack,
  Button,
  TextField,
  OutlinedInput,
  Tooltip
} from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { fCurrency } from 'src/utils/formatNumber';
import Page from 'src/components/Page';
import Scrollbar from 'src/components/Scrollbar';
import { LoadingButton } from '@material-ui/lab';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
import { empty } from 'src/Helpers/Common';

// Styled Components
const RowResultStyle = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

export default function CompareJobCost({ id }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedProjects1, setSelectedProjects1] = useState([]);
  const [materialPayOut, setMaterialPayOut] = useState(0);
  const [materialPayOut1, setMaterialPayOut1] = useState(0);
  const [manualTotalPayOut, setManualTotalPayOut] = useState(0);
  const [hourlyTotalPayOut, setHourlyTotalPayOut] = useState(0);
  const [additionalServices, setAdditionalServices] = useState([])
  const [projectData, setProjectData] = useState([])
  const [recipe, setRecipe] = useState([])
  // const [services, setServices] = useState([]);
  const [services, setAllServices] = useState([]);
  const [jobCost, setJobCost] = useState(0);
  const [loading, setLoading] = useState(false);
  const [initialMaterials, setInitialMaterials] = useState([]);
  const [productList, setProductList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const params = localStorage.getItem("params");
  const user = JSON.parse(window.localStorage.getItem('user'))

  const fetchData = () => {
    try {
      setLoading(true)
      axios.post(`${REST_API_END_POINT}project/fetch-all-job-cost-recipe`,
        { userId: user.user_id,userType: user.user_type, projectId: id, orderId: params }).then((response) => {
        const productListData = response.data.results
        let selectedProducts = response.data.selectedMaterials
        // let selectedProducts = [
        //   ...(response.data.selectedMaterials || []), 
        //   ...(response.data.materials || [])
        // ];
        let receivedRfpProducts=selectedProducts.filter(row=>row.rfp===1)
        let rfpProducts = receivedRfpProducts.map(product => ({
            ...product,
            jobcost_unit_cost: product.unit_cost,
            jobcost_quantity: product.quantity,
            jobcost_actual_unit_cost: product.actual_unit_cost,
            jobcost_actual_quantity: product.actual_quantity
        }));
        selectedProducts=selectedProducts.filter(row=>row.rfp===0)
        let initialData = response.data.selectedMaterials
        const allPayouts = response.data.allPayOut
        const project = response.data.projects
        const allMaterials = response.data.allMaterials
        const allRecipes = response.data.allRecipies
        const material = response.data.materials
        let allServices = response.data.allServices
        let additionalServices = response.data.additionalServices
        if (additionalServices) {
          setAdditionalServices(additionalServices.reduce((accumulator, currentValue) => {
            return accumulator + (currentValue?.jobcost_unit_cost * currentValue?.jobcost_quantity);
          }, 0))
        }
        if (project) {
          setProjectData(project)
        }
        if (allRecipes) {
          setRecipe(allRecipes)
        }
        if (material?.length > 0) {
          setMaterialPayOut(
            material
              ?.filter(curr => curr.notFromRecipe != true)
              .reduce((acc, curr) => acc + curr.actual_total, 0)
          );
          
          setMaterialPayOut1(
            material
              ?.filter(curr => curr.notFromRecipe == true)
              .reduce((acc, curr) => acc + curr.total, 0)
          );          
        }
        if (allServices) {
          setAllServices(allServices)
        }
        if (allPayouts?.length > 0) {
          let totalPayOut = allPayouts?.reduce((acc, curr) => acc + curr.amount, 0)
          const totalHourlyPayoutAmount = allPayouts.filter(payout => payout.is_auto_pay === 1).reduce((acc, curr) => acc + curr.amount, 0);
          const totalManualPayoutAmount = allPayouts.filter(payout => payout.is_auto_pay !== 1).reduce((acc, curr) => acc + curr.amount, 0);
          
          const summedHourlyPayouts = Object.values(
            allPayouts?.filter(payout => payout.is_auto_pay === 1)?.reduce((acc, payout) => {
              // Create a unique key based on project_id, task_id, and team
              const key = `${payout.project_id}-${payout.task_id || "null"}-${payout.team || "null"}`;
              
              // Initialize the group in the accumulator if it doesn't exist
              if (!acc[key]) {
                acc[key] = {
                  project_id: payout.project_id,
                  task_id: payout.task_id,
                  team: payout.team,
                  total_amount: 0,
                };
              }
          
              // Add the current payout amount to the total for this group, rounded to 2 decimals
              acc[key].total_amount = parseFloat((acc[key].total_amount + payout.amount).toFixed(2));
          
              return acc;
            }, {})
          );
          
          console.log('summedHourlyPayouts===',summedHourlyPayouts);

          const summedManualPayouts = Object.values(
            allPayouts?.filter(payout => payout.is_auto_pay !== 1)?.reduce((acc, payout) => {
              // Create a unique key based on project_id, task_id, and team
              const key = `${payout.project_id}-${payout.task_id || "null"}-${payout.team || "null"}`;
              
              // Initialize the group in the accumulator if it doesn't exist
              if (!acc[key]) {
                acc[key] = {
                  project_id: payout.project_id,
                  task_id: payout.task_id,
                  team: payout.team,
                  total_amount: 0,
                };
              }
          
              // Add the current payout amount to the total for this group, rounded to 2 decimals
              acc[key].total_amount = parseFloat((acc[key].total_amount + payout.amount).toFixed(2));
          
              return acc;
            }, {})
          );
          
          console.log('summedManualPayouts===',summedManualPayouts);
          
          setHourlyTotalPayOut(summedHourlyPayouts?.reduce((acc, curr) => acc + curr.total_amount, 0))
          setManualTotalPayOut(summedManualPayouts?.reduce((acc, curr) => acc + curr.total_amount, 0))
          // setHourlyTotalPayOut(allPayouts.filter(payout => payout.is_auto_pay === 1).reduce((acc, curr) => acc + curr.amount, 0))
          // setManualTotalPayOut(allPayouts.filter(payout => payout.is_auto_pay !== 1).reduce((acc, curr) => acc + curr.amount, 0))
        }
        if (initialData) {
          setInitialMaterials(initialData)
        }
        if (productListData) {
          setProductList(productListData)
        }
        if (allMaterials) {
          setMaterialList(allMaterials)
        }
        if (project) {
          setJobCost(project.cost || 0)
        }
        if (selectedProducts?.length > 0 || material?.length > 0) {
          const selectedProjects1 = selectedProducts?.map(selectedProduct => {
            const matchingProduct = productListData.find(product => product.id === selectedProduct.product_id);
            if (matchingProduct) {
              return {
                ...matchingProduct,
                product_id: selectedProduct.product_id,
                jobcost_quantity: selectedProduct.quantity,
                jobcost_unit_cost: selectedProduct.unit_price,
                jobcost_total: selectedProduct.total,
                jobcost_actual_quantity: selectedProduct.actual_quantity,
                jobcost_actual_unit_cost: selectedProduct.actual_unit_price,
                recipeName: selectedProduct.recipeName,
                taken:selectedProduct.taken,
                task_id:selectedProduct.task_id
              };
            }
            return null; // Handle cases where no matching product is found
          }).filter(project => project !== null);
          const selectedProjects2 = material?.map(selectedProduct => {
            const matchingProduct = productListData.find(product => product.id === selectedProduct.product_id);
            if (matchingProduct) {
              return {
                ...matchingProduct,
                product_id: selectedProduct.product_id,
                jobcost_quantity: selectedProduct.quantity,
                jobcost_unit_cost: selectedProduct.unit_price,
                jobcost_total: selectedProduct.total,
                jobcost_actual_quantity: selectedProduct.actual_quantity,
                jobcost_actual_unit_cost: selectedProduct.actual_unit_price,
                recipeName: selectedProduct.recipeName,
                taken:selectedProduct.taken,
                task_id:selectedProduct.task_id
              };
            }
            return null; // Handle cases where no matching product is found
          }).filter(project => project !== null);
          setSelectedProjects([...selectedProjects1,...rfpProducts]);
          setSelectedProjects1([...selectedProjects2]);

          const materialPayOut = selectedProjects2
            ?.filter(curr => curr.notFromRecipe != true)
            .reduce((acc, curr) => acc + curr.jobcost_actual_quantity * curr.jobcost_actual_unit_cost, 0);

          const materialPayOut1 = selectedProjects2
            ?.filter(curr => curr.notFromRecipe != true)
            .reduce((acc, curr) => acc + curr.jobcost_total, 0);

          console.log('Material PayOut (Not From Recipe):', materialPayOut);
          console.log('Material PayOut1 (From Recipe):', materialPayOut1);

          setMaterialPayOut(materialPayOut);
          setMaterialPayOut1(materialPayOut1);

        }
      })  
    }
    catch (error) {
      console.error("Error fetching data:", error);
    }finally{
      setTimeout(() => {
        setLoading(false)
      }, 500);
    }
  }

  useEffect(() => {
    fetchData();
  }, [id]);

  const grandTotalCost = useMemo(() => {
    const selectedProjectsCost = selectedProjects.reduce(
      // (acc, proj) => acc + (proj.jobcost_actual_unit_cost) * proj.jobcost_actual_quantity,
      // (acc, proj) => acc + (Number(proj.jobcost_total)), //* proj.jobcost_actual_quantity,
      //{fCurrency((project.jobcost_actual_unit_cost || project.unit_cost) * project.jobcost_actual_quantity)}
      (acc, proj) => {
        // Use `jobcost_total` if available; otherwise, calculate using unit cost and quantity
        const totalCost = proj.jobcost_total 
          ? Number(proj.jobcost_total) 
          : (Number(proj.jobcost_actual_unit_cost || proj.unit_cost || 0) * Number(proj.jobcost_actual_quantity || 0));
    
        return acc + totalCost;
      }, 0
    );
    // const selectedProjectsCost = selectedProjects.reduce(
    //   (acc, proj) => acc + (Number(proj.jobcost_actual_unit_cost) || 0) * (Number(proj.jobcost_actual_quantity) || 0),
    //   0
    // );    

    // const servicesCost = services.reduce(
    //   (acc, serv) => acc + serv.jobcost_unit_cost * serv.jobcost_quantity,
    //   0
    // );


    const servicesCost = services.reduce((sum, service) => {
      // if (service.recipe_id == null) {
        const quantity = parseFloat(service.qty || 0);
        const unitCost = parseFloat(service.originalCost || service.unit_cost || 0);
        return sum + (quantity * unitCost);
      // }
      return sum;
    }, 0);
    return (
      materialPayOut +
      selectedProjectsCost +
      servicesCost 
      // + Math.max(0, manualTotalPayOut + hourlyTotalPayOut - servicesCost)
    );
  }, [selectedProjects, services, materialPayOut, manualTotalPayOut, hourlyTotalPayOut]);

  const grandTotalCost1 = useMemo(() => {
    const selectedProjectsCost = selectedProjects?.filter(i => i.taken==1)?.reduce(
      (acc, proj) => acc + proj.jobcost_total,
      0
    );

    // const servicesCost = services?.filter(i => i.taken==1)?.reduce(
    //   (acc, serv) => acc + serv.jobcost_unit_cost * serv.jobcost_quantity,
    //   0
    // );

    const servicesCost = services.reduce((sum, service) => {
      if (projectData?.job_category_name === 'Time & Material') {
        // if (service.recipe_id == null) {
        if (service.taken == 1) {
          return sum + parseFloat(service.jobcost_total_cost || 0);
        }
      } else {
        // if (service.recipe_id == null) {
        if (service.taken == 1) {
          return sum + parseFloat(service.jobcost_total_cost || 0);
          // const quantity = parseFloat(service.jobcost_quantity || service.qty || 0);
          // const unitCost = parseFloat(service.jobcost_unit_cost || service.originalCost || service.unit_cost || 0);
          // return sum + (quantity * unitCost);
        }
      }
      return sum;
    }, 0);

    console.log('grandtotal1==materialPayOut1==',materialPayOut1)
    console.log('grandtotal1==selectedProjectsCost==',selectedProjectsCost)
    console.log('grandtotal1==servicesCost==',servicesCost)
    console.log('grandtotal1==manualTotalPayOut==',manualTotalPayOut)
    console.log('grandtotal1==hourlyTotalPayOut==',hourlyTotalPayOut)

    return (
      materialPayOut1 +
      selectedProjectsCost +
      servicesCost +
      Math.max(0, manualTotalPayOut + hourlyTotalPayOut - servicesCost)
    );
  }, [selectedProjects, services, materialPayOut1, manualTotalPayOut, hourlyTotalPayOut]);

  const grandTotalCost2 = useMemo(() => {
    const selectedProjectsCost = selectedProjects?.filter(i => i.taken==1)?.reduce(
      (acc, proj) => acc + proj.jobcost_total,
      0
    );

    // const servicesCost = services?.filter(i => i.taken==1)?.reduce(
    //   (acc, serv) => acc + serv.jobcost_unit_cost * serv.jobcost_quantity,
    //   0
    // );

    const servicesCost = services.reduce((sum, service) => {
      if (projectData?.job_category_name === 'Time & Material') {
        // if (service.recipe_id == null) {
        if (service.taken == 1) {
          return sum + parseFloat(service.jobcost_total_cost || 0);
        }
      } else {
        // if (service.recipe_id == null) {
        if (service.taken == 1) {
          return sum + parseFloat(service.jobcost_total_cost || 0);
        }
      }
      return sum;
    }, 0);

    return (
      materialPayOut1 +
      selectedProjectsCost +
      servicesCost 
      // +
      // Math.max(0, manualTotalPayOut + hourlyTotalPayOut - servicesCost)
    );
  }, [selectedProjects, services, materialPayOut1, manualTotalPayOut, hourlyTotalPayOut]);

  const additionalLaborCost = useMemo(() => (
    services.reduce((sum, service) => {
      if (service.recipe_id == null) {
        const quantity = parseFloat(service.qty || 0);
        const unitCost = parseFloat(service.originalCost || service.unit_cost || 0);
        return sum + (quantity * unitCost);
      }
      // else {
      //   const jobcost_total_cost = parseFloat(service.jobcost_total_cost || 0);
      //   return sum + jobcost_total_cost;
      // }
      // if (service.recipe_id == null) {
      //   return sum + service.jobcost_unit_cost * service.jobcost_quantity;
      // }
      return sum;
    }, 0)
  ), [services]);
  console.log('additionalLaborCost=====',additionalLaborCost)
  console.log('services=====',services)
  console.log('selectedProjects=====',selectedProjects)
  const additionalLaborCost1 = useMemo(() => (
    services.reduce((sum, service) => {
      if(projectData?.job_category_name=='Time & Material'){
        const jobcost_total_cost = parseFloat(service.jobcost_total_cost || 0);
        if (service.recipe_id == null && service.taken == 1) {
          return sum + jobcost_total_cost;
        }else{
          return sum + 0
        }
      }else{
        const quantity = parseFloat(service.jobcost_quantity || service.qty || 0);
        const unitCost = parseFloat(service.jobcost_unit_cost || service.originalCost || service.unit_cost || 0);
        const jobcost_total_cost = parseFloat(service.jobcost_total_cost || 0);
        if (service.recipe_id == null && service.labour_cost_service_id == 10 && service.taken == 1) {
          return sum + jobcost_total_cost;
        }else if (service.recipe_id == null && service.taken == 1) {
          // return sum + (quantity * unitCost);
          return sum + jobcost_total_cost;
        }else{
          return sum + 0
        }
      }
      // if (service.recipe_id == null) {
      //   return sum + service.jobcost_unit_cost * service.jobcost_quantity;
      // }
      return sum;
    }, 0)
  ), [services]);

  // const productFormulaCost = useMemo(() => (
  //   selectedProjects?.reduce((sum, proj) => sum + (proj.jobcost_actual_unit_cost || proj.unit_cost) * proj.jobcost_actual_quantity, 0) +
  //   services.reduce((sum, serv) => (serv.recipe_id ? sum + serv.jobcost_unit_cost * serv.jobcost_quantity : sum), 0)
  // ), [selectedProjects, services]);

  const productFormulaCost = useMemo(() => (
    (selectedProjects?.reduce(
      (sum, proj) => sum + (Number(proj.jobcost_actual_unit_cost) || Number(proj.unit_cost)) * Number(proj.jobcost_actual_quantity), 
      0
    ) || 0) +
    (services?.reduce(
      (sum, serv) => (serv.recipe_id != null ? sum + serv.jobcost_unit_cost * serv.jobcost_quantity : sum), 
      0
    ) || 0)
  ), [selectedProjects, services]);
  

  // const productFormulaCost1 = useMemo(() => (
  //   selectedProjects?.filter(i => i.taken==1)?.reduce((sum, proj) => sum + proj.jobcost_total, 0) +
  //   services?.filter(i => i.taken==1)?.reduce((sum, serv) => (serv.recipe_id ? sum + serv.jobcost_unit_cost * serv.jobcost_quantity : sum), 0)
  // ), [selectedProjects, services]);
  console.log('selectedProjects=1===',selectedProjects);
  console.log('selectedProjects=2===',services);
  const productFormulaCost1 = useMemo(() => (
    (selectedProjects?.reduce(
      (sum, proj) => (proj.taken == 1 && !empty(proj.recipeName) ? sum + proj.jobcost_total : sum), 
      0
    ) || 0) +
    (services?.reduce(
      (sum, serv) => (serv.taken == 1 && serv.recipe_id != null ? sum + serv.jobcost_unit_cost * serv.jobcost_quantity : sum), 
      0
    ) || 0)
  ), [selectedProjects, services]);
  

  const excessLaborCost = 0;
  // const excessLaborCost = useMemo(() => (
  //   Math.max(0, manualTotalPayOut + hourlyTotalPayOut - additionalLaborCost)
  // ), [manualTotalPayOut, hourlyTotalPayOut, additionalLaborCost]);

  const excessLaborCost1 = useMemo(() => (
    Math.max(0, manualTotalPayOut + hourlyTotalPayOut - additionalLaborCost1)
  ), [manualTotalPayOut, hourlyTotalPayOut, additionalLaborCost1]);

  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape({}),
    onSubmit: () => {}
  });

  return (
    <Page title="Job Calculator">
      <Container>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Card>
              <Box sx={{ display: 'flex', alignItems: 'center', py: 2, pl: 3 }}>
                <Typography variant="h4" noWrap>
                  Expected Budget
                </Typography>
              </Box>
              <FormikProvider value={formik}>
                <Scrollbar sx={{ height: '320px' }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name </TableCell>
                          <TableCell>{projectData?.job_category_name === 'Time & Material' ? '' : 'Product Formula'}</TableCell>
                          <TableCell>Quantity / Hrs</TableCell>
                          {/* <TableCell>Unit Price</TableCell> */}
                          <TableCell>Total</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedProjects?.map((project, index) => (
                          <TableRow key={index}>
                            <TableCell>{project.name} {project?.barcode ? '' : "Sub contract"}</TableCell>
                            <TableCell>{project.recipeName || ''}</TableCell>
                            <TableCell>{project.jobcost_actual_quantity}</TableCell>
                            {/* <TableCell>{fCurrency((project.jobcost_actual_unit_cost || project.unit_cost))}</TableCell> */}
                            <TableCell>
                              {fCurrency((project.jobcost_actual_unit_cost || project.unit_cost) * project.jobcost_actual_quantity)}
                            </TableCell>
                            

                          </TableRow>
                        ))}
                        {selectedProjects1?.filter(data=>data?.notFromRecipe != true)?.map((project, index) => ( // && data?.jobcost_actual_quantity > 0
                          <TableRow key={index}>
                            <TableCell>{project.name}</TableCell>
                            <TableCell>{''}</TableCell>
                            <TableCell>{project.jobcost_actual_quantity}</TableCell>
                            {/* <TableCell>{fCurrency(project.jobcost_actual_unit_cost)}</TableCell> */}
                            <TableCell>
                              {fCurrency((project.jobcost_actual_unit_cost) * project.jobcost_actual_quantity)}
                            </TableCell>
                          </TableRow>
                        ))}
                        {services.map((service, index) => (
                          <TableRow key={index}>
                            <TableCell style={{ color: 'yellow' }}>{service.name}</TableCell>
                            <TableCell style={{ color: 'yellow' }}>{service.recipeName}</TableCell>
                            <TableCell style={{ color: 'yellow' }}>{service.qty}</TableCell>
                            {/* <TableCell style={{ color: 'yellow' }}>{fCurrency(service.jobcost_unit_cost|| service.originalCost||service.unit_cost)}</TableCell> */}
                            <TableCell style={{ color: 'yellow' }}>
                              {fCurrency((service.qty * (service.originalCost||service.unit_cost))?.toFixed(2))}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <Box>
                <RowResultStyle>
                  <TableCell align="right" colSpan={4}>
                    {projectData?.job_category_name === 'Time & Material' ? 
                      <Typography variant="h6">Material Cost</Typography>
                    :
                      <Typography variant="h6">Additional Material Cost</Typography>
                    }
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="h6">{fCurrency(materialPayOut)}</Typography>
                  </TableCell>
                </RowResultStyle>
                {projectData?.job_category_name === 'Time & Material' ? '' :
                <RowResultStyle>
                  <TableCell align="right" colSpan={4}>
                      {/* <Typography variant="h6">Services Cost</Typography> */}
                      <Typography variant="h6">Additional Services</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="h6">{fCurrency(additionalLaborCost)}</Typography>
                  </TableCell>
                </RowResultStyle>
                }
                {projectData?.job_category_name === 'Time & Material' ? '' :
                <RowResultStyle>
                  <TableCell align="right" colSpan={4}>
                    <Typography variant="h6">Actual Product Formula Cost</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="h6">{fCurrency(productFormulaCost)}</Typography>
                  </TableCell>
                </RowResultStyle>}
                <RowResultStyle>
                  <TableCell align="right" colSpan={4}>
                    <Typography variant="h6">{projectData?.job_category_name === 'Time & Material'? "Service Cost":"Excess Labor Cost"}</Typography>
                  </TableCell>
                  <TableCell align="left">
                  {projectData?.job_category_name === 'Time & Material'? 
                    <Typography variant="h6">{fCurrency(additionalLaborCost)}</Typography>
                    :
                    <Typography variant="h6">{fCurrency(excessLaborCost)}</Typography>
                  }
                  </TableCell>
                </RowResultStyle>
                <RowResultStyle>
                  <TableCell align="right" colSpan={4}>
                    <Typography variant="h6" sx={{ color: '#fc8c03' }}>Total Cost</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="h6" sx={{ color: '#fc8c03' }}>{fCurrency(grandTotalCost)}</Typography>
                  </TableCell>
                </RowResultStyle>
                </Box>
                </Box>
              </FormikProvider>
            </Card>
          </Grid>

          <Grid item md={6} xs={12}>
            <Card>
              <Box sx={{ display: 'flex', alignItems: 'center', py: 2, pl: 3 }}>
                <Typography variant="h4" noWrap>
                  Actual Budget
                </Typography>
              </Box>
              <FormikProvider value={formik}>
                <Scrollbar sx={{ height: '320px' }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>{projectData?.job_category_name === 'Time & Material' ? '' : 'Product Formula'}</TableCell>
                          <TableCell>Quantity / Hrs</TableCell>
                          {/* <TableCell>Unit Price</TableCell> */}
                          <TableCell>Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedProjects?.filter(i => i.taken==1)?.map((project, index) => (
                          <TableRow key={index}>
                            <TableCell>{project.name} {project?.barcode ? '' : "Sub contract"}</TableCell>
                            <TableCell>{project.recipeName || ''}</TableCell>
                            <TableCell>{project.jobcost_quantity}</TableCell>
                            {/* <TableCell>{fCurrency(project.jobcost_unit_cost)}</TableCell> */}
                            <TableCell>
                              {/* {fCurrency(project.jobcost_unit_cost * project.jobcost_quantity)} */}
                              {fCurrency(project.jobcost_total)}
                            </TableCell>
                          </TableRow>
                        ))}
                        {selectedProjects1?.filter(data=>(data.notFromRecipe != true && data.jobcost_quantity > 0))?.map((project, index) => (
                          <TableRow key={index}>
                            <TableCell>{project.name}</TableCell>
                            <TableCell>{''}</TableCell>
                            <TableCell>{project.jobcost_quantity}</TableCell>
                            {/* <TableCell>{fCurrency(project.jobcost_unit_cost)}</TableCell> */}
                            <TableCell>
                              {/* {fCurrency(project.jobcost_unit_cost * project.jobcost_quantity)} */}
                              {fCurrency(project.jobcost_total)}
                            </TableCell>
                          </TableRow>
                        ))}
                        {services?.filter(i => i.taken==1)?.map((service, index) => (
                          <TableRow key={index}>
                            <TableCell style={{ color: 'yellow' }}>{service.name}</TableCell>
                            <TableCell style={{ color: 'yellow' }}>{service.recipeName}</TableCell>
                            <TableCell style={{ color: 'yellow' }}>{service.jobcost_quantity}</TableCell>
                            {/* <TableCell style={{ color: 'yellow' }}>{fCurrency(service.jobcost_unit_cost|| service.originalCost||service.unit_cost)}</TableCell> */}
                            <TableCell style={{ color: 'yellow' }}>
                              {projectData?.job_category_name=='Time & Material' ? 
                                <Typography variant="subtitle2" style={{ color: 'yellow' }}>${service.jobcost_total_cost}</Typography>
                              : (service.labour_cost_service_id == 10 ?
                                <Typography variant="subtitle2" style={{ color: 'yellow' }}>${service.jobcost_total_cost}</Typography>
                              :
                                <Typography variant="subtitle2" style={{ color: 'yellow' }}>${service.jobcost_total_cost}</Typography>
                                // <Typography variant="subtitle2" style={{ color: 'yellow' }}>${((service.jobcost_quantity || service.qty)*(service.jobcost_unit_cost||service.originalCost||service.unit_cost))?.toFixed(2)}</Typography>
                              )}
                              {/* {fCurrency(((service.jobcost_quantity)*(service.jobcost_unit_cost||service.originalCost||service.unit_cost))?.toFixed(2))} */}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <Box>
                <RowResultStyle>
                  <TableCell align="right" colSpan={4}>
                    {projectData?.job_category_name === 'Time & Material' ? 
                      <Typography variant="h6">Material Cost</Typography>
                    :
                      <Typography variant="h6">Additional Material Cost</Typography>
                    }
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="h6">{fCurrency(materialPayOut1)}</Typography>
                  </TableCell>
                </RowResultStyle>
                {projectData?.job_category_name === 'Time & Material' ? "" :
                <RowResultStyle>
                  <TableCell align="right" colSpan={4}>
                    {/* <Typography variant="h6">Services Cost</Typography> */}
                    <Typography variant="h6">Additional Services</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="h6">{fCurrency(additionalLaborCost1)}</Typography>
                  </TableCell>
                </RowResultStyle>
                }
                {(projectData?.job_category_name === 'Time & Material') ? '' :
                <RowResultStyle>
                  <TableCell align="right" colSpan={4}>
                    <Typography variant="h6">Actual Product Formula Cost</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="h6">{fCurrency(productFormulaCost1)}</Typography>
                  </TableCell>
                </RowResultStyle>}
                <RowResultStyle>
                  <TableCell align="right" colSpan={4}>
                    <Typography variant="h6">{(projectData?.job_category_name === 'Time & Material') ? "Service Cost" : "Excess Labor Cost"}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    {/* <Typography variant="h6">{fCurrency(excessLaborCost1)}</Typography> */}
                    {projectData?.job_category_name === 'Time & Material'? 
                    <Typography variant="h6">{fCurrency(additionalLaborCost1)}</Typography>
                    :
                    <Typography variant="h6">{fCurrency(excessLaborCost1)}</Typography>
                  }
                  </TableCell>
                </RowResultStyle>
                <RowResultStyle>
                  <TableCell align="right" colSpan={4}>
                    <Typography variant="h6" sx={{ color: '#fc8c03' }}>Total Cost</Typography>
                  </TableCell>
                  <TableCell align="left">
                  {projectData?.job_category_name === 'Time & Material'? 
                    <Typography variant="h6" sx={{ color: '#fc8c03' }}>{fCurrency(grandTotalCost2)}</Typography>
                    :
                    <Typography variant="h6" sx={{ color: '#fc8c03' }}>{fCurrency(grandTotalCost1)}</Typography>
                  }
                  </TableCell>
                </RowResultStyle>
                </Box>
                </Box>
              </FormikProvider>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
