import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { paramCase } from 'change-case';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import undoOutline from '@iconify/icons-eva/undo-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { Autorenew, BlockOutlined, CopyAll, Delete, TaskAltOutlined } from '@material-ui/icons';
// material
import { useTheme } from '@material-ui/core/styles';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Typography, Grid, Stack, TextField, Autocomplete, Box, Button, Checkbox, FormControlLabel, } from '@material-ui/core';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
import axios from 'axios';
import { REST_API_END_POINT, UNITS } from 'src/constants/Defaultvalues';
import { useSnackbar } from 'notistack';
import ConfirmationPopup from '../../official-request/ConfirmationPopup'
import Modal from '../../blog/ProjectModel';
// ----------------------------------------------------------------------

MaterialInventoryMenu.propTypes = {
  onDelete: PropTypes.func,
  userName: PropTypes.string,
  id: PropTypes.number,
  status: PropTypes.number
};

export default function MaterialInventoryMenu({ materialTab, onMoveToClientOwned, setLoading, noDelete,showRefill,onDeletePartial,barcode,unit,unitName,onDelete,partialFinishedProducts, userName, id, unit_cost, qty, noEdit, status, fromFinishedProducts,fromInventoryAudit, onRefresh, onEdit ,setRefresh,maxQty,vpt_number}) {
  const ref = useRef(null);
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false);
  const [openDeletePop,setOpenDeletePop] =useState(false)
  const [openPartialPop,setOpenPartialPop] =useState(false)
  const [partialModalOpen,setPartialModalOpen] =useState(false)
  const [movingQty,setMovingQty] =useState(1)
  const [convertionUnit,setConvertionUnit] =useState(null)
  const [convertionQty,setConvertionQty] =useState(0)
  const [convertionUnitPrice,setConvertionUnitPrice] =useState(0)
  const [convertionTotalPrice,setConvertionTotalPrice] =useState(0)
  const [autoFill,setAutoFill] =useState(true)
  const [openWastePop,setOpenWastePop] =useState(false)
  const [openDeletePartialPop,setOpenDeletePartialPop] =useState(false)
  const [openMovetoClientOwned,setOpenMovetoClientOwned] =useState(false)
  const [errors, setErrors] = useState({});
  const [wasteqty,setWasteQty] =useState(1)
  const [refillOpen,setRefillOpen] =useState(false)
  const [refillQty,setRefillQty] =useState('')
  const [openRefillPop,setOpenRefillPop] =useState(false)
  
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const userData = JSON.parse(localStorage.getItem('user'))
  const explodedPermissions =userData?.exploded_permissions

  const changeStatus = async (prodId, prodStatus) => {
    try {
      let id = prodId;
      let status = prodStatus;
      let response;
      // console.log("id-----", id, " status-----------", status)
      if(fromFinishedProducts){
        response = await axios.post(`${REST_API_END_POINT}product/delete-finished-product`,{id})
      } else if(partialFinishedProducts){
        response = await axios.post(`${REST_API_END_POINT}product/delete-finished-product`,{id})
      } else{
       response = await axios.post((`${REST_API_END_POINT}product/delete-product?id=${id}&status=${status}`),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        })
      }
      // console.log("response-----------------", response.data);
      if (response.data.status === 1 || response.data.status === 2 || response.data.status === 4) {
        enqueueSnackbar(response.data.message, { variant: 'success' });
      }
      else if (response.data.status === 0 || response.data.status === 3 || response.data.status === 5 || response.data.status === 500) {
        enqueueSnackbar(response.data.message, { variant: 'error' });
      }
      else {
        console.log("Error.........!");
        enqueueSnackbar("Error.........!", { variant: 'error' });
      }
      (fromFinishedProducts || partialFinishedProducts) ? onRefresh(id) : navigate(PATH_DASHBOARD.products.material, { state: { data: true } });
    }
    catch (error) {
      console.log("Delete error : ", error);
    }
  }

  const handleMenuItemClick = async (id, newStatus) => {
    setIsOpen(false)
    setOpenDeletePop(false);
    await changeStatus(id, newStatus);
    if(!fromFinishedProducts && !partialFinishedProducts){
      navigate(PATH_DASHBOARD.products.material);
    }
  }

  const handleSubmitPartial = () => {
    const newErrors = {};
    
    if (!movingQty || movingQty <= 0) newErrors.movingQty = "Required";
    if (!convertionUnit) newErrors.convertionUnit = "Required";
    if (!convertionQty || convertionQty <= 0) newErrors.convertionQty = "Required";
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setOpenPartialPop(true);
    setErrors({});
  };

  const handleSubmitRefill = () => {
    const newErrors = {};
    if (!refillQty || refillQty <= 0) newErrors.refillQty = "Required";
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setOpenRefillPop(true);
    setErrors({});
  };

  const handleRefillFinishedProduct = async (id) => {
    try {
      setIsOpen(false);
      setRefillOpen(false);
      setOpenRefillPop(false);
      let response = await axios.post(`${REST_API_END_POINT}product/refill-finished-product`, 
        {userId:userData?.user_id, refillFinishedProductId:id, refillQty })
      if (response.data.status === 1) {
        enqueueSnackbar(response.data.message, { variant: 'success' });
      } else {
        enqueueSnackbar(response.data.message, { variant: 'error' });
      }
      onRefresh(id);
    } catch (error) {
      console.error("Error : ", error);
    }
  }

  const handleMoveToPartial = async (id) => {
    try {
      setIsOpen(false);
      setPartialModalOpen(false);
      setOpenPartialPop(false);
      let response = await axios.post(`${REST_API_END_POINT}product/move-finished-product-partial`, 
        {userId:userData?.user_id,editFinishedProductId:id,movingQty,convertionUnit,convertionQty,convertionUnitPrice,convertionTotalPrice,autoFill })
      if (response.data.status === 1) {
        enqueueSnackbar(response.data.message, { variant: 'success' });
      } else {
        enqueueSnackbar(response.data.message, { variant: 'error' });
      }
      onRefresh(id);
    } catch (error) {
      console.error("Error : ", error);
    }
  }

  const handleMoveToWaste = async (id) => {
    try {
      setIsOpen(false);
      setOpenWastePop(false);
      let response = await axios.post(`${REST_API_END_POINT}product/move-finished-product-to-waste`, 
        {userId:userData?.user_id,editFinishedProductId:id,wasteqty})
      if (response.data.status === 1) {
        enqueueSnackbar(response.data.message, { variant: 'success' });
      } else {
        enqueueSnackbar(response.data.message, { variant: 'error' });
      }
      onRefresh(id);
    } catch (error) {
      console.error("Error : ", error);
    }
  }

  const handleDeletePartial = async (id) => {
    try {
      setIsOpen(false);
      setOpenDeletePartialPop(false);
      let response = await axios.post(`${REST_API_END_POINT}product/delete-partial-finished-product`, 
        { userId:userData?.user_id, deleteFinishedProductId:id })
      if (response.data.status === 1) {
        enqueueSnackbar(response.data.message, { variant: 'success' });
      } else {
        enqueueSnackbar(response.data.message, { variant: 'error' });
      }
      onRefresh(id);
    } catch (error) {
      console.error("Error : ", error);
    }
  }

  const handleMoveToClientOwned = async (material_id) => {
    setLoading(true);
    try {
      setIsOpen(false);
      setOpenMovetoClientOwned(false);
      let response = await axios.post(`${REST_API_END_POINT}product/move-to-client-owned`, 
        { userId:userData?.user_id, id:material_id })
      if (response.data.status === 1) {
        enqueueSnackbar(response.data.message, { variant: 'success' });
      } else {
        enqueueSnackbar(response.data.message, { variant: 'error' });
      }
      setRefresh(true);
    } catch (error) {
      console.error("Error : ", error);
    }finally{
      setLoading(false);
    }
  }

  const handleDuplicate=async()=>{
    let res = await axios.post(`${REST_API_END_POINT}product/duplicate`,{
      id,
     })
      if(res.data.status ==1){
        enqueueSnackbar(res.data.message,{variant:'success'})
        setRefresh(true)
      }else if( res.data.status ==0){
        enqueueSnackbar(res.data.message,{variant:'success'})
        
      }else{
      enqueueSnackbar(res.data.message,{variant:'success'})
  
      }
    
   
    
   }
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {(fromFinishedProducts || partialFinishedProducts) && onEdit ? (
            !noEdit ? 
              <MenuItem
                onClick={()=>{onEdit(id);setIsOpen(false);}}
              >
                <ListItemIcon>
                  <Icon icon={editFill} width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
              </MenuItem>
            : ""
        ) : (
          <>
            <MenuItem
              disabled={explodedPermissions?.some(permission => ['Edit Material Label'].includes(permission)) ? false : true}
              // component={RouterLink}
              // to={`${PATH_DASHBOARD.products.editMaterial}?id=${id}/edit`}
              onClick={()=>{
                if(status==1){
                  navigate(`${PATH_DASHBOARD.products.editMaterial}?id=${id}/edit`,{state:{materialTab:materialTab}});
                }else{
                  enqueueSnackbar('Inactive material. Please activate to edit.', { variant: 'warning' });
                  setIsOpen(false);
                }
              }}
            >
              <ListItemIcon>
                <Icon icon={editFill} width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
            <MenuItem onClick={()=>{handleDuplicate()}} >
              <ListItemIcon>
              <CopyAll icon={CopyAll} width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary="Duplicate" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          </>
        )}

        {(fromFinishedProducts || partialFinishedProducts) ? '' : (status === 1 ? (
          <MenuItem
          disabled={explodedPermissions?.some(permission => ['Activate/Deactivate Material Label'].includes(permission)) ? false : true}
          onClick={() => handleMenuItemClick(id, 0)}
          >
            <ListItemIcon>
              <BlockOutlined sx={{ width: 25, height: 25 }} />
            </ListItemIcon>
            <ListItemText primary="Deactivate" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        ) : (
          <MenuItem
          disabled={explodedPermissions?.some(permission => ['Activate/Deactivate Material Label'].includes(permission)) ? false : true}
          onClick={() => handleMenuItemClick(id, 1)}
          >
            <ListItemIcon>
              <TaskAltOutlined sx={{ width: 25, height: 25 }} />
            </ListItemIcon>
            <ListItemText primary="Activate" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        ))}

        {noDelete ? '' :
        ((!fromInventoryAudit && !partialFinishedProducts) && <MenuItem
        disabled={explodedPermissions?.some(permission => ['Delete Material Label'].includes(permission)) ? false : true}
        onClick={()=>{setOpenDeletePop(true),setIsOpen(false)}}>
          <ListItemIcon>
          <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>)}

        {(partialFinishedProducts && !fromInventoryAudit) && <MenuItem onClick={()=>{setOpenWastePop(true),setIsOpen(false)}}>
          <ListItemIcon>
          <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Move to Waste" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}

        {(fromFinishedProducts && !fromInventoryAudit) && <MenuItem onClick={()=>{setPartialModalOpen(true),setIsOpen(false)}}>
          <ListItemIcon>
          <Icon icon={undoOutline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Move to Partial" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}

        {(fromFinishedProducts && showRefill) && <MenuItem onClick={()=>{setRefillOpen(true),setIsOpen(false)}}>
          <ListItemIcon>
          <Autorenew width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Refill" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}

        {onDeletePartial && <MenuItem onClick={()=>{setOpenDeletePartialPop(true),setIsOpen(false)}}>
          <ListItemIcon>
            {/* <Icon icon={trash2Outline} width={24} height={24} /> */}
            <Delete width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}

        {onMoveToClientOwned && <MenuItem onClick={()=>{setOpenMovetoClientOwned(true),setIsOpen(false)}}>
          <ListItemIcon>
            <Icon icon={undoOutline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Move to Client Owned" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
      </Menu>

    {/* Confirmation for deleting product  */}
    <ConfirmationPopup 
      open={openDeletePop}
      handleClose={()=>{setOpenDeletePop(false)}}
      message={`You want to delete ${userName} ! `}
      handleClick={()=>{handleMenuItemClick(id, 2)}}
    />
    <ConfirmationPopup 
      open={openPartialPop}
      handleClose={()=>{setOpenPartialPop(false)}}
      message={`You want to move ${userName} to partial! `}
      handleClick={()=>{handleMoveToPartial(id)}}
    />
    <ConfirmationPopup 
      open={openRefillPop}
      handleClose={()=>{setOpenRefillPop(false)}}
      message={`You want to refill ${userName} (${barcode}) `}
      handleClick={()=>{handleRefillFinishedProduct(id)}}
    />
    <ConfirmationPopup 
      open={openWastePop}
      handleClose={()=>{setOpenWastePop(false)}}
      message={`You want to move quantity of ${wasteqty} ${userName} to waste! `}
      handleClick={()=>{handleMoveToWaste(id)}}
      setWasteQty={setWasteQty}
      wasteqty={wasteqty}
      moveToWaste={true}
      maxQty={maxQty}
    />
    <ConfirmationPopup 
      open={openDeletePartialPop}
      handleClose={()=>{setOpenDeletePartialPop(false)}}
      message={`You want to delete ${barcode} of ${userName} `}
      handleClick={()=>{handleDeletePartial(id)}}
    />
    <ConfirmationPopup 
      open={openMovetoClientOwned}
      handleClose={()=>{setOpenMovetoClientOwned(false)}}
      message={`You want to transfer ${userName} to Client Owned`}
      handleClick={()=>{handleMoveToClientOwned(id)}}
    />
    <Modal
      modalTitle={'Move Finished Product to Partial'}
      open={partialModalOpen}
      handleClose={() => {
        setOpenPartialPop(false); 
        setPartialModalOpen(false);
        setMovingQty(1);
        setConvertionUnit(null);
        setConvertionQty(0);
        setConvertionUnitPrice(0);
        setConvertionTotalPrice(0);
        setAutoFill(true);
      }}
    >
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 3, sm: 2 }}>
              <TextField
                fullWidth
                disabled
                label={unitName + ' Qty to be moved'}
                type="number"
                onKeyPress={(event) => {
                  if (event.key === '0' && event.target.value === '0' ) {
                    event.preventDefault();
                  }
                  // if (event.key === '0' && event.target.value === '' ) {
                  //   event.preventDefault();
                  // }
                  if (event.key === '-' || event.key === '+' || event.key === 'e' ) {
                    event.preventDefault();
                  }
                }}
                value={movingQty}
                onChange={(e) => {
                  let inputQty = e.target.value || 1;
                  if (inputQty > qty) {
                    inputQty = qty;
                  }
                  setMovingQty(inputQty);
                  setErrors((prev) => ({ ...prev, movingQty: "" }));
                }}
                error={!!errors.movingQty}
                helperText={errors.movingQty}
              />
              <Autocomplete
                fullWidth
                id="combo-box-demo"
                options={UNITS}
                // options={
                //   unit == 4 
                //     ? UNITS 
                //     : UNITS?.filter(item => {
                //         const foundUnit = UNITS.find(u => u.id === unit);
                //         return foundUnit ? item.unit_id === foundUnit.unit_id : false;
                //       })
                // }
                value={convertionUnit}
                onChange={(event, newValue) =>{
                  setConvertionUnit(newValue)
                  setErrors((prev) => ({ ...prev, convertionUnit: "" }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params} required
                    error={!!errors.convertionUnit}
                    helperText={errors.convertionUnit}
                    label="Select a conversion unit"
                  />
                )}
                getOptionLabel={(option) => option.name ? option.name : ''}
              />
              <TextField
                fullWidth
                label={convertionUnit ? `${convertionUnit?.short_name} Qty` : "Conversion Qty"}
                type="number"
                onKeyPress={(event) => {
                  if (event.key === '0' && event.target.value === '0' ) {
                    event.preventDefault();
                  }
                  // if (event.key === '0' && event.target.value === '' ) {
                  //   event.preventDefault();
                  // }
                  if (event.key === '-' || event.key === '+' || event.key === 'e' ) {
                    event.preventDefault();
                  }
                }}
                value={convertionQty || ""}
                onChange={(e) => {
                  let inputValue = parseFloat(e.target.value) || 0;
                  let unitCost = inputValue > 0 ? unit_cost / inputValue : 0;
                  setConvertionQty(inputValue);
                  setConvertionUnitPrice(unitCost);
                  setConvertionTotalPrice(unitCost * inputValue);
                  setErrors((prev) => ({ ...prev, convertionQty: "" }));
                }}
                error={!!errors.convertionQty}
                helperText={errors.convertionQty}
              />

              <TextField
                fullWidth
                label={'Unit Cost'}
                type="number"
                disabled
                value={convertionUnitPrice ? parseFloat(convertionUnitPrice || 0)?.toFixed(2) : ""}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={autoFill}
                    onChange={(event) => setAutoFill(event.target.checked)}
                  />
                }
                label="Automate the process"
              />
              {/* <TextField
                fullWidth
                label={'Total Cost'}
                type="number"
                disabled
                value={convertionTotalPrice ? parseFloat(convertionTotalPrice || 0)?.toFixed(2) : ""}
              /> */}
            </Stack>

            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <Button type="submit" variant="contained" onClick={handleSubmitPartial}>
                Move to Partial
              </Button>
              <Button variant="outlined" 
                onClick={()=>{
                  setOpenPartialPop(false); 
                  setPartialModalOpen(false);
                  setMovingQty(1);
                  setConvertionUnit(null);
                  setConvertionQty(0);
                  setConvertionUnitPrice(0);
                  setConvertionTotalPrice(0);
                  setAutoFill(true);
                }}
                sx={{ marginLeft: '10px' }}>
                Cancel
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Modal>
    <Modal
      modalTitle={'Refill Finished Product'}
      open={refillOpen}
      handleClose={() => {
        setRefillOpen(false);
        setOpenRefillPop(false);
      }}
    >
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 3, sm: 2 }}>
              <TextField
                fullWidth
                label={unitName + ' Quantity to be added'}
                type="number"
                onKeyPress={(event) => {
                  if (event.key === '0' && event.target.value === '0' ) {
                    event.preventDefault();
                  }
                  // if (event.key === '0' && event.target.value === '' ) {
                  //   event.preventDefault();
                  // }
                  if (event.key === '-' || event.key === '+' || event.key === 'e' ) {
                    event.preventDefault();
                  }
                }}
                value={refillQty}
                onChange={(e) => {
                  let inputQty = e.target.value || 1;
                  setRefillQty(inputQty);
                  setErrors((prev) => ({ ...prev, refillQty: "" }));
                }}
                error={!!errors.refillQty}
                helperText={errors.refillQty}
              />
            </Stack>

            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <Button type="submit" variant="contained" onClick={handleSubmitRefill}>
                Refill
              </Button>
              <Button variant="outlined" 
                onClick={()=>{
                  setRefillOpen(false);
                  setOpenRefillPop(false);
                }}
                sx={{ marginLeft: '10px' }}>
                Cancel
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Modal>
    </>
  );
}
