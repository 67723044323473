import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { capitalCase } from 'change-case';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import roundAccountBox from '@iconify/icons-ic/round-account-box';
import roundPowerSettingsNew from '@iconify/icons-ic/round-power-settings-new';
// material
import {
  Box,
  List,
  Select,
  Divider,
  Popover,
  Tooltip,
  ListItem,
  Typography,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@material-ui/core';
// hooks
import MyAvatar from 'src/components/MyAvatar';
import BadgeStatusSignwiseChat from 'src/components/BadgeStatusSignwiseChat';
import useAuth from 'src/hooks/useAuth';
import axios from 'axios';
import { REST_API_END_POINT, STATUS_OPTIONS } from 'src/constants/Defaultvalues';

// ----------------------------------------------------------------------

const STATUS = ['online', 'invisible', 'away', 'busy', 'dnd']; // Added busy and DND statuses

export default function ChatAccountSignWise({status, setStatus,refresh, setRefresh}) {
  const user = JSON.parse(localStorage.getItem('user'));
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeStatus = async (event) => {
    try{
      const newStatus = event.target.value;
      console.log('newStatus---',newStatus)
      const filteredStatus = STATUS_OPTIONS?.find(item=>item.id == newStatus)
      console.log('newStatus---filteredStatus-2--',filteredStatus)
      setStatus(filteredStatus);
      const response = await axios.post(`${REST_API_END_POINT}chat/update-chat-status`, 
        {userId:user.user_id, newStatus });
      if (response.data.status === 1) {
        setRefresh(!refresh);
        console.log('Status updated successfully');
      }else{
        console.log('Status not updated');
      }
    }catch(error){
      console.log('Error updating status : ', error);
    }
  }

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <MyAvatar
          onClick={handleOpen}
          sx={{ cursor: 'pointer', width: 48, height: 48, borderRadius: '50%', objectFit: 'cover' }}
        />
        <BadgeStatusSignwiseChat status={status?.statusData} sx={{ position: 'absolute', bottom: 2, right: 2 }} />
      </Box>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Box
          sx={{
            py: 2,
            pr: 1,
            pl: 2.5,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {/* <MyAvatar /> */}
          <Box sx={{ position: 'relative' }}>
            <MyAvatar
              sx={{ cursor: 'pointer', width: 48, height: 48, borderRadius: '50%', objectFit: 'cover' }}
            />
            <BadgeStatusSignwiseChat status={status?.statusData} sx={{ position: 'absolute', bottom: 2, right: 2 }} />
          </Box>

          <Box sx={{ ml: 2, mr: 3 }}>
            <Typography noWrap variant="subtitle1">
              {user.person_name}
            </Typography>
            <Typography noWrap variant="body2" sx={{ color: 'text.secondary' }}>
              {user.email}
            </Typography>
          </Box>

          {/* <Tooltip title="Log out">
            <IconButton>
              <Icon icon={roundPowerSettingsNew} width={24} height={24} />
            </IconButton>
          </Tooltip> */}
        </Box>

        <Divider />

        <List>
          <ListItem disableGutters sx={{ py: 1, px: 2.5 }}>
            <ListItemIcon>
              <Box
                sx={{
                  width: 24,
                  height: 24,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <BadgeStatusSignwiseChat status={status?.statusData} />
              </Box>
            </ListItemIcon>
            <ListItemText>
              <Select
                fullWidth
                size="small"
                value={status?.statusData}
                onChange={handleChangeStatus}
                displayEmpty
                sx={{
                  width: '100%',
                  padding: 0,
                  fontSize: '14px',
                  '& .MuiSelect-icon': {
                    display: 'none', // Hide the default dropdown icon
                  },
                  '& .MuiSelect-select': {
                    paddingLeft: 0, // Align text with icon
                  },
                  '& fieldset': { borderWidth: '0 !important' },
                }}
                renderValue={(selected) => {
                  const selectedStatus = STATUS_OPTIONS.find((option) => option.statusData === selected);
                  return selectedStatus ? selectedStatus.label : selected;
                }}
              >
                {STATUS_OPTIONS.map(({ id, label, statusData }) => (
                  <MenuItem key={id} value={id}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <BadgeStatusSignwiseChat status={statusData} sx={{ mr: 2 }} />
                      {label}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </ListItemText>
          </ListItem>

          <ListItem button disableGutters sx={{ py: 1, px: 2.5 }}>
            <ListItemIcon>
              <Icon icon={roundAccountBox} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Profile"
              primaryTypographyProps={{
                variant: 'body2',
              }}
            />
          </ListItem>

          {/* <ListItem button disableGutters sx={{ py: 1, px: 2.5 }}>
            <ListItemIcon>
              <Icon icon={settings2Fill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Settings"
              primaryTypographyProps={{
                variant: 'body2'
              }}
            />
          </ListItem> */}
        </List>
      </Popover>
    </>
  );
}
