
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { sentenceCase } from 'change-case';// Change this import path to your actual sentenceCase function
import { useNavigate, useParams } from 'react-router-dom';

// import { Form, FormikProvider, useFormik } from 'formik';
import {
     Container,Stack,
    } from '@material-ui/core';
    // import Label from 'src/components/Label';
    // import { Icon } from '@iconify/react';
    // import plusFill from '@iconify/icons-eva/plus-fill';
    // redux
    // import { useDispatch, useSelector } from '../../redux/store';
    // import { getPost, getRecentPosts } from '../../redux/slices/blog';
    // import { LoadingButton } from '@material-ui/lab';
    // routes
    // import { PATH_DASHBOARD } from 'src/routes/paths.js';
    // components
    import Page from 'src/components/Page.js';
    // import Markdown from '../../components/Markdown';
    // import Scrollbar from 'src/components/Scrollbar.js';
    import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs.js';
    // import UserList from './UserList';
    // import Modal from '../../components/_dashboard/blog/ProjectModel';
// import { UserListHead, UserMoreMenu } from 'src/components/_dashboard/user/list';
// import  RequestView from 'src/components/_dashboard/user/list/RequestView.js';
import  RequestList from 'src/pages/dashboard/RequestList.js';
import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
// import { tr } from 'faker/lib/locales';

export default function Requests({
  receivedId,
  requests,
  isTeam,
  clientId,
  clientDetails,
  isClient,
  refreshData,
}){

  const user = localStorage.getItem('user')
  const userData = JSON.parse(user)
    const navigate = useNavigate()
    const [clientRequests,setClientRequest] = useState(null)
     const fetchAllClientsRequest=async()=>{
      if(isClient){
        const data={userType:clientDetails?.user_type,userId:clientDetails?.user_id}
        console.log('===================',data)
        const res = await axios.post(`${REST_API_END_POINT}request/get-all-request-new`,data)
        console.log(res.data,'------------res in react for client acc')
        if(res.data){
          setClientRequest(res.data.data.data)
        }
      }
     }
     useEffect(()=>{
      fetchAllClientsRequest()
     },[clientId,refreshData])

    return(
    <Page title="Requests | Sign-Wise Solution">
      <Container>

        <RequestList clientId={clientId}  isClient={isClient} receivedId={receivedId} requests={ isClient ?clientRequests: requests} isTeam={isTeam}/>
        
      </Container>
    </Page>
  );
}