import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, Button, Stack, Menu, List, MenuItem, ListItemText, Checkbox } from '@material-ui/core';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
import { useSnackbar } from 'notistack';
import ConfirmationPopup from '../../official-request/ConfirmationPopup';
import { useRef, useState } from 'react';
import { sentenceCase } from 'change-case'
import { BlockOutlined, Delete, ExpandLess, ExpandMore, TaskAltOutlined } from '@material-ui/icons';

// ----------------------------------------------------------------------

const sourceData = [
  { id: 0, name: 'Inventory' },
  { id: 1, name: 'Vendor' },
  { id: 3, name: 'Clear filter' },
]
const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  // padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

MaterialInventoryToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

export default function MaterialInventoryToolbar({ inventoryAudit, numSelected, filterName, onFilterName, filterSource, onFilterSource, selected, onDelete, selectedName, item, rowCount, userId, productCategories,filterCategory,setFilterCategory,filterSubcategory, setFilterSubcategory,showActiveInactive,materialTab}) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openDeletePop, setOpenDeletePop] = useState(false);
  const [openactivatePop, setOpenActivatePop] = useState(false);
  const [communitiesOpen, setCommunitiesOpen] = useState(false);
  const [deleteConfirmationMessage, setDeleteConfirmationMessage] = useState()
  const refCommunities = useRef(null);
  const refSubcategories = useRef(null);
  let materialStatus = materialTab ==="Inactive Materials" ? 1 : 0
 
  const [subcategoriesOpen, setSubcategoriesOpen] = useState(false);

  const changeStatus = async (deleteId, deleteStatus,activate) => {
    try {
      let id = deleteId;
      let status = deleteStatus;
      let response;
      // console.log("id-----", id, " status-----------", status, "item---------",item)
      if (item === "Materials" && activate) {
        response = await axios.post((`${REST_API_END_POINT}product/delete-product?id=${id}&status=${status}`))
      }
     else if (item === "contacts") {
        response = await axios.post(`${REST_API_END_POINT}contacts/delete-contact`, { contactId: id, status })
      }
      else if (item === "products") {
        response = await axios.post((`${REST_API_END_POINT}product/delete-product?id=${id}&status=${status}`))
      }
      else if (item === "opportunities") {
        response = await axios.post(`${REST_API_END_POINT}opportunities/delete-opportunity`, { deleteId: id, status, userId })
      }
      else if (item === "Materials") {
        response = await axios.post((`${REST_API_END_POINT}product/delete-product?id=${id}&status=${status}`))
      }
      // console.log("response-----------------", response.data);
      return response?.data;
    }
    catch (error) {
      console.log("Delete error : ", error);
    }
  }

  const handleMenuItemClick = async (selectedIds, newStatus,activate) => {
    try {
      setOpenDeletePop(false);
      setOpenActivatePop(false)
      const result = await Promise.all(selectedIds.map((id) => changeStatus(id, newStatus,activate)));
      // console.log("deleted data length",result.length);
      if (selectedIds.length === result.length) {
        onDelete();
        if(activate){

          enqueueSnackbar(`${result.length} ${item} ${materialTab=='Inactive Materials'? "Activated" :"Deactivated"}`,
             { variant: 'success' });
        }else{
          enqueueSnackbar(`${result.length} ${item} deleted`, { variant: 'success' });

        }
        if (item === "contacts") {
          navigate(PATH_DASHBOARD.crm.contact, { state: { data: result } });
        }
        else if (item === "products") {
          navigate(PATH_DASHBOARD.products.material, { state: { data: result } });
        }
        else if (item === "opportunities") {
          navigate(PATH_DASHBOARD.crm.opportunity, { state: { data: result } });
        }
        else if (item === "Materials") {
          navigate(PATH_DASHBOARD.products.material, { state: { data: result } });
        }
      }
      else {
        console.log("Error.........!");
        enqueueSnackbar(`Unable to delete the ${item}.!`, { variant: 'error' });
      }
    }
    catch (error) {
      console.log("Server error", error);
    }
  }
  const handleSubcategoryChange = (subcategoryId) => {
    const updatedCategories = [...productCategories];
    const category = updatedCategories.find((cat) => cat.id === filterCategory[0]);
    if (category) {
      const subcategory = category.subCategoryInfo?.find((sub) => sub.id === subcategoryId);
      if (subcategory) {
        subcategory.checked = !subcategory.checked;
        setFilterSubcategory((prevIds) =>
          subcategory.checked ? [...prevIds, subcategory.id] : prevIds.filter((id) => id !== subcategory.id)
        );
      }
    }
  };
  return (
    <RootStyle
    sx={{
      ...(numSelected > 0 && {
        color: isLight ? 'primary.main' : 'text.primary',
        bgcolor: '#05191f',
      }),
      justifyContent: (item === "finished products" || item === "partial finished products") ? 'flex-end' : 'space-between',
    }}
  >
    {/* <Stack direction="row" justifyContent="space-between" spacing={2} flexWrap="wrap"> */}
      {(item !== "finished products" && item !== "partial finished products") && (
        <>
          <SearchStyle
            sx={{ backgroundColor: '#05191f' }}
            value={filterName}
            onChange={onFilterName}
            placeholder={`Search ${sentenceCase(item)}...`}
            startAdornment={
              <InputAdornment position="start">
                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
          
        </>
      )}
      {numSelected > 0 && (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      )}
      {(item === "finished products" || item === "partial finished products") && (
        <>
          <Button
            ref={refCommunities}
            onClick={() => setCommunitiesOpen((prevState) => !prevState)}
            endIcon={
              <Stack>
                {communitiesOpen ? <ExpandLess sx={{ ml: 'auto' }} /> : <ExpandMore sx={{ ml: 'auto' }} />}
              </Stack>
            }
          >
            {filterSource !== '' && filterSource !== 'Clear filter' ? filterSource : 'Filter Source'}
          </Button>
          <Menu
            open={Boolean(communitiesOpen)}
            anchorEl={refCommunities.current}
            onClose={() => setCommunitiesOpen(false)}
            PaperProps={{ sx: { width: 'auto', maxWidth: '100%' } }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <List style={{ maxHeight: '250px', overflowY: 'auto' }}>
              {sourceData?.map((type) => (
                <MenuItem key={type.id} onClick={(e) => { setCommunitiesOpen(false); onFilterSource(e.target.textContent); }}>
                  <ListItemText primary={type.name} />
                </MenuItem>
              ))}
            </List>
          </Menu>
        </>
      )}
      {numSelected > 0 && showActiveInactive &&(
        <>
        <Tooltip
          title={materialTab=="Inactive Materials" ? 'Activate' : 'Deactivate'}
          onClick={() => {
            setOpenActivatePop(true);
            setDeleteConfirmationMessage(numSelected === rowCount ? " all " : ` ${numSelected} `);
          }}
        >
          {/* <IconButton>
          {materialTab=="Inactive Materials" ? <TaskAltOutlined sx={{ width: 25, height: 25 }} />: <BlockOutlined sx={{ width: 25, height: 25 }} />}
          </IconButton> */}
          <Button
  variant="contained"
  color={materialTab === "Inactive Materials" ? "success" : "error"}
  onClick={() => {
    setOpenActivatePop(true);
            setDeleteConfirmationMessage(numSelected === rowCount ? " all " : ` ${numSelected} `);
  }}
  sx={{ display: 'flex', alignItems: 'center', gap: 1, textTransform: 'none', padding: '6px 12px' }} // Ensures proper spacing
>
  {materialTab === "Inactive Materials" ? 
    <TaskAltOutlined sx={{ width: 20, height: 20 }} /> : 
    <BlockOutlined sx={{ width: 20, height: 20 }} />
  }
  <Typography variant="body2">
    {materialTab === "Inactive Materials" ? "Activate" : "Deactivate"}
  </Typography>
</Button>

        </Tooltip>
</>
      )}
       {numSelected > 0  &&(
<>
        <Tooltip
          title="Delete"
          onClick={() => {
            setOpenDeletePop(true);
            setDeleteConfirmationMessage(numSelected === rowCount ? " all " : ` ${numSelected} `);
          }}
        >
          <IconButton>
            <Delete />
          </IconButton>
        </Tooltip>
        </>
      )}
      {inventoryAudit && (
        <Box sx={{ width:"100%", display:"flex", alignItems:'center', justifyContent:"flex-end", my:2, mr:5 }}>
          {/* Color indicators for stock types */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 2, mr:2 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Box sx={{ width: 20, height: 20, backgroundColor: "#ffc0cb", borderRadius: "20%", border: "1px solid black" }} />
              <Typography variant="body2">From Partial</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Box sx={{ width: 20, height: 20, backgroundColor: "#ffffff", borderRadius: "20%", border: "1px solid black" }} />
              <Typography variant="body2">From Finished</Typography>
            </Box>
          </Box>
        </Box>
      )}
      {/* {console.log("##############Filtered category",filterCategory)} */}
      {/* {item === "Materials" && (
        <>
          <Button
            ref={refCommunities}
            onClick={() => setCommunitiesOpen((prevState) => !prevState)}
            endIcon={
              <Stack>
                {communitiesOpen ? <ExpandLess sx={{ ml: 'auto' }} /> : <ExpandMore sx={{ ml: 'auto' }} />}
              </Stack>
            }
          >
            Filter Category
          </Button>
          <Menu
            open={Boolean(communitiesOpen)}
            anchorEl={refCommunities.current}
            onClose={() => setCommunitiesOpen(false)}
            PaperProps={{ sx: { width: 'auto', maxWidth: '100%' } }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <List style={{ maxHeight: '250px', overflowY: 'auto' }}>
              {productCategories?.map((type) => (
                <MenuItem key={type.id}>
                  <ListItemText primary={type.name} />
                  <Checkbox
                    checked={type.checked || false}
                    onChange={() => {
                      const updatedType = [...productCategories];
                      const item = updatedType.find((item) => item.id === type.id);
                      if (item) {
                        item.checked = !item.checked;
                        setFilterCategory((prevIds) =>
                          item.checked ? [...prevIds, item.id] : prevIds.filter((id) => id !== item.id)
                        );
                       
                      }
                    }}
                  />
                </MenuItem>
              ))}
            </List>
          </Menu> {filterCategory.length===1 && (
       <Button
          ref={refSubcategories}
          onClick={() => setSubcategoriesOpen((prevState) => !prevState)}
          endIcon={
            <Stack>
              {subcategoriesOpen ? <ExpandLess sx={{ ml: 'auto' }} /> : <ExpandMore sx={{ ml: 'auto' }} />}
            </Stack>
          }
        >
          Filter Subcategory
        </Button>
      )}
      <Menu
        open={Boolean(subcategoriesOpen)}
        anchorEl={refSubcategories.current}
        onClose={() => setSubcategoriesOpen(false)}
        PaperProps={{ sx: { width: 'auto', maxWidth: '100%' } }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <List style={{ maxHeight: '250px', overflowY: 'auto' }}>
          {productCategories.find((cat) => cat.id === filterCategory[0])?.subCategoryInfo.map((sub) => (
            <MenuItem key={sub.id}>
              <ListItemText primary={sub.name} />
              <Checkbox
                checked={sub.checked || false}
                onChange={() => handleSubcategoryChange(sub.id)}
                
              />
            </MenuItem>
          ))}
        </List>
      </Menu>
        </>
      )} */}
    {/* </Stack> */}
    
    <ConfirmationPopup
      open={openDeletePop}
      handleClose={() => setOpenDeletePop(false)}
      message={`You want to delete ${deleteConfirmationMessage} ${item}!`}
      handleClick={() => handleMenuItemClick(selected, 2)}
    />
    <ConfirmationPopup
      open={openactivatePop}
      handleClose={() => setOpenActivatePop(false)}
      message={`You want to ${materialTab==="Inactive Materials" ? "Activate" : "Deativate"} ${deleteConfirmationMessage} ${item}!`}
      handleClick={() => handleMenuItemClick(selected, materialStatus,1)}
    />
  </RootStyle>
  );
}
