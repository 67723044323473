import { useEffect, useState } from 'react';
// material
import { Card, Container } from '@material-ui/core';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { useDispatch } from 'react-redux';
import ChatSidebarSignWise from './ChatRightScreen/ChatSidebarSignWise';
import { getContacts, getConversations } from 'src/redux/slices/chat';
import Page from 'src/components/Page';
import ChatScreenWindow from './ChatLeftScreen/ChatScreenWindow';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
import axios from 'axios';
import { useLocation } from 'react-router';

// ----------------------------------------------------------------------

export default function ChatMain() {
  const [open, setOpen] = useState(false)
  const [openCompose, setCompose] = useState(true)
  const dispatch = useDispatch()
  const user = localStorage.getItem('user')
  const userData = JSON.parse(user);
  const isAdmin = true || userData.user_type===1
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null); 
  const [renderChat, setRenderChat] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation()
  const isHistory = location.state?.showHistory
  const [status, setStatus] = useState(null);
  const [refreshStatus, setRefreshStatus] = useState(false);
  const [showHistory, setShowHistory] = useState(isHistory ?? true);
  const [chatOpenTime,setChatOpenTime] = useState(null)
  const [historyMessage,setHistoryMessage]  = useState([])
  const [selectedChatLogId,setSelectedChatLogId] = useState(null)
  const [newChat,setNewChat] =  useState(false)
  
  const fetchStatus = async () => {
    try{
      const response = await axios.post(`${REST_API_END_POINT}chat/get-chat-status-by-userid`, 
        {userId:userData.user_id });
      if (response.data.status === 1) {
        const fetchedStatus = response.data.result.chat_status
        const filteredStatus = STATUS_OPTIONS?.find(item=>item.id == fetchedStatus)
        console.log('newStatus---filteredStatus-1--',filteredStatus)
        setStatus(filteredStatus);
        console.log('Status set successfully');
      }else{
        console.log('Status not found');
      }
    }catch(error){
      console.log('Error finding status : ', error);
    }
  }

  useEffect(()=>{
    fetchStatus();
  },[refreshStatus, open])

  const fetchData = async (updateSelctedContact = false) => {
    if (isAdmin && userData) {
      try {
        const response = await axios.post(`${REST_API_END_POINT}chat/get-chat-list`, {
          usersId: userData.user_id,
          usersType: userData.user_type,
          // page:page+1,rowsPerPage,search:filterName,
        });
        if (response.data.data) {
          const newData = response.data.data
          setTeamMembers(newData);
          if(updateSelctedContact){
            const filteredData = newData.find((member) => member?.new_conversation_id === selectedContact?.new_conversation_id )
            console.log('selectedContact---',selectedContact)
            console.log('filteredData---',filteredData)
            setSelectedContact(filteredData);
          }
        }else{
          setTeamMembers([]);
        }
      } catch (error) {
        console.log("Error fetching data: ", error);
      }
      finally{
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, [open]);

  useEffect(() => {
    fetchData(true);
  }, [renderChat]);

const handleRefresh=()=>{
  fetchData(true);
}

const refreshChat=()=>{
  setRenderChat(!renderChat)
}

  useEffect(() => {
    dispatch(getConversations())
    dispatch(getContacts())
  }, [dispatch])

  const togglePopup = () => {
    setOpen(!open)
    setCompose(true)
    setShowHistory(!showHistory)
    setSelectedContact(null)
    // console.log('showHistoryshowHistory',!showHistory,selectedContact)
  }
  const openChat=()=>{
    setCompose(false)
  }


  return (
    <Page title="Messenger | Signwise Solutions">
      <Container maxWidth="xl">
        <HeaderBreadcrumbs
          heading="Messenger"
        />
        <Card sx={{ height: '72vh', display: 'flex' }}>
          <ChatSidebarSignWise
            openCompose={openCompose}
            setCompose={setCompose}
            setOpen={setOpen}
            teamMembers={teamMembers}
            setSelectedContact={setSelectedContact}
            selectedContact={selectedContact}
            refreshChat={refreshChat}
            setRenderChat={setRenderChat}
            renderChat={renderChat}
            status={status} 
            setStatus={setStatus} 
            refresh={refreshStatus} 
            setRefresh={setRefreshStatus}
            setShowHistory={setShowHistory}
            showHistory={showHistory}
            chatOpenTime={chatOpenTime}
            setChatOpenTime={setChatOpenTime}
            setHistoryMessage={setHistoryMessage}
            historyMessage={historyMessage}
            selectedChatLogId={selectedChatLogId}
            setSelectedChatLogId={setSelectedChatLogId}
            setNewChat={setNewChat}
            newChat={newChat}
            handleRefresh={handleRefresh}
          />
          <ChatScreenWindow
            openCompose={openCompose}
            setCompose={setCompose}
            teamMembers={teamMembers}
            contact={selectedContact}
            setSelectedContact={setSelectedContact}
            refreshChat={refreshChat}
            setRenderChat={setRenderChat}
            renderChat={renderChat}
            handleRefresh={handleRefresh}
            setShowHistory={setShowHistory}
            showHistory={showHistory}
            chatOpenTime={chatOpenTime}
            setChatOpenTime={setChatOpenTime}
            setHistoryMessage={setHistoryMessage}
            historyMessage={historyMessage}
            selectedChatLogId={selectedChatLogId}
            setSelectedChatLogId={setSelectedChatLogId}
            setNewChat={setNewChat}
            newChat={newChat}
          />
        </Card>
      </Container>  
    </Page>
  );
}
