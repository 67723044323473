import { merge } from 'lodash';
import { Icon } from '@iconify/react';
import ReactApexChart from 'react-apexcharts';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
import trendingDownFill from '@iconify/icons-eva/trending-down-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/routes/paths.js';
// import { Icon } from '@iconify/react';
// import successIcon from '@iconify/icons-mdi/success';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Card, Typography, Stack, Button, Divider } from '@material-ui/core';
// utils
import { fNumber, fPercent } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';
import { REST_API, REST_API_END_POINT } from 'src/constants/Defaultvalues';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Scrollbar from 'src/components/Scrollbar';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(1),
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.16)
}));

// ----------------------------------------------------------------------

const PERCENT = 2.6;
const TOTAL_SOLD = 765;
let dataAA= [
    {
        "workflow_name": "Checking workflow001",
        "id": 1657,
        "name": "Stage4",
        "stage_type": 4,
        "type_of_stage": 1,
        "internal_stage": 0,
        "position_order": 4,
        "exit_message": "",
        "partner_type": 0,
        "group_ids": null,
        "relationship_managers": null,
        "stage_status": 1,
        "stage_submit": 1
    },
    {
        "workflow_name": "Checking workflow001",
        "id": 1656,
        "name": "Stage3",
        "stage_type": 6,
        "type_of_stage": 1,
        "internal_stage": 0,
        "position_order": 3,
        "exit_message": "",
        "partner_type": 0,
        "group_ids": null,
        "relationship_managers": null,
        "stage_status": 1,
        "stage_submit": 1
    },
    {
        "workflow_name": "Checking workflow001",
        "id": 1655,
        "name": "Stage 2",
        "stage_type": 5,
        "type_of_stage": 1,
        "internal_stage": 0,
        "position_order": 2,
        "exit_message": "",
        "partner_type": 0,
        "group_ids": null,
        "relationship_managers": null,
        "stage_status": 1,
        "stage_submit": 1
    },
    {
        "workflow_name": "Checking workflow001",
        "id": 1654,
        "name": "Stage1",
        "stage_type": 1,
        "type_of_stage": 1,
        "internal_stage": 0,
        "position_order": 1,
        "exit_message": "Testing",
        "partner_type": 0,
        "group_ids": null,
        "relationship_managers": null,
        "stage_status": 1,
        "stage_submit": 1
    }
]

export default function OnboardingStagesTwo({ 
  setReqCount, setEstimateCount, 
  setCompletedInstallationCount, setReceiveOrderCount, setRFPCount,
 onboardCompleted, setOnboardComplete,setManual,manual
 }) {
  let user = JSON.parse(localStorage.getItem('user'))
  const navigate = useNavigate()
  
  const [stages, setStages] = useState(null)

  const [designCount,setDesignCount]= useState(0)
  const [designCountTotal,setDesignCountTotal]= useState(0)
  const fetchDashboardData = async () => {
    try {
      let res = await axios.post(`${REST_API_END_POINT}user/dashboard`, {
        userId: user.user_id, userType: user.user_type
      })

      setStages(res.data.stages?.slice().reverse())
    //   setStages(dataAA?.slice().reverse())
    //   setReqCount(res.data?.requestCounts?.count)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchDashboardEstimateData = async () => {
    try {
      let user = JSON.parse(localStorage.getItem('user'))
      let userType = user.user_type
      let userId = user.user_id

      const values = {
        userType: userType, 
        userId: userId, 
        page: 1, 
        rowsPerPage : 6, 
        search: null,
        where:" r.status = 8 AND r.quotation_status = 2 ",
        sort: 'new',
        from: null,
        to: null
      }
      await axios.post(`${REST_API}webservice/request/get-all-quotations-count`, values)
        .then((res) => {
          if (res?.data?.data?.totalCount) {
            setEstimateCount(res?.data?.data?.totalCount)
          } else {
            setEstimateCount(0)
          }
        })
        .catch((errors) => {
          console.log(errors, 'error in fetching all requests')
          setEstimateCount(0)
        })
    } catch (error) {
      console.log(error)
      setEstimateCount(0)
    }
  }

  const fetchDesignOrders = async () => {
    try {
      let userType = user.user_type
      let userId = user.user_id

      const values = {
        userType: userType,
        userId: userId,
        page: null,
        rowsPerPage:null,
        search: null,
        from: null,
        to: null,
      }
      await axios
        .post(`${REST_API}webservice/design/all-design-order-count`, values)
        .then((res) => {
          
          if (res?.data?.data?.openCount) {
            let designCount = res?.data?.data?.openCount
            let designCountTotal = res?.data?.data?.totalCount
            console.log('designCount+++++++++++1', designCount)
            console.log('designCount+++++++++++2', designCountTotal)
            setDesignCount(designCount)
            setDesignCountTotal(designCountTotal)
        } else {
          setDesignCount(0)
          setDesignCountTotal(0)
        }
        })
        .catch((errors) => {
          console.log(errors, 'error in fetching all requests')
          setDesignCount(0)
          setDesignCountTotal(0)
        })
    } catch (error) {
      console.log(error)
      setDesignCount(0)
    }
  }


  useEffect( async () => {
    try {
      const response = await axios.post(`${REST_API_END_POINT}product/get-all-receive-orders`,
        {userId: user.user_id, userType: user.user_type });
      if(response.data.status === 1){
        const orderList = response?.data?.orderData;
        setReceiveOrderCount(orderList?.length)
      }
      else{
        setReceiveOrderCount(0);
      }
    }
      catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);
  const fetchRFPData = async () => {
    try {
      const values = {
        userType: user.user_type, userId: user.user_id, 
        page: 1, rowsPerPage:6, search: null,
        sort:'new',
        from:null,
        to:null
      }
      await axios.post(`${REST_API}webservice/request/get-all-proposals`, values)
        .then((res) => {
          if (res?.data?.data?.totalCount) {
            setRFPCount(res?.data?.data?.totalCount)
          } else {
            setRFPCount(0)
          }
        })
        .catch((errors) => {
          console.log(errors, 'error in fetching all requests')
        })
    } catch (error) {
      console.log(error)
    }
  }
  const fetchCompletedInstallationsDataForDashboard = async () => {
    try {
      // let whereCondition = ` i.status IS NOT NULL AND ( inst_counts.installation_count >= cop.product_count OR inst_counts.installation_count >= rss.service_count ) `
      let whereCondition = ` i.status IS NOT NULL AND r.installation_acceptance IS NULL AND ( inst_counts.installation_count >= cop.product_count OR inst_counts.installation_count >= rss.service_count ) `
      const values = {
        userType: user.user_type, userId: user.user_id, page: 1, 
        rowsPerPage:6, search: null, 
        where: whereCondition,
        sort:'new',
        from:null,
        to:null,
        filterCommunity:[]
      }
      await axios.post(`${REST_API}webservice/request/get-all-request-new`, values)
        .then((res) => {
          if (res?.data?.data?.totalCount) {
            setCompletedInstallationCount(res?.data?.data?.totalCount)
          } else {
            setCompletedInstallationCount(0)
          }
        })
        .catch((errors) => {
          console.log(errors, 'error in fetching all requests')
        })
    } catch (error) {
      console.log(error)
    }
  }
  const fetchOnboardprocess = async () => {
    try {
      let res=null
      if(user.user_type==3){
        res = await axios.post(`${REST_API_END_POINT}onboard/process`, {
          userId: user.user_id
        })
      }
      else{
       res = await axios.post(`${REST_API_END_POINT}onboard/team-process`, {
          userId: user.user_id
        })
      }
      

      if (res.data.onboardCompleted == 1) {
        setOnboardComplete(true)
        if (res.data.manual == 1) {
          setManual(true)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchDashboardData()
    // fetchDashboardEstimateData()
    // fetchOnboardprocess()
    // fetchCompletedInstallationsDataForDashboard()
    // fetchRFPData()
    // fetchDesignOrders()
  }, [])

  return (
    <>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6" paragraph>
            Onboarding stages
          </Typography>
         <Scrollbar sx={{maxHeight:70,overflowY:'auto',overflowX:'hidden'}}>
              {stages && stages?.map((data) => {
                const { id, internal_stage, name, stage_status } = data;
      
                if ((user.user_type === 3 && internal_stage === 0) || (user.user_type !== 3 && internal_stage === 1)) {
                  return (
                    <>
                      <Stack key={id} direction="row" sx={{ display: 'flex', justifyContent: 'space-between' }} flexWrap="wrap">
                        <Typography variant="subtitle2" paragraph>
                          {name}
                        </Typography>
                        {stage_status === 1 && (
                          <IconWrapperStyle>
                            <Icon width={16} height={16} icon={checkmarkFill} />
                          </IconWrapperStyle>
                        )}
                      </Stack>
                    </>
                  );
                }
      
                return null;
              })}
         </Scrollbar>
  
  
  
          {/* {!onboardCompleted && (
            <Stack direction="row" sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }} flexWrap="wrap">
              <Button variant="contained" type="submit" onClick={() => navigate(PATH_DASHBOARD.onboard.process, { state: manual, setManual })}
              // component={RouterLink} to={PATH_DASHBOARD.onboard.process}
              >
                Start Onboarding
              </Button>
  
            </Stack>
          )} */}
        </Box>
    </>
  );
}
