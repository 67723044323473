import { sentenceCase } from 'change-case'
// material
import { experimentalStyled as styled } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import {
  Box,
  Card,
  Grid,
  Typography,
  Stack,
  CircularProgress,
  Button,
  Checkbox,
  makeStyles,
  Slider as Sliderw,
  TextField,
  InputAdornment,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  useMediaQuery,
} from '@material-ui/core'
import {
  Groups,
  Attachment,
  ChatBubble,
  DeleteOutlineOutlined,
  Logout,
  Login,
  CheckBoxRounded,
  AccessTime,
  LocationOn,
  Search,
  Visibility,
  QrCodeScanner,
} from '@material-ui/icons'
//

import Label from 'src/components/Label'
import { useNavigate } from 'react-router'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { useState, useEffect, useRef } from 'react'
import ConfirmationPopup from 'src/components/confirmationPopup'
import ProjectViewList from 'src/components/_dashboard/blog/ProjectViewList.js'
import ProjectViewGridList from 'src/components/_dashboard/blog/ProjectViewGridList'
import { BASE_URL, REST_API_END_POINT } from 'src/constants/Defaultvalues'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import FinishedProductmenu from 'src/components/_dashboard/blog/FinishedProductmenu'
import moment from 'moment'
import { UserMoreMenu } from 'src/components/_dashboard/user/list'
import Modal from 'src/components/_dashboard/blog/ProjectModel'
import QrReader from 'react-qr-reader';
import { Html5QrcodeScanner } from 'html5-qrcode';

const option = [
  { id: 6, color: 'error', value: 'Inactive' },
  { id: 1, color: 'primary', value: 'New' },
  { id: 2, color: 'info', value: 'In Progress' },
  { id: 3, color: 'success', value: 'Completed' },
  // { id: 4, color: 'warning', value: 'Hold' },
  { id: 5, color: 'success', value: 'Active' },
  // { id: 6, color:'success', value: 'Approved by Dev team' },
  // { id: 7, color:'success', value: 'Approved by the client' },
  // { id: 8, color:'primary', value: 'Reopen' },
]

const CardMediaStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  cursor: 'pointer',
  paddingTop: 'calc(100% * 9 / 16)',
  '&:before': {
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
    //  backdropFilter: 'blur(3px)',
    WebkitBackdropFilter: 'blur(3px)', // Fix on Mobile
    borderTopLeftRadius: theme.shape.borderRadiusMd,
    borderTopRightRadius: theme.shape.borderRadiusMd,
    // backgroundColor: alpha(theme.palette.primary.darker, 0.72)
  },
}))

const CoverImgStyle = styled('img')({
  top: 0,
  zIndex: 8,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  // background: `url(${src}) center/cover no-repeat`
})

function ProjectStatusPopup({ open, onClose, staffTimeLogData, handleClockInOut, autoClockoutTime }) {
  const [timerId, setTimerId] = useState(null);

  useEffect(() => {
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [timerId]);

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        handleClockInOut();
      }, autoClockoutTime * 60 * 1000);

      setTimerId(timer);
    } else {
      if (timerId) {
        clearTimeout(timerId);
      }
    }
  }, [open]);
 
  return (
      <Dialog open={open} onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose(false);
        }
      }}>
          <DialogTitle>Are you still working on this project?</DialogTitle>
          <DialogContent>
              <DialogContentText sx={{mt:3}}>
                  Do you want to continue working on "{staffTimeLogData?.task_name}" in "{staffTimeLogData?.project_name}"?
              </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button variant='contained' onClick={() => { clearTimeout(timerId); onClose(true); }}>
                  Yes
              </Button>
              <Button variant='outlined' onClick={() => { clearTimeout(timerId); onClose(false); }}>
                  No
              </Button>
          </DialogActions>
      </Dialog>
  );
}

export default function Tasks({
  designs,
  details,
  setFetchData,
  valueTab,
  data,
  projectId,
  currentTab,
  popupOn,
  setProjectsId,
  setPostId,
  handleinnerTask,
  setTaskdetails,
  setTaskCurrentTab,
  onDataChange
}) {
  console.log('detailsdata', data)
  const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [isHover, setIsHover] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [deleteStates, setDeleteStates] = useState({})
  const [taskId, setTaskId] = useState(null)
  const [materialData, setMaterialData] = useState(null)
  const [staffTimeLog, setStaffTimeLog] = useState(null)
  const [openPopup, setOpenPopup] = useState(false)
  const [searchMaterial, setSearchMaterial] = useState('')
  const [qty, setQty] = useState(1);
  const [viewMaterialData, setViewMaterialData] = useState([])
  const [viewMaterial, setViewMaterial] = useState(false)
  const [loadingMaterials, setLoadingMaterials] = useState(false)
  const [elapsedTime, setElapsedTime] = useState(0)
  const [checkedItems, setCheckedItems] = useState([])
  const [popUpTime, setPopUpTime] = useState(0)
  const [autoClockoutTime, setAutoClockoutTime] = useState(0)
  const [isSelectAll, setIsSelectAll] = useState(false)
  const [confirmAdditionalJObCost, setConfirmAdditionalJObCost] =
    useState(false)
  const [confirmMessage, setConfirmMessage] = useState('')
  const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [openPopupComplete, setOpenPopupComplete] = useState(false)
      const [completeStatus, setCompleteStatus] = useState(1)
    
   
  

  console.log('details Tab value-------', details)
  const user = JSON.parse(window.localStorage.getItem('user'))
  const explodedPermissions = user?.exploded_permissions
  const hasJobCostPermission = explodedPermissions?.some((permission) => ['Manage Job Cost'].includes(permission)) ? true : false
  const userId = user?.user_id
  const [openQRScanner, setOpenQRScanner] = useState(false);
  const scannerRef = useRef(null);


    useEffect(() => {
      if (openQRScanner) {
        setTimeout(() => {
          if (!scannerRef.current) {
            scannerRef.current = new Html5QrcodeScanner("reader", { fps: 10, qrbox: 250 });
  
            scannerRef.current.render(
              (decodedText) => {
                setSearchMaterial(decodedText);
                handleAddMaterialToJobCost(decodedText); // Automatically update material state
                setOpenQRScanner(false);
              },
              (errorMessage) => {
                console.error("QR Scan Error:", errorMessage);
              }
            );
          }
        }, 500); // Ensure modal is fully rendered before initializing scanner
      }
  
      return () => {
        if (scannerRef.current) {
          scannerRef.current.clear();
          scannerRef.current = null;
        }
      };
    }, [openQRScanner]);


 const handleOpenTask=(postId,project_id,currentTab,details)=>{
  console.log('handleOpenTaskData',postId,project_id,currentTab,details)
  setProjectsId(project_id)
  setTaskCurrentTab(currentTab)
  setTaskdetails(details)
  setPostId(postId)
  handleinnerTask()
  console.log('handleOpenTask',postId,project_id,currentTab,details)
 }
  const fetchClockSettings = async() => {
    try {
        const res = await axios.post(`${REST_API_END_POINT}settings/fetch-all-clock-settings`)
        if(res.data.status === 1){
            const settingsData = res.data.clocksettings
            settingsData?.map((item)=>{
              if(item?.id == 1){
                setPopUpTime(parseInt(item?.time))
              }else if(item?.id == 2){
                setAutoClockoutTime(parseInt(item?.time))
              }
            })
        }else{
          
            setPopUpTime(0)
            setAutoClockoutTime(0)
        }
    } catch (error) {
        console.log("Clock settings error : ",error)
        setPopUpTime(0)
        setAutoClockoutTime(0)
    }
  }
 
  useEffect(()=>{
    fetchClockSettings()
  },[])

  const logo =
    'https://t3.ftcdn.net/jpg/04/60/44/08/360_F_460440876_a8lkfz8UHz64eDPFzmSJJZ55UQBNiC0a.jpg'
  const img =
    'https://www.shutterstock.com/image-photo/indianapolis-circa-february-2017-pizza-260nw-572078815.jpg'

  const handleDeleteClick = (postId) => {
    setDeleteStates((prevState) => ({
      ...prevState,
      [postId]: true,
    }))
  }

  const handleUpdateStatus = (status, taskId,projectId)=>{
    try {
      axios.post(`${REST_API_END_POINT}project/update-project-task-status`,{ taskId, status, userId: user.user_id,projectId },{
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if(response.data.status === 1){
          enqueueSnackbar(response.data.message, { variant: 'success' });
         
        }else if(response.data.status === 0){
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
         if(response.data.allProjectCompleted){
          setOpenPopupComplete(true)
          }else{
            setFetchData(true)
          }
        
      }).catch((error) => {
        console.error(error);
      })
    }catch (error) {
      console.error("Error updating task:", error);
    }
  }

  const convertJObToInstallation = async()=>{
    const res = await axios.post(`${REST_API_END_POINT}installation/convert-job-to-installation`,
      { selectedIds: Number(projectId), userId: userId,completeStatus:Number(completeStatus),clientId:details?.customer_id})
      if(res.data.status===1) {
        setOpenPopupComplete(false);
        enqueueSnackbar(`${Number(completeStatus) === 1
          ? 'Moved To Installs'
          : Number(completeStatus) === 2
          ? 'Moved To Pickup'
          : 'Moved To Shipping'} Successfully`, { variant: 'success' });
          // onDataChange()
          setFetchData(true)

      }else{
        enqueueSnackbar("Not Moved To Installation", { variant: 'error' });
      }
  }

  const handleCancelDelete = (postId) => {
    setDeleteStates((prevState) => ({
      ...prevState,
      [postId]: false,
    }))
  }
  const valuetext = (value) => {
    return `${value}%`
  }
  const updateStatus = (event, value) => {
    console.log('###############value', value)
    let values = {}
    let user = JSON.parse(window.localStorage.getItem('user'))
    values.completion = event.target.value
    values.projectId = projectId
    values.added_by = user.user_id
    values.taskId = value
    if (event.target.value && values.projectId) {
      axios.post(
        `${REST_API_END_POINT}project/update-project-task-progress`,
        { ...values },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          // headers: { 'Content-Type': 'multipart/form-data' }
        },
      )
    }
  }
  const handleDeleteTask = (id, projectId) => {
    console.log(projectId)
    try {
      // const  user  = JSON.parse(window.localStorage.getItem('user'))
      axios
        .post(
          `${REST_API_END_POINT}project/delete-project-task`,
          { id: Number(id), projectId, status: 0 },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then((response) => {
          if (response.data.status === 4) {
            enqueueSnackbar(response.data.message, { variant: 'success' })
            setFetchData(true)
          } else if (response.data.status === 5) {
            enqueueSnackbar(response.data.message, { variant: 'error' })
          }
          handleCancelDelete(id)
        })
        .catch((error) => {
          console.error(error)
        })
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const fetchTimeLogData = async () => {
    try {
      const res = await axios.post(
        `${REST_API_END_POINT}project-task/fetch-clockedIn-staff-task-timelog`,
        { projectId: projectId, taskId: taskId, userId: userId },
      )
      if (res.data.status === 1) {
        const staffTimeLogData = res.data.result[0]
        let taskIds = staffTimeLogData?.sub_task_id
        taskIds = taskIds?.toString()
        const taskArray = taskIds?.split(',')
        setCheckedItems(taskArray)

        const singleTaskId = taskArray?.length > 0 ? Number(taskArray[0]) : null;
        setTaskId(singleTaskId);
        
        setStaffTimeLog(staffTimeLogData)
        if (staffTimeLogData.end_time === null) {
          setIsLoggedIn(true)
        } else {
          setIsLoggedIn(false)
        }
      } else {
        setStaffTimeLog(null)
        setIsLoggedIn(false)
        setCheckedItems([])
      }
    } catch (error) {
      console.log('Error fetching : ', error)
    }
  }

  const handleConfirmAdditionalJobCost = async () => {
    try {
    let realQty = (materialData.balanceQty || qty || 1) 

      let values = [
        {
          productId: materialData.product_id || materialData.id,
          projectId: projectId,
          actual_quantity: materialData.actual_quantity || 0,
          actual_unit_cost: materialData.actual_unit_cost || 0,
          actual_total: materialData.actual_total || 0,
          quantity: materialData.balanceQty ? materialData.balanceQty : (qty ? qty : 1),
          total: (materialData.material_unit_cost * realQty) || (materialData.unit_price * realQty) ||( materialData.unit_cost * realQty),
          unitPrice: materialData.material_unit_cost || materialData.unit_price || materialData.unit_cost,
        },
      ]
      axios
        .post(
          `${REST_API_END_POINT}project/add-job-cost`,
          {
            userId: user.user_id,
            values,
            projectId,
            recipe: 0,
            selectedServices: [],
            scanQr: true,
            taskId,
            
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then((response) => {
          if (response.data.status === 1) {
            onDataChange()
            enqueueSnackbar(response.data.message, { variant: 'success' })
          } else if (response?.data?.message) {
            enqueueSnackbar(response.data.message, { variant: 'error' })
          } else {
            enqueueSnackbar('Error adding Jobcost', { variant: 'error' })
          }
          setConfirmAdditionalJObCost(false)
          setConfirmMessage('')
          setSearchMaterial('')
        })
        setQty(1)
        .catch((error) => {
          console.error('Error adding Jobcost', error)
          enqueueSnackbar('Error adding Jobcost', { variant: 'error' })
        })
    } catch (error) {
      console.log(error)
    }
  }

  const handleCheckboxChange = (id) => {
    setCheckedItems((prevState) => {
      const isPresent = prevState.includes(id)
      const updatedState = isPresent
        ? prevState.filter((item) => item !== id)
        : [...prevState, id]
      setTaskId(updatedState.length === 1 ? id : null)
      return updatedState
    })
  }

  const handleSelectAll = () => {
    if (isSelectAll) {
      setIsSelectAll(false)
      setCheckedItems([])
    } else {
      const allIds = data?.map((post) => String(post.ID)) || []
      setCheckedItems(allIds)
    }
    setIsSelectAll(!isSelectAll)
  }

  useEffect(() => {
    fetchTimeLogData()
  }, [isLoggedIn])

  useEffect(() => {
    if (isLoggedIn) {
      const intervalId = setInterval(() => {
        setOpenPopup(true)
      }, popUpTime * 60 * 1000)

      return () => {
        clearInterval(intervalId)
      }
    }
  }, [isLoggedIn])

  const handleClockInOut = async () => {
    try {
      if (checkedItems?.length > 0) {
        let res = ''
        const now = Math.round(moment().valueOf() / 1000)
        if (isLoggedIn) {
          const timeLogId = staffTimeLog?.id
          const startTime = staffTimeLog?.start_time
          const staffId = staffTimeLog?.staff_id
          const staffHourlyRate = staffTimeLog?.staff_hourly_rate || 20
          res = await axios.post(
            `${REST_API_END_POINT}project-task/update-staff-task-timelog`,
            {
              checkedItems,
              projectId,
              userId,
              timeLogId,
              taskId,
              startTime,
              now,
              staffId,
              staffHourlyRate,
              noSubTask:true
            },
          )
          setFetchData(true)
        } else {
          res = await axios.post(
            `${REST_API_END_POINT}project-task/add-staff-task-timelog`,
            { checkedItems, projectId, userId, taskId, now, noSubTask:true },
          )
          // setFetchData(true)
        }
        setIsLoggedIn(!isLoggedIn)
        setOpenPopup(false)
        setElapsedTime(0)
        if (res.data.status === 1) {
          if (res.data.result === 'add') {
            enqueueSnackbar('Clocked In successfully', { variant: 'success' })
          } else if (res.data.result === 'update') {
            enqueueSnackbar('Clocked Out successfully', { variant: 'success' })
          }
        } else {
          console.log('handleClockInOut res : ', res.data)
        }
      } else {
        enqueueSnackbar('Please select a task', { variant: 'error' })
      }
    } catch (error) {
      console.log('HandleClockInOut error : ', error)
    }
  }

  const handleClosePopup = (continueWorking) => {
    setOpenPopup(false)
    if (!continueWorking) {
      handleClockInOut()
    }
  }

  useEffect(() => {
    if (staffTimeLog) {
      const interval = setInterval(() => {
        const now = Math.round(moment().valueOf() / 1000)
        const difference = now - staffTimeLog?.start_time

        setElapsedTime(difference)
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [staffTimeLog])

  const hours = String(Math.floor(elapsedTime / 3600)).padStart(2, '0')
  const minutes = String(Math.floor((elapsedTime % 3600) / 60)).padStart(2, '0')
  const seconds = String(elapsedTime % 60).padStart(2, '0')

  const handleAddMaterialToJobCost = async (materialBarcode) => {
    try {
      if (materialBarcode && materialBarcode != '') {
        const response = await axios.post(
          `${REST_API_END_POINT}project/update-material-in-jobcost`,
          { userId: user.user_id, materialBarcode, projectId, taskId ,qty},
        )
        if (response.data.status === 1) {
          enqueueSnackbar(response.data.message, { variant: 'success' })
          setQty(1)
          setSearchMaterial('')
        } else if (response.data.status === 2 || response.data.status === 3 || response.data.status === 4) {
          setConfirmAdditionalJObCost(true)
          setConfirmMessage(response.data.message)
          setMaterialData(response.data.materialData)
        } else if (response.data.message) {
          enqueueSnackbar(response.data.message, { variant: 'error' })
        } else {
          enqueueSnackbar('Error adding Jobcost', { variant: 'error' })
        }
        // setQty(1)

      }
    } catch (error) {
      console.log('Error in add material to jobcost', error)
    }
  }

  const handleViewAddedMaterials = async () => {
    setLoadingMaterials(true)
    try {
      const response = await axios.post(
        `${REST_API_END_POINT}project/view-added-material-in-task`,
        { projectId, taskId },
      )
      if (response.data.status === 1) {
        setViewMaterialData(response.data.result)
      } else {
        enqueueSnackbar('Failed to view materials', { variant: 'error' })
        setViewMaterialData([])
      }
    } catch (error) {
      console.log('Error in add material to jobcost', error)
      setViewMaterialData([])
    } finally {
      setLoadingMaterials(false)
    }
  }

  return (
    <>
      {valueTab === 'one' ? (
        <>
          <Grid container spacing={3}>
            {details?.job_category_name == 'Time & Material' ? (
              <Box sx={{ width: '100%' }}>
                {user.user_type !== 3 &&
                  currentTab != 'Installs' && currentTab != 'Pickup' && currentTab != 'Shipping' &&
                  currentTab != 'Completed' &&
                  currentTab != 'Hold' &&
                  data?.status !== 3 &&
                  details?.hold != 1 && hasJobCostPermission && (
                    <Stack
                      justifyContent="space-between"
                      alignItems="center"
                      // flexDirection="row"
                      flexDirection={isSmallScreen ? 'column' : 'row'}
                     
                      sx={{ mb: 2, mt: 2 }}
                    >
                       <Stack justifyContent='space-between' alignItems='center' flexDirection='row' gap={2} sx={{mb:2}} >
                      <TextField
                        size='small'
                        type="number"
                        label={'Enter Qty'}
                        placeholder='Enter Qty'
                        value={qty || null}
                        onChange={(e)=>{
                          setQty(e.target.value);
                        }}
                        onKeyDown={(event) => {
                          if (event.key === '-' || event.key === '+' || event.key ==='.') {
                            event.preventDefault();
                          }
                        }}
                        onWheel={(e) => e.target.blur()}
                      />

                      <TextField
                        size='small'
                        fullWidth
                        label={'Scan Material'}
                        placeholder="Scan Material"
                        value={searchMaterial}
                        onChange={(e) => {
                          setSearchMaterial(e.target.value)
                          handleAddMaterialToJobCost(e.target.value)
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          ),
                        }}
                      />
</Stack>
<Stack justifyContent='space-between' alignItems='center' flexDirection='row' gap={2} sx={{mb:2}} >
<>
      {/* Icon Button to trigger QR scanner */}
 {/* QR Code Scanner Icon */}
 <IconButton onClick={() => setOpenQRScanner(true)}>
        <QrCodeScanner sx={{ fontSize: "30px" }} />
      </IconButton>

      {/* Dialog for QR Scanner */}
      <Dialog open={openQRScanner} onClose={() => setOpenQRScanner(false)}>
        <DialogTitle>Scan QR Code</DialogTitle>
        <DialogContent>
          <div id="reader" style={{ width: "100%" }}></div>
        </DialogContent>
      </Dialog>
    </>
                      <Tooltip title='View Added Materials'>
                      <Button
                        variant="contained"
                        sx={{width:'200px'}}
                        type="submit"
                        onClick={() => {
                          handleViewAddedMaterials()
                          setViewMaterial(true)
                        }}
                        startIcon={
                          <Stack>
                            <Visibility />
                          </Stack>
                        }
                      >
                        View Materials
                      </Button>
                      </Tooltip>
                      </Stack>
                    </Stack>
                  )}
                {data?.length > 0 && (
                  <Stack
                    justifyContent="space-between"
                    alignItems="center"
                    flexDirection="row"
                  >
                    <Stack
                      justifyContent="flex-start"
                      alignItems="center"
                      flexDirection="row"
                    >
                      <Typography variant="subtitle1">
                        {checkedItems?.length > 0 &&
                          checkedItems?.length + ' selected'}
                      </Typography>
                    </Stack>
                    {user.user_type !== 3 &&
                      currentTab != 'Installs' && currentTab != 'Pickup' && currentTab != 'Shipping' &&
                      currentTab != 'Completed' &&
                      currentTab != 'Hold' &&
                      data?.status !== 3 &&
                      details?.hold != 1 && (
                        <Stack
                          justifyContent="flex-end"
                          alignItems="center"
                          flexDirection="row"
                        >
                          <Card>
                            <Stack
                              justifyContent="flex-end"
                              alignItems="center"
                              flexDirection="row"
                              sx={{ m: 2 }}
                            >
                              <Stack
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                                sx={{ mr: 2 }}
                              >
                                <Typography
                                  variant="h6"
                                  sx={{
                                    textAlign: 'center',
                                    color: theme.palette.secondary,
                                  }}
                                >
                                  {'Date'}{' '}
                                </Typography>
                                <Typography
                                  variant="h5"
                                  sx={{
                                    textAlign: 'center',
                                    color: theme.palette.secondary,
                                  }}
                                >
                                  {moment().format('MM/DD/YYYY')}
                                </Typography>
                              </Stack>
                              <Stack
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                              >
                                <Typography
                                  variant="h6"
                                  sx={{ textAlign: 'center', color: '#fc8c03' }}
                                >
                                  {'Work Time'}{' '}
                                </Typography>
                                {isLoggedIn ? (
                                  // (elapsedTime!==0) ?
                                  <Typography
                                    variant="h4"
                                    sx={{
                                      textAlign: 'center',
                                      color: '#fc8c03',
                                    }}
                                  >
                                    {hours + ':' + minutes + ':' + seconds}
                                  </Typography>
                                ) : (
                                  // :
                                  // <Stack sx={{mr:2,textAlign: 'center',mb: 2}}>
                                  //   <CircularProgress color="warning" variant="indeterminate" size='20px' />
                                  // </Stack>
                                  <Typography
                                    variant="h4"
                                    sx={{
                                      textAlign: 'center',
                                      color: '#fc8c03',
                                    }}
                                  >
                                    {'00:00:00'}
                                  </Typography>
                                )}
                              </Stack>
                            </Stack>
                          </Card>
                          <Button
                            variant="contained"
                            type="submit"
                            disabled={isSelectAll}
                            onClick={() => {
                              handleClockInOut()
                            }}
                            sx={{ mb: 2, ml: 2 }}
                            startIcon={
                              <Stack>
                                {isLoggedIn ? <Logout /> : <Login />}
                              </Stack>
                            }
                          >
                            {isLoggedIn ? 'Clock Out' : 'Clock In'}
                          </Button>
                        </Stack>
                      )}
                  </Stack>
                )}
              </Box>
            ) : (
              ''
            )}
            {data?.map((post, index) => (
              <Grid key={index} item xs={12} sm={6}>
                {console.log("posttttttttt",post)}
                <Card
                  onMouseEnter={() => setIsHover(true)}
                  onMouseLeave={() => setIsHover(false)}
                  sx={{
                    minWidth: 300,
                    transition: 'transform 0.2s',
                    '&:hover': {
                      transform: 'scale(1.02)',
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      zIndex: 20,
                      padding: '12px',
                    }}
                  >
                    {post.product_id != null && post.status === 3 && (
                      <Stack
                        sx={{
                          zIndex: 10,
                          top: 330,
                          right: 70,
                          color: theme.palette.grey[500],
                          position: 'absolute',
                          cursor: 'pointer',
                        }}
                      >
                        <FinishedProductmenu
                          url={`${BASE_URL}view-finished-task/${post.ID}/`}
                          id={projectId}
                          useIcon={true}
                        />
                      </Stack>
                    )}
                    {isHover &&
                      !taskId &&
                      user.user_type !== 3 &&
                      currentTab != 'Installs' && currentTab != 'Pickup' && currentTab != 'Shipping' &&
                      currentTab != 'Completed' &&
                      currentTab != 'Hold' &&
                      details?.status != 3 &&
                      details?.hold != 1 &&
                      post.from_product === 0 && (
                        <DeleteOutlineOutlined
                          onClick={() => handleDeleteClick(post.ID)}
                          sx={{
                            zIndex: 10,
                            top: 17,
                            right: 16,
                            color: theme.palette.grey[500],
                            position: 'absolute',
                            cursor: user.user_type == 3 ? 'default' : 'pointer',
                          }}
                        />
                      )}
                    {!taskId &&
                      user.user_type !== 3 &&
                      currentTab != 'Installs' && currentTab != 'Pickup' && currentTab != 'Shipping' &&
                      currentTab != 'Completed' &&
                      currentTab != 'Hold' &&
                      details?.status != 3 &&
                      details?.hold != 1 &&
                      post?.status != 3 &&
                      // (post?.isAdditionalProduct === 1 || post?.service_isAdditionalProduct == 1) &&
                      post?.subtask_count <= 0 && (
                        <Button
                          variant="contained"
                          size="small"
                          color="success"
                          onClick={()=>{
                            handleUpdateStatus(3, post.ID,post.project_id);
                          }}
                        >
                          Complete
                        </Button>
                      )}
                    {/* <Box sx={{ px: 2, py: 1, textAlign: 'center', justifyContent: 'center' }}> */}
                    {
                      user.user_type !== 3 &&
                        currentTab != 'Installs' && currentTab != 'Pickup' && currentTab != 'Shipping' &&
                        details?.status != 3 &&
                        currentTab != 'Completed' &&
                        currentTab != 'Hold' &&
                        details?.job_category_name == 'Time & Material' &&
                        details?.hold != 1 && (
                          // <Stack
                          //   direction={'row'}
                          //   justifyContent={'space-between'}
                          //   alignItems={'center'}
                          // >
                          <Checkbox
                            sx={{
                              cursor:
                                user.user_type === 3 ? 'default' : 'pointer',
                              display:
                                !taskId || post?.ID == taskId
                                  ? 'block'
                                  : 'none',
                              zIndex: 10,
                              position: 'absolute',
                              top: 10,
                              right: 45,
                              color: theme.palette.grey[500],
                            }}
                            // disabled={taskId ? (post?.ID == taskId ? false : true) : false}
                            checked={
                              checkedItems?.includes(String(post?.ID))
                                ? true
                                : false
                            }
                            onChange={() => {
                              isLoggedIn || post.status == 3
                                ? null
                                : handleCheckboxChange(String(post.ID))
                            }}
                            color="primary"
                          />
                        )
                      // </Stack>
                    }
                    {post.service_id != null && (
                      <Tooltip title={sentenceCase(post.name ==="Labor Install" ? "Service" : post.rfp == 1 ? 'Proposal service' : 'Additional Service')}>
                      <Typography sx={{
                        zIndex: 10,
                        position: 'absolute',
                        top: '140px',
                        right: '10px',
                        cursor: 'pointer',
                        display: 'inline-block',
                        maxWidth: 150,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}>
                        <Label
                          color={post?.rfp == 1 ? 'info' : 'secondary'}
                          variant={ theme.palette.mode === 'light' ? 'ghost' : 'filled' }
                        >
                          {sentenceCase(post.name ==="Labor Install" ? "Service" : post.rfp == 1 ? 'Proposal service' : 'Additional Service')}
                        </Label>
                      </Typography>
                      </Tooltip>
                    )}
                    {/* </Box> */}
                  </Box>
                  <CardMediaStyle
                    onClick={() => {
                      if(popupOn){
                        (user.user_type == 3 || (taskId && details?.job_category_name == 'Time & Material'))
                        ? null:
                        handleOpenTask(post.ID,post.project_id,currentTab,details)
                      }else{
                          ((user.user_type == 3 || (taskId && details?.job_category_name == 'Time & Material'))
                          ? null
                          : navigate(
                              PATH_DASHBOARD.project.viewTask +
                                post.ID +
                                '/' +
                                post.project_id,
                              { state: { currentTab: currentTab,details:details } },
                            ))}
                    }}
                  >
                    <CoverImgStyle
                      src={
                        post?.product_design && post?.approved == 1
                          ? post?.product_design : post?.avatarUrl ? post?.avatarUrl
                          : logo
                      }
                    />
                  </CardMediaStyle>
                  <Box
                    sx={{
                      p: 2,
                      textAlign: 'center',
                      justifyContent: 'center',
                      cursor: user.user_type == 3 ? 'default' : 'pointer',
                    }}
                    // onClick={() => {
                    //   (user.user_type == 3 || (taskId && details?.job_category_name == 'Time & Material'))
                    //     ? null
                    //     : navigate(
                    //         PATH_DASHBOARD.project.viewTask +
                    //           post.ID +
                    //           '/' +
                    //           post.project_id,
                    //         {
                    //           state: {
                    //             designs: designs,
                    //             currentTab: currentTab,
                    //             details:details
                    //           },
                    //         },
                    //       )
                    // }}
                    onClick={() => {
                      if(popupOn){
                        (user.user_type == 3 || (taskId && details?.job_category_name == 'Time & Material'))
                        ? null:
                        handleOpenTask(post.ID,post.project_id,currentTab,details)
                      }else{
                          ((user.user_type == 3 || (taskId && details?.job_category_name == 'Time & Material'))
                          ? null
                          : navigate(
                              PATH_DASHBOARD.project.viewTask +
                                post.ID +
                                '/' +
                                post.project_id,
                              { state: { currentTab: currentTab,details:details } },
                            ))}
                    }}
                  >
                    <Stack
                      direction={'row'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Typography
                        sx={{
                          cursor: user.user_type == 3 ? 'default' : 'pointer',
                        }}
                        // onClick={() => {
                        //   (user.user_type == 3 || (taskId && details?.job_category_name == 'Time & Material'))
                        //     ? null
                        //     : navigate(
                        //         PATH_DASHBOARD.project.viewTask +
                        //           post.ID +
                        //           '/' +
                        //           post.project_id,
                        //         {
                        //           state: {
                        //             designs: designs,
                        //             currentTab: currentTab,
                        //             details:details
                        //           },
                        //         },
                        //       )
                        // }}
                        onClick={() => {
                          if(popupOn){
                            (user.user_type == 3 || (taskId && details?.job_category_name == 'Time & Material'))
                            ? null:
                            handleOpenTask(post.ID,post.project_id,currentTab,details)
                          }else{
                              ((user.user_type == 3 || (taskId && details?.job_category_name == 'Time & Material'))
                              ? null
                              : navigate(
                                  PATH_DASHBOARD.project.viewTask +
                                    post.ID +
                                    '/' +
                                    post.project_id,
                                  { state: { currentTab: currentTab,details:details } },
                                ))}
                        }}
                        variant="subtitle2"
                        align="center"
                      >
                        {post.name} {post.name=="Labor Install" ? `(${post?.productName}) `: ''}
                      </Typography>

                      {/* <Typography
                    variant="overline"
                    sx={{ mt: 3, display: 'block', color: 'text.secondary' }}
                  >
                    Completion :{post.complete}%
                  </Typography> */}
                      {/* { console.log("#############Post",post)}
                    {post && <Sliderw
                      aria-label="Percentage"
                      defaultValue={post.complete}
                      
                      getAriaValueText={() => post.complete}
                      // onChange={(event, value) => updateStatus(event, post.ID)}
                      valueLabelDisplay={true}
                    onMouseEnter={post.complete}
                      //  step={10}
                      marks
                      min={10}
                      max={100}
                      disabled={true}
                    />} */}
                      {details?.hold == 1 ? (
                        <Typography>
                          <Label
                            color={'warning'}
                            variant={
                              theme.palette.mode === 'light'
                                ? 'ghost'
                                : 'filled'
                            }
                          >
                            {sentenceCase('Hold')}
                          </Label>
                        </Typography>
                      ) : (
                        <Typography>
                          <Label
                            variant={
                              theme.palette.mode === 'light'
                                ? 'ghost'
                                : 'filled'
                            }
                            color={
                              option.find((opt) => opt?.id === post?.status)
                                .color || ''
                            }
                          >
                            {sentenceCase(
                              option.find((opt) => opt?.id === post?.status)
                                .value || '',
                            )}
                          </Label>
                        </Typography>
                      )}
                    </Stack>
                    {(post.from_product === 0 || details?.job_category_name == 'Time & Material') ? (
                      ''
                    ) : (
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        marginTop={2}
                      >
                        {/* Left side: LocationOn icon and location name */}
                        {/* {post?.product_map_service == 1 ?
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <LocationOn sx={{ color: theme.palette.error.main }} />
                            <Tooltip
                              title={
                                <Box>
                                  {post?.locations?.map((location, index) => (
                                    <Typography key={index} variant="caption" sx={{ display: 'block' }}>
                                      {`${index + 1}) ${location.address}, ${location.city}, ${location.state}, ${location.zip}`}
                                    </Typography>
                                  ))}
                                </Box>
                              }
                              arrow
                              placement="top"
                            >
                              <Typography
                                sx={{ color: theme.palette.primary.main }}
                                variant="subtitle2"
                                align="center"
                              >
                                {post?.locations?.length} Locations
                              </Typography>
                            </Tooltip>
                          </Stack>
                        : */}
                          <Stack direction="row" alignItems="center" spacing={1}>
                            {post.name !=="Labor Install" ?
                            <LocationOn
                            sx={{ color: theme.palette.error.main }}
                            />
                            : ''
                          }
                            <Typography
                              sx={{ color: theme.palette.primary.main }}
                              variant="subtitle2"
                              align="center"
                            >
                              {post.service_id != null ? post.service_location : post.location}
                            </Typography>
                          </Stack>
                        {/* } */}
                        
                        {/* Right side: Quantity */}
                        <Typography
                          sx={{ cursor: 'pointer' }}
                          variant="subtitle2"
                          align="center"
                        >
                          Quantity: {post.location_quantity ? 1 : post.service_qty}
                        </Typography>
                      </Stack>
                    )}
                    <Stack>
                      <Typography
                        variant="overline"
                        sx={{
                          mt: 3,
                          display: 'block',
                          color: 'text.secondary',
                        }}
                      >
                        Completion :{post.complete}%
                      </Typography>
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      p: 2,
                      textAlign: 'center',
                      justifyContent: 'center',
                      cursor: user.user_type == 3 ? 'default' : 'pointer',
                    }}
                    // onClick={() => {
                    //   (user.user_type == 3 || (taskId && details?.job_category_name == 'Time & Material'))
                    //     ? null
                    //     : navigate(
                    //         PATH_DASHBOARD.project.viewTask +
                    //           post.ID +
                    //           '/' +
                    //           post.project_id,
                    //         {
                    //           state: {
                    //             designs: designs,
                    //             currentTab: currentTab,
                    //             details:details
                    //           },
                    //         },
                    //       )
                    // }}
                    onClick={() => {
                      if(popupOn){
                        (user.user_type == 3 || (taskId && details?.job_category_name == 'Time & Material'))
                        ? null:
                        handleOpenTask(post.ID,post.project_id,currentTab,details)
                      }else{
                          ((user.user_type == 3 || (taskId && details?.job_category_name == 'Time & Material'))
                          ? null
                          : navigate(
                              PATH_DASHBOARD.project.viewTask +
                                post.ID +
                                '/' +
                                post.project_id,
                              { state: { currentTab: currentTab,details:details,designs:designs } },
                            ))}
                    }}
                  >
                    <Stack
                      direction={'row'}
                      justifyContent={'flex-start'}
                      alignItems={'center'}
                      spacing={2}
                    >
                      {/* <Stack spacing={1} alignItems={'center'} direction={'row'}>
                    <Groups sx={{ width: 17 }} />
                    <Typography variant="subtitle2">{post.member_count}</Typography>
                  </Stack> */}
                      <Stack
                        spacing={1}
                        alignItems={'center'}
                        direction={'row'}
                      >
                        <Attachment sx={{ width: 25 }} />
                        <Typography variant="subtitle2">
                          {post.file_count}
                        </Typography>
                      </Stack>
                      <Stack
                        spacing={1}
                        alignItems={'center'}
                        direction={'row'}
                      >
                        <ChatBubble sx={{ width: 25 }} />
                        <Typography variant="subtitle2">
                          {post.post_count}
                        </Typography>
                      </Stack>
                      <Stack
                        spacing={1}
                        alignItems={'center'}
                        direction={'row'}
                      >
                        <CheckBoxRounded sx={{ width: 25 }} />
                        <Typography variant="subtitle2">
                          {post.subtask_count}
                        </Typography>
                      </Stack>
                      <Stack
                        spacing={1}
                        alignItems={'center'}
                        direction={'row'}
                      >
                        <AccessTime sx={{ width: 25 }} />
                        <Typography variant="subtitle2">
                          {post?.total_hours ? post?.total_hours : '00:00:00'}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                </Card>
                <ConfirmationPopup
                  open={deleteStates[post.ID]}
                  handleClick={() => handleDeleteTask(post.ID, projectId)}
                  message={'Want to Delete This Task'}
                  handleClose={() => handleCancelDelete(post.ID)}
                />
                <ConfirmationPopup
                  open={confirmAdditionalJObCost}
                  handleClick={() => handleConfirmAdditionalJobCost()}
                  message={confirmMessage}
                  handleClose={() => {
                    setConfirmAdditionalJObCost(false)
                    setConfirmMessage('')
                    setSearchMaterial('')
                  }}
                />


<ConfirmationPopup
                    isComplete={true}
                      open={openPopupComplete}
                      setCompleteStatus={setCompleteStatus}
                      handleClick={() => {
                        convertJObToInstallation()
                      }}
                       hideCancel={true}
                    />

              </Grid>
            ))}
          </Grid>
          <Modal
            open={viewMaterial ? true : false}
            handleClose={() => {
              setViewMaterialData([])
              setViewMaterial(false)
            }}
            modalTitle={'Materials added'}
          >
            {loadingMaterials ? (
              <CircularProgress color="primary" variant="indeterminate" />
            ) : viewMaterialData?.length > 0 ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl No.</TableCell>
                      <TableCell>Barcode</TableCell>
                      <TableCell>Product Name</TableCell>
                      <TableCell>Unit Cost</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {viewMaterialData?.map((material, index) => (
                      <TableRow key={material.id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{material.barcode}</TableCell>
                        <TableCell>{material.productName}</TableCell>
                        <TableCell>{material.unit_price}</TableCell>
                        <TableCell>{material.quantity}</TableCell>
                        <TableCell>{parseFloat(material.unit_price * material.quantity).toFixed(2)}</TableCell>
                        {/* <TableCell>{material.total}</TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography>No materials added</Typography>
            )}
          </Modal>
        </>
      ) : valueTab === 'three' ? (
        <ProjectViewList handleOpenTask={handleOpenTask} popupOn={popupOn} handleDeleteTask={handleDeleteTask} data={data} />
      ) : (
        <ProjectViewGridList
          designs={designs}
          handleDeleteTask={handleDeleteTask}
          data={data}
          handleOpenTask={handleOpenTask} popupOn={popupOn}
        />
      )}

    <ProjectStatusPopup open={openPopup} onClose={handleClosePopup} staffTimeLogData={staffTimeLog} handleClockInOut={handleClockInOut} autoClockoutTime={autoClockoutTime} />
    </>
  )
}
