import { capitalCase } from 'change-case'
import { useState, useEffect, useRef, useCallback, forwardRef } from 'react'
import {
  Groups,
  CheckCircle,
  Error,
  Attachment,
  MoreVert,
  Print,
  List,
  ViewList,
  ViewModule,
  UploadFileSharp,
} from '@material-ui/icons'
import Tooltip from '@mui/material/Tooltip'
// material
import {
  Container,
  Tab,
  Box,
  Tabs,
  Stack,
  Button,
  Menu,
  Typography,
  MenuItem,
  TextField,
  Card,
  Grid,
  Slider as Sliderw,
  ListItemText,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CardContent,
  ImageList,
  useMediaQuery,
  ImageListItem,
  ImageListItemBar,
  CircularProgress,
} from '@material-ui/core'
import {
  alpha,
  experimentalStyled as styled,
  useTheme,
} from '@material-ui/core/styles'
// components
import Tasks from '../../components/projects/viewProjects/Tasks'
import Members from '../../components/projects/viewProjects/Members'
import ViewRequest from 'src/components/_dashboard/official-request/ViewRequest'
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs'
import axios from 'axios'
import { COMPANY_NAME, REST_API_END_POINT } from 'src/constants/Defaultvalues'
import { useParams, useNavigate, useLocation } from 'react-router'
import Modal from 'src/components/_dashboard/blog/ProjectModel'
import Label from 'src/components/Label'
import Scrollbar from 'src/components/Scrollbar'
import moment from 'moment'
import AddTask from 'src/components/projects/AddTask'
import AddMember from 'src/components/projects/AddMember'
import AddProject from 'src/components/_dashboard/blog/AddProject'
import { useSnackbar } from 'notistack'
import ReactToPrint from 'react-to-print'
import { KeyboardBackspaceOutlined } from '@material-ui/icons'
import { Link as RouterLink } from 'react-router-dom'
import { PATH_DASHBOARD, ROOTS_PCB } from '../../routes/paths'
import { CarouselControlsPaging1 } from '../../components/carousel'
import Slider from 'react-slick'
import { LoadingButton } from '@material-ui/lab'
import { storage } from 'src/firebase/firebase'
import {
  deleteObject,
  getDownloadURL,
  uploadBytesResumable,
  ref as imageref,
} from '@firebase/storage'
import { UserMoreMenu } from 'src/components/_dashboard/user/list'
import ProjectAttachment from 'src/components/_dashboard/blog/ProjectAttachment'
import { set } from 'lodash'
import Locations from 'src/components/_dashboard/official-request/Locations'
import ConfirmationPopup from 'src/components/confirmationPopup'
import LoadingScreen from 'src/components/LoadingScreen'
import DesignOrderTask from 'src/components/projects/viewProjects/DesignOrderTask'
import ViewDesignOrder from './ViewDesignOrder'
// ----------------------------------------------------------------------

// let  option = [
//   { id: 0, value: 'Inactive' },
//   { id: 1, value: 'New' },
//   { id: 2, value: 'Open' },
//   { id: 3, value: 'Completed' },
//   { id: 4, value: 'In Testing' },
//   { id: 5, value: 'Accepted' },
//   { id: 6, value: 'Rejected' },
//   { id: 7, value: 'Verified' },
//   { id: 6, value: 'Reopen' },
// ]
let option = [
  // { id: 0, value: 'Inactive' },
  { id: 1, value: 'New' },
  { id: 2, value: 'Inprogress' },
  { id: 3, value: 'Completed' },
  { id: 4, value: 'Hold' },
  { id: 5, value: 'Active' },
  // { id: 6, value: 'Rejected' },
  // { id: 7, value: 'Hold' },
  // { id: 6, value: 'Reopen' },
]
const colors = [
  '#c9302c',
  '#5bc0de',
  '#0275d8',
  '#008000',
  '#f0ad4e',
  '#5cb85c',
  '#c9302c',
  '#5cb85c',
  '#5bc0de',
]

const PRODUCTS = [
  { name: 'Air Max 97', image: `/static/mock-images/products/product_1.jpg` },
  { name: 'PierBurger', image: `/static/mock-images/products/product_2.jpg` },
  {
    name: 'BLACK LIVE MATTERS',
    image: `/static/mock-images/products/product_3.jpg`,
  },
  { name: 'Britex', image: `/static/mock-images/products/product_4.jpg` },
  {
    name: 'KEG Steak House Bar',
    image: `/static/mock-images/products/product_5.jpg`,
  },
]

const CarouselImgStyle = styled('img')(({ theme }) => ({
  width: '100%',
  height: 280,
  objectFit: 'cover',
  [theme.breakpoints.up('xl')]: {
    height: 320,
  },
}))

const RowResultStyle = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))

const CustomTab = styled(Tab)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  boxShadow: 'none', //theme.shadows[5],
  minHeight: 'fit-content',
  indicator: {
    display: 'none',
  },
  height: 38,
  fontSize: 12,
  borderRadius: 'none', //20
  border: '1.5px solid #00c2ff', //newlyadded
  background: '#161c24',
  margin: 0, //5
  color: theme.palette.text.primary,
  '&:hover': {
    background: '#161c24',
  },
  '&.Mui-selected': {
    background: theme.palette.primary.main,
    color: '#fff',
  },
  '&:first-child': {
    borderRadius: '7px 0 0 7px',
    borderLeft: `1.5px solid ${theme.palette.primary.main}`,
  },
  '&:nth-child(2)': {
    borderRadius: '0',
    borderLeft: '0',
  },
  '&:nth-child(3)': {
    borderRadius: '0',
    borderLeft: '0',
  },
  '&:last-child': {
    borderRadius: '0 7px 7px 0',
    borderLeft: '0',
  },

  [theme.breakpoints.up('lg')]: {
    marginRight: `0 !important`,
    minWidth: '20% !important',
  },
  [theme.breakpoints.down('lg')]: {
    marginRight: `0 !important`,
    minWidth: '20% !important',
  },
  [theme.breakpoints.down('sm')]: {
    marginRight: `0 !important`,
    minWidth: '30.33% !important',
  },
}))

const getStatusColorAndLabel = (status) => {
  switch (status) {
    case 1:
      return { color: 'warning', label: 'Status: Draft' }
    case 2:
      return { color: 'info', label: 'Status: Open' }
    case 3:
      return { color: 'primary', label: 'Status: Evaluating' }
    case 4:
      return { color: 'success', label: 'Status: Accepted' }
    case 5:
      return { color: 'warning', label: 'Status: Closed' }
    case 6:
      return { color: 'error', label: 'Status: Rejected' }
    default:
      return { color: 'default', label: 'Status: Open' }
  }
}
const getFileType = (fileType) => {
  if (fileType.startsWith('image/')) {
    return 'image'
  } else if (fileType.startsWith('video/')) {
    return 'video'
  } else {
    return 'other'
  }
}
export default function ViewDesignOrderProject() {
  const theme = useTheme()
  const mdd = useMediaQuery(theme.breakpoints.down('md'))
  const xld = useMediaQuery(theme.breakpoints.up('xl'))
  const { enqueueSnackbar } = useSnackbar()
  const ref = useRef(null)
  const componentRef = useRef()
  const navigate = useNavigate()
  const { id } = useParams()
  const fileInputRef = useRef()
  const [progress, setProgress] = useState(false)
  const user = JSON.parse(window.localStorage.getItem('user'))
  const explodedPermissions = user?.exploded_permissions
  const [isOpen, setIsOpen] = useState(false)
  const [secTab, setSecTab] = useState('Tasks')
  const [value, setValue] = useState({ id: 1, value: 'New' })
  const [add, setAdd] = useState(false)
  const [addMember, setAddMember] = useState(false)
  const [selected, setSelected] = useState([])
  const [data, setData] = useState(null)
  const [projectMembers, setProjectMembers] = useState(null)
  const [projectTask, setProjectTask] = useState(null)
  const [allTeamMembers, setAllTeamMembers] = useState(null)
  const [openInvoice, setOpenInvoice] = useState(false)
  const [details, setDetails] = useState(null)
  const [requestServices, setRequestServices] = useState([])
  const [rfpServices, setRfpServices] = useState([])
  const [customerData, setCustomerData] = useState(null)
  const [designs, setDesigns] = useState([])
  const [invoice, setInvoice] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [renderCards, setRenderCards] = useState(false)
  const [fetchData, setFetchData] = useState(false)
  const [singleProjectData, setSingleProjectData] = useState(null)
  const [openAttachModal, setOpenAttachModal] = useState(false)
  const [attachments, setAttachments] = useState({})
  const [valueTab, setValueTab] = useState('one')
  const [refresh, setRefresh] = useState(false)
  const [signAgreements, setSignAgreements] = useState(null)
  const [portfolio, setPortfolio] = useState(null)
  const [customTemplate, setCustomTemplate] = useState(null)
  const [questionnaireTemplate, setQuestionnaireTemplate] = useState(null)
  const [allDataEmpty, setDataEmpty] = useState(true)
  const [proposalData, setProposalData] = useState(null)
  const [component, setComponent] = useState(null)
  const [openPopup, setOpenPopup] = useState(false)
  const [openPopupComplete, setOpenPopupComplete] = useState(false)
  const [openHoldPopup, setOpenHoldPopup] = useState(false)
  const [status, setStatus] = useState(null)
  const [completeStatus, setCompleteStatus] = useState(1)
  const [loading, setLoading] = useState(false)
  const [loadingJobCost, setLoadingJobCost] = useState(false)
  const [options, setOptions] = useState([
    // { id: 6, value: 'Inactive' },
    { id: 1, value: 'New' },
    { id: 2, value: 'Inprogress' },
    { id: 3, value: 'Completed' },
    // { id: 4, value: 'Hold' },
    // { id: 5, value: 'Active' },
    // { id: 6, value: 'Rejected' },
    // { id: 7, value: 'Verified' },
    // { id: 6, value: 'Reopen' },
  ])
  const location = useLocation()
  let currentTab = location?.state?.currentTab
  console.log('############location view project', currentTab)
  const handleChange = (event, newValue) => {
    setValueTab(newValue)
  }

  const handleback = () => {
    window.history.back()
  }

  const [data1, setData1] = useState({})
  const fetchDetails = async () => {
    try {
      const res = await axios.post(
        `${REST_API_END_POINT}settings/get-companyProfile-details`,
      )
      if (res.data.status === 1) {
        setData1(...res.data.data)
      }
    } catch (error) {
      console.log('get-companyProfile-details error : ', error)
    }
  }

  useEffect(() => {
    fetchDetails()
  }, [])

    const handleDesignJobCount = async () => {
      try {
        const response = await axios.post(`${REST_API_END_POINT}design-jobs/reduce-count`, {
          design_order_id: id,
          userId: user.user_id,
        });
        
      } catch (error) {
        console.error("Error reducing design order job count:", error);
      }
    };
    useEffect(() => {
      handleDesignJobCount()
    }, [])

  const settings = {
    speed: 1000,
    dots: true,
    arrows: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === 'rtl'),
    ...CarouselControlsPaging1({ color: 'primary.main' }),
  }
console.log('Designsssss===',designs)
  const TABS = [
    // ...(details?.job_category_name=='Service only' ? [] : [
    {
      value: 'Members',
      component: (
        <Members
          setFetchData={setFetchData}
          data={projectMembers}
          currentTab={currentTab}
          designOrder={true}
          projectData={data}
        />
      ),
    },
    // ]),
    {
      value: 'Tasks',
      component: (
        <DesignOrderTask
          details={data}
          designs={designs}
          valueTab={valueTab}
          setFetchData={setFetchData}
          data={projectTask}
          projectId={id}
          currentTab={currentTab}
        />
      ),
    },
    // {
    //   value: 'Locations',
    //   component: (
    //     <Locations
    //       products={invoice?.cust_product}
    //       details={details}
    //       additionalSelectedServices={requestServices}
    //       designs={designs}
    //       isFullWidth
    //       rfpServices={rfpServices}
    //     />
    //   ),
    // },
    {
      value: 'Designs',
      component: <ProjectAttachment setFetchData={setFetchData} projectId={id} currentTab={currentTab} designOrder={true} data={data} />,
    },
    // {
    //   value: `Order Details`,
    //   component: details ? (
    //     <ViewDesignOrder
    //       products={invoice?.cust_product}
    //       teamMembers={projectMembers}
    //       // fetchRequestDetails={fetchRequestDetails}
    //       user={user}
    //       designs={designs}
    //       setDesigns={setDesigns}
    //       details={details}
    //       isRequest={true}
    //       requestId={id}
    //       signAgreements={signAgreements}
    //       customTemplate={customTemplate}
    //       questionnaireTemplate={questionnaireTemplate}
    //       allDataEmpty={allDataEmpty}
    //       portfolio={portfolio}
    //       project={true}
    //       clientDetails={customerData}
    //     />
    //   ) : (
    //     ''
    //   ),
    // },
  ]

  useEffect(() => {
    try {
      localStorage.setItem('params', '')
      const hasPermission = explodedPermissions?.some((permission) =>
        [
          'View Design Order Jobs',
          'Hold Design Order Jobs',
          'Manage Design Order Job Cost'
        ].includes(permission),
      )
        ? true
        : false
      setLoading(true)
      axios
        .post(
          `${REST_API_END_POINT}project/get-deisgn-order-project-by-id`,
          {
            id: { value: id },
            userId: user.user_id,
            userType: user.user_type,
            hasPermission,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then((response) => {
          setSingleProjectData(response.data)
          setValue(
            options?.find((opt) => response?.data?.result?.design_order_job_status === opt?.id),
          )
          fetchRequestDetails(id)
          localStorage.setItem('params', id)
            
          setData(response.data.result)
          setProjectMembers(response.data.projectMembers)
          setProjectTask(response?.data?.projectTask)
          setAllTeamMembers(response.data.teamMembers)
          setFetchData(false)
          setRefresh(false)
          // let option = options.filter(opt => opt.id=== response.data.result.status);
          // setOptions(option)
          console.log('##############3#333', response.data.result.status)
          
          setTimeout(() => {
            setLoading(false)
          }, 1000)

          console.log('###############@@@@@@@@@@@@@@@', options)
        })
        .catch((error) => {
          console.error(error)
        })

      // handleFetchEditData(id)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }, [isEdit, fetchData, refresh])

  //add, addMember,



 
  const fetchRequestDetails = async (request_id) => {
    setLoading(true)
    try {
      console.log({ request_id })
      const res = await axios.post(`${REST_API_END_POINT}design/get-design-order-by-id`, {
        design_order_id: id,
        userId: user.user_id,
        userType: user.user_type,
      })
      res.data
      setCustomerData(res.data.customerData)
      setDetails(res.data?.data[0])
      // if(res.data?.data[0]?.job_category_name=='Service only'){
      // setSecTab('Locations')
      // }else{
      // setSecTab('Tasks')
      // }
      console.log('dataaaaaaaaaaaaaaaaaaaaaaaaaaaa', res.data?.data[0])
      // if (res.data?.data[0].project_category == 11) {
        // setRfpServices(res.data.requestServices.filter((row) => row.rfp === 1))
      // }
      console.log('resdata', res.data)
      setDesigns(res.data.attachments)
      setRequestServices(
        res.data?.requestServices?.filter((row) => row.rfp === 0),
      )


      // fetchOrderProductDetails(res.data.data[0]?.id)
      setCustomTemplate(res.data.fieldTemplates)
      setQuestionnaireTemplate(res.data.QuestionTemplates)
      setSignAgreements(res.data.signAgrementData)
      setDataEmpty(res.data.allDataEmpty)
      setPortfolio(res.data.portfolio)
      setRefresh(false)
      setProposalData(res.data.requestProposals)
    } catch (error) {
      console.error('Error fetching request details:', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchOrderProductDetails = async (orderId) => {
    setLoadingJobCost(true)
    try {
      // let orderId = details?.id;
      const res = await axios.post(
        `${REST_API_END_POINT}get-customer-order-product/${orderId}`,
      )
      await setInvoice(res.data.data)

      localStorage.setItem('params', res.data.data.request_id)
    } catch (error) {
      console.error('Error fetching request details:', error)
      throw error
    } finally {
      setLoadingJobCost(false)
    }
  }
  const handleUpdateStatus = async (optio) => {
    try {
      setOpenPopup(false)
      setOpenPopupComplete(false)
      setValue(optio)
      let status =optio || optio?.id
      const user = JSON.parse(window.localStorage.getItem('user'))
      
      // const response = await axios.post(`${REST_API_END_POINT}installation/convert-job-to-installation`,{selectedIds,userId:user.user_id})
      // const res = await axios.post(`${REST_API_END_POINT}project/update-project-status`,
      const res = await axios
        .post(
          `${REST_API_END_POINT}design-order/update-design-order-job-status`,
          {
            status,
            selectedIds: Number(id),
            userId: user.user_id,
            projectId: Number(id),
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then((response) => {
          if (response.data.status === 1) {
            setRefresh(true)
            enqueueSnackbar(response.data.message, { variant: 'success' })
          } else if (response.data.status === 0) {
            enqueueSnackbar(response.data.message, { variant: 'error' })
          }
        })
    } catch (error) {
      console.error('Error status update:', error)
      throw error
    }
  }
  const handleUpdateHoldStatus = async () => {
    try {
      setOpenHoldPopup(false)
      const user = JSON.parse(window.localStorage.getItem('user'))
      const res = await axios
        .post(
          `${REST_API_END_POINT}project/update-design-order-job-hold-status`,
          { projectId: Number(id), userId: user.user_id },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then((response) => {
          if (response.data.status === 1) {
            setRefresh(!refresh)
            enqueueSnackbar(response.data.message, { variant: 'success' })
          } else if (response.data.status === 0) {
            enqueueSnackbar(response.data.message, { variant: 'error' })
          }
        })
    } catch (error) {
      console.error('Error Moving to Hold:', error)
      throw error
    }
  }
  const handlePopup = async (opto) => {
    setOpenPopup(true)
    setStatus(opto) 
  }
  const handleTab = (event, newValue) => {
    setSecTab(newValue)
  }
  //////////////////////
  const handleFileChange = async (event) => {
    const files = event.target.files

    if (files.length === 0) {
      return
    }

    const uploadPromises = Array.from(files).map(async (file) => {
      const filename = new Date().getTime() + file.name
      const storageRef = imageref(storage, filename)
      const uploadTask = uploadBytesResumable(storageRef, file)

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const prog = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            )
            setProgress(true)
          },
          (error) => {
            console.error(error.message)
            setProgress(false)
            reject(error)
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref)
              .then((downloadURL) => {
                resolve({ downloadURL, fileType: getFileType(file.type) })
              })
              .catch((error) => {
                console.error(error.message)
                reject(error)
              })
          },
        )
      })
    })

    try {
      const validFileUploads = await Promise.all(uploadPromises)
      const newAttachments = validFileUploads.reduce(
        (acc, { downloadURL, fileType }) => {
          acc[fileType] = acc[fileType] || []
          acc[fileType].push(downloadURL)
          return acc
        },
        {},
      )

      setAttachments((prevAttachments) => ({
        ...prevAttachments,
        image: [
          ...(prevAttachments.image || []),
          ...(newAttachments.image || []),
        ],
        video: [
          ...(prevAttachments.video || []),
          ...(newAttachments.video || []),
        ],
        other: [
          ...(prevAttachments.other || []),
          ...(newAttachments.other || []),
        ],
      }))
      setProgress(false)
    } catch (error) {
      console.error('Error uploading files:', error)
    }
  }

  const RemoveAttachFromSubmit = (type, value) => {
    const deleteRef = imageref(storage, value)

    deleteObject(deleteRef)
      .then(() => {
        // File deleted successfully
        setAttachments((prevState) => ({
          ...prevState,
          [type]: (prevState[type] || []).filter((item) => item !== value),
        }))
      })
      .catch((error) => {
        console.error('Error deleting file:', error)
      })
  }

  const handleSubmitAttachments = async () => {
    try {
      if (Object.keys(attachments).length === 0) {
        enqueueSnackbar('Please provide attachments', { variant: 'warning' })
        return
      }
      let user = JSON.parse(window.localStorage.getItem('user'))
      const res = await axios.post(
        `${REST_API_END_POINT}project-task/add-project-attachment`,
        {
          attachments,
          taskId,
          projectId,
          userId: user.user_id,
        },
      )
      if (res.data.status === 1) {
        enqueueSnackbar(res.data.message, { variant: 'success' })
      } else {
        enqueueSnackbar(res.data.message, { variant: 'error' })
      }
      setAttachments({})
      setOpenAttachModal(false)
    } catch (error) {
      console.log(error)
    }
  }
  const valuetext = (value) => {
    return `${value}%`
  }
  const updateStatus = (event, value) => {
    let values = {}
    let user = JSON.parse(window.localStorage.getItem('user'))
    values.completion = event.target.value
    values.projectId = Number(id)
    values.added_by = user.user_id
    if (event.target.value && values.projectId) {
      axios.post(
        `${REST_API_END_POINT}project/update-project-progress`,
        { ...values },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          // headers: { 'Content-Type': 'multipart/form-data' }
        },
      )
    }
  }
  // if(loading){

  //   <Stack alignItems='center' justifyContent='center'>
  //     <LoadingScreen  />
  //   </Stack>
  // }
  console.log('##################Loading', loading)
  console.log("currentTab:", currentTab);
  console.log("currentTab:details?.status:", details?.status);
  console.log("currentTab:details?.design_order_job_status:", details?.design_order_job_status);

  return (
    <>
      {loading ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ height: '100vh' }}
        >
          <LoadingScreen />
        </Stack>
      ) : (
        <Container>
          {!isEdit ? (
            <Grid container spacing={3}>
              <Grid key={1} item xs={12} md={8}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button
                    variant="outlined"
                    type="submit"
                    size="small"
                    // onClick={handleback}
                    component={RouterLink}
                    to={PATH_DASHBOARD.design.job}
                    sx={{ alignItems: 'flex-end' }}
                    startIcon={
                      <Stack>
                        <KeyboardBackspaceOutlined />
                      </Stack>
                    }
                  >
                    Back to All Jobs
                  </Button>
                  <Button
                    variant="outlined"
                    type="submit"
                    size="small"
                    component={RouterLink}
                    to={ROOTS_PCB}
                    sx={{ alignItems: 'flex-end', mt: -2 }}
                    startIcon={
                      <Stack>
                        <KeyboardBackspaceOutlined />
                      </Stack>
                    }
                  >
                    Back to Home
                  </Button>
                </Stack>
                <HeaderBreadcrumbs
                  sx={{ mt: 2 }}
                  // heading={"Job Details"}
                  heading={
                    <Box>
                      {"Job Details"} {details?.job_category_name === 'Time & Material' ? <Label variant="filled" color="info">T&M</Label> : ''}
                    </Box>
                  }
                //   action={
                //     <Stack direction={'row'} spacing={2}>
                //       {secTab === 'Tasks' && (
                //         <Tabs
                //           value={valueTab}
                //           onChange={handleChange}
                //           textColor="secondary"
                //           indicatorColor="none"
                //           aria-label="secondary tabs example"
                //         >
                //           <CustomTab value="one" icon={<ViewModule />} />
                //           <CustomTab value="two" icon={<ViewList />} />
                //           <CustomTab value="three" icon={<List />} />
                //         </Tabs>
                //       )}
                //     </Stack>
                //   }
                />
                {/* <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="none"
                aria-label="secondary tabs example"
              >
                <CustomTab value="one" icon={<List />} />
                <CustomTab value="two" icon={<ViewList  />}/>
                <CustomTab value="three" icon={<ViewModule />} />
              </Tabs> */}

                <Stack spacing={5}>
                {currentTab != 'Completed' && (details?.design_order_job_status != 3) && (
                    <Stack
                      spacing={{ xs: 2, md: 0 }}
                      alignItems="flex-end"
                      justifyContent="space-between"
                    >
                      <Button
                        onClick={() => {
                          setOpenHoldPopup(true)
                        }}
                        disabled={
                          user.user_type == 3 
                            ? true 
                            : !explodedPermissions?.some((permission) => ['Hold Design Order Jobs'].includes(permission))
                        }                        
                        sx={{
                          borderRadius: 20,
                          backgroundColor: theme.palette.warning.main,
                          color: 'black',
                          boxShadow: 'none',
                          '&:hover': {
                            backgroundColor: theme.palette.warning.dark,
                            boxShadow: 'none',
                          },
                        }}
                        variant="contained"
                      >
                        {data?.hold === 0
                          ? 'Move to Hold'
                          : 'Release from Hold'}
                      </Button>
                    </Stack>
                  )}
                  <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    spacing={{ xs: 2, md: 0 }}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {/* {
                    value?.value &&
                    ((details?.job_category_name === 'Time & Material' && data?.status === 3) ||
                    (details?.job_category_name !== 'Time & Material' &&
                      ((data?.status !== 1 && data?.status !== 0) || data?.hold === 1)))
                     ? (
                      <Button
                        sx={{
                          width: '40%',
                          background: colors[value.id],
                          color: '#ffffff',
                          borderRadius: 1,
                          border: 'none',
                          '&:hover': {
                            // transform: 'scale(1.02)',
                            border: 'none',
                          },
                        }}
                      >
                        {' '}
                        {value?.value}{' '}
                      </Button>
                    ) : (
                      <TextField
                        select
                        size="small"
                        value={value?.value}
                        sx={{
                          width: '35%',
                          background: colors[value?.id],
                          borderRadius: 1,
                          border: 'none',
                          '&:hover': {
                            // transform: 'scale(1.02)',
                            border: 'none',
                          },
                        }}
                      >
                        {options.map((optio) => (
                          <MenuItem
                            onClick={() => {
                              // setValue(optio)
                              // handleUpdateStatus(optio.id)
                              handlePopup(optio)
                            }}
                            disabled={
                              option.value === 'Completed' ||
                              user.user_type == 3
                                ? true
                                : false
                            }
                            key={optio.value}
                            value={optio.value}
                          >
                            {optio.value}
                          </MenuItem>
                        ))}
                      </TextField>
                    )} */}

                      {/* <TextField
                        select
                        size="small"
                        value={value?.value}
                        sx={{
                          width: '35%',
                          background: colors[value?.id],
                          borderRadius: 1,
                          border: 'none',
                          '&:hover': {
                            // transform: 'scale(1.02)',
                            border: 'none',
                          },
                        }}
                      >
                        {options.map((optio) => (
                          <MenuItem
                            onClick={() => {
                              setValue(optio)
                              handleUpdateStatus(optio.id)
                              // handlePopup(optio)
                            }}
                            disabled={
                              option.value === 'Completed' ||
                              user.user_type == 3
                                ? true
                                : false
                            }
                            key={optio.value}
                            value={optio.value}
                          >
                            {optio.value}
                          </MenuItem>
                        ))}
                      </TextField> */}
                    {/* {(value?.value == 3 || details?.hold == 1 || details?.design_order_job_status == 3) ? ( */}
                    {(value?.value == 3 || details?.hold == 1 || (details?.design_order_job_status == 3 && details?.status == 3)) ? (
                      <Button
                        sx={{
                          width: '40%',
                          background: colors[value.id],
                          color: '#ffffff',
                          borderRadius: 1,
                          border: 'none',
                          '&:hover': {
                            // transform: 'scale(1.02)',
                            border: 'none',
                          },
                        }}
                      >
                        {' '}
                        {value?.value}{' '}
                      </Button>
                    ) : (
                      <TextField
                        select
                        size="small"
                        value={value?.value}
                        sx={{
                          width: '35%',
                          background: colors[value?.id],
                          borderRadius: 1,
                          border: 'none',
                          '&:hover': {
                            // transform: 'scale(1.02)',
                            border: 'none',
                          },
                        }}
                      >
                        {options.map((optio) => (
                          <MenuItem
                            onClick={() => {
                              // setValue(optio)
                              // handleUpdateStatus(optio.id)
                              handlePopup(optio)
                            }}
                            disabled={
                              option.value === 'Completed' ||
                              user.user_type == 3
                                ? true
                                : false
                            }
                            key={optio.value}
                            value={optio.value}
                          >
                            {optio.value}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    
                    <ConfirmationPopup
                      open={openPopup}
                      handleClick={() => {
                        handleUpdateStatus(status)
                      }}
                      handleClose={() => {
                        setOpenPopup(!openPopup)
                      }}
                      message={`All tasks and subtasks will be moved to the "${status?.value}" state`}
                    />
                    <ConfirmationPopup
                    isComplete={true}
                    setCompleteStatus={setCompleteStatus}
                      open={openPopupComplete}
                      handleClick={() => {
                        handleUpdateStatus(status)
                      }}
                      handleClose={() => {
                        setOpenPopupComplete(!openPopupComplete)
                      }}
                      // message={`All tasks and subtasks have been moved to the ${status?.value} state`}
                    />
                    <ConfirmationPopup
                      open={openHoldPopup}
                      handleClick={() => {
                        handleUpdateHoldStatus()
                      }}
                      handleClose={() => {
                        setOpenHoldPopup(false)
                      }}
                      message={
                        data?.hold === 1
                          ? `You want to move this Job from Hold`
                          : `You want to move this Job to Hold`
                      }
                    />
                    <Stack direction={'row'} spacing={1}>
                      {loadingJobCost ? (
                        <Stack
                          flexDirection={'row'}
                          alignItems="center"
                          justifyContent="center"
                        >
                          {/* <Typography variant='subtitle2'>Loading Job Cost...</Typography> */}
                          <CircularProgress
                            color="primary"
                            variant="indeterminate"
                            size="20px"
                          />
                        </Stack>
                      ) : (
                        user.user_type !== 3 && (
                          <Button
                            disabled={
                              explodedPermissions?.some((permission) =>
                                ['Manage Design Order Job Cost'].includes(permission),
                              )
                                ? false
                                : true
                            }
                            onClick={() => {
                             
                                navigate(
                                  PATH_DASHBOARD.design.designOrderRecipeCost + '/' + id,
                                  { state: { currentTab: currentTab } },
                                )
                                // navigate(
                                //   PATH_DASHBOARD.project.designOrderRecipeCost + '/' + id,
                                //   { state: { currentTab: currentTab } },
                                // )
                              // }
                            }}
                            sx={{ borderRadius: 20 }}
                            variant="outlined"
                          >
                            Job Cost
                          </Button>
                        )
                      )}
                      <Tooltip
                        title={
                          details?.status === 0
                            ? 'Request has been deleted'
                            : ''
                        }
                      >
                        <Button
                          // onClick={() => setOpenInvoice(!openInvoice)}
                          onClick={() => {
                            details?.status == 0
                              ? ''
                              : navigate(
                                  `/dashboard/design-order/view/${details?.id}`,
                                )
                          }}
                          // disabled={details?.status === 0}
                          sx={{
                            borderRadius: 20,
                            background: details?.status == 0 ? '#ed2424' : '',
                            border: details?.status == 0 ? '#ed2424' : '',
                            color: details?.status == 0 ? '#ffff' : '',
                          }}
                          variant="outlined"
                        >
                          Order Details
                        </Button>
                      </Tooltip>
                      {/* {// secTab === 'Tasks'&& details?.job_category_name!='Service only' && /////////////Design
                      user.user_type !== 3 &&
                        currentTab != 'Installations' &&
                        secTab === 'Tasks' &&
                        currentTab != 'Completed' &&
                        currentTab != 'Hold' &&
                        details?.hold != 1 && (
                          <Button
                            onClick={() => {
                              setAdd(!add)
                            }}
                            disabled={user.user_type == 3 ? true : false}
                            sx={{ borderRadius: 20 }}
                            variant="contained"
                          >
                            Add Task
                          </Button>
                        )} */}
                      {/* && details?.job_category_name!='Service only' */}
                      {secTab === 'Members' &&
                        user.user_type !== 3 &&
                        currentTab != 'Completed' &&
                        currentTab != 'Hold' &&
                        details?.hold != 1 &&
                        details?.design_order_job_status != 3 && (
                          <Button
                            disabled={user.user_type == 3 ? true : 
                              // false
                              !explodedPermissions?.some((permission) => ['Add Member to Design Order Job'].includes(permission))
                            }
                            onClick={() => {
                              setAddMember(true)
                            }}
                            sx={{ borderRadius: 20 }}
                            variant="contained"
                          >
                            Add Member
                          </Button>
                        )}
                    </Stack>
                  </Stack>
                  <Stack sx={{ alignItems: 'center', mb: 2 }} spacing={2}>
                    <Tabs
                      value={secTab}
                      scrollButtons="auto"
                      variant="scrollable"
                      allowScrollButtonsMobile
                      onChange={handleTab}
                    >
                      {TABS.map((tab) => (
                        <Tab
                          disableRipple
                          key={tab.value}
                          label={
                            <Typography variant="overline">
                              {capitalCase(tab.value)}
                            </Typography>
                          }
                          icon={tab.icon}
                          value={tab.value}
                        />
                      ))}
                    </Tabs>

                    {TABS.map((tab) => {
                      const isMatched = tab.value === secTab

                      return (
                        isMatched && (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              ...(secTab === 'Attachments'
                                ? { width: '80%' }
                                : secTab === 'Locations'
                                ? { width: '100%' }
                                : { width: '100%' }),
                            }}
                            key={tab.value}
                          >
                            {tab.component}
                          </Box>
                        )
                      )
                    })}
                  </Stack>
                </Stack>
              </Grid>
              <Grid key={2} item xs={12} md={4}>
                <Card sx={{ minHeight: 100 }}>
                  <Stack width={'100%'}>
                    {/* <Box component={'image'}>
                <img
                  src={
                    data?.cover_image && data?.cover_image !=='DefaultThumbnailURL' ? data?.cover_image : 'https://t3.ftcdn.net/jpg/04/60/44/08/360_F_460440876_a8lkfz8UHz64eDPFzmSJJZ55UQBNiC0a.jpg'
                  }
                  alt="img"
                />
              </Box> */}
                    <Slider {...settings}>
                      {designs
                        .filter(
                          (row) =>
                            details.status == 2 &&
                            (row?.file_type?.startsWith('image') ||
                              row.file_type == 'img'),
                        )
                        ?.map((item) => (
                          <Box sx={{ position: 'relative' }}>
                            <CarouselImgStyle src={item.name} />
                            {/* <Box
                        sx={{
                          top: 0,
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                          bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
                        }}
                      /> */}
                            <CardContent
                              sx={{
                                left: 0,
                                bottom: -40,
                                maxWidth: '80%',
                                textAlign: 'left',
                                position: 'absolute',
                                color: 'common.white',
                              }}
                            >
                              <Typography
                                noWrap
                                variant="h5"
                                sx={{ mt: 1, mb: 3 }}
                              >
                                Approved Designs
                              </Typography>
                            </CardContent>
                          </Box>
                        ))}
                    </Slider>
                    <Box>
                      <Typography
                        variant="overline"
                        sx={{
                          mt: 3,
                          ml: 2,
                          display: 'block',
                          color: 'text.secondary',
                        }}
                      >
                        Project Completion:
                        {singleProjectData?.result?.completion}%
                      </Typography>

                      {/* {singleProjectData && <Sliderw
                    aria-label="Percentage"
                    defaultValue={singleProjectData.result.completion}
                    getAriaValueText={valuetext}
                    onChange={updateStatus}
                    valueLabelDisplay="auto"
                    //  step={10}
                    marks
                    min={10}
                    max={100}
                  />} */}
                    </Box>
                    <Box
                      sx={{
                        my: 2,
                        mx: 3,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Stack width={'100%'} spacing={3}>
                        <Stack
                          direction={'row'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                        >
                          <Typography variant="h4">{data?.name}</Typography>
                          {/* {currentTab != 'Installations' &&
                            currentTab != 'Completed' &&
                            currentTab != 'Hold' &&
                            details?.hold != 1 &&
                            (details?.job_category_name != 'Service only' ||
                              data?.status !== 3) && (
                              <>
                                {' '}
                                <MoreVert
                                  ref={ref}
                                  sx={{ width: 25 }}
                                  onClick={() => setIsOpen(true)}
                                />
                                <Menu
                                  open={isOpen}
                                  anchorEl={ref.current}
                                  onClose={() => setIsOpen(false)}
                                  PaperProps={{
                                    sx: { width: 200, maxWidth: '100%' },
                                  }}
                                  anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                >
                                  {details?.job_category_name !=
                                    'Service only' && (
                                    <MenuItem
                                      onClick={() => setAdd(true)}
                                      disabled={
                                        user.user_type == 3 ? true : false
                                      }
                                    >
                                      <ListItemText
                                        primary="Add Task"
                                        primaryTypographyProps={{
                                          variant: 'body2',
                                        }}
                                      />
                                    </MenuItem>
                                  )}

                                  {data?.status !== 3 && (
                                    <MenuItem
                                      // component={RouterLink}
                                      // to={`${PATH_DASHBOARD.user.root}/${paramCase(userName)}/edit`}
                                      disabled={
                                        explodedPermissions?.some(
                                          (permission) =>
                                            ['Edit Jobs'].includes(permission),
                                        )
                                          ? false
                                          : true
                                      }
                                      onClick={() => {
                                        setIsEdit(true)
                                      }}
                                      // sx={{ color: 'text.secondary' }}
                                    >
                                      <ListItemText
                                        primary="Edit Job"
                                        primaryTypographyProps={{
                                          variant: 'body2',
                                        }}
                                      />
                                    </MenuItem>
                                  )}
                                </Menu>
                              </>
                            )} */}
                        </Stack>
                        <Typography variant="caption">
                          {data?.description}
                        </Typography>
                        <Stack
                          display={'flex'}
                          direction={'row'}
                          justifyContent={'space-between'}
                        >
                          <Grid container>
                            <Grid item xs={2}>
                              <Stack spacing={1.7}>
                                <Groups sx={{ width: 17 }} />
                                <Attachment sx={{ width: 15 }} />
                                <CheckCircle sx={{ width: 15 }} />
                                <Error sx={{ width: 17 }} />
                              </Stack>
                            </Grid>
                            <Grid item xs={8}>
                              <Stack spacing={2}>
                                <Typography
                                  variant="subtitle2"
                                  onClick={() => setSecTab('Members')}
                                  style={{ cursor: 'pointer' }}
                                >
                                  Members
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  onClick={() => setSecTab('Attachments')}
                                  style={{ cursor: 'pointer' }}
                                >
                                  Attachment
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  onClick={() => setSecTab('Tasks')}
                                  style={{ cursor: 'pointer' }}
                                >
                                  Completed Tasks
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  onClick={() => setSecTab('Tasks')}
                                  style={{ cursor: 'pointer' }}
                                >
                                  Pending Tasks
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={2}>
                              <Stack spacing={2}>
                                <Typography
                                  textAlign={'right'}
                                  variant="subtitle2"
                                  onClick={() => setSecTab('Members')}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {data?.member_count}
                                </Typography>
                                <Typography
                                  textAlign={'right'}
                                  variant="subtitle2"
                                  onClick={() => setSecTab('Attachments')}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {data?.attachment_count}
                                </Typography>
                                <Typography
                                  textAlign={'right'}
                                  variant="subtitle2"
                                  onClick={() => setSecTab('Tasks')}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {data?.completed_task_count}
                                </Typography>
                                <Typography
                                  textAlign={'right'}
                                  variant="subtitle2"
                                  onClick={() => setSecTab('Tasks')}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {data?.pending_task_count}
                                </Typography>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Stack>
                      </Stack>
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          ) : (
            <>
              <HeaderBreadcrumbs heading={'Edit Job'} links={''} action={''} />
              <AddProject
                singleProjectData={singleProjectData}
                setRenderCards={setRenderCards}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                setAdd={setAdd}
              />
            </>
          )}
          <Modal
            modalTitle={'Sales Order Details'}
            open={openInvoice === true}
            handleClose={() => setOpenInvoice(false)}
          >
            <Stack
              direction={{ xs: 'column', sm: 'row', md: 'column' }}
              spacing={{ xs: 3, sm: 2 }}
            >
              <Stack
                direction="row"
                justifyContent={'flex-end'}
                alignItems="center"
                spacing={1}
              >
                <ReactToPrint
                  trigger={() => (
                    <Button>
                      {' '}
                      <Print />
                      Print
                    </Button>
                  )}
                  content={() => componentRef.current}
                  copyStyles={false}
                  pageStyle={`
               @media print {
                 body {
                   font-size: 12pt
                 }
                 table {
                   width: 100%;
                   border-collapse: collapse;
                   margin-bottom: 10px;
                  
                 }
     
                 th, td {
                   border: 1px solid #ddd;
                   padding: 8px;
                   text-align: left;
                 
                 }
               }
             `}
                />
                <div style={{ display: 'none' }}>
                  <PrintFunction
                    details={details}
                    invoice={invoice}
                    data1={data1}
                    ref={componentRef}
                  />
                </div>
                {/* <Button><Print />Print</Button> */}
              </Stack>
            </Stack>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
                <Typography variant="body2">{COMPANY_NAME}</Typography>
                <Typography variant="body2">www.{COMPANY_NAME}.com</Typography>
                <Typography variant="body2">
                  {data1?.address}, {data1?.city}, {data1?.state},{' '}
                  {data1?.zipcode}
                </Typography>
                <Typography variant="body2">Telephone: +15208362947</Typography>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
                <Typography variant="body2">
                  Order date:{' '}
                  {moment.unix(details?.timestamp).format('DD/MM/YYYY')}
                </Typography>
                {/* <Stack sx={{display:'flex',flexDirection:'row'}}> */}
                {/* <Typography variant="body2">Order status:</Typography> */}
                <Label
                  variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                  color={getStatusColorAndLabel(details?.status).color}
                >
                  {getStatusColorAndLabel(details?.status).label}
                </Label>
                {/* </Stack> */}
                <Typography variant="body2">Order ID: {details?.id}</Typography>
                <Typography variant="body2">
                  Job Name: {details?.name}
                </Typography>
                <Typography variant="body2">
                  Client Name: {customerData?.customer_name}
                </Typography>
                <Typography variant="body2">
                  Phone:{' '}
                  {customerData?.customer_phone &&
                    '+' + customerData?.customer_phone}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 420 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>PRODUCT</TableCell>
                        <TableCell>UNIT PRICE</TableCell>
                        <TableCell>QTY</TableCell>
                        <TableCell>DISCOUNT</TableCell>
                        <TableCell>SUB TOTAL</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoice?.cust_product?.map((val) => {
                        const {
                          vid,
                          product_name,
                          product_price,
                          quantity,
                          product_total,
                          discount,
                        } = val
                        return (
                          <TableRow
                            key={vid}
                            sx={{
                              borderBottom: (theme) =>
                                `solid 1px ${theme.palette.divider}`,
                            }}
                          >
                            <TableCell>{product_name}</TableCell>
                            <TableCell>{product_price}</TableCell>
                            <TableCell>{quantity}</TableCell>
                            <TableCell>{discount}</TableCell>
                            <TableCell>{product_total}</TableCell>
                          </TableRow>
                        )
                      })}

                      <RowResultStyle>
                        <TableCell colSpan={3} />
                        <TableCell align="center">
                          <Typography variant="overline">Subtotal</Typography>
                        </TableCell>
                        <TableCell align="center" width={10}>
                          <Typography variant="overline">
                            {invoice?.sub_total}
                          </Typography>
                        </TableCell>
                      </RowResultStyle>
                      <RowResultStyle>
                        <TableCell colSpan={3} />
                        <TableCell align="center">
                          <Typography variant="overline">
                            Extra Discount
                          </Typography>
                        </TableCell>
                        <TableCell align="center" width={10}>
                          <Typography variant="overline">
                            {invoice?.extra_discount}
                          </Typography>
                        </TableCell>
                      </RowResultStyle>
                      {/* <RowResultStyle>
                    <TableCell colSpan={3} />
                    <TableCell align="center">
                      <Typography variant="overline">Total Discount</Typography>
                    </TableCell>
                    <TableCell align="center" width={10}>
                      <Typography variant="overline">$0</Typography>
                    </TableCell>
                    
                  </RowResultStyle> */}
                      <RowResultStyle>
                        <TableCell colSpan={3} />
                        <TableCell align="center">
                          <Typography variant="subtitle1">Total</Typography>
                        </TableCell>
                        <TableCell align="center" width={10}>
                          <Typography variant="subtitle1">
                            {invoice?.total}
                          </Typography>
                        </TableCell>
                      </RowResultStyle>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              {/* </Card>   */}
            </Grid>
          </Modal>
          {/* this modal represent adding of attachments */}
          <Modal
            modalTitle={'Add Designs'}
            open={openAttachModal}
            handleClose={() => setOpenAttachModal(false)}
          >
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'flex-start'}
              spacing={{ xs: 2, sm: 3 }}
              mb={2}
            >
              <LoadingButton
                variant="outlined"
                size="small"
                sx={{ height: 40 }}
                onClick={() => fileInputRef.current.click()}
                loading={progress}
              >
                <UploadFileSharp /> Upload
              </LoadingButton>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
                multiple
              />
            </Stack>
            <Box>
              <Scrollbar sx={{ width: '100%', height: 250 }}>
                <ImageList gap={8} cols={mdd ? 1 : xld ? 3 : 2}>
                  {attachments?.video &&
                    attachments.video.map((videoUrl, index) => (
                      <ImageListItem key={index}>
                        <ImageListItemBar
                          actionIcon={
                            <UserMoreMenu
                              onDelete={() =>
                                RemoveAttachFromSubmit('video', videoUrl)
                              }
                              noEdit
                            />
                          }
                        />
                        <video width="300" height="300" controls>
                          <source
                            src={`${videoUrl}?w=300&h=300&fit=crop&auto=format`}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      </ImageListItem>
                    ))}
                  {attachments?.other &&
                    attachments?.other.map((url, index) => (
                      <Card
                        key={index}
                        sx={{ p: 3, maxWidth: 368, mx: 'auto' }}
                      >
                        <embed
                          src={url}
                          type="application/pdf"
                          width="300"
                          height="300px"
                        />
                        <UserMoreMenu
                          onDelete={() => RemoveAttachFromSubmit('other', url)}
                          noEdit
                        />
                      </Card>
                    ))}
                  {attachments?.image &&
                    attachments.image.map((imageUrl, index) => (
                      <ImageListItem key={index}>
                        <img
                          srcSet={`${imageUrl}?w=248&fit=crop&auto=format&dpr=2 2x`}
                          src={`${imageUrl}?w=248&fit=crop&auto=format`}
                          loading="lazy"
                        />
                        <ImageListItemBar
                          actionIcon={
                            <UserMoreMenu
                              onDelete={() =>
                                RemoveAttachFromSubmit('image', imageUrl)
                              }
                              noEdit
                            />
                          }
                        />
                      </ImageListItem>
                    ))}
                </ImageList>
              </Scrollbar>
            </Box>
            <Box
              sx={{
                mt: 3,
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 1,
              }}
            >
              <LoadingButton
                onClick={() => handleSubmitAttachments()}
                variant="contained"
                size="small"
              >
                Submit
              </LoadingButton>
              <Button
                variant="outlined"
                onClick={() => {
                  setAttachments({}), setOpenAttachModal(false)
                }}
                size="small"
              >
                Cancel
              </Button>
            </Box>
          </Modal>
          <AddTask
            coverImg={designs.length ? designs[0].name : ''}
            projectMembers={projectMembers}
            projectId={Number(id)}
            title={'Add Task'}
            add={add}
            setIsOpen={setIsOpen}
            setAdd={setAdd}
            onRefresh={() => {
              setRefresh(!refresh)
            }}
            designOrder={true}
          />
          <AddMember
            designOrder={true}
            allTeamMembers={allTeamMembers}
            projectId={Number(id)}
            prevProjectMembers={projectMembers}
            add={addMember}
            setIsOpen={setIsOpen}
            setAdd={setAddMember}
            onRefresh={() => {
              setRefresh(!refresh)
            }}
          />
        </Container>
      )}
    </>
  )
}

const PrintFunction = forwardRef(({ details, invoice, data1 }, ref) => {
  const theme = useTheme()
  return (
    <Stack ref={ref}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
          <Typography variant="body2">{COMPANY_NAME}</Typography>
          <Typography variant="body2">www.{COMPANY_NAME}.com</Typography>
          <Typography variant="body2">
            {data1?.address}, {data1?.city}, {data1?.state}, {data1?.zipcode}
          </Typography>
          <Typography variant="body2">Telephone: +15208362947</Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
          <Typography variant="body2">
            Order date: {moment.unix(details?.timestamp).format('DD/MM/YYYY')}
          </Typography>
          {/* <Stack sx={{display:'flex',flexDirection:'row'}}> */}
          {/* <Typography variant="body2">Order status:</Typography> */}
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={getStatusColorAndLabel(details?.status).color}
          >
            {getStatusColorAndLabel(details?.status).label}
          </Label>
          {/* </Stack> */}
          <Typography variant="body2">Order ID:{details?.id}</Typography>
          <Typography
            paragraph
            variant="overline"
            sx={{ color: 'text.disabled', marginTop: '20px' }}
          >
            TO {invoice?.customer_name}
          </Typography>
          <Typography variant="body2">
            Phone:{invoice?.customer_phone}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 420 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>PRODUCT</TableCell>
                  <TableCell>UNIT PRICE</TableCell>
                  <TableCell>QTY</TableCell>
                  <TableCell>DISCOUNT</TableCell>
                  <TableCell>SUB TOTAL</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoice?.cust_product?.map((val) => {
                  const {
                    vid,
                    product_name,
                    product_price,
                    quantity,
                    product_total,
                    discount,
                  } = val
                  return (
                    <TableRow
                      key={vid}
                      sx={{
                        borderBottom: (theme) =>
                          `solid 1px ${theme.palette.divider}`,
                      }}
                    >
                      <TableCell>{product_name}</TableCell>
                      <TableCell>{product_price}</TableCell>
                      <TableCell>{quantity}</TableCell>
                      <TableCell>{discount}</TableCell>
                      <TableCell>{product_total}</TableCell>
                    </TableRow>
                  )
                })}

                <RowResultStyle>
                  <TableCell colSpan={3} />
                  <TableCell align="center">
                    <Typography variant="overline">Subtotal</Typography>
                  </TableCell>
                  <TableCell align="center" width={10}>
                    <Typography variant="overline">
                      {invoice?.sub_total}
                    </Typography>
                  </TableCell>
                </RowResultStyle>
                <RowResultStyle>
                  <TableCell colSpan={3} />
                  <TableCell align="center">
                    <Typography variant="overline">Extra Discount</Typography>
                  </TableCell>
                  <TableCell align="center" width={10}>
                    <Typography variant="overline">
                      {invoice?.extra_discount}
                    </Typography>
                  </TableCell>
                </RowResultStyle>
                {/* <RowResultStyle>
                  <TableCell colSpan={3} />
                  <TableCell align="center">
                    <Typography variant="overline">Total Discount</Typography>
                  </TableCell>
                  <TableCell align="center" width={10}>
                    <Typography variant="overline">$0</Typography>
                  </TableCell>
                </RowResultStyle> */}
                <RowResultStyle>
                  <TableCell colSpan={3} />
                  <TableCell align="center">
                    <Typography variant="subtitle1">Total</Typography>
                  </TableCell>
                  <TableCell align="center" width={10}>
                    <Typography variant="subtitle1">
                      {invoice?.total}
                    </Typography>
                  </TableCell>
                </RowResultStyle>
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
        {/* </Card>   */}
      </Grid>
    </Stack>
  )
})
