// material
import { experimentalStyled as styled } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import {
  Box,
  Card,
  Grid,
  Typography,
  Stack,
  Avatar,
  TextField,
  Button,
  Divider,
  OutlinedInput,
  InputAdornment,
  Icon,
  FormHelperText,
} from '@material-ui/core'
import roundSend from '@iconify/icons-ic/round-send'

import { Attachment, Person, Collections, TextsmsRounded, CommentOutlined,Favorite,FavoriteBorder, Star,MoreHoriz, Delete } from '@material-ui/icons'
//
import { useNavigate } from 'react-router'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { useState,useRef, } from 'react'
import MyAvatar from 'src/components/MyAvatar'
import IconButton from 'src/theme/overrides/IconButton'
import EmojiPicker from 'src/components/EmojiPicker'
import { QuillEditor } from 'src/components/editor/index.js';
import { MIconButton } from 'src/components/@material-extend'
import Modal from 'src/components/_dashboard/blog/ProjectModel'
import { Form, FormikProvider,useFormik } from 'formik'
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab'
import { UserMoreMenu } from 'src/components/_dashboard/user/list'
import axios from 'axios'
import { REST_API, REST_API_END_POINT } from 'src/constants/Defaultvalues'
import { useSnackbar } from 'notistack'
import { filter } from 'lodash'
import moment from 'moment'
import { getAgoString } from 'src/common/Helper'
import Scrollbar from 'src/components/Scrollbar'
import  {keyframes}  from '@emotion/react';

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const img =
  'https://www.shutterstock.com/image-photo/indianapolis-circa-february-2017-pizza-260nw-572078815.jpg'

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: '100%', boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}))

const messageStyle = {
  display: 'inline-block',
  padding: '5px 10px',
  borderRadius: '8px',
  backgroundColor: '#172e34',
  color: '#919eab',
  marginBottom: '25px',
  wordWrap: 'break-word',
  maxWidth: '60%',
};
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
  }
  
  function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
  }
  const removeHtmltags=(data)=>{
    return data.replace(/<\/?[^>]+(>|$)/g, "")
  }
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => removeHtmltags(_user.message).toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis?.map((el) => el[0]);
  }
export default function Posts({taskId, projectId,projectPosts,setRefresh,refresh,currentTab}) {
  const user = JSON.parse(window.localStorage.getItem('user'))
  const theme = useTheme()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [message, setMessage] = useState('')
  const [filterName, setFilterName] = useState('');
  const [liked, setLiked] = useState(false);
  const [like, setLike] = useState(false);
  const [stared, setStared] = useState(false);
  const [initialPost,setInitialPost]= useState([])
  const [isEdit,setIsEdit] = useState(false)
  const [postId,setPostId] = useState(null)
  const NewUserSchema = Yup.object().shape({
    description:Yup.mixed().required('Please write something...'),
   });



  const [add,setAdd] = useState(false)
 const handleEditPost=async(postId)=>{
  try {   
     const res = await axios.post(`${REST_API_END_POINT}project-task/post-by-id`,{postId})
     console.log(res.data,'------------res')
     setIsEdit(true)
     setPostId(postId)
     setInitialPost(...res.data.data)
  } catch (error) {
    console.log(error)
  }
 }
 console.log(initialPost,'-----------initialPost')
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: isEdit ? initialPost.message:'',
    },
    validationSchema: NewUserSchema,
  
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try { 
           const data = { postId, values, userId:user.user_id,taskId,projectId}           
          let res =  await axios.post(`${REST_API}webservice/project-task/${ isEdit ?"edit-post":"add-new-post"}`, data)         
        if(res.data.status ===1){
          enqueueSnackbar(res.data.message , { variant: 'success' });
          resetForm()
          setRefresh(!refresh)
        }else{
          enqueueSnackbar(res.data.message , { variant: 'error' });
        }
        setAdd(false)
      } catch (error) {
        console.error(error);

      }
    }
  })
  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  const handleLikeClick = (postId) => {
  
     axios.post(`${REST_API_END_POINT}project-task/post-like`,{
      postId,userId:user.user_id
     }).then((response)=>{
      
     }).catch((error)=>{
      console.log(error,'error in liked ')
     })
     setRefresh(!refresh)
  };


  const handleCommentLikeClick=async(id)=>{
    try {
      const res = await axios.post(`${REST_API_END_POINT}project-task/post-comment-like`,{
        userId:user.user_id,commentId:id
      })
      if(res.data.status ===1){
        setRefresh(!refresh)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleDeleteComment=async(id)=>{
    try {
      const res = await axios.post(`${REST_API_END_POINT}project-task/post-comment-delete`,
        { commentId:id, userId:user.user_id }
      )
      console.log(res.data)
      setRefresh(!refresh)
    } catch (error) {
      console.log(error)
    }
  }

  const handleStarClick = async(postId) => {
   
     axios.post(`${REST_API_END_POINT}project-task/post-starred`,{
      postId,userId:user.user_id
     }).then((response)=>{
       
     }).catch((error)=>{
      console.log(error,'error in starred ')
     })
     setRefresh(!refresh)
  };
const handleDeletePost=async(postId)=>{
  try {
     const res = await axios.post(`${REST_API_END_POINT}project-task/delete-post`,{
      postId,userId:user.user_id
     })
   if(res.data.status===1){
      setRefresh(!refresh)
   }
 } catch (error) {
    console.log(error)
  }
}
console.log(message,'---------msg')
 const handleSendPostComments=async(index,postId)=>{
  try {
    let sendMsg = message[index]
   
   const res = await axios.post(`${REST_API_END_POINT}project-task/add-comments`,{
     sendMsg,userId:user.user_id,postId,comment_id:''
    })
  console.log(res.data)
  const updatedMessage = [...message];
  updatedMessage[index]=''
  setMessage(updatedMessage)
   setRefresh(!refresh)
  } catch (error) {
    console.log(error)
  }
 }

 const handleChangeMessage = (index, value) => {
  const newMessageStates = [...message];
  newMessageStates[index] = value;
  setMessage(newMessageStates);
};

  const handleFilterByName = (event) => {
    setFilterName(event.target.value)
  }
  const filteredPosts = applySortFilter(projectPosts, getComparator("desc", "id"), filterName);
   console.log(filteredPosts,'-----------fffff')
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={10}>
        <Card
          sx={{
            transition: 'transform 0.2s',
            '&:hover': {
              transform: 'scale(1.02)',
            },
          }}
        >

          <Stack>
            {user.user_type ===1 &&
            <Stack direction={'row'} spacing={3} alignItems={'center'} p={2}
           >
              <Avatar
                 alt={user.first_name}
                 src={user.profile_pic}
                sx={{
                  width: 44,
                  height: 44,
                }}
              />
              {(currentTab!="Installations" && currentTab!="Completed" && currentTab!="Hold") &&<Button variant='outlined'  
              onClick={() => {
              setAdd(!add);
             }} fullWidth sx={{borderRadius:30}}>Whats On Your Mind ?</Button>}
              
            </Stack> }

             {/* modal is used for adding a new post  */}
            <Modal
            open={add}
            handleClose={() => {
                setAdd(false);
            }}
            modalTitle={'Create A Post'}
            >
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} md={10}>
                <Stack direction={'row'} spacing={3} alignItems={'center'} p={2}>
                <Avatar
                  alt={user.first_name}
                  src={user.profile_pic}
                  sx={{
                    width: 60,
                    height: 60,
                  }}
                />
                <Stack direction={'column'}>
                  <Typography variant="subtitle2">{user.first_name+" "+user.last_name}</Typography>
                  <Typography sx={{ color: '#919eab', fontSize: '0.8rem' }} variant="subtitle1">{user.user_role}</Typography>
                </Stack>
              </Stack>
              <Stack spacing={2} mt={3}>                                
                    <QuillEditor
                      simple
                      id="product-description"
                      value={values.description}
                      onChange={(val) => setFieldValue('description', val)}
                      error={Boolean(touched.description && errors.description)}
                    />
                    {touched.description && errors.description && (
                      <FormHelperText error sx={{ px: 2 }}>
                        {touched.description && errors.description}
                      </FormHelperText>
                    )}                             
                </Stack>             
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 1 }}>
                    <LoadingButton type="submit" fullWidth sx={{borderRadius:30}} variant="contained"  loading={isSubmitting}>
                       Post 
                    </LoadingButton>                    
                  </Box>
                </Grid>
              </Grid> 
              </Form>  
              </FormikProvider>     
            </Modal>
           
          </Stack>
        </Card>
      </Grid>
      
      {/* <Grid item xs={12} md={4}>
        <Card
          sx={{
            transition: 'transform 0.2s',
            '&:hover': {
              transform: 'scale(1.02)',
            },
          }}
          onClick={() => {
            // navigate(PATH_DASHBOARD.project.viewTask)
          }}
        >
          <Stack>
            <Box
              width={'50%'}
              justifyContent={'flex-start'}
              alignItems={'center'}
              p={2}
            >
              <Typography variant="subtitle2">{'About'}</Typography>
            </Box>
            <Box px={2}>
              <Divider />
            </Box>
            <Box width={'12rem'} p={2}>
              <Typography variant="overline">xvxvxcv</Typography>
            </Box>
          </Stack>
        </Card>
      </Grid> */}
      <Grid item xs={12} md={10}>
        <Card
          sx={{
            transition: 'transform 0.2s',
            '&:hover': {
              transform: 'scale(1.02)',
            },
          }}
          onClick={() => {
            // navigate(PATH_DASHBOARD.project.viewTask)
          }}
        >
          {user.user_type===1 &&
          <Stack>
            <Stack direction={'row'} spacing={3} alignItems={'center'} p={2}>
              <Typography variant="subtitle2">{'Search Posts'}</Typography>
            </Stack>
            <Box px={2}>
              <Divider />
            </Box>
            <Stack direction={'row'} spacing={2} px={2} pb={2}>
              <Box
                sx={{
                  mt: 3,
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <SearchStyle
                  value={filterName}
                  size="small"
                  onChange={handleFilterByName}
                  placeholder="Search "                
                />
              </Box>
            </Stack>
          </Stack>}
        </Card>
      </Grid>

      {filteredPosts.map((item, index) => (
        <Grid key={index} item xs={12} md={10}>
          <Card
            sx={{
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'scale(1.02)',
              },
            }}          
          >
            <Stack>
            <Stack direction={'row'} spacing={3} alignItems={'center'} p={2}>
              <Avatar
                 alt={user.first_name}
                 src={user.profile_pic}
                sx={{
                  width: 60,
                  height: 60,
                }}
              />
              <Grid container alignItems="center" sx={{display:'flex',justifyContent:'space-between'}} spacing={1}>
                <Grid item>
                  <Stack direction={'column'}>
                    <Typography variant="subtitle2">{item.username}</Typography>
                    <Typography sx={{ color: '#919eab', fontSize: '0.8rem' }} variant="subtitle1">
                      {getAgoString(item?.timestamp)}
                      </Typography>
                  </Stack>
                </Grid>               
                <Grid item sx={{display:'flex',justifyContent:'flex-end'}}>   
                  <MIconButton>
                    <Star onClick={()=>handleStarClick(item.id)} sx={{ color: item.starred!==0 ? '#FFD93D' : '#919eab',}} />
                  </MIconButton>  
                  { user.user_type === item?.added_by &&          
                    <UserMoreMenu onEdit={()=>{
                      setAdd(true)
                      handleEditPost(item.id)
                    }} onDelete={()=>handleDeletePost(item.id)} 
                    />  
                     }               
                </Grid>
              </Grid>
            </Stack>

              <Stack direction={'column'} alignItems={'left'} pl={3}>
                <Typography
                 variant="subtitle2"
                 dangerouslySetInnerHTML={{ __html:item.message}}
                 />
              </Stack>
              <Stack direction={'row'} alignItems={'left'} mt={2} pl={3} mb={2} spacing={4}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Favorite onClick={()=>handleLikeClick(item.id)} sx={{color:item.liked!==0?'#f77ea9':'',cursor:'pointer'}} />
                  <Typography sx={{color:'white'}} variant="subtitle2">{item.likes_count}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <TextsmsRounded sx={{color:'#1ccaff'}} />
                  <Typography sx={{color:'white'}} variant="subtitle2">{item?.comments_count}</Typography>
                </Stack>
              </Stack>
              <Divider/>
              <Box>
                <Scrollbar sx={{ width: '100%',height: 230}}>
                  {item?.comments.length>0 && item?.comments.map((data)=>{
                     console.log(data,'=================datat')
                    return(
                      <>
                      <Stack key={data.id} direction={'row'} spacing={3} alignItems={'center'} p={2}>
                      <Avatar
                        alt={data.username}
                        src={data.profile_pic}
                        sx={{
                          width: 40,
                          mt:-12,
                          height: 40,
                        }}
                      />
                      <Stack direction={'row'}>
                      <Box
                      sx={{
                        ...messageStyle,                  
                        marginLeft: -1,       
                        minWidth: '140%',
                      }}
                      >
                        <Stack direction={'column'}>
                        <Typography variant="subtitle2" 
                        sx={{
                          color: 'white',
                          textAlign: 'left',
                        }}>{data.
                          username}</Typography>
                        <Typography sx={{ mt:2 }} variant="subtitle1">{data.message}</Typography>
                        <Typography sx={{ mt:2,display:'flex',justifyContent:'flex-end',color:theme.palette.common.white }} variant="caption">{getAgoString(data.timestamp)}</Typography>
                      </Stack>
                      </Box>              
                      </Stack>
                    </Stack>
                     
                    <Stack direction={'row'} alignItems={'left'} mt={-5} pl={8} mb={2} spacing={3}>
                        <MIconButton 
                         onClick={()=>handleCommentLikeClick(data.id)}
                         sx={{ color: data.liked!==0 ? '#f77ea9' : '#919eab',}}>
                          <Favorite  />
                          <Typography onClick={()=>handleCommentLikeClick(data.id)} 
                          variant="subtitle2"  sx={{ marginLeft: '5px' }}>{data.likes_count} Like</Typography>
                        </MIconButton>
                        {
                        user.user_type === data.added_by && 
                        <MIconButton onClick={()=>handleDeleteComment(data.id)}>
                          <Delete />
                          <Typography  variant="subtitle2" sx={{ marginLeft: '5px' }}>Delete</Typography>
                        </MIconButton>
                           }
                    </Stack> 
                    </>
                  )}) 
                 }                     
                </Scrollbar>
                </Box>

              <Stack direction={'row'} spacing={2} px={2} pb={2}>
                <Box
                  sx={{
                    mt: 3,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Avatar
                    alt={'AVATAR'}
                    src={item.img}
                    sx={{
                      width: 40,
                      height: 40,
                      mr: 2
                    }}
                  />
                   <SearchStyle
                  value={message && message[index]}
                  size="small"
                  onChange={(e) => handleChangeMessage(index, e.target.value)}
                  placeholder="Write a Message…"
                  startAdornment={
                    <InputAdornment position="start">
                      <Box sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  }
                    endAdornment={
                      <InputAdornment position="end">
                        {/* Assuming EmojiPicker and Button are properly implemented */}
                        <EmojiPicker
                          alignRight
                          value={message && message[index]}                        
                          setValue={(event)=>{                          
                             handleChangeMessage(index, event)
                          } }
                        />
                        <Button onClick={()=>handleSendPostComments(index,item.id)} sx={{ ml: 1 }} size="small" variant="contained">
                          Post
                        </Button>
                      </InputAdornment>
                    }
                  />
                </Box>
              </Stack>
            </Stack>
          </Card>
        </Grid>
      ))}
      {/* <Grid item xs={12} md={4}>
        <Card
          sx={{
            transition: 'transform 0.2s',
            '&:hover': {
              transform: 'scale(1.02)',
            },
          }}
          onClick={() => {
            // navigate(PATH_DASHBOARD.project.viewTask)
          }}
        >
          <Stack>
            <Stack
              justifyContent={'space-between'}
              alignItems={'center'}
              p={2}
              direction={'row'}
            >
              <Typography variant="subtitle2">{'Attachments'}</Typography>
              <Typography variant="caption">{'Show all'}</Typography>
            </Stack>
            <Box px={2}>
              <Divider />
            </Box>
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              p={3}
            >
              <Typography>No images found</Typography>
            </Box>
          </Stack>
        </Card>
      </Grid> */}
    </Grid>
  )
}
