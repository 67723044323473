import { capitalCase, sentenceCase } from 'change-case'
import { useState, useEffect, useRef } from 'react'
import { useSnackbar } from 'notistack';
// material
import {
  Container,
  Tab,
  Box,
  Tabs,
  Stack,
  Typography,
  MenuItem,
  TextField,
  Card,
  Grid,
  Divider,
  CircularProgress,
  AvatarGroup,
  Tooltip,
  Avatar,
} from '@material-ui/core'
import { useTheme, experimentalStyled as styled } from '@material-ui/core/styles'
// components
import Posts from '../../components/projects/viewProjects/ViewTasks/Posts'
import Attachments from '../../components/projects/viewProjects/ViewTasks/Attachments'
import SubTasks from '../../components/projects/viewProjects/ViewTasks/SubTasks'
import Activities from '../../components/projects/viewProjects/ViewTasks/Activities'
import { useLocation, useNavigate, useParams } from 'react-router'
import axios from 'axios'
import { REST_API_END_POINT } from 'src/constants/Defaultvalues'
import { UserMoreMenu } from 'src/components/_dashboard/user/list'
import AddTask from 'src/components/projects/AddTask'
import ConfirmationPopup from 'src/components/confirmationPopup';
import { PATH_DASHBOARD } from 'src/routes/paths';
import {Button} from '@material-ui/core';
import { KeyboardBackspaceOutlined, Login, Logout, TryOutlined } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom'
import { truncate } from 'lodash';
import LoadingScreen from 'src/components/LoadingScreen';
import Label from 'src/components/Label';


// ----------------------------------------------------------------------

let option = [
  { id: 1, color:'primary', value: 'New' },
  { id: 2, color:'info', value: 'In Progress' },
  { id: 3, color:'success', value: 'Completed' },
  { id: 4, color:'warning', value: 'In Testing' },
  { id: 5, color:'error', value: 'Canceled' },
  { id: 6, color:'success', value: 'Approved by Dev team' },
  { id: 7, color:'success', value: 'Approved by the client' },
  { id: 8, color:'primary', value: 'Reopen' },
]
const colors = [
  '#c9302c',
  '#5bc0de',
  '#008000',
  '#5cb85c',
  '#f0ad4e',
  '#c9302c',
  '#5cb85c',
  '#5cb85c',
  '#5bc0de',
]

const CoverImgStyle = styled('img')({
  top: 0,
  zIndex: 8,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
})

export default function ViewTask({popupOn,postId,projectsId,taskCurrentTab,taskDetails,handleBackToAlljobs,onDataChange}) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate()
  const { id:paramsId, projectId:paramsProjectId } = useParams();
  const id = popupOn? postId:paramsId;
  const projectId = popupOn? projectsId:paramsProjectId;

  const theme = useTheme()
  const ref = useRef(null)
  const designs =useLocation().state?.design || null
   const currentTab=popupOn ? taskCurrentTab: useLocation().state?.currentTab
   const details=popupOn ?taskDetails: useLocation().state?.details
  console.log("##############CurrentTab",useLocation());
  const logo =
    'https://t3.ftcdn.net/jpg/04/60/44/08/360_F_460440876_a8lkfz8UHz64eDPFzmSJJZ55UQBNiC0a.jpg'
  const [secTab, setSecTab] = useState('SUBTASKS')
  const [value, setValue] = useState({ id: 1, value: 'New' })
  const [data,setData] = useState(null)
  const [subTasks,setSubTasks] = useState(null)
  const [projectMembers,setProjectMembers] = useState(null)
  const [isEdit,setIsEdit] = useState(false)
  const [isDelete,setIsDelete] = useState(false)
  const [fetchData,setFetchData] = useState(false)
  const [projectPosts,setProjectPosts] = useState([])
  const [refresh,setRefresh] = useState(false)
  const [options,setOptions]= useState(option)
  const [skills,setSkills] = useState([])
  const [taskAttachments,setTaskAttachments] = useState([])
  const user = JSON.parse(window.localStorage.getItem('user'))
  const userId = user?.user_id
  const TABS = [
    {
      value: 'SUBTASKS',
      component: <SubTasks details={details}  data={subTasks} setFetchData={setFetchData} projectMembers={projectMembers} taskId={id} projectId={projectId} setRefresh={setRefresh} refresh={refresh} currentTab={currentTab} onDataChange={onDataChange} />,
    },
  {
    value: 'POSTS',
    component: <Posts projectPosts={projectPosts} taskId={Number(id)} projectId={projectId}
      refresh={refresh} setRefresh={setRefresh} currentTab={currentTab}/>,
  },
  {
    value: 'ATTACHMENTS',
    component: <Attachments taskId={Number(id)} projectId={projectId} details={details}  
    refresh={refresh} setRefresh={setRefresh} currentTab={currentTab} taskData={data} taskAttachments={taskAttachments}/>,
  },
  {
    value: 'SKILLS',
    component: <Activities skills={skills}/>,
  },
  // {
  //   value: 'ACTIVITIES',
  //   component: <Activities />,
  // },
  ]

  useEffect(() => {
    try {
      axios.post(`${REST_API_END_POINT}project/fetch-all-project-sub-task`,{
        taskId: Number(id),userType: user.user_type, userId: user.user_id
      },{
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if(response.data.status === 1){
          setSubTasks(response.data.result)
          setSkills(response.data.skills)
        }
      
      }).catch((error) => {
        console.error(error);
      })
      setFetchData(false)
      }
      catch (error) {
        console.error("Error fetching data:", error);
      }
  }, [fetchData])

  useEffect(() => {
    try {
      axios.post(`${REST_API_END_POINT}project/view-project-task`,{ taskId: id, projectId,userId: user.user_id},{
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if(response.data.status === 1){
          setProjectPosts(response.data.projectPost)
          setData(response.data.result[0])
          setProjectMembers(response.data.projectMembers)
         
          // Assume 'statusValue' is the value you want to filter by

       option = option.filter(opt => opt.id=== response.data.result[0].status);
       setOptions(option)
         
        }
      
      }).catch((error) => {
        console.error(error);
      })
      // handleFetchEditData(id)
      }
      catch (error) {
        console.error("Error fetching data:", error);
      }
  }, [isEdit,refresh])

  useEffect(() => {
    if(data?.status){
      setValue(option.find(opt=>opt.id === data.status))
    }
  }, [data])

  const handleUpdateStatus = (status)=>{
    try {
      axios.post(`${REST_API_END_POINT}project/update-project-task-status`,{ taskId: id, status, userId: user.user_id },{
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if(response.data.status === 1){
          setValue(option.find(opt=>opt.id === status))
          enqueueSnackbar(response.data.message, { variant: 'success' });
        }else if(response.data.status === 0){
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
      
      }).catch((error) => {
        console.error(error);
      })
      }
      catch (error) {
        console.error("Error fetching data:", error);
      }
  }
  const handleDeleteTask = ()=>{
    try {
      // const  user  = JSON.parse(window.localStorage.getItem('user'))
      axios.post(`${REST_API_END_POINT}project/delete-project-task`,{ id: Number(id),projectId, status: 0 },{
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if(response.data.status === 4){
          enqueueSnackbar(response.data.message, { variant: 'success' });
         if(popupOn){
          // onDataChange()
          handleBackToAlljobs()
         }
          else{ navigate(PATH_DASHBOARD.project.viewproject+projectId)}
        }else if(response.data.status === 5){
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
        setIsDelete(false)
      }).catch((error) => {
        console.error(error);
      })
      }
      catch (error) {
        console.error("Error fetching data:", error);
      }
  }

  const handleTab = (event, newValue) => {
    setSecTab(newValue)
  }


  useEffect(()=>{
    fetchAttachmentsFromTask()
  },[id])

  const fetchAttachmentsFromTask =async()=>{
    const response = await axios.post(`${REST_API_END_POINT}project-task/fetch-all-designs-from-task`,{id})
    if(response.data.status==1){
      setTaskAttachments(response?.data?.attachments)
    }else{
      setTaskAttachments([])
    }
  }

  return (
    <Container sx={{mt:2}}>
      {/* {loading ? 
        <LoadingScreen sx={{ top: 0, left: 0, width: 1, zIndex: 9999, position: 'fixed' }} />
      :
        <> */}
          <Stack justifyContent='space-between' flexDirection='row'>
            {popupOn?<Button
              variant="outlined"
              type="submit"
              onClick={handleBackToAlljobs}
              // component={RouterLink}
              // to={PATH_DASHBOARD.project.viewproject+projectId}
              state={{currentTab:currentTab}}
              sx={{ alignItems: 'flex-end', mb: 3.5 }}
              startIcon={ // Use the startIcon prop to add an icon to the left
                <Stack>
                  <KeyboardBackspaceOutlined/>
                </Stack>

              }
            >
              Back to Job Details
            </Button>:
            <Button
            variant="outlined"
            type="submit"
            component={RouterLink}
            to={PATH_DASHBOARD.project.viewproject+projectId}
            state={{currentTab:currentTab}}
            sx={{ alignItems: 'flex-end', mb: 3.5 }}
            startIcon={ // Use the startIcon prop to add an icon to the left
              <Stack>
                <KeyboardBackspaceOutlined/>
              </Stack>

            }
          >
            Back to Job Details
          </Button>}
          </Stack>
          <Stack spacing={5}>
            <Stack
              // direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ background: theme.palette.grey[800], borderRadius: 2 }}
            >
              <Grid container justifyContent={'center'}>
                <Grid item xs={8} pt={4}>
                  <Stack spacing={3}>
                    <Card
                      style={{
                        height: 300,
                        width: '100%',
                        // backgroundImage:
                        //   `url(${data?.cover_image})`,
                      }}
                    >
                      <CoverImgStyle  src={(data?.product_design && data?.approved==1) ? data?.product_design : data?.avatarUrl ? data?.avatarUrl: logo} />
                    </Card>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={3}
                      justifyContent={'space-between'}
                    >
                      <Typography variant="h4" >{data?.name}</Typography>
                      {/* <Typography>
                        <Label
                          variant={'filled'}
                          color={value?.color || 'info'}
                        >
                          {sentenceCase(value?.value || '')}
                        </Label>
                      </Typography> */}
                      {/* <TextField
                        select
                        size="small"
                        value={value.value}
                       
                        sx={{
                          minWidth: { lg: '30%', md: '50%', sm: '40%' },
                          background: colors[value.id],
                          color:'blue',
                          borderRadius: 1,
                          border: 'none',
                          '&:hover': {
                            // transform: 'scale(1.02)',
                            border: 'none',
                          },
                        }}
                     
                      >
                        {console.log("################OPtion",option)}
                        {options.map((optio) => (
                          <MenuItem
                            // onClick={() => {
                            //   handleUpdateStatus(optio.id)
                            //   setValue(optio)
                            // }}
                            key={optio.value}
                            value={optio.value}
                          >
                            {optio.value}
                          </MenuItem>
                        ))}
                          
                      </TextField> */}
                    </Stack>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                      <Stack direction={'row'} spacing={1}>
                        {/* <Typography variant="subtitle2">{data?.member_count} Members</Typography> */}
                        <Typography variant="subtitle2">{(data?.file_count +taskAttachments.length)} Attachments</Typography>
                      </Stack>
                      <Box sx={{mb: -2}}>
                        { (user.user_type !== 3 && currentTab != 'Installs' && currentTab != 'Pickup' && currentTab != 'Shipping' && currentTab!="Completed" && currentTab!="Hold" && details?.status != 3 && details?.hold != 1) &&
                          <UserMoreMenu onDelete={()=>setIsDelete(true)} onEdit={()=>setIsEdit(true)}/>
                        }
                      </Box>
                    </Stack>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                      <Box sx={{ display: 'flex',justifyContent:'flex-start', alignItems: 'center',}}>
                        <Stack>    
                          <Typography variant="subtitle2">
                              Job Members
                          </Typography>             
                          <AvatarGroup max={4}>
                            {projectMembers?.map((row)=>( 
                                <Tooltip key={row.id} title={row.name}>             
                              <Avatar src={row.profile_pic} alt={row.name} />
                              </Tooltip>                   
                            ))}
                          </AvatarGroup>
                        </Stack>
                      </Box>
                      <Box sx={{mb: -2}}>
                        { (user.user_type !== 3 && currentTab != 'Installs' && currentTab != 'Pickup' && currentTab != 'Shipping' && currentTab!="Completed" && currentTab!="Hold" && details?.status != 3 && details?.hold != 1 && data?.status != 3 && subTasks?.length <= 0) && // && (data?.isAdditionalProduct == 1 || data?.service_isAdditionalProduct == 1)
                          <Button variant='contained'
                            onClick={()=>{
                              handleUpdateStatus(3);
                              handleBackToAlljobs()
                            }}>Complete</Button>
                        }
                      </Box>
                    </Stack>
                    <Divider />
                    <Tabs
                      value={secTab}
                      scrollButtons="auto"
                      variant="scrollable"
                      allowScrollButtonsMobile
                      onChange={handleTab}
                    >
                      {TABS.map((tab) => (
                        <Tab
                          disableRipple
                          key={tab.value}
                          label={
                            <Typography variant="overline">
                              {capitalCase(tab.value)}
                            </Typography>
                          }
                          icon={tab.icon}
                          value={tab.value}
                        />
                      ))}
                    </Tabs>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
            <Stack sx={{ alignItems: 'center', mb: 2 }} spacing={2}>
              {TABS.map((tab) => {
                const isMatched = tab.value === secTab
                return (
                  isMatched && (
                    <Box sx={{ width: '75%' }} key={tab.value}>
                      {tab.component}
                    </Box>
                  )
                )
              })}
            </Stack>
          </Stack>
          <AddTask data={data} setData={setData} projectMembers={projectMembers} projectId={Number(data?.project_id)} add={isEdit} setAdd={setIsEdit} refresh={refresh} setRefresh={setRefresh} />
          <ConfirmationPopup open={isDelete} handleClick={handleDeleteTask} message={'Want to Delete This Task'} handleClose={()=>setIsDelete(false)}/>
        {/* </>
      } */}
    </Container>
  )
}
