import PropTypes from 'prop-types';
import { findIndex } from 'lodash';
import { useEffect, useState, useRef } from 'react';
//
import Scrollbar from '../../Scrollbar';
import LightboxModal from '../../LightboxModal';
import ChatMessageItem from './ChatMessageItem';

// ----------------------------------------------------------------------

ChatMessageList.propTypes = {
  conversation: PropTypes.object.isRequired
};

export default function ChatMessageList({ products, conversation, handleFetchData, conversationMessage,isDesignOrder,renderCards,setRenderCards,fetchRequestDetails,  details }) {
  const scrollRef = useRef();
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    scrollMessagesToBottom();
  }, [conversationMessage]);


  const images = conversation.messages
    .filter((messages) => messages.contentType === 'image')
    .map((messages) => messages.body);

  const handleOpenLightbox = (url) => {
    const selectedImage = findIndex(images, (index) => index === url);
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };
  console.log('conversationMessage----',conversationMessage);
  

  return (
    <Scrollbar scrollableNodeProps={{ ref: scrollRef }} sx={{ p: 3, flexGrow: 1 }}>
      {/* {conversation.messages.map((message) => (
        <ChatMessageItem 
          key={message.id}
          message={message}
          conversation={conversation}
          onOpenLightbox={handleOpenLightbox}
        />
      ))} */}
        {conversationMessage?.map((message, index)=>(
         <ChatMessageItem 
          isDesignOrder={isDesignOrder}
          key={index}
          message={message}
          products={products}
          handleFetchData={handleFetchData}
          setRenderCards={setRenderCards}
          renderCards={renderCards}
          fetchRequestDetails={fetchRequestDetails}
          details={details}
          // conversation={conversation}
          // onOpenLightbox={handleOpenLightbox}
        />
        ))}
      <LightboxModal
        images={images}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onClose={() => setOpenLightbox(false)}
      />
    </Scrollbar>
  );
}
