import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useState,forwardRef,useRef, useEffect } from 'react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import shareFill from '@iconify/icons-eva/share-fill';
import downloadFill from '@iconify/icons-eva/download-fill';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
// material
import { Box, Tooltip, IconButton, DialogActions, Stack,Card,Grid,Typography,TableContainer,Table,TableHead,TableBody,TableCell,TableRow } from '@material-ui/core';
import Label from 'src/components/Label.js';

import { LoadingButton } from '@material-ui/lab';
//
import { MButton } from '../../../@material-extend';
import { DialogAnimate } from '../../../animate';
import { useTheme } from '@material-ui/core/styles';

import InvoicePDF from './InvoicePDF';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import Scrollbar from "src/components/Scrollbar";
import { Print } from "@material-ui/icons";


import { Link as RouterLink } from 'react-router-dom';
import faker from 'faker';
import { fCurrency } from 'src/utils/formatNumber.js';

import { PATH_DASHBOARD } from 'src/routes/paths.js';
import ReactToPrint from 'react-to-print'
import { COMPANY_LOGO, COMPANY_NAME, REST_API_END_POINT } from 'src/constants/Defaultvalues';
import axios from 'axios';


// ----------------------------------------------------------------------

InvoiceToolbar.propTypes = {
  invoice: PropTypes.object.isRequired

};

const RowResultStyle = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));

const INVOICE = {
  id: faker.datatype.uuid(),
  taxes: 5,
  discount: 10,
  status: 'paid',
  invoiceFrom: {
    name: faker.name.findName(),
    address: 'DieSachbearbeiter Choriner Straße 49 10435 Berlin',
    company: faker.company.companyName(),
    email: faker.internet.email(),
    phone: faker.phone.phoneNumberFormat()
  },
  invoiceTo: {
    name: faker.name.findName(),
    address: 'Keas 69 Str. 15234, Chalandri Athens, Greece',
    company: faker.company.companyName(),
    email: faker.internet.email(),
    phone: faker.phone.phoneNumberFormat()
  },
  items: [...Array(1)].map(() => ({
    id: faker.datatype.uuid(),
    title: faker.lorem.sentence(),
    description: faker.lorem.lines(),
    qty: faker.datatype.number({ min: 1, max: 5 }),
    price: faker.datatype.number({ min: 4, max: 99, precision: 0.01 })
  }))
};

export default function InvoiceToolbar({ invoice }) {
  const [openPDF, setOpenPDF] = useState(false);
  const componentRef = useRef();
  const theme = useTheme()

  const [data, setData] = useState({});
  const fetchDetails = async () => {
    try {
      const res = await axios.post(`${REST_API_END_POINT}settings/get-companyProfile-details`);
      if (res.data.status === 1) {
        setData(...res.data.data);
      }
    } catch (error) {
      console.log('get-companyProfile-details error : ', error);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const handleOpenPreview = () => {
    setOpenPDF(true);
  };

  const handleClosePreview = () => {
    setOpenPDF(false);
  };

  return (
    <>
      <Stack mb={5} direction="row" justifyContent="flex-end" spacing={1.5}>

      {/* <MButton
          color="info"
          size="small"
          variant="contained"
          component={RouterLink} to={PATH_DASHBOARD.customerOrders.root}
          endIcon={<Icon icon={eyeFill} />}
          sx={{ mx: 1 }}
        >
          Close 
        </MButton> */}

            <ReactToPrint 
              
              trigger={() =>(
              <MButton
                size="small"
                // loading={loading}
                variant="outlined"
                loadingPosition="end"
                // endIcon={<Icon icon={downloadFill} />}
              ><Print />
                Print
              </MButton>)}
               content={() => componentRef.current}
               copyStyles={false}
               pageStyle={`
               @media print {
                 body {
                   font-size: 16pt
                 }
                 table {
                   width: 100%;
                   border-collapse: collapse;
                   margin-bottom: 10px;
                  
                 }
     
                 th, td {
                   border: 1px solid #ddd;
                   padding: 8px;
                   text-align: left;
                 
                 }
               }
             `}
               />
            <div style={{ display: "none" }}>
              <PrintFunction
                data={data}
                ref={componentRef}
              />          
              </div>
      </Stack>

      <DialogAnimate fullScreen open={openPDF}>
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <DialogActions
            sx={{
              zIndex: 9,
              padding: '12px !important',
              boxShadow: (theme) => theme.customShadows.z8
            }}
          >
            <Tooltip title="Close">
              <IconButton color="inherit" onClick={handleClosePreview}>
                <Icon icon={closeFill} />
              </IconButton>
            </Tooltip>
          </DialogActions>
          <Box sx={{ flexGrow: 1, height: '100%', overflow: 'hidden' }}>
            <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
              <InvoicePDF invoice={invoice} />
            </PDFViewer>
          </Box>
        </Box>
      </DialogAnimate>
    </>
  );
}

const PrintFunction = forwardRef(({ invoiceDate, details, invoice, subTotal, data }, ref) => {
  const theme = useTheme();

  return (
    <Stack ref={ref}>
      <Card sx={{ pt: 1, px: 1 }}>
        <Grid container>
          {/* Left Side */}
          <Grid item xs={12} sm={6} >
            <Box component="img" alt="logo" src={COMPANY_LOGO} sx={{ height: 98 }} />
          </Grid>

          <Grid item xs={12} sm={6} >
            <Box sx={{ textAlign: { sm: 'left' } }}>
              <Typography variant="h3"><Typography>Invoice No: </Typography>INV-ebd62bcb-037f-49e4-9277-7914572b086d</Typography>
            </Box>
          </Grid>

          {/* Right Side */}
          <Grid item xs={12} sm={6} >
            <Typography variant="h3">
              Invoice to :
            </Typography>
            <Typography variant="h4">Laffety</Typography>
            <Typography variant="h4">Laffety Singboards , Maryland</Typography>
            <Typography variant="h4">Phone: 123456789</Typography>
            <Typography variant="h4" sx={{ marginTop: '20px' }}>
              Order Details :
            </Typography>
            <Typography variant="h4">Order date: 12/31/2023</Typography>
            <Typography variant="h4">Order ID: #000012</Typography>
          </Grid>

          <Grid item xs={12} sm={6} >
            <Typography variant="h3" >
              Invoice from :
            </Typography>
            <Typography variant="h4">{COMPANY_NAME}</Typography>
            <Typography variant="h4">www.{COMPANY_NAME}.com</Typography>
            <Typography variant="h4">
              {data?.address}, {data?.city}, {data?.state}, {data?.zipcode}
            </Typography>
            <Typography variant="h4">Telephone: +15208362947</Typography>
          </Grid>
        </Grid>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 960 }}>
              <Table>
                <TableHead
                  sx={{
                    borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                    '& th': { backgroundColor: 'transparent' }
                  }}
                >
                  <TableRow>
                    <TableCell variant='h4' width={40}>#</TableCell>
                    <TableCell variant='h4' align="left">Products</TableCell>
                    <TableCell variant='h4' align="left">Qty</TableCell>
                    <TableCell variant='h4' align="right">Unit price</TableCell>
                    <TableCell variant='h4' align="right">Total</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {INVOICE.items.map((row, index) => (
                    <TableRow
                    //   key={index}
                      sx={{
                        borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell align="left">
                        <Box sx={{ maxWidth: 560 }}>
                          <Typography variant="body2">MDO Sign 4x8 Single Face</Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="left">1</TableCell>
                      <TableCell align="right">$1200.00</TableCell>
                      <TableCell align="right">{fCurrency(1200 * 100)}</TableCell>
                    </TableRow>
                  ))}

                  <RowResultStyle>
                    <TableCell colSpan={3} />
                    <TableCell align="right">
                      <Box sx={{ mt: 2 }} />
                      <Typography variant="body1">Subtotal</Typography>
                    </TableCell>
                    <TableCell align="right" width={120}>
                      <Box sx={{ mt: 2 }} />
                      <Typography variant="body1">$1300</Typography>
                    </TableCell>
                  </RowResultStyle>
                  <RowResultStyle>
                    <TableCell colSpan={3} />
                    <TableCell align="right">
                      <Typography variant="body1">Discount</Typography>
                    </TableCell>
                    <TableCell align="right" width={120}>
                      <Typography sx={{ color: 'error.main' }}>$100</Typography>
                    </TableCell>
                  </RowResultStyle>
                  <RowResultStyle>
                    <TableCell colSpan={3} />
                    <TableCell align="right">
                      <Typography variant="body1">Taxes</Typography>
                    </TableCell>
                    <TableCell align="right" width={120}>
                      <Typography variant="body1">$50</Typography>
                    </TableCell>
                  </RowResultStyle>
                  <RowResultStyle>
                    <TableCell colSpan={3} />
                    <TableCell align="right">
                      <Typography variant="body1">Total</Typography>
                    </TableCell>
                    <TableCell align="right" width={140}>
                      <Typography variant="body1">$1150</Typography>
                    </TableCell>
                  </RowResultStyle>
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          {/* <Divider sx={{ mt: 5 }} /> */}

          {/* <Grid container>
            <Grid item xs={12} md={9} sx={{ py: 3 }}>
              <Typography variant="subtitle2">NOTES</Typography>
              <Typography variant="body2">
                We appreciate your business. Should you need us to add VAT or extra notes let us know!
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} sx={{ py: 3, textAlign: 'right' }}>
              <Typography variant="subtitle2">Have a Question?</Typography>
              <Typography variant="body2">support@signwise.com</Typography>
            </Grid>
          </Grid> */}
        </Card>
          </Stack>
  )
})
