import React from 'react';
import { Typography, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Divider, Grid, Tooltip } from '@mui/material';
import moment from 'moment';
import Scrollbar from 'src/components/Scrollbar'; // Assuming you have a Scrollbar component
import Label from 'src/components/Label'; // Assuming you have a Label component
import { TextField } from '@material-ui/core';

const PrintableContent = React.forwardRef((props, ref) => {
    const {
      previewData, selectedProducts, selectedServices, additionalSelectedServices,
      totalsum, totalMarkupAmount, totalMarkupPercentage, DiscountTotal, status,
      getStatusColorAndLabel, VALUEPreview, VALUE_SERVICES, theme, calculateTotal, markupType, hasMarkupPermission, UNITS, values
    } = props;
  
    return (
      <div ref={ref}>
        <Grid container sx={{ justifyContent: 'center' }} spacing={3}>
          <Grid item xs={12} md={12}>
            <Stack sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} spacing={2}>
              <Stack direction={{ xs: 'column', md: 'row' }} display="flex" justifyContent="space-between">
                <Typography variant="subtitle2">
                  <span style={{ color: theme.palette.primary.darker }}>Name: </span>
                  {previewData?.name}
                </Typography>
                <Typography variant="subtitle2">
                  <span style={{ color: theme.palette.primary.darker }}>Use custom welcome message: </span>
                  {previewData.welcome_message == null ? 'No' : previewData.welcome_message == false ? 'No' : 'Yes'}
                </Typography>
              </Stack>
              <Stack>
                <Typography variant="subtitle2" style={{ color: theme.palette.primary.darker }}>Description:</Typography>
                <Typography variant="subtitle2" style={{ overflowWrap: 'break-word', wordWrap: 'break-word', wordBreak: 'break-word' }}>
                  {previewData?.description}
                </Typography>
              </Stack>
              <Stack direction={{ xs: 'column', md: 'row' }} display="flex" justifyContent="space-between">
                <Typography variant="subtitle2">
                  <span style={{ color: theme.palette.primary.darker }}>Job Class: </span>
                  {previewData?.project_type?.name}
                </Typography>
                <Typography variant="subtitle2">
                  <span style={{ color: theme.palette.primary.darker }}>Job Types: </span>
                  {previewData?.project_category?.name}
                </Typography>
              </Stack>
              <Stack direction={{ xs: 'column', md: 'row' }} display="flex" justifyContent="space-between">
                <Typography variant="subtitle2">
                  <span style={{ color: theme.palette.primary.darker }}>Project: </span>
                  {previewData?.community?.name}
                </Typography>
                {/* <Typography variant="subtitle2">
                  <span style={{ color: theme.palette.primary.darker }}>Markup Type: </span>
                  {previewData?.markup_type?.name}
                </Typography> */}
              </Stack>
              <Stack direction={{ xs: 'column', md: 'row' }} display="flex" justifyContent="space-between">
                <Typography variant="subtitle2">
                  <span style={{ color: theme.palette.primary.darker }}>Start Date: </span>
                  {moment.unix(previewData.start).format("MM/DD/YYYY")}
                </Typography>
                <Typography variant="subtitle2">
                  <span style={{ color: theme.palette.primary.darker }}>Installation Date: </span>
                  {moment.unix(previewData.end).format("MM/DD/YYYY")}
                </Typography>
              </Stack>
              <Typography variant="h6">Products</Typography>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 500 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {VALUEPreview?.map((val) => (
                          <TableCell key={val.value}>{val.value}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedProducts?.map((data) => {
                        const quantityFieldName = `quantity_${data.product_id || data.id}`;
                        const discountFieldName = `discount_${data.product_id || data.id}`;
                        let unitName = UNITS?.filter((item) => item?.id == data?.unit)[0]?.name;
                        let shortUnitName = unitName?.[0]?.short_name || "";
                        const dataPrice = data.product_price || data.price;
                        const dataWholesalePrice = data.product_price || data.price;
                        const dataQuantity = values[quantityFieldName];
                        const dataDiscount = data.discount ? data.discount : values[discountFieldName];
                        const dataretailPrice = data.product_retail_price || data.retail_price;
                        const rowTotal = calculateTotal(markupType == 1 ? dataPrice : (markupType == 2 ? dataretailPrice : 0), dataQuantity, dataDiscount, data.quantity, data.locationQuantity);
                        const invoiceAmount = data?.expected_material_cost + data?.expected_total_service_cost;
                        let markup = markupType ? (invoiceAmount / rowTotal) : null;
                        markup = markup ? Number(markup) : 0;
  
                        return (
                          <TableRow key={data.id}>
                            <TableCell>{data.product_name || data.name}</TableCell>
                            <TableCell>{(hasMarkupPermission && markupType == 2) ? dataretailPrice : null}</TableCell>
                            <TableCell>{(hasMarkupPermission && markupType == 1) ? dataPrice : null}</TableCell>
                            <TableCell>{(hasMarkupPermission && markupType == 1) ? data.wholesaleMarkup : (hasMarkupPermission && markupType == 2) ? data.retailMarkup : null}</TableCell>
                            <TableCell>
                              <TextField
                                required
                                fullWidth
                                type='number'
                                sx={{ width: 85 }}
                                disabled
                                label={shortUnitName}
                                value={Number(data.quantity)}
                                defaultValue={1}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                required
                                fullWidth
                                type='number'
                                disabled
                                sx={{ width: 85 }}
                                label={'Qty'}
                                value={Number(data.locationQuantity)}
                                defaultValue={1}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                disabled
                                type="number"
                                label="Discount"
                                defaultValue={0}
                                value={Number(data.discount)?.toFixed(2)}
                              />
                            </TableCell>
                            <TableCell>{rowTotal?.toFixed(2)}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <Typography variant="h6">Services</Typography>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 500 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {VALUE_SERVICES?.map((val) => (
                          <TableCell key={val.value}>{val.value}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedServices?.map((data) => {
                        const dataCost = data.unit_cost || data.total_cost;
                        let originalMarkup = data.originalMarkup || 0;
                        let markup = data.markup || 0;
                        const total = dataCost * data.quantity;
                        data['total'] = total?.toFixed(2);
                        return (
                          <TableRow key={data.id}>
                            <TableCell>{data.name}</TableCell>
                            <TableCell>{originalMarkup?.toFixed(2)}</TableCell>
                            <TableCell>{data.cost}</TableCell>
                            <TableCell>{dataCost}</TableCell>
                            <TableCell>{data.quantity}</TableCell>
                            <TableCell>{data.total}</TableCell>
                          </TableRow>
                        );
                      })}
                      {additionalSelectedServices?.map((data) => {
                        const dataCost = data.unit_cost || data.total_cost;
                        const total = dataCost * (data?.quantity || 1);
                        data['total'] = total ? total?.toFixed(2) : 0.00;
                        let originalMarkup = data.originalMarkup || 0;
                        return (
                          <TableRow key={data.id}>
                            <TableCell>{data.name}</TableCell>
                            <TableCell>{originalMarkup?.toFixed(2)}</TableCell>
                            <TableCell>{data.cost}</TableCell>
                            <TableCell>{data.unit_cost || data.total_cost}</TableCell>
                            <TableCell>{data?.quantity || 1}</TableCell>
                            <TableCell>{data.total}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <Divider />
              <Stack>
                <Typography variant="subtitle2" style={{ color: theme.palette.primary.darker }}>Notes:</Typography>
                <Typography variant="subtitle2" style={{ overflowWrap: 'break-word', wordWrap: 'break-word', wordBreak: 'break-word' }}>
                  <Scrollbar sx={{ maxHeight: 200 }}>
                    {previewData?.notes}
                  </Scrollbar>
                </Typography>
              </Stack>
              <Stack direction={{ xs: 'column', md: 'row' }} display="flex" justifyContent="space-between">
                <Typography variant="subtitle2">
                  <span style={{ color: theme.palette.primary.darker }}>Total Price: </span>
                  {totalsum < 0 ? 0 : totalsum?.toFixed(2)}
                </Typography>
                <Tooltip title="Total Selling Price - Total Product Formula Cost">
                  <Typography variant="subtitle2" sx={{ cursor: 'pointer' }}>
                    <span style={{ color: theme.palette.primary.darker }}>Total Markup Amount: </span>
                    {totalMarkupAmount ? totalMarkupAmount?.toFixed(2) : 0}
                  </Typography>
                </Tooltip>
                <Tooltip title="(Total Markup Amount / Total Product Formula Cost) * 100">
                  <Typography variant="subtitle2" sx={{ cursor: 'pointer' }}>
                    <span style={{ color: theme.palette.primary.darker }}>Total Markup Percentage: </span>
                    {totalMarkupPercentage ? totalMarkupPercentage?.toFixed(2) : 0}
                  </Typography>
                </Tooltip>
                <Typography variant="subtitle2">
                  <span style={{ color: theme.palette.primary.darker }}>Total Discount: </span>
                  {DiscountTotal ? DiscountTotal?.toFixed(2) : 0.00}
                </Typography>
              </Stack>
              <Stack direction={{ xs: 'column', md: 'row' }} display="flex" justifyContent="space-between">
                <Typography variant="subtitle2">
                  <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'} color={getStatusColorAndLabel(status).color}>
                    {getStatusColorAndLabel(status).label}
                  </Label>
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </div>
    );
  });
  
  export default PrintableContent;
