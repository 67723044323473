import { Autocomplete, Avatar, Backdrop, Badge, Box, Button, Card, CardHeader, Checkbox, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Stack, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from "@material-ui/core";
import Page from '../../components/Page';
import { capitalCase, sentenceCase } from "change-case";
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import lockOpenFill from '@iconify/icons-ph/lock-open-fill';
import editFill from '@iconify/icons-eva/edit-fill';
import plusFill from '@iconify/icons-eva/plus-fill';
//materail
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Label from "src/components/Label";
import { useLocation, useNavigate, useParams } from "react-router";

import ViewRequest from "src/components/_dashboard/official-request/ViewRequest";
import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import PublicMsgBoard from "src/components/_dashboard/official-request/PublicMsgBoard";
import RequestTabUpdates from 'src/components/_dashboard/user/account/RequestTabUpdates'
import PublicMessageBoard from 'src/components/_dashboard/user/profile/PublicMessageBoard.js'
import RequestUpdates from "src/components/_dashboard/official-request/RequestUpdates";
import PaymentRequest from "src/components/_dashboard/official-request/PaymentRequest";
import { AddCircle, BlockOutlined, Delete, KeyboardBackspaceOutlined, Print, LocationOn, Add, Remove, CenterFocusStrong, NoteAdd, AddCircleOutlined } from "@material-ui/icons";
import Modal from "src/components/_dashboard/blog/ProjectModel";
import Icon from "@iconify/react";
import Scrollbar from "src/components/Scrollbar";
import axios from "axios";
import { REST_API, REST_API_END_POINT, SEND_EMAIL, UNITS } from "src/constants/Defaultvalues";
import moment from "moment";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useSnackbar } from "notistack";
import { LoadingButton, MobileDatePicker } from "@material-ui/lab";
import { useCallback } from "react";
import { UploadAvatar, UploadMultiFile } from "src/components/upload";
import AddClients from "src/components/_dashboard/official-request/AddClients";
import AddProposal from "src/components/_dashboard/official-request/AddProposal";
import ConfirmationPopup from "src/components/_dashboard/official-request/ConfirmationPopup";
import ReactToPrint from 'react-to-print'
import { storage } from '../../firebase/firebase'
import { getDownloadURL, ref as imageref, uploadBytesResumable } from 'firebase/storage';
import { Link } from "react-router-dom";
import { empty } from "src/Helpers/Common";
import Locations from "src/components/_dashboard/official-request/Locations";
import Workflow from "src/components/_dashboard/general-analytics/RfqForm";
import { AccountNotifications } from '../../components/_dashboard/user/account'
import { set } from "lodash";
import Proposals from "src/components/_dashboard/official-request/Proposals";
import useCompanyProfile from "src/hooks/useCompanyProfile";
import LoadingScreen from "src/components/LoadingScreen";
import PrintableContent from './printPreview';
import { red } from "@material-ui/core/colors";
import AnimatedButton from "src/components/animate/AnimatedButton";
import { MapControlScale } from "src/components/map";
import RouteMap from "src/components/_dashboard/official-request/RouteMap";
import RouteMapInstall from "src/components/_dashboard/official-request/RouteMapInstalls";
import SalesOrderInstallationReportView from "src/components/_dashboard/official-request/SalesOrderInstallationReportView";
const RowResultStyle = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));
const MAX_CHARACTERS = 35;
// const getStatusColorAndLabel = (status,installationStatus,allInstallationCompleted,accepted,min_payment_status,project_category,billing_type,installationCompleteStatus) => {
// console.log('project_categoryproject_category',project_category)
//   switch (status) {
//     case 0:
//       return { color: 'error', label: 'Status: Removed' };
//     case 1:
//       return { color: 'warning', label: 'Status: Draft' };
//     case 2:
//       return { color: 'info', label: 'Status: Open' };
//     case 3:
//       // return { color: 'primary', label: 'Status: Accepted' }; // Evaluating
//       // return { color: `${min_payment_status==0? 'warning' :'primary'}`, label: `Status: ${min_payment_status==1? 'Approved and Paid' :'Approved & Awaiting for Payment'}` }
//       return { color: 
//         `${project_category == 'No Charge'? "primary" 
//           : min_payment_status==0? 'warning' :'primary'}`,
//            label: `Status: ${
//        ( project_category == 'No Charge' || billing_type==0)
//           ? 'Approved'
//           : min_payment_status == 1
//           ? 'Approved and Paid'
//           : 'Approved and Payment Due'
//       }` }
//     case 4:
//       // return { color: empty(accepted) ? 'warning' : 'success', label: empty(accepted) ? 'Installation completed & Awaiting approval' : !empty(allInstallationCompleted) ? 'Completed & Accepted' : !empty(installationStatus) ? 'Status: Installation' : 'Status: In Production' }; // return { color: 'primary', label: 'Status: Converted to Job' };
//       return { color: (empty(accepted) && !empty(allInstallationCompleted)) ? 'warning' : 'success', label: (empty(accepted) && !empty(allInstallationCompleted)) ? 'Installation completed & Awaiting approval' : (!empty(accepted) && !empty(allInstallationCompleted)) ? 'Completed' : !empty(installationStatus) ? 'Status: Installation' : 'Status: In Production' }; // return { color: 'primary', label: 'Status: Converted to Job' };
//     case 5:
//       return { color: 'warning', label: 'Status: Closed' };
//     case 6:
//       return { color: 'error', label: 'Status: Rejected' };
//     default:
//       return { color: 'default', label: 'Status: Open' };
//   }
// };





const markupTypes = [
  { id: 1, name: 'Wholesale Markup' },
  { id: 2, name: 'Retail Markup' }
]

const marketingAdjustmentType = [
  { id: 1, name: '%' },
  { id: 2, name: '$' },
  // { id: 3, name: '¢' }
];

export default function ViewofficialRequest({popupOn,idOfProject,isEditSalesOrder,closeView,setRenderCards,renderCards,onDataChange}) {
  const { COMPANY_LOGO, COMPANY_NAME } = useCompanyProfile()
  const theme = useTheme()
  const { id:ids } = useParams()
  const id =popupOn ? idOfProject :ids
  const user = JSON.parse(localStorage.getItem('user'))
  const location = useLocation();
  const isFromProjects = location.state?.fromProjects || false;
  const edit = popupOn ? isEditSalesOrder : !!(location.state && !isFromProjects);
  let editState = (user.user_type == 3 || user.user_type !== 1) ? false : !!(location.state && !isFromProjects);  
  // const edit = false
  console.log('popupOn=popupOn===',popupOn)
  console.log('popupOn=isEditSalesOrder===',isEditSalesOrder)
  console.log('popupOn=state===',useLocation().state)

  const componentRef = useRef();
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();
  const [currentRFQData, setCurrentRFQdata] = useState({})
  const [openEdit, setOpenEdit] = useState(false)
  const [showServices, setShowServices] = useState(true)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [initialServiceCostInProductFormula, setInitialServiceCostInProductFormula] = useState(0)
  const [markupType, setMarkupType] = useState();
  const [clientApprove , setClientApprove] = useState()
  const [designs, setDesigns] = useState([])
  const [details, setDetails] = useState(null)
  console.log('setDetails+++++++++++++++++', details)
  const [skills, setSkill] = useState(null)
  const [workflow, setWorkFlow] = useState(null)
  const [allProducts, setAllproducts] = useState(null)
  const [invoice, setInvoice] = useState(null)
  const [open, setIsOpen] = useState(false)
  const [totalsum, setTotalSum] = useState(0);
  const [rfqSubTotal, setRFQSubTotal] = useState(0);
  const [DiscountTotal, setDiscountTotal] = useState(0);
  const [totalWholesalePrice, setTotalWholesalePrice] = useState(0);
  const [totalRetailPrice, setTotalRetailPrice] = useState(0);
  const [totalProductFormula, setTotalProductFormula] = useState(0);
  const [addClient, setAddClient] = useState(false)
  const [addVendor, setAddVendor] = useState(false)
  const [addProposal, setAddProposal] = useState(false)
  const [openInvoice, setOpenInvoice] = useState(false)
  const [requestAccept, setRequestAccept] = useState(false)
  const [requestReject, setRequestReject] = useState(false)
  const [allClients, setAllClients] = useState(null)
  const [allClientsEdit, setAllClientsEdit] = useState(null)
  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));
  const [currentTab, setCurrentTab] = useState(user.isVendor ? 'Details' : 'Sales Order Details')
  const [openPreview, setOpenPreview] = useState(false)
  const [openConvertToRFQ, setOpenConvertToRFQ] = useState(false)
  const [avatarUrl, setAvatarUrl] = useState([])
  const [openDelete, setOpenDelete] = useState(false)
  const [reOpen, setReopen] = useState(false)
  const [confirmInvoice, setConfirmInvoice] = useState(false)
  const [convertToRFQ, setConvertToRFQ] = useState(false)
  const [invoiceReject, setRejectInvoice] = useState(false)
  // const [reqAcceptClient,setReqAcceptClient] = useState(false)
  const [reason, setReason] = useState('')
  const [acceptedClients, setAcceptedClients] = useState(null)
  const [updates, setUpdates] = useState(null)
  const [isSaving, setIsSaving] = useState(false)
  const [previewData, setPreviewData] = useState({})
  const [locationStates, setLocationStates] = useState({});
  const [serviceOnlyLocationStates, setServiceOnlyLocationStates] = useState({});
  const [payments, setPayments] = useState(null)
  const [projectTypes, setProjectTypes] = useState([]);
  const [projectCategories, setProjectCategories] = useState([]);
  const [noChargeType, setNoChargeType] = useState([]);
  const [isCommunityLoading, setIsCommunityLoading] = useState(false);
  const [communities, setCommunities] = useState([]);
  const [refresh, setRefresh] = useState(false)
  const [refreshData, setRefreshData] = useState(false)
  const ref = useRef()
  const [openWorkflow, setWorkflowOpen] = useState(false)
  const [manual, setManual] = useState(false)
  const [signAgreements, setSignAgreements] = useState(null)
  const [portfolio, setPortfolio] = useState(null)
  const [customTemplate, setCustomTemplate] = useState(null)
  const [questionnaireTemplate, setQuestionnaireTemplate] = useState(null)
  const [allDataEmpty, setDataEmpty] = useState(true)
  const [proposalData, setProposalData] = useState(null)
  const [teamMembers, setTeamMember] = useState([])
  const [customerData, setCustomerData] = useState([])
  const [labourCostServices, setLabourCostServices] = useState([])
  const [selectedServices, setSelectedServices] = useState([]);
  const [labourInstallService, setLabourInstallService] = useState([]);
  const [invoiceSelectedServices, setInvoiceSelectedServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([])
  const [requestServices, setRequestServices] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingEdit, setLoadingEdit] = useState(false)
  const [budget, setBudget] = useState(0)
  const [totalMarkupPercentage, setTotalMarkupPercentage] = useState(0)
  const [totalMarkupAmount, setTotalMarkupAmount] = useState(0)
  const printableContentRef = useRef();
  const [additionalSelectedServices, setAdditionalSelectedServices] = useState([]);
  localStorage.setItem('requestId', id ? id : null)
  const userType = user.user_type
  const userId = user.user_id
  const explodedPermissions = user?.exploded_permissions
  const hasMarkupPermission = explodedPermissions?.some(permission => ['View Markup'].includes(permission)) ? true : false
  const hasDiscountPermission = explodedPermissions?.some(permission => ['Manage Discount'].includes(permission)) ? false : true
  const [showWarning, setShowWarning] = useState(selectedServices.map(() => false));
  const [allLocationSame, setAllLocationSame] = useState(false)
  const [allServiceLocationSame, setAllServiceLocationSame] = useState(true)
  const [allRfpServiceLocationSame, setAllRfpServiceLocationSame] = useState(true)
  const [allRfpProductsLocationSame, setAllRfpProductsLocationSame] = useState(true)
  const [indexCounter, setIndexCounter] = useState(0)
  const [serviceIndexCounter, setServiceIndexCounter] = useState(0)
  const [totalLabourCost, setTotalLabourCost] = useState(0)
  const [totalProductLabourCost, setTotalProductLabourCost] = useState(0)
  const [checkLength,setCheckLength]= useState(false)
  let productFormulaCost = 0
  const [rfps, setRfps] = useState([]); // List of RFPs
  const [selectedRfp, setSelectedRfp] = useState(null); // Selected RFP
  const [openModal, setOpenModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [rfpServices, setRfpServices] = useState([]);
  const [rfpProducts, setRfpProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [cartService, setCartService] = useState([]);
  const [rfpServicesEdit, setRfpServicesEdit] = useState([]);
  const [rfpLocationStates, setRfpLocationStates] = useState({});
  const [rfpServiceLocationStates, setRfpServiceLocationStates] = useState({});
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [materials, setAllMaterials] = useState([]);
  const [openProductNote, setOpenProductNote] = useState({});
  const [isSavingNote, setIsSavingNote] = useState(false);
  const [currentNote, setCurrentNote] = useState('');
  const [taxAmount, setTaxAmount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [selectedTaxCategory,setSelectedTaxCategory]=  useState(null);
  const [taxCategories,setTaxCategories]=  useState([]);
  const [data, setData] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [formDatas, setFormDatas] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [noCharge, setNoCharge] = useState(false);
  
  const [mapServices,setMapServices]=  useState([]);
  const [mapServicesLoading, setMapServicesLoading] = useState(false);
  const [selectedMapserviceProducts, setSelectedMapserviceProducts] = useState([]);
    const [progress, setProgress] = useState(null)



    const getStatusColorAndLabel = (
      status,
      installationStatus,
      allInstallationCompleted,
      accepted,
      min_payment_status,
      billing_type,
      installationCompleteStatus,
      
    ) => {
  console.log('installationCompleteStatus',details?.installationCompleteStatus,installationCompleteStatus)
  let CommunityJob = false
      let CompletedStatus =
  (installationCompleteStatus == 1 || details?.installationCompleteStatus == 1)
    ? 'Installs'
    : (installationCompleteStatus == 2 || details?.installationCompleteStatus == 2)
    ? 'Pick up'
    :  (installationCompleteStatus == 3 || details?.installationCompleteStatus == 3) ? 'Shipping' :'Installation'
      switch (status) {
        case 1:
          return { color: 'warning', label: 'Status: Draft' }
        case 2:
          return { color: 'info', label: 'Status: Open' }
        case 3:
          return {
            color: `${
              details?.project_category == 13
                ? 'primary'
                : min_payment_status == 0
                ? 'warning'
                : 'primary'
            }`,
            label: `Status: ${
              details?.project_category == 13 || billing_type == 0
                ? 'Approved'
                : min_payment_status == 1
                ? 'Approved and Paid'
                : 'Approved and Payment Due'
            }`,
          }
        // return { color: `${min_payment_status==0? 'warning' :'primary'}`, label: `Status: ${min_payment_status==1? 'Approved and Paid' :'Approved & Awaiting for Payment'}` }
        case 4:
          // return { color: 'success', label: !empty(allInstallationCompleted) ? 'Completed' : (!empty(installationStatus) ? 'Status: Installation' : 'Status: In Production') }
          return {
            color:
              empty(accepted) && !empty(allInstallationCompleted)
                ? 'warning'
                : 'success',
            label:
              empty(accepted) && !empty(allInstallationCompleted)
                ? `${CompletedStatus} completed & Awaiting approval`
                : !empty(accepted) && !empty(allInstallationCompleted)
                ? `${CompletedStatus} completed & Approved`
                : !empty(installationStatus)
                ? `Status: ${CompletedStatus}`
                : CommunityJob ? `${CompletedStatus} completed & Approved` : `Status: In-Production`,
          } // return { color: 'primary', label: 'Status: Converted to Job' };
        case 5:
          return { color: 'warning', label: 'Status: Closed' }
        case 6:
          return { color: 'error', label: 'Status: Rejected' }
        default:
          return { color: 'default', label: 'Status: Open' }
      }
    }
  

  console.log('allRfpServiceLocationSame',allRfpServiceLocationSame)
  const fetchDetails = async () => {
    try {
      const res = await axios.post(`${REST_API_END_POINT}settings/get-companyProfile-details`);
      if (res.data.status === 1) {
        setData(...res.data.data);
      }
    } catch (error) {
      console.log('get-companyProfile-details error : ', error);
    }
  };

  const updatePaymentFromQb =async(ids)=>{
    try {
      let user = JSON.parse(localStorage.getItem('user'))
      // console.log('useruseruseruser',user)
      let userType = user.user_type
      let userId = user.user_id
      let admin_email = user.email
      let company_name= user.company_name
      let username = user.person_name
      let user_role = user.user_role
      const response= await axios.post(`${REST_API_END_POINT}sales-order/update-payment-from-qb`,{userId,allId:ids,admin_email,company_name,username,user_role})
    // if(response.data.details.success > 0){
    //   // fetchAllRequest()
    // }
    } catch (error) {
      console.log("error",error)
    }
  }

  useEffect(() => {
    fetchDetails();
    if(id){
      let ids =[id]
      updatePaymentFromQb(ids)
    }

  }, [id]);


    const handleSalesOrderTabCount = async () => {
      try {
        const response = await axios.post(`${REST_API}webservice/sales-order/reduce-tabs-count`, {
          userId,
          request_id:id,
        });
        
      } catch (error) {
        console.error("Error reducing sales order count:", error);
      }
    };
  
    useEffect(() => {
      handleSalesOrderTabCount()
    }, [])

  const handleOpenNote = (indexId, note) => {
    setCurrentNote(note || '');
    setOpenProductNote((prev) => ({ ...prev, [indexId]: true }));
  };

  const handleCloseNote = (indexId) => {
    setOpenProductNote((prev) => ({ ...prev, [indexId]: false }));
    setCurrentNote('');
  };

  const handleNoteChange = (event) => {
    const newNote = event.target.value;
    if (newNote.length <= MAX_CHARACTERS) {
      setCurrentNote(newNote);
    }
  };

  const handleSaveNote = (indexId) => {
    setIsSavingNote(true);
    setTimeout(() => {
      setSelectedProducts((prev) =>
        prev.map((product) =>
          product.indexId === indexId ? { ...product, product_notes: currentNote } : product
        )
      );
      setIsSavingNote(false);
      handleCloseNote(indexId);
    }, 100);
  };

  const handleRfpSelect = (event, value) => {
    setSelectedRfp(value);
    setRfpServices(value?.services?.filter(service => !isInCartService(service)) || []);
    setRfpProducts(value?.products?.filter(product => !isInCart(product)) || []);
    if (!empty(value)) {
      setOpenModal(true);
    }

  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // const addToCart = (item) => {
  //   // Extract project/community address
  //   const { address: projAddress = '', city: projCity = '', state: projState = '', zip: projZip = '' } = values?.community || {};
  //   const communityAddress = { address: projAddress, city: projCity, state: projState, zip: projZip };
  
  //   if (tabIndex === 0) {
  //     // Adding to RFP Services
  //     const newService = assignDefaultLocation(cartService, item, allRfpServiceLocationSame, communityAddress);
  //     setCartService([...cartService, newService]);
  
  //     if (allRfpServiceLocationSame) {
  //       const commonLocation = cartService[0]?.location?.[0] || {
  //         address: '',
  //         city: '',
  //         state: '',
  //         zip: '',
  //       };
  
  //       setCartService((prevServices) =>
  //         prevServices.map((service) => ({
  //           ...service,
  //           location: service.location ? service.location.map(() => ({ ...commonLocation })) : [{ ...commonLocation }],
  //         }))
  //       );
  //     }
  //   } else {
  //     // Adding to RFP Products
  //     const newProduct = assignDefaultLocation(cart, item, allRfpProductsLocationSame, communityAddress);
  //     setCart([...cart, newProduct]);
  
  //     if (allRfpProductsLocationSame) {
  //       const commonLocation = cart[0]?.location?.[0] || {
  //         address: '',
  //         city: '',
  //         state: '',
  //         zip: '',
  //       };
  
  //       setCart((prevProducts) =>
  //         prevProducts.map((product) => ({
  //           ...product,
  //           location: product.location ? product.location.map(() => ({ ...commonLocation })) : [{ ...commonLocation }],
  //         }))
  //       );
  //     }
  //   }
  
  //   // Close modal and reset form fields
  //   setOpenModal(false);
  //   setSelectedRfp(null);
  //   setFieldValue('rfp', null);
  // };
  
  const addToCart = (item) => {
    // Extract project/community address
    const { address: projAddress = '', city: projCity = '', state: projState = '', zip: projZip = '' } = values?.community || {};
    const communityAddress = { address: projAddress, city: projCity, state: projState, zip: projZip };

    const locationQuantity = item.location_quantity || 1; // Ensure a default value of 1

    if (tabIndex === 0) {
        // ✅ Adding to RFP Services
        const newService = assignDefaultLocation(cartService, item, allRfpServiceLocationSame, communityAddress, locationQuantity);
        setCartService([...cartService, newService]);

        if (allRfpServiceLocationSame) {
            setCartService((prevServices) =>
                prevServices.map((service) => ({
                    ...service,
                    location: Array.from({ length: locationQuantity }, () => ({ ...communityAddress })),
                }))
            );
        }
    } else {
        // ✅ Adding to RFP Products
        const newProduct = assignDefaultLocation(cart, item, item.allRfpProductsLocationSame, communityAddress, locationQuantity);
        setCart([...cart, newProduct]);

        if (item.allRfpProductsLocationSame) {
            setCart((prevProducts) =>
                prevProducts.map((product) => ({
                    ...product,
                    location: Array.from({ length: locationQuantity }, () => ({ ...communityAddress })),
                }))
            );
        }
    }

    // ✅ Close modal and reset form fields
    setOpenModal(false);
    setSelectedRfp(null);
    setFieldValue('rfp', null);
  };


  const assignDefaultLocation = (existingItems, newItem, allLocationsSame, communityAddress) => {
    let assignedLocation = [];
  
    if (allLocationsSame && existingItems.length > 0) {
      // Use the first item's location as the common location
      const commonLocation = existingItems[0]?.location?.[0] || {
        address: '',
        city: '',
        state: '',
        zip: '',
      };
      assignedLocation = [{ ...commonLocation }];
    } else if (
      communityAddress.address &&
      communityAddress.city &&
      communityAddress.state &&
      communityAddress.zip
    ) {
      // Use the community address as the default location
      assignedLocation = [
        {
          id: 0,
          address: communityAddress.address,
          city: communityAddress.city,
          state: communityAddress.state,
          zip: communityAddress.zip,
        },
      ];
    } else {
      // Default to an empty location
      assignedLocation = [
        {
          id: 0,
          address: '',
          city: '',
          state: '',
          zip: '',
        },
      ];
    }
  
    return {
      ...newItem,
      location: assignedLocation,
    };
  };
  
  const handleMaterial = (newValue) => {
    setSelectedMaterials((prevMaterials) => {
      const additionals = prevMaterials?.filter(item => item.isAdditionalProduct == true);
      const baseIndex = prevMaterials?.length || 0;
      const updatedNewValue = newValue.map((item, idx) => ({
        ...item,
        newIndexId: baseIndex + 1,
      }));
      return [...updatedNewValue, ...additionals];
    });
  };

  const handleDeleteMaterial = (id, indexId) => {
    const updatedMaterials = selectedMaterials?.filter((data) => {
      if (data?.isAdditionalProduct) {
        return data?.newIndexId !== indexId;
      }
      return data?.id !== id;
    });
    
    setSelectedMaterials(updatedMaterials);
    };

  console.log("######################Cart", cart);
  console.log("######################Cart Service", cartService);
  const isInCart = (item) => {
    return cart.some(cartItem => cartItem.product_id === item.product_id);
  };
  const isInCartService = (item) => {
    return cartService.some(cartItem => cartItem.labour_cost_service_id
      === item.labour_cost_service_id);
  };
  const closeModal = () => {
    setOpenModal(false);
    setFieldValue('rfp',null)
  };
  const handleDeleteRFPProduct = (productId) => {
    setCheckLength(!checkLength)
    setCart(prevCart => prevCart.filter(item => item.product_id !== productId));
  };
  const handleDeleteRFPService = (productId) => {
    setCheckLength(!checkLength)
    console.log("######################Cartde", productId);
    setCartService(prevCart => prevCart.filter(item => item.labour_cost_service_id !== productId));
  };
  const VALUE = [
    { id: 1, value: 'Sl No.' },
    { id: 2, value: 'Products' },
    { id: 3, value: 'Note' },
    // { id: 4, value: 'Product formula cost (Material cost + Service cost with markup)' },
    { id: 5, value: 'Product formula cost' },
    { id: 6, value: markupType ? ((markupType == 2 && hasMarkupPermission) ? 'Retail Price Each' : null) : null },
    { id: 7, value: markupType ? ((markupType == 1 && hasMarkupPermission) ? 'Retail Price Each' : null) : null },
    { id: 14, value: hasMarkupPermission ? 'SPMA' : null },
    // { id: 8, value: hasMarkupPermission ? 'Markup (%)' : null },
    { id: 9, value: 'Unit' },
    { id: 10, value: 'Quantity' },
    { id: 11, value: 'Location' },
    { id: 11, value: 'Marketing Adjustment Type' },
    { id: 12, value: 'Marketing Adjustment' },
    // { id: 12, value: 'Discount' },
    { id: 13, value: 'Subtotal' },
    { id: 15, value: '' }
  ];

  const VALUEPreview = [
    { id: 0, value: 'Sl No.' },
    { id: 1, value: 'Products & Notes' },
    // { id: 2, value: 'Product formula cost (Material cost + Service cost with markup)' },
    { id: 3, value: markupType ? ((markupType == 2 && hasMarkupPermission) ? 'Retail Price Each' : null) : null },
    { id: 4, value: markupType ? ((markupType == 1 && hasMarkupPermission) ? 'Retail Price Each' : null) : null },
    { id: 14, value: hasMarkupPermission ? 'SPMA' : null },
    // { id: 5, value: hasMarkupPermission ? 'Markup (%)' : null },
    { id: 6, value: 'Unit' },
    { id: 7, value: 'Quantity' },
    // { id: 11, value: 'Marketing Adjustment Type' },
    { id: 8, value: 'Marketing Adjustment' },
    // { id: 8, value: 'Discount' },
    { id: 9, value: 'Subtotal' },
    { id: 10, value: '' }
  ]

  const VALUE_SERVICES = [
    { id: 0, value: 'Product Sl No.' },
    { id: 1, value: 'Service Name' },
    { id: 2, value: 'Markup (%)' },
    { id: 3, value: 'Cost (Hourly)' },
    { id: 4, value: 'Markup Cost (Hourly)' },
    { id: 5, value: 'Hours' },
    { id: 6, value: 'Location' },
    { id: 7, value: 'Product Formula Cost'},
    { id: 8, value: 'Additional Cost' },
    { id: 9, value: 'Total cost' },
    { id: 10, value: '' }
  ];
  const VALUE_TIME = [
    { id: 1, value: 'Sl No.' },
    { id: 2, value: 'Products' },
    { id: 3, value: 'Note' },
    { id: 4, value: 'Retail Price Each'},
    { id: 14, value: 'SPMA' },
    { id: 5, value: 'Unit' },
    { id: 6, value: 'Quantity' },
    { id: 7, value: 'Location' },
    { id: 11, value: 'Marketing Adjustment Type' },
    { id: 8, value: 'Marketing Adjustment' },
    { id: 9, value: 'Subtotal' },
    { id: 10, value: '' }
  ];
  const VALUE_TIME_Preview = [
    { id: 1, value: 'Sl No.' },
    { id: 2, value: 'Products' },
    // { id: 3, value: 'Note' },
    { id: 4, value: 'Retail Price Each'},
    { id: 14, value: 'SPMA' },
    { id: 5, value: 'Unit' },
    { id: 6, value: 'Quantity' },
    // { id: 7, value: 'Location' },
    // { id: 11, value: 'Marketing Adjustment Type' },
    { id: 8, value: 'Marketing Adjustment' },
    { id: 9, value: 'Subtotal' },
    { id: 10, value: '' }
  ];
  const VALUE_MATERIAL=[
    {id: 0, value: 'Material'},
    {id: 1, value: 'Unit Price'},
    {id: 2, value: 'Markup (%)'},
    {id: 3, value: 'Markup Cost (Unit)' },
    {id: 4, value: 'Quantity'},
    {id: 5, value: 'Product Formula Cost'},
    {id: 6, value: 'Total'},
    {id: 7, value: '' }
  ]
  const VALUE_RFP_Product = [
    { id: 0, value: ' Name' },
    { id: 1, value: 'Mark Up' },
    { id: 2, value: 'Cost ' },
    { id: 3, value: 'Selling Price' },
    { id: 4, value: 'Unit' },
    { id: 5, value: 'Quantity' },
    { id: 6, value: 'Product Formula Cost'},
    { id: 7, value: 'Total' },
    { id: 8, value: '' },
    { id: 9, value: '' }
  ];
  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue)
    // setRefresh(true)
    console.log('tab--newValuenewValue--',newValue)
    if(newValue == 'Locations and Notes'){
      // setRefresh(!refresh);
      fetchRequestDetails();
    }
    if(newValue == "Sales Order Details"){
      setRenderCards(!renderCards)
    }
  }
  const HandleClickmenu = () => {
    setAddClient(true)
  }
  
  const fetchTaxCategory = async () => {
    try{
        const response = await axios.post(`${REST_API_END_POINT}product/get-tax-category`)
        if(response.data.status === 1){
            setTaxCategories(response.data.taxResult);
        }else{
          setTaxCategories([])
        }
    }catch(error){
        console.log("Error - ",error);
        setTaxCategories([])
    }
  }

  //add clients
  const fetchAllRequestClients = async () => {

    try {
      const res = await axios.post(`${REST_API}webservice/request/fetch-all-clients-request`, { requestId: id })

      await setAllClients(res.data.data)
    } catch (error) {
      console.log(error, 'Error in fetching all clients')
    }
  }

  useEffect(() => {
    fetchAllRequestClients()
  }, [addClient])
  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    //  avatarUrl: Yup.mixed().required('Image is required'),
    // skills:userType!=3 && Yup.array().min(1, 'Skill is required'),
    // workflow:userType!=3 && Yup.array().min(1, 'Workflow is required'),
    start: Yup.mixed().required('Start Date is required'),
    end: Yup.date()
      .nullable()
      .transform((value, originalValue) => {
        return originalValue ? new Date(originalValue * 1000) : null;
      })
      .when('start', (start, schema) => {
        return start
          ? schema.min(
            start,
            'Installation Date must be greater than the Start Date'
          )
          : schema;
      }),
    products: userType == 1 && Yup.array(),
    //.min(1, 'At least one product is required')
    // services:userType!=3 && Yup.array().min(1, 'At least one service is required'), 
    markup_type: userType != 3 && Yup.object().nullable().required('Please select the markup type'),
    project_type: Yup.object().nullable().required('Job Class is required'),
    project_category: Yup.object().nullable().required('Job Type is required'),
    map_service: Yup.mixed().when('project_category', {
      is: (project_category) => userType !== 3 && project_category?.id == 14,
      then: Yup.object().nullable().required('Please select the map service'),
      otherwise: Yup.mixed().nullable(),
    }),
    community: Yup.object().nullable().required('Project is required'),
  });
  //
  const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    //   marginBottom: theme.spacing(1)
  }));
  const LabelStyleHead = styled(Typography)(({ theme }) => ({
    ...theme.typography.h5,
    color: theme.palette.text.secondary,
  }))
  //delete official request 
  const handleDeleteRFQ = async () => {
    try {
      const data = { request_id: id,request:true }
      const res = await axios.post(`${REST_API}webservice/delete-request/id`, data)

      if (res.data.data == true) {
        enqueueSnackbar('Delete success', { variant: 'success' });
        // 
        if(popupOn){
          closeView()
          localStorage.removeItem('salesOrderProjectId');
          localStorage.removeItem('salesOrderProjectName');
          localStorage.removeItem('isEditSalesOrder');
          localStorage.removeItem('salesOrderOpenPopup',);
          setRenderCards(!renderCards)
          onDataChange()
        }else{
          navigate(PATH_DASHBOARD.official.root)
        }
      }
    } catch (error) {
      console.log(error, 'Error in deleting the order')
    }

  }
  //fetch request details
  const fetchRequestDetails = async () => {
    try {
      setLoadingEdit(true)
      const res = await axios.post(`${REST_API}webservice/view-request`, { request_id: id, userId, userType });
      res.data;
      setTeamMember(res.data.team)
      setCustomerData(res.data.customerData)
      setAvatarUrl(res.data.attachments)
      setDetails(res.data?.data[0])
      setLabourCostServices(res.data.labourCostServices)
      setFilteredServices(res.data.labourCostServices)
      setRequestServices(res.data.requestServices.filter(row => row.rfp === 0))
      setRfpServicesEdit(res.data.requestServices.filter(row => row.rfp === 1))
      setClientApprove(res.data?.data[0]?.client_approve)
      console.log("setDetailssetDetailssetDetails",res.data.data)
      setRfps(res.data.allProposals)
      setMarkupType(res.data?.data[0]?.markup_type)
      setDesigns(res.data?.attachments)
      setSkill(res.data.skill)
      setWorkFlow(res.data.workflows)
      setAllproducts(res.data.products)
      // setAllMaterials(res.data.materials)   15-01-25 previos
      if (editState) {
        setOpenEdit(true)
        console.log('truetruetruetrue==editState',editState)
      }
      //new
      const allMatData = Array.isArray(res.data.materials) 
      ? res.data.materials.map((material) => ({
          ...material,
          markup: Number(material.retail_price || 0), 
        }))
      : [];
      console.log('allMatData---2',allMatData)
      setAllMaterials(allMatData?.filter(item=>item.isAdditionalProduct == 0))
      
      setAcceptedClients(res.data.requestAcceptedPartners)
      setUpdates(res.data.updates)
      setPayments(res.data.payments)
      setCustomTemplate(res.data.fieldTemplates)
      setQuestionnaireTemplate(res.data.QuestionTemplates)
      setSignAgreements(res.data.signAgrementData)
      setDataEmpty(res.data.allDataEmpty)
      setPortfolio(res.data.portfolio)
      setRefresh(false)
      setProposalData(res.data.requestProposals)
      if(res.data?.data[0].project_category == 13){
        setNoCharge(true)
      }
      // if (res.data?.data[0]?.is_location === 1) {
      //   setAllLocationSame(true)
      // }
      // if(res.data?.data[0]?.is_service_location ==1 || res.data?.data[0]?.is_service_location !=0) {
      //   conosle.log('if is_service_location',res.data?.data[0]?.is_service_location)
      //   setAllServiceLocationSame(true)
      // }else{
      //   conosle.log('else is_service_location',res.data?.data[0]?.is_service_location)
      //   setAllServiceLocationSame(false)

      // }

      // if(res.data?.data[0]?.is_rfp_service_location == 1){
      //   setAllRfpServiceLocationSame(true)
      // }else{
      //   setAllRfpServiceLocationSame(false)

      // }
      // if(res.data?.data[0]?.is_rfp_product_location == 1){
      //   setAllRfpProductsLocationSame(true)
      // }else{
      //   setAllRfpProductsLocationSame(false)
      // }

      // setTimeout(()=>{
      //   setLoading(false)
      // },2000)

    } catch (error) {
      console.error('Error fetching request details:', error);
    } 
    finally {
      setLoadingEdit(false)
    }
  };
  //fetch request details
  const fetchRequestDetailsNew = async () => {
    try {
      setLoading(true)
      const res = await axios.post(`${REST_API}webservice/view-request-new`,
        { request_id: id, userId, userType });
      setTeamMember(res.data.team)
      setCustomerData(res.data.customerData)
      setAvatarUrl(res.data.attachments)
      setDetails(res.data?.data[0])
      setDesigns(res.data?.attachments)
      setAcceptedClients(res.data.requestAcceptedPartners)
      setUpdates(res.data.updates)
      setPayments(res.data.payments)
      // setRefresh(false)
      // setTimeout(()=>{
      //   setLoading(false)
      // },2000)
    } catch (error) {
      console.error('Error fetching request details:', error);
    } finally {
      setLoading(false)
    }
  };

  const fetchAllCommunityByClient = async (clientId) => {
    setIsCommunityLoading(true);
    try {
      clientId = clientId.id
      const res = await axios.post(`${REST_API}webservice/rfq/get-community-by-client`, { clientId })
      let data = res.data?.community
      await setCommunities(data)
    } catch (error) {
      console.log(error, 'Error in fetching all clients');
      setCommunities([]);
    } finally {
      setIsCommunityLoading(false)
    }
  }

  const fetchAllMapServices = async () => {
    setMapServicesLoading(true);
    try {
      const res = await axios.post(`${REST_API}webservice/route/fetch-routes-with-locations`,{ requestId : id })
      let data = res.data?.routeData
      if(data){
        const newData = data?.filter((i) => i.locations != null)
        if(newData){
          setMapServices(newData);
        }else{
          setMapServices([]);
        }
      }else{
        setMapServices([]);
      }
    } catch (error) {
      setMapServices([]);
      console.log(error, 'Error in fetching map services');
    }finally {
      setMapServicesLoading(false)
    }
  }

  const fetchAllClients = async () => {
    try {
      const res = await axios.post(`${REST_API}webservice/clients/getAllClients`, { userType, userId: user.user_id,order:true })
      let data = res.data?.customer
      await setAllClientsEdit(data)
    } catch (error) {
      console.log(error, 'Error in fetching all clients', error)
    }
  }

  useEffect(() => {
    console.log('id=outside=3==',id)
    fetchRequestDetailsNew()
    fetchRequestDetails()
    fetchTaxCategory()
    fetchAllMapServices()
    fetchAllClients()

    // setFieldValue('markup_type', markupTypes[1])
    setMarkupType(markupTypes[1]?.id)
  }, [id, refresh])
  console.log('reqqqqqqqqqqqqqqqqqqq details',details)
  console.log('reqqqqqqqqqqqqqqqqqqq invoice',invoice)
  useEffect(() => {
   
    try {
      axios.post(`${REST_API_END_POINT}project/get-data-for-add-project`, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if (response.data.status === 1) {
          setProjectTypes(response.data.projectTypes)
          setProjectCategories(response.data.projectCategories)
          setNoChargeType(response.data.noChargeType)
          // setCommunities(response.data.community)

        }
      }).catch((error) => {
        console.error(error);
      })
    }
    catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [])
  //rfq product detais
  const fetchOrderProductDetails = async () => {
    try {
      setLoadingEdit(true)
      let orderId = details?.id;
      if (orderId && (orderId != 'undefined')) {
        const res = await axios.post(`${REST_API}webservice/get-customer-order-product/${orderId}`);
        const invoiceData = res.data.data
        let jobcostTotal = 0
        let jobcostServiceTotal = 0
        if(invoiceData?.jobcost_product && invoiceData?.jobcost_product?.length > 0){
          jobcostTotal = invoiceData?.jobcost_product?.reduce((sum, product) => {
            return sum + (product.total || 0);
          }, 0);
        }
        if(invoiceData?.jobcost_service && invoiceData?.jobcost_service?.length > 0){
          jobcostServiceTotal = invoiceData?.jobcost_service?.filter(it=>it.total_cost == 0)?.reduce((sum, product) => {
            return sum + (parseFloat(product.total_service_cost) || 0);
          }, 0);
        }
        const total = invoiceData.total || 0;
        const extraDiscount = parseFloat(invoice?.extra_discount) || 0;
        const taxAmount = parseFloat(invoice?.tax_amount) || 0;
        const totalPaidAmount = details?.totalPaidAmount || 0;

        // let grandTotal = (total + (jobcostTotal || 0) + (jobcostServiceTotal || 0)) - extraDiscount + taxAmount;
        const grandTotal = invoiceData?.grandTotal || 0;
        let balanceDue = grandTotal - totalPaidAmount;

        const updatedInvoiceData = {
          ...invoiceData,
          jobcostTotal,
          jobcostServiceTotal,
          grandTotal : grandTotal || invoiceData?.total,
          newBalanceDue : balanceDue
        };
        console.log('updatedInvoiceData===',updatedInvoiceData)
        setInvoice(updatedInvoiceData);


        const rfqServicesData = updatedInvoiceData?.cust_product?.flatMap(product => {
          console.log('product1234', product)
          return product.services.map(service => ({
            ...service,
            productIndex: product.product_index,
            product_id: product.product_id,
            qtyData: product.quantity,
            locationQuantity: product.locationQuantity || 1,
            productName :product?.product_name
    
          }));
        });
        let labourInstallData =rfqServicesData?.filter(item =>item.labour_cost_service_id == 10)
        setLabourInstallService(labourInstallData)

        setCommunities(res.data.community)

        let rfqCommunity = res.data.community?.find(com => details.community === com.id)

        if(rfqCommunity){
          const { address, city, state, zip } = rfqCommunity;
          setAdditionalSelectedServices((prevServices) =>
            prevServices.map((service) => {
              const updatedAddresses = service.addresses.map((loc) => ({
                ...loc,
                address: address || loc.address,
                city: city || loc.city,
                state: state || loc.state,
                zip: zip || loc.zip,
              }));
              return { ...service, addresses: updatedAddresses };
            })
          );
        }

        return res.data;
      }
    } catch (error) {
      console.error('Error fetching request details:', error);
      throw error;
    }
    finally{
      setLoadingEdit(false)
    }
  };

  // useEffect(()=>{

    
  //   let rfqCommunity = communities?.find(com => details.community === com.id)
  //   console.log("additionalSelectedServicesrfqCommunity",rfqCommunity)
  //   console.log("additionalSelectedServicesadditionalSelectedServices",additionalSelectedServices)

  //   if(rfqCommunity){
  //     const { address, city, state, zip } = rfqCommunity;
  //     setAdditionalSelectedServices((prevServices) =>
  //       prevServices.map((service) => {
  //         const updatedAddresses = service.addresses.map((loc) => ({
  //           ...loc,
  //           address: address || loc.address,
  //           city: city || loc.city,
  //           state: state || loc.state,
  //           zip: zip || loc.zip,
  //         }));
  //         return { ...service, addresses: updatedAddresses };
  //       })
  //     );
  //   }

  //   assignProjectAgain()

  // },[communities,details,loadingEdit])

  const assignProjectAgain = (rfqCommunity, shouldCallAssignProject, shouldCallAssignProjectRFP) => {
    console.log('New Value:', rfqCommunity);
    const { address, city, state, zip } = rfqCommunity;
    if (shouldCallAssignProject) {
      setAdditionalSelectedServices((prevServices) =>
        prevServices.map((service) => {
          const updatedAddresses = service.addresses?.map((loc) => ({
            ...loc,
            address: address ?? loc.address,
            city: city ?? loc.city,
            state: state ?? loc.state,
            zip: zip ?? loc.zip,
          })) ?? []; // Ensures addresses is never undefined
  
          return { ...service, addresses: updatedAddresses };
        })
      );
    }
    if (shouldCallAssignProjectRFP) {
      setCartService((prevServices) =>
        prevServices.map((service) => {
          const updatedAddresses = service.addresses?.map((loc) => ({
            ...loc,
            address: address ?? loc.address,
            city: city ?? loc.city,
            state: state ?? loc.state,
            zip: zip ?? loc.zip,
          })) ?? []; 
  
          return { ...service, addresses: updatedAddresses };
        })
      );
    }
  };

  const handleSendEmail = async () => {
    try {
      let orderId = details?.id;
      const res = await axios.post(`${REST_API}webservice/settings/send-mail`, { id: orderId, allInstallationCompleted:details?.allInstallationCompleted || null });

      if (res.data.status === 1) {
        enqueueSnackbar('Email send successfully', { variant: 'success' });
        setOpenInvoice(false)
      }
      return res.data;
    } catch (error) {
      console.error('Error fetching request details:', error);
      throw error;
    }
  };
  const fetchRequestServicesDetails = async () => {
    try {
      let orderId = details?.id;
      const res = await axios.post(`${REST_API}webservice/get-request-services/${orderId}`);
      setInvoice(res.data.data);
      return res.data.data;
    } catch (error) {
      console.error('Error fetching request details:', error);
      throw error;
    }
  };


  const addingInitialdataRFQ = async () => {
    setLoadingEdit(true)
    try {
      if (openEdit) {
        let servicesArray = details?.services?.split(',')?.map(Number);
        let workflowSet = details?.workflows?.split(',')?.map(Number);
        const newInvoiceData = await fetchOrderProductDetails();
        const invoiceData = newInvoiceData?.data
        const communityData = newInvoiceData?.community
        const rfqTaxCategory = taxCategories?.find(cat => invoiceData.tax_category === cat.id) || null
        setSelectedTaxCategory(rfqTaxCategory)
        // let rfqSkill = skills?.filter((row) => row.id == details?.services);
        // let rfqSkill = skills.filter(skill => servicesArray.includes(skill.id));
        // let rfqWorkFlow = workflow?.filter(skill => workflowSet?.includes(skill.id));
        let rfqProjectType = projectTypes?.find(type => details.project_type === type.id)
        let rfqCustomerData = allClientsEdit?.find(cat => cat?.id === customerData?.customer_id)
        let rfqProjectcategory = projectCategories?.find(cat => details.project_category === cat.id)
        let rfqNOChargeTypes = noChargeType?.find(cat => details.no_charge_type === cat.id)
        let rfqMarkupType = markupTypes?.find(cat => details?.markup_type === cat.id)
        let rfqMapServices = mapServices?.find(cat=>details?.map_service === cat.id)   
        let rfqCommunity = communityData?.find(com => details.community === com.id)

        // rfqProduct = invoiceData?.cust_product.filter((row) => {
        //   return allProducts.filter(data => data.id == row.product_id)
        // })
        let rfqProduct = [];
        // if(rfqProjectcategory?.name == 'Time & Material'){
        //   rfqProduct = invoiceData?.cust_product?.filter((newItem)=>(newItem.material != 1 && newItem.rfp != 1))?.map((row) => {
        //       return {
        //         ...row,
        //         indexId: row.product_index,
        //         retail_price: row.product_retail_price || row.retail_price || 0,
        //         price:row.product_price || row.price || 0,
        //         expected_cost:0,
        //         expected_total_service_cost:0,
        //         expected_service_cost:0,
        //         expected_material_cost:0,
        //         markAdjType:row.markAdjType

        //       };
        //   }).filter(item => item !== undefined);
        // }else{
          rfqProduct = invoiceData?.cust_product?.filter((row) => row.rfp === 0 && row.material===0)?.map((row) => {
            let match = allProducts.find(data => data.id == row.product_id);
            if (match) {
              // If a match is found, return a new object with data from allProducts and quantity from invoiceData?.cust_product
              return {
                ...match,
                indexId: row.product_index,
                product_notes: row.product_notes,
                discount: row.discount,
                adjustment: row.adjustment,
                quantity: row.quantity,
                location: row.location,
                allLocationSame:row.allLocationSame == 1 ? true : false,
                locationQuantity: row.locationQuantity,
                markAdjType:row.markAdjType,
                fromMapServices : row.map_service == 1 ? true : false

              };
            } return undefined;
          }).filter(item => item !== undefined);
        // }

        console.log("####################RFQ", rfqProduct);
        console.log("invoiceData----", invoiceData);

        const highestIndexId = rfqProduct?.reduce((max, product) => {
          return typeof product.indexId === 'number' && product.indexId > max ? product.indexId : max;
        }, 0);

        setIndexCounter(highestIndexId);
        const filteredData = rfqProduct?.filter(item=>item?.fromMapServices == true)
        setSelectedMapserviceProducts(filteredData || [])
        setSelectedProducts(rfqProduct || [])
        


        let rfpProduct = invoiceData?.cust_product.filter((row) => row.rfp === 1 && row.material===0)
        console.log('rfpProduct---', rfpProduct)
        let materialsData = invoiceData?.cust_product.filter(row => row.material === 1 && row.rfp === 0);

        let rfpProductUpdated = rfpProduct?.map(item => ({
          ...item,
          allRfpProductsLocationSame:item.allLocationSame == 1 ? true : false,
        }))
        console.log('rfpProduct---rfpProductUpdated', rfpProductUpdated)

        let materialsWithPrices = materialsData.map((material,index) => {
          let matchedMaterial = materials.find(item => item.id === material.product_id);
          return {
            ...material,
            newIndexId: index + 1,
            price: matchedMaterial ? matchedMaterial.price : material.price,
            inhouse : matchedMaterial ? matchedMaterial.inhouse : 1
          };
        });
        setSelectedMaterials(materialsWithPrices)
        setCart(rfpProductUpdated);
        // const rfpServices = rfpServicesEdit?.filter(item => item?.recipe_id === null && item?.rfp === 1);
        const rfpServices = rfpServicesEdit?.filter(item => item?.recipe_id === null && item?.rfp === 1)?.map(serviceItem => ({
          ...serviceItem,
          location: [
            {
              address: serviceItem.address,
              city: serviceItem.city,
              state: serviceItem.state,
              zip: serviceItem.zip,
            }
          ],
        }));

        
        setCartService(rfpServices)
        console.log("####################rfpProduct", rfpProduct);
        console.log("####################rfpServices", rfpServices);



        // const serviceIdArray = details?.labour_cost_services?.split(',')?.map(Number);
        // const rfqServices = invoiceData?.cust_product?.map((row)=>
        //   labourCostServices?.find(data => data.id == row.id)
        // )
        //   const rfqServices = invoiceData?.cust_product?.map(product => {
        //     const productServices = product.services?.map(service => {
        //         const serviceData = labourCostServices.find(data => data.id === service.id);
        //         return serviceData;
        //     });

        //     // Return an object containing product details along with its services
        //     return productServices;
        // });
        const rfqServicesData = invoiceData?.cust_product?.flatMap(product => {
          console.log('product123', product)
          return product.services.map(service => ({
            ...service,
            productIndex: product.product_index,
            product_id: product.product_id,
            qtyData: product.quantity,
            fromMapServices : product.map_service == 1 ? true : false,
            locationQuantity: product.locationQuantity || 1
          }));
        });
        console.log("##################rfqServiceData", rfqServicesData);
        console.log("##################labourCostServices", labourCostServices);
        console.log("##################filteredServices", filteredServices);
        await setSelectedServices(rfqServicesData || [])

        const filteredServicesNew = rfqServicesData.length === 0
          ? labourCostServices
          : labourCostServices.filter((service) => !rfqServicesData.find((selectedService) => selectedService.labour_cost_service_id === service.id));
        setFilteredServices(filteredServicesNew);

        const additionalServices = requestServices?.filter(item => item?.recipe_id === null);
        const additionalServicesData = additionalServices || [];
        let newIndexCounter = serviceIndexCounter
        const updatedServicesData = additionalServicesData?.map((serviceItem, index) => {
          newIndexCounter = newIndexCounter + 1
          setServiceIndexCounter(newIndexCounter);
          console.log('newIndexCounter-------',newIndexCounter)
          return {
            ...serviceItem,
            indexId:newIndexCounter,
            newIndexId:index+1,
            productId : serviceItem.labour_cost_service_id,
            addresses: [
              {
                address: serviceItem.address,
                city: serviceItem.city,
                state: serviceItem.state,
                zip: serviceItem.zip,
              }
            ]
          };
        });
        console.log('updatedServicesData----',updatedServicesData);
        
        setAdditionalSelectedServices(updatedServicesData);

        const shouldCallAssignProject = (updatedServicesData ?? []).some((service) =>
          (service.addresses ?? []).some((loc) =>
            loc.address == null || loc.city == null || loc.state == null || loc.zip == null
          )
        );
        
        const shouldCallAssignProjectRFP = (rfpServices ?? []).some((service) =>
          (service.addresses ?? []).some((loc) =>
            loc.address == null || loc.city == null || loc.state == null || loc.zip == null
          )
        );
        
        if(rfqCommunity && (shouldCallAssignProject || shouldCallAssignProjectRFP)){
          assignProjectAgain(rfqCommunity, shouldCallAssignProject, shouldCallAssignProjectRFP);
        }
      
        setCurrentRFQdata({
          name: details?.name,
          description: details?.description,
          notes: details?.notes,

          // skills: rfqSkill,
          // workflow: rfqWorkFlow,
          client:rfqCustomerData,
          project_category: rfqProjectcategory,
          no_charge_type: rfqNOChargeTypes,
          markup_type: rfqMarkupType,
          community: rfqCommunity,
          map_service: rfqMapServices,
          project_type: rfqProjectType,
          start: details?.submission_deadline,
          end: details?.participation_ends,
          labour_cost: details?.labour_cost,
          budget: invoiceData?.budget || 0,
          products: rfqProduct || null,
          services: additionalServicesData || null,
          invoice_discount: invoiceData?.extra_discount,
          welcome_message: details?.welcome_message == 1 ? true : false || null,
          total: invoiceData?.total,
          taxAmount: invoiceData?.taxAmount || 0,
          tax: invoiceData?.tax,
          draft: details?.status == 1 ? true : false || null,
          sub_total: invoiceData?.sub_total,
          order_id: invoiceData?.id,
          subject: details?.subject || null,
          email_content: details?.email_content || null,
          estimate_id:details?.estimate_id
          // avatarUrl:details?.thumbnail||null
        });
      }
    } catch (error) {
      console.error('Error adding initial data for Sales Order:', error);
    } finally {
      setTimeout(() => {
        setLoadingEdit(false)
      }, 3000);
    }
  };

  useEffect(() => {
    if (details) {
      fetchOrderProductDetails();
    
 if(details?.is_service_location ==1 || details?.is_service_location !=0) {
      setAllServiceLocationSame(true)
    }else{
      setAllServiceLocationSame(false)
    }

    if(details?.is_rfp_service_location == 1){
      setAllRfpServiceLocationSame(true)
    }else{
      setAllRfpServiceLocationSame(false)
    }
    if(details?.is_rfp_product_location == 1){
      setAllRfpProductsLocationSame(true)
    }else{
      setAllRfpProductsLocationSame(false)
    }
   
  }
  }, [details]);

  useEffect(() => {
    setLoadingEdit(true)
    addingInitialdataRFQ();
    setTimeout(() => {
      setLoadingEdit(false)
    }, 3000)
  }, [openEdit]);

  // The below useEffect is used for fetching the invoice details only
  useEffect(() => {
    fetchOrderProductDetails();
    console.log('requestServices--', requestServices);

    const rfqServicesData = invoice?.cust_product?.flatMap(product => {
      console.log('product1234', product)
      return product.services.map(service => ({
        ...service,
        productIndex: product.product_index,
        product_id: product.product_id,
        qtyData: product.quantity,
        locationQuantity: product.locationQuantity || 1,
        productName :product?.product_name

      }));
    });
    let labourInstallData =rfqServicesData?.filter(item =>item.labour_cost_service_id == 10)
    setLabourInstallService(labourInstallData)
    console.log("##################rfqInvoiceServiceData", rfqServicesData);
    const newrfqServicesData = rfqServicesData?.filter(item => {
      const newQty = parseFloat((item.prQty * item.qtyData * item.locationQuantity)?.toFixed(2));
      console.log('rfqInvoiceServiceDatanewQty-----------', newQty);
      console.log('rfqInvoiceServiceDataitem.qty-----------', item.qty);
      return newQty !== item.qty;
    }).map(item => {
      const newQty = item.prQty * item.qtyData * item.locationQuantity;
      let quantityDifference = 0;
      if(details?.job_category_name === 'Time & Material'){
        quantityDifference = 0;
      }else{
        quantityDifference = item.qty - newQty;
      }
      return {
        ...item,
        quantity: quantityDifference,
        quantityDifference: quantityDifference,
        total_cost: item.unit_cost * quantityDifference
      };
    });

    console.log('newrfqServicesData-------', newrfqServicesData);

    setInvoiceSelectedServices(newrfqServicesData || [])

    const additionalServices = requestServices?.filter(item => item?.recipe_id === null);
    const additionalServicesData = additionalServices || [];
    const updatedServicesData = additionalServicesData?.map((serviceItem)=>{
      return{
        ...serviceItem,
        productId : serviceItem.labour_cost_service_id,
        addresses: [
          {
            address: serviceItem.address,
            city: serviceItem.city,
            state: serviceItem.state,
            zip: serviceItem.zip,
          }
        ]
      }
    })
    console.log('updatedServicesData----',updatedServicesData);
    setAdditionalSelectedServices(updatedServicesData);
    const rfpServices = rfpServicesEdit?.filter(item => item?.recipe_id === null && item?.rfp === 1);
   
    setCartService(rfpServices)
  }, [openInvoice]);



  const invoiceDate = moment.unix(details?.timestamp).format('MM/DD/YYYY')

  //edit rfq
  const handleEditClick = () => {
    // setOpenEdit(!openEdit)
    setOpenEdit(true)

  }
  const handleDeleteMenu = () => {
    setOpenDelete(!openDelete)
  }
  const handleReopen = () => {
    setReopen(!reOpen)
  }

  const addNewProductRow = () => {
    const newIndexCounter = indexCounter + 1
    setIndexCounter(newIndexCounter)
    const { address: projAddress = '', city: projCity = '', state: projState = '', zip: projZip = '' } = values?.community || {};
    // Auto-populate addresses from the selected Project
    const newAddresses = [{
      id: 0,
      address: projAddress,
      city: projCity,
      state: projState,
      zip: projZip
    }];
  
    setSelectedProducts((prevProducts) => {
    // if (allLocationSame && prevProducts.length > 0) {
    //     const commonAddress = { ...prevProducts[0].addresses[0] } || {
    //     address: '',
    //     city: '',
    //     state: '',
    //     zip: '',
    //     };
    //     newAddresses[0] = commonAddress;
    // }
    return [
    ...prevProducts,
    { 
        indexId: newIndexCounter, 
        name: '', 
        quantity: 1, 
        price: 0,
        retail_price:0, 
        adjustment:0,
        unit:4,
        locationQuantity: 1, 
        expected_total_service_cost:0,
        expected_service_cost:0,
        expected_material_cost:0,
        discount: 0 ,
        allLocationSame: true,
        service:[],
        isAdditionalProduct:true,
        location: newAddresses
    },
    ]});
  };

  // const addNewServiceRow = () => {
  //   const { address: projAddress = '', city: projCity = '', state: projState = '', zip: projZip = '' } = values?.community || {};
  //   // Auto-populate addresses from the selected Project
  //   const newAddresses = [{
  //     id: 0,
  //     address: projAddress,
  //     city: projCity,
  //     state: projState,
  //     zip: projZip
  //   }];

  //   setAdditionalSelectedServices((prevServices) => {
  //     return [
  //       ...prevServices,
  //       {  
  //         id:null,
  //         newIndexId: prevServices?.length + 1,
  //         name: '', 
  //         avatarUrl: null,
  //         quantity: 1, 
  //         cost: 0,
  //         markup: 0, 
  //         workflow: null,
  //         originalMarkup: 0, 
  //         service_type: null,
  //         total_cost:0,
  //         product_total:0,
  //         allLocationSame: true,
  //         isAdditionalProduct:true,
  //         addresses: newAddresses,
  //       }
  //     ]
  //   });
  // };

  const addNewServiceRow = () => {
    const {
      address: projAddress = '',
      city: projCity = '',
      state: projState = '',
      zip: projZip = ''
    } = values?.community || {};
  
    const newAddresses = [{
      id: 0,
      address: projAddress,
      city: projCity,
      state: projState,
      zip: projZip
    }];
  
    setAdditionalSelectedServices((prevServices) => {
      const maxId = prevServices.reduce(
        (max, item) => Math.max(max, item.newIndexId || 0),
        0
      );
  
      return [
        ...prevServices,
        {
          id: null,
          newIndexId: maxId + 1,
          // newIndexId: prevServices?.length + 1,
          name: '',
          avatarUrl: null,
          quantity: 1,
          cost: 0,
          markup: 0,
          workflow: null,
          originalMarkup: 0,
          service_type: null,
          total_cost: 0,
          product_total: 0,
          allLocationSame: true,
          isAdditionalProduct: true,
          addresses: newAddresses,
        }
      ];
    });
  };

  
  // const addNewMaterialRow = () => {
  //   setSelectedMaterials((prevProducts) => {
  //   return [
  //   ...prevProducts,
  //   { 
  //     newIndexId: prevProducts?.length + 1,
  //     quantity: 1, 
  //     allLocationSame: true,
  //     isAdditionalProduct:true,
  //     description: '',
  //     finishedProductQty: 0,
  //     markUpCost: 0,
  //     markup: 0,
  //     material_types: 1,
  //     max_price: null,
  //     max_value: 10,
  //     meterial: 1,
  //     min_value: 1,
  //     name: '',
  //     partial: 0,
  //     partialProductQty: 0,
  //     pf_retail_markup: null,
  //     price: 0,
  //     recipe_id: null,
  //     retail_price: 0,
  //     special_price: 0,
  //     tags: '',
  //     tax: null,
  //     tax_category: 0,
  //     total_qty: 0,
  //     twilio_field_value_sid: null,
  //     unit: 4,
  //     unit_qty: 0,
  //     vendor_id: '',
  //     vpt_number: '',
  //     workflow: null,
  //   },
  //   ]});
  // };

  const addNewMaterialRow = () => {
    setSelectedMaterials((prevProducts) => {
      const maxId = prevProducts.reduce(
        (max, item) => Math.max(max, item.newIndexId || 0),
        0
      );
  
      return [
        ...prevProducts,
        {
          newIndexId: maxId + 1,
          // newIndexId: prevProducts?.length + 1,
          quantity: 1,
          allLocationSame: true,
          isAdditionalProduct: true,
          description: '',
          finishedProductQty: 0,
          markUpCost: 0,
          markup: 0,
          material_types: 1,
          max_price: null,
          max_value: 10,
          meterial: 1,
          min_value: 1,
          name: '',
          partial: 0,
          partialProductQty: 0,
          pf_retail_markup: null,
          price: 0,
          product_price:0,
          recipe_id: null,
          retail_price: 0,
          special_price: 0,
          tags: '',
          tax: null,
          tax_category: 0,
          total_qty: 0,
          twilio_field_value_sid: null,
          unit: 4,
          unit_qty: 0,
          vendor_id: '',
          vpt_number: '',
          product_retail_price:0,
          workflow: null,
        }
      ];
    });
  };
  

  // const handleProduct = (value) => {
  
  //   // Extract community address from Formik's values
  //   const { address: projAddress = '', city: projCity = '', state: projState = '', zip: projZip = '' } = values?.community || {};
  //   const communityAddress = { address: projAddress, city: projCity, state: projState, zip: projZip };
  
  //   setSelectedProducts((prevProducts) => {
  
  //     // Determine common address if allLocationSame is enabled
  //     const commonAddress = allLocationSame && prevProducts.length > 0
  //       ? { ...prevProducts[0].location[0] }
  //       : null;
  
  //     const updatedProducts = value.map((product) => {
  //       const dataRetailPrice = product.product_retail_price || product.retail_price;
  //       const dataWholesalePrice = product.product_price || product.price || 0;
  
  //       // Check if the product already exists in the selected products
  //       const existingProduct = prevProducts.find((p) =>
  //         (p.id === product.id || p.id === product.product_id) &&
  //         p.indexId !== undefined &&
  //         p.indexId === product.indexId
  //       );
  
  //       console.log("###############ExistingProduct", existingProduct);
  
  //       if (existingProduct) {
  //         // Update existing product's quantity and location
  //         const quantity = existingProduct.quantity;
  //         const locationQuantity = existingProduct.locationQuantity;
  //         const marketingAdjustment = Number(existingProduct?.adjustment);
  
  //         // Preserve existing address if it exists, otherwise assign dynamically
  //         const location = existingProduct.location?.[0] || commonAddress || {
  //           id: 0,
  //           address: communityAddress.address || '',
  //           city: communityAddress.city || '',
  //           state: communityAddress.state || '',
  //           zip: communityAddress.zip || '',
  //         };
  
  //         const rowTotal = calculateTotal(
  //           markupType === 1 ? dataWholesalePrice : markupType === 2 ? dataRetailPrice : 0,
  //           product.dataQuantity,
  //           product.dataDiscount,
  //           quantity,
  //           locationQuantity,
  //           marketingAdjustment
  //         );
  
  //         return {
  //           ...existingProduct,
  //           quantity,
  //           rowTotal,
  //           location: [location], // Ensure location is always an array
  //         };
  //       } else {
  //         // Add new product with default quantity and location
  //         const location = commonAddress || {
  //           id: 0,
  //           address: communityAddress.address || '',
  //           city: communityAddress.city || '',
  //           state: communityAddress.state || '',
  //           zip: communityAddress.zip || '',
  //         };
  
  //         const newIndexCounter = indexCounter + 1;
  //         setIndexCounter(newIndexCounter);
  
  //         return {
  //           ...product,
  //           indexId: newIndexCounter,
  //           quantity: 1,
  //           locationQuantity: 1,
  //           discount: 0,
  //           adjustment: 0,
  //           markAdjType: 1,
  //           rowTotal: calculateTotal(
  //             markupType === 1 ? dataWholesalePrice : markupType === 2 ? dataRetailPrice : 0,
  //             product.dataQuantity,
  //             product.dataDiscount,
  //             1,
  //             1,
  //             0
  //           ),
  //           location: [location],
  //         };
  //       }
  //     });
  //     // Merge products from selectedMapserviceProducts with fromMapServices = true into the value
  //     const allSelectedProducts = [
  //       ...updatedProducts,
  //       ...selectedMapserviceProducts?.filter(product => product?.fromMapServices == true)
  //     ];
  //     const productServices = allSelectedProducts?.reduce((services, product) => {
  //       console.log("updatedProducts--product---------", product);
  //       if (product.services && product.services.length > 0) {
  //         return services.concat(
  //           product.services.map((service) => ({
  //             ...service,
  //             fromMapServices: product?.fromMapServices,
  //             productIndex: product.indexId,
  //             locationQuantity: 1,
  //           }))
  //         );
  //       }
  //       return services;
  //     }, []);
  
  //     setSelectedServices((prevServices) => {
  //       const filteredProductServices = productServices.filter((service) => {
  //         console.log("productServices-----service--", service);
  //         return !prevServices.some(
  //           (existingService) =>
  //             existingService?.labour_cost_service_id === service?.id &&
  //             existingService?.recipe_id === service?.recipe_id &&
  //             existingService?.product_id === service?.product_id &&
  //             existingService?.productIndex === service?.productIndex
  //         );
  //       });
  
  //       let updatedServices = prevServices.map((service) => ({
  //         ...service,
  //         quantity: service.quantity || 1,
  //         locationQuantity: service.locationQuantity || 1,
  //       }));
  
  //       updatedServices = updatedServices.filter((existingService) =>
  //         productServices.some(
  //           (service) =>
  //             (existingService?.labour_cost_service_id === service?.id ||
  //               existingService?.id === service?.id) &&
  //             existingService?.recipe_id === service?.recipe_id &&
  //             existingService?.product_id === service?.product_id &&
  //             existingService?.productIndex === service?.productIndex
  //         )
  //       );
  
  //       const filteredProductServicesUnique = filteredProductServices.filter((service) => {
  //         console.log("filteredProductServicesUnique--service---", service);
  //         return !updatedServices.some(
  //           (existingService) =>
  //             (existingService?.labour_cost_service_id === service?.id ||
  //               existingService?.id === service?.id) &&
  //             existingService?.recipe_id === service?.recipe_id &&
  //             existingService?.product_id === service?.product_id &&
  //             existingService?.productIndex === service?.productIndex
  //         );
  //       });
  
  //       return updatedServices.concat(filteredProductServicesUnique);
  //     });
  
  //     const filteredServices =
  //       productServices.length === 0
  //         ? labourCostServices
  //         : labourCostServices.filter(
  //             (service) =>
  //               !productServices.find(
  //                 (selectedService) => selectedService.id === service.id
  //               )
  //           );
  //     setFilteredServices(filteredServices);
  
  //     let initialServicesCost = 0;
  //     if (updatedProducts.length > 0) {
  //       initialServicesCost = updatedProducts.reduce((total, data) => {
  //         return (
  //           total +
  //           data.quantity *
  //             parseFloat(data.expected_total_service_cost?.toFixed(2))
  //         );
  //       }, 0);
  //     }
  
  //     setInitialServiceCostInProductFormula(initialServicesCost);
  //     return allSelectedProducts;
  //   });
  // };

  const handleProduct = (value) => {
  console.log('handleProduct---value--', value)
  console.log('handleProduct---selectedProducts--', selectedProducts)
  
    // Extract community address from Formik's values
    const { address: projAddress = '', city: projCity = '', state: projState = '', zip: projZip = '' } = values?.community || {};
    const communityAddress = { address: projAddress, city: projCity, state: projState, zip: projZip };
  
    setSelectedProducts((prevProducts) => {
  
      // Determine common address if allLocationSame is enabled
      // const commonAddress = allLocationSame && prevProducts.length > 0
      //   ? { ...prevProducts[0].location[0] }
      //   : null;
      const commonAddress = null;
      
      const updatedProducts = value.map((product) => {
        const dataRetailPrice = product.product_retail_price || product.retail_price;
        const dataWholesalePrice = product.product_price || product.price || 0;
  
        // Check if the product already exists in the selected products
        const existingProduct = prevProducts.find((p) =>
          (p.id === product.id || p.id === product.product_id) &&
          p.indexId !== undefined &&
          p.indexId === product.indexId
        );
  
        console.log("###############ExistingProduct", existingProduct);
  
        if (existingProduct) {
          // Update existing product's quantity and location
          const quantity = existingProduct.quantity;
          const locationQuantity = existingProduct.locationQuantity;
          const marketingAdjustment = Number(existingProduct?.adjustment);
          let location = [...existingProduct.location];
          // Preserve existing address if it exists, otherwise assign dynamically

          if (allLocationSame && commonAddress) {
            // Overwrite all location with the "common" location (from the first product)
            location = [...commonAddress];
          } else {
              // Ensure at least one address object
              for (let i = location.length; i < 1; i++) {
                location.push({
                  id: i,
                  address: '',
                  city: '',
                  state: '',
                  zip: '',
              });
              }
          }
  
          const rowTotal = calculateTotal(
            markupType === 1 ? dataWholesalePrice : markupType === 2 ? dataRetailPrice : 0,
            product.dataQuantity,
            product.dataDiscount,
            quantity,
            locationQuantity,
            marketingAdjustment
          );
  
          return {
            ...existingProduct,
            quantity,
            rowTotal,
            location: location, // Ensure location is always an array
          };
        } else {
          // Add new product with default quantity and location
          const location = commonAddress || {
            id: 0,
            address: communityAddress.address || '',
            city: communityAddress.city || '',
            state: communityAddress.state || '',
            zip: communityAddress.zip || '',
          };
  
          const newIndexCounter = indexCounter + 1;
          setIndexCounter(newIndexCounter);
  
          return {
            ...product,
            indexId: newIndexCounter,
            quantity: 1,
            locationQuantity: 1,
            discount: 0,
            allLocationSame: true,
            adjustment: 0,
            markAdjType: 1,
            rowTotal: calculateTotal(
              markupType === 1 ? dataWholesalePrice : markupType === 2 ? dataRetailPrice : 0,
              product.dataQuantity,
              product.dataDiscount,
              1,
              1,
              0
            ),
            location: [location],
          };
        }
      });
      // Merge products from selectedMapserviceProducts with fromMapServices = true into the value
      const allSelectedProducts = [
        ...updatedProducts,
        ...selectedProducts?.filter((item)=>item.isAdditionalProduct == true),
        ...selectedMapserviceProducts?.filter(product => product?.fromMapServices == true)
      ];
      const productServices = allSelectedProducts?.reduce((services, product) => {
        console.log("updatedProducts--product---------", product);
        if (product.services && product.services.length > 0) {
          return services.concat(
            product.services.map((service) => ({
              ...service,
              fromMapServices: product?.fromMapServices,
              productIndex: product.indexId,
              locationQuantity: 1,
            }))
          );
        }
        return services;
      }, []);
  
      setSelectedServices((prevServices) => {
        const filteredProductServices = productServices.filter((service) => {
          console.log("productServices-----service--", service);
          return !prevServices.some(
            (existingService) =>
              existingService?.labour_cost_service_id === service?.id &&
              existingService?.recipe_id === service?.recipe_id &&
              existingService?.product_id === service?.product_id &&
              existingService?.productIndex === service?.productIndex
          );
        });
  
        let updatedServices = prevServices.map((service) => ({
          ...service,
          quantity: service.quantity || 1,
          locationQuantity: service.locationQuantity || 1,
        }));
  
        updatedServices = updatedServices.filter((existingService) =>
          productServices.some(
            (service) =>
              (existingService?.labour_cost_service_id === service?.id ||
                existingService?.id === service?.id) &&
              existingService?.recipe_id === service?.recipe_id &&
              existingService?.product_id === service?.product_id &&
              existingService?.productIndex === service?.productIndex
          )
        );
  
        const filteredProductServicesUnique = filteredProductServices.filter((service) => {
          console.log("filteredProductServicesUnique--service---", service);
          return !updatedServices.some(
            (existingService) =>
              (existingService?.labour_cost_service_id === service?.id ||
                existingService?.id === service?.id) &&
              existingService?.recipe_id === service?.recipe_id &&
              existingService?.product_id === service?.product_id &&
              existingService?.productIndex === service?.productIndex
          );
        });
  
        return updatedServices.concat(filteredProductServicesUnique);
      });
  
      const filteredServices =
        productServices.length === 0
          ? labourCostServices
          : labourCostServices.filter(
              (service) =>
                !productServices.find(
                  (selectedService) => selectedService.id === service.id
                )
            );
      setFilteredServices(filteredServices);
  
      let initialServicesCost = 0;
      if (updatedProducts.length > 0) {
        initialServicesCost = updatedProducts.reduce((total, data) => {
          return (
            total +
            data.quantity *
              parseFloat(data.expected_total_service_cost?.toFixed(2))
          );
        }, 0);
      }
  
      setInitialServiceCostInProductFormula(initialServicesCost);
      return allSelectedProducts;
    });
  };
  
  console.log('selectedServices-------', selectedServices);
  console.log("currentttt" , currentRFQData)
  // const handleAllLocationSameChange = () => {

  //   console.log("##################Alllll", allLocationSame);
  //   if (!allLocationSame) {
  //     const commonAddress = selectedProducts[0]?.location[0] || {
  //       address: '',
  //       city: '',
  //       state: '',
  //       zip: '',
  //     };
  //     console.log("##################Alllll", commonAddress);
  //     setSelectedProducts(prevProducts =>
  //       prevProducts.map(product => ({
  //         ...product,
  //         location: product.location.map(() => commonAddress),
  //       }))
  //     );
  //   }
  //   setAllLocationSame(!allLocationSame);
  // };

  // const handleAllServiceLocationSameChange = () => {
  //   console.log("##################Alllll", allServiceLocationSame);
    
  //   if (!allServiceLocationSame) {
  //     // Ensure there's at least one service and its location array is valid
  //     const commonAddress = additionalSelectedServices[0]?.location?.[0] || {
  //       address: '',
  //       city: '',
  //       state: '',
  //       zip: '',
  //     };
  
  //     console.log("Common Address being applied:", commonAddress);
  
  //     // Update all locations to use the commonAddress
  //     setAdditionalSelectedServices(prevProducts =>
  //       prevProducts.map(product => ({
  //         ...product,
  //         location: product.location ? product.location.map(() => ({ ...commonAddress })) : [{ ...commonAddress }],
  //       }))
  //     );
  //   }
  
  //   setAllServiceLocationSame(!allServiceLocationSame);
  // };
  
  const handleAllLocationSameChange = (productIndex) => {
    setSelectedProducts(prevProducts =>
      prevProducts.map((product, index) => {
        if (index === productIndex) {
          console.log('productIndex--1---', index)
          console.log('productIndex--2---', productIndex)

          // Toggle allLocationSame flag
          const isAllSame = !product?.allLocationSame;
          // Get the first address of the selected product
          const commonAddress = product.location[0] || {
            address: '',
            city: '',
            state: '',
            zip: '',
          };
  
          return {
            ...product,
            allLocationSame: isAllSame,
            location: product.location.map(() => commonAddress),
          };
        }
        return product;
      })
    );
  };

  const handleAllServiceLocationSameChange = () => {

    console.log("##################Alllll", allLocationSame);
    if (!allServiceLocationSame) {
      const commonAddress = additionalSelectedServices[0]?.addresses[0] || {
        address: '',
        city: '',
        state: '',
        zip: '',
      };
      setAdditionalSelectedServices(prevProducts =>
        prevProducts.map(product => ({
          ...product,
          addresses: product.addresses.map(() => commonAddress),
        }))
      );
    }
    setAllServiceLocationSame(!allServiceLocationSame);
  };
  
  const handleAllRfpServiceLocationSameChange = () => {
    
    if (!allRfpServiceLocationSame) {
      // Ensure there's at least one service and its location array is valid
      const commonAddress = cartService[0]?.location?.[0] || {
        address: '',
        city: '',
        state: '',
        zip: '',
      };
  
      console.log("Common Address being applied:", commonAddress);
  
      // Update all locations to use the commonAddress
      setCartService(prevProducts =>
        prevProducts.map(product => ({
          ...product,
          location: product.location ? product.location.map(() => ({ ...commonAddress })) : [{ ...commonAddress }],
        }))
      );
    }
  
    setAllRfpServiceLocationSame(!allRfpServiceLocationSame);
  };
  
  // const handleAllRfpProductsLocationSameChange = () => {
    
  //   if (!allRfpProductsLocationSame) {
  //     // Ensure there's at least one service and its location array is valid
  //     const commonAddress = cart[0]?.location?.[0] || {
  //       address: '',
  //       city: '',
  //       state: '',
  //       zip: '',
  //     };
  
  //     console.log("Common Address being applied:", commonAddress);
  
  //     // Update all locations to use the commonAddress
  //     setCart(prevProducts =>
  //       prevProducts.map(product => ({
  //         ...product,
  //         location: product.location ? product.location.map(() => ({ ...commonAddress })) : [{ ...commonAddress }],
  //       }))
  //     );
  //     setAllRfpProductsLocationSame(true);

  //   }else{

  //     setAllRfpProductsLocationSame(false);
  //   }
  
  // };
  
  const handleAllRfpProductsLocationSameChange = (productIndex) => {
    setCart(prevProducts =>
      prevProducts.map((product, index) => {
        if (index === productIndex) {
          const isAllSame = !product.allRfpProductsLocationSame;
  
          // Ensure there's at least one valid address and it matches `location_quantity`
          const locationQuantity = product.location_quantity || 1;
          const commonAddress = product?.location?.[0] || {
            address: '',
            city: '',
            state: '',
            zip: '',
          };
  
          console.log("Common Address being applied:", commonAddress);
  
          return {
            ...product,
            allRfpProductsLocationSame: isAllSame,
            location: isAllSame 
              ? Array.from({ length: locationQuantity }, () => ({ ...commonAddress }))
              : Array.from({ length: locationQuantity }, () => ({ ...commonAddress })),
              // : Array.from({ length: locationQuantity }, () => ({ address: '', city: '', state: '', zip: '' })),
          };
        }
        return product;
      })
    );
  };

  const handleDeleteProduct = (productId, indexId) => {
    setCheckLength(!checkLength)
    const fileredProduct = selectedProducts.filter(data => !((data.id || data.product_id) === productId && data.indexId === indexId))
    setFieldValue('products', fileredProduct);
    setSelectedProducts(fileredProduct)
    const quantityFieldName = `quantity_${productId}`;
    const discountFieldName = `discount_${productId}`;
    setFieldValue(quantityFieldName, 1);
    setFieldValue(discountFieldName, 1);
    console.log('fileredProduct-------', fileredProduct);
    console.log('selectedServices-------', selectedServices);
    console.log("###############InitialserviceCost", initialServiceCostInProductFormula);
    const filteredServices = selectedServices?.filter(service => {
      // Check if there is any product in fileredProduct array which contains the service
      return fileredProduct?.some(product => (
        product?.recipe_id === service?.recipe_id &&
        product?.id === service?.product_id &&
        product?.indexId === service?.productIndex
      ));
    });


    setSelectedServices(filteredServices);

    setSelectedServices(filteredServices);
    let initialServicesCost = 0
    if (fileredProduct.length > 0) {
      initialServicesCost = fileredProduct?.reduce((total, data, index) => {
        return total + ((data?.quantity) * (parseFloat(data?.expected_total_service_cost?.toFixed(2))));
      }, 0);
    }
    console.log('initialServiceCost In ProductFormula-----', initialServicesCost);
    setInitialServiceCostInProductFormula(initialServicesCost)


  }

  const assignServiceAddresses = (existingServices, newService, allServiceLocationSame, communityAddress) => {
    let assignedAddresses = [];
  
    if (allServiceLocationSame && existingServices.length > 0) {
      // Use the first service's address as the common address
      const commonAddress = existingServices[0]?.addresses[0] || {
        address: '',
        city: '',
        state: '',
        zip: '',
      };
      assignedAddresses = [{ ...commonAddress }];
    } else if (
      communityAddress.address &&
      communityAddress.city &&
      communityAddress.state &&
      communityAddress.zip
    ) {
      // Assign the community address if available
      assignedAddresses = [
        {
          id: 0,
          address: communityAddress.address,
          city: communityAddress.city,
          state: communityAddress.state,
          zip: communityAddress.zip,
        },
      ];
    } else {
      // Default to an empty address
      assignedAddresses = [
        {
          id: 0,
          address: '',
          city: '',
          state: '',
          zip: '',
        },
      ];
    }
  
    return {
      ...newService,
      addresses: assignedAddresses,
    };
  };


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: currentRFQData.name || '',
      description: currentRFQData.description || '',
      notes: currentRFQData.notes || '',
      // skills: currentRFQData.skills || [],
      // workflow: currentRFQData.workflow || [],
      clients:currentRFQData.client || null,
      project_type: currentRFQData.project_type || null,
      project_category: currentRFQData.project_category || null,
      map_service:currentRFQData.map_service || null,
      community: currentRFQData.community || null,
      start: currentRFQData.start || currentDate,
      end: currentRFQData.end || currentDate,
      // avatarUrl: currentRFQData.avatarUrl||'',
      products: currentRFQData.products || [],
      services: currentRFQData.services || [],
      markup_type: currentRFQData.markup_type || null,
      no_charge_type: currentRFQData.no_charge_type || null,
      invoice_discount: currentRFQData.invoice_discount || '',
      welcome_message: currentRFQData.welcome_message || false,
      draft: currentRFQData.draft || false,
      total: currentRFQData.total || '',
      labour_cost: currentRFQData.labour_cost || 0,
      budget: currentRFQData.budget || 0,
      tax: 0,
      client_approve: clientApprove == 0 ? false : true,
      order_id: currentRFQData.order_id || '',
      subject: currentRFQData.subject || '',
      email_content: currentRFQData.email_content || '',
      rfp:null,
      estimate_id:currentRFQData.estimate_id,
      editReq:true
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        setIsSubmitted(true);
        // if (values.project_category?.name === 'Time & Material') {
        //   const hasEmptyName = selectedProducts?.some(product => {
        //     return !product.product_name?.trim(); // Safe check for empty or undefined product names
        //   });
        //   if (hasEmptyName) {
        //     enqueueSnackbar('Please fill in all required product names.', { variant: 'error' });
        //     return;
        //   }
        //   const hasInvalidName = selectedProducts?.some(product => {
        //     const trimmedName = product.product_name?.trim();
        //     if (trimmedName === '.') {
        //       enqueueSnackbar('Product name cannot be just a dot.', { variant: 'error' });
        //       return true;
        //     }
        //     if (trimmedName === '-') {
        //       enqueueSnackbar('Product name cannot be just a hyphen.', { variant: 'error' });
        //       return true;
        //     }
        //     if (!/^[a-zA-Z0-9\s-]+$/.test(trimmedName)) {
        //       enqueueSnackbar('Product name can only contain letters, numbers, and hyphens.', { variant: 'error' });
        //       return true;
        //     }
        //     if (trimmedName[0] === '.') {
        //       enqueueSnackbar('Product name cannot start with a dot.', { variant: 'error' });
        //       return true;
        //     }
        //     if (trimmedName[0] === '-') {
        //       enqueueSnackbar('Product name cannot start with a hyphen.', { variant: 'error' });
        //       return true;
        //     }
        //     if (trimmedName === '') {
        //       enqueueSnackbar('Product name cannot be empty or just spaces.', { variant: 'error' });
        //       return true;
        //     }
        //     return false;
        //   });          
        //   if (hasInvalidName) {
        //     // enqueueSnackbar('Product names must contain letters or numbers.', { variant: 'error' });
        //     return;
        //   }
        // }
        const hasEmptyProductName = selectedProducts?.some(product => (product.name || product.product_name || '').trim() === '');
        const hasEmptyServiceName = additionalSelectedServices?.some(product => product.name.trim() === '');
        const hasEmptyMaterialName = selectedMaterials?.some(product => (product.name || product.product_name || '').trim() === '');

        if (hasEmptyProductName) {
          enqueueSnackbar('Please fill in all required product names.', { variant: 'error' });
          return;
        }
        if (hasEmptyServiceName) {
          enqueueSnackbar('Please fill in all required service names.', { variant: 'error' });
          return;
        }
        if (hasEmptyMaterialName) {
          enqueueSnackbar('Please fill in all required material names.', { variant: 'error' });
          return;
        }
        const hasInvalidProductName = selectedProducts?.filter(item=>item?.isAdditionalProduct == true)?.some(product => {
          const newName = product.product_name || product.name;
          const trimmedName = newName.trim();
          if (trimmedName === '.') {
            enqueueSnackbar('Product name cannot be just a dot.', { variant: 'error' });
            return true;
          }
          if (trimmedName === '-') {
            enqueueSnackbar('Product name cannot be just a hyphen.', { variant: 'error' });
            return true;
          }
          // if (!/^[a-zA-Z0-9\s-]+$/.test(trimmedName)) {
          //   enqueueSnackbar('Product name can only contain letters, numbers, and hyphens.', { variant: 'error' });
          //   return true;
          // }
          if (/[:]/.test(trimmedName)) {
            enqueueSnackbar('Product name cannot contain colon (:).', { variant: 'error' });
            return true;
          }
          if (trimmedName[0] === '.') {
            enqueueSnackbar('Product name cannot start with a dot.', { variant: 'error' });
            return true;
          }
          if (trimmedName[0] === '-') {
            enqueueSnackbar('Product name cannot start with a hyphen.', { variant: 'error' });
            return true;
          }
          if (trimmedName === '') {
            enqueueSnackbar('Product name cannot be empty or just spaces.', { variant: 'error' });
            return true;
          }
          return false;
        });
        
        const hasInvalidServiceName = additionalSelectedServices?.filter(item=>item?.isAdditionalProduct == true)?.some(product => {
          const trimmedName = product.name.trim();
          if (trimmedName === '.') {
            enqueueSnackbar('Service name cannot be just a dot.', { variant: 'error' });
            return true;
          }
          if (trimmedName === '-') {
            enqueueSnackbar('Service name cannot be just a hyphen.', { variant: 'error' });
            return true;
          }
          if (/[:]/.test(trimmedName)) {
            enqueueSnackbar('Service name cannot contain colon (:).', { variant: 'error' });
            return true;
          }                
          if (trimmedName[0] === '.') {
            enqueueSnackbar('Service name cannot start with a dot.', { variant: 'error' });
            return true;
          }
          if (trimmedName[0] === '-') {
            enqueueSnackbar('Service name cannot start with a hyphen.', { variant: 'error' });
            return true;
          }
          if (trimmedName === '') {
            enqueueSnackbar('Service name cannot be empty or just spaces.', { variant: 'error' });
            return true;
          }
          return false;
        });
        
        const hasInvalidMaterialName = selectedMaterials?.filter(item=>item?.isAdditionalProduct == true)?.some(product => {
          const newName = product.product_name || product.name;
          const trimmedName = newName.trim();
          if (trimmedName === '.') {
            enqueueSnackbar('Material name cannot be just a dot.', { variant: 'error' });
            return true;
          }
          if (trimmedName === '-') {
            enqueueSnackbar('Material name cannot be just a hyphen.', { variant: 'error' });
            return true;
          }
          if (/[:]/.test(trimmedName)) {
            enqueueSnackbar('Material name cannot contain colon (:).', { variant: 'error' });
            return true;
          }                
          if (trimmedName[0] === '.') {
            enqueueSnackbar('Material name cannot start with a dot.', { variant: 'error' });
            return true;
          }
          if (trimmedName[0] === '-') {
            enqueueSnackbar('Material name cannot start with a hyphen.', { variant: 'error' });
            return true;
          }
          if (trimmedName === '') {
            enqueueSnackbar('Material name cannot be empty or just spaces.', { variant: 'error' });
            return true;
          }
          return false;
        });
        
        if (hasInvalidProductName || hasInvalidServiceName || hasInvalidMaterialName) {
          // enqueueSnackbar('Product names must contain letters or numbers.', { variant: 'error' });
          return;
        }
        const request_id = id
        const clientsArray = values.clients ? [values.clients] : [];
        values.clientsData = clientsArray
        values.products = selectedProducts
        values.userName = user.first_name;
        const allServices = [...selectedServices, ...additionalSelectedServices];
        const servicesIdArray = allServices?.map(service => service.id);

        values.avatarUrl = avatarUrl

        // values.project_category = values.project_type?.name == 'TS' ? (values.project_category || null) : null
        values.map_service = (values.project_type?.name == 'TS' && (values.project_category && values.project_category?.id == 14)) ? values.map_service : null

        values.services = servicesIdArray?.join(',')
        values.totalMarkup = totalMarkup
        values.selectedServices = allServices
        values['labour_cost'] = totalLabourCost;
        const prodAddress= selectedProducts.reduce((acc, product) => {
          return [
            ...acc,
            ...product.location.map((address) => ({
              ...address,
              productId: product.id,
              productIndex: product.indexId,
              isService:0
            })),
          ];
        }, []);
        const serviceAddress= additionalSelectedServices?.reduce((acc, product) => {
          return [
            ...acc,
            ...product.addresses.map((address) => ({
              ...address,
              productId: product.labour_cost_service_id || product.id,
              productIndex: product.indexId,
              newIndexId: product.newIndexId,
              isService:1
            })),
          ];
        }, []);

        const combinedAddress = [...serviceAddress, ...prodAddress];
        const data = {
          values, userId, userType, request_id,
          allLocationSame : (values.project_category?.name == 'Service only' || values.project_category?.name == 'Time & Material') ? false : allLocationSame ,
          allServiceLocationSame : allServiceLocationSame ,
          allRfpServiceLocationSame : allRfpServiceLocationSame ,
          allRfpProductsLocationSame : (values.project_category?.name == 'Service only' || values.project_category?.name == 'Time & Material') ? false : allRfpProductsLocationSame ,
          rfpProducts: cart,
          taxCategory : selectedTaxCategory?.id || null,
          rfpServices: cartService,
          materials:selectedMaterials,
          // address: (values.project_category?.name == 'Service only' || values.project_category?.name == 'Time & Material')  ? serviceAddress : prodAddress
          address: combinedAddress
          // address: selectedProducts.reduce((acc, product) => {
          //   return [
          //     ...acc,
          //     ...product.location.map((address) => ({
          //       ...address,
          //       productId: product.id,
          //       productIndex: product.indexId,
          //     })),
          //   ];
          // }, []),
        }
        let isvalidationNotOk = false
        let isServicevalidationNotOk = false
        // if((values.project_category?.name == 'Service only') || (values?.project_category?.name == 'Time & Material')){
        if(additionalSelectedServices?.length > 0){
          isServicevalidationNotOk = additionalSelectedServices?.find(product => {
            if (product.addresses.length && product.addresses.some(obj =>
              [obj.address, obj.city, obj.state, obj.zip].some(value => (value === '' || value === null))
            )) {
              return true
            } else {
              return false
            }
          })
        }
        if((values.project_category?.name != 'Service only') && (values?.project_category?.name != 'Time & Material')){
          isvalidationNotOk = selectedProducts.find(product => {
            if (product.location.length && product.location.some(obj =>
              [obj.address, obj.city, obj.state, obj.zip].some(value => (value === '' || value === null))
            )) {
              return true
            } else {
              return false
            }
          })
        }
        values.total = totalsum
        values.taxAmount = taxAmount
        values.sub_total = rfqSubTotal
        values.budget = budget

        const isvalidationNotOkRfp = cart?.find(product => {
          if (product.location.length > 0 && product.location.some(obj =>
            [obj.address, obj.city, obj.state, obj.zip].some(value => (value === '' || value === null))
          )) {
            console.log("Validation failed for product:", product);
            return true;
          } else {
            console.log("Validation passed for product:", product);
            return false;
          }
        });

        let isvalidationNotOkRfpServices= false
        // if(values.project_category?.name == 'Service only' || (values?.project_category?.name == 'Time & Material')){
        if(cartService?.length > 0){
          isvalidationNotOkRfpServices = cartService?.find(product => {
            if (product.location.length > 0 && product.location.some(obj =>
              [obj.address, obj.city, obj.state, obj.zip].some(value => (value === '' || value === null))
            )) {
              console.log("Validation failed for product:", product);
              return true;
            } else {
              console.log("Validation passed for product:", product);
              return false;
            }
          });
        }

        if(userType != 3 &&
          (values?.project_category?.name == "Time & Material") && 
          // (cart.length <= 0 && cartService?.length <= 0 && additionalSelectedServices?.length <= 0 && selectedProducts?.length <= 0)
          (cartService?.length <= 0 && additionalSelectedServices?.length <= 0)
        ){
          enqueueSnackbar('Please choose atleast one Service/ Proposal service', { variant: 'error' });
        }else if((values?.project_category?.name != "Time & Material") && (values.total <= 0)){
          enqueueSnackbar('Please select at least one product, service, Proposal, or material.', { variant: 'error' });
        }else if (
          isvalidationNotOk ||
          isServicevalidationNotOk || 
          isvalidationNotOkRfp || 
          isvalidationNotOkRfpServices 
        ) {
          enqueueSnackbar('Please fill all the address fields', { variant: 'error' });
        } else {
          setFormValues(values);
          setFormDatas(data);
          if(details?.status == 4){
            setOpenDialog(true);
          }else{
            handleConfirmSubmit(values,data);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  });

  const handleConfirmSubmit = async (values=null,data=null) =>{
    setOpenDialog(false);
    setIsSubmitting(true);
    try{
      const formValuesData = values || formValues;
      const formDatasData = data || formDatas;
      console.log('Form Values:', formValuesData);
      console.log('Form Data:', formDatasData);
      // if(formValuesData.project_category.name =="Time & Material"){ 
      //   const productResponse = await axios.post(`${REST_API_END_POINT}product/add-product-on-request`,{values:formValuesData})

      //   if(productResponse) {
      //     await axios.post(`${REST_API}webservice/edit-request`, formDatasData)
      //     .then((res) => {
      //       setOpenEdit(false)
      //       setIsOpen(false)
      //       setSelectedTaxCategory(null);
      //       setCurrentRFQdata({}) // added on 3-01-2024
      //       setSelectedProducts([])
      //       setSelectedMapserviceProducts([]);
      //       setSelectedServices([]);
      //       setAdditionalSelectedServices([])
      //       setFilteredServices([])
      //       fetchRequestDetailsNew()
      //       fetchRequestDetails()
      //       if (edit) {
      //         navigate(`/dashboard/official-request`)
      //       }
      //       enqueueSnackbar('Update success', { variant: 'success' });
      //       formik.resetForm();
      //       // resetForm();
      //     })
      //     .catch((errors) => {
      //       console.log(errors, 'error')
      //     })
      //   }

      // }else{

        await axios.post(`${REST_API}webservice/edit-request`, formDatasData)
        .then((res) => {
          setOpenEdit(false)
          setIsOpen(false)
          setSelectedTaxCategory(null);
          setCurrentRFQdata({}) // added on 3-01-2024
          setSelectedProducts([])
          setSelectedMapserviceProducts([]);
          setSelectedServices([]);
          setAdditionalSelectedServices([])
          setFilteredServices([])
          fetchRequestDetailsNew()
          fetchRequestDetails()   
          if (formValuesData?.project_category?.name === "Time & Material" && formValuesData?.client_approve === false) {
            editHandleConvertToJob()
            // if(popupOn){
            //   onDataChange()
            //   closeView()
            // }
        }else{
          if(popupOn){
            localStorage.removeItem('salesOrderProjectId');
            localStorage.removeItem('salesOrderProjectName');
            localStorage.removeItem('isEditSalesOrder');
            localStorage.removeItem('salesOrderOpenPopup',);
            closeView()
            setRenderCards(!renderCards)
            onDataChange()
          }else{
            if (edit) {
              navigate(`/dashboard/official-request`)
            }
          }
        }
          enqueueSnackbar('Update success', { variant: 'success' });
          formik.resetForm();
          // resetForm();
        })
        .catch((errors) => {
          console.log(errors, 'error')
        })
      // }
    } catch (error) {
      console.error('Error submitting form:', error);
      enqueueSnackbar('An error occurred during submission.', { variant: 'error' });
    } finally {
      setIsSubmitting(false);
      setFormValues({});
      setFormDatas({});
    }
  }

  const editHandleConvertToJob = async () => {
    try {
      // setOpenConvertToRFQ(false);
      // setConvertToRFQ(true);
      let requestId = id
      let requestPartnerId = details?.rt_partner_id
      let acceptStatus = 1
      let userId = user.user_id

      const data = { requestId, requestPartnerId, acceptStatus, userId }
      const res = await axios.post(`${REST_API}webservice/request/accept-or-reject-invoice`, data);

      if (res.data.status == 1) {
        // setConfirmInvoice(false)
        // setRejectInvoice(false)
        enqueueSnackbar('Job created successfully', { variant: 'success' });
      } else {
        // setConfirmInvoice(false)
        // setRejectInvoice(false)
        enqueueSnackbar('Failed to convert Sales Order to Job', { variant: 'error' });
      }
      if(popupOn){
        localStorage.removeItem('salesOrderProjectId');
        localStorage.removeItem('salesOrderProjectName');
        localStorage.removeItem('isEditSalesOrder');
        localStorage.removeItem('salesOrderOpenPopup',);
        closeView()
        setRenderCards(!renderCards)
        onDataChange()
      }else{
        if (edit) {
          navigate(`/dashboard/official-request`)
        }
      }
    } catch (error) {
      console.log(error, 'error in Converting Sales Order to job')
      // setConfirmInvoice(false)
      // setRejectInvoice(false)
      enqueueSnackbar('Server Error', { variant: 'error' })
    }
    // finally{
    //   setConvertToRFQ(false);
    // }
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // useEffect(()=>{
  //   // let currentUnix = moment(currentDate).unix()
  //   //  let requestSubmitDeadline = details?.submission_deadline

  //    if((user?.user_id == details?.partner_user_id)&& (details?.accept_status == 0)){
  //     setReqAcceptClient(true)
  //    }

  // },[details])

  const submissionDeadlineUnix = details?.submission_deadline;
  const submissionDeadlineMoment = moment.unix(submissionDeadlineUnix);

  const current = moment();

  const daysLeft = submissionDeadlineMoment.diff(current, 'days');

  const handleDeleteTask = (id)=>{
    try {
      let newStatus = 2
      axios.post(`${REST_API_END_POINT}project/delete-project`,{ id: Number(id), status: newStatus },{
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if(response.data.status === 4 || response.data.status === 2 || response.data.status === 1){
          enqueueSnackbar('Job deleted successfully', { variant: 'success' });
          onDataChange()
        }else if(response.data.status === 5 || response.data.status === 3 || response.data.status === 0){
          enqueueSnackbar('Failed to delete Job', { variant: 'error' });
        }
      }).catch((error) => {
        console.error(error);
      })
    }
    catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleAcceptorRejectInvoice = async (value) => {
    try {
      setConfirmInvoice(false)
      setRejectInvoice(false)
      setRequestAccept(true);
      if (value == 3) {
        setConfirmInvoice(false);
        setRejectInvoice(false);
        let requestId = id
        let requestPartnerId = details?.rt_partner_id;
        let userId = user.user_id;
        const data = { requestId, requestPartnerId, userId, additionalAmount : ((invoice.jobcostTotal || 0) + (invoice.jobcostServiceTotal || 0)), grandTotal : invoice.grandTotal || invoice.total };
        const res = await axios.post(`${REST_API}webservice/request/accept-final-invoice`,data);
        if (res.data.status == 1) {
          enqueueSnackbar('Final invoice approved', { variant: 'success' });
          setConfirmInvoice(false);
          setRejectInvoice(false);
          setOpenInvoice(!openInvoice);
        }else{
          enqueueSnackbar('Failed to Approve final invoice', { variant: 'error' });
          setConfirmInvoice(false);
          setRejectInvoice(false);
          setOpenInvoice(!openInvoice);
        }
        onDataChange()
        fetchRequestDetailsNew();
        fetchRequestDetails();
      } else if (value === 2 && reason === '') {
        enqueueSnackbar('Please fill rejected reason ', { variant: 'warning' })
        return
      } else {
        let requestId = id
        let requestPartnerId = details?.rt_partner_id
        let acceptStatus = value
        let userId = user.user_id
        let billing_type =details?.billing_type

        const data = { requestId, requestPartnerId, acceptStatus, userId, reason,billing_type }
        // const res = await axios.post(`${REST_API}webservice/request/accept-or-reject-invoice`, data);
        const res = await axios.post(`${REST_API}webservice/request/accept-or-reject-request-new`, data);

        if (res.data.status == 1) {
          if(res.data.checkProjectExist){
            enqueueSnackbar('Invoice approved. Converting to production now...', { variant: 'success' });
            handleConvertToJob(true);
            setOpenInvoice(!openInvoice);
          }else if(details.project_category ==13 || invoice?.grandTotal <= 0 ){
            enqueueSnackbar('Invoice approved. Converting to production now...', { variant: 'success' });
            handleConvertToJob(true);
            setOpenInvoice(!openInvoice);
          }else{
            setOpenInvoice(!openInvoice)
            setConfirmInvoice(false)
            setRejectInvoice(false)
            enqueueSnackbar('Invoice approved', { variant: 'success' });
            fetchRequestDetailsNew()
            fetchRequestDetails()
          }
        } else if (res.data.status == 2) {
          setOpenInvoice(!openInvoice)
          setConfirmInvoice(false)
          setRejectInvoice(false)
          if(res.data.checkProjectExist){
            enqueueSnackbar('Invoice Rejected. Deleting the related Job...', { variant: 'error' })
            handleDeleteTask(res.data.checkProjectExist);
          }else{
            enqueueSnackbar('Invoice Rejected', { variant: 'error' })
          }
          setReason('')
          navigate(PATH_DASHBOARD.official.root)
        } else {
          setOpenInvoice(!openInvoice)
          setConfirmInvoice(false)
          setRejectInvoice(false)
          enqueueSnackbar(res.data.message, { variant: 'error' });
        }
      }
    } catch (error) {
      console.log(error, 'error in accepting invoice')
      setConfirmInvoice(false)
      setRejectInvoice(false)
      enqueueSnackbar('Server Error', { variant: 'error' })
    }finally{
      setRequestAccept(false);
    }
  }

  const handleConvertToJob = async (existingJob = false) => {
    try {
      setOpenConvertToRFQ(false);
      setConvertToRFQ(true);
      let requestId = id
      let requestPartnerId = details?.rt_partner_id
      let acceptStatus = 1
      let userId = user.user_id

      const data = { requestId, requestPartnerId, acceptStatus, userId, reason }
      const res = await axios.post(`${REST_API}webservice/request/accept-or-reject-invoice`, data);

      if (res.data.status == 1) {
        setConfirmInvoice(false)
        setRejectInvoice(false)
        enqueueSnackbar(existingJob ? 'Job updated successfully' :'Job created successfully', { variant: 'success' });
        if(details?.job_category_name== "Service only" || details?.job_category_name == 11){
          handleConvertJobToInstallation(requestId)
        }else{
          fetchRequestDetailsNew()
          fetchRequestDetails()
        }
        onDataChange()
      } else {
        setConfirmInvoice(false)
        setRejectInvoice(false)
        enqueueSnackbar('Failed to convert Sales Order to Job', { variant: 'error' });
      }
    } catch (error) {
      console.log(error, 'error in Converting Sales Order to job')
      setConfirmInvoice(false)
      setRejectInvoice(false)
      // enqueueSnackbar('Server Error', { variant: 'error' })
    }finally{
      setConvertToRFQ(false);
    }
  }

  const handleConvertJobToInstallation = async (id)=>{
    const user = JSON.parse(window.localStorage.getItem('user'))

    let selectedIds=id
    let request = true
    const response = await axios.post(`${REST_API_END_POINT}installation/convert-job-to-installation`,{selectedIds,userId:user.user_id,request,userType:userType , clientId:details?.customer_id})
    if(response.data.status==1){
      // enqueueSnackbar("Moved to Installation successfully !", { variant: 'success' });
      fetchRequestDetailsNew()
      fetchRequestDetails()
    }else{
      // enqueueSnackbar("Installation Not Moved !", { variant: 'error' });
      fetchRequestDetailsNew()
      fetchRequestDetails()
    }
  }

  const handleReopeningRequest = async () => {
    try {
      let requestId = id
      let userId = user.user_id
      const data = { requestId, userId }
      const res = await axios.post(`${REST_API}webservice/request/reopen`, data)
      if (res.data.status == 1) {
        enqueueSnackbar('Order reopened successfully', { variant: "success" })
        setReopen(false)
        fetchRequestDetailsNew()
        fetchRequestDetails()
      } else if (res.data.status == 0) {
        enqueueSnackbar('Order reopened failed', { variant: "error" })
        setReopen(false)
      }
    } catch (error) {
      console.log(error, 'Error in reopen')
    }
  }
  // const handleAcceptOrRejectRequest=async(value)=>{
  //   try {
  //     console.log(value)
  //     let requestId = id
  //     let requestPartnerId = details?.rt_partner_id
  //     let acceptStatus = value
  //     let userId=user.user_id

  //     const data={requestId,requestPartnerId,acceptStatus,userId}
  //     const res = await axios.post(`${REST_API_END_POINT}request/accept-or-reject-request`,data)

  //     if(res.data.status ==1){
  //       setReqAcceptClient(!reqAcceptClient)
  //       setRequestAccept(false)
  //       setRequestReject(false)
  //       enqueueSnackbar(value === 1 ? 'Request Accepted' : 'Request Rejected', { variant: value === 1 ? 'success' : 'error' });
  //       if(value==2){
  //         navigate(PATH_DASHBOARD.official.root)
  //       }
  //     }
  //     // setReqAcceptClient(false)
  //     // setRequestAccept(false)
  //     // setRequestReject(false)
  //   } catch (error) {
  //     console.log(error,'error in reject request')
  //   }

  // }

  const { errors, values, touched, handleSubmit, setFieldValue, getFieldProps } = formik;



  const setValuesToPreview = () => {
    setPreviewData(values)
  }

  // let  totalsum = 0
  // let DiscountTotal=0
  // let newTotalProductFormula1 = 0;
  // let newTotalWholesalePrice1 = 0;
  // let newTotalRetailPrice1 = 0;

  const calculateTotal = (price, quantity, discount, value, location = 1, adjustment = 0,markAdjType=0) => {

    const parsedQuantity = parseFloat(quantity ? quantity : value) || 1;
    const parsedDiscount = parseFloat(discount) || 0;
    const locationQuantity = parseFloat(location) || 1
    const newPrice = parseFloat(price) || 0
    // let subtotal = price * parsedQuantity * locationQuantity;
    let SPMA = newPrice + ((newPrice * (adjustment ? Number(adjustment) : 0)) / 100)
    let subtotal = parseFloat(SPMA)?.toFixed(2) * parsedQuantity * locationQuantity;

    if(markAdjType ==2 || markAdjType ==3){
      SPMA = newPrice + (adjustment ? Number(adjustment) : 0)
      subtotal = parseFloat(SPMA)?.toFixed(2) * parsedQuantity * locationQuantity;
    }

    if (discount) {
      subtotal = subtotal - discount;
    }
    // if (adjustment) {
    //   subtotal = subtotal + ((subtotal * adjustment) / 100);
    // }
    return subtotal;
  };

  const calculateServiceTotal = (price, quantity, discount, value, location = 1) => {

    const parsedQuantity = parseFloat(quantity ? quantity : value) || 0;
    const parsedDiscount = parseFloat(discount) || 0;
    const locationQuantity = parseFloat(location) || 1
    const subtotal = price * parsedQuantity * locationQuantity;

    if (discount) {
      const totalDiscount = subtotal - discount;
      return totalDiscount;
    } else {
      return subtotal;
    }
  };
  let sum = 0
  let taxAmountData = 0 ;
  let grandTotalData = 0;
  let totalJobcostSum = 0
  useEffect(() => {
    try {
      console.log('selectedProducts-----', selectedProducts);
      // if(selectedProducts?.length > 0 || selectedServices?.length > 0 || additionalSelectedServices?.length > 0){
      let selectedProductsTotalDiscount = selectedProducts?.reduce((total, data, index) => {
        let quantityFieldName = `quantity_${data.product_id ? data.product_id : data.id}`;
        let discountFieldName = `discount_${data.product_id ? data.product_id : data.id}`;
        const dataRetailPrice = data.product_retail_price || data.retail_price || 0
        const dataWholesalePrice = data.product_price || data.price || 0
        // const dataQuantity = values[quantityFieldName]?values[quantityFieldName]:(data?.quantity||1)

        // const dataDiscount = values[discountFieldName]?values[discountFieldName]:(data?.discount||0);
        const dataQuantity = data?.quantity || 1

        const dataDiscount = data?.discount || 0;

        const productTotal = calculateTotal(markupType == 1 ? dataWholesalePrice : (markupType == 2 ? dataRetailPrice : 0), dataQuantity, dataDiscount, null, data?.locationQuantity, Number(data?.adjustment),data.markAdjType);
        const fixedProductTotal = parseFloat(productTotal).toFixed(2);
        return total + parseFloat(fixedProductTotal);
      }, 0);

      const selectedProductsTotalDiscountForJobCost = selectedProducts?.reduce((total, data, index) => {
        const quantityFieldName = `quantity_${data.product_id || data.id}`;
        const discountFieldName = `discount_${data.product_id || data.id}`;
        const quantityVal = values[quantityFieldName] ? values[quantityFieldName] : 1
        const discountVal = values[discountFieldName] ? values[discountFieldName] : 0
        console.log("###############quantityVal", quantityVal)
        console.log("###############quantityValdata", data)
        // const productTotal = calculateTotal(data.expected_material_cost, data.quantity, discountVal);
        const productTotal = calculateTotal(parseFloat(data.expected_material_cost), data.quantity, 0, null, data.locationQuantity, 0);
        console.log("###############product formula", productTotal)
        return total + productTotal;
      }, 0);
      const allServices = [...selectedServices, ...additionalSelectedServices];
      let selectedServicesCost = allServices?.reduce((total, service, index) => {
        // const quantityFieldName = `service_quantity_${index}`;
        // const quantityVal = values[quantityFieldName]?values[quantityFieldName]:1
        const quantityVal = service?.quantity ? Number(service?.quantity) : 0
        const serviceTotal = calculateServiceTotal(service.cost, quantityVal, 0);

        console.log("############SSSSSSSSSSSer", serviceTotal);

        // let selectedQuantity=selectedProducts.filter(product=>product.id===service.product_id)
        // console.log("############SSSSSSSSSSSer",selectedQuantity);
        // let quantity=selectedQuantity[0].quantity
        // return total + (serviceTotal*quantity);
        return total + serviceTotal;
      }, 0);
      // selectedServicesCost = selectedServicesCost - initialServiceCostInProductFormula
      console.log('allServices-----------', allServices);

      let selectedServicesTotalCost = allServices?.reduce((total, service, index) => {
        const quantityVal = service?.quantity ? Number(service?.quantity) : 0
        const costVal = service?.unit_cost ? Number(service?.unit_cost) : Number(service?.total_cost)
        const serviceTotal = calculateServiceTotal(costVal, quantityVal, 0);
        // const roundedServiceTotal = Math.round(serviceTotal * 100) / 100;
        return total + serviceTotal;
      }, 0);
      let initialServicesCost = selectedProducts?.reduce((total, data, index) => {
        const serviceTotal = (data?.quantity) * (parseFloat(data?.expected_total_service_cost?.toFixed(2))) * (data?.locationQuantity || 1) || 0;
        // const roundedServiceTotal = Math.round(serviceTotal * 100) / 100;
        return total + serviceTotal;
      }, 0);

      console.log("###################1111selectedServicesTotalCost-1", selectedServicesTotalCost);
      console.log("###################1111initialServicesCost-1", initialServicesCost);

      const roundToThreeDecimals = (num) => Math.round(num * 1000) / 1000;
      selectedServicesTotalCost = roundToThreeDecimals(selectedServicesTotalCost);
      initialServicesCost = roundToThreeDecimals(initialServicesCost);

      console.log("###################1111selectedServicesTotalCost-2", selectedServicesTotalCost);
      console.log("###################1111initialServicesCost-2", initialServicesCost);
      const roundToTwoDecimals = (num) => Math.round(num * 100) / 100;
      const selectedServicesSubTotal = roundToTwoDecimals(selectedServicesTotalCost - initialServicesCost)
      setTotalLabourCost(selectedServicesSubTotal)
      setTotalProductLabourCost(selectedServicesSubTotal)
      console.log("###################1111selectedServicesSubTotal", selectedServicesSubTotal);

      const newSum = (selectedProductsTotalDiscount + selectedServicesTotalCost) - (values['invoice_discount'] ? values['invoice_discount'] : 0)
      // setRFQSubTotal(newSum)
      console.log("###################1selectedProductsTotalDiscount", selectedProductsTotalDiscount);
      const selectedRfpProduct = cart?.reduce((total, data, index) => {
        let markup = markupType ? (markupType === 1 ? data.WholesaleMarkup || data.product_markup : data.RetailMarkup || data.product_markup) : 0;

        let productTotal = parseFloat((data.product_price + data.product_price * markup / 100)?.toFixed(2)) * data.quantity * data.location_quantity;
        productTotal = parseFloat(productTotal.toFixed(2));  // Ensure two decimal places and parse back to float

        console.log("#####################ProductTotal", productTotal);
        return total + productTotal;
      }, 0);

      const selectedRfpProductJobCost = cart?.reduce((total, data, index) => {
        let productTotal = data.product_price * data.quantity * data.location_quantity;
        productTotal = parseFloat(productTotal.toFixed(2));  // Ensure two decimal places and parse back to float

        console.log("#####################ProductTotal", productTotal);
        return total + productTotal;
      }, 0);

      const selectedRfpServices = cartService?.reduce((total, data, index) => {
        let markup = data.originalMarkup;
        data.sellingPrice = data.sellingPrice || data.unit_cost;

        let productTotal = (data.sellingPrice + (data.sellingPrice * markup / 100)) * data.qty;
        productTotal = parseFloat(productTotal.toFixed(2));  // Ensure two decimal places and parse back to float

        console.log("#####################ProductTotal", productTotal);
        return total + productTotal;
      }, 0);

      const selectedRfpServicesJobCost = cartService?.reduce((total, data, index) => {
        let markup = data.originalMarkup;
        data.sellingPrice = data.sellingPrice || data.unit_cost;

        let productTotal = data.sellingPrice * data.qty;
        productTotal = parseFloat(productTotal.toFixed(2));  // Ensure two decimal places and parse back to float

        console.log("#####################ProductTotal", productTotal);
        return total + productTotal;
      }, 0);
      const selectedMaterialCostJobCost = selectedMaterials?.reduce((total, data, index) => {
        let productTotal = (data.product_price||data.price) * (data.quantity||1) ;
        if(data?.inhouse == 0){
          productTotal = 0
        }
        productTotal = parseFloat(productTotal.toFixed(2));  // Ensure two decimal places and parse back to float
        console.log("#####################ProductTotalewwww1",data);
        console.log("#####################ProductTotalewwww1", productTotal);
        return total + productTotal;
      }, 0);
      const selectedMaterialCost = selectedMaterials?.reduce((total, data, index) => {
        data.price=data.price ? data.price : data.product_price
        data.markup = data.markup ? data.markup : data.product_markup
        let markUpCost = data.price + ((parseFloat(data.price) * parseFloat(data.markup || 0)) / 100)
        markUpCost = parseFloat(parseFloat(markUpCost).toFixed(2)) || 0;
        let productTotal = (markUpCost || data.product_retail_price) * (data.quantity||1) ;
        if(data?.inhouse == 0){
          productTotal = 0
        }
        productTotal = values?.project_category?.name == 'Time & Material' ? 0 : parseFloat(productTotal.toFixed(2));  // Ensure two decimal places and parse back to float
        console.log("#####################ProductTotalewwww2-data",data);
        console.log("#####################ProductTotalewwww2--markUpCost",markUpCost);
        console.log("#####################ProductTotalewwww2--productTotal", productTotal);
        return total + productTotal;
      }, 0);
      console.log("#####################Labour cost", selectedServicesSubTotal + selectedRfpServices);
      setTotalLabourCost(selectedServicesSubTotal + selectedRfpServices)
      console.log("#####################selectedRfpServices", selectedRfpServices);
      console.log("#####################selectedRfpProduct", selectedRfpProduct);
      sum = (selectedProductsTotalDiscount + selectedServicesSubTotal +selectedMaterialCost+ selectedRfpServices + selectedRfpProduct) - (values['invoice_discount'] ? values['invoice_discount'] : 0)
      taxAmountData = selectedTaxCategory ? (selectedTaxCategory.type === 1 ? (sum * (selectedTaxCategory.value || 0) / 100) : (selectedTaxCategory.value || 0) ) : 0;
      let negativeCheck = (noCharge && sum > 0) ? -1 : 1
      // let negativeCheck = noCharge  ? -1 : 1
     console.log('negativeChecknegativeChecknegativeCheck',negativeCheck)
      grandTotalData = ((parseFloat(sum) + parseFloat(taxAmountData)) * negativeCheck ).toFixed(2);
      console.log("#####################selectedRfpServices", selectedRfpServices);
      console.log("#####################selectedProductsTotalDiscount", selectedProductsTotalDiscount);
      setTotalSum(sum);
      setTaxAmount(taxAmountData);
      setGrandTotal(grandTotalData);

      let selectedProductsTotalProductFormula = selectedProducts?.reduce((total, data) => {
        let quantityFieldName = `quantity_${data.product_id ? data.product_id : data.id}`;
        let discountFieldName = `discount_${data.product_id ? data.product_id : data.id}`;
        const dataRetailPrice = data.product_retail_price || data.retail_price;
        const dataWholesalePrice = data.product_price || data.price;
        const dataQuantity = values[quantityFieldName] ? values[quantityFieldName] : (data?.quantity || 1);

        let newTotalProductFormula = parseFloat(data.expected_cost?.toFixed(2)) * data.quantity * data.locationQuantity;
        newTotalProductFormula = newTotalProductFormula ? Number(newTotalProductFormula) : 0;


        return total + newTotalProductFormula;
      }, 0);

      selectedProductsTotalProductFormula = selectedProductsTotalProductFormula ? (Number(selectedProductsTotalProductFormula)) : 0

      setTotalProductFormula(selectedProductsTotalProductFormula)

      let selectedProductsTotalWholesalePrice = selectedProducts?.reduce((total, data, index) => {
        let quantityFieldName = `quantity_${data.product_id ? data.product_id : data.id}`;
        let discountFieldName = `discount_${data.product_id ? data.product_id : data.id}`;
        const dataRetailPrice = data.product_retail_price || data.retail_price
        const dataWholesalePrice = data.product_price || data.price
        const dataQuantity = values[quantityFieldName] ? values[quantityFieldName] : (data?.quantity || 1)

        let newTotalWholesalePrice = dataWholesalePrice * data.quantity * data.locationQuantity
        if(data?.adjustment){
          newTotalWholesalePrice = newTotalWholesalePrice ? newTotalWholesalePrice + ((newTotalWholesalePrice * Number(data?.adjustment)) / 100) : 0
        }
        newTotalWholesalePrice = newTotalWholesalePrice ? Number(newTotalWholesalePrice) : 0
        // return total + newTotalWholesalePrice;
        const fixedProductTotal = parseFloat(newTotalWholesalePrice).toFixed(2);
        return total + parseFloat(fixedProductTotal);
      }, 0);
      selectedProductsTotalWholesalePrice = selectedProductsTotalWholesalePrice ? Number(selectedProductsTotalWholesalePrice) : 0

      let selectedServicesTotalWholesalePrice = selectedServices?.reduce((total, data, index) => {
        let newTotalWholesalePrice = (data?.unit_cost * data?.quantityDifference)?.toFixed(2)
        newTotalWholesalePrice = newTotalWholesalePrice ? Number(newTotalWholesalePrice) : 0
        return total + newTotalWholesalePrice;
      }, 0);
      selectedServicesTotalWholesalePrice = selectedServicesTotalWholesalePrice ? Number(selectedServicesTotalWholesalePrice) : 0

      let selectedAddlServicesTotalWholesalePrice = additionalSelectedServices?.reduce((total, data, index) => {
        let newTotalWholesalePrice = data?.total
        newTotalWholesalePrice = newTotalWholesalePrice ? Number(newTotalWholesalePrice) : 0
        return total + newTotalWholesalePrice;
      }, 0);
      selectedAddlServicesTotalWholesalePrice = selectedAddlServicesTotalWholesalePrice ? Number(selectedAddlServicesTotalWholesalePrice) : 0

      let selectedCartTotalWholesalePrice = cart?.reduce((total, data, index) => {
        let markup = markupType ? (markupType === 1 ? data.WholesaleMarkup || data.product_markup : data.RetailMarkup || data.product_markup) : 0;
        let newTotalWholesalePrice = (data.location_quantity * data.quantity * ((data.product_price + (data.product_price * markup / 100)) || 0)).toFixed(2)
        newTotalWholesalePrice = newTotalWholesalePrice ? Number(newTotalWholesalePrice) : 0
        return total + newTotalWholesalePrice;
      }, 0);
      selectedCartTotalWholesalePrice = selectedCartTotalWholesalePrice ? Number(selectedCartTotalWholesalePrice) : 0

      let selectedCartServicesTotalWholesalePrice = cartService?.reduce((total, data, index) => {
        let newTotalWholesalePrice = (data.qty * (data.sellingPrice + (data.sellingPrice * data.originalMarkup / 100)))?.toFixed(2)
        newTotalWholesalePrice = newTotalWholesalePrice ? Number(newTotalWholesalePrice) : 0
        return total + newTotalWholesalePrice;
      }, 0);
      selectedCartServicesTotalWholesalePrice = selectedCartServicesTotalWholesalePrice ? Number(selectedCartServicesTotalWholesalePrice) : 0
      
      let selectedMaterialsTotalWholesalePrice = selectedMaterials?.reduce((total, data, index) => {
        let newTotalWholesalePrice = ((data.markUpCost || 0) * (data.quantity||1))?.toFixed(2)
        newTotalWholesalePrice = newTotalWholesalePrice ? Number(newTotalWholesalePrice) : 0
        return total + newTotalWholesalePrice;
      }, 0);
      selectedMaterialsTotalWholesalePrice = selectedMaterialsTotalWholesalePrice ? Number(selectedMaterialsTotalWholesalePrice) : 0

      let newTotalWholesalePrice = selectedProductsTotalWholesalePrice + selectedServicesTotalWholesalePrice + selectedAddlServicesTotalWholesalePrice + selectedCartTotalWholesalePrice + selectedCartServicesTotalWholesalePrice + selectedMaterialsTotalWholesalePrice
      setTotalWholesalePrice(newTotalWholesalePrice)

      let selectedProductsTotalRetailPrice = selectedProducts?.reduce((total, data, index) => {
        let quantityFieldName = `quantity_${data.product_id ? data.product_id : data.id}`;
        let discountFieldName = `discount_${data.product_id ? data.product_id : data.id}`;
        const dataRetailPrice = data.product_retail_price || data.retail_price
        const dataWholesalePrice = data.product_price || data.price
        const dataQuantity = values[quantityFieldName] ? values[quantityFieldName] : (data?.quantity || 1)

        let newTotalRetailPrice = dataRetailPrice * data.quantity * data.locationQuantity
        if(data?.adjustment){
          newTotalRetailPrice = newTotalRetailPrice ? newTotalRetailPrice + ((newTotalRetailPrice * Number(data?.adjustment)) / 100) : 0
        }
        newTotalRetailPrice = newTotalRetailPrice ? Number(newTotalRetailPrice) : 0
        // return total + newTotalRetailPrice;
        const fixedProductTotal = parseFloat(newTotalRetailPrice).toFixed(2);
        return total + parseFloat(fixedProductTotal);
      }, 0);
      selectedProductsTotalRetailPrice = selectedProductsTotalRetailPrice ? Number(selectedProductsTotalRetailPrice) : 0

      let newTotalRetailPrice = selectedProductsTotalRetailPrice + selectedServicesTotalWholesalePrice + selectedAddlServicesTotalWholesalePrice + selectedCartTotalWholesalePrice + selectedCartServicesTotalWholesalePrice + selectedMaterialsTotalWholesalePrice
      setTotalRetailPrice(newTotalRetailPrice)

      //discount count
      let selectedProductDiscount = selectedProducts?.reduce((acc, data, index) => {
        // const discountFieldName = `discount_${data.product_id?data.product_id:data.id}`;
        // const discountVal = values[discountFieldName]?values[discountFieldName]:(data?.discount||0);
        const discountVal = data.discount ? data.discount : 0
        let productDiscount = +discountVal || 0;
        return acc + productDiscount;
      }, 0);
      const newDiscountTotal = selectedProductDiscount + (parseFloat(values['invoice_discount']) || 0);
      setDiscountTotal(selectedProductDiscount + (parseFloat(values['invoice_discount']) || 0));

      console.log("#############selectedProductsTotalProductFormula", selectedProductsTotalProductFormula);
      console.log("#############selectedProductsTotalWholesalePrice", selectedProductsTotalWholesalePrice);
      console.log("#############selectedProductsTotalRetailPrice", selectedProductsTotalRetailPrice);
      console.log("#############newDiscountTotal", newDiscountTotal);
      console.log("#############selectedRfpProduct", selectedRfpProduct);
      console.log("#############selectedMaterialCost", selectedMaterialCost);
      console.log("#############selectedMaterialCostJobCost", selectedMaterialCostJobCost);
      console.log("#############selectedMaterialCostJobCost", selectedMaterialCostJobCost);
      console.log("#############selectedProductsTotalDiscountForJobCost", selectedProductsTotalDiscountForJobCost);
      console.log("#############selectedServicesCost", selectedServicesCost);
      console.log("#############selectedRfpProductJobCost", selectedRfpProductJobCost);
      console.log("#############selectedRfpServicesJobCost", selectedRfpServicesJobCost);


      totalJobcostSum = (selectedProductsTotalDiscountForJobCost + selectedServicesCost + selectedRfpProductJobCost + selectedRfpServicesJobCost+selectedMaterialCostJobCost);
      console.log('total job cost------- budget', totalJobcostSum);
      values['total'] = sum
      values['taxAmount'] = taxAmountData
      values['budget'] = totalJobcostSum
      setBudget(totalJobcostSum)
      let subTotal = markupType == 1 ? ((selectedProductsTotalWholesalePrice + selectedRfpProduct + selectedMaterialCost))
        : (markupType == 2 ? ((selectedProductsTotalRetailPrice + selectedRfpProduct + selectedMaterialCost)) : 0);
      console.log("################subTotal", subTotal);
      setRFQSubTotal(subTotal)
      // let selectedProductsTotalMarkupAmount = markupType == 1 ? ((selectedProductsTotalWholesalePrice - newDiscountTotal) - selectedProductsTotalProductFormula)
      //   : (markupType == 2 ? ((selectedProductsTotalRetailPrice - newDiscountTotal) - selectedProductsTotalProductFormula) : 0);
      // setTotalMarkupAmount(selectedProductsTotalMarkupAmount)
      let selectedProductsTotalMarkupAmount = sum - totalJobcostSum
      setTotalMarkupAmount(selectedProductsTotalMarkupAmount)

      // let selectedProductsTotalMarkup = (selectedProductsTotalMarkupAmount / totalJobcostSum) * 100
      let selectedProductsTotalMarkup = totalJobcostSum !== 0 
        ? (selectedProductsTotalMarkupAmount / totalJobcostSum) * 100 
        : 0;
      setTotalMarkupPercentage(selectedProductsTotalMarkup)

      console.log('selectedProductsTotalMarkupAmount-------', selectedProductsTotalMarkupAmount);
      console.log('totalJobcostSum-------', totalJobcostSum);
      console.log('selectedProductsTotalMarkup-------', selectedProductsTotalMarkup);
      console.log('initialServiceCost In ProductFormula-----', initialServicesCost);
      setInitialServiceCostInProductFormula(initialServicesCost)

    } catch (error) {
      console.log('Error--------------', error);
    }
  }, [currentRFQData, selectedTaxCategory, selectedProducts,selectedMaterials, selectedServices, additionalSelectedServices, values.markup_type, values.invoice_discount, refreshData, cartService, cart,loading])

  console.log('additionalSelectedServices--', additionalSelectedServices);
  console.log("####################selectedMaterials", selectedMaterials);
  ////image functionality
  const handleRemoveAll = () => {
    setAvatarUrl([]);
  };

  // const handleRemove = (file) => {
  //   console.log('filefilefilefile',file)
  //   const filteredItems = avatarUrl.filter((_file) => _file !== file);
  //   setAvatarUrl([filteredItems]);
  // };

  ///////
  // const handleDrop = useCallback(
  //   (acceptedFiles) => {
  //     if (acceptedFiles && acceptedFiles.length > 0) {
  //       const file = acceptedFiles[0];
  //       const Filename = new Date().getTime() + file.name;

  //       const StorageRef = imageref(storage, Filename);
  //       const uploadTask = uploadBytesResumable(StorageRef, file);

  //       uploadTask.on(
  //         'state_changed',
  //         (snapshot) => {
  //           const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
  //         },
  //         (error) => {
  //           console.error(error.message);
  //         },
  //         () => {
  //           getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
  //             setFieldValue('avatarUrl', downloadURL)

  //           });
  //         }
  //       );
  //     }
  //   },
  //   [setFieldValue]
  // );

  const handleRemove = (file) => {
    console.log('Removing file:', file);
  
    const filteredItems = avatarUrl.filter((_file) => {
      // If files have `url`, compare by `url`
      if (file.url && _file.url) {
        return _file.url !== file.url;
      }
      
      // If files have `name`, compare by `name`
      if (file.name && _file.name) {
        return _file.name !== file.name;
      }
  
      // If files have `id`, compare by `id`
      if (file.id && _file.id) {
        return _file.id !== file.id;
      }
  
      return true;
    });
  
    setAvatarUrl(filteredItems); // No need to wrap in an array
  };
  

    const handleDrop = useCallback(
      (acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
          const uploadPromises = acceptedFiles?.map((file) => {
            return new Promise((resolve, reject) => {
              const Filename = new Date().getTime() + file.name;
              const StorageRef = imageref(storage, Filename);
              const uploadTask = uploadBytesResumable(StorageRef, file);
  
              uploadTask.on(
                'state_changed',
                (snapshot) => {
                  const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                  setProgress(progress);
                },
                (error) => {
                  console.error(error.message);
                  reject(error);
                },
                () => {
                  getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    resolve({
                      url: downloadURL,
                      file_type: file.type,
                      extension: file.name.split('.').pop(),
                      size: file.size
                    });
                  });
                }
              );
            });
          });
  
          Promise.all(uploadPromises)
            .then((downloadURLs) => {
              setAvatarUrl((prevAvatarUrls) => {
                const updatedFiles = downloadURLs.map((file) => ({
                  url: file.url,
                  file_type: file.file_type,
                  extension: file.extension,
                  size: file.size
                }));
                return [...prevAvatarUrls, ...updatedFiles]
              });
            })
            .catch((error) => {
              console.error(error.message);
            });
        }
      },
      [setFieldValue]
    );

  let subTotal = 0
  const ACCOUNT_TABS = [
    {
      value: `${user.isVendor ? '' : 'Sales Order '}Details`,
      component: details ? (
        <ViewRequest
          teamMembers={teamMembers}
          clientDetails={invoice}
          products={invoice?.cust_product}
          productsWithLocation={invoice?.cust_product_location}
          fetchRequestDetails={fetchRequestDetails}
          fetchRequestDetailsNew={fetchRequestDetailsNew}
          user={user}
          designs={designs}
          setDesigns={setDesigns}
          details={details}
          isRequest={true}
          requestId={id}
          signAgreements={signAgreements}
          customTemplate={customTemplate}
          questionnaireTemplate={questionnaireTemplate}
          allDataEmpty={allDataEmpty}
          portfolio={portfolio}
          invoice={invoice}
          setRenderCards={setRenderCards}
          renderCards={renderCards}
          rfpServicesEdit={rfpServicesEdit}
          requestServices={requestServices}
          labourInstallService={labourInstallService}
        />
      ) : "",
    },
    ...((user.user_type === 3 || explodedPermissions?.some((permission) => ['Access Sales Order Public Message Board'].includes(permission))) ? [
    {
      value: 'public message board',
      component: (
        <PublicMessageBoard
          products={invoice?.cust_product}
          requestDetails={details}
          requestId={id}
          acceptedClients={acceptedClients}
          setRenderCards={setRenderCards}
          renderCards={renderCards}
          fetchRequestDetails={fetchRequestDetails}
          details={details}
        />
      ),
    }
  ] : []),
    ...(user.isVendor ? [] : [
      {
        // value: 'change order',
        value: 'Job Log',
        component: <RequestTabUpdates updates={updates?.length > 0 ? updates : []} />,
      },
      {
        value: 'payments',
        component: <PaymentRequest popupOn={popupOn} idOfProject={idOfProject} totalValue={!empty(details?.allInstallationCompleted) ? invoice?.grandTotal : invoice?.total } setLoading={setLoading} setRefresh={setRefresh} refresh={refresh} />,
      },
      {
        value: 'Locations and Notes',
        component: <Locations products={invoice?.cust_product} details={details} additionalSelectedServices={requestServices} designs={designs} rfpServices={rfpServicesEdit} />,
      },
      {
        value: 'Installation Reports',
        component: <SalesOrderInstallationReportView products={invoice?.cust_product} details={details} additionalSelectedServices={requestServices} designs={designs} rfpServices={rfpServicesEdit} />,
      },
    ]),
    ...(!details?.map_service ? [
      {
        value: 'Route - Map Services',
        component: <RouteMapInstall salesOrderDetails={details} refresh={refresh} setRefresh={setRefresh}/>,
      },
    ] : [
      {
        value: 'Route - Map Services',
        component: <RouteMap details={details} id={id} route_id={details?.map_service} />,
      },
    ]),
    // ...(userType === 3 ? (user.isVendor ? [
    //   {
    //     value: 'My Proposal',
    //     component: <Proposals setReloadData={() => setRefresh(!refresh)} acceptedClients={acceptedClients} proposalData={proposalData} />,
    //   },
    // ] : []) : [
    //   {
    //     value: 'Proposals',
    //     component: <Proposals setReloadData={() => setRefresh(!refresh)} acceptedClients={acceptedClients} proposalData={proposalData} />,
    //   },
    // ]),
  ];

  const handleDeleteService = (servId, indexId, newIndexId) => {
    setCheckLength(!checkLength)
    console.log('indexId--------',newIndexId,indexId,servId)
    // const newFilteredServices = additionalSelectedServices?.filter(data => !(data.id == servId && data.indexId == indexId));
    const newFilteredServices = additionalSelectedServices?.filter((data) => {
        console.log('indexId--------data-servId', servId, data.id)
        console.log('indexId--------data-indexId', indexId, data.indexId)
        console.log('indexId--------data-isAdditionalProduct', data.isAdditionalProduct)
        console.log('indexId--------data-newIndexId', newIndexId, data.newIndexId)
        if (data?.isAdditionalProduct) {
          return data?.newIndexId !== newIndexId;
        }
        return !(data.id == servId && data.indexId == indexId)
    });
    const newLocationStates = { ...serviceOnlyLocationStates };
    delete newLocationStates[indexId];
    setServiceOnlyLocationStates(newLocationStates);
    // const allFilteredServices = filteredServices?.find(data => data.id !== servId);
    setFieldValue('services', newFilteredServices);
    setAdditionalSelectedServices(newFilteredServices);
  };
  // useEffect(() => {
  //   if (selectedProducts.length <= 0) {
  //     setAllLocationSame(false)
  //   }
  // }, [selectedProducts])

  useEffect(() => {
    if (selectedProducts.length <= 0) {
      setAllLocationSame(false)
    }
    if (additionalSelectedServices.length <= 0) {
      setAllServiceLocationSame(false)
    }
    if (cart.length <= 0) {
      setAllRfpProductsLocationSame(false)
    }
    if (cartService.length <= 0) {
      setAllRfpServiceLocationSame(false)
    }

  }, [checkLength])

  const handleCancel = async (isEdit) => {
    
    setOpenEdit(false)
    setIsOpen(false)
    setCurrentRFQdata({})
    // resetForm();
    setSelectedProducts([])
    setSelectedMapserviceProducts([]);
    setSelectedServices([]);
    setAdditionalSelectedServices([])
    setFilteredServices([])
    fetchRequestDetailsNew()
    fetchRequestDetails()
    setAllLocationSame(false)
    // closeView()
    // onDataChange()
    // setRenderCards(!renderCards);
    if(popupOn && !isEdit){
      closeView()
      // setRenderCards(!renderCards)
      onDataChange()
      }
      if(editState){
        editState=false
        setOpenEdit(false)
        setIsOpen(false)
      }

      if(!popupOn && !isEdit){
        if(isFromProjects){
          window.history.back()
        }else{
          navigate(`/dashboard/official-request`)
        }
      }
    
    // if (edit) {
      localStorage.removeItem('salesOrderProjectId');
      localStorage.removeItem('salesOrderProjectName');
      localStorage.removeItem('isEditSalesOrder');
      localStorage.removeItem('salesOrderOpenPopup',);
      // closeView()
      // navigate(`/dashboard/official-request`)
    // }
  }

  const selectedProductsAdditionalCost = selectedProducts?.filter(item=>item?.isAdditionalProduct == true)
    ?.reduce((total, item) => total + (item.product_total || 0), 0)
    ?.toFixed(2)

  const selectedMaterialSubTotal = selectedMaterials //?.filter(item=>item?.isAdditionalProduct == true)
    ?.reduce((total, item) => total + ((item.markUpCost || 0) * (item.quantity || 1)), 0)
    ?.toFixed(2)

  console.log("cartService--cartService--", cartService);
  console.log("cartService--rfpServiceLocationStates--", rfpServiceLocationStates);
  console.log("cartService--cart--", cart);
  console.log("cartService--rfpLocationStates--", rfpLocationStates);

  let totalMarkup = 0;
  let totalServiceMarkup = 0;
  let originaltotalMarkup = 0
  let originaltotalServiceMarkup = 0
  let totalAmount = 0;
  return (
    <Page title="Sales Order: View | Sign Wise Solutions" >

      {(loading || convertToRFQ) ? 
        <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center',mt:8 }} >
          <LoadingScreen />
        </Stack>
      :
        <Container sx={{mt:3}}>
          <Button
            variant="outlined"
            type="submit"
            // onClick={()=>navigate(PATH_DASHBOARD.official.root)}
            // onClick={() => { window.history.back() }}
            onClick={() => {
              openEdit ? handleCancel(true) : handleCancel() //setOpenEdit(false) //handleCancel() // navigate(PATH_DASHBOARD.official.root)}
            }}
            sx={{ alignItems: 'flex-end', mb: 4 }}
            startIcon={ // Use the startIcon prop to add an icon to the left
              <Stack>
                <KeyboardBackspaceOutlined />
              </Stack>

            }
          >
            Back
          </Button>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <HeaderBreadcrumbs
              heading={details?.name}
            />
            {!openEdit && (
              <Stack marginBottom={5} flexDirection={'column'}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  {(user.isVendor === 1 && proposalData && !proposalData?.length > 0) && (<Button
                    size="small"
                    variant="contained"
                    disabled={explodedPermissions?.some(permission => ['Submit Proposal'].includes(permission)) ? false : true}
                    onClick={() => setAddProposal(true)}
                    sx={{ height: 34 }}
                    startIcon={<Icon icon={plusFill} />}
                  >
                    Submit Proposal
                  </Button>)}
                </Stack>
                {(userType != 3) ?
                  <Stack>
                    {(details && invoice) ? (
                      <Stack flexDirection={'row'} gap={2}>
                        {details?.status !== 6 && !details?.installationStatus && (
                          <Tooltip title="Edit">
                            <IconButton sx={{ backgroundColor: theme.palette.divider }} onClick={() => {
                              explodedPermissions?.some(permission => ['Edit Request'].includes(permission))
                                ? handleEditClick()
                                : !empty(details?.allInstallationCompleted)
                                  ? enqueueSnackbar("Installation Completed", { variant: 'error' })
                                : details?.installationStatus
                                  ? enqueueSnackbar("Access Denied: Moved to Installation Phase", { variant: 'error' })
                                  : enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' });
                            }}>
                              <Icon icon={editFill} width={24} height={24} color="#ffffff" />
                            </IconButton>
                          </Tooltip>
                        )}

                        {/* <Tooltip title="Delete">
                          <IconButton sx={{ backgroundColor: theme.palette.divider }} onClick={() => {
                            explodedPermissions?.some(permission => ['Delete request'].includes(permission))
                              ? handleDeleteMenu()
                              : enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' });
                          }}>
                            <Icon icon={trash2Outline} width={24} height={24} color="#ffffff" />
                          </IconButton>
                        </Tooltip> */}

                        {!details || details?.installationStatus ? '' :
                          <Tooltip title="Delete">
                            <IconButton sx={{ backgroundColor: theme.palette.divider }} onClick={() => {
                              explodedPermissions?.some(permission => ['Delete request'].includes(permission))
                              ? handleDeleteMenu()
                              : enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' });
                            }}>
                              <Icon icon={trash2Outline} width={24} height={24} color="#ffffff" />
                            </IconButton>
                          </Tooltip>
                        }

                        {details?.status === 6 && (
                          <Tooltip title="Reopen">
                            <IconButton sx={{ backgroundColor: theme.palette.divider }} onClick={handleReopen}>
                              <Icon icon={lockOpenFill} width={24} height={24} color="#ffffff" />
                            </IconButton>
                          </Tooltip>
                        )}
                        {explodedPermissions?.some(permission => ['Create Request','Edit Request','Delete request'].includes(permission)) ?
                          (details?.status === 3 && (details.project_category ==13 ||details?.min_payment_status ==1) && (
                            <AnimatedButton title={'Convert to Production'} onClick={()=>{ setOpenConvertToRFQ(true); console.log('details-setOpenConvertToRFQ--',details); }} />
                          )) : '' }
                        {/* {details?.status === 3 && (
                          <Tooltip title="Convert to Production">
                            <IconButton sx={{ backgroundColor: theme.palette.divider }} onClick={()=>{ setOpenConvertToRFQ(true); console.log('details-setOpenConvertToRFQ--',details); }}>
                              <Icon icon={plusFill} width={24} height={24} color="#ffffff" />
                            </IconButton>
                          </Tooltip>
                        )} */}
                      </Stack>
                    ) : (
                      <Stack flexDirection="row" alignItems="center" gap={1}>
                        <CircularProgress size={20} color="primary" variant="indeterminate" />
                        <Typography variant="body2" color="textSecondary">Loading data...</Typography>
                      </Stack>
                    )}
                  </Stack>
                : ""}
              </Stack>
            )}
          </Stack>
          {openEdit && (loadingEdit || !values?.name ? (
            <LoadingScreen />
          ) : (
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={3}>

                  <Grid item xs={12} md={12}>
                    <Card sx={{ p: 3 }}>
                      <Stack spacing={3}>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                          <Stack flexDirection={'column'}>
                            {/* <LabelStyle>Name</LabelStyle> */}
                            <TextField
                              //   fullWidth
                              sx={{ width: 400 }}
                              label="Order Name"
                              {...getFieldProps('name')}
                              error={Boolean(touched.name && errors.name)}
                              helperText={touched.name && errors.name}
                            />
                          </Stack>
                        </Stack>
                        {/* <LabelStyle>Description</LabelStyle> */}
                        <TextField
                          fullWidth
                          multiline
                          minRows={3}
                          maxRows={7}
                          label="keep the summary brief and make it communicates"
                          {...getFieldProps('description')}
                          error={Boolean(touched.description && errors.description)}
                          helperText={touched.description && errors.description}
                        />
                        {/* <LabelStyle>Upload Approved Design</LabelStyle> */}
                        {/* <UploadAvatar
                        accept="image/*"
                        file={values.avatarUrl}
                        maxSize={3145728}
                        onDrop={handleDrop}
                        error={Boolean(touched.avatarUrl && errors.avatarUrl)}
                      /> */}
                        {/* <UploadMultiFile
                         showPreview
                        maxSize={3145728}
                        accept="image/*"
                         files={avatarUrl}
                        onDrop={handleDrop}
                        onRemove={handleRemove}
                        onRemoveAll={handleRemoveAll}
                    // error={Boolean(touched.avatarUrl && errors.avatarUrl)}
                    /> */}

                    {userType !== 3 &&
                                      explodedPermissions?.some((permission) =>
                                        ['Create Request'].includes(permission),
                                      ) && (
                                        <>
                                          {/* {values?.project_category?.name  != 'Service only' &&
                                            values?.project_category?.name  !=
                                              'Time & Material' && (
                                              <> */}
                                                <LabelStyle>Upload Designs / Documents</LabelStyle>
                                                <UploadMultiFile
                                                  showPreview
                                                  maxSize={3145728}
                                                  accept="image/*,application/pdf"
                                                  files={avatarUrl}
                                                  onDrop={handleDrop}
                                                  onRemove={handleRemove}
                                                  onRemoveAll={handleRemoveAll}
                                                  // error={Boolean(touched.avatarUrl && errors.avatarUrl)}
                                                />
                                              {/* </>
                                            )} */}
                                            </>)
                    }




                        {/* <LabelStyle>Skills or services required</LabelStyle> */}
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                          {/* <Autocomplete
                          multiple
                          fullWidth
                          id="combo-box-demo"
                          options={skills}
                          value={values.skills}
                          onChange={(event, newValue) => setFieldValue('skills', newValue)}
                          renderInput={(params) => (
                            <TextField {...params} label="Skills or services required" />
                          )}
                          getOptionLabel={(option) => option.name}
                        /> */}
                          <Autocomplete
                            sx={{ width: '50%' }}
                            id="combo-box-demo"
                            disabled={details?.checkProjectExist ? true : false}
                            options={allClientsEdit}
                            value={values.clients}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setFieldValue('clients', newValue);
                                setFieldValue('community', null);
                                fetchAllCommunityByClient(newValue);
                              } else {
                                setFieldValue('clients', null);
                                setFieldValue('community', null);
                                setCommunities([]);
                              }
                            }}

                            renderInput={(params) => (
                              <TextField required
                                {...params} label="Clients"
                                error={Boolean(touched.clients && errors.clients)}
                                helperText={touched.clients && errors.clients} />
                            )}
                            getOptionLabel={(option) => option.name}
                          />
                          {isCommunityLoading ?
                              <Stack flexDirection={'row'} alignItems='center' justifyContent='center'>
                                <CircularProgress color="primary" variant="indeterminate" />
                                <Typography variant='body2'>Loading projects...</Typography>
                              </Stack> :
                          communities.length > 0 ? (<Autocomplete
                            sx={{ width: '50%' }}
                            id="community"
                            options={communities}
                            value={values.community}
                            onChange={(event, newValue) => {
                              if(newValue){
                              setFieldValue('community', newValue)
                              if (newValue.address || newValue.city || newValue.state || newValue.zip) {
                                const { address, city, state, zip } = newValue;
                        
                                // 3) Update selected products’ addresses
                                setSelectedProducts((prevProducts) =>
                                  prevProducts.map((product) => {
                                    if (product?.fromMapServices == true) {
                                      return product;
                                    }else{
                                      const updatedAddresses = product?.location.map((loc) => ({
                                        ...loc,
                                        address: address || loc.address,
                                        city: city || loc.city,
                                        state: state || loc.state,
                                        zip: zip || loc.zip,
                                      }));
                                      return { ...product, location: updatedAddresses };
                                    }
                                  })
                                );


                                console.log("cartServicecartServicecartService",selectedProducts)
                                // 4) Update selected services’ addresses (if applicable)
                                setAdditionalSelectedServices((prevServices) =>
                                  prevServices.map((service) => {
                                    const updatedAddresses = service.addresses.map((loc) => ({
                                      ...loc,
                                      address: address || loc.address,
                                      city: city || loc.city,
                                      state: state || loc.state,
                                      zip: zip || loc.zip,
                                    }));
                                    return { ...service, addresses: updatedAddresses };
                                  })
                                );


                                // 5) Update RFP Products’ addresses
                                

                                setCart((prevCart) =>
                                  prevCart.map((product) => {
                                    const updatedAddresses = (product.location).map((loc) => ({
                                      ...loc,
                                      address: address || loc.address,
                                      city: city || loc.city,
                                      state: state || loc.state,
                                      zip: zip || loc.zip,
                                    }));
                                    return { ...product, location: updatedAddresses };
                                  })
                                );

                                  // 6) Update RFP Services’ addresses (only for 'Service only' category)
                                  //  if (values.project_category?.name === 'Service only') {
                                    // console.log("cartServicecartServicecartService",cartService)
                                    setCartService((prevServices) =>
                                      prevServices?.map((service) => {
                                        const updatedAddresses = (service?.location|| []).map((loc) => ({
                                          ...loc,
                                          address: address || loc.address,
                                          city: city || loc.city,
                                          state: state || loc.state,
                                          zip: zip || loc.zip,
                                        }));
                                        return { ...service, location: updatedAddresses };
                                      })
                                    );
                                  // }
                                }
                              if(newValue.tax_category){
                              let taxCat = taxCategories?.find(cat => newValue.tax_category === cat.id)
                              setSelectedTaxCategory(taxCat)
                              }
                            }else {
                              setFieldValue('community', null);
                              setSelectedTaxCategory(null);
                            }
                            }}
                            renderInput={(params) => (
                              <TextField required
                                {...params} label="Project"
                                error={Boolean(touched.community && errors.community)}
                                helperText={touched.community && errors.community} />
                            )}
                            getOptionLabel={(option) => option.name}
                          />) : (
                            <Stack sx={{ width: '50%' }} justifyContent={'center'}>
                              {values?.clients ?
                                <Typography sx={{ color: "red" }}>The selected client does not have any projects yet. Please create a new project for them!</Typography>
                                : <Typography sx={{ color: "red" }}>No client selected. Please choose a client from the list.</Typography>}
                            </Stack>
                          )
                        }
                        </Stack>

                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                          <Autocomplete
                            fullWidth
                            id="combo-box-demo-type"
                            options={projectTypes}
                            value={values.project_type}
                            onChange={(event, newValue) => {
                              setFieldValue('project_type', newValue)
                              setFieldValue('project_category', null)
                              setFieldValue('map_service', null)
                              if (newValue?.name !== 'TS') {
                                setFieldValue('map_service', null)
                                const filteredProducts = selectedProducts?.filter(product => product?.fromMapServices !== true);
                                setSelectedProducts(filteredProducts);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField required
                                {...params} label="Job Class"
                                error={Boolean(touched.project_type && errors.project_type)}
                                helperText={touched.project_type && errors.project_type} />
                            )}
                            getOptionLabel={(option) => option.name}

                          />

                          {/* <Autocomplete
                            fullWidth
                            disabled
                            id="combo-box-demo-type"
                            options={markupTypes}
                            value={values.markup_type}
                            onChange={(event, newValue) => {
                              setFieldValue('markup_type', newValue)
                              setMarkupType(newValue?.id)
                            }}
                            renderInput={(params) => (
                              <TextField required
                                {...params} label="Markup Type"
                                error={Boolean(touched.markup_type && errors.markup_type)}
                                helperText={touched.markup_type && errors.markup_type} />
                            )}
                            getOptionLabel={(option) => option.name}
                          /> */}
                        </Stack>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                          <Autocomplete
                            // fullWidth
                            sx={{ width: '50%' }}
                            id="combo-box-demo-category"
                            // options={projectCategories}
                            options={(values.project_type?.name == 'TS') ? projectCategories : projectCategories?.filter(ite=>ite.id != 14)}
                            value={values.project_category}
                            onChange={(event, newValue) => {
                              if(newValue?.name== 'No Charge' || newValue?.id==13){
                                setNoCharge(true)
                                // setFieldValue('client_approve',true)
                              }else{
                                setNoCharge(false)
                                setFieldValue('no_charge_type', null)
                              }
                              if(newValue?.name == 'Service only' || newValue?.name == 'Time & Material'){
                                setSelectedProducts([])
                                setSelectedMapserviceProducts([]);
                                setSelectedServices([])
                                setAdditionalSelectedServices([])
                                setSelectedMaterials([])
                                setFilteredServices(labourCostServices)
                                setCart([])
                                setCartService([])
                                if(newValue?.name == 'Time & Material'){
                                  // addNewProductRow()
                                  // setMarkupType(1)
                                  // setFieldValue('client_approve',true)
                                }else{
                                  setSelectedMaterials([])
                                }
                              }
                              if(values.project_category?.name == 'Time & Material' || values.project_category?.id == 14){
                                setSelectedProducts([])
                                setSelectedMapserviceProducts([]);
                                setSelectedServices([])
                                setAdditionalSelectedServices([])
                                setSelectedMaterials([])
                                setCart([])
                                setCartService([])
                                // setFieldValue('client_approve',true)
                              }
                              if ( values.project_category?.name == 'Service only' ) {
                                setSelectedMaterials([])
                              }
                              setFieldValue('project_category', newValue)
                              if (values?.project_type?.name !== 'TS' || newValue?.id != 14) {
                                setFieldValue('map_service', null)
                                if(newValue?.name == 'Service only' || newValue?.name == 'Time & Material'){
                                  setSelectedProducts([]);
                                  setSelectedMapserviceProducts([]);
                                  setSelectedServices([])
                                }
                                setSelectedMapserviceProducts([]);
                                // setSelectedServices([]);  this cause the calculation issue on Total Additional Service Cost (negative value)
                                // const filteredProducts = selectedProducts?.filter(product => product?.fromMapServices !== true);
                                // setSelectedProducts(filteredProducts);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField required
                                {...params} label="Job Type"
                                error={Boolean(touched.project_category && errors.project_category)}
                                helperText={touched.project_category && errors.project_category} />
                            )}
                            getOptionLabel={(option) => option.name}
                          />
                          {values.project_category?.id == 14 ? 
                            <Autocomplete
                              // fullWidth
                              sx={{ width: '50%' }}
                              id="combo-box-demo-type"
                              options={mapServices}
                              value={values.map_service}
                              onChange={(event, newValue) => {
                                console.log('map_service==newValue', newValue)
                                setFieldValue('map_service', newValue)
                                if (newValue?.products) {
                                  let newIndexCounter = indexCounter;
                                  const updatedProductsWithLocations = newValue?.products?.map((product,index) => {
                                    const filteredData = allProducts?.find((i) => i.id === product.product_id);
                                    const associatedLocations = newValue?.locations
                                      .filter((location) => location?.route_product_id === product?.id)
                                      .map((location) => ({
                                        address: location.address,
                                        city: location.city,
                                        state: location.state,
                                        zip: location.zip,
                                        route_location_id: location.id,
                                      }));
                                      newIndexCounter += 1;
                                    return {
                                      ...product,
                                      ...filteredData,
                                      indexId: newIndexCounter, // `map_${index}`,
                                      fromMapServices:true,
                                      locationQuantity: product?.qty,
                                      location: associatedLocations,
                                      discount: 0,
                                      adjustment: 0,
                                      quantity: 1,
                                      markAdjType:1
                                    };
                                  });
                                  console.log('updatedProductsWithLocations-----------',updatedProductsWithLocations)
                                  const mergedSelectedProducts = [
                                    ...selectedProducts.filter((product) => product?.fromMapServices !== true), // Keep existing products without fromMapServices
                                    ...updatedProductsWithLocations, // Add the new products from map services
                                  ];
                                  setSelectedMapserviceProducts(updatedProductsWithLocations);
                                  setSelectedProducts(mergedSelectedProducts);
                                  setIndexCounter(newIndexCounter);
                                  const productServices = mergedSelectedProducts?.reduce((services, product) => {
                                    if (product.services && product.services.length > 0) {
                                      return services.concat(
                                        product.services.map((service) => ({
                                          ...service,
                                          fromMapServices:product?.fromMapServices || false,
                                          productIndex: product?.indexId,
                                          quantity: service.quantity * product.quantity * product.locationQuantity,
                                          previousQty: service.quantity,
                                          qtyData: product.quantity,
                                          locationQuantity: product.locationQuantity,
                                        }))
                                      );
                                    }
                                    return services;
                                  }, []);
                                  setSelectedServices(productServices);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField required
                                  {...params} label="Map Service"
                                  error={Boolean(touched.map_service && errors.map_service)}
                                  helperText={touched.map_service && errors.map_service} />
                              )}
                              // getOptionLabel={(option) => option.name}
                              getOptionLabel={(option) => `${option.name} (${option.locations_count})`}
                            /> 
                          : '' }

                          {values?.project_category?.id===13 &&
                            <Autocomplete
                              // fullWidth
                              sx={{ width: '50%' }}
                              id="combo-box-demo-type"
                              options={noChargeType}
                              value={values.no_charge_type}
                              onChange={(event, newValue) => {
                                setFieldValue('no_charge_type', newValue)
                                // setMarkupType(newValue?.id)
                              }}
                              renderInput={(params) => (
                                <TextField required
                                  {...params} label="No charge Type"
                                  error={Boolean(touched.no_charge_type && errors.no_charge_type)}
                                  helperText={touched.no_charge_type && errors.no_charge_type} />
                              )}
                              getOptionLabel={(option) => option.name}
                            />}
                        </Stack>
                        {/* <Stack>
                      <Autocomplete
                          multiple
                          fullWidth
                          id="combo-box-demo"
                          options={workflow}
                          value={values.workflow}
                          onChange={(event, newValue) => setFieldValue('workflow', newValue)}
                          renderInput={(params) => (
                            <TextField {...params} label="Workflow" />
                          )}
                          getOptionLabel={(option) => option.name}

                          />
                      </Stack> */}
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                          <Box
                            component={MobileDatePicker}
                            label="Start Date"
                            minDate={moment()}
                            defaultValue={moment.unix(values.start).format('MM/DD/YYYY')}
                            value={moment.unix(values.start)}
                            onChange={(date) => setFieldValue('start', moment(date).unix())}
                            renderInput={(params) => (
                              <TextField size="small" {...params} fullWidth />
                            )}
                          />

                          <Box
                            component={MobileDatePicker}
                            label="Installation Date"
                            minDate={moment()}
                            defaultValue={moment.unix(values.end).format('MM/DD/YYYY')}
                            value={moment.unix(values.end)}
                            onChange={(date) => setFieldValue('end', moment(date).unix())}
                            renderInput={(params) => (
                              <TextField size="small" {...params} fullWidth
                                error={Boolean(touched.end && errors.end)}
                                helperText={touched.end && errors.end}
                              />
                            )}
                          />
                        </Stack>
                        {(values.project_category?.name == 'Service only' || values.project_category?.name == 'Time & Material') ? '' : <>
                        <Divider />
                        <LabelStyleHead>Products</LabelStyleHead>
                        {false ? 
                        // {values.project_category?.name == 'Time & Material' ? 
                        <>
                        <Scrollbar>
                          <TableContainer sx={{ minWidth: 500 }}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  {VALUE_TIME?.map((val) => (
                                    val.id === 14 ? (
                                      <Tooltip key={val.id} title="Selling Price With Marketing Adjustment">
                                        <TableCell>{val.value}</TableCell>
                                      </Tooltip>) :
                                    <TableCell key={val.id}>{val.value}</TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {selectedProducts?.sort((a, b) => a.indexId - b.indexId)?.map((data, index) => {
                                  console.log("edit product log ---",data)
                                  const quantityFieldName = `quantity_${data.product_id || data.id}`;
                                  const discountFieldName = `discount_${data.product_id || data.id}`;
                                  const adjustmentFieldName = `adjustment_${data.product_id || data.id}`;

                                  let unitName = UNITS?.filter((item) => item?.id == data?.unit)
                                  unitName = unitName[0]?.name
                                  let shortUnitName = ''
                                  if (unitName == 'Square feet') {
                                    shortUnitName = 'Sq ft'
                                  } else if (unitName == 'Lineal feet') {
                                    shortUnitName = 'Lin ft'
                                  } else if (unitName == 'Square Inches') {
                                    shortUnitName = 'Sq Inches'
                                  } else if (unitName == 'Unit') {
                                    shortUnitName = 'Unit'
                                  }
                                  data['expected_cost'] = data.expected_cost || 0
                                  data['expected_material_cost'] = data.expected_material_cost || 0
                                  data['expected_service_cost'] = data.expected_service_cost || 0
                                  data['expected_total_service_cost'] = data.expected_total_service_cost || 0
                                  const dataPrice = data.price ? data.price : 0
                                  const dataretailPrice = data.retail_price ? data.retail_price : 0
                                  const dataWholesalePrice = data.price ? data.price : 0
                                  // const dataQuantity = values[quantityFieldName]
                                  const dataQuantity = data.quantity || 1
                                  console.log('data.discount--', data.discount);
                                  const dataDiscount = data.discount ? data.discount : 0
                                  const rowTotal = calculateTotal(markupType == 1 ? dataWholesalePrice : (markupType == 2 ? dataretailPrice : 0), dataQuantity, dataDiscount, data.quantity, data.locationQuantity, Number(data?.adjustment),data.markAdjType);
                                  // const markup = ((dataretailPrice - dataPrice) / dataPrice) * 100;
                                  const invoiceAmount = parseFloat(data?.expected_material_cost) + parseFloat(data?.expected_total_service_cost?.toFixed(2))
                                  
                                  data['markup'] = 0
                                  totalMarkup += 0
                                  data['markup_type'] = markupType
                                  data['totalMarkup'] = totalMarkup?.toFixed(2)
                                  
                                  // values['totalMarkup'] = totalMarkup?.toFixed(2)
                                  productFormulaCost += parseFloat(data.expected_cost?.toFixed(2)) * data.quantity * data.locationQuantity

                                  const pfc = parseFloat(data.expected_cost?.toFixed(2)) * data.quantity * data.locationQuantity // total product formula cost
                                  const rtp = dataretailPrice * data.quantity * data.locationQuantity // total retail price
                                  const wsp = dataWholesalePrice * data.quantity * data.locationQuantity // total wholesale price

                                  const maxDiscount_wholesale = wsp - pfc
                                  const maxDiscount_retail = rtp - pfc

                                  // const maxDiscount_wholesale = (wsp - pfc) - DiscountTotal
                                  // const maxDiscount_retail = (rtp - pfc) - DiscountTotal

                                  console.log('dataWholesalePrice--', dataWholesalePrice);
                                  console.log('dataretailPrice--', dataretailPrice);
                                  console.log('maxDiscount_wholesale--', maxDiscount_wholesale);
                                  console.log('maxDiscount_retail--', maxDiscount_retail);
                                  console.log('DiscountTotal--', DiscountTotal);
                                  console.log('pfc--', pfc);
                                  console.log('rtp--', rtp);
                                  console.log('wsp--', wsp);
                                  data['product_total']=Number(rowTotal)
                                  data['retail_price']=Number(dataretailPrice)
                                  data['price']=Number(dataPrice)
                                  data['product_id']=null

                                  let SPMA = 0
                                  if(markupType){
                                    if(markupType == 1){
                                      if(data.markAdjType==2 || data.markAdjType==3) {
                                        SPMA = (Number(dataPrice) || 0) + (data.adjustment ? Number(data.adjustment) : 0)
                                      }else{
                                        SPMA = (dataPrice ? Number(dataPrice) : 0) + (((dataPrice ? Number(dataPrice) : 0) * (data.adjustment ? Number(data.adjustment) : 0)) / 100)
                                      }
                                    }else{
                                      if(data.markAdjType==2 || data.markAdjType==3) {
                                        SPMA = (Number(dataretailPrice) || 0) + (dataretailPrice ? Number(data.adjustment) : 0)
                                      }else{
                                        SPMA = (dataretailPrice ? Number(dataretailPrice) : 0) + (((dataretailPrice ? Number(dataretailPrice) : 0) * (dataretailPrice ? Number(data.adjustment) : 0)) / 100)
                                      }
                                    }
                                  }
                                  console.log('SPMA-----data.price-----',dataPrice)
                                  console.log('SPMA-----data.adjustment-----',data.adjustment)
                                  console.log('SPMA-----data.retail_price-----',dataretailPrice)
                                  console.log('SPMA-----SPMA-----SPMA',SPMA)

                                  SPMA = Number(SPMA)
                                  data['SPMA'] = SPMA?.toFixed(2)

                                  return (
                                    <>
                                      <TableRow key={index}>
                                        <TableCell>#{data.indexId}</TableCell>
                                        <TableCell>
                                          {/* albin */}
                                        {/* <TextField required
                                            fullWidth
                                            type='text'
                                            label={'Product Name'}
                                            sx={{ width: 85 }}
                                            value={data.product_name || data.name}
                                            onChange={(event) => {
                                              let newValue = event.target.value;
                                              if (newValue !== "") {
                                                setSelectedProducts(pv => ([...pv.map(p => {
                                                  if (p.indexId === data.indexId) {
                                                    return {
                                                      ...p,
                                                      name: newValue,
                                                    }
                                                  } else {
                                                    return p;
                                                  }
                                                })]));
                                                data['name'] = newValue || '';
                                              }
                                            }}
                                            // error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                            // helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                          /> */}

                                          {/* ram */}

                                          <TextField
                                            required
                                            fullWidth
                                            type="text"
                                            label="Product Name"
                                            sx={{ width: 85 }}
                                            value={data.product_name || ''} // Controlled component with default to empty string
                                            onChange={(event) => {
                                              const newValue = event.target.value;

                                              // Update the selected product's name based on its indexId
                                              setSelectedProducts((prevProducts) =>
                                                prevProducts.map((product) => {
                                                  if (product.indexId === data.indexId) {
                                                    return {
                                                      ...product,
                                                      product_name: newValue, // Update the product_name with newValue
                                                    };
                                                  }
                                                  return product;
                                                })
                                              );
                                              data['product_name'] = newValue || '';
                                              if (isSubmitted) setIsSubmitted(false);
                                            }}
                                            error={isSubmitted && (!data.product_name?.trim())}
                                            helperText={isSubmitted && (!data.product_name?.trim()) ? 'Product Name is required' : ''}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <IconButton onClick={() => handleOpenNote(data.indexId, data.product_notes)}>
                                            <NoteAdd
                                              sx={{
                                                color: (!data?.product_notes || data?.product_notes === '') ? '' : theme.palette.primary.main,
                                              }}
                                            />
                                          </IconButton>
                                        </TableCell>
                                        <TableCell>
                                          <TextField required
                                            fullWidth
                                            type='number'
                                            label={'Retail Price Each'}
                                            sx={{ width: 85 }}
                                            disabled={(values.project_category?.name == 'Time & Material') ? true : false}
                                            value={data.retail_price || data.price}
                                            defaultValue={0}
                                            onChange={(event) => {
                                              let newValue = event.target.value;
                                              console.log('Selling Price newValue',newValue)
                                              if (newValue !== "") {
                                                setSelectedProducts(pv => ([...pv.map(p => {
                                                  console.log('Selling Price---data',data.indexId)
                                                  console.log('Selling Price---p',p.indexId)
                                                  if (p.indexId === data.indexId) {
                                                    return {
                                                      ...p,
                                                      retail_price: Number(newValue),
                                                      price: Number(newValue),
                                                      product_retail_price : Number(newValue),
                                                      product_price : Number(newValue),
                                                    }
                                                  } else {
                                                    return p;
                                                  }
                                                })]));
                                                data['retail_price'] = Number(newValue) || 0;
                                                data['product_retail_price'] = Number(newValue) || 0;
                                                data['price'] = Number(newValue) || 0;
                                                data['product_price'] = Number(newValue) || 0;
                                              }
                                            }}
                                            // error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                            // helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                          />
                                        </TableCell>
                                        <TableCell>{hasMarkupPermission ? (data.SPMA || null) : null}</TableCell>
                                        <TableCell>
                                          <TextField
                                            type="number"
                                            sx={{ width: 85 }}
                                            onKeyPress={(event) => {
                                              if (event.key === '0' && event.target.value === '') {
                                                event.preventDefault();
                                              }
                                              if (event.key === '.') {
                                                event.preventDefault();
                                              }
                                            }}
                                            fullWidth
                                            label={shortUnitName}
                                            disabled={shortUnitName == 'Unit' ? true : false}
                                            // {...getFieldProps(quantityFieldName)}
                                            defaultValue={data.quantity ? data.quantity : 1}
                                            value={data.quantity ? data.quantity : 1}

                                            onChange={(event) => {

                                              let newValue = event.target.value;
                                              if (newValue.startsWith('-')) {
                                                newValue = newValue.slice(1);
                                              }
                                              newValue = newValue.replace(/[^0-9]/g, '');
                                              if (newValue === '' || Number(newValue) <= 0) {
                                                newValue = 1;
                                              }
                                              else {
                                                newValue = Number(newValue);
                                              }

                                              setSelectedProducts(pv => ([...pv.map(p => {
                                                // if (p.id === data.id) {
                                                if (p.indexId === data.indexId) {

                                                  // let arrayAddr

                                                  // const commonAddresses = (allLocationSame && pv.length > 0) ? [...pv[0].location][0]: null;

                                                  // if(allLocationSame && commonAddresses){
                                                  //   arrayAddr = Array((newValue)).fill().map((_, i) => ({
                                                  //     ...commonAddresses,
                                                  //     id: i
                                                  //   }));

                                                  // }
                                                  // else{
                                                  //   arrayAddr = [...p.location]
                                                  //   console.log("Hifiiiiiiiiiiiiii Aff",arrayAddr);
                                                  //   if(p.location.length<=newValue){
                                                  //     arrayAddr.push(...[...Array((newValue)-p.location.length)].map((a,i)=>{
                                                  //       return {
                                                  //         id: p.location.length + i,
                                                  //         address: '',
                                                  //         city: '',
                                                  //         state: '',
                                                  //         zip: '',
                                                  //       }
                                                  //     }))
                                                  //   }else{
                                                  //     arrayAddr = arrayAddr.slice(0,-(p.location.length-(newValue)));
                                                  //   }
                                                  // }

                                                  return {
                                                    ...p,
                                                    quantity: newValue,
                                                    // location: arrayAddr
                                                  }
                                                } else {
                                                  return p;
                                                }
                                              })]));
                                              console.log("###############SElected services", selectedServices);
                                              setSelectedServices(pv => ([...pv.map(service => {
                                                // if (service.product_id === data.id && service.prQty) {
                                                console.log('data---new----', data);
                                                console.log('service---new----', service);
                                                if (service.productIndex === data.indexId && service.prQty) {
                                                  return {
                                                    ...service,
                                                    quantity: (service.prQty * (newValue) * (service?.locationQuantity || 1)).toFixed(2),
                                                    qtyData: Number(newValue),
                                                  };
                                                } else {
                                                  return {
                                                    ...service,

                                                  }
                                                }
                                              })]));
                                              //new chnage on 2-2-24
                                              //   if (newValue === '.') {
                                              //     event.preventDefault();
                                              //     return;
                                              //   }
                                              // if (newValue === '' || newValue <= 0) {
                                              //   event.preventDefault();
                                              //   return;
                                              // }
                                              //   if (newValue.startsWith('-')) {
                                              //     newValue = newValue.slice(1);
                                              //   }
                                              data['quantity'] = newValue || 1;

                                              setFieldValue(quantityFieldName, newValue);
                                            }}

                                            error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                            helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <TextField
                                            type="number"
                                            onKeyPress={(event) => {
                                              if (event.key === '0' && event.target.value === '') {
                                                event.preventDefault();
                                              }
                                              if (event.key === '.') {
                                                event.preventDefault();
                                              }
                                            }}
                                            fullWidth
                                            label={"Qty"}
                                            // {...getFieldProps(quantityFieldName)}
                                            defaultValue={data.locationQuantity ? data.locationQuantity : 1}
                                            value={data.locationQuantity ? data.locationQuantity : 1}
                                            onChange={(event) => {

                                              let newValue = event.target.value;
                                              if (newValue.startsWith('-')) {
                                                newValue = newValue.slice(1);
                                              }
                                              newValue = newValue.replace(/[^0-9]/g, '');
                                              if (newValue === '' || Number(newValue) <= 0) {
                                                newValue = 1;
                                              }
                                              else {
                                                newValue = Number(newValue);
                                              }

                                              setSelectedProducts(pv => ([...pv.map(p => {
                                                // if (p.id === data.id) {
                                                if (p.indexId === data.indexId) {

                                                  let arrayAddr

                                                  const commonAddresses = (data?.allLocationSame && pv.length > 0) ? p.location[0] : null;
                                                  console.log("Hifiiiiiiiiiiiiii Aff", commonAddresses);
                                                  if (data?.allLocationSame && commonAddresses) {
                                                    arrayAddr = Array(Number(newValue)).fill().map((_, i) => ({ //1
                                                      ...commonAddresses,
                                                      id: i,
                                                      req_loc_id: p.location[i]?.req_loc_id ?? null,
                                                    }));

                                                  }
                                                  else {
                                                    arrayAddr = [...p.location]
                                                    console.log("Hifiiiiiiiiiiiiii Aff", arrayAddr);
                                                    if (p.location.length <= Number(newValue)) { //1
                                                      arrayAddr.push(...[...Array(Number(newValue) - p.location.length)].map((a, i) => { //1
                                                        return {
                                                          id: p.location.length + i,
                                                          address: '',
                                                          city: '',
                                                          state: '',
                                                          zip: '',
                                                        }
                                                      }))
                                                    }
                                                    else if (p.location.length > Number(newValue)) { //1
                                                      arrayAddr = arrayAddr.slice(0, Number(newValue)); //1
                                                    }
                                                    else {
                                                      arrayAddr = arrayAddr.slice(0, -(p.location.length - (newValue)));
                                                    }
                                                  }

                                                  return {
                                                    ...p,
                                                    // quantity:newValue,
                                                    locationQuantity: newValue,
                                                    location: arrayAddr
                                                  }
                                                } else {
                                                  return p;
                                                }
                                              })]));
                                              setSelectedServices(pv => ([...pv.map(service => {
                                                console.log("############@@@@@@@@@@service", service);
                                                console.log("############@@@@@@@@@@data", data);
                                                if (service.productIndex === data.indexId && service.prQty) {
                                                  console.log("############service.newValue", newValue);
                                                  return {
                                                    ...service,
                                                    quantity: (newValue * (service.qtyData || 1) * service.prQty).toFixed(2),
                                                    locationQuantity: newValue,
                                                  };
                                                } else {
                                                  return {
                                                    ...service,

                                                  }
                                                }
                                              })]));
                                            }}

                                            error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                            helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <IconButton
                                            onClick={() => {
                                              setLocationStates({ ...locationStates, [data.indexId]: true });
                                            }}
                                          >
                                            <LocationOn sx={{
                                              color: data.location.some(obj =>
                                                [obj.address, obj.city, obj.state, obj.zip].some(value => (value === '' || value === null))
                                              ) ? '' : theme.palette.primary.main
                                            }} />
                                          </IconButton>
                                        </TableCell>
                                        {/* <TableCell>{data.product_id||data.id}</TableCell> */}

                                        <TableCell>
                                      <Autocomplete
  sx={{ width: 70 }}
  id="combo-box-demo"
  disableClearable
  options={marketingAdjustmentType || []}
  value={marketingAdjustmentType?.find((type) => type.id === data.markAdjType)} // Use value to set the correct selected option
  onChange={(event, newValue) => {
    console.log(newValue)
    setSelectedProducts((prevProducts) => {
      return prevProducts.map((product) => {
        if (product.indexId === data.indexId) {
          return {
            ...product,
            markAdjType: newValue?.id, // Set adjustment type or default to the first option
            adjustment: 0,
          };
        }
        return product;
      });
    });
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      required
      error={Boolean(touched.marketingAdjType && errors.marketingAdjType)}
      helperText={touched.marketingAdjType && errors.marketingAdjType}
      label="Select type"
    />
  )}
  getOptionLabel={(option) => (option.name ? option.name : '')}
/>

                                          </TableCell>
                                        <TableCell>
                                          {/* <TextField
                                            type="number"
                                            label="Discount"
                                            value={Number(data.discount)}
                                            // {...getFieldProps(discountFieldName)}
                                            defaultValue={data.discount ? data.discount : 0}
                                            onChange={(event) => {
                                              let newValue = event.target.value;
                                              if (newValue.startsWith('-')) {
                                                newValue = newValue.slice(1);
                                              }
                                              if (markupType == 1) {
                                                if (Number(newValue) > maxDiscount_wholesale) {
                                                  newValue = maxDiscount_wholesale.toFixed(2);
                                                }
                                              } else if (markupType == 2) {
                                                if (Number(newValue) > maxDiscount_retail) {
                                                  newValue = maxDiscount_retail.toFixed(2);
                                                }
                                              } else {
                                                newValue = 0;
                                              }
                                              if (newValue === '-0.00') {
                                                newValue = 0;
                                              }
                                              setRefreshData(!refreshData)
                                              data['discount'] = newValue
                                              setFieldValue(discountFieldName, newValue);
                                            }}
                                            error={Boolean(touched[discountFieldName] && errors[discountFieldName])}
                                            helperText={touched[discountFieldName] && errors[discountFieldName]}
                                            inputProps={{ max: markupType == 1 ? maxDiscount_wholesale : markupType == 2 ? maxDiscount_retail : 0, min: 0 }}
                                          /> */}

                                          <TextField
                                            type="number"
                                            label={
                                              data.markAdjType === 2 ? "$" : data.markAdjType === 3 ? "¢" : "%"
                                            }
                                            // value={Number(data?.adjustment)}
                                            value={Number(data.adjustment).toFixed(2)}
                                            defaultValue={data?.adjustment ? Number(data?.adjustment) : 0}
                                            onChange={(event) => {
                                              let newValue = event.target.value;
                                              if (newValue.startsWith('-')) {
                                                newValue = newValue.slice(1);
                                              }
                                              
                                              if (newValue === '-0.00') {
                                                newValue = 0;
                                              }
                                              setRefreshData(!refreshData)
                                              data['adjustment'] = newValue
                                              setFieldValue(adjustmentFieldName, newValue);
                                            }}
                                            error={Boolean(touched[adjustmentFieldName] && errors[adjustmentFieldName])}
                                            helperText={touched[adjustmentFieldName] && errors[adjustmentFieldName]}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          {rowTotal.toFixed(2)}
                                        </TableCell>
                                        <TableCell >
                                          <IconButton onClick={() => handleDeleteProduct(data.id || data.product_id, data.indexId)}>
                                            <Delete />
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                      <Modal
                                        open={locationStates[data.indexId]}
                                        handleClose={() => {
                                          setLocationStates({ ...locationStates, [data.indexId]: false });
                                        }}
                                      >
                                        {/* {index === 0 &&  */}
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              checked={data?.allLocationSame}
                                              onChange={()=>handleAllLocationSameChange(index)}
                                            />
                                          }
                                          label="All Locations Same"
                                        />
                                        {/* } */}
                                        <Grid
                                          container
                                          spacing={3}
                                          sx={{ justifyContent: 'center', width: '100%' }}
                                        >
                                          {data.location.map((addr, i) => (
                                            <Grid item xs={12}>
                                              <Typography variant="h5" sx={{ mb: 2 }}>
                                                Location
                                              </Typography>

                                              <Stack
                                                direction={{ xs: 'column', sm: 'row' }}
                                                spacing={{ xs: 3, sm: 2 }}
                                                sx={{ mb: 2 }}
                                              >
                                                <TextField
                                                  fullWidth
                                                  label="Address"
                                                  size="small"
                                                  value={data.location[i].address || ''}
                                                  disabled={(i !== 0 && data?.allLocationSame) ? true : false}
                                                  // onChange={(event) => {
                                                  //   let arrayAddr = [...data.location]
                                                  //   arrayAddr[i].address = event.target.value;
                                                  //   setSelectedProducts(pv => ([...pv.map(p => {
                                                  //     // if (p.id === data.id) {
                                                  //     if (p.indexId === data.indexId) {
                                                  //       return {
                                                  //         ...p,
                                                  //         location: arrayAddr
                                                  //       }
                                                  //     } else {
                                                  //       return p;
                                                  //     }
                                                  //   })]))
                                                  // }}
                                                  onChange={(event) => {
                                                    setSelectedProducts((prevProducts) =>
                                                      prevProducts.map((p) => {
                                                        if (p.indexId === data.indexId) {
                                                          let updatedLocations = [...p.location];
                                                          if (p.allLocationSame) {
                                                            updatedLocations = updatedLocations.map(() => ({
                                                              ...updatedLocations[0], 
                                                              address: event.target.value, 
                                                            }));
                                                          } else {
                                                            updatedLocations[i] = {
                                                              ...updatedLocations[i], 
                                                              address: event.target.value,
                                                            };
                                                          }
                                                          return {
                                                            ...p,
                                                            location: updatedLocations,
                                                          };
                                                        }
                                                        return p;
                                                      })
                                                    );
                                                  }}
                                                />
                                              </Stack>

                                              <Stack
                                                direction={{ xs: 'column', sm: 'row' }}
                                                spacing={{ xs: 3, sm: 2 }}
                                                sx={{ mb: 2 }}
                                              >
                                                <TextField
                                                  fullWidth
                                                  label="City"
                                                  size="small"
                                                  value={data.location[i].city || ''}
                                                  disabled={(i !== 0 && data?.allLocationSame) ? true : false}
                                                  // onChange={(event) => {
                                                  //   let arrayAddr = [...data.location]
                                                  //   arrayAddr[i].city = event.target.value;
                                                  //   setSelectedProducts(pv => ([...pv.map(p => {
                                                  //     // if (p.id === data.id) {
                                                  //     if (p.indexId === data.indexId) {
                                                  //       return {
                                                  //         ...p,
                                                  //         location: arrayAddr
                                                  //       }
                                                  //     } else {
                                                  //       return p;
                                                  //     }
                                                  //   })]))
                                                  // }}
                                                  onChange={(event) => {
                                                    setSelectedProducts((prevProducts) =>
                                                      prevProducts.map((p) => {
                                                        if (p.indexId === data.indexId) {
                                                          let updatedLocations = [...p.location];
                                                          if (p.allLocationSame) {
                                                            updatedLocations = updatedLocations.map(() => ({
                                                              ...updatedLocations[0], 
                                                              city: event.target.value, 
                                                            }));
                                                          } else {
                                                            updatedLocations[i] = {
                                                              ...updatedLocations[i], 
                                                              city: event.target.value,
                                                            };
                                                          }
                                                          return {
                                                            ...p,
                                                            location: updatedLocations,
                                                          };
                                                        }
                                                        return p;
                                                      })
                                                    );
                                                  }}
                                                />
                                                <TextField
                                                  fullWidth
                                                  label="State"
                                                  size="small"
                                                  value={data.location[i].state || ''}
                                                  disabled={(i !== 0 && data?.allLocationSame) ? true : false}
                                                  // onChange={(event) => {
                                                  //   let arrayAddr = [...data.location]
                                                  //   arrayAddr[i].state = event.target.value;
                                                  //   setSelectedProducts(pv => ([...pv.map(p => {
                                                  //     // if (p.id === data.id) {
                                                  //     if (p.indexId === data.indexId) {
                                                  //       return {
                                                  //         ...p,
                                                  //         location: arrayAddr
                                                  //       }
                                                  //     } else {
                                                  //       return p;
                                                  //     }
                                                  //   })]))
                                                  // }}
                                                  onChange={(event) => {
                                                    setSelectedProducts((prevProducts) =>
                                                      prevProducts.map((p) => {
                                                        if (p.indexId === data.indexId) {
                                                          let updatedLocations = [...p.location];
                                                          if (p.allLocationSame) {
                                                            updatedLocations = updatedLocations.map(() => ({
                                                              ...updatedLocations[0], 
                                                              state: event.target.value, 
                                                            }));
                                                          } else {
                                                            updatedLocations[i] = {
                                                              ...updatedLocations[i], 
                                                              state: event.target.value,
                                                            };
                                                          }
                                                          return {
                                                            ...p,
                                                            location: updatedLocations,
                                                          };
                                                        }
                                                        return p;
                                                      })
                                                    );
                                                  }}
                                                />
                                                <TextField
                                                  fullWidth
                                                  label="Zip / Postal Code"
                                                  size="small"
                                                  value={data.location[i].zip || ''}
                                                  disabled={(i !== 0 && data?.allLocationSame) ? true : false}
                                                  // onChange={(event) => {
                                                  //   let arrayAddr = [...data.location]
                                                  //   arrayAddr[i].zip = event.target.value;
                                                  //   setSelectedProducts(pv => ([...pv.map(p => {
                                                  //     // if (p.id === data.id) {
                                                  //     if (p.indexId === data.indexId) {
                                                  //       return {
                                                  //         ...p,
                                                  //         location: arrayAddr
                                                  //       }
                                                  //     } else {
                                                  //       return p;
                                                  //     }
                                                  //   })]))
                                                  // }}
                                                  onChange={(event) => {
                                                    setSelectedProducts((prevProducts) =>
                                                      prevProducts.map((p) => {
                                                        if (p.indexId === data.indexId) {
                                                          let updatedLocations = [...p.location];
                                                          if (p.allLocationSame) {
                                                            updatedLocations = updatedLocations.map(() => ({
                                                              ...updatedLocations[0], 
                                                              zip: event.target.value, 
                                                            }));
                                                          } else {
                                                            updatedLocations[i] = {
                                                              ...updatedLocations[i], 
                                                              zip: event.target.value,
                                                            };
                                                          }
                                                          return {
                                                            ...p,
                                                            location: updatedLocations,
                                                          };
                                                        }
                                                        return p;
                                                      })
                                                    );
                                                  }}
                                                />
                                              </Stack>
                                            </Grid>
                                          ))}
                                        </Grid>

                                        <Grid container justifyContent="center" alignItems="center">
                                          <Grid item xs={12} md={4}>
                                          {/* {(i !== 0 && data?.allLocationSame) ? '' : */}
                                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 1 }}>
                                              <LoadingButton variant="contained" onClick={() => {
                                                setIsSaving(true)
                                                setTimeout(() => {
                                                  setIsSaving(false)
                                                  setLocationStates(false)
                                                }, 100)
                                              }} loading={isSaving}>
                                                {' Save '}
                                              </LoadingButton>
                                            </Box>
                                            {/* } */}
                                          </Grid>
                                        </Grid>
                                      </Modal>
                                      <Modal
                                        open={openProductNote[data.indexId] || false}
                                        handleClose={() => handleCloseNote(data.indexId)}
                                      >
                                        <Grid
                                          container
                                          spacing={3}
                                          sx={{ justifyContent: 'center', width: '100%' }}
                                        >
                                            <Grid item xs={12}>
                                              <Typography variant="h5" sx={{ mb: 2 }}>
                                                Note
                                              </Typography>

                                              <Stack
                                                direction={{ xs: 'column', sm: 'row' }}
                                                spacing={{ xs: 3, sm: 2 }}
                                                sx={{ mb: 2 }}
                                              >
                                                <TextField
                                                  fullWidth
                                                  multiline
                                                  rows={4}
                                                  size="medium"
                                                  value={currentNote}
                                                  onChange={handleNoteChange}
                                                  inputProps={{ maxLength: MAX_CHARACTERS }}
                                                  helperText={`${currentNote.length}/${MAX_CHARACTERS} characters`}
                                                />
                                              </Stack>
                                            </Grid>
                                        </Grid>

                                        <Grid container justifyContent="center" alignItems="center">
                                          <Grid item xs={12} md={4}>
                                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 1 }}>
                                              <LoadingButton
                                                variant="contained"
                                                onClick={() => handleSaveNote(data.indexId)}
                                                loading={isSavingNote}>
                                                Save
                                              </LoadingButton>
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </Modal>
                                    </>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Scrollbar>
                        <Stack sx={{ justifyContent:'center' }}>
                        <Box sx={{ justifyContent:'center' }}>
                          <Button variant="outlined" startIcon={ <Stack> <Add /> </Stack> }
                          onClick={()=>{
                            addNewProductRow()
                            // setMarkupType(1)
                          }}>Add Product Row</Button>
                        </Box>
                        </Stack>
                        </>
                        : <>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                          <Autocomplete
                            sx={{ width: 400 }}
                            multiple
                            id="combo-box-demo"
                            options={allProducts?.filter(item=>item.inhouse == 1) || []}
                            // value={selectedProducts}
                            value={selectedProducts?.filter(
                              (item) => (item?.fromMapServices != true && !item.isAdditionalProduct)
                            )}
                            onChange={(event, newValue) => {
                              handleProduct(newValue);
                              setFieldValue('products', newValue);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Select a product" />
                            )}
                            getOptionLabel={(option) => option.product_name ? option.product_name : option.name}
                            renderOption={(props, option) => (
                              <li {...props}>
                                {option.name}
                              </li>
                            )}
                          />
                        </Stack>
                        <LabelStyle>Selected Products</LabelStyle>
                        <Scrollbar>
                          <TableContainer sx={{ minWidth: 500 }}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  {VALUE?.map((val) => (
                                    val.id === 10 ? (
                                      <Tooltip key={val.id} title="Product Formula Cost Per Unit * Quantity">
                                        <TableCell>{val.value}</TableCell>
                                      </Tooltip>
                                    ): (val.id === 14 ? (
                                      <Tooltip key={val.id} title="Selling Price With Marketing Adjustment">
                                        <TableCell>{val.value}</TableCell>
                                      </Tooltip> )
                                     : (
                                      <TableCell key={val.id}>{val.value}</TableCell>
                                    ))
                                  ))}
                                  {/* {VALUE?.map((val) => (
                                  <TableCell>{val.value}</TableCell>
                                ))} */}
                                </TableRow>
                              </TableHead>
                              <TableBody>

                                {selectedProducts?.sort((a, b) => a.indexId - b.indexId)?.map((data, index) => {
                                  const quantityFieldName = `quantity_${data.product_id || data.id}`;
                                  const discountFieldName = `discount_${data.product_id || data.id}`;
                                  const adjustmentFieldName = `adjustment_${data.product_id || data.id}`;

                                  let unitName = UNITS?.filter((item) => item?.id == data?.unit)
                                  unitName = unitName[0]?.name
                                  let shortUnitName = ''
                                  if (unitName == 'Square feet') {
                                    shortUnitName = 'Sq ft'
                                  } else if (unitName == 'Lineal feet') {
                                    shortUnitName = 'Lin ft'
                                  } else if (unitName == 'Square Inches') {
                                    shortUnitName = 'Sq Inches'
                                  } else if (unitName == 'Unit') {
                                    shortUnitName = 'Unit'
                                  }

                                  if(values.project_category?.name == 'Time & Material'){
                                    data.price = 0
                                    data.product_price = 0
                                    data.retail_price = 0
                                    data.product_retail_price = 0
                                    data.expected_cost = 0
                                    data.expected_material_cost = 0
                                    data.expected_total_service_cost = 0
                                  }
                                  const dataPrice = data.product_price || data.price
                                  const dataretailPrice = data.product_retail_price || data.retail_price
                                  const dataWholesalePrice = data.product_price || data.price
                                  // const dataQuantity = values[quantityFieldName]
                                  const dataQuantity = data.quantity || 1
                                  console.log('data.discount--', data.discount);
                                  const dataDiscount = data.discount ? data.discount : 0
                                  const rowTotal = calculateTotal(markupType == 1 ? dataWholesalePrice : (markupType == 2 ? dataretailPrice : 0), dataQuantity, dataDiscount, data.quantity, data.locationQuantity, Number(data?.adjustment),data.markAdjType);
                                  // const markup = ((dataretailPrice - dataPrice) / dataPrice) * 100;
                                  const invoiceAmount = parseFloat(data?.expected_material_cost) + parseFloat(data?.expected_total_service_cost?.toFixed(2))
                                  // let markup = markupType ? (invoiceAmount / rowTotal) : null

                                  let markup = markupType == 1 ? (((dataWholesalePrice - parseFloat(data.expected_cost?.toFixed(2))) / parseFloat(data.expected_cost?.toFixed(2))) * 100)
                                    : (markupType == 2 ? (((dataretailPrice - parseFloat(data.expected_cost?.toFixed(2))) / parseFloat(data.expected_cost?.toFixed(2))) * 100) : 0);
                                  markup = markup ? Number(markup) : 0
                                  data['markup'] = markup
                                  let markupData = data.product_markup?.toFixed(2) || data.markup?.toFixed(2)
                                  markupData = Number(markupData) * data.quantity
                                  data['markup'] = markupData
                                  totalMarkup += Number(data.product_markup) || Number(data.markup) || 0
                                  data['markup_type'] = markupType
                                  data['totalMarkup'] = totalMarkup?.toFixed(2)
                                  let originalMarkup = markupType == 1 ? data.wholesaleMarkup : markupType == 2 ? data.retailMarkup : 0
                                  originaltotalMarkup += originalMarkup
                                  // values['totalMarkup'] = totalMarkup?.toFixed(2)
                                  productFormulaCost += parseFloat(data.expected_cost?.toFixed(2)) * data.quantity * data.locationQuantity

                                  const pfc = parseFloat(data.expected_cost?.toFixed(2)) * data.quantity * data.locationQuantity // total product formula cost
                                  const rtp = dataretailPrice * data.quantity * data.locationQuantity // total retail price
                                  const wsp = dataWholesalePrice * data.quantity * data.locationQuantity // total wholesale price

                                  const maxDiscount_wholesale = wsp - pfc
                                  const maxDiscount_retail = rtp - pfc

                                  // const maxDiscount_wholesale = (wsp - pfc) - DiscountTotal
                                  // const maxDiscount_retail = (rtp - pfc) - DiscountTotal

                                  console.log('maxDiscount_wholesale--', maxDiscount_wholesale);
                                  console.log('maxDiscount_retail--', maxDiscount_retail);
                                  console.log('DiscountTotal--', DiscountTotal);
                                  console.log('pfc--', pfc);
                                  console.log('rtp--', rtp);
                                  console.log('wsp--', wsp);
                                  data['product_total']=rowTotal

                                  let SPMA = 0
                                  if(markupType){
                                    if(markupType == 1){
                                      if(data.markAdjType==2 || data.markAdjType==3) {
                                        SPMA = (Number(dataPrice) || 0) + (data.adjustment ? Number(data.adjustment) : 0)
                                      }else{

                                        SPMA = (Number(dataPrice) || 0) + (((Number(dataPrice) || 0) * (data.adjustment ? Number(data.adjustment) : 0)) / 100)
                                      }
                                    }else{
                                      if(data.markAdjType==2 || data.markAdjType==3) {
                                        SPMA = (Number(dataretailPrice) || 0) + (dataretailPrice ? Number(data.adjustment) : 0)
                                      }else{

                                        SPMA = (Number(dataretailPrice) || 0) + (((Number(dataretailPrice) || 0) * (dataretailPrice ? Number(data.adjustment) : 0)) / 100)
                                      }
                                    }
                                  }
                                  console.log('SPMA-----markupType-----',markupType,data.markAdjType)
                                  console.log('SPMA-----data.price-----',dataPrice)
                                  console.log('SPMA-----data.adjustment-----',data.adjustment)
                                  console.log('SPMA-----data.retail_price-----',dataretailPrice)
                                  console.log('SPMA-----SPMA-----',SPMA)
                                  SPMA = Number(SPMA)
                                  data['SPMA'] = SPMA?.toFixed(2)

                                  console.log(
                                    'location-colour---',data.location,
                                    data.location.some(obj =>
                                      [obj.address, obj.city, obj.state, obj.zip]?.some(value => value === '' || value === null)
                                    )
                                  );

                                  return (
                                    <>
                                      <TableRow key={index}>
                                        <TableCell>#{data.indexId}</TableCell>
                                        <TableCell>{data?.isAdditionalProduct ?
                                          <TextField
                                            required
                                            fullWidth
                                            type="text"
                                            label={'Product Name'}
                                            sx={{ width: 85 }}
                                            value={data.product_name || data.name}
                                            onChange={(event) => {
                                              let newValue =
                                                event.target.value

                                              // Update the selectedProducts state
                                              setSelectedProducts(
                                                (prevProducts) =>
                                                  prevProducts.map(
                                                    (product) => {
                                                      if (
                                                        product.indexId ===
                                                        data.indexId
                                                      ) {
                                                        return {
                                                          ...product,
                                                          name: newValue,
                                                          product_name: newValue,
                                                        }
                                                      } else {
                                                        return product
                                                      }
                                                    },
                                                  ),
                                              )
                                              data['name'] = newValue || ''
                                              data['product_name'] = newValue || ''
                                              if (isSubmitted)
                                                setIsSubmitted(false)
                                            }}
                                            error={
                                              isSubmitted && !data.name?.trim() && !data.product_name?.trim()
                                            }
                                            helperText={
                                              isSubmitted && !data.name?.trim() && !data.product_name?.trim()
                                                ? 'Product Name is required'
                                                : ''
                                            }                                            
                                          />
                                        : data.product_name || data.name}</TableCell>
                                        <TableCell>
                                          <IconButton onClick={() => handleOpenNote(data.indexId, data.product_notes)}>
                                            <NoteAdd
                                              sx={{
                                                color: (!data?.product_notes || data?.product_notes === '') ? '' : theme.palette.primary.main,
                                              }}
                                            />
                                          </IconButton>
                                        </TableCell>
                                        <TableCell>{data?.isAdditionalProduct ? 'N/A' :(parseFloat(data.expected_cost) * data.quantity * data.locationQuantity).toFixed(2)}</TableCell>
                                        {/* <TableCell>{invoiceAmount}</TableCell> */}
                                        <TableCell>{(hasMarkupPermission && markupType == 2) ? 
                                          (data?.isAdditionalProduct ?
                                            <TextField
                                              required
                                              fullWidth
                                              type="number"
                                              label={'Retail Price Each'}
                                              disabled={values.project_category?.name == 'Time & Material' ? true : false}
                                              sx={{ width: 85 }}
                                              value={dataretailPrice}
                                              // onWheel={(e) => e.target.blur()}
                                              onKeyPress={(event) => {
                                                // if (event.key === '0' && event.target.value === '') {
                                                //   event.preventDefault();
                                                // }
                                                if (event.key === '-') {
                                                  event.preventDefault();
                                                }
                                                if (event.key === 'e') {
                                                  event.preventDefault();
                                                }
                                              }}
                                              inputProps={{ min: 0 }}
                                              defaultValue={0}
                                              onChange={(event) => {
                                                let newValue =
                                                  event.target.value
                                                if (newValue !== '') {
                                                  setSelectedProducts(
                                                    (pv) => [
                                                      ...pv.map((p) => {
                                                        if ( p.indexId === data.indexId ) {
                                                          return {
                                                            ...p,
                                                            retail_price:newValue,
                                                            product_retail_price:newValue,
                                                          }
                                                        } else {
                                                          return p
                                                        }
                                                      }),
                                                    ],
                                                  )
                                                  data['product_retail_price'] = newValue || 0
                                                  data['retail_price'] = newValue || 0
                                                  // dataretailPrice = newValue || 0
                                                }
                                              }}
                                              // error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                              // helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                            /> :
                                            dataretailPrice?.toFixed(2))
                                          : null}</TableCell>
                                        <TableCell>{(hasMarkupPermission && markupType == 1) ? 
                                        (data?.isAdditionalProduct ?
                                          <TextField
                                            required
                                            fullWidth
                                            type="number"
                                            label={'Retail Price Each'}
                                            disabled={values.project_category?.name == 'Time & Material' ? true : false}
                                            sx={{ width: 85 }}
                                            value={dataPrice}
                                            // onWheel={(e) => e.target.blur()}
                                            onKeyPress={(event) => {
                                              // if (event.key === '0' && event.target.value === '') {
                                              //   event.preventDefault();
                                              // }
                                              if (event.key === '-') {
                                                event.preventDefault();
                                              }
                                              if (event.key === 'e') {
                                                event.preventDefault();
                                              }
                                            }}
                                            inputProps={{ min: 0 }}
                                            defaultValue={0}
                                            onChange={(event) => {
                                              let newValue = event.target.value
                                              if (newValue !== '') {
                                                setSelectedProducts(
                                                  (pv) => [
                                                    ...pv.map((p) => {
                                                      if ( p.indexId === data.indexId ) {
                                                        return {
                                                          ...p,
                                                          product_price:newValue,
                                                          price:newValue,
                                                        }
                                                      } else {
                                                        return p
                                                      }
                                                    }),
                                                  ],
                                                )
                                                data['product_price'] = newValue || 0
                                                data['price'] = newValue || 0
                                                // dataPrice = newValue || 0
                                              }
                                            }}
                                            // error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                            // helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                          /> :
                                          dataPrice?.toFixed(2))
                                        : null}</TableCell>
                                        <TableCell>{hasMarkupPermission ? (data.SPMA || null) : null}</TableCell>
                                        {/* <TableCell>{hasMarkupPermission ? Number(markupData).toFixed(2) : null}</TableCell> */}

                                        {/* <TableCell> */}
                                          {/* previos */}
                                          {/* {(hasMarkupPermission && markupType == 1) ? data.wholesaleMarkup?.toFixed(2)
                                            : (hasMarkupPermission && markupType == 2) ? data.pf_retail_markup?.toFixed(2)
                                              : null} */}
                                              {/* old */}
                                          {/* {(hasMarkupPermission && markupType == 1) ? data.wholesaleMarkup?.toFixed(2)
                                            : (hasMarkupPermission && markupType == 2) ? data.retailMarkup?.toFixed(2)
                                              : null} */}
                                        {/* </TableCell> */}
                                        {/* <TableCell>{shortUnitName}</TableCell> */}
                                        <TableCell>
                                          <TextField
                                            type="number"
                                            sx={{ width: 85 }}
                                            onKeyPress={(event) => {
                                              if (event.key === '0' && event.target.value === '') {
                                                event.preventDefault();
                                              }
                                              if (event.key === '.') {
                                                event.preventDefault();
                                              }
                                            }}
                                            fullWidth
                                            label={shortUnitName}
                                            disabled={(data?.fromMapServices == true) ? true : shortUnitName == 'Unit' ? true : false}
                                            // {...getFieldProps(quantityFieldName)}
                                            defaultValue={data.quantity ? data.quantity : 1}
                                            value={data.quantity ? data.quantity : 1}

                                            onChange={(event) => {

                                              let newValue = event.target.value;
                                              if (newValue.startsWith('-')) {
                                                newValue = newValue.slice(1);
                                              }
                                              newValue = newValue.replace(/[^0-9]/g, '');
                                              if (newValue === '' || Number(newValue) <= 0) {
                                                newValue = 1;
                                              }
                                              else {
                                                newValue = Number(newValue);
                                              }

                                              setSelectedProducts(pv => ([...pv.map(p => {
                                                // if (p.id === data.id) {
                                                if (p.indexId === data.indexId) {

                                                  // let arrayAddr

                                                  // const commonAddresses = (data?.allLocationSame && pv.length > 0) ? [...pv[0].location][0]: null;

                                                  // if(data?.allLocationSame && commonAddresses){
                                                  //   arrayAddr = Array((newValue)).fill().map((_, i) => ({
                                                  //     ...commonAddresses,
                                                  //     id: i
                                                  //   }));

                                                  // }
                                                  // else{
                                                  //   arrayAddr = [...p.location]
                                                  //   console.log("Hifiiiiiiiiiiiiii Aff",arrayAddr);
                                                  //   if(p.location.length<=newValue){
                                                  //     arrayAddr.push(...[...Array((newValue)-p.location.length)].map((a,i)=>{
                                                  //       return {
                                                  //         id: p.location.length + i,
                                                  //         address: '',
                                                  //         city: '',
                                                  //         state: '',
                                                  //         zip: '',
                                                  //       }
                                                  //     }))
                                                  //   }else{
                                                  //     arrayAddr = arrayAddr.slice(0,-(p.location.length-(newValue)));
                                                  //   }
                                                  // }

                                                  return {
                                                    ...p,
                                                    quantity: newValue,
                                                    // location: arrayAddr
                                                  }
                                                } else {
                                                  return p;
                                                }
                                              })]));
                                              console.log("###############SElected services", selectedServices);
                                              setSelectedServices(pv => ([...pv.map(service => {
                                                // if (service.product_id === data.id && service.prQty) {
                                                console.log('data---new----', data);
                                                console.log('service---new----', service);
                                                if (service.productIndex === data.indexId && service.prQty) {
                                                  return {
                                                    ...service,
                                                    quantity: (service.prQty * (newValue) * (service?.locationQuantity || 1)).toFixed(2),
                                                    qtyData: Number(newValue),
                                                  };
                                                } else {
                                                  return {
                                                    ...service,

                                                  }
                                                }
                                              })]));
                                              //new chnage on 2-2-24
                                              //   if (newValue === '.') {
                                              //     event.preventDefault();
                                              //     return;
                                              //   }
                                              // if (newValue === '' || newValue <= 0) {
                                              //   event.preventDefault();
                                              //   return;
                                              // }
                                              //   if (newValue.startsWith('-')) {
                                              //     newValue = newValue.slice(1);
                                              //   }
                                              data['quantity'] = newValue || 1;

                                              setFieldValue(quantityFieldName, newValue);
                                            }}

                                            error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                            helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <TextField
                                            type="number"
                                            onKeyPress={(event) => {
                                              if (event.key === '0' && event.target.value === '') {
                                                event.preventDefault();
                                              }
                                              if (event.key === '.') {
                                                event.preventDefault();
                                              }
                                            }}
                                            fullWidth
                                            disabled={(data?.fromMapServices == true) ? true : false}
                                            label={"Qty"}
                                            // {...getFieldProps(quantityFieldName)}
                                            defaultValue={data.locationQuantity ? data.locationQuantity : 1}
                                            value={data.locationQuantity ? data.locationQuantity : 1}
                                            onChange={(event) => {

                                              let newValue = event.target.value;
                                              if (newValue.startsWith('-')) {
                                                newValue = newValue.slice(1);
                                              }
                                              newValue = newValue.replace(/[^0-9]/g, '');
                                              if (newValue === '' || Number(newValue) <= 0) {
                                                newValue = 1;
                                              }
                                              else {
                                                newValue = Number(newValue);
                                              }

                                              setSelectedProducts(pv => ([...pv.map(p => {
                                                // if (p.id === data.id) {
                                                if (p.indexId === data.indexId) {

                                                  let arrayAddr

                                                  const commonAddresses = (data?.allLocationSame && pv.length > 0) ? p.location[0] : null;
                                                  console.log("Hifiiiiiiiiiiiiii Aff", commonAddresses);
                                                  if (data?.allLocationSame && commonAddresses) {
                                                    arrayAddr = Array(Number(newValue)).fill().map((_, i) => ({
                                                      ...commonAddresses,
                                                      id: i,
                                                      req_loc_id: p.location[i]?.req_loc_id ?? null,
                                                    }));

                                                  }
                                                  else {
                                                    arrayAddr = [...p.location]
                                                    console.log("Hifiiiiiiiiiiiiii Aff", arrayAddr);
                                                    if (p.location.length <= Number(newValue)) {
                                                      arrayAddr.push(...[...Array(Number(newValue) - p.location.length)].map((a, i) => {
                                                        return {
                                                          id: p.location.length + i,
                                                          address: '',
                                                          city: '',
                                                          state: '',
                                                          zip: '',
                                                        }
                                                      }))
                                                    }
                                                    else if (p.location.length > Number(newValue)) {
                                                      arrayAddr = arrayAddr.slice(0, Number(newValue));
                                                    }
                                                    else {
                                                      arrayAddr = arrayAddr.slice(0, -(p.location.length - Number(newValue)));
                                                    }
                                                  }

                                                  return {
                                                    ...p,
                                                    // quantity:newValue,
                                                    locationQuantity: newValue,
                                                    location: arrayAddr
                                                  }
                                                } else {
                                                  return p;
                                                }
                                              })]));
                                              setSelectedServices(pv => ([...pv.map(service => {
                                                console.log("############@@@@@@@@@@service", service);
                                                console.log("############@@@@@@@@@@data", data);
                                                if (service.productIndex === data.indexId && service.prQty) {
                                                  console.log("############service.newValue", newValue);
                                                  return {
                                                    ...service,
                                                    quantity: (newValue * (service.qtyData || 1) * service.prQty).toFixed(2),
                                                    locationQuantity: newValue,
                                                  };
                                                } else {
                                                  return {
                                                    ...service,

                                                  }
                                                }
                                              })]));
                                            }}

                                            error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                            helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <IconButton
                                            onClick={() => {
                                              setLocationStates({ ...locationStates, [data.indexId]: true });
                                            }}
                                          >
                                            <LocationOn sx={{
                                              color: data.location.some(obj =>
                                                [obj.address, obj.city, obj.state, obj.zip].some(value => (value === '' || value === null))
                                              ) ? '' : theme.palette.primary.main
                                            }} />
                                          </IconButton>
                                        </TableCell>
                                        {/* <TableCell>{data.product_id||data.id}</TableCell> */}

                                        <TableCell>
                                          <Autocomplete
                                            sx={{ width: 70 }}
                                            id="combo-box-demo"
                                            disableClearable
                                            disabled={values.project_category?.name == 'Time & Material' ? true : false}
                                            options={marketingAdjustmentType || []}
                                            value={marketingAdjustmentType?.find((type) => type.id === data.markAdjType)} // Use value to set the correct selected option
                                            onChange={(event, newValue) => {
                                              console.log(newValue)
                                              setSelectedProducts((prevProducts) => {
                                                return prevProducts.map((product) => {
                                                  if (product.indexId === data.indexId) {
                                                    return {
                                                      ...product,
                                                      markAdjType: newValue?.id, // Set adjustment type or default to the first option
                                                      // adjustment: 0,
                                                    };
                                                  }
                                                  return product;
                                                });
                                              });
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                required
                                                error={Boolean(touched.marketingAdjType && errors.marketingAdjType)}
                                                helperText={touched.marketingAdjType && errors.marketingAdjType}
                                                label="Select type"
                                              />
                                            )}
                                            getOptionLabel={(option) => (option.name ? option.name : '')}
                                          />
                                        </TableCell>

                                        <TableCell>
                                          {/* <TextField
                                            type="number"
                                            label="Discount"
                                            value={Number(data.discount)}
                                            // {...getFieldProps(discountFieldName)}
                                            defaultValue={data.discount ? data.discount : 0}
                                            onChange={(event) => {
                                              let newValue = event.target.value;
                                              if (newValue.startsWith('-')) {
                                                newValue = newValue.slice(1);
                                              }
                                              if (markupType == 1) {
                                                if (Number(newValue) > maxDiscount_wholesale) {
                                                  newValue = maxDiscount_wholesale.toFixed(2);
                                                }
                                              } else if (markupType == 2) {
                                                if (Number(newValue) > maxDiscount_retail) {
                                                  newValue = maxDiscount_retail.toFixed(2);
                                                }
                                              } else {
                                                newValue = 0;
                                              }
                                              if (newValue === '-0.00') {
                                                newValue = 0;
                                              }
                                              setRefreshData(!refreshData)
                                              data['discount'] = newValue
                                              setFieldValue(discountFieldName, newValue);
                                            }}
                                            error={Boolean(touched[discountFieldName] && errors[discountFieldName])}
                                            helperText={touched[discountFieldName] && errors[discountFieldName]}
                                            inputProps={{ max: markupType == 1 ? maxDiscount_wholesale : markupType == 2 ? maxDiscount_retail : 0, min: 0 }}
                                          /> */}

                                          <TextField
                                            type="number"
                                            label={
                                              data.markAdjType === 2 ? "$" : data.markAdjType === 3 ? "¢" : "%"
                                            }
                                            disabled={values.project_category?.name == 'Time & Material' ? true : false}
                                            value={Number(data.adjustment) || null }
                                            // defaultValue={data?.adjustment ? Number(data?.adjustment) : 0}
                                            onChange={(event) => {
                                              let newValue = event.target.value;
                                              // if (newValue.startsWith('-')) {
                                              //   newValue = newValue.slice(1);
                                              // }
                                              
                                              // if (newValue === '-0.00') {
                                              //   newValue = 0;
                                              // }

                                                    // Allow only two decimal places
                                                    if (newValue.includes('.')) {
                                                      const parts = newValue.split('.');
                                                      if (parts[1].length > 2) {
                                                        newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
                                                      }
                                                    }
                                              setRefreshData(!refreshData)
                                              data['adjustment'] = newValue
                                              setFieldValue(adjustmentFieldName, newValue);
                                            }}
                                            onKeyDown={(event) => {
                                              if (event.key === '-' || event.key === '+') {
                                                event.preventDefault();
                                              }
                                            }}
                                            error={Boolean(touched[adjustmentFieldName] && errors[adjustmentFieldName])}
                                            helperText={touched[adjustmentFieldName] && errors[adjustmentFieldName]}
                                          />

                                        </TableCell>
                                        <TableCell>
                                          {rowTotal.toFixed(2)}
                                        </TableCell>
                                        <TableCell>
                                        {(data?.fromMapServices == true) ? '' : 
                                          <IconButton onClick={() => handleDeleteProduct(data.id || data.product_id, data.indexId)}>
                                            <Delete />
                                          </IconButton>
                                        }
                                        </TableCell>
                                      </TableRow>
                                      <Modal
                                        open={locationStates[data.indexId]}
                                        handleClose={() => {
                                          setLocationStates({ ...locationStates, [data.indexId]: false });
                                        }}
                                      >
                                        {((data?.fromMapServices != true) 
                                        // && index === 0
                                      ) && <FormControlLabel
                                          control={
                                            <Switch
                                              checked={data?.allLocationSame}
                                              onChange={()=>handleAllLocationSameChange(index)}
                                            />
                                          }
                                          label="All Locations Same"
                                        />}
                                        <Grid
                                          container
                                          spacing={3}
                                          sx={{ justifyContent: 'center', width: '100%' }}
                                        >
                                          {data.location.map((addr, i) => (
                                            <Grid item xs={12}>
                                              <Typography variant="h5" sx={{ mb: 2 }}>
                                                Location {data?.fromMapServices == true ? i+1 : i+1}
                                              </Typography>

                                              <Stack
                                                direction={{ xs: 'column', sm: 'row' }}
                                                spacing={{ xs: 3, sm: 2 }}
                                                sx={{ mb: 2 }}
                                              >
                                                <TextField
                                                  fullWidth
                                                  label="Address1"
                                                  size="small"
                                                  value={data.location[i].address || ''}
                                                  disabled={(data?.fromMapServices == true && data.location[i].address) ? true : (i !== 0 && data?.allLocationSame) ? true : false}
                                                  // onChange={(event) => {
                                                  //   let arrayAddr = [...data.location]
                                                  //   arrayAddr[i].address = event.target.value;
                                                  //   setSelectedProducts(pv => ([...pv.map(p => {
                                                  //     // if (p.id === data.id) {
                                                  //     if (p.indexId === data.indexId) {
                                                  //       return {
                                                  //         ...p,
                                                  //         location: arrayAddr
                                                  //       }
                                                  //     } else {
                                                  //       return p;
                                                  //     }
                                                  //   })]))
                                                  // }}
                                                  onChange={(event) => {
                                                    setSelectedProducts((prevProducts) =>
                                                      prevProducts.map((p) => {
                                                        if (p.indexId === data.indexId) {
                                                          let updatedLocations = [...p.location];
                                                          if (p.allLocationSame) {
                                                            updatedLocations = updatedLocations.map(() => ({
                                                              ...updatedLocations[0], 
                                                              address: event.target.value, 
                                                            }));
                                                          } else {
                                                            updatedLocations[i] = {
                                                              ...updatedLocations[i], 
                                                              address: event.target.value,
                                                            };
                                                          }
                                                          return {
                                                            ...p,
                                                            location: updatedLocations,
                                                          };
                                                        }
                                                        return p;
                                                      })
                                                    );
                                                  }}
                                                />
                                              </Stack>

                                              <Stack
                                                direction={{ xs: 'column', sm: 'row' }}
                                                spacing={{ xs: 3, sm: 2 }}
                                                sx={{ mb: 2 }}
                                              >
                                                <TextField
                                                  fullWidth
                                                  label="City"
                                                  size="small"
                                                  value={data.location[i].city || ''}
                                                  disabled={(data?.fromMapServices == true && data.location[i].city) ? true : (i !== 0 && data?.allLocationSame) ? true : false}
                                                  // onChange={(event) => {
                                                  //   let arrayAddr = [...data.location]
                                                  //   arrayAddr[i].city = event.target.value;
                                                  //   setSelectedProducts(pv => ([...pv.map(p => {
                                                  //     // if (p.id === data.id) {
                                                  //     if (p.indexId === data.indexId) {
                                                  //       return {
                                                  //         ...p,
                                                  //         location: arrayAddr
                                                  //       }
                                                  //     } else {
                                                  //       return p;
                                                  //     }
                                                  //   })]))
                                                  // }}
                                                  onChange={(event) => {
                                                    setSelectedProducts((prevProducts) =>
                                                      prevProducts.map((p) => {
                                                        if (p.indexId === data.indexId) {
                                                          let updatedLocations = [...p.location];
                                                          if (p.allLocationSame) {
                                                            updatedLocations = updatedLocations.map(() => ({
                                                              ...updatedLocations[0], 
                                                              city: event.target.value, 
                                                            }));
                                                          } else {
                                                            updatedLocations[i] = {
                                                              ...updatedLocations[i], 
                                                              city: event.target.value,
                                                            };
                                                          }
                                                          return {
                                                            ...p,
                                                            location: updatedLocations,
                                                          };
                                                        }
                                                        return p;
                                                      })
                                                    );
                                                  }}
                                                />
                                                <TextField
                                                  fullWidth
                                                  label="State"
                                                  size="small"
                                                  value={data.location[i].state || ''}
                                                  disabled={(data?.fromMapServices == true && data.location[i].state) ? true : (i !== 0 && data?.allLocationSame) ? true : false}
                                                  // onChange={(event) => {
                                                  //   let arrayAddr = [...data.location]
                                                  //   arrayAddr[i].state = event.target.value;
                                                  //   setSelectedProducts(pv => ([...pv.map(p => {
                                                  //     // if (p.id === data.id) {
                                                  //     if (p.indexId === data.indexId) {
                                                  //       return {
                                                  //         ...p,
                                                  //         location: arrayAddr
                                                  //       }
                                                  //     } else {
                                                  //       return p;
                                                  //     }
                                                  //   })]))
                                                  // }}
                                                  onChange={(event) => {
                                                    setSelectedProducts((prevProducts) =>
                                                      prevProducts.map((p) => {
                                                        if (p.indexId === data.indexId) {
                                                          let updatedLocations = [...p.location];
                                                          if (p.allLocationSame) {
                                                            updatedLocations = updatedLocations.map(() => ({
                                                              ...updatedLocations[0], 
                                                              state: event.target.value, 
                                                            }));
                                                          } else {
                                                            updatedLocations[i] = {
                                                              ...updatedLocations[i], 
                                                              state: event.target.value,
                                                            };
                                                          }
                                                          return {
                                                            ...p,
                                                            location: updatedLocations,
                                                          };
                                                        }
                                                        return p;
                                                      })
                                                    );
                                                  }}
                                                />
                                                <TextField
                                                  fullWidth
                                                  label="Zip / Postal Code"
                                                  size="small"
                                                  value={data.location[i].zip || ''}
                                                  disabled={(data?.fromMapServices == true && data.location[i].zip) ? true : (i !== 0 && data?.allLocationSame) ? true : false}
                                                  // onChange={(event) => {
                                                  //   let arrayAddr = [...data.location]
                                                  //   arrayAddr[i].zip = event.target.value;
                                                  //   setSelectedProducts(pv => ([...pv.map(p => {
                                                  //     // if (p.id === data.id) {
                                                  //     if (p.indexId === data.indexId) {
                                                  //       return {
                                                  //         ...p,
                                                  //         location: arrayAddr
                                                  //       }
                                                  //     } else {
                                                  //       return p;
                                                  //     }
                                                  //   })]))
                                                  // }}
                                                  onChange={(event) => {
                                                    setSelectedProducts((prevProducts) =>
                                                      prevProducts.map((p) => {
                                                        if (p.indexId === data.indexId) {
                                                          let updatedLocations = [...p.location];
                                                          if (p.allLocationSame) {
                                                            updatedLocations = updatedLocations.map(() => ({
                                                              ...updatedLocations[0], 
                                                              zip: event.target.value, 
                                                            }));
                                                          } else {
                                                            updatedLocations[i] = {
                                                              ...updatedLocations[i], 
                                                              zip: event.target.value,
                                                            };
                                                          }
                                                          return {
                                                            ...p,
                                                            location: updatedLocations,
                                                          };
                                                        }
                                                        return p;
                                                      })
                                                    );
                                                  }}
                                                />
                                              </Stack>
                                            </Grid>
                                          ))}
                                        </Grid>

                                        <Grid container justifyContent="center" alignItems="center">
                                          <Grid item xs={12} md={4}>
                                          {/* {(data?.fromMapServices == true) ? '' : (i !== 0 && data?.allLocationSame) ? '' : */}
                                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 1 }}>
                                              <LoadingButton variant="contained" onClick={() => {
                                                setIsSaving(true)
                                                setTimeout(() => {
                                                  setIsSaving(false)
                                                  setLocationStates(false)
                                                }, 100)
                                              }} loading={isSaving}>
                                                {' Save '}
                                              </LoadingButton>
                                            </Box>
                                            {/* } */}
                                          </Grid>
                                        </Grid>
                                      </Modal>
                                      <Modal
                                        open={openProductNote[data.indexId] || false}
                                        handleClose={() => handleCloseNote(data.indexId)}
                                      >
                                        <Grid
                                          container
                                          spacing={3}
                                          sx={{ justifyContent: 'center', width: '100%' }}
                                        >
                                            <Grid item xs={12}>
                                              <Typography variant="h5" sx={{ mb: 2 }}>
                                                Note
                                              </Typography>

                                              <Stack
                                                direction={{ xs: 'column', sm: 'row' }}
                                                spacing={{ xs: 3, sm: 2 }}
                                                sx={{ mb: 2 }}
                                              >
                                                <TextField
                                                  fullWidth
                                                  multiline
                                                  rows={4}
                                                  size="medium"
                                                  value={currentNote}
                                                  onChange={handleNoteChange}
                                                  inputProps={{ maxLength: MAX_CHARACTERS }}
                                                  helperText={`${currentNote.length}/${MAX_CHARACTERS} characters`}
                                                />
                                              </Stack>
                                            </Grid>
                                        </Grid>

                                        <Grid container justifyContent="center" alignItems="center">
                                          <Grid item xs={12} md={4}>
                                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 1 }}>
                                              <LoadingButton
                                                variant="contained"
                                                onClick={() => handleSaveNote(data.indexId)}
                                                loading={isSavingNote}>
                                                Save
                                              </LoadingButton>
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </Modal>
                                    </>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Scrollbar>
                        <Stack sx={{ justifyContent: 'space-between', alignItems: 'center', display:'flex', flexDirection:'row' }}>
                          <Button
                            variant="outlined"
                            startIcon={
                              <Stack>
                                {' '}
                                <Add />{' '}
                              </Stack>
                            }
                            onClick={() => {
                              addNewProductRow()
                              // setMarkupType(1)
                            }}
                          >
                            Add Product Row
                          </Button>
                          <Typography variant="h6">
                            Total Additional Product Cost:{' '}
                            {selectedProductsAdditionalCost}
                          </Typography>
                        </Stack>
                        </>}   
                        </>}

                        <Divider />
                        <Stack direction='row'>
                          <LabelStyleHead>Services</LabelStyleHead>
                          {showServices ? <Remove sx={{ color: theme.palette.primary.main }} onClick={(e) => { setShowServices(false) }} /> : <Add sx={{ color: theme.palette.primary.main }} onClick={(e) => { setShowServices(true) }} />}
                        </Stack>
                        {showServices && <>
                          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                            <Autocomplete
                              sx={{ width: 400 }}
                              multiple
                              id="combo-box-demo"
                              options={
                                filteredServices?.filter(item =>
                                  item.status === 1 &&
                                  !additionalSelectedServices.some(selectedService =>
                                    selectedService?.id === item?.id || selectedService?.labour_cost_service_id === item?.id
                                  )
                                ) || []
                              }
                              value={additionalSelectedServices?.filter(item=>!item.isAdditionalProduct)}
                              // value={values.services}
                              // onChange={(event, newValue) => {
                              //   setFieldValue('services', newValue);
                              //   // setAdditionalSelectedServices(newValue);
                              //   setAdditionalSelectedServices(prevProducts => {
                              //     const updatedProducts = newValue?.map((product, index) => {
                              //       const existingProduct = prevProducts.find(p => (p?.id === product?.id && p?.indexId === product?.indexId));
                              //       console.log('existingProduct-----------------',existingProduct)
                              //       console.log('prevProducts-----------------',prevProducts)
                              //       console.log('product-----------------',product)
                              //       if (existingProduct) {
                              //         let addresses = [...existingProduct?.addresses];
                              //         for (let i = addresses?.length; i < 1; i++) {
                              //           addresses.push({
                              //             id: i,
                              //             address: '',
                              //             city: '',
                              //             state: '',
                              //             zip: '',
                              //           });
                              //         }
                              //         return {
                              //           ...existingProduct,
                              //           addresses
                              //         };
                              //       } else {
                              //         const newAddresses = [{
                              //           id: 0,
                              //           address: '',
                              //           city: '',
                              //           state: '',
                              //           zip: '',
                              //         }];
                            
                              //         // if (prevProducts.length > 0) {
                              //         //   const commonAddress = { ...prevProducts[0].addresses[0] } || {
                              //         //     address: '',
                              //         //     city: '',
                              //         //     state: '',
                              //         //     zip: '',
                              //         //   };
                              //         //   newAddresses[0] = commonAddress;
                              //         // }
                              //         const newIndexCounter = serviceIndexCounter + 1
                              //         setServiceIndexCounter(newIndexCounter);
                              //         return {
                              //           ...product,
                              //           indexId: newIndexCounter,
                              //           addresses: newAddresses
                              //         };
                              //       }
                              //     });
                              //     return updatedProducts;
                              //   });
                              // }}


                              onChange={(event, newValue) => {
                                // Extract community address from Formik's values
                                const { address: projAddress = '', city: projCity = '', state: projState = '', zip: projZip = '' } = values?.community || {};
                              
                                const communityAddress = { 
                                  address: projAddress, 
                                  city: projCity, 
                                  state: projState, 
                                  zip: projZip 
                                };
                              
                                // Update Formik's field value
                                setFieldValue('services', newValue);
                              
                                // Update additional selected services
                                setAdditionalSelectedServices((prevProducts) => {
                                  const additionals = prevProducts?.filter(item => item.isAdditionalProduct === true || item.isAdditionalProduct === 1)
                                  const updatedProducts = newValue?.map((product) => {
                                    // Check if the product already exists in the selected services
                                    const existingProduct = prevProducts.find(
                                      (p) => p?.id === product?.id && p?.indexId === product?.indexId
                                    );
                              
                                    if (existingProduct) {
                                      // -----------------------
                                      // Update existing product's addresses
                                      // -----------------------
                                      let addresses = [...existingProduct?.addresses];
                                      for (let i = addresses.length; i < 1; i++) {
                                        addresses.push({
                                          id: i,
                                          address: '',
                                          city: '',
                                          state: '',
                                          zip: '',
                                        });
                                      }
                                      return {
                                        ...existingProduct,
                                        addresses,
                                      };
                                    } else {
                                      // -----------------------
                                      // Add a new product
                                      // -----------------------
                                      const assignedService = assignServiceAddresses(
                                        prevProducts,
                                        product,
                                        allServiceLocationSame,
                                        communityAddress
                                      );
                              
                                      // Increment the service index counter for a unique indexId
                                      const newIndexCounter = serviceIndexCounter + 1;
                                      setServiceIndexCounter(newIndexCounter);
                              
                                      return {
                                        ...assignedService,
                                        indexId: newIndexCounter,
                                      };
                                    }
                                  });
                                  const finalServices = [...updatedProducts, ...additionals];
                                  console.log('finalServices--additionals', additionals)
                                  console.log('finalServices--updatedProducts', updatedProducts)
                                  console.log('finalServices--finalServices', finalServices)
                                  return finalServices;
                                });
                              }}
                              //previos one
                              // onChange={(event, newValue) => {
                              //   setFieldValue('services', newValue);
                                
                              //   setAdditionalSelectedServices(prevProducts => {
                              //     const updatedProducts = newValue?.map((product, index) => {
                              //       const existingProduct = prevProducts.find(
                              //         p => p?.id === product?.id && p?.indexId === product?.indexId
                              //       );
                              
                              //       if (existingProduct) {
                              //         // Update existing product's addresses
                              //         let addresses = [...existingProduct?.addresses];
                              //         for (let i = addresses?.length; i < 1; i++) {
                              //           addresses.push({
                              //             id: i,
                              //             address: '',
                              //             city: '',
                              //             state: '',
                              //             zip: '',
                              //           });
                              //         }
                              //         return {
                              //           ...existingProduct,
                              //           addresses,
                              //         };
                              //       } else {
                              //         // Add a new product
                              //         let newAddresses = [
                              //           {
                              //             id: 0,
                              //             address: '',
                              //             city: '',
                              //             state: '',
                              //             zip: '',
                              //           },
                              //         ];
                              
                              //         // If allServiceLocationSame is enabled, use the common address
                              //         if (allServiceLocationSame && prevProducts.length > 0) {
                              //           const commonAddress = { ...prevProducts[0].addresses[0] };
                              //           newAddresses[0] = commonAddress;
                              //         }
                              
                              //         const newIndexCounter = serviceIndexCounter + 1;
                              //         setServiceIndexCounter(newIndexCounter);
                              
                              //         return {
                              //           ...product,
                              //           indexId: newIndexCounter,
                              //           addresses: newAddresses,
                              //         };
                              //       }
                              //     });
                              
                              //     return updatedProducts;
                              //   });
                              // }}
                              
                              renderInput={(params) => (
                                <TextField {...params} required
                                  error={Boolean(touched.services && errors.services)}
                                  helperText={touched.services && errors.services}
                                  label={
                                    ((values.project_category?.name == 'Time & Material') || (values.project_category?.name == 'Service only')) 
                                    ? "Select services" : "Select additional services"
                                  } />
                              )}
                              getOptionLabel={(option) => option.name ? option.name : ''}
                            />
                          </Stack>
                          <LabelStyle>Selected services</LabelStyle>
                          <Scrollbar>
                            <TableContainer sx={{ minWidth: 500 }}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    {VALUE_SERVICES?.map((val) => (
                                      // ((values.project_category?.name == 'Time & Material') && val.id === 6) ? <TableCell></TableCell> : 
                                      ((((values.project_category?.name == 'Service only') || 
                                      (values.project_category?.name == 'Time & Material')) && val.id === 0)
                                      // || ((values.project_category?.name !== 'Service only') && val.id === 6)
                                      ) ? null : (
                                        <TableCell>{val.value}</TableCell>
                                      ))
                                    )}
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {selectedServices?.map((data, index) => {
                                    const quantityFieldName = `service_quantity_${index}`;
                                    if(values.project_category?.name == 'Time & Material'){
                                      data['total_cost'] = 0
                                      data['unit_cost'] = 0
                                      data['cost'] = 0
                                      data['quantity'] = 0
                                    }
                                    const dataCost = data.unit_cost || data.total_cost
                                    const total = dataCost * data.quantity
                                    totalAmount += total
                                    data['totalAmount'] = totalAmount?.toFixed(2)
                                    let markup = 0
                                    if(total > 0){
                                      markup = dataCost / total
                                    }
                                    data['markup'] = markup?.toFixed(2)
                                    totalServiceMarkup += markup
                                    data['total'] = total?.toFixed(2)
                                    data['totalServiceMarkup'] = totalServiceMarkup?.toFixed(2)
                                    let originalMarkup = data.originalMarkup || 0
                                    originaltotalServiceMarkup += originalMarkup
                                    // values['totalServiceMarkup'] = totalServiceMarkup?.toFixed(2)
                                    console.log("############WWWWW", originalMarkup);
                                    let qtyData = data.qtyData || 1
                                    console.log("############qtyData", qtyData);
                                    const previousQty = data.prQty*qtyData * (data.locationQuantity||1);
                                    const newQty = (data.prQty * qtyData * data.locationQuantity || 1)?.toFixed(2)
                                    let quantityDifference = 0
                                    if(values.project_category?.name == 'Time & Material'){
                                      quantityDifference = 0
                                    }else{
                                      quantityDifference = Number(data.quantity) - newQty;
                                    }
                                    data['quantityDifference'] = quantityDifference
                                    data['jobcost_unit_cost'] = data.cost
                                    data['jobcost_quantity'] = quantityDifference
                                    data['jobcost_total_cost'] = (data.cost*Number(data.quantity))?.toFixed(2)
                                    return (
                                      <>
                                        <TableRow key={index}>
                                          <TableCell>#{data?.productIndex}</TableCell>
                                          <TableCell>{data.name}</TableCell>
                                          <TableCell>{originalMarkup?.toFixed(2)}</TableCell>
                                          <TableCell>{data.cost}</TableCell>
                                          <TableCell>{dataCost}</TableCell>
                                          <TableCell>
                                            <TextField
                                              type="number"
                                              // sx={{ width: 400 }}                                                                                
                                              label={'Hours'}
                                              // {...getFieldProps(quantityFieldName)}
                                              disabled={(data?.fromMapServices == true) ? true : values.project_category?.name == 'Time & Material' ? true : false}
                                              value={values.project_category?.name == 'Time & Material' ? 0 : Number(data.quantity)}
                                              defaultValue={values.project_category?.name == 'Time & Material' ? 0 : (data.quantity ? Number(data.quantity) : 1)}
                                              onChange={(event) => {
                                                let newValue = event.target.value;
                                                const newData = { ...data, quantity: newValue || 0 };
                                                if (newValue.startsWith('-')) {
                                                  newValue = newValue.slice(1);
                                                }
                                                if (newValue < previousQty) {
                                                  setShowWarning(prev => {
                                                    const newWarnings = [...prev];
                                                    newWarnings[index] = true;
                                                    return newWarnings;
                                                  });
                                                  console.log('data?.prQty---', data?.prQty);
                                                  data['quantity'] = previousQty;
                                                  setFieldValue(quantityFieldName, previousQty);
                                                } else {
                                                  setShowWarning(prev => {
                                                    const newWarnings = [...prev];
                                                    newWarnings[index] = false;
                                                    return newWarnings;
                                                  });
                                                  data['quantity'] = newValue || 1;
                                                  setFieldValue(quantityFieldName, newValue);
                                                }

                                                const updatedServices = [...selectedServices];
                                                updatedServices[index] = newData;
                                                updatedServices[index] = newData;

                                                updatedServices[index] = newData;

                                                setSelectedServices(updatedServices);
                                              }}
                                              inputProps={{ min: previousQty}}
                                              error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                              helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                            />
                                            {showWarning[index] && (
                                              <Typography color="error" variant='body2'>
                                                Cannot reduce the added hours in the product formula. If you need to reduce, change it in the product formula.
                                              </Typography>
                                            )}
                                          </TableCell>
                                          {/* {values.project_category?.name == 'Time & Material' ?  */}
                                          <TableCell></TableCell>
                                           {/* : null } */}
                                          <TableCell>{(data.cost*quantityDifference?.toFixed(2))?.toFixed(2)}</TableCell>
                                          <TableCell>{(data.unit_cost * quantityDifference?.toFixed(2))?.toFixed(2)}</TableCell>
                                          <TableCell>{total?.toFixed(2)}</TableCell>
                                          {/* <TableCell align='right'>
                                        <IconButton onClick={() => handleDeleteService(data.id)}>
                                          <Delete />
                                        </IconButton>
                                      </TableCell> */}
                                        </TableRow>
                                      </>
                                    );
                                  })}
                                  
                                </TableBody>
                                  {console.log(additionalSelectedServices,'------------------------------additionalSelectedServices')}
                                <TableBody>
                                  {additionalSelectedServices?.map((data, index) => {
                                    console.log("############additional services", data);
                                    if(values.project_category?.name == 'Time & Material'){
                                      data['total_cost'] = 0
                                      data['unit_cost'] = 0
                                      data['cost'] = 0
                                      data['quantity'] = 0
                                    }
                                    const quantityFieldName = `service_quantity_${index}`;
                                    const dataQuantity = data?.quantity ? data?.quantity : 1;
                                    const dataCost = data.unit_cost || data.total_cost
                                    const total = dataCost * dataQuantity
                                    const markup = dataCost / Number(total)
                                    data['markup'] = markup ? markup?.toFixed(2) : 0.00
                                    data['quantity'] = dataQuantity
                                    totalServiceMarkup += markup
                                    data['total'] = total ? total?.toFixed(2) : 0.00
                                    data['totalServiceMarkup'] = totalServiceMarkup?.toFixed(2)
                                    let originalMarkup = data.originalMarkup || 0
                                    originaltotalServiceMarkup += originalMarkup

                                    const quantityDifference = Number(data.quantity)
                                    data['quantityDifference'] = quantityDifference
                                    data['jobcost_unit_cost'] = data.cost
                                    data['jobcost_quantity'] = quantityDifference
                                    data['jobcost_total_cost'] = (data.cost*Number(data?.quantity) || 1)?.toFixed(2)
                                    // values['totalServiceMarkup'] = totalServiceMarkup?.toFixed(2)
                                    console.log('total---', total);
                                    console.log('data---', data);
                                    return (
                                      <>
                                        <TableRow key={index}>
                                        {(values.project_category?.name != 'Service only' && values.project_category?.name != 'Time & Material') ? (
                                          <TableCell></TableCell>
                                        ) : null}
                                          <TableCell>{
                                            data?.isAdditionalProduct ?
                                            <TextField
                                              required
                                              fullWidth
                                              type="text"
                                              label={'Service Name'}
                                              sx={{ width: 85 }}
                                              value={data.name}
                                              onChange={(event) => {
                                                let newValue = event.target.value

                                                // Update the selectedProducts state
                                                setAdditionalSelectedServices(
                                                  (prevProducts) =>
                                                    prevProducts.map((service) => {
                                                        if ( service.newIndexId === data.newIndexId ) {
                                                          return {
                                                            ...service,
                                                            name: newValue,
                                                          }
                                                        } else {
                                                          return service
                                                        }
                                                      },
                                                    ),
                                                )
                                                data['name'] = newValue || ''
                                                if (isSubmitted)
                                                  setIsSubmitted(false)
                                              }}
                                              error={
                                                isSubmitted && data?.name?.trim() === ''
                                              }
                                              helperText={
                                                isSubmitted && data?.name?.trim() === '' ? 'Service Name is required' : ''
                                              }
                                            />
                                            : 
                                            data.name}</TableCell>
                                          <TableCell>{originalMarkup ? originalMarkup?.toFixed(2) : 0.00}</TableCell>
                                          <TableCell>{data.cost}</TableCell>
                                          <TableCell>{
                                            data?.isAdditionalProduct ?
                                            <TextField
                                              required
                                              fullWidth
                                              type="number"
                                              label={'Hourly Cost'}
                                              disabled={values.project_category?.name == 'Time & Material' ? true : false}
                                              sx={{ width: 85 }}
                                              value={dataCost}
                                              // onWheel={(e) => e.target.blur()}
                                              onKeyPress={(event) => {
                                                // if (event.key === '0' && event.target.value === '') {
                                                //   event.preventDefault();
                                                // }
                                                if (event.key === '-') {
                                                  event.preventDefault();
                                                }
                                                if (event.key === 'e') {
                                                  event.preventDefault();
                                                }
                                              }}
                                              inputProps={{ min: 0 }}
                                              defaultValue={0}
                                              onChange={(event) => {
                                                let newValue = event.target.value
                                                if (newValue !== '') {
                                                  setAdditionalSelectedServices((pv) => [
                                                      ...pv.map((p) => {
                                                        if ( p.newIndexId === data.newIndexId ) {
                                                          return {
                                                            ...p,
                                                            total_cost: newValue,
                                                            unit_cost: newValue,
                                                          }
                                                        } else {
                                                          return p
                                                        }
                                                      }),
                                                    ],
                                                  )
                                                  data['total_cost'] =  newValue || 0
                                                  data['unit_cost'] =  newValue || 0
                                                }
                                              }}
                                              // error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                              // helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                            /> :
                                            dataCost}</TableCell>
                                          <TableCell>
                                            <TextField
                                              type="number"
                                              // sx={{ width: 400 }}                                                                                
                                              label={'Hours'}
                                              {...getFieldProps(quantityFieldName)}
                                              disabled={(values.project_category?.name == 'Time & Material') ? true : false }
                                              value={(values.project_category?.name == 'Time & Material') ? 0 : Number(data?.quantity) || 1}
                                              defaultValue={(values.project_category?.name == 'Time & Material') ? 0 : 1}
                                              onChange={(event) => {
                                                let newValue = event.target.value;
                                                if (newValue.startsWith('-')) {
                                                  newValue = newValue.slice(1);
                                                }
                                                // data['quantity'] = newValue || 1;
                                                const newData = { ...data, quantity: newValue || 1 };
                                                const updatedServices = [...additionalSelectedServices];
                                                updatedServices[index] = newData;
                                                setAdditionalSelectedServices(updatedServices);
                                                setFieldValue(quantityFieldName, newValue);
                                              }}

                                              error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                              helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                            />
                                          </TableCell>   
                                          {/* {values.project_category?.name == 'Service only'  || (values?.project_category?.name == 'Time & Material') ? ( */}
                                          <TableCell>
                                            <IconButton
                                              onClick={() => {
                                                const indexKey = data.indexId ?? data.newIndexId;
                                                setServiceOnlyLocationStates({ ...serviceOnlyLocationStates, [indexKey]: true });
                                              }}
                                            >
                                              <LocationOn sx={{
                                                color: data?.addresses?.some(obj =>
                                                  [obj.address, obj.city, obj.state, obj.zip].some(value => (value === '' || value === null))
                                                ) ? '' : theme.palette.primary.main
                                              }} />
                                            </IconButton>
                                          </TableCell>
                                          {/* ) : null}                                   */}
                                          <TableCell>{data?.isAdditionalProduct ? 'N/A' : (data.cost*quantityDifference)?.toFixed(2)}</TableCell>
                                          <TableCell>{total ? total?.toFixed(2) : 0.00}</TableCell>
                                          <TableCell>{total ? total?.toFixed(2) : 0.00}</TableCell>
                                          <TableCell align='right'>
                                            <IconButton onClick={() => handleDeleteService(data.id, data.indexId, data.newIndexId)}>
                                              <Delete />
                                            </IconButton>
                                          </TableCell>
                                        </TableRow>
                                        <Modal
                                            open={serviceOnlyLocationStates[data.indexId]}
                                            handleClose={() => {
                                              const indexKey = data.indexId ?? data.newIndexId;
                                              setServiceOnlyLocationStates({ ...serviceOnlyLocationStates, [indexKey]: false });
                                            }}
                                          >
                                               {index === 0 && <FormControlLabel
                                          control={
                                            <Switch
                                              checked={allServiceLocationSame}
                                              onChange={handleAllServiceLocationSameChange}
                                            />
                                          }
                                          label="All Locations Same"
                                        />}
                                            <Grid
                                              container
                                              spacing={3}
                                              sx={{ justifyContent: 'center', width: '100%' }}
                                            >

                                              {data?.addresses?.map((addr, i) => (
                                                <Grid item xs={12}>
                                                  {/* Side Heading */}
                                                  <Typography variant="h5" sx={{ mb: 2 }}>
                                                    Location
                                                  </Typography>

                                                  {/* Address Fields */}

                                                  <Stack
                                                    direction={{ xs: 'column', sm: 'row' }}
                                                    spacing={{ xs: 3, sm: 2 }}
                                                    sx={{ mb: 2 }}
                                                  >
                                                    <TextField
                                                      fullWidth
                                                      label="Address"
                                                      size="small"
                                                      value={data.addresses[i].address || ''}
                                                      disabled={index !== 0 && allServiceLocationSame ? true : false}
                                                      onChange={(event) => {
                                                        let arrayAddr = [...data.addresses]
                                                        arrayAddr[i].address = event.target.value;
                                                        setAdditionalSelectedServices(pv => ([...pv.map(p => {
                                                          // if (p.id === data.id) {
                                                          if (p.indexId === data.indexId) {
                                                            return {
                                                              ...p,
                                                              addresses: arrayAddr
                                                            }
                                                          } else {
                                                            return p;
                                                          }
                                                        })]))
                                                      }}
                                                    />
                                                  </Stack>

                                                  {/* City, State, Zip Fields */}
                                                  <Stack
                                                    direction={{ xs: 'column', sm: 'row' }}
                                                    spacing={{ xs: 3, sm: 2 }}
                                                    sx={{ mb: 2 }}
                                                  >
                                                    <TextField
                                                      fullWidth
                                                      label="City"
                                                      size="small"
                                                      value={data.addresses[i].city || ''}
                                                      disabled={index !== 0 && allServiceLocationSame ? true : false}
                                                      onChange={(event) => {
                                                        let arrayAddr = [...data.addresses]
                                                        arrayAddr[i].city = event.target.value;
                                                        setAdditionalSelectedServices(pv => ([...pv.map(p => {
                                                          // if (p.id === data.id) {
                                                          if (p.indexId === data.indexId) {
                                                            return {
                                                              ...p,
                                                              addresses: arrayAddr
                                                            }
                                                          } else {
                                                            return p;
                                                          }
                                                        })]))
                                                      }}
                                                    />
                                                    <TextField
                                                      fullWidth
                                                      label="State"
                                                      size="small"
                                                      value={data.addresses[i].state || ''}
                                                      disabled={index !== 0 && allServiceLocationSame ? true : false}
                                                      onChange={(event) => {
                                                        let arrayAddr = [...data.addresses]
                                                        arrayAddr[i].state = event.target.value;
                                                        setAdditionalSelectedServices(pv => ([...pv.map(p => {
                                                          // if (p.id === data.id) {
                                                          if (p.indexId === data.indexId) {
                                                            return {
                                                              ...p,
                                                              addresses: arrayAddr
                                                            }
                                                          } else {
                                                            return p;
                                                          }
                                                        })]))
                                                      }}
                                                    />
                                                    <TextField
                                                      fullWidth
                                                      label="Zip / Postal Code"
                                                      size="small"
                                                      value={data.addresses[i].zip || ''}
                                                      disabled={index !== 0 && allServiceLocationSame ? true : false}
                                                      onChange={(event) => {
                                                        let arrayAddr = [...data.addresses]
                                                        arrayAddr[i].zip = event.target.value;
                                                        setAdditionalSelectedServices(pv => ([...pv.map(p => {
                                                          if (p.indexId === data.indexId) {
                                                            // if (p.id === data.id) {
                                                            return {
                                                              ...p,
                                                              addresses: arrayAddr
                                                            }
                                                          } else {
                                                            return p;
                                                          }
                                                        })]))
                                                      }}
                                                    />
                                                  </Stack>
                                                </Grid>
                                              ))}
                                            </Grid>

                                            {index !== 0 && allServiceLocationSame ? '' :
                                            <Grid container justifyContent="center" alignItems="center">
                                              <Grid item xs={12} md={4}>
                                                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 1 }}>
                                                  <LoadingButton variant="contained" onClick={() => {
                                                    setIsSaving(true)
                                                    setTimeout(() => {
                                                      setIsSaving(false)
                                                      setServiceOnlyLocationStates(false)
                                                    }, 100)
                                                  }} loading={isSaving}>
                                                    {' Save '}
                                                  </LoadingButton>
                                                  {/* <Button variant="outlined" type="submit" onClick={() => { setLocationStates(false) }}>
                                                  Cancel
                                                </Button> */}
                                                </Box>
                                              </Grid>
                                            </Grid>}
                                          </Modal>
                                      </>
                                    );
                                  })}
                                  {/* <TableRow>
                                    <TableCell colSpan={8}>
                                      <Typography variant="h6" align="right">
                                        Total Additional Cost: {totalProductLabourCost.toFixed(2)}
                                      </Typography>
                                    </TableCell>
                                  </TableRow> */}
                                </TableBody>

                              </Table>
                            </TableContainer>
                          </Scrollbar>
                          <Stack sx={{ justifyContent: 'space-between', alignItems: 'center', display:'flex', flexDirection:'row' }}>
                            <Button
                              variant="outlined"
                              startIcon={
                                <Stack>
                                  {' '}
                                  <Add />{' '}
                                </Stack>
                              }
                              onClick={() => {
                                addNewServiceRow()
                                // setMarkupType(1)
                              }}
                            >
                              Add Service Row
                            </Button>
                            <Typography variant="h6">
                              Total Additional Service Cost:{' '}
                              {totalProductLabourCost?.toFixed(2)}
                            </Typography>
                          </Stack>
                          {/* {(values.project_category?.name == 'Time & Material') ? '' :
                          <> */}
                          {(values?.project_type?.name == 'TS' && values?.project_category?.id == 14) ? '' :
                          <>
                          <LabelStyleHead>Proposals</LabelStyleHead>

                          <Autocomplete
                            options={rfps}
                            getOptionLabel={(option) => option.name}
                            value={values.rfp}
                            onChange={(event, value) => {handleRfpSelect(event, value)
                              setFieldValue('rfp',value)
                            }}
                            renderInput={(params) => <TextField {...params} label="Select Proposal" />}
                          />

                          {/* Modal for RFP Selection */}
                          <Modal open={openModal} handleClose={closeModal}>
                            <Box sx={{ position: 'relative', p: 3, bgcolor: 'background.paper', boxShadow: 24 }}>

                              <Tabs value={tabIndex}
                                variant="fullWidth"
                                sx={{
                                  marginBottom: 4

                                }}
                                onChange={handleTabChange}>
                                <Tab label="Services" sx={{ width: '50%', minWidth: 0 }} />
                                {((values?.project_category?.name != 'Service only') && (values?.project_category?.name != 'Time & Material')) && <Tab label="Products" sx={{ width: '50%', minWidth: 0 }} />}
                              </Tabs>
                              
                              {tabIndex === 0 && (
                                  <TableContainer sx={{ minWidth: 500 }}>
                                  <Table>
                                  <TableHead>
                                    <TableCell align='center'>Name</TableCell>
                                    <TableCell align='center'>Unit Cost</TableCell>
                                    <TableCell align='center'>Hours</TableCell>
                                    <TableCell align='center'>Total Cost</TableCell>
                                    <TableCell align='center'></TableCell>
                                  </TableHead>
                                  {rfpServices.map((service) => {
                                    if(values?.project_category?.name == 'Time & Material'){
                                      service.sellingPrice = 0
                                      service.quantity = 0
                                      service.total_cost = 0
                                    }
                                    return(
                                    // <Box key={service.id} sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                      <TableRow key={service.id}>
                                        <TableCell align='center'>{service.name}</TableCell>
                                        <TableCell align='center'>${service.sellingPrice}</TableCell>
                                        <TableCell align='center'>{service.quantity}</TableCell>
                                        <TableCell align='center'>${service.total_cost}</TableCell>
                                        <TableCell align='center'><Button onClick={() => addToCart(service)}>Add</Button></TableCell>
                                      </TableRow>
                                    // </Box>
                                  )})}
                                  </Table>
                                  </TableContainer>
                              )}
                              {tabIndex === 1 && (
                                <TableContainer sx={{ minWidth: 500 }}>
                                  <Table>
                                  <TableHead>
                                    <TableCell align='center'>Name</TableCell>
                                    <TableCell align='center'>Unit Cost</TableCell>
                                    <TableCell align='center'>Unit</TableCell>
                                    <TableCell align='center'>Qty</TableCell>
                                    <TableCell align='center'>Total Cost</TableCell>
                                    <TableCell align='center'></TableCell>
                                  </TableHead>
                                  {rfpProducts.map((product) => {
                                    let unitName = UNITS?.filter((item) => item?.id == product?.unit)
                                    unitName = unitName[0]?.name
                                    let shortUnitName = ''
                                    if (unitName == 'Square feet') {
                                      shortUnitName = 'Sq ft'
                                    } else if (unitName == 'Lineal feet') {
                                      shortUnitName = 'Lin ft'
                                    } else if (unitName == 'Square Inches') {
                                      shortUnitName = 'Sq Inches'
                                    } else if (unitName == 'Unit') {
                                      shortUnitName = 'Unit'
                                    }
                                    if(values?.project_category?.name == 'Time & Material'){
                                      product.product_price = 0
                                      // product.quantity = 0
                                    }
                                    return(
                                    // <Box key={product.id} sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                      <TableRow key={product.id}>
                                        <TableCell align='center'>{product.product_name}</TableCell>
                                        <TableCell align='center'>${product.product_price}</TableCell>
                                        <TableCell align='center'>{product.quantity} {shortUnitName}</TableCell>
                                        <TableCell align='center'>{product.location_quantity}</TableCell>
                                        <TableCell align='center'>${(product.location_quantity*product.quantity * ((product.product_price).toFixed(2) || 0)).toFixed(2)}</TableCell>
                                        <TableCell align='center'><Button onClick={() => addToCart(product)}>Add</Button></TableCell>
                                      </TableRow>
                                    // </Box>
                                  )})}
                                  </Table>
                                </TableContainer>
                              )}
                            </Box>
                          </Modal>
                          <Scrollbar>
                            <TableContainer sx={{ minWidth: 500 }}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    {VALUE_RFP_Product?.map((val) => (

                                      <TableCell key={val.id}>{val.value}</TableCell>

                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {cart?.map((data, index) => {
                                    let unitName = UNITS?.filter((item) => item?.id == data?.unit)
                                    unitName = unitName[0]?.name
                                    let shortUnitName = ''
                                    if (unitName == 'Square feet') {
                                      shortUnitName = 'Sq ft'
                                    } else if (unitName == 'Lineal feet') {
                                      shortUnitName = 'Lin ft'
                                    } else if (unitName == 'Square Inches') {
                                      shortUnitName = 'Sq Inches'
                                    } else if (unitName == 'Unit') {
                                      shortUnitName = 'Unit'
                                    }
                                    if(values.project_category?.name == 'Time & Material'){
                                      data.product_price = 0
                                      data.product_retail_price = 0
                                      data.sellingPrice = 0
                                    }
                                    let markup = markupType ? markupType === 1 ? data.WholesaleMarkup || data.product_markup : data.RetailMarkup || data.product_markup : 0
                                    data.product_markup = markup
                                    return (
                                      <>
                                        <TableRow key={data.id}>
                                          <TableCell>{data.name || data.product_name}</TableCell>
                                          {/* <TableCell>{invoiceAmount}</TableCell> */}
                                          <TableCell>
                                            {markup?.toFixed(2) || 0.00}
                                          </TableCell>
                                          <TableCell>
                                            {data.product_price?.toFixed(2) || 0}
                                          </TableCell>
                                          <TableCell>
                                            {(data.product_price + (data.product_price * markup / 100)).toFixed(2) || 0.00}
                                          </TableCell>
                                          <TableCell>
                                            {data.quantity} {shortUnitName}
                                          </TableCell>
                                          <TableCell>
                                            {data.location_quantity}
                                          </TableCell>
                                          <TableCell>
                                            {(data.product_price * data.quantity * data.location_quantity)?.toFixed(2)||0}
                                          </TableCell>
                                          <TableCell>
                                            {(data.location_quantity * data.quantity * ((data.product_price + (data.product_price * markup / 100)) || 0)).toFixed(2)}
                                          </TableCell>                                     
                                          <TableCell>
                                            <IconButton
                                              onClick={() => {
                                                setRfpLocationStates({ ...rfpLocationStates, [data.product_id]: true });
                                              }}
                                            >
                                              <LocationOn sx={{
                                                color: data && data.location && data.location.some(obj =>
                                                  [obj.address, obj.city, obj.state, obj.zip].some(value => (value === '' || value === null))
                                                ) ? '' : theme.palette.primary.main
                                              }} />
                                            </IconButton>
                                          </TableCell>
                                          <TableCell >
                                            <IconButton onClick={() => handleDeleteRFPProduct(data.product_id)}>
                                              <Delete />
                                            </IconButton>
                                          </TableCell>
                                        </TableRow>
                                        <Modal
                                          open={rfpLocationStates[data.product_id]}
                                          handleClose={() => {
                                            setRfpLocationStates({ ...rfpLocationStates, [data.product_id]: false });
                                          }}
                                        >
                                          {/* {index === 0 &&  */}
                                          <FormControlLabel
                                            control={
                                              <Switch
                                                checked={data?.allRfpProductsLocationSame}
                                                onChange={()=>handleAllRfpProductsLocationSameChange(index) }
                                              />
                                            }
                                            label="All Locations Same"
                                          />
                                          {/* } */}

                                          <Grid
                                            container
                                            spacing={3}
                                            sx={{ justifyContent: 'center', width: '100%' }}
                                          >

                                            {data?.location?.map((addr, i) => (
                                              <Grid item xs={12}>
                                                {/* Side Heading */}
                                                <Typography variant="h5" sx={{ mb: 2 }}>
                                                  Location {i+1}
                                                </Typography>

                                                {/* Address Fields */}

                                                <Stack
                                                  direction={{ xs: 'column', sm: 'row' }}
                                                  spacing={{ xs: 3, sm: 2 }}
                                                  sx={{ mb: 2 }}
                                                >
                                                  <TextField
                                                    fullWidth
                                                    label="Address"
                                                    size="small"
                                                    value={data.location[i].address || ''}

                                                    // onChange={(event) => {
                                                    //   let arrayAddr = [...data.location]
                                                    //   arrayAddr[i].address = event.target.value;
                                                    //   setCart(pv => ([...pv.map(p => {


                                                    //     if (p.product_id === data.product_id) {
                                                    //       return {
                                                    //         ...p,
                                                    //         addresses: arrayAddr
                                                    //       }
                                                    //     }
                                                    //     else {
                                                    //       return {
                                                    //         ...p
                                                    //       }
                                                    //     }

                                                    //   })]))
                                                    // }}
                                                    disabled={
                                                      i !== 0 &&
                                                      data?.allRfpProductsLocationSame
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(event) => {
                                                      setCart((prevProducts) =>
                                                        prevProducts.map((p) => {
                                                          if (p.product_id === data.product_id) {
                                                            let updatedAddresses = [...p.location];
                                                            if (p.allRfpProductsLocationSame) {
                                                              updatedAddresses = updatedAddresses.map(() => ({
                                                                ...updatedAddresses[0],
                                                                address: event.target.value,
                                                              }));
                                                            } else {
                                                              updatedAddresses[i] = {
                                                                ...updatedAddresses[i],
                                                                address: event.target.value,
                                                              };
                                                            }
                                                            return {
                                                              ...p,
                                                              location: updatedAddresses,
                                                            };
                                                          }
                                                          return p;
                                                        })
                                                      );
                                                    }}
                                                  />
                                                </Stack>

                                                {/* City, State, Zip Fields */}
                                                <Stack
                                                  direction={{ xs: 'column', sm: 'row' }}
                                                  spacing={{ xs: 3, sm: 2 }}
                                                  sx={{ mb: 2 }}
                                                >
                                                  <TextField
                                                    fullWidth
                                                    label="City"
                                                    size="small"
                                                    value={data.location[i].city || ''}

                                                    // onChange={(event) => {
                                                    //   let arrayAddr = [...data.location]
                                                    //   arrayAddr[i].city = event.target.value;
                                                    //   setCart(pv => ([...pv.map(p => {


                                                    //     if (p.product_id === data.product_id) {
                                                    //       return {
                                                    //         ...p,
                                                    //         addresses: arrayAddr
                                                    //       }
                                                    //     }
                                                    //     else {
                                                    //       return {
                                                    //         ...p
                                                    //       }
                                                    //     }

                                                    //   })]))
                                                    // }}
                                                    disabled={
                                                      i !== 0 &&
                                                      data?.allRfpProductsLocationSame
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(event) => {
                                                      setCart((prevProducts) =>
                                                        prevProducts.map((p) => {
                                                          if (p.product_id === data.product_id) {
                                                            let updatedAddresses = [...p.location];
                                                            if (p.allRfpProductsLocationSame) {
                                                              updatedAddresses = updatedAddresses.map(() => ({
                                                                ...updatedAddresses[0],
                                                                city: event.target.value,
                                                              }));
                                                            } else {
                                                              updatedAddresses[i] = {
                                                                ...updatedAddresses[i],
                                                                city: event.target.value,
                                                              };
                                                            }
                                                            return {
                                                              ...p,
                                                              location: updatedAddresses,
                                                            };
                                                          }
                                                          return p;
                                                        })
                                                      );
                                                    }}
                                                  />
                                                  <TextField
                                                    fullWidth
                                                    label="State"
                                                    size="small"
                                                    value={data.location[i].state || ''}

                                                    // onChange={(event) => {
                                                    //   let arrayAddr = [...data.location]
                                                    //   arrayAddr[i].state = event.target.value;
                                                    //   setCart(pv => ([...pv.map(p => {

                                                    //     if (p.product_id === data.product_id) {
                                                    //       return {
                                                    //         ...p,
                                                    //         addresses: arrayAddr
                                                    //       }
                                                    //     }
                                                    //     else {
                                                    //       return {
                                                    //         ...p
                                                    //       }
                                                    //     }

                                                    //   })]))
                                                    // }}
                                                    disabled={
                                                      i !== 0 &&
                                                      data?.allRfpProductsLocationSame
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(event) => {
                                                      setCart((prevProducts) =>
                                                        prevProducts.map((p) => {
                                                          if (p.product_id === data.product_id) {
                                                            let updatedAddresses = [...p.location];
                                                            if (p.allRfpProductsLocationSame) {
                                                              updatedAddresses = updatedAddresses.map(() => ({
                                                                ...updatedAddresses[0],
                                                                state: event.target.value,
                                                              }));
                                                            } else {
                                                              updatedAddresses[i] = {
                                                                ...updatedAddresses[i],
                                                                state: event.target.value,
                                                              };
                                                            }
                                                            return {
                                                              ...p,
                                                              location: updatedAddresses,
                                                            };
                                                          }
                                                          return p;
                                                        })
                                                      );
                                                    }}
                                                  />
                                                  <TextField
                                                    fullWidth
                                                    label="Zip / Postal Code"
                                                    size="small"
                                                    value={data.location[i].zip || ''}

                                                    // onChange={(event) => {
                                                    //   let arrayAddr = [...data.location]
                                                    //   arrayAddr[i].zip = event.target.value;
                                                    //   setCart(pv => ([...pv.map(p => {


                                                    //     if (p.product_id === data.product_id) {
                                                    //       return {
                                                    //         ...p,
                                                    //         addresses: arrayAddr
                                                    //       }
                                                    //     }
                                                    //     else {
                                                    //       return {
                                                    //         ...p
                                                    //       }
                                                    //     }

                                                    //   })]))
                                                    // }}
                                                    disabled={
                                                      i !== 0 &&
                                                      data?.allRfpProductsLocationSame
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(event) => {
                                                      setCart((prevProducts) =>
                                                        prevProducts.map((p) => {
                                                          if (p.product_id === data.product_id) {
                                                            let updatedAddresses = [...p.location];
                                                            if (p.allRfpProductsLocationSame) {
                                                              updatedAddresses = updatedAddresses.map(() => ({
                                                                ...updatedAddresses[0],
                                                                zip: event.target.value,
                                                              }));
                                                            } else {
                                                              updatedAddresses[i] = {
                                                                ...updatedAddresses[i],
                                                                zip: event.target.value,
                                                              };
                                                            }
                                                            return {
                                                              ...p,
                                                              location: updatedAddresses,
                                                            };
                                                          }
                                                          return p;
                                                        })
                                                      );
                                                    }}
                                                  />
                                                </Stack>
                                              </Grid>
                                            ))}
                                          </Grid>


                                          <Grid container justifyContent="center" alignItems="center">
                                            <Grid item xs={12} md={4}>
                                              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 1 }}>
                                                <LoadingButton variant="contained" onClick={() => {
                                                  setIsSaving(true)
                                                  setTimeout(() => {
                                                    setIsSaving(false)
                                                    setRfpLocationStates(false)
                                                  }, 100)
                                                }} loading={isSaving}>
                                                  {' Save '}
                                                </LoadingButton>

                                              </Box>
                                            </Grid>
                                          </Grid>
                                        </Modal>
                                      </>
                                    );
                                  })}
                                </TableBody>
                                <h6>Services</h6>
                                <TableBody>
                                  {cartService?.map((data, index) => {
                                    if(values.project_category?.name == 'Time & Material'){
                                      data.sellingPrice = 0
                                      data.unit_cost = 0
                                      data.qty = 0
                                    }
                                    data.sellingPrice = data.sellingPrice || data.unit_cost
                                    data.originalMarkup = data.originalMarkup || data.product_markup

                                    return (
                                      <>
                                        <TableRow key={data.id}>
                                          <TableCell>{data.name || data.product_name}</TableCell>
                                          {/* <TableCell>{invoiceAmount}</TableCell> */}
                                          <TableCell>
                                            {data.originalMarkup?.toFixed(2)}
                                          </TableCell>
                                          <TableCell>
                                            {data.sellingPrice?.toFixed(2)}
                                          </TableCell>
                                          <TableCell>
                                            {((data.sellingPrice + (data.sellingPrice * data.originalMarkup / 100)))?.toFixed(2)}
                                          </TableCell>
                                          <TableCell>
                                            {data.qty} Hours
                                          </TableCell>
                                          <TableCell></TableCell>                                     
                                          <TableCell>
                                            {(data.qty*data.sellingPrice).toFixed(2)||0}
                                          </TableCell>
                                          <TableCell>
                                            {(data.qty * (data.sellingPrice + (data.sellingPrice * data.originalMarkup / 100)))?.toFixed(2)}
                                          </TableCell>
                                          {/* <TableCell></TableCell> */}
                                          {/* {((values?.project_category?.name == 'Service only') ||
                                            (values?.project_category?.name == 'Time & Material')) ? ( */}

                                          <TableCell>
                                            <IconButton
                                              onClick={() => {
                                                setRfpServiceLocationStates({ ...rfpServiceLocationStates, [data.id]: true });
                                              }}
                                            >
                                              <LocationOn sx={{
                                                color: data && data.location && data.location.some(obj =>
                                                  [obj.address, obj.city, obj.state, obj.zip].some(value => (value === '' || value === null))
                                                ) ? '' : theme.palette.primary.main
                                              }} />
                                            </IconButton>
                                          </TableCell>
                                          {/* ):
                                          <TableCell></TableCell>
                                          } */}
                                          <TableCell >
                                            <IconButton onClick={() => handleDeleteRFPService(data.labour_cost_service_id)}>
                                              <Delete />
                                            </IconButton>
                                          </TableCell>
                                        </TableRow>
                                        <Modal
                                          open={rfpServiceLocationStates[data.id]}
                                          handleClose={() => {
                                            setRfpServiceLocationStates({ ...rfpServiceLocationStates, [data.id]: false });
                                          }}
                                        >
                                            {index === 0 && <FormControlLabel
                                          control={
                                            <Switch
                                              checked={allRfpServiceLocationSame}
                                              onChange={handleAllRfpServiceLocationSameChange}
                                            />
                                          }
                                          label="All Locations Same"
                                        />}

                                          <Grid
                                            container
                                            spacing={3}
                                            sx={{ justifyContent: 'center', width: '100%' }}
                                          >

                                            {data?.location?.map((addr, i) => (
                                              <Grid item xs={12}>
                                                {/* Side Heading */}
                                                <Typography variant="h5" sx={{ mb: 2 }}>
                                                  Location
                                                </Typography>

                                                {/* Address Fields */}

                                                <Stack
                                                  direction={{ xs: 'column', sm: 'row' }}
                                                  spacing={{ xs: 3, sm: 2 }}
                                                  sx={{ mb: 2 }}
                                                >
                                                  <TextField
                                                    fullWidth
                                                    label="Address"
                                                    size="small"
                                                    value={data.location[i].address || ''}
                                                    disabled={index !== 0 && allRfpServiceLocationSame ? true : false}
                                                    // onChange={(event) => {
                                                    //   let arrayAddr = [...data.location]
                                                    //   arrayAddr[i].address = event.target.value;
                                                    //   setCartService(pv => ([...pv.map(p => {


                                                    //     if (p.id === data.id) {
                                                    //       return {
                                                    //         ...p,
                                                    //         location: arrayAddr
                                                    //       }
                                                    //     }
                                                    //     else {
                                                    //       return {
                                                    //         ...p
                                                    //       }
                                                    //     }

                                                    //   })]))
                                                    // }}
                                                    onChange={(event) => {
                                                      const updatedValue = event.target.value;
                                                    
                                                      setCartService((prevServices) =>
                                                        prevServices.map((service) => {
                                                          let updatedLocations = [...service.location];
                                                    
                                                          if (allRfpServiceLocationSame) {
                                                            // Apply the new value to all services in cartService
                                                            return {
                                                              ...service,
                                                              location: updatedLocations.map(loc => ({
                                                                ...loc,
                                                                address: updatedValue, // Update address field, same logic applies for others
                                                              })),
                                                            };
                                                          } else {
                                                            // Update only the specific service
                                                            if (service.id === data.id) {
                                                              updatedLocations[i].address = updatedValue;
                                                            }
                                                            return {
                                                              ...service,
                                                              location: updatedLocations,
                                                            };
                                                          }
                                                        })
                                                      );
                                                    }}                                                    
                                                  />
                                                </Stack>

                                                {/* City, State, Zip Fields */}
                                                <Stack
                                                  direction={{ xs: 'column', sm: 'row' }}
                                                  spacing={{ xs: 3, sm: 2 }}
                                                  sx={{ mb: 2 }}
                                                >
                                                  <TextField
                                                    fullWidth
                                                    label="City"
                                                    size="small"
                                                    value={data.location[i].city || ''}
                                                    disabled={index !== 0 && allRfpServiceLocationSame ? true : false}
                                                    // onChange={(event) => {
                                                    //   let arrayAddr = [...data.location]
                                                    //   arrayAddr[i].city = event.target.value;
                                                    //   setCartService(pv => ([...pv.map(p => {


                                                    //     if (p.id === data.id) {
                                                    //       return {
                                                    //         ...p,
                                                    //         location: arrayAddr
                                                    //       }
                                                    //     }
                                                    //     else {
                                                    //       return {
                                                    //         ...p
                                                    //       }
                                                    //     }

                                                    //   })]))
                                                    // }}
                                                    onChange={(event) => {
                                                      const updatedValue = event.target.value;
                                                    
                                                      setCartService((prevServices) =>
                                                        prevServices.map((service) => {
                                                          let updatedLocations = [...service.location];
                                                    
                                                          if (allRfpServiceLocationSame) {
                                                            // Apply the new value to all services in cartService
                                                            return {
                                                              ...service,
                                                              location: updatedLocations.map(loc => ({
                                                                ...loc,
                                                                city: updatedValue, // Update city field, same logic applies for others
                                                              })),
                                                            };
                                                          } else {
                                                            // Update only the specific service
                                                            if (service.id === data.id) {
                                                              updatedLocations[i].city = updatedValue;
                                                            }
                                                            return {
                                                              ...service,
                                                              location: updatedLocations,
                                                            };
                                                          }
                                                        })
                                                      );
                                                    }}                                                    
                                                  />
                                                  <TextField
                                                    fullWidth
                                                    label="State"
                                                    size="small"
                                                    value={data.location[i].state || ''}
                                                    disabled={index !== 0 && allRfpServiceLocationSame ? true : false}
                                                    // onChange={(event) => {
                                                    //   let arrayAddr = [...data.location]
                                                    //   arrayAddr[i].state = event.target.value;
                                                    //   setCartService(pv => ([...pv.map(p => {

                                                    //     if (p.id === data.id) {
                                                    //       return {
                                                    //         ...p,
                                                    //         location: arrayAddr
                                                    //       }
                                                    //     }
                                                    //     else {
                                                    //       return {
                                                    //         ...p
                                                    //       }
                                                    //     }

                                                    //   })]))
                                                    // }}
                                                    onChange={(event) => {
                                                      const updatedValue = event.target.value;
                                                    
                                                      setCartService((prevServices) =>
                                                        prevServices.map((service) => {
                                                          let updatedLocations = [...service.location];
                                                    
                                                          if (allRfpServiceLocationSame) {
                                                            // Apply the new value to all services in cartService
                                                            return {
                                                              ...service,
                                                              location: updatedLocations.map(loc => ({
                                                                ...loc,
                                                                state: updatedValue, // Update state field, same logic applies for others
                                                              })),
                                                            };
                                                          } else {
                                                            // Update only the specific service
                                                            if (service.id === data.id) {
                                                              updatedLocations[i].state = updatedValue;
                                                            }
                                                            return {
                                                              ...service,
                                                              location: updatedLocations,
                                                            };
                                                          }
                                                        })
                                                      );
                                                    }}                                                    
                                                  />
                                                  <TextField
                                                    fullWidth
                                                    label="Zip / Postal Code"
                                                    size="small"
                                                    value={data.location[i].zip || ''}
                                                    disabled={index !== 0 && allRfpServiceLocationSame ? true : false}
                                                    // onChange={(event) => {
                                                    //   let arrayAddr = [...data.location]
                                                    //   arrayAddr[i].zip = event.target.value;
                                                    //   setCartService(pv => ([...pv.map(p => {


                                                    //     if (p.id === data.id) {
                                                    //       return {
                                                    //         ...p,
                                                    //         location: arrayAddr
                                                    //       }
                                                    //     }
                                                    //     else {
                                                    //       return {
                                                    //         ...p
                                                    //       }
                                                    //     }

                                                    //   })]))
                                                    // }}
                                                    onChange={(event) => {
                                                      const updatedValue = event.target.value;
                                                    
                                                      setCartService((prevServices) =>
                                                        prevServices.map((service) => {
                                                          let updatedLocations = [...service.location];
                                                    
                                                          if (allRfpServiceLocationSame) {
                                                            // Apply the new value to all services in cartService
                                                            return {
                                                              ...service,
                                                              location: updatedLocations.map(loc => ({
                                                                ...loc,
                                                                zip: updatedValue, // Update ZIP field, same logic applies for others
                                                              })),
                                                            };
                                                          } else {
                                                            // Update only the specific service
                                                            if (service.id === data.id) {
                                                              updatedLocations[i].zip = updatedValue;
                                                            }
                                                            return {
                                                              ...service,
                                                              location: updatedLocations,
                                                            };
                                                          }
                                                        })
                                                      );
                                                    }}                                                    
                                                  />
                                                </Stack>
                                              </Grid>
                                            ))}
                                          </Grid>

                                          {index !== 0 && allRfpServiceLocationSame ? '' :
                                          <Grid container justifyContent="center" alignItems="center">
                                            <Grid item xs={12} md={4}>
                                              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 1 }}>
                                                <LoadingButton variant="contained" onClick={() => {
                                                  setIsSaving(true)
                                                  setTimeout(() => {
                                                    setIsSaving(false)
                                                    setRfpServiceLocationStates(false)
                                                  }, 100)
                                                }} loading={isSaving}>
                                                  {' Save '}
                                                </LoadingButton>

                                              </Box>
                                            </Grid>
                                          </Grid>}
                                        </Modal>
                                      </>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Scrollbar>
                          </>}
                          {/* {
                            values.project_category?.name == 'Service only' ?
                            ''
                            :( */}
                              <>
                           
                          <LabelStyleHead>Materials</LabelStyleHead>
                          {/* <Typography></Typography> */}
                          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                            <Autocomplete
                              sx={{ width: 400 }}
                              multiple
                              id="combo-box-demo"
                              // options={materials || []}
                              // options={materials?.filter((option) => !selectedMaterials?.some(selected => selected.product_id === option.id)) || []}
                              options={
                                materials?.filter((option) => {
                                  // Exclude selected materials
                                  const isSelected = selectedMaterials?.some((selected) => selected.product_id === option.id);
                                  const isServiceOnly = values.project_category?.name === 'Service only';
                                  // Filter materials: show only inhouse = 1 unless it's 'Service only'
                                  const shouldInclude = isServiceOnly || option.inhouse === 1;
                                  return !isSelected && shouldInclude;
                                }) || []
                              }
                              value={selectedMaterials?.filter(item => !item?.isAdditionalProduct)}
                              onChange={(event, newValue) => {
                                
                                handleMaterial(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Materials" variant="outlined" />
                              )}
                              // getOptionLabel={(option) => option.name ? option.name : option.product_name?option.product_name:''}
                              getOptionLabel={(option) => {
                                const name = option.name || option.product_name || '';
                                const ownershipLabel = option.inhouse === 1 ? ' (Inhouse)' : ' (Client owned)';
                                return name + ownershipLabel;
                              }}
                            />
                          </Stack>
                          <LabelStyle>Selected Materials</LabelStyle>
                          <Scrollbar>
                            <TableContainer sx={{ minWidth: 500 }}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    {VALUE_MATERIAL?.map((val) => (
                                      val.id === 3 ? (
                                        <Tooltip key={val.id} title="Unit Price + ((Unit Price * Markup)/100)">
                                          <TableCell key={val.id}>{val.value}</TableCell>
                                        </Tooltip>
                                      ) : val.id === 5 ? (
                                        <Tooltip key={val.id} title="Unit Price * Quantity">
                                          <TableCell key={val.id}>{val.value}</TableCell>
                                        </Tooltip>
                                      ) : val.id === 6 ? (
                                        <Tooltip key={val.id} title="Markup Cost * Quantity">
                                          <TableCell key={val.id}>{val.value}</TableCell>
                                        </Tooltip>
                                      ) :
                                      <TableCell key={val.id}>{val.value}</TableCell>
                                     
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {selectedMaterials?.map((data, index) => {
                                    console.log("################Materials",data);
                                    let unitName = UNITS?.filter((item) => item?.id == data?.unit)
                                    unitName = unitName[0]?.name
                                    let shortUnitName = ''
                                    if (unitName == 'Square feet') {
                                      shortUnitName = 'Sq ft'
                                    } else if (unitName == 'Lineal feet') {
                                      shortUnitName = 'Lin ft'
                                    } else if (unitName == 'Square Inches') {
                                      shortUnitName = 'Sq Inches'
                                    } else if (unitName == 'Unit') {
                                      shortUnitName = 'Unit'
                                    }
                                    data.price=data.price ? data.price : data.product_price 
                                    if(values.project_category?.name == 'Time & Material'){
                                      data['price'] = 0
                                      data['quantity'] = 0
                                    }
                                    if (values.project_category?.name == 'Service only' && data?.inhouse == 0 ) {
                                      data['price'] = 0
                                    }
                                    data.product_id= data.product_id ? data.product_id : data.id
                                    data.markup = data.markup ? data.markup : data.product_markup
                                    const markUpCost = data.price + ((parseFloat(data.price) * parseFloat(data.markup || 0)) / 100)
                                    data['markUpCost'] = parseFloat(parseFloat(markUpCost).toFixed(2)) || 0;             
                                    return (
                                      <>
                                        <TableRow key={data?.newIndexId || index}>
                                         
                                         <TableCell>
                                          {data?.isAdditionalProduct ?
                                            <TextField
                                              required
                                              fullWidth
                                              type="text"
                                              label={'Material Name'}
                                              sx={{ width: 85 }}
                                              value={data.name||data.product_name}
                                              onChange={(event) => {
                                                let newValue = event.target.value

                                                // Update the selectedProducts state
                                                setSelectedMaterials(
                                                  (prevProducts) =>
                                                    prevProducts.map((service) => {
                                                        if ( service.newIndexId === data.newIndexId ) {
                                                          return {
                                                            ...service,
                                                            name: newValue,
                                                            product_name: newValue,
                                                          }
                                                        } else {
                                                          return service
                                                        }
                                                      },
                                                    ),
                                                )
                                                data['name'] = newValue || ''
                                                data['product_name'] = newValue || ''
                                                if (isSubmitted)
                                                  setIsSubmitted(false)
                                              }}
                                              error={
                                                isSubmitted && !data.name?.trim() && !data.product_name?.trim()
                                              }
                                              helperText={
                                                isSubmitted && !data.name?.trim() && !data.product_name?.trim()
                                                  ? 'Material Name is required'
                                                  : ''
                                              }                                              
                                            />
                                          : (data.name||data.product_name)}
                                          {data?.isAdditionalProduct ? '' : data.inhouse==1 ? ' (Inhouse)' : ' (Client owned)'}
                                          </TableCell>
                                         <TableCell>{
                                          data?.isAdditionalProduct ?
                                            <TextField
                                              required
                                              fullWidth
                                              type="number"
                                              label={'Unit Price'}
                                              disabled={values.project_category?.name == 'Time & Material' ? true : false}
                                              sx={{ width: 85 }}
                                              value={data.price}
                                              // onWheel={(e) => e.target.blur()}
                                              onKeyPress={(event) => {
                                                // if (event.key === '0' && event.target.value === '') {
                                                //   event.preventDefault();
                                                // }
                                                if (event.key === '-') {
                                                  event.preventDefault();
                                                }
                                                if (event.key === 'e') {
                                                  event.preventDefault();
                                                }
                                              }}
                                              inputProps={{ min: 0 }}
                                              defaultValue={0}
                                              onChange={(event) => {
                                                let newValue = event.target.value
                                                if (newValue !== '') {
                                                  setAdditionalSelectedServices((pv) => [
                                                      ...pv.map((p) => {
                                                        if ( p.newIndexId === data.newIndexId ) {
                                                          return {
                                                            ...p,
                                                            price: Number(newValue),
                                                          }
                                                        } else {
                                                          return p
                                                        }
                                                      }),
                                                    ],
                                                  )
                                                  data['price'] =  Number(newValue || 0)
                                                }
                                              }}
                                              // error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                              // helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                            />
                                          : 
                                          data.price}</TableCell>
                                         <TableCell>
                                            <TextField required
                                              fullWidth
                                              type='number'
                                              label={'Markup'}
                                              value={Number(data.markup)||0}
                                              defaultValue={0}
                                              onKeyPress={(event) => {
                                                // if (event.key === '0' && event.target.value === '') {
                                                //   event.preventDefault();
                                                // }
                                                if (event.key === '-') {
                                                  event.preventDefault();
                                                }
                                                if (event.key === 'e') {
                                                  event.preventDefault();
                                                }
                                              }}
                                              onChange={(event) => {

                                                let newValue = event.target.value;
                                                console.log('newValuenewValue',newValue)

                                                setSelectedMaterials((pv) =>
                                                  pv.map((p) =>
                                                    p.newIndexId === data.newIndexId
                                                      ? {
                                                          ...p,
                                                          markup: newValue === "" ? "" : Number(newValue), // Allow empty string temporarily
                                                        }
                                                      : p
                                                  )
                                                );
                                              
                                                if(newValue == null ||newValue == '' || (/^0[0-9]+$/.test(newValue))){
                                                  console.log('newValuenewValue 0',newValue)

                                                  setSelectedMaterials(pv => ([...pv.map(p => {
                                                    if (p.newIndexId === data.newIndexId) {
                                                      return {
                                                        ...p,
                                                        markup: '0',
                                                      }
                                                    } else {
                                                      return p
                                                    }
                                                  })]));
                                                  newValue=0
                                                  console.log('newValuenewValue data',selectedMaterials)
                                                  return selectedMaterials
 
                                                }else if (newValue !== "") {
                                                console.log('newValuenewValue 1',newValue)

                                                  setSelectedMaterials(pv => ([...pv.map(p => {
                                                    if (p.newIndexId === data.newIndexId) {
                                                      return {
                                                        ...p,
                                                        markup: Number(newValue),
                                                      }
                                                    } else {
                                                      return p
                                                    }
                                                  })]));
                                                //  if (newValue === '' || newValue < 0) {
                                                // console.log('newValuenewValue 2',newValue)

                                                //     event.preventDefault();
                                                //     return;
                                                //   }
                                                  if (newValue.startsWith('-')) {
                                                    newValue = newValue.slice(1);
                                                  }
                                                  data['markup'] = newValue || 0;
                                                 
                                                }
                                              }}
                                              
                                            
                                            />
                                         </TableCell>
                                         <TableCell>{data.markUpCost || 0}</TableCell>
                                         <TableCell>
                                            <TextField required
                                              fullWidth
                                              type='number'
                                              label={shortUnitName || 'Quantity'}
                                              disabled={(values.project_category?.name == 'Time & Material') ? true : false}
                                              value={(values.project_category?.name == 'Time & Material') ? 0 : Number(data.quantity)||1}
                                              defaultValue={(values.project_category?.name == 'Time & Material') ? 0 : 1}
                                              onKeyPress={(event) => {
                                                if (event.key === '0' && event.target.value === '') {
                                                  event.preventDefault();
                                                }
                                                if (event.key === '-') {
                                                  event.preventDefault();
                                                }
                                                // if (event.key === '.') {
                                                //   event.preventDefault();
                                                // }
                                                if (event.key === 'e') {
                                                  event.preventDefault();
                                                }
                                              }}
                                              onChange={(event) => {

                                                let newValue = event.target.value;
                                                if (newValue !== "0" && newValue !== "") {

                                                  setSelectedMaterials(pv => ([...pv.map(p => {
                                                    console.log("################p.newIndexId", p.newIndexId);
                                                    console.log("################data.newIndexId", data.newIndexId);
                                                   
                                                    if (p.newIndexId === data.newIndexId) {
                                                     
                                                      return {
                                                        ...p,
                                                        quantity: Number(newValue),
                                                       
                                                      }
                                                    } else {
                                                      return p
                                                    }
                                                  })]));
                                                  
                                                 if (newValue === '' || newValue <= 0) {
                                                    event.preventDefault();
                                                    return;
                                                  }
                                                  if (newValue.startsWith('-')) {
                                                    newValue = newValue.slice(1);
                                                  }
                                                  data['quantity'] = newValue || 1;
                                                 
                                                }
                                              }}
                                            
                                            />
                                          </TableCell>
                                         <TableCell>{(data.price*(data.quantity||1))?.toFixed(2)}</TableCell>
                                         <TableCell>{((data.markUpCost || 0) * (data.quantity||1))?.toFixed(2)}</TableCell>
                                          <TableCell >
                                            <IconButton onClick={() => handleDeleteMaterial(data.id, data.newIndexId,)}>
                                              <Delete />
                                            </IconButton>
                                          </TableCell>
                                        </TableRow>
                                        
                                      </>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Scrollbar>
                          <Stack sx={{ justifyContent: 'space-between', alignItems: 'center', display:'flex', flexDirection:'row' }}>
                            <Button
                              variant="outlined"
                              startIcon={
                                <Stack>
                                  {' '}
                                  <Add />{' '}
                                </Stack>
                              }
                              onClick={() => {
                                addNewMaterialRow()
                                // setMarkupType(1)
                              }}
                            >
                              Add Material Row
                            </Button>
                            <Typography variant="h6">
                              Total Additional Material Cost:{' '}
                              {selectedMaterialSubTotal}
                            </Typography>
                          </Stack>
                          </>
                          {/* )
                          } */}
                          <Divider />
                          <TextField
                            fullWidth
                            multiline
                            minRows={3}
                            maxRows={7}
                            label="Notes"
                            {...getFieldProps('notes')}
                            error={Boolean(touched.notes && errors.notes)}
                            helperText={touched.notes && errors.notes}
                          />
                        </>}
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }} justifyContent={'space-between'}>
                          <TextField
                            sx={{ width: 400 }}
                            type='number'
                            onKeyPress={(event) => {
                              if (event.key === '-') {
                                event.preventDefault();
                              }
                            }}
                            onChange={(event) => {
                              let newValue = event.target.value
                              let data = 0
                              if (markupType === 1) {
                                data = totalWholesalePrice - budget
                                
                              } else if (markupType === 2) {
                                data = totalRetailPrice - budget
                              }
                              if (Number(newValue) > data) {
                                newValue = data?.toFixed(2);
                              }
                              if (newValue === '-0.00') {
                                newValue = 0;
                              }
                                console.log("discounttttttttttttttttttt",totalWholesalePrice - budget);

                              console.log('newValue-2-------', newValue);
                              setFieldValue('invoice_discount', newValue)
                            }}
                            label={hasDiscountPermission ? "Discount (Access Denied)" : "Discount"}
                            disabled = {hasDiscountPermission}
                            value={values.invoice_discount}
                            // {...getFieldProps('invoice_discount')}
                            error={Boolean(touched.invoice_discount && errors.invoice_discount)}
                            helperText={touched.invoice_discount && errors.invoice_discount}
                            inputProps={{ max: (markupType === 1) ? totalWholesalePrice - budget : ((markupType === 2) ? totalRetailPrice - budget : 0) , min: 0 }}
                          />
                          <Autocomplete
                            sx={{ width: 400 }}
                            id="combo-box-demo"
                            disabled={true}
                            options={taxCategories || []}
                            value={selectedTaxCategory || null}
                            onChange={(event, newValue) => {
                              setSelectedTaxCategory(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Tax category" />
                            )}
                            getOptionLabel={(option) => option.name ? option.name : ''}
                          />
                        </Stack>
                        <Typography sx={{ color: theme.palette.error.main }}>**All values in the software are rounded to two decimal places in accordance with standard US calculation practices.</Typography>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                          <Stack>
                            
                            {/* <TextField
                            sx={{ width: 400 }}
                            type='number'
                            onChange={(event, newValue) => setFieldValue('invoice_discount', newValue)}
                            label="Extra Discount"
                            {...getFieldProps('invoice_discount')}
                            error={Boolean(touched.invoice_discount && errors.invoice_discount)}
                            helperText={touched.invoice_discount && errors.invoice_discount}
                          /> */}

                            {/* <Typography sx={{ color: 'text.secondary' }}>Sub Total: {rfqSubTotal ? rfqSubTotal?.toFixed(2) : 0}</Typography>
                          <Typography sx={{ color: 'text.secondary' }}>Service cost in Product Formula: {initialServiceCostInProductFormula ? initialServiceCostInProductFormula?.toFixed(2) : 0}</Typography>
                          <Typography sx={{ color: 'text.secondary' }}>Total ({rfqSubTotal?.toFixed(2)} - {initialServiceCostInProductFormula?.toFixed(2)}): {totalsum ? totalsum?.toFixed(2) : 0}</Typography> */}
                          <Typography sx={{ color: 'text.secondary' }}>
                              Sub Total: {totalsum ? totalsum.toFixed(2) : '0.00'}
                            </Typography>
                            <Typography sx={{ color: 'text.secondary' }}>
                              Tax: {parseFloat(taxAmount).toFixed(2) || 0.00}
                            </Typography>
                            <Typography sx={{ color:noCharge? 'red' :'yellow' }}>
                              Grand Total: {(noCharge && grandTotal >0) ?(grandTotal * -1) :grandTotal || 0.00}
                            </Typography>
                            {/* <Typography sx={{ color: 'yellow' }}>Grand Total : {totalsum ? totalsum?.toFixed(2) : 0}</Typography> */}
                            <Typography sx={{ color: 'yellow' }}>Total Product Formula Cost: {budget ? budget?.toFixed(2) : 0}</Typography>
                            <Typography sx={{ color: 'text.secondary' }}>Total Discount: {DiscountTotal ? DiscountTotal?.toFixed(2) : 0.00}</Typography>

                            <Tooltip title="Sub Total - Total Product Formula Cost">
                              <Typography sx={{ color: 'text.secondary', cursor: 'pointer' }}>Total Markup Amount: {totalMarkupAmount ? parseFloat(Number(totalMarkupAmount))?.toFixed(2) : 0}</Typography>
                            </Tooltip>

                            <Tooltip title="(Total Markup Amount / Total Product Formula Cost) * 100">
                              <Typography sx={{ color: 'text.secondary', cursor: 'pointer' }}>Total Markup Percentage: {totalMarkupPercentage ? parseFloat(Number(totalMarkupPercentage))?.toFixed(2) : 0}</Typography>
                            </Tooltip>

                            {/* <Typography sx={{ color: 'text.secondary' }}>Total Product Markup: {(originaltotalMarkup/selectedProducts.length)?.toFixed(2)}</Typography> */}
                            {/* { showServices ?<Typography sx={{ color: 'text.secondary' }}>Total Service Markup: {(originaltotalServiceMarkup/(selectedServices.length+additionalSelectedServices.length))?.toFixed(2)}</Typography>:""} */}
                          </Stack>
                          <Stack direction={'column'} display={'flex'} justifyContent={'center'}>
                          <FormControlLabel
                            onChange={() => {
                              // Toggle the value of welcome_message using setCurrentRFQdata
                              setCurrentRFQdata((prevData) => ({
                                ...prevData,
                                welcome_message: !prevData.welcome_message,
                              }));
                            }}
                            control={<Switch  {...getFieldProps('welcome_message')} color="primary"
                              checked={values.welcome_message == 1}
                            />}
                            labelPlacement="end"
                            label="Use Custom Welcome Message"
                            sx={{ mt: 6 , mb:2 }}
                          />
                          {
                            noCharge ? ""
                          :
                          <FormControlLabel
                          control={
                            <Switch
                            {...getFieldProps('client_approve')}
                              color="primary"
                              checked={values.client_approve}
                              onChange={(event) => setFieldValue('client_approve', event.target.checked)}
                            />
                          }
                          labelPlacement="end"
                          label="Enable for Client Approval"
                          sx={{ mt: 0 }}
                        />
                        
                      }
                        </Stack>
                        </Stack>
                        {values.welcome_message == true && (
                          <>
                            <Stack>
                              <LabelStyle>Subject</LabelStyle>
                              <TextField
                                //   fullWidth
                                type='text'
                                sx={{ width: 400 }}
                                defaultValue={values.subject}
                                {...getFieldProps('subject')}
                                error={Boolean(touched.subject && errors.subject)}
                                helperText={touched.subject && errors.subject}
                              />
                            </Stack>
                            <LabelStyle>Email Content</LabelStyle>
                            <TextField
                              fullWidth
                              multiline
                              minRows={3}
                              maxRows={7}
                              label="Email Content"
                              defaultValue={values.email_content}
                              {...getFieldProps('email_content')}
                              error={Boolean(touched.email_content && errors.email_content)}
                              helperText={touched.email_content && errors.email_content}
                            />
                          </>
                        )}
                        <LabelStyle>Order state</LabelStyle>
                        <Typography>While in draft mode, clients you add to order tasks won't be notified</Typography>
                        <FormControlLabel
                          onChange={() => {

                            setCurrentRFQdata((prevData) => ({
                              ...prevData,
                              draft: !prevData.draft,
                            }));
                          }}
                          control={<Switch {...getFieldProps('draft')} color="primary" checked={values.draft} />}
                          labelPlacement="end"
                          label="Draft"
                          sx={{ mt: 5 }}
                        />
                        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                          <LoadingButton type="submit" variant="contained" loading={isSubmitting}  >
                            Save Order
                          </LoadingButton>
                          <Button variant="outlined" onClick={() => {
                            setOpenPreview(!openPreview),
                              setValuesToPreview()
                          }}>
                            Preview
                          </Button>
                          <Button variant="outlined" onClick={() => openEdit ? handleCancel(true) : handleCancel()}>
                            Cancel
                          </Button>
                        </Box>
                      </Stack>
                    </Card>
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          ))}
          {!openEdit && (
            <>
            {(details && invoice && !loadingEdit) ? (
              <Stack direction={'row'} display={'flex'} alignItems={'center'} spacing={{ xs: 3, sm: 2 }}>
                {/* <Typography  fontSize={'1.09rem'} variant="overline" sx={{ color: 'text.secondary', }}>RFQ</Typography> */}
                {details?.id && (invoice?.total !== 0 || details?.job_category_name == 'Time & Material') && !user.isVendor && (
                  (empty(details?.installation_acceptance) && !empty(details?.allInstallationCompleted)) ?
                    <AnimatedButton title={'Invoice'} onClick={() => setOpenInvoice(!openInvoice)} />
                  :
                  <Link
                    color={theme.palette.success.dark}
                    onClick={() => setOpenInvoice(!openInvoice)}
                    // component={RouterLink}
                    to={''}
                  >
                    {details?.status === 2 ? (
                      <Badge badgeContent={'1'} color={'warning'}>
                        <Typography fontSize={'1.09rem'} variant="overline">
                          <span style={{ textDecoration: 'underline' }}>Invoice</span>
                        </Typography>
                      </Badge>) : (
                      <Typography fontSize={'1.09rem'} variant="overline">Invoice</Typography>
                    )}
                  </Link>
                )}
                <Typography fontWeight={'0.09rem'} variant="overline" >
                  <Label
                    sx={{ p: '12px' }}
                    variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                    color={getStatusColorAndLabel(details?.status,details?.installationStatus,details?.allInstallationCompleted,details?.installation_acceptance,details?.min_payment_status,details?.job_category_name,details?.billing_type,details?.installationCompleteStatus).color} >
                    {getStatusColorAndLabel(details?.status,details?.installationStatus,details?.allInstallationCompleted,details?.installation_acceptance,details?.min_payment_status,details?.job_category_name,details?.billing_type,details?.installationCompleteStatus).label}
                  </Label>
                </Typography>
              </Stack>
            ) : ('')}

              <Grid container spacing={3}>

                <Grid sx={{ mt: 3 }} item xs={12} md={12}>
                  <Stack spacing={3}>
                    <Stack spacing={5}>
                      <Tabs
                        value={currentTab}
                        scrollButtons="auto"
                        variant="scrollable"
                        allowScrollButtonsMobile
                        onChange={handleChangeTab}
                      >
                        {ACCOUNT_TABS.map((tab) => (
                          <Tab
                            disableRipple
                            key={tab.value}
                            label={capitalCase(tab.value)}
                            value={tab.value}
                          />
                        ))}
                      </Tabs>

                      {ACCOUNT_TABS.map((tab) => {
                        const isMatched = tab.value === currentTab
                        return isMatched && <Box key={tab.value}>{tab.component}</Box>
                      })}
                    </Stack>
                  </Stack>
                </Grid>

              </Grid>
            </>)}

          {/* adding client modal  */}
          <AddClients
            request_id={id}
            setIsOpen={setIsOpen}
            setAllClients={setAllClients}
            allClients={allClients?.filter(a => !a.isVendor)}
            addClient={addClient}
            setAddClient={setAddClient} />
          <AddClients
            isVendor
            request_id={id}
            setIsOpen={setIsOpen}
            setAllClients={setAllClients}
            allClients={allClients?.filter(a => a.isVendor)}
            addClient={addVendor}
            setAddClient={setAddVendor} />
          <AddProposal
            request_id={id}
            setIsOpen={setIsOpen}
            setReloadData={() => setRefresh(!refresh)}
            addProposal={addProposal}
            setAddProposal={setAddProposal} />
          {/* invoice modal */}
          <Modal modalTitle={'Sales Order Details'} open={openInvoice === true} handleClose={() => setOpenInvoice(false)}>
            <Stack direction={{ xs: 'column', sm: 'row', md: 'column' }}
              spacing={{ xs: 3, sm: 2 }}>
              <Stack direction="row" justifyContent={'flex-end'} alignItems="center" spacing={1}>
                <ReactToPrint
                  trigger={() => (<Button> <Print />Print A3</Button>)}
                  content={() => componentRef.current}
                  copyStyles={false}
                  pageStyle={`
                    @media print {
                      body {
                        font-size: 12pt;
                      }
                      table {
                        width: 100%;
                        border-collapse: collapse;
                        margin-bottom: 10px;
                      }

                      th, td {
                        border: 1px solid #ddd;
                        padding: 5px;
                        text-align: left;
                      }
                      #right {
                        text-align: right;
                        margin-top: -200px; 
                      }
                      #total {
                        text-align: right;
                        display:flex;
                        justify-content: flex-end;
                      }
                      #subtotal {
                        text-align: left;
                        
                      }
                      #discount {
                        text-align: left;
                        word-wrap: break-word;
                        margin-right: 100px;
                    }
                    
                      #totalmain {
                        text-align: left;
                        
                      }
                      #subtotal1 {
                        text-align: left;
                        
                      }
                      #discount1 {
                        text-align: left;
                        word-wrap: break-word;
                        margin-right: 30px;
                    }
                    
                      #totalmain1 {
                        text-align: left;
                        
                      }
                      #total1 {
                        text-align: right;
                        display:flex;
                        justify-content: flex-end;
                        margin-top: -220px; 
                        
                      }
                      #logoImage {
                        height: 100px;
                        width:100px;
                      }
                      #order{
                        margin-bottom:-20px
                      }
                      #title{
                        margin-bottom:-20px
                      }
                      #orderID {
                        margin-bottom:-20px
                      }
                      #to {
                        margin-bottom:-20px
                      }
                      
                    }
                  `}
                />
                {(details?.status === 4 || details?.status === 3) && SEND_EMAIL && <Button onClick={handleSendEmail}>Send Email</Button>}
                <div style={{ display: "none" }}>
                  <PrintFunction
                    invoiceDate={invoiceDate}
                    openInvoice={openInvoice}
                    details={details}
                    invoice={invoice}
                    subTotal={subTotal}
                    ref={componentRef}
                    cartService={cartService}
                    additionalSelectedServices={additionalSelectedServices}
                    invoiceSelectedServices={invoiceSelectedServices}
                    data={data}
                    userType={userType}
                    labourInstallService={labourInstallService}
                  />
                </div>

              </Stack>
            </Stack>
            <Grid container spacing={3} >

              <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
                  <Box component="img" alt="logo" src={COMPANY_LOGO} sx={{ height: 98 }} />
                </Grid>
                <Typography variant="body2">{COMPANY_NAME}</Typography>
                <Typography variant="body2">www.{COMPANY_NAME}.com</Typography>
                <Typography variant="body2">
                  {data?.address}, {data?.city}, {data?.state}, {data?.zipcode}
                </Typography>
                <Typography variant="body2">Telephone: +15208362947</Typography>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mb: 5 }}>

                <Typography variant="body2">
                  Order date: {invoiceDate}
                </Typography>
                {/* <Stack sx={{display:'flex',flexDirection:'row'}}> */}
                {/* <Typography variant="body2">Order status:</Typography> */}
                <Label
                  variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                  color={getStatusColorAndLabel(details?.status,details?.installationStatus,details?.allInstallationCompleted,details?.installation_acceptance,details?.min_payment_status,details?.job_category_name,details?.billing_type,details?.installationCompleteStatus).color} >
                  {getStatusColorAndLabel(details?.status,details?.installationStatus,details?.allInstallationCompleted,details?.installation_acceptance,details?.min_payment_status,details?.job_category_name,details?.billing_type,details?.installationCompleteStatus).label}
                </Label>
                {/* </Stack> */}
                <Typography variant="body2">Order Name:{details?.name}</Typography>
                <Typography variant="body2">Order ID:{details?.id}</Typography>
                <Typography paragraph variant="overline" sx={{ color: 'text.disabled', marginTop: '20px' }}>
                  TO {invoice?.customer_name}
                </Typography>
                <Typography variant="body2">Phone: {invoice?.customer_phone}</Typography>
                {(details?.job_category_name == 'Time & Material') ? 
                  <Label variant='filled' color="info">Time & Material</Label>
                : ''}
              </Grid>
            </Grid>
            <Grid container spacing={2}>

              <Scrollbar>
                {((details?.job_category_name == 'Time & Material') && (details?.allInstallationCompleted == 1)) ? 
                  <>
                    {(!empty(invoice?.jobcost_product)) ? 
                    <>
                    <Typography variant='h6' sx={{ mt: 2 }}>Materials</Typography> 
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>NAME</TableCell>
                            <TableCell>UNIT COST</TableCell>
                            <TableCell>QUANTITY</TableCell>
                            <TableCell>TOTAL COST</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {invoice?.jobcost_product?.map((val,index) => {
                            const { id, product_name, unit_price, quantity, total, unit,partial_unit, unit_qty } = val
                            let unitToUse = partial_unit ? partial_unit : unit
                            let unitName = UNITS?.filter((item) => item?.id == unitToUse)
                            let shortUnitName = unitName[0]?.short_name || 'Unit'
                            unitName = unitName[0]?.name
                            // let shortUnitName = ''
                            // if (unitName == 'Square feet') {
                            //   shortUnitName = 'Sq ft'
                            // } else if (unitName == 'Lineal feet') {
                            //   shortUnitName = 'Lin ft'
                            // } else if (unitName == 'Square Inches') {
                            //   shortUnitName = 'Sq Inches'
                            // } else if (unitName == 'Unit') {
                            //   shortUnitName = 'Unit'
                            // }
                            return (
                              <TableRow sx={{
                                borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                              }}>
                                <TableCell colSpan={2}>{product_name}</TableCell>
                                <TableCell> {unit_price}</TableCell>
                                <TableCell>{quantity}({shortUnitName})</TableCell>
                                <TableCell> {total}</TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    </>
                    : ''}
                    {invoice?.jobcost_service?.length > 0 ? <Typography variant='h6' sx={{ mt: 2 }}>Services</Typography> : ''}
                    <TableContainer>
                        <Table>
                        {invoice?.jobcost_service?.length > 0 ? 
                        <>
                          <TableHead>
                            <TableRow>
                              <TableCell colSpan={2}>NAME</TableCell>
                              <TableCell>HOURLY COST</TableCell>
                              <TableCell>HOURS</TableCell>
                              <TableCell>TOTAL COST</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {invoice?.jobcost_service?.map((ser,index) => {
                              return (
                                <TableRow sx={{
                                  borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                                }}>
                                  <TableCell colSpan={2}>{ser?.name}</TableCell>
                                  <TableCell>{ser ? parseFloat(ser?.service_original_cost)?.toFixed(2) : 0}</TableCell>
                                  <TableCell>{ser ? parseFloat(ser?.jobcost_quantity)?.toFixed(2) : 0}</TableCell>
                                  <TableCell>{ser ? ser?.total_service_cost : 0}</TableCell>
                                </TableRow>
                              )
                            })}
                          </TableBody>
                        </> : ''}
                          <RowResultStyle>
                            <TableCell colSpan={3} />
                            <TableCell align="right">
                              <Typography variant="overline">Subtotal</Typography>
                            </TableCell>
                            <TableCell align="left" width={10}>
                              <Typography variant="overline">{invoice?.jobcostTotal ? invoice?.jobcostTotal?.toFixed(2) : 0.00}</Typography>
                            </TableCell>
                          </RowResultStyle>

                          <RowResultStyle>
                            <TableCell colSpan={3} />
                            <TableCell align="right">
                              <Typography variant="overline">{'Services'}</Typography>
                            </TableCell>
                            <TableCell align="left" width={10}>
                              <Typography variant="overline">{invoice?.jobcostServiceTotal ? invoice?.jobcostServiceTotal?.toFixed(2) : 0.00}</Typography>
                            </TableCell>
                          </RowResultStyle>

                          <RowResultStyle>
                            <TableCell colSpan={3} />
                            <TableCell align="right">
                              <Typography variant="overline">Discount</Typography>
                            </TableCell>
                            <TableCell align="left" width={10}>
                              <Typography variant="overline">{invoice ? invoice?.extra_discount?.toFixed(2) : 0.00}</Typography>
                            </TableCell>
                          </RowResultStyle>

                          <RowResultStyle>
                            <TableCell colSpan={3} />
                            <TableCell align="right">
                              <Typography variant="overline">Tax</Typography>
                            </TableCell>
                            <TableCell align="left" width={10}>
                              <Typography variant="overline">{invoice ? invoice?.tax_amount?.toFixed(2) : 0.00}</Typography>
                            </TableCell>
                          </RowResultStyle>

                          <RowResultStyle>
                            <TableCell colSpan={3} />
                            <TableCell align="right">
                              <Typography variant="subtitle1"  sx={{ color: invoice?.grandTotal < 0 ? 'red' : 'inherit' }}>Total</Typography>
                            </TableCell>
                            <TableCell align="left" width={10}>
                              <Typography variant="subtitle1"  sx={{ color: invoice?.grandTotal < 0 ? 'red' : 'inherit' }}>{invoice?.grandTotal ? invoice?.grandTotal?.toFixed(2) : 0.00}</Typography>
                            </TableCell>
                          </RowResultStyle>
                          {invoice?.project_category == 13 ? '' : 
                            <>
                            {(userType !=3 && details?.job_category_name != 'Time & Material' && details?.job_category_name != 'No Charge') ?
                            <RowResultStyle>
                              <TableCell colSpan={3} />
                              <TableCell align="right" sx={{display:"flex"}}>
                              <Typography fontSize={"small"} variant="caption"  sx={{ color:  'inherit' }}> (only viewable for admin)</Typography>
                                <Typography variant="subtitle1"  sx={{ color:  '#12b2e3' }}>Billing Type</Typography>
                              </TableCell>
                              <TableCell align="left" width={10}>
                                <Typography variant="subtitle1"  sx={{ color:  '#12b2e3' }}>{details?.billing_type ? `${details?.billing_type} %` :"Nill"}</Typography>
                              </TableCell>
                            </RowResultStyle>
                            :""}
                         {
                         (invoice?.total && details?.billing_type)?

                            <RowResultStyle>
                              <TableCell colSpan={3} />
                              <TableCell align="right">
                                <Typography variant="subtitle1"  sx={{ color:  'yellow' }}>Minimum Payable Amount</Typography>
                              </TableCell>
                              <TableCell align="left" width={10}>
                                <Typography variant="subtitle1"  sx={{ color:  'yellow' }}>{(invoice?.total && details?.billing_type)? parseFloat((invoice?.total *details?.billing_type) / 100).toFixed(2) :0.00}</Typography>
                              </TableCell>
                            </RowResultStyle>
                            :""
                            }

                              <RowResultStyle>
                                <TableCell colSpan={3} />
                                <TableCell align="right">
                                  <Typography variant="subtitle1"  sx={{ color: details?.totalPaidAmount < 0 ? 'red' : 'green' }}>Amount Paid</Typography>
                                </TableCell>
                                <TableCell align="left" width={10}>
                                  <Typography variant="subtitle1"  sx={{ color: details?.totalPaidAmount < 0 ? 'red' : 'green' }}>{details?.totalPaidAmount ? parseFloat(details?.totalPaidAmount)?.toFixed(2) : 0.00}</Typography>
                                </TableCell>
                              </RowResultStyle>
                              <RowResultStyle>
                                <TableCell colSpan={3} />
                                <TableCell align="right">
                                  <Typography variant="subtitle1"  sx={{ color: invoice?.newBalanceDue <= 0 ? 'inherit' : 'red' }}>Balance Due</Typography>
                                </TableCell>
                                <TableCell align="left" width={10}>
                                  <Typography variant="subtitle1"  sx={{ color: invoice?.newBalanceDue <= 0 ? 'inherit' : 'red' }}>{invoice?.newBalanceDue ? parseFloat(invoice?.newBalanceDue)?.toFixed(2) : 0.00}</Typography>
                                </TableCell>
                              </RowResultStyle>
                            </>
                          }
                      </Table>
                    </TableContainer>
                    {(details?.allInstallationCompleted == 1 && !empty(invoice?.note_data)) ? (
                      <>
                        <Typography variant="h6" sx={{ mt: 2, mb: 1, fontWeight: 'bold' }}>Installation Notes</Typography>
                        <Stack spacing={2}>
                          {invoice?.note_data?.map((val, index) => {
                            const { id, authorized_by, note, timestamp } = val;
                            return (
                              <Paper
                                key={id}
                                elevation={3}
                                sx={{ padding: 2, borderRadius: 2, backgroundColor: 'rgba(145, 158, 171, 0.16)', color: '#ffffff' }}
                              >
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={8}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                      {note}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={4} sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                    {/* <Typography variant="body2" sx={{ color: 'primary.light' }}> */}
                                    <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
                                      Added By: {authorized_by}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
                                      {moment.unix(timestamp).format('MM-DD-YYYY hh:mm:ss A')}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Paper>
                            );
                          })}
                        </Stack>
                      </>
                    ) : ''}
                  </> :
                  <>
                  {(invoice?.cust_product?.length > 0) ? <>
                  <Typography variant='h6'>{details?.job_category_name != 'Time & Material' ? 'Products' : 'Materials'}</Typography>
                  <TableContainer sx={{ minWidth: 420, mt: 3 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{details?.job_category_name != 'Time & Material' ? 'PRODUCT & NOTE' : 'MATERIAL' }</TableCell>
                          <TableCell>UNIT PRICE</TableCell>
                          <TableCell align="center">UNIT</TableCell>
                          <TableCell>QTY</TableCell>
                          {/* <TableCell>MARKETING ADJUSTMENT (%)</TableCell> */}
                          <TableCell>SUB TOTAL</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {invoice?.cust_product?.map((val) => {
                          const { vid, product_name, product_notes, product_price, product_retail_price, adjustment, 
                            quantity, locationQuantity, product_total, discount, unit, unit_qty, material,rfp,markAdjType,
                            services = [],
                          } = val
                          console.log('val--------------',val);
                           // Find the service with labour_cost_service_id === 10
                       const labourService = services.find(service => service.labour_cost_service_id === 10);
                       const minusAmountLabourInstall = labourService?.total_cost || 0;

                          let unitName = UNITS?.filter((item) => item?.id == unit)
                          unitName = unitName[0]?.name
                          let shortUnitName = ''
                          if (unitName == 'Square feet') {
                            shortUnitName = 'Sq ft'
                          } else if (unitName == 'Lineal feet') {
                            shortUnitName = 'Lin ft'
                          } else if (unitName == 'Square Inches') {
                            shortUnitName = 'Sq Inches'
                          } else {
                            shortUnitName = 'Unit'
                          }
                          //  subTotal += product_total    
                          let SPMA = 0
                          if(markAdjType==2 || markAdjType==3){
                            SPMA = (product_retail_price || 0) +  (adjustment ? Number(adjustment) : 0)
            
                          }else{

                            SPMA = (product_retail_price || 0) + (((product_retail_price || 0) * (adjustment ? Number(adjustment) : 0)) / 100)
                          }
                          SPMA = parseFloat(SPMA)?.toFixed(2)                 
                          return (

                            <TableRow key={vid} sx={{
                              borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                            }}>
                              <TableCell width={'25%'} sx={{ flexWrap: 'wrap', overflow: 'hidden' }}>
                                <Typography variant='subtitle2' sx={{ fontWeight: 'bold', mb: 1, wordBreak: 'break-word', overflowWrap: 'break-word' }}>{product_name}</Typography>
                                {product_notes ? 
                                <Stack direction={'column'}>
                                  <Typography variant='body2' sx={{textDecoration: 'underline'}}>Note : </Typography> 
                                  <Typography variant='body2' sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>{product_notes}</Typography> 
                                </Stack>
                                : ''}
                                {(material === 1 && details?.job_category_name != 'Time & Material') ? 
                                  <Typography variant='body2' sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>(Addl. Material)</Typography> 
                                : ''}
                              </TableCell>
                              <TableCell>{SPMA ? SPMA : 0.00} {minusAmountLabourInstall && minusAmountLabourInstall > 0? "(Included Labor Install)" :""}</TableCell>
                              <TableCell align="center">{quantity}({shortUnitName})</TableCell>
                              <TableCell>{locationQuantity}</TableCell>
                              {/* <TableCell>{discount ? discount?.toFixed(2) : 0.00}</TableCell> */}
                              {/* <TableCell>{(material === 1 || rfp === 1) ? 0.00 : adjustment ? Number(adjustment)?.toFixed(2) : 0.00}</TableCell> */}
                              
                              {markAdjType==1?
                            <TableCell>{product_total ? (product_total - minusAmountLabourInstall)?.toFixed(2) : 0.00}</TableCell>
                          :
                          <TableCell>{((SPMA * (locationQuantity * quantity)) - minusAmountLabourInstall).toFixed(2)}</TableCell>

                          }
                              {/* <TableCell>{product_total ? product_total?.toFixed(2) : 0.00}</TableCell> */}
                            </TableRow>
                          )
                        }
                        )}

                        {/* <RowResultStyle>
                        <TableCell colSpan={3} />
                        <TableCell align="center">
                          <Typography variant="overline">Subtotal</Typography>
                        </TableCell>
                        <TableCell align="center" width={10}>
                          <Typography variant="overline">{subTotal ? subTotal?.toFixed(2) : 0.00}</Typography>
                        </TableCell>
                      </RowResultStyle>
                      <RowResultStyle>
                        <TableCell colSpan={3} />
                        <TableCell align="center">
                          <Typography variant="overline">Additional Labor Cost</Typography>
                        </TableCell>
                        <TableCell align="center" width={10}>
                          <Typography variant="overline">{details ? details?.labour_cost?.toFixed(2) : 0.00}</Typography>
                        </TableCell>
                      </RowResultStyle>
                      <RowResultStyle>
                        <TableCell colSpan={3} />
                        <TableCell align="center">
                          <Typography variant="overline">Extra Discount</Typography>
                        </TableCell>
                        <TableCell align="center" width={10}>
                          <Typography variant="overline">{invoice?.extra_discount ? invoice?.extra_discount?.toFixed(2) : 0.00}</Typography>
                        </TableCell>

                      </RowResultStyle>
                      <RowResultStyle>
                        <TableCell colSpan={3} />
                        <TableCell align="center">
                          <Typography variant="subtitle1">Total</Typography>
                        </TableCell>
                        <TableCell align="center" width={10}>
                          <Typography variant="subtitle1">{invoice?.total ? invoice?.total?.toFixed(2) : 0.00}</Typography>
                        </TableCell>

                      </RowResultStyle> */}

                      </TableBody>
                    </Table>
                  </TableContainer>
                  </> : null}


{/*below for labour Install */}
                  <Typography variant='h6' sx={{ mt: 2 }}>{(labourInstallService?.length > 0 ) ? ((details?.job_category_name != 'Service only' && details?.job_category_name != 'Time & Material') ? "Services(Labor Install)" : 'Services(Labor Install)') : ""}</Typography>
                  <TableContainer sx={{ minWidth: 420, mt: 3 }}>
                    <Table>
                      {(labourInstallService?.length > 0 ) &&
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>NAME</TableCell>
                            <TableCell>COST (HOURLY)</TableCell>
                            <TableCell>HOURS</TableCell>
                            <TableCell>TOTAL COST</TableCell>
                          </TableRow>
                        </TableHead>}
                      <TableBody>
                      
                      {labourInstallService?.map((ser) => {
                          console.log('invoiceSelectedServices--@@ 1', ser);
                          return (
                            <TableRow sx={{
                              borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                            }}>
                              <TableCell colSpan={2}>{ser?.name}-({ser.productName})</TableCell>
                              <TableCell>{ser ? ser?.unit_cost?.toFixed(2) : 0}</TableCell>
                              <TableCell>{ser ? ser?.quantity?.toFixed(2) : 0}</TableCell>
                              <TableCell>{ser ? ser?.total_cost?.toFixed(2) : 0}</TableCell>
                            </TableRow>
                          )
                        })}
                        </TableBody>
                        </Table>
                        </TableContainer>

{/*above  for labour Install */}


                  <Typography variant='h6' sx={{ mt: 2 }}>{(additionalSelectedServices?.length > 0 || cartService?.length > 0 || invoiceSelectedServices?.length > 0) ? ((details?.job_category_name != 'Service only' && details?.job_category_name != 'Time & Material') ? "Additional Services" : 'Services') : ""}</Typography>
                  <TableContainer sx={{ minWidth: 420, mt: 3 }}>
                    <Table>
                      {(additionalSelectedServices?.length > 0 || cartService?.length > 0 || invoiceSelectedServices?.length > 0) &&
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>NAME</TableCell>
                            <TableCell>COST (HOURLY)</TableCell>
                            <TableCell>HOURS</TableCell>
                            <TableCell>TOTAL COST</TableCell>
                          </TableRow>
                        </TableHead>}
                      <TableBody>
                        {invoice?.cust_product?.map((val) => {
                          const { vid, product_name, product_price, product_retail_price,
                            quantity, product_total, discount, unit, unit_qty,markAdjType,adjustment,locationQuantity
                          } = val
                          let unitName = UNITS?.filter((item) => item?.id == unit)
                          unitName = unitName[0]?.name
                          let shortUnitName = ''
                          if (unitName == 'Square feet') {
                            shortUnitName = 'Sq ft'
                          } else if (unitName == 'Lineal feet') {
                            shortUnitName = 'Lin ft'
                          } else if (unitName == 'Square Inches') {
                            shortUnitName = 'Sq Inches'
                          } else if (unitName == 'Unit') {
                            shortUnitName = 'Unit'
                          }

                          let SPMA = 0
                        if(markAdjType==2 || markAdjType==3) {
                          SPMA = (product_retail_price || 0) + (adjustment ? Number(adjustment) : 0)
                        }else{
                          SPMA = (product_retail_price || 0) + (((product_retail_price || 0) * (adjustment ? Number(adjustment) : 0)) / 100)

                        }
                        SPMA = parseFloat(SPMA)?.toFixed(2)  

                        let theSubTotalAmount =markAdjType==1?product_total: (SPMA * (locationQuantity * quantity))

                          subTotal += theSubTotalAmount
                          return (
                            null
                          )
                        })}

                        {openInvoice && invoiceSelectedServices?.map((ser) => {
                          console.log('invoiceSelectedServices--@@', ser);
                          return (
                            <TableRow sx={{
                              borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                            }}>
                              <TableCell colSpan={2}>{ser?.name}</TableCell>
                              <TableCell>{ser ? ser?.unit_cost?.toFixed(2) : 0}</TableCell>
                              <TableCell>{ser ? ser?.quantity?.toFixed(2) : 0}</TableCell>
                              <TableCell>{ser ? ser?.total_cost?.toFixed(2) : 0}</TableCell>
                            </TableRow>
                          )
                        })}

                        {openInvoice && additionalSelectedServices
                          ?.filter(servItem=>{
                            if (details?.job_category_name != 'Time & Material' && servItem.labour_cost_service_id == 10) {
                              return false;
                            }
                            return true;
                          })?.map((ser) => {
                            console.log('additionalSelectedServices--', ser);
                            return (
                              <TableRow sx={{
                                borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                              }}>
                                <TableCell colSpan={2}>{ser?.name}</TableCell>
                                <TableCell>{ser ? ser?.unit_cost?.toFixed(2) : 0}</TableCell>
                                <TableCell>{ser ? ser?.quantity?.toFixed(2) : 0}</TableCell>
                                <TableCell>{ser ? ser?.total_cost?.toFixed(2) : 0}</TableCell>
                              </TableRow>
                            )
                          }
                        )}

                        {openInvoice && cartService?.map((ser) => {
                          console.log('cartService--', ser);
                          ser.sellingPrice = ser.sellingPrice || ser.unit_cost
                          ser.originalMarkup = ser.originalMarkup || ser.product_markup
                          return (
                            <TableRow sx={{
                              borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                            }}>
                              <TableCell colSpan={2}>{ser?.name}</TableCell>
                              <TableCell> {((ser.sellingPrice + (ser.sellingPrice * ser.originalMarkup / 100)))?.toFixed(2)}</TableCell>
                              <TableCell>{ser ? ser?.qty?.toFixed(2) : 0}</TableCell>
                              <TableCell> {(ser.qty * (ser.sellingPrice + (ser.sellingPrice * ser.originalMarkup / 100)))?.toFixed(2)}</TableCell>
                            </TableRow>
                          )
                        }
                        )}
                        {(details?.job_category_name != 'Service only' || subTotal > 0) &&
                        <RowResultStyle>
                          <TableCell colSpan={3} />
                          <TableCell align="right">
                            <Typography variant="overline">Subtotal</Typography>
                          </TableCell>
                          <TableCell align="left" width={10}>
                            <Typography variant="overline">{subTotal ? subTotal?.toFixed(2) : 0.00}</Typography>
                          </TableCell>
                        </RowResultStyle>}

                        <RowResultStyle>
                          <TableCell colSpan={3} />
                          <TableCell align="right">
                            {/* <Typography variant="overline">{details?.job_category_name != 'Service only' ? 'Additional Labor Cost' : 'Labor Cost'}</Typography> */}
                            <Typography variant="overline">{(details?.job_category_name != 'Service only' && details?.job_category_name != 'Time & Material') ? 'Additional Services' : 'Services'}</Typography>
                          </TableCell>
                          <TableCell align="left" width={10}>
                            <Typography variant="overline">{details ? details?.labour_cost?.toFixed(2) : 0.00}</Typography>
                          </TableCell>
                        </RowResultStyle>

                        <RowResultStyle>
                          <TableCell colSpan={3} />
                          <TableCell align="right">
                            <Typography variant="overline">Discount</Typography>
                          </TableCell>
                          <TableCell align="left" width={10}>
                            <Typography variant="overline">{invoice ? invoice?.extra_discount?.toFixed(2) : 0.00}</Typography>
                          </TableCell>
                        </RowResultStyle>

                        <RowResultStyle>
                          <TableCell colSpan={3} />
                          <TableCell align="right">
                            <Typography variant="overline">Tax</Typography>
                          </TableCell>
                          <TableCell align="left" width={10}>
                            <Typography variant="overline">{invoice ? invoice?.tax_amount?.toFixed(2) : 0.00}</Typography>
                          </TableCell>
                        </RowResultStyle>

                        <RowResultStyle>
                          <TableCell colSpan={3} />
                          <TableCell align="right">
                            <Typography variant="subtitle1"  sx={{ color: invoice?.total < 0 ? 'red' : 'inherit' }}>Total</Typography>
                          </TableCell>
                          <TableCell align="left" width={10}>
                            <Typography variant="subtitle1"  sx={{ color: invoice?.total < 0 ? 'red' : 'inherit' }}>{invoice?.total ? invoice?.total?.toFixed(2) : 0.00}</Typography>
                          </TableCell>
                        </RowResultStyle>
                        {invoice?.project_category == 13 ? '' : 
                          <>

{
                              (userType !=3 && details?.job_category_name != 'Time & Material' && details?.job_category_name != 'No Charge') ?
                           <RowResultStyle>
                              <TableCell colSpan={3} />
                              <TableCell align="right" sx={{display:"flex", alignItems: "center",gap: 1}}>
                                <Typography fontSize={"small"} variant="caption"  sx={{ color:  'inherit' }}> (only viewable for admin)</Typography>
                                <Typography variant="subtitle1"  sx={{ color:  '#12b2e3' }}>Billing Type</Typography>
                              </TableCell>
                              <TableCell align="left" width={10}>
                                <Typography variant="subtitle1"  sx={{ color:  '#12b2e3' }}>{details?.billing_type ? `${details?.billing_type} %` :"Nill"}</Typography>
                              </TableCell>
                            </RowResultStyle>
                            :""}
                         {
                         (invoice?.total && details?.billing_type)?

                            <RowResultStyle>
                              <TableCell colSpan={3} />
                              <TableCell align="right">
                                <Typography variant="subtitle1"  sx={{ color:  'yellow' }}>Minimum Payable Amount</Typography>
                              </TableCell>
                              <TableCell align="left" width={10}>
                                <Typography variant="subtitle1"  sx={{ color:  'yellow' }}>{(invoice?.total && details?.billing_type)? parseFloat((invoice?.total *details?.billing_type) / 100).toFixed(2) :0.00}</Typography>
                              </TableCell>
                            </RowResultStyle>
                            :""
                            }

                            <RowResultStyle>
                              <TableCell colSpan={3} />
                              <TableCell align="right">
                                <Typography variant="subtitle1"  sx={{ color: details?.totalPaidAmount < 0 ? 'red' : 'green' }}>Amount Paid</Typography>
                              </TableCell>
                              <TableCell align="left" width={10}>
                                <Typography variant="subtitle1"  sx={{ color: details?.totalPaidAmount < 0 ? 'red' : 'green' }}>{details?.totalPaidAmount ? parseFloat(details?.totalPaidAmount)?.toFixed(2) : 0.00}</Typography>
                              </TableCell>
                            </RowResultStyle>
                            <RowResultStyle>
                              <TableCell colSpan={3} />
                              <TableCell align="right">
                                <Typography variant="subtitle1"  sx={{ color: details?.dueAmount <= 0 ? 'inherit' : 'red' }}>Balance Due</Typography>
                              </TableCell>
                              <TableCell align="left" width={10}>
                                <Typography variant="subtitle1"  sx={{ color: details?.dueAmount <= 0 ? 'inherit' : 'red' }}>{details?.dueAmount ? parseFloat(details?.dueAmount <=0 ? 0.00:details?.dueAmount)?.toFixed(2) : 0.00}</Typography>
                              </TableCell>
                            </RowResultStyle>
                          </>
                        }
                        </TableBody>
                    </Table>
                  </TableContainer>
                  {/* {((details?.allInstallationCompleted == 1) && (!empty(invoice?.jobcost_product) || !empty(invoice?.jobcost_service?.filter(it=>it.total_cost == 0))) ) ?
                  <>
                    <Typography variant='h6' sx={{ mt: 2 }}>Additional Amount Details</Typography>
                    <TableContainer>
                      <Table>
                        {invoice?.jobcost_product?.length > 0 ?
                        <>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>NAME</TableCell>
                            <TableCell>UNIT COST</TableCell>
                            <TableCell>QUANTITY</TableCell>
                            <TableCell>TOTAL COST</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {invoice?.jobcost_product?.map((val,index) => {
                            const { id, product_name, unit_price, quantity, total, unit, unit_qty } = val
                            let unitName = UNITS?.filter((item) => item?.id == unit)
                            unitName = unitName[0]?.name
                            let shortUnitName = ''
                            if (unitName == 'Square feet') {
                              shortUnitName = 'Sq ft'
                            } else if (unitName == 'Lineal feet') {
                              shortUnitName = 'Lin ft'
                            } else if (unitName == 'Square Inches') {
                              shortUnitName = 'Sq Inches'
                            } else if (unitName == 'Unit') {
                              shortUnitName = 'Unit'
                            }
                            return (
                              <TableRow sx={{
                                borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                              }}>
                                <TableCell colSpan={2}>{product_name}</TableCell>
                                <TableCell> {unit_price}</TableCell>
                                <TableCell>{quantity}({shortUnitName})</TableCell>
                                <TableCell> {total}</TableCell>
                              </TableRow>
                            )
                          })}
                          {invoice?.jobcost_service?.filter(it=>it.total_cost == 0)?.length > 0 ? '' :
                          <>
                          <RowResultStyle>
                            <TableCell colSpan={2} />
                            <TableCell align="right" colSpan={2}>
                              <Typography variant="overline">Additional Amount</Typography>
                            </TableCell>
                            <TableCell align="left" width={10}>
                              <Typography variant="overline">{invoice?.jobcostTotal ? invoice?.jobcostTotal?.toFixed(2) : 0.00}</Typography>
                            </TableCell>
                          </RowResultStyle>
                          <RowResultStyle>
                            <TableCell colSpan={2} />
                            <TableCell align="right" colSpan={2}>
                              <Typography variant="overline">Grand Total</Typography>
                            </TableCell>
                            <TableCell align="left" width={10}>
                              <Typography variant="overline">{invoice?.grandTotal ? invoice?.grandTotal?.toFixed(2) : 0.00}</Typography>
                            </TableCell>
                          </RowResultStyle>
                          </>}
                        </TableBody>
                        </> : ''}
                        {invoice?.jobcost_service?.filter(it=>it.total_cost == 0)?.length > 0 ? 
                        <>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>NAME</TableCell>
                            <TableCell>HOURLY COST</TableCell>
                            <TableCell>HOURS</TableCell>
                            <TableCell>TOTAL COST</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {invoice?.jobcost_service?.filter(it=>it.total_cost == 0)?.map((ser,index) => {
                            return (
                              <TableRow sx={{
                                borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                              }}>
                                <TableCell colSpan={2}>{ser?.name}</TableCell>
                                <TableCell>{ser ? parseFloat(ser?.service_original_cost)?.toFixed(2) : 0}</TableCell>
                                <TableCell>{ser ? parseFloat(ser?.jobcost_quantity)?.toFixed(2) : 0}</TableCell>
                                <TableCell>{ser ? ser?.total_service_cost : 0}</TableCell>
                              </TableRow>
                            )
                          })}
                          <RowResultStyle>
                            <TableCell colSpan={2} />
                            <TableCell align="right" colSpan={2}>
                              <Typography variant="overline">Additional Amount</Typography>
                            </TableCell>
                            <TableCell align="left" width={10}>
                              <Typography variant="overline">{invoice?.jobcostTotal ? invoice?.jobcostTotal?.toFixed(2) : 0.00}</Typography>
                            </TableCell>
                          </RowResultStyle>
                          <RowResultStyle>
                            <TableCell colSpan={2} />
                            <TableCell align="right" colSpan={2}>
                              <Typography variant="overline">Additional Service Amount</Typography>
                            </TableCell>
                            <TableCell align="left" width={10}>
                              <Typography variant="overline">{invoice?.jobcostServiceTotal ? invoice?.jobcostServiceTotal?.toFixed(2) : 0.00}</Typography>
                            </TableCell>
                          </RowResultStyle>
                          <RowResultStyle>
                            <TableCell colSpan={2} />
                            <TableCell align="right" colSpan={2}>
                              <Typography variant="overline">Grand Total</Typography>
                            </TableCell>
                            <TableCell align="left" width={10}>
                              <Typography variant="overline">{invoice?.grandTotal ? invoice?.grandTotal?.toFixed(2) : 0.00}</Typography>
                            </TableCell>
                          </RowResultStyle>
                        </TableBody>
                        </> : ''}
                      </Table>
                    </TableContainer>
                  </>
                  : ''} */}
                  {(details?.allInstallationCompleted == 1 && !empty(invoice?.note_data)) ? (
                    <>
                      <Typography variant="h6" sx={{ mt: 2, mb: 1, fontWeight: 'bold' }}>Installation Notes</Typography>
                      <Stack spacing={2}>
                        {invoice?.note_data?.map((val, index) => {
                          const { id, authorized_by, note, timestamp } = val;
                          return (
                            <Paper
                              key={id}
                              elevation={3}
                              sx={{ padding: 2, borderRadius: 2, backgroundColor: 'rgba(145, 158, 171, 0.16)', color: '#ffffff' }}
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={8}>
                                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                    {note}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4} sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                  {/* <Typography variant="body2" sx={{ color: 'primary.light' }}> */}
                                  <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
                                    Added By: {authorized_by}
                                  </Typography>
                                  <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
                                    {moment.unix(timestamp).format('MM-DD-YYYY hh:mm:ss A')}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Paper>
                          );
                        })}
                      </Stack>
                    </>
                  ) : ''}
                  </>
                }
                {((userType == 3 ||userType !== 1 ||  (userType == 1 && details?.status !==2)) && (((details?.allInstallationCompleted == 1) && empty(details?.installation_acceptance)) || !(details?.status == 4 || details?.status == 3))) &&
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                    {(requestAccept) ? 
                      <Stack alignItems='center' justifyContent='center'>
                        <CircularProgress color="primary" variant="indeterminate" />
                      </Stack> 
                      : 
                      <>
                        {confirmInvoice ? 
                          <Stack alignItems='center' justifyContent='center'>
                            <CircularProgress color="primary" variant="indeterminate" />
                          </Stack>
                        :
                          <Button type="submit" variant="contained"
                            onClick={() => setConfirmInvoice(true)}
                          // onClick={()=>handleAcceptorRejectInvoice(1)}
                          >
                            Approve
                          </Button>}
                          {invoiceReject ? 
                          <Stack alignItems='center' justifyContent='center'>
                            <CircularProgress color="primary" variant="indeterminate" />
                          </Stack>
                        :
                          <>
                            {!empty(details?.installationStatus) ? '' :
                            <Button size="small" variant="outlined"
                              onClick={() => setRejectInvoice(true)}
                              //  onClick={()=>handleAcceptorRejectInvoice(2)}
                              sx={{ color: theme.palette.error.main }} >
                              Reject
                            </Button>}
                          </>
                        }
                      </>
                    }
                  </Box>}
                {/* {(userType ==3&&details?.status!=4&&details?.onboard_complete==0) && 
                <Box sx={{ mt: 3,  display: 'flex', justifyContent: 'flex-end',gap:1 }}>
                  <Button type="submit" variant="contained" 
                   onClick={()=> navigate(PATH_DASHBOARD.official.process,{state:{requestId:id}})}
                  // onClick={()=>handleAcceptorRejectInvoice(1)}
                   >                 
                   Proceed to Workflow
                  </Button>
                 
                </Box>} */}
              </Scrollbar>

            </Grid>
          </Modal>
          {/* delete popup */}
          <ConfirmationPopup
            open={openDelete}
            handleClick={handleDeleteRFQ}
            handleClose={() => { setOpenDelete(!openDelete), setIsOpen(false) }}
            message={'You want to delete this order '}
          />
          <ConfirmationPopup
            open={openDialog}
            handleClick={handleConfirmSubmit}
            handleClose={handleCloseDialog}
            message={'you want to submit this order? The changes will be applied to the job upon client acceptance.'}
          />

          <ConfirmationPopup
            open={openConvertToRFQ}
            handleClick={handleConvertToJob}
            handleClose={() => { setOpenConvertToRFQ(false) }}
            message={'You want to convert this Sales Order to Production'}
          />

          {/* Accept request invoice */}
          <ConfirmationPopup
            open={confirmInvoice}
            handleClick={() => {
              if(details?.allInstallationCompleted==1){
                handleAcceptorRejectInvoice(3);
              }else{
                handleAcceptorRejectInvoice(1);
              }
            }}
            handleClose={() => setConfirmInvoice(false)}
            message={'You want to Approve this invoice '}
          />
          {/* reject request invoice */}
          {/* <ConfirmationPopup 
        open={invoiceReject}
        handleClick={()=>handleAcceptorRejectInvoice(2)}
         handleClose={()=>setRejectInvoice(false)}
         message={'You want to reject this invoice '}
        /> */}
          {/* Reopen rejected request  */}
          <ConfirmationPopup
            open={reOpen}
            handleClick={() => handleReopeningRequest()}
            handleClose={() => setReopen(false)}
            message={'You want to reopen this order '}
          />
          {/* Preview Modal */}
          {openPreview && (
            <Modal
              width={900}
              open={openPreview}
              handleClose={() => {
                setOpenPreview(false);
              }}
              modalTitle={'Preview'}
            >
              <ReactToPrint
                trigger={() => <Button variant="contained" color="primary">Print A3</Button>}

                content={() => printableContentRef.current}
                pageStyle={`@media print {
    .print-black {
      color: black !important;
      background:white !important
    }
    table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 10px;
    }
    * {
      color: black !important;
      background-color: white !important;
    }
    .MuiTableCell-root {
      color: black !important;
    }
    .MuiInputBase-input {
      color: black !important;
    }
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }
    .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
      .enable-for-print {
      pointer-events: auto;
      color: black !important;
    }
      .print-black .enable-for-print input[type='number'],
.print-black .enable-for-print input[type='text'] {
  color: black !important; /* Set the text color to black for input fields */
}
  .css-wr96yv-MuiInputBase-input-MuiOutlinedInput-input {
    
  color: black !important;
  opacity: 1;
    -webkit-text-fill-color:black !important;
  }
    input[type=number]{
    color:black !important}
  }`}
              />
              <div ref={printableContentRef} className="print-black">
                <Grid container sx={{ justifyContent: 'center', }} spacing={3} >
                  <Grid item xs={12} md={12}>
                    <Stack
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                      spacing={2}
                    >
                      <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Typography variant="subtitle2">
                          <span style={{ color: theme.palette.primary.darker }}>Nameeeee: </span>
                          {previewData?.name}
                        </Typography>

                        <Typography variant="subtitle2">
                          <span style={{ color: theme.palette.primary.darker }}>Use custom welcome message: </span>
                          {previewData.welcome_message == null ? 'No' : previewData.welcome_message == false ? 'No' : 'Yes'}
                        </Typography>
                      </Stack>
                      <Stack>
                        <Typography variant="subtitle2" style={{ color: theme.palette.primary.darker }}>
                          Description:
                        </Typography>
                        <Typography variant="subtitle2" style={{ overflowWrap: 'break-word', wordWrap: 'break-word', wordBreak: 'break-word' }}>
                          {previewData?.description}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        display="flex"
                        justifyContent="space-between"
                      >
                        {/* <Typography variant="subtitle2">
                      <span style={{ color:theme.palette.primary.darker,
                        overflowWrap: 'break-word', wordWrap: 'break-word',
                         wordBreak: 'break-word' }}>Skills or services required:</span> <br/>
                          <List>
                           {previewData?.skills?.map((val, index) => (
                           <ListItem sx={{ listStyleType: 'disc', paddingLeft: '20px' }} key={index}>
                           <ListItemText primary={val.name} />
                           </ListItem>
                                   ))}
                                </List>
                    </Typography> */}
                        {/* <Typography variant="subtitle2">
                      <span style={{ color:theme.palette.primary.darker }}>Workflows: </span> <br/>                                           
                      <List>
                           {previewData?.workflow?.map((val, index) => (
                           <ListItem sx={{ listStyleType: 'dics', paddingLeft: '20px' }} key={index}>
                           <ListItemText primary={val.name} />
                           </ListItem>
                                   ))}
                                </List>
                    </Typography> */}
                      </Stack>
                      <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Typography variant="subtitle2">
                          <span style={{ color: theme.palette.primary?.darker }}>Job Class: </span>
                          {previewData?.project_type?.name}
                        </Typography>
                        {/* <Typography variant="subtitle2">
                          <span style={{ color: theme.palette.primary.darker, }}>Markup Type: </span>
                          {previewData?.markup_type?.name}
                        </Typography> */}
                      </Stack>
                      <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Typography variant="subtitle2">
                          <span style={{ color: theme.palette.primary?.darker, }}>Job Type: </span>
                          {previewData?.project_category?.name}
                        </Typography>
                        {previewData?.project_category?.id===13 &&<Typography variant="subtitle2">
                          <span style={{ color: theme.palette.primary?.darker, }}>No Charge Type: </span>
                          {previewData?.no_charge_type?.name}
                        </Typography>}
                        {previewData?.project_category?.id===14 &&<Typography variant="subtitle2">
                          <span style={{ color: theme.palette.primary?.darker, }}>Map Service: </span>
                          {previewData?.map_service?.name}
                        </Typography>}
                      </Stack>

                      <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Typography variant="subtitle2">
                          <span style={{ color: theme.palette.primary.darker }}>Client: </span>
                          {previewData?.clients?.name}
                        </Typography>
                        <Typography variant="subtitle2">
                          <span style={{ color: theme.palette.primary.darker }}>Project: </span>
                          {previewData?.community?.name}
                        </Typography>
                      </Stack>

                      <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Typography variant="subtitle2">
                          <span style={{ color: theme.palette.primary.darker }}>Start Date: </span>
                          {moment.unix(previewData.start).format("MM/DD/YYYY")}
                        </Typography>
                        <Typography variant="subtitle2">
                          <span style={{ color: theme.palette.primary.darker }}>installation Date: </span>
                          {moment.unix(previewData.end).format("MM/DD/YYYY")}
                        </Typography>
                      </Stack>
                      {(previewData?.project_category?.name == 'Service only' || previewData?.project_category?.name == 'Time & Material') ? null : <>
                      <Typography>PRODUCTS</Typography>
                      <Scrollbar>
                        <TableContainer sx={{ minWidth: 500 }}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                {/* {VALUEPreview?.map((val) => (
                                  <TableCell>{val.value}</TableCell>
                                ))} */}
                                {previewData?.project_category?.name == 'Time & Material' ?
                                VALUE_TIME_Preview?.map((val) => (
                                  val.id === 14 ? (
                                    <Tooltip key={val.id} title="Selling Price With Marketing Adjustment">
                                      <TableCell>{val.value}</TableCell>
                                    </Tooltip>) :
                                  <TableCell>{val.value}</TableCell>
                                )) :
                                VALUEPreview?.map((val) => (
                                  val.id === 14 ? (
                                    <Tooltip key={val.id} title="Selling Price With Marketing Adjustment">
                                      <TableCell>{val.value}</TableCell>
                                    </Tooltip>) :
                                  <TableCell>{val.value}</TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {selectedProducts?.sort((a, b) => a.indexId - b.indexId)?.map((data, index) => {
                                const quantityFieldName = `quantity_${data.product_id || data.id}`;
                                const discountFieldName = `discount_${data.product_id || data.id}`;
                                let unitName = UNITS?.filter((item) => item?.id == data?.unit)
                                unitName = unitName[0]?.name
                                let shortUnitName = ''
                                if (unitName == 'Square feet') {
                                  shortUnitName = 'Sq ft'
                                } else if (unitName == 'Lineal feet') {
                                  shortUnitName = 'Lin ft'
                                } else if (unitName == 'Square Inches') {
                                  shortUnitName = 'Sq Inches'
                                } else if (unitName == 'Unit') {
                                  shortUnitName = 'Unit'
                                }
                                const dataPrice = data.product_price || data.price
                                const dataWholesalePrice = data.product_price || data.price
                                const dataQuantity = values[quantityFieldName]
                                const dataDiscount = data.discount ? data.discount : values[discountFieldName]
                                const dataretailPrice = data.product_retail_price || data.retail_price
                                // const markup = ((dataretailPrice - dataPrice) / dataPrice) * 100;
                                const rowTotal = calculateTotal(markupType == 1 ? dataPrice : (markupType == 2 ? dataretailPrice : 0), dataQuantity, dataDiscount, data.quantity, data.locationQuantity, Number(data?.adjustment),data.markAdjType);
                                const invoiceAmount = parseFloat(data?.expected_material_cost) + parseFloat(data?.expected_total_service_cost?.toFixed(2))
                                let markup = markupType ? (invoiceAmount / rowTotal) : null
                                markup = markup ? Number(markup) : 0
                                // data['markup'] = Math.round(markup)
                                console.log("###########Discount", data);
                                return (
                                  <TableRow key={index}>
                                    <TableCell>#{data.indexId}</TableCell>
                                    {/* <TableCell>{data.product_name || data.name}</TableCell> */}
                                    <TableCell width={'25%'} sx={{ flexWrap: 'wrap', overflow: 'hidden' }}>
                                      <Typography variant='body2' sx={{ fontWeight: 'bold', mb: 1, wordBreak: 'break-word', overflowWrap: 'break-word' }}>{data?.product_name || data?.name}</Typography>
                                      {data?.product_notes ? 
                                      <Stack direction={'column'}>
                                        <Typography variant='body2' sx={{textDecoration: 'underline'}}>Note : </Typography> 
                                        <Typography variant='body2' sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>{data?.product_notes}</Typography> 
                                      </Stack>
                                      : ''}
                                    </TableCell>
                                    {/* <TableCell>{invoiceAmount}</TableCell> */}

                                    {previewData?.project_category?.name == 'Time & Material' ? 
                                    <TableCell>{dataretailPrice || dataPrice || 0}</TableCell>
                                  : <>
                                    <TableCell>{(hasMarkupPermission && markupType == 2) ? dataretailPrice : null}</TableCell>
                                    <TableCell>{(hasMarkupPermission && markupType == 1) ? dataPrice : null}</TableCell>
                                  </>}
                                  <TableCell>{hasMarkupPermission ? (data.SPMA || null) : null}</TableCell>
                                  {/* {previewData?.project_category?.name == 'Time & Material' ? null :  */}
                                    {/* // <TableCell> */}
                                      {/* {hasMarkupPermission ? (data.markup ? data.markup?.toFixed(2) : 0) : null} */}
                                      {/* previos */}
                                      {/* {(hasMarkupPermission && markupType == 1) ? data.wholesaleMarkup?.toFixed(2)
                                        : (hasMarkupPermission && markupType == 2) ? data.retailMarkup?.toFixed(2)
                                          : null}
                                    </TableCell>} */}
                                    {/* <TableCell>{shortUnitName}</TableCell> */}
                                    <TableCell>
                                      {/* <TextField required
                                        fullWidth
                                        type='number'
                                        
                                        sx={{ width: 85 }}
                                       
                                        label={shortUnitName}
                                        value={Number(data.quantity)}
                                        defaultValue={1}
                                      /> */}
                                      {Number(data.quantity)} {shortUnitName}
                                    </TableCell>
                                    <TableCell>
                                      {/* <TextField required
                                        fullWidth
                                        type='number'
                                         
                                       
                                      
                                        sx={{ width: 85 }}
                                        label={'Qty'}
                                        value={Number(data.locationQuantity)}
                                        defaultValue={1}
                                      /> */}
                                      {Number(data.locationQuantity)}
                                    </TableCell>
                                    {/* <TableCell>{data.id}</TableCell> */}
                                    <TableCell align="center">
                                      {/* {Number(data.discount)?.toFixed(2)} */}
                                      {data.markAdjType === 3 ? "¢" : data.markAdjType === 2 ? "$" : "%"} {data?.adjustment ? Number(data?.adjustment)?.toFixed(2) : 0}
                                    </TableCell>
                                    <TableCell>
                                      {rowTotal?.toFixed(2)}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}

                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Scrollbar>
                      </>}
                      <LabelStyle>SERVICES</LabelStyle>
                      <Scrollbar>
                        <TableContainer sx={{ minWidth: 500 }}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                {VALUE_SERVICES?.map((val) => (
                                  ((((previewData?.project_category?.name == 'Service only') || (previewData?.project_category?.name == 'Time & Material')) && val.id === 0) || (val.id === 6)) ? null : (
                                    <TableCell>{val.value}</TableCell>
                                  )
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {selectedServices?.map((data, index) => {
                                const dataCost = data.unit_cost || data.total_cost
                                let originalMarkup = data.originalMarkup || 0
                                let markup = data.markup || 0
                                const total = dataCost * data.quantity
                                data['total'] = total?.toFixed(2)
                                console.log("###########data total", data);
                                const newQty = (data.prQty * (data.qtyData || 1) * data.locationQuantity)?.toFixed(2)
                                const quantityDifference = data.quantity - newQty;
                                data['quantityDifference'] = quantityDifference
                                return (
                                  <>
                                    <TableRow key={index}>
                                      <TableCell>#{data?.productIndex}</TableCell>
                                      <TableCell>{data.name}</TableCell>
                                      <TableCell>{originalMarkup?.toFixed(2)}</TableCell>
                                      <TableCell>{data.cost}</TableCell>
                                      <TableCell>{dataCost}</TableCell>
                                      <TableCell>{data.quantity}</TableCell>
                                      <TableCell>{(data.cost*quantityDifference?.toFixed(2))?.toFixed(2)}</TableCell>
                                      <TableCell>{(data.unit_cost * quantityDifference?.toFixed(2))?.toFixed(2)}</TableCell>
                                      <TableCell>{data.total}</TableCell>
                                      {/* <TableCell></TableCell> */}
                                      {/* <TableCell align='right'>
                                      </TableCell> */}
                                    </TableRow>
                                  </>
                                );
                              })}
                              {additionalSelectedServices?.map((data, index) => {
                                const dataCost = data.unit_cost || data.total_cost
                                const total = dataCost * (data?.quantity || 1)
                                data['total'] = total ? total?.toFixed(2) : 0.00
                                let originalMarkup = data.originalMarkup || 0
                                console.log("###########data total", data);
                                const quantityDifference = Number(data.quantity)
                                data['quantityDifference'] = quantityDifference
                                return (
                                  <TableRow key={data.id}>
                                    {(previewData?.project_category?.name == 'Service only' || previewData?.project_category?.name == 'Time & Material') ? null : <TableCell></TableCell>}
                                    <TableCell>{data.name}</TableCell>
                                    <TableCell>{originalMarkup?.toFixed(2)}</TableCell>
                                    <TableCell>{data.cost}</TableCell>
                                    <TableCell>{data.unit_cost || data.total_cost}</TableCell>
                                    <TableCell>{previewData?.project_category?.name == 'Time & Material' ? 0 : data?.quantity || 1}</TableCell>
                                    <TableCell>{(data.cost*quantityDifference?.toFixed(2))?.toFixed(2)}</TableCell>
                                    <TableCell>{data.total}</TableCell>
                                    <TableCell>{data.total}</TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Scrollbar>
                      
                      <Divider />
                      { (cart?.length > 0 || cartService?.length > 0 ) ? <>
                      <Typography>Proposals</Typography>
                      <Scrollbar>
                        <TableContainer sx={{ minWidth: 500}}>
                        {/* <TableContainer sx={{ minWidth: 500, whiteSpace: 'nowrap' }}> */}
                          <Table>
                            <TableHead>
                              <TableRow>

                                {VALUE_RFP_Product?.map((val) => (

                                  <TableCell key={val.id}>{val.value}</TableCell>

                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody >
                              {cart?.map((data, index) => {
                                let unitName = UNITS?.filter((item) => item?.id == data?.unit)
                                unitName = unitName[0]?.name
                                let shortUnitName = ''
                                if (unitName == 'Square feet') {
                                  shortUnitName = 'Sq ft'
                                } else if (unitName == 'Lineal feet') {
                                  shortUnitName = 'Lin ft'
                                } else if (unitName == 'Square Inches') {
                                  shortUnitName = 'Sq Inches'
                                } else if (unitName == 'Unit') {
                                  shortUnitName = 'Unit'
                                }
                                let markup = markupType ? markupType === 1 ? data.WholesaleMarkup : data.RetailMarkup : 0
                                return (
                                  <TableRow key={index}>
                                    <TableCell>{data.name || data.product_name}</TableCell>
                                    {/* <TableCell>{invoiceAmount}</TableCell> */}
                                    <TableCell>
                                      {markup?.toFixed(2) || 0.00}
                                    </TableCell>
                                    <TableCell>
                                      {data.product_price?.toFixed(2) || 0}
                                    </TableCell>
                                    <TableCell>
                                      {(data.product_price + (data.product_price * markup / 100)).toFixed(2) || 0.00}
                                    </TableCell>
                                    <TableCell>
                                      {data.quantity} {shortUnitName}
                                    </TableCell>
                                    <TableCell>
                                      {data.location_quantity}
                                    </TableCell>
                                    <TableCell>
                                            {(data.product_price * data.quantity * data.location_quantity)?.toFixed(2)||0}
                                    </TableCell>
                                    <TableCell>
                                      {(data.location_quantity * data.quantity * ((data.product_price + (data.product_price * markup / 100)).toFixed(2) || 0)).toFixed(2)}
                                    </TableCell>
                                   
                                  </TableRow>
                                );
                              })}

                            </TableBody>
                            {cartService?.length > 0 ? <h6>Services</h6> : null}
                            <TableBody>
                              {cartService?.map((data, index) => {


                                return (
                                  <>
                                    <TableRow key={data.id}>
                                      <TableCell>{data.name || data.product_name}</TableCell>
                                      {/* <TableCell>{invoiceAmount}</TableCell> */}
                                      <TableCell>
                                        {data.originalMarkup?.toFixed(2)}
                                      </TableCell>
                                      <TableCell>
                                        {data.sellingPrice?.toFixed(2)}
                                      </TableCell>
                                      <TableCell>
                                        {((data.sellingPrice + (data.sellingPrice * data.originalMarkup / 100)))?.toFixed(2)}
                                      </TableCell>
                                      <TableCell>
                                        {data.qty} Hours
                                      </TableCell>
                                      <TableCell></TableCell>
                                      <TableCell>
                                            {(data.qty*data.sellingPrice).toFixed(2)||0}
                                      </TableCell>
                                      <TableCell>
                                        {(data.qty * (data.sellingPrice + (data.sellingPrice * data.originalMarkup / 100)))?.toFixed(2)}
                                      </TableCell>
                                    
                                    </TableRow>

                                  </>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Scrollbar>
                      </> : null}
                      {selectedMaterials?.length > 0 ? <>
                      <Typography>MATERIALS</Typography>
                      <Scrollbar>
                            <TableContainer sx={{ minWidth: 500 }}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    {VALUE_MATERIAL?.map((val) => (
                                      val.id === 3 ? (
                                        <Tooltip key={val.id} title="Unit Price + ((Unit Price * Markup)/100)">
                                          <TableCell key={val.id}>{val.value}</TableCell>
                                        </Tooltip>
                                      ) : val.id === 5 ? (
                                        <Tooltip key={val.id} title="Unit Price * Quantity">
                                          <TableCell key={val.id}>{val.value}</TableCell>
                                        </Tooltip>
                                      ) : val.id === 6 ? (
                                        <Tooltip key={val.id} title="Markup Cost * Quantity">
                                          <TableCell key={val.id}>{val.value}</TableCell>
                                        </Tooltip>
                                      ) :
                                      <TableCell key={val.id}>{val.value}</TableCell>
                                     
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {selectedMaterials?.map((data, index) => {
                                    console.log("################Materials",data);
                                    let unitName = UNITS?.filter((item) => item?.id == data?.unit)
                                    unitName = unitName[0]?.name
                                    let shortUnitName = ''
                                    if (unitName == 'Square feet') {
                                      shortUnitName = 'Sq ft'
                                    } else if (unitName == 'Lineal feet') {
                                      shortUnitName = 'Lin ft'
                                    } else if (unitName == 'Square Inches') {
                                      shortUnitName = 'Sq Inches'
                                    } else if (unitName == 'Unit') {
                                      shortUnitName = 'Unit'
                                    }
                                    data.price=data.price ? data.price : data.product_price || 0 
                                    data.product_id= data.product_id ? data.product_id : data.id
                                    data.markup = data.markup ? data.markup : data.product_markup
                                    const markUpCost = data.price + ((parseFloat(data.price) * parseFloat(data.markup || 0)) / 100)
                                    data['markUpCost'] = parseFloat(parseFloat(markUpCost).toFixed(2)) || 0;
                                    return (
                                      <>
                                        <TableRow key={index}>
                                         
                                         <TableCell>{data.name || data.product_name}{data.inhouse==1 ? ' (Inhouse)' : ' (Client owned)'}</TableCell>
                                         <TableCell>{data.price}</TableCell>
                                         <TableCell>{data.markup || 0}</TableCell>
                                         <TableCell>{data.markUpCost || 0}</TableCell>
                                         <TableCell>{data.quantity || 1} {shortUnitName}</TableCell>
                                         <TableCell>{(data.price * (data.quantity || 1))?.toFixed(2)}</TableCell>
                                         <TableCell>{((data.markUpCost || 0) * (data.quantity || 1))?.toFixed(2)}</TableCell>
                                          <TableCell >
                                          </TableCell>
                                        </TableRow>
                                        
                                      </>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Scrollbar>
                          <Divider />
                          </> : null}
                      <Stack>
                        <Typography variant="subtitle2" style={{ color: theme.palette.primary.darker }}>
                          Notes:
                        </Typography>
                        <Typography variant="subtitle2" style={{ overflowWrap: 'break-word', wordWrap: 'break-word', wordBreak: 'break-word' }}>
                          <Scrollbar
                            sx={{
                              maxHeight: 200,
                              '& .simplebar-content': {
                                maxHeight: 200,
                                display: 'flex',
                                flexDirection: 'column',
                              },
                            }}
                          >{previewData?.notes}</Scrollbar>

                        </Typography>
                      </Stack>
                      <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Typography variant="subtitle2">
                          <span style={{ color:noCharge? 'red' : theme.palette.primary.darker }}>Grand Total: </span>
                          <span style={{ color:noCharge? 'red' : theme.palette.primary.darker }}> 
                            {grandTotal ? parseFloat((noCharge && grandTotal >0) ? (grandTotal * -1) : grandTotal)?.toFixed(2) : 0.00} </span>
                        </Typography>

                        <Tooltip title="Sub Total - Total Product Formula Cost">
                          <Typography variant="subtitle2" sx={{ cursor: 'pointer' }}>
                            <span style={{ color: theme.palette.primary.darker }}>Total Markup Amount: </span>
                            {totalMarkupAmount ? parseFloat(Number(totalMarkupAmount))?.toFixed(2) : 0}
                          </Typography>
                        </Tooltip>

                        <Tooltip title="(Total Markup Amount / Total Product Formula Cost) * 100">
                          <Typography variant="subtitle2" sx={{ cursor: 'pointer' }}>
                            <span style={{ color: theme.palette.primary.darker }}>Total Markup Percentage: </span>
                            {totalMarkupPercentage ? parseFloat(Number(totalMarkupPercentage))?.toFixed(2) : 0}
                            {/* {totalMarkup ? totalMarkup?.toFixed(2) : 0} */}
                          </Typography>
                        </Tooltip>

                        {/* <Typography variant="subtitle2">
                      <span style={{ color:theme.palette.primary.darker }}>Total Service Markup: </span>
                      {totalServiceMarkup ? totalServiceMarkup?.toFixed(2) : 0}
                    </Typography> */}
                        <Typography variant="subtitle2">
                          <span style={{ color: theme.palette.primary.darker }}>Total Discount: </span>
                          {DiscountTotal ? DiscountTotal?.toFixed(2) : 0.00}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Typography variant="subtitle2">
                          <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            color={getStatusColorAndLabel(details?.status,details?.installationStatus,details?.allInstallationCompleted,details?.installation_acceptance,details?.min_payment_status,details?.job_category_name,details?.billing_type,details?.installationCompleteStatus).color} >
                            {getStatusColorAndLabel(details?.status,details?.installationStatus,details?.allInstallationCompleted,details?.installation_acceptance,details?.min_payment_status,details?.job_category_name,details?.billing_type,details?.installationCompleteStatus).label}
                          </Label>
                        </Typography>
                      </Stack>

                    </Stack>
                  </Grid>
                </Grid>
              </div>
            </Modal>
          )}
          {/* reject request invoice */}
          {invoiceReject == true && <Dialog
            open={invoiceReject}
            onClose={() => { setRejectInvoice(false) }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            BackdropComponent={Backdrop}
            BackdropProps={{ invisible: true }}
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you sure ?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                You want to reject this invoice
              </DialogContentText>
              <TextField
                sx={{ width: 400 }}
                label="Reason For Rejection"
                type="text"
                multiline
                minRows={3}
                maxRows={7}
                onInput={(event) => setReason(event.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button size="small" variant="contained"
                onClick={() => handleAcceptorRejectInvoice(2)}
                sx={{ color: theme.palette.background.paper }}>OK</Button>

              <Button size="small" variant="outlined"
                onClick={() => { setRejectInvoice(false), setReason('') }}
                sx={{ color: theme.palette.error.main }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>}
          {/* reject request invoice */}

        </Container>
      }
    </Page >
  )
}

const PrintFunction = forwardRef(({ invoiceDate, details, additionalSelectedServices, invoice, subTotal, cartService, openInvoice, invoiceSelectedServices, data,userType,labourInstallService }, ref) => {
  const theme = useTheme();
  const { COMPANY_LOGO, COMPANY_NAME } = useCompanyProfile()
  return (
    <Stack ref={ref}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} sx={{ mb: 5, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Box component="img" alt="logo" src={COMPANY_LOGO} id="logoImage" sx={{ height: 98, alignSelf: 'flex-start' }} />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ mb: 5, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          {/* <Typography variant="h1">Invoice from:</Typography> */}
          <Typography variant="h2">{COMPANY_NAME}</Typography>
          <Typography variant="h2">www.{COMPANY_NAME}.com</Typography>
          <Typography variant="h3">
            {data?.address}<br /> {data?.city}<br /> {data?.state}, {data?.zipcode}
          </Typography>
          <Typography variant="h3">Telephone: +15208362947</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>

        <Grid id="total1" item xs={12} sm={6} >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Box id="order" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography id="discount1" variant="h3" style={{ textAlign: 'left' }}>Order date:</Typography>
              <Typography variant="h3">{invoiceDate}</Typography>
            </Box>
            <Box id="title" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', maxWidth:250 }}>
              <Typography id="discount1" variant="h3" style={{ textAlign: 'left' }}>Title:</Typography>
              <Typography variant="h3">{details?.name}</Typography>
            </Box>
            <Box id="orderID" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography id="discount" variant="h3" style={{ textAlign: 'left' }}>Order ID:</Typography>
              <Typography variant="h3">{details?.id}</Typography>
            </Box>
            <Box id="to" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography id="totalmain1" variant="h3" >To:</Typography>
              <Typography variant="h3" style={{ textAlign: 'right' }}>{invoice?.customer_name}</Typography>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography id="totalmain1" variant="h3" >Phone:</Typography>
              <Typography variant="h3" style={{ textAlign: 'right' }}>{invoice?.customer_phone}</Typography>
            </Box>
            {(details?.job_category_name == 'Time & Material') ? 
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: 1,
                  borderColor: 'black',
                }}
              >
                <Typography id="totalmain1" variant="h3" >{details?.job_category_name}</Typography>
              </Box> 
            : ''}

          </Box>
        </Grid>
        {((details?.job_category_name == 'Time & Material') && (details?.allInstallationCompleted == 1)) ? 
          <>
            <Scrollbar>
              {(!empty(invoice?.jobcost_product)) ? 
              <>
              <Typography variant='h4' sx={{ mt: 2 }}>Materials</Typography> 
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>NAME</TableCell>
                      <TableCell>UNIT COST</TableCell>
                      <TableCell>QUANTITY</TableCell>
                      <TableCell>TOTAL COST</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoice?.jobcost_product?.map((val,index) => {
                      const { id, product_name, unit_price, quantity, total, unit, unit_qty, partial_unit } = val
                      let unitToUse = partial_unit ? partial_unit : unit
                      let unitName = UNITS?.filter((item) => item?.id == unitToUse)
                      let shortUnitName = unitName[0]?.short_name || 'Unit'
                      unitName = unitName[0]?.name
                      return (
                        <TableRow sx={{
                          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                        }}>
                          <TableCell colSpan={2}>{product_name}</TableCell>
                          <TableCell> {unit_price}</TableCell>
                          <TableCell>{quantity}({shortUnitName})</TableCell>
                          <TableCell> {total}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              </>
              : ''}
              {invoice?.jobcost_service?.length > 0 ? <Typography variant='h4' sx={{ mt: 2 }}>Services</Typography> : ''}
              <TableContainer>
                  <Table>
                  {invoice?.jobcost_service?.length > 0 ? 
                  <>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2}>NAME</TableCell>
                        <TableCell>HOURLY COST</TableCell>
                        <TableCell>HOURS</TableCell>
                        <TableCell>TOTAL COST</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoice?.jobcost_service?.map((ser,index) => {
                        return (
                          <TableRow sx={{
                            borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                          }}>
                            <TableCell colSpan={2}>{ser?.name}</TableCell>
                            <TableCell>{ser ? parseFloat(ser?.service_original_cost)?.toFixed(2) : 0}</TableCell>
                            <TableCell>{ser ? parseFloat(ser?.jobcost_quantity)?.toFixed(2) : 0}</TableCell>
                            <TableCell>{ser ? ser?.total_service_cost : 0}</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </> : ''}
                </Table>
              </TableContainer>
            </Scrollbar>
            <Grid id="total" item xs={12} sm={6} >
              <Box sx={{ mb: 5, display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '8px' }}>
              {(details?.job_category_name != 'Service only' || invoice?.jobcostTotal > 0) &&
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography id="subtotal" variant="h2" style={{ textAlign: 'left', margin: '4px', padding: 0 }}>Subtotal</Typography>
                <Typography variant="h2" style={{ textAlign: 'right', margin: '4px', padding: 0 }}>{invoice?.jobcostTotal ? invoice?.jobcostTotal?.toFixed(2) : 0.00}</Typography>
              </Box>}
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography id="subtotal" variant="h2" style={{ textAlign: 'left', margin: '4px', padding: 0 }}>Services</Typography>
                <Typography variant="h2" style={{ textAlign: 'right', margin: '4px', padding: 0 }}>{invoice?.jobcostServiceTotal ? invoice?.jobcostServiceTotal?.toFixed(2) : 0.00}</Typography>
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography id="subtotal" variant="h2" style={{ textAlign: 'left', margin: '4px', padding: 0 }}>Discount</Typography>
                <Typography variant="h2" style={{ textAlign: 'right', margin: '4px', padding: 0 }}>{invoice?.extra_discount ? invoice?.extra_discount?.toFixed(2) : 0.00}</Typography>
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography id="subtotal" variant="h2" style={{ textAlign: 'left', margin: '4px', padding: 0 }}>Tax</Typography>
                <Typography variant="h2" style={{ textAlign: 'right', margin: '4px', padding: 0 }}>{invoice?.tax_amount ? invoice?.tax_amount?.toFixed(2) : 0.00}</Typography>
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography id="subtotal" variant="h2" style={{ textAlign: 'left', margin: '4px', padding: 0 }}>Total</Typography>
                <Typography variant="h2" style={{ textAlign: 'right', margin: '4px', padding: 0 }}>{invoice?.grandTotal ? invoice?.grandTotal?.toFixed(2) : 0.00}</Typography>
              </Box>
              {invoice?.project_category == 13 ? '' : 
              <>
              {
              (userType !=3 && details?.job_category_name != 'Time & Material' && details?.job_category_name != 'No Charge') ?
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography id="subtotal" variant="h2" style={{ textAlign: 'left', margin: '4px', padding: 0 }}>Billing Type</Typography>
                <Typography variant="h2" style={{ textAlign: 'right', margin: '4px', padding: 0 }}>{details?.billing_type ? `${details?.billing_type} %` :"Nill"}</Typography>
              </Box>
              :""}
{
                         (invoice?.total && details?.billing_type)?
               <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography id="subtotal" variant="h2" style={{ textAlign: 'left', margin: '4px', padding: 0 }}>Minimum Payable Amount</Typography>
                <Typography variant="h2" style={{ textAlign: 'right', margin: '4px', padding: 0 }}>{(invoice?.total && details?.billing_type)? parseFloat((invoice?.total *details?.billing_type) / 100).toFixed(2) :0.00}</Typography>
              </Box>
              :""}

              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography id="subtotal" variant="h2" style={{ textAlign: 'left', margin: '4px', padding: 0 }}>Amount Paid</Typography>
                <Typography variant="h2" style={{ textAlign: 'right', margin: '4px', padding: 0 }}>{details?.totalPaidAmount ? details?.totalPaidAmount?.toFixed(2) : 0.00}</Typography>
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography id="subtotal" variant="h2" style={{ textAlign: 'left', margin: '4px', padding: 0 }}>Balance Due</Typography>
                <Typography variant="h2" style={{ textAlign: 'right', margin: '4px', padding: 0 }}>{invoice?.newBalanceDue ? invoice?.newBalanceDue?.toFixed(2) : 0.00}</Typography>
              </Box>
              </>
              }
              </Box>
            </Grid>
            {(details?.allInstallationCompleted == 1 && !empty(invoice?.note_data)) ? (
              <>
                <Typography variant="h6" sx={{ mt: 2, mb: 1, fontWeight: 'bold' }}>Installation Notes</Typography>
                <Stack spacing={2}>
                  {invoice?.note_data?.map((val, index) => {
                    const { id, authorized_by, note, timestamp } = val;
                    return (
                      <Paper
                        key={id}
                        elevation={3}
                        sx={{ padding: 2, borderRadius: 2, backgroundColor: 'rgba(145, 158, 171, 0.16)', color: '#ffffff' }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={8}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                              {note}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                            {/* <Typography variant="body2" sx={{ color: 'primary.light' }}> */}
                            <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
                              Added By: {authorized_by}
                            </Typography>
                            <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
                              {moment.unix(timestamp).format('MM-DD-YYYY hh:mm:ss A')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    );
                  })}
                </Stack>
              </>
            ) : ''}
          </> :
          <>
        <Scrollbar>
          {(invoice?.cust_product?.length > 0) ? <>
            <TableContainer sx={{ minWidth: 420 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{details?.job_category_name != 'Time & Material' ? 'PRODUCT & NOTE' : 'MATERIAL'}</TableCell>
                    <TableCell>UNIT PRICE</TableCell>
                    <TableCell>UNIT</TableCell>
                    <TableCell>QTY</TableCell>
                    {/* <TableCell>MARKETING ADJUSTMENT (%)</TableCell> */}
                    <TableCell>SUB TOTAL</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoice?.cust_product?.map((val) => {
                    console.log('invoice val',val);
                    const { vid, product_name,product_notes, unit_qty, adjustment, product_price, material, rfp, product_retail_price, quantity, product_total, discount, unit, locationQuantity,markAdjType,services = []} = val;
                    const labourService = services.find(service => service.labour_cost_service_id === 10);
                    const minusAmountLabourInstall = labourService?.total_cost || 0;

                    let SPMAAmount = 0
                    if(markAdjType==2 || markAdjType==3) {
                      SPMAAmount = (product_retail_price || 0) + (adjustment ? Number(adjustment) : 0)
                    }else{
                      SPMAAmount = (product_retail_price || 0) + (((product_retail_price || 0) * (adjustment ? Number(adjustment) : 0)) / 100)

                    }
                    SPMAAmount = parseFloat(SPMAAmount)?.toFixed(2)  


                    let theSubTotalAmount =markAdjType==1?product_total: (SPMAAmount * (locationQuantity * quantity))
                    subTotal += theSubTotalAmount;
                    let unitName = UNITS?.filter((item) => item?.id == unit)
                    unitName = unitName[0]?.name
                    let shortUnitName = ''
                    if (unitName == 'Square feet') {
                      // shortUnitName = unit_qty ? 'Sq ft ('+unit_qty+')' : 'Sq ft'?
                      shortUnitName = 'Sq ft'
                    } else if (unitName == 'Lineal feet') {
                      // shortUnitName = unit_qty ? 'Lin ft ('+unit_qty+')' : 'Lin ft'
                      shortUnitName = 'Lin ft'
                    } else if (unitName == 'Square Inches') {
                      // shortUnitName = unit_qty ? 'Sq Inches ('+unit_qty+')' : 'Sq Inches'
                      shortUnitName = 'Sq Inches'
                    } else {
                      shortUnitName = 'Unit'
                    }
                    let SPMA = 0
                    if(markAdjType==2 || markAdjType==3){
                      SPMA = (product_retail_price || 0) +  (adjustment ? Number(adjustment) : 0)
      
                    }else{

                      SPMA = (product_retail_price || 0) + (((product_retail_price || 0) * (adjustment ? Number(adjustment) : 0)) / 100)
                    }
                    SPMA = parseFloat(SPMA)?.toFixed(2)  
                    return (
                      <TableRow key={vid} 
                      sx={{
                        borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                        '& .productName': {
                          fontWeight: 'bold',
                        },
                      }}
                      >
                        {/* <TableCell>{product_name}</TableCell> */}
                        <TableCell width={'25%'} sx={{flexWrap:'wrap'}}>
                          <Typography variant="h4" className="productName">{product_name}</Typography>
                          {product_notes ? <Typography variant='subtitle2'>Note : {product_notes}</Typography> : ''}
                          {(material === 1 && details?.job_category_name != 'Time & Material') ? <Typography variant='subtitle2'>(Addl. Material)</Typography> : ''}
                        </TableCell>
                        <TableCell variant="h4">{SPMA ? SPMA : 0.00}  {minusAmountLabourInstall && minusAmountLabourInstall > 0? "(Included Labor Install)" :""}</TableCell>
                        <TableCell variant="h4">{quantity}({shortUnitName})</TableCell>
                        <TableCell variant="h4">{locationQuantity}</TableCell>
                        {/* <TableCell variant="h4">{discount ? discount?.toFixed(2) : 0.00}</TableCell> */}
                        {/* <TableCell variant="h4">{(material === 1 || rfp === 1) ? 0.00 : adjustment ? Number(adjustment)?.toFixed(2) : 0.00}</TableCell> */}
                        

                        {markAdjType==1?
                            <TableCell>{product_total ? (product_total - minusAmountLabourInstall)?.toFixed(2) : 0.00}</TableCell>
                          :
                          <TableCell>{((SPMA * (locationQuantity * quantity)) - minusAmountLabourInstall).toFixed(2)}</TableCell>

                          }
                        
                        {/* <TableCell variant="h4">{product_total ? product_total?.toFixed(2) : 0.00}</TableCell> */}
                      </TableRow>
                    )
                  }
                  )}

                </TableBody>
              </Table>
            </TableContainer>
          </> : null}

          {/*below for labour Install */}

                  <TableContainer sx={{ minWidth: 420, mt: 3 }}>
                    <Table>
                      {(labourInstallService?.length > 0 ) &&
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>Services(Labor Install) NAME</TableCell>
                            <TableCell>COST (HOURLY)</TableCell>
                            <TableCell>HOURS</TableCell>
                            <TableCell>TOTAL COST</TableCell>
                          </TableRow>
                        </TableHead>}
                      <TableBody>
                      
                      {labourInstallService?.map((ser) => {
                          console.log('invoiceSelectedServices--@@ 1', ser);
                          return (
                            <TableRow sx={{
                              borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                            }}>
                              <TableCell colSpan={2}>{ser?.name}-({ser.productName})</TableCell>
                              <TableCell>{ser ? ser?.unit_cost?.toFixed(2) : 0}</TableCell>
                              <TableCell>{ser ? ser?.quantity?.toFixed(2) : 0}</TableCell>
                              <TableCell>{ser ? ser?.total_cost?.toFixed(2) : 0}</TableCell>
                            </TableRow>
                          )
                        })}
                        </TableBody>
                        </Table>
                        </TableContainer>

{/*above  for labour Install */}

          {(additionalSelectedServices?.length > 0 || invoiceSelectedServices?.length > 0 || cartService?.length > 0) && <Typography variant='h4' sx={{ mt: 2 }}>{(details?.job_category_name != 'Service only' && details?.job_category_name != 'Time & Material') ? "Additional Services" : 'Services'}</Typography>}
          <TableContainer sx={{ minWidth: 420, mt: 3 }}>
            <Table>
              {(additionalSelectedServices?.length > 0 || invoiceSelectedServices?.length > 0 || cartService?.length > 0) &&
                <TableHead>
                  <TableRow>
                    <TableCell>NAME</TableCell>
                    <TableCell>COST (HOURLY)</TableCell>
                    <TableCell>HOURS</TableCell>
                    <TableCell>TOTAL COST</TableCell>
                  </TableRow>
                </TableHead>}
              <TableBody>
                {openInvoice && invoiceSelectedServices?.map((ser) => {
                  return (
                    <TableRow sx={{
                      borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                    }}>
                      <TableCell>{ser?.name}</TableCell>
                      <TableCell>{ser ? ser?.unit_cost?.toFixed(2) : 0}</TableCell>
                      <TableCell>{ser ? ser?.quantity?.toFixed(2) : 0}</TableCell>
                      <TableCell>{ser ? ser?.total_cost?.toFixed(2) : 0}</TableCell>
                    </TableRow>
                  )
                }
                )}
                {openInvoice && additionalSelectedServices
                ?.filter(servItem=>{
                  if (details?.job_category_name != 'Time & Material' && servItem.labour_cost_service_id == 10) {
                    return false;
                  }
                  return true;
                  })
                ?.map((ser) => {
                  console.log('additionalSelectedServices--', ser);
                  return (
                    <TableRow sx={{
                      borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                    }}>
                      <TableCell>{ser?.name}</TableCell>
                      <TableCell>{ser ? ser?.unit_cost?.toFixed(2) : 0}</TableCell>
                      <TableCell>{ser ? ser?.quantity?.toFixed(2) : 0}</TableCell>
                      <TableCell>{ser ? ser?.total_cost?.toFixed(2) : 0}</TableCell>
                    </TableRow>
                  )
                }
                )}
                {openInvoice && cartService?.map((ser) => {
                  console.log('cartService--', ser);
                  ser.sellingPrice = ser.sellingPrice || ser.unit_cost
                  ser.originalMarkup = ser.originalMarkup || ser.product_markup
                  return (
                    <TableRow sx={{
                      borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                    }}>
                      <TableCell>{ser?.name}</TableCell>
                      <TableCell> {((ser.sellingPrice + (ser.sellingPrice * ser.originalMarkup / 100)))?.toFixed(2)}</TableCell>
                      <TableCell>{ser ? ser?.qty?.toFixed(2) : 0}</TableCell>
                      <TableCell> {(ser.qty * (ser.sellingPrice + (ser.sellingPrice * ser.originalMarkup / 100)))?.toFixed(2)}</TableCell>
                    </TableRow>
                  )
                }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Grid id="total" item xs={12} sm={6} >
          <Box sx={{ mb: 5, display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '8px' }}>
            {(details?.job_category_name != 'Service only' || subTotal > 0) &&
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography id="subtotal" variant="h2" style={{ textAlign: 'left', margin: '4px', padding: 0 }}>Subtotal</Typography>
              <Typography variant="h2" style={{ textAlign: 'right', margin: '4px', padding: 0 }}>{subTotal ? subTotal?.toFixed(2) : 0.00}</Typography>
            </Box>}
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography id="discount" variant="h2" style={{ textAlign: 'left', margin: '4px', padding: 0 }}> {(details?.job_category_name != 'Service only' && details?.job_category_name != 'Time & Material') ? 'Additional Services' : 'Services'}</Typography>
              <Typography variant="h2" style={{ textAlign: 'right', margin: '4px', padding: 0 }}>{details ? details?.labour_cost?.toFixed(2) : 0.00}</Typography>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography id="discount" variant="h2" style={{ textAlign: 'left', margin: '4px', padding: 0 }}>Discount</Typography>
              <Typography variant="h2" style={{ textAlign: 'right', margin: '4px', padding: 0 }}>{invoice ? invoice?.extra_discount?.toFixed(2) : 0.00}</Typography>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography id="discount" variant="h2" style={{ textAlign: 'left', margin: '4px', padding: 0 }}>Tax</Typography>
              <Typography variant="h2" style={{ textAlign: 'right', margin: '4px', padding: 0 }}>{invoice ? invoice?.tax_amount?.toFixed(2) : 0.00}</Typography>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography id="totalmain" variant="h2" style={{ textAlign: 'left', margin: '4px', padding: 0 }}>Total</Typography>
              <Typography variant="h2" style={{ textAlign: 'right', margin: '4px', padding: 0 }}>{invoice?.total ? invoice?.total?.toFixed(2) : 0.00}</Typography>
            </Box>
            {invoice?.project_category == 13 ? '' : 
              <>
              {
              (userType !=3 && details?.job_category_name != 'Time & Material' && details?.job_category_name != 'No Charge') ?
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography id="subtotal" variant="h2" style={{ textAlign: 'left', margin: '4px', padding: 0 }}>Billing Type</Typography>
                <Typography variant="h2" style={{ textAlign: 'right', margin: '4px', padding: 0 }}>{details?.billing_type ? `${details?.billing_type} %` :"Nill"}</Typography>
              </Box>
              :""}
{
                         (invoice?.total && details?.billing_type)?
               <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
               
                <Typography id="subtotal" variant="h2" style={{ textAlign: 'left', margin: '4px', padding: 0 }}>Minimum Payable Amount</Typography>
               
                <Typography variant="h2" style={{ textAlign: 'right', margin: '4px', padding: 0 }}>{(invoice?.total && details?.billing_type)? parseFloat((invoice?.total *details?.billing_type) / 100).toFixed(2) :0.00}</Typography>
              </Box>
              :""}
              
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography id="totalmain" variant="h2" style={{ textAlign: 'left', margin: '4px', padding: 0 }}>Amount Paid</Typography>
                  <Typography variant="h2" style={{ textAlign: 'right', margin: '4px', padding: 0 }}>{details?.totalPaidAmount ? details?.totalPaidAmount?.toFixed(2) : 0.00}</Typography>
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography id="totalmain" variant="h2" style={{ textAlign: 'left', margin: '4px', padding: 0 }}>Balance Due</Typography>
                  <Typography variant="h2" style={{ textAlign: 'right', margin: '4px', padding: 0 }}>{details?.dueAmount ? details?.dueAmount?.toFixed(2) : 0.00}</Typography>
                </Box>
              </>
            }
          </Box>
        </Grid>
        {/* {((details?.allInstallationCompleted == 1) && (!empty(invoice?.jobcost_product) || !empty(invoice?.jobcost_service?.filter(it=>it.total_cost == 0)))) ?
          <>
            <Typography id="discount" variant="h2" style={{ textAlign: 'left' }}>Additional Amount Details</Typography>
            {!empty(invoice?.jobcost_product) ? 
            <TableContainer sx={{ minWidth: 420 }}>
              <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>NAME</TableCell>
                      <TableCell>UNIT COST</TableCell>
                      <TableCell>QUANTITY</TableCell>
                      <TableCell>TOTAL COST</TableCell>
                    </TableRow>
                  </TableHead>
                <TableBody>
                  {invoice?.jobcost_product?.map((val,index) => {
                    const { id, product_name, unit_price, quantity, total, unit, unit_qty } = val
                    let unitName = UNITS?.filter((item) => item?.id == unit)
                    unitName = unitName[0]?.name
                    let shortUnitName = ''
                    if (unitName == 'Square feet') {
                      shortUnitName = 'Sq ft'
                    } else if (unitName == 'Lineal feet') {
                      shortUnitName = 'Lin ft'
                    } else if (unitName == 'Square Inches') {
                      shortUnitName = 'Sq Inches'
                    } else if (unitName == 'Unit') {
                      shortUnitName = 'Unit'
                    }
                    return (
                      <TableRow key={index} 
                        sx={{
                          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                          '& .productName': {
                            fontWeight: 'bold',
                          },
                        }}
                      >
                        <TableCell width={'25%'} sx={{flexWrap:'wrap'}}>
                          <Typography variant="h4" className="productName">
                            {product_name}
                          </Typography>
                        </TableCell>
                        <TableCell variant="h4">{unit_price}</TableCell>
                        <TableCell variant="h4">{quantity}({shortUnitName})</TableCell>
                        <TableCell variant="h4"> {total}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            : ''}
            {!empty(invoice?.jobcost_service?.filter(it=>it.total_cost == 0)) ? 
            <TableContainer sx={{ minWidth: 420 }}>
              <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>NAME</TableCell>
                      <TableCell>HOURLY COST</TableCell>
                      <TableCell>HOURS</TableCell>
                      <TableCell>TOTAL COST</TableCell>
                    </TableRow>
                  </TableHead>
                <TableBody>
                  {invoice?.jobcost_service?.filter(it=>it.total_cost == 0)?.map((ser,index) => {
                    
                    return (
                      <TableRow key={index} 
                        sx={{
                          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                          '& .productName': {
                            fontWeight: 'bold',
                          },
                        }}
                      >
                        <TableCell width={'25%'} sx={{flexWrap:'wrap'}}>
                          <Typography variant="h4" className="productName">
                            {ser?.name}
                          </Typography>
                        </TableCell>
                        <TableCell variant="h4">{ser ? parseFloat(ser?.service_original_cost)?.toFixed(2) : 0}</TableCell>
                        <TableCell variant="h4">{ser ? parseFloat(ser?.jobcost_quantity)?.toFixed(2) : 0}</TableCell>
                        <TableCell variant="h4">{ser ? ser?.total_service_cost : 0}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            : ''}
          <Grid id="total" item xs={12} sm={6} >
          <Box sx={{ mb: 5, display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap:'8px' }}>
            {!empty(invoice?.jobcost_product) ? 
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography id="discount" variant="h2" style={{ textAlign: 'left', margin: '4px', padding: 0 }}>Additional Amount</Typography>
              <Typography variant="h2" style={{ textAlign: 'right', margin: '4px', padding: 0 }}>{invoice?.jobcostTotal ? invoice?.jobcostTotal?.toFixed(2) : 0.00}</Typography>
            </Box> : '' }
            {!empty(invoice?.jobcost_service?.filter(it=>it.total_cost == 0)) ? 
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography id="discount" variant="h2" style={{ textAlign: 'left', margin: '4px', padding: 0 }}>Additional Service Amount</Typography>
              <Typography variant="h2" style={{ textAlign: 'right', margin: '4px', padding: 0 }}>{invoice?.jobcostServiceTotal ? invoice?.jobcostServiceTotal?.toFixed(2) : 0.00}</Typography>
            </Box> : '' }
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography id="totalmain" variant="h2" style={{ textAlign: 'left', margin: '4px', padding: 0 }}>Grand Total</Typography>
              <Typography variant="h2" style={{ textAlign: 'right', margin: '4px', padding: 0 }}>{invoice?.grandTotal ? invoice?.grandTotal?.toFixed(2) : 0.00}</Typography>
            </Box>
          </Box>
          </Grid>
          </>
        : ''} */}
      </>}
      </Grid>
    </Stack>
  )
})