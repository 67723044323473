import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useEffect, useCallback, useState } from 'react';
// material
import { Box, Grid, Button, Skeleton, Container, Stack, TextField, Autocomplete, TableBody, TableRow, TableCell, TableContainer, Table, Typography, CircularProgress, Pagination, TablePagination } from '@material-ui/core';
// redux
// import { useDispatch, useSelector } from '../../redux/store';
// import { getMorePosts } from '../../redux/slices/blog';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../../components/_dashboard/blog';
import CardAllClients from 'src/components/_dashboard/user/cards/CardAllClients';
import AllClientsSearch from 'src/components/_dashboard/blog/AllClientsSearch.js';
import AddProject from 'src/components/_dashboard/blog/AddProject';
import axios from 'axios';
import { REST_API, REST_API_END_POINT } from 'src/constants/Defaultvalues';
import TeamSearch from './TeamSearch';
import SearchNotFound from 'src/components/SearchNotFound';
import LoadingScreen from 'src/components/LoadingScreen';
import useCompanyProfile from 'src/hooks/useCompanyProfile';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FullScreenDialogClients from 'src/pages/components-overview/material-ui/dialog/FullScreenDialogClients';
// ----------------------------------------------------------------------



const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  // { value: 'popular', label: 'Project Category' },
  { value: 'oldest', label: 'Oldest' }
];

// ----------------------------------------------------------------------

// const applySort = (posts, sortBy) => {
//   if (sortBy === 'latest') {
//     return orderBy(posts, ['createdAt'], ['desc']);
//   }
//   if (sortBy === 'oldest') {
//     return orderBy(posts, ['createdAt'], ['asc']);
//   }
//   //   if (sortBy === 'popular') {
//   //     return orderBy(posts, ['view'], ['desc']);
//   //   }
//   return posts;
// };

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function clientSortFilter(data,comparator,place,services,availability,rank) {

  const stabilizedThis = data.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  // if (searchString) {
  //   data = data.filter((_user) => {
  //     const name = `${_user.name}`.toLowerCase();
  //     return name.includes(searchString.toLowerCase());
  //   });
  // }

  if (services && services.length > 0) {
    data = data.filter((client) => {
      return !services || services.includes(client.service_id)
    });
  }

  if (place && place.length > 0) {
     data = data.filter((client) => {
      return !place || place.includes(client.state_id)
    });
  }

  if (availability && availability.length > 0) {
    data = data.filter((client) => {
      return !availability || availability.includes(String(client.onboard_complete))
    });

  }
  if(rank &&rank.length>0){
    data = data.filter((client)=>{
      return !rank || rank.includes(client.rank_id)
    })
  }

  return data;
}

function applySortFilter(array, comparator, services, location, availability) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  // if (query) {
  //   array = array.filter((_user) => {
  //     const name = `${_user.first_name} ${_user.last_name}`.toLowerCase();
  //     return name.includes(query.toLowerCase());
  //   });
  // }

  if (services && services.length > 0) {
    array = array.filter((teamMember) => {
      return !services || services.includes(teamMember.service_id)
    });
  }

  if (location && location.length > 0) {
    array = array.filter((teamMember) => {
      return !location || location.includes(teamMember.state_id)
    });
  }

  if (availability && availability.length > 0) {
    array = array.filter((teamMember) => {
      return !availability || availability.includes(teamMember.onboard_complete)
    });

  }
  // array.sort((a, b) => comparator(a, b));     // to sort the filtered array by first name - case sensitive

  // array.sort((a, b) => {                        // to sort the filtered array by first name - case insensitive
  //   const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
  //   const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
  //   return nameA.localeCompare(nameB, undefined, { sensitivity: 'base', caseFirst: 'false' });
  // });


  return array;
}

const SkeletonLoad = (
  <Grid container spacing={3} sx={{ mt: 2 }}>
    {[...Array(4)].map((_, index) => (
      <Grid item xs={12} md={3} key={index}>
        <Skeleton variant="rectangular" width="100%" sx={{ height: 200, borderRadius: 2 }} />
        <Box sx={{ display: 'flex', mt: 1.5 }}>
          <Skeleton variant="circular" sx={{ width: 40, height: 40 }} />
          <Skeleton variant="text" sx={{ mx: 1, flexGrow: 1 }} />
        </Box>
      </Grid>
    ))}
  </Grid>
);
//  
export default function AllClients({
   isAdmin,
   isVendor,
  servicesFromPopbar,
  locationFromPopbar,
  availabilityFromPopbar,
  setClientAvailability,
  setClientServices,
  setClientLocation,
  setClientRank,
  filterClientLocation,
  filterClientService,
  filterClientAvailability,
  filterClientRank,
  }) {
   
  //------------------------------------------------------------------------------------------
  //get user logged in data from local storage
  const user = localStorage.getItem('user')
  const userData = JSON.parse(user)
  //------------------------------------------------------------------------------------------
  const [order, setOrder] = useState('desc');
  const [filters, setFilters] = useState('latest');
  const [add, setAdd] = useState(false)
  const [orderBy, setOrderBy] = useState('id');
  const [teamMembers, setTeamMembers] = useState([]);
  const [data, setData] = useState([]);
  // const { posts, hasMore, index, step } = useSelector((state) => state.blog);
  // const onScroll = useCallback(() => dispatch(getMorePosts()), [dispatch]);
  const [filterName, setFilterName] = useState('');
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const receivedData  = location.state?.data;
  const [refreshClient,setRefreshClient] = useState(false)
  const [totalCount,setTotalCount] = useState(0)
  const [page,setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const {COMPANY_NAME} = useCompanyProfile()
  const [finalData,setFinalData] = useState([])
    const handleOpenClientTemp= localStorage.getItem('clientPopupOpen')
    const [handleOpenClient,setHandleOpenClient] = useState(handleOpenClientTemp || false)
    const [clientId,setClientId] = useState(false)
    const [clientName,setClientName] = useState(false)

    const [clientEdit,setClientEdit] = useState('')
    const [clientType,setClientType] = useState(null)

  const applySort = (sortBy) => {
    if (sortBy === 'latest') {
      setOrder('desc');
    }
    if (sortBy === 'oldest') {
      setOrder('asc');
    }
  };
 
const clearPreviousData = ()=> {
  setClientName('')
  setClientId('')
}

  useEffect(() => {
    setClientId(false)
    setClientName(false)
  }, [isVendor]);

  useEffect(() => {
    fetchData();
    // dispatch(getPostsInitial(index, step));
  }, [receivedData,order,page,rowsPerPage,filterName,refreshClient]);

  useEffect(()=>{
    setFilterName('')
  },[isVendor])

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const fetchData = async () => {
    if (isAdmin && userData) {
      try {
        const response = await axios.post(`${REST_API_END_POINT}teams/get-all-teams-data`, {
          usersId: userData.user_id,
          usersType: userData.user_type,
          page:page+1,rowsPerPage,search:filterName,
        });
        if (response.data.team_members) {
          setTeamMembers(response.data.team_members.data);
          setTotalCount(response.data.team_members.totalCount)
        }else{
          setTeamMembers([]);
          setTotalCount(0)
        }
      } catch (error) {
        console.log("Error fetching data: ", error);
      }
      finally{
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    try {   
       axios.post(REST_API_END_POINT+'clients/getAllClients', {
        userId: userData.user_id,
        userType: userData.user_type,
        page:page+1,rowsPerPage,search:filterName,
        isVendor:isVendor?1:0,
        })
        .then((res) => {
          let data = res.data;
          if(!isAdmin){
          setData(data.customer);
          console.log(data.customer,"dataaaaaaaaaaaaaaaaaaaaaaaaa")
          setTotalCount(data.totalCount)
          setClientAvailability(res.data.availablities)
          setClientLocation(res.data.states)
          setClientRank(res.data.ranks)
          setClientServices(res.data.services)
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    } catch (e) {
      console.error('Exception:', e);
    }

  }, [refreshClient,page,rowsPerPage,filterName,isVendor]);

  const handleChangeSort = (event) => {
    applySort(event);
    setFilters(event);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  let service = servicesFromPopbar ? servicesFromPopbar : '';
  let locat = locationFromPopbar ? locationFromPopbar : '';
  let availab = availabilityFromPopbar ? availabilityFromPopbar : '';
  let filteredUsers, isUserNotFound;
  
  if (teamMembers&& (isAdmin==true)) {
    filteredUsers = applySortFilter(teamMembers, getComparator(order, orderBy), service, locat, availab);
    isUserNotFound = filteredUsers.length === 0;
  }else{
    // console.log('fasdfsf asdfsa');
    filteredUsers =  clientSortFilter(data,getComparator(order, orderBy),filterClientLocation,filterClientService,filterClientAvailability,filterClientRank);
  }
  // if(data){
  //   filteredUsers =  clientSortFilter(data,getComparator(order, orderBy),filterName,filterClientLocation,filterClientService,filterClientAvailability,filterClientRank);
  // }
  //-----------------------------------------------------------------------

  useEffect(()=>{
    setFinalData(filteredUsers)
    console.log('filteredUsers',filteredUsers)
  },[filteredUsers])

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(finalData);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    setTeamMembers(reorderedItems);
    setFinalData(reorderedItems)
    console.log("reorderedItemsreorderedItemsreorderedItems", reorderedItems)
    saveNewOrder(reorderedItems);
  };


  const saveNewOrder = async (reorderedItems) => {
    try {
      await axios.post(`${REST_API}webservice/${isAdmin?'teams/save-card-order-id' : 'client-vendor/save-card-order'}`, { reorderedItems });
      console.log('Order saved successfully');
    } catch (error) {
      console.error('Error saving order:', error);
    }
  };

  return (
    <Page title={isAdmin ? `All Team Members |  ${COMPANY_NAME}` : (isVendor ? `All Vendors | ${COMPANY_NAME}` : `All Clients | ${COMPANY_NAME}`)}>
      <Container sx={{ marginTop: "15px" }}>
        {/* <HeaderBreadcrumbs heading="" /> */}
        {/* <Stack mb={4} direction="row" alignItems="center" justifyContent="flex-end" marginTop={'-50px'}> */}
          {/* <BlogPostsSort query={filters} options={SORT_OPTIONS} onSort={handleChangeSort} /> */}
          {/* {isAdmin && <BlogPostsSort query={filters} options={SORT_OPTIONS} onSort={handleChangeSort} />} */}
        {/* </Stack> */}
        <Stack mb={3} direction="row" alignItems="center" justifyContent="space-between" marginTop={'-20px'}>
          
          {isAdmin ?
            <TeamSearch filterName={filterName} onFilterName={handleFilterByName} isTeam />
            :
            (isVendor ?
            <TeamSearch filterName={filterName} onFilterName={handleFilterByName} isVendor />
            :
            <TeamSearch filterName={filterName} onFilterName={handleFilterByName} />)
          }

        </Stack>

        <InfiniteScroll
          // next={onScroll}
          //   hasMore={hasMore}
          loader={SkeletonLoad}
          dataLength={filteredUsers ? filteredUsers.length : (posts ? posts.length : null)}
          sx={{ overflow: 'inherit' }}
        >
        
          <Grid sx={{ p: 2 }} container spacing={3}>
            {isAdmin==true ? (
              loading ? 
              <Stack alignItems='center' justifyContent='center'>
                <CircularProgress color="primary" variant="indeterminate" />
              </Stack>
              :
              
              <>
                {/* {filteredUsers?.map((post, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4}>
                    <CardAllClients team={isAdmin} user={post} />
                  </Grid>
                ))
                } */}
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId="officialRequests" direction="vertical">
                        {(provided) => (
                          <Grid
                            container
                            spacing={3}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                           {finalData?.map((post, index) => {
                             

                              return (
                                <Draggable
                                  key={post?.id}
                                  draggableId={post?.id.toString()}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      sx={{ mt: 3 }}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        ...provided.draggableProps.style,
                                        opacity: snapshot.isDragging ? 0.5 : 1,
                                      }}
                                    >
                                        <CardAllClients team={isAdmin} user={post} />
                                    </Grid>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </Grid>
                        )}
                      </Droppable>
                    </DragDropContext>
              </>
            ) : (
              <>              
                {/* {filteredUsers?.map((post, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4}>
                    <CardAllClients refreshClient={refreshClient} setRefreshClient={setRefreshClient} fromClient={true} user={post} />
                  </Grid>
                ))} */}

                <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId="officialRequests" direction="vertical">
                        {(provided) => (
                          <Grid
                            container
                            spacing={3}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                           {finalData?.map((post, index) => {
                             

                              return (
                                <Draggable
                                  key={post?.id}
                                  draggableId={post?.id.toString()}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      sx={{ mt: 3 }}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        ...provided.draggableProps.style,
                                        opacity: snapshot.isDragging ? 0.5 : 1,
                                      }}
                                    >
                                       <CardAllClients
                                       clientId={clientId} setClientId={setClientId} clientName={clientName} setClientName={setClientName} clientEdit={clientEdit} setClientEdit={setClientEdit} clientType={clientType} setClientType={setClientType} handleOpenClient={handleOpenClient} setHandleOpenClient={setHandleOpenClient}
                                         refreshClient={refreshClient}
                                          setRefreshClient={setRefreshClient}
                                           fromClient={true}
                                            user={post}
                                            clearPreviousData={clearPreviousData}
                                             />
                                    </Grid>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </Grid>
                        )}
                      </Droppable>
                    </DragDropContext>
              </>
            )}
          </Grid>

          {(isUserNotFound && isAdmin)&& 
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} sx={{ backgroundColor: '#161c24' }} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          }
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
         <TablePagination
            rowsPerPageOptions={[6, 12, 27]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Box>
        </InfiniteScroll>
      </Container>
      <FullScreenDialogClients
        open={handleOpenClient}
        clientType={clientType}
        team={isAdmin}
        setOpen={setHandleOpenClient}
        projectName={clientName}
        projectId={clientId}
        setRenderCards={setRefreshClient}
        renderCards={refreshClient}
        editClient={clientEdit}
        setIsClientEdit={setClientEdit}
        clearPreviousData={clearPreviousData}
      />
    </Page>
  );
}
