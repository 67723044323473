import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Slide,
  Paper,
  IconButton,
  Card,
  Badge,
  CircularProgress,
  Stack,
} from '@material-ui/core'
import { styled } from '@material-ui/styles'
import CloseIcon from '@material-ui/icons/Close'
import ChatIcon from '@material-ui/icons/Chat'
import ChatMain from './ChatMain'
import ChatSidebarSignWise from './ChatRightScreen/ChatSidebarSignWise'
import ChatScreenWindow from './ChatLeftScreen/ChatScreenWindow'
import { useDispatch } from 'react-redux'
import { getContacts, getConversations } from 'src/redux/slices/chat'
import IMG from '../../../Gif/apple-touch-icon.png'
import { MBadge } from 'src/components/@material-extend'
import axios from 'axios'
import { REST_API_END_POINT, STATUS_OPTIONS } from 'src/constants/Defaultvalues'
import Draggable from 'react-draggable'

// Styled Button for Floating Chat
const FloatingButton = styled(Button)(({ theme }) => ({
  position: 'fixed',
  // bottom: 25,
  // right: 80, // Default value
  zIndex: 1000,
  width: '50px',
  height: '50px',
  minWidth: '50px',
  borderRadius: '50%',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#1ccaff',
  transition: 'transform 0.3s ease',
  cursor: 'inherit',

  '&:hover': {
    backgroundColor: '#276699',
    transform: 'scale(1.1)',
  },
  '& *': {
    pointerEvents: 'none',
  },

  // Responsive styles
  // [theme.breakpoints.down('md')]: {
  //   right: 22, // Apply when screen width is below md (960px by default)
  //   bottom: 65, // Apply when screen height is below md (960px by default)
  // },
}))

// Styled Chat Popup Window
const PopupChat = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  bottom: 90,
  right: 80,
  zIndex: 1100,
  width: 'fit-content',
  [theme.breakpoints.down('md')]: {
    right: 0, // Apply when screen width is below md (960px by default)
  },
}))

export default function FloatingChat() {
  const [open, setOpen] = useState(false)
  const [openCompose, setCompose] = useState(true)
  const dispatch = useDispatch()
  const user = localStorage.getItem('user')
  const userData = JSON.parse(user)
  const isAdmin = true || userData.user_type === 1
  const [teamMembers, setTeamMembers] = useState([])
  const [selectedContact, setSelectedContact] = useState(null)
  const [renderChat, setRenderChat] = useState(false)
  const [loading, setLoading] = useState(true)

  const [status, setStatus] = useState(null)
  const [refreshStatus, setRefreshStatus] = useState(false)
  const [showHistory, setShowHistory] = useState(true)
  const [chatOpenTime, setChatOpenTime] = useState(null)
  const [historyMessage, setHistoryMessage] = useState([])
  const [selectedChatLogId, setSelectedChatLogId] = useState(null)
  const [newChat, setNewChat] = useState(false)
  const [position, setPosition] = useState(null)
  const isDragging = useRef(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isDraggingNow, setIsDraggingNow] = useState(false);


  const isMobile = windowWidth < 768
  const isMd = windowWidth < 900

useEffect(() => {
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, []);


  const clampPosition = (x, y) => ({
    x: Math.min(Math.max(0, x), window.innerWidth - 60),
    y: Math.min(Math.max(0, y), window.innerHeight - 60),
  })

  const fetchSavedPosition = async () => {
    try {
      const response = await axios.post(
        `${REST_API_END_POINT}get-chat-position`,
        {
          userId: userData.user_id,
        },
      )

      if (response.data.status === 1) {
        const { position_x, position_y } = response?.data
        if (
          position_x !== null &&
          position_y !== null &&
          position_x !== undefined &&
          position_y !== undefined
        ) {
          const clamped = clampPosition(position_x, position_y)
          setPosition(clamped)
        } else {
          const fallback = {
            x: window.innerWidth - 100,
            y: window.innerHeight - 150,
          }
          setPosition(fallback)
        }
      }
    } catch (err) {
      console.error('Failed to fetch saved position:', err)
      const fallback = {
        x: window.innerWidth - 100,
        y: window.innerHeight - 150,
      }
      setPosition(fallback)
    }
  }

  useEffect(() => {
    fetchSavedPosition()
  }, [isMd])

  const handleDrag = (e, data) => {
    isDragging.current = true
    setIsDraggingNow(true)
    setPosition({ x: data.x, y: data.y })
  }

  const handleDragStop = async (e, data) => {
    setTimeout(() => {
      isDragging.current = false
      setIsDraggingNow(false)
    }, 200)

    const clamped = clampPosition(data.x, data.y)
    setPosition(clamped)
    setPosition(clamped)

    try {
      await axios.post(`${REST_API_END_POINT}update-chat-position`, {
        userId: userData.user_id,
        chat_position_x: data.x,
        chat_position_y: data.y,
      })
      console.log(' Chat position saved:', clamped)
    } catch (err) {
      console.error(' Failed to save position:', err)
    }
  }

  const fetchStatus = async () => {
    try {
      const response = await axios.post(
        `${REST_API_END_POINT}chat/get-chat-status-by-userid`,
        { userId: userData.user_id },
      )
      if (response.data.status === 1) {
        const fetchedStatus = response.data.result.chat_status
        const filteredStatus = STATUS_OPTIONS?.find(
          (item) => item.id == fetchedStatus,
        )
        console.log('newStatus---filteredStatus-1--', filteredStatus)
        setStatus(filteredStatus)
        console.log('Status set successfully')
      } else {
        console.log('Status not found')
      }
    } catch (error) {
      console.log('Error finding status : ', error)
    }
  }

  useEffect(() => {
    fetchStatus()
  }, [refreshStatus, open])

  const fetchData = async (updateSelctedContact = false) => {
    if (isAdmin && userData) {
      try {
        const response = await axios.post(
          `${REST_API_END_POINT}chat/get-chat-list`,
          {
            usersId: userData.user_id,
            usersType: userData.user_type,
            // page:page+1,rowsPerPage,search:filterName,
          },
        )
        if (response.data.data) {
          const newData = response.data.data
          setTeamMembers(newData)
          if (updateSelctedContact) {
            const filteredData = newData.find(
              (member) =>
                member?.new_conversation_id ===
                selectedContact?.new_conversation_id,
            )
            console.log('selectedContact---', selectedContact)
            console.log('filteredData---', filteredData)
            setSelectedContact(filteredData)
          }
        } else {
          setTeamMembers([])
        }
      } catch (error) {
        console.log('Error fetching data: ', error)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [open])

  useEffect(() => {
    fetchData(true)
  }, [renderChat])

  const handleRefresh = () => {
    fetchData(true)
  }

  const refreshChat = () => {
    setRenderChat(!renderChat)
  }

  useEffect(() => {
    dispatch(getConversations())
    dispatch(getContacts())
  }, [dispatch])

  const togglePopup = () => {
    setOpen(!open)
    setCompose(true)
    setShowHistory(false)
    setSelectedContact(null)
    // console.log('showHistoryshowHistory',!showHistory,selectedContact)
  }
  const openChat = () => {
    setCompose(false)
  }

  return (
    <>
      {/* <FloatingButton onClick={togglePopup}>
        <Badge badgeContent={3} color="error" overlap="circular">
          <Box
            component="img"
            src={IMG}
            alt="LOGO"
            sx={{ width: 45, height: 45, objectFit: 'cover', mt: 0.5 }}
          />
        </Badge>
      </FloatingButton> */}

      {position && !isMobile && (
        <Draggable
          bounds="body"
          position={position}
          onDrag={handleDrag}
          onStop={handleDragStop}
        >
          <div
            className="drag-wrapper"
            style={{ position: 'fixed', zIndex: 9999,cursor: isDraggingNow ? 'grabbing' : 'grab', }}
          >
            <FloatingButton
              onClick={() => {
                if (!isDragging.current) togglePopup()
              }}
            >
              <Badge badgeContent={3} color="error" overlap="circular">
                <Box
                  component="img"
                  src={IMG}
                  alt="LOGO"
                  sx={{ width: 45, height: 45, objectFit: 'cover', mt: 0.5 }}
                />
              </Badge>
            </FloatingButton>
          </div>
        </Draggable>
      )}
      {isMobile && (
        <div style={{ position: 'fixed', bottom: 80, right: 80, zIndex: 9999,cursor: isDragging.current ? 'grabbing' : 'grab', }}>
          <FloatingButton onClick={togglePopup}>
            <Badge badgeContent={3} color="error" overlap="circular">
              <Box
                component="img"
                src={IMG}
                alt="LOGO"
                sx={{ width: 45, height: 45, objectFit: 'cover', mt: 0.5 }}
              />
            </Badge>
          </FloatingButton>
        </div>
      )}

      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <PopupChat>
          {/* {loading ? 
              <Stack alignItems='center' justifyContent='center'>
                <CircularProgress color="primary" variant="indeterminate" />
              </Stack>: */}
          <Card sx={{ height: '72vh', display: 'flex' }}>
            <ChatSidebarSignWise
              openCompose={openCompose}
              setCompose={setCompose}
              setOpen={setOpen}
              teamMembers={teamMembers}
              setSelectedContact={setSelectedContact}
              selectedContact={selectedContact}
              refreshChat={refreshChat}
              setRenderChat={setRenderChat}
              renderChat={renderChat}
              status={status}
              setStatus={setStatus}
              refresh={refreshStatus}
              setRefresh={setRefreshStatus}
              setShowHistory={setShowHistory}
              showHistory={showHistory}
              chatOpenTime={chatOpenTime}
              setChatOpenTime={setChatOpenTime}
              setHistoryMessage={setHistoryMessage}
              historyMessage={historyMessage}
              selectedChatLogId={selectedChatLogId}
              setSelectedChatLogId={setSelectedChatLogId}
              setNewChat={setNewChat}
              newChat={newChat}
              handleRefresh={handleRefresh}
            />
            <ChatScreenWindow
              openCompose={openCompose}
              setCompose={setCompose}
              teamMembers={teamMembers}
              contact={selectedContact}
              setSelectedContact={setSelectedContact}
              refreshChat={refreshChat}
              setRenderChat={setRenderChat}
              renderChat={renderChat}
              handleRefresh={handleRefresh}
              setShowHistory={setShowHistory}
              showHistory={showHistory}
              chatOpenTime={chatOpenTime}
              setChatOpenTime={setChatOpenTime}
              setHistoryMessage={setHistoryMessage}
              historyMessage={historyMessage}
              selectedChatLogId={selectedChatLogId}
              setSelectedChatLogId={setSelectedChatLogId}
              setNewChat={setNewChat}
              newChat={newChat}
            />
          </Card>
          {/* } */}
        </PopupChat>
      </Slide>
    </>
  )
}
