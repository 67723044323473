import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import { useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import QRCode from 'react-qr-code'
import ReactToPrint from 'react-to-print'
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill'
import { PrintOutlined, QrCode2 } from '@material-ui/icons'
// material
import { useTheme } from '@material-ui/core/styles'
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip
} from '@material-ui/core'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'notistack'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { BASE_URL, COMPANY_NAME } from 'src/constants/Defaultvalues'
import { empty } from 'src/Helpers/Common'

const labelStyle = {
  width: '62mm',
  height: '20mm',
  // padding: '1mm',
  fontFamily: 'Arial, sans-serif', fontSize: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', boxSizing: 'border-box', gap: '2mm', 
  overflow: 'hidden', 
  // pageBreakInside: 'avoid',
  // breakInside: 'avoid',
  pageBreakInside: 'avoid',
  breakInside: 'avoid',
  pageBreakBefore: 'avoid',
  flexWrap: 'nowrap',
  transform: 'scale(0.9)', /* Reducing size slightly */
  // border:"2px solid black",
  paddingRight:"12px",
  // paddingLeft:"1px",
  paddingTop:"10px"

};
const labelStyle1 = {
  width: '0.9in',
  height: '0.8in',
  // padding: '1mm',
  fontFamily: 'Arial, sans-serif',
  fontSize: '8px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  // gap: '1mm',
  overflow: 'hidden',
  // border:"2px solid black",

};

const rowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  gap: 2
};

const rowStyle1 = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  marginTop:"5px"
};

const sectionStyle = {
  display: 'flex',
  flexDirection: 'column',
};

const qrCodeSection = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  // gap: '1mm',
};
const qrCodeSectionNew = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  // gap: '1mm',
  marginLeft:"5px"
};

const typographyStyle = {
  fontWeight: 'bold',
  textAlign: 'center',
  color: 'black',
  fontSize: '8px',
  wordWrap: 'break-word',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordBreak: 'break-all',
};
const typographyStyleForDesc = {
  fontWeight: 'bold',
  textAlign: 'center',
  color: 'black',
  fontSize: '8px',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 2,     
  WebkitBoxOrient: 'vertical',
};

// const typographyStyle1 = {
//   textAlign: 'center',
//   color: 'black',
//   fontSize: '6px',
//   wordWrap: 'break-word',
//   overflow: 'hidden',
//   textOverflow: 'ellipsis',
//   wordBreak: 'break-all',
// };

const typographyStyle1 = {
  textAlign: 'center',
  color: 'black',
  fontSize: '6px',
  maxWidth: '100%', // Prevents overflow
  wordWrap: 'break-word',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordBreak: 'break-word',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center', 
  padding:"2px"
  // height: '100%' // Ensure it takes full height for proper centering
};


export default function FinishedProductmenu({ id, url, useIcon, name, barcode,vpt_number ,description}) {
  const ref = useRef(null)
  const componentRef = useRef(null)
  const detailsRef = useRef(null);
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  let user = JSON.parse(localStorage.getItem('user'))
console.log('descriptiondescription',description)
  return (
    <>
    {useIcon ? 
      <ReactToPrint
        trigger={() => (
            <IconButton ref={ref} color='#ffffff'>
              {/* <QrCode2 color='#ffffff' /> */}
              <PrintOutlined />
            </IconButton>
        )}
        content={() => componentRef.current}
      /> :
      <>
        <ReactToPrint
          trigger={() => (
              <Tooltip title='Print Label'>
                <IconButton ref={ref} color='#ffffff'>
                  <PrintOutlined />
                </IconButton>
              </Tooltip>
          )}
          content={() => componentRef.current}
        />
        <ReactToPrint
          trigger={() => (
              <Tooltip title='Print Small Label'>
                <IconButton ref={detailsRef} color='primary'>
                  <PrintOutlined />
                </IconButton>
              </Tooltip>
          )}
          content={() => detailsRef.current}
        />
      </>}
      
      <div style={{ display: 'none' }}>
        <div ref={componentRef} style={labelStyle}>
          <div style={rowStyle}>
            {!empty(name) && (
              <>
                <div style={qrCodeSectionNew}>
                  <QRCode
                    value={name}
                    size={45}
                    bgColor={'#ffffff'}
                    fgColor={'#000000'}       
                    includeMargin={false}
                  />
                  <Typography variant="caption" sx={{color:'black', textAlign:'center',fontSize:"7px"}}>Material</Typography>
                </div>
              </>
            )}
            {!empty(name) && (
              <div style={sectionStyle}>
                {/* <Typography variant="h5" style={typographyStyle}>{name}</Typography> */}
                <Typography variant="h5" style={typographyStyleForDesc}>{description}</Typography>
                <Typography variant="h5" style={typographyStyle}>SKU# {vpt_number}</Typography>
              </div>
            )}
            <div style={qrCodeSection}>
              <QRCode
                value={`${url}${id}`}
                size={45}
                bgColor={'#ffffff'}
                fgColor={'#000000'}       
                includeMargin={false}
              />
              <Typography variant="caption" sx={{color:'black', textAlign:'center',fontSize:"7px"}}>Details</Typography>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: 'none' }}>
        <div ref={detailsRef} style={{ ...labelStyle1, margin: '0' }}>
          <div>
            <div style={rowStyle1}>
              {!empty(barcode) && (
                <>
                  <div style={qrCodeSection}>
                    <QRCode
                      value={barcode}
                      size={50}
                      bgColor={'#ffffff'}
                      fgColor={'#000000'}       
                      includeMargin={false}
                    />
                    {/* <Typography variant="caption" style={typographyStyle1}>{name}</Typography> */}
                  </div>
                </>
              )}
              {/* <div style={sectionStyle}>
                {!empty(name) && (
                  <Typography variant="h5" style={typographyStyle}>{name}</Typography>
                )}
              </div> */}
              {/* <div style={qrCodeSection}>
                <QRCode
                  value={`${url}${id}`}
                  size={50}
                  bgColor={'#ffffff'}
                  fgColor={'#000000'}       
                  includeMargin={false}
                />
                <Typography variant="caption" sx={{color:'black', textAlign:'center'}}>Scan for Details</Typography>
              </div> */}
            </div>
          </div>
          <Typography variant="caption" style={typographyStyle1}>{name}</Typography>
        </div>
      </div>
    </>
  )
}

FinishedProductmenu.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  useIcon: PropTypes.bool,
  name: PropTypes.string,
  vpt_number: PropTypes.string
};
