import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import { capitalCase } from 'change-case';
import { useState, useEffect,useRef } from 'react';
import bellFill from '@iconify/icons-eva/bell-fill';
import shareFill from '@iconify/icons-eva/share-fill';
import roundVpnKey from '@iconify/icons-ic/round-vpn-key';
import roundReceipt from '@iconify/icons-ic/round-receipt';
import roundAccountBox from '@iconify/icons-ic/round-account-box';
// material
import { Container, Tab, Box, Tabs, Stack,Button, Typography, CircularProgress } from '@material-ui/core';
// redux
import { useDispatch, useSelector  } from '../../redux/store';
import { getCards, getProfile, getInvoices, getAddressBook, getNotifications } from '../../redux/slices/user';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import {
  AccountGeneral,
  AccountBilling,
  AccountSocialLinks,
  AccountNotifications,
  AccountChangePassword
} from '../../components/_dashboard/user/account';
import Messages from 'src/components/_dashboard/user/profile/Messages.js'
import Sms from 'src/components/_dashboard/user/profile/Sms.js'
import Whatsapp from 'src/components/_dashboard/user/profile/Whatsapp.js'
import Request from 'src/components/_dashboard/user/profile/Request.js'
import Payments from 'src/components/_dashboard/user/profile/Payments.js'
import Documents from 'src/components/_dashboard/user/profile/Documents.js'
import Calls from 'src/components/_dashboard/user/profile/Calls.js'
import Tickets from 'src/components/_dashboard/user/profile/Tickets.js'
import { KeyboardBackspaceOutlined, SwitchAccount } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom'
import { ProfilePostCard } from 'src/components/_dashboard/user/profile';
import  ChatWindow  from 'src/components/_dashboard/chat/ChatWindow.js';
import  ChatMessageItem  from 'src/components/_dashboard/chat/ChatMessageItem.js';
import Scrollbar from 'src/components/Scrollbar';
import Chat from 'src/pages/dashboard/Chat.js';
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';
import useCompanyProfile from 'src/hooks/useCompanyProfile';
import { REST_API_END_POINT } from '../../constants/Defaultvalues';
import ReceiveOrderList from './ReceiveOrderList';
import ReceiveOrders from 'src/components/_dashboard/user/list/ReceiveOrders';
import SupportTickets from 'src/components/_dashboard/crm/tickets/SupportTickets';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

const conversationSelector = (state) => {
  const { conversations, activeConversationId } = state.chat;
  const conversation = conversations.byId[activeConversationId];
  if (conversation) {
    return conversation;
  }
  return {
    id: null,
    messages: [],
    participants: [],
    unreadMessages: 0
  };
};

export default function UserAccount({popupOn,team ,clientType ,idOfProject,closeView,setClientEdit,setClientId,setViewAccount}) {
   const clientId = useParams() 
   const {COMPANY_NAME}=useCompanyProfile()
   const [refreshData,setRefreshData]= useState(false)
  const [currentTab, setCurrentTab] = useState('general');
  const [details,setDetails] = useState(null)
  const [states,setStates] =useState(null)
  const  [partnerType,setPartnerType] =useState(null)
  const  [ranks,setRanks] =useState(null)
  const  [workFlow,setWorkFlow] =useState(null)
  const [allClientRequest,setAllClientRequest] = useState(null)
  const [payments,setPayments] = useState([])
  const [bankInfo,setBankInfo] = useState(null)
  const [portfolio,setPortfolio] = useState(null)
  const [taxInfo,setTaxInfo] = useState(null)
  const [tickets,setTickets] = useState([])
  const [signAgreements,setSignAgreements] = useState([])
  const [customTemplate,setCustomTemplate] = useState(null)
  const [questionnaireTemplate,setQuestionnaireTemplate] = useState(null)
  const [loading,setLoading] = useState(false)
   const [contactTags,setContactTags] = useState([])
   const[backView,setBackView]=useState(true)
  const dispatch = useDispatch();
  const { loginWithUserId } = useAuth();

  useEffect(() => {
    dispatch(getCards());
    dispatch(getAddressBook());
    dispatch(getInvoices());
    dispatch(getNotifications());
    dispatch(getProfile());
  }, [dispatch]);
  const navigate=useNavigate()
  const user = localStorage.getItem('user')
  const userData = JSON.parse(user)
  const explodedPermissions =userData?.exploded_permissions
  let hasPermission = explodedPermissions?.some(permission => ['Inviting clients','Edit clients','Delete clients'].includes(permission)) ? true : false
  const fetchViewClientData=async()=>{
    setLoading(true)
    try{
      const data ={id:popupOn ? idOfProject : clientId.id,userId:userData.user_id,userType:userData.user_type,hasPermission:hasPermission}
      console.log('data>>>>>>>' , data)
      const res = await axios.post(`${REST_API_END_POINT}clients/fetch-client-by-id`,data)
      setTickets(res.data.tickets)
      setAllClientRequest(res.data.allRequest)
      setDetails(res.data.client)
      setStates(res.data.states)
      setRanks(res.data.ranks)
      setWorkFlow(res.data?.workflows)
      setPartnerType(res.data?.partnerTypes)
      setBankInfo(res.data?.bankInfo)
      setPortfolio(res.data?.portfolio)
      setTaxInfo(res.data?.taxInfo)
      setSignAgreements(res.data?.signAgrementData)
      setCustomTemplate(res.data?.fieldTemplates)
      setQuestionnaireTemplate(res.data?.QuestionTemplates)
      setPayments(res.data?.payments)
      setContactTags(res.data.contactTags.data)
      localStorage.setItem('userData', JSON.stringify(user));
    }
    catch(error){
      console.log("fetchViewClientData error - ",error)
    }
    finally{
      setLoading(false)
    }
  }
  useEffect(()=>{
    fetchViewClientData()
  },[clientId,refreshData])

  let clientStateDetail = states?.filter((state)=>state.id == taxInfo?.state_id)
  let tag = details?.contact_tags?.split(',')?.map(Number);
  let defaultTags = contactTags?.filter(data => tag?.includes(data.id));

  const ACCOUNT_TABS = [
    {
      value: 'general',
      
      component: <AccountGeneral
                 allClientRequest={allClientRequest}
                 clientId={popupOn ? idOfProject :clientId.id}
                 setClientEdit={setClientEdit}
                 setClientId={setClientId}
                 setViewAccount={setViewAccount}
                 clientDetails={details}
                 tags={defaultTags?defaultTags:[]}
                 setCurrentTab={setCurrentTab}
                 setRefreshData={setRefreshData}
                 refreshData={refreshData}
                 fetchViewClientData={fetchViewClientData}
                 clientworkFlow={workFlow}
                 isClient/>
    },
    // {
    //   value: 'More Info',
    //   component: details?.user_id && (bankInfo || portfolio || details || taxInfo || signAgreements || customTemplate || questionnaireTemplate) && (
    //     <AccountNotifications 
    //       bankInfo={bankInfo}
    //       portfolio={portfolio}
    //       details={details}
    //       taxInfo={taxInfo}
    //       clientId={popupOn ? idOfProject : clientId.id}
    //       signAgreements={signAgreements}
    //       customTemplate={customTemplate}
    //       questionnaireTemplate={questionnaireTemplate}
    //       clientStateDetail={clientStateDetail}       
    //       updatingFunction={fetchViewClientData}
    //     />
    //   )
    // },
    
    
    // {
    //   value: 'Messages',
    // component: details?.user_id &&<Messages  
    //          id={popupOn ? idOfProject : clientId.id}
    //          userData={details}
    //          isClient
    //         />
      
    // },

    // details?.isVendor && {
    //   value: 'Sales Order',
    //   component:details?.user_id && <Request 
    //      refreshData={refreshData}
    //      clientId={popupOn ? idOfProject : clientId.id}
    //      clientDetails={details}
    //      isClient/>
    // },
    // {
    //   value: 'Payments',
    //   component:details?.user_id && <Payments payments={payments}/>
    // },
    {
      value: 'Agreements',
      component: details?.user_id &&<Documents agreements={signAgreements?signAgreements:[]} />
    },
    // {
    //   value: 'Calls',
    //   component:details?.user_id && <Calls phone={details?.phone}/>
    // },
    // {
    //   value: 'SMS',
    //   component:details?.user_id && <Sms name={details?.name} profilePic={details?.profile_pic}  phone={details?.phone} id={details?.id}/>
    // },
    // {
    //   value: 'Tickets',
    //   // component:details?.user_id && <Tickets fromClient={true} tickets={tickets} clientId={details?.id} />
    //   component :details?.user_id && <SupportTickets            
    //     tickets={tickets} 
    //     clientId={details?.id}
    //     fromClient={true}
    //     />
    // },
  ];

  if (!details?.isVendor) {
    ACCOUNT_TABS.push({
      value: 'Payments',
      component:details?.user_id && <Payments payments={payments}/>
    },);
  }

  if (details?.user_id && details?.isVendor) {
    ACCOUNT_TABS.push({
      value: 'Order Log',
      component: <ReceiveOrders clientId={details?.id} />
    });
  }
  if (!details?.isVendor) {
    ACCOUNT_TABS.push({
      value: 'Sales Order',
      component:details?.user_id && <Request 
         refreshData={refreshData}
         clientId={popupOn ? idOfProject : clientId.id}
         clientDetails={details}
         isClient/>
    });
  }

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const loginAsUser = () => {
    // localStorage.removeItem('is_admin')
   
    console.log("$$$$$$$$$$$$$$$^^^^^^^^^^^^^^",details);
    // loginWithUserId(1, details.user_id)
    // window.location.reload();
      // localStorage.setItem('user',"")
      //  navigate(PATH_DASHBOARD.root)
      // setLoading(true);
      loginWithUserId(1, details.user_id)
      .then(() => {
       setBackView(false)
       setTimeout(() => {
       navigate(PATH_DASHBOARD.root);
      }, 1000);
      closeView()
        // window.location.reload();
      })
      .catch((error) => {
        setBackView(true)
        
        console.error('Error logging in as user:', error);
      });
  } 
 
  return (
    <Page title={`User: Profile Settings | ${COMPANY_NAME}`}>
      <Container>
        {loading ?
            <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ height: '100vh' }}
          >
            <CircularProgress color="primary" variant="indeterminate" />
          </Stack>
         :
         <>
        {backView && <Button
          variant="outlined"
          type="submit"
          // component={RouterLink}
          // to={PATH_DASHBOARD.client.cards}
          onClick={() => {
            if (popupOn) {
              closeView(); // ✅ Closes the modal if popup is active
            } else {
              if (details?.isVendor) {
                navigate(PATH_DASHBOARD.client.vendors);
               
              } else {
                navigate(PATH_DASHBOARD.client.cards);
              }
            }
          }}
          
          sx={{ alignItems: 'flex-end', mb: 4 , mt:4 }}
          startIcon={ // Use the startIcon prop to add an icon to the left
            <Stack>
              <KeyboardBackspaceOutlined/>
            </Stack>
          }
        >
          {details?.isVendor ? "Back to All Vendors" : "Back to All Clients"}
        </Button>}
        
     
        <Stack direction={'row'} gap={2}>
          <HeaderBreadcrumbs
            heading="Profile"
            links={[
              { name: 'Dashboard', href: PATH_DASHBOARD.root },
              { name: 'User', href: PATH_DASHBOARD.client.root },
              { name: 'Profile Settings' }
            ]}
          />
          { userData.user_type === 1 && details?.user_id && (
            <Stack>
              <Button
                variant="outlined"
                type="submit"
                // color='secondary'
                // component={RouterLink}
                // to={PATH_DASHBOARD.client.cards}
                onClick={loginAsUser}
                // sx={{ alignItems: 'flex-end', mb: 2 }}
                
              >
                {/* <SwitchAccount /> */}
              Switch to {details?.name}
              </Button>
            </Stack> )}
        </Stack>
        <Stack spacing={5}>
          <Tabs
            value={currentTab}
            scrollButtons="auto"
            variant="scrollable"
            allowScrollButtonsMobile
            onChange={handleChangeTab}
          >
            {ACCOUNT_TABS.map((tab) => (
              <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
            ))}
          </Tabs>

          {ACCOUNT_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          })}
        </Stack>
        </>}
      </Container>
    </Page>
  );
}
