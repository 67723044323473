import {
  Autocomplete,
  Avatar,
  Backdrop,
  Badge,
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import Page from '../../components/Page'
import { capitalCase, sentenceCase } from 'change-case'
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill'
import trash2Outline from '@iconify/icons-eva/trash-2-outline'
import lockOpenFill from '@iconify/icons-ph/lock-open-fill'
import editFill from '@iconify/icons-eva/edit-fill'
import plusFill from '@iconify/icons-eva/plus-fill'
//materail
import * as Yup from 'yup'
import { Form, FormikProvider, useFormik } from 'formik'
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs'
import Label from 'src/components/Label'
import { useLocation, useNavigate, useParams } from 'react-router'
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import PublicMsgBoard from 'src/components/_dashboard/official-request/PublicMsgBoard'
import RequestTabUpdates from 'src/components/_dashboard/user/account/RequestTabUpdates'
import PublicMessageBoard from 'src/components/_dashboard/user/profile/PublicMessageBoard.js'
import RequestUpdates from 'src/components/_dashboard/official-request/RequestUpdates'
import PaymentRequest from 'src/components/_dashboard/official-request/PaymentRequest'
import {
  AddCircle,
  BlockOutlined,
  Delete,
  KeyboardBackspaceOutlined,
  Print,
  LocationOn,
  Add,
  Remove,
  CenterFocusStrong,
  NoteAdd,
  AddCircleOutlined,
  PictureAsPdf,
} from '@material-ui/icons'
import Modal from 'src/components/_dashboard/blog/ProjectModel'
import Icon from '@iconify/react'
import Scrollbar from 'src/components/Scrollbar'
import axios from 'axios'
import {
  REST_API,
  REST_API_END_POINT,
  SEND_EMAIL,
  UNITS,
} from 'src/constants/Defaultvalues'
import moment from 'moment'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { useSnackbar } from 'notistack'
import { LoadingButton, MobileDatePicker } from '@material-ui/lab'
import { useCallback } from 'react'
import { UploadAvatar, UploadMultiFile } from 'src/components/upload'
import AddClients from 'src/components/_dashboard/official-request/AddClients'
import AddProposal from 'src/components/_dashboard/official-request/AddProposal'
import ConfirmationPopup from 'src/components/_dashboard/official-request/ConfirmationPopup'
import ReactToPrint from 'react-to-print'
import { storage } from '../../firebase/firebase'
import {
  getDownloadURL,
  ref as imageref,
  uploadBytesResumable,
} from 'firebase/storage'
import { Link } from 'react-router-dom'
import { empty } from 'src/Helpers/Common'
import Locations from 'src/components/_dashboard/official-request/Locations'
import Workflow from 'src/components/_dashboard/general-analytics/RfqForm'
import { AccountNotifications } from '../../components/_dashboard/user/account'
import { set } from 'lodash'
import Proposals from 'src/components/_dashboard/official-request/Proposals'
import useCompanyProfile from 'src/hooks/useCompanyProfile'
import LoadingScreen from 'src/components/LoadingScreen'
import PrintableContent from './printPreview'
import { red } from '@material-ui/core/colors'
import AnimatedButton from 'src/components/animate/AnimatedButton'
import ViewDesignOrderDetails from 'src/components/_dashboard/official-request/ViewDesignOrderDetails'
const RowResultStyle = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))
const MAX_CHARACTERS = 35

const getStatusColorAndLabel = (status, hold) => {
  switch (status) {
    case 1:
      return { color: hold == 1 ? 'warning' : 'info', label: hold == 1 ? 'Status: Hold' : 'Status: Open'}
    case 2:
      return { color: hold == 1 ? 'warning' : 'success', label: hold == 1 ? 'Status: Hold' : 'Status: Approved' } // Evaluating
    case 3:
      return { color: hold == 1 ? 'warning' : 'success', label: hold == 1 ? 'Status: Hold' : 'Status: Approved' }
    case 4:
      return { color: hold == 1 ? 'warning' : 'warning', label: hold == 1 ? 'Status: Hold' : 'Status: Draft' }
    case 5:
      return { color: hold == 1 ? 'warning' : 'warning', label: hold == 1 ? 'Status: Hold' : 'Status: Closed' }
    case 6:
      return { color: hold == 1 ? 'warning' : 'error', label: hold == 1 ? 'Status: Hold' : 'Status: Rejected' }
    default:
      return { color: hold == 1 ? 'warning' : 'default', label: hold == 1 ? 'Status: Hold' : 'Status: Open' }
  }
}


const VALUE_SERVICES = [
  // { id: 0, value: 'Product Sl No.' },
  { id: 1, value: 'Service Name' },
  { id: 2, value: 'Markup (%)' },
  { id: 3, value: 'Cost (Hourly)' },
  { id: 4, value: 'Markup Cost (Hourly)' },
  { id: 5, value: 'Hours' },
  // { id: 6, value: 'Location' },
  { id: 7, value: 'Product Formula Cost' },
  { id: 8, value: 'Additional Cost' },
  { id: 9, value: 'Total cost' },
  { id: 10, value: '' },
]

export default function ViewDesignOrder() {
  const { COMPANY_LOGO, COMPANY_NAME } = useCompanyProfile()
  const theme = useTheme()
  const { id } = useParams()

  const edit = useLocation()?.state
  // const edit = false
  const xs = useMediaQuery(theme.breakpoints.down('xs'))
  const sm = useMediaQuery(theme.breakpoints.down('sm'))
  const mdd = useMediaQuery(theme.breakpoints.down('md'))
  const xld = useMediaQuery(theme.breakpoints.up('xl'))
  const componentRef = useRef()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const user = JSON.parse(localStorage.getItem('user'))
  const [currentRFQData, setCurrentRFQdata] = useState({})
  const [openEdit, setOpenEdit] = useState(false)
  const [designs, setDesigns] = useState([])
  const [details, setDetails] = useState(null)
  console.log('details++++++++++++++++++', details)
  const [skills, setSkill] = useState(null)
  const [workflow, setWorkFlow] = useState(null)
  const [allProducts, setAllproducts] = useState(null)
  const [invoice, setInvoice] = useState(null)
  const [open, setIsOpen] = useState(false)
  const [totalsum, setTotalSum] = useState(0)
  const [rfqSubTotal, setRFQSubTotal] = useState(0)
  const [DiscountTotal, setDiscountTotal] = useState(0)
  // const [totalWholesalePrice, setTotalWholesalePrice] = useState(0)
  // const [totalRetailPrice, setTotalRetailPrice] = useState(0)
  // const [totalProductFormula, setTotalProductFormula] = useState(0)
  const [addClient, setAddClient] = useState(false)
  const [addVendor, setAddVendor] = useState(false)
  const [progress, setProgress] = useState(null)
  const [addProposal, setAddProposal] = useState(false)
  const [openInvoice, setOpenInvoice] = useState(false)
  const [requestAccept, setRequestAccept] = useState(false)
  const [requestReject, setRequestReject] = useState(false)
  const [allClients, setAllClients] = useState(null)
  const [allClientsEdit, setAllClientsEdit] = useState(null)
  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'))
  const [isCommunityLoading, setIsCommunityLoading] = useState(false)
  const [communities, setCommunities] = useState([])
  const [currentTab, setCurrentTab] = useState(
    user.isVendor ? 'Details' : 'Order Details',
  )
  const [clientApprove, setClientApprove] = useState();
  const [openPreview, setOpenPreview] = useState(false)
  const [openConvertToSalesOrder, setOpenConvertToSalesOrder] = useState(false)
  const [openConvertToEstimate, setOpenConvertToEstimate] = useState(false)
  const [avatarUrl, setAvatarUrl] = useState([])
  const [openDelete, setOpenDelete] = useState(false)
  const [reOpen, setReopen] = useState(false)
  const [confirmInvoice, setConfirmInvoice] = useState(false)
  const [convertToRFQ, setConvertToRFQ] = useState(false)
  const [invoiceReject, setRejectInvoice] = useState(false)
  // const [reqAcceptClient,setReqAcceptClient] = useState(false)
  const [reason, setReason] = useState('')
  const [acceptedClients, setAcceptedClients] = useState(null)
  const [updates, setUpdates] = useState(null)
  const [isSaving, setIsSaving] = useState(false)
  const [previewData, setPreviewData] = useState({})
  const [refresh, setRefresh] = useState(false)
  const ref = useRef()
  const [proposalData, setProposalData] = useState(null)
  const [teamMembers, setTeamMember] = useState([])
  const [customerData, setCustomerData] = useState([])
  const [loading, setLoading] = useState(false)
  const printableContentRef = useRef()
  localStorage.setItem('requestId', id ? id : null)
  const userType = user.user_type
  const userId = user.user_id
  const explodedPermissions = user?.exploded_permissions
  const [data, setData] = useState({})
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [formValues, setFormValues] = useState({})
  const [formDatas, setFormDatas] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [additionalSelectedServices, setAdditionalSelectedServices] = useState([])
  const [initialSelectedServices, setInitialSelectedServices] = useState([])
  const [selectedServices, setSelectedServices] = useState([])
  const [filteredServices, setFilteredServices ] = useState([])
  const [serviceOnlyLocationStates, setServiceOnlyLocationStates] = useState({})
  const [allServiceLocationSame, setAllServiceLocationSame] = useState(false)


  const handleSendClientApproveReq = async()=>{
    try {
      console.log('datailssssssssssss',details)
      const response = await axios.post(`${REST_API_END_POINT}design-order/sent-approve-req`,{design_order_id:details.id,client:details?.client})
      if(response.data.status ===1){

        enqueueSnackbar('Success', { variant: 'success' })
        setRefresh(true)
      }else{
        enqueueSnackbar('Failed', { variant: 'error' })

      }
    } catch (error) {
      console.log("error",error)
    }
  }

  const fetchDetails = async () => {
    try {
      const res = await axios.post(
        `${REST_API_END_POINT}settings/get-companyProfile-details`,
      )
      if (res.data.status === 1) {
        setData(...res.data.data)
      }
    } catch (error) {
      console.log('get-companyProfile-details error : ', error)
    }
  }

  useEffect(() => {
    fetchDetails()
  }, [])

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue)
    setRefresh(true)
  }
  const HandleClickmenu = () => {
    setAddClient(true)
  }

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    start: Yup.mixed().required('Start Date is required'),
    end: Yup.date()
      .nullable()
      .transform((value, originalValue) => {
        return originalValue ? new Date(originalValue * 1000) : null
      })
      .when('start', (start, schema) => {
        return start
          ? schema.min(
              start,
              'Due Date must be greater than the Start Date',
            )
          : schema
      }),
    community: Yup.object().nullable().required('Project is required'),
  })
  const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    //   marginBottom: theme.spacing(1)
  }))
  const LabelStyleHead = styled(Typography)(({ theme }) => ({
    ...theme.typography.h5,
    color: theme.palette.text.secondary,
  }))
  //delete design order
  const handleDeleteOrder = async () => {
    try {
      const data = { design_order_id: id,client:details?.client }
      const res = await axios.post(
        `${REST_API}webservice/delete-design-order/id`,
        data,
      )
      if (res.data.data == true) {
        enqueueSnackbar('Order deleted successfully', { variant: 'success' })
        navigate(PATH_DASHBOARD.design.root)
      }
    } catch (error) {
      console.log(error, 'Error in deleting the order')
    }
  }
  //fetch request details
  const fetchRequestDetails = async () => {
    try {
      setLoading(true)
      const res = await axios.post(`${REST_API_END_POINT}design/get-design-order-by-id`, {
        design_order_id: id,
        userId,
        userType,
      })
      setCustomerData(res.data.customerData)
      setAvatarUrl(res.data.attachments)
      setDetails(res.data?.data[0])
      setInvoice(res.data?.data[0])
      setDesigns(res.data?.attachments)
      setUpdates(res.data.updates)
      setCommunities(res.data.community)
      setRefresh(false)
      setInitialSelectedServices(res?.data?.servicesData)
      setClientApprove(res.data?.data[0]?.client_approve);
      if (edit) {
        setOpenEdit(true)
      }
    } catch (error) {
      console.error('Error fetching design order details:', error)
    } finally {
      setLoading(false)
    }
  }


  const fetchAllCommunityByClient = async (clientId) => {
    setIsCommunityLoading(true)
    try {
      clientId = clientId.id
      const res = await axios.post(
        `${REST_API}webservice/rfq/get-community-by-client`,
        { clientId },
      )
      let data = res.data?.community
      await setCommunities(data)
    } catch (error) {
      console.log(error, 'Error in fetching all clients')
      setCommunities([])
    } finally {
      setIsCommunityLoading(false)
    }
  }

  const fetchAllClients = async () => {
    try {
      const res = await axios.post(
        `${REST_API}webservice/clients/getAllClients`,
        { userType, userId: user.user_id ,order:true},
      )
      let data = res.data?.customer
      await setAllClientsEdit(data)
    } catch (error) {
      console.log(error, 'Error in fetching all clients', error)
    }
  }

  const fetchAllServices =async()=>{
    await axios.post(`${REST_API}webservice/request/get-all-request`, values)
    .then((res) => {
      let result =res?.data?.labourCostServices?.filter((item) => item?.status === 1)
      // setFilteredServices(res.data.labourCostServices)
      setFilteredServices(
        res.data.labourCostServices.filter(
          (service) => service.service_type === 33
        )
      )
    })
    .catch((errors) => {
      console.log(errors, 'error in fetching all requests')
    })
  }

  // const filteredOptions = useMemo(() => {
  //   return filteredServices?.filter((item) => item?.status === 1) || [];
  // }, [filteredServices]);
  

  // useEffect(() => {
  //   fetchRequestDetails()
  //   fetchAllClients()
  //   fetchAllServices()
  // }, [id, refresh])

  useEffect(() => {
    const fetchAll = async () => {
      await Promise.all([fetchRequestDetails(), fetchAllClients(), fetchAllServices()])
     
    }
  
    fetchAll()
  }, [id, refresh])


  const handleSendEmail = async () => {
    try {
      let orderId = details?.id
      const res = await axios.post(`${REST_API}webservice/settings/send-mail`, {
        id: orderId,
      })

      if (res.data.status === 1) {
        enqueueSnackbar('Email send successfully', { variant: 'success' })
        setOpenInvoice(false)
      }
      return res.data
    } catch (error) {
      console.error('Error fetching request details:', error)
      throw error
    }
  }
  const addingInitialdataRFQ = async () => {
    // setLoading(true)
    try {
      if (openEdit) {
        let rfqCustomerData = allClientsEdit?.find(
          (cat) => cat?.id === customerData?.id,
        )
        let rfqCommunity = communities?.find(com => details?.community === com?.id)
        setCurrentRFQdata({
          name: details?.name,
          description: details?.description,
          notes: details?.notes,
          client: rfqCustomerData,
          start: details?.start_date,
          community: rfqCommunity,
          end: details?.due_date,
          welcome_message: details?.welcome_message == 1 ? true : false || null,
          draft: details?.status == 4 ? true : false || null,
          order_id: invoice?.id,
          subject: details?.subject || null,
          email_content: details?.email_content || null,
          // avatarUrl:details?.thumbnail||null
        })

         // Map the services data
      let result = initialSelectedServices.map((val) => {
        // Find the matching service in filteredServices
        let matchedService = filteredServices.find(
          (service) => service.id === val.labour_cost_service_id
        );

        if (matchedService) {
          // Create a new object with updated quantity and total
          return {
            ...matchedService,
            quantity: val.quantity,
            total: val.total,
          };
        }
        return null; // Return null for unmatched services (optional)
      }).filter((item) => item !== null); // Remove any null entries

      // Set the matched and updated services to additionalSelectedServices
      setAdditionalSelectedServices(result);


      }
      
    } catch (error) {
      console.error('Error adding initial data for Sales Order:', error)
    } finally {
      // setTimeout(() => {
      //   setLoading(false)
      // }, 3000);
    }
  }
  const handleDesignCount = async () => {
    try {
      const response = await axios.post(`${REST_API}webservice/design/reduce-count`, {
        userId,
        design_order_id:id,
      });
      
    } catch (error) {
      console.error("Error reducing design order count:", error);
    }
  };
  useEffect(() => {
    handleDesignCount()
  }, [])
  

  useEffect(() => {
    // setLoading(true)
    addingInitialdataRFQ()
    setTimeout(() => {
      // setLoading(false)
    }, 3000)
  }, [openEdit, filteredServices, initialSelectedServices])
  // }, [openEdit])

  const invoiceDate = moment.unix(details?.timestamp).format('MM/DD/YYYY')

  //edit rfq
  const handleEditClick = () => {
    setOpenEdit(!openEdit)
  }
  const handleDeleteMenu = () => {
    setOpenDelete(!openDelete)
  }
  const handleReopen = () => {
    setReopen(!reOpen)
  }


  const roundToThreeDecimals = (num) => Math.round(num * 1000) / 1000;

  const calculateServiceTotal = (price, quantity, discount, location = 1) => {
    const parsedQuantity = parseFloat(quantity) || 1;
    const parsedDiscount = parseFloat(discount) || 0;
    const locationQuantity = parseFloat(location) || 1
    const subtotal = price * parsedQuantity * locationQuantity;
    if (discount) {
      const totalDiscount = subtotal - discount;
      return totalDiscount;
    } else {
      return subtotal;
    }
  };


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: currentRFQData.name || '',
      description: currentRFQData.description || '',
      notes: currentRFQData.notes || '',
      clients: currentRFQData.client || null,
      start: currentRFQData.start || currentDate,
      end: currentRFQData.end || currentDate,
      community: currentRFQData?.community || null,
      welcome_message: currentRFQData.welcome_message || false,
      draft: currentRFQData.draft || false,
      client_approve : clientApprove == 0 ? false : true,
      order_id: currentRFQData.order_id || '',
      subject: currentRFQData.subject || '',
      email_content: currentRFQData.email_content || '',
      editReq: true,
      quantityFieldName:null
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        if(additionalSelectedServices?.length <= 0){
          enqueueSnackbar('Please choose atleast one service', {variant: 'error',})
          return;
        }
        setIsSubmitted(true)
        const design_order_id = id
        const clientsArray = values.clients ? [values.clients] : []
        values.clientsData = clientsArray
        values.userName = user.first_name
        values.avatarUrl = avatarUrl.map(file => ({
          extension: file.extension,
          file_type: file.file_type,
          size: file.file_size || file.size,
          url: file.name || file.url,
        }));


        let selectedServicesTotalCost = additionalSelectedServices?.reduce((total, service, index) => {
          const serviceTotal = calculateServiceTotal(service?.total_cost, service?.quantity, 0, 1);
          // return total + Number(service?.total);
          return total + Number(serviceTotal);
        }, 0);
        selectedServicesTotalCost = roundToThreeDecimals(selectedServicesTotalCost);

        const data = {
          values,
          userId,
          userType,
          design_order_id,
          selectedServices :additionalSelectedServices,
          totalCost:selectedServicesTotalCost
        }
        setFormValues(values)
        setFormDatas(data)
        handleConfirmSubmit(values, data)
      } catch (error) {
        console.error(error)
      }
    },
  })

  const handleConfirmSubmit = async (values = null, data = null) => {
    setIsSubmitting(true)
    try {
      const formValuesData = values || formValues
      const formDatasData = data || formDatas
      console.log('Form Values:', formValuesData)
      console.log('Form Data:', formDatasData)
      await axios
        .post(`${REST_API}webservice/design/update-design-order`, formDatasData)
        .then((res) => {
          setOpenEdit(false)
          setIsOpen(false)
          setCurrentRFQdata({})
          fetchRequestDetails()
          if (edit) {
            navigate(`/dashboard/design-order`)
          }
          enqueueSnackbar('Update success', { variant: 'success' })
          formik.resetForm()
          // resetForm();
        })
        .catch((errors) => {
          console.log(errors, 'error')
        })
    } catch (error) {
      console.error('Error submitting form:', error)
      enqueueSnackbar('An error occurred during submission.', {
        variant: 'error',
      })
    } finally {
      setIsSubmitting(false)
      setFormValues({})
      setFormDatas({})
    }
  }

  const current = moment()

  const handleAcceptorRejectInvoice = async (value) => {
    try {
      if (value === 2 && reason === '') {
        enqueueSnackbar('Please fill rejected reason ', { variant: 'warning' })
        return
      } else {
        let design_order_id = id
        let acceptStatus = value
        let userId = user.user_id
        let newStatus = (value == 1) ? 2 : 6
        const data = {
          design_order_id,
          acceptStatus,
          newStatus,
          userId,
          userType,
          reason,
        }
        const res = await axios.post(
          `${REST_API}webservice/design/accept-or-reject-order`,
          data,
        )

        if (res.data.status == 1) {
          // setOpenInvoice(!openInvoice)
          setOpenInvoice(false)
          setConfirmInvoice(false)
          setRejectInvoice(false)
          enqueueSnackbar('Order Approved', { variant: 'success' })
          fetchRequestDetails()
        } else if (res.data.status == 2) {
          setOpenInvoice(!openInvoice)
          setConfirmInvoice(false)
          setRejectInvoice(false)
          enqueueSnackbar('Order Rejected', { variant: 'error' })
          setReason('')
          navigate(PATH_DASHBOARD.design.root)
        } else {
          setOpenInvoice(!openInvoice)
          setConfirmInvoice(false)
          setRejectInvoice(false)
          enqueueSnackbar(res.data.message, { variant: 'error' })
        }
      }
    } catch (error) {
      console.log(error, 'error in accepting invoice')
      setConfirmInvoice(false)
      setRejectInvoice(false)
      enqueueSnackbar('Server Error', { variant: 'error' })
    }
  }

  const handleConvertToSalesOrder = async (existingJob = false) => {
    try {
      setOpenConvertToSalesOrder(false)
      setConvertToRFQ(true)
      let design_order_id = id
      let userId = user.user_id
      let userType = user.user_type
      let userName = user.first_name;

      const data = { design_order_id, userId, userType, userName, salesOrder:true,isDesignOrder:true }
      const res = await axios.post( `${REST_API}webservice/design/convert-design-to-sales-order`, data )

      if (res.data.request_id) {
        const id = res.data.request_id
        setConfirmInvoice(false)
        setRejectInvoice(false)
        enqueueSnackbar('Order converted to Sales Order successfully',{variant:"success"})
        navigate(`/dashboard/official-request/view/${id}`,{state:true})
      }else {
        setConfirmInvoice(false)
        setRejectInvoice(false)
        enqueueSnackbar('Failed to convert Design to Sales Order', { variant: 'error' })
      }
    } catch (error) {
      console.log(error, 'error in Converting Design to Sales Order')
      setConfirmInvoice(false)
      setRejectInvoice(false)
      enqueueSnackbar('Server Error', { variant: 'error' })
    } finally {
      setConvertToRFQ(false)
    }
  }

  const handleConvertToEstimate = async (existingJob = false) => {
    try {
      setOpenConvertToEstimate(false)
      setConvertToRFQ(true)
      let design_order_id = id
      let userId = user.user_id
      let userType = user.user_type
      let userName = user.first_name;

      const data = { design_order_id, userId, userType, userName, salesOrder:null,isDesignOrder:true }
      const res = await axios.post( `${REST_API}webservice/design/convert-design-to-sales-order`, data )

      if (res.data.request_id) {
        const id = res.data.request_id
        setConfirmInvoice(false)
        setRejectInvoice(false)
        enqueueSnackbar('Order converted to Estimate successfully',{variant:"success"})
        navigate(`/dashboard/quick-bid/view/${id}`,{state:true})
      }else {
        setConfirmInvoice(false)
        setRejectInvoice(false)
        enqueueSnackbar('Failed to convert Design to Estimate', { variant: 'error' })
      }
    } catch (error) {
      console.log(error, 'error in Converting Design to Estimate')
      setConfirmInvoice(false)
      setRejectInvoice(false)
      enqueueSnackbar('Server Error', { variant: 'error' })
    } finally {
      setConvertToRFQ(false)
    }
  }

  const handleReopeningRequest = async () => {
    try {
      let requestId = id
      let userId = user.user_id
      const data = { requestId, userId }
      const res = await axios.post(`${REST_API}webservice/request/reopen`, data)
      if (res.data.status == 1) {
        enqueueSnackbar('Request reopened successfully', { variant: 'success' })
        setReopen(false)
        fetchRequestDetails()
      } else if (res.data.status == 0) {
        enqueueSnackbar('Request reopened failed', { variant: 'error' })
        setReopen(false)
      }
    } catch (error) {
      console.log(error, 'Error in reopen')
    }
  }

  const {
    errors,
    values,
    touched,
    handleSubmit,
    setFieldValue,
    getFieldProps,
  } = formik

  const setValuesToPreview = () => {
    setPreviewData(values)
  }

  const handleRemoveAll = () => {
    setAvatarUrl([])
  }

  const handleRemove = (file) => {
    const filteredItems = avatarUrl.filter((_file) => _file !== file)
    if(filteredItems){
      setAvatarUrl(filteredItems)
    }else{
      setAvatarUrl([])
    }
  }

  ///////
  // const handleDrop = useCallback(
  //   (acceptedFiles) => {
  //     if (acceptedFiles && acceptedFiles.length > 0) {
  //       const file = acceptedFiles[0]
  //       const Filename = new Date().getTime() + file.name

  //       const StorageRef = imageref(storage, Filename)
  //       const uploadTask = uploadBytesResumable(StorageRef, file)

  //       uploadTask.on(
  //         'state_changed',
  //         (snapshot) => {
  //           const progress = Math.round(
  //             (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
  //           )
  //         },
  //         (error) => {
  //           console.error(error.message)
  //         },
  //         () => {
  //           getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
  //             setFieldValue('avatarUrl', downloadURL)
  //           })
  //         },
  //       )
  //     }
  //   },
  //   [setFieldValue],
  // )
  const handleDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const uploadPromises = acceptedFiles?.map((file) => {
          return new Promise((resolve, reject) => {
            const Filename = new Date().getTime() + file.name
            const StorageRef = imageref(storage, Filename)
            const uploadTask = uploadBytesResumable(StorageRef, file)

            uploadTask.on(
              'state_changed',
              (snapshot) => {
                const progress = Math.round(
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
                )
                setProgress(progress)
              },
              (error) => {
                console.error(error.message)
                reject(error)
              },
              () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                  resolve({
                    url: downloadURL,
                    file_type: file.type,
                    extension: file.name.split('.').pop(),
                    size: file.size,
                  })
                })
              },
            )
          })
        })

        Promise.all(uploadPromises)
          .then((downloadURLs) => {
            setAvatarUrl((prevAvatarUrls) => {
              const updatedFiles = downloadURLs.map((file) => ({
                url: file.url,
                file_type: file.file_type,
                extension: file.extension,
                size: file.size,
              }))
              return [...prevAvatarUrls, ...updatedFiles]
            })
          })
          .catch((error) => {
            console.error(error.message)
          })
      }
    },
    [setFieldValue],
  )
  let subTotal = 0
  const ACCOUNT_TABS = [
    {
      value: `Order Details`,
      component: details ? (
        <ViewDesignOrderDetails
          teamMembers={teamMembers}
          clientDetails={invoice}
          products={invoice?.cust_product}
          fetchRequestDetails={fetchRequestDetails}
          user={user}
          designs={designs}
          setDesigns={setDesigns}
          details={details}
          isRequest={true}
          requestId={id}
          invoice={invoice}
          isDesign={true}
        />
      ) : (
        ''
      ),
    },
    ...((user.user_type === 3 || explodedPermissions?.some((permission) => ['Access Design Order Public Message Board'].includes(permission))) ? [
    {
      value: 'public message board',
      component: (
        <PublicMessageBoard
          products={invoice?.cust_product}
          requestDetails={details}
          requestId={id}
          acceptedClients={[customerData]}
          fromDesign={true}
          isDesignOrder={true}
        />
      ),
    }
  ] : []),
    ...(user.isVendor
      ? []
      : [
          {
            // value: 'change order',
            value: 'Job Log',
            component: (
              <RequestTabUpdates updates={updates?.length > 0 ? updates : []} />
            ),
          },
        ]),
  ]

  const handleCancel = async () => {
    setOpenEdit(false)
    setIsOpen(false)
    setCurrentRFQdata({})
    fetchRequestDetails()

    if (edit) {
      navigate(`/dashboard/design-order`)
    }
  }



  let selectedServicesTotalCost = additionalSelectedServices?.reduce((total, service, index) => {

    const serviceTotal = calculateServiceTotal(service?.total_cost, service?.quantity, 0, 1);
   
    
    // return total + Number(service?.total);
    return total + Number(serviceTotal);
  }, 0);

// selectedServicesTotalCost = roundToThreeDecimals(selectedServicesTotalCost);
  const handleChangeSort = (category) => {
    setSort(category)
    setSortOpen(false)
  }

  
  selectedServicesTotalCost = roundToThreeDecimals(selectedServicesTotalCost);
  const selectedServicesSubTotal = selectedServicesTotalCost 

  let totalAmount = 0;
  let totalMarkup = 0;
  let totalProductFormula = 0;
  let totalWholesalePrice = 0;
  let totalRetailPrice = 0;
  let originaltotalMarkup = 0
  let originaltotalServiceMarkup = 0
  let totalServiceMarkup = 0;


  const memoizedRows = useMemo(() => {
    let totalServiceMarkup = 0;
    let originaltotalServiceMarkup = 0;
    let totalProductFormula = 0;
    let totalWholesalePrice = 0;
    let totalRetailPrice = 0;
  
    // Map over additionalSelectedServices to calculate values without mutating
    const updatedRows = additionalSelectedServices.map((data, index) => {
      const quantity = data?.quantity || 1; // Default to 1 if undefined
      const total = data.total_cost * quantity; // Calculate total
      const markup = total > 0 ? (data.total_cost / total).toFixed(2) : 0;
  
      // Calculate original markup
      const originalMarkup = data.originalMarkup || 0;
  
      // Update totals
      totalServiceMarkup += Number(markup);
      originaltotalServiceMarkup += Number(originalMarkup);
      const productFormulaCost = (data.cost * quantity).toFixed(2);
      totalProductFormula += Number(productFormulaCost);
      totalWholesalePrice += Number(total);
      totalRetailPrice += Number(total);
  
      // Calculate quantityDifference (if needed)
      const quantityDifference = Number(quantity);
  
      // Return updated row without mutating the original object
      return {
        ...data,
        quantity,
        total: total.toFixed(2), // Include total
        markup,
        originalMarkup, // Include originalMarkup
        totalServiceMarkup: totalServiceMarkup.toFixed(2),
        quantityDifference, // Include quantityDifference
        jobcost_unit_cost: data.cost,
        jobcost_quantity: quantity,
        jobcost_total_cost: (data.cost * quantity).toFixed(2),
        quantity
      };
    });
  
    // Update Formik values only once after calculations
    values['totalServiceMarkup'] = totalServiceMarkup.toFixed(2);
    values['totalProductFormula'] = totalProductFormula.toFixed(2);
    values['totalWholesalePrice'] = totalWholesalePrice.toFixed(2);
    values['totalRetailPrice'] = totalRetailPrice.toFixed(2);
  
    return updatedRows;
  }, [additionalSelectedServices]);
  
  
  const handleDeleteService = (id) => {
    const updatedServices = additionalSelectedServices.filter(
      (service) => service.id !== id
    );
  
    // Update selected services
    setAdditionalSelectedServices(updatedServices);
  
    // Optional: Update options if necessary
    // setFilteredServices((prevServices) =>
    //   prevServices.filter((service) => service.id !== id)
    // );
  };
  
  

  return (
    <Page title="View Request: View | Sign Wise Solutions">
      {loading || convertToRFQ ? (
        <Stack
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          <LoadingScreen />
        </Stack>
      ) : (
        <Container>
          <Button
            variant="outlined"
            type="submit"
            // onClick={()=>navigate(PATH_DASHBOARD.official.root)}
            // onClick={() => { window.history.back() }}
            onClick={() => (openEdit ? handleCancel() : window.history.back())}
            sx={{ alignItems: 'flex-end', mb: 4 }}
            startIcon={
              // Use the startIcon prop to add an icon to the left
              <Stack>
                <KeyboardBackspaceOutlined />
              </Stack>
            }
          >
            Back
          </Button>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <HeaderBreadcrumbs heading={details?.name} />
            {!openEdit && (
              <Stack marginBottom={5} flexDirection={'column'}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                </Stack>
                {userType != 3 ? (
                  <Stack>
                    <Stack flexDirection={'row'} gap={2}>
                      {/* {details?.design_order_job_status === 3 || details?.status === 3 || details?.hold == 1 ? ( */}
                        {(details?.status === 3 || details?.status === 2) || details?.hold == 1 ? (
                        ''
                      ) : (
                        <Tooltip title="Edit">
                          <IconButton
                            sx={{ backgroundColor: theme.palette.divider }}
                            onClick={() => {
                              explodedPermissions?.some((permission) =>
                                ['Edit Design Order'].includes(permission),
                              )
                                ? handleEditClick()
                                : enqueueSnackbar(
                                    'Access Denied: You do not have sufficient permissions',
                                    { variant: 'error' },
                                  )
                            }}
                          >
                            <Icon
                              icon={editFill}
                              width={24}
                              height={24}
                              color="#ffffff"
                            />
                          </IconButton>
                        </Tooltip>
                      )}

                      {/* {details?.design_order_job_status === 3 || details?.status === 3 || details?.hold == 1 ? ( */}
                      { details?.status === 3  ? (
                        ''
                      ) : (
                        <Tooltip title="Delete">
                          <IconButton
                            sx={{ backgroundColor: theme.palette.divider }}
                            onClick={() => {
                              explodedPermissions?.some((permission) =>
                                ['Delete Design Order'].includes(permission),
                              )
                                ? handleDeleteMenu()
                                : enqueueSnackbar(
                                    'Access Denied: You do not have sufficient permissions',
                                    { variant: 'error' },
                                  )
                            }}
                          >
                            <Icon
                              icon={trash2Outline}
                              width={24}
                              height={24}
                              color="#ffffff"
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      {explodedPermissions?.some(permission => ['Create Design Order','Edit Design Order','Delete Design Order'].includes(permission)) ? 
                        (details?.status === 2 && details?.hold == 0 && (
                          <AnimatedButton
                            title={'Convert to Estimate'}
                            onClick={() => {
                              setOpenConvertToEstimate(true);
                            }}
                          />
                        )) : ''}
                      {explodedPermissions?.some(permission => ['Create Design Order','Edit Design Order','Delete Design Order'].includes(permission)) ?
                        details?.status === 2 && details?.hold == 0 && (
                          <AnimatedButton
                            title={'Convert to Sales Order'}
                            onClick={() => {
                              setOpenConvertToSalesOrder(true);
                            }}
                          />
                        ) : '' }
                    </Stack>
                  </Stack>
                ) : (
                  ''
                )}
              </Stack>
            )}
          </Stack>
          {openEdit && (
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Card sx={{ p: 3 }}>
                      <Stack spacing={3}>
                        <Stack
                          direction={{ xs: 'column', sm: 'row' }}
                          spacing={{ xs: 3, sm: 2 }}
                        >
                          <Stack flexDirection={'column'}>
                            {/* <LabelStyle>Name</LabelStyle> */}
                            <TextField
                              //   fullWidth
                              sx={{ width: 400 }}
                              label="Order Name"
                              {...getFieldProps('name')}
                              error={Boolean(touched.name && errors.name)}
                              helperText={touched.name && errors.name}
                            />
                          </Stack>
                        </Stack>
                        {/* <LabelStyle>Description</LabelStyle> */}
                        <TextField
                          fullWidth
                          multiline
                          minRows={3}
                          maxRows={7}
                          label="keep the summary brief and make it communicates"
                          {...getFieldProps('description')}
                          error={Boolean(
                            touched.description && errors.description,
                          )}
                          helperText={touched.description && errors.description}
                        />
                        <LabelStyle>Upload Design</LabelStyle>
                        {/* <UploadAvatar
                          accept="image/*"
                          file={values.avatarUrl}
                          maxSize={3145728}
                          onDrop={handleDrop}
                          error={Boolean(touched.avatarUrl && errors.avatarUrl)}
                        /> */}
                        <UploadMultiFile
                          showPreview
                          maxSize={3145728}
                          accept="image/*,application/pdf"
                          files={avatarUrl}
                          onDrop={handleDrop}
                          onRemove={handleRemove}
                          onRemoveAll={handleRemoveAll}
                          // error={Boolean(touched.avatarUrl && errors.avatarUrl)}
                        />
                         <Stack
                              direction={{ xs: 'column', sm: 'row' }}
                              spacing={{ xs: 3, sm: 2 }}
                            >
                              <Autocomplete
                                // multiple
                                // fullWidth
                                disabled={true}
                                  sx={{ width: '50%' }}
                                id="combo-box-demo"
                                options={allClientsEdit}
                                value={values.clients || null}
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    setFieldValue('clients', newValue)
                                    setFieldValue('community', null)
                                    fetchAllCommunityByClient(newValue)
                                  } else {
                                    setFieldValue('clients', null)
                                    setFieldValue('community', null)
                                    setCommunities([])
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label="Clients"
                                    error={Boolean(
                                      touched.clients && errors.clients,
                                    )}
                                    helperText={
                                      touched.clients && errors.clients
                                    }
                                  />
                                )}
                                getOptionLabel={(option) => option.name}
                              />
                              {isCommunityLoading ?
                                <Stack flexDirection={'row'} alignItems='center' justifyContent='center'>
                                  <CircularProgress color="primary" variant="indeterminate" />
                                  <Typography variant='body2'>Loading projects...</Typography>
                                </Stack> :
                                ((communities?.length > 0 ? (
                                  <Autocomplete
                                    // fullWidth
                                    sx={{ width: '50%' }}
                                    id="community"
                                    options={communities || []}
                                    value={values?.community || null}
                                    onChange={(event, newValue) =>{
                                      if(newValue){
                                        setFieldValue('community', newValue)
                                      }else {
                                        setFieldValue('community', null);
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        required
                                        {...params}
                                        label="Project"
                                        error={Boolean(touched.community && errors.community)}
                                        helperText={touched.community && errors.community}
                                      />
                                    )}
                                    getOptionLabel={(option) => option?.name || ''}
                                  />
                                ) : (
                                  <Stack sx={{ width: '50%' }} justifyContent={'center'}>
                                    {values?.clients ?
                                      <Typography sx={{ color: "red" }}>The selected client does not have any projects yet. Please create a new project for them!</Typography>
                                      : <Typography sx={{ color: "red" }}>No client selected. Please choose a client from the list.</Typography>}
                                  </Stack>
                                )))
                              }
                            </Stack>

                        <Stack
                          direction={{ xs: 'column', sm: 'row' }}
                          spacing={{ xs: 3, sm: 2 }}
                        >
                          <Box
                            component={MobileDatePicker}
                            label="Start Date"
                            minDate={moment()}
                            defaultValue={moment
                              .unix(values.start)
                              .format('MM/DD/YYYY')}
                            value={moment.unix(values.start)}
                            onChange={(date) =>
                              setFieldValue('start', moment(date).unix())
                            }
                            renderInput={(params) => (
                              <TextField size="small" {...params} fullWidth />
                            )}
                          />

                          <Box
                            component={MobileDatePicker}
                            label="Due Date"
                            minDate={moment()}
                            defaultValue={moment
                              .unix(values.end)
                              .format('MM/DD/YYYY')}
                            value={moment.unix(values.end)}
                            onChange={(date) =>
                              setFieldValue('end', moment(date).unix())
                            }
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                {...params}
                                fullWidth
                                error={Boolean(touched.end && errors.end)}
                                helperText={touched.end && errors.end}
                              />
                            )}
                          />
                        </Stack>

                        <Stack direction='row'>
                            <LabelStyleHead>Services</LabelStyleHead>
                        </Stack>

                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                            <Autocomplete
                              sx={{ width: 400 }}
                              multiple
                              id="combo-box-demo"
                              options={filteredServices || []}
                              // value={additionalSelectedServices} 
                              value={filteredServices?.filter(service => 
                                additionalSelectedServices?.some(selected => selected.id === service.id)
                              ) || []}
                              onChange={(event, newValue) => {
                                if(newValue){
                                  setAdditionalSelectedServices(newValue)

                                }
                              }}
                          
                              renderInput={(params) => (
                                <TextField {...params} required
                                  error={Boolean(touched.services && errors.services)}
                                  helperText={touched.services && errors.services}
                                  label='Select services'/>
                              )}
                              getOptionLabel={(option) => option.name ? option.name : ''}
                            />
                          </Stack>

                            <LabelStyle>Selected services</LabelStyle>

                            <Scrollbar>
                              <TableContainer sx={{ minWidth: 500 }}>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      {VALUE_SERVICES?.map((val) => (
                                        // ((values.project_categories?.name == 'Time & Material') && val.id === 6) ? <TableCell></TableCell> : 
                                        ((((values.project_categories?.name == 'Service only') || 
                                            (values.project_categories?.name == 'Time & Material')) && val.id === 0)
                                          //  || ((values.project_categories?.name !== 'Service only') && val.id === 6)
                                          ) ? null : (
                                        <TableCell>{val.value}</TableCell>
                                        ))
                                      )}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>

                                    {memoizedRows?.map((data, index) => {
                                      
                                    
                                      // const quantityFieldName = `service_quantity_${index}`;
                                      // const dataQuantity = data?.quantity ? data?.quantity : 1;
                                      // data['quantity'] = dataQuantity
                                      // const total = data.total_cost * data?.quantity
                                      // let markup = 0;
                                      // if (total > 0) {
                                      //   markup = data.total_cost / Number(total);
                                      //   data['markup'] = markup?.toFixed(2);
                                      // } else {
                                      //   markup = 0
                                      //   data['markup'] = 0;
                                      // }
                                      // totalServiceMarkup += markup
  
                                      // let originalMarkup = data.originalMarkup || 0
                                      // originaltotalServiceMarkup += originalMarkup
                                      // data['total'] = total?.toFixed(2)
                                      // data['totalServiceMarkup'] = totalServiceMarkup?.toFixed(2)
                                      // values['totalServiceMarkup'] = totalServiceMarkup?.toFixed(2)
                                      // const quantityDifference = Number(data.quantity)
                                      // data['quantityDifference'] = quantityDifference
                                      // data['jobcost_unit_cost'] = data.cost
                                      // data['jobcost_quantity'] = quantityDifference
                                      // data['jobcost_total_cost'] = (data.cost*Number(data.quantity) || 1)?.toFixed(2)
  
                                      // let newTotalCost = total?.toFixed(2)
                                      // let newTotalProductFormula = (data.cost*quantityDifference)?.toFixed(2)
                                      // totalProductFormula += Number(newTotalProductFormula)
                                      // totalWholesalePrice += Number(newTotalCost)
                                      // totalRetailPrice += Number(newTotalCost)
                                      // values['totalProductFormula'] = totalProductFormula
                                      // values['totalWholesalePrice'] = totalWholesalePrice
                                      // values['totalRetailPrice'] = totalRetailPrice
  
                                      return (
                                        <>
                                          <TableRow key={data.id}>
                                          {/* {(values.project_categories?.name != 'Service only' && values.project_categories?.name != 'Time & Material') ? (
                                            <TableCell></TableCell>
                                          ) : null} */}
                                            <TableCell>{data.name}</TableCell>
                                            {/* <TableCell>{originalMarkup?.toFixed(2)}</TableCell> */}
                                            <TableCell>{data.originalMarkup?.toFixed(2)}</TableCell>
                                            <TableCell>{data.cost}</TableCell>
                                            <TableCell>{data.total_cost}</TableCell>
                                            <TableCell>
                                              <TextField
                                                type="number"
                                                // sx={{ width: 400 }}                                                                                
                                                label={'Hours'}
                                                value={ Number(data.quantity) || 1}
                                                defaultValue={1}
                                                onChange={(event) => {
                                                  let newValue = event.target.value;
                                                  
                                                  if (newValue.startsWith('-')) {
                                                    newValue = newValue.slice(1);
                                                  }
                                                  data['quantity'] = newValue || 1;

                                                  setAdditionalSelectedServices((prevServices) =>
                                                    prevServices.map((service) =>
                                                      service.id === data.id
                                                        ? { ...service, quantity: newValue } // Update quantity for the matching service
                                                        : service // Keep other services unchanged
                                                    )
                                                  );
                                                

                                                }}
                                                
                                              />
                                            </TableCell>
{/*                                                                    
                                            <TableCell>
                                              <IconButton
                                                onClick={() => {
                                                  setServiceOnlyLocationStates({ ...serviceOnlyLocationStates, [data.indexId]: true });
                                                }}
                                              >
                                                <LocationOn sx={{
                                                  color: data?.addresses?.some(obj =>
                                                    Object.values(obj).some(value => value === '')
                                                  ) ? '' : theme.palette.primary.main
                                                }} />
                                              </IconButton>
                                            </TableCell> */}
                                            {/* ) : null} */}
                                            <TableCell>{(data.cost*data.quantityDifference?.toFixed(2))?.toFixed(2)}</TableCell>
                                            {/* <TableCell>{(data.cost*quantityDifference?.toFixed(2))?.toFixed(2)}</TableCell> */}
                                            <TableCell>{data?.total}</TableCell>
                                            <TableCell>{data?.total}</TableCell>
                                            <TableCell align='right'>
                                              <IconButton onClick={() => handleDeleteService(data.id)}>
                                                <Delete />
                                              </IconButton>
                                            </TableCell>
                                          </TableRow>
                                        </>
                                      );
                                    })}
                                    <TableRow>
                                      <TableCell colSpan={8}>
                                        <Typography variant="h6" align="right">
                                          Total Additional Service Cost: {selectedServicesSubTotal?.toFixed(2)}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Scrollbar>

                        <Divider />

                        <LabelStyle>Notes</LabelStyle>
                        <TextField
                          fullWidth
                          multiline
                          minRows={3}
                          maxRows={7}
                          label="Notes"
                          {...getFieldProps('notes')}
                          error={Boolean(touched.notes && errors.notes)}
                          helperText={touched.notes && errors.notes}
                        />

                        <Typography variant="h6" align="right">
                          Total Cost: {selectedServicesSubTotal?.toFixed(2)}
                        </Typography>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                            {/* <FormControlLabel
                          control={
                            <Switch
                            {...getFieldProps('client_approve')}
                              color="primary"
                              checked={values.client_approve}
                              onChange={(event) => setFieldValue('client_approve', event.target.checked)}
                            />
                          }
                          labelPlacement="end"
                          label="Enable forClient  Approval"
                          sx={{ mt: 0 }}
                        /> */}
                          </Stack>

                        {/* <Stack
                          direction={{ xs: 'column', sm: 'row' }}
                          spacing={{ xs: 3, sm: 2 }}
                        >
                          <FormControlLabel
                            onChange={() => {
                              // Toggle the value of welcome_message using setCurrentRFQdata
                              setCurrentRFQdata((prevData) => ({
                                ...prevData,
                                welcome_message: !prevData.welcome_message,
                              }))
                            }}
                            control={
                              <Switch
                                {...getFieldProps('welcome_message')}
                                color="primary"
                                checked={values.welcome_message == 1}
                              />
                            }
                            labelPlacement="end"
                            label="Use Custom Welcome Message"
                            sx={{ mt: 5 }}
                          />
                        </Stack>
                        {values.welcome_message == true && (
                          <>
                            <Stack>
                              <LabelStyle>Subject</LabelStyle>
                              <TextField
                                //   fullWidth
                                type="text"
                                sx={{ width: 400 }}
                                defaultValue={values.subject}
                                {...getFieldProps('subject')}
                                error={Boolean(
                                  touched.subject && errors.subject,
                                )}
                                helperText={touched.subject && errors.subject}
                              />
                            </Stack>
                            <LabelStyle>Email Content</LabelStyle>
                            <TextField
                              fullWidth
                              multiline
                              minRows={3}
                              maxRows={7}
                              label="Email Content"
                              defaultValue={values.email_content}
                              {...getFieldProps('email_content')}
                              error={Boolean(
                                touched.email_content && errors.email_content,
                              )}
                              helperText={
                                touched.email_content && errors.email_content
                              }
                            />
                          </>
                        )}
                        <LabelStyle>Request state</LabelStyle>
                        <Typography>
                          While in draft mode, clients you add to request tasks
                          won't be notified
                        </Typography>
                        <FormControlLabel
                          onChange={() => {
                            setCurrentRFQdata((prevData) => ({
                              ...prevData,
                              draft: !prevData.draft,
                            }))
                          }}
                          control={
                            <Switch
                              {...getFieldProps('draft')}
                              color="primary"
                              checked={values.draft}
                            />
                          }
                          labelPlacement="end"
                          label="Draft"
                          sx={{ mt: 5 }}
                        /> */}
                        <Box
                          sx={{
                            mt: 3,
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: 1,
                          }}
                        >
                          {progress != null && progress < 100 ? (
                            <CircularProgress color="primary" variant="determinate" value={progress} />
                          ) : (
                            <>
                              <LoadingButton
                                type="submit"
                                variant="contained"
                                loading={isSubmitting}
                              >
                                Save Order
                              </LoadingButton>
                              <Button
                                variant="outlined"
                                onClick={() => handleCancel()}
                              >
                                Cancel
                              </Button>
                            </>
                          )}
                        </Box>
                      </Stack>
                    </Card>
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          )}
          {!openEdit && (
            <>
              <Stack
                direction={'row'}
                display={'flex'}
                alignItems={'center'}
                spacing={{ xs: 3, sm: 2 }}
              >
                {details?.id &&
                  invoice?.total !== 0 &&
                  !user.isVendor &&
                  (empty(details?.installation_acceptance) &&
                  !empty(details?.allInstallationCompleted) ? (
                    <AnimatedButton
                      title={'Details'}
                      onClick={() => setOpenInvoice(!openInvoice)}
                    />
                  ) : (
                    <Link
                      color={theme.palette.success.dark}
                      onClick={() => setOpenInvoice(!openInvoice)}
                      // component={RouterLink}
                      to={''}
                    >
                      {(details?.status === 1 && designs?.length > 0 && details?.send_client_app_req == 1) ? (
                        <Badge badgeContent={'1'} color={'warning'}>
                          <Typography fontSize={'1.09rem'} variant="overline">
                            <span style={{ textDecoration: 'underline' }}>
                              Details
                            </span>
                          </Typography>
                        </Badge>
                      ) : (
                        <Typography fontSize={'1.09rem'} variant="overline">
                          Details
                        </Typography>
                      )}
                    </Link>
                  ))}
                <Typography fontWeight={'0.09rem'} variant="overline">
                  <Label
                    sx={{ p: '12px' }}
                    variant={
                      theme.palette.mode === 'light' ? 'ghost' : 'filled'
                    }
                    color={
                      getStatusColorAndLabel(
                        details?.status,
                        details?.hold,
                      ).color
                    }
                  >
                    {
                      getStatusColorAndLabel(
                        details?.status,
                        details?.hold,
                      ).label
                    }
                  </Label>
                </Typography>

            {
              (userType=== 1 && details?.send_client_app_req == 0 && designs?.length > 0 && details.status ===1 && initialSelectedServices?.length > 0)?

              <Button variant='contained' onClick={handleSendClientApproveReq}>
                  Send For Client Approval
                </Button>
            :""}

{(
                  userType == 3 
                && designs?.length > 0) && details?.send_client_app_req == 1?
                  (!(details?.status == 2 || details?.status == 3) 
                  // &&
                  // (details?.design_order_job_status == 3)
                ) && (


 <Button
                          type="submit"
                          variant="contained"
                          onClick={() => setConfirmInvoice(true)}
                        >
                          Approve
                        </Button>
                ) : ''}
              </Stack>

              <Grid container spacing={3}>
                <Grid sx={{ mt: 3 }} item xs={12} md={12}>
                  <Stack spacing={3}>
                    <Stack spacing={5}>
                      <Tabs
                        value={currentTab}
                        scrollButtons="auto"
                        variant="scrollable"
                        allowScrollButtonsMobile
                        onChange={handleChangeTab}
                      >
                        {ACCOUNT_TABS.map((tab) => (
                          <Tab
                            disableRipple
                            key={tab.value}
                            label={capitalCase(tab.value)}
                            value={tab.value}
                          />
                        ))}
                      </Tabs>

                      {ACCOUNT_TABS.map((tab) => {
                        const isMatched = tab.value === currentTab
                        return (
                          isMatched && (
                            <Box key={tab.value}>{tab.component}</Box>
                          )
                        )
                      })}
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </>
          )}
          {/* invoice modal */}
          <Modal
            modalTitle={'Order Details'}
            open={openInvoice === true}
            handleClose={() => setOpenInvoice(false)}
          >
            <Stack
              direction={{ xs: 'column', sm: 'row', md: 'column' }}
              spacing={{ xs: 3, sm: 2 }}
            >
              <Stack
                direction="row"
                justifyContent={'flex-end'}
                alignItems="center"
                spacing={1}
              >
                {/* <ReactToPrint
                  trigger={() => (
                    <Button>
                      {' '}
                      <Print />
                      Print A3
                    </Button>
                  )}
                  content={() => componentRef.current}
                  copyStyles={false}
                  pageStyle={`
                    @media print {
                      body {
                        font-size: 12pt;
                      }
                      table {
                        width: 100%;
                        border-collapse: collapse;
                        margin-bottom: 10px;
                      }

                      th, td {
                        border: 1px solid #ddd;
                        padding: 5px;
                        text-align: left;
                      }
                      #right {
                        text-align: right;
                        margin-top: -200px; 
                      }
                      #total {
                        text-align: right;
                        display:flex;
                        justify-content: flex-end;
                      }
                      #subtotal {
                        text-align: left;
                        
                      }
                      #discount {
                        text-align: left;
                        word-wrap: break-word;
                        margin-right: 100px;
                    }
                    
                      #totalmain {
                        text-align: left;
                        
                      }
                      #subtotal1 {
                        text-align: left;
                        
                      }
                      #discount1 {
                        text-align: left;
                        word-wrap: break-word;
                        margin-right: 30px;
                    }
                    
                      #totalmain1 {
                        text-align: left;
                        
                      }
                      #total1 {
                        text-align: right;
                        display:flex;
                        justify-content: flex-end;
                        margin-top: -220px; 
                        
                      }
                      #logoImage {
                        height: 100px;
                        width:100px;
                      }
                      #order{
                        margin-bottom:-20px
                      }
                      #title{
                        margin-bottom:-20px
                      }
                      #orderID {
                        margin-bottom:-20px
                      }
                      #to {
                        margin-bottom:-20px
                      }
                      
                    }
                  `}
                /> */}
                {(details?.status === 4 || details?.status === 3) && SEND_EMAIL && (
                  <Button onClick={handleSendEmail}>Send Email</Button>
                )}
                <div style={{ display: 'none' }}>
                  <PrintFunction
                    invoiceDate={invoiceDate}
                    openInvoice={openInvoice}
                    details={details}
                    invoice={invoice}
                    ref={componentRef}
                    data={data}
                  />
                </div>
              </Stack>
            </Stack>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
                  <Box
                    component="img"
                    alt="logo"
                    src={COMPANY_LOGO}
                    sx={{ height: 98 }}
                  />
                </Grid>
                <Typography variant="body2">{COMPANY_NAME}</Typography>
                <Typography variant="body2">www.{COMPANY_NAME}.com</Typography>
                <Typography variant="body2">
                  {data?.address}, {data?.city}, {data?.state}, {data?.zipcode}
                </Typography>
                <Typography variant="body2">Telephone: +15208362947</Typography>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
                <Typography variant="body2">
                  Order date: {invoiceDate}
                </Typography>
                <Label
                  variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                  color={
                    getStatusColorAndLabel(
                      details?.status,
                      details?.hold,
                    ).color
                  }
                >
                  {
                    getStatusColorAndLabel(
                      details?.status,
                      details?.hold,
                    ).label
                  }
                </Label>
                {/* </Stack> */}
                <Typography variant="body2">
                  Order Name:{details?.name}
                </Typography>
                <Typography variant="body2">Order ID:{details?.id}</Typography>
                <Typography
                  paragraph
                  variant="overline"
                  sx={{ color: 'text.disabled', marginTop: '20px' }}
                >
                  TO {invoice?.customer_name}
                </Typography>
                <Typography variant="body2">
                  Phone: {invoice?.customer_phone}
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="subtitle1" sx={{ textAlign: 'center', color: 'GrayText',p:3 }}>
              {/* {designs?.length > 0 ? "Designs" : "No designs uploaded"} */}
              {(user?.user_type === 3 ? details?.send_client_app_req : designs?.length >0) ?  "Designs" : "No designs uploaded"}
            </Typography>
            <Grid container spacing={2}>
              <Scrollbar>
              {/* {designs?.length > 0 ? ( */}
              {(user?.user_type === 3 ? details?.send_client_app_req : designs?.length >0) ? (
                // <Box sx={{border: '0.1px solid', borderColor: borderColor,padding:2, }}>
                //   <Scrollbar sx={{ width: '100%', height: 450 }}>
                    <ImageList  gap={mdd ? 8 : xld ? 12 : 8} cols={mdd ? 3 : xld ? 3 : xs ? 1  : 3}>
                      {designs?.map((item) =>{
                          const { file_type, name, url, extension } = item;
                          // const isPdf = file_type === 'application/pdf' || extension=='pdf';
                           const isImage = file_type?.startsWith('image/') || file_type === 'img' || file_type === 'image'
                          const isPdf = file_type === 'pdf' || file_type === 'application/pdf' || file_type === "other" || extension=='pdf'
                          return (
                            <ImageListItem  key={item.name}>
                                  {isImage ? (
                                    <img
                                      srcSet={`${item.name}?w=240&h=240&fit=crop&auto=format&dpr=2 2x`}
                                      src={`${item.name}?w=240&h=240&fit=crop&auto=format`}
                                      alt={item.title}
                                      loading="lazy"
                                      style={{ width: '240px', height: '240px' }}
                                    />
                                  ) : isPdf ? (
                                    <Box display="flex" alignItems="center">
                                    {/* PDF Icon Button */}
                                    <Tooltip title='View'>
                                      <IconButton onClick={() => window.open(name, '_blank')} aria-label="open pdf"
                                        sx={{ position:'absolute', top:60, right:'50%', zIndex: 200,color:theme.palette.error.main, backgroundColor:theme.palette.text.primary }} >
                                        <PictureAsPdf style={{ fontSize: 40 }} />
                                      </IconButton>
                                    </Tooltip>
                              
                                    {/* Iframe */}
                                    <iframe
                                      src={name}
                                      title={name}
                                      style={{ width: '240px', height: '240px' }}
                                      frameBorder="0"
                                    />
                                  </Box>
                                  ) : (
                                    <Typography 
                                      sx={{
                                        color:theme.palette.error.main, 
                                        alignItems:'center', 
                                        justifyContent:'center',
                                        width: '240px', height: '240px'
                                      }}>
                                      Unsupported file type</Typography>
                                  )}
                            </ImageListItem>
                          )
                        })}
                    </ImageList>
                //   </Scrollbar>
                // </Box>
              ) : ''}
              </Scrollbar>
            </Grid>
            <Grid container spacing={2}>
              <Scrollbar>
                {(
                  userType == 3 
                && designs?.length > 0) && details?.send_client_app_req == 1?
                  (!(details?.status == 2 || details?.status == 3) 
                  // &&
                  // (details?.design_order_job_status == 3)
                ) && (
                    <Box
                      sx={{
                        mt: 3,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 1,
                      }}
                    >
                      {confirmInvoice ? (
                        <Stack alignItems="center" justifyContent="center">
                          <CircularProgress
                            color="primary"
                            variant="indeterminate"
                          />
                        </Stack>
                      ) : (
                        <Button
                          type="submit"
                          variant="contained"
                          onClick={() => setConfirmInvoice(true)}
                        >
                          Approve
                        </Button>
                      )}
                      {invoiceReject ? (
                        <Stack alignItems="center" justifyContent="center">
                          <CircularProgress
                            color="primary"
                            variant="indeterminate"
                          />
                        </Stack>
                      ) : (
                        <>
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={() => setRejectInvoice(true)}
                            sx={{ color: theme.palette.error.main }}
                          >
                            Reject
                          </Button>
                        </>
                      )}
                    </Box>
                  ) : ''}
              </Scrollbar>
            </Grid>
          </Modal>
          <ConfirmationPopup
            open={openDelete}
            handleClick={handleDeleteOrder}
            handleClose={() => {
              setOpenDelete(!openDelete), setIsOpen(false)
            }}
            message={'You want to delete this order '}
          />

          <ConfirmationPopup
            open={openConvertToSalesOrder}
            handleClick={handleConvertToSalesOrder}
            handleClose={() => {
              setOpenConvertToSalesOrder(false)
            }}
            message={'This Design Order will be converted and moved to Sales Order Page '}
          />
          <ConfirmationPopup
            open={openConvertToEstimate}
            handleClick={handleConvertToEstimate}
            handleClose={() => {
              setOpenConvertToEstimate(false)
            }}
            message={'This Design Order will be converted and moved to Estimate Page '}
          />

          <ConfirmationPopup
          isapproveOrder={true}
            open={confirmInvoice}
            handleClick={() => {
              handleAcceptorRejectInvoice(1)
            }}
            handleClose={() => setConfirmInvoice(false)}
            // message={'You want to Approve this Order '}
            message={'Do you Approve this design ? '}
          />
          <ConfirmationPopup
            open={reOpen}
            handleClick={() => handleReopeningRequest()}
            handleClose={() => setReopen(false)}
            message={'You want to reopen this Order '}
          />
          {/* Preview Modal */}
          {openPreview && (
            <Modal
              width={900}
              open={openPreview}
              handleClose={() => {
                setOpenPreview(false)
              }}
              modalTitle={'Preview'}
            >
              {/* <ReactToPrint
                trigger={() => (
                  <Button variant="contained" color="primary">
                    Print A3
                  </Button>
                )}
                content={() => printableContentRef.current}
                pageStyle={`@media print {
                  .print-black {
                    color: black !important;
                    background:white !important
                  }
                  table {
                    width: 100%;
                    border-collapse: collapse;
                    margin-bottom: 10px;
                  }
                  * {
                    color: black !important;
                    background-color: white !important;
                  }
                  .MuiTableCell-root {
                    color: black !important;
                  }
                  .MuiInputBase-input {
                    color: black !important;
                  }
                  .no-scrollbar::-webkit-scrollbar {
                    display: none;
                  }
                  .no-scrollbar {
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                  }
                  .enable-for-print {
                    pointer-events: auto;
                    color: black !important;
                  }
                  .print-black .enable-for-print input[type='number'],
                  .print-black .enable-for-print input[type='text'] {
                    color: black !important;
                  }
                  .css-wr96yv-MuiInputBase-input-MuiOutlinedInput-input {
                  color: black !important;
                  opacity: 1;
                    -webkit-text-fill-color:black !important;
                  }
                  input[type=number]{
                    color:black !important}
                  }`}
              /> */}
              <div ref={printableContentRef} className="print-black">
                <Grid container sx={{ justifyContent: 'center' }} spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Stack
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                      spacing={2}
                    >
                      <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Typography variant="subtitle2">
                          <span style={{ color: theme.palette.primary.darker }}>
                            Name:{' '}
                          </span>
                          {previewData?.name}
                        </Typography>

                        <Typography variant="subtitle2">
                          <span style={{ color: theme.palette.primary.darker }}>
                            Use custom welcome message:{' '}
                          </span>
                          {previewData.welcome_message == null
                            ? 'No'
                            : previewData.welcome_message == false
                            ? 'No'
                            : 'Yes'}
                        </Typography>
                      </Stack>
                      <Stack>
                        <Typography
                          variant="subtitle2"
                          style={{ color: theme.palette.primary.darker }}
                        >
                          Description:
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          style={{
                            overflowWrap: 'break-word',
                            wordWrap: 'break-word',
                            wordBreak: 'break-word',
                          }}
                        >
                          {previewData?.description}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        display="flex"
                        justifyContent="space-between"
                      >
                      </Stack>

                      <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Typography variant="subtitle2">
                          <span style={{ color: theme.palette.primary.darker }}>
                            Client:{' '}
                          </span>
                          {previewData?.clients?.name}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Typography variant="subtitle2">
                          <span style={{ color: theme.palette.primary.darker }}>
                            Start Date:{' '}
                          </span>
                          {moment.unix(previewData.start).format('MM/DD/YYYY')}
                        </Typography>
                        <Typography variant="subtitle2">
                          <span style={{ color: theme.palette.primary.darker }}>
                          Due Date:{' '}
                          </span>
                          {moment.unix(previewData.end).format('MM/DD/YYYY')}
                        </Typography>
                      </Stack>
                      <Stack>
                        <Typography
                          variant="subtitle2"
                          style={{ color: theme.palette.primary.darker }}
                        >
                          Notes:
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          style={{
                            overflowWrap: 'break-word',
                            wordWrap: 'break-word',
                            wordBreak: 'break-word',
                          }}
                        >
                          <Scrollbar
                            sx={{
                              maxHeight: 200,
                              '& .simplebar-content': {
                                maxHeight: 200,
                                display: 'flex',
                                flexDirection: 'column',
                              },
                            }}
                          >
                            {previewData?.notes}
                          </Scrollbar>
                        </Typography>
                      </Stack>
                      <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Typography variant="subtitle2">
                          <Label
                            variant={
                              theme.palette.mode === 'light'
                                ? 'ghost'
                                : 'filled'
                            }
                            color={
                              getStatusColorAndLabel(
                                details?.status,
                                details?.hold,
                              ).color
                            }
                          >
                            {
                              getStatusColorAndLabel(
                                details?.status,
                                details?.hold,
                              ).label
                            }
                          </Label>
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </div>
            </Modal>
          )}
          {/* reject request invoice */}
          {invoiceReject == true && (
            <Dialog
              open={invoiceReject}
              onClose={() => {
                setRejectInvoice(false)
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              BackdropComponent={Backdrop}
              BackdropProps={{ invisible: true }}
            >
              <DialogTitle id="alert-dialog-title">
                {'Are you sure ?'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  You want to reject this Order
                </DialogContentText>
                <TextField
                  sx={{ width: 400 }}
                  label="Reason For Rejection"
                  type="text"
                  multiline
                  minRows={3}
                  maxRows={7}
                  onInput={(event) => setReason(event.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => handleAcceptorRejectInvoice(2)}
                  sx={{ color: theme.palette.background.paper }}
                >
                  OK
                </Button>

                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    setRejectInvoice(false), setReason('')
                  }}
                  sx={{ color: theme.palette.error.main }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {/* reject request invoice */}
        </Container>
      )}
    </Page>
  )
}

const PrintFunction = forwardRef(
  (
    {
      invoiceDate,
      details,
      invoice,
      data,
    },
    ref,
  ) => {
    const theme = useTheme()
    const { COMPANY_LOGO, COMPANY_NAME } = useCompanyProfile()
    return (
      <Stack ref={ref}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              mb: 5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Box
              component="img"
              alt="logo"
              src={COMPANY_LOGO}
              id="logoImage"
              sx={{ height: 98, alignSelf: 'flex-start' }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              mb: 5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            {/* <Typography variant="h1">Invoice from:</Typography> */}
            <Typography variant="h2">{COMPANY_NAME}</Typography>
            <Typography variant="h2">www.{COMPANY_NAME}.com</Typography>
            <Typography variant="h3">
              {data?.address}
              <br /> {data?.city}
              <br /> {data?.state}, {data?.zipcode}
            </Typography>
            <Typography variant="h3">Telephone: +15208362947</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid id="total1" item xs={12} sm={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <Box
                id="order"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  id="discount1"
                  variant="h3"
                  style={{ textAlign: 'left' }}
                >
                  Order date:
                </Typography>
                <Typography variant="h3">{invoiceDate}</Typography>
              </Box>
              <Box
                id="title"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  maxWidth: 250,
                }}
              >
                <Typography
                  id="discount1"
                  variant="h3"
                  style={{ textAlign: 'left' }}
                >
                  Title:
                </Typography>
                <Typography variant="h3">{details?.name}</Typography>
              </Box>
              <Box
                id="orderID"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  id="discount"
                  variant="h3"
                  style={{ textAlign: 'left' }}
                >
                  Order ID:
                </Typography>
                <Typography variant="h3">{details?.id}</Typography>
              </Box>
              <Box
                id="to"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography id="totalmain1" variant="h3">
                  To:
                </Typography>
                <Typography variant="h3" style={{ textAlign: 'right' }}>
                  {invoice?.customer_name}
                </Typography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography id="totalmain1" variant="h3">
                  Phone:
                </Typography>
                <Typography variant="h3" style={{ textAlign: 'right' }}>
                  {invoice?.customer_phone}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Scrollbar>
          </Scrollbar>
        </Grid>
      </Stack>
    )
  },
)
