import * as Yup from 'yup';
import axios from 'axios';
import { Icon } from '@iconify/react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { capitalCase, sentenceCase } from 'change-case';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Container, Grid, IconButton,
  Stack, Box, Button, TableRow,
  TableBody, Checkbox, Card,
  TableCell, Typography,
  TableContainer, Table,
  TablePagination,
  TextField,
  Switch,
  FormControlLabel,
  Autocomplete,
  Divider,
  Paper,
  Popover,
  CardHeader,
  TableHead,
  List,
  Menu,
  MenuItem,
  ListItemText,
  ListItem,
  Tabs,
  Tab,
  Tooltip,
  CircularProgress,
  Badge,
} from '@material-ui/core';
import CloseIcon from '@iconify/icons-eva/close-fill';
import { useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import plusFill from '@iconify/icons-eva/plus-fill';
// redux
import { LoadingButton, MobileDatePicker, MobileDateRangePicker } from '@material-ui/lab';
// routes
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Modal from '../../components/_dashboard/blog/ProjectModel';
import Label from 'src/components/Label';
import { useSnackbar } from 'notistack';
import { Delete, UploadFile, LocationOn, ExpandMore, ExpandLess, Remove, Add, NoteAdd, Visibility, VisibilityOff } from '@material-ui/icons';
import OfficialRequestSearch from 'src/components/_dashboard/official-request/OfficialRequestSearch';
import InfiniteScroll from 'react-infinite-scroll-component';
import OfficialCard from 'src/components/_dashboard/official-request/OfficialCard';
import Scrollbar from 'src/components/Scrollbar';
import moment from 'moment';
import { REST_API, REST_API_END_POINT, UNITS } from 'src/constants/Defaultvalues';
import { UploadAvatar, UploadMultiFile } from 'src/components/upload';
import { storage } from '../../firebase/firebase'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import ReactToPrint from 'react-to-print';
import { empty } from 'src/Helpers/Common';
import LoadingScreen from 'src/components/LoadingScreen';
import SearchNotFound from 'src/components/SearchNotFound';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


const getStatusColorAndLabel = (status) => {
  switch (status) {
    case 1:
      return { color: 'warning', label: 'Status: Draft' };
    case 2:
      return { color: 'info', label: 'Status: Open' };
    case 3:
      return { color: 'primary', label: 'Status: Accepted' }; // Evaluating
    case 4:
      return { color: 'success', label: 'Status: In Production' }; // Converted to Job
    case 5:
      return { color: 'warning', label: 'Status: Closed' };
    case 6:
      return { color: 'error', label: 'Status: Rejected' };
    default:
      return { color: 'default', label: 'Status: Open' };
  }
};

const markupTypes = [
  { id: 1, name: 'Wholesale Markup' },
  { id: 2, name: 'Retail Markup' }
]
const marketingAdjustmentType = [
  { id: 1, name: '%' },
  { id: 2, name: '$' },
  // { id: 3, name: '¢' }
];


//sorting
const SORT = [
  { id: 'new', label: 'Newest', color: '#54D62C' }, 
  { id: 'old', label: 'Oldest', color: '#ffff00' },
  { id: 'last_updated', label: 'Recently Updated', color: '#ffff00' },
  { id: 'custom_order', label: 'Custom Order', color: '#ffff00' },
];

const MAX_CHARACTERS = 35;

export default function OfficialRequestAddPreview({
    preview,
    markupType,
    hasMarkupPermission,
    DiscountTotal,
    totalJobcostSum,
    totalsum,
    grandTotal,
    noCharge,
    selectedMaterials,
    cartService,
    cart,
    additionalSelectedServices,
    selectedServices,
    selectedProducts,
    values,
    totalAmount
}) {
  const theme = useTheme()
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const printableContentRef = useRef();

  console.log('preview==',preview)

  const VALUE_TIME_Preview = [
    { id: 1, value: 'Sl No.' },
    { id: 2, value: 'Products' },
    // { id: 3, value: 'Note' },
    { id: 4, value: 'Retail price each'},
    { id: 14, value: 'SPMA' },
    { id: 5, value: 'Unit' },
    { id: 6, value: 'Quantity' },
    // { id: 7, value: 'Location' },
    { id: 8, value: 'Marketing Adjustment' },
    { id: 9, value: 'Subtotal' },
    { id: 10, value: '' }
  ];
  const VALUE_MATERIAL=[
    {id: 0, value: 'Material'},
    {id: 1, value: 'Unit Price'},
    {id: 2, value: 'Markup (%)'},
    {id: 3, value: 'Markup Cost (Unit)' },
    {id: 4, value: 'Quantity'},
    {id: 5, value: 'Product Formula Cost'},
    {id: 6, value: 'Total'},
    {id: 7, value: ''}
  ]
  const VALUEPreview = [
    { id: 0, value: 'Sl No.' },
    { id: 1, value: 'Products & Notes' },
    // { id: 2, value: 'Product formula cost' }, // (Material cost + Service cost with markup)
    { id: 3, value: markupType ? ((markupType == 2 && hasMarkupPermission) ? 'Retail price each' : null) : null },
    { id: 4, value: markupType ? ((markupType == 1 && hasMarkupPermission) ? 'Retail price each' : null) : null },
    { id: 14, value: hasMarkupPermission ? 'SPMA' : null },
    // { id: 5, value: hasMarkupPermission ? 'Markup (%)' : null },
    { id: 6, value: 'Unit  ' },
    { id: 7, value: 'Quantity' },
    { id: 8, value: 'Marketing Adjustment' },
    // { id: 8, value: 'Discount' },
    { id: 9, value: 'Subtotal' },
    { id: 10, value: '' }
  ]

  const VALUE_SERVICES = [
    { id: 0, value: 'Product Sl No.' },
    { id: 1, value: 'Service Name' },
    { id: 2, value: 'Markup (%)' },
    { id: 3, value: 'Cost (Hourly)' },
    { id: 4, value: 'Markup Cost (Hourly)' },
    { id: 5, value: 'Hours' },
    { id: 6, value: 'Location' },
    { id: 7, value: 'Product Formula Cost'},
    { id: 8, value: 'Additional Cost' },
    { id: 9, value: 'Total cost' },
    { id: 10, value: '' }
  ];
  const VALUE_RFP_Product = [
    { id: 0, value: ' Name' },
    { id: 1, value: 'Mark Up' },
    { id: 2, value: 'Cost ' },
    { id: 3, value: 'Selling Price' },
    { id: 4, value: 'Unit' },
    { id: 5, value: 'Quantity' },
    { id: 6, value: 'Product Formula Cost'},
    { id: 7, value: 'Total' },
    { id: 8, value: '' },
    { id: 9, value: '' }
  ];

  const calculateTotal = (price, quantity, discount = 0, location = 1, adjustment = 0,markAdjType=1) => {
    const parsedQuantity = parseFloat(quantity) || 1;
    const parsedDiscount = parseFloat(discount) || 0;
    const locationQuantity = parseFloat(location) || 1
    const newPrice = parseFloat(price) || 0
    // let subtotal = price * parsedQuantity * locationQuantity;

    let SPMA = newPrice + ((newPrice * (adjustment ? Number(adjustment) : 0)) / 100)
    let subtotal = parseFloat(SPMA)?.toFixed(2) * parsedQuantity * locationQuantity;

    if(markAdjType ==2 || markAdjType ==3){
      SPMA = newPrice + (adjustment ? Number(adjustment) : 0)
      subtotal = parseFloat(SPMA)?.toFixed(2) * parsedQuantity * locationQuantity;
    }

    if (discount) {
      subtotal = subtotal - discount;
    }
    // if (adjustment) {
    //   subtotal = subtotal + ((subtotal * adjustment) / 100);
    // }
    return subtotal;
  };

  return(
    <Page>
      <Container>
        <ReactToPrint
        trigger={() => <Button variant="contained" color="primary">Print A3</Button>}

        content={() => printableContentRef.current}
        pageStyle={`@media print {
        .print-black {
        color: black !important;
        background:white !important
        }
        table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 10px;
        }
        * {
        color: black !important;
        background-color: white !important;
        }
        .MuiTableCell-root {
        color: black !important;
        }
        .MuiInputBase-input {
        color: black !important;
        }
        .no-scrollbar::-webkit-scrollbar {
        display: none;
        }
        .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        }
        .enable-for-print {
        pointer-events: auto;
        color: black !important;
        }
        .print-black .enable-for-print input[type='number'],
        .print-black .enable-for-print input[type='text'] {
        color: black !important; /* Set the text color to black for input fields */
        }
        .css-wr96yv-MuiInputBase-input-MuiOutlinedInput-input {

        color: black !important;
        opacity: 1;
        -webkit-text-fill-color:black !important;
        }
        input[type=number]{
        color:black !important}
        }`}
        />
        <div ref={printableContentRef} className="print-black">
        <Grid container sx={{ justifyContent: 'center' }} spacing={3}>
        <Grid item xs={12} md={12}>
            <Stack
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
            spacing={2}
            >
            <Stack
                direction={{ xs: 'column', md: 'row' }}
                display="flex"
                justifyContent="space-between"
            >
                <Typography variant="subtitle2">
                <span style={{ color: theme.palette.primary.darker }}>Name: </span>
                {preview?.name}
                </Typography>
                <Typography variant="subtitle2">
                <span style={{ color: theme.palette.primary.darker }}>Use custom welcome message: </span>
                {preview.welcome_message == null ? 'No' : preview.welcome_message == false ? 'No' : 'Yes'}
                </Typography>
            </Stack>
            <Stack>
                <Typography variant="subtitle2" style={{ color: theme.palette.primary.darker }}>
                Description:
                </Typography>
                <Typography variant="subtitle2" style={{ overflowWrap: 'break-word', wordWrap: 'break-word', wordBreak: 'break-word' }}>
                {preview?.description}
                </Typography>
            </Stack>
            <Stack
                direction={{ xs: 'column', md: 'row' }}
                display="flex"
                justifyContent="space-between"
            >
                {/* <Typography variant="subtitle2">
                <span style={{ color:theme.palette.primary.darker,
                overflowWrap: 'break-word', wordWrap: 'break-word',
                wordBreak: 'break-word' }}>Skills or services required:</span> <br/>
                    <List>
                    {preview?.skills?.map((val, index) => (
                    <ListItem sx={{ listStyleType: 'disc', paddingLeft: '20px' }} key={index}>
                    <ListItemText primary={val.name} />
                    </ListItem>
                            ))}
                        </List>
            </Typography> */}
                {/* <Typography variant="subtitle2">
                <span style={{ color:theme.palette.primary.darker }}>Workflows: </span> <br/>                                           
                <List>
                    {preview?.workflows?.map((val, index) => (
                    <ListItem sx={{ listStyleType: 'dics', paddingLeft: '20px' }} key={index}>
                    <ListItemText primary={val.name} />
                    </ListItem>
                            ))}
                        </List>
            </Typography> */}
            </Stack>
            <Stack
                direction={{ xs: 'column', md: 'row' }}
                display="flex"
                justifyContent="space-between"
            >
                <Typography variant="subtitle2">
                <span style={{ color: theme.palette.primary?.darker }}>Job Class: </span>
                {preview?.project_type?.name}
                </Typography>
                {/* <Typography variant="subtitle2">
                <span style={{ color: theme.palette.primary.darker, }}>Markup Type: </span>
                {preview?.markup_type?.name}
                </Typography> */}
                {/* <Typography variant="subtitle2">
                <span style={{ color: theme.palette.primary?.darker, }}>Job Type: </span>
                {preview?.project_categories?.name}
                </Typography>
                {preview?.project_categories?.id===13 &&<Typography variant="subtitle2">
                    <span style={{ color: theme.palette.primary?.darker, }}>No Charge Type: </span>
                    {preview?.no_charge_type?.name}
                </Typography>}
                {preview?.project_categories?.id===14 &&<Typography variant="subtitle2">
                <span style={{ color: theme.palette.primary?.darker, }}>Map Service: </span>
                {preview?.map_service?.name}
                </Typography>} */}
            </Stack>
            <Stack
                direction={{ xs: 'column', md: 'row' }}
                display="flex"
                justifyContent="space-between"
            >
                <Typography variant="subtitle2">
                <span style={{ color: theme.palette.primary?.darker, }}>Job Type: </span>
                {preview?.project_categories?.name}
                </Typography>
                {preview?.project_categories?.id===13 &&<Typography variant="subtitle2">
                    <span style={{ color: theme.palette.primary?.darker, }}>No Charge Type: </span>
                    {preview?.no_charge_type?.name}
                </Typography>}
                {preview?.project_categories?.id===14 &&<Typography variant="subtitle2">
                    <span style={{ color: theme.palette.primary?.darker, }}>Map Service: </span>
                    {preview?.map_service?.name}
                </Typography>}
            </Stack>
            <Stack
                direction={{ xs: 'column', md: 'row' }}
                display="flex"
                justifyContent="space-between"
            >
                <Typography variant="subtitle2">
                <span style={{ color: theme.palette.primary.darker }}>Client: </span>
                {preview?.clients?.name}
                </Typography>
                <Typography variant="subtitle2">
                <span style={{ color: theme.palette.primary.darker }}>Project: </span>
                {preview?.community?.name}
                </Typography>
            </Stack>

            <Stack
                direction={{ xs: 'column', md: 'row' }}
                display="flex"
                justifyContent="space-between"
            >
                <Typography variant="subtitle2">
                <span style={{ color: theme.palette.primary.darker }}>Start Date: </span>
                {moment.unix(preview.start).format("MM/DD/YYYY")}
                </Typography>
                <Typography variant="subtitle2">
                <span style={{ color: theme.palette.primary.darker }}>Installation Date: </span>
                {moment.unix(preview.end).format("MM/DD/YYYY")}
                </Typography>
            </Stack>
            {(preview?.project_categories?.name == 'Service only' || preview?.project_categories?.name == 'Time & Material') ? null : <>
            <Typography>PRODUCTS</Typography>
            <Scrollbar>
                <TableContainer sx={{ minWidth: 500 }}>
                <Table>
                    <TableHead>
                    <TableRow>
                        {preview?.project_categories?.name == 'Time & Material' ?
                        VALUE_TIME_Preview?.map((val) => (
                        val.id === 14 ? (
                            <Tooltip key={val.id} title="Selling Price With Marketing Adjustment">
                            <TableCell>{val.value}</TableCell>
                            </Tooltip>) :
                        <TableCell>{val.value}</TableCell>
                        )) :
                        VALUEPreview?.map((val) => (
                        val.id === 14 ? (
                            <Tooltip key={val.id} title="Selling Price With Marketing Adjustment">
                            <TableCell>{val.value}</TableCell>
                            </Tooltip>) : 
                        <TableCell>{val.value}</TableCell>
                        ))}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {selectedProducts?.map((data, index) => {
                        const quantityFieldName = `quantity_${index}`;
                        const discountFieldName = `discount_${index}`;
                        const adjustmentFieldName = `adjustment_${index}`;
                        let unitName = UNITS?.filter((item) => item?.id == data?.unit)
                        unitName = unitName[0]?.name
                        let shortUnitName = ''
                        if (unitName == 'Square feet') {
                        shortUnitName = 'Sq ft'
                        } else if (unitName == 'Lineal feet') {
                        shortUnitName = 'Lin ft'
                        } else if (unitName == 'Square Inches') {
                        shortUnitName = 'Sq Inches'
                        } else if (unitName == 'Unit') {
                        shortUnitName = 'Unit'
                        }
                        const rowTotal = calculateTotal(markupType == 1 ? data.price : (markupType == 2 ? data.retail_price : 0), Number(data.quantity), values[discountFieldName], data.locationQuantity, data.adjustment);
                        // Accumulate the totalAmount
                        totalAmount += rowTotal;
                        // values['sub_total'] = totalAmount?.toFixed(2)
                        const invoiceAmount = parseFloat(data?.expected_material_cost?.toFixed(2)) + parseFloat(data?.expected_total_service_cost?.toFixed(2))
                        // const markup = ((data.retail_price - data.price) / data.price) * 100;
                        // const markup = (markupType == 1 ? (data.expected_cost / data.price - ) : markupType == 2 ? (data.expected_cost / data.retail_price) : null )


                        let markup = markupType == 1 ? (((data.price - parseFloat(data.expected_cost?.toFixed(2))) / parseFloat(data.expected_cost?.toFixed(2))) * 100)
                        : (markupType == 2 ? (((data.retail_price - parseFloat(data.expected_cost?.toFixed(2))) / parseFloat(data.expected_cost?.toFixed(2))) * 100) : 0);

                        markup = markup ? Number(markup) : 0

                        // let markup = markupType ? (invoiceAmount / rowTotal) : null
                        // markup = markup ? Number(markup) : 0
                        let originalMarkup = markupType == 1 ? data.wholesaleMarkup : markupType == 2 ? data.retailMarkup : 0
                        return (
                        <TableRow key={index}>
                            <TableCell>#{data?.indexId}</TableCell>
                            {/* <TableCell>{data.name}</TableCell> */}
                            <TableCell width={'25%'} sx={{ flexWrap: 'wrap', overflow: 'hidden' }}>
                            <Typography variant='body2' sx={{ fontWeight: 'bold', mb: 1, wordBreak: 'break-word', overflowWrap: 'break-word' }}>{data?.name}</Typography>
                            {data?.product_notes ? 
                            <Stack direction={'column'}>
                                <Typography variant='body2' sx={{textDecoration: 'underline'}}>Note : </Typography> 
                                <Typography variant='body2' sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>{data?.product_notes}</Typography> 
                            </Stack>
                            : ''}
                            </TableCell>
                            {/* <TableCell>{invoiceAmount}</TableCell> */}
                            {preview?.project_categories?.name == 'Time & Material' ? 
                            <TableCell>{data?.retail_price || data?.price || null}</TableCell>
                            : <>
                            <TableCell>{(hasMarkupPermission && markupType == 2) ? data.retail_price : null}</TableCell>
                            <TableCell>{(hasMarkupPermission && markupType == 1) ? data.price : null}</TableCell>
                            </>}
                            <TableCell>{hasMarkupPermission ? (data?.SPMA || null) : null}</TableCell>
                        {/* previos */}
                            {/* {preview?.project_categories?.name == 'Time & Material' ? null : <TableCell>{hasMarkupPermission ? originalMarkup?.toFixed(2) : null}</TableCell>} */}
                        
                            {/* <TableCell>{hasMarkupPermission ? (markup * data.quantity).toFixed(2) : null}</TableCell> */}
                            {/* <TableCell>{shortUnitName}</TableCell> */}
                            <TableCell>
                            {/* <TextField required
                            fullWidth
                            sx={{ width: 85 }}
                            type='number'
                            disabled
                            label={shortUnitName}
                            value={Number(data.quantity)}
                            defaultValue={1}
                            /> */}
                            {Number(data.quantity)}{shortUnitName}
                            </TableCell>
                            <TableCell>
                            {/* <TextField required
                            fullWidth
                            type='number'
                            disabled
                            label={'Qty'}
                            value={Number(data.locationQuantity)}
                            defaultValue={1}
                            /> */}
                            {Number(data.locationQuantity)}
                            </TableCell>
                            {/* <TableCell>{data.id}</TableCell> */}
                        
                            <TableCell>
                            {/* {Number(data?.discount)?.toFixed(2) || 0.00} */}
                            {data.markAdjType === 3 ? "¢" : data.markAdjType === 2 ? "$" : "%"} {data?.adjustment ? Number(data?.adjustment)?.toFixed(2) : 0}
                            </TableCell>
                            <TableCell>
                            {calculateTotal(markupType == 1 ? data.price : (markupType == 2 ? data.retail_price : 0), Number(data.quantity), values[discountFieldName], data.locationQuantity, data.adjustment,data.markAdjType)?.toFixed(2)}
                            </TableCell>
                        </TableRow>
                        );
                    })}

                    </TableBody>
                </Table>
                </TableContainer>
            </Scrollbar>
            </>}
            <Typography>SERVICES</Typography>
            <Scrollbar>
                <TableContainer sx={{ minWidth: 500 }}>
                <Table>
                    <TableHead>
                    <TableRow>
                        {VALUE_SERVICES?.map((val) => (
                        ((((preview?.project_categories?.name == 'Service only') || (preview?.project_categories?.name == 'Time & Material')) && val.id === 0) || (val.id === 6)) ? null : (
                        <TableCell>{val.value}</TableCell>
                        )
                        ))}
                    </TableRow>
                    </TableHead>
                    <TableBody >
                    {selectedServices?.map((data, index) => {
                        let originalMarkup = data.originalMarkup || 0
                        const newQty = (data.previousQty * (data.qtyData||1) * data.locationQuantity)?.toFixed(2);
                        const quantityDifference = Number(data.quantity)-newQty;
                        data['quantityDifference'] = quantityDifference
                        return (
                        <TableRow key={index}>
                            <TableCell>#{data.productIndex}</TableCell>
                            <TableCell>{data.name}</TableCell>
                            <TableCell>{originalMarkup?.toFixed(2)}</TableCell>
                            <TableCell>{data.cost}</TableCell>
                            <TableCell>{data.total_cost}</TableCell>
                            <TableCell>{parseFloat(data?.quantity)?.toFixed(2) || 1}</TableCell>
                            <TableCell>{(data.cost*quantityDifference?.toFixed(2))?.toFixed(2)}</TableCell>
                            <TableCell>{(data.unit_cost * quantityDifference?.toFixed(2))?.toFixed(2)}</TableCell>
                            <TableCell>{data.total}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        );
                    })}
                    {additionalSelectedServices?.map((data, index) => {
                        console.log('additionalSelectedServicesdata',data)
                        let originalMarkup = data.originalMarkup || 0
                        const quantityDifference = Number(data.quantity)
                        data['quantityDifference'] = quantityDifference
                        return (
                        <TableRow key={data.id}>
                            {(preview?.project_categories?.name == 'Service only' || preview?.project_categories?.name == 'Time & Material') ? null : <TableCell></TableCell>}
                            <TableCell>{data.name}</TableCell>
                            {/* <TableCell>{data.markup}</TableCell>
                        <TableCell>N/A</TableCell> */}

                            <TableCell>{originalMarkup?.toFixed(2)}</TableCell>
                            <TableCell>{data.cost}</TableCell>
                            <TableCell>{data.total_cost}</TableCell>
                            <TableCell>{parseFloat(data?.quantity)?.toFixed(2) || 1}</TableCell>
                            <TableCell>{data.cost*quantityDifference?.toFixed(2)}</TableCell>
                            <TableCell>{data.total}</TableCell>
                            <TableCell>{data.total}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        );
                    })}
                    </TableBody>
                </Table>
                </TableContainer>
            </Scrollbar>
            {(cart?.length > 0 || cartService?.length > 0 ) ? <>
            <Typography>Proposals</Typography>
            <Scrollbar>
                <TableContainer sx={{ minWidth: 500 }}>
                <Table>
                    <TableHead>
                    <TableRow>

                        {VALUE_RFP_Product?.map((val) => (

                        <TableCell key={val.id}>{val.value}</TableCell>

                        ))}
                    </TableRow>
                    </TableHead>
                    <TableBody >
                    {cart?.map((data, index) => {
                        let unitName = UNITS?.filter((item) => item?.id == data?.unit)
                        unitName = unitName[0]?.name
                        let shortUnitName = ''
                        if (unitName == 'Square feet') {
                        shortUnitName = 'Sq ft'
                        } else if (unitName == 'Lineal feet') {
                        shortUnitName = 'Lin ft'
                        } else if (unitName == 'Square Inches') {
                        shortUnitName = 'Sq Inches'
                        } else if (unitName == 'Unit') {
                        shortUnitName = 'Unit'
                        }
                        let markup = markupType ? markupType === 1 ? data.WholesaleMarkup : data.RetailMarkup : 0
                        return (
                        <TableRow key={index}>
                            <TableCell>{data.name || data.product_name}</TableCell>
                            {/* <TableCell>{invoiceAmount}</TableCell> */}
                            <TableCell>
                            {markup?.toFixed(2) || 0.00}
                            </TableCell>
                            <TableCell>
                            {data.product_price?.toFixed(2) || 0}
                            </TableCell>
                            <TableCell>
                            {(data.product_price + (data.product_price * markup / 100)).toFixed(2) || 0.00}
                            </TableCell>
                            <TableCell>
                            {data.quantity} {shortUnitName}
                            </TableCell>
                            <TableCell>
                            {data.location_quantity}
                            </TableCell>
                            <TableCell>
                                {(data.product_price * data.quantity * data.location_quantity)?.toFixed(2)||0}
                            </TableCell>
                            <TableCell>
                            {(data.location_quantity*data.quantity * ((data.product_price + (data.product_price * markup / 100)).toFixed(2) || 0)).toFixed(2)}
                            </TableCell>
                        </TableRow>
                        );
                    })}

                    </TableBody>
                    {cartService?.length > 0 ? <h6>Services</h6> : null}
                    <TableBody>
                    {cartService?.map((data, index) => {


                        return (
                        <>
                            <TableRow key={data.id}>
                            <TableCell>{data.name || data.product_name}</TableCell>
                            {/* <TableCell>{invoiceAmount}</TableCell> */}
                            <TableCell>
                                {data.originalMarkup?.toFixed(2)}
                            </TableCell>
                            <TableCell>
                                {data.sellingPrice?.toFixed(2)}
                            </TableCell>
                            <TableCell>
                                {((data.sellingPrice + (data.sellingPrice * data.originalMarkup / 100)))?.toFixed(2)}
                            </TableCell>
                            <TableCell>
                                {data.qty} Hours
                            </TableCell>
                            <TableCell >

                            </TableCell>
                            <TableCell>
                                {(data.qty*data.sellingPrice).toFixed(2)||0.00}
                                </TableCell>
                            <TableCell>
                                {(data.qty * (data.sellingPrice + (data.sellingPrice * data.originalMarkup / 100)))?.toFixed(2)}
                            </TableCell>
                            
                            <TableCell >

                            </TableCell>
                            <TableCell >

                            </TableCell>
                            </TableRow>

                        </>
                        );
                    })}
                    </TableBody>
                </Table>
                </TableContainer>
            </Scrollbar>
            </> : null}
            {selectedMaterials?.length > 0 ? <>
            <Typography>MATERIALS</Typography>
            <Scrollbar>
                <TableContainer sx={{ minWidth: 500 }}>
                <Table>
                    <TableHead>
                    <TableRow>

                        {VALUE_MATERIAL?.map((val) => (

                        <TableCell key={val.id}>{val.value}</TableCell>

                        ))}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                            {selectedMaterials?.map((data, index) => {
                            console.log("################Materials",data);
                            let unitName = UNITS?.filter((item) => item?.id == data?.unit)
                            unitName = unitName[0]?.name
                            let shortUnitName = ''
                            if (unitName == 'Square feet') {
                                shortUnitName = 'Sq ft'
                            } else if (unitName == 'Lineal feet') {
                                shortUnitName = 'Lin ft'
                            } else if (unitName == 'Square Inches') {
                                shortUnitName = 'Sq Inches'
                            } else if (unitName == 'Unit') {
                                shortUnitName = 'Unit'
                            }
                            
                            return (
                                <>
                                <TableRow key={index}>
                                
                                <TableCell>{data.name}{data.inhouse==1 ? ' (Inhouse)' : ' (Client owned)'}</TableCell>
                                <TableCell>{data.price}</TableCell>
                                <TableCell>{data.markup}</TableCell>
                                <TableCell>{data.markUpCost}</TableCell>
                                <TableCell>
                                    {data.quantity||1} {shortUnitName}
                                    </TableCell>
                                <TableCell>{(data.price*(data.quantity||1))?.toFixed(2)}</TableCell>
                                <TableCell>{( (data.markUpCost || 0) * (data.quantity||1) )?.toFixed(2)}</TableCell>
                                    <TableCell >
                                    
                                    
                                    </TableCell>
                                </TableRow>
                                
                                </>
                            );
                            })}
                        </TableBody>
                    
                </Table>
                </TableContainer>
            </Scrollbar>
            </> : null}
            <Stack>
                <Typography variant="subtitle2" style={{ color: theme.palette.primary.darker }}>
                Notes:
                </Typography>
                <Typography variant="subtitle2" style={{ overflowWrap: 'break-word', wordWrap: 'break-word', wordBreak: 'break-word', textAlign: "justify" }}>
                <Scrollbar
                    sx={{
                    maxHeight: 200,
                    '& .simplebar-content': {
                        maxHeight: 200,
                        display: 'flex',
                        flexDirection: 'column',
                    },
                    }}
                >{preview?.notes}</Scrollbar>

                </Typography>
            </Stack>
            <Stack
                direction={{ xs: 'column', md: 'row' }}
                display="flex"
                justifyContent="space-between"
            >
                {
                        noCharge?
                <Typography variant="subtitle2">
                <span style={{ color: 'red' }}>Grand Total: </span>
                <span style={{ color: 'red' }}> {grandTotal ?  parseFloat(grandTotal)?.toFixed(2) : 0.00}</span>
                </Typography>
                :
                <Typography variant="subtitle2">
                <span style={{ color: theme.palette.primary.darker }}>Grand Total: </span>
                {grandTotal ?  parseFloat(grandTotal)?.toFixed(2) : 0.00}
                </Typography>
        }
                <Typography variant="subtitle2">
                <span style={{ color: theme.palette.primary.darker }}>Total Product Formula Cost: </span>
                {totalJobcostSum < 0 ? 0 : totalJobcostSum?.toFixed(2)}
                </Typography>
                <Tooltip title="Sub Total - Total Product Formula Cost">
                <Typography variant="subtitle2" sx={{ cursor: 'pointer' }}>
                    <span style={{ color: theme.palette.primary.darker }}>Total Markup Amount: </span>
                    {(totalsum - totalJobcostSum)?.toFixed(2)}
                </Typography>
                {/* <Typography variant="subtitle2" sx={{ cursor: 'pointer' }}>
                    <span style={{ color: theme.palette.primary.darker }}>Total Markup Amount: </span>
                    {markupType == 1 ? ((totalWholesalePrice - DiscountTotal) - totalProductFormula)?.toFixed(2) : ((totalRetailPrice - DiscountTotal) - totalProductFormula)?.toFixed(2)}
                </Typography> */}
                </Tooltip>

                <Tooltip title="(Total Markup Amount / Total Product Formula Cost) * 100">
                <Typography variant="subtitle2" sx={{ cursor: 'pointer' }}>
                    <span style={{ color: theme.palette.primary.darker }}>Total Markup Percentage: </span>
                    {(totalJobcostSum !== 0 && totalsum !== 0)
                    ? (((totalsum - totalJobcostSum) / totalJobcostSum) * 100).toFixed(2)
                    : '0.00'}
                </Typography>
                {/* <Typography variant="subtitle2" sx={{ cursor: 'pointer' }}>
                    <span style={{ color: theme.palette.primary.darker }}>Total Markup Percentage: </span>
                    {markupType === 1
                    ? (totalProductFormula !== 0
                        ? ((((totalWholesalePrice - DiscountTotal) - totalProductFormula) / totalProductFormula) * 100).toFixed(2)
                        : '0.00')
                    : markupType === 2
                        ? (totalProductFormula !== 0
                        ? ((((totalRetailPrice - DiscountTotal) - totalProductFormula) / totalProductFormula) * 100).toFixed(2)
                        : '0.00')
                        : '0.00'}
                </Typography> */}
                </Tooltip>

                {/* <Typography variant="subtitle2">
                <span style={{ color: theme.palette.primary.darker }}>Total Service Markup: </span>
                {originaltotalServiceMarkup ? (originaltotalServiceMarkup / (selectedServices.length + additionalSelectedServices.length))?.toFixed(2) : 0}
            </Typography> */}
                <Typography variant="subtitle2">
                <span style={{ color: theme.palette.primary.darker }}>Total Discount: </span>
                {DiscountTotal ? DiscountTotal?.toFixed(2) : 0.00}
                </Typography>
            </Stack>
            <Stack
                direction={{ xs: 'column', md: 'row' }}
                display="flex"
                justifyContent="space-between"
            >
                <Typography variant="subtitle2">
                <Label
                    variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                    color={getStatusColorAndLabel(status).color} >
                    {getStatusColorAndLabel(status).label}
                </Label>
                </Typography>
            </Stack>

            </Stack>
        </Grid>
        </Grid>
        </div>
      </Container>
    </Page>
  )
}
