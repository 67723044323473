// material
import { experimentalStyled as styled } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import {
  Box,
  Card,
  Grid,
  Typography,
  Stack,
  Button,
  Divider,
  OutlinedInput,
  Tab,
  Tabs,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  useMediaQuery,
} from '@material-ui/core'
//
import { useNavigate } from 'react-router'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { useEffect, useRef, useState } from 'react'
import { capitalCase } from 'change-case'
import Modal from 'src/components/_dashboard/blog/ProjectModel'
import { LoadingButton } from '@material-ui/lab'
import { storage } from 'src/firebase/firebase'
import { getDownloadURL, uploadBytesResumable ,ref as imageref, deleteObject } from '@firebase/storage'
import { Upload, UploadFileSharp } from '@material-ui/icons'
import Scrollbar from 'src/components/Scrollbar'
import { UserMoreMenu } from 'src/components/_dashboard/user/list'
import { DialogAnimate } from 'src/components/animate'
import axios from 'axios'
import { REST_API_END_POINT } from 'src/constants/Defaultvalues'
import { useSnackbar } from 'notistack'
import ProjectFileAttach from './ProjectFileAttach'
import ProjectImageAttach from './ProjectImageAttach'
import ProjectVideo from './ProjectVideo'


const getFileType = (fileType) => {
  console.log(fileType,'------------')
  if (fileType.startsWith('image/')) {
    return 'image';
  } else if (fileType.startsWith('video/')) {
    return 'video';
  } else {
    return 'other';
  }
};
const validateFile = (file) => {
  const allowedFileTypes = ['pdf', 'doc', 'docx', 'csv', 'txt','image/*','video/*'];
  const maxFileSize = 2 * 1024 * 1024; // 2MB
  

  const getFileExtension = (fileName) => {
    const matches = fileName.match(/\.[0-9a-z]+$/i);
    return matches ? matches[0].toLowerCase() : null;
  };
  const fileExtension = getFileExtension(file.name);

if (!fileExtension || !allowedFileTypes.includes(fileExtension) ) {
  console.log(`File type not allowed: ${file.name}`);
  return false;
}


  if (file.size > maxFileSize) {
    console.log(`File size exceeds the limit: ${file.name}`);
    return false;
  }

  return true;
};
export default function ProjectAttachment({projectId,setFetchData,currentTab, designOrder, data}) {
  const theme = useTheme()
  const mdd = useMediaQuery(theme.breakpoints.down('md'))
  const xld = useMediaQuery(theme.breakpoints.up('xl'))
  const fileInputRef = useRef()
  const {enqueueSnackbar}=useSnackbar()
  const navigate = useNavigate()
  const [progress, setProgress] = useState(false)
  const [secTab, setSecTab] = useState('Images')
  const [openAttachment,setOpenAttachment] = useState(false)
  const [attachments,setAttachments] = useState({})
  const [designs,setDesigns] = useState({})
  const [refresh,setRefresh] = useState(false)
   const [images,setImages]= useState([])
   const [videos,setVideos]= useState([])
   const [otherAttachments,setOtherAttachments]= useState([])
  // const fetchAllAttachments=async()=>{
  //   try {
  //     let apiUrl = '';
  //     if(designOrder){
  //       apiUrl = `design-order-job/fetch-all-attachment`
  //     }else{
  //       apiUrl = `project-task/fetch-all-project-attachment`
  //     }
  //     const res = await axios.post(`${REST_API_END_POINT}${apiUrl}`,{
  //       projectId
  //     })
  //     if (res.data && res.data.attachments) {
  //       const images = [];
  //       const videos = [];
  //       const otherAttachments = [];
  
  //       res.data.attachments.forEach((data) => {
  //         const fileType = data.file_type?.toLowerCase(); // Normalize case
  //         const fileExtension = data.extension?.toLowerCase(); // Normalize case
      
  //         console.log(fileType, fileExtension);
          
  //         // Check if the file is an image
  //         const isImage = fileExtension != "other" && (fileType?.startsWith('image/') || fileType === 'img' || fileType === 'image' ||
  //         ['jpg', 'jpeg', 'png', 'gif', 'avif', 'bmp'].includes(fileExtension));

  //         // Check if the file is a video
  //         const isVideo = fileType?.startsWith('video/') || fileType === 'video' ||
  //           ['mp4', 'avi', 'mkv', 'mov', 'wmv'].includes(fileExtension);

  //         // Check if the file is a document (PDF, Word, Excel)
  //         const isDocument = fileType === 'application/pdf' || fileExtension === 'pdf' || fileExtension === "other" ||
  //           fileType?.includes('document') || ['doc', 'docx', 'xls', 'xlsx'].includes(fileExtension);

  //         if (isImage) {
  //           images.push(data);
  //         } else if (isVideo) {
  //           videos.push(data);
  //         } else if (isDocument) {
  //           otherAttachments.push(data);
  //         }
  //       });
  //       setImages(images);
  //       setVideos(videos);
  //       setOtherAttachments(otherAttachments);
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const fetchAllAttachments = async () => {
    try {
      let apiUrl = designOrder
        ? `design-order-job/fetch-all-attachment`
        : `project-task/fetch-all-project-attachment`;
  
      const res = await axios.post(`${REST_API_END_POINT}${apiUrl}`, {
        projectId,
      });
  
      if (res.data && res.data.attachments) {
        const images = [];
        const videos = [];
        const otherAttachments = [];
  
        res.data.attachments.forEach((data) => {
          const fileType = data.file_type?.toLowerCase(); // Normalize case
          const fileExtension = data.extension?.toLowerCase(); // Normalize case
  
          console.log("File Type:", fileType, "Extension:", fileExtension);
  
          // Define valid image extensions
          const imageExtensions = [
            "jpg",
            "jpeg",
            "png",
            "gif",
            "avif",
            "bmp",
            "tiff",
            "webp",
            "heif",
            // "other"
          ];
          const videoExtensions = ["mp4", "avi", "mkv", "mov", "wmv"];
          const documentExtensions = ["pdf", "doc", "docx", "xls", "xlsx", "csv", "txt"];
  
          // **Check if the file is an image**
          const isImage =
            fileType?.startsWith("image/") ||
            fileType === "img" ||
            fileType === "image" ||
            imageExtensions.includes(fileExtension);
  
          // **Check if the file is a video**
          const isVideo =
            fileType?.startsWith("video/") ||
            fileType === "video" ||
            videoExtensions.includes(fileExtension);
  
          // **Check if the file is a document**
          const isDocument =
            fileType === "application/pdf" ||
            documentExtensions.includes(fileExtension) ||
            fileType?.includes("document");
  
          if (isImage) {
            images.push(data);
          } else if (isVideo) {
            videos.push(data);
          } else if (isDocument) {
            otherAttachments.push(data);
          } else {
            console.warn(`Unrecognized file type: ${data.file_name} (Type: ${fileType}, Extension: ${fileExtension})`);
            otherAttachments.push(data);
          }
        });
  
        setImages(images);
        setVideos(videos);
        setOtherAttachments(otherAttachments);
      }
    } catch (error) {
      console.log("Error fetching attachments:", error);
    }
  };
  
  useEffect(()=>{
    fetchAllAttachments()
  },[projectId,refresh,openAttachment])
  
  const TABS = [
    {
      value: 'Images',
      component: <ProjectImageAttach setFetchData={setFetchData} refresh={refresh} setRefresh={setRefresh} images={images} projectId={projectId} designOrder={designOrder} />,
    },
    ...(designOrder
      ? [] // If designOrder is true, do not add the Videos tab
      : [
          {
            value: 'Videos',
            component: <ProjectVideo setFetchData={setFetchData} refresh={refresh} setRefresh={setRefresh} videos={videos} projectId={projectId} />,
          },
        ]),
    {
      value: 'Files',
      component: <ProjectFileAttach setFetchData={setFetchData} refresh={refresh} setRefresh={setRefresh} data={otherAttachments} videos={videos} projectId={projectId} designOrder={designOrder} />,
    },
  ];
  
  const handleTab = (event, newValue) => {
    setSecTab(newValue)
  }
  const handleFileChange = async (event) => {
    const files = event.target.files;
  
    if (files.length === 0) {
      return;
    }
  
    const uploadPromises = Array.from(files).map(async (file) => {
      // if (!validateFile(file)) {
      //   console.log(`Invalid file: ${file.name}`);
      //   return null;
      // }

      // const isImage = file?.type?.startsWith('image/');
      // const isVideo = file?.type?.startsWith('video/');
      // const isPdf = file?.type === 'application/pdf';
      const isImage = file?.type?.startsWith('image/') || file?.type === 'img' || file?.type === 'image';
      const isVideo = file?.type?.startsWith('video/');
      const isPdf = file?.type === 'application/pdf' || file?.type === "other" || file?.type === "pdf" ;
      if (!isImage && !isPdf && !isVideo){
        enqueueSnackbar('Please select a valid file. Only Images, Videos and PDF are allowed', { variant: 'error' })
        return;
      }
      const filename = new Date().getTime() + file.name;
      const storageRef = imageref(storage, filename);
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setProgress(true)
          },
          (error) => {
            console.error(error.message);
            setProgress(false)
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref)
              .then((downloadURL) => {
                console.log(downloadURL, '-------------downloadURL');
                resolve({ downloadURL, fileType: getFileType(file.type) });
              })
              .catch((error) => {
                console.error(error.message);
                reject(error);
              });
          }
        );
      });
    });
  
    try {
      const validFileUploads = await Promise.all(uploadPromises);
    const newAttachments = validFileUploads.reduce((acc, { downloadURL, fileType }) => {
      console.log(fileType,'-------------',downloadURL)
      acc[fileType] = acc[fileType] || [];
      acc[fileType].push(downloadURL);
      return acc;
    }, {});
    console.log(newAttachments,'-----------newAttachments')
    // setAttachments((prevAttachments) => ({ ...prevAttachments, ...newAttachments }));
    setAttachments((prevAttachments) => ({
      ...prevAttachments,
      image: [...(prevAttachments.image || []), ...(newAttachments.image || [])],
      video: [...(prevAttachments.video || []), ...(newAttachments.video || [])],
      other: [...(prevAttachments.other || []), ...(newAttachments.other || [])],
    }));
      setProgress(false)
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };
  
  const handleDesignFileChange = async (event) => {
    const files = event.target.files;
  
    if (files.length === 0) {
      return;
    }
  
    const uploadPromises = Array.from(files).map(async (file) => {
      // if (!validateFile(file)) {
      //   console.log(`Invalid file: ${file.name}`);
      //   return null;
      // }

      // const isImage = file?.type?.startsWith('image/');
      // const isPdf = file?.type === 'application/pdf';
      const isImage = file?.type?.startsWith('image/') || file?.type === 'img' || file?.type === 'image';
      const isPdf = file?.type === 'application/pdf' || file?.type === "other" || file?.type === "pdf" ;
      if (!isImage && !isPdf){
        enqueueSnackbar('Please select a valid file. Only Images and PDF are allowed', { variant: 'error' })
        return;
      }
      const extension = file.name.split('.').pop().toLowerCase();
      const fileType = getFileType(file.type);
      const filename = new Date().getTime() + '-' + file.name;
      const storageRef = imageref(storage, filename);
      const uploadTask = uploadBytesResumable(storageRef, file);
      console.log('fileType--extension--',extension);
      console.log('fileType--fileType--',fileType);
      console.log('fileType--filename--',filename);
      console.log('fileType--file.size--',file.size);
  
      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setProgress(true)
          },
          (error) => {
            console.error(error.message);
            setProgress(false)
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref)
              .then((downloadURL) => {
                console.log(downloadURL, 'downloadURL-------------downloadURL');
                resolve({ 
                  fileType,
                  downloadURL, 
                  extension,
                  size: file.size,
                 });
              })
              .catch((error) => {
                console.error(error.message);
                reject(error);
              });
          }
        );
      });
    });
  
    try {
      const validFileUploads = await Promise.all(uploadPromises);
      const newAttachments = validFileUploads.reduce((acc, { downloadURL, fileType }) => {
        console.log(fileType,'-------------',downloadURL)
        acc[fileType] = acc[fileType] || [];
        acc[fileType].push(downloadURL);
        return acc;
      }, {});
    const newDesigns = validFileUploads.reduce((acc, file) => {
      const { downloadURL, fileType, extension, size } = file;
      console.log(fileType,'-----downloadURL-------------downloadURL--------',downloadURL)
      acc[fileType] = acc[fileType] || [];
      acc[fileType].push({ downloadURL, fileType, extension, size });
      return acc;
    }, {});
    console.log(newAttachments,'-----------newAttachments')
    // setAttachments((prevAttachments) => ({ ...prevAttachments, ...newAttachments }));
    setAttachments((prevAttachments) => ({
      ...prevAttachments,
      image: [...(prevAttachments.image || []), ...(newAttachments.image || [])],
      other: [...(prevAttachments.other || []), ...(newAttachments.other || [])],
    }));
    setDesigns((prevAttachments) => ({
      ...prevAttachments,
      image: [...(prevAttachments.image || []), ...(newDesigns.image || [])],
      other: [...(prevAttachments.other || []), ...(newDesigns.other || [])],
    }));
      setProgress(false)
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };
console.log('setAttachments--setAttachments==',attachments)
console.log('setAttachments--setDesigns==',designs)
  const RemoveAttachFromSubmit = (type, value) => {
    const deleteRef = imageref(storage, value);
  
    deleteObject(deleteRef)
      .then(() => {
        // File deleted successfully
        setAttachments(prevState => ({
          ...prevState,
          [type]: (prevState[type] || []).filter(item => item !== value)
        }));
        console.log('File deleted successfully');
      })
      .catch(error => {
        console.error('Error deleting file:', error);
      });
  };


  // const RemoveAttachFromSubmitDesign = (type, value) => {
  //   const deleteRef = imageref(storage, value);
  
  //   deleteObject(deleteRef)
  //     .then(() => {
  //       // File deleted successfully
  //       setAttachments(prevState => ({
  //         ...prevState,
  //         [type]: (prevState[type] || []).filter(item => item !== value)
  //       }));
  //       setDesigns(prevState => ({
  //         ...prevState,
  //         [type]: (prevState[type] || []).filter(item => item !== value)
  //       }));
  //       console.log('File deleted successfully');
  //     })
  //     .catch(error => {
  //       console.error('Error deleting file:', error);
  //     });
  // };




  const RemoveAttachFromSubmitDesign = (type, value) => {
    const deleteRef = imageref(storage, value);
  
    deleteObject(deleteRef)
      .then(() => {
        setAttachments((prevState) => {
          const updatedAttachments = { ...prevState };
          updatedAttachments[type] = (updatedAttachments[type] || []).filter(
            (item) => item !== value
          );
          return { ...updatedAttachments };
        });
  
        setDesigns((prevState) => {
          const updatedDesigns = { ...prevState };
          updatedDesigns[type] = (updatedDesigns[type] || []).filter(
            (item) => item.downloadURL !== value
          );
          return { ...updatedDesigns };
        });
  
        console.log('File deleted successfully');
      })
      .catch((error) => {
        console.error('Error deleting file:', error);
      });
  };
  
  const handleSubmit=async()=>{
    try {
      if (Object.keys(attachments).length === 0) {
        enqueueSnackbar('Please provide attachments',{variant:'warning'});
        return;
      }
      let user=JSON.parse(window.localStorage.getItem('user'))
      let apiUrl = ''
      if(designOrder){
        apiUrl = 'design-order-job/add-attachment'
      }else{
        apiUrl = 'project-task/add-project-attachment'
      }
      const res = await axios.post(`${REST_API_END_POINT}${apiUrl}`,{
        attachments,designs,projectId,userId:user.user_id,
      })
      if(res.data.status ===1){
        enqueueSnackbar(res.data.message,{variant:'success'})
        // setRefresh(!refresh)
      }else{
        enqueueSnackbar(res.data.message,{variant:'error'})
      }
      if(designOrder){
        setFetchData(true)
      }
      setAttachments({})
      setDesigns({})
      setOpenAttachment(false)
    } catch (error) {
      console.log(error)
    }
  }
  let user=JSON.parse(window.localStorage.getItem('user'))
  const explodedPermissions = user?.exploded_permissions
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box width={'100%'} display={'flex'} justifyContent={'flex-end'} mb={2}>
          {designOrder ? (
            (data?.hold == 0 && (data?.design_order_job_status !=3 || data?.status != 3)) &&
              <Button onClick={()=>setOpenAttachment(!openAttachment)} variant="contained"
                disabled={user.user_type == 3 ? true : 
                // false
                !explodedPermissions?.some((permission) => ['Add Design to Design Order Job'].includes(permission))
                }>
                Add Design
              </Button>
          ) : (
            (currentTab != 'Installs' && currentTab != 'Pickup' && currentTab != 'Shipping' && currentTab!="Completed" && currentTab!="Hold" && data?.hold != 1 && data?.status !=3 ) &&<Button onClick={()=>setOpenAttachment(!openAttachment)} variant="contained" disabled={user.user_type == 3 ? true : false}>{designOrder ? 'Add Design' : 'ADD ATTACHMENT'}</Button>
          )}
        </Box>
        <Card
          sx={{
            transition: 'transform 0.2s',
            '&:hover': {
              transform: 'scale(1.02)',
            },
          }}
        
        >
          <Stack spacing={2}>
            <Stack direction={'row'} spacing={3} alignItems={'center'} p={2}>
              <Typography variant="h4">{designOrder ? 'Designs' :'Attachments'}</Typography>
            </Stack>
            <Box px={2}>
              <Divider />
              <Tabs
                value={secTab}
                scrollButtons="auto"
                variant="scrollable"
                allowScrollButtonsMobile
                onChange={handleTab}
              >
                {TABS.map((tab) => (
                  <Tab
                    disableRipple
                    key={tab.value}
                    label={
                      <Typography variant="overline">
                        {capitalCase(tab.value)}
                      </Typography>
                    }
                    icon={tab.icon}
                    value={tab.value}
                  />
                ))}
              </Tabs>
            </Box>
            <Stack sx={{ alignItems: 'center', mb: 2 }} spacing={2}>
          {TABS.map((tab) => {
            const isMatched = tab.value === secTab
            return (
              isMatched && (
                <Box sx={{ width: '80%' }} key={tab.value}>
                  {tab.component}
                </Box>
              )
            )
          })}
        </Stack>
            {/* <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              py={15}
            >
              No images found
            </Box> */}
          </Stack>
        </Card>
        {/* this  modal represent to add attachments */}
        <Modal open={openAttachment} modalTitle={designOrder ? 'Add Design':'Add Attachment'} handleClose={()=>setOpenAttachment(false)}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={{ xs: 2, sm: 3 }}
          mb={2}
        >
        <LoadingButton
            variant="outlined"
            size="small"
            sx={{ height: 40 }}
            onClick={() => fileInputRef.current.click()}
            loading={progress}
            startIcon={<UploadFileSharp/>}
          >
            Upload
          </LoadingButton>
          <input
            type="file"
            ref={fileInputRef}
             onChange={designOrder ? handleDesignFileChange : handleFileChange}
            style={{ display: 'none' }}
            accept="image/*,video/*,application/pdf"
            multiple
          />
          </Stack>
          {(attachments && Object.keys(attachments)?.length > 0) && <>
          <Box>
            <Scrollbar sx={{ width: '100%', height: 250 }}>
              <ImageList gap={8} cols={mdd ? 1 : xld ? 3 : 2}>
   
              {attachments?.video && attachments.video.map((videoUrl, index) => (              
            <ImageListItem key={index}>
              <ImageListItemBar                  
                      actionIcon={
                        <UserMoreMenu  
                        onDelete={ ()=>{
                          if(designOrder){
                            RemoveAttachFromSubmitDesign('video',videoUrl)
                          }else{
                            RemoveAttachFromSubmit('video',videoUrl)
                          }
                        }}         
                         noEdit                         
                        />
                      }
                    />
            <video width="300" height="300" controls>
              <source src={`${videoUrl}?w=300&h=300&fit=crop&auto=format`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            
            </ImageListItem>        
        ))}
        {attachments?.other && attachments?.other.map((url,index)=>(
          <Card key={index} sx={{ p: 3, maxWidth: 368, mx: 'auto' }}>
            <embed src={url} type="application/pdf" width="300" height="300px" />
            <UserMoreMenu 
                        onDelete={ ()=>{
                          if(designOrder){
                            RemoveAttachFromSubmitDesign('other',url)
                          }else{
                            RemoveAttachFromSubmit('other',url)
                          }
                        }}
                         noEdit                         
                        />
          </Card>
        ))}
        {attachments?.image && attachments.image.map((imageUrl, index) => (
          <ImageListItem key={index}>
            <img
              srcSet={`${imageUrl}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${imageUrl}?w=248&fit=crop&auto=format`}
              loading="lazy"
            />
             <ImageListItemBar                  
                      actionIcon={
                        <UserMoreMenu  
                        onDelete={ ()=>{
                          if(designOrder){
                            RemoveAttachFromSubmitDesign('image',imageUrl)
                          }else{
                            RemoveAttachFromSubmit('image',imageUrl)
                          }
                        }}         
                         noEdit                         
                        />
                      }
                    />
          </ImageListItem>
        ))}
              </ImageList>
            </Scrollbar>
          </Box>
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
              <LoadingButton   disabled={Object.values(attachments).every(arr => arr.length === 0)} onClick={()=>handleSubmit()} variant='contained' size='small'>
                Submit
              </LoadingButton>
              <Button variant='outlined' onClick={()=>{setAttachments({}),setDesigns({}),setOpenAttachment(false)}} size='small'>
                  Cancel
              </Button>
          </Box>
          </>}
        </Modal>
      </Grid>
    </Grid>
  )
}














// // material
// import { experimentalStyled as styled } from '@material-ui/core/styles'
// import { useTheme } from '@material-ui/core/styles'
// import {
//   Box,
//   Card,
//   Grid,
//   Typography,
//   Stack,
//   Button,
//   Divider,
//   OutlinedInput,
//   Tab,
//   Tabs,
//   ImageList,
//   ImageListItem,
//   ImageListItemBar,
//   useMediaQuery,
// } from '@material-ui/core'
// //
// import { useNavigate } from 'react-router'
// import { PATH_DASHBOARD } from 'src/routes/paths'
// import { useEffect, useRef, useState } from 'react'
// import { capitalCase } from 'change-case'
// import Modal from 'src/components/_dashboard/blog/ProjectModel'
// import { LoadingButton } from '@material-ui/lab'
// import { storage } from 'src/firebase/firebase'
// import { getDownloadURL, uploadBytesResumable ,ref as imageref, deleteObject } from '@firebase/storage'
// import { Upload, UploadFileSharp } from '@material-ui/icons'
// import Scrollbar from 'src/components/Scrollbar'
// import { UserMoreMenu } from 'src/components/_dashboard/user/list'
// import { DialogAnimate } from 'src/components/animate'
// import axios from 'axios'
// import { REST_API_END_POINT } from 'src/constants/Defaultvalues'
// import { useSnackbar } from 'notistack'
// import ProjectFileAttach from './ProjectFileAttach'
// import ProjectImageAttach from './ProjectImageAttach'
// import ProjectVideo from './ProjectVideo'


// const getFileType = (fileType) => {
//   console.log(fileType,'------------')
//   if (fileType.startsWith('image/')) {
//     return 'image';
//   } else if (fileType.startsWith('video/')) {
//     return 'video';
//   } else {
//     return 'other';
//   }
// };
// const validateFile = (file) => {
//   const allowedFileTypes = ['pdf', 'doc', 'docx', 'csv', 'txt','image/*','video/*'];
//   const maxFileSize = 2 * 1024 * 1024; // 2MB
  

//   const getFileExtension = (fileName) => {
//     const matches = fileName.match(/\.[0-9a-z]+$/i);
//     return matches ? matches[0].toLowerCase() : null;
//   };
//   const fileExtension = getFileExtension(file.name);

// if (!fileExtension || !allowedFileTypes.includes(fileExtension) ) {
//   console.log(`File type not allowed: ${file.name}`);
//   return false;
// }


//   if (file.size > maxFileSize) {
//     console.log(`File size exceeds the limit: ${file.name}`);
//     return false;
//   }

//   return true;
// };
// export default function ProjectAttachment({projectId,setFetchData,currentTab, designOrder, data}) {
//   const theme = useTheme()
//   const mdd = useMediaQuery(theme.breakpoints.down('md'))
//   const xld = useMediaQuery(theme.breakpoints.up('xl'))
//   const fileInputRef = useRef()
//   const {enqueueSnackbar}=useSnackbar()
//   const navigate = useNavigate()
//   const [progress, setProgress] = useState(false)
//   const [secTab, setSecTab] = useState('Images')
//   const [openAttachment,setOpenAttachment] = useState(false)
//   const [attachments,setAttachments] = useState({})
//   const [designs,setDesigns] = useState({})
//   const [refresh,setRefresh] = useState(false)
//    const [images,setImages]= useState([])
//    const [videos,setVideos]= useState([])
//    const [otherAttachments,setOtherAttachments]= useState([])
//   const fetchAllAttachments=async()=>{
//     try {
//       let apiUrl = '';
//       if(designOrder){
//         apiUrl = `design-order-job/fetch-all-attachment`
//       }else{
//         apiUrl = `project-task/fetch-all-project-attachment`
//       }
//       const res = await axios.post(`${REST_API_END_POINT}${apiUrl}`,{
//         projectId
//       })
//       if (res.data && res.data.attachments) {
//         const images = [];
//         const videos = [];
//         const otherAttachments = [];
  
//         res.data.attachments.forEach((data) => {
//           const fileType = data.file_type?.toLowerCase(); // Normalize case
//           const fileExtension = data.extension?.toLowerCase(); // Normalize case
      
//           console.log(fileType, fileExtension);
          
//           // Check if the file is an image
//           const isImage = fileType?.startsWith('image/') || fileType === 'img' || fileType === 'image' ||
//           ['jpg', 'jpeg', 'png', 'gif', 'avif', 'bmp'].includes(fileExtension);

//           // Check if the file is a video
//           const isVideo = fileType?.startsWith('video/') || fileType === 'video' ||
//             ['mp4', 'avi', 'mkv', 'mov', 'wmv'].includes(fileExtension);

//           // Check if the file is a document (PDF, Word, Excel)
//           const isDocument = fileType === 'application/pdf' || fileExtension === 'pdf' ||
//             fileType?.includes('document') || ['doc', 'docx', 'xls', 'xlsx'].includes(fileExtension);

//           if (isImage) {
//             images.push(data);
//           } else if (isVideo) {
//             videos.push(data);
//           } else if (isDocument) {
//             otherAttachments.push(data);
//           }
//         });
//         setImages(images);
//         setVideos(videos);
//         setOtherAttachments(otherAttachments);
//       }
//     } catch (error) {
//       console.log(error)
//     }
//   }
//   useEffect(()=>{
//     fetchAllAttachments()
//   },[projectId,refresh,openAttachment])
  
//   const TABS = [
//     {
//       value: 'Images',
//       component: <ProjectImageAttach setFetchData={setFetchData} refresh={refresh} setRefresh={setRefresh} images={images} projectId={projectId} designOrder={designOrder} />,
//     },
//     ...(designOrder
//       ? [] // If designOrder is true, do not add the Videos tab
//       : [
//           {
//             value: 'Videos',
//             component: <ProjectVideo setFetchData={setFetchData} refresh={refresh} setRefresh={setRefresh} videos={videos} projectId={projectId} />,
//           },
//         ]),
//     {
//       value: 'Files',
//       component: <ProjectFileAttach setFetchData={setFetchData} refresh={refresh} setRefresh={setRefresh} data={otherAttachments} videos={videos} projectId={projectId} designOrder={designOrder} />,
//     },
//   ];
  
//   const handleTab = (event, newValue) => {
//     setSecTab(newValue)
//   }
//   const handleFileChange = async (event) => {
//     const files = event.target.files;
  
//     if (files.length === 0) {
//       return;
//     }
  
//     const uploadPromises = Array.from(files).map(async (file) => {
//       // if (!validateFile(file)) {
//       //   console.log(`Invalid file: ${file.name}`);
//       //   return null;
//       // }

//       const isImage = file?.type?.startsWith('image/');
//       const isVideo = file?.type?.startsWith('video/');
//       const isPdf = file?.type === 'application/pdf';
//       if (!isImage && !isPdf && !isVideo){
//         enqueueSnackbar('Please select a valid file. Only Images, Videos and PDF are allowed', { variant: 'error' })
//         return;
//       }
//       const filename = new Date().getTime() + file.name;
//       const storageRef = imageref(storage, filename);
//       const uploadTask = uploadBytesResumable(storageRef, file);
  
//       return new Promise((resolve, reject) => {
//         uploadTask.on(
//           'state_changed',
//           (snapshot) => {
//             const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
//             setProgress(true)
//           },
//           (error) => {
//             console.error(error.message);
//             setProgress(false)
//             reject(error);
//           },
//           () => {
//             getDownloadURL(uploadTask.snapshot.ref)
//               .then((downloadURL) => {
//                 console.log(downloadURL, '-------------downloadURL');
//                 resolve({ downloadURL, fileType: getFileType(file.type) });
//               })
//               .catch((error) => {
//                 console.error(error.message);
//                 reject(error);
//               });
//           }
//         );
//       });
//     });
  
//     try {
//       const validFileUploads = await Promise.all(uploadPromises);
//     const newAttachments = validFileUploads.reduce((acc, { downloadURL, fileType }) => {
//       console.log(fileType,'-------------',downloadURL)
//       acc[fileType] = acc[fileType] || [];
//       acc[fileType].push(downloadURL);
//       return acc;
//     }, {});
//     console.log(newAttachments,'-----------newAttachments')
//     // setAttachments((prevAttachments) => ({ ...prevAttachments, ...newAttachments }));
//     setAttachments((prevAttachments) => ({
//       ...prevAttachments,
//       image: [...(prevAttachments.image || []), ...(newAttachments.image || [])],
//       video: [...(prevAttachments.video || []), ...(newAttachments.video || [])],
//       other: [...(prevAttachments.other || []), ...(newAttachments.other || [])],
//     }));
//       setProgress(false)
//     } catch (error) {
//       console.error('Error uploading files:', error);
//     }
//   };
  
//   const handleDesignFileChange = async (event) => {
//     const files = event.target.files;
  
//     if (files.length === 0) {
//       return;
//     }
  
//     const uploadPromises = Array.from(files).map(async (file) => {
//       // if (!validateFile(file)) {
//       //   console.log(`Invalid file: ${file.name}`);
//       //   return null;
//       // }

//       const isImage = file?.type?.startsWith('image/');
//       const isPdf = file?.type === 'application/pdf';
//       if (!isImage && !isPdf){
//         enqueueSnackbar('Please select a valid file. Only Images and PDF are allowed', { variant: 'error' })
//         return;
//       }
//       const extension = file.name.split('.').pop().toLowerCase();
//       const fileType = getFileType(file.type);
//       const filename = new Date().getTime() + '-' + file.name;
//       const storageRef = imageref(storage, filename);
//       const uploadTask = uploadBytesResumable(storageRef, file);
//       console.log('fileType--extension--',extension);
//       console.log('fileType--fileType--',fileType);
//       console.log('fileType--filename--',filename);
//       console.log('fileType--file.size--',file.size);
  
//       return new Promise((resolve, reject) => {
//         uploadTask.on(
//           'state_changed',
//           (snapshot) => {
//             const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
//             setProgress(true)
//           },
//           (error) => {
//             console.error(error.message);
//             setProgress(false)
//             reject(error);
//           },
//           () => {
//             getDownloadURL(uploadTask.snapshot.ref)
//               .then((downloadURL) => {
//                 console.log(downloadURL, 'downloadURL-------------downloadURL');
//                 resolve({ 
//                   fileType,
//                   downloadURL, 
//                   extension,
//                   size: file.size,
//                  });
//               })
//               .catch((error) => {
//                 console.error(error.message);
//                 reject(error);
//               });
//           }
//         );
//       });
//     });
  
//     try {
//       const validFileUploads = await Promise.all(uploadPromises);
//       const newAttachments = validFileUploads.reduce((acc, { downloadURL, fileType }) => {
//         console.log(fileType,'-------------',downloadURL)
//         acc[fileType] = acc[fileType] || [];
//         acc[fileType].push(downloadURL);
//         return acc;
//       }, {});
//     const newDesigns = validFileUploads.reduce((acc, file) => {
//       const { downloadURL, fileType, extension, size } = file;
//       console.log(fileType,'-----downloadURL-------------downloadURL--------',downloadURL)
//       acc[fileType] = acc[fileType] || [];
//       acc[fileType].push({ downloadURL, fileType, extension, size });
//       return acc;
//     }, {});
//     console.log(newAttachments,'-----------newAttachments')
//     // setAttachments((prevAttachments) => ({ ...prevAttachments, ...newAttachments }));
//     setAttachments((prevAttachments) => ({
//       ...prevAttachments,
//       image: [...(prevAttachments.image || []), ...(newAttachments.image || [])],
//       other: [...(prevAttachments.other || []), ...(newAttachments.other || [])],
//     }));
//     setDesigns((prevAttachments) => ({
//       ...prevAttachments,
//       image: [...(prevAttachments.image || []), ...(newDesigns.image || [])],
//       other: [...(prevAttachments.other || []), ...(newDesigns.other || [])],
//     }));
//       setProgress(false)
//     } catch (error) {
//       console.error('Error uploading files:', error);
//     }
//   };
// console.log('setAttachments--setAttachments==',attachments)
// console.log('setAttachments--setDesigns==',designs)
//   const RemoveAttachFromSubmit = (type, value) => {
//     const deleteRef = imageref(storage, value);
  
//     deleteObject(deleteRef)
//       .then(() => {
//         // File deleted successfully
//         setAttachments(prevState => ({
//           ...prevState,
//           [type]: (prevState[type] || []).filter(item => item !== value)
//         }));
//         console.log('File deleted successfully');
//       })
//       .catch(error => {
//         console.error('Error deleting file:', error);
//       });
//   };
//   const RemoveAttachFromSubmitDesign = (type, value) => {
//     const deleteRef = imageref(storage, value);
  
//     deleteObject(deleteRef)
//       .then(() => {
//         // File deleted successfully
//         setAttachments(prevState => ({
//           ...prevState,
//           [type]: (prevState[type] || []).filter(item => item !== value)
//         }));
//         setDesigns(prevState => ({
//           ...prevState,
//           [type]: (prevState[type] || []).filter(item => item !== value)
//         }));
//         console.log('File deleted successfully');
//       })
//       .catch(error => {
//         console.error('Error deleting file:', error);
//       });
//   };
  
//   const handleSubmit=async()=>{
//     try {
//       if (Object.keys(attachments).length === 0) {
//         enqueueSnackbar('Please provide attachments',{variant:'warning'});
//         return;
//       }
//       let user=JSON.parse(window.localStorage.getItem('user'))
//       let apiUrl = ''
//       if(designOrder){
//         apiUrl = 'design-order-job/add-attachment'
//       }else{
//         apiUrl = 'project-task/add-project-attachment'
//       }
//       const res = await axios.post(`${REST_API_END_POINT}${apiUrl}`,{
//         attachments,designs,projectId,userId:user.user_id,
//       })
//       if(res.data.status ===1){
//         enqueueSnackbar(res.data.message,{variant:'success'})
//         // setRefresh(!refresh)
//       }else{
//         enqueueSnackbar(res.data.message,{variant:'error'})
//       }
//       setFetchData(true)
//       setAttachments({})
//       setDesigns({})
//       setOpenAttachment(false)
//     } catch (error) {
//       console.log(error)
//     }
//   }
//   let user=JSON.parse(window.localStorage.getItem('user'))
//   return (
//     <Grid container spacing={3}>
//       <Grid item xs={12}>
//         <Box width={'100%'} display={'flex'} justifyContent={'flex-end'} mb={2}>
//           {designOrder ? (
//             (data?.hold == 0 && (data?.design_order_job_status !=3 || data?.status == 3)) &&
//               <Button onClick={()=>setOpenAttachment(!openAttachment)} variant="contained" disabled={user.user_type == 3 ? true : false}>
//                 Add Design
//               </Button>
//           ) : (
//             (currentTab != 'Installs' && currentTab != 'Pickup' && currentTab != 'Shipping' && currentTab!="Completed" && currentTab!="Hold" && data?.hold != 1 && data?.status !=3 ) &&<Button onClick={()=>setOpenAttachment(!openAttachment)} variant="contained" disabled={user.user_type == 3 ? true : false}>{designOrder ? 'Add Design' : 'ADD ATTACHMENT'}</Button>
//           )}
//         </Box>
//         <Card
//           sx={{
//             transition: 'transform 0.2s',
//             '&:hover': {
//               transform: 'scale(1.02)',
//             },
//           }}
        
//         >
//           <Stack spacing={2}>
//             <Stack direction={'row'} spacing={3} alignItems={'center'} p={2}>
//               <Typography variant="h4">{designOrder ? 'Designs' :'Attachments'}</Typography>
//             </Stack>
//             <Box px={2}>
//               <Divider />
//               <Tabs
//                 value={secTab}
//                 scrollButtons="auto"
//                 variant="scrollable"
//                 allowScrollButtonsMobile
//                 onChange={handleTab}
//               >
//                 {TABS.map((tab) => (
//                   <Tab
//                     disableRipple
//                     key={tab.value}
//                     label={
//                       <Typography variant="overline">
//                         {capitalCase(tab.value)}
//                       </Typography>
//                     }
//                     icon={tab.icon}
//                     value={tab.value}
//                   />
//                 ))}
//               </Tabs>
//             </Box>
//             <Stack sx={{ alignItems: 'center', mb: 2 }} spacing={2}>
//           {TABS.map((tab) => {
//             const isMatched = tab.value === secTab
//             return (
//               isMatched && (
//                 <Box sx={{ width: '80%' }} key={tab.value}>
//                   {tab.component}
//                 </Box>
//               )
//             )
//           })}
//         </Stack>
//             {/* <Box
//               display={'flex'}
//               justifyContent={'center'}
//               alignItems={'center'}
//               py={15}
//             >
//               No images found
//             </Box> */}
//           </Stack>
//         </Card>
//         {/* this  modal represent to add attachments */}
//         <Modal open={openAttachment} modalTitle={designOrder ? 'Add Design':'Add Attachment'} handleClose={()=>setOpenAttachment(false)}>
//         <Stack
//           direction={'row'}
//           alignItems={'center'}
//           justifyContent={'flex-start'}
//           spacing={{ xs: 2, sm: 3 }}
//           mb={2}
//         >
//         <LoadingButton
//             variant="outlined"
//             size="small"
//             sx={{ height: 40 }}
//             onClick={() => fileInputRef.current.click()}
//             loading={progress}
//             startIcon={<UploadFileSharp/>}
//           >
//             Upload
//           </LoadingButton>
//           <input
//             type="file"
//             ref={fileInputRef}
//              onChange={designOrder ? handleDesignFileChange : handleFileChange}
//             style={{ display: 'none' }}
//             accept="image/*,video/*,application/pdf"
//             multiple
//           />
//           </Stack>
//           {(attachments && Object.keys(attachments)?.length > 0) && <>
//           <Box>
//             <Scrollbar sx={{ width: '100%', height: 250 }}>
//               <ImageList gap={8} cols={mdd ? 1 : xld ? 3 : 2}>
   
//               {attachments?.video && attachments.video.map((videoUrl, index) => (              
//             <ImageListItem key={index}>
//               <ImageListItemBar                  
//                       actionIcon={
//                         <UserMoreMenu  
//                         onDelete={ ()=>{
//                           if(designOrder){
//                             RemoveAttachFromSubmitDesign('video',videoUrl)
//                           }else{
//                             RemoveAttachFromSubmit('video',videoUrl)
//                           }
//                         }}         
//                          noEdit                         
//                         />
//                       }
//                     />
//             <video width="300" height="300" controls>
//               <source src={`${videoUrl}?w=300&h=300&fit=crop&auto=format`} type="video/mp4" />
//               Your browser does not support the video tag.
//             </video>
            
//             </ImageListItem>        
//         ))}
//         {attachments?.other && attachments?.other.map((url,index)=>(
//           <Card key={index} sx={{ p: 3, maxWidth: 368, mx: 'auto' }}>
//             <embed src={url} type="application/pdf" width="300" height="300px" />
//             <UserMoreMenu 
//                         onDelete={ ()=>{
//                           if(designOrder){
//                             RemoveAttachFromSubmitDesign('other',url)
//                           }else{
//                             RemoveAttachFromSubmit('other',url)
//                           }
//                         }}
//                          noEdit                         
//                         />
//           </Card>
//         ))}
//         {attachments?.image && attachments.image.map((imageUrl, index) => (
//           <ImageListItem key={index}>
//             <img
//               srcSet={`${imageUrl}?w=248&fit=crop&auto=format&dpr=2 2x`}
//               src={`${imageUrl}?w=248&fit=crop&auto=format`}
//               loading="lazy"
//             />
//              <ImageListItemBar                  
//                       actionIcon={
//                         <UserMoreMenu  
//                         onDelete={ ()=>{
//                           if(designOrder){
//                             RemoveAttachFromSubmitDesign('image',imageUrl)
//                           }else{
//                             RemoveAttachFromSubmit('image',imageUrl)
//                           }
//                         }}         
//                          noEdit                         
//                         />
//                       }
//                     />
//           </ImageListItem>
//         ))}
//               </ImageList>
//             </Scrollbar>
//           </Box>
//           <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
//               <LoadingButton onClick={()=>handleSubmit()} variant='contained' size='small'>
//                 Submit
//               </LoadingButton>
//               <Button variant='outlined' onClick={()=>{setAttachments({}),setDesigns({}),setOpenAttachment(false)}} size='small'>
//                   Cancel
//               </Button>
//           </Box>
//           </>}
//         </Modal>
//       </Grid>
//     </Grid>
//   )
// }
