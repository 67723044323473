import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useState, useRef, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import roundSend from '@iconify/icons-ic/round-send';
import heartFill from '@iconify/icons-eva/heart-fill';
import shareFill from '@iconify/icons-eva/share-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import messageSquareFill from '@iconify/icons-eva/message-square-fill';
import roundAddPhotoAlternate from '@iconify/icons-ic/round-add-photo-alternate';
// material
import {
  Box,
  Link,
  Card,
  Stack,
  Paper,
  Avatar,
  TextField,
  Typography,
  Button,
  CardHeader,
  IconButton,
  AvatarGroup,
  InputAdornment,
  FormControlLabel
} from '@material-ui/core';
// utils
import { fDate } from '../../../../utils/formatTime';
import { fShortenNumber } from '../../../../utils/formatNumber';
// hooks
import useAuth from '../../../../hooks/useAuth';
//
import MyAvatar from '../../../MyAvatar';
import EmojiPicker from '../../../EmojiPicker';
import { MCheckbox } from '../../../@material-extend';
import Scrollbar from 'src/components/Scrollbar';

// ----------------------------------------------------------------------

ProfilePostCard.propTypes = {
  post: PropTypes.object
};


export default function ProfilePostCard({updates}) {
  const initialcount = 4
  const extraLoadCount = 4;
  const [displayedComments, setDisplayedComments] = useState(updates.slice(0, initialcount))
  const [showAll, setShowAll] = useState(false);

  const handleLoadMore = () => {
    const currentDisplayCount = displayedComments.length;
    const newDisplayCount = currentDisplayCount + extraLoadCount;
    setDisplayedComments(updates.slice(0, newDisplayCount));
    setShowAll(newDisplayCount >= updates.length);
  };

  const handleLoadLess = () => {
    const currentDisplayCount = displayedComments.length;
  
    if (currentDisplayCount > 3) {
      // Remove three comments at a time
      const newDisplayCount = currentDisplayCount - 3;
      setDisplayedComments(updates.slice(0, newDisplayCount));
      setShowAll(false);
    } else {
      // If only three comments are left, display the initial set of comments
      setDisplayedComments(updates.slice(0, initialcount));
      setShowAll(false);
    }
  };
  
  const ActivityComponent = ({ activity }) => {
    return (
      <Typography
        variant="body1"
        component="div"
        sx={{ '& strong': { fontWeight: 'bold' } }}
        dangerouslySetInnerHTML={{ __html: activity }}
      />
    );
  };

  return (
    <Card sx={{ height: '400px',}}>
      <Scrollbar>
      <Stack spacing={3} sx={{ p: 3 }}>
        <Stack spacing={1.5}>
          {displayedComments.map((comment) => (
            <Stack key={comment.id} direction="row" spacing={2}>
              <Avatar alt={comment.name} src={comment.profile_pic} />
              <Paper sx={{ p: 1.5, flexGrow: 1, bgcolor: 'background.neutral' }}>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  alignItems={{ sm: 'center' }}
                  justifyContent="space-between"
                  sx={{ mb: 0.5 }}
                >
                  <Typography variant="subtitle2">{comment.name}</Typography>
                  <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                    {comment.timestamp}
                  </Typography>
                </Stack>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {/* {comment.activity} */}
                  <ActivityComponent activity={comment.activity} />
                </Typography>
              </Paper>
            </Stack>
          ))}
        </Stack>
        {updates.length > initialcount  && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button variant="contained" onClick={showAll ? handleLoadLess : handleLoadMore}>
              {showAll ? 'Load Less' : 'Load More'}
            </Button>
          </Box>
        )}
      </Stack>
        </Scrollbar>
    </Card>
  );
}
