import React, { useEffect, useState } from 'react';
import { Container, Button, Grid, Box, TextField, Checkbox, Typography, Autocomplete, Stack, Skeleton, Card, CardContent, IconButton, Tooltip, InputAdornment } from '@material-ui/core';
import { LoadingButton, MobileDatePicker } from '@material-ui/lab';
import { useTheme } from '@material-ui/core/styles';
import { Link, Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import linkFill from '@iconify/icons-eva/link-fill';
import { useDispatch, useSelector } from '../../redux/store';
import { getUsers } from '../../redux/slices/user';
import { PATH_DASHBOARD } from '../../routes/paths';
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Popbar from 'src/layouts/dashboard/Popbar';
import AllClients from '../../pages/dashboard/AllClients';
// routes
import { PATH_PAGE } from 'src/routes/paths.js';
// components
import CopyClipboard from 'src/components/CopyClipboard.js';
import { Form, FormikProvider, useFormik } from 'formik';
import Modal from '../../components/_dashboard/blog/ProjectModel'; // replace 'path/to/Modal' with the correct path to your Modal component
import { ContactSupport, Visibility, VisibilityOff } from '@material-ui/icons';
import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
import { useSnackbar } from 'notistack';
import faker from 'faker';
import moment from 'moment';


const option = [
  { id: 1, value: 'Low' },
  { id: 2, value: 'Medium' },
  { id: 3, value: 'High' },
]

export default function AddTask({skillsandServices,
   coverImg,data,setData,projectId,
   setFetchData,projectMembers,title,
   taskId=null,add,setAdd,setIsOpen,
   view,setView,refresh,setRefresh,onRefresh,designOrder
  }) {

  //get user logged in data from local storage
  const user = localStorage.getItem('user')
  const userData = JSON.parse(user)
 let taskMembers =[]
 if(data?.task_members){
  let MembersArray =data?.task_members.split(',').map(Number);
   taskMembers = projectMembers.filter(member => MembersArray.includes(member.staff_id));
 }
   //------------------------------------------------------------------------------------------
  const { enqueueSnackbar } = useSnackbar();
  const [workFlows, setWorkFlows] = useState([]);
  const navigate = useNavigate();


  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };


  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
  });
  //
  const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1)
  }));
  //
  console.log('skillsandServices=1===',skillsandServices)
  console.log('skillsandServices=2===',data?.skill_ids)
  let initialSkills = []
  if(!designOrder && skillsandServices && skillsandServices?.length){
    initialSkills = skillsandServices?.filter(d=>data?.skill_ids?.includes(String(d.id)))
    // let initialSkills = skillsandServices||[]
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name || '',
      description: data?.description || '',
      start_date: (data ? moment.unix(data.start_date) : null),
      end_date: (data ? moment.unix(data.due_date) : null),
      reporter: data?.reporter_id ? projectMembers?.find(opt=>opt.id===data.reporter_id)?.id : null,
      priority: data?.priority ? option.find(opt=>opt.id===data.priority).id : null,
      assigned_to: taskMembers||[],
      skills: designOrder ? [] : (data?.skill_ids ? initialSkills:[]),
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors, setFieldError }) => {
     
      try {
        const  user  = JSON.parse(window.localStorage.getItem('user'))
        if(title !== 'Add SubTask' && title !== 'Edit SubTask'){
          values.cover_image = data?.cover_image ? data?.cover_image : coverImg;
        }
        if(title === 'Add SubTask'){
          values.taskId = Number(taskId)
          console.log("######Values",values.assigned_to);
          values.assignedTo = values?.assigned_to?.map(obj => obj?.staff_id)?.join(',') 
        }else if(data){
          values.id = data.ID
          values.assignedTo = values?.assigned_to?.map(obj => obj?.staff_id)?.join(',') 
        }else{
          values.projectId = projectId
          values.project_task_uid = faker.datatype.uuid()
        }
        values.userId = user.user_id
        if(!values.start_date){
          enqueueSnackbar(`Please select the Start date`, { variant: 'error' });
          setFieldError(start_date)
          return;
        }else if(!values.end_date){
          enqueueSnackbar(`Please select an End date`, { variant: 'error' });
          setFieldError(end_date)
          return;
        }else if(values.start_date > values.end_date){
          enqueueSnackbar(`Start date cannot be greater than end date`, { variant: 'error' });
          setFieldError(start_date)
          setFieldError(end_date)
          return;
        }
        // else if(!values.reporter){
        //   enqueueSnackbar(`Please select Reporter`, { variant: 'error' });
        //   setFieldError(reporter)
        //   return;
        // }
        else if(!values.priority){
          enqueueSnackbar(`Please select Priority`, { variant: 'error' });
          setFieldError(priority)
          return;
        }else{
        if (values.start_date) {
          values.start = moment(values.start_date).valueOf() / 1000;
          if (isNaN(values.start)) {
            values.start = '';
          }
        }
        else {
          values.start = '';
        }
        if (values.end_date) {
          values.end = moment(values.end_date).valueOf() / 1000;
          if (isNaN(values.end)) {
            values.end = '';
          }
        }
        else {
          values.end = '';
        }
        values.projectId=projectId
        console.log("####################Values",values);
        if(title === 'Add SubTask' || title === 'Edit SubTask'){
          if(data){
            axios.post(`${REST_API_END_POINT}project/update-project-sub-task`,{ ...values },{
              headers: {
                'Content-Type': 'application/json',
              },
              // headers: { 'Content-Type': 'multipart/form-data' }
            }).then((response) => {
              if(response.data.status === 1){
                resetForm()
                setSubmitting(false);
                setAdd(false);
                setFetchData(true)
                resetForm(); // Clear the form after successful submission
                data=[]
                enqueueSnackbar(response.data.message, { variant: 'success' });
              } else{
                enqueueSnackbar(response.data.message, { variant: 'error' });
              }
            }).catch((error) => {
              console.error(error);
            })
          }else{
            axios.post(`${REST_API_END_POINT}project/add-project-sub-task`,{ ...values },{
              headers: {
                'Content-Type': 'application/json',
              },
              // headers: { 'Content-Type': 'multipart/form-data' }
            }).then((response) => {
              if(response.data.status === 1){
                resetForm()
                setSubmitting(false);
                setAdd(false);
                setFetchData(true)
                resetForm(); // Clear the form after successful submission
                data=[]
                enqueueSnackbar(response.data.message, { variant: 'success' });
                setRefresh(!refresh);
              } else{
                enqueueSnackbar(response.data.message, { variant: 'error' });
              }
            }).catch((error) => {
              console.error(error);
            })
          }
        } else if(!data){
          axios.post(`${REST_API_END_POINT}project/add-project-task`,{ ...values },{
            headers: {
              'Content-Type': 'application/json',
            },
            // headers: { 'Content-Type': 'multipart/form-data' }
          }).then((response) => {
            if(response.data.status === 1){
              setSubmitting(false);
              setAdd(false);
              setIsOpen(false)
              resetForm(); // Clear the form after successful submission
              enqueueSnackbar(response.data.message, { variant: 'success' });
            } else{
              enqueueSnackbar(response.data.message, { variant: 'error' });
            }
          }).catch((error) => {
            console.error(error);
          })
        }else{
          axios.post(`${REST_API_END_POINT}project/update-project-task`,{ ...values },{
            headers: {
              'Content-Type': 'application/json',
            },
            // headers: { 'Content-Type': 'multipart/form-data' }
          }).then((response) => {
            if(response.data.status === 1){
              enqueueSnackbar(response.data.message, { variant: 'success' });
              setSubmitting(false);
              setAdd(false);
              setIsOpen(false)
              resetForm(); // Clear the form after successful submission
            } else{
              enqueueSnackbar(response.data.message, { variant: 'error' });
            }
          }).catch((error) => {
            console.error(error);
          })
        }
      }
      onRefresh(true);
      } catch (error) {
        console.error("Adding error - ", error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps,resetForm } = formik;

  return (
    <Modal
      open={add}
      handleClose={() => {
        setAdd(false);
        setView?setView(false):""
        if(title !== 'Add Task'){
          setData(null)
        }
      }}
      modalTitle={title}
    >
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container justifyContent="center" >
            <Grid item xs={12} md={10}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Name"
                    {...getFieldProps('name')}
                    disabled={view}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    InputProps={{ style: { minHeight: 100, } }}
                    fullWidth
                    label="Description"
                    {...getFieldProps('description')}
                    disabled={view}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <Box
                    component={ MobileDatePicker }
                    label="Start Date"
                    minDate={moment()}
                    value={values.start_date}
                    disabled={view}
                    onChange={(date) => setFieldValue('start_date', date)}
                    renderInput={(params) => (
                      <TextField size="small" {...params} fullWidth
                        error={Boolean(touched.start_date && errors.start_date)}
                        helperText={touched.start_date && errors.start_date}
                      />
                    )}
                  />
                  <Box
                    component={ MobileDatePicker }
                    label="End Date"
                    minDate={values.start_date || moment()}
                    value={values.end_date}
                    disabled={view}
                    onChange={(date) => setFieldValue('end_date', date)}
                    renderInput={(params) => (
                      <TextField size="small" {...params} fullWidth 
                        error={Boolean(touched.end_date && errors.end_date)}
                        helperText={touched.end_date && errors.end_date}
                      />
                    )}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  {/* <Autocomplete
                    select
                    fullWidth
                    id="reporter"
                    options={projectMembers || []}
                    defaultValue={data?.reporter_id ? projectMembers?.find(opt=>opt.id===data.reporter_id) : null}
                    value={projectMembers?.find(opt=>opt.id===values.reporter) || null}
                    getOptionLabel={(state) => state.name}
                    disabled={view}
                    onChange={(event, value) => {
                      setFieldValue('reporter', value.id);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Reporter"
                        error={Boolean(touched.reporter && errors.reporter)}
                        helperText={touched.reporter && errors.reporter}
                      />
                    )}
                  /> */}
                  {(title==='Add SubTask'||title==="Edit SubTask")&& (
                    <Autocomplete
                      multiple
                      fullWidth
                      id="assigned_to"
                      options={projectMembers || []}
                      value={values.assigned_to}
                      getOptionLabel={(state) => state.name}
                      disabled={view}
                      onChange={(event, value) => {
                        setFieldValue('assigned_to',value);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Assignee"
                          error={Boolean(touched.assigned_to && errors.assigned_to)}
                          helperText={touched.assigned_to && errors.assigned_to}
                        />
                      )}
                    />)}
                  <Autocomplete
                    select
                    fullWidth
                    id="priority"
                    options={option}
                    disabled={view}
                    defaultValue={data?.priority ? option.find(opt=>opt.id===data.priority) : null}
                    value={option.find(opt=>opt.id===values.priority) || null}
                    getOptionLabel={(state) => state.value}
                    onChange={(event, value) => {
                      setFieldValue('priority', value.id);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Priority"
                        error={Boolean(touched.priority && errors.priority)}
                        helperText={touched.priority && errors.priority}
                      />
                    )}
                  />
                </Stack>
                    {(title === 'Add SubTask' || title === 'Edit SubTask')&& (
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 3, sm: 2 }}
                    >
                     
                    <Autocomplete                   
                        fullWidth
                        id="skills"
                        multiple
                        getOptionLabel={(state) => state.name}
                        options={skillsandServices||[]}                  
                        value={values.skills}                      
                        onChange={(event, value) => {
                          setFieldValue('skills', value);
                        }}
                        // sx={{ width: 400 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Select Skills"
                            error={Boolean(touched.skills && errors.skills)}
                            helperText={touched.skills && errors.skills}
                          />
                        )}
                      />  
                    </Stack>
                  )}
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                  {!view && <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    Save
                  </LoadingButton>}
                  <Button variant="outlined" onClick={()=>{
                    resetForm();
                    setAdd(false)
                    if(title !== 'Add Task'){
                      setData(null)
                      setView(false)
                    }
                  }}>
                    Cancel
                  </Button>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Modal>
  );
}

