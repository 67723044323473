
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles'
import { experimentalStyled as styled } from '@material-ui/core/styles'
import {
     Container,Grid,IconButton,
     Stack,Box,Button, TableRow,
     TableBody, Checkbox,Card,
     TableCell,Typography,
     TableContainer,Table, TablePagination,
     AvatarGroup,
     Tooltip,
     Avatar,
     Autocomplete,
     TextField,
    } from '@material-ui/core';

    // routes
    import { PATH_DASHBOARD } from 'src/routes/paths';
    // components
    import Scrollbar from 'src/components/Scrollbar';
import { UserListHead, UserMoreMenu } from '../../user/list';
import Label from 'src/components/Label';
import { filter } from 'lodash';
import SearchNotFound from 'src/components/SearchNotFound';
import  ProfileTicketsToolbar  from 'src/components/_dashboard/user/list/ProfileTicketsToolbar';
import ConfirmationPopup from 'src/components/confirmationPopup';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
import axios from 'axios'
import { useSnackbar } from 'notistack';
import moment from 'moment';
import Modal from '../../blog/ProjectModel';
import { MapControlScale } from 'src/components/map';

const TABLE_HEAD=[
  { id: 'ID', label: 'ID', alignRight: false },      
  { id: 'name', label: 'Tickets', alignRight: false },
  { id: 'module_name', label: 'Module', alignRight: false },
  { id: 'timestamp', label: 'Created At', alignRight: false },
  { id: 'due_date', label: 'Due Date', alignRight: false },
  { id: 'created_by', label: 'Created By', alignRight: false },
  { id: 'taskMembers', label: 'Assignee', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },              
  { id: '', label: '', alignRight: false },              
]
   
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// function applySortFilter(array, comparator, query,status,selectedModule ) {
//   if (!Array.isArray(array)) {
//     return [];
//   }

//   const stabilizedThis = array?.map((el, index) => [el, index]);
//   stabilizedThis?.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });

//   if (query) {
//     return filter(array, (_user) => 
//       _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
//     (`SW-${_user.ID}`.toLowerCase().indexOf(query.toLowerCase()) !== -1)
//   );
//   }
//   if(status){
//     return filter(array,(user)=> user.status === status)
//    }

//      // Apply module filter
//   if (selectedModule) {
//     return filter(array, (user) => user.module_name == selectedModule.module_name);
//   }


//   return stabilizedThis?.map((el) => el[0]) || [];
// }

function applySortFilter(array, comparator, query, status, selectedModule) {
  if (!Array.isArray(array)) {
    return [];
  }

  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  // Apply all filters together
  let filteredArray = array;

  // Apply search query filter
  if (query) {
    filteredArray = filter(filteredArray, (_user) =>
      _user.name.toLowerCase().includes(query.toLowerCase()) ||
      `SW-${_user.ID}`.toLowerCase().includes(query.toLowerCase())
    );
  }

  // Apply status filter
  if (status) {
    filteredArray = filter(filteredArray, (user) => user.status === status);
  }

  // Apply module filter
  if (selectedModule) {
    filteredArray = filter(filteredArray, (user) => user.module_name === selectedModule.module_name);
  }

  return stabilizedThis
    ?.filter(([el]) => filteredArray.includes(el))
    .map((el) => el[0]) || [];
}

export default function SupportTickets({tickets,buttonStatus,setRefresh,refresh,fromClient}){
    const navigate = useNavigate()
    const userSession = JSON.parse(localStorage.getItem('user'))
    const theme = useTheme()
    const {enqueueSnackbar} =useSnackbar()
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('desc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('ID');
    const [openDelete,setOpenDelete] = useState({id:null,type:false})
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
     const [opens, setOpens] = useState(false);
     const [selectedRow,setSelectedRow] = useState(null)
     const [modules, setModules] = useState(null)
     const [selectedModule, setSelectedModule] = useState(null); // State for module filter



     const fetchAllMembers = async () => {
      try {
        axios
          .post(`${REST_API_END_POINT}tickets/get-team-data`)
          .then((res) => {
            if (res.data.status === 1) {
             
              setModules(res.data.Modules)
              console.log('ModulesModulesModules',res.data.Modules)
              
            }else{
              setMembers([])
            }
          })
          .catch((error) => {
            console.log(error)
          })
      } catch (error) {
        console.log(error)
      }
    }

    useEffect(()=>{
      fetchAllMembers()
    },[])

  const handleOpens = (data) => {
    console.log('Avatar clicked');
    setOpens(true);
    setSelectedRow(data)
  };

  const handleCloses = () => {
    console.log('close clicked');
    setOpens(false);
    setSelectedRow(null)

  };


    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = tickets?.map((n) => n.ID);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    };
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected?.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
      };
      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
      const DeleteTicket = async () => {
        try {
          let res = await axios.post(`${REST_API_END_POINT}tickets/delete-ticket`, {
            taskId: openDelete.id,
            userId: userSession.user_id,
          })
          if (res.data.status == 1) {
            enqueueSnackbar(res.data.message, { variant: 'success' })
            setOpenDelete({id:null,type:false})
            setRefresh(!refresh)
          } else {
            enqueueSnackbar('failed to delete ticket', { variant: 'error' })
            setOpenDelete({id:null,type:false})
          }
          setDelPopup(!delPopup)
          setIsOpen(!open)
        } catch (error) {
          console.log(error, 'error in deleting ticket')
        }
      }
      const handleFilterByName = (event) => {
        setFilterName(event.target.value);
      };
      const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tickets?.length) : 0;

      const filteredUsers = applySortFilter(tickets, getComparator(order, orderBy), filterName,buttonStatus,selectedModule  );
    
      const isUserNotFound = filteredUsers?.length === 0;
    return(
        <Card>
            <Box sx={{display:"flex",}}>

           <ProfileTicketsToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
        <Autocomplete
        sx={{mt:2.5,width:"240px"}}
              options={modules}  
              getOptionLabel={(option) => option.module_name} 
             value={selectedModule || null}
              onChange={(event, newValue) => {
                if(newValue){
                  setSelectedModule(newValue);
                }else{
                  setSelectedModule(null);
                }
              }} 
              renderInput={(params) => (
                <TextField {...params} label="Select Module" variant="outlined" />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id} 
            />
              </Box>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                 order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                 rowCount={tickets?.length}
                 numSelected={selected.length}
                 onRequestSort={handleRequestSort}
                 onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                 {filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => {             
                  const { ID, name,project_name, status,timestamp,created_by,module_name,taskMembers,due_date
                  } = row;

                  console.log('filteredUsersfilteredUsers',row)
                  const isItemSelected = selected.indexOf(ID) !== -1;
                  return (
                    <TableRow
                      hover
                      key={ID}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        {/* <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, ID)} /> */}
                      </TableCell>
                     
                      <TableCell align="center" scope="row" padding="none">{"SW-"}{ID}</TableCell>
                      <TableCell align="center" sx={{cursor:'pointer'}}
                        onClick={()=>navigate(PATH_DASHBOARD.crm.viewticket,{state:{id:ID}})}
                         component="th" scope="row" padding="none">                         
                              {name}                       
                        </TableCell>
                      <TableCell align="center">{module_name}</TableCell>
                       { console.log(moment.unix(timestamp).format('MM/DD/YYYY'))}
                      <TableCell align="center">{timestamp ? moment.unix(timestamp).format('MM/DD/YYYY'):''}</TableCell>
                      <TableCell align="center">{due_date?moment.unix(due_date).format('MM/DD/YYYY') : ''}</TableCell>
                      <TableCell align="center">{created_by}</TableCell>

                      <TableCell align="center">

                      <AvatarGroup >
    {taskMembers?.slice(0, 2).map((row) => (
      <Tooltip key={row.ID} title={row.username || 'No Name Provided'}>
        <Avatar src={row.profile_pic} alt={row.username} />
      </Tooltip>
    ))}
    {taskMembers?.length > 2 && (
      <Tooltip title="Show all">
        <Avatar
          onClick={()=>handleOpens(taskMembers)}
          style={{ cursor: 'pointer', backgroundColor: '#c8e6c9' }}
        >
          +{taskMembers.length - 2}
        </Avatar>
      </Tooltip>
    )}
  </AvatarGroup>

                      {/* <Box sx={{width:"200px",height:"100px",position:"fixed",backgroundColor:"#fff",color:"#111"}}>
                        d
                        </Box> */}


                      </TableCell>

                       <TableCell align="center">                     
                       <Label
                          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                          color={status === 1 ? 'default' : status === 2 ? 'info' : status === 3 ? 'success' : status === 4? "warning": 'error'}
                        >
                       {status===1?'New':status===2 ? 'In Progress':status===3?'Completed':status===4?'Re Open':'Cancelled'}
                        </Label> 
                      </TableCell> 
                      {/* {(!fromClient ||  userSession.user_type == 1) && ( */}
                      {(userSession.user_type == 1 || userSession.user_type == 8) ?
                      <TableCell>
                        <UserMoreMenu 
                        noEdit
                        onDelete={()=>setOpenDelete({id:ID,type:true})}/>
                      </TableCell>
                      : ''
                      }
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
          <ConfirmationPopup 
          open={openDelete.type}
          message={'You want to delete this ticket'}
          handleClose={()=>{setOpenDelete({type:false,id:null})}}
          handleClick={()=>DeleteTicket()}
          />

            <Modal open={opens} handleClose={handleCloses} width={400}>
              <Box
              >
                <Typography variant="h6" gutterBottom>
                  All Support Agents (Assignee's)
                </Typography>
                <Scrollbar sx={{height:'350px'}}>
                  <Stack spacing={2}>
                    {selectedRow?.map((row) => (
                      <Stack key={row.ID} direction="row" spacing={2} alignItems="center">
                        <Avatar src={row.profile_pic} alt={row.username} />
                        <Typography variant="body1">{row.username || 'No Name Provided'}</Typography>
                      </Stack>
                    ))}
                  </Stack>
                </Scrollbar>
                <Button
                  variant="contained"
                  onClick={handleCloses}
                  sx={{ mt: 2, display: 'block', ml: 'auto' }}
                >
                  Close
                </Button>
              </Box>
            </Modal>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50,100]}
          component="div"
          count={filteredUsers?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    )
}