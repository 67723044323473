import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, List, Avatar, Button, Collapse, ListItem, ListItemText, ListItemAvatar, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
//
import BadgeStatus from 'src/components/BadgeStatus';
import Scrollbar from 'src/components/Scrollbar';
import ChatRoomPopupSignWise from './ChatRoomPopupSignWise';
import { AdminPanelSettings, MoreHoriz, RemoveCircleOutline, Stars, WorkspacesOutlined } from '@material-ui/icons';
import BadgeStatusSignwiseChat from 'src/components/BadgeStatusSignwiseChat';
import { REST_API_END_POINT, STATUS_OPTIONS } from 'src/constants/Defaultvalues';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

const HEIGHT = 64;

const CollapseButtonStyle = styled(Button)(({ theme }) => ({
  ...theme.typography.overline,
  height: 40,
  borderRadius: 0,
  padding: theme.spacing(1, 2),
  justifyContent: 'space-between',
  color: theme.palette.text.disabled
}));

// ----------------------------------------------------------------------

Participant.propTypes = {
  participant: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  onClosePopup: PropTypes.func,
  onShowPopup: PropTypes.func
};

function Participant({ participant,participants, isOpen, onClosePopup, onShowPopup, handleRefresh }) {
  const { first_name, last_name, is_admin, profile_pic, status, messengerStatus, conversation_id, active,chat_status, position, user_id } = participant;
  let user = JSON.parse(localStorage.getItem('user'))
  const fullName = user_id === user.user_id ? 'You' : `${first_name} ${last_name}`
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [userIsAdmin, setUserIsAdmin] = useState(false)

  useEffect(() => {
    const filteredParticipants = participants?.filter(item => item.is_admin);
    const adminData = filteredParticipants?.find(participant => participant.user_id === user.user_id);
    setUserIsAdmin(!!adminData);
  }, [participants, user]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleAdminStatus = async () =>{
    handleClose();
    try{
      const res = await axios.post(`${REST_API_END_POINT}chat/toggle-admin-status`, 
        {conversationId:conversation_id, userId:user_id, isAdmin:is_admin})
      if(res.data.status == 1){
        enqueueSnackbar(res.data.message, { variant: 'success' })
        handleRefresh()
      }else{
        enqueueSnackbar(res.data.message, { variant: 'error' })
      }
    }catch(error){
      console.error('Error in handleToggleAdminStatus : ', error);
      enqueueSnackbar('Error updating admin status', { variant: 'error' })
    }
  }
  return (
    <>
      <ListItem button disableGutters onClick={()=>{onShowPopup();}} sx={{ height: HEIGHT, px: 2.5 }}>
        <ListItemAvatar>
          <Box sx={{ position: 'relative', width: 40, height: 40 }}>
            <Avatar alt={fullName} src={profile_pic} />
            <BadgeStatusSignwiseChat status={chat_status ? STATUS_OPTIONS?.find(item=>item.id == chat_status)?.statusData : 'offline'} sx={{ right: 0, bottom: 0, position: 'absolute' }} />
          </Box>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Box sx={{ display: 'flex', alignItems: 'center' }}> {fullName}
              {is_admin === 1 && <Stars style={{ marginLeft: 5, fontSize:16, color: '#FFD700' }} />}
            </Box>
          }
          secondary={position}
          primaryTypographyProps={{ variant: 'subtitle2', noWrap: true }}
          secondaryTypographyProps={{ noWrap: true }}
        />
        {userIsAdmin ?
        <ListItemIcon onClick={handleClick}>
          <MoreHoriz />
        </ListItemIcon>
        : null }
      </ListItem>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {is_admin ? (
          <MenuItem onClick={() => handleToggleAdminStatus()} sx={{ gap: 1, padding: '8px 16px' }}>
            {/* <ListItemIcon> */}
              <RemoveCircleOutline sx={{ fontSize: 18, color: '#F44336' }} />
            {/* </ListItemIcon> */}
            <Typography variant="body2">Remove Admin</Typography>
          </MenuItem>
        ) : (
          <MenuItem onClick={() => handleToggleAdminStatus()} sx={{ gap: 1, padding: '8px 16px' }}>
            {/* <ListItemIcon> */}
              <AdminPanelSettings sx={{ fontSize: 18, color: '#4CAF50' }} />
            {/* </ListItemIcon> */}
            <Typography variant="body2">Make Admin</Typography>
          </MenuItem>
        )}
      </Menu>
      {/* <ChatRoomPopupSignWise participant={participant} isOpen={isOpen} onClose={onClosePopup} /> */}
    </>
  );
}

ChatRoomGroupParticipantSignWise.propTypes = {
  participants: PropTypes.array.isRequired,
  selectUserId: PropTypes.string,
  onShowPopupUserInfo: PropTypes.func,
  isCollapse: PropTypes.bool,
  onCollapse: PropTypes.func
};

export default function ChatRoomGroupParticipantSignWise({
  participants,
  selectUserId,
  onShowPopupUserInfo,
  isCollapse,
  onCollapse,
  handleRefresh,
  ...other
}) {
  let user = JSON.parse(localStorage.getItem('user'));

  // Sort participants in order: First myself, then admins (A-Z), then others (A-Z)
  const sortedParticipants = [...participants].sort((a, b) => {
    // Check if user is in the list
    if (a.user_id === user.user_id) return -1;
    if (b.user_id === user.user_id) return 1;

    // Sort admins alphabetically
    if (a.is_admin === 1 && b.is_admin !== 1) return -1;
    if (b.is_admin === 1 && a.is_admin !== 1) return 1;
    if (a.is_admin === 1 && b.is_admin === 1) {
      return a.first_name.localeCompare(b.first_name);
    }

    // Sort non-admins alphabetically
    return a.first_name.localeCompare(b.first_name);
  });
  return (
    <Box {...other}>
      <CollapseButtonStyle
        fullWidth
        disableRipple
        color="inherit"
        onClick={onCollapse}
        endIcon={<Icon icon={isCollapse ? arrowIosDownwardFill : arrowIosForwardFill} width={16} height={16} />}
      >
        In room ({participants?.length})
      </CollapseButtonStyle>

      <Box sx={{ height: isCollapse ? HEIGHT * 4 : 0 }}>
        <Scrollbar>
          <Collapse in={isCollapse}>
            <List disablePadding>
              {sortedParticipants?.map((participant) => (
                <Participant
                  key={participant?.id}
                  participants={participants}
                  participant={participant}
                  isOpen={selectUserId === participant?.id}
                  onShowPopup={() => onShowPopupUserInfo(participant?.id)}
                  onClosePopup={() => onShowPopupUserInfo(null)}
                  handleRefresh={handleRefresh}
                />
              ))}
            </List>
          </Collapse>
        </Scrollbar>
      </Box>
    </Box>
  );
}
