import React, { forwardRef, useState,useEffect } from 'react';
// material
import CloseIcon from '@material-ui/icons/Close';
import {
  List,
  Slide,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  Divider,
  ListItem,
  IconButton,
  Typography,
  ListItemText
} from '@material-ui/core';
//
import ViewofficialRequest from 'src/pages/dashboard/ViewQuotation';
import UserAccount from 'src/pages/dashboard/UserAccount';
import UserNewForm from 'src/components/_dashboard/user/UserEdit';

// ----------------------------------------------------------------------

const Transition = forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

 function FullScreenDialogClients({
  open,
  setIsClientEdit,
  setOpen,
  projectId: initialProjectId,
  projectName: initialProjectName,
  setRenderCards,
  renderCards,
  team,
  editClient,
  clientType: initialClientType,
  clearPreviousData
}) {
  
    

    const [viewAccount, setViewAccount] = useState( localStorage.getItem('clientViewAccount') || editClient )
    console.log("viewAccountviewAccount",viewAccount);
    
  const [clientEdit, setClientEdit] = useState(
    localStorage.getItem('clientEdit') || initialClientType || ''
  );
  const [clientId, setClientId] = useState(
    localStorage.getItem('clientId') || initialProjectId || ''
  );
  const [clientName, setClientName] = useState(
    localStorage.getItem('clientName') || initialProjectName || ''
  );

  useEffect(() => {
    if (initialProjectId) {
      setClientId(initialProjectId);
      localStorage.setItem('clientId', initialProjectId);
    }
  }, [initialProjectId]);

  useEffect(() => {
    if (initialClientType) {
      setClientEdit(initialClientType);
      localStorage.setItem('clientEdit', initialClientType);
    }
  }, [initialClientType]);

  useEffect(() => {
    
      setViewAccount(editClient);
      localStorage.setItem('clientViewAccount',editClient);
  }, [editClient]);

  useEffect(() => {
    if (open) {
      localStorage.setItem('clientId', clientId);
      localStorage.setItem('clientName', clientName);
      localStorage.setItem('clientEdit', clientEdit);
      localStorage.setItem('clientViewAccount', viewAccount);
      localStorage.setItem('clientPopupOpen', 'true');
    } else {
      localStorage.removeItem('clientPopupOpen');
    }
  }, [open, clientId, clientName, clientEdit, viewAccount]);

  useEffect(() => {
    if (open) {
      const storedClientId = localStorage.getItem('clientId') || initialProjectId || '';
      const storedClientName = localStorage.getItem('clientName') || initialProjectName || '';
      const storedClientEdit = localStorage.getItem('clientEdit') || initialClientType || '';
      const storedViewAccount =localStorage.getItem('clientViewAccount') || editClient||'' ;
  
      setClientId(storedClientId);
      setClientName(storedClientName);
      setClientEdit(storedClientEdit);
      setViewAccount(storedViewAccount);
    }
  }, [open]);
  useEffect(() => {
    const isPopupOpen = localStorage.getItem('clientPopupOpen') === 'true';
    if (isPopupOpen) {
      setOpen(true); // Open the dialog only if it was open before the refresh
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    setViewAccount('');
    setIsClientEdit('');
    setClientId('');
    setClientName('');
    setClientEdit('');
    clearPreviousData()
    // Clear all localStorage keys related to the dialog state
    localStorage.removeItem('clientId');
    localStorage.removeItem('clientName');
    localStorage.removeItem('clientEdit');
    localStorage.removeItem('clientViewAccount');
    localStorage.removeItem('clientPopupOpen');
  };
  return (
    <>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} >
        <AppBar position="relative" mb={3}>
          <Toolbar>
            <Typography variant="h6" sx={{ flex: 1, ml: 2 }}>
              {clientName}
            </Typography>
              <CloseIcon onClick={handleClose} sx={{fontSize:'2.7rem',cursor:'pointer'}}/>
          </Toolbar>
        </AppBar>
        {
          viewAccount==="clientView" ?
          <MemoizedUserAccount popupOn={true} team={team} setRenderCards={setRenderCards}  idOfProject={clientId}  closeView={handleClose} renderCards={renderCards} setClientEdit={setClientEdit} setClientId={setClientId} setViewAccount={setViewAccount} clientType={clientEdit} />
        :
        <MemoizedUserNewForm popupOn={true} team={team} setRenderCards={setRenderCards} clientType={clientEdit}   closeView={handleClose} renderCards={renderCards}  editClientId={clientId} setViewAccount={setViewAccount}/>
        
        }      
        </Dialog>
    </>
  );
}
const MemoizedUserAccount = React.memo(UserAccount);
const MemoizedUserNewForm = React.memo(UserNewForm);

export default React.memo(FullScreenDialogClients);