import * as Yup from 'yup';
// import PropTypes from 'prop-types';
import { useCallback ,useEffect,useState} from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton,MobileDatePicker } from '@material-ui/lab';
import { useTheme } from '@material-ui/core/styles';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { PictureAsPdf } from '@material-ui/icons';
import {
  Box,
  Card,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
  Link,
  FormHelperText,
  FormControlLabel,
  Button,
  Autocomplete,
  Chip,
} from '@material-ui/core';
import { LinearProgress } from '@mui/material';

// utils
import { fData } from '../../../utils/formatNumber';
import fakeRequest from '../../../utils/fakeRequest';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
//
import Label from '../../Label';
import { UploadAvatar, UploadSingleFile } from '../../upload';

import countries from '../user/countries';
import axios from 'axios';
import { REST_API, REST_API_END_POINT } from 'src/constants/Defaultvalues';
import moment from 'moment';
import faker from 'faker';
import {storage} from '../../../firebase/firebase'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { DialogAnimate } from 'src/components/animate';

export default function AddProject ({ singleProjectData, setRenderCards, renderCards, setAdd, isEdit= false, setIsEdit }){
    const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [tags, setTags] = useState([]);
  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));
  const [inputValue, setInputValue] = useState('');
  const [selectedRFQOptions, setSelectedRFQOptions] = useState(null);
  const [projectTypeOptions, setProjectTypeOptions] = useState(null);
  const [communityOptions, setCommunityOptions] = useState(null);
  const [projectCategoryOptions, setProjectCategoryOptions] = useState(null);
  const [stateOptions, setStateOptions] = useState(null);
  const [projectTypes, setProjectTypes] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [projectCategories, setProjectCategories] = useState([]);
  const [acceptedRequests, setAcceptedRequests] = useState([]);
  const [states, setStates] = useState([]);
  const [progress,setProgress] = useState(null)
  const [fileProgress,setFileProgress] = useState(null)
  const [open,setOpen] = useState(false)

  useEffect(() => {
    console.log(singleProjectData);
    setTags([...(singleProjectData?.result.tags ? singleProjectData.result.tags.split(',') : [] || [])])
    setSelectedRFQOptions(singleProjectData?.acceptedRequests?.find(type=>type.id === singleProjectData?.result.request_id) || null)
    setProjectTypeOptions(singleProjectData?.projectTypes?.find(type=>type.id === singleProjectData?.result.project_type))
    setCommunityOptions(singleProjectData?.community?.find(type=>type.id === singleProjectData?.result.community))
    setProjectCategoryOptions(singleProjectData?.projectCategories?.find(type=>type.id === singleProjectData?.result.project_category))
    setStateOptions(singleProjectData?.states?.find(type=>type.id === singleProjectData?.result.state_id) || null)
  },[singleProjectData])

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      setTags([...tags, inputValue.trim()]);
      setInputValue('');
    }
  };

  const handleDelete = (tagToDelete) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    short_name: Yup.string().max(5).required('Short Name is required'),
    // end:Yup.mixed().required('End Date is required'),
    // end: Yup.mixed()
    // .required('End Date is required')
    // .test({
    //   name: 'dateComparison',
    //   message: 'End date should be between Start date and Installation date',
    //   test: function (value) {
    //     const { start, installation } = this.parent;
    //     return (
    //       (start === undefined || installation === undefined || value === undefined) ||
    //       (start <= value && value <= installation)
    //     );
    //   },
    // }),
    // Type: Yup.string().required('Project Type is required'),
    // Category: Yup.string().required('Project Category is required'),
    // tags: Yup.string().required('Tags is required'),
    // rfq: Yup.string().required('RFQ is required'),
    // state: Yup.string().required('State is required'),
    // city: Yup.string().required('City is required'),
    cost: Yup.number().typeError('Please enter a valid cost').required('Expected cost is required'),
    // avatarUrl: Yup.mixed().required('Avatar is required')
    
  });
//
const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1)
  }));
  //

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: singleProjectData?.result.name || '',
      short_name: singleProjectData?.result.short_code || '',
      start: (singleProjectData ? moment.unix(singleProjectData.result.start_date) : null),
      // end: (singleProjectData ? moment.unix(singleProjectData.result.due_date) : null),
      installation: (singleProjectData ? moment.unix(singleProjectData.result.installation_date) : null),
      // url: singleProjectData?.result.website_url || '',
      // city: singleProjectData?.result.city || '',
      cost: singleProjectData?.result.cost || null,
      description: singleProjectData?.result.description || '',
      avatarUrl: singleProjectData?.result.cover_image || null,
      cover: singleProjectData?.result.approved_image || null,
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        const  user  = JSON.parse(window.localStorage.getItem('user'))
        if(isEdit){
          values.projectId = singleProjectData?.result.id
        }else {
          values.project_uid = faker.datatype.uuid()
        }
        values.added_by = user.user_id
        values.tags = tags.join(',')
        values.rfq = selectedRFQOptions?.id
        values.rfqServices= selectedRFQOptions?.services
        values.project_category = projectCategoryOptions?.id
        values.project_type = projectTypeOptions?.id
        values.community = communityOptions?.id
        // values.state = stateOptions?.id
        if(selectedRFQOptions === null){
          enqueueSnackbar(`Please select rfq service`, { variant: 'error' });
          return;
        }else if(values.start === null){
          enqueueSnackbar(`Please select an Start date`, { variant: 'error' });
          return;
        // }else if(values.end === null){
        //   enqueueSnackbar(`Please select an End date`, { variant: 'error' });
        //   return;
        // }else if(!(moment(values.end).isAfter(moment(values.start)))){
        //   enqueueSnackbar(`End date is not valid`, { variant: 'error' });
        //   return;
        }else if(values.installation === null){
          enqueueSnackbar(`Please select an Installation date`, { variant: 'error' });
          return;
        // }else if(!(moment(values.installation).isAfter(moment(values.end)))){
        //   enqueueSnackbar(`Installation date is not valid`, { variant: 'error' });
        //   return;
        }
        // else if(stateOptions===null){
        //   enqueueSnackbar(`Please select the state`, { variant: 'error' });
        //   return;
        // }
        else{
        if (values.start) {
          values.start_date = moment(values.start).valueOf() / 1000;
          if (isNaN(values.start_date)) {
            values.start_date = '';
          }
        }
        else {
          values.start_date = '';
        }
        if (values.end) {
          values.end_date = moment(values.end).valueOf() / 1000;
          if (isNaN(values.end_date)) {
            values.end_date = '';
          }
        }
        else {
          values.end_date = '';
        }
        if (values.installation) {
          values.installation_date = moment(values.installation).valueOf() / 1000;
          if (isNaN(values.installation_date)) {
            values.installation_date = '';
          }
        }
        else {
          values.installation_date = '';
        }
      
        // if(tags.length==0){
        //   enqueueSnackbar(`Tag is required`, { variant: 'error' });
        //   return;
        // }
        if(isEdit){
          axios.post(`${REST_API_END_POINT}project/update-project`,{ ...values },{
            headers: {
              'Content-Type': 'application/json',
            },
            // headers: { 'Content-Type': 'multipart/form-data' }
          }).then((response) => {
            if(response.data.status === 1){
              setRenderCards('true')
              onDataChange()
              setSubmitting(false);
              setAdd(false);
              setIsEdit(false)
              enqueueSnackbar(!isEdit ? 'Create success' : 'Update success', { variant: 'success' });
            }else if(response.data.status === 2){
              setAdd(false);
              setIsEdit(false)
              enqueueSnackbar( response.data.message,{ variant: 'error' })
            } else{
              setAdd(false);
              setIsEdit(false)
              enqueueSnackbar(`Failed to ${!isEdit ? 'Create' : 'Update'} project`, { variant: 'error' });
            }
            resetForm();
          }).catch((error) => {
            console.error(error);
          })
        }else{
            try {
              setOpenConvertToRFQ(false);
              setConvertToRFQ(true);
              let requestId = selectedRFQOptions?.id
              let requestPartnerId = selectedRFQOptions?.rt_partner_id
              let acceptStatus = 1
              let userId = user.user_id
              let reason = ''
        
              const data = { requestId, requestPartnerId, acceptStatus, userId, reason }
              const res = await axios.post(`${REST_API}webservice/request/accept-or-reject-invoice`, data);
        
              if (res.data.status == 1) {
                setRenderCards('true')
                onDataChange()
                setSubmitting(false);
                setAdd(false);
                setIsEdit(false);
                enqueueSnackbar('Job created successfully', { variant: 'success' });
              } else {
                setAdd(false);
                setIsEdit(false)
                enqueueSnackbar(`Failed to Create project`, { variant: 'error' });
              }
            } catch (error) {
              console.log(error, 'error in Converting rfq to job')
              setConfirmInvoice(false)
              setRejectInvoice(false)
              enqueueSnackbar('Server Error', { variant: 'error' })
            }finally{
              setConvertToRFQ(false);
            }
        }
        // axios.post(`${REST_API_END_POINT}project/${isEdit ? 'update-project' : 'add-project'}`,{ ...values },{
        //     headers: {
        //       'Content-Type': 'application/json',
        //     },
        //     // headers: { 'Content-Type': 'multipart/form-data' }
        //   }).then((response) => {
        //     if(response.data.status === 1){
        //       setRenderCards('true')
        //       setSubmitting(false);
        //       setAdd(false);
        //       setIsEdit(false)
        //       enqueueSnackbar(!isEdit ? 'Create success' : 'Update success', { variant: 'success' });
        //     }else if(response.data.status === 2){
        //       setAdd(false);
        //       setIsEdit(false)
        //       enqueueSnackbar( response.data.message,{ variant: 'error' })
        //     } else{
        //       setAdd(false);
        //       setIsEdit(false)
        //       enqueueSnackbar(`Failed to ${!isEdit ? 'Create' : 'Update'} project`, { variant: 'error' });
        //     }
        //     resetForm();
        //   }).catch((error) => {
        //     console.error(error);
        //   })
        }
        
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;
 const handleSelectRequest=(value)=>{
   setSelectedRFQOptions(value)
    console.log("value-------------------------------",value);
    setProjectTypeOptions(projectTypes.find(type => type.id === value?.project_type))
    setProjectCategoryOptions(projectCategories.find(cat => cat.id === value?.project_category))
    setCommunityOptions(communities.find(type => type.id === value?.community))
    setFieldValue('avatarUrl',value?.thumbnail)
    setFieldValue('name',value?.name)
    setFieldValue('start',moment.unix(value?.submission_deadline))
    setFieldValue('installation',moment.unix(value?.participation_ends))
    setFieldValue('description',value?.description)
 }
  useEffect(() => {
    try {
      axios.post(`${REST_API_END_POINT}project/get-data-for-add-project`,{
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if(response.data.status === 1){
          setProjectTypes(response.data.projectTypes)
          setProjectCategories(response.data.projectCategories)
          setAcceptedRequests(response.data.acceptedRequests)
          setStates(response.data.states)
          setCommunities(response.data.community)
        }
      }).catch((error) => {
        console.error(error);
      })
      }
      catch (error) {
        console.error("Error fetching data:", error);
      }
  },[])

  // const handleDrop = useCallback(
  //   (acceptedFiles) => {
  //     const file = acceptedFiles[0];
  //     if (file) {
  //       setFieldValue('avatarUrl', {
  //         ...file,
  //         preview: URL.createObjectURL(file)
  //       });
  //     }
  //   },
  //   [setFieldValue]
  // );
  const handleDrop = useCallback(
    (acceptedFiles,type=null) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const file = acceptedFiles[0]; 
        const Filename = new Date().getTime() + file.name;
  
        const StorageRef = ref(storage, Filename);
        const uploadTask = uploadBytesResumable(StorageRef, file);
  
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            if(type==='cover'){
              setFileProgress(progress);
            }else{
              setProgress(progress);
            }
          },
          (error) => {
            console.error(error.message);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              if(type==='cover'){
                setFieldValue('cover', downloadURL)
              }else{
                setFieldValue('avatarUrl',downloadURL)
              }
              console.log(downloadURL, '&&&&&&&&&&&&&&&&&&&');
            });
          }
        );
      }
    },
    [setFieldValue]
  );
   const UploadImage=(e)=>{
    const file = e.target.files[0];
    setSelectedFile(file);
   }
  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} md={4}>
            <Card sx={{ py: 7, px: 3 }}>

              <Box sx={{ mb: 5 }}>
              <LabelStyle>Cover Photo</LabelStyle>
                <UploadAvatar
                  accept="image/*"
                  file={values.avatarUrl}
                  maxSize={3145728}
                  onDrop={handleDrop}
                  error={Boolean(touched.avatarUrl && errors.avatarUrl)}
                  
                  caption={
                    <Stack>
                      {(progress && progress < 100) ? <Box display={'flex'} width={'100%'} height={5} marginTop={2} justifyContent={'center'}><LinearProgress
                        sx={{ width: 60 }}
                        color="secondary"
                        variant="determinate"
                        value={progress}
                      /></Box> : ''}
                      <Typography
                        variant="caption"
                        sx={{
                          mt: 2,
                          mx: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          color: 'text.secondary'
                        }}
                      >
                        Allowed *.jpeg, *.jpg, *.png, *.gif
                        <br /> max size of {fData(3145728)}
                      </Typography>
                    </Stack>
                  }
                />

                <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                  {touched.avatarUrl && errors.avatarUrl}
                </FormHelperText>
                <LabelStyle>Approved Design</LabelStyle>

                <UploadSingleFile
                      maxSize={3145728}
                      accept=".pdf"
                      file={values.cover}
                      onDrop={(file)=>handleDrop(file,'cover')}
                      error={Boolean(touched.cover && errors.cover)}
                    />
                    {touched.cover && errors.cover && (
                      <FormHelperText error sx={{ px: 2 }}>
                        {touched.cover && errors.cover}
                      </FormHelperText>
                    )}
                    {(values.cover || fileProgress) ? <Box sx={{cursor:'pointer',minHeight:15}} onClick={()=>setOpen(true)} display={'flex'} borderRadius={1} width={'100%'} bgcolor={'#172e34'} px={2} py={1} minHeight={5} marginTop={2} justifyContent={'center'}>
                      {(fileProgress && fileProgress < 100) ? <LinearProgress
                        sx={{ width: 60 }}
                        color="secondary"
                        variant="determinate"
                        value={fileProgress}
                      /> : <Stack width={'95%'} direction={'row'} spacing={2} justifyContent={'flex-start'}>
                        <PictureAsPdf/>
                        <Typography>Attachment</Typography>
                      </Stack>}
                    </Box> : ''}
                    
              </Box>
              <Box>
              {values.cover && <DialogAnimate open={open} onClose={()=>setOpen(false)}>
                <Card sx={{ p: 3, maxWidth: 768, mx: 'auto' }}>
                  <embed src={values.cover} type="application/pdf" width="100%" height="600px" />
                </Card>
              </DialogAnimate>}
            </Box>

            </Card>
          </Grid> */}

          <Grid item xs={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <Autocomplete
                    // multiple
                    fullWidth
                    options={acceptedRequests}
                    getOptionLabel={(option) => option.name}
                    value={selectedRFQOptions}
                    onChange={(event, newValues) =>{handleSelectRequest(newValues)} }
                    renderInput={(params) => <TextField {...params} label="Select Request" />}
                  />
                  <TextField
                    fullWidth
                    label="Short Name"
                    {...getFieldProps('short_name')}
                    error={Boolean(touched.short_name && errors.short_name)}
                    helperText={touched.short_name && errors.short_name}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                 
                  <TextField
                    fullWidth
                    label="Name"
                    defaultValue={values.name}
                    value={values.name}
                    onInput={(event)=>setFieldValue('name',event.target.value)}
                    // {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  
                  <Autocomplete
                    freeSolo
                    multiple
                    fullWidth
                    id="tags-outlined"
                    // options={tags}
                    options={[]}
                    value={tags}
                    onChange={(event, newValue) => setTags(newValue)}
                    inputValue={inputValue}
                    onInputChange={handleInputChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Tags"
                        onKeyDown={handleKeyDown}
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          onDelete={() => handleDelete(tag)}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <Box
                component={ MobileDatePicker }
                label="Start Date"
                 value={values.start}
                onChange={(date) => setFieldValue('start', date)}
                renderInput={(params) => (
                  <TextField size="medium" {...params} fullWidth />
                )}
              />
                  {/* <Box
                component={ MobileDatePicker }
                label="End Date"
                 value={values.end}
                onChange={(date) => setFieldValue('end', date)}
                renderInput={(params) => (
                  <TextField size="medium" {...params} fullWidth 
                  error={Boolean(touched.end && errors.end)}
                  helperText={touched.end && errors.end}
                    />
                )}
              /> */}
                  <Box
                    component={ MobileDatePicker }
                    label="Installation Date"
                    value={values.installation}
                    onChange={(date) => setFieldValue('installation', date)}
                    renderInput={(params) => (
                      <TextField size="medium" {...params} fullWidth />
                    )}
                  />    
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <Autocomplete
                    // multiple
                    fullWidth
                    options={projectTypes}
                    getOptionLabel={(option) => option.name}
                    value={projectTypeOptions}
                    onChange={(event, newValues) => {console.log("newValues---",newValues); setProjectTypeOptions(newValues)}}
                    renderInput={(params) => <TextField {...params} label="Job Class" />}
                  />
                  <Autocomplete
                    // multiple
                    fullWidth
                    options={projectCategories}
                    getOptionLabel={(option) => option.name}
                    value={projectCategoryOptions}
                    onChange={(event, newValues) => setProjectCategoryOptions(newValues)}
                    renderInput={(params) => <TextField {...params} label="Job Types" />}
                  />

                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <Autocomplete
                    // multiple
                    fullWidth
                    options={communities}
                    getOptionLabel={(option) => option.name}
                    value={communityOptions}
                    onChange={(event, newValues) => {console.log("newValues---",newValues); setCommunityOptions(newValues)}}
                    renderInput={(params) => <TextField {...params} label="Project" />}
                  />
                  <TextField
                    fullWidth
                    type='number'
                    label="Expected Cost"
                    {...getFieldProps('cost')}
                    error={Boolean(touched.cost && errors.cost)}
                    helperText={touched.cost && errors.cost}
                  />
                  {/* <TextField
                    fullWidth
                    label="url"
                    {...getFieldProps('url')}
                    error={Boolean(touched.url && errors.url)}
                    helperText={touched.url && errors.url}
                    InputProps={{
                        endAdornment: (
                          <Link href={getFieldProps('url').value} target="_blank" rel="noopener noreferrer">
                            
                          </Link>
                        ),
                      }}
                  /> */}
                  {/* <TextField
                    select
                    fullWidth
                    label="Country"
                    placeholder="Country"
                    {...getFieldProps('country')}
                    SelectProps={{ native: true }}
                    error={Boolean(touched.country && errors.country)}
                    helperText={touched.country && errors.country}
                  >
                    <option value="" />
                    {countries.map((option) => (
                      <option key={option.code} value={option.label}>
                        {option.label}
                      </option>
                    ))}
                  </TextField> */}
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  {/* <TextField
                    fullWidth
                    label="State/Region"
                    {...getFieldProps('state')}
                    error={Boolean(touched.state && errors.state)}
                    helperText={touched.state && errors.state}
                  /> */}
                  {/* <Autocomplete
                    // multiple
                    fullWidth
                    options={states}
                    getOptionLabel={(option) => option.name}
                    value={stateOptions}
                    onChange={(event, newValues) => setStateOptions(newValues)}
                    renderInput={(params) => <TextField {...params} label="State" />}
                  />
                  <TextField
                    fullWidth
                    label="City"
                    {...getFieldProps('city')}
                    error={Boolean(touched.city && errors.city)}
                    helperText={touched.city && errors.city}
                  /> */}
                </Stack>
                
                <TextField
                    fullWidth
                    multiline
                    minRows={3}
                    maxRows={7}
                    label="Description"
                    {...getFieldProps('description')}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
    
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end',gap:1 }}>
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    {/* {!isEdit ? 'Save Project' : 'Save Changes'} */}
                    Save
                  </LoadingButton>
                  <Button variant="outlined" onClick={()=>{
                    setAdd(false)
                    setIsEdit(false)
                    }}>
                    Cancel
                  </Button>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}