import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  Stack,
  TextField,
} from '@material-ui/core'
import Modal from '../blog/ProjectModel'
import * as Yup from 'yup'
import { Form, FormikProvider, useFormik } from 'formik'
import { LoadingButton } from '@material-ui/lab'
import { REST_API } from 'src/constants/Defaultvalues'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { InsertLink } from '@material-ui/icons'
import { useEffect, useRef, useState } from 'react'
import { storage } from 'src/firebase/firebase'
import {
  deleteObject,
  getDownloadURL,
  uploadBytesResumable,
  ref as imageref,
} from '@firebase/storage'
import { UserMoreMenu } from '../user/list'

export default function AddProposal({
  editData,
  setAddProposal,
  addProposal,
  setIsOpen,
  request_id,
  setReloadData,
}) {
  const fileInputRef = useRef()
  const { enqueueSnackbar } = useSnackbar()
  const [progress, setProgress] = useState(false)
  const [budget, setBudget] = useState(null)
  const [title, setTitle] = useState('')
  const [comment, setComment] = useState('')
  const [attachments, setAttachments] = useState({type:'',url:''})

  useEffect(() => {
    if (editData) {
      setComment(editData.description)
      setBudget(editData.budget)
      setTitle(editData.title)
      setAttachments({
        type:editData.attachment_type,
        url:editData.attachment_url
      })
    } else {
      setComment('')
      setBudget(null)
      setAttachments({})
    }
  },[editData])

  const handleSubmit = async (values) => {
    try {
      let user = JSON.parse(localStorage.getItem('user'))
      const userId = user.user_id

      let data = {
        attachment: attachments,
        description: comment,
        budget,
        title,
        userId,
        request_id,
      }

      if(editData){
        data.proposalId = editData.id
      }

      let res = await axios.post(`${REST_API}webservice/rfq/${editData ? 'update-proposal' : 'add-proposal'}`, data)
      if (res.data.status == 1) {
        setReloadData('false')
        setAddProposal(false)
        enqueueSnackbar(res.data.message, { variant: 'success' })
      } else {
        setAddProposal(false)
        enqueueSnackbar(res.data.message, {
          variant: 'error',
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getFileType = (fileType) => {
    if (fileType.startsWith('image/')) {
      return 'image'
    } else if (fileType.startsWith('video/')) {
      return 'video'
    } else {
      return 'other'
    }
  }

  const RemoveAttachFromSubmit = (type, value) => {
    const deleteRef = imageref(storage, value)

    deleteObject(deleteRef)
      .then(() => {
        // File deleted successfully
       setAttachments({})
        console.log('File deleted successfully')
      })
      .catch((error) => {
        console.error('Error deleting file:', error)
      })
  }


  const handleFileChange = async (event) => {
    const files = event.target.files;
  
    if (files.length === 0) {
      return;
    }
  
    const file = files[0];
  
    const filename = new Date().getTime() + file.name;
    const storageRef = imageref(storage, filename);
    const uploadTask = uploadBytesResumable(storageRef, file);
  
    try {
      const uploadSnapshot = await new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const prog = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(true);
          },
          (error) => {
            console.error(error.message);       
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref)
              .then((downloadURL) => {             
                resolve({ downloadURL, fileType: getFileType(file.type) });
              })
              .catch((error) => {
                console.error(error.message);
                reject(error);
              });
          }
        );
      });
      const { downloadURL, fileType } = uploadSnapshot;
      setAttachments((prevAttachments) => ({
        ...prevAttachments,
        url: downloadURL,
        type: fileType,
      }));
  
      setProgress(false);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <Modal
      modalTitle={'Add Proposal'}
      open={addProposal == true}
      handleClose={() => {
        setAddProposal(false)
      }}
    >
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid item xs={12}>
          <Stack spacing={2}>
            <TextField
              fullWidth
              label="Name"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              fullWidth
              type="number"
              label="Budget"
              value={budget}
              onChange={(e) => setBudget(e.target.value)}
            />
            <TextField
              fullWidth
              multiline
              minRows={3}
              maxRows={7}
              label="Comments"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </Stack>
          <LoadingButton
            variant="outlined"
            size="small"
            sx={{ height: 40, mt: 2 }}
            onClick={() => fileInputRef.current.click()}
            loading={progress}
          >
            <InsertLink /> Attach Document
          </LoadingButton>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            {/* {attachments?.other &&
              attachments?.other.map((url, index) => (
                <Grid item xs={6} md={4} lg={3} xl={2}>
                  <Card key={index} sx={{ p: 2, maxWidth: 500, mx: 'auto' }}>
                    <UserMoreMenu
                      onDelete={() => RemoveAttachFromSubmit('other', url)}
                      noEdit
                    />
                    <embed
                      src={url}
                      type="application/pdf"
                      width="300"
                      height="300px"
                    />
                  </Card>
                </Grid>
              ))} */}
              {attachments?.type==='image'?(
                 <Grid item xs={6} md={4} lg={3} xl={2}>
                 <Card  sx={{ p: 2, maxWidth: 500, mx: 'auto' }}>
                   <UserMoreMenu
                     onDelete={() => RemoveAttachFromSubmit('other', attachments.url)}
                     noEdit
                   />               
                    <img
                  srcSet={`${attachments.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${attachments.url}?w=248&fit=crop&auto=format`}
                 loading="lazy"
                   />
                 </Card>
               </Grid>
              ):attachments.type==='video'?(
                <Grid item xs={6} md={4} lg={3} xl={2}>
                <Card  sx={{ p: 2, maxWidth: 500, mx: 'auto' }}>
                  <UserMoreMenu
                    onDelete={() => RemoveAttachFromSubmit('other', attachments.url)}
                    noEdit
                  />
                  <video style={{ width:'100%',height: '100%',objectFit:'cover', }} controls>
                             <source src={attachments.url} type="video/mp4" />
                             Your browser does not support the video tag.
                 </video>
                  </Card>
                  </Grid>  
              ):attachments.type==='other'?(
                <Grid item xs={6} md={4} lg={3} xl={2}>
                <Card  sx={{ p: 2, maxWidth: 500, mx: 'auto' }}>
                  <UserMoreMenu
                    onDelete={() => RemoveAttachFromSubmit('other', attachments.url)}
                    noEdit
                  />
                  <embed
                    src={attachments?.url}
                    type="application/pdf"
                    width="300"
                    height="300px"
                  />
                  </Card>
                  </Grid>              
              ):null}
          </Grid>
          <Box
            sx={{
              mt: 3,
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 1,
            }}
          >
            <LoadingButton
              type="submit"
              variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </LoadingButton>
            <Button
              variant="outlined"
              onClick={() => {
                setAddProposal(false)
              }}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  )
}
