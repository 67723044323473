import PropTypes from 'prop-types';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { ArrowBack, ArrowBackIos, ArrowForward, Cancel, CheckCircle, DoNotDisturb, NotificationsOff, NotificationsOffOutlined, RadioButtonUnchecked } from '@material-ui/icons';


// ----------------------------------------------------------------------

const RootStyle = styled('span')(({ theme, styleProps }) => {
  const { size, status } = styleProps;

  return {
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',

    ...(size === 'small'
      ? { height: theme.spacing(1), width: theme.spacing(1) }
      : { height: theme.spacing(1.25), width: theme.spacing(1.25) }),

    ...(status === 'offline' && {
      backgroundColor: 'transparent'
    }),

    ...(status === 'away' && {
    //   backgroundColor: theme.palette.warning.main,
    backgroundColor: 'transparent'
    }),

    ...(status === 'busy' && {
      backgroundColor: theme.palette.error.main
    }),

    ...(status === 'online' && {
      backgroundColor: theme.palette.success.main
    }),

    ...(status === 'invisible' && {
      backgroundColor: theme.palette.text.disabled,
    }),

    ...(status === 'unread' && {
      backgroundColor: theme.palette.info.main
    }),
  };
});

// ----------------------------------------------------------------------

export default function BadgeStatusSignwiseChat({ size = 'medium', status = 'offline', ...other }) {
  let IconComponent;

  // Choose the appropriate icon based on the status
  switch (status) {
    case 'away':
      IconComponent = <ArrowBack style={{ color: 'yellow', fontSize: 15 }} />;
      break;
    case 'dnd':
      IconComponent = <NotificationsOffOutlined style={{ color: 'red', fontSize: 15 }} />;
      break;
    default:
      IconComponent = null;
  }

  return (
    <RootStyle styleProps={{ status, size }} {...other}>
      {IconComponent}
    </RootStyle>
  );
}

BadgeStatusSignwiseChat.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  status: PropTypes.oneOf(['away', 'busy', 'unread', 'online', 'offline', 'invisible', 'dnd']), // Added dnd status here
};
