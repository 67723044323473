import { Box, Button, Grid, Stack, Typography } from "@material-ui/core";
import { UserCard } from "../user/cards";
import LoadingScreen from "src/components/LoadingScreen";
import { capitalCase } from "change-case";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useEffect, useState } from "react";
import { REST_API_END_POINT } from "src/constants/Defaultvalues";
import axios from "axios";
import { useSnackbar } from "notistack";
import AnimatedButton from "src/components/animate/AnimatedButton";
import FullScreenDialogsJobs from 'src/pages/components-overview/material-ui/dialog/FullScreenDialogsAllJobs';

export default function ProductionControll({ medal,communityJob, isLoading, setRenderCards, renderCards, projectData, setIsEdit, handleFetchEditData,currentTab,projectId, setProjectId,openViewPage, setOpenViewPage,projectName,setProjectName,removePopupData}){
  const [cards, setCards] = useState(projectData);
  const [checkedCount, setCheckedCount] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const { enqueueSnackbar } = useSnackbar();



  const handleCheckboxChange = (id, isChecked,user) => {
    console.log('useruser',user)
    setCheckedCount((prevCount) => (isChecked ? prevCount + 1 : prevCount - 1));
    setSelectedIds((prevIds) =>
      isChecked ? [...prevIds, id] : prevIds.filter((itemId) => itemId !== id)
    );
  };

  const handleClickOpenViewPage=(id,name)=>{
    setProjectId(id)
    setProjectName(name)
    setOpenViewPage(true)
    localStorage.setItem("openProductionPopup", "true");
    localStorage.setItem("projectId", id);
    localStorage.setItem("projectName", name);
    
    // setSearchParams({
    //   open: "true",
    //   // view: "viewPage",
    //   // projectId: id,
    //   // projectName: name,
    // });
  }

  useEffect(()=>{
    setCards(projectData)
  },[projectData])

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const updatedCards = Array.from(cards);
    const [reorderedCard] = updatedCards.splice(result.source.index, 1);
    updatedCards.splice(result.destination.index, 0, reorderedCard);
    setCards(updatedCards);
    saveCardOrder(updatedCards);
  };

  const saveCardOrder = async (updatedCards) => {
    try{
      console.log('updatedCards---',updatedCards)
      const res = await axios.post(`${REST_API_END_POINT}project/save-job-card-order`, { updatedCards })
      if(res.data.status === 1){
        console.log('Order saved')
        // setRenderCards(!renderCards)
      }
    }catch(error){
      console.error('Error saving order', error);
    }
  };

  const handleConvertJobToInstallation = async ()=>{
    const user = JSON.parse(window.localStorage.getItem('user'))

    console.log('selectedIds',selectedIds)
    const response = await axios.post(`${REST_API_END_POINT}installation/convert-job-to-installation`,{selectedIds,userId:user.user_id})
    if(response.data.status==1){
      enqueueSnackbar("Moved to Installation successfully !", { variant: 'success' });
      setRenderCards(true)
      setSelectedIds([])
      setCheckedCount(0)
    }else{
      enqueueSnackbar("Installation Not Moved !", { variant: 'error' });
    }
  }
  return(
    isLoading ? (
      <Grid container alignItems={'center'} justifyContent={'center'}>
        <Stack sx={{my:15}}>
          <LoadingScreen />
        </Stack>
      </Grid>
    ) : (
      <>
        {currentTab === 'Post Production' ?
          <Box variant="outlined" sx={{position:"absolute",top:180 }}>
            <Typography>Selected Job: {checkedCount}</Typography>
            {checkedCount > 0 ?
              // <Button  sx={{backgroundColor:"green",color:"#fff",ml:1,'&:hover':{
              // 	backgroundColor:"green",
              // 	color:"#fff"
              // }}} onClick={handleConvertJobToInstallation}>Move to Installation</Button>
              <AnimatedButton title={'Move to Installation'} onClick={handleConvertJobToInstallation} />
            : "" }
          </Box>
        : "" }
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="cards" direction="horizontal">
            {(provided) => (
              <Grid container spacing={3} sx={{ padding: 2 }} {...provided.droppableProps} ref={provided.innerRef} >
                {
                // projectData?.length > 0 ? 
                  cards?.map((post, index) => (
                    <Draggable key={post.id} draggableId={post.id.toString()} index={index}>
                    {(provided, snapshot) => (
                    <Grid
                    key={index} 
                    item 
                    xs={12}
                    sm={6}
                    md={4}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                      opacity: snapshot.isDragging ? 0.5 : 1,
                    }}
                    >
                      <UserCard setRenderCards={setRenderCards} 
                        setIsEdit={setIsEdit}
                        renderCards={renderCards}
                        handleFetchEditData={handleFetchEditData} 
                        medal={medal} user={post} 
                        currentTab={currentTab} 
                        handleCheckboxChange={handleCheckboxChange}
                        openViewPage={openViewPage}
                        setOpenViewPage={setOpenViewPage}
                        projectId={projectId} setProjectId={setProjectId}
                        projectName={projectName} setProjectName={setProjectName}
                        removePopupData={removePopupData}
                        handleClickOpenViewPage={handleClickOpenViewPage}
                        communityJob={communityJob}
                      />
                    </Grid>
                    )}
                  </Draggable>
                  )) 
                  // : 
                  // <Grid container alignItems={'center'} justifyContent={'center'}>
                  //   <Stack sx={{my:15}}>
                  //     <Typography gutterBottom align="center" variant="subtitle1">
                  //       No {capitalCase(currentTab)} Jobs Found
                  //     </Typography>
                  //   </Stack>
                  // </Grid>
                }
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
        <FullScreenDialogsJobs
          removePopupData={removePopupData}
          initialFromCalender={"false"}
          open={openViewPage}
          setOpen={setOpenViewPage}
          renderCards={renderCards}
          handleClickOpen={handleClickOpenViewPage}
          projectName={projectName}
          projectId={projectId}
          setRenderCards={setRenderCards}
        />
      </> 
    )
  )
}