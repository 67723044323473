import React, { forwardRef, useEffect, useState } from 'react';
// material
import CloseIcon from '@material-ui/icons/Close';
import {
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
} from '@material-ui/core';
//
import ViewOfficialRequest from 'src/pages/dashboard/ViewQuotation';

const Transition = forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);
 function FullScreenDialogEstimate({
  open,
   setOpen,
   projectId:initialProjectId,
   projectName:initialProjectName,
   isEditEstimate:initialIsEditEstimate,
   setRenderCards,
   renderCards,
   setIsEditEstimate:EditEstimate
  }) {

  const [dataChanged, setDataChanged] = useState(false); 
// const[jobCostId,setJobCostId]=useState('');
const [projectId, setProjectId] = useState(
  localStorage.getItem('estimateProjectId') || initialProjectId || ''
);
const [projectName, setProjectName] = useState(
  localStorage.getItem('estimateProjectName') || initialProjectName || ''
);
const [isEditEstimate, setIsEditEstimate] = useState(
  localStorage.getItem('isEditEstimate') === 'true' || initialIsEditEstimate || false
);
useEffect(() => {
  if (open) {
    localStorage.setItem('estimateProjectId', projectId);
    localStorage.setItem('estimateProjectName', projectName);
    localStorage.setItem('isEditEstimate', isEditEstimate);
    localStorage.setItem('estimateOpenProductionPopup', 'true');
  } else {
    // localStorage.removeItem('estimateProjectId');
    // localStorage.removeItem('estimateProjectName');
    // localStorage.removeItem('isEditEstimate');
    localStorage.removeItem('estimateOpenProductionPopup',);
  }
}, [open, projectId, projectName, isEditEstimate]);
useEffect(() => {
  if (open) {
    setProjectId(localStorage.getItem('estimateProjectId') || initialProjectId || '');
    setProjectName(localStorage.getItem('estimateProjectName') || initialProjectName || '');
    setIsEditEstimate(localStorage.getItem('isEditEstimate') === 'true' || initialIsEditEstimate || false);
  }
}, [open]);
  const handleClose = () => {
    setProjectId("");
    setProjectName("");
    setIsEditEstimate("");
    setOpen(false);
    localStorage.removeItem('estimateProjectId');
    localStorage.removeItem('estimateProjectName');
    localStorage.removeItem('isEditEstimate');
    localStorage.removeItem('estimateOpenProductionPopup',);
    // if (dataChanged) {
      setRenderCards(!renderCards);
      setDataChanged(false); // Reset the flag after triggering the re-render
    // }
  };
  const handleDataChange = () => {
    setDataChanged(true); 
    console.log('Entered=handleDataChange')
  };
  return (
    <>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar position="relative" mb={3}>
          <Toolbar>
            <Typography variant="h6" sx={{ flex: 1, ml: 2 }}>
              {projectName}
            </Typography>
              <CloseIcon onClick={handleClose} sx={{fontSize:'2.7rem',cursor:'pointer'}}/>
          </Toolbar>
        </AppBar>
        <MemoizedViewOfficialRequest
          popupOn={true}
          setRenderCards={setRenderCards}
          idOfProject={projectId}
          isEditEstimate={isEditEstimate}
          closeView={handleClose}
          renderCards={renderCards}
          onDataChange={handleDataChange} // Pass data change handler
          setIsEditEstimate={EditEstimate}

        />
      </Dialog>
    </>
  );
}
const MemoizedViewOfficialRequest = React.memo(ViewOfficialRequest);

export default React.memo(FullScreenDialogEstimate);