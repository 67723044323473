import * as Yup from 'yup'
import { useCallback, useState } from 'react'
import { sentenceCase } from 'change-case'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, FormikProvider, useFormik } from 'formik'
import { Icon } from '@iconify/react'
import plusFill from '@iconify/icons-eva/plus-fill'
import Modal from 'src/components/_dashboard/blog/ProjectModel.js'
import { UploadMultiFile } from 'src/components/upload/index.js'
import {
  Container,
  Grid,
  IconButton,
  Stack,
  Box,
  Button,
  TableRow,
  TableBody,
  Checkbox,
  Card,
  TableCell,
  Typography,
  TableContainer,
  Table,
  TablePagination,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Switch,
  CardContent,
  CardMedia,
  CardHeader,
} from '@material-ui/core'

import { LoadingButton } from '@material-ui/lab'
import { useTheme } from '@material-ui/core/styles'
import { experimentalStyled as styled } from '@material-ui/core/styles'
import Page from 'src/components/Page.js'
import Agreements from 'src/pages/dashboard/Agreements'
import RequestedDocument from 'src/pages/dashboard/RequestedDocument'
import Files from 'src/pages/dashboard/Files'
import { Textarea } from '@guidesmiths/react-form-builder'
import Label from 'src/components/Label'

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}))
export default function Documents({ agreements }) {
  const theme = useTheme()
  const [modalData, setModalData] = useState(null)
  const [openModal, setOpenModal] = useState(false)

  const handleView = (id) => {
    let data = agreements?.filter((data) => data.id === id)
    setModalData(...data)
    setOpenModal(true)
  }
  return (
    <Page title="Agreements | Sign-Wise Solution">
      <Container>
        <Box sx={{ marginTop: '40px', marginBottom: '30px' }}>
          <Typography variant="h4" component="h2">
            Agreements
          </Typography>
        </Box>

        <Agreements agreements={agreements} handleView={handleView} />
        <Modal
          open={openModal}
          modalTitle={'View Agreement'}
          handleClose={() => {
            setOpenModal(false)
            setModalData(null)
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={15}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <Stack>
                    <TextField
                      fullWidth
                      disabled
                      value={modalData?.agreement_name}
                      label="Agreement Name"
                    />
                  </Stack>
                  <Stack sx={{ ml: 3 }}>
                    <LabelStyle sx={{ justifyContent: 'flex-start' }}>
                      Content
                    </LabelStyle>
                    <Typography
                      textAlign={'start'}
                      color={'GrayText'}
                      variant="subtitle2"
                      component={'div'}
                      dangerouslySetInnerHTML={{ __html: modalData?.content }}
                    />
                  </Stack>
                  <Stack sx={{ ml: 3 }}>                                    
                      <CardHeader title={'Signature'}/>
                      <CardMedia sx={{objectFit:'contain',display:'flex',
                      justifyContent:'flex-start',
                      width:'40%',height:'40%'}}
                        component="img" 
                        alt={modalData?.agreement_name}  
                        image={modalData?.file_name}
                      />                   
                  </Stack>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Modal>
      </Container>
    </Page>
  )
}
