import PropTypes from 'prop-types';
import { List, Avatar, ListItem, Typography, ListItemText, ListItemAvatar, Badge, Box } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import profilePic from '../../../../assets/defaultAvatar.png'
import BadgeStatus from 'src/components/BadgeStatus';
import { formatDistanceToNowStrict } from 'date-fns';
import moment from 'moment';
import { useEffect, useState } from 'react';

ChatHomeListSignWise.propTypes = {
  teamMembers: PropTypes.array,
  onSelectContact:PropTypes.func
};

// Styled Badge for Online Status
const OnlineBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-dot': {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: '#44b700',
    border: `2px solid ${theme.palette.background.paper}`,
  },
}));
const AvatarWrapperStyle = styled('div')({
  position: 'relative',
  width: 32,  // Adjust size for group avatar
  height: 32,
  '& .MuiAvatar-img': { borderRadius: '50%' },
  '& .MuiAvatar-root': { width: '100%', height: '100%' }
});

export default function ChatHomeListSignWise({openSidebar,setHistoryMessage, teamMembers = [],onSelectContact,setCompose, openCompose, chatOpenTime,setChatOpenTime,setSelectedChatLogId }) {
  console.log('teamMembersdgf', teamMembers)
  const user = JSON.parse(localStorage.getItem('user'));
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <List disablePadding>
      {teamMembers.map((conversation) =>{ 
      console.log('conversation2222222222222', conversation)
const isGroup = conversation.conversation_type === 'group';
const otherParticipants = conversation.participants || []; // Assuming 'participants' contains members for the group
const displayNames = otherParticipants.reduce((names, participant) => [...names, participant.full_name], []).join(', ');
// console.log('displayNames1', displayNames)

      // return(
      //   <ListItem
      //     key={conversation.id}
      //     button
      //     onClick={() => {onSelectContact(conversation);setCompose(false)}} 
      //     sx={{
      //       py: 1.5,
      //       px: 3,
      //       borderRadius: '10px', // ✅ Smooth rounded corners
      //       transition: '0.3s',
      //       '&:hover': { bgcolor: '#0b2329' } // ✅ Correct hover effect (matches first image)
      //     }}
      //   >
      //     <ListItemAvatar>
      //       <OnlineBadge
      //         overlap="circular"
      //         variant={!conversation.active==='N' ? 'dot' : 'standard'}
      //       >
      //         <Avatar alt={conversation.full_name} src={conversation.profile_pic || profilePic} />
      //       </OnlineBadge>
      //     </ListItemAvatar>
      //     {openSidebar && ( 
      //     <ListItemText
      //     primary={conversation.group_name || conversation.full_name}
      //       secondary={conversation.lastMessage || 'Tap to chat...'}
      //       primaryTypographyProps={{ noWrap: true, variant: 'subtitle2', color: '#fff' }}
      //       secondaryTypographyProps={{ color: 'gray' }}
      //     />
      //     )}
      //     <Typography variant="caption" color="gray">
      //       {conversation.lastActive || '2h'}
      //     </Typography>
      //   </ListItem>
      // )
      return (
        <ListItem
          key={conversation.id}
          button
          onClick={() => {
             onSelectContact(conversation); 
             setCompose(false); 
             setHistoryMessage([]);
             let currentUnixTime =moment().unix()
             console.log('currentUnixTime',currentUnixTime)
             setChatOpenTime(currentUnixTime)
             setSelectedChatLogId(null)
            }}
          sx={{
            py: 1.5,
            px: 3,
            borderRadius: '10px',
            transition: '0.3s',
            '&:hover': { bgcolor: '#0b2329' }
          }}
        >
          <ListItemAvatar>
            <Box
              sx={{
                position: 'relative',
                width: 48,
                height: 48,
                '& .avatarWrapper': {
                  position: 'absolute',
                  width: 32,
                  height: 32,
                  '&:nth-child(1)': {
                    left: 0,
                    zIndex: 9,
                    bottom: 2,
                    '& .MuiAvatar-root': { border: 'solid 2px #fff' }
                  },
                  '&:nth-child(2)': { top: 2, right: 0 }
                }
              }}
            >
              {isGroup && otherParticipants.slice(0, 2).map((participant) => (
                <AvatarWrapperStyle className="avatarWrapper" key={participant.id}>
                  <Avatar alt={participant?.full_name} src={participant?.profile_pic || profilePic} />
                  <BadgeStatus
                    status={participant?.status || 'offline'}
                    sx={{ right: 2, bottom: 2, position: 'absolute' }}
                  />
                </AvatarWrapperStyle>
              ))}
              {!isGroup && (
                <Avatar alt={conversation?.full_name} src={conversation?.profile_pic || profilePic} />
              )}
            </Box>
          </ListItemAvatar>

          {openSidebar && (
            <>
              <ListItemText
                primary={isGroup ? conversation.group_name : conversation.full_name}
                primaryTypographyProps={{ noWrap: true, variant: 'subtitle2', color: '#fff' }}
                secondary={
                  <span 
                    dangerouslySetInnerHTML={{ 
                      __html: isGroup 
                        ? (conversation?.last_message 
                            ? `${conversation?.last_message_sender_id === user.user_id ? 'You' : conversation?.last_message_sender}: ${conversation?.last_message.replace(/<\/p><p>/g, ' ').replace(/<p>/g, '').replace(/<\/p>/g, '').trim()}` 
                            : 'Tap to chat...') 
                        : (conversation?.last_message 
                            ? conversation?.last_message.replace(/<\/p><p>/g, ' ').replace(/<p>/g, '').replace(/<\/p>/g, '').trim() 
                            : 'Tap to chat...')
                    }}
                  />
                }                                
                secondaryTypographyProps={{
                  color: 'gray',
                  noWrap: true
                }}
              />
              <Box sx={{ ml: 2, height: 44, display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                <Box sx={{ mb: 1.25, fontSize: 12, lineHeight: '22px', whiteSpace: 'nowrap', color: 'text.disabled' }}>
                  {/* {conversation?.last_message_time ? formatDistanceToNowStrict(new Date(moment.unix(conversation?.last_message_time)), { addSuffix: false, baseDate: currentTime }) : ''} */}
                  {conversation?.last_message_time 
                    ? (() => {
                        const messageTime = new Date(moment.unix(conversation?.last_message_time));
                        const distance = formatDistanceToNowStrict(messageTime, { addSuffix: false, baseDate: currentTime });
                        // Check if the time difference is less than a minute and display "just now"
                        if (distance.includes('second')) {
                          return 'just now';
                        }
                        return distance;
                      })()
                    : ''
                  }
                  {/* {conversation.lastActive || '2h'} */}
                </Box>
              </Box>
            </>
          )}
        </ListItem>
      );
      })}


    </List>
  );
}
