import faker from 'faker';
import { sentenceCase } from 'change-case';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import {
  Box,
  Card,
  Table,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  Typography,
  TableContainer,
  LinearProgress,
  linearProgressClasses,
  Autocomplete,
  TextField,
  CircularProgress,
  TablePagination,
  Pagination,
} from '@material-ui/core';
// utils
import { fCurrency } from '../../../utils/formatNumber';
//
import Label from '../../Label';
import Scrollbar from '../../Scrollbar';
import React, { Fragment, useEffect , useState } from 'react';
import { empty } from 'src/Helpers/Common';
import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';

// ----------------------------------------------------------------------

  const  EcommerceBestSalesman = React.memo(() => {
  const navigate=useNavigate()
  const [page,setPage] = useState(1)
  const theme = useTheme();
  const [loading,setLoading] = useState(false)
  const [allProjects,setAllProjects]= useState([])
  const [totalCount,setTotalCount] = useState(0)
  const userData = JSON.parse(localStorage.getItem('user'))
  let userType = userData?.user_type;
  let userId = userData?.user_id;
  const fetchAllProjects = async()=>{
    try {
       setLoading(true)
       const res = await axios.post(REST_API_END_POINT+'settings/project-upcoming',{
        all:true, page:page-1, userType, userId })
       if(res){
         let budget = res?.data?.data?.results?.reduce((total, currentItem) => total + currentItem.cost, 0)?.toFixed(2);
         let expense = res?.data?.data?.results?.reduce((total, currentItem) => total + currentItem.expense, 0)?.toFixed(2);
         let Utilization = parseFloat((expense/budget)*100).toFixed(2)
         setAllProjects(res?.data?.data.results)
         setTotalCount(res?.data?.data.totalCount)
       }
    } catch (error) {
      console.log(error)
    } finally{
      setLoading(false)
    }
    
  }
  useEffect(()=>{
    fetchAllProjects()
   },[page,userType])
  const handleProjectByname=(value)=>{
    if(!empty(value)){
      setAllProjects([value])
    }else{
      fetchAllProjects()
    }
  }
  let progressValue 
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: `hsl(${120 - (progressValue * 1.2)}, ${60}%, 50%)`, 
    },
  }));
  const StickyTableHead = styled(TableHead)(({ theme }) => ({
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
  }));

  return (
    <Card sx={{ pb: 3 }}>
      <CardHeader 
      title="Upcoming Deadlines" 
      action={
       <Autocomplete
           loading={loading}
           sx={{width:200}}
          id="combo-box-demo"
          options={allProjects}
          onChange={(event, newValue) =>{
            handleProjectByname(newValue)}
          }
          renderInput={(params) => (
            <TextField
            {...params}
             label="Upcoming Jobs"
             InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                  </Fragment>
              ),
            }}
            />
          )}
          getOptionLabel={(option) => option.name}

        />}
      sx={{ mb: 3 }} />
      <Scrollbar>
        <TableContainer
      //    sx={{ minWidth: 520, maxHeight: 240, 
      // overflowY: 'auto',
      // '&::-webkit-scrollbar': {
      //   width: '0.4em',
      // },
      // '&::-webkit-scrollbar-track': {
      //   boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      //   webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      // },
      // '&::-webkit-scrollbar-thumb': {
      //   backgroundColor: 'rgba(0,0,0,.1)',
      //   borderRadius: '4px',
      // }, }}

      sx={{
        minWidth: 520,
        maxHeight: 240,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.divider,
          borderRadius: '4px',
          },
          '&::-webkit-scrollbar-track': {
          backgroundColor: '#00191e',
          },
      }}
      >
          <Table>
            <StickyTableHead>
              <TableRow>
                <TableCell>Job</TableCell>
                <TableCell>Deadline</TableCell>
                <TableCell>Progress</TableCell>              
              </TableRow>
            </StickyTableHead>
            <TableBody >
              {allProjects?.map((row) =>{
                progressValue=row.completion
                return(
                <TableRow key={row.name}>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>                   
                      <Box sx={{ ml: 2 }}>
                        <Typography onClick={()=>{ userType === 3 ? navigate(PATH_DASHBOARD.official.viewRequest.replace(':id', row.request_id)) :navigate(PATH_DASHBOARD.project.viewproject+row.id) }} variant="subtitle2" sx={{ color: 'text.primary', cursor: 'pointer'}} > 
                        {row.name}</Typography>                     
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell> 
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          {moment.unix(row.installation_date).format('MM/DD/YYYY')}
                        </Typography>
                     </TableCell>
                  <TableCell>
                  <BorderLinearProgress variant="determinate" value={row.completion} />  {row.completion}{'%'} Completed             
                  </TableCell>
                </TableRow>
              ) }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
      <Pagination
        count={Math.ceil(totalCount / 10)}
        onChange={(event, value) => setPage(value)}
        />
      </Box>
    </Card>
  );
});

export default EcommerceBestSalesman;
