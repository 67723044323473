import * as Yup from 'yup'
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useSnackbar } from 'notistack'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Form, FormikProvider, useFormik } from 'formik'
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs.js'
import Page from 'src/components/Page.js'
// material
import { LoadingButton } from '@material-ui/lab'
import { Autocomplete, Checkbox, CircularProgress, FormControl, FormLabel, IconButton, InputAdornment, MenuItem } from '@material-ui/core'
import { Icon } from '@iconify/react'
import eyeFill from '@iconify/icons-eva/eye-fill'
import eyeOffFill from '@iconify/icons-eva/eye-off-fill'
import { experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
  FormHelperText,
  Container,
} from '@material-ui/core'
// utils
import { fData } from '../../../utils/formatNumber'
import fakeRequest from '../../../utils/fakeRequest'
// routes
import { PATH_DASHBOARD } from '../../../routes/paths'
import { Link as RouterLink } from 'react-router-dom'
//
import Label from '../../Label'
import { UploadAvatar } from '../../upload'
import axios from 'axios'
import { COMPANY_NAME, REST_API, REST_API_END_POINT } from 'src/constants/Defaultvalues'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from 'src/firebase/firebase'
import is from 'date-fns/esm/locale/is/index.js'
import TagChip from '../contact_tag/TagChip'

// ----------------------------------------------------------------------

UserNewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object,
}

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  textAlign:'center',
  marginBottom: theme.spacing(1)
}));
export default function UserNewForm({popupOn,team, setRenderCards, clientType,  closeView, renderCards, editClientId, setViewAccount}) {
  //------------------------------------------------------------------------------------------
  //get user logged in data from local storage
  const user = localStorage.getItem('user')
  const userData = JSON.parse(user)

  //------------------------------------------------------------------------------------------

  /*******************************************************************************************/
  const location = useLocation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const clientId = useParams()
  const searchParams = new URLSearchParams(location.search)
  const id = popupOn? editClientId : clientId.id
  // console.log(clientId.id, '%%%%%%%%%%%%%%%%')
  const editId = popupOn ? editClientId : parseInt(id)
  const isAdmin = popupOn ? team : clientId.isAdmin === 'true'
  const [progress,setProgress] = useState(0)
  const [editDetails, setEditDetails] = useState([])
  const [stateData, setStateData] = useState([])
  const [roleData, setRoleData] = useState([])
  const [departmentData, setDepartmentData] = useState([])
  const [servicesData, setServicesData] = useState([])
  const [partnerType,setPartnerType] =useState(null)
  const [ranks,setRanks] =useState(null)
  const [contactTags,setContactTags] = useState([])
  const [workFlow,setWorkFlow] =useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [customerTypeData,setCustomerTypeData] = useState([])

  const [newPassError, setNewPassError] = useState('');
  const [confirmPassError, setConfirmPassError] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [quickbookSandboxExistingCustomers,setQuickbookSandboxExistingCustomers] = useState([])
  const [uniqueName,setUniqueName] = useState(true)
  const [loading,setLoading] = useState(false)
  let defaultDepartment = null
  let defaultState = null
  let defaultRole = null
  let defaultParnterType =null
  let defaultRank =null
  let defaultTags = []
  useEffect(() => {
    const fetchData = async () => {
      if (isAdmin) {
        const response = await axios.post(`${REST_API_END_POINT}teams/get-team-by-id?id=${editId}`,)
        if (response.data.status == 1) {
          setEditDetails(response.data.teamData)
          setStateData(response.data.states)
          setRoleData(response.data.userRoles)
          setDepartmentData(response.data.departments)
          setServicesData(response.data.services)
          setContactTags(response.data.contact_tags.data)
        } else {
          enqueueSnackbar('No matching data found', { variant: 'error' })
          navigate(PATH_DASHBOARD.callCenter.teams)
        }
      }else{
      try{
        setLoading(true)
          const explodedPermissions =userData?.exploded_permissions
        let hasPermission = explodedPermissions?.some(permission => ['Inviting clients','Edit clients','Delete clients'].includes(permission)) ? true : false
        if(!editId || editId==""){
          console.log('editId not available')
          return;
        }
        const data ={id:editId,userId:userData.user_id,userType:userData.user_type,hasPermission:hasPermission}
        const res = await axios.post(`${REST_API_END_POINT}clients/fetch-client-by-id`,data)
        setEditDetails(res.data.client)
        setStateData(res.data.states)
        setRanks(res.data.ranks)
        setWorkFlow(res.data.workflows)
        setPartnerType(res.data.partnerTypes)
        setContactTags(res.data.contactTags.data)
        setCustomerTypeData(res.data.customerTypes)
      }catch{
        setEditDetails([])
        setStateData([])
        setRanks([])
        setWorkFlow([])
        setPartnerType(null)
        setContactTags([])
        setCustomerTypeData([])
      }finally{
        setLoading(false)
      }
      }
    }
    fetchData();
    fetchQuickbookCustomerVendorData()
  }, [])

  const fetchQuickbookCustomerVendorData = async()=>{
    if(popupOn ? clientType === 'client' : clientId.type === 'client'){
   await axios.get(`${REST_API}webservice/clients/get-customers-quickbook-sandbox`)
      .then(res =>{
       console.log("quickbook sandbox existing customers",res.data.result.QueryResponse.Customer)
       setQuickbookSandboxExistingCustomers(res.data.result.QueryResponse.Customer)
      }).catch(err=>console.log(err))
     }else if(popupOn ? clientType === 'vendor' : clientId.type === 'vendor'){
     await axios.get(`${REST_API}webservice/vendor/get-vendors-quickbook-sandbox`)
       .then(res =>{
        console.log("quickbook sandbox existing vendors",res.data.result.QueryResponse.Vendor)
        setQuickbookSandboxExistingCustomers(res.data.result.QueryResponse.Vendor)
       }).catch(err=>console.log(err))
     }
  }


let defaultServicesArray=null
  // console.log(contactTags,'--------contactTags------')
  // console.log(editDetails,'--------editDetails------')
    if (isAdmin) {
      if (editDetails.department_id) {
        const d_department = departmentData.find((d) => d.id === editDetails.department_id,)
        if (d_department) {
          defaultDepartment = d_department
        }
      } else {
        defaultDepartment = null
      }
      if (editDetails.services) {
        // const d_services = servicesData.find((d) => d.id === editDetails.services,)
        const serviceIds = (editDetails.services.includes(',')) ? (editDetails.services.split(',').map((id) => parseInt(id))) : [parseInt(editDetails.services)];
        defaultServicesArray = serviceIds.map((serviceId) => servicesData?.find((d) => d.id === serviceId) || null);
      }

      if (editDetails?.state_id) {
        const d_state = stateData?.find((d) => d?.id === editDetails?.state_id)
        if (d_state) {
          defaultState = d_state
        }
      } else {
        defaultState = null
      }
      if (editDetails?.user_type) {
        const d_userRole = roleData.find((d) => d.id === editDetails.user_type)
        if (d_userRole) {
          defaultRole = d_userRole
        }
      } else {
        defaultRole = null
      }
      if(editDetails?.contact_tags){
        let tag = editDetails?.contact_tags.split(',').map(Number);
        let def_tag = contactTags?.filter(skill => tag.includes(skill.id));
        if(def_tag){
          defaultTags=def_tag
        }
      }
      else{
        defaultTags=[]
      }
    }else{
      if (editDetails?.state_id) {
        const d_state = stateData?.find((d) => d?.id === editDetails?.state_id)
        if (d_state) {
          defaultState = d_state
        }
      } else {
        defaultState = null
      }
      if(editDetails.partner_type){
        const def_part_Type = partnerType?.find((d)=>
           d.id === editDetails?.partner_type
        )
       
        if(def_part_Type){
          defaultParnterType=def_part_Type
        }
      }
      else{
        defaultParnterType = null
      }
      if(editDetails.rank_id){
        const def_rank = ranks?.find((def)=>def.id == editDetails?.rank_id)
        
        if(def_rank){
          defaultRank=def_rank
        }
      }
      else{
        defaultRank=null
      }
      if(editDetails?.contact_tags){
        let tag = editDetails?.contact_tags?.split(',')?.map(Number);
        let def_tag = contactTags?.filter(skill => tag.includes(skill.id));
        if(def_tag){
          defaultTags=def_tag
        }
      }
      else{
        defaultTags=[]
      }
    }


  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email:!isAdmin && Yup.string().required('Email is required').email(),
    // profile_pic:  Yup.mixed().required('Upload a profile photo'),
    // avatarUrl:  Yup.mixed().required('Upload Cover Image'),
    phoneNumber:!isAdmin && Yup.number().typeError('Please enter a valid phone number').required('Phone number is required'),
    forward_no:Yup.number().typeError('Please enter a valid phone number'),
    hourlyRate:Yup.number().typeError('Please enter a valid amount'),
    state: !isAdmin && Yup.object().nullable().required('State is required'),
  })
  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: isAdmin && editDetails.first_name || editDetails.name || '',
      lastName: isAdmin && editDetails.last_name || '',
      email: editDetails.email || '',
      phoneNumber: editDetails.phone || '',
      forward_no: editDetails.forward_no || '',
      hourlyRate: editDetails.hourly_rate || '',
      role: defaultRole || null,
      department: defaultDepartment || null,
      service: defaultServicesArray || [],
      state: defaultState || null,
      zipcode:!isAdmin && editDetails.zip_code ||'',
      // avatarUrl: isAdmin && `${REST_API}build/images/team_members/${editDetails.profile_pic}` || null,
      avatarUrl: isAdmin && ((editDetails?.company_logo !== "default_logo.png") ? editDetails?.company_logo : '') || editDetails?.thumbnail || '',  // '/static/mock-images/avatars/avatar_default1.jpg'
      address:  !isAdmin && editDetails.address|| '',
      city: editDetails.city|| '',
      rank:!isAdmin && defaultRank||null,
      contact_tags: defaultTags||null,
      partnerType:!isAdmin && defaultParnterType||'',
      fax: !isAdmin && editDetails.fax_number||'',
      profile_pic: editDetails?.profile_pic !== 'avatar.jpg' && editDetails?.profile_pic || '', //avatar.jpg
      quickbookCustomerName:editDetails?.quickbookCustomerName || '',
      existingQuickbookCustomerName:editDetails?.quickbookCustomerName || '',
      isSyncWithQuickbooks: editDetails?.isSyncWithQuickbooks===1? true:false || false,
      customerType:editDetails? customerTypeData?.find(val => editDetails?.customerTypeId ==val.id) : null

    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors, setFieldError }) => {
      
      let team = {}
      try {
if((popupOn ? clientType === 'client' : clientId.type === 'client') && !values.customerType){
  return enqueueSnackbar("Client type is required", { variant: 'error' })
}
        if(values.isSyncWithQuickbooks===true){
          if(!values.quickbookCustomerName){
            {(popupOn && clientType === 'client') || (!popupOn && clientId.type === 'client') ?
            enqueueSnackbar("customer display name is required", { variant: 'error' })
            :
            enqueueSnackbar("Vendor display name is required", { variant: 'error' })
            }
            return
          }
        
          if(!uniqueName) {
            (popupOn && clientType === 'client') || (!popupOn && clientId.type === 'client')?
            enqueueSnackbar("Existing customer display name in Quickbooks", { variant: 'error' })
            :
            enqueueSnackbar("Existing Vendor display name in Quickbooks", { variant: 'error' })
            return
          }
        }


        if (isAdmin) {

        
            if(!values.email && !values.phoneNumber) {
              return  enqueueSnackbar("Email or Phone number is required", { variant: 'error' });
            }
            else if(values.phoneNumber && !values.forward_no){
              return  enqueueSnackbar("Forward number is required", { variant: 'error' });
            }
    
          try {
            /*****************UPDATE IN STAFF MEMBER TABLE************************************************************/
            if(newPassword !== confirmPassword){
              setConfirmPassError('Passwords do not match');
            }else{
              if(values.forward_no){
                if(values.hourlyRate){
                  team['first_name'] = values.name
                  team['last_name'] = values.lastName
                  team['email'] = values.email
                  team['forward_no'] = values.forward_no
                  team['hourly_rate'] = values.hourlyRate
                  if (values.phoneNumber) {
                    team['phone'] = values.phoneNumber
                  }
                  if (values.city) {
                    team['city'] = values.city
                  }
                  if (values.state) {
                    team['state_id'] = values.state.id
                  }
                  if (values.service){
                    const idsString = values.service.map(obj => obj.id).join(',');
                    team['services'] = idsString
                  }
                  if (values?.contact_tags){
                    const ids = values.contact_tags?.map(obj => obj.id).join(',');
                    team['contact_tags'] = ids
                  }
                  if(!editId || editId==""){
                    console.log('editId not available')
                    return;
                  }
                  const updatedTeam = await axios.post(`${REST_API_END_POINT}teams/update-team`,{ editId, team },
                    {
                      headers: { 'Content-Type': 'application/json' },
                    },
                  )

                  const statusData = updatedTeam.data.status
                  
                  /****************UPDATE IN USER TABLE***********************************************************************/
                  let role, department;
                  if (values.role) {
                    role = values.role.id
                  }
                  if (values.department) {
                    department = values.department.id
                  }

                  const formData = new FormData()
                  formData.append('profile_pic', values.profile_pic)
                  formData.append('company_logo', values.avatarUrl)
                  formData.append('first_name', values.name)
                  formData.append('last_name', values.lastName)
                  formData.append('email', values.email)
                  formData.append('password', newPassword)
                  formData.append('user_type', role)
                  formData.append('department_id', department)
                  formData.append('statusData', statusData)
                  formData.append('loggedInUserId', userData.user_id)
                  formData.append('loggedInUserType', userData.user_type)
                  //Displaying formdata in console
                  //----------------------------------------
                  
                  // for (var pair of formData.entries()) {
                  //   console.log(pair[0] + ' - ' + pair[1])
                  // }
                  const updatedUser = await axios.post(
                    `${REST_API_END_POINT}teams/update-user?userId=${editDetails.user_id}`,
                    formData,
                    {
                      headers: { 'Content-Type': 'multipart/form-data' },
                    },
                  )
                  const updatedUserStatus = updatedUser.data.status
                  if (statusData || updatedUserStatus === 1) {
                  
                    enqueueSnackbar(updatedUser.data.message, { variant: 'success' })
                  } else {
                    enqueueSnackbar(updatedUser.data.message, { variant: 'error' })
                  }
                  resetForm()
                  setSubmitting(false)
                  navigate(PATH_DASHBOARD.callCenter.teams)
                }else{
                  setFieldError('hourlyRate','Hourly rate is required')
                }
              }else{
                setFieldError('forward_no','Forward number is required')
              }
            }
          } catch (error) {
            console.log('Error in updation : ', error)
            enqueueSnackbar('Error in updation', { variant: 'error' })
          }
        } else {
          if(newPassword !== confirmPassword){
            setConfirmPassError('Passwords do not match');
          }else{
            if(!editId || editId==""){
              console.log('editId not available')
              return;
            }
            const data={clientId:editId,values,newPassword,userId:userData.user_id}
            const res = await axios.post(`${REST_API_END_POINT}clients/update-client`,data)
          
            if(res.data.status ==1){
              resetForm()
              setSubmitting(false)
              enqueueSnackbar(res.data.message, {variant: 'success'})
              closeView()
              setRenderCards(!renderCards)
              // if(clientId.type === 'client'){
              //   navigate(PATH_DASHBOARD.client.cards)
              // }else {
              //   navigate(PATH_DASHBOARD.client.vendors)
              // }
            }else if(res.data.status ==0){
              resetForm()
              setSubmitting(false)
              enqueueSnackbar(res.data.message, {variant: 'success'})
              closeView()
              // if(clientId.type === 'client'){
              //   navigate(PATH_DASHBOARD.client.cards)
              // }else {
              //   navigate(PATH_DASHBOARD.client.vendors)
              // }
            }else {
              resetForm()
              setSubmitting(false)
              enqueueSnackbar('Failed to update profile', {variant: 'error'})
            }
          }
        }
      } catch (error) {
        console.error(error)
        setSubmitting(false)
        setErrors(error)
      }
    },
  })

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik
  const [setAdd] = useState(false)
  //function to update the profile pic

const handleDropProfilePic = useCallback(
  (acceptedFiles) => {
  //   if(isAdmin){
  //   let file = acceptedFiles[0]
  //   if (file) {
  //     setSelectedFile(file)
  //     setFieldValue('profile_pic', {
  //       ...file,
  //       preview: URL.createObjectURL(file),
  //     })
  //   }
  // }else{
    const file = acceptedFiles[0]
    if(file){
      const Filename = new Date().getTime() + file.name;

      const StorageRef = ref(storage, Filename);
      const uploadTask = uploadBytesResumable(StorageRef, file);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setProgress(progress); 
        },
        (error) => {
          console.error(error.message);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setFieldValue('profile_pic', downloadURL);
            console.log(downloadURL, '&&&&&&&&&&&&&&&&&&&');
          });
        }
      );
    }
  },
  [setFieldValue],
)
///below functn to update the logo 
  const handleDrop = useCallback(
    (acceptedFiles) => {
    //   if(isAdmin){
    //   let file = acceptedFiles[0]
    //   if (file) {
    //     setSelectedFile(file)
    //     setFieldValue('avatarUrl', {
    //       ...file,
    //       preview: URL.createObjectURL(file),
    //     })
    //   }
    // }else{
      const file = acceptedFiles[0]
      if(file){
        const Filename = new Date().getTime() + file.name;
    
        const StorageRef = ref(storage, Filename);
        const uploadTask = uploadBytesResumable(StorageRef, file);
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setProgress(progress); 
          },
          (error) => {
            console.error(error.message);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setFieldValue('avatarUrl', downloadURL);
              console.log(downloadURL, '&&&&&&&&&&&&&&&&&&&');
            });
          }
        );
      }
    },
    [setFieldValue],
  )


  useEffect(() => {
    if(editDetails?.quickbookCustomerName ===formik.values.quickbookCustomerName){
      console.log(editDetails?.quickbookCustomerName,formik.values.quickbookCustomerName,"vallllll");

     setUniqueName(true)
     
    }else{

    
    const customerExists = quickbookSandboxExistingCustomers?.some(customer => 
      customer.DisplayName === formik.values.quickbookCustomerName
    );
    console.log('quickbookCustomerName value:', formik.values.quickbookCustomerName);
    setUniqueName(true)
    console.log("customerExists",customerExists);
    
    if (customerExists) {
      console.log('Customer name already exists in QuickBooks');
      setUniqueName(false)
    }
  }
  }, [formik.values.quickbookCustomerName, quickbookSandboxExistingCustomers,editDetails]);

  return (
    <Page
      title={
        isAdmin
          ? `Edit Team-Member | ${COMPANY_NAME}`
          : `Edit ${(popupOn ? clientType === 'client' : clientId.type === 'client') ? 'Client' : 'Vendor'} | ${COMPANY_NAME}`
      }
    >
      <Container>
        <HeaderBreadcrumbs sx={{mt:3}}
          heading={isAdmin ? 'Edit Team Member' : ((popupOn ? clientType === 'client' : clientId.type === 'client' )? 'Edit Client' : 'Edit Vendor')}
        />
        {loading ?
                    <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: '100vh' }}
                  >
                    <CircularProgress color="primary" variant="indeterminate" />
                  </Stack>
                 :
          <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Card sx={{ py: 10, px: 3 }}>
                  {/* {isEdit && (
                    <Label
                      color={values.status !== 'active' ? 'error' : 'success'}
                      sx={{
                        textTransform: 'uppercase',
                        position: 'absolute',
                        top: 24,
                        right: 24,
                      }}
                    >
                      {values.status}
                    </Label>
                  )} */}
                  <Box sx={{ mb: 5 }}>
                  <LabelStyle>Cover Image</LabelStyle>
                    <UploadAvatar
                      accept="image/*"
                      file={values.avatarUrl}
                      maxSize={3145728}
                      onDrop={handleDrop}
                      error={Boolean(touched.avatarUrl && errors.avatarUrl)}
                      // caption={
                      //   <Typography
                      //     variant="caption"
                      //     sx={{
                      //       mt: 2,
                      //       mx: 'auto',
                      //       display: 'block',
                      //       textAlign: 'center',
                      //       color: 'text.secondary',
                      //     }}
                      //   >
                      //     Allowed *.jpeg, *.jpg, *.png, *.gif
                      //     <br /> max size of {fData(3145728)}
                      //   </Typography>
                      // }
                    />
                    <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                      {touched.avatarUrl && errors.avatarUrl}
                    </FormHelperText>
                  </Box>
                  <Box sx={{ mb: 5 }}>
                  <LabelStyle>Profile Photo</LabelStyle>
                    <UploadAvatar
                      accept="image/*"
                      file={values.profile_pic}
                      maxSize={3145728}
                      onDrop={handleDropProfilePic}
                      error={Boolean(touched.profile_pic && errors.profile_pic)}
                      caption={
                        <Typography
                          variant="caption"
                          sx={{
                            mt: 2,
                            mx: 'auto',
                            display: 'block',
                            textAlign: 'center',
                            color: 'text.secondary',
                          }}
                        >
                          Allowed *.jpeg, *.jpg, *.png, *.gif
                          <br /> max size of {fData(3145728)}
                        </Typography>
                      }
                    />
                    <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                      {touched.profile_pic && errors.profile_pic}
                    </FormHelperText>
                  </Box>
                  {/* {isEdit && (
                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <Switch
                      onChange={(event) => setFieldValue('status', event.target.checked ? 'banned' : 'active')}
                      checked={values.status !== 'active'}
                    />
                  }
                  label={
                    <>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Banned
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Apply disable account
                      </Typography>
                    </>
                  }
                  sx={{ mx: 0, mb: 3, width: 1, justifyContent: 'space-between' }}
                />
              )} */}
                </Card>
              </Grid>

              <Grid item xs={12} md={8}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 3, sm: 2 }}
                    >
                      <TextField
                        fullWidth
                        label={isAdmin ? 'First Name' : 'Name/Company'}
                        {...getFieldProps('name')}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                      <TextField
                        fullWidth
                        label="Email" required
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Stack>
                    {isAdmin ? (
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 3, sm: 2 }}
                      >
                        <TextField
                          fullWidth
                          label="Last Name"
                          {...getFieldProps('lastName')}
                          error={Boolean(touched.lastName && errors.lastName)}
                          helperText={touched.lastName && errors.lastName}
                        />
                        <TextField
                          fullWidth
                          label="Phone Number"
                          {...getFieldProps('phoneNumber')}
                          type='number'
                          onKeyPress={(event) => {
                            if (event.key === '-') {
                              event.preventDefault();
                            }
                            if (event.key === '.') {
                              event.preventDefault();
                            }
                          }}
                          error={Boolean(
                            touched.phoneNumber && errors.phoneNumber,
                          )}
                          helperText={touched.phoneNumber && errors.phoneNumber}
                        />
                      </Stack>
                    ) : (
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 3, sm: 2 }}
                      >
                        <TextField
                          fullWidth
                          required
                          label="Phone Number"
                          {...getFieldProps('phoneNumber')}
                          type='number'
                          onKeyPress={(event) => {
                            if (event.key === '-') {
                              event.preventDefault();
                            }
                            if (event.key === '.') {
                              event.preventDefault();
                            }
                          }}
                          error={Boolean(
                            touched.phoneNumber && errors.phoneNumber,
                          )}
                          helperText={touched.phoneNumber && errors.phoneNumber}
                        />
                        <TextField
                          fullWidth
                          label="Fax Number"
                          {...getFieldProps('fax')}
                          type='number'
                          onKeyPress={(event) => {
                            if (event.key === '-') {
                              event.preventDefault();
                            }
                            if (event.key === '.') {
                              event.preventDefault();
                            }
                          }}
                          error={Boolean(touched.fax && errors.fax)}
                          helperText={touched.fax && errors.fax}
                        />
                          <TextField
                          fullWidth
                          type='number'
                          label="Zip code"
                          {...getFieldProps('zipcode')}
                          error={Boolean(touched.zipcode && errors.zipcode)}
                          helperText={touched.zipcode && errors.zipcode}
                        />
                      </Stack>
                    )}
                    {isAdmin ? (
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 3, sm: 2 }}
                      >
                        <TextField
                          fullWidth
                          label="Call Forward Number"
                          {...getFieldProps('forward_no')}
                          type='number'
                          onKeyPress={(event) => {
                            if (event.key === '-') {
                              event.preventDefault();
                            }
                            if (event.key === '.') {
                              event.preventDefault();
                            }
                          }}
                          error={Boolean(touched.forward_no && errors.forward_no)}
                          helperText={touched.forward_no && errors.forward_no}
                        />
                        <TextField
                          fullWidth
                          label="Hourly rate"
                          {...getFieldProps('hourlyRate')}
                          type='number'
                          onKeyPress={(event) => {
                            if (event.key === '-') {
                              event.preventDefault();
                            }
                          }}
                          error={Boolean(touched.hourlyRate && errors.hourlyRate)}
                          helperText={touched.hourlyRate && errors.hourlyRate}
                        />
                      </Stack>
                    ) : null }
                    {isAdmin ? (
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 3, sm: 2 }}
                      >
                        <TextField
                          fullWidth
                          label="City"
                          {...getFieldProps('city')}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                        
                        <Autocomplete
                          select
                          fullWidth
                          id="state"
                          options={stateData}
                          getOptionLabel={(option) => option.name}
                          defaultValue={defaultState}
                          value={values.state}
                          onChange={(event, newValue) =>
                            setFieldValue('state', newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="State"
                              placeholder="Select State"
                              fullWidth
                              SelectProps={{ native: true }}
                            />
                          )}
                        >
                          <option value="" />
                          {stateData.map((option) => (
                            <option key={option.id} value={option.name}>
                              {option.name}
                            </option>
                          ))}
                        </Autocomplete>
                      </Stack>
                    ) : (
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 3, sm: 2 }}
                      >
                        <TextField
                          fullWidth
                          label="Address"
                          {...getFieldProps('address')}
                          error={Boolean(touched.address && errors.address)}
                          helperText={touched.address && errors.address}
                        />
                        <TextField
                          fullWidth
                          label="City"
                          {...getFieldProps('city')}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                        <Autocomplete
                          select
                          fullWidth
                          id="state"
                          options={stateData}
                          getOptionLabel={(option) => option.name}
                          defaultValue={defaultState}
                          value={values.state}
                          onChange={(event, newValue) =>
                            setFieldValue('state', newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="State" required
                              error={Boolean(touched.state && errors.state)}
                              helperText={touched.state && errors.state}
                              placeholder="Select State"
                              fullWidth
                              SelectProps={{ native: true }}
                            />
                          )}
                        >
                          <option value="" />
                          {stateData.map((option) => (
                            <option key={option.id} value={option.name}>
                              {option.name}
                            </option>
                          ))}
                        </Autocomplete>
                      </Stack>
                    )}

                    {isAdmin ? (
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 3, sm: 2 }}
                      >
                       <Autocomplete
                          multiple
                          fullWidth
                          id="service"
                          options={servicesData?servicesData:[]}
                          getOptionLabel={(option) => option?.name}
                          defaultValue={values?.service || []}  // Set the default value here
                          value={values?.service}
                          onChange={(event, newValue) => setFieldValue('service', newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Skills and Services"
                              placeholder="Select Skills and Services"
                              fullWidth
                              SelectProps={{ native: true }}
                            />
                          )}
                        />
                      </Stack>
                    ): null}

                    {isAdmin ? (
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 3, sm: 2 }}
                      >
                        
                        <Autocomplete
                          select
                          fullWidth
                          id="role"
                          options={roleData}
                          getOptionLabel={(option) => option.name}
                          defaultValue={defaultRole}
                          value={values.role}
                          onChange={(event, newValue) =>
                            setFieldValue('role', newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="User Role"
                              placeholder="Select User Role"
                              fullWidth
                              SelectProps={{ native: true }}
                            />
                          )}
                        >
                          <option value="" />
                          {roleData.map((option) => (
                            <option key={option.id} value={option.name}>
                              {option.name}
                            </option>
                          ))}
                        </Autocomplete>

                        <Autocomplete
                          multiple
                          fullWidth
                          id="combo-box-dem"
                          options={contactTags||[]}
                          value={values.contact_tags}
                          onChange={(event, newValue) => setFieldValue('contact_tags', newValue)}
                          renderOption={(props, tag) => {
                            return(
                              <MenuItem {...props}>
                              <TagChip
                                color={tag.color}
                                variant={tag.variant}
                                label={tag.name}
                                size={'small'}
                              />
                            </MenuItem>
                            )
                          }}
                          renderTags={(tags, getTagProps) =>
                            tags.map((tag, i) => (
                              <TagChip
                                key={i}
                                color={tag.color}
                                variant={tag.variant}
                                label={tag.name}
                                size={'small'}
                                {...getTagProps({ index: i })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField {...params} label="Contact Tags" />
                          )}
                          getOptionLabel={(option) => option.name}

                          />
                        <Autocomplete
                          select
                          fullWidth
                          id="department"
                          options={departmentData}
                          getOptionLabel={(option) => option.name}
                          defaultValue={defaultDepartment}
                          value={values.department}
                          onChange={(event, newValue) =>
                            setFieldValue('department', newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Department"
                              placeholder="Select Department"
                              fullWidth
                              SelectProps={{ native: true }}
                            />
                          )}
                        >
                          <option value="" />
                          {departmentData.map((option) => (
                            <option key={option.id} value={option.name}>
                              {option.name}
                            </option>
                          ))}
                        </Autocomplete>
                      </Stack>
                    ) : (
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 3, sm: 2 }}
                      >
                      
                       {partnerType&& <Autocomplete
                          select 
                          fullWidth
                          id="partner-type-as-demo" 
                          options={partnerType} 
                          getOptionLabel={(option) => option.name}
                          defaultValue={defaultParnterType}
                          value={values.partnerType}
                          onChange={(event, newValue) =>
                            setFieldValue('partnerType', newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Partner Type"
                              placeholder="Partner Type"
                              fullWidth 
                              SelectProps={{ native: true }}
                            >
                              <option value="" />
                              {partnerType?.map((option) => (
                                <option key={option.id} value={option.name}>
                                  {option.name}
                                </option>
                              ))}
                            </TextField>
                          )}
                        /> }
                         {contactTags &&  <Autocomplete
                          multiple
                          fullWidth
                          id="combo-box-demo"
                          options={contactTags}
                          value={values.contact_tags}
                          onChange={(event, newValue) => setFieldValue('contact_tags', newValue)}
                          renderOption={(props, tag) => {
                            return(
                              <MenuItem {...props}>
                              <TagChip
                                color={tag.color}
                                variant={tag.variant}
                                label={tag.name}
                                size={'small'}
                              />
                            </MenuItem>
                            )
                          }}
                          renderTags={(tags, getTagProps) =>
                            tags.map((tag, i) => (
                              <TagChip
                                key={i}
                                color={tag.color}
                                variant={tag.variant}
                                label={tag.name}
                                size={'small'}
                                {...getTagProps({ index: i })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField {...params} label="Contact Tags" />
                          )}
                          getOptionLabel={(option) => option.name}

                          />}
                       {ranks && <Autocomplete
                          select 
                          fullWidth
                          id="ranks" 
                          options={ranks} 
                          getOptionLabel={(option) => option?.name}
                          defaultValue={defaultRank}
                          value={values?.rank}
                          onChange={(event, newValue) =>
                            setFieldValue('rank', newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Rank"
                              placeholder="Rank"
                              fullWidth 
                              SelectProps={{ native: true }} 
                            >
                              <option value="" />
                              {ranks?.map((option) => (
                                <option key={option.id} value={option.name}>
                                  {option.name}
                                </option>
                              ))}
                            </TextField>
                          )}
                        />}
                      </Stack>
                    )}

                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 3, sm: 2 }}
                      >
                        <TextField
                                label="New Password"
                                value={newPassword}
                                type={showNewPassword ? 'text' : 'password'}
                                onChange={(e) => {
                                  setNewPassword(e.target.value)
                                  setNewPassError('')
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        edge="end"
                                        onClick={() =>
                                          setShowNewPassword((prev) => !prev)
                                        }
                                      >
                                        <Icon
                                          icon={
                                            showNewPassword
                                              ? eyeFill
                                              : eyeOffFill
                                          }
                                        />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                error={Boolean(newPassError)}
                                helperText={newPassError}
                                sx={{ width: '100%' }}
                                autoComplete="new-password"
                              />
                              <TextField
                                label="Confirm New Password"
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={confirmPassword}
                                onChange={(e) => {
                                  setConfirmPassword(e.target.value)
                                  setConfirmPassError('')
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        edge="end"
                                        onClick={() =>
                                          setShowConfirmPassword(
                                            (prev) => !prev,
                                          )
                                        }
                                      >
                                        <Icon
                                          icon={
                                            showConfirmPassword
                                              ? eyeFill
                                              : eyeOffFill
                                          }
                                        />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                error={Boolean(confirmPassError)}
                                helperText={confirmPassError}
                                sx={{ width: '100%' }}
                                autoComplete="new-password"
                              />
                      </Stack>
                      {isAdmin ? null :
                        <Stack
                          direction={{ xs: 'column', sm:'row' }}
                          spacing={{ xs: 3, sm: 2 }}
                        >
                          <TextField
                          fullWidth
                          label={(popupOn ? clientType ==='client' :clientId.type==="client")? "Customer display name":"Vendor display name" }
                          placeholder='Name must be unique for every users'
                          {...getFieldProps('quickbookCustomerName')} // Use a unique field name for each item
                          error={Boolean(touched.quickbookCustomerName && errors.quickbookCustomerName)}
                          helperText={touched.quickbookCustomerName && errors.quickbookCustomerName} />

{
                        !uniqueName?
                        <Typography sx={{color:"red",ml:1}}> {(popupOn ? clientType ==='client' :clientId.type === 'client') ?"Customer" :"Vendor"} name already exists </Typography>
                        : ""
                       }

                       {
                        (popupOn ? clientType ==='client' :clientId.type === 'client')?
                        <Autocomplete
                        fullWidth
                    sx={{ width: 400 }}
                    id="partner-type"
                    options={customerTypeData}
                    value={values?.customerType || null}
                    onChange={(event, newValue) => {
                      if(newValue){
                        setFieldValue('customerType', newValue)
                      }else{
                        setFieldValue('customerType', null)

                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Client Type" />
                    )}
                    // getOptionLabel={(option) => option?.name}
                    getOptionLabel={(option) => 
                      option?.name ? `${option?.name} ( ${option?.billing_type} % )` : ''
                    }

                  />
                        :
                         ""
                       }
                        </Stack>
                      }
                    <Box
                      sx={{
                        mt: 3,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                      >
                        { 'Save Changes'}
                      </LoadingButton>
                      {isAdmin ? (
                        <Button
                          variant="outlined"
                          type="submit"
                          component={RouterLink}
                          to={PATH_DASHBOARD.callCenter.teams}
                          sx={{ marginLeft: '10px' }}
                        >
                          Cancel
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          type="submit"
                          onClick={() => {
                            if (popupOn) {
                              closeView(); 
                            } else {
                              if (clientType === 'client') {
                                navigate(PATH_DASHBOARD.client.cards);
                              } else {
                                navigate(PATH_DASHBOARD.client.vendors);
                              }
                            }
                          }}
                          
                          // component={RouterLink}
                          // to={clientId.type === 'client' ? PATH_DASHBOARD.client.cards : PATH_DASHBOARD.client.vendors} 
                          sx={{ marginLeft: '10px' }}
                        >
                          Cancel
                        </Button>
                      )}
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>}
      </Container>
    </Page>
  )
}
