import React, { useState } from 'react'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'

import { Form, FormikProvider, useFormik } from 'formik'
import { Icon } from '@iconify/react'
import copyFill from '@iconify/icons-eva/copy-fill';
import phoneCallFill from '@iconify/icons-eva/phone-call-fill'
import { MIconButton } from 'src/components/@material-extend/index.js'
import { PATH_CALL } from 'src/routes/paths'
import { Block } from 'src/pages/components-overview/Block.js'
// import { Block } from '../Block';

// material
import {
  Box,
  Autocomplete,
  Checkbox,
  Button,
  Grid,
  Card,
  Stack,
  Switch,
  TextField,
  FormControlLabel,
  Typography,
  FormHelperText,
  Paper,
  Divider,
  Avatar,
  Rating,
  IconButton,
  InputAdornment,
  Tooltip,
  CardMedia,
  CardContent,
  CardHeader,
  CircularProgress,
} from '@material-ui/core'
import Label from 'src/components/Label.js'
import { Popover } from '@material-ui/core'
import { Details, Group as GroupIcon } from '@material-ui/icons'
import { Lock as LockIcon } from '@material-ui/icons'
import { ExpandMore, ExpandLess } from '@material-ui/icons'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'

import { LoadingButton } from '@material-ui/lab'
// hooks
import useAuth from '../../../../hooks/useAuth'
import useIsMountedRef from '../../../../hooks/useIsMountedRef'
import { UploadAvatar } from '../../../upload'
import { Link as RouterLink } from 'react-router-dom'
import { PATH_DASHBOARD } from 'src/routes/paths.js'
// utils
import { fData } from '../../../../utils/formatNumber'
//
import countries from '../countries'
import UserEdit from 'src/components/_dashboard/user/UserEdit.js'
import Request from 'src/components/_dashboard/user/profile/Request.js'
import Messages from 'src/components/_dashboard/user/profile/Messages.js'
import UserAccount from 'src/pages/dashboard/UserAccount.js'
import { experimentalStyled as styled } from '@material-ui/core/styles'
import Modal from 'src/components/_dashboard/blog/ProjectModel.js' // replace 'path/to/Modal' with the correct path to your Modal component
import User from 'src/redux/slices/user'
import { REST_API, REST_API_END_POINT } from 'src/constants/Defaultvalues'
import { status } from 'nprogress'
import axios from 'axios'
import { Text } from '@react-pdf/renderer'
import { useEffect } from 'react'
import { capitalCase } from 'change-case'
import ConfirmationPopup from '../../official-request/ConfirmationPopup'
import LoadingScreen from 'src/components/LoadingScreen'
import CopyToClipboard from 'react-copy-to-clipboard'
import TagChip from '../../contact_tag/TagChip'

// ----------------------------------------------------------------------

export default function AccountGeneral({
  setCurrentTab,
  userData,
  requests,
  services,
  states,
  teamServices,
  teamworkflows,
  receivedId,
  isTeam,
  isClient,
  clientId,
  clientDetails,
  allClientRequest,
  setRefreshData,
  refreshData,
  fetchViewClientData,
  clientworkFlow,
  driverData,
  isDriver,
  tags,
  setClientEdit,
  setClientId,
  setViewAccount
}) {
  const navigate = useNavigate()
  const isMountedRef = useIsMountedRef()
  const { enqueueSnackbar } = useSnackbar()
  const { user, updateProfile } = useAuth()
  const [request, setRequest] = useState(false)
  const [popup, setPopup] = useState(false)
  const [group, setGroup] = useState(false)
  const [workflows, setWorkflows] = useState(false)
  const [deleteTeam, setDeleteTeam] = useState(false)
  const [openDeletePop,setOpenDeletePop] =useState(false)
  const [message, setMessage] = useState(false)
  const [isReviewFormOpen, setIsReviewFormOpen] = useState(false)
  const [liked, setLiked] = useState(false)
  const [disliked, setDisliked] = useState(false)
  const [internalNoteState, setInternalNote] = useState(false)
  const [teamRequest, setTeamRequest] = useState([])
  const [loading,setLoading] = useState(false);
 const  [password,setPassword] = useState({
  value:'',
  copied: false
 })
 const [customerTypeData,setCustomerTypeData] = useState([])
  // console.log('isTeam - ', isTeam)
  // console.log('isClient - ', isClient)
  let deleteStatus = 2
  let workflowIds
  let namesArray
  let workflowArray
  let idsArray
  let requestIdsArray
  let requestsArray

  useEffect(() => {
    setLoading(true);
    try{
      fetchData(receivedId)
      fetchAllCustomerTypeData()
    }
    catch(error){
      console.log(error);
    }
    finally{
      setTimeout(function () {
        setLoading(false);
      }, 1000)
    }
  }, [receivedId])


  const fetchData = async (receivedId) => {
    if(isTeam || isClient ==false){
    axios
      .post(`${REST_API_END_POINT}teams/get-requests`, { teamId: receivedId })
      .then((response) => {
        
        setTeamRequest(response.data)
      })
    }
  }

  const fetchAllCustomerTypeData = async()=>{
    const response = await axios.post(`${REST_API_END_POINT}customer-type/get-customer-type`,{id:clientDetails?.customerTypeId})
    if(response.data.status==1){
      setCustomerTypeData(response.data.result)
      console.log('resulttttttttt',response.data.result)
    }else{
      setCustomerTypeData([])
    }
  }

  /***************************************************************************************************************************/
  //filter out the already added workflows

  if (isTeam) {
    if (userData.workflows) {
      workflowIds = userData.workflows
      
      idsArray = workflowIds.split(',') //split the workflow ids received from database
      workflowArray = teamworkflows.filter(
        (obj) => !idsArray.includes(obj.id.toString()),
      )
      namesArray = idsArray.map((id) => {
        const matchingObject = teamworkflows.find(
          (obj) => obj.id === parseInt(id, 10),
        )
        // return matchingObject ? matchingObject.name : 'Unknown'
        return matchingObject ? matchingObject:""
      })
    } 
  }else{
     if(clientDetails){
        workflowIds = clientDetails?.workflows
       
        idsArray = workflowIds.split(',') 
        workflowArray = clientworkFlow?.filter(
          (obj) => !idsArray.includes(obj.id.toString()),
        )
        namesArray = idsArray.map((id) => {        
          const matchingObject = clientworkFlow?.find(
            (obj) => obj.id === parseInt(id, 10),
          )
          // return matchingObject ? matchingObject.name : 'Unknown'
          return matchingObject ? matchingObject:""
        })
      }
  }
 
  /***************************************************************************************************************************/
  //filter out the already added requests

  if (isTeam) {
    if (requests && requests.length > 0) {
      requestIdsArray = requests.map((item) => item.id.toString())
      requestsArray = teamRequest.filter(
        (obj) => !requestIdsArray.includes(obj.id.toString()),
      )
    } else {
      requestsArray = teamRequest.slice() 
    }
    
  }
  /***************************************************************************************************************************/
  //get user logged in data from local storage

  const userd = localStorage.getItem('user')
  const userDataSession = JSON.parse(userd)
  let userId = userDataSession.user_id
  let explodedPermissions = userDataSession?.exploded_permissions

  const handleLike = () => {
    if (disliked) {
      setDisliked(false)
    }
    setLiked(!liked)
  }

  const handleDislike = () => {
    if (liked) {
      setLiked(false)
    }
    setDisliked(!disliked)
  }

  const readOnlyRating = 3
  // const [currentTab, setCurrentTab] = useState('Messages'); // Declare the currentTab state here

  const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  }))

  const UpdateUserSchema = Yup.object().shape({
    // about: Yup.string().required('Internal note is required'),
  })

  const [popoverAnchor, setPopoverAnchor] = useState(null)

  const openPopover = (event) => {
    setPopoverAnchor(event.currentTarget)
  }

  const closePopover = () => {
    setPopoverAnchor(null)
  }

  const Workflow = [
    { code: 'AD', label: 'Eli White' },
    { code: 'AE', label: 'John Manager' },
    { code: 'AF', label: 'Installer' },
    { code: 'AG', label: 'Install Coordinator' },
    { code: 'AI', label: 'Printer All' },
    { code: 'AL', label: 'Fab Cnc' },
    { code: 'AM', label: 'Fabricator Welder' },
    { code: 'AO', label: 'Fabricator Cutting And Sending' },
    { code: 'AQ', label: 'Painter Prep' },
    { code: 'AR', label: 'Quality Check Wrap' },
  ] // replace with your workflow data

  const Request = [
    { code: 'AD', label: 'Begin Production' },
    { code: 'AE', label: 'New Quotation' },
    { code: 'AF', label: 'Request 1' },
    { code: 'AG', label: 'Signboard A' },
    { code: 'AI', label: 'Sign Request Form' },
    { code: 'AL', label: 'Request 2' },
  ] // replace with your Request data

  const Group = [
    { code: 'AD', label: 'Admin' },
    { code: 'AE', label: 'Production' },
    { code: 'AF', label: 'Design' },
    { code: 'AG', label: 'Sales' },
  ] // replace with your Request data
  console.log('userData--userData==',userData);
  let defaultServicesArray=null
  if(userData?.services && isTeam){
    const serviceIds = (userData?.services?.includes(',')) ? (userData?.services?.split(',').map((id) => parseInt(id))) : [parseInt(userData?.services)];
    defaultServicesArray = serviceIds?.map((serviceId) => services?.find((d) => d.id === serviceId) || null);
    console.log('services==serviceIds==',serviceIds);
    console.log('services==services==',services);
    console.log('services==defaultServicesArray==',defaultServicesArray);
  }


// profile_photo
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      displayName:
        (isTeam && `${userData?.first_name} ${userData?.last_name}`) ||
        (isClient && clientDetails?.name) || ( isDriver && driverData?.name)|| '',
      email: (isTeam && userData?.email) || (isClient && clientDetails?.email) || ( isDriver && driverData?.email)|| '',
      photoURL: (isTeam && userData?.profile_pic) || (isClient && clientDetails?.profile_pic) || ( isDriver && driverData?.profile_photo)||'',
      phoneNumber: (isTeam && userData?.phone) || (isClient && clientDetails?.phone) || ( isDriver && driverData?.phone)|| '',
      forward_no: (isTeam && userData?.forward_no) || '',
      hourlyRate: (isTeam && userData?.hourly_rate) || '',
      departmentName: (isTeam && userData?.department_name) || '',
      state: (isTeam && userData?.state) || (isClient && clientDetails?.state) || '',
      city: (isTeam && userData?.city) || (isClient && clientDetails?.city) || '',
      zipCode: (isClient && clientDetails?.zip_code) || '',
      about: (isTeam && userData?.internal_note) ||(isClient && clientDetails?.internal_note)|| '',
      service: isTeam && defaultServicesArray || [],
      role: isTeam && userData?.user_role || '',
      clientRequestcheckbox: null,
      clients:[clientDetails],
      address:(isDriver && driverData?.address)||'',
      licence_number:(isDriver && driverData?.licence_number)||'',
      licence_image:(isDriver && driverData?.licence_image)||'',
      hourly_rate:(isDriver && driverData?.hourly_rate)||'',
      blood_group:(isDriver && driverData?.blood_group)||'',
      gender:(isDriver && driverData?.gender)||'',
      selectworkflows: namesArray && namesArray || '',
      customerType :customerTypeData
    },
   
    validationSchema: UpdateUserSchema,
    onSubmit: async (values, { resetForm, setErrors, setSubmitting }) => {
      try {
        if (internalNoteState === true) {
          const internalNote = values.about
          
          if(isClient==true){
           const clientNoteResponse = await axios.post(`${REST_API_END_POINT}clients/add-internal-note`,
           {internalNote,userId,clientId:clientId})
           if(clientNoteResponse.data.status==1){
            enqueueSnackbar(clientNoteResponse.data.message, {
              variant: 'success',
            })
            fetchViewClientData()  // if temporary data 
            
            resetForm()
           }else{
            enqueueSnackbar('Internal note updation error', {
              variant: 'error',
            })
           }
          }else if(isTeam ==true){
          const internalNoteResult = await axios.post(
            `${REST_API_END_POINT}teams/add-internal-note`,
            { receivedId, userId, internalNote },
          )
          
          if (internalNoteResult.data.status === 1) {
            enqueueSnackbar('Internal note updated successfully', {
              variant: 'success',
            })
            navigate(PATH_DASHBOARD.callCenter.teams)
            resetForm()
          } else {
            enqueueSnackbar('Internal note updation error', {
              variant: 'error',
            })
          }
        }
        setInternalNote(false)
          setSubmitting(false)
        }
      } catch (error) {
        setErrors()
        setSubmitting(false)
      }
    },
  })

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik
  
  const handleResetInternalNote = (formik) => {
    setInternalNote(false)
    formik.resetForm();
  }

  const changeStatus = async (teamId, teamStatus) => {
    try {
      let status = teamStatus
     
      let response = await axios.post(
        `${REST_API_END_POINT}teams/delete-team-members`,
        { teamId, status, userId },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      
      if (response.data.status === 1) {
        enqueueSnackbar(response.data.message, { variant: 'success' })
      } else if (response.data.status === 0) {
        enqueueSnackbar(response.data.message, { variant: 'error' })
      } else {
        
        enqueueSnackbar('Error.........!', { variant: 'error' })
      }
    } catch (error) {
      console.log('Delete error : ', error)
    }
  }

  const addWorkflowSubmit = async () => {
    try {
      setWorkflows(false)
      if (values.newWorkflow) {
        let workflowIds
        let combinedIdsArray
        let newWorkflowIds = values.newWorkflow.map((workflow) => workflow.id)
        // if (idsArray) {
        //   combinedIdsArray = idsArray.concat(newWorkflowIds)
        //   workflowIds = combinedIdsArray.join(',')
        // } else {
        //   workflowIds = newWorkflowIds.join(',')
        // }
        workflowIds = newWorkflowIds.join(',')
        if(isClient){
          let res = await axios.post(`${REST_API_END_POINT}clients/manage-workflow`,{
            workflowIds,receivedId:clientId,userId
          })
          if (res.data.status === 1) {
           
            enqueueSnackbar(res.data.message, { variant: 'success' })
          } else {
            enqueueSnackbar(res.data.message, { variant: 'error' })
          }
        }  else{
          ///this api call  is for team
        let response = await axios.post(
          `${REST_API_END_POINT}teams/update-workflows`,
          { receivedId, workflowIds, userId },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        
        if (response.data.status === 1) {
          enqueueSnackbar(response.data.message, { variant: 'success' })
          // navigate(PATH_DASHBOARD.callCenter.teamaccount, { state: { data: receivedId, isTeam: true } })
          navigate(PATH_DASHBOARD.callCenter.teams)
        } else {
          enqueueSnackbar(response.data.message, { variant: 'error' })
          // navigate(PATH_DASHBOARD.callCenter.teams);
          navigate(PATH_DASHBOARD.callCenter.teamaccount, {
            state: { data: receivedId, isTeam: true },
          })
        }
      }
      } else {
        navigate(PATH_DASHBOARD.callCenter.teamaccount, {
          state: { data: receivedId, isTeam: true },
        })
      }
    } catch (error) {
      console.log('addWorkflowSubmit error - ', error)
    }
  }
  const handleDeletClient=async(id,status)=>{
    try {
      if(isClient){
     const data={id:id,status:status}
     const res = await axios.post(`${REST_API_END_POINT}clients/remove-client`,data)
     
      if(res.data.status ==1){
      enqueueSnackbar(res.data.message,{variant:'success'})
      closePopover()
      navigate(PATH_DASHBOARD.client.cards)
      }else if(res.data.status ==0){
       enqueueSnackbar(res.data.message,{variant:'error'})
       closePopover()
       navigate(PATH_DASHBOARD.client.cards)
      }
    }
    } catch (error) {
      console.log(error,'Error in deleting client')
    }
  }

  const addRequestSubmit = async () => {
    try {
      setRequest(false)
      if (isClient) {
        
        if (values.clientRequestcheckbox) {
           let request_id=values.clientRequestcheckbox.id

          let response = await axios.post(
            `${REST_API_END_POINT}request/add-clients`,
            { request_id,values , userId },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            },
          )
          
          if (response.data.status === 1) {
            enqueueSnackbar(response.data.message, { variant: 'success' })
          
            setRequest(false)
            closePopover()
            setRefreshData(!refreshData)
            
          } else {
            enqueueSnackbar(response.data.message, { variant: 'error' })
            // navigate(PATH_DASHBOARD.callCenter.teams);
            setRequest(false)
            closePopover()
           
          }
        } else {
          setRequest(false)
          closePopover()
         
        }
      } else {
        navigate(PATH_DASHBOARD.callCenter.teamaccount, {
          state: { data: receivedId, isTeam: true },
        })
      }
    } catch (error) {
      console.log('addRequestSubmit error ', error)
    }
  }

const onCopy = (state) => {
  setPassword({ value: state, copied: true });
  if (state) {
    enqueueSnackbar('Copied', { variant: 'success' });
  }
};

const handleEditClient = (isVendor , clientId)=>{
  const editClient = isVendor ? 'vendor' : 'client' 
  setClientEdit(editClient)
  setClientId(clientId)
  setViewAccount("editClient")

}

  return (
    loading ? 
      <Stack alignItems='center' justifyContent='center'>
        <CircularProgress color="primary" variant="indeterminate" />
      </Stack>
    :
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card sx={{ py: 5, px: 3, textAlign: 'center' }}>
              {isClient && (
                <Grid container justifyContent="flex-start">
                  <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
                    <Box sx={{ textAlign: { sm: 'left' } }}>
                      <Typography sx={{color:clientDetails?.bg_color}} variant='subtitle2'>
                       {clientDetails?.partner_type_name}
                       </Typography>
                    </Box>
                    <Box sx={{ textAlign: { sm: 'left',md:'left' } }}>
                      <Typography variant='caption'>
                       {clientDetails?.partner_type_description}
                       </Typography>
                    </Box>
                  </Grid>
                </Grid>
              )}
                
              {/* First Section */}
              <Box sx={{ mb: 3, paddingBottom: '30px' }}>
                <Avatar
                  alt={ isClient ?clientDetails?.company_name:values.displayName}
                  src={ isClient ?clientDetails?.profile_pic:values.photoURL}
                  sx={{ width: 150, height: 150, margin: 'auto' }}
                />
                {userData && (
                  <span
                    style={{
                      width: '13px',
                      height: '13px',
                      borderRadius: '50%',
                      marginLeft: '50px',
                      marginTop: '-30px',
                      position: 'absolute',
                      zIndex: 13,
                      backgroundColor:
                        userData.active == 'Y' ? '#00FF00' : '#fc3838',
                    }}
                  ></span>
                )}

                <Typography
                  variant="h4"
                  //variant="caption"
                  sx={{
                    mt: 5,
                    // mt: 2,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    // color: 'text.secondary'
                  }}
                >
                  {capitalCase(values.displayName)}
                </Typography>
                  {isDriver && (
                    <Card>
                    <CardHeader sx={{justifyContent:'flex-start'}} title='Licence'/>
                    <CardMedia
                    component="img"
                    height="140"
                    loading='lazy'
                    image={values?.licence_image}
                     />
                     </Card>
                  )}
                {/* {isClient && (
                  <Box
                    component="fieldset"
                    mb={3}
                    borderColor="transparent"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Rating name="read-only" value={Number(clientDetails?.rating)} readOnly />
                  </Box>
                )} */}

                {/* {isClient && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      mt: 2,
                      mb: 2.5,
                    }}
                  >
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography
                        variant="body2"
                        sx={{ cursor: 'pointer', color: 'primary.main' }}
                      >
                        {clientDetails?.no_of_reviews} Reviews
                      </Typography>
                    </Stack>
                  </Box>
                )} */}

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 2,
                    mb: 2.5,
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography
                      variant="body2"
                      sx={{ cursor: 'pointer', color: 'primary.main' }}
                    >
                      {/* <Icons icon={location-city} width={30} height={30} color="#006097" /> */}
                      {`${values.city}`}
                      {values.city && values.state ? ' , ' : ''}
                      {`${values.state}`}
                    </Typography>
                  </Stack>
                </Box>
                {
                          (userData && userData?.user_type ===1)? ""
                          :
                <Grid item sx={{ marginTop: '20px' }}>
                {(isTeam ||(isClient&&clientDetails?.user_id))?
                  (<Button
                    sx={{ marginTop: '10px' }}
                    onClick={openPopover}
                    variant="contained"
                  >
                    Actions ***
                  </Button>):null}
                  <Popover
                    sx={{ marginTop: '40px' }}
                    open={Boolean(popoverAnchor)}
                    anchorEl={popoverAnchor}
                    onClose={closePopover}
                    anchorOrigin={{
                      vertical: 'center', // Updated to 'top'
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}
                  >
                    <Paper
                      sx={{
                        maxWidth: '300px', // Adjust the width as per your requirement
                        maxHeight: 210,
                        overflow: 'auto',
                        '&::-webkit-scrollbar': {
                          width: '0.4em',
                        },
                        '&::-webkit-scrollbar-track': {
                          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: 'rgba(0,0,0,.1)',
                          borderRadius: '4px',
                        },
                      }}
                    >
                      <Stack direction="column" spacing={2} sx={{ p: 2 }}>
                       <Typography
                          onClick={
                            isTeam
                              ? () => {
                                  // navigate(<Messages id={receivedId}/>)
                                  closePopover()
                                  navigate(
                                    PATH_DASHBOARD.callCenter.teamaccount,
                                    { state: { data: receivedId } },
                                  )
                                  setCurrentTab('Messages') // Set the desired tab as active
                                }
                              : () => {
                                  closePopover()
                                  setMessage(true)
                                  // navigate(`/dashboard/client/account/${clientId}`)
                                  // navigate(PATH_DASHBOARD.client.account) 
                                  setCurrentTab('Messages') // Set the desired tab as active
                                }
                          }
                          component="div"
                          variant="body1"
                          sx={{
                            cursor: 'pointer',
                            textAlign: 'left',
                            color: 'primary.main',
                            '&:hover': { color: 'white' },
                          }}
                        >
                          Send Message
                        </Typography>
                        {isClient && (
                          <>
                        <Typography
                          onClick={() => {
                            closePopover()
                            setRequest(!request)
                          }}
                          variant="body1"
                          component="div"
                          sx={{
                            cursor: 'pointer',
                            textAlign: 'left',
                            color: 'primary.main',
                            '&:hover': { color: 'white' },
                          }}
                        >
                          Add to Request
                        </Typography>

                        
                          {/* <Typography
                            onClick={() => {
                              setIsReviewFormOpen((prev) => !prev)
                            }}
                            variant="body1"
                            component="div"
                            sx={{
                              cursor: 'pointer',
                              textAlign: 'left',
                              color: 'primary.main',
                              '&:hover': { color: 'white' },
                            }}
                          >
                            Add Review
                          </Typography> */}
                          </>
                        )}

                        {/* {isClient && (
                          <Typography
                            onClick={() => {
                              setGroup(!group)
                            }}
                            variant="body1"
                            component="div"
                            sx={{
                              cursor: 'pointer',
                              textAlign: 'left',
                              color: 'primary.main',
                              '&:hover': { color: 'white' },
                            }}
                          >
                            Add to Group
                          </Typography>
                        )} */}
                        <Typography
                          onClick={() => {
                            if(isTeam && (explodedPermissions?.some(permission => ['Delete team members'].includes(permission))))
                            {
                              closePopover()
                              setDeleteTeam(!deleteTeam)
                            } else if((clientDetails?.isVendor && explodedPermissions?.some(permission => ['Delete Vendors'].includes(permission))) ||
                            (!clientDetails?.isVendor && explodedPermissions?.some(permission => ['Delete clients'].includes(permission))))
                            {
                              setOpenDeletePop(true)
                            } else{
                              enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                            }
                          }}
                          variant="body1"
                          component="div"
                          sx={{
                            cursor: 'pointer',
                            textAlign: 'left',
                            color: 'primary.main',
                            '&:hover': { color: 'white' },
                          }}
                        >
                          Remove {isTeam ? 'Team Member' : clientDetails?.isVendor ? 'Vendor' : 'Client'}
                        </Typography>
                     
                        <Typography
                          onClick={
                            isTeam
                              ? () => {
                                if(explodedPermissions?.some(permission => ['Edit team members'].includes(permission))){
                                  closePopover()
                                  navigate(`/dashboard/client/edit/team/${receivedId}/${true}`)
                                }else{
                                  enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                                }}
                              : () => {
                                if((clientDetails?.isVendor && explodedPermissions?.some(permission => ['Edit Vendors'].includes(permission))) ||
                                (!clientDetails?.isVendor && explodedPermissions?.some(permission => ['Edit clients'].includes(permission))))
                                {
                                  closePopover()
                                  handleEditClient(clientDetails.isVendor ,clientId )
                                  // navigate(`/dashboard/client/edit/${clientDetails.isVendor ? 'vendor' : 'client'}/${clientId}/${false}`)
                                }else{
                                  enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                                }
                              }
                          }
                          variant="body1"
                          component="div"
                          sx={{
                            cursor: 'pointer',
                            textAlign: 'left',
                            color: 'primary.main',
                            '&:hover': { color: 'white' },
                          }}
                        >
                          Edit Profile Information
                        </Typography>

                        <Typography
                          onClick={
                            isTeam
                              ? () => {
                                  closePopover()
                                  setWorkflows(!workflows)
                                }
                              : () => {
                                  closePopover()
                                  setWorkflows(!workflows)
                                }
                          }
                          variant="body1"
                          component="div"
                          sx={{
                            cursor: 'pointer',
                            textAlign: 'left',
                            color: 'primary.main',
                            '&:hover': { color: 'white' },
                          }}
                        >
                          Manage On Boarding Workflows
                        </Typography>
                      </Stack>
                    </Paper>
                  </Popover>              
                </Grid>
                }
              </Box>

              <Divider sx={{ mb: 3,}} />
              <Grid
             container
            direction={{ xs: 'column', sm: 'row', md: 'row' }}
             spacing={1}
              flexWrap="wrap"
               >
             {tags?.map((obj) => (
             <Grid item key={obj.id}>
               <TagChip
                sx={{ mb: 1 }}
                  size={'small'}
                 label={obj.name}
                color={obj.color}
              variant={obj.variant}
              />
            </Grid>
            ))}
           </Grid>

              {/* Second Section */}
              {/* {isClient && (
                <Box
                  sx={{
                    mx: 0,
                    mb: 3,
                    width: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box>
                    <Typography
                      variant="subtitle2"
                      sx={{ mb: 0.5, color: 'text.primary' }}
                    >
                      Relationship managers
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <GroupIcon color="primary" fontSize="small" />
                      <Typography
                        variant="body2"
                        sx={{ cursor: 'pointer', color: 'primary.main' }}
                        onClick={() => {
                          setPopup(!popup)
                        }}
                      >
                        Relationship managers
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              )} */}
            </Card>
          </Grid>
         
          {/* Set Relationship Managers */}
          <Modal
            open={popup}
            handleClose={() => {
              setPopup(false)
            }}
            modalTitle={'Set Relationship Managers'}
          >
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off">
                <Stack
                  direction={{ xs: 'column', sm: 'column' }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <Autocomplete
                    multiple
                    fullWidth
                    id="checkboxes-tags-demo"
                    options={Workflow}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox checked={selected} />
                        {option.label}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Relationship Managers"
                        placeholder="Relationship Managers"
                        error={Boolean(touched.Workflow && errors.Workflow)}
                        helperText={touched.Workflow && errors.Workflow}
                        fullWidth // add this line to make the Autocomplete component full width
                      />
                    )}
                  />
                </Stack>

                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={12} md={4}>
                    <Box
                      sx={{
                        mt: 3,
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 1,
                      }}
                    >
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        onClick={() => {
                          setPopup(false)
                        }}
                        loading={isSubmitting}
                      >
                        Add
                      </LoadingButton>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setPopup(false)
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </Modal>
                         {/* deleteing client  */}
          <ConfirmationPopup 
                          open={openDeletePop}
                          handleClose={()=>{setOpenDeletePop(false),closePopover()}}
                          message={`You want to delete ${clientDetails?.name} ! `}
                          handleClick={()=>{handleDeletClient(clientId,2)}}
                          />

          {/* Add To Request */}
          <Modal
            open={request}
            handleClose={() => {
              setRequest(false)
            }}
            modalTitle={'Add To Request'}
          >
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off">
                <Stack
                  direction={{ xs: 'column', sm: 'column' }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  {isClient ? (
                    <Autocomplete
                      fullWidth
                      id="clientRequestcheckbox"
                      options={allClientRequest}
                      disableCloseOnSelect={true}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Add to request"
                          placeholder="Search requests"
                          error={Boolean(
                            touched.clientRequestcheckbox &&
                              errors.clientRequestcheckbox,
                          )}
                          helperText={
                            touched.clientRequestcheckbox &&
                            errors.clientRequestcheckbox
                          }
                        />
                      )}
                      // value={values.teamRequestcheckbox}
                      onChange={(event, newValue) => {
                        setFieldValue('clientRequestcheckbox', newValue)
                        
                      }}
                    />
                  ) : (
                    <Autocomplete
                      multiple
                      fullWidth
                      id="checkboxes-tags-demo"
                      options={Request}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox checked={selected} />
                          {option.label}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Add To Request"
                          placeholder="Add To Request"
                          error={Boolean(touched.Request && errors.Request)}
                          helperText={touched.Request && errors.Request}
                          fullWidth // add this line to make the Autocomplete component full width
                        />
                      )}
                    />
                  )}
                </Stack>

                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={12} md={4}>
                    <Box
                      sx={{
                        mt: 3,
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 1,
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        onClick={() => {
                          addRequestSubmit()
                        }}
                      >
                        Add
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setRequest(false)
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </Modal>

          {/* Add To Group */}
          <Modal
            open={group}
            handleClose={() => {
              setGroup(false)
            }}
            modalTitle={'Add To Group'}
          >
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off">
                <Stack
                  direction={{ xs: 'column', sm: 'column' }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <Autocomplete
                    multiple
                    fullWidth
                    id="checkboxes-tags-demo"
                    options={Group}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox checked={selected} />
                        {option.label}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Add To Group"
                        placeholder="Add To Group"
                        error={Boolean(touched.Group && errors.Group)}
                        helperText={touched.Group && errors.Group}
                        fullWidth // add this line to make the Autocomplete component full width
                      />
                    )}
                  />
                </Stack>

                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={12} md={4}>
                    <Box
                      sx={{
                        mt: 3,
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 1,
                      }}
                    >
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        onClick={() => {
                          setGroup(false)
                        }}
                        loading={isSubmitting}
                      >
                        {' Add '}
                      </LoadingButton>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setGroup(false)
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </Modal>
      
          {/* Manage Onboarding Workflows */}
          {console.log("#############workflows",values)}
          <Modal
            open={workflows}
            handleClose={() => {  
              setWorkflows(false)
            }}
            modalTitle={'Manage Workflows'}
          >
             
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off">
                <Stack
                  direction={{ xs: 'column', sm: 'column' }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  {/* {namesArray &&
                    namesArray.map((name) => (
                      <TextField fullWidth value={name} disabled />
                    ))} */}
                  <Typography variant="body1" sx={{ color: 'primary.main' }}>
                    Select Workflows
                  </Typography>
                  {isClient ? (
                     <Autocomplete
                     multiple
                     fullWidth
                     id="newWorkflow"
                     options={clientworkFlow?clientworkFlow:[]}
                     getOptionLabel={(state) => state.name}
                     onChange={(event, value) => {
                       setFieldValue('newWorkflow', value)
                       setFieldValue('selectworkflows',value)
                     }}
                     defaultValue={values.selectworkflows}
                    
                     // sx={{ width: 400 }}
                     renderInput={(params) => (
                       <TextField
                         {...params}
                         label="Type to search workflows"
                         error={Boolean(
                           touched.newWorkflow && errors.newWorkflow,
                         )}
                         helperText={touched.newWorkflow && errors.newWorkflow}
                       />
                     )}
                   />
                  ):(
                  <Autocomplete
                    multiple
                    fullWidth
                    id="newWorkflow"
                    options={teamworkflows?.filter((item)=>item.status==1)}
                    getOptionLabel={(state) => state.name}
                    onChange={(event, value) => {
                      setFieldValue('newWorkflow', value)
                    }}
                    defaultValue={values.selectworkflows}
                    // sx={{ width: 400 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Type to search workflows"
                        error={Boolean(
                          touched.newWorkflow && errors.newWorkflow,
                        )}
                        helperText={touched.newWorkflow && errors.newWorkflow}
                      />
                    )}
                  />
                  )}
                </Stack>

                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={12} md={4}>
                    <Box
                      sx={{
                        mt: 3,
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 1,
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => {
                          addWorkflowSubmit()
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setWorkflows(false)
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </Modal>

          {/* Delete confirmation */}
          <Modal
            open={deleteTeam}
            handleClose={() => {
              setDeleteTeam(false)
            }}
            // modalTitle={'Remove Team Member'}
          >
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off">
                <Stack
                  direction={{ xs: 'column', sm: 'column' }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <Avatar
                    alt={values.displayName}
                    src={values.photoURL}
                    sx={{ width: 150, height: 150, margin: 'auto' }}
                  />
                  <Typography
                    variant="h5"
                    sx={{ color: 'primary.secondary', alignSelf: 'center' }}
                  >
                    Are you sure you want to delete {values.displayName} ?
                  </Typography>
                </Stack>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        my: 3,
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 2,
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => {
                          changeStatus(receivedId, deleteStatus)
                          navigate(PATH_DASHBOARD.callCenter.teams)
                        }}
                      >
                        Yes, Delete it!
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setDeleteTeam(false)
                        }}
                      >
                        No, Cancel pls!
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </Modal>
    {/* this is the display of name and other details */}
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={{ xs: 2, md: 3 }}>
                {/* First Section */}
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Name"
                    {...getFieldProps('displayName')}
                    disabled
                  />
                  <TextField
                    fullWidth
                    label="Email Address"
                    {...getFieldProps('email')}
                    disabled
                  />
                </Stack>

                <LabelStyle>User Credentials</LabelStyle>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <TextField
                    fullWidth
                    label="User Name"
                    // {...getFieldProps('email')}
                    value={userData?.username || clientDetails?.username || driverData?.username || ''}
                    disabled
                  />             
                   <TextField
                    fullWidth                   
                    value={userData?.pass || clientDetails?.pass||driverData?.password || ''}
                     label={'Password'}
                     placeholder={'Password'}                   
                    InputProps={{
                     endAdornment: (
                      <InputAdornment position="end">
                      <CopyToClipboard text={userData?.pass || clientDetails?.pass||driverData?.password} onCopy={()=>onCopy(userData ? userData?.pass : clientDetails?.pass||driverData?.password)}>
                       <Tooltip title="Copy Clipboard">
                         <IconButton>
                      <Icon icon={copyFill} width={24} height={24} />
                     </IconButton>
                   </Tooltip>
                  </CopyToClipboard>
                 </InputAdornment>
                 )
                }}     
                  />
                </Stack>        
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  {values.phoneNumber && (
                    <TextField
                      fullWidth
                      label="Phone Number"
                      {...getFieldProps('phoneNumber')}
                      disabled
                      InputProps={{
                        endAdornment: isClient && (
                          <Button
                          onClick={() => values.phoneNumber ? window.open(`${PATH_CALL.root}?phone=${values.phoneNumber}&name=${values.displayName}` , '_blank', 'width=500,height=1000') : enqueueSnackbar('Contact number not available', { variant: 'error' })}
                          >
                            <Icon
                              icon={phoneCallFill}
                              width={30}
                              height={30}
                              color="#006097"
                            />
                          </Button>
                        ),
                      }}
                    />
                  )}
                  {(values.forward_no && isTeam) && (
                    <TextField
                      fullWidth
                      label="Call Forward Number"
                      {...getFieldProps('forward_no')}
                      disabled
                      InputProps={{
                        endAdornment: isClient && (
                          <Button
                          onClick={() => values.forward_no ? window.open(`${PATH_CALL.root}?phone=${values.forward_no}&name=${values.displayName}` , '_blank', 'width=500,height=1000') : enqueueSnackbar('Contact number not available', { variant: 'error' })}
                          >
                            <Icon
                              icon={phoneCallFill}
                              width={30}
                              height={30}
                              color="#006097"
                            />
                          </Button>
                        ),
                      }}
                    />
                  )}
                  {isDriver && (
                     <TextField
                     fullWidth
                     label="Address"
                     {...getFieldProps('address')}
                     disabled
                   />
                  )}
                </Stack>
                {isDriver ? (
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>               
                    <TextField
                      fullWidth
                      label="Hourly Rate"
                      {...getFieldProps('hourly_rate')}
                      disabled
                    />
                  </Stack>
                ) : (
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                    {values.hourlyRate && (
                      <TextField
                        fullWidth
                        label="Hourly Rate"
                        {...getFieldProps('hourlyRate')}
                        disabled
                      />
                    )}
                    {(values.departmentName && isTeam) && (
                      <TextField
                        fullWidth
                        label="Department"
                        {...getFieldProps('departmentName')}
                        disabled
                      />
                    )}
                  </Stack>
                )}
                {isDriver && (
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>               
                    <TextField
                      fullWidth
                      label="Licence Number"
                      {...getFieldProps('licence_number')}
                      disabled
                    />        
                    {/* <TextField
                      fullWidth
                      label="Blood Group"
                      {...getFieldProps('blood_group')}
                      disabled
                    />  */}
                     <TextField
                      fullWidth
                      label="Gender"
                      {...getFieldProps('gender')}
                      disabled
                    />                
                </Stack>
                )}
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  {values.zipCode && (
                    <TextField
                      fullWidth
                      label="Zip/Code"
                      {...getFieldProps('zipCode')}
                      disabled
                    />
                  )}
                  {values.city && (
                    <TextField
                      fullWidth
                      label="City"
                      {...getFieldProps('city')}
                      disabled
                    />
                  )}
                  {values.state && (
                    <>
                    <TextField
                      fullWidth
                      label="State/Region"
                      {...getFieldProps('state')}
                      disabled
                    />
                   
                    </>

                  )}
                </Stack>
                {isTeam ?
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    label="User Role"
                    {...getFieldProps('role')}
                    disabled
                  />
                  <Autocomplete
                    multiple
                    fullWidth
                    id="service"
                    disabled
                    options={ services ? services : [] }
                    getOptionLabel={(option) => option?.name}
                    defaultValue={values?.service || []}  // Set the default value here
                    value={values?.service}
                    onChange={(event, newValue) => setFieldValue('service', newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Skills and Services"
                        placeholder="Select Skills and Services"
                        fullWidth
                        SelectProps={{ native: true }}
                      />
                    )}
                  />
                </Stack> : ''}
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                {(isClient && !clientDetails?.isVendor)?
                      <Autocomplete
                      disabled
                      fullWidth
                      sx={{ width: 400 }}
                      id="partner-type"
                      options={customerTypeData || []}
                      value={customerTypeData || null}
                      onChange={(event, newValue) => setFieldValue('customerType', newValue)}
                      renderInput={(params) => (
                        <TextField {...params} label="Client Type" />
                      )}
                      // getOptionLabel={(option) => option?.name}
                      getOptionLabel={(option) => 
                        option?.name ? `${option?.name} ( ${option?.billing_type} % )` : ''
                      }
  
                    />

                      :
                      ""
                    }
                </Stack>



                <Divider sx={{ mb: 3, paddingBottom: '10px' }} />

                {/* About Section */}
                {!isDriver && (
                <TextField
                  {...getFieldProps('about')}
                  fullWidth
                  disabled={
                    isTeam ? (userData.internal_note ? true : false) : false
                  }
                  multiline
                  minRows={4}
                  maxRows={4}
                  onChange={(e) => {
                    if(e.target.value){
                      setInternalNote(true)
                      setFieldValue('about', e.target.value)
                    }
                    else{
                      setInternalNote(false)
                      setFieldValue('about', e.target.value)
                    }
                  }}
                  label="Internal note"
                  placeholder="Write a note here"
                  error={Boolean(touched.about && errors.about)}
                  helperText={touched.about && errors.about}
                />
                )}

                {/* Save and Cancel Buttons */}
                {internalNoteState && (
                  <Box
                    sx={{
                      mt: 3,
                      display: 'flex',
                      justifyContent: 'flex-end',
                      paddingBottom: 2,
                      marginBottom: 2,
                    }}
                  >
                    {isTeam && !userData.internal_note && (
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                      >
                        Add Internal Note
                      </LoadingButton>
                    )}
                    {isClient && !clientDetails.internal_note && (
                       <LoadingButton
                       type="submit"
                       variant="contained"
                       loading={isSubmitting}
                     >
                       Add Internal Note
                     </LoadingButton>
                      // <Button variant="contained">Add Internal Note</Button>
                    )}

                    {isTeam && !userData?.internal_note && (
                      <Button
                        variant="outlined"
                        type="submit"
                        onClick={()=>handleResetInternalNote(formik)}
                        sx={{ marginLeft: '10px' }}
                      >
                        Clear
                      </Button>
                    )}
                    {isClient && !clientDetails?.internal_note &&(
                      <Button 
                      variant="contained"
                      type="submit"
                      onClick={handleResetInternalNote}
                       sx={{ marginLeft: '10px' }}>
                        Clear
                      </Button>
                    )}
                  </Box>
                )}
              </Stack>

              {/* <Divider sx={{ mb: 3, paddingBottom: '30px' }} /> */}

              {/* Second Section */}
              {/* {isClient && (
                <>
                  <Box>
                    <Typography variant="h6">Groups</Typography>
                    <Typography variant="body1" sx={{ color: 'primary.main' }}>
                      Traffic Solutions, Install
                    </Typography>
                  </Box>
                  <Divider sx={{ mb: 3, paddingBottom: '30px' }} />
                </>
              )} */}

              {/* Third Section */}
              {/* {isClient && (
                <Box
                  sx={{
                    mt: 3,
                    display: 'flex',
                    paddingBottom: 2,
                    marginBottom: 2,
                    alignItems: 'center',
                  }}
                >
                  {isReviewFormOpen ? (
                    <ExpandLess
                      onClick={() => {
                        setIsReviewFormOpen(false)
                      }}
                      sx={{ cursor: 'pointer', color: 'primary.main' }}
                    />
                  ) : (
                    <ExpandMore
                      onClick={() => {
                        setIsReviewFormOpen(true)
                      }}
                      sx={{ cursor: 'pointer', color: 'primary.main' }}
                    />
                  )}
                  <Typography
                    variant="body1"
                    onClick={() => {
                      setIsReviewFormOpen((prev) => !prev)
                    }}
                    sx={{ cursor: 'pointer', color: 'primary.main' }}
                  >
                    Reviews
                  </Typography>
                </Box>
              )} */}
              {/* {isReviewFormOpen && (
                // The form that appears when Reviews is clicked
                <Grid item xs={12} md={12}>
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={3}>
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 3, sm: 2 }}
                      >
                        <Grid item xs={3}>
                          {[100].map((size, index) => (
                            <Avatar
                              key={size}
                              alt={ isClient ?clientDetails?.name:values.displayName}
                              src={ isClient ?clientDetails?.profile_pic:values.photoURL}
                              // alt="Travis Howard"
                              // src={`/static/mock-images/avatars/avatar_3.jpg`}
                              sx={{ width: size, height: size }}
                            />
                          ))}
                        </Grid>

                        <TextField
                          fullWidth
                          label="Write your review here"
                          multiline
                          value={clientDetails?.review_comment}
                          rows={4} 
                          // placeholder="Write your review here"
                        />
                      </Stack>
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 3, sm: 2 }}
                      >
                      </Stack>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                          <div>
                            <LabelStyle>Quality</LabelStyle>
                            <Rating
                              name="Quality"
                              value={clientDetails?.quality_rating}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <div>
                            <LabelStyle>Delivery</LabelStyle>
                            <Rating
                              name="Delivery"
                              value={clientDetails?.delivery_rating}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <div>
                            <LabelStyle>Value for money</LabelStyle>
                            <Rating
                              name="Value-for-money"
                              value={clientDetails?.value_for_money_rating}
                             
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <div>
                            <LabelStyle>Recommended</LabelStyle>
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <IconButton
                                aria-label="like"
                                
                                style={{ color: clientDetails.recommended ==1 ? 'green' : 'inherit' }}
                              >
                                <ThumbUpIcon />
                              </IconButton>
                              <IconButton
                                aria-label="dislike"
                                
                                style={{ color:clientDetails.recommended == 0  ? 'red' : 'inherit' }}
                              >
                                <ThumbDownIcon />
                              </IconButton>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Card>
                </Grid>
              )} */}
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
}
