import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import pinFill from '@iconify/icons-eva/pin-fill'
import emailFill from '@iconify/icons-eva/email-fill'
import roundBusinessCenter from '@iconify/icons-ic/round-business-center'
// material
import { experimentalStyled as styled } from '@material-ui/core/styles'
import { Link, Card, Typography, CardHeader, Stack } from '@material-ui/core'
import { UploadAvatar } from 'src/components/upload'
import { useCallback, useState } from 'react'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from 'src/firebase/firebase'
import Label from 'src/components/Label'
import axios from 'axios'
import { REST_API_END_POINT } from 'src/constants/Defaultvalues'
import { useSnackbar } from 'notistack'

// ----------------------------------------------------------------------
const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  textAlign:'center',
  marginBottom: theme.spacing(1)
}));
const IconStyle = styled(Icon)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}))

// ----------------------------------------------------------------------

ProfileAbout.propTypes = {
  profile: PropTypes.object,
  userData: PropTypes.object,
  teamData: PropTypes.object,
}

export default function ProfileAbout({ profile, userData, teamData,internalNotes,setCoverImage,coverImage }) {
  const { quote, country, email, role, company, school } = profile
  const {enqueueSnackbar}=useSnackbar()

  const updateCoverImage=async(imgURL)=>{
    let response;
    if(teamData){
      response = await axios.post(`${REST_API_END_POINT}teams/update-cover-image`,{
        userId:userData.user_id,imgURL:imgURL
      })
    }
    else{
      response = await axios.post(`${REST_API_END_POINT}clients/update-coverimage`,{
        userid:userData.user_id,imgURL:imgURL
      })
    }
    if(response.data.status ==1){
      enqueueSnackbar(response.data.message,{variant:'success'})
    }else{
      enqueueSnackbar(response.data.message,{variant:'error'})
    }
  
  }
  const handleDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const file = acceptedFiles[0]; 
        const Filename = new Date().getTime() + file.name;
  
        const StorageRef = ref(storage, Filename);
        const uploadTask = uploadBytesResumable(StorageRef, file);
  
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

          },
          (error) => {
            console.error(error.message);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {              
              setCoverImage(downloadURL)
                updateCoverImage(downloadURL)
              console.log(downloadURL, '&&&&&&&&&&&&&&&&&&&');
            });
          }
        );
      } 
      
    },
    [setCoverImage]
  );
  return (
    <Stack spacing={2}>
      {(teamData.internal_note || internalNotes) ? (
        <Card sx={{ width: '100%' }}>
          <CardHeader title="Internal Note" />
          <Stack spacing={2} sx={{ p: 3 }}>
            <Typography variant="body2" sx={{ mb: 3 }}>
              {teamData.internal_note ? teamData.internal_note : internalNotes}
            </Typography>
          </Stack>
        </Card>
      ) : null }

      <Card sx={{ width: '100%' }}>
        <Stack spacing={2} sx={{ p: 3 }}>
          {(teamData.city || teamData.state) && (
            <Stack direction="row">
              <IconStyle icon={pinFill} />
              <Typography variant="body2">
                Live at &nbsp;
                <Link component="span" variant="subtitle2" color="text.primary">
                  {(teamData.city ? `${teamData.city}` : '') +
                    (teamData.city && teamData.state ? ', ' : '') +
                    (teamData.state ? `${teamData.state}` : '')}
                </Link>
              </Typography>
            </Stack>
          )}

          <Stack direction="row">
            <IconStyle icon={emailFill} />
            <Typography variant="body2">{userData.email}</Typography>
          </Stack>

          <Stack direction="row">
            <IconStyle icon={roundBusinessCenter} />
            <Typography variant="body2">
              {userData.isVendor===1 ? 'Vendor' : userData?.user_type == 3 ? 'Client' : userData.user_role} at &nbsp;
              <Link component="span" variant="subtitle2" color="text.primary">
                {userData.company_name}
              </Link>
            </Typography>
          </Stack>
        </Stack>
        {(userData?.user_type ==3 || teamData) &&
        <>
        <UploadAvatar sx={{mb:1}}        
         accept="image/*"
         file={coverImage}
         maxSize={3145728}
         type="file"
         onDrop={handleDrop}
        />
        <LabelStyle>Update Cover Image</LabelStyle>
        </>
        }
      </Card>
    </Stack>
  )
}
