import faker from 'faker';
import { sample, set } from 'lodash';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Link, Card, CardHeader, Typography, Stack, Autocomplete, TextField, Divider, CircularProgress } from '@material-ui/core';
// utils
import { fCurrency } from '../../../utils/formatNumber';
import { mockImgProduct } from '../../../utils/mockImages';
//
import Scrollbar from '../../Scrollbar';
import ColorPreview from '../../ColorPreview';
import { empty } from 'src/Helpers/Common';
import React, { Fragment, useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
import { project } from 'deck.gl';

// ----------------------------------------------------------------------

const PRODUCTS = [...Array(5)].map((_, index) => {
  const setIndex = index + 12;
  return {
    name: faker.commerce.productName(),
    image: mockImgProduct(setIndex),
    price: faker.datatype.number({ min: 4, max: 49, precision: 0.1 }),
    priceSale: sample([0, faker.datatype.number({ min: 49, max: 99, precision: 0.1 })]),
    colors: (index === 1 && [faker.vehicle.color(), faker.vehicle.color()]) ||
      (index === 2 && [
        faker.commerce.color(),
        faker.commerce.color(),
        faker.commerce.color(),
        faker.commerce.color(),
        faker.commerce.color(),
        faker.commerce.color()
      ]) || [faker.internet.color(), faker.internet.color(), faker.internet.color(), faker.internet.color()]
  };
});
const Product=[
  {id:0,
    name:'Addidas',
    image:'https://media.designrush.com/inspiration_images/292255/conversions/levi_logo_4_82250bd000e1-mobile.jpg',
    total:16,
    actual:14,
  },
  { id:1,
    name:'TITANS',
    image:'https://yt3.googleusercontent.com/ytc/APkrFKa4rHOmY5DlqAsH9kWfuCjGdhtVj-YJ_BMnnk087Q=s900-c-k-c0x00ffffff-no-rj',
    total:34,
    actual:17
  },
  { id:2,
    name:'RAYMOND',
    image:'https://i.pinimg.com/736x/43/0e/77/430e77e66be53229113c82e8350ac712.jpg',
    total:18,
    actual:16
  }, {
    id:3,
    name:'KELLOGs',
    image:'https://cdn.shopify.com/s/files/1/0558/6413/1764/files/Kellogg_s_Logo_Design_History_Evolution_0_1024x1024.jpg?v=1692865122',
    total:23,
    actual:15
  }, {
    id:5,
    name:'STARBUCKS',
    image:'https://images.crowdspring.com/blog/wp-content/uploads/2022/09/07052909/62bc70073fd3864405fe0551_starbucks-logo-1992-2011.png',
    total:20,
    actual:13
  }
]

const ThumbImgStyle = styled('img')(({ theme }) => ({
  width: 62,
  height: 62,
  objectFit: 'cover',
  borderRadius: theme.shape.borderRadiusSm
}));

// ----------------------------------------------------------------------

ProductItem.propTypes = {
  product: PropTypes.object.isRequired
};

function ProductItem({ product }) {
  const { name, image, price, id,total,actual } = product;

  return (
    <Stack direction="row" spacing={2}>
      {/* <ThumbImgStyle alt={name} src={image} /> */}

      <Box sx={{ flexGrow: 1, minWidth: 200 }}>
        <Typography variant='subtitle2' sx={{ color: 'text.primary',  }}>
          {name}
        </Typography>

        <Stack direction="row" sx={{justifyContent:'space-between'}}>
            <Typography variant="body2" sx={{ color: 'text.secondary',}}>
            Actual : {'$'}{actual}
            </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Total111 : {'$'}{total}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Utilized Budget : {'$'}{((actual/total)*100).toFixed(2)}
          </Typography>
        </Stack>

      </Box>

    </Stack>
  );
}


  const  EcommerceLatestProducts = React.memo(() => {

  const [projects,setProjects] = useState(Product)
  const [allProjects,setAllProjects]= useState([])
  const[expense,setExpense]=useState(0)
  const[budget,setBudget]=useState(0)
  const[Utilization,setUtilization]=useState(0)
  const [loading, setLoading] = useState(true);
  const user = localStorage.getItem('user')
  const userData = JSON.parse(user)
  let userType = userData?.user_type;
  let userId = userData?.user_id;
  const fetchAllProjects = async()=>{
    try {
      setLoading(true)
      const res = await axios.post(REST_API_END_POINT+'settings/project-statistics',{all:true, userType, userId})
      if(res){
        let budget = res?.data?.data.reduce((total, currentItem) => total + currentItem.cost, 0);
        let expense = res?.data?.data.reduce((total, currentItem) => total + currentItem.expense, 0);
        budget = budget ? budget?.toFixed(2) : 0
        expense = expense ? expense?.toFixed(2) : 0
        let Utilization;
        if (budget !== 0) {
          Utilization = parseFloat((expense / budget) * 100).toFixed(2);
        } else {
          Utilization = 0;
        }
        setAllProjects(res?.data?.data)
        setExpense(expense)
        setBudget(budget)
        setUtilization(Utilization)
      }
    } catch (error) {
      console.log(error)
    }finally{
      setLoading(false)
    }
  }
  useEffect(()=>{
    fetchAllProjects()
   },[userType])
  const handleProjectByname=(value)=>{
    if(!empty(value)){
   let Utilization = parseFloat(((value.expense||0)/(value.budget||1))*100).toFixed(2)
   setExpense(value.expense?.toFixed(2))
   setBudget(value.budget?.toFixed(2))
   setUtilization(Utilization)
    }else{
      fetchAllProjects()
    }
  }
  return (
    <Card sx={{ml:10,mr:10,pl:3,pr:3}}>
      <CardHeader
       title="Utilization"
       action={
        <Autocomplete
          loading={loading}
           sx={{width:250}}
          id="combo-box-demo"
          options={allProjects}
          onChange={(event, newValue) =>{
            handleProjectByname(newValue)}
          }
          renderInput={(params) => (
            <TextField
            {...params} label="Select Jobs"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                  </Fragment>
              ),
            }}
            />
          )}
          getOptionLabel={(option) => option.name}

        />
       }
       />
      
        <Stack spacing={3} sx={{ minWidth: 350}}>
          {/* {Product.map((product) => (
            <ProductItem key={product.id} product={product} />
          ))} */}
          <Box sx={{ flexGrow: 1, alignItems:'center',p: 1,zIndex:8}}>
            <Stack direction="column" >
              <Stack >
              <Typography variant='h4' sx={{ color: 'text.primary',  }}>
                ${expense}
              </Typography>
              <Typography variant='subtitle1' sx={{ color: 'text.primary',  }}>
                Total Expense 
              </Typography>
              </Stack><br/>
              <Stack>
              <Typography variant='h4' sx={{ color: 'text.primary',  }}>
                ${budget}
              </Typography>
              <Typography variant='subtitle1' sx={{ color: 'text.primary',  }}>
                Total Budget
              </Typography>
              </Stack><br/>
              <Divider />
              <Stack sx={{textAlign:'center'}}>
              <Typography variant='h4' sx={{ color: "#1ccaff",  }}>
                {Utilization} %
              </Typography>
              <Typography variant='h6' sx={{ color: "#1ccaff",  }}>
                Utilized Budget
              </Typography>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      
    </Card>
  );
})

export default EcommerceLatestProducts;