import React, { useState } from 'react';
import { Grid, Card, CardMedia, makeStyles, Checkbox, Box, Stack, Button, Typography, IconButton } from '@material-ui/core';
import { Icon } from '@iconify/react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
import { useSnackbar } from 'notistack';
import arrowLeftOutlined from '@iconify/icons-ant-design/arrow-left-outlined';
import arrowRightOutlined from '@iconify/icons-ant-design/arrow-right-outlined'; 
import Modal from './ProjectModel';

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: theme.spacing(1),
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative', 
        cursor:'pointer'
    },
    delete:{
      m:2,
      display:'flex',
      justifyContent:'flex-end' 
    },
    media: {
        height: 0,
        paddingTop: '100%', 
        width: '100%',
        objectFit: 'fill', 
    },
    checkboxContainer: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
    gridContainer: {
        height: '350px', 
        overflowY: 'auto', 
        paddingRight: '0.5rem',
        '&::-webkit-scrollbar': {
            width: '0.75rem',
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(110, 108, 108, 0.32)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(110, 108, 108, 0.32)',
            borderRadius: '4px',
          },
    },
}));

export default function ProjectImageAttach({images,setFetchData,projectId,setRefresh,refresh,designOrder}) {
    let user = JSON.parse(localStorage.getItem('user'))
    const explodedPermissions = user?.exploded_permissions
    const classes = useStyles();
    const {enqueueSnackbar}=useSnackbar() 
    const [checkedItems, setCheckedItems] = useState([]);
    const [openImageModal,setOpenImageModal] = useState(false)
    const [currentImageIndex, setCurrentImageIndex] = useState(null);
    
    const handleCheckboxChange = (id) => {
        console.log(id)
        setCheckedItems((prevState) => {
            const isPresent = prevState.includes(id);
            const updatedState = isPresent
              ? prevState.filter((item) => item !== id)
              : [...prevState, id];
            return updatedState;
          });   
    };

  const handleDelete=async()=>{
    try {
        let apiUrl = '';
        if(designOrder){
            apiUrl = `design-order-job/delete-attachment`
        }else{
            apiUrl = `project-task/delete-project-attachment`
        }
        const res = await axios.post(`${REST_API_END_POINT}${apiUrl}`,{
            userId:user.user_id,attachments:checkedItems,projectId, 
        })
        if(res.data.status===1){
            if(designOrder){
                setFetchData(true)
              }
            enqueueSnackbar(res.data.message,{variant:'success'}) 
            setRefresh(!refresh)
            setCheckedItems([])
        }else{
            enqueueSnackbar(res.data.message,{variant:'error'}) 
        }
    } catch (error) {
        console.log(error)
    }
  }

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setOpenImageModal(true);
  };
  
    const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex < imageDesigns.length - 1 ? prevIndex + 1 : prevIndex));
  };
  
    const imageDesigns = images?.filter(
    (item) => item.file_type?.startsWith('image/') || item.file_type === 'img' || item.file_type === 'image'
  );
    return (
        <Grid className={classes.gridContainer}> 
           {checkedItems.length > 0 &&(
                 <Box sx={{ mb: 3, display: 'flex', justifyContent: 'flex-end',gap:2,m:2.5,  }}>
                    <Typography  variant='subtitle1'>{checkedItems.length} selected</Typography>
                    <Button
                      disabled={ designOrder ? !explodedPermissions?.some((permission) => ['Delete Design from Design Order Job'].includes(permission)) : false }
                      onClick={()=>handleDelete()} className={classes.delete} size='small' variant='outlined'>
                         <Icon icon={trash2Outline} width={24} height={24} /> Delete
                         </Button>
                </Box>)}
                {images?.length>0 ?(
            <Grid container spacing={2}>
                {images?.map((image) => (
                    <Grid key={image.ID} item xs={12} md={4} pb={4}>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.media}
                                image={image.file_url}                              
                                title={`Image ${image.file_url}`}
                                onClick={(e) => {
                            e.preventDefault();
                            handleImageClick(imageDesigns.findIndex((img) => img.file_url === image.file_url))
                          }}
                                
                            />
                            <Box className={classes.checkboxContainer}>
                                <Checkbox sx={{cursor:'pointer'}}
                                    checked={checkedItems.includes(image.ID) ? true : false}
                                    onChange={() => handleCheckboxChange(image.ID)}
                                    color="primary"
                                />
                            </Box>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            ):(
                <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              py={15}
            >
              No images found
            </Box>
                )}
                <Modal  width={1000}
              open={openImageModal}
              handleClose={() => {
                setOpenImageModal(false);
              }}
              modalTitle={'Designs'}
              >
              <div
              style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                display: 'flex',
                gap:'1rem',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
            <IconButton
              onClick={handlePrevious}
              disabled={currentImageIndex === 0}
              sx={{
                opacity: currentImageIndex === 0 ? 0.3 : 1,
                pointerEvents: currentImageIndex === 0 ? 'none' : 'auto',
              }}
            >
              <Icon
                icon={arrowLeftOutlined}
                width={24}
                height={24}
                color="#006097"
              />
            </IconButton>

            <Box
            component={'div'} 
              style={{
                width: '500px',
                height: '500px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 8,
                overflow:'hidden'
              }}
            >
              <img
                src={imageDesigns[currentImageIndex]?.file_url}
                alt={`Image ${currentImageIndex + 1}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: 8,
                }}
              />
            </Box>
            <IconButton
              onClick={handleNext}
              disabled={currentImageIndex === imageDesigns.length - 1}
              sx={{
                opacity: currentImageIndex === imageDesigns.length - 1 ? 0.3 : 1,
                pointerEvents: currentImageIndex === imageDesigns.length - 1 ? 'none' : 'auto',
              }}
            >
              <Icon
                icon={arrowRightOutlined}
                width={24}
                height={24}
                color="#006097"
              />
            </IconButton>
          </div>
        </Modal>
        </Grid> 
    );
}
