import { merge } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box, TextField, Autocomplete, CircularProgress, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
//
import { BaseOptionChart } from '../../charts';
import { empty } from 'src/Helpers/Common';
import  axios  from 'axios';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';

// ----------------------------------------------------------------------


const  EcommerceYearlySales = React.memo(() => {
 const  navigate = useNavigate()
  const [projects,setProjects] = useState([])
  const [allProjects,setAllProjects]= useState([])
  const [loading, setLoading] = useState(true);
  const [selectedPhase, setSelectedPhase] = useState('4');
  const [isEnlarge,setIsEnlarge] = useState(false)
  const user = localStorage.getItem('user')
  const userData = JSON.parse(user)
  let userType = userData?.user_type;
  let userId = userData?.user_id;
  console.log('allProjects====',allProjects)
  useEffect(()=>{
    fetchAllProjects()
  },[userType])

  const handleProjectByname=(value)=>{
    if(!empty(value)){
    setProjects([value])
    }else{
      setProjects(allProjects)
    }
  }

const fetchAllProjects = async(selectedPhaseValue='4')=>{
  try {
    setLoading(true)
    let all=false
    if(selectedPhaseValue=="4"){
      all=true;
    }
    const res = await axios.post(REST_API_END_POINT+'settings/project-statistics',
      { all, selectedPhaseValue, userType, userId })
    setProjects(res?.data?.data)
    setAllProjects(res?.data?.data)
  } catch (error) {
    console.log(error)
  } finally {
    setLoading(false)
  }
  
}
const handlePhaseChange = (event) => {
  setSelectedPhase(event.target.value);
  fetchAllProjects(event.target.value)
  
};


const handleBarFocus = (event, chartContext, config) => {
  const clickedProject = projects[config.dataPointIndex];
  if (clickedProject) {
    setIsEnlarge(true)
    setProjects([clickedProject]);
  }
  
};

const handleBarNavigate = (event, chartContext, config) => {
  const clickedProject = projects[config.dataPointIndex];
  setIsEnlarge(false)
  if (clickedProject) {
    navigate(PATH_DASHBOARD.project.viewproject + clickedProject.id,{state:{fromDashboard:true}}); 
  }
  setProjects(allProjects);
};


  const chartOptions = merge(BaseOptionChart(), {
    legend: { position: 'top', horizontalAlign: 'right' },  
    // legend:{show: false},
    xaxis: {
      categories:projects?.map(project => project?.name),
      title:{
        text:'JOBS',
       },
    },
    yaxis:{
      title:{
        text:'COST ($)',
       },
       
    },
    chart: {
      events: {
        dataPointSelection:  isEnlarge ? handleBarNavigate : handleBarFocus,
      },
    },
  });

  const series = [
    {
      name: 'Expense',
      data: projects?.map(project => project?.expense?.toFixed(2)),
    },
    {
      name: 'Budget',
      data: projects?.map(project => project?.budget?.toFixed(2)),
    },
  ];
  return (
    <Card>
      <CardHeader
        title="Financial Analysis"
        
        action={
          <Fragment>
          <FormControl variant="outlined"  sx={{ width: 280,marginBottom:2 }}>
            <InputLabel id="phase-select-label">Phase</InputLabel>
            <Select
              labelId="phase-select-label"
              id="phase-select"
              value={selectedPhase}
              onChange={handlePhaseChange}
              label="Phase"
            >
              <MenuItem value="4">All</MenuItem>
              <MenuItem value="1">Pre production</MenuItem>
              <MenuItem value="2">Production</MenuItem>
              <MenuItem value="3">Post production & Installation</MenuItem>
              <MenuItem value="5">Completed</MenuItem>
              {/* Add more phases as needed */}
            </Select>
          </FormControl>
          <Autocomplete
            sx={{ width: 280 }}
            id="combo-box-demo"
            options={allProjects}
            loading={loading}
            onChange={(event, newValue) => {
              handleProjectByname(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Jobs"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
            getOptionLabel={(option) => option.name}
          />
        </Fragment>
         
        }
      />
     
        <Box  sx={{ mt: 3, mx: 3 }} dir="ltr"> 
            <ReactApexChart type="bar" series={series} options={chartOptions} height={364} />  
        </Box>

    </Card>
  );
});

export default EcommerceYearlySales;