import * as Yup from 'yup';
import axios from 'axios';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { useCallback, useEffect, useRef, useState } from 'react';
import { sentenceCase } from 'change-case';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Container, Grid, IconButton,
  Stack, Box, Button, TableRow,
  TableBody, Checkbox, Card,
  TableCell, Typography,
  TableContainer, Table,
  TablePagination,
  TextField,
  Switch,
  FormControlLabel,
  Autocomplete,
  Divider,
  Paper,
  Popover,
     CardHeader,
  TableHead,
  List,
  Menu,
  MenuItem,
  ListItemText,
  CircularProgress,
  FormHelperText,
} from '@material-ui/core';
import { useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import plusFill from '@iconify/icons-eva/plus-fill';
// redux
import { LoadingButton, MobileDatePicker, MobileDateRangePicker } from '@material-ui/lab';
// routes
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Modal from '../../components/_dashboard/blog/ProjectModel';
import Label from 'src/components/Label';
import { useSnackbar } from 'notistack';
import { Delete, UploadFile,LocationOn, ExpandMore, ExpandLess, KeyboardBackspaceOutlined, Visibility, VisibilityOff } from '@material-ui/icons';
import OfficialRequestSearch from 'src/components/_dashboard/official-request/OfficialRequestSearch';
import InfiniteScroll from 'react-infinite-scroll-component';
import OfficialCard from 'src/components/_dashboard/official-request/OfficialCard';
import Scrollbar from 'src/components/Scrollbar';
import moment from 'moment';
import { REST_API, REST_API_END_POINT } from 'src/constants/Defaultvalues';
import { UploadAvatar, UploadMultiFile } from 'src/components/upload';
import {storage} from '../../firebase/firebase'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import ProductToolbar from 'src/components/_dashboard/user/list/ProductToolbar';
import SearchNotFound from 'src/components/SearchNotFound';


const VALUE = [
  { id: 0, value: 'Products' },
  { id: 1, value: 'Price' },
  { id: 2, value: 'Quantity' },
  { id: 3, value: 'Location' },
  { id: 4, value: 'Product ID' },
  { id: 5, value: 'Discount' },
  { id: 6, value: 'Subtotal' },
  { id: 7, value: '' }
]

const getStatusColorAndLabel = (status) => {
  switch (status) {
    case 1:
      return { color: 'warning', label: 'Status: Draft' };
    case 2:
      return { color: 'info', label: 'Status: Open' };
    case 3:
      return { color: 'primary', label: 'Status: Evaluating' };
    case 4:
      return { color: 'success', label: 'Status: Accepted' };
      case 5:
        return { color: 'warning', label: 'Status: Closed' };
        case 6:
          return { color: 'error', label: 'Status: Rejected' };
    default:
      return { color: 'default', label: 'Status: Open' };
  }
};
const SORT = [
  { id: 'new', label: 'Newest', color: '#54D62C' },
  { id: 'old', label: 'Oldest', color: '#ffff00' },
  { id: 'last_updated', label: 'Recently Updated', color: '#ffff00' },
  { id: 'custom_order', label: 'Custom Order', color: '#ffff00' },
]
export default function CommunityDesigns() {
    const location = useLocation();
    const communityId = location.state && location.state.id;
    const communityName = location.state && location.state.name;
    const projectsClients = location.state && location.state.clients;
    const projectsClientsArray = projectsClients ? projectsClients.split(',').map(id => id.trim()) : [];
  const theme = useTheme()
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searching,setSearching] = useState(null)
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(0);
  const [preview,setPreview] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [allRequest, setAllRequest] = useState([])
  const [skillServices, setSkillServices] = useState([])
  const [allProducts, setAllproducts] = useState([])
  const [requestTeam, setRequestTeam] = useState([])
  const [openPreview, setOpenPreview] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [avatarUrl, setAvatarUrl] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const[workflow,setWorkFlow]= useState([]);
  const [projectCategories, setProjectCategories] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [locationStates, setLocationStates] = useState({});
  const [address, setAddress] = useState({23:[{'0':{address:'addd',city:'fsdasadf',state:'fsd',zip:1654}}]}); // 23:[{address:'sdfsdf',city:'fsdasadf',state:'fsd',zip:1654}]
  const [progress,setProgress] = useState(null)
  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));
  let user = JSON.parse(localStorage.getItem('user'))
  const userType=user.user_type
  const refCommunities = useRef(null);
  const [communitiesOpen, setCommunitiesOpen] = useState(false);
  const [communitiesData, setCommunitiesData] = useState([]);
  const [filterCommunity, setFilterCommunity] = useState([]);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [loading, setLoading] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [dueData,setDueData] = useState(true)
  const [filterDateValue, setFilterDateValue] = useState([null, null])
  const refSort = useRef(null)
  const [sort, setSort] = useState(SORT[0])
  const [sortOpen, setSortOpen] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const refClients = useRef(null)
  const [clientsOpen, setClientsOpen] = useState(false)
  const [clientData, setClientData] = useState([])
  const [filterClient, setFilterClient] = useState([])

  // const [expandedCommunities, setExpandedCommunities] = useState({});

  // const handleExpandToggle = (community) => {
  //   setExpandedCommunities(prevState => ({
  //     ...prevState,
  //     [community]: !prevState[community]
  //   }));
  // };

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    // address: Yup.string().required('Address is required'),
    // city: Yup.string().required('City is required'),
    // state: Yup.string().required('State is required'),
    // zip: Yup.string().required('Zip/Postal Code is required'),
    skills:userType!=3 && Yup.array().min(1, 'Skill is required'),
    workflows:userType!=3 && Yup.array().min(1, 'workflow is required'),
    start: Yup.mixed().required('Start Date is required'),
    end: Yup.date()
      .nullable()
      .when('start', (start, schema) => {
        return start
          ? schema.min(
            start,
            'Installation Date must be greater than the Start Date'
          )
          : schema;
      }),
    products:userType!=3 && Yup.array().min(1, 'At least one product is required'), 
    project_type:userType!=3 && Yup.object().nullable().required('Job Class is required'),
    project_categories:userType!=3 && Yup.object().nullable().required('Job Types is required'),
    community:userType!=3 && Yup.object().nullable().required('Project is required'),
  });
  //
  const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    //   marginBottom: theme.spacing(1)
  }));
  const LabelStyleHead = styled(Typography)(({ theme }) => ({
    ...theme.typography.h5,
    color: theme.palette.text.secondary,
  }))

  //get requests
  const fetchRequestByCommunity = async () => {
    setLoading(true)
    try{
        let user = JSON.parse(localStorage.getItem('user'))
        const userType=user.user_type
        const userId=user.user_id
              const formattedFrom = filterDateValue[0]
                ? moment(filterDateValue[0]).format('YYYY-MM-DD HH:mm:ss')
                : null
              const formattedTo = filterDateValue[1]
                ? moment(filterDateValue[1]).format('YYYY-MM-DD HH:mm:ss')
                : null
        
              const values = {
                communityId:communityId,
                userType: userType,
                userId: userId,
                page: page + 1,
                rowsPerPage,
                search: searching,
                sort: sort.id,
                from: formattedFrom,
                to: formattedTo,
                dueData:dueData,
                filterClient:filterClient
              }
        // const values = {
        // userType: userType, userId: userId
        // }
        await axios.post(`${REST_API}webservice/rfq/get-design-by-community`,values)
        .then((res) => {
            res.data.data
            setAllRequest(res.data.data)
            setRequestTeam(res.data.requestTeam)
            setSkillServices(res.data.services)
            setWorkFlow(res.data.workflows)
            setAllproducts(res.data.products)
            if (res?.data?.totalCount) {
              setTotalCount(res?.data?.totalCount)
              } else {
              setTotalCount(0)
            }
            const othersClient = { id: 'other', name: 'Others' };
            if (res.data?.clients) {
              setClientData([...res.data.clients, othersClient]);
            } else {
              setClientData([othersClient]);
            }
            const othersCommunity = { id: 'other', name: 'Others' };
            if(res.data?.community) {
            setCommunitiesData([...res.data.community, othersCommunity]);
            } else {
            setCommunitiesData([othersCommunity]);
            }
        })
        .catch((errors) => {
            console.log(errors, 'error in fetching all designs')
        })
    }
    catch(error){
        console.log("Error fetching",error);
    }
    finally{
        setLoading(false)
    }
  }
  useEffect(() => {
    fetchRequestByCommunity()
  }, [open,searching,communityId,page, rowsPerPage, sort, filterDateValue, dueData,filterClient])

  useEffect(() => {
    try {
      axios.post(`${REST_API_END_POINT}project/get-data-for-add-project`,{
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if(response.data.status === 1){
          setProjectTypes(response.data.projectTypes)
          setProjectCategories(response.data.projectCategories)
          setCommunities(response.data.community)
        }
      }).catch((error) => {
        console.error(error);
      })
      }
      catch (error) {
        console.error("Error fetching data:", error);
      }
  },[])
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleByDue =()=>{
    setDueData(!dueData)
  }
  const handleChangeSort = (category) => {
    setSort(category)
    setSortOpen(false)
  }
  const handleProduct = (value) => {
    const productsWithQuantity = value.map((product) => ({
      ...product,
      quantity: selectedProducts.filter((p) => p.id === product.id)[0]?.quantity || 1,
      addresses: [...Array(Number(selectedProducts.filter((p) => p.id === product.id)[0]?.quantity) || 1)].map((_, i) => ({
        id: i,
        address: '',
        city: '',
        state: '',
        zip: '',
      })),
      
    }));
    // console.log('*****',productsWithQuantity);
    setSelectedProducts(productsWithQuantity);
  };
  
  // console.log(selectedProducts);
  //

  const calculateTotal = (price, quantity, discount) => {
    const parsedQuantity = parseFloat(quantity) || 1;
    const parsedDiscount = parseFloat(discount) || 0;
    const subtotal = price * parsedQuantity;
    if (discount) {
      const totalDiscount = subtotal - discount;
      return totalDiscount;
    } else {
      return subtotal;
    }
  };
  const handleDeleteProduct = (productId) => {
    const filteredProduct = selectedProducts.filter(data => data.id !== productId);
    setFieldValue('products', filteredProduct);
    setSelectedProducts(filteredProduct);

    // Remove the corresponding location state when a product is deleted
    const newLocationStates = { ...locationStates };
    delete newLocationStates[productId];
    setLocationStates(newLocationStates);
  };
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  function handleToggleClient(id) {
    setFilterClient((prevIds) => {
      if (!prevIds.includes(id)) {
        return [...prevIds, id]
      }
      return prevIds.filter((oldId) => oldId !== id)
    })
  }
  
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

  function applySortFilter(array, comparator, name, community) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    if (name && Array.isArray(array)) {
      array = array.filter((_user) => _user?.name?.toLowerCase().includes(name.toLowerCase()));
  }
  

    // Filter based on the community parameter
    if (community && community?.length > 0 ){
      if (community?.includes('other')) {
        array = array?.filter((item) => item?.community === null || community?.includes(item?.community));
      } else {
        array = array?.filter((item) => community?.includes(item?.community));
      }
    }
    return array;
  }


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      description: '',
      skills: [],
      workflows:[],
      start: currentDate,
      end: currentDate,
      // avatarUrl: [],
      products: [],
      invoice_discount: null,
      welcome_message: null,
      draft: null,
      total:0,
      subject:null,
      email_content:null,
      project_categories:null,
      project_type:null,
      community:null,
      // address:null,
      // city:null,
      // state:null,
      // zip:null,
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        let user = JSON.parse(localStorage.getItem('user'));
        const userType = user.user_type;
        const userId = user.user_id;
        const userName = user.first_name;
        values.avatarUrl = avatarUrl
        values.products = selectedProducts
        const requestData = {
          values,
          userId,
          userType,
          userName,
          address: selectedProducts.reduce((acc, product) => {
            return [
              ...acc,
              ...product.addresses.map((address) => ({
                ...address,
                productId: product.id,
              })),
            ];
          }, []),
        };

        const isvalidationNotOk = selectedProducts.find(product=>{
          if(product.addresses.length && product.addresses.some(obj =>
            Object.values(obj).some(value => value === '')
          )){
            return true
          }else{
            return false
          }
        })
        if(isvalidationNotOk){
          enqueueSnackbar('Please fill all the address fields', { variant: 'error' });
        }else{
    
        await axios.post(`${REST_API}webservice/request/add-request`, requestData)
          .then((res) => {
            setOpen(false);
            resetForm();
            setSelectedProducts([]);
            setAvatarUrl([])
            enqueueSnackbar('Create success', { variant: 'success' });
          })
          .catch((errors) => {
            console.log(errors, 'error');
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
    
  });
  let totalAmount = 0;
  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;
   useEffect(()=>{
     setPreview(values)
   },[openPreview])
  const selectedProductsTotalDiscount = selectedProducts?.reduce((total, data, index) => {
    const quantityFieldName = `quantity_${index}`;
    const discountFieldName = `discount_${index}`;
    const quantityVal = values[quantityFieldName]?values[quantityFieldName]:1
    const discountVal = values[discountFieldName]?values[discountFieldName]:0

    const productTotal = calculateTotal(data.price, quantityVal, discountVal);
  
    return total + productTotal;
  }, 0);
  const totalsum = selectedProductsTotalDiscount - parseFloat(values['invoice_discount'] || 0); 
  values['total'] = totalsum
  //discount count
  const selectedProductDiscount = selectedProducts?.reduce((acc, data, index) => {
    const discountFieldName = `discount_${index}`;
    const discountVal = values[discountFieldName]?values[discountFieldName]:0
    let productDiscount = +discountVal || 0;  // Ensure that productDiscount is a number
    return acc + productDiscount;
  }, 0);
  
  const DiscountTotal = selectedProductDiscount + (parseFloat(values['invoice_discount']) || 0);
  
  const handleDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const uploadPromises = acceptedFiles?.map((file) => {
          return new Promise((resolve, reject) => {
            const Filename = new Date().getTime() + file.name;
            const StorageRef = ref(storage, Filename);
            const uploadTask = uploadBytesResumable(StorageRef, file);
  
            uploadTask.on(
              'state_changed',
              (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgress(progress);
              },
              (error) => {
                console.error(error.message);
                reject(error);
              },
              () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                  resolve(downloadURL);
                });
              }
            );
          });
        });
  
        Promise.all(uploadPromises)
          .then((downloadURLs) => {
            // console.log(downloadURLs, '&&&&&&&&&&&&&&&&&&&');
            setAvatarUrl((prevAvatarUrls) => {
              // console.log({prevAvatarUrls});
              return [...prevAvatarUrls,...downloadURLs]
            });
          })
          .catch((error) => {
            console.error(error.message);
          });
      }
    },
    [setFieldValue]
  );
  
  const handleRemoveAll = () => {
    setAvatarUrl([]);
  };
//  console.log({avatarUrl},'--------avt')
 const handleRemove = (file) => {
  // console.log(file, '--------file');
  const filteredItems = avatarUrl.filter((_file) => _file !== file);
  setAvatarUrl(filteredItems);
};

const filteredUsers = allRequest?.length
  ? applySortFilter(allRequest, getComparator(order, orderBy), filterName)
  : [];

const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredUsers?.length) : 0;
const isUserNotFound = !filteredUsers || filteredUsers?.length === 0;


  return (
    <Page title="Project - Design Order| Sign Wise Solutions">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
        <Button
          variant="outlined"
          type="submit"
          onClick={()=>{ window.history.back() }}
          sx={{mr: 2,mb:5 }}
          startIcon={
            <Stack>
              <KeyboardBackspaceOutlined/>
            </Stack>
          }
        >
          Back
        </Button>
          <HeaderBreadcrumbs
            heading={communityName ? `${communityName} - Design Orders ` :"Design Orders"}
          />
        </Stack>
        <Stack direction={'row'} justifyContent={'end'} mb={'2'} spacing={{ xs: 3, sm: 2 }} sx={{marginRight:'20px'}}>
          {!user.isVendor && user.user_type !==3 && (
            <Button
              ref={refClients}
              onClick={() =>
                setClientsOpen((prevState) => !prevState)
              }
              variant="outlined"
              type="submit"
              sx={{ alignItems: 'flex-end' }}
              endIcon={
                <Stack>
                  {clientsOpen ? (
                    <ExpandLess sx={{ ml: 'auto' }} />
                  ) : (
                    <ExpandMore sx={{ ml: 'auto' }} />
                  )}
                </Stack>
              }
            >
              Filter By Client
            </Button>
          )}
          <Menu
            open={Boolean(clientsOpen)}
            anchorEl={refClients.current}
            onClose={() => setClientsOpen(false)}
            PaperProps={{
              sx: { width: 'auto', maxWidth: '100%' },
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <List style={{ maxHeight: '250px', overflowY: 'auto' }}>
              {clientData
                ?.filter(type => type.id && projectsClientsArray.includes(String(type.id)))
                .map((type) => (
                  <MenuItem key={type.id}>
                    {type.id === 'other' ? (
                      <ListItemText primary={`${type.name}`} />
                    ) : (
                      <ListItemText primary={`${type.name}`} />
                    )}
                    <Checkbox
                      checked={filterClient.includes(type.id)}
                      onChange={() => {
                        setPage(0);
                        handleToggleClient(type.id);
                      }}
                    />
                  </MenuItem>
                ))}
            </List>

            {/* <List style={{ maxHeight: '250px', overflowY: 'auto' }}>
              {clientData?.map((type) => (
                <MenuItem key={type.id}>
                  {type.id === 'other' ? (
                    <ListItemText primary={`${type.name}`} />
                  ) : (
                    <ListItemText
                      // primary={`${type.name} (${type.no_of_requests})`}
                      primary={`${type.name}`}
                    />
                  )}
                  <Checkbox
                    checked={filterClient.includes(type.id)}
                    onChange={() => {
                      setPage(0)
                      handleToggleClient(type.id)
                    }}
                  />
                </MenuItem>
              ))}
            </List> */}
          </Menu>
        </Stack>
          {/* <Stack mb={5} direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 3, sm: 2 }} alignItems="center"
            justifyContent="space-between">
            
               <OfficialRequestSearch
                searching={searching}
                setSearching={setSearching}
                setAllRequest={setAllRequest}/>

        <ProductToolbar 
            filterName={filterName} 
            onFilterName={handleFilterByName} 
            item="Designs"
        />
          </Stack> */}
          <Stack
              mb={5}
              mt={2}
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 3, sm: 2 }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
              <OfficialRequestSearch
                searching={searching}
                setSearching={setSearching}
                setAllRequest={setAllRequest}
              />
              </Box>
              <Stack flexDirection={'row'} gap={2}>
              <Button variant='outlined' 
                sx={{ height:'40px',backgroundColor : dueData ? '#1ccaff' : '',color : dueData ? "#fff" : '' }} 
                onClick={handleByDue} endIcon={<Stack> {dueData ? <Visibility /> : <VisibilityOff />} </Stack>}>
                By Due
              </Button>
                <MobileDateRangePicker
                  startText="Filter Date"
                  endText="Filter Date"
                  value={filterDateValue}
                  onChange={(newValue) => {
                    setFilterDateValue(newValue)
                  }}
                  renderInput={(startProps, endProps) => (
                    <>
                      <TextField
                        {...startProps}
                        sx={{ width: 160 }}
                        helperText="Filter By Due Date"
                        label="From Date"
                        size="small"
                        InputLabelProps={{
                          style: {
                            color: theme.palette.primary.main,
                            borderColor: theme.palette.primary.main,
                          },
                        }}
                      />
                      <Box sx={{ mx: 1, color: theme.palette.primary.main }}>
                        {' '}
                        -{' '}
                      </Box>
                      {/* <Box sx={{ mx: 2 }}> to </Box> */}
                      <TextField
                        {...endProps}
                        sx={{ width: 150 }}
                        helperText=""
                        label="To Date"
                        size="small"
                        InputLabelProps={{
                          style: {
                            color: theme.palette.primary.main,
                            borderColor: theme.palette.primary.main,
                          },
                        }}
                      />
                    </>
                  )}
                />

<Stack>
                  <Button
                    ref={refSort}
                    onClick={() => setSortOpen((prevState) => !prevState)}
                    variant="outlined"
                    disabled={dueData}
                    type="button"
                    endIcon={
                      <Stack>
                        {sortOpen ? (
                          <ExpandLess sx={{ ml: 'auto' }} />
                        ) : (
                          <ExpandMore sx={{ ml: 'auto' }} />
                        )}
                      </Stack>
                    }
                  >
                    {sort.label}
                  </Button>
                  {dueData && (
                    <FormHelperText sx={{ color: 'error.main', fontSize: '0.65rem', mt: 0.5 }}>
                      Disable "By Due" to enable this.
                    </FormHelperText>
                  )}
                  <Menu
                    open={Boolean(sortOpen)}
                    anchorEl={refSort.current}
                    onClose={() => setSortOpen(false)}
                    PaperProps={{
                      sx: {
                        width: 'auto',
                        maxWidth: '100%',
                        maxHeight: '250px',
                      },
                    }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  >
                    {SORT.map((category) => (
                      <MenuItem
                        key={category.id}
                        onClick={() => handleChangeSort(category)}
                      >
                        <ListItemText primary={category.label} />
                      </MenuItem>
                    ))}
                  </Menu>
                </Stack>

                <Stack></Stack>
              </Stack>
            </Stack>
             {filterClient?.length > 0 && (
                <>
                  <Button
                    variant="outlined"
                    sx={{
                      color: 'white',
                      borderColor: 'primary.main',
                      pointerEvents: 'none',
                      userSelect: 'text',
                    }}
                  >
                    <span
                      style={{
                        color: theme.palette.primary.darker,
                        marginRight: 8,
                      }}
                    >
                      Selected Clients{' '}
                    </span>
                    {clientData
                      .filter((client) =>
                        filterClient?.includes(client.id),
                      )
                      .map((client) => client.name)
                      .join(', ')}
                  </Button>
                </>
              )}
          <Scrollbar>
            {loading ? (
                <Stack alignItems="center" justifyContent="center" style={{ height: '100%', width: '100%' }}>
                <CircularProgress color="primary" variant="indeterminate" />
                </Stack>
            ) : (
                <>
                <Grid container spacing={3} sx={{mt:2}}>
                    {filteredUsers?.map((row) => {
                    const team_member = requestTeam?.length > 0 && requestTeam?.filter(member => member.request_id == row.id) || [];
                    // console.log("row-----------------------",row);
                    return (
                      <Grid key={row.id} item xs={12} sm={6} md={4}>
                        <OfficialCard card={row} team_member={team_member} key={row.id} communityId={communityId} designOrder={true} communityDesignOrder={true}/>
                      </Grid>
                    );
                    })}
                </Grid>
                {/* Display SearchNotFound only when filteredUsers is empty */}
                {emptyRows > 0 && filteredUsers?.length <= 0 && (
                <Card>
                  <Grid item xs={12} style={{ minHeight: 100 }}>
                  </Grid>
                </Card>
                )}

                {isUserNotFound && (
                    <Card sx={{ py: 3}}>
                    <Grid item xs={12} sx={{ py: 3 }} alignItems='center' justifyContent='center'>
                        <SearchNotFound searchQuery={filterName} msg="Design Orders" />
                    </Grid>
                    </Card>
                )}
                </>
            )}
            </Scrollbar>

          { filteredUsers?.length > 0 && 
          <TablePagination
          rowsPerPageOptions={[6, 12, 27]}
          component="div"
          count={totalCount || filteredUsers?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        
          // <TablePagination
          //   rowsPerPageOptions={[6, 12, 27]}
          //   component="div"
          //   count={filteredUsers ? filteredUsers?.length : 0}
          //   rowsPerPage={rowsPerPage}
          //   page={page}
          //   onPageChange={handleChangePage}
          //   onRowsPerPageChange={handleChangeRowsPerPage}
          // />
          }
      </Container>

    </Page>
  )
}