import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill'
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill'
// material
import { experimentalStyled as styled } from '@material-ui/core/styles'
import {
  Box,
  List,
  Avatar,
  Button,
  Collapse,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  Stack,
  TextField,
  Container,
  IconButton,
  InputAdornment,
} from '@material-ui/core'
//
import BadgeStatus from 'src/components/BadgeStatus'
import Scrollbar from 'src/components/Scrollbar'
import ChatRoomPopupSignWise from './ChatRoomPopupSignWise'
import { useEffect, useState } from 'react'
import { REST_API_END_POINT } from 'src/constants/Defaultvalues'
import { useSnackbar } from 'notistack'
import { Check, Send } from '@material-ui/icons'
import axios from 'axios'

// ----------------------------------------------------------------------

const HEIGHT = 64

const CollapseButtonStyle = styled(Button)(({ theme }) => ({
  ...theme.typography.overline,
  height: 40,
  borderRadius: 0,
  padding: theme.spacing(1, 2),
  justifyContent: 'space-between',
  color: theme.palette.text.disabled,
}))

// ----------------------------------------------------------------------

Participant.propTypes = {
  participant: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  onClosePopup: PropTypes.func,
  onShowPopup: PropTypes.func,
}

function Participant({ participant, isOpen, onClosePopup, onShowPopup, isAdmin, onRemoveMember }) {
  const { first_name, profile_pic, status, position } = participant

  const handleRemoveMember = () => {
    onRemoveMember(participant);
    onShowPopup();
  }

  return (
    <>
      <ListItem
        button
        disableGutters
        onClick={handleRemoveMember}
        sx={{ height: HEIGHT, px: 2.5 }}
      >
        <ListItemAvatar>
          <Box sx={{ position: 'relative', width: 40, height: 40 }}>
            <Avatar alt={first_name} src={profile_pic} />
            <BadgeStatus
              status={status}
              sx={{ right: 0, bottom: 0, position: 'absolute' }}
            />
          </Box>
        </ListItemAvatar>
        <ListItemText
          primary={first_name}
          secondary={position}
          primaryTypographyProps={{ variant: 'subtitle2', noWrap: true }}
          secondaryTypographyProps={{ noWrap: true }}
        />
      </ListItem>
      {/* <ChatRoomPopupSignWise participant={participant} isOpen={isOpen} onClose={onClosePopup} /> */}
    </>
  )
}

GroupChatSettings.propTypes = {
  participants: PropTypes.array.isRequired,
  selectUserId: PropTypes.string,
  onShowPopupUserInfo: PropTypes.func,
  isCollapse: PropTypes.bool,
  onCollapse: PropTypes.func,
}

export default function GroupChatSettings({
  participants,
  selectUserId,
  onShowPopupUserInfo,
  isCollapse,
  onCollapse,
  conversationId,
  initialGrpName,
  handleRefresh,
  setSelectedContact,
  setCompose,
  ...other
}) {
  console.log('participants+++', participants)
  const [isRemoveMembersOpen, setRemoveMembersOpen] = useState(false)
  const [isChangeGroupOpen, setIsChangeGroupOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [groupName, setGroupName] = useState(initialGrpName)
  const [isAdmin, setIsAdmin] = useState(false)
  let user = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    const filteredParticipants = participants?.filter(item => item.is_admin);
    const adminData = filteredParticipants?.find(participant => participant.user_id === user.user_id);
    setIsAdmin(!!adminData);
  }, [participants, user]);

  const handleRenameGroup = async () => {
    if (!groupName.trim()) {
      enqueueSnackbar('Group name cannot be empty', { variant: 'warning' })
      return
    }
    if (groupName.trim() === initialGrpName) {
      enqueueSnackbar('Please change the group name', { variant: 'warning' })
      return
    }
    try {
      const response = await axios.post(
        `${REST_API_END_POINT}chat/change-group-name`,
        {
          newGroupName: groupName,
          conversationId,
        },
      )
      let res = response?.data
      if (res.status === 1) {
        enqueueSnackbar(res.message, { variant: 'success' })
        // setGroupName('')
        setIsChangeGroupOpen(false)
        handleRefresh()
      } else {
        enqueueSnackbar(res.message, { variant: 'success' })
      }
    } catch (error) {
      console.error(error, 'error==========edit group name')
      enqueueSnackbar("Error: Something went wrong!", { variant: 'error' });
    }
  }

  const handleExitGroup = async () => {
    try {
      const response = await axios.post(`${REST_API_END_POINT}chat/exit-group`,
        { conversationId, userId:user.user_id })
      let res = response?.data
      if(res){
        enqueueSnackbar(res.message, { variant: res.status === 1 ? 'success' : 'error' });
        if(res.status === 1){
          handleRefresh();
          setCompose(true);
          setSelectedContact(null);
        }
      }
    } catch (error) {
      console.error('Error exiting group:', error);
      if (error.response) {
        enqueueSnackbar(error.response.data?.message || "Something went wrong!", { variant: 'error' });
      } else {
        enqueueSnackbar("Error: Something went wrong!", { variant: 'error' });
      }
    }
  }

  const handleRemoveMemberFromGroup = async (member) => {
    try {
      const response = await axios.post(`${REST_API_END_POINT}chat/remove-member-from-group`,
        { conversationId, userId: member?.user_id, fullName: member?.full_name })
      let res = response?.data
      if(res){
        enqueueSnackbar(res.message, { variant: res.status === 1 ? 'success' : 'error' });
        if(res.status === 1){
          handleRefresh();
        }
      }
    } catch (error) {
      console.error('Error removing member from group:', error);
      if (error.response) {
        enqueueSnackbar(error.response.data?.message || "Something went wrong!", { variant: 'error' });
      } else {
        enqueueSnackbar("Error: Something went wrong!", { variant: 'error' });
      }
    }
  }

  return (
    <Box {...other}>
      <CollapseButtonStyle
        fullWidth
        disableRipple
        color="inherit"
        onClick={onCollapse}
        endIcon={
          <Icon
            icon={isCollapse ? arrowIosDownwardFill : arrowIosForwardFill}
            width={16}
            height={16}
          />
        }
      >
        Group Settings
      </CollapseButtonStyle>

      <Box
        sx={{
          minHeight: isCollapse ? 30 * 4 : 0,
          maxHeight: isCollapse ? HEIGHT * 6 : 0,
        }}
      >
        <Scrollbar>
          <Collapse in={isCollapse}>
            <List disablePadding>
              {/* Change Group Name */}
              {isAdmin ?
                <>
                  <ListItem
                    button
                    disableGutters
                    sx={{ height: 30, px: 2.5 }}
                    //   onClick={handleRenameGroup}
                    onClick={() => setIsChangeGroupOpen(!isChangeGroupOpen)}
                  >
                    <Stack
                      className="hugeicons--edit-02"
                      style={{ marginRight: 8, color: '#637381' }}
                    />
                    <Typography variant="subtitle2" color={'text.secondary'}>
                      Change Group Name
                    </Typography>
                    <Icon
                      icon={
                        isChangeGroupOpen
                          ? arrowIosDownwardFill
                          : arrowIosForwardFill
                      }
                      width={16}
                      height={16}
                      style={{ marginLeft: 'auto' }}
                    />
                  </ListItem>
                  <Collapse in={isChangeGroupOpen} timeout="auto" unmountOnExit>
                    <Container sx={{ mt: 1, mb: 1 }}>
                      <Stack direction={'column'} spacing={1}>
                        <TextField
                          variant="standard"
                          size="small"
                          value={groupName}
                          onChange={(e) => setGroupName(e.target.value)}
                          fullWidth
                          label="Group name"
                          sx={{
                            '& .MuiInputBase-input': {
                              fontSize: '0.875rem', // Match Typography variant="subtitle2"
                            },
                            '& .MuiInputLabel-root': {
                              fontSize: '0.875rem', // Match label font size too
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button
                                  onClick={handleRenameGroup}
                                  sx={{
                                    minWidth: '32px',
                                    height: '32px',
                                    padding: "0", 
                                    // border: '1px dashed #2e434b',
                                    borderRadius: '4px',
                                    '&:hover': {
                                      backgroundColor: 'transparent',
                                      color: 'common.white',
                                    },
                                  }}
                                >
                                  <Send fontSize="small" />{' '}
                                  {/* Adjust icon size */}
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                    </Container>
                  </Collapse>
                </>
              : null}
              {/* Remove Members */}
              {isAdmin ?
                <>
                  <ListItem
                    button
                    disableGutters
                    sx={{ height: 30, px: 2.5 }}
                    onClick={() => setRemoveMembersOpen(!isRemoveMembersOpen)}
                  >
                    <Stack
                      className="mdi--account-remove-outline"
                      style={{ marginRight: 8, color: '#637381' }}
                    />
                    <Typography variant="subtitle2" color={'text.secondary'}>
                      Remove Members
                    </Typography>
                    <Icon
                      icon={
                        isRemoveMembersOpen
                          ? arrowIosDownwardFill
                          : arrowIosForwardFill
                      }
                      width={16}
                      height={16}
                      style={{ marginLeft: 'auto' }}
                    />
                  </ListItem>
                  <Collapse in={isRemoveMembersOpen} timeout="auto" unmountOnExit>
                    <Scrollbar sx={{ minHeight: 'fit-content', maxHeight: 200 }}>
                      <List disablePadding>
                        {participants?.filter((item) => item?.user_id != user?.user_id)?.map((participant) => (
                          <Participant
                            key={participant?.user_id}
                            participant={participant}
                            isOpen={selectUserId === participant?.user_id}
                            onShowPopup={() => onShowPopupUserInfo(participant?.user_id)}
                            onClosePopup={() => onShowPopupUserInfo(null)}
                            isAdmin={isAdmin}
                            onRemoveMember={handleRemoveMemberFromGroup}
                          />
                        ))}
                      </List>
                    </Scrollbar>
                  </Collapse>
                </>
              : null}
              {/* Exit Group */}
              <ListItem button disableGutters sx={{ height: 30, px: 2.5 }} onClick={()=>handleExitGroup()}>
                <Stack
                  className="ci--exit"
                  style={{ marginRight: 8, color: '#FF4842' }}
                />
                <Typography variant="subtitle2" color={'error.main'}>
                  Exit Group
                </Typography>
              </ListItem>
            </List>
          </Collapse>
        </Scrollbar>
      </Box>
    </Box>
  )
}
