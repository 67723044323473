import { useCallback, useEffect, useState } from 'react';
import Slider from 'react-slick';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Card, Stack, Button, Typography, Grid, Divider,Box, CardContent, Avatar, Icon, CardHeader, TextField, FormHelperText, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Autocomplete, Select, MenuItem, FormGroup, Checkbox } from '@material-ui/core';
import { alpha, useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import moment from 'moment'

import { LoadingButton, MobileDatePicker } from '@material-ui/lab';
import { mockImgProduct } from 'src/utils/mockImages.js';

// redux
import { useSelector } from '../../../../redux/store';
import Modal from 'src/components/_dashboard/blog/ProjectModel.js';

// utils
import fakeRequest from '../../../../utils/fakeRequest';
import { EcommerceNewProducts } from '../../general-ecommerce';
import { CarouselControlsPaging1 } from 'src/components/carousel/index.js';
import { sentenceCase } from 'change-case';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { MButton, MIconButton } from 'src/components/@material-extend';
import { Edit } from '@material-ui/icons';
import { UploadAvatar, UploadSingleFile } from 'src/components/upload';
import { fData } from 'src/utils/formatNumber';
import { storage } from 'src/firebase/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
import { useSnackbar } from 'notistack';
import { options } from 'numeral';


// ----------------------------------------------------------------------



// const CarouselImgStyle = styled('img')(({ theme }) => ({
//   width: '100%',
//   height: 280,
//   objectFit: 'cover',
//   [theme.breakpoints.up('xl')]: {
//     height: 320
//   }
// }));
// ----------------------------------------------------------------------
const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  //   marginBottom: theme.spacing(1)
}));
const LabelStyleHead = styled(Typography)(({ theme }) => ({
  ...theme.typography.h5,
  color: theme.palette.text.secondary,
}))
export default function AccountNotifications({
  bankInfo,
  portfolio,
  details,
  taxInfo,
  customTemplate,
  questionnaireTemplate,
  signAgreements,
  clientStateDetail,
  allDataEmpty,
  clientId,
  requestId,
  isRequest,
  isTeam,
  teamId,
  updatingFunction,
}) {
  const user = localStorage.getItem('user')
  const userData = JSON.parse(user)
  const [workFlow,setWorkflow] = useState('')
  const [itemEdit,setItemEdit] = useState({})
  const [openModal,setOpenModal] = useState(false)
  const [progress,setProgress] = useState(false)
  const [stageType,setStageType] = useState(Number)
  const theme = useTheme(); 
  const { enqueueSnackbar } = useSnackbar()
  const handleEditWorkFlow=(workflow,data,type)=>{
    setWorkflow(workflow)
    setItemEdit(data)
    setStageType(type)
    setOpenModal(true)
  }

  const NewUserSchema = Yup.object().shape({
    portfolio_title:workFlow === 'portfolio' && Yup.string().required('Title is required'),
    portfolio_description:workFlow === 'portfolio' && Yup.string().required('Description is required'),
  })

  const updatedWithFieldId = (data) => {
    if (!itemEdit.fields) return {};     
    const updatedData = Object.entries(data).reduce((acc, [key, value]) => {
      const matchingField = Object.values(itemEdit.fields).find(field => field.content === key);  
        if (matchingField) {
          if(isTeam){
            acc[key] = { field_id: matchingField.field_id, value,team_id:matchingField?.team_id };
          }else{
            acc[key] = { field_id: matchingField.field_id, value,partner_id:matchingField?.partnerId };
          }
        } else {
            acc[key] = value;
        }

        return acc;
    }, {});

    return updatedData;
};
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id:itemEdit.id||'',
      partner_id:isRequest && itemEdit?.partner_id,
      team_id:itemEdit?.team_id,
     portfolio_title:itemEdit.title||'',
     portfolio_description:itemEdit?.description||'',
     portfolio_image:itemEdit.file_name||'',
     ...(itemEdit.fields &&
      Object.entries(itemEdit.fields).reduce((acc, [key, value]) => {
       
        if (value.tag==='checkbox-group') {
          acc[value.content] = value.value.split(',').map(item => item.trim());
        }else{
          acc[value.content] = value.value
        }
        return acc;
      }, {})),
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        let updatedValues ={}
        if(stageType===4){
          updatedValues=values
        }else{
          updatedValues = updatedWithFieldId(values)
        } 
        if(isRequest){
          axios.post(`${REST_API_END_POINT}onboard/update-request-workflow`,{
            stageType,updatedValues,requestId,userId:userData.user_id
          })
          .then((res)=>{
            if(res.data.status===1){
              enqueueSnackbar(res.data.message,{variant:'success'})
              updatingFunction()
            }else{
              enqueueSnackbar(res.data.message,{variant:'error'})
            }
          })
          .catch((error)=>{
            console.log(error)
          })
        }else if(isTeam){
     
          axios.post(`${REST_API_END_POINT}onboard/update-data-team`,{
            stageType,updatedValues,userId:userData.user_id,teamId
          })
          .then((res)=>{
            if(res.data.status ===1){
              enqueueSnackbar(res.data.message,{variant:'success'})
              updatingFunction()
            }else{
              enqueueSnackbar(res.data.message,{variant:'error'})
            }
          })
          .catch((error)=>{
            console.log(error)
          })       
        }else{   
        axios.post(`${REST_API_END_POINT}onboard/update-data`,{
          stageType,updatedValues,userId:userData.user_id,clientId
        })
        .then((res)=>{
          if(res.data.status ===1){
            enqueueSnackbar(res.data.message,{variant:'success'})
            updatingFunction()
          }else{
            enqueueSnackbar(res.data.message,{variant:'error'})
          }
        })
        .catch((error)=>{
          console.log(error)
        })
        }
        setItemEdit({})
            setWorkflow('')
            setStageType(null)
        setOpenModal(false)
        resetForm();
      } catch (error) {
        console.log(error)
      }
    }
      
  })
  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;
  
  const handleDropPorfolio = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0]
      if(file){
        const Filename = new Date().getTime() + file.name;
  
        const StorageRef = ref(storage, Filename);
        const uploadTask = uploadBytesResumable(StorageRef, file);
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setProgress(true); 
          },
          (error) => {
            console.error(error.message);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setFieldValue('portfolio_image', downloadURL);
              setProgress(false)
            });
          }
        );
      }
    },
    [setFieldValue],
  )
  const handleDropFile = useCallback(
    async (acceptedFiles, field_id, content) => {
      try {
        if (acceptedFiles && acceptedFiles.length > 0) {
          const file = acceptedFiles[0]
          const filename = new Date().getTime() + file.name

          const storageRef = ref(storage, filename)
          const uploadTask = uploadBytesResumable(storageRef, file)

          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const newProgress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
              )
              setProgress(newProgress)
            },
            (error) => {
              console.error('Error during upload:', error)
            },
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
             
              setFieldValue(content,downloadURL)
            },
          )
        }
      } catch (error) {
        console.error('Unexpected error:', error)
      }
    },
    [setFieldValue],
  )
  return (
    <Card 
    sx={{ 
      p: 3, height:isRequest?"300px": '450px', overflowY: 'auto' ,
      '&::-webkit-scrollbar': {
        width: '0.4em',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
        borderRadius: '4px',
      },
      }}>

        {bankInfo && bankInfo.some(item => item.bank_name !== "null") ? (
          <Grid item md={12} sm={12} xs={12} >
            {bankInfo.map((item, index) => (
              <Stack key={index} spacing={3} alignItems="flex-start"  sx={{ mb: 2,mt:4 }}>
                <Stack spacing={2} sx={{ width: 1 }}>
                  
                <Typography variant="h5">
                  Bank Information
                  </Typography>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ mb: 5 }}
                    justifyContent={'space-between'}
                    spacing={1}
                  >
                    <Typography variant="subtitle2">Bank Name</Typography>
                    {item?.bank_name !== "null" ? (
                      <Typography variant="subtitle2">
                        <span style={{ color: '#1ccaff' }}>{item.bank_name}</span>
                      </Typography>
                    ) : null}
                  </Stack>

                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ mb: 5 }}
                    justifyContent={'space-between'}
                    spacing={1}
                  >
                    <Typography variant="subtitle2">Name on Account</Typography>
                    {item?.name_on_account !== "null" ? (
                      <Typography variant="subtitle2">
                        <span style={{ color: '#1ccaff' }}>{item.name_on_account}</span>
                      </Typography>
                    ) : null}             
                  </Stack>                  
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ mb: 5 }}
                    justifyContent={'space-between'}
                    spacing={1}
                  >
                    <Typography variant="subtitle2">Routing Number</Typography>
                    {item?.routing_number !== "null" ? (
                      <Typography variant="subtitle2">
                        <span style={{ color: '#1ccaff' }}>{item.routing_number}</span>
                      </Typography>
                    ) : null}                     
                  </Stack>                  
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ mb: 5 }}
                    justifyContent={'space-between'}
                    spacing={1}
                  >
                    <Typography variant="subtitle2">Account Number</Typography>
                    {item?.account_number !== "null" ? (
                      <Typography variant="subtitle2">
                        <span style={{ color: '#1ccaff' }}>{item.account_number}</span>
                      </Typography>
                    ) : null}                    
                  </Stack>                  
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ mb: 5 }}
                    justifyContent={'space-between'}
                    spacing={1}
                  >
                    <Typography variant="subtitle2">Account Type</Typography>
                    <Typography variant="subtitle2">
                      <span style={{ color: '#1ccaff' }}>{item?.account_type}</span>
                    </Typography>
                  </Stack>                  
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ mb: 5 }}
                    justifyContent={'space-between'}
                    spacing={1}
                  >
                    <Typography variant="subtitle2">Swift Code</Typography>
                    {item?.swift_code !== "null" ? (
                      <Typography variant="subtitle2">
                        <span style={{ color: '#1ccaff' }}>{item.swift_code}</span>
                      </Typography>
                    ) : null}                   
                  </Stack>                  
                </Stack>
              </Stack>
            ))}          
          </Grid>) : null}
          {bankInfo && bankInfo.some(item => item?.card_number) ? (
        <Grid item md={12} sm={12} sx={12}>
        {bankInfo?.map((item, index) => (
              <Stack key={index} spacing={3} alignItems="flex-start"  sx={{ mb: 2,mt:4 }}>
                <Stack spacing={2} sx={{ width: 1 }}>
                <Typography variant="h5">
                  Credit Card/Debit Card Information
                  </Typography>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ mb: 5 }}
                    justifyContent={'space-between'}
                    spacing={1}
                  >
                    <Typography variant="subtitle2">CC/DC Number</Typography>
                    <Typography variant="subtitle2">
                      <span style={{ color: '#1ccaff' }}>{item?.card_number!="null"?item.card_number:""}</span>
                    </Typography>
                  </Stack>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ mb: 5 }}
                    justifyContent={'space-between'}
                    spacing={1}
                  >
                    <Typography variant="subtitle2">Expiration Date</Typography>

                    <Typography variant="subtitle2">
                      {item?.card_number!="null"?<span style={{ color: '#1ccaff' }}>{moment.unix(item?.expiration_date).format('MM-DD-YYYY')}</span>:""}
                    </Typography>
                  </Stack>                  
                </Stack>
              </Stack>
            ))}      
             {bankInfo && bankInfo.length > 0 && (
            <Divider sx={{ mb: 5 }} />
          )}
          </Grid>) : null}

          
          
          {portfolio?<Grid item md={12} sm={12} xs={12} >
            {portfolio?.map((item, index) => (
              <Stack key={index} spacing={3} alignItems="flex-start" sx={{
                ...((isRequest&&!allDataEmpty) ? { border: '1px solid',
                borderColor: theme.palette.success.main,padding:2,mb:2}
                :{mb:2})        
              }}>
                <Stack spacing={2} sx={{ width: 1 }}>
               
                  <Stack sx={{display:'flex',justifyContent:'flex-start',flexDirection:'row'}}>
                <Typography variant="h5">
                    Portfolio
                  </Typography>
                  {userData.user_type===1&&(
                  <MIconButton size="small" onClick={()=>handleEditWorkFlow('portfolio',item,4)}>
                    <Edit sx={{color: theme.palette.primary.main, cursor:'pointer',}}/>
                  </MIconButton>
                  )}
                  </Stack> 
                  <Stack direction={'row'} justifyContent={'space-between'}>
                  <Box>             
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ mb: 5 }}
                    justifyContent={'flex-start'}
                    spacing={1}
                  >
                    <Typography variant="subtitle2">Title :</Typography>
                    <Typography variant="subtitle2">
                      <span style={{ color: '#1ccaff' }}>{item?.title}</span>
                    </Typography>
                  </Stack>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ mb: 5 }}
                    justifyContent={'flex-start'}
                    spacing={1}
                  >
                    <Typography variant="subtitle2">Description :</Typography>
                    <Typography variant="subtitle2">
                      <span style={{ color: '#1ccaff' }}>{item?.description}</span>
                    </Typography>
                  </Stack>
                  </Box>                
                  <Stack 
                  mt={-3}                                                
                    justifyContent={'flex-start'}                 
                  >
                    <Typography variant="subtitle2">Image</Typography>
                    <img
                        
                        src={item?.file_name}
                        alt={item?.title}
                        style={{
                            width: '100px',
                            height: '100px',
                            borderRadius: '5px', 
                            objectFit : 'fill'
                        }}  
                    />                 
                  </Stack>
                  </Stack> 
                </Stack>
              </Stack>
            ))}        
          </Grid>:""}
          
          
          {signAgreements ?<Grid item md={12} sm={12} sx={12}>
          
            {signAgreements && signAgreements?.map((item, index) => (
              <Stack key={index} spacing={3} alignItems="flex-start" sx={{
                ...((isRequest&&!allDataEmpty) ? { border: '1px solid',
                borderColor: theme.palette.success.main,padding:2,mb:2}
                :{mb:2})        
              }}>
                <Stack spacing={2} sx={{ width: 1 }}>
                <Typography variant="h5">
                    Sign Agreements
                  </Typography>
                  <Stack                  
                    sx={{ mb: 5 }}
                    direction={'row'}
                    justifyContent={'flex-start'}
                    spacing={1}
                  >
                    <Typography variant="subtitle2">Agreement name :</Typography>
                    <Typography variant="subtitle2">
                      <span style={{ color: '#1ccaff' }}>{item?.agreement_name}</span>
                    </Typography>
                  </Stack>
                 

                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ mb: 5 }}
                    justifyContent={'flex-start'}
                    spacing={1}
                  >
                    {/* <Typography variant="subtitle2">Content</Typography> */}
                    <Typography
                      variant="subtitle2"
                      sx={{
                        '& span': { color: '#1ccaff' },
                        overflowWrap: 'break-word', // or use 'word-wrap: break-word'
                        wordWrap: 'break-word',
                        marginLeft:'20px'
                      }}
                    >
                      <Stack dangerouslySetInnerHTML={{ __html: item?.content }} />
                    </Typography>
                  </Stack>



                  <Stack                 
                    sx={{ mb: 5 }}
                    justifyContent={'flex-start'}
                    spacing={1}
                  >
                    <Typography variant="subtitle2" color={'#1ccaff'}>Signature</Typography>
                    <img                       
                      src={item.file_name}
                      alt={'signature'}
                      style={{
                          width: '120px',
                          height: '120px',
                          borderRadius: '5px', 
                          objectFit : 'fill'
                      }}  
                    /> 
                   
                  </Stack>
                </Stack>
              </Stack>
            ))}
            

          </Grid>:""}
          
          {taxInfo?<Grid item md={12} sm={12} sx={12}>
          {taxInfo && taxInfo?.map((item, index) => (
              <Stack key={index} spacing={3} alignItems="flex-start" sx={{ mb: 2,mt:4 }}>
                <Stack spacing={2} sx={{ width: 1 }}>
                <Typography variant="h5">
                  Tax Information
                  </Typography>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ mb: 5 }}
                    justifyContent={'space-between'}
                    spacing={1}
                  >
                    <Typography variant="subtitle2">How do you report to your tax authority</Typography>
                    <Typography variant="subtitle2">
                          {item?.business_type !== "null" ? (
                              <span style={{ color: '#1ccaff' }}>{item?.business_type == 1 ? ' Yes' : ' No'}</span>
                          ) : null}
                      </Typography>                                  
                  </Stack>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ mb: 5 }}
                    justifyContent={'space-between'}
                    spacing={1}
                  >
                    <Typography variant="subtitle2">TAX/VAT Number</Typography>
                    <Typography variant="subtitle2">
                          {item?.social_security_number !== "null" ? (
                              <span style={{ color: '#1ccaff' }}>{item?.social_security_number}</span>
                          ) : null}
                      </Typography>
                    
                    
                  </Stack>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ mb: 5 }}
                    justifyContent={'space-between'}
                    spacing={1}
                  >
                    <Typography variant="subtitle2">First Name</Typography>
                    <Typography variant="subtitle2">
                          {item?.first_name !== "null" ? (
                              <span style={{ color: '#1ccaff' }}>{sentenceCase(item?.first_name)}</span>
                          ) : null}
                      </Typography>
                    
                    
                  </Stack>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ mb: 5 }}
                    justifyContent={'space-between'}
                    spacing={1}
                  >
                    <Typography variant="subtitle2">Middle Name</Typography>
                      <Typography variant="subtitle2">
                          {item?.middle_name !== "null" ? (
                              <span style={{ color: '#1ccaff' }}>{sentenceCase(item?.middle_name)}</span>
                          ) : null}
                      </Typography>                                 
                  </Stack>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ mb: 5 }}
                    justifyContent={'space-between'}
                    spacing={1}
                  >
                    <Typography variant="subtitle2">Last Name</Typography>
                    <Typography variant="subtitle2">
                          {item?.last_name !== "null" ? (
                              <span style={{ color: '#1ccaff' }}>{sentenceCase(item?.last_name)}</span>
                          ) : null}
                      </Typography>
                                       
                  </Stack>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ mb: 5 }}
                    justifyContent={'space-between'}
                    spacing={1}
                  >
                    <Typography variant="subtitle2">Address</Typography>
                    <Typography variant="subtitle2">
                          {item?.address !== "null" ? (
                              <span style={{ color: '#1ccaff' }}>{item?.address}</span>
                          ) : null}
                      </Typography>
                    
                  </Stack>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ mb: 5 }}
                    justifyContent={'space-between'}
                    spacing={1}
                  >
                    <Typography variant="subtitle2">City</Typography>
                    <Typography variant="subtitle2">
                          {item?.city !== "null" ? (
                              <span style={{ color: '#1ccaff' }}>{item?.city}</span>
                          ) : null}
                      </Typography>
                    
                    
                  </Stack>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ mb: 5 }}
                    justifyContent={'space-between'}
                    spacing={1}
                  >
                    <Typography variant="subtitle2">State</Typography>
                    <Typography variant="subtitle2">
                          {clientStateDetail[0]?.name !== "null" ? (
                              <span style={{ color: '#1ccaff' }}>{clientStateDetail[0]?.name}</span>
                          ) : null}
                      </Typography>
                    
                    
                  </Stack>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ mb: 5 }}
                    justifyContent={'space-between'}
                    spacing={1}
                  >
                    <Typography variant="subtitle2">Zip Code</Typography>
                    <Typography variant="subtitle2">
                          {item?.zipcode !== "null" ? (
                              <span style={{ color: '#1ccaff' }}>{item?.zipcode}</span>
                          ) : null}
                      </Typography>
                    
                    
                  </Stack>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ mb: 5 }}
                    justifyContent={'space-between'}
                    spacing={1}
                  >
                    <Typography variant="subtitle2">Federal Tax Classification</Typography>
                    <Typography variant="subtitle2">
                          {item?.tax_classification !== "null" ? (
                              <span style={{ color: '#1ccaff' }}>{item?.tax_classification ==1 ?'Yes':'No'}</span>
                          ) : null}
                      </Typography>
                                       
                  </Stack>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ mb: 5 }}
                    justifyContent={'space-between'}
                    spacing={1}
                  >
                    <Typography variant="subtitle2">Do you have a business name or disregarded entity name that is different than the name that you provided above, such as a "doing business as (DBA)" name?</Typography>
                    <Typography variant="subtitle2">
                          {item?.business_type !== "null" ? (
                              <span style={{ color: '#1ccaff' }}>{item?.business_type == 1 ? ' Yes' : ' No'}</span>
                          ) : null}
                      </Typography>
                    
                    
                  </Stack>
                  
                </Stack>
              </Stack>
            ))}   
            {taxInfo && taxInfo.length > 0 && (
            <Divider sx={{ mb: 5 }} />
          )}      
          </Grid>:""}

          {customTemplate && customTemplate.length > 0 && (
          <Grid item md={12} sm={12} sx={12}>
            {customTemplate.map((template, index) => (
             
              <Stack key={index} spacing={3} alignItems="flex-start" sx={{
                ...((isRequest&&!allDataEmpty) ? { border: '1px solid',
                borderColor: theme.palette.success.main,padding:2,my:2}
                :{my:2})        
              }}>
                <Stack spacing={2} sx={{ width: 1 }}>
                <Stack sx={{display:'flex',justifyContent:'flex-start',flexDirection:'row'}}>
                  <Typography variant="h5">
                    {template?.template_name}  
                  </Typography>
                  {userData.user_type===1&&(
                  <MIconButton size="small" onClick={()=>handleEditWorkFlow('',template,1)}>
                    <Edit sx={{color: theme.palette.primary.main, cursor:'pointer',}}/>
                   </MIconButton>
                   )}
                   </Stack>
                  {template?.fields && Object.entries(template?.fields)?.map(
                    ([key, values]) => {
                      const { tag, config, content, field_id, id, value } = values;
                      return (
                        <RendererComponents
                          key={id} // Assuming id is unique
                          tag={tag}
                          config={config}
                          content={content}
                          field_id={field_id}
                          value={value}
                          id={id}
                        />
                      );
                    }
                  )}
                </Stack>
              </Stack>
              
            ))}
           
          </Grid>
        )}

        {questionnaireTemplate && questionnaireTemplate.length > 0 && (
          <Grid item md={12} sm={12} sx={12}>
            {questionnaireTemplate.map((template, index) => (
              <Stack key={index} spacing={3} alignItems="flex-start" sx={{
                ...((isRequest&&!allDataEmpty) ? { border: '1px solid',
                borderColor: theme.palette.success.main,padding:2,my:2}
                :{my:2})        
              }}>
                <Stack spacing={2} sx={{ width: 1 }}>
                <Stack sx={{display:'flex',justifyContent:'flex-start',flexDirection:'row'}}>
                  <Typography variant="h5">
                    {template?.template_name}
                  </Typography>
                  {userData.user_type===1&&(
                  <MIconButton size="small" onClick={()=>handleEditWorkFlow('',template,5)}>
                    <Edit sx={{color: theme.palette.primary.main, cursor:'pointer',}}/>
                   </MIconButton>
                   )}
                   </Stack>
                  {template?.fields && Object.entries(template?.fields)?.map(
                    ([key, values]) => {
                      const { tag, config, content, field_id, id, value } = values;
                      return (
                        <RendererComponents
                          key={id} // Assuming id is unique
                          tag={tag}
                          config={config}
                          content={content}
                          field_id={field_id}
                          value={value}
                          id={id}
                        />
                      );
                    }
                  )}
                </Stack>
              </Stack>            
            ))}
           
          </Grid>
        )}

          { 
           allDataEmpty && (
              // <Grid item md={10} sm={10} sx={12}>
              <Typography variant='subtitle1' textAlign={'center'}>No Information Available</Typography>
              // </Grid>
            )
          }
         {/* data editing Modal */}
        <Modal open={openModal} 
        modalTitle={workFlow ?sentenceCase(workFlow):itemEdit.template_name}
         handleClose={()=>{
          setItemEdit({})
          setWorkflow('')
          setStageType(null)
          setOpenModal(false)}}>
        <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container spacing={3}>

                <Grid item xs={12} md={12}>
                  <Card sx={{ p: 3 }}> 
                  <Stack spacing={3}>
                  {workFlow ==='portfolio'?(
                    <>        
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <Stack flexDirection={'column'}>                        
                          <TextField                          
                            sx={{ width: 400 }}
                            label="Title"
                            {...getFieldProps('portfolio_title')}
                            error={Boolean(touched.portfolio_title && errors.portfolio_title)}
                            helperText={touched.portfolio_title && errors.portfolio_title}
                          />
                        </Stack>
                      </Stack>
                      <LabelStyle>Description</LabelStyle>
                      <TextField 
                        fullWidth
                        multiline
                        minRows={3}
                        maxRows={7}
                        label="Description"
                        {...getFieldProps('portfolio_description')}
                        error={Boolean(touched.portfolio_description && errors.portfolio_description)}
                        helperText={touched.portfolio_description && errors.portfolio_description}
                      />
                      <Stack direction={{ xs: 'column', sm: 'column',md:'column',lg:'column',xl:'column' }}
                       spacing={{ xs: 3, sm: 2, }} justifyContent={'flex-start'}>
                        <LabelStyle>Upload Photo</LabelStyle>
                      <UploadAvatar
                        accept="image/*"
                         file={values.portfolio_image}
                        maxSize={3145728}
                         onDrop={handleDropPorfolio}
                        error={Boolean(touched.portfolio_image && errors.portfolio_image)}
                        caption={
                          <Typography
                            variant="caption"
                            sx={{
                              mt: 2,
                              mx: 'auto',
                              display: 'block',                   
                              color: 'text.secondary'
                            }}
                          >
                            Allowed *.jpeg, *.jpg, *.png, *.gif
                            <br /> max size of {fData(3145728)}
                          </Typography>
                        }
                      />
                      <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                        {touched.portfolio_image && errors.portfolio_image}
                      </FormHelperText> 
                      </Stack>
                      </>    
                      ):(
                        <>                    
                         {itemEdit?.fields
                          ? Object.entries(itemEdit?.fields).map(
                              ([key, value]) => {                                                       
                                const {
                                  tag,
                                  config,
                                  content,
                                  field_id,
                                  required,
                                } = value
                                switch (tag) {                                                  
                                  case 'textarea':
                                     return (                                    
                                      <TextField
                                      key={value.id}
                                      label={<div 
                                        style={{ whiteSpace: 'pre-wrap' }}
                                         dangerouslySetInnerHTML={{ __html: content }} />}                                     
                                      fullWidth
                                      id={'text'}                                                                        
                                       value={values[content]}
                                      onChange={(event) =>
                                        setFieldValue(content,event.target.value)
                                      }                                    
                                      sx={{
                                        width: '100%',
                                        marginTop: '8px',
                                        padding: '8px',
                                      }}
                                    />
                                     )
                                  case 'text':
                                     return (
                                       <TextField
                                        key={value.id}
                                        label={<div 
                                          style={{ whiteSpace: 'pre-wrap' }}
                                           dangerouslySetInnerHTML={{ __html: content }} />}                                     
                                        fullWidth
                                        id={'text'}                                      
                                         value={values[content]}
                                        onChange={(event) =>
                                          setFieldValue(content,event.target.value)
                                        }
                                        sx={{
                                          width: '100%',
                                          marginTop: '8px',
                                          padding: '8px',
                                        }}
                                      />
                                     )
                                  case 'date':
                                    return (
                                      <Box
                                        key={content}
                                        component={MobileDatePicker}
                                        label={<div 
                                          style={{ whiteSpace: 'pre-wrap' }}
                                           dangerouslySetInnerHTML={{ __html: content }} />}
                                        placeholder={<div 
                                          style={{ whiteSpace: 'pre-wrap' }}
                                           dangerouslySetInnerHTML={{ __html: content }} />}                                                                               
                                        value={values[content]}
                                        onChange={(date) =>setFieldValue(content,moment(date).format('YYYY-MM-DD'))}
                                        renderInput={(params) => (
                                          <TextField                                          
                                            size="small"
                                            {...params}
                                            fullWidth
                                          />
                                        )}
                                      />
                                    )
                                  case 'number':
                                    return (                                  
                                      <TextField
                                        key={value.id}
                                        label={<div 
                                          style={{ whiteSpace: 'pre-wrap' }}
                                           dangerouslySetInnerHTML={{ __html: content }} />}
                                        type="number"
                                        fullWidth
                                        value={values[content]}
                                        onChange={(event) =>setFieldValue(content,event.target.value)}
                                      />                                     
                                    )
                                  case 'select':
                                    return (
                                      <FormControl>                                   
                                      <FormLabel  id="demo-select-label">
                                        {<div 
                                        style={{ whiteSpace: 'pre-wrap' }}
                                         dangerouslySetInnerHTML={{ __html: content }} />}
                                      </FormLabel>             
                                      <Select
                                      label={<div 
                                        style={{ whiteSpace: 'pre-wrap' }}
                                         dangerouslySetInnerHTML={{ __html: content }} />}
                                         placeholder={<div 
                                        style={{ whiteSpace: 'pre-wrap' }}
                                         dangerouslySetInnerHTML={{ __html: content }} />}
                                         defaultValue={values[content]}
                                         value={values[content]}
                                        onChange={(event,newValue) =>setFieldValue(content,newValue.props.children)}
                                      >
                                        {value?.options?.map((data) => (
                                          <MenuItem
                                            key={data.label}
                                            value={data.label}
                                          >
                                            {data.label}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      </FormControl>
                                    )
                                  case 'file':
                                    return (
                                      <>
                                        <LabelStyle>
                                          <div 
                                          style={{ whiteSpace: 'pre-wrap' }}
                                           dangerouslySetInnerHTML={{ __html: content }} /></LabelStyle>
                                        <UploadSingleFile
                                          key={content}
                                          maxSize={3145728}
                                          accept="image/*"
                                          file={values[content]}
                                          onDrop={(acceptedFiles) =>
                                            handleDropFile(
                                              acceptedFiles,
                                              field_id,
                                              content,
                                            )
                                          }
                                          />
                                      </>
                                    )
                                  case 'autocomplete':
                                    return (
                                      <FormControl>
                                        <FormLabel id="demo-autocomplete-label"
                                        >  
                                         <div 
                                          style={{ whiteSpace: 'pre-wrap' }}
                                           dangerouslySetInnerHTML={{ __html: content }} />                           
                                        </FormLabel>                                     
                                        <Autocomplete
                                          fullWidth
                                          id="combo-box-demo"
                                          defaultValue={values[content]}
                                          value={values[content]}
                                          options={(value?.options || [])?.map(option => option.label)}
                                          getOptionLabel={(option)=>option}
                                          onChange={(event,newValue) =>{
                                            setFieldValue(content,newValue)
                                          }  
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}/>
                                          )}    
                                         />
                                      </FormControl>
                                    )
                                  case 'radio-group':
                                    return (
                                      <FormControl>
                                        <FormLabel id="demo-radio-buttons-group-label">
                                         <div 
                                          style={{ whiteSpace: 'pre-wrap' }}
                                           dangerouslySetInnerHTML={{ __html: content }} />
                                        </FormLabel>
                                        <RadioGroup
                                          aria-labelledby="demo-radio-buttons-group-label"
                                          name="radio-buttons-group"
                                          value={values[content]}
                                          onChange={(event) =>setFieldValue(content,event.target.defaultValue) }
                                        >
                                          {value?.options?.map((data) => (
                                            <FormControlLabel
                                              key={data.value}
                                              value={data.label}
                                              control={<Radio />}
                                              label={data.label}
                                            />
                                          ))}
                                        </RadioGroup>
                                      </FormControl>
                                    )
                                    case 'checkbox-group':
                                      return (
                                        <FormControl>
                                        <FormLabel id="demo-autocomplete-label"
                                        >                                       
                                         <div 
                                          style={{ whiteSpace: 'pre-wrap' }}
                                           dangerouslySetInnerHTML={{ __html: content }} />                           
                                        </FormLabel>                                     
                                        <Autocomplete
                                          multiple
                                          fullWidth
                                          id="combo-box-demo"
                                          defaultValue={values[content]}
                                          value={values[content]}
                                          options={(value?.options || [])?.map(option => option.label)}
                                          getOptionLabel={(option)=>option}
                                          onChange={(event,newValue) =>{setFieldValue(content,newValue)
                                          }  
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}/>
                                          )}    
                                         />
                                      </FormControl>
                                      );
                                  default:
                                    return null
                                }
                              },
                            )
                          : null}
                        </>
                      )}  
                     <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                        <LoadingButton type="submit" variant="contained" loading={isSubmitting}  >
                          Save 
                        </LoadingButton>
                       
                        <Button variant="outlined"  onClick={() => {
                          setItemEdit({})
                          setWorkflow('')
                          setStageType(null)
                           setOpenModal(false) }}>
                          Cancel
                        </Button>
                      </Box> 
                      </Stack>
                      </Card>
                      </Grid>
                      </Grid>
                      </Form>
                      </FormikProvider>
        </Modal>
    </Card>
  );
}

// function CarouselItem({ item }) {
//   const { image, name } = item;

//   return (
//     <Box sx={{ position: 'relative' }}>
//       <CarouselImgStyle alt={name} src={image} />
//       <Box
//         sx={{
//           top: 0,
//           width: '100%',
//           height: '100%',
//           position: 'absolute',
//           bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
//         }}
//       />
//       <CardContent
//         sx={{
//           left: 0,
//           bottom: 0,
//           maxWidth: '80%',
//           textAlign: 'left',
//           position: 'absolute',
//           color: 'common.white'
//         }}
//       >
//         <Typography variant="overline" sx={{ opacity: 0.48 }}>
//           New Orders
//         </Typography>
//         <Typography noWrap variant="h5" sx={{ mt: 1, mb: 3 }}>
//           {name}
//         </Typography>
//         {/* <Button to="#" variant="contained" component={RouterLink}>
//           Buy Now
//         </Button> */}
//       </CardContent>
//     </Box>
//   );
// }

const RendererComponents = ({ tag, config, content, field_id, value, id }) => {
  const rightContentStyle = { color: '#1ccaff' };

  const parseContent = (htmlContent) => {
    const doc = new DOMParser().parseFromString(htmlContent, 'text/html');
    return doc.body.textContent || "";
  };

  switch (tag) {
    case 'header':
      return (
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={1}
        >
          {/* <Typography variant="subtitle2">{sentenceCase(tag)}</Typography> */}
          <Typography variant="subtitle2" sx={rightContentStyle}>{content ? sentenceCase(parseContent(content)) : ""}</Typography>
        </Stack>
      );
    case 'paragraph':
    case 'textarea':
    case 'text':
    case 'number':
    case 'autocomplete':
    case 'select':
    case 'checkbox-group':
    case 'radio-group':
      return (
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={1}
        >
          <Typography variant="subtitle2">{content ? sentenceCase(parseContent(content)) : ""}</Typography>
          <Typography variant="subtitle2" sx={rightContentStyle}>{value}</Typography>
        </Stack>
      );
    case 'file':
      return (
        <Stack
         justifyContent={'flex-start'}
          spacing={1}
        >
          <Typography variant="subtitle2">{sentenceCase(parseContent(content))}</Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            {value && (
              <img
                src={value}
                alt={parseContent(content)}
                style={{
                  width: '120px',
                  height: '120px',
                  borderRadius: '5px',
                  objectFit: 'fill'
                }}
              />
            )}
          </Stack>
        </Stack>
      );

    case 'date':
      return (
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          spacing={1}
        >
          <Typography variant="subtitle2">{sentenceCase(parseContent(content))}</Typography>
          <Typography variant="subtitle2" sx={rightContentStyle}>{value}</Typography>
        </Stack>
      );
    default:
      return null;
  }
};





