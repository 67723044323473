// material
import { Box, Card, Grid, Typography } from '@material-ui/core'

export default function Activities({skills}) {
 
  return (
    <Grid  container spacing={3}>
      {skills?.map((row)=>{
        return (
          <Grid key={row.id} item xs={12} md={4} xl={3}>
            <Card >
         <Box
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'} sx={{p:1}}
        >
          <Typography textAlign={'center'} gutterBottom variant='subtitle2'>{row.name}</Typography>
        </Box>
        </Card>
      </Grid>
        )
      })}
      
    </Grid>
  )
}
