import React, { useEffect, useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import roundGroup from '@iconify/icons-ic/round-group';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import instagramFilled from '@iconify/icons-ant-design/instagram-filled';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { sentenceCase } from 'change-case';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { PATH_CALL, PATH_DASHBOARD } from 'src/routes/paths.js';
import { Folder, Group as GroupIcon } from '@material-ui/icons';
import Rating from '@material-ui/lab/Rating';
import { capitalCase } from 'change-case';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import phoneCallFill from '@iconify/icons-eva/phone-call-fill';
import baselineTextsms from '@iconify/icons-ic/baseline-textsms';
import { LoadingButton } from '@material-ui/lab';
import { useTheme } from '@material-ui/core/styles';
import { Box, Card, Grid, Avatar, Tooltip, Divider, Typography, IconButton, Button, Stack, Autocomplete, TextField, Checkbox } from '@material-ui/core';
// utils
import Modal from 'src/components/_dashboard/blog/ProjectModel.js';
import { fShortenNumber } from '../../../../utils/formatNumber';
//
import SvgIconStyle from '../../../SvgIconStyle';
import { Form, FormikProvider, useFormik } from 'formik';
import Label from 'src/components/Label';
import { REST_API, REST_API_END_POINT } from 'src/constants/Defaultvalues';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import blank from 'src/Gif/blank_img.jpg'
import ClientRequests from 'src/pages/dashboard/ClientRequests';
import quickbookLogo from '../../../../assets/quickbookLogo-removebg-preview.png'

// ----------------------------------------------------------------------

const Group = [
  { code: 'AD', label: 'Admin' },
  { code: 'AE', label: 'Production' },
  { code: 'AF', label: 'Design' },
  { code: 'AG', label: 'Sales' },
] // replace with your Request data


const SOCIALS = [
  {
    name: 'Facebook',
    icon: <Icon icon={facebookFill} width={20} height={20} color="#1877F2" />
  },
  {
    name: 'Instagram',
    icon: <Icon icon={instagramFilled} width={20} height={20} color="#D7336D" />
  },
  {
    name: 'Linkedin',
    icon: <Icon icon={linkedinFill} width={20} height={20} color="#006097" />
  },
  {
    name: 'Twitter',
    icon: <Icon icon={twitterFill} width={20} height={20} color="#1C9CEA" />
  }
];

const CardMediaStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  paddingTop: 'calc(100% * 9 / 16)',
  '&:before': {
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
    WebkitBackdropFilter: 'blur(3px)', // Fix on Mobile
    borderTopLeftRadius: theme.shape.borderRadiusMd,
    borderTopRightRadius: theme.shape.borderRadiusMd,
  }
}));

const CoverImgStyle = styled('img')({
  top: 0,
  zIndex: 8,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

const readOnlyRating = 3;

function InfoItem({ number }) {
  return (
    <Grid item xs={4}>
      <Typography variant="caption" sx={{ mb: 0.5, color: 'text.secondary', display: 'block' }}>
        Follower
      </Typography>
      <Typography variant="subtitle1">{fShortenNumber(number)}</Typography>
    </Grid>
  );
}

InfoItem.propTypes = {
  number: PropTypes.number.isRequired,
};

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
};
const getStatusColorAndLabel = (status) => {
  switch (status) {
    case 1:
      return { color: 'primary', label: 'Status: Open' }
    default:
      return { color: 'default', label: 'Status: Pending' }
  }
}
const checkRankStatus=(rankid)=>{
   switch (rankid) {
    case 1:
      return 'https://signwisesolutions.com/uploads/ranks/super.png' 
    case 2:
      return 'https://signwisesolutions.com/uploads/ranks/great.png' 
    case 3:
      return 'https://signwisesolutions.com/uploads/ranks/regular.png'
    case 4:
      return 'https://signwisesolutions.com/uploads/ranks/blacklisted.png' 
      default :
      return null;
   }
}
export default function UserCard({ user, team ,fromClient,setRefreshClient,refreshClient,clientId,setClientId,clientName,setClientName,clientEdit,setClientEdit,clientType,setClientType,setHandleOpenClient,handleOpenClient,clearPreviousData}) {
   
  //get user logged in data from local storage
  const userData = JSON.parse(localStorage.getItem('user'));
  let explodedPermissions = userData?.exploded_permissions;
  let userType = userData?.user_type;
  let userId = userData?.user_id;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const navigate = useNavigate();
  let fullName,user_id, id, first_name, last_name, no_of_requests,no_of_projects,company_logo, profile_pic, city, state, profile_img, status, active, userRole,syncWithQuickBook;
  let projectName, dueDate, numberOfMembers, tasks, img, following, logo, medal,name,partner_type_name,no_of_reviews,rating,thumbnail, customer_type_name;
  let teamUserType =user?.user_type || null
  if (team) {
    ({ id, first_name, last_name, no_of_requests,company_logo, profile_pic, city, state, status, active, userRole,syncWithQuickBook } = user);
    logo = ((company_logo === "default_logo.png")||(company_logo === "")||(company_logo === null)) ? blank : company_logo // "/static/mock-images/avatars/mockbg.png";
    // profile_img = `${REST_API}build/images/team_members/${profile_pic}` 
    profile_img = profile_pic ? profile_pic : first_name  // "/static/mock-images/avatars/avatar_default1.jpg"
    fullName = first_name + " " + last_name;
  }
  else {
    ({  name,user_id, partner_type_name,customer_type_name, no_of_requests,no_of_projects, tasks,  following,city,state,id,no_of_reviews,rating,thumbnail,status,profile_pic,syncWithQuickBook } = user);
    console.log('user========++',user)
    img= profile_pic?profile_pic:name
    logo=thumbnail?thumbnail:blank
    medal= checkRankStatus(user.rank_id)
    // '/static/mock-images/avatars/avatar_1.jpg'
  }
  const [anchorEl, setAnchorEl] = useState(null);
  const [popup, setPopup] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [deleteTeam, setDeleteTeam] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [projectCount, setProjectCount] = useState(0);

  
  const deleteStatus = 2;

  const formik = useFormik({
    enableReinitialize: true,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updateProfile({ ...values });
        enqueueSnackbar('Add Success', { variant: 'success' });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.code });
          setSubmitting(false);
        }
      }
    }
  });

  const { errors, touched, isSubmitting } = formik;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const HandleViewProfile = (id,name,action) => {
    if (team === true) {
      // setHandleOpenClient(true)
      // setClientId(id)
      // setClientName(name)
      navigate(PATH_DASHBOARD.callCenter.teamaccount, { state: { data: id, isTeam: true } })
    } else {
      const type = user.isVendor ? 'vendor' : 'client'
      setClientType(type)
      // navigate(`/dashboard/client/account/${id}`)

      
      if (action === 'edit') {    
        console.log('eddddddddddddddddddddddd')
        localStorage.setItem('clientViewAccount', "editClient");
        setClientEdit("editClient");
        handleClose()

      }else{
        localStorage.setItem('clientViewAccount', "clientView");
        setClientEdit("clientView");
      }
      setHandleOpenClient(true)
      setClientId(id)
      setClientName(name)
    }
  }

  const changeStatus = async (teamId, teamStatus) => {
    try {
      let status = teamStatus;
      let userId = userData.user_id
    
      let response = await axios.post(`${REST_API_END_POINT}teams/delete-team-members`,
        { teamId, status, userId },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        })
    
      if (response.data.status === 1) {
        setDeleteId('');
        enqueueSnackbar(response.data.message, { variant: 'success' });
        navigate(PATH_DASHBOARD.callCenter.teams, { state: { data: teamId } });
      }
      else if (response.data.status === 0) {
        enqueueSnackbar(response.data.message, { variant: 'error' });
      }
      else {
       
        enqueueSnackbar("Error.........!", { variant: 'error' });
      }
    }
    catch (error) {
      console.log("Delete error : ", error);
    }
  }
 const handleDeleteClient=async(id,status)=>{
   try {
    const data={id:id,status:status}
    const res = await axios.post(`${REST_API_END_POINT}clients/remove-client`,data)
    console.log(res.data)
     if(res.data.status ==1){
      setDeleteId('');
      setDeleteTeam(false);
      setRefreshClient(!refreshClient)
     enqueueSnackbar(res.data.message,{variant:'success'})
     }else if(res.data.status ==0){
      setDeleteId('');
      enqueueSnackbar(res.data.message,{variant:'error'})
     }
   } catch (error) {
     console.log(error,'Error in deleting client')
   }
 }

  const fetchProjectCount = async() =>{
    const response = await axios.post(`${REST_API}webservice/rfq/get-project-by-client`,{clientId:user_id, userId, userType})
    const projectData = response?.data?.data;
    if(projectData){
      setProjectCount(projectData?.length)
    }else{
      setProjectCount(0)
    }
  }

  useEffect(()=>{
    if(user_id){
      fetchProjectCount()
    }
  },[user_id])

  return (
    <>
    <Card sx={{
      transition: 'transform 0.2s',
      '&:hover': {
        transform: 'scale(1.02)',
      },
    }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >

      {/* {isHovered && <Box sx={{ position: 'absolute', top: 0, left: 0, zIndex: 10, padding: '12px' }}>
        <Checkbox
          checked={isChecked}
          onChange={(e) => setIsChecked(e.target.checked)}
          sx={{
            color: (theme) => theme.palette.common.white,
            '&.Mui-checked': {
              color: (theme) => theme.palette.common.white
            }
          }}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Box>} */}
      {!isHovered && <Box sx={{ position: 'absolute', top: 8, left: 8, zIndex: 10, padding: '12px' }}>
        {!team && <img width={25} height={25} src={medal} />}
      </Box>}
      <Box sx={{ position: 'absolute', top: 0, right: 0, zIndex: 10, padding: '12px' }}>
        <IconButton
          sx={{
            color: (theme) => theme.palette.common.white,
            '&.Mui-checked': {
              color: (theme) => theme.palette.common.white
            }
          }}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
        >
          <MoreVertIcon />
        </IconButton>
      </Box>
      <CardMediaStyle>
        <IconButton
          sx={{
            top: 8,
            right: 8,
            position: 'absolute'
          }}
        >
          <Icon icon={moreVerticalFill} width={20} height={20} />
        </IconButton>
        <SvgIconStyle
          color="paper"
          src="/static/icons/shape-avatar.svg"
          sx={{
            width: 144,
            height: 62,
            zIndex: 10,
            bottom: -26,
            position: 'absolute'
          }}
        />
        <Avatar
          alt={team ? fullName : name}
          src={team ? profile_img : img}
          sx={{
            width: 64,
            height: 64,
            zIndex: 11,
            position: 'absolute',
            transform: 'translateY(-50%)',
            border:'2px solid white'
          }}
        />
        <span
          style={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            marginLeft: '50px',
            marginTop: '18px',
            position: 'absolute',
            zIndex: 13,
            backgroundColor: active == 'Y' ? '#00FF00' : '#fc3838',
          }}
        ></span>

        <CoverImgStyle alt="cover" src={logo} />
        {!isHovered && <Typography
          sx={{
            zIndex: 12,
            position: 'absolute',
            top: 15,
            right: 10,
          }}
        >
        {!team && <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
           color={getStatusColorAndLabel(status).color} >
            {getStatusColorAndLabel(status).label}
          </Label>}
        </Typography>}
        {(!team && customer_type_name) && 
          <Tooltip title={`Customer Type : ${customer_type_name}`}>
          <Typography // !isHovered && 
            sx={{
              zIndex: 12,
              position: 'absolute',
              bottom: 15,
              right: 10,
              fontSize: '13px',
              fontWeight:'500',
              cursor: 'pointer',
              display: 'inline-block',
              maxWidth: 100, // Adjust as necessary
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              borderRadius:2,
              px:1,
              backgroundColor:'#fcba03',
              color:'black'
            }}
          >
            <span
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {customer_type_name}
            </span>
          </Typography>
          </Tooltip>
        }
      </CardMediaStyle>
      {/* {!team &&
        <Box sx={{ zIndex: 12, position: 'absolute', left: 7, mt: 1 }}>
          <Rating size="small" name="read-only" value={rating} readOnly />
        </Box>} */}
      {/* {!team &&
        <Box sx={{ zIndex: 12, position: 'absolute', right: 15, mt: 1 }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body1"
              sx={{ cursor: 'pointer', color: 'primary.main' }}>
              {no_of_reviews} Reviews
            </Typography>
          </Stack>
        </Box>} */}
        <Typography onClick={() => HandleViewProfile(id,team ? fullName : name)}
         variant="subtitle1"
         align="center"
          sx={{
          mt: 6,
          '&:hover': {
           cursor: 'pointer', 
          textDecoration: 'underline', 
          },
           }}>
         {team ? capitalCase(fullName) : name}
        </Typography>

        <Box sx={{width:'100%',display:"flex",justifyContent:"center",height:"30px",mt:2}}>
      {
          syncWithQuickBook===1?
        <img src={quickbookLogo} width={"50px"} height={"30px"}/>
        :""
      }
      </Box>
     

        <Typography variant="body2" align="center" sx={{ color: 'text.secondary', minHeight: '25px' }}>
        {team ? (userRole ? userRole : '') : null }
      </Typography>

      <Typography variant="body2" align="center" sx={{ color: 'text.secondary', minHeight: '25px' }}>
        {team ?
          (city ? `${city}` : '') +
          (city && state ? ', ' : '') +
          (state ? `${state}` : '')
          : (city ? `${city}` : '') +
          (city && state ? ', ' : '') +
          (state ? `${state}` : '')}
      </Typography>
      {/* {!team &&
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2.5 }}>
          {SOCIALS.map((social) => (
            <Tooltip key={social.name} title={social.name}>
              <IconButton>{social.icon}</IconButton>
            </Tooltip>
          ))}
        </Box>} */}
      {!team &&
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2.5, }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Button
              // component={RouterLink} to={PATH_DASHBOARD.user.minimizecall}
              onClick={() => user.phone ? window.open(`${PATH_CALL.root}?phone=${user.phone}&name=${name}&clId=${id}` , '_blank', 'width=500,height=1000') : enqueueSnackbar('Contact number not available', { variant: 'error' })}
            >
              <Icon icon={phoneCallFill} width={40} height={40} color="#006097" />
            </Button>
            <Button
              // component={RouterLink} to={PATH_DASHBOARD.user.minimizecall}
              onClick={() => user.phone ? window.open(`${PATH_CALL.root}?phone=${user.phone}&name=${name}&clId=${id}` , '_blank', 'width=500,height=1000') : enqueueSnackbar('Contact number not available', { variant: 'error' })}
            >
              <Icon icon={baselineTextsms} width={40} height={40} color="#006097" />
            </Button>
          </Stack>
        </Box>
      }
      <Divider />
      <Grid container sx={{ py: 3, textAlign: 'center', justifyContent: 'center', display: 'flex', px: 2, }}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} justifyContent={'space-between'}>
         {/* {!team &&
            <Button style={{ background: `${theme.palette.action.hover}` }}
              startIcon={<Icon icon={roundGroup} />}
              onClick={() => {
                setPopup(!popup);
              }}
            >
              <Typography variant='subtitle2'>{`Add To Group`}</Typography>
            </Button>
            }
          */}
          {/* {(team || user.isVendor) ? null : <Stack sx={{alignItems:'center', justifyContent:'center', cursor:'pointer'}}
          onClick={() => {
            // navigate(<ClientRequests id={id} name={name} />);
            navigate(PATH_DASHBOARD.client.rfqclient, { state: { id: user_id, name : name } })
          }}
          >
            <Folder sx={{ color: theme.palette.warning.main, boxShadow: 'none' }}/>
            <Typography variant="body2">Requests</Typography>
            <Typography variant="body2" sx={{color:theme.palette.primary.main}}>Count : {no_of_requests}</Typography>
          </Stack>} */}
          <Button style={{ background: `${theme.palette.action.hover}`, }}
           onClick={() => HandleViewProfile(id,team ? fullName : name)}       
          >
            <Typography variant='subtitle2'>{`View Profile`}</Typography>
          </Button>
          {(team || user.isVendor) ? null : <Stack sx={{alignItems:'center', justifyContent:'center', cursor:'pointer'}}
          onClick={() => {
            navigate(PATH_DASHBOARD.client.projectClient, { state: { id: user_id, name : name } })
          }}>
            <Folder sx={{ color: theme.palette.warning.main, boxShadow: 'none' }}/>
            <Typography variant="body2">Projects</Typography>
            <Typography variant="body2" sx={{color:theme.palette.primary.main}}>Count : {projectCount}</Typography>
          </Stack>}
        </Stack>
        { 
         ( teamUserType ==1) ? ""
         :
        
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {(!team && status) &&(
          <MenuItem
            // component={RouterLink}
            // to={{
            //   //  pathname: `/dashboard/client/edit/${user.isVendor ? 'vendor' : 'client'}/${id}/${false}`, 
             
            //   ...(team && {
            //     search: `?id=${id}&isAdmin=true`,
            //   }),
            // }}
            // onClick={handleClose}
            onClick={() => HandleViewProfile(id,team ? fullName : name , 'edit')}
            disabled={user?.isVendor ? (explodedPermissions?.some(permission => ['Edit Vendors'].includes(permission)) ? false : true)
          : (explodedPermissions?.some(permission => ['Edit clients'].includes(permission)) ? false : true)}
          >
            Edit
          </MenuItem>
          )}
          <MenuItem onClick={() => {
            setDeleteTeam(!deleteTeam);
            setAnchorEl(null);
            setDeleteId(id);
          }}
          disabled={team ? (explodedPermissions?.some(permission => ['Delete team members'].includes(permission)) ? false : true) 
          : (user?.isVendor ? (explodedPermissions?.some(permission => ['Delete Vendors'].includes(permission)) ? false : true)
          : (explodedPermissions?.some(permission => ['Delete clients'].includes(permission)) ? false : true))}
          >
            Delete
          </MenuItem>
        </Menu>
}
      </Grid>
      <Grid container sx={{ paddingBottom: 1, textAlign: 'center', justifyContent: 'center' }}>
        {/* {team ? null
          // no_of_requests > 1 ?( <Typography>{` Requests : ${no_of_requests}`}</Typography>):
          //   (<Typography>{` Request : ${no_of_requests}`}</Typography>)
          :
          <Typography sx={{cursor:'pointer'}} onClick={()=>HandleViewProfile(id)}>{(no_of_requests > 1) ? `Requests : ${no_of_requests}` : `Request : ${no_of_requests}`}</Typography>
        } */}
      </Grid>

      <Modal
        open={popup}
        handleClose={() => {
          setPopup(false);
        }}
        modalTitle={'Add To Groups'}
      >
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off">
            <Stack direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 3, sm: 2 }}>
              <Autocomplete
                multiple
                fullWidth
                id="checkboxes-tags-demo"
                options={Group}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox checked={selected} />
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Add To Groups"
                    placeholder="Search Groups"
                    error={Boolean(touched.Group && errors.Group)}
                    helperText={touched.Group && errors.Group}
                    fullWidth // add this line to make the Autocomplete component full width
                  />
                )}
              />
            </Stack>

            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} md={4}>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 1 }}>
                  <LoadingButton variant="contained" onClick={() => { setPopup(false) }} loading={isSubmitting}>
                    {' Add '}
                  </LoadingButton>
                  <Button variant="outlined" onClick={() => { setPopup(false) }}>
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Modal>

      <Modal
        open={deleteTeam}
        handleClose={() => {
          setDeleteTeam(false);
        }}
      // modalTitle={'Remove Team Member'}
      >
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off">
            <Stack direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 3, sm: 2 }}>
              <Avatar
                alt={team ? fullName : projectName}
                src={team ? profile_img : img}
                sx={{ width: 150, height: 150, margin: "auto", }}
              />
              <Typography variant="h5" sx={{ color: 'primary.secondary', alignSelf: 'center' }}>
                Are you sure you want to delete {team ? fullName : name} ?</Typography>
            </Stack>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} md={6}>
                <Box sx={{ my: 3, display: 'flex', justifyContent: 'center', gap: 2 }}>
                  <Button variant="contained"
                    onClick={() => {
                      if(fromClient !== true){
                        setDeleteTeam(false);
                        changeStatus(deleteId, deleteStatus);
                      // navigate(PATH_DASHBOARD.callCenter.teams, { state: { data: deleteId } });
                      }else{
                        handleDeleteClient(deleteId,deleteStatus)
                        
                      }
                    }}>
                    Yes, Delete it!
                  </Button>
                  <Button variant="outlined" onClick={() => {
                    setDeleteTeam(false);
                  }}>
                    No, Cancel pls!
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Modal>
    </Card>

    </>
  );
}
