import React, { useState } from 'react';
import { Grid, Card, Box, Typography, makeStyles, Checkbox, Button } from '@material-ui/core';
import { PictureAsPdf } from '@material-ui/icons';
import { DialogAnimate } from 'src/components/animate';
import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';


const useStyles = makeStyles((theme) => ({
    attachmentBox: {
        cursor: 'pointer',
        display: 'flex',
        borderRadius: '6px',
        backgroundColor: '#172e34',
        padding: theme.spacing(1),
        margin: theme.spacing(1, 0),
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative', 
        height: '100px', 
    },
    attachmentIcon: {
        marginRight: theme.spacing(1),
    },
    delete:{
        m:2,
        display:'flex',
        justifyContent:'flex-end' 
      },
    checkbox: {
        position: 'absolute',
        top: theme.spacing(1), 
        right: theme.spacing(1), 
    },
    checkboxContainer: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
    dialogContent: {
        padding: theme.spacing(3),
        maxWidth: 768,
        margin: 'auto',
    },
    gridContainer: {
        height: 'auto',
    },
}));

export default function OtherFiles({data,refresh,setRefresh,projectId,taskId}) {
    const classes = useStyles();
    let user = JSON.parse(localStorage.getItem('user'))
    const {enqueueSnackbar} = useSnackbar()
    const [open, setOpen] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [checkedItems, setCheckedItems] = useState([]);

    const handleCheckboxChange = (id) => {
        setCheckedItems((prevState) => {
            const isPresent = prevState.includes(id);
            const updatedState = isPresent
              ? prevState.filter((item) => item !== id)
              : [...prevState, id];
            return updatedState;
          });   
    };
     
    const handleDelete=async()=>{
        try {
            const res = await axios.post(`${REST_API_END_POINT}project-task/delete-attachment`,{
                userId:user.user_id,attachments:checkedItems,projectId, taskId
            })
            if(res.data.status===1){
                enqueueSnackbar(res.data.message,{variant:'success'}) 
                setRefresh(!refresh)
                setCheckedItems([])
            }else{
                enqueueSnackbar(res.data.message,{variant:'error'}) 
            }
        } catch (error) {
            console.log(error)
        }
      }

    const handleAttachmentClick = (attachment) => {
        setSelectedAttachment(attachment);
        setOpen(true);
    };


    return (
        <>
            {checkedItems.length > 0 &&(
                  <Box sx={{ mb: 3, display: 'flex', justifyContent: 'flex-end',gap:2,m:2.5,  }}>
                  <Typography  variant='subtitle1'>{checkedItems.length} selected</Typography>
                    <Button onClick={()=>handleDelete()} className={classes.delete} size='small' variant='outlined'>
                         <Icon icon={trash2Outline} width={24} height={24} /> Delete
                         </Button>
                </Box>)}
                {data?.length>0 ? (
            <Grid className={classes.gridContainer} container spacing={2} pb={4}>
                {data?.map((attachment, index) => (
                    <Grid key={attachment.ID} item xs={12} sm={4}>
                        <Box
                            className={classes.attachmentBox}
                            display="flex"
                            alignItems="center"
                        >
                            <Box onClick={() => handleAttachmentClick(attachment)}>
                                <PictureAsPdf className={classes.attachmentIcon} />
                                <Typography>{attachment.name}</Typography>
                            </Box>
                            <Box className={classes.checkboxContainer}>
                            <Checkbox
                                checked={checkedItems.includes(attachment.ID) ? true : false}
                                onChange={() => handleCheckboxChange(attachment.ID)}
                                color="primary"
                            />
                        </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>):(
                <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                py={15}
              >
                No files found
              </Box>
            )}

            <DialogAnimate open={open} onClose={() => setOpen(false)}>
                <Card className={classes.dialogContent}>
                    {selectedAttachment && (
                        <embed src={selectedAttachment.file_url} type="application/pdf" width="100%" height="350px" />
                    )}
                </Card>
            </DialogAnimate>
        </>
    );
}
