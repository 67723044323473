import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import { useMemo, useState } from 'react'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import checkmarkFill from '@iconify/icons-eva/checkmark-fill'
// material
// import { alpha, styled } from '@material-ui/core/styles';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles'
import {
  Box,
  Avatar,
  TextField,
  Typography,
  Autocomplete,
  Stack,
  Button,
} from '@material-ui/core'
//
import SearchNotFound from 'src/components/SearchNotFound'
import { MChip, MIconButton } from 'src/components/@material-extend'
import { Send } from '@material-ui/icons'
import axios from 'axios'
import { REST_API_END_POINT } from 'src/constants/Defaultvalues'
import { useSnackbar } from 'notistack'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: theme.spacing(2, 3),
}))

const AutocompleteStyle = styled('div')(({ theme }) => ({
  '& .MuiAutocomplete-root': {
    width: 280,
    '&.Mui-focused .MuiAutocomplete-inputRoot': {
      boxShadow: theme.customShadows.z8,
    },
  },
  '& .MuiAutocomplete-inputRoot': {
    transition: theme.transitions.create('box-shadow', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '& fieldset': {
      borderWidth: `1px !important`,
    },
  },
}))

// ----------------------------------------------------------------------

// ChatHeaderComposeSignWise.propTypes = {
//   contacts: PropTypes.array.isRequired,
//   // recipients: PropTypes.array.isRequired,
//   // onAddRecipient: PropTypes.func.isRequired,
// }

export default function ChatHeaderComposeSignWise({
  contacts,
  // recipients,
  refreshChat,
  ...other
}) {
  const { enqueueSnackbar } = useSnackbar()
  const [query, setQuery] = useState('')
  const [groupName, setGroupName] = useState('')
  const [members, setMembers] = useState([])
  const user = localStorage.getItem('user')
  const userData = JSON.parse(user)
  const [recipients, setRecipients] = useState([]);



  const filteredContacts = useMemo(() => {
    return (contacts || []).filter((contact) => {
      if (!query.trim()) return contact.user_id !== null;
      return contact.user_id !== null && contact?.first_name?.toLowerCase().includes(query.trim().toLowerCase());
    });
  }, [contacts, query]);
  
  console.log('filteredContacts===============',filteredContacts )

  const handleChangeQuery = (event) => {
    setQuery(event.target.value)
  }

const handleAddRecipient = (event, newRecipients) => {
  if (!newRecipients) return;
  setRecipients(newRecipients);  // ✅ Store selected users
  setMembers(newRecipients.map((recipient) => recipient.user_id));
};
  const onAddRecipient = (recipients) => {
    console.log('Added recipient IDs:', recipients)
    const recipientIds = recipients.map((recipient) => recipient.user_id)
    setMembers(recipientIds)
    console.log('Added recipient IDs:', recipientIds)
  }
  // onAddRecipient={(recipient) => console.log('Added recipient:', recipient)}
  const handlecreateGroup = async () => {
    if (!groupName.trim()) {
      enqueueSnackbar("Group name cannot be empty", { variant: "warning" });
      return;
    }
  
    if (members.length === 0) {
      enqueueSnackbar("Please select at least one member", { variant: "warning" });
      return;
    }
    try {
      const response = await axios.post(
        `${REST_API_END_POINT}chat/create-group`,
        {
          groupName,
          createdBy: userData?.user_id,
          members,
        },
      )
      let res = response?.data
      if (res.status === 1) {
        enqueueSnackbar(res.message, { variant: 'success' })
        setGroupName('')
        setMembers([])
        setRecipients([]);
        setQuery('');
        refreshChat()
      } else {
        enqueueSnackbar(res.message, { variant: 'error' })
      }
    } catch (error) {
      console.error(error, 'error==========adding group')
    }
  }

  return (
    <RootStyle {...other}>
      <Typography variant="subtitle2" sx={{ color: 'text.secondary', mb: 1 }}>
        {/* Select Members To Create Group: */}
        Create a Group
      </Typography>
      <TextField
        label="Group name"
        size="small"
        fullWidth
        sx={{ mb: 1 }}
        value={groupName}
        onChange={(e) => setGroupName(e.target.value)}
      />
      <Stack direction="row" spacing={1} alignItems="center">
        <AutocompleteStyle>
          <Autocomplete
            multiple
            size="small"
            disablePortal
            popupIcon={null}
            noOptionsText={<SearchNotFound searchQuery={query} />}
            onChange={handleAddRecipient}
            value={recipients}
            inputValue={query}
            onInputChange={(event, newQuery) => setQuery(newQuery)}
            options={filteredContacts} // ✅ Using filtered contacts
            getOptionLabel={(contact) => contact.first_name || 'Unknown'}
            renderOption={(props, contact, { inputValue, selected }) => {
              const matches = match(contact.first_name, inputValue)
              const parts = parse(contact.first_name, matches)

              return (
                <li {...props}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                      alt={contact.first_name}
                      src={contact.profile_pic || '/default-avatar.png'}
                    />

                    <Box sx={{ ml: 2 }}>
                      {parts.map((part, index) => (
                        <Typography
                          key={index}
                          variant="subtitle2"
                          color={part.highlight ? 'primary' : 'textPrimary'}
                        >
                          {part.text}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                </li>
              )
            }}
            renderTags={(recipients, getTagProps) =>
              recipients.map((recipient, index) => (
                <MChip
                  key={recipient.id}
                  size="small"
                  label={recipient.first_name}
                  color="info"
                  avatar={
                    <Avatar
                      alt={recipient.first_name}
                      src={recipient.profile_pic || '/default-avatar.png'}
                    />
                  }
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                label="Select members..."
                {...params}
                placeholder={recipients.length === 0 ? 'Recipients' : ''}
              />
            )}
          />
        </AutocompleteStyle>
        <Button
          onClick={handlecreateGroup}
          sx={{
            border: '1px dashed #2e434b',
            '&:hover': {
              backgroundColor: 'primary.main',
              color: 'common.white',
            },
          }}
        >
          <Send sx={{ fontSize: '1rem' }} />
        </Button>
      </Stack>
    </RootStyle>
  )
}
