import PropTypes from 'prop-types';
import { formatDistanceToNowStrict } from 'date-fns';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Avatar, Box, Stack, Typography } from '@material-ui/core';
import TypingAnimation from 'src/components/animate/TypingAnimation';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(3)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 320,
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral
}));

const InfoStyle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(0.75),
  color: theme.palette.text.secondary
}));

const MessageImgStyle = styled('img')(({ theme }) => ({
  height: 200,
  minWidth: 216,
  width: '100%',
  cursor: 'pointer',
  objectFit: 'cover',
  borderRadius: theme.shape.borderRadius
}));

// ----------------------------------------------------------------------

ChatMessageItemSignWise.propTypes = {
  message: PropTypes.object.isRequired,
  // conversation: PropTypes.object.isRequired,
  onOpenLightbox: PropTypes.func
};

export default function ChatMessageItemSignWise({ message,  onOpenLightbox, ...other }) {
  // const sender = conversation.participants.find((participant) => participant.id === message.senderId);
  // const senderDetails =
  //   message.senderId === '8864c717-587d-472a-929a-8e5f298024da-0'
  //     ? { type: 'me' }
  //     : { avatar: sender.avatar, name: sender.name };

  const isMe = message.sender === 'You';
  const senderDetails = isMe ? { type: 'me' } : { avatar: message.profile_pic, name: message.sender };
  console.log('senderDetails++++++++++', senderDetails)
  console.log('senderDetails++++++++++', message)
  // const isImage = message.contentType === 'image';
  const firstName = senderDetails.name && senderDetails.name.split(' ')[0];

  return (
    <RootStyle {...other}>
      <Box
        sx={{
          display: 'flex',
          ...(isMe && {
            ml: 'auto'
          })
        }}
      >
        {senderDetails.type !== 'me' && (
          <Avatar alt={senderDetails.name} src={senderDetails.avatar} sx={{ width: 32, height: 32 }} />
        )}

        <Box sx={{ ml: 2 }}>
          {/* <InfoStyle noWrap variant="caption" sx={{ ...(isMe && { justifyContent: 'flex-end' }) }}>
            {!isMe && `${firstName},`}&nbsp;
            {formatDistanceToNowStrict(new Date(message?.createdAt), {
              addSuffix: true
            })}
          </InfoStyle> */}

          <ContentStyle
            sx={{
              ...(isMe && {
                color: 'grey.800',
                bgcolor: 'primary.lighter'
              })
            }}
          >
            {message.contentType === 'image' ? (
              <>
              <MessageImgStyle alt="attachment" src={message.attachment} onClick={() => onOpenLightbox(message.attachment)} />
              <Typography variant="body2" dangerouslySetInnerHTML={{ __html: message.text.replace(/<\/p><p>/g, ' ') }} sx={{
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                whiteSpace: 'pre-wrap',
                width: '100%',
              }}/>
              </>
            ) : (
              message.isTyping ? 
              <TypingAnimation /> :
              <Typography variant="body2" dangerouslySetInnerHTML={{ __html: message.text.replace(/<\/p><p>/g, ' ') }}sx={{
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                whiteSpace: 'pre-wrap',
                width: '100%',
              }}/>
            )}
          </ContentStyle>
        </Box>
      </Box>
    </RootStyle>
  );
}
