// import { List, ListItem, ListItemText, Typography, Divider } from '@material-ui/core';
// import { styled } from '@material-ui/styles';

// const SectionTitle = styled(Typography)({
//   padding: '10px 16px',
//   fontSize: 14,
//   fontWeight: 600,
//   color: '#a0a0a0',
//   textTransform: 'uppercase'
// });

// // Static Chat Data
// const chatData = {
//   today: [
//     { id: 1, title: 'Fastify Multipart Error Fix' },
//     { id: 2, title: 'Upload File to DB' },
//     { id: 3, title: 'Camera Capture Integration' },
//   ],
//   yesterday: [
//     { id: 4, title: 'Delete Payout Logic' },
//     { id: 5, title: 'Fix SQL Query' },
//     { id: 6, title: 'Moment.js Unix Timestamp' },
//     { id: 7, title: 'WebRTC Integration Fastify' },
//     { id: 8, title: 'Chat Header Integration' },
//   ],
//   last7Days: [
//     { id: 9, title: 'Optimizing Material Upload' },
//   ],
//   last30Days: [
//     { id: 10, title: 'Implement Multi-Step Form' },
//     { id: 11, title: 'Redux Store Optimization' },
//   ],
//   olderChats: {
//     'January 2025': [
//       { id: 12, title: 'Docker Deployment Fix' },
//       { id: 13, title: 'Custom React Hooks' },
//     ],
//     'December 2024': [
//       { id: 14, title: 'Refactor API Endpoints' },
//       { id: 15, title: 'GraphQL Integration' },
//     ],
//   },
// };

// // Function to Render Chat List
// const renderChatList = (chats) =>
//   chats.map((chat) => (
//     <ListItem key={chat.id} button>
//       <ListItemText primary={chat.title} primaryTypographyProps={{ noWrap: true, variant: 'subtitle2', color: '#fff' }} />
//     </ListItem>
//   ));

// export default function ChatHistory( historyMessage,setHistoryMessage) {
//   return (
//     <List disablePadding>
//       {chatData.today.length > 0 && (
//         <>
//           <SectionTitle>Today</SectionTitle>
//           {renderChatList(chatData.today)}
//           <Divider />
//         </>
//       )}
//       {chatData.yesterday.length > 0 && (
//         <>
//           <SectionTitle>Yesterday</SectionTitle>
//           {renderChatList(chatData.yesterday)}
//           <Divider />
//         </>
//       )}
//       {chatData.last7Days.length > 0 && (
//         <>
//           <SectionTitle>Previous 7 Days</SectionTitle>
//           {renderChatList(chatData.last7Days)}
//           <Divider />
//         </>
//       )}
//       {chatData.last30Days.length > 0 && (
//         <>
//           <SectionTitle>Previous 30 Days</SectionTitle>
//           {renderChatList(chatData.last30Days)}
//           <Divider />
//         </>
//       )}
//       {Object.entries(chatData.olderChats).map(([month, chats]) => (
//         <>
//           <SectionTitle key={month}>{month}</SectionTitle>
//           {renderChatList(chats)}
//           <Divider />
//         </>
//       ))}
//     </List>
//   );
// }







import { List, ListItem, ListItemText, Typography, Divider, ListItemSecondaryAction, IconButton, Menu, MenuItem, TextField, Stack, Button, useTheme, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { useMemo, useState } from 'react';
import moment from 'moment';
import { Check, Delete, Edit, MoreHoriz, MoreVert } from '@material-ui/icons';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
import axios from 'axios';

const SectionTitle = styled(Typography)({
  padding: '10px 16px',
  fontSize: 14,
  fontWeight: 600,
  color: '#a0a0a0',
  textTransform: 'uppercase'
});

const groupChatsByDate = (historyMessages) => {
  console.log("Raw historyMessages:", historyMessages);

  const chatsByDate = {
    today: [],
    yesterday: [],
    last7Days: [],
    last30Days: [],
    olderChats: {}
  };

  const groupedByChatLog = {};

  // Group messages by chat_log_id and pick the smallest ID as title
  historyMessages.forEach((msg) => {
    if (!msg.created_at) {
      console.log("Missing created_at:", msg);
      return;
    }

    if (!msg.chat_log_id) {
      console.log("Missing chat_log_id:", msg);
      return;
    }

    const messageText = msg.chat_log_msg && msg.chat_log_msg.trim() !== "" ? msg.chat_log_msg : msg.contentType;

    if (!groupedByChatLog[msg.chat_log_id]) {
      groupedByChatLog[msg.chat_log_id] = { ...msg, messages: [messageText], firstMessage: msg.chat_log_title || '' };
    } else {
      groupedByChatLog[msg.chat_log_id].messages.push(messageText);
      groupedByChatLog[msg.chat_log_id].messages.sort((a, b) => a.localeCompare(b)); // Sort text in ascending order
      if (msg.id < groupedByChatLog[msg.chat_log_id].id) {
        groupedByChatLog[msg.chat_log_id] = { ...msg, messages: groupedByChatLog[msg.chat_log_id].messages, firstMessage: msg.chat_log_title || '' };
      }
    }
  });

  const now = moment();
  let allChats = [];

  Object.values(groupedByChatLog).forEach((chat) => {
    const chatDate = chat.created_at ? moment.utc(Number(chat.created_at) * 1000) : moment.invalid();

    if (!chatDate.isValid()) {
      console.log("Invalid date detected:", chat.created_at);
      return;
    }

    const daysDiff = now.diff(chatDate, "days");
    const chatEntry = { id: chat.id, title: chat.firstMessage, chat_log_id: chat.chat_log_id };
    allChats.push({ chatEntry, daysDiff, chatDate });
  });

  // Sort all chats by id in descending order
  allChats.sort((a, b) => b.chatEntry.id - a.chatEntry.id);

  allChats.forEach(({ chatEntry, daysDiff, chatDate }) => {
    if (daysDiff === 0) {
      chatsByDate.today.push(chatEntry);
    } else if (daysDiff === 1) {
      chatsByDate.yesterday.push(chatEntry);
    } else if (daysDiff <= 7) {
      chatsByDate.last7Days.push(chatEntry);
    } else if (daysDiff <= 30) {
      chatsByDate.last30Days.push(chatEntry);
    } else {
      const monthYear = chatDate.format("MMMM YYYY");
      if (!chatsByDate.olderChats[monthYear]) {
        chatsByDate.olderChats[monthYear] = [];
      }
      chatsByDate.olderChats[monthYear].push(chatEntry);
    }
  });

  return chatsByDate;
};

const ChatListItem = ({ chat, editId, setEditId, setSelectedChatLogId, onRename, onDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [editText, setEditText] = useState(chat?.title || '');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const theme = useTheme()
  const open = Boolean(anchorEl);
  const isRenaming = editId === chat.chat_log_id;

  const handleMenuOpen = (event) => {
    event.stopPropagation(); // Prevents triggering chat selection
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRenameClick = () => {
    setEditId(chat.chat_log_id);
    setAnchorEl(null);
  };

  const handleRenameSubmit = () => {
    if (editText.trim() && editText !== chat?.title) {
      onRename(chat?.chat_log_id, editText); // Call rename function
      chat.title = editText;
    }
    setEditId(null);
  };

  const handleDeleteClick = () => {
    setOpenConfirmDialog(true); // Open delete confirmation dialog
    setAnchorEl(null);
  };

  const confirmDelete = () => {
    onDelete(chat.chat_log_id);
    setOpenConfirmDialog(false);
  };
  const extractMessageContent = (message) => {
    if (!message) return 'Image';

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = message;

    if (tempDiv.querySelector('img')) {
      return tempDiv.querySelector('img').src.includes('.gif') ? 'GIF' : 'Image';
    }
    return tempDiv.textContent || tempDiv.innerText || '';
  };
  

  return (
    <>
      <ListItem button={!isRenaming} onClick={() => setSelectedChatLogId(chat.chat_log_id)}>
        {isRenaming ? (
          <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
            <TextField
              value={extractMessageContent(editText)}
              onChange={(e) => setEditText(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && handleRenameSubmit()} // Save on Enter key
              autoFocus
              size="small"
              variant="outlined"
              sx={{ borderRadius: '4px', flexGrow: 1 }}
            />
            <IconButton onClick={handleRenameSubmit} sx={{ color: 'green' }}>
              <Check />
            </IconButton>
          </Stack>
        ) : (
          <ListItemText
            primary={extractMessageContent(chat?.title)}
            primaryTypographyProps={{ noWrap: true, variant: 'subtitle2', sx: { fontSize: '14px', color: '#fff' } }}
          />
        )}

        {/* Three-dot menu */}
        {!isRenaming && (
          <ListItemSecondaryAction>
            <IconButton onClick={handleMenuOpen} > 
              {/* sx={{ color: '#fff' }} */}
              <MoreHoriz />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              sx={{
                '& .MuiPaper-root': {
                  // backgroundColor: '#1e1e1e', // Dark background
                  // MenuItem '&:hover': { backgroundColor: '#2a2a2a' }
                  color: '#fff', // White text
                  borderRadius: 2,
                  padding: '6px 0',
                },
              }}
            >
              {/* Styled Rename Option */}
              <MenuItem onClick={handleRenameClick} sx={{ gap: 1, padding: '8px 16px' }}>
                <Edit sx={{ fontSize: 18, color: '#fff' }} />
                <Typography variant="body2">Rename</Typography>
              </MenuItem>

              {/* Styled Delete Option */}
              <MenuItem onClick={handleDeleteClick} sx={{ gap: 1, padding: '8px 16px' }}>
                <Delete sx={{ fontSize: 18, color: theme.palette.error.main }} />
                <Typography variant="body2" sx={{ color: theme.palette.error.main }}>
                  Delete
                </Typography>
              </MenuItem>
            </Menu>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openConfirmDialog}
        onClose={()=>setOpenConfirmDialog(false)}
        sx={{
          '& .MuiPaper-root': {
            // backgroundColor: theme.palette.background.default,
            borderRadius: 3,
            padding: '10px',
            minWidth: '400px',
          },
        }}
      >
        {/* Title */}
        <DialogTitle sx={{ fontSize: '18px', fontWeight: 'bold', color: '#fff', paddingBottom: '5px' }}>
          Delete chat?
        </DialogTitle>

        {/* Content */}
        <DialogContent sx={{ paddingBottom: '10px' }}>
          <Typography variant="body2" sx={{ color: '#b0b0b0' }}>
            This will delete <strong>{extractMessageContent(chat?.title)}</strong>.
          </Typography>
        </DialogContent>

        {/* Actions */}
        <DialogActions sx={{ justifyContent: 'center', gap:3 }}>
          <Button
            onClick={()=>setOpenConfirmDialog(false)}
            sx={{
              border: '1px solid #555',
              color: '#fff',
              backgroundColor: 'transparent',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={confirmDelete}
            variant="outlined"
            sx={{
              color: theme.palette.error.main,
              border: `1px solid ${theme.palette.error.main}`,
              '&:hover': { backgroundColor: theme.palette.error.main, color: '#fff', border: `1px solid rgba(255, 0, 0, 0.1)` },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const renderChatList = (chats, setSelectedChatLogId, onRename, onDelete, editId, setEditId) =>
  chats.map((chat) => (
    <ChatListItem 
      key={chat.id} 
      chat={chat} 
      setSelectedChatLogId={setSelectedChatLogId} 
      onRename={onRename} 
      onDelete={onDelete} 
      editId={editId} 
      setEditId={setEditId} 
    />
  ))

export default function ChatHistory({ historyMessage, setSelectedChatLogId, handleRefresh }) {
  const chatData = useMemo(() => groupChatsByDate(historyMessage || []));
  const [editId, setEditId] = useState(null);

  const handleRename = async(chatId, chatTitle)=>{
    console.log('handle rename : ', chatId,chatTitle );
    const response = await axios.post(`${REST_API_END_POINT}chat/rename-chat-log`, {
      chatLogId:chatId, 
      chatTitle : chatTitle
    });
    if (response.data.data) {
      handleRefresh()
    }else{
      handleRefresh()
    }
  }
  const handleDelete = async(chatId)=>{
    console.log('handle delete : ', chatId );
    const response = await axios.post(`${REST_API_END_POINT}chat/delete-chat-log`, {
      chatLogId:chatId
    });
    if (response.data.data) {
      handleRefresh();
    }else{
      handleRefresh();
    }
  }
  return (
    <List disablePadding>
      {chatData.today.length > 0 && (
        <>
          <SectionTitle>Today</SectionTitle>
          {renderChatList(chatData.today, setSelectedChatLogId, handleRename, handleDelete, editId, setEditId)}
          <Divider />
        </>
      )}
      {chatData.yesterday.length > 0 && (
        <>
          <SectionTitle>Yesterday</SectionTitle>
          {renderChatList(chatData.yesterday, setSelectedChatLogId, handleRename, handleDelete, editId, setEditId)}
          <Divider />
        </>
      )}
      {chatData.last7Days.length > 0 && (
        <>
          <SectionTitle>Previous 7 Days</SectionTitle>
          {renderChatList(chatData.last7Days, setSelectedChatLogId, handleRename, handleDelete, editId, setEditId)}
          <Divider />
        </>
      )}
      {chatData.last30Days.length > 0 && (
        <>
          <SectionTitle>Previous 30 Days</SectionTitle>
          {renderChatList(chatData.last30Days, setSelectedChatLogId, handleRename, handleDelete, editId, setEditId)}
          <Divider />
        </>
      )}
      {Object.entries(chatData.olderChats).map(([month, chats]) => (
        <div key={month}>
          <SectionTitle>{month}</SectionTitle>
          {renderChatList(chats, setSelectedChatLogId, handleRename, handleDelete, editId, setEditId)}
          <Divider />
        </div>
      ))}
    </List>
  );
}









// import { List, ListItem, ListItemText, Typography, Divider, Menu, MenuItem, IconButton, TextField } from '@material-ui/core';
// import { styled } from '@material-ui/styles';
// import { useMemo, useState } from 'react';
// import moment from 'moment';
// import { MoreVertSharp } from '@material-ui/icons';

// const SectionTitle = styled(Typography)({
//   padding: '10px 16px',
//   fontSize: 14,
//   fontWeight: 600,
//   color: '#a0a0a0',
//   textTransform: 'uppercase'
// });

// const groupChatsByDate = (historyMessages) => {
//   console.log("Raw historyMessages:", historyMessages);

//   const chatsByDate = {
//     today: [],
//     yesterday: [],
//     last7Days: [],
//     last30Days: [],
//     olderChats: {}
//   };

//   const groupedByChatLog = {};

//   historyMessages.forEach((msg) => {
//     if (!msg.created_at) {
//       console.log("Missing created_at:", msg);
//       return;
//     }

//     if (!msg.chat_log_id) {
//       console.log("Missing chat_log_id:", msg);
//       return;
//     }

//     const messageText = msg.chat_log_msg && msg.chat_log_msg.trim() !== "" ? msg.chat_log_msg : msg.contentType;

//     if (!groupedByChatLog[msg.chat_log_id]) {
//       groupedByChatLog[msg.chat_log_id] = { ...msg, messages: [messageText], firstMessage: messageText };
//     } else {
//       groupedByChatLog[msg.chat_log_id].messages.push(messageText);
//       groupedByChatLog[msg.chat_log_id].messages.sort((a, b) => a.localeCompare(b));
//       if (msg.id < groupedByChatLog[msg.chat_log_id].id) {
//         groupedByChatLog[msg.chat_log_id] = { ...msg, messages: groupedByChatLog[msg.chat_log_id].messages, firstMessage: messageText };
//       }
//     }
//   });

//   const now = moment();
//   let allChats = [];

//   Object.values(groupedByChatLog).forEach((chat) => {
//     const chatDate = chat.created_at ? moment.utc(Number(chat.created_at) * 1000) : moment.invalid();

//     if (!chatDate.isValid()) {
//       console.log("Invalid date detected:", chat.created_at);
//       return;
//     }

//     const daysDiff = now.diff(chatDate, "days");
//     const chatEntry = { id: chat.id, title: chat.firstMessage, chat_log_id: chat.chat_log_id };
//     allChats.push({ chatEntry, daysDiff, chatDate });
//   });

//   allChats.sort((a, b) => b.chatEntry.id - a.chatEntry.id);

//   allChats.forEach(({ chatEntry, daysDiff, chatDate }) => {
//     if (daysDiff === 0) {
//       chatsByDate.today.push(chatEntry);
//     } else if (daysDiff === 1) {
//       chatsByDate.yesterday.push(chatEntry);
//     } else if (daysDiff <= 7) {
//       chatsByDate.last7Days.push(chatEntry);
//     } else if (daysDiff <= 30) {
//       chatsByDate.last30Days.push(chatEntry);
//     } else {
//       const monthYear = chatDate.format("MMMM YYYY");
//       if (!chatsByDate.olderChats[monthYear]) {
//         chatsByDate.olderChats[monthYear] = [];
//       }
//       chatsByDate.olderChats[monthYear].push(chatEntry);
//     }
//   });

//   return chatsByDate;
// };

// const ChatItem = ({ chat, setSelectedChatLogId }) => {
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [isEditing, setIsEditing] = useState(false);
//   const [title, setTitle] = useState(chat.title);

//   const handleMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   return (
//     <ListItem button onClick={() => setSelectedChatLogId(chat.chat_log_id)}>
//       {isEditing ? (
//         <TextField
//           value={title}
//           onChange={(e) => setTitle(e.target.value)}
//           onBlur={() => setIsEditing(false)}
//           onKeyDown={(e) => e.key === 'Enter' && setIsEditing(false)}
//           autoFocus
//         />
//       ) : (
//         <ListItemText primary={title} primaryTypographyProps={{ noWrap: true, variant: 'subtitle2', color: '#fff' }} />
//       )}
//       <IconButton onClick={handleMenuOpen}>
//         <MoreVertSharp />
//       </IconButton>
//       <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
//         <MenuItem onClick={() => { setIsEditing(true); handleMenuClose(); }}>Rename</MenuItem>
//         <MenuItem onClick={handleMenuClose}>Delete</MenuItem>
//       </Menu>
//     </ListItem>
//   );
// };

// const renderChatList = (chats, setSelectedChatLogId) =>
//   chats.map((chat) => <ChatItem key={chat.id} chat={chat} setSelectedChatLogId={setSelectedChatLogId} />);

// export default function ChatHistory({ historyMessage, setSelectedChatLogId }) {
//   const chatData = useMemo(() => groupChatsByDate(historyMessage || []));

//   return (
//     <List disablePadding>
//       {chatData.today.length > 0 && (
//         <>
//           <SectionTitle>Today</SectionTitle>
//           {renderChatList(chatData.today, setSelectedChatLogId)}
//           <Divider />
//         </>
//       )}
//       {chatData.yesterday.length > 0 && (
//         <>
//           <SectionTitle>Yesterday</SectionTitle>
//           {renderChatList(chatData.yesterday, setSelectedChatLogId)}
//           <Divider />
//         </>
//       )}
//       {chatData.last7Days.length > 0 && (
//         <>
//           <SectionTitle>Previous 7 Days</SectionTitle>
//           {renderChatList(chatData.last7Days, setSelectedChatLogId)}
//           <Divider />
//         </>
//       )}
//       {chatData.last30Days.length > 0 && (
//         <>
//           <SectionTitle>Previous 30 Days</SectionTitle>
//           {renderChatList(chatData.last30Days, setSelectedChatLogId)}
//           <Divider />
//         </>
//       )}
//     </List>
//   );
// }
