import * as Yup from 'yup'
// import PropTypes from 'prop-types';
import { useCallback, useRef, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, FormikProvider, useFormik } from 'formik'
// material
import { LoadingButton, MobileDatePicker } from '@material-ui/lab'
import { useTheme } from '@material-ui/core/styles'
import { experimentalStyled as styled } from '@material-ui/core/styles'
import {
  Box,
  Card,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
  Link,
  FormHelperText,
  FormControlLabel,
  Button,
  Autocomplete,
  Container,
  Checkbox,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  FormGroup,
} from '@material-ui/core'

// utils
import { fData } from 'src/utils/formatNumber.js'
import fakeRequest from 'src/utils/fakeRequest.js'
// routes
import { PATH_DASHBOARD } from 'src/routes/paths.js'
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs.js'
import Page from 'src/components/Page.js'
import { Icon } from '@iconify/react'
import plusFill from '@iconify/icons-eva/plus-fill'
import trash2Fill from '@iconify/icons-eva/trash-2-fill'
//
import Label from 'src/components/Label.js'
import { UploadAvatar, UploadSingleFile } from 'src/components/upload/index.js'
import AccountTypeOnboardClient from 'src/components/_dashboard/user/AccountTypeOnboardClient'
import AddTicketStatus from 'src/components/_dashboard/user/AddTicketStatus.js'
import { Link as RouterLink } from 'react-router-dom'
import { QuillEditor } from 'src/components/editor/index.js'
import moment from 'moment'
import { storage } from 'src/firebase/firebase'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import axios from 'axios'
import { REST_API_END_POINT } from 'src/constants/Defaultvalues'
import SignatureCanvas from 'react-signature-canvas'

export default function OnboardingForm({
  stageType,
  states,
  bankForm,
  details,
  fetchProcessData,
  onboardComplete,
  templatefield,
  requestId
}) {
  const location = useLocation()
  const navigate = useNavigate()
  const mySignature = useRef(null)
  let user = JSON.parse(localStorage.getItem('user'))
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'))
  const [selectedFile, setSelectedFile] = useState(null)

  const [progress, setProgress] = useState(null)
  const [extraPorfolio, setExtraPortfolio] = useState([])
  // const manual=location.manual
  // const setManual=location.setManual
  const [fieldTemplates, setFieldTemplates] = useState(
    templatefield[0]?.fields
      ? {
          // checkboxgroup: [],
          stage: 1,
          ...Object.entries(templatefield[0]?.fields).reduce(
            (obj, [key, value], index) => {
              const { tag, config, content, field_id, required } = value
              obj[content] = {
                field_id,
                content: tag,
                required,
                value: '',
              }
              return obj
            },
            {},
          ),
        }
      : null,
  )

  const NewUserSchema = Yup.object().shape({
    tax_num: stageType == 3 && Yup.string().required('Tax Number is required'),
    first_name:
      stageType == 3 && Yup.string().required('First Name is required'),
    address: stageType == 3 && Yup.string().required('Address is required'),
    city: stageType == 3 && Yup.string().required('City is required'),
    zip_code: stageType == 3 && Yup.string().required('Zip code is required'),
    ccdc:
      stageType == 2 &&
      bankForm == 1 &&
      Yup.string()
        .min(12, 'CC/DC Number is too short')
        .max(19, 'CC/DC Number is too long')
        .required('CC/DC Number is required'),
    expire_date:
      stageType == 2 &&
      bankForm == 1 &&
      Yup.mixed().required('Expiration Date is required'),
    bank:
      stageType == 2 &&
      bankForm == 2 &&
      Yup.string().required('Bank Name is required'),
    nameOnAccount:
      stageType == 2 &&
      bankForm == 2 &&
      Yup.string().required('Name on Account is required'),
    routing:
      stageType == 2 &&
      bankForm == 2 &&
      Yup.string()
        .min(9, 'Routing Number is a nine digit')
        .required('Routing Number is required'),
    accountno:
      stageType == 2 &&
      bankForm == 2 &&
      Yup.string().required('Account Number is required'),
    swift:
      stageType == 2 &&
      bankForm == 2 &&
      Yup.string()
        .matches(
          /^[a-zA-Z0-9]{11}$/,
          'Swift Code must be 11 alphanumeric characters',
        )
        .required('Swift Code is required'),

    title: stageType == 4 && Yup.string().required('Title is required'),
    description:
      stageType == 4 && Yup.string().required('Description is required'),
    avatarUrl: stageType == 4 && Yup.mixed().required('Avatar is required'),
    sign_attach:
      stageType == 6 &&
      Yup.string().required('Please put your signature before accept!'),
  })
  //
  const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  }))
  //
  const ActivityComponent = ({ activity }) => {
    return (
      <Typography
        variant="body1"
        component="div"
        sx={{ '& strong': { fontWeight: 'bold' } }}
        dangerouslySetInnerHTML={{ __html: activity }}
      />
    )
  }
  const HandleAddExtraPortfolio = () => {
    setExtraPortfolio([...extraPorfolio, ''])
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ccdc: '',
      bank: '',
      nameOnAccount: '',
      routing: '',
      accountno: '',
      swift: '',
      account_type: [],
      expire_date: currentDate,
      avatarUrl: '' || null,
      approach: 'buisness' ? 1 : 2,

      title: '',
      description: '',
      avatarUrl: '',
      sign_attach: '',
      tax_num: '',
      first_name: '',
      last_name: '',
      states: [],
      address: '',
      city: '',
      zip_code: '',
      middle_name: '',
      tax_clarify: false ,
      buisness_name: 2,
      fieldTemplates: fieldTemplates ? fieldTemplates : [],
    },
    validationSchema: NewUserSchema,

    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        let res = null
        if (user.user_type == 3) {
          let sample = false
          if (stageType == 1 || stageType == 5) {
            Object.entries(fieldTemplates).map(([key, values]) => {
              if (
                // key !== 'checkboxgroup' &&
                values.required == 1 &&
                values.value == ''
              ) {
                sample = true
                enqueueSnackbar('Please fill the mandatory fields', {
                  variant: 'warning',
                })
              }
            })
          }
          if (sample == false) {
            res = await axios.post(`${REST_API_END_POINT}rfq/process`, {
              values,
              userId: user.user_id,
              userType:user.user_type,
              requestId:requestId,
              partnerStage_id: details?.stage?.partner_stage_id,
              partnerStageStatus: details?.stage?.flow_type === 1 ? 1 : 0,
              stage_submit: 1,
              stage_id: details?.stage?.id,
              position_order: details?.stage?.position_order,
              stage_type: stageType,
              workflow_id: details?.stage?.workflow_id,
              sign_agrement: details?.stage?.sign_agreement_ids || '',
            })
          }
        } else if (user.user_type !== 3 && user.user_type !== 1) {
          let teamMandatory = false
          if (stageType == 1 || stageType == 5) {
            Object.entries(fieldTemplates)?.map(([key, values]) => {
              if (
                // key !== 'checkboxgroup' &&
                values.required == 1 &&
                values.value == ''
              ) {
                teamMandatory = true
                enqueueSnackbar('Please fill the mandatory fields', {
                  variant: 'warning',
                })
              }
            })
          }
          if (teamMandatory == false) {
            res = await axios.post(
              `${REST_API_END_POINT}onboard/team-process`,
              {
                values,
                userId: user.user_id,
                partnerStage_id: details?.stage?.partner_stage_id,
                partnerStageStatus: details?.stage?.flow_type === 1 ? 1 : 0,
                stage_submit: 1,
                stage_id: details?.stage?.id,
                position_order: details?.stage?.position_order,
                stage_type: stageType,
                workflow_id: details?.stage?.workflow_id,
                sign_agrement: details?.stage?.sign_agreement_ids[0] || '',
              },
            )
          }
        }

        if (res?.data?.status == 1) {
          resetForm()
          setSubmitting(false)
          setFieldTemplates([])
          enqueueSnackbar('Submit success', {
            variant: 'success',
          })
          fetchProcessData()
          // navigate(PATH_DASHBOARD.onboard.process)
        } else {
          resetForm()
          enqueueSnackbar('Submit failed', {
            variant: 'error',
          })
        }
      } catch (error) {
        console.error(error)
        setSubmitting(false)
        setErrors(error)
      }
    },
  })
  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik

  const handleDrop = useCallback(
    (index, acceptedFiles) => {
      if (acceptedFiles) {
        const file = acceptedFiles[0]

        const Filename = new Date().getTime() + file.name

        const StorageRef = ref(storage, Filename)
        const uploadTask = uploadBytesResumable(StorageRef, file)

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            )
            setProgress(progress)
          },
          (error) => {
            console.error(error.message)
          },
          () => {
            const fieldName =
              index !== undefined ? `avatarUrl${index}` : 'avatarUrl'
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setFieldValue(fieldName, downloadURL)
              console.log(downloadURL, '&&&&&&&&&&&&&&&&&&&')
            })
          },
        )
      }
    },
    [setFieldValue],
  )
  const handleDropFile = useCallback(
    async (acceptedFiles, field_id, content) => {
      try {
        if (acceptedFiles && acceptedFiles.length > 0) {
          const file = acceptedFiles[0]
          const filename = new Date().getTime() + file.name

          const storageRef = ref(storage, filename)
          const uploadTask = uploadBytesResumable(storageRef, file)

          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const newProgress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
              )
              setProgress(newProgress)
            },
            (error) => {
              console.error('Error during upload:', error)
            },
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
              setFieldTemplates((prev) => ({
                ...prev,
                [content]: { field_id, value: downloadURL, content: 'file' },
              }))
              console.log(downloadURL, '&&&&&&&&&&&&&&&&&&&')
            },
          )
        }
      } catch (error) {
        console.error('Unexpected error:', error)
      }
    },
    [setProgress, setFieldTemplates],
  )

  //--------delete extra portfolio
  const handleDeleteportfolio = (index) => {
    console.log(index)
    const updatedList = [...extraPorfolio]
    delete values[`title${index}`]
    delete values[`description${index}`]
    delete values[`avatar${index}`]
    updatedList.splice(index, 1)

    setExtraPortfolio(updatedList)
  }
  const submitSignature = () => {
    let signature = mySignature.current
    if (signature.isEmpty()) {
      enqueueSnackbar('Please put your signature before accept!', {
        variant: 'warning',
      })
    } else {
      let attachment = signature.toDataURL()
      setFieldValue('sign_attach', attachment)
      signature.clear()
    }
  }

  {
    console.log(fieldTemplates, '========================fieldTemplates')
  }
  return (
    <Page>
      <Container>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mb: -2, mt: 2 }}
                >
                  {/* <HeaderBreadcrumbs heading="Credit Card/Debit Card Information" /> */}
                </Stack>
                {stageType == 3 ? (
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      How do you report your Tax Authority ?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="buisness"
                      value={values.type_of_stage}
                      onChange={(event, newValue) =>
                        setFieldValue('approach', newValue)
                      }
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="buisness"
                        control={<Radio />}
                        label="As a Buisness"
                      />
                      <FormControlLabel
                        value="individual"
                        control={<Radio />}
                        label="As an Individual"
                      />
                    </RadioGroup>
                  </FormControl>
                ) : stageType == 2 && bankForm == 1 ? (
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={3}>
                      <Stack>
                        <LabelStyle>CC/DC Number</LabelStyle>
                        <TextField
                          fullWidth
                          required
                          type="number"
                          label="CC/DC Number"
                          {...getFieldProps('ccdc')}
                          error={Boolean(touched.ccdc && errors.ccdc)}
                          helperText={touched.ccdc && errors.ccdc}
                        />
                      </Stack>
                      <Box
                        component={MobileDatePicker}
                        label="Expiration Date"
                        minDate={currentDate}
                        value={values.expire_date}
                        onChange={(date) => setFieldValue('expire_date', date)}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </Stack>
                  </Card>
                ) : null}

                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    {stageType == 3 ? (
                      <>
                        <Stack
                          direction={{ xs: 'column', sm: 'row' }}
                          spacing={{ xs: 3, sm: 2 }}
                        >
                          <TextField
                            fullWidth
                            type="number"
                            required
                            placeholder="TAX/VAT Number"
                            label="TAX/VAT Number"
                            {...getFieldProps('tax_num')}
                            error={Boolean(touched.tax_num && errors.tax_num)}
                            helperText={touched.tax_num && errors.tax_num}
                          />
                          <TextField
                            fullWidth
                            label="First Name"
                            required
                            {...getFieldProps('first_name')}
                            error={Boolean(
                              touched.first_name && errors.first_name,
                            )}
                            helperText={touched.first_name && errors.first_name}
                          />
                        </Stack>
                        <Stack
                          direction={{ xs: 'column', sm: 'row' }}
                          spacing={{ xs: 3, sm: 2 }}
                        >
                          <TextField
                            fullWidth
                            placeholder="Middle Name"
                            label="Middle Name"
                            {...getFieldProps('middle_name')}
                            error={Boolean(
                              touched.middle_name && errors.middle_name,
                            )}
                            helperText={
                              touched.middle_name && errors.middle_name
                            }
                          />
                          <TextField
                            fullWidth
                            placeholder="Last Name"
                            label="Last Name"
                            {...getFieldProps('last_name')}
                            error={Boolean(
                              touched.last_name && errors.last_name,
                            )}
                            helperText={touched.last_name && errors.last_name}
                          />
                        </Stack>
                        <Stack
                          direction={{ xs: 'column', sm: 'row' }}
                          spacing={{ xs: 3, sm: 2 }}
                        >
                          <TextField
                            fullWidth
                            placeholder="Address"
                            required
                            label="Address"
                            {...getFieldProps('address')}
                            error={Boolean(touched.address && errors.address)}
                            helperText={touched.address && errors.address}
                          />
                          <TextField
                            fullWidth
                            placeholder="City"
                            label="City"
                            {...getFieldProps('city')}
                            error={Boolean(touched.city && errors.city)}
                            helperText={touched.city && errors.city}
                          />
                        </Stack>
                        <Stack
                          direction={{ xs: 'column', sm: 'row' }}
                          spacing={{ xs: 3, sm: 2 }}
                        >
                          <Autocomplete
                            select
                            fullWidth
                            id="state"
                            options={states}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) =>
                              setFieldValue('states', newValue)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="State"
                                placeholder="Select State"
                                fullWidth
                                SelectProps={{ native: true }}
                              />
                            )}
                          >
                            <option value="" />
                            {states?.map((option) => (
                              <option key={option.id} value={option.name}>
                                {option.name}
                              </option>
                            ))}
                          </Autocomplete>
                          <TextField
                            fullWidth
                            type="number"
                            placeholder="Zip code"
                            label="Zip code"
                            {...getFieldProps('zip_code')}
                            error={Boolean(touched.zip_code && errors.zip_code)}
                            helperText={touched.zip_code && errors.zip_code}
                          />
                        </Stack>
                        <Stack
                          direction={{ xs: 'column', sm: 'row', md: 'column' }}
                          spacing={{ xs: 3, sm: 2 }}
                        >
                          <LabelStyle>Federal tax clarification</LabelStyle>                         
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.tax_clarify}
                                // {...getFieldProps('tax_clarify')}
                                onChange={(e) => {                              
                                  setFieldValue('tax_clarify', e.target.checked);
                                }}
                              />
                            }
                            label="Induividual/Sole Proprietor"
                          />
                        </Stack>
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label">
                            Do you have a Buisness name
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="Yes"
                            value={values.buisness_name}
                            onChange={(event, newValue) =>
                              setFieldValue('buisness_name', newValue)
                            }
                            name="radio-buttons-group"
                          >
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="2"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </>
                    ) : stageType == 2 && bankForm == 2 ? (
                      <>
                        <Stack
                          direction={{ xs: 'column', sm: 'row' }}
                          spacing={{ xs: 3, sm: 2 }}
                        >
                          <TextField
                            placeholder="Bank Name"
                            fullWidth
                            label="Bank Name"
                            {...getFieldProps('bank')}
                            error={Boolean(touched.bank && errors.bank)}
                            helperText={touched.bank && errors.bank}
                          />
                          <TextField
                            fullWidth
                            placeholder="Name on Account"
                            label="Name on Account"
                            {...getFieldProps('nameOnAccount')}
                            error={Boolean(
                              touched.nameOnAccount && errors.nameOnAccount,
                            )}
                            helperText={
                              touched.nameOnAccount && errors.nameOnAccount
                            }
                          />
                        </Stack>
                        <Stack
                          direction={{ xs: 'column', sm: 'row' }}
                          spacing={{ xs: 3, sm: 2 }}
                        >
                          <TextField
                            fullWidth
                            type="number"
                            placeholder="Routing Number"
                            label="Routing Number"
                            {...getFieldProps('routing')}
                            error={Boolean(touched.routing && errors.routing)}
                            helperText={touched.routing && errors.routing}
                          />
                          <TextField
                            fullWidth
                            type="number"
                            placeholder="Account Number"
                            label="Account Number"
                            {...getFieldProps('accountno')}
                            error={Boolean(
                              touched.accountno && errors.accountno,
                            )}
                            helperText={touched.accountno && errors.accountno}
                          />
                        </Stack>
                        <Stack
                          direction={{ xs: 'column', sm: 'row' }}
                          spacing={{ xs: 3, sm: 2 }}
                        >
                          <Autocomplete
                            select
                            fullWidth
                            id="combo-box-demo"
                            options={AccountTypeOnboardClient || []}
                            onChange={(event, newValue) =>
                              setFieldValue('account_type', newValue)
                            }
                            renderInput={(params) => (
                              <TextField {...params} label="Account Type" />
                            )}
                            getOptionLabel={(option) => option.label}
                          />
                          <TextField
                            fullWidth
                            placeholder="Swift Code"
                            label="Swift Code"
                            {...getFieldProps('swift')}
                            error={Boolean(touched.swift && errors.swift)}
                            helperText={touched.swift && errors.swift}
                          />
                        </Stack>
                      </>
                    ) : stageType == 4 ? (
                      <>
                        <Stack>
                          <TextField
                            fullWidth
                            label="Title"
                            {...getFieldProps('title')}
                            error={Boolean(touched.title && errors.title)}
                            helperText={touched.title && errors.title}
                          />
                        </Stack>

                        <TextField
                          fullWidth
                          placeholder="Description"
                          multiline
                          minRows={3}
                          maxRows={7}
                          label="keep the summary brief and make it communicates"
                          {...getFieldProps('description')}
                          error={Boolean(
                            touched.description && errors.description,
                          )}
                          helperText={touched.description && errors.description}
                        />
                        <UploadAvatar
                          accept="image/*"
                          file={values.avatarUrl}
                          maxSize={3145728}
                          onDrop={(acceptedFiles) =>
                            handleDrop(undefined, acceptedFiles)
                          }
                          error={Boolean(touched.avatarUrl && errors.avatarUrl)}
                        />
                        {extraPorfolio?.map((_, index) => {
                          return (
                            <Stack key={index}>
                              <Box
                                sx={{
                                  boxShadow: `0 0 3px 0 ${theme.palette.primary.dark}`,
                                  pt: 1,
                                  px: 2,
                                  mb: 2,
                                  borderRadius: 1,
                                }}
                              >
                                <Button
                                  variant="text"
                                  sx={{
                                    width: 'fit-content',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                                  startIcon={<Icon icon={trash2Fill} />}
                                  onClick={() => handleDeleteportfolio(index)}
                                />
                                <Stack
                                  direction={{ xs: 'column', sm: 'row' }}
                                  m={2}
                                  spacing={{ xs: 3, sm: 2 }}
                                >
                                  <TextField
                                    fullWidth
                                    label="Title"
                                    {...getFieldProps(`title${index}`)}
                                    error={Boolean(
                                      touched[`title${index}`] &&
                                        errors[`title${index}`],
                                    )}
                                    helperText={
                                      touched[`title${index}`] &&
                                      errors[`title${index}`]
                                    }
                                  />
                                </Stack>
                                <Stack
                                  // direction={{ xs: 'column', sm: 'row' }}
                                  m={2}
                                  spacing={{ xs: 3, sm: 2 }}
                                >
                                  <TextField
                                    fullWidth
                                    multiline
                                    minRows={3}
                                    maxRows={7}
                                    placeholder="Description"
                                    label="keep the summary brief and make it communicates"
                                    {...getFieldProps(`description${index}`)}
                                    error={Boolean(
                                      touched[`description${index}`] &&
                                        errors[`description${index}`],
                                    )}
                                    helperText={
                                      touched[`description${index}`] &&
                                      errors[`description${index}`]
                                    }
                                  />
                                  <UploadAvatar
                                    sx={{ m: 2 }}
                                    accept="image/*"
                                    file={values[`avatarUrl${index}`]}
                                    maxSize={3145728}
                                    onDrop={(acceptedFiles) =>
                                      handleDrop(index, acceptedFiles)
                                    }
                                    error={Boolean(
                                      touched[`avatarUrl${index}`] &&
                                        errors[`avatarUrl${index}`],
                                    )}
                                  />
                                </Stack>
                              </Box>
                            </Stack>
                          )
                        })}
                        <Box
                          sx={{
                            mt: 3,
                            display: 'flex',
                            justifyContent: 'flex-start',
                            gap: 1,
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={HandleAddExtraPortfolio}
                          >
                            Add More
                          </Button>
                        </Box>
                      </>
                    ) : stageType == 6 ? (
                      <>
                          {details?.stage?.sign_agreements.map((agreement)=>(   
                        <Stack>
                          {/* <TextField fullWidth value={name} disabled /> */}
                          <Typography gutterBottom  variant='caption'>{agreement.name}</Typography>
                          <Typography
                            fullWidth                                       
                            variant="subtitle1"
                            component="div"
                            sx={{ '& strong': { fontWeight: 'bold' } }}
                            dangerouslySetInnerHTML={{
                              __html:
                              agreement?.content,
                            }}
                          />
                        </Stack>
                        ))}
                        <Box
                          sx={{
                            width: `fit-content`,
                            maxWidth: '-moz-fit-content',
                            mx: 'auto',
                            borderRadius: 1,
                          }}
                        >
                          <SignatureCanvas
                            ref={mySignature}
                            penColor="#4a4a4a"
                            canvasProps={{
                              width: 300,
                              height: 200,
                              className: 'sigCanvas',
                              style: {
                                borderRadius: 8,
                                border: '1px solid #a4a4a4',
                              },
                            }}
                          />
                        </Box>
                      </>
                    ) : stageType == 1 || stageType == 5 ? (
                      <>
                        {templatefield[0]?.fields
                          ? Object.entries(templatefield[0]?.fields).map(
                              ([key, value]) => {
                                console.log(
                                  value,
                                  '--------------------values of stage type',
                                )
                                const {
                                  tag,
                                  config,
                                  content,
                                  field_id,
                                  required,
                                } = value
                                switch (tag) {
                                  case 'paragraph':
                                    return (
                                      <Typography
                                        key={value.id}
                                        variant="subtitle2"
                                      >
                                        <ActivityComponent
                                          activity={content ? content : ''}
                                        />
                                      </Typography>
                                    )
                                  case 'header':
                                    return (
                                      <Typography
                                        key={value.id}
                                        variant="subtitle1"
                                      >
                                        <ActivityComponent
                                          activity={content ? content : ''}
                                        />
                                      </Typography>
                                    )
                                  case 'textarea':
                                    return (
                                      <TextField
                                        key={value.id}
                                        label={<div 
                                          style={{ whiteSpace: 'pre-wrap' }}
                                           dangerouslySetInnerHTML={{ __html: content }} />}
                                        // placeholder={<div 
                                        //   style={{ whiteSpace: 'pre-wrap' }}
                                        //    dangerouslySetInnerHTML={{ __html: content }} />}
                                        id="Textarea"
                                        fullWidth
                                        multiline
                                        value={fieldTemplates[content]?.value}
                                        minRows={3}
                                        required={required == 1 ? true : false}
                                        maxRows={7}
                                        onChange={(event) =>
                                          setFieldTemplates(
                                            (prevTemplates) => ({
                                              ...prevTemplates,
                                              [content]: {
                                                required: required,
                                                field_id,
                                                content: 'textarea',
                                                value: event.target.value,
                                              },
                                            }),
                                          )
                                        }
                                        sx={{
                                          width: '100%',
                                          marginTop: '8px',
                                          padding: '8px',
                                        }}
                                      />
                                    )
                                  case 'text':
                                    return (
                                      <TextField
                                        key={value.id}
                                        label={<div 
                                          style={{ whiteSpace: 'pre-wrap' }}
                                           dangerouslySetInnerHTML={{ __html: content }} />}
                                        // placeholder={<div 
                                        //   style={{ whiteSpace: 'pre-wrap' }}
                                          //  dangerouslySetInnerHTML={{ __html: content }} />}
                                        fullWidth
                                        id={'text'}
                                        required={required == 1 ? true : false}
                                        value={fieldTemplates[content]?.value}
                                        onChange={(event) =>
                                          setFieldTemplates(
                                            (prevTemplates) => ({
                                              ...prevTemplates,
                                              [content]: {
                                                field_id,
                                                content: 'text',
                                                required: required,
                                                value: event.target.value,
                                              },
                                            }),
                                          )
                                        }
                                        sx={{
                                          width: '100%',
                                          marginTop: '8px',
                                          padding: '8px',
                                        }}
                                      />
                                    )
                                  case 'date':
                                    return (
                                      <Box
                                        key={content}
                                        component={MobileDatePicker}
                                        label={<div 
                                          style={{ whiteSpace: 'pre-wrap' }}
                                           dangerouslySetInnerHTML={{ __html: content }} />}
                                        placeholder={<div 
                                          style={{ whiteSpace: 'pre-wrap' }}
                                           dangerouslySetInnerHTML={{ __html: content }} />}
                                        // minDate={moment()}
                                        value={
                                          fieldTemplates[content]?.value || null
                                        }
                                        onChange={(date) =>
                                          setFieldTemplates(
                                            (prevTemplates) => ({
                                              ...prevTemplates,
                                              [content]: {
                                                field_id,
                                                content: 'date',
                                                required: required,
                                                value:
                                                  moment(date).format(
                                                    'YYYY-MM-DD',
                                                  ),
                                              },
                                            }),
                                          )
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            required={
                                              required == 1 ? true : false
                                            }
                                            size="small"
                                            {...params}
                                            fullWidth
                                          />
                                        )}
                                      />
                                    )
                                  case 'number':
                                    return (
                                      <TextField
                                        key={value.id}
                                        label={<div 
                                          style={{ whiteSpace: 'pre-wrap' }}
                                           dangerouslySetInnerHTML={{ __html: content }} />}
                                        // placeholder={<div 
                                        //   style={{ whiteSpace: 'pre-wrap' }}
                                        //    dangerouslySetInnerHTML={{ __html: content }} />}
                                        required={required == 1 ? true : false}
                                        type="number"
                                        fullWidth
                                        value={fieldTemplates[content]?.value}
                                        onChange={(event) =>
                                          setFieldTemplates(
                                            (prevTemplates) => ({
                                              ...prevTemplates,
                                              [content]: {
                                                field_id,
                                                content: 'number',
                                                required: required,
                                                value: event.target.value,
                                              },
                                            }),
                                          )
                                        }
                                      />
                                    )
                                  case 'select':
                                    return (
                                      <FormControl>                                   
                                      <FormLabel 
                                      required={required ==1 ? true:false}
                                       id="demo-select-label">
                                        {<div 
                                        style={{ whiteSpace: 'pre-wrap' }}
                                         dangerouslySetInnerHTML={{ __html: content }} />}
                                      </FormLabel>
                                      <Select
                                      label={<div 
                                        style={{ whiteSpace: 'pre-wrap' }}
                                         dangerouslySetInnerHTML={{ __html: content }} />}
                                      placeholder={<div 
                                        style={{ whiteSpace: 'pre-wrap' }}
                                         dangerouslySetInnerHTML={{ __html: content }} />}
                                      // value={fieldTemplates[content]?.value}
                                        onChange={(event,newValue) =>{
                                          console.log("$$$$$NewB",newValue.props.children),
                                          setFieldTemplates(
                                            (prevTemplates) => ({
                                              ...prevTemplates,
                                              [content]: {
                                                field_id,content:'select',
                                                value: newValue?.props?.children,
                                              },
                                            }),
                                          )
                                        }}
                                      >
                                        {value?.options?.map((data) => (
                                          <MenuItem
                                            key={data.value}
                                            value={data.value}
                                          >
                                            {data.label}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      </FormControl>
                                    )
                                  case 'file':
                                    return (
                                      <>
                                       
                                        <LabelStyle><div 
                                          style={{ whiteSpace: 'pre-wrap' }}
                                           dangerouslySetInnerHTML={{ __html: content }} /></LabelStyle>
                                        <UploadSingleFile
                                          key={content}
                                          maxSize={3145728}
                                          accept="image/*"
                                          file={fieldTemplates[content]?.value}
                                          onDrop={(acceptedFiles) =>
                                            handleDropFile(
                                              acceptedFiles,
                                              field_id,
                                              content,
                                            )
                                          }
                                          error={Boolean(
                                            touched.coverImage &&
                                              errors.coverImage,
                                          )}
                                        />
                                      </>
                                    )
                                  case 'autocomplete':
                                    return (
                                      <FormControl>
                                        <FormLabel
                                          required={
                                            required == 1 ? true : false
                                          }
                                          id="demo-autocomplete-label"
                                        >
                                        
                                        </FormLabel>
                                        <Autocomplete
                                          fullWidth
                                          id="combo-box-demo"
                                          // value={fieldTemplates[content]?.value}
                                          options={value?.options || []}
                                          onChange={(event, newValue) =>
                                            setFieldTemplates(
                                              (prevTemplates) => ({
                                                ...prevTemplates,
                                                [content]: {
                                                  value: newValue?.label,
                                                  content: 'autocomplete',
                                                  field_id,
                                                },
                                              }),
                                            )
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label={value?.tag}
                                            />
                                          )}
                                          getOptionLabel={(option) =>
                                            option.label
                                          }
                                        />
                                      </FormControl>
                                    )
                                  case 'radio-group':
                                    return (
                                      <FormControl>
                                        <FormLabel  required={
                                            required == 1 ? true : false
                                          } id="demo-radio-buttons-group-label">
                                         <div 
                                          style={{ whiteSpace: 'pre-wrap' }}
                                           dangerouslySetInnerHTML={{ __html: content }} />
                                        </FormLabel>
                                        <RadioGroup
                                          aria-labelledby="demo-radio-buttons-group-label"
                                          name="radio-buttons-group"
                                          onChange={(event) =>
                                            setFieldTemplates(
                                              (prevTemplates) => ({
                                                ...prevTemplates,
                                                [content]: {
                                                  field_id,
                                                  content: 'radiogroup',
                                                  value: event?.target?.value,
                                                },
                                              }),
                                            )
                                          }
                                        >
                                          {value?.options?.map((data) => (
                                            <FormControlLabel
                                              key={data.value}
                                              value={data.label}
                                              control={<Radio />}
                                              label={data.label}
                                            />
                                          ))}
                                        </RadioGroup>
                                      </FormControl>
                                    )
                                  case 'checkbox-group':
                                    return (
                                      <FormGroup>
                                        <FormLabel  id="checkbox-group">
                                        <div 
                                          style={{ whiteSpace: 'pre-wrap' }}
                                           dangerouslySetInnerHTML={{ __html: content }} />
                                        </FormLabel>
                                        {value?.options?.map((data) => (
                                          <FormControlLabel
                                            key={data?.value}
                                            control={
                                              <Checkbox
                                              checked={fieldTemplates?.content?.some((item) => {                                           
                                                return item?.label === data?.value;
                                              })}
                                                onChange={(event) => {
                                                  const isChecked =
                                                    event.target.checked
                                                    setFieldTemplates((prevTemplates) => {
                                                      const currentContent = Array.isArray(prevTemplates?.[content])
                                                        ? prevTemplates?.[content]
                                                        : [prevTemplates?.[content]]; 
                                                    
                                                      if (isChecked) {
                                                        return {
                                                          ...prevTemplates,
                                                          [content]: [...currentContent, { field_id, label: data?.value, value: data?.label }],
                                                        };
                                                      } else {
                                                        return {
                                                          ...prevTemplates,
                                                          [content]: currentContent.filter((value) => value.label !== data?.value),
                                                        };
                                                      }
                                                    });
                                                    
                                                }}
                                              />
                                            }
                                            label={data?.label}
                                          />
                                        ))}
                                      </FormGroup>
                                    )

                                  default:
                                    return null
                                }
                              },
                            )
                          : null}
                      </>
                    ) : null}

                    <Box
                      sx={{
                        mt: 3,
                        display: 'flex',
                        justifyContent:
                          stageType == 6 ? 'flex-start' : 'center',
                        gap: 1,
                      }}
                    >
                      {stageType == 6 ? (
                        <>
                          <Button
                            variant="contained"
                            onClick={() => {
                              const signature = mySignature.current
                              signature.clear()
                            }}
                            sx={{ marginLeft: '10px' }}
                          >
                            Clear
                          </Button>
                          <LoadingButton
                            type="submit"
                            variant="contained"
                            onClick={submitSignature}
                            loading={isSubmitting}
                          >
                            Submit
                          </LoadingButton>
                        </>
                      ) : !onboardComplete ? (
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          loading={isSubmitting}
                        >
                          Submit
                        </LoadingButton>
                      ) : (
                        <Typography variant="subtitle1"> </Typography>
                      )}
                      {/* <Button
                        variant="contained"
                        type="submit"
                        component={RouterLink}
                        to={PATH_DASHBOARD.onboard.process}
                        sx={{ marginLeft: '10px' }}
                      >
                        Save Changes
                      </Button> */}
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  )
}
