import { orderBy } from 'lodash';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useEffect, useCallback, useState, useRef, useMemo } from 'react';
import { useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import searchFill from '@iconify/icons-eva/search-fill';
// material
import { Box, Grid, Button, Skeleton, Container, Stack,Checkbox, ListItemText, Popover, Tabs, Tab, Typography, Menu, MenuItem, OutlinedInput, InputAdornment, Badge, TextField, FormHelperText } from '@material-ui/core';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import { getPostsInitial, getMorePosts } from '../../redux/slices/blog';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../../components/_dashboard/blog';
import { UserCard } from 'src/components/_dashboard/user/cards';
import AddProject from 'src/components/_dashboard/blog/AddProject';
import { capitalCase } from 'change-case';
import { MButton } from 'src/components/@material-extend';
import { Visibility, VisibilityOff,List,ViewList, ViewModule, ExpandMore, ExpandLess, KeyboardBackspaceOutlined } from '@material-ui/icons';
import ProductionControll from 'src/components/_dashboard/blog/ProductionControl';
import ProjectGrid from 'src/components/_dashboard/blog/ProjectGridList';
import ProjectList from 'src/components/_dashboard/blog/ProjectListView';
import axios from 'axios';
import { COMPANY_NAME, REST_API_END_POINT } from 'src/constants/Defaultvalues';
import useWorkSpaceOption from 'src/hooks/useWorkspaceOptions';
import { MobileDateRangePicker } from '@material-ui/lab';
import moment from 'moment';
import Scrollbar from 'src/components/Scrollbar';

// ----------------------------------------------------------------------

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

const applySort = (posts, sortBy) => {
  if (sortBy === 'latest') {
    return orderBy(posts, ['createdAt'], ['desc']);
  }
  if (sortBy === 'oldest') {
    return orderBy(posts, ['createdAt'], ['asc']);
  }
  if (sortBy === 'popular') {
    return orderBy(posts, ['view'], ['desc']);
  }
  return posts;
};

const SkeletonLoad = (
  <Grid container spacing={3} sx={{ mt: 2 }}>
    {[...Array(4)].map((_, index) => (
      <Grid item xs={12} md={3} key={index}>
        <Skeleton variant="rectangular" width="100%" sx={{ height: 200, borderRadius: 2 }} />
        <Box sx={{ display: 'flex', mt: 1.5 }}>
          <Skeleton variant="circular" sx={{ width: 40, height: 40 }} />
          <Skeleton variant="text" sx={{ mx: 1, flexGrow: 1 }} />
        </Box>
      </Grid>
    ))}
  </Grid>
);
//  
const SORT = [
  { id: 'custom_order', label: 'Custom Order', color: '#ffff00' },
  { id: 'new', label: 'Newest', color: '#54D62C' }, 
  { id: 'old', label: 'Oldest', color: '#ffff00' },
  { id: 'last_updated', label: 'Recently Updated', color: '#ffff00' },
];

export default function CommunityJobs() {
  const theme = useTheme()
  const { workSpaceTitle } = useWorkSpaceOption()
  const ref = useRef(null);
  const location = useLocation()
  const communityId = location.state && location.state?.id
  const communityName = location.state && location.state.name;
  const projectsClients = location.state && location.state.clients;
  const projectsClientsArray = projectsClients ? projectsClients.split(',').map(id => id.trim()) : [];
  const refcategory = useRef(null);
  const dispatch = useDispatch();
  const [types,setTypes]  = useState([])
  const [categories,setCategories]  = useState([])
  const [filters, setFilters] = useState('latest');
  const [filterName, setFilterName] = useState('');
  const [currentTab, setCurrentTab] = useState('Pre-Production');
  const [renderCards, setRenderCards] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [add,setAdd] = useState(false)
  const [isEdit,setIsEdit] = useState(false)
  const [all,setAll] = useState(false)
  const [openType,setOpenType] = useState(false)
  const [openCategory,setOpencategory] = useState(false)
  const [data,setData] = useState([])
  const [singleProjectData,setSingleProjectData] = useState(null)
  const [filtered,setFiltered] = useState([])
  const [searchData,setSearchData] = useState([])
  const { posts, hasMore, index, step } = useSelector((state) => state.blog);
  const sortedPosts = applySort(posts, filters);
  const onScroll = useCallback(() => dispatch(getMorePosts()), [dispatch]);
  const [productionCount,setProductionCount] = useState(0)
  const [holdCount,setHoldCount] = useState(0)
  const [preCount,setPreCount] = useState(0)
  const [postCount,setPostCount] = useState(0)
  const [filteredInstallations,setFilteredInstallations] = useState([])
  const [filteredPickup,setFilteredPickup] = useState([])
  const [filteredShipping,setFilteredShipping] = useState([])
  const [filteredCompletedInstallations,setFilteredCompletedInstallations] = useState([])
  const [value, setValue] = useState('one')

  const refCommunities = useRef(null);
  const [communitiesOpen, setCommunitiesOpen] = useState(false);
  const [communitiesData, setCommunitiesData] = useState([]);
  const [filterCommunity, setFilterCommunity] = useState([communityId]);
  const [installations,setInstallations]=useState([])
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const refClients = useRef(null);
  const [clientOpen, setClientOpen] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [filterClient, setFilterClient] = useState([]);
  const [sortOpen, setSortOpen] = useState(false);
  const refSort = useRef(null);
  const [sort,setSort]=  useState(SORT[1])
  const [filterDateValue,setFilterDateValue]=  useState([null, null]);
  const [dueData,setDueData] = useState(true)
  const openPopupTemp=localStorage.getItem("openProductionPopup")
  console.log('openPopupTemp===',openPopupTemp)
  const [openViewPage, setOpenViewPage] = useState(openPopupTemp||false);
  const [projectId, setProjectId] = useState('');
  const [projectName,setProjectName]=useState('')
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const removePopupData=()=>{
    setProjectId('');
    setProjectName('');
  }

  const  user  = JSON.parse(window.localStorage.getItem('user'))
  const explodedPermissions =user?.exploded_permissions
  const hasPermission  = explodedPermissions?.some(permission => ['View Jobs','Add Jobs','Edit Jobs','Activate/Deactivate Jobs','Delete Jobs'].includes(permission)) ? true : false

  const memoizedFilteredData = useMemo(() => {
    return data.filter((item) => {
      const nameMatch = filterName ? item.name.toLowerCase().includes(filterName.toLowerCase()) : true;
      const typeMatch = types.some((t) => t.checked) ? types.filter((t) => t.checked).map((t) => t.id).includes(item.project_type) : true;
      const categoryMatch = categories.some((c) => c.checked) ? categories.filter((c) => c.checked).map((c) => c.id).includes(item.project_category) : true;
      return nameMatch && typeMatch && categoryMatch;
    });
  }, [data, filterName, types, categories]);

  useEffect(() => {
    setSearchData(memoizedFilteredData);
  }, [memoizedFilteredData]);

  useEffect(() => {
    // const fetchData = async () => {
    //   setIsLoading(true);
    //   try {
    //     const fromDate = filterDateValue[0] ? moment(filterDateValue[0])?.format('YYYY-MM-DD') : null;
    //     const toDate = filterDateValue[1] ? moment(filterDateValue[1])?.format('YYYY-MM-DD') : null;
    //     const projectResponse = await axios.post(`${REST_API_END_POINT}project/get-all-projects`,
    //       {
    //         userType: { value: user.user_type },
    //         userId: { value: user.user_id },
    //         hasPermission,
    //         sort: sort.id,
    //         from:fromDate,
    //         to:toDate,
    //         dueData:dueData
    //       },
    //       {
    //         headers: {
    //           'Content-Type': 'application/json',
    //         },
    //       });

    //       const completedInstallations = projectResponse?.data?.completedInstallations;
    //       // Count installations per community
    //       const installationCounts = completedInstallations?.reduce((acc, installation) => {
    //         acc[installation.community] = (acc[installation.community] || 0) + 1;
    //         return acc;
    //       }, {});

    //       // Merge installation counts into community data
    //       const communities = projectResponse?.data?.community?.map(community => ({
    //         ...community,
    //         no_of_projects: (community.no_of_projects || 0) + (installationCounts[community.id] || 0)
    //       }));

    //       const othersCommunity = { id: 'other', name: 'Others' };
    //       if (communities) {
    //         console.log('projectResponse?.data?.community---------',projectResponse?.data?.community);
    //         console.log('communities---------',communities);
    //         setCommunitiesData([...communities, othersCommunity]);
    //       } else {
    //         setCommunitiesData([othersCommunity]);
    //       }

    //     setData(projectResponse.data.results);
    //     const inititialData = projectResponse.data.results?.filter(item => ((item.status === 1 || item.status === 0 ) && item.jobcost===0))
    //     console.log('inititialData------',inititialData);
    //     setFiltered(projectResponse.data.results.filter(item => ((item.status === 1 || item.status === 0 ) && item.jobcost===0)));
    //     // setFiltered(projectResponse.data.results.filter(item => item.status === 1 || item.status === 0));
    //     setSearchData(inititialData);
    //     // setSearchData(projectResponse.data.results.filter(item => item.status === 1 || item.status === 0));
    //     setInstallations(projectResponse.data.completedInstallations);
    //     console.log('dataaaaaaaaaaaaaaaaaaaaacompletedInstallations',projectResponse.data.completedInstallations)

    //     // const othersCommunity = { id: 'other', name: 'Others' };
    //     // if (projectResponse.data?.community) {
    //     //   setCommunitiesData([...projectResponse.data.community, othersCommunity]);
    //     // } else {
    //     //   setCommunitiesData([othersCommunity]);
    //     // }

    //     const categoryResponse = await axios.post(`${REST_API_END_POINT}project/get-all-project-categories`, {
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //     });

    //     if (categoryResponse.data.status === 1) {
    //       setTypes(categoryResponse.data.projectTypes.map(type => ({ ...type, checked: false })));
    //       setCategories(categoryResponse.data.projectCategories.map(category => ({ ...category, checked: false })));
    //     }


    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   } finally {
    //     setTimeout(() => {
    //       setIsLoading(false);
    //     }, 1000);
    //   }
    //   setRenderCards(false);
    // };
    // const fetchData = async () => {
    //   setIsLoading(true);
    //   try {
    //     const fromDate = filterDateValue[0]
    //       ? moment(filterDateValue[0])?.format('YYYY-MM-DD')
    //       : null;
    //     const toDate = filterDateValue[1]
    //       ? moment(filterDateValue[1])?.format('YYYY-MM-DD')
    //       : null;
    
    //     // 1) Fetch main project data
    //     const projectResponse = await axios.post(
    //       `${REST_API_END_POINT}project/get-all-projects`,
    //       {
    //         userType: { value: user.user_type },
    //         userId: { value: user.user_id },
    //         hasPermission,
    //         sort: sort.id,
    //         from: fromDate,
    //         to: toDate,
    //         dueData: dueData
    //       },
    //       {
    //         headers: {
    //           'Content-Type': 'application/json'
    //         }
    //       }
    //     );
    
    //     // (Optional) completedInstallations logic
    //     const completedInstallations = projectResponse?.data?.completedInstallations || [];
    //     const installationCounts = completedInstallations.reduce((acc, installation) => {
    //       acc[installation.community] = (acc[installation.community] || 0) + 1;
    //       return acc;
    //     }, {});
    
    //     // Merge installation counts into community data
    //     const fetchedCommunities = (projectResponse?.data?.community || []).map((community) => ({
    //       ...community,
    //       no_of_projects: (community.no_of_projects || 0) + (installationCounts[community.id] || 0)
    //     }));
    
    //     const othersCommunity = { id: 'other', name: 'Others' };
    //     fetchedCommunities.push(othersCommunity); // add "others" at the end
    
    //     // 2) Update local states for raw project data
    //     setData(projectResponse.data.results);
    //     setFiltered(
    //       projectResponse.data.results.filter(
    //         (item) => (item.status === 1 || item.status === 0) && item.jobcost === 0
    //       )
    //     );
    //     setSearchData(
    //       projectResponse.data.results.filter(
    //         (item) => (item.status === 1 || item.status === 0) && item.jobcost === 0
    //       )
    //     );
    //     setInstallations(projectResponse.data.completedInstallations || []);
    
    //     // 3) Fetch job classes, categories, etc.
    //     const categoryResponse = await axios.post(
    //       `${REST_API_END_POINT}project/get-all-project-categories`,
    //       {
    //         headers: {
    //           'Content-Type': 'application/json'
    //         }
    //       }
    //     );
    
    //     // 4) Merge newly fetched data with previously checked states
    //     //    so user selections persist on refetch.
    
    //     // ============ Communities ============
    //     setCommunitiesData((oldCommunities) =>
    //       fetchedCommunities.map((comm) => {
    //         // Find any old item with the same ID
    //         const old = oldCommunities.find((o) => o.id === comm.id);
    //         return {
    //           ...comm,
    //           // If old was found, reuse its `checked` value; else default to false
    //           checked: old ? old.checked : false
    //         };
    //       })
    //     );
    
    //     // ============ Types ============
    //     if (categoryResponse.data.status === 1) {
    //       const fetchedTypes = categoryResponse.data.projectTypes || [];
    
    //       setTypes((oldTypes) =>
    //         fetchedTypes.map((type) => {
    //           const old = oldTypes.find((o) => o.id === type.id);
    //           return {
    //             ...type,
    //             checked: old ? old.checked : false
    //           };
    //         })
    //       );
    
    //       // ============ Categories ============
    //       const fetchedCats = categoryResponse.data.projectCategories || [];
    
    //       setCategories((oldCats) =>
    //         fetchedCats.map((cat) => {
    //           const old = oldCats.find((o) => o.id === cat.id);
    //           return {
    //             ...cat,
    //             checked: old ? old.checked : false
    //           };
    //         })
    //       );
    //     }
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   } finally {
    //     setTimeout(() => {
    //       setIsLoading(false);
    //     }, 1000);
    //   }
    //   setRenderCards(false);
    // };
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const fromDate = filterDateValue[0]
          ? moment(filterDateValue[0])?.format('YYYY-MM-DD')
          : null;
        const toDate = filterDateValue[1]
          ? moment(filterDateValue[1])?.format('YYYY-MM-DD')
          : null;
    
        const projectResponse = await axios.post(
          `${REST_API_END_POINT}project/get-all-projects`,
          {
            userType: { value: user.user_type },
            userId: { value: user.user_id },
            hasPermission,
            sort: sort.id,
            from: fromDate,
            to: toDate,
            dueData: false
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
    
        const completedInstallations = projectResponse?.data?.completedInstallations || [];
        const installationCounts = completedInstallations.reduce((acc, installation) => {
          acc[installation.community] = (acc[installation.community] || 0) + 1;
          return acc;
        }, {});
    
        // const fetchedCommunities = (projectResponse?.data?.community || []).map((community) => ({
        //   ...community,
        //   no_of_projects: (community.no_of_projects || 0) + (installationCounts[community.id] || 0)
        // }));
        const filteredProject = projectResponse.data.results.filter(
          (project) => project.status !== 3
        );
    
        // Count projects per community, excluding "Post Production"
        const fetchedCommunities = (projectResponse?.data?.community || []).map(
          (community) => ({
            ...community,
            no_of_projects: filteredProject.filter(
              (project) => project.community === community.id
            ).length + (installationCounts[community.id] || 0),
          })
        );
        const othersCommunity = { id: 'other', name: 'Others' };
        fetchedCommunities.push(othersCommunity);
    
        setCommunitiesData((oldCommunities) =>
          fetchedCommunities.map((comm) => {
            const old = oldCommunities.find((o) => o.id === comm.id);
            return {
              ...comm,
              checked: old ? old.checked : false
            };
          })
        );
        const installationClientCount = 0
        const fetchedClients = (projectResponse?.data?.clients || []).map(
          (client) => ({
            ...client,
            no_of_projects: filteredProject.filter(
              (project) => project.customer_id === client.id
            ).length + (installationClientCount[client.id] || 0),
          })
        );
        const othersClient = { id: 'other', name: 'Others' };
        fetchedClients.push(othersClient);
        // setClientData(fetchedClients);
        setClientData((oldClients) =>
          fetchedClients.map((comm) => {
            const old = oldClients.find((o) => o.id === comm.id);
            console.log("clientsssssss 3 ",old )
            return {
              ...comm,
              checked: old ? old.checked : false
            };
          })
        );
    
        const projects = projectResponse.data.results || [];
        const filteredProjects = projects.filter(
          (item) => (item.status === 1 || item.status === 0) && item.jobcost === 0
        );
    
        // Set counts for tabs
        const counts = {
          Production: 0,
          'Pre-Production': 0,
          'Post Production': 0,
          Hold: 0
        };
    
        projects.forEach((item) => {
          if (item.hold === 1) counts['Hold'] += 1;
          else if (item.status === 3 && item.hold === 0) counts['Post Production'] += 1;
          else if ((item.status === 1 || item.status === 0) && item.hold === 0)
            counts['Pre-Production'] += 1;
          else if (item.status !== 1 && item.status !== 3 && item.status !== 0 && item.hold === 0)
            counts['Production'] += 1;
        });
    
        // setProductionCount(counts['Production']);
        // setPreCount(counts['Pre-Production']);
        // setPostCount(counts['Post Production']);
        // setHoldCount(counts['Hold']);
        const allProjects = projects?.filter(project => project?.community == communityId);
        setData(allProjects);
        setFiltered(filteredProjects);
        setSearchData(filteredProjects);
        setInstallations(completedInstallations);
    
        const categoryResponse = await axios.post(
          `${REST_API_END_POINT}project/get-all-project-categories`,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
    
        if (categoryResponse.data.status === 1) {
          const fetchedTypes = categoryResponse.data.projectTypes || [];
          setTypes((oldTypes) =>
            fetchedTypes.map((type) => {
              const old = oldTypes.find((o) => o.id === type.id);
              return {
                ...type,
                checked: old ? old.checked : false
              };
            })
          );
    
          const fetchedCats = categoryResponse.data.projectCategories || [];
          setCategories((oldCats) =>
            fetchedCats.map((cat) => {
              const old = oldCats.find((o) => o.id === cat.id);
              return {
                ...cat,
                checked: old ? old.checked : false
              };
            })
          );
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
      setRenderCards(false);
    };
    
    
    fetchData();
  }, [renderCards, sort, filterDateValue]);
    
  //  useEffect(()=>{
  //    if(currentTab){
  //     const Data = data?.filter(item => {
  //       let variable = ''
  //       if(item.jobcost===1) variable = 'Hold'
  //       if(item.status !== 1 && item.status !== 3 && item.status !== 0 && item.jobcost===0) variable = 'Production'
  //       if((item.status === 1 || item.status === 0 )&& item.jobcost===0) variable = 'Pre-Production'
  //       if(item.status === 3 && item.jobcost===0) variable = 'Post Production'
  //       return variable === currentTab
  //     });
  //     if(currentTab === 'Production'){
  //       setFilterCommunity([])
  //       setProductionCount(Data?.length)
  //     }
  //     if(currentTab === 'Pre-Production'){
  //       setFilterCommunity([])
  //       setPreCount(Data?.length)
  //     }
  //     if(currentTab === 'Post Production'){
  //       setFilterCommunity([])
  //       setPostCount(Data?.length)
  //     }
  //     if(currentTab === 'Hold'){
  //       setFilterCommunity([])
  //        setHoldCount(Data?.length)
  //     }
  //     setFiltered(Data)
  //     setSearchData(Data)
  //    }
  //  },[currentTab,data])

  // useEffect(() => {
  //   if (data && installations) {
  //     const updateCounts = (filteredData, filteredInstallations, filteredCompletedInstallations) => {
  //       const counts = {
  //         'Production': 0,
  //         'Pre-Production': 0,
  //         'Post Production': 0,
  //         'Hold': 0,
  //         'Installs': filteredInstallations.length,
  //         'Completed': filteredCompletedInstallations.length
  //       };
  
  //       filteredData.forEach(item => {
  //         let variable = '';
  //         if (item.hold === 1) variable = 'Hold';
  //         if (item.status !== 1 && item.status !== 3 && item.status !== 0 && item.hold === 0) variable = 'Production';
  //         if ((item.status === 1 || item.status === 0) && item.hold === 0) variable = 'Pre-Production';
  //         if (item.status === 3 && item.hold === 0) variable = 'Post Production';
  
  //         if (variable) {
  //           counts[variable]++;
  //         }
  //       });
  
  //       setProductionCount(counts['Production']);
  //       setPreCount(counts['Pre-Production']);
  //       setPostCount(counts['Post Production']);
  //       setHoldCount(counts['Hold']);
  //     };
  
  //     // Filter data based on community selection
  //     const filteredByCommunity = data.filter(item => 
  //       filterCommunity.length === 0 || 
  //       filterCommunity.includes(item.community) || 
  //       (item.community === null && filterCommunity.includes('other'))
  //     );

  //     // Filter completed installations based on community selection
  //     const filteredInstallationsByCommunity = installations.filter(installation =>
  //       filterCommunity.length === 0 ||
  //       filterCommunity.includes(installation.community) ||
  //       (installation.community === null && filterCommunity.includes('other'))
  //     );
  
  //     const installationsTabData = filteredInstallationsByCommunity?.filter((item)=>{
  //       return item.allInstallationCompleted !== 1
  //     })
  //     const completedTabData = filteredInstallationsByCommunity?.filter((item)=>{
  //       return item.allInstallationCompleted === 1
  //     })
  //     if (currentTab === 'Installs') {
  //       setFiltered(installationsTabData);
  //     } else if (currentTab === 'Completed') {
  //       setFiltered(completedTabData);
  //     } else {
  //       setFiltered(filteredByCommunity);
  //     }
  //     // Update counts for the filtered data
  //     updateCounts(filteredByCommunity, installationsTabData, completedTabData);
  //     setFilteredInstallations(installationsTabData);
  //     setFilteredCompletedInstallations(completedTabData);
  
  //     // Set search data based on current tab
  //     if (currentTab) {
  //       if (currentTab === 'Installs') {
  //         setSearchData(installationsTabData);
  //       } else if (currentTab === 'Completed') {
  //         setSearchData(completedTabData);
  //       } else {
  //         const newData = filteredByCommunity.filter(item => {
  //           let variable = '';
  //           if (item.hold === 1) variable = 'Hold';
  //           if (item.status !== 1 && item.status !== 3 && item.status !== 0 && item.hold === 0) variable = 'Production';
  //           if ((item.status === 1 || item.status === 0) && item.hold === 0) variable = 'Pre-Production';
  //           if (item.status === 3 && item.hold === 0) variable = 'Post Production';
  //           return variable === currentTab;
  //         });
  //         setFiltered(newData);
  //         setSearchData(newData);
  //       }
  //     } 
  //     // else {
  //     //   setSearchData(filteredByCommunity);
  //     // }
  //   }
  // }, [currentTab, data, filterCommunity, installations]);

  useEffect(() => {
    if (data && installations) {
      const updateCounts = (filteredData, filteredInstallations, filteredCompletedInstallations,filteredPickup,filteredShipping) => {
        const counts = {
          'Production': 0,
          'Pre-Production': 0,
          'Post Production': 0,
          'Hold': 0,
          'Installs': filteredInstallations.length, 
          'Pickup': filteredPickup.length,
          'Shipping': filteredShipping.length,
          'Completed': filteredCompletedInstallations.length // Total completed installations
        };
  
        filteredData.forEach(item => {
          let variable = '';
          if (item.hold === 1) variable = 'Hold';
          if (item.status !== 1 && item.status !== 3 && item.status !== 0 && item.hold === 0) variable = 'Production';
          if ((item.status === 1 || item.status === 0) && item.hold === 0) variable = 'Pre-Production';
          if (item.status === 3 && item.hold === 0) variable = 'Post Production';
  
          if (variable) {
            counts[variable]++;
          }
        });
  
        // Update counts state
        // setProductionCount(counts['Production']);
        // setPreCount(counts['Pre-Production']);
        // setPostCount(counts['Post Production']);
        // setHoldCount(counts['Hold']);
      };
  
      // Comprehensive filtering for all data
      const filteredByFilters = data.filter(item => {
        const communityMatch = filterCommunity.length === 0 || 
          filterCommunity.includes(item.community) || 
          (item.community === null && filterCommunity.includes('other'));
  
          const clientMatch = filterClient.length === 0 ||
          filterClient.includes(item.customer_id) ||
          (item.customer_id === null && filterClient.includes('other'))

        const typeMatch = types.some(type => type.checked) 
          ? types.filter(type => type.checked).map(type => type.id).includes(item.project_type) 
          : true;
  
        const categoryMatch = categories.some(cat => cat.checked) 
          ? categories.filter(cat => cat.checked).map(cat => cat.id).includes(item.project_category) 
          : true;
  
        const nameMatch = filterName 
          ? item.name?.toLowerCase().includes(filterName.toLowerCase()) 
          : true;
  
        return communityMatch && typeMatch && categoryMatch && nameMatch && clientMatch;
       
      });
        const sortedByDueDate = dueData
        ? filteredByFilters.sort((a, b) => new Date(a.installation_date) - new Date(b.installation_date))
        : filteredByFilters;
  //  const sortedByDueDate = filteredByFilters
      // setFiltered(sortedByDueDate);
      // setSearchData(sortedByDueDate);
      // Filter completed and incomplete installations based on community and status
      const filteredInstallationsByCommunity = installations.filter(item =>{
        const communityMatch = filterCommunity.length === 0 ||
        filterCommunity.includes(item.community) ||
        (item.community === null && filterCommunity.includes('other'))

        const clientMatch = filterClient.length === 0 ||
        filterClient.includes(item.customer_id) ||
        (item.customer_id === null && filterClient.includes('other'))

        const typeMatch = types.some(type => type.checked) 
        ? types.filter(type => type.checked).map(type => type.id).includes(item.project_type) 
        : true;

      const categoryMatch = categories.some(cat => cat.checked) 
        ? categories.filter(cat => cat.checked).map(cat => cat.id).includes(item.project_category) 
        : true;

      const nameMatch = filterName 
        ? item.name?.toLowerCase().includes(filterName.toLowerCase()) 
        : true;
        return communityMatch && typeMatch && categoryMatch && nameMatch && clientMatch;
    });
          const sortedByDueDated = dueData
        ? filteredInstallationsByCommunity.sort((a, b) => new Date(a.installation_date) - new Date(b.installation_date))
        : filteredInstallationsByCommunity;
  
      // const sortedByDueDated = filteredInstallationsByCommunity
  
      const installationsTabData = sortedByDueDated.filter(item => item.allInstallationCompleted !== 1 && item.InstallCompleteStatus ==1);
      const pickupTabData = sortedByDueDated.filter(item => item.allInstallationCompleted !== 1 && item.InstallCompleteStatus ==2);
      const shippingTabData = sortedByDueDated.filter(item => item.allInstallationCompleted !== 1 && item.InstallCompleteStatus ==3 && item.installationStatus !=5);
      const completedTabData = sortedByDueDated.filter(item => item.allInstallationCompleted === 1 );
  
      // Set filtered data for current tab
      if (currentTab === 'Installs') {
        setFiltered(installationsTabData);
      } else if (currentTab === 'Pickup') {
        setFiltered(pickupTabData);
      } else if (currentTab === 'Shipping') {
        setFiltered(shippingTabData);
      } else if (currentTab === 'Completed') {
        setFiltered(completedTabData);
      } else {
        setFiltered(sortedByDueDate);
      }
  
      // Update counts based on the filtered data
      updateCounts(sortedByDueDate, installationsTabData, completedTabData,pickupTabData,shippingTabData);
  
      // Set installations and completed installations data
      setFilteredInstallations(installationsTabData);
      setFilteredPickup(pickupTabData);
      setFilteredShipping(shippingTabData);
      setFilteredCompletedInstallations(completedTabData);
  
      // Set search data based on the current tab
      if (currentTab === 'Installs') {
        setSearchData(installationsTabData);
      } else if (currentTab === 'Pickup') {
        setSearchData(pickupTabData);
      } else if (currentTab === 'Shipping') {
        setSearchData(shippingTabData);
      } else if (currentTab === 'Completed') {
        setSearchData(completedTabData);
      } else {
        const newData = sortedByDueDate.filter(item => {
          let variable = '';
          if (item.hold === 1) variable = 'Hold';
          if (item.status !== 1 && item.status !== 3 && item.status !== 0 && item.hold === 0) variable = 'Production';
          if ((item.status === 1 || item.status === 0) && item.hold === 0) variable = 'Pre-Production';
          if (item.status === 3 && item.hold === 0) variable = 'Post Production';
          return variable === currentTab;
        });
        setFiltered(newData);
        setSearchData(newData);
      }
    }
  }, [currentTab, data, filterCommunity, installations, types, categories, filterName,dueData,filterClient]);
  
  
  console.log('setFiltered----------',filtered);

  // useEffect(() => {
  //   if(filterName || types || categories || filterCommunity){
  //     setSearchData(filtered.filter(p => ((filterName ? p.name.toLowerCase().includes(filterName.toLowerCase()) : true) &&
  //       (types.filter(t => t.checked).length ? types.filter(t => t.checked).map(t => t.id).includes(p.project_type) : true) &&
  //       (categories.filter(t => t.checked).length ? categories.filter(t => t.checked).map(t => t.id).includes(p.project_category) : true) &&
  //       (communitiesData?.some(community => community.id === 'other' && community.checked) ?
  //         (p.community === null || p.community === 'other' || communitiesData.some(c => c.id === p.community && c.checked)) :
  //         (communitiesData?.filter(t => t.checked).length ? communitiesData?.filter(t => t.checked).map(t => t.id).includes(p.community) : true)
  //       )
  //     )));
  //     console.log("useEffect-communitiesData----", communitiesData);
  //     console.log("useEffect-filterName----", filterName);
  //     console.log("useEffect-types----", types);
  //     console.log("useEffect-categories----", categories);
  //     console.log("useEffect-filterCommunity----", filterCommunity);
  //   }
  // }, [filterName, types, categories, filterCommunity]);

  //  useEffect(()=>{
  //   if(filterName == ''){
  //     setSearchData(filtered);
  //   } else if(filterName != '' || types?.some(item=>item.checked) || categories?.some(item=>item.checked) || filterCommunity?.length > 0){
  //     setSearchData(filtered.filter(p=>((filterName ? p.name.toLowerCase().includes(filterName.toLowerCase()) : true) &&
  //     (types.filter(t=>t.checked).length ? types.filter(t=>t.checked).map(t=>t.id).includes(p.project_type) :  true) &&
  //     (categories.filter(t=>t.checked).length ? categories.filter(t=>t.checked).map(t=>Number(t.id)).includes(Number(p.project_category)) :  true) &&
  //     // (communitiesData?.filter(t => t.checked).length ? communitiesData?.filter(t => t.checked).map(t => t.id).includes(p.community) : true)
  //     (
  //       communitiesData?.some(community => community.id === 'other' && community.checked) ? 
  //       (p.community === null || p.community === 'other' || communitiesData.some(c => c.id === p.community && c.checked)) : 
  //       (communitiesData?.filter(t => t.checked).length ? communitiesData?.filter(t => t.checked).map(t => t.id).includes(p.community) : true)
  //     )
  //     )))

  //   }
  //  },[filterName,types,categories,filterCommunity])
  // useEffect(() => {
  //   // If no filters at all, just show everything in `filtered`.
  //   const noSearchText = !filterName || filterName.trim() === '';
  //   const noTypes = !types.some((t) => t.checked);
  //   const noCategories = !categories.some((c) => c.checked);
  //   const noCommunities = filterCommunity.length === 0;
  
  //   if (noSearchText && noTypes && noCategories && noCommunities) {
  //     // No filters at all -> show everything
  //     setSearchData(filtered);
  //     return;
  //   }
  
  //   // Otherwise, do a combined filter pass
  //   const newData = filtered.filter((p) => {
  //     // --- 1) NAME MATCH ---
  //     const nameMatch = filterName
  //       ? p.name?.toLowerCase().includes(filterName.toLowerCase())
  //       : true;
  
  //     // --- 2) JOB TYPE MATCH ---
  //     const activeTypes = types
  //       .filter((t) => t.checked)
  //       .map((t) => Number(t.id));
  //     // If no job types are checked, it's automatically true
  //     const typeMatch = activeTypes.length > 0
  //       ? activeTypes.includes(Number(p.project_type))
  //       : true;
  
  //     // --- 3) JOB CATEGORY MATCH ---
  //     const activeCategories = categories
  //       .filter((c) => c.checked)
  //       .map((c) => Number(c.id));
  //     // If no categories are checked, automatically true
  //     const categoryMatch = activeCategories.length > 0
  //       ? activeCategories.includes(Number(p.project_category))
  //       : true;
  
  //     // --- 4) COMMUNITY MATCH ---
  //     // If no communities are selected, automatically true
  //     let communityMatch = true;
  //     if (filterCommunity.length > 0) {
  //       // If "other" is selected and p.community is null or "other", that is a match
  //       if (filterCommunity.includes('other') && 
  //           (p.community === null || p.community === 'other')) {
  //         communityMatch = true;
  //       } else {
  //         // Otherwise, we see if p.community is in the array
  //         communityMatch = filterCommunity.includes(p.community);
  //       }
  //     }
  
  //     // Only if ALL are true, we keep this item
  //     return nameMatch && typeMatch && categoryMatch && communityMatch;
  //   });
  
  //   setSearchData(newData);
  //   console.log('fdsfjsdfjsdf',newData)
  //   setFiltered(newData);
  // }, [filterName, filtered, types, categories, filterCommunity]);
  
  useEffect(() => {
    const noSearchText = !filterName || filterName.trim() === '';
    const noTypes = !types.some((t) => t.checked);
    const noCategories = !categories.some((c) => c.checked);
    const noCommunities = filterCommunity.length === 0;
    const noClients = filterClient.length === 0;

    if (noSearchText && noTypes && noCategories && noCommunities && noClients) {
      // No filters -> show all filtered data
      setSearchData(filtered); 
      return; // Prevent further execution
    }
  
    const newData = filtered.filter((p) => {
      const nameMatch = filterName
        ? p.name?.toLowerCase().includes(filterName.toLowerCase())
        : true;
      const activeTypes = types
        .filter((t) => t.checked)
        .map((t) => Number(t.id));
      const typeMatch = activeTypes.length > 0
        ? activeTypes.includes(Number(p.project_type))
        : true;
      const activeCategories = categories
        .filter((c) => c.checked)
        .map((c) => Number(c.id));
      const categoryMatch = activeCategories.length > 0
        ? activeCategories.includes(Number(p.project_category))
        : true;
      const communityMatch = filterCommunity.length === 0 ||
        filterCommunity.includes(p.community) ||
        (filterCommunity.includes('other') && (p.community === null || p.community === 'other'));

        const clientMatch = filterClient.length === 0 ||
      filterClient.includes(p.customer_id) ||
        (filterClient.includes('other') && (p.customer_id === null || p.customer_id === 'other'));
  
      return nameMatch && typeMatch && categoryMatch && communityMatch && clientMatch;
    });
  
    setSearchData((prevSearchData) => {
      // Avoid unnecessary updates
      if (JSON.stringify(prevSearchData) === JSON.stringify(newData)) {
        return prevSearchData;
      }
      return newData;
    });
  }, [filterName, filtered, types, categories, filterCommunity ,filterClient]);
  

   const handleFetchEditData = useCallback((id) => {
    try {
      const  user  = JSON.parse(window.localStorage.getItem('user'))
      axios.post(`${REST_API_END_POINT}project/get-project-by-id`,{ id: { value: id}, userId:user.user_id, userType: user.user_type  },{
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        setSingleProjectData(response.data)
      
      }).catch((error) => {
        console.error(error);
      })
      }
      catch (error) {
        console.error("Error fetching data:", error);
      }
  }, [isEdit]);


  const ACCOUNT_TABS = [
    {
      value: 'Hold',
      count:holdCount||0,
      component:<ProductionControll removePopupData={removePopupData} projectId={projectId} setProjectId={setProjectId}openViewPage={openViewPage} setOpenViewPage={setOpenViewPage}projectName={projectName} setProjectName={setProjectName} isLoading={isLoading} setRenderCards={setRenderCards} renderCards={renderCards} setIsEdit={setIsEdit} handleFetchEditData={handleFetchEditData} currentTab={currentTab} medal={'https://signwisesolutions.com/uploads/ranks/super.png'} projectData={searchData} communityJob={true}/>,
      listComponent:<ProjectList removePopupData={removePopupData} projectId={projectId} setProjectId={setProjectId}openViewPage={openViewPage} setOpenViewPage={setOpenViewPage}projectName={projectName} setProjectName={setProjectName} isLoading={isLoading} setRenderCards={setRenderCards} renderCards={renderCards} setIsEdit={setIsEdit} handleFetchEditData={handleFetchEditData} currentTab={currentTab} medal={'https://signwisesolutions.com/uploads/ranks/super.png'} projectData={searchData} communityJob={true}/>,
      GridListComponent:<ProjectGrid removePopupData={removePopupData} projectId={projectId} setProjectId={setProjectId}openViewPage={openViewPage} setOpenViewPage={setOpenViewPage}projectName={projectName} setProjectName={setProjectName} isLoading={isLoading} setRenderCards={setRenderCards} renderCards={renderCards} setIsEdit={setIsEdit} handleFetchEditData={handleFetchEditData} currentTab={currentTab} medal={'https://signwisesolutions.com/uploads/ranks/super.png'} projectData={searchData} communityJob={true}/>,
    },
    {
      value: 'Pre-Production',
      count:preCount||0,
      component:<ProductionControll removePopupData={removePopupData} projectId={projectId} setProjectId={setProjectId}openViewPage={openViewPage} setOpenViewPage={setOpenViewPage}projectName={projectName} setProjectName={setProjectName} isLoading={isLoading} setRenderCards={setRenderCards} renderCards={renderCards} setIsEdit={setIsEdit} handleFetchEditData={handleFetchEditData} currentTab={currentTab} medal={'https://signwisesolutions.com/uploads/ranks/super.png'} projectData={searchData} communityJob={true}/>,
      listComponent:<ProjectList removePopupData={removePopupData} isLoading={isLoading} setRenderCards={setRenderCards} renderCards={renderCards} projectId={projectId} setProjectId={setProjectId}openViewPage={openViewPage} setOpenViewPage={setOpenViewPage}projectName={projectName} setProjectName={setProjectName} setIsEdit={setIsEdit} handleFetchEditData={handleFetchEditData} currentTab={currentTab} medal={'https://signwisesolutions.com/uploads/ranks/super.png'} projectData={searchData} communityJob={true}/>,
      GridListComponent:<ProjectGrid removePopupData={removePopupData} projectId={projectId} setProjectId={setProjectId}openViewPage={openViewPage} setOpenViewPage={setOpenViewPage}projectName={projectName} setProjectName={setProjectName} isLoading={isLoading} setRenderCards={setRenderCards} renderCards={renderCards} setIsEdit={setIsEdit} handleFetchEditData={handleFetchEditData} currentTab={currentTab} medal={'https://signwisesolutions.com/uploads/ranks/super.png'} projectData={searchData} communityJob={true}/>,
    },
    {
      value: 'Production',
      count:productionCount||0,
      component:<ProductionControll removePopupData={removePopupData} projectId={projectId} setProjectId={setProjectId}openViewPage={openViewPage} setOpenViewPage={setOpenViewPage}projectName={projectName} setProjectName={setProjectName} isLoading={isLoading} setRenderCards={setRenderCards} renderCards={renderCards} setIsEdit={setIsEdit} handleFetchEditData={handleFetchEditData} currentTab={currentTab} medal={'/static/icons/great.png'} projectData={searchData} communityJob={true}/>,
      listComponent:<ProjectList removePopupData={removePopupData} projectId={projectId} setProjectId={setProjectId}openViewPage={openViewPage} setOpenViewPage={setOpenViewPage}projectName={projectName} setProjectName={setProjectName} isLoading={isLoading} setRenderCards={setRenderCards} renderCards={renderCards} setIsEdit={setIsEdit} handleFetchEditData={handleFetchEditData} currentTab={currentTab} medal={'/static/icons/great.png'} projectData={searchData} communityJob={true}/>,
      GridListComponent:<ProjectGrid removePopupData={removePopupData}  projectId={projectId} setProjectId={setProjectId}openViewPage={openViewPage} setOpenViewPage={setOpenViewPage}projectName={projectName} setProjectName={setProjectName} isLoading={isLoading} setRenderCards={setRenderCards} renderCards={renderCards} setIsEdit={setIsEdit} handleFetchEditData={handleFetchEditData} currentTab={currentTab} medal={'/static/icons/great.png'} projectData={searchData} communityJob={true}/>,
    },
    // {
    //   value: 'Post Production',
    //   count:postCount||0,
    //   component:<ProductionControll isLoading={isLoading} setRenderCards={setRenderCards} renderCards={renderCards} setIsEdit={setIsEdit} handleFetchEditData={handleFetchEditData} currentTab={currentTab} medal={'https://signwisesolutions.com/uploads/ranks/regular.png'} projectData={searchData}/>,
    //   listComponent:<ProjectList isLoading={isLoading} setRenderCards={setRenderCards} renderCards={renderCards} setIsEdit={setIsEdit} handleFetchEditData={handleFetchEditData} currentTab={currentTab} medal={'https://signwisesolutions.com/uploads/ranks/regular.png'} projectData={searchData}/>,
    //   GridListComponent:<ProjectGrid isLoading={isLoading} setRenderCards={setRenderCards} renderCards={renderCards} setIsEdit={setIsEdit} handleFetchEditData={handleFetchEditData} currentTab={currentTab} medal={'https://signwisesolutions.com/uploads/ranks/regular.png'} projectData={searchData}/>,
    // },
    {
      value: 'Installs',
      count:filteredInstallations.length||0,
      // count:installations?.length||0,
      component:<ProductionControll removePopupData={removePopupData} projectId={projectId} setProjectId={setProjectId}openViewPage={openViewPage} setOpenViewPage={setOpenViewPage}projectName={projectName} setProjectName={setProjectName} isLoading={isLoading} setRenderCards={setRenderCards} renderCards={renderCards} setIsEdit={setIsEdit} handleFetchEditData={handleFetchEditData} currentTab={currentTab} medal={'https://signwisesolutions.com/uploads/ranks/regular.png'} projectData={searchData} communityJob={true}/>,
      listComponent:<ProjectList removePopupData={removePopupData} projectId={projectId} setProjectId={setProjectId}openViewPage={openViewPage} setOpenViewPage={setOpenViewPage}projectName={projectName} setProjectName={setProjectName} isLoading={isLoading} setRenderCards={setRenderCards} renderCards={renderCards} setIsEdit={setIsEdit} handleFetchEditData={handleFetchEditData} currentTab={currentTab} medal={'https://signwisesolutions.com/uploads/ranks/regular.png'} projectData={searchData} communityJob={true}/>,
      GridListComponent:<ProjectGrid removePopupData={removePopupData} projectId={projectId} setProjectId={setProjectId}openViewPage={openViewPage} setOpenViewPage={setOpenViewPage}projectName={projectName} setProjectName={setProjectName} isLoading={isLoading} setRenderCards={setRenderCards} renderCards={renderCards} setIsEdit={setIsEdit} handleFetchEditData={handleFetchEditData} currentTab={currentTab} medal={'https://signwisesolutions.com/uploads/ranks/regular.png'} projectData={searchData} communityJob={true}/>,
    },
    {
      value: 'Pickup',
      count:filteredPickup.length||0,
      // count:installations?.length||0,
      component:<ProductionControll removePopupData={removePopupData} projectId={projectId} setProjectId={setProjectId}openViewPage={openViewPage} setOpenViewPage={setOpenViewPage}projectName={projectName} setProjectName={setProjectName} isLoading={isLoading} setRenderCards={setRenderCards} renderCards={renderCards} setIsEdit={setIsEdit} handleFetchEditData={handleFetchEditData} currentTab={currentTab} medal={'https://signwisesolutions.com/uploads/ranks/regular.png'} projectData={searchData} communityJob={true}/>,
      listComponent:<ProjectList removePopupData={removePopupData} projectId={projectId} setProjectId={setProjectId}openViewPage={openViewPage} setOpenViewPage={setOpenViewPage}projectName={projectName} setProjectName={setProjectName} isLoading={isLoading} setRenderCards={setRenderCards} renderCards={renderCards} setIsEdit={setIsEdit} handleFetchEditData={handleFetchEditData} currentTab={currentTab} medal={'https://signwisesolutions.com/uploads/ranks/regular.png'} projectData={searchData} communityJob={true}/>,
      GridListComponent:<ProjectGrid removePopupData={removePopupData} projectId={projectId} setProjectId={setProjectId}openViewPage={openViewPage} setOpenViewPage={setOpenViewPage}projectName={projectName} setProjectName={setProjectName} isLoading={isLoading} setRenderCards={setRenderCards} renderCards={renderCards} setIsEdit={setIsEdit} handleFetchEditData={handleFetchEditData} currentTab={currentTab} medal={'https://signwisesolutions.com/uploads/ranks/regular.png'} projectData={searchData} communityJob={true} />,
    },
    {
      value: 'Shipping',
      count:filteredShipping?.length||0,
      component:<ProductionControll removePopupData={removePopupData} projectId={projectId} setProjectId={setProjectId}openViewPage={openViewPage} setOpenViewPage={setOpenViewPage}projectName={projectName} setProjectName={setProjectName} isLoading={isLoading} setRenderCards={setRenderCards} renderCards={renderCards} setIsEdit={setIsEdit} handleFetchEditData={handleFetchEditData} currentTab={currentTab} medal={'https://signwisesolutions.com/uploads/ranks/regular.png'} projectData={searchData} communityJob={true}/>,
      listComponent:<ProjectList removePopupData={removePopupData} projectId={projectId} setProjectId={setProjectId}openViewPage={openViewPage} setOpenViewPage={setOpenViewPage}projectName={projectName} setProjectName={setProjectName} isLoading={isLoading} setRenderCards={setRenderCards} renderCards={renderCards} setIsEdit={setIsEdit} handleFetchEditData={handleFetchEditData} currentTab={currentTab} medal={'https://signwisesolutions.com/uploads/ranks/regular.png'} projectData={searchData} communityJob={true}/>,
      GridListComponent:<ProjectGrid removePopupData={removePopupData} projectId={projectId} setProjectId={setProjectId}openViewPage={openViewPage} setOpenViewPage={setOpenViewPage}projectName={projectName} setProjectName={setProjectName} isLoading={isLoading} setRenderCards={setRenderCards} renderCards={renderCards} setIsEdit={setIsEdit} handleFetchEditData={handleFetchEditData} currentTab={currentTab} medal={'https://signwisesolutions.com/uploads/ranks/regular.png'} projectData={searchData} communityJob={true}/>,
    },
    {
      value: 'Completed',
      count:filteredCompletedInstallations?.length||0,
      component:<ProductionControll removePopupData={removePopupData} projectId={projectId} setProjectId={setProjectId}openViewPage={openViewPage} setOpenViewPage={setOpenViewPage}projectName={projectName} setProjectName={setProjectName} isLoading={isLoading} setRenderCards={setRenderCards} renderCards={renderCards} setIsEdit={setIsEdit} handleFetchEditData={handleFetchEditData} currentTab={currentTab} medal={'https://signwisesolutions.com/uploads/ranks/regular.png'} projectData={searchData} communityJob={true}/>,
      listComponent:<ProjectList removePopupData={removePopupData} projectId={projectId} setProjectId={setProjectId}openViewPage={openViewPage} setOpenViewPage={setOpenViewPage}projectName={projectName} setProjectName={setProjectName} isLoading={isLoading} setRenderCards={setRenderCards} renderCards={renderCards} setIsEdit={setIsEdit} handleFetchEditData={handleFetchEditData} currentTab={currentTab} medal={'https://signwisesolutions.com/uploads/ranks/regular.png'} projectData={searchData} communityJob={true}/>,
      GridListComponent:<ProjectGrid removePopupData={removePopupData} projectId={projectId} setProjectId={setProjectId}openViewPage={openViewPage} setOpenViewPage={setOpenViewPage}projectName={projectName} setProjectName={setProjectName} isLoading={isLoading} setRenderCards={setRenderCards} renderCards={renderCards} setIsEdit={setIsEdit} handleFetchEditData={handleFetchEditData} currentTab={currentTab} medal={'https://signwisesolutions.com/uploads/ranks/regular.png'} projectData={searchData} communityJob={true}/>,
    },
  ];

  useEffect(() => {
    dispatch(getPostsInitial(index, step));
  }, [dispatch, index, step]);

  const handleChangeSort = (event) => {
    setFilters(event.target.value);
  };
  const handleClick =()=>{
    setAll(!all)
    localStorage.setItem('all_project',all)
  }
  const handleChangeTab = (event, newValue) => {
    if (newValue === 'Completed') {
      setDueData(false);
    } else {
      setDueData(true);
    }
    setProjectId("");
    setProjectName("");
    setCurrentTab(newValue);
    // setRenderCards(!renderCards);
    // setFilterName('');
  };
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const CustomTab = styled(Tab)(({ theme }) => ({
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    boxShadow: 'none', //theme.shadows[5],
    minHeight: 'fit-content',
    indicator: {
      display: 'none',
    },
    height: 38,
    fontSize: 12,
    borderRadius: 'none', //20
    border: '1.5px solid #00c2ff', //newlyadded
    background: '#161c24',
    margin: 0, //5
    color: theme.palette.text.primary,
    '&:hover': {
      background: '#161c24',
    },
    '&.Mui-selected': {
      background: theme.palette.primary.main,
      color: '#fff',
    },
    '&:first-child': {
      borderRadius: '7px 0 0 7px',
      borderLeft: `1.5px solid ${theme.palette.primary.main}`,
    },
    '&:nth-child(2)': {
      borderRadius: '0',
      borderLeft: '0',
    },
    '&:nth-child(3)': {
      borderRadius: '0',
      borderLeft: '0',
    },
    '&:last-child': {
      borderRadius: '0 7px 7px 0',
      borderLeft: '0',
    },
  
    [theme.breakpoints.up('lg')]: {
      marginRight: `0 !important`,
      minWidth: '20% !important',
    },
    [theme.breakpoints.down('lg')]: {
      marginRight: `0 !important`,
      minWidth: '20% !important',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: `0 !important`,
      minWidth: '30.33% !important',
    },
  }))
  const handleChangeSortJobs = (category) => {
    setSort(category);
    setSortOpen(false);
  };

  const handleByDue =()=>{
    setDueData(!dueData)
  }

  const toggleType = (typeId) => {
    setTypes((prev) =>
      prev.map((type) =>
        type.id === typeId ? { ...type, checked: !type.checked } : type
      )
    );
  };
  const toggleCategory = (catId) => {
    setCategories((prev) =>
      prev.map((c) =>
        c.id === catId ? { ...c, checked: !c.checked } : c
      )
    );
  };

  const toggleCommunity = (commId) => {
    setCommunitiesData((prev) =>
      prev.map((c) =>
        c.id === commId ? { ...c, checked: !c.checked } : c
      )
    );
        // Then also manage the “filterCommunity” array
        setFilterCommunity((prev) =>
          prev.includes(commId)
            ? prev.filter((id) => id !== commId) // remove
            : [...prev, commId] // add
        );
      };
      const toggleClient = (commId) => {
        setClientData((prev) =>
          prev.map((c) =>
            c.id === commId ? { ...c, checked: !c.checked } : c
          )
        );
            setFilterClient((prev) =>
              prev.includes(commId)
                ? prev.filter((id) => id !== commId)
                : [...prev, commId]
            );
          };

  return (
    <Page title={`${workSpaceTitle} | ${COMPANY_NAME}`}>
      <Container>
        <Button
            variant="outlined"
            type="submit"
            onClick={() => window.history.back()}
            sx={{ alignItems: 'flex-end', mb: 4 }}
            startIcon={
                <Stack>
                <KeyboardBackspaceOutlined />
                </Stack>

            }
            >
            Back
        </Button>
        <HeaderBreadcrumbs
          heading={add ? `Add ${workSpaceTitle}` : isEdit ? `Edit ${workSpaceTitle}` : `${communityName} - All ${workSpaceTitle}`}
          links={[
            { name: 'Job Class', href: PATH_DASHBOARD.root },
            { name: 'Job Types', href: PATH_DASHBOARD.blog.root },
            { name: 'Posts' }
          ]}
          action={
            ((!add && !isEdit) && <Stack direction={'row'} spacing={2}>
              {/* <Button
                disabled={explodedPermissions?.some(permission => ['Add Jobs'].includes(permission)) ? false : true}
                variant="contained"
                onClick={()=>{setAdd(true)}}
                sx={{ height: 38 }}
                // component={RouterLink}
                // to={PATH_DASHBOARD.blog.newPost}
                startIcon={<Icon icon={plusFill} />}
              >
              {`Add ${workSpaceTitle}`}
              </Button> */}
              {!all && <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="none"
                aria-label="secondary tabs example"
              >
                <CustomTab value="one" icon={<ViewModule />} />
                <CustomTab value="two" icon={<ViewList  />}/>
                <CustomTab value="three" icon={<List />} />
              </Tabs>}
            </Stack>)
          }
        />
       
        {(!add && !isEdit) ?
        (<>
        <Stack mb={2} spacing={{ xs: 3, sm: 2 }}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }} alignItems="flex-end" justifyContent='flex-end'>
            {!all &&
              <>
            

                {/* <Button ref={refCommunities} onClick={() => setCommunitiesOpen(prevState => !prevState)} 
                        variant="outlined"
                        type="submit"
                        endIcon={
                          <Stack>
                            {communitiesOpen ? <ExpandLess sx={{ ml: 'auto' }} /> : <ExpandMore sx={{ ml: 'auto' }} />}
                          </Stack>
                        }
                      >
                        Filter Project
                </Button> */}
                {/* <Menu
                  open={Boolean(communitiesOpen)}
                  anchorEl={refCommunities.current}
                  onClose={() => setCommunitiesOpen(false)}
                  PaperProps={{
                    sx: { width: 'auto', maxWidth: '100%', maxHeight: '250px' }
                  }}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                  <List style={{ maxHeight: '250px', overflowY: 'auto' }}>
                    {communitiesData?.map((type) => (
                      <MenuItem key={type.id} >
                        <Checkbox
                          // checked={type.checked ? type.checked : false}
                          // onChange={() => {
                          //   const updatedType = [...communitiesData];
                          //   const item = updatedType.find((item) => item.id === type.id);
                          //   if (item) {
                          //     item.checked = !item.checked;
                          //     setCommunitiesData(updatedType);
                          //     setFilterCommunity((prevIds) => {
                          //       if (item.checked) {
                          //         return [...prevIds, item.id];
                          //       } else {
                          //         return prevIds.filter((id) => id !== item.id);
                          //       }
                          //     });
                          //   }
                          // }}
                          checked={!!type.checked}
                            onChange={() => toggleCommunity(type.id)}
                        />
                        {type.id === 'other' ?
                          <ListItemText primary={`${type.name}`} />
                        :
                          <ListItemText primary={`${type.name} (${type.no_of_projects})`} />
                        }
                      </MenuItem>
                    ))}
                    </List>
                </Menu> */}
              </>
            }
            {!all && 
                <>
                  <Button ref={refClients} onClick={() => setClientOpen(prevState => !prevState)} 
                          variant="outlined"
                          type="submit"
                          endIcon={
                            <Stack>
                              {clientOpen ? <ExpandLess sx={{ ml: 'auto' }} /> : <ExpandMore sx={{ ml: 'auto' }} />}
                            </Stack>
                          }
                        >
                          Filter By Client
                  </Button>
                  <Menu
                    open={Boolean(clientOpen)}
                    anchorEl={refClients.current}
                    onClose={() => setClientOpen(false)}
                    PaperProps={{
                      sx: { width: 'auto', maxWidth: '100%', maxHeight: '250px' }
                    }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  >
                      {/* {clientData?.map((type) => (
                        <MenuItem key={type.id} >
                          <Checkbox
                            checked={!!type.checked}
                              onChange={() => toggleClient(type.id)}
                          />
                          {type.id === 'other' ?
                            <ListItemText primary={`${type.name}`} />
                          :
                            // <ListItemText primary={`${type.name} (${type.no_of_projects})`} />
                            <ListItemText primary={`${type.name}`} />
                          }
                        </MenuItem>
                      ))} */}
                      {clientData
                      ?.filter(type => type.id && projectsClientsArray.includes(String(type.id))) // 🔹 Filter By Client based on projectsClientsArray
                      .map((type) => (
                        <MenuItem key={type.id}>
                          <Checkbox
                            checked={!!type.checked}
                            onChange={() => toggleClient(type.id)}
                          />
                          {type.id === 'other' ? (
                            <ListItemText primary={`${type.name}`} />
                          ) : (
                            <ListItemText primary={`${type.name}`} />
                          )}
                        </MenuItem>
                      ))}

                    {/* </List> */}
                  </Menu>
                </>
              }
            <Button variant='outlined' onClick={handleClick}>
              All Jobs {all ?(<Visibility sx={{ml:1}}/>):(<VisibilityOff sx={{ml:1}}/>)}
            </Button>
            {/* <BlogPostsSearch /> */}
            {/* </Stack>
            <Stack direction={'row'} spacing={1}> */}
            {!all &&
              <>
              <Button variant='outlined' ref={ref} onClick={()=>setOpenType(!openType)}>
              Job Class
              </Button>
              <Menu       
              open={Boolean(openType)}
              anchorEl={ref.current}
              onClose={()=>setOpenType(false)}
              PaperProps={{
                sx: { minWidth: 50, maxWidth: '100%' }
              }}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
              
              
                  {types.map((type) => (
                    <MenuItem key={type.id} >
                      <Checkbox
                      // checked={type.checked}
                      // onChange={() => {
                      //   const updatedType = [...types];
                      //   const item = updatedType.find((item) => item.id === type.id);
                      //   if (item) {
                      //     item.checked = !item.checked;
                      //   }
                      //   setTypes(updatedType);
                      // }}

                      checked={!!type.checked}
            onChange={() => toggleType(type.id)}
                      />
                      <ListItemText primary={type.name} />
                    </MenuItem>
                  ))}
                  
            
              </Menu>
              </>
            }
            {/* </Stack>         
            <Stack> */}
            {!all &&
            <>
              <Button variant='outlined' ref={refcategory} onClick={()=>setOpencategory(!openCategory)}>
                Job Types
              </Button>
      
              <Menu         
              open={Boolean(openCategory)}
              anchorEl={refcategory.current}
              onClose={()=>setOpencategory(false)}
              PaperProps={{
                sx: { minWidth: 50, maxWidth: '100%' }
              }}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              >

                  {categories.map((type) => (
                    
                    <MenuItem key={type.id}>
                      <Checkbox
                      // checked={type.checked}
                      // onChange={() => {
                      //   const updatedType = [...categories];
                      //   const item = updatedType.find((item) => item.id === type.id);
                      //   if (item) {
                      //     item.checked = !item.checked;
                      //   }
                      //   setCategories(updatedType);
                      // }}
                      checked={!!type.checked}
                      onChange={() => toggleCategory(type.id)}
                      />
                      <ListItemText primary={type.name} />
                    </MenuItem>           
                  ))}
              </Menu>
            </>
            }
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }} alignItems="center" justifyContent="space-between">
          <SearchStyle
            sx={{ backgroundColor: '#05191f', height: 40 }}
              value={filterName}
              onChange={handleFilterByName}
              placeholder={`Search ${workSpaceTitle}`}
              startAdornment={
                <InputAdornment position="start">
                  <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              }
            />
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }} >
              <Button variant='outlined' 
                sx={{height:'40px',backgroundColor: dueData?'#1ccaff' :'',color:dueData?"#fff":''}} 
                onClick={handleByDue} endIcon={<Stack> {dueData ? <Visibility /> : <VisibilityOff/>} </Stack>}>
                By Due
              </Button>
              <MobileDateRangePicker
                startText="Filter Date"
                endText="Filter Date"
                value={filterDateValue}
                onChange={(newValue) => {
                  console.log('setFilterDateValue newValue',newValue);
                  setFilterDateValue(newValue);
                }}
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField {...startProps} sx={{ width: 160 }} helperText='Filter By Due Date' label='From Date' size="small" InputLabelProps={{ style: { color: theme.palette.primary.main, borderColor: theme.palette.primary.main } }}/>
                    <Box sx={{ mx: 1, color: theme.palette.primary.main }}> - </Box>
                    {/* <Box sx={{ mx: 2 }}> to </Box> */}
                    <TextField {...endProps} sx={{ width: 150 }}  helperText='' label='To Date' size="small" InputLabelProps={{ style: { color: theme.palette.primary.main, borderColor: theme.palette.primary.main } }}/>
                  </>
                )}
              />
              <Stack>
                <Button
                  ref={refSort}
                  onClick={() => setSortOpen((prevState) => !prevState)}
                  disabled={dueData}
                  variant="outlined"
                  type="button"
                  endIcon={
                    <Stack>
                      {sortOpen ? <ExpandLess sx={{ ml: 'auto' }} /> : <ExpandMore sx={{ ml: 'auto' }} />}
                    </Stack>
                  }
                >
                  {sort.label}
                </Button>
                  {dueData && (
                    <FormHelperText sx={{ color: 'error.main', fontSize: '0.65rem', mt: 0.5 }}>
                      Disable "By Due" to enable this.
                    </FormHelperText>
                  )}
                <Menu
                  open={Boolean(sortOpen)}
                  anchorEl={refSort.current}
                  onClose={() => setSortOpen(false)}
                  PaperProps={{
                    sx: { width: 'auto', maxWidth: '100%', maxHeight: '250px' },
                  }}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                  {SORT.map((category) => (
                    <MenuItem key={category.id} onClick={() => handleChangeSortJobs(category)}>
                      <ListItemText primary={category.label} />
                    </MenuItem>
                  ))}
                </Menu>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        {filterClient.length > 0 &&
            <>
              <Button
                variant='outlined'
                sx={{
                  color: 'white',
                  borderColor: 'primary.main',
                  pointerEvents: 'none',
                  userSelect: 'text',
                }}
              >
                <span style={{ color: theme.palette.primary.darker,marginRight:8 }}>Selected Clients </span>
                {clientData
                  .filter(client => filterClient.includes(client.id))
                  .map(client => client.name)
                  .join(', ')
                }
              </Button>
            </>
          }
        {/* {filterCommunity.length > 0 &&
          <>
            <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 2 }}>
              <span style={{ color: theme.palette.primary.darker }}>Selected Projects : </span>
              {communitiesData
                .filter(community => filterCommunity.includes(community.id))
                .map(community => community.name)
                .join(', ')
              }
            </Typography>
            <Button
              variant='outlined'
              sx={{
                color: 'white',
                borderColor: 'primary.main',
                pointerEvents: 'none',
                userSelect: 'text',
              }}
            >
              <span style={{ color: theme.palette.primary.darker,marginRight:8 }}>Selected Projects </span>
              {communitiesData
                .filter(community => filterCommunity.includes(community.id))
                .map(community => community.name)
                .join(', ')
              }
            </Button>
          </>
        } */}
        {all ?(<InfiniteScroll
          next={onScroll}
          hasMore={hasMore}
          loader={SkeletonLoad}
          dataLength={posts.length}
          style={{ overflow: 'inherit' }}
        >
          <Grid container spacing={3}>
            {data?.map((post, index) => (
              // <BlogPostCard key={post.id} post={post} index={index} />
              <Grid key={index} item xs={12} sm={6} md={4}>
              <UserCard medal={post.status === 0 || post.status === 1 ? 'https://signwisesolutions.com/uploads/ranks/super.png' : post.status !== 0 && post.status !== 1 && post.status !== 3 ? '/static/icons/great.png' : 'https://signwisesolutions.com/uploads/ranks/regular.png'} handleFetchEditData={handleFetchEditData} setIsEdit={setIsEdit} user={post} communityJob={true} />
            </Grid>
            ))}
          </Grid>
          </InfiniteScroll>
          ):(
        <Scrollbar>
            {/* <Stack sx={{ alignItems: { lg:'center',md:'center' } }} spacing={5}> */}
            <Grid container spacing={2} >
            <Tabs
              value={currentTab}
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
              onChange={handleChangeTab}   
              sx={{mb:2.5}}      
            >
              {ACCOUNT_TABS.map((tab) => (
                <Tab  sx={{
                  justifyContent: 'center', 
                  marginX: 6, 
                  mt: 2.5,
                  overflow:'visible'
                }}
                disableRipple
                key={tab.value}
                label={
                 // <Badge badgeContent={tab.count} color="primary" max={9999} showZero>
                    <Typography variant="overline">{capitalCase(tab.value)}</Typography>
                 // </Badge>
                }
                icon={tab.icon}
                value={tab.value}
              />
              ))}
            </Tabs>
  
            {ACCOUNT_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
  
        return isMatched && value === 'one' ?
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} key={tab.value}>
              {tab.component}
          </Box>
          : (isMatched && value === 'three') ?
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} key={tab.value}>
                  {tab.listComponent}
              </Box>
              : (isMatched && value === 'two') ?
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} key={tab.value}>
                 {tab.GridListComponent}
              </Box> : '';
            })}
   </Grid>
          {/* </Stack> */}
        </Scrollbar>
        )}
        
        </>
        ):(
          <AddProject singleProjectData={isEdit ? singleProjectData : null} setRenderCards={setRenderCards} isEdit={isEdit} setIsEdit={setIsEdit} setAdd={setAdd}/>
        )}
      </Container>
    </Page>
  );
}
