// materialimport { useTheme } from '@material-ui/core/styles'
import { Box, Grid, Button, Card, Stack, Typography, TextField, MenuItem, Checkbox, makeStyles, CircularProgress, AvatarGroup, Tooltip, Avatar, InputAdornment, TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody, IconButton, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';
import AddTask from '../../AddTask'
import { useEffect, useRef, useState } from 'react'
import { UserMoreMenu } from 'src/components/_dashboard/user/list';
import ConfirmationPopup from 'src/components/confirmationPopup';
import axios from 'axios';
import { BASE_URL, REST_API_END_POINT } from 'src/constants/Defaultvalues';
import { useSnackbar } from 'notistack';
import { KeyboardBackspaceOutlined, Login, Logout, QrCodeScanner, Search, Visibility } from '@material-ui/icons';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,Slider as Sliderw } from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import moment from 'moment';
import FinishedProductmenu from 'src/components/_dashboard/blog/FinishedProductmenu';
import { empty } from 'src/Helpers/Common';
import Modal from 'src/components/_dashboard/blog/ProjectModel';
import QrReader from 'react-qr-reader';
import { Html5QrcodeScanner } from 'html5-qrcode';

const option = [
  { id: 6, color:'error', value: 'In Active' },
  { id: 1, color:'primary', value: 'New' },
  { id: 2, color:'info', value: 'In Progress' },
  { id: 3, color:'success', value: 'Completed' },
  // { id: 4, color:'warning', value: 'Hold' },
  { id: 5, color:'primary', value: 'Active' },
 
  // { id: 6, color:'success', value: 'Approved by Dev team' },
  // { id: 7, color:'success', value: 'Approved by the client' },
  // { id: 8, color:'primary', value: 'Reopen' },
]
const colors = [
  '#c9302c',
  '#5bc0de',
  '#0275d8',
  '#008000',
  '#f0ad4e',
  '#5cb85c',
  '#c9302c',
  '#5cb85c',
  '#5cb85c',
  '#5bc0de',
]

const useStyles = makeStyles((theme) => ({
  card: {
      borderRadius: theme.spacing(1),
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative', 
  },
  delete:{
    m:2,
    display:'flex',
    justifyContent:'flex-end' 
  },
  media: {
      height: 0,
      paddingTop: '100%', 
      width: '100%',
      objectFit: 'fill', 
  },
  checkboxContainer: {
      position: 'absolute',
      top: theme.spacing(1),
      left: theme.spacing(1),
      zIndex:999,
  },
  gridContainer: {
      height: '350px', 
      overflowY: 'auto', 
      '&::-webkit-scrollbar': {
          width: '0.4em',
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.1)',
          borderRadius: '4px',
        },
  },
}));

function ProjectStatusPopup({ open, onClose, staffTimeLogData, handleClockInOut, autoClockoutTime }) {
  const [timerId, setTimerId] = useState(null);

  useEffect(() => {
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [timerId]);

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        handleClockInOut();
      }, autoClockoutTime * 60 * 1000);

      setTimerId(timer);
    } else {
      if (timerId) {
        clearTimeout(timerId);
      }
    }
  }, [open]);
 
  return (
      <Dialog open={open} onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose(false);
        }
      }}>
          <DialogTitle>Are you still working on this project?</DialogTitle>
          <DialogContent>
              <DialogContentText sx={{mt:3}}>
                  Do you want to continue working on "{staffTimeLogData?.task_name}" in "{staffTimeLogData?.project_name}"?
              </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button variant='contained' onClick={() => { clearTimeout(timerId); onClose(true); }}>
                  Yes
              </Button>
              <Button variant='outlined' onClick={() => { clearTimeout(timerId); onClose(false); }}>
                  No
              </Button>
          </DialogActions>
      </Dialog>
  );
}


export default function SubTasks({ details, data,setFetchData, projectMembers, taskId, projectId, setRefresh, refresh,currentTab,onDataChange }) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();
  const [singleData, setSingleData] = useState(null)
  const [add, setAdd] = useState(false)
  const [value, setValue] = useState({ id: 1, value: 'New' })
  const [isDelete,setIsDelete] = useState(false)
  const [isEdit,setIsEdit] = useState(false)
  const [deleteStates, setDeleteStates] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isSelectAll, setIsSelectAll] = useState(false)
  const [confirmAdditionalJObCost, setConfirmAdditionalJObCost] = useState(false)
  const [confirmMessage, setConfirmMessage] = useState('')
  const [materialData, setMaterialData] = useState(null)
  const [staffTimeLog, setStaffTimeLog] = useState(null)
  const [openPopup, setOpenPopup] = useState(false);
  const [searchMaterial, setSearchMaterial] = useState('');
  const [qty, setQty] = useState(1);
  const [viewMaterialData, setViewMaterialData] = useState([]);
  const [viewMaterial, setViewMaterial] = useState(false);
  const [loadingMaterials, setLoadingMaterials] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const classes = useStyles();
  const [completeStatus, setCompleteStatus] = useState(1)
  const [checkedItems, setCheckedItems] = useState([]);
  const [popUpTime, setPopUpTime] = useState(0);
  const [autoClockoutTime, setAutoClockoutTime] = useState(0);
  const [openPopupComplete, setOpenPopupComplete] = useState(false)
  const user = JSON.parse(window.localStorage.getItem('user'))
  const[skillsandServices,setSkillsService]=useState([])
  const [openQRScanner, setOpenQRScanner] = useState(false);
  const scannerRef = useRef(null);


  useEffect(() => {
    if (openQRScanner) {
      setTimeout(() => {
        if (!scannerRef.current) {
          scannerRef.current = new Html5QrcodeScanner("reader", { fps: 10, qrbox: 250 });

          scannerRef.current.render(
            (decodedText) => {
              setSearchMaterial(decodedText);
              handleAddMaterialToJobCost(decodedText); // Automatically update material state
              setOpenQRScanner(false);
            },
            (errorMessage) => {
              console.error("QR Scan Error:", errorMessage);
            }
          );
        }
      }, 500); // Ensure modal is fully rendered before initializing scanner
    }

    return () => {
      if (scannerRef.current) {
        scannerRef.current.clear();
        scannerRef.current = null;
      }
    };
  }, [openQRScanner]);

  const userId = user?.user_id
  const[view,setView]=useState(false)
 console.log("##############details",details);
  const fetchClockSettings = async() => {
    try {
        const res = await axios.post(`${REST_API_END_POINT}settings/fetch-all-clock-settings`)
        if(res.data.status === 1){
            const settingsData = res.data.clocksettings
            settingsData?.map((item)=>{
              if(item?.id == 1){
                setPopUpTime(parseInt(item?.time))
              }else if(item?.id == 2){
                setAutoClockoutTime(parseInt(item?.time))
              }
            })
        }else{
          
            setPopUpTime(0)
            setAutoClockoutTime(0)
        }
    } catch (error) {
        console.log("Clock settings error : ",error)
        setPopUpTime(0)
        setAutoClockoutTime(0)
    }
  }
 
  useEffect(()=>{
    fetchClockSettings()
  },[])

  const handleConfirmAdditionalJobCost = async () => {
  try{
    let realQty = materialData?.balanceQty || (qty || 1)
    let values = [
      {
        productId: materialData.product_id || materialData.id,
        projectId: projectId,
        actual_quantity: materialData.actual_quantity || 0,
        actual_unit_cost: materialData.actual_unit_cost || 0,
        actual_total: materialData.actual_total || 0,

        // actual_quantity: project.unit_qty || 0,
        // actual_unit_cost: project.unit_cost || 0,
        // actual_total: project.actual_total_cost || 0,


        quantity: materialData?.balanceQty || (qty || 1),
        total: (materialData.unit_price * realQty) || (materialData.unit_cost * realQty),
        unitPrice: materialData.unit_price || materialData.unit_cost
      }
    ];  
    console.log('valuesssssssssssssssssssssss',values)
    axios.post(`${REST_API_END_POINT}project/add-job-cost`, 
      { userId: user.user_id, values, projectId, recipe: 0, selectedServices:[], scanQr : true, taskId,qty }, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      if (response.data.status === 1) {
        enqueueSnackbar(response.data.message, { variant: 'success' });
      } else if (response?.data?.message) {
        enqueueSnackbar(response.data.message, { variant: 'error' });
      } else {
        enqueueSnackbar('Error adding Jobcost', { variant: 'error' });
      }
      setConfirmAdditionalJObCost(false);
      setConfirmMessage('');
      setSearchMaterial('');
      setRefresh(!refresh);
      onDataChange()
    }).catch((error) => {
      console.error(error);
      enqueueSnackbar('Error adding Jobcost', { variant: 'error' });
    })
    setQty(1)
    }catch(error){
      console.log(error)
    }
  }
  console.log('datadatadata====',data)
  const handleDeleteClick = (postId) => {
    setDeleteStates((prevState) => ({
      ...prevState,
      [postId]: true,
    }));
  };

  const handleCancelDelete = (postId) => {
    setDeleteStates((prevState) => ({
      ...prevState,
      [postId]: false,
    }));
  };
  const valuetext = (value) => {
   
    return `${value}%`
  }
  const updateStatus=(event,value)=>{
    
    let values={}
    let user=JSON.parse(window.localStorage.getItem('user'))
    values.completion=event.target.value
     values.projectId=projectId
    values.added_by=user.user_id
    values.taskId=value
    values.task=taskId
    
    if(event.target.value && values.taskId){
      axios.post(`${REST_API_END_POINT}project/update-project-sub-task-progress`,{ ...values },{
        headers: {
          'Content-Type': 'application/json',
        },
        // headers: { 'Content-Type': 'multipart/form-data' }
      })
    }
    
  }
  const handleDeleteSubTask = (id)=>{
    try {
      // const  user  = JSON.parse(window.localStorage.getItem('user'))
      axios.post(`${REST_API_END_POINT}project/delete-project-sub-task`,{ id: Number(id),taskId,projectId,status: 0 },{
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if(response.data.status === 4){
          enqueueSnackbar(response.data.message, { variant: 'success' });
          setRefresh(!refresh)
          setFetchData(true)
        }else if(response.data.status === 5){
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
        handleCancelDelete(id)
      }).catch((error) => {
        console.error(error);
      })
      }
      catch (error) {
        console.error("Error fetching data:", error);
      }
  }

  const handleFetchSubTask = (id)=>{
    try {
      // const  user  = JSON.parse(window.localStorage.getItem('user'))
      axios.post(`${REST_API_END_POINT}project/view-project-sub-task`,{ id: Number(id) },{
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if(response.data.status === 1){
          // enqueueSnackbar(response.data.message, { variant: 'success' });
          setSingleData(response.data.result[0])
          setIsEdit(true)
         
        }else if(response.data.status === 0){
          // enqueueSnackbar(response.data.message, { variant: 'error' });
        }
        handleCancelDelete(id)
      }).catch((error) => {
        console.error(error);
      })
      }
      catch (error) {
        console.error("Error fetching data:", error);
      }
  }
  const handleFetchSubTaskView = (id,view=true)=>{
    try {
      // const  user  = JSON.parse(window.localStorage.getItem('user'))
      axios.post(`${REST_API_END_POINT}project/view-project-sub-task`,{ id: Number(id) },{
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if(response.data.status === 1){ 
          // enqueueSnackbar(response.data.message, { variant: 'success' });
        
          setSingleData(response.data.result[0])

          setIsEdit(true)
          setView(view)
        }else if(response.data.status === 0){
          // enqueueSnackbar(response.data.message, { variant: 'error' });
        }
        handleCancelDelete(id)
      }).catch((error) => {
        console.error(error);
      })
      }
      catch (error) {
        console.error("Error fetching data:", error);
      }
  }
  const handleUpdateStatus = (id,status)=>{
    try {
      if(isLoggedIn){
        enqueueSnackbar('Please clockout', { variant: 'error' });
      }else{
        const  user  = JSON.parse(window.localStorage.getItem('user'))
        axios.post(`${REST_API_END_POINT}project/update-project-sub-task-status`,{ taskId: Number(id),task:Number(taskId),projectId,status,userId:user.user_id },{
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((response) => {
          if(response.data.status === 1){
            onDataChange()
            setRefresh(!refresh);
            if(response.data.readyForInstallation){
              // convertJObToInstallation()
              console.log("dfgidfbgdfgb");
              if(details?.project_category == 14){
                setCompleteStatus(1);
                convertJObToInstallation(user.user_id)
              }else{
                setOpenPopupComplete(true)
              }
            }else{
              enqueueSnackbar(response.data.message, { variant: 'success' });
            }
            // setSingleData(response.data.result[0])
            setFetchData(true)
          }else if(response.data.status === 0){
            // enqueueSnackbar(response.data.message, { variant: 'error' });
          }
          // setIsDelete(false)
        }).catch((error) => {
          console.error(error);
        })
      }
    }
    catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const convertJObToInstallation = async(userId)=>{
    const res = await axios.post(`${REST_API_END_POINT}installation/convert-job-to-installation`,
      { selectedIds: Number(projectId), userId: userId,completeStatus:Number(completeStatus),clientId:details?.customer_id})
      if(res.data.status===1) {
        setOpenPopupComplete(false);
        enqueueSnackbar(`${Number(completeStatus) === 1
          ? 'Moved To Installs'
          : Number(completeStatus) === 2
          ? 'Moved To Pickup'
          : 'Moved To Shipping'} Successfully`, { variant: 'success' });
          // onDataChange()
      }else{
        enqueueSnackbar("Not Moved To Installation", { variant: 'error' });
      }
  }
  const handleUpdateAllStatus = (status) => {
    try {
      if(isLoggedIn){
        enqueueSnackbar('Please clockout', { variant: 'error' });
      }else{
        const user = JSON.parse(window.localStorage.getItem('user'))
        let resultArray = []
        checkedItems?.map(async(id)=>{
          const response = await axios.post(`${REST_API_END_POINT}project/update-project-sub-task-status`,{ taskId: Number(id),task:Number(taskId),projectId,status,userId:user.user_id },{
            headers: {
              'Content-Type': 'application/json',
            },
          })
          if(response.data.status === 1){
            resultArray.push(response.data.status);
            onDataChange()
            if(response.data.readyForInstallation){
              console.log("dfgidfbgdfgb");
              if(details?.project_category == 14){
                setCompleteStatus(1);
                convertJObToInstallation(user.user_id)
              }else{
                setOpenPopupComplete(true)
              }
              // convertJObToInstallation(projectId,user.user_id)
            }
          }
        })
        // console.log('resultArray=====',resultArray)
        // console.log('checkedItems=====',checkedItems)
        // if(resultArray?.length == checkedItems?.length){
        //   enqueueSnackbar("Status updated successfully for all subtasks", { variant: 'success' });
        // }else{
        //   enqueueSnackbar("Failed to update status for all subtasks", { variant: 'error' });
        // }
        enqueueSnackbar("Status updated successfully for all subtasks", { variant: 'success' });
        setFetchData(true);
      }
    }
    catch (error) {
      console.error("Error fetching data:", error);
    }
  }


  const handleCheckboxChange = (id) => {
    setCheckedItems((prevState) => {
      const isPresent = prevState.includes(id);
      const updatedState = isPresent
        ? prevState.filter((item) => item !== id)
        : [...prevState, id];
      return updatedState;
    });   
  };

  const handleSelectAll = () => {
    if(isSelectAll){
      setIsSelectAll(false);
      setCheckedItems([]);
    }else{
      const allIds = data?.map((post) => String(post.ID)) || [];
      setCheckedItems(allIds);
    }
    setIsSelectAll(!isSelectAll);
  }

  const fetchTimeLogData = async () => {
    try{
      const res = await axios.post(`${REST_API_END_POINT}project-task/fetch-clockedIn-staff-task-timelog`,
      {projectId:projectId,taskId:taskId,userId:userId})
      if(res.data.status===1){
        const staffTimeLogData = res.data.result[0]
        let taskIds = staffTimeLogData?.sub_task_id
        taskIds = taskIds?.toString();
        const taskArray = taskIds?.split(",");
        setCheckedItems(taskArray)
        setStaffTimeLog(staffTimeLogData)
        if(staffTimeLogData.end_time === null){
          setIsLoggedIn(true)
        }else{
          setIsLoggedIn(false)
        }
      }else{
        setStaffTimeLog(null)
        setIsLoggedIn(false)
        setCheckedItems([])
      }
    }catch(error){
      console.log('Error fetching : ',error);
    }
  }


  useEffect(()=>{
    fetchTimeLogData()
  },[isLoggedIn])

  useEffect(() => {
    if(isLoggedIn){
      const intervalId = setInterval(() => {
          setOpenPopup(true);
      }, popUpTime * 60 * 1000);

      return () => {
          clearInterval(intervalId);
      };
    }
}, [isLoggedIn]);



const handleClockInOut = async()=>{
  try {
    if(checkedItems?.length > 0){
      let res = '';
      const now = Math.round(moment().valueOf() / 1000);
      if(isLoggedIn){
        const timeLogId = staffTimeLog?.id
        const startTime = staffTimeLog?.start_time
        const staffId = staffTimeLog?.staff_id
        const staffHourlyRate = staffTimeLog?.staff_hourly_rate || 20
        res = await axios.post(`${REST_API_END_POINT}project-task/update-staff-task-timelog`,{checkedItems, projectId, userId, timeLogId,taskId,startTime, now, staffId, staffHourlyRate})
        setFetchData(true)
      }else{
        res = await axios.post(`${REST_API_END_POINT}project-task/add-staff-task-timelog`,{checkedItems, projectId, userId,taskId,now})
        // setFetchData(true)
      }
      setIsLoggedIn(!isLoggedIn)
      setOpenPopup(false);
      setElapsedTime(0)
      if(res.data.status===1){
       
        if(res.data.result === 'add'){
          enqueueSnackbar('Clocked In successfully', { variant: 'success' });
        }else if(res.data.result === 'update'){
          enqueueSnackbar('Clocked Out successfully', { variant: 'success' });
        }
      }else{
        console.log('handleClockInOut res : ',res.data);
      }
    }else{
      enqueueSnackbar("Please select atleast one sub task",{variant:'error'})
    }
  }catch(error){
    console.log('HandleClockInOut error : ',error);
  }
}

const handleClosePopup = (continueWorking) => {
  setOpenPopup(false);
  if (!continueWorking) {
    handleClockInOut();
  }
};

useEffect(() => {
  if(staffTimeLog){
    const interval = setInterval(() => {
      const now = Math.round(moment().valueOf() / 1000);
      const difference = now - staffTimeLog?.start_time;

      setElapsedTime(difference);
    }, 1000);

    return () => clearInterval(interval);
  }
}, [staffTimeLog]);

  const hours = String(Math.floor(elapsedTime / 3600)).padStart(2, '0');
  const minutes = String(Math.floor((elapsedTime % 3600) / 60)).padStart(2, '0');
  const seconds = String(elapsedTime % 60).padStart(2, '0');

  const handleTypographyClick = () => {
   
    setIsEdit(true);
  };
  const fetchAllSkills = async () => {
    try {
      const response = await axios.post(`${REST_API_END_POINT}settings/get-all-settings`, {userId:user.user_id, userType: user.user_type})
      let data = response.data.results.services;
      setSkillsService(data)     
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(()=>{
    fetchAllSkills()
  },[isEdit])

  const getMostCommonStatus = () => {
    const statusCount = {};
    
    data?.forEach((post) => {
      const status = post?.status;
      if (status) {
        statusCount[status] = (statusCount[status] || 0) + 1;
      }
    });
  
    const mostCommonStatus = Object.keys(statusCount).reduce((a, b) => 
      statusCount[a] > statusCount[b] ? a : b
    );
    return mostCommonStatus;
  };

  const handleAddMaterialToJobCost = async (materialBarcode) => {
    try{
      if(materialBarcode && materialBarcode !=''){
        const response = await axios.post(`${REST_API_END_POINT}project/update-material-in-jobcost`,
          { userId: user.user_id, materialBarcode, projectId, taskId,qty });
        if (response.data.status === 1) {
          enqueueSnackbar(response.data.message, { variant: 'success' });
          setSearchMaterial('');
        }else if(response.data.status === 4){
          setConfirmAdditionalJObCost(true);
          setConfirmMessage(response.data.message);
          setMaterialData(response.data.materialData);
          console.log('dataaaaaaaaaaaaaaaaaa',response.data.materialData)
        } else if ((response.data.status === 2) || (response.data.status === 3)) {
          setConfirmAdditionalJObCost(true);
          setConfirmMessage(response.data.message);
          setMaterialData(response.data.materialData);
          
        } else if (response.data.message) {
          enqueueSnackbar(response.data.message, { variant: 'error' });
        } else {
          enqueueSnackbar('Error adding Jobcost', { variant: 'error' });
        }
        // setQty(1)

      }
    }catch(error){
      console.log('Error in add material to jobcost', error);
    }
  }

  const handleViewAddedMaterials = async () => {
    setLoadingMaterials(true);
    try{
      const response = await axios.post(`${REST_API_END_POINT}project/view-added-material-in-task`,
        { projectId, taskId });
      if (response.data.status === 1) {
        setViewMaterialData(response.data.result);
      } else {
        enqueueSnackbar('Failed to view materials', { variant: 'error' });
        setViewMaterialData([]);
      }
    }catch(error){
      console.log('Error in add material to jobcost', error);
      setViewMaterialData([]);
    }finally{
      setLoadingMaterials(false);
    }
  }

  return (
    <Grid  spacing={3}>
      <Grid item xs={12}>
        {(user.user_type !== 3 && 
        (details?.job_category_name == 'Service only' ||
        (currentTab != 'Installs' && currentTab != 'Pickup' && currentTab != 'Shipping' && currentTab!="Completed" && details?.status != 3)) &&
        currentTab!="Hold" && details?.hold != 1
      ) &&
        <Stack alignItems='center'  flexDirection={isSmallScreen ? 'column' : 'row'} >
          <Stack justifyContent='space-between' alignItems='center' flexDirection='row' gap={2} sx={{mb:2}} >
            <TextField
              size='small'
              type="number"
              label={'Enter Qty'}
              placeholder='Enter Qty'
              value={qty || null}
              onChange={(e)=>{
                setQty(e.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === '-' || event.key === '+' || event.key ==='.') {
                  event.preventDefault();
                }
              }}
              onWheel={(e) => e.target.blur()}
            />
            <TextField
              fullWidth
              size='small'
              label={'Scan Material'}
              placeholder='Scan Material'
              value={searchMaterial}
              onChange={(e)=>{
                setSearchMaterial(e.target.value);
                handleAddMaterialToJobCost(e.target.value)
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
            />
            
</Stack>
<Stack justifyContent='space-between' alignItems='center' flexDirection='row' gap={2} sx={{mb:2}} >
<>
      {/* Icon Button to trigger QR scanner */}
 {/* QR Code Scanner Icon */}
 <IconButton onClick={() => setOpenQRScanner(true)}>
        <QrCodeScanner sx={{ fontSize: "30px" }} />
      </IconButton>

      {/* Dialog for QR Scanner */}
      <Dialog open={openQRScanner} onClose={() => setOpenQRScanner(false)}>
        <DialogTitle>Scan QR Code</DialogTitle>
        <DialogContent>
          <div id="reader" style={{ width: "100%" }}></div>
        </DialogContent>
      </Dialog>
    </>

            <Tooltip title='View Added Materials'>
            <Button
              variant="contained"
              sx={{width:'270px'}}
              type="submit"
              onClick={()=>{handleViewAddedMaterials(); setViewMaterial(true);}}
              startIcon={
                <Stack>
                  <Visibility />
                </Stack>
              }
            >
              View Materials
            </Button>
            </Tooltip>
          </Stack>
          </Stack>
          }
          {(data?.length > 0) && <Stack justifyContent='space-between' alignItems='center' flexDirection='row'>
            <Stack justifyContent='flex-start' alignItems='center' flexDirection='row'>
              <Typography  variant='subtitle1'>{(checkedItems?.length > 0) && checkedItems?.length+' selected' }</Typography>
            </Stack>
            { (user.user_type !== 3 && currentTab != 'Installs' && currentTab != 'Pickup' && currentTab != 'Shipping' && currentTab!="Completed" && currentTab!="Hold" && details?.status != 3 && details?.hold != 1) &&
            <Stack justifyContent='flex-end' alignItems='center' flexDirection='row'>
              <Card>
                <Stack justifyContent='flex-end' alignItems='center' flexDirection='row' sx={{m:2}}>
                  <Stack justifyContent='center' alignItems='center' flexDirection='column' sx={{mr:2}}>
                    <Typography variant="h6" sx={{textAlign: 'center',color:theme.palette.secondary}}>
                    {"Date"} </Typography>
                    <Typography variant="h5" sx={{textAlign: 'center',color:theme.palette.secondary}}>
                    {moment().format('MM/DD/YYYY')}</Typography>
                  </Stack>
                  <Stack justifyContent='center' alignItems='center' flexDirection='column'>
                  <Typography variant="h6" sx={{textAlign: 'center',color:'#fc8c03'}}>
                  {"Work Time"} </Typography>
                  {isLoggedIn ? 
                    // (elapsedTime!==0) ?
                      <Typography variant="h4" sx={{textAlign: 'center',color:'#fc8c03'}}>
                      {hours+':'+minutes+':'+seconds}</Typography> 
                    // :
                    // <Stack sx={{mr:2,textAlign: 'center',mb: 2}}>
                    //   <CircularProgress color="warning" variant="indeterminate" size='20px' />
                    // </Stack>
                  : <Typography variant="h4" sx={{textAlign: 'center',color:'#fc8c03'}}>
                      {'00:00:00'}</Typography>  }
                  </Stack>
                </Stack>
              </Card>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSelectAll}
                  onClick={()=>{handleClockInOut()}}
                  sx={{ mb: 2,ml:2 }}
                  startIcon={
                    <Stack>
                      {isLoggedIn ? <Logout /> : <Login />}
                    </Stack>
                  }
                >
                  {isLoggedIn ? "Clock Out" : "Clock In"}
                </Button>
            </Stack>}
          </Stack>}
          { (user.user_type !== 3 && currentTab != 'Installs' && currentTab != 'Pickup' && currentTab != 'Shipping' && currentTab!="Completed" && currentTab!="Hold" && details?.status != 3 && details?.hold != 1) &&
          <Box width={'100%'} display={'flex'} justifyContent={ (currentTab == "Pre-Production") ? 'space-between' : 'flex-end'} mb={2}>
            {(currentTab == "Pre-Production" && data?.length > 0) &&
            <Box display={'flex'} >
              <Checkbox sx={{cursor:'pointer'}}
                disabled={isLoggedIn ? true : false}
                checked={isSelectAll}
                onChange={() => {(isLoggedIn) ? null : handleSelectAll()}}
                color="primary"
              />
              <Typography sx={{mt:1}}>Select All</Typography>
            </Box>
            }
            {isSelectAll ?
              <TextField
                select
                size="small"
                value={option?.find(opt=>opt.id==getMostCommonStatus())?.value || ''}
                sx={{
                  minWidth: { lg: '50%', md: '40%', sm: '30%' },
                  // height: '30px !important',
                  background: colors[getMostCommonStatus()],
                  color:'blue',
                  borderRadius: 1,
                  border: 'none',
                  '&:hover': {
                    // transform: 'scale(1.02)',
                    border: 'none',
                  },
                }}
              >
                {option.map((optio) => (
                  <MenuItem
                    disabled={(user.user_type == 3 || currentTab == 'Installs' || currentTab == 'Pickup' || currentTab == 'Shipping' || currentTab == 'Completed' || details?.status == 3 || details?.hold == 1) ? true : false}
                    onClick={() => {
                      handleUpdateAllStatus(optio.id)
                    }}
                    key={optio.value}
                    value={optio.value}
                  >
                    {optio.value}
                  </MenuItem>
                ))}
              </TextField>
            :
              <Button onClick={() => {
              setAdd(true);
              }} 
              variant="outlined"
              disabled={isSelectAll}
              startIcon={<Icon icon={plusFill} />}>Add Subtask</Button>
            }
          </Box>}
        {data?.length ? data.map((post, index) => {
          return(
        // <Grid key={index} item xs={12} sm={6}>
          <Card key={index}
            sx={{
              minWidth: 275,
              mb: 2,
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'scale(1.02)',
              },
              backgroundColor: checkedItems.includes(String(post.ID)) ? theme.palette.divider : null,
            }}
          >
            {/* <Box className={classes.checkboxContainer}>
              <Checkbox sx={{cursor:'pointer'}}
                checked={checkedItems?.includes(String(post?.ID)) ? true : false}
                onChange={() => {isLoggedIn ? null : handleCheckboxChange(String(post.ID))}}
                color="primary"
              />
            </Box> */}
            
            <Box sx={{ px: 2, py: 1, textAlign: 'center', justifyContent: 'center' }}>
            { (user.user_type !== 3 && currentTab != 'Installs' && currentTab != 'Pickup' && currentTab != 'Shipping' && currentTab!="Completed" && currentTab!="Hold" && details?.status != 3 && details?.hold != 1)&&
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Checkbox sx={{cursor:'pointer'}}
                  checked={checkedItems?.includes(String(post?.ID)) ? true : false}
                  onChange={() => {(isLoggedIn || post.status==3) ? null : handleCheckboxChange(String(post.ID))}}
                  color="primary"
                />
              {isSelectAll ? null :
               <UserMoreMenu
               onEdit={()=>{
                if(isLoggedIn){
                  enqueueSnackbar('Please clockout to make changes', { variant: 'error' });
                }else{
                  handleFetchSubTask(post.ID)
                }
              }}
               onDelete={()=>{
                if(isLoggedIn){
                  enqueueSnackbar('Please clockout to delete the subtask', { variant: 'error' });
                }else{
                  handleDeleteClick(post.ID)
                }}}
               />}
              </Stack>}
            </Box>
            <Box sx={{ px: 2,pb: 3, justifyContent: 'space-between' }}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Typography variant="h6" align="center" sx={{cursor:'pointer'}}
                  onClick={()=>handleFetchSubTaskView(post.ID)}>
                  {post.name}
                </Typography>
                {/* <Stack>
                <Typography
                    variant="overline"
                    sx={{ mt: 3, display: 'block', color: 'text.secondary' }}
                  >
                    Description
                  </Typography>
                <Typography variant="body" align="center">
                  {post.description}
                </Typography>
                </Stack> */}
                {(currentTab != 'Installs' && currentTab != 'Pickup' && currentTab != 'Shipping' && details?.status != 3 && details?.hold != 1 && currentTab!="Completed" && currentTab!="Hold") &&<Typography variant="body" align="center">
                  {post?.total_hours ? 'Total Hours : '+post?.total_hours : 'Total Hours : 00:00:00'}
                </Typography>}
               {(currentTab != 'Installs' && currentTab != 'Pickup' && currentTab != 'Shipping' && details?.status != 3 && details?.hold != 1 && currentTab!="Completed" && currentTab!="Hold") && <TextField
                    select
                    disabled={(checkedItems?.includes(String(post?.ID)) || isSelectAll || post.status==3) ? true : false}
                    size="small"
                    value={option.find(opt=>opt.id===post.status).value || ''}
                    sx={{
                      minWidth: { lg: '50%', md: '40%', sm: '30%' ,color:"#fff"},
                      // height: '30px !important',
                      background: colors[post.status],
                      // color:'blue',
                      borderRadius: 1,
                      border: 'none',
                      '&:hover': {
                        // transform: 'scale(1.02)',
                        border: 'none',
                      },
                    }}
                  >
                    {option.map((optio) => (
                      <MenuItem
                        disabled={(user.user_type == 3 || currentTab == 'Installs' || currentTab == 'Pickup' || currentTab == 'Shipping' || currentTab == 'Completed' || details?.status == 3 || details?.hold == 1)? true : false}
                        onClick={() => {
                          handleUpdateStatus(post.ID,optio.id)
                          // setValue(optio)
                        }}
                        key={optio.value}
                        value={optio.value}
                      >
                        {optio.value}
                      </MenuItem>
                    ))}
                </TextField>}
              </Stack>
            </Box>
           {/* {(currentTab != 'Installs' && currentTab != 'Pickup' && currentTab != 'Shipping' && details?.status != 3 && details?.hold != 1 && currentTab!="Completed" && currentTab!="Hold") && <Box sx={{ marginLeft: 2, marginRight: 2 }}>
                  <Typography
                    variant="overline"
                    sx={{ mt: 3,display: 'block', color: 'text.secondary' }}
                  >
                    Completion
                  </Typography>
                
                  <Sliderw
                    aria-label="Percentage"
                    defaultValue={post.complete}
                    getAriaValueText={valuetext}
                    onChange={(event, value) => updateStatus(event, post.ID)}
                    valueLabelDisplay="auto"
                    disabled={(user.user_type == 3 || currentTab == 'Installs' || currentTab == 'Pickup' || currentTab == 'Shipping' || currentTab == 'Completed' || details?.status == 3 || details?.hold == 1) ? true : false}
                    //  step={10}
                    marks
                    min={0}
                    max={100}
                  />
            </Box>} */}
            <Box sx={{ display: 'flex',justifyContent:'space-between', alignItems: 'center', p: 2, }}>    
            {/* {post.status===3 && (
            <Stack
                direction={'row'}
                justifyContent={'flex-end'}
                alignItems={'center'}
              >
                <FinishedProductmenu 
                url={`${BASE_URL}view-sub-task/`}
                id={post.ID}
                 useIcon={true}/>
                </Stack>
            )} */}
             <Stack>    
              <Typography variant="subtitle2">
                   Job Members
                    </Typography>             
                    <AvatarGroup max={4}>
                     {post.teamMembers?.map((row)=>( 
                        <Tooltip key={row.id} title={row.name}>             
                      <Avatar src={row.profile_pic} alt={row.name} />
                      </Tooltip>                   
                     ))}
                    </AvatarGroup>
                  </Stack>
            </Box>
            <ConfirmationPopup open={deleteStates[post.ID]} handleClick={()=>handleDeleteSubTask(post.ID)} message={'Want to Delete This SubTask'} handleClose={()=>handleCancelDelete(post.ID)}/>
          </Card>
          )
        }) :
        <Box
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          py={15}
        >
          No subtasks found 
        </Box>}
      </Grid>
      <ConfirmationPopup open={confirmAdditionalJObCost} handleClick={()=>handleConfirmAdditionalJobCost()} message={confirmMessage} handleClose={()=>{setConfirmAdditionalJObCost(false);setConfirmMessage('');setSearchMaterial('')}}/>
      <AddTask title={isEdit ? view?'View SubTask':'Edit SubTask' : 'Add SubTask'} data={singleData} setData={setSingleData} setFetchData={setFetchData} projectMembers={projectMembers} taskId={taskId} add={add || isEdit} setAdd={isEdit ? setIsEdit : setAdd} projectId={projectId} view={view} setView={setView} skillsandServices={skillsandServices} />
      <ProjectStatusPopup open={openPopup} onClose={handleClosePopup} staffTimeLogData={staffTimeLog} handleClockInOut={handleClockInOut} autoClockoutTime={autoClockoutTime} />
      <Modal
      open={viewMaterial ? true : false}
      handleClose={() => {
        setViewMaterialData([]);
        setViewMaterial(false);
      }}
      modalTitle={'Materials added'}
    >
      {loadingMaterials ?
      (
        <CircularProgress color="primary" variant="indeterminate" />
      ) : (
        viewMaterialData?.length > 0 ?
        <TableContainer component={Paper}>
          <Table>
              <TableHead>
                  <TableRow>
                      <TableCell>Sl No.</TableCell>
                      <TableCell>Barcode</TableCell>
                      <TableCell>Product Name</TableCell>
                      <TableCell>Unit Cost</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Total</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  {viewMaterialData?.map((material, index) => (
                      <TableRow key={material.id}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{material.barcode}</TableCell>
                          <TableCell>{material.productName}</TableCell>
                          <TableCell>{material.unit_price}</TableCell>
                          <TableCell>{material.quantity}</TableCell>
                        <TableCell>{parseFloat(material.unit_price * material.quantity).toFixed(2)}</TableCell>
                          {/* <TableCell>{material.total}</TableCell> */}
                      </TableRow>
                  ))}
              </TableBody>
          </Table>
        </TableContainer>
        :
        <Typography>No materials added</Typography>
      )}
    </Modal>
    <ConfirmationPopup
      isComplete={true}
      setCompleteStatus={setCompleteStatus}
        open={openPopupComplete}
        handleClick={() => {
          convertJObToInstallation(user.user_id)
          
        }}
        hideCancel={true}
        // handleClose={() => {
        //   setOpenPopupComplete(!openPopupComplete)
        // }}
        // message={`All tasks and subtasks have been moved to the ${status?.value} state`}
      />
    </Grid>
  )
}
