const AccountType = [
    { id: 1, code: 'BA', label: 'Basic Checking Accounts' },
    { id: 2, code: 'SA', label: 'Savings Accounts' },
    { id: 3, code: 'IC', label: 'Interest Bearing Checking Accounts' },
    { id: 4, code: 'MA', label: 'Money Market Accounts' },
    { id: 5, code: 'CD', label: "CD's" },
    { id: 6, code: 'IRA', label: 'Investment Retirement Accounts (IRAs)' },
    { id: 7, code: 'BR', label: 'Brokerage Accounts' },
];

export default AccountType;