import { TextField, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

export default function ChatSearchSignWise({ query, setQuery }) {
  return (
    <TextField
      variant="outlined"
      fullWidth
      placeholder="Search contacts..."
      value={query}
      onChange={(e) => setQuery(e.target.value)}
      InputProps={{
        style: { borderRadius: 10 },
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon style={{ color: 'gray' }} />
          </InputAdornment>
        ),
      }}
    />
  );
}
