import PropTypes from 'prop-types'
// material
import { experimentalStyled as styled } from '@material-ui/core/styles'
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  Rating,
} from '@material-ui/core'
import { Details, Group as GroupIcon } from '@material-ui/icons'
import { Lock as LockIcon } from '@material-ui/icons'
import { ExpandMore, ExpandLess } from '@material-ui/icons'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
//
import ProfileAbout from './ProfileAbout'
import ProfilePostCard from './ProfilePostCard'
import ProfilePostInput from './ProfilePostInput'
import ProfileFollowInfo from './ProfileFollowInfo'
import ProfileSocialInfo from './ProfileSocialInfo'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { REST_API, REST_API_END_POINT } from 'src/constants/Defaultvalues'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router'
import { PATH_DASHBOARD } from 'src/routes/paths'
import ProfileMessage from 'src/components/_dashboard/user/profile/ProfileMessage.js'
import Label from 'src/components/Label'
import { event } from 'jquery'

// ----------------------------------------------------------------------

Profile.propTypes = {
  myProfile: PropTypes.object,
  posts: PropTypes.array,
}

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}))
export default function Profile({ myProfile, posts, userData, teamData,clientData,services,coverImage,setCoverImage }) {
  const [internalNote, setInternalNote] = useState('')
  const [selectedUser, setSelectedUser] = useState()
  const [chatUsers, setChatUsers] = useState([])
  const [internalNotes, setInternalNotes] = useState()
  const [feedbackData,setFeedbackData] = useState({
    review: '',
    quest: [],
    qualityRating: 0,
    deliveryRating: 0,
    valueForMoneyRating: 0,
    recommended: null,
  })
 
  const { enqueueSnackbar } = useSnackbar()
  const [liked, setLiked] = useState(false)
  const [disliked, setDisliked] = useState(false)
  const [allRequest,setAllRequest] = useState(null)
  const navigate = useNavigate()
  const fetchAllRequets=async()=>{
    try {
      let res =  await axios.post(`${REST_API}webservice/request/get-all-request-new`, {
        userId:userData.user_id,
        userType:userData.user_type
      })
      setAllRequest(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(()=>{
    fetchAllRequets()
  },[])
  const fetchUpdatedData = async (teamId) => {
    const response = await axios.post(
      `${REST_API_END_POINT}teams/view-team-profile`,
      { userId: teamId },
    )
    return response.data.data.team
  }

  const handleLike = () => {
    if (disliked) {
      setDisliked(false)
    }
    setLiked(!liked)
    setFeedbackData((prevFeedbackData) => ({
      ...prevFeedbackData,
      recommended: true,
    }));
  }

  const handleDislike = () => {
    if (liked) {
      setLiked(false)
    }
    setDisliked(!disliked)
    setFeedbackData((prevFeedbackData) => ({
      ...prevFeedbackData,
      recommended: false,
    }));
  }
  const handleInternalNote = async () => {
    try {
      if (internalNote) {
        const userId = userData.user_id // userId
        const receivedId = teamData.id // teamId
        const internalNoteResult = await axios.post(
          `${REST_API_END_POINT}teams/add-internal-note`,
          { receivedId, userId, internalNote },
        )
        if (internalNoteResult.data.status === 1) {
          enqueueSnackbar('Internal note added successfully', {
            variant: 'success',
          })
          const updatedData = await fetchUpdatedData(receivedId)
          setInternalNotes(updatedData.internal_note)
          navigate(PATH_DASHBOARD.user.profile)
        } else {
          enqueueSnackbar('Internal note updation error', { variant: 'error' })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleResetInternalNote = () => {
    setInternalNote('')
  }

  const handleAddRecipient = (newValue) => {
    setSelectedUser(newValue?.id || null)
  }
  //  the below function points to the review adding functionality
  const handleAddReview=async()=>{
    try {
      
         const res = await axios.post(`${REST_API_END_POINT}clients/add-review`,{
           userId: userData.user_id,
           clientId:clientData.id,
           feedbackData
         } )
         if (res.data.status === 1) {
         enqueueSnackbar(res.data.message,{variant:'success'})
        }
          else{
            enqueueSnackbar(res.data.message, {
              variant: 'error',
            })
          }
          // navigate(PATH_DASHBOARD.user.profile)
    } catch (error) {
      console.log(error,'Error in adding review ')
    }
  }
  const fetchUserData = () => {
    axios
      .post(`${REST_API_END_POINT}teams/get-all-users`, {
        userId: userData.user_id,
        selectedUser: selectedUser,
      })
      .then((resultUsers) => {
        if (resultUsers.data.status === 1) {
          setChatUsers(resultUsers.data.users)
        }
      })
  }
  useEffect(() => {
    fetchUserData()
  }, [selectedUser, internalNotes])
 
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Stack spacing={3}>
          {/* <ProfileFollowInfo profile={myProfile} /> */}
          <ProfileAbout
           coverImage={coverImage}
            setCoverImage={setCoverImage}
            profile={myProfile}
            clientData={clientData}
            userData={userData}
            teamData={teamData}
            internalNotes={internalNotes}
          />
          {/* <ProfileSocialInfo profile={myProfile} /> */}
        </Stack>
      </Grid>
      
      <Grid item xs={12} md={8}>
        <Stack spacing={3}>
          {userData.user_type !== 1 && userData.user_type !== 3 &&
            (teamData.internal_note || internalNotes ? null : (
              <>
                <TextField
                  fullWidth
                  disabled={userData.internal_note ? true : false}
                  multiline
                  minRows={4}
                  maxRows={4}
                  onChange={(e) => {
                    const typedData = e.target.value
                    setInternalNote(typedData)
                  }}
                  value={internalNote}
                  label="Internal note"
                  placeholder="Write a note here"
                />
                <Box
                  sx={{
                    mt: 3,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingBottom: 2,
                    marginBottom: 2,
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={handleInternalNote}
                  >
                    Add Internal Note
                  </Button>

                  <Button
                    variant="outlined"
                    type="submit"
                    onClick={handleResetInternalNote}
                    sx={{ marginLeft: '10px' }}
                  >
                    Clear
                  </Button>
                </Box>
              </>
            ))}
            {userData.user_type === 3 && (
               <TextField
               fullWidth              
               multiline
               minRows={4}
               maxRows={4}              
               value={clientData?.internal_note}
               label="Internal note"

             />
            )}
          <Autocomplete
            select
            fullWidth
            options={chatUsers}
            disableCloseOnSelect={false}
            onChange={(event, newValue) => handleAddRecipient(newValue)}
            getOptionLabel={(option) =>
              `${option.first_name} ${option.last_name}`
            }
            value={chatUsers.find((user) => user.id === selectedUser)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Avatar src={option.profile_pic} alt={option.first_name} />
                <Box ml={2}>
                  {option.first_name} {option.last_name}
                </Box>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="All team members"
                placeholder="Select team member to chat"
              />
            )}
          />

          {selectedUser && (
            <ProfileMessage
              key={selectedUser}
              id={selectedUser}
              userData={userData}
              // messageCount={handleMessageCount}
            />
          )}
          
        </Stack>
                 {/* {userData.user_type  === 3 && (
                  <>
                  <Label 
                   sx={{ textTransform: 'uppercase',mt:2}}
                  color='primary' variant='outlined'>Review</Label>
                    <Stack mt={2} spacing={3}>
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 3, sm: 2 }}
                      >
                        <Grid item xs={3}>
                          {[100].map((size, index) => (
                            <Avatar
                              key={size}
                              // alt="Travis Howard"
                              src={userData?.profile_pic}
                              sx={{ width: size, height: size }}
                            />
                          ))}
                        </Grid>

                        <TextField
                          fullWidth
                          id='review'
                          label="Write your review here"
                          multiline
                          onChange={(event)=>{
                            setFeedbackData((prevFeedbackData) => ({
                              ...prevFeedbackData,
                               review:event.target.value,
                            }));
                          }}
                          rows={4}
                          placeholder="Write your review here"
                        />
                      </Stack>
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 3, sm: 2 }}
                      >
                         <Autocomplete
                          multiple 
                          fullWidth
                          id="quest" 
                          options={allRequest?allRequest:[]}  
                          onChange={(event,newValue)=>{                   
                            setFeedbackData((prevFeedbackData) => ({
                              ...prevFeedbackData,
                              quest: newValue,
                            }));
                          }}               
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Add Quest"
                              placeholder="Type to add quest (optional)"
                              fullWidth 
                              SelectProps={{ native: true }} 
                            >
                              {allRequest?.map((option) => (
                                <option key={option.id} value={option.name}>
                                  {option.name}
                                </option>
                              ))}
                            </TextField>
                          )}
                        />
                        <Autocomplete
                          multiple 
                          fullWidth
                          id="services" 
                          options={services?services:[]}  
                          onChange={(event,newValue)=>{                   
                            setFeedbackData((prevFeedbackData) => ({
                              ...prevFeedbackData,
                              services: newValue,
                            }));
                          }}               
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Add Service"
                              placeholder="Type to add service (optional)"
                              fullWidth 
                              SelectProps={{ native: true }} 
                            >
                              {services?.map((option) => (
                                <option key={option.id} value={option.name}>
                                  {option.name}
                                </option>
                              ))}
                            </TextField>
                          )}
                        />
                      </Stack>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                          <Stack>
                            <LabelStyle>Quality</LabelStyle>
                            <Rating
                              name="Quality"
                              defaultValue={0}
                              onChange={(event)=>{
                                setFeedbackData((prevFeedbackData) => ({
                                  ...prevFeedbackData,
                                   qualityRating:event.target.value,
                                }));
                              }}
                              precision={0.5}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Stack>
                            <LabelStyle>Delivery</LabelStyle>
                            <Rating
                              name="Delivery"
                              defaultValue={0}
                              onChange={(event)=>{
                                setFeedbackData((prevFeedbackData) => ({
                                  ...prevFeedbackData,
                                   deliveryRating:event.target.value,
                                }));
                              }}
                              precision={0.5}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Stack>
                            <LabelStyle>Value for money</LabelStyle>
                            <Rating
                              name="Value-for-money"
                              defaultValue={0}
                              onChange={(event)=>{
                                setFeedbackData((prevFeedbackData) => ({
                                  ...prevFeedbackData,
                                   valueForMoneyRating:event.target.value,
                                }));
                              }}
                              precision={0.5}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Stack>
                            <LabelStyle>Recommended</LabelStyle>
                            <Stack
                              style={{ display: 'flex', alignItems: 'center',flexDirection:'row' }}
                            >
                              <IconButton
                                aria-label="like"
                                onClick={handleLike}
                                style={{ color: liked ? 'green' : 'inherit' }}
                              >
                                <ThumbUpIcon/>
                              </IconButton>
                              <IconButton
                                aria-label="dislike"
                                onClick={handleDislike}
                                style={{ color: disliked ? 'red' : 'inherit' }}
                              >
                                <ThumbDownIcon />
                              </IconButton>
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack>
                      <Box
                        sx={{
                          mt: 3,
                          display: 'flex',
                          justifyContent: 'flex-start',
                          paddingBottom: 2,
                          marginBottom: 2,
                        }}
                      >
                        <Button
                          variant="contained"
                          type='submit'
                          sx={{ marginLeft: '10px' }}
                          onClick={() => 
                            handleAddReview()
                          }
                        >
                          Post Private Feedback
                        </Button>
                        <Button
                          variant="outlined"
                          sx={{ marginLeft: '10px' }}
                          onClick={() => {
                           setFeedbackData({})
                          }}
                        >
                          Cancel
                        </Button>
                      </Box>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <LockIcon color="primary" fontSize="small" />
                        <Typography
                          variant="body2"
                          sx={{
                            cursor: 'pointer',
                            color: 'primary.main',
                          }}
                          onClick={() => {}}
                        >
                          Your feedback will only be visible to co-workers
                        </Typography>
                      </Stack>
                    </Stack>
                    </>
                    )} */}
                
      </Grid>
    </Grid>
  )
}
