import { useState, useEffect } from 'react'
import { Box, IconButton, Stack, Typography, useMediaQuery } from '@material-ui/core'
import { experimentalStyled as styled } from '@material-ui/core/styles'
import Scrollbar from 'src/components/Scrollbar'
import ChatHomeListSignWise from './ChatHomeListSignWise'
import ChatSearchSignWise from './ChatSearchSignWise'
import ChatAccountSignWise from './ChatAccountSignWise'
import { MIconButton } from 'src/components/@material-extend'
import { Icon } from '@iconify/react'
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill'
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill'
import { Navigate, useNavigate } from 'react-router'
import { PATH_DASHBOARD } from 'src/routes/paths'
import ChatHistory from './ChatHistory'
import { Button } from '@mui/material'
import moment from 'moment'
// Sidebar Container
const RootStyle = styled('div')(({ theme, openSidebar }) => ({
  width: openSidebar ? 320 : 80,
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'column',
  transition: 'width 0.3s ease-in-out',
  overflow: 'hidden',
  // transition: theme.transitions.create('width'),
  borderRight: `1px solid ${theme.palette.divider}`,
  // backgroundColor: '#121212',
  // color: '#fff',
  height: '100vh',
}))

export default function ChatSidebarSignWise({
  teamMembers,
  setSelectedContact,
  selectedContact,
  setCompose,
  openCompose,
  setOpen,
  refreshChat,
  setRenderChat,
  renderChat,
  status, setStatus,refresh, setRefresh,
  showHistory,
  setShowHistory,
  chatOpenTime,
  setChatOpenTime,
  historyMessage,
  setHistoryMessage,
  selectedChatLogId,
  setSelectedChatLogId,
  newChat,
  setNewChat,
  handleRefresh,

}) {
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useState('')
  const [openSidebar, setOpenSidebar] = useState(true)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const filteredMembers = teamMembers.filter(
    (member) =>
      member.full_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      member.group_name?.toLowerCase().includes(searchQuery.toLowerCase()),
      // member.email?.toLowerCase().includes(searchQuery.toLowerCase()),
  )
  useEffect(() => {
    if (!openSidebar) {
      setSearchQuery('') // Reset search when sidebar is collapsed
    }
  }, [openSidebar])

  return (
    <RootStyle openSidebar={openSidebar}>
      <Box
        sx={{
          py: 2,
          px: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: openSidebar ? 'space-between' : 'center',
        }}
      >
        {openSidebar && <ChatAccountSignWise status={status} setStatus={setStatus} refresh={refresh} setRefresh={setRefresh} />}
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" spacing={0.4}>
          <MIconButton onClick={() => setOpenSidebar(!openSidebar)}>
            <Icon
              width={20}
              height={20}
              icon={openSidebar ? arrowIosBackFill : arrowIosForwardFill}
            />
          </MIconButton>
          {openSidebar && (
            <MIconButton>
              <Stack
                className="material-symbols--group-add-outline"
                sx={{
                  fontSize: '1.5rem',
                  cursor: 'pointer',
                  color: 'text.secondary',
                }}
                onClick={() => {
                  if(selectedContact){
                    setCompose(!openCompose); 
                    setShowHistory(false); 
                    setSelectedContact(null);   
                  }
                }}
                // onClick={() => console.log('Add New Group Clicked')}
              />
            </MIconButton>
          )}
          {/* {openSidebar && (
            <MIconButton>
              <Stack
                className="ic--round-open-in-full"
                sx={{
                  fontSize: '1.3rem',
                  cursor: 'pointer',
                  color: 'text.secondary',  
                }}
                onClick={() => {
                  navigate(PATH_DASHBOARD.chatSignwise.root , {state :{showHistory:showHistory}});
                  setOpen(false);
                }}
              />
            </MIconButton>
          )} */}
        </Stack>
      </Box>

    
      {/* Search Bar */}
      {openSidebar && (
        <Stack sx={{
          // py: 0.5,
          px: 3,
        }}>
           {
          (!selectedContact && !showHistory)?(
          <ChatSearchSignWise query={searchQuery} setQuery={setSearchQuery} />
        ):(
        <>
          <Button onClick={()=>{
            let currentUnixTime =moment().unix()
            setChatOpenTime(currentUnixTime)
            setSelectedChatLogId(null)
            setNewChat(!newChat)
          }}>
            <Stack className='note-icon' /> 
            <Typography variant="body2" sx={{ml:2,color:"#fff"}}>New Chat</Typography>
          </Button>
        </>)}
        </Stack>
      )}

      {/* Contact List */}
      <Scrollbar sx={{maxHeight: '320px'}}>
        {
          (!selectedContact && !showHistory)?
        
        <ChatHomeListSignWise
          openSidebar={openSidebar}
          teamMembers={filteredMembers}
          onSelectContact={setSelectedContact}
          setCompose={setCompose}
          openCompose={openCompose}
          setRenderChat={setRenderChat}
          renderChat={renderChat}
          setShowHistory={setShowHistory}
          showHistory={showHistory}
          chatOpenTime={chatOpenTime}
          setChatOpenTime={setChatOpenTime}
          setSelectedChatLogId={setSelectedChatLogId}
          setHistoryMessage={setHistoryMessage}
        />
          :
       <ChatHistory
          // openSidebar={openSidebar}
          // teamMembers={filteredMembers}
          // onSelectContact={setSelectedContact}
          // setCompose={setCompose}
          // openCompose={openCompose}
          // setRenderChat={setRenderChat}
          // renderChat={renderChat}
          // setShowHistory={setShowHistory}
          // showHistory={showHistory}
          setHistoryMessage={setHistoryMessage}
          historyMessage={historyMessage}
          selectedChatLogId={selectedChatLogId}
          setSelectedChatLogId={setSelectedChatLogId}
          handleRefresh={handleRefresh}
        />
}
      </Scrollbar>
    </RootStyle>
  )
}
